import React from "react";
import { withRouter, useParams } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import LabelPrint from "../../components/printer/label-print";
import { storagePrintLabel } from "../../services/storage.service";

const StorageLabelPrintPage: React.FC = (props: any) => {
  const { uuid, storageUuid, templateUuid } = useParams<{
    uuid: string;
    storageUuid: string;
    templateUuid: string;
  }>();
  const { t } = useTranslation();
  const payload = {
    restaurantuuid: uuid,
    storageUuid: storageUuid,
    data: {
      template_uuid: templateUuid,
    },
  };
  return <LabelPrint payload={payload} printFn={storagePrintLabel} />;
};

export default withTranslation()(withRouter(StorageLabelPrintPage));
