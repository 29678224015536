import { call, put, takeLatest } from "redux-saga/effects";

import { GLOBAL_REQUEST } from "../config/api.config";
import {
  channelsDetailsFailure,
  channelsDetailsSuccess,
  channelsFailure,
  channelsSuccess,
  channelsAddRestaurantsFailure,
  channelsAddRestaurantsSuccess,
  restaurantChannelListSuccess,
  restaurantChannelListFailure,
} from "../redux/actions/channels.action";

import * as types from "../redux/actions/types";
import channels from "../services/channels.service";
import handleResponse from "../services/response.service";

const channelsListCall = (params: any) =>
  channels.channelsList(GLOBAL_REQUEST, params);
const restaurantChannelList = (params: any) =>
  channels.restaurantChannelList(GLOBAL_REQUEST);

const channelsDetailsCall = (params: any) =>
  channels.channelsDetails(GLOBAL_REQUEST, params);

const channelsAddRestaurantCall = (params: any) =>
  channels.addRestaurantsTochannels(GLOBAL_REQUEST, params);

function* handlechannelsRequest(params: object) {
  try {
    let result: any;
    result = yield call(channelsListCall, params);
    yield handlechannelsRequest200(result);
  } catch (error) {
    throw error;
  }
}

const handlechannelsRequest200 = function* handlechannelsRequest200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(channelsSuccess(result.data));
  } else {
    yield put(channelsFailure(result.data));
    handleResponse(result);
  }
};

function* handlechannelsDetailsRequest(params: object) {
  try {
    let result: any;
    result = yield call(channelsDetailsCall, params);
    yield handlechannelsDetailsRequest200(result);
  } catch (error) {
    throw error;
  }
}

const handlechannelsDetailsRequest200 = function* handlechannelsDetailsRequest(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(channelsDetailsSuccess(result.data));
  } else {
    yield put(channelsDetailsFailure(result.data));
    handleResponse(result);
  }
};

function* handlechannelsAddRestaurantsRequest(params: object) {
  try {
    let result: any;
    result = yield call(channelsAddRestaurantCall, params);
    yield handlechannelsAddRestaurantsRequest200(result);
  } catch (error) {
    throw error;
  }
}

const handlechannelsAddRestaurantsRequest200 =
  function* handlechannelsAddRestaurantsRequest(result: any) {
    if (!result) {
      return;
    }
    if (result.status === 200) {
      yield put(channelsAddRestaurantsSuccess(result.data));
    } else {
      yield put(channelsAddRestaurantsFailure(result.data));
      handleResponse(result);
    }
  };
function* handleRestaurantsChannelsListRequest(params: object) {
  try {
    let result: any;
    result = yield call(restaurantChannelList, params);
    yield handleRestaurantsChannelsListRequest200(result);
  } catch (error) {
    throw error;
  }
}

const handleRestaurantsChannelsListRequest200 =
  function* handleRestaurantsChannelsListRequest(result: any) {
    if (!result) {
      return;
    }
    if (result.status === 200) {
      yield put(restaurantChannelListSuccess(result.data));
    } else {
      yield put(restaurantChannelListFailure(result.data));
      handleResponse(result);
    }
  };

export default function* channelsSaga() {
  yield takeLatest(types.CHANNELS.REQUEST, handlechannelsRequest);
  yield takeLatest(
    types.CHANNELS_DETAILS.REQUEST,
    handlechannelsDetailsRequest
  );
  yield takeLatest(
    types.CHANNELS_ADD_RESTAURANTS.REQUEST,
    handlechannelsAddRestaurantsRequest
  );
  yield takeLatest(
    types.RESTAURANTS_CHANNELS_LIST.REQUEST,
    handleRestaurantsChannelsListRequest
  );
}
