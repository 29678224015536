import apiCall from "./api.call.config";

async function language(url: string, params: any) {
  try {
    return await apiCall.get(url + "language");
  } catch (e) {
    throw e;
  }
}

async function updateLanguage(url: string, params: any) {
  try {
    return await apiCall.put(
      url + "/" + params.credentials.restaurantuuid + "/languages",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  language,
  updateLanguage,
};
