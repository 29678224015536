import {
  GuestGroupModel,
  GuestGroupModelInitState,
} from "../../models/guest.group.model";
import * as types from "../actions/types";

export function guestGroupReducer(
  state = GuestGroupModelInitState,
  action: any
): GuestGroupModel {
  switch (action.type) {
    case types.GUEST_GROUPS.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.GUEST_GROUPS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.GUEST_GROUPS.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function guestGroupUpdateReducer(
  state = GuestGroupModelInitState,
  action: any
): GuestGroupModel {
  switch (action.type) {
    case types.GUEST_GROUP_UPDATE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.GUEST_GROUP_UPDATE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.GUEST_GROUP_UPDATE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
