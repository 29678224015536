import { RestaurantModel } from '../../models/restaurant.model';
import * as types from '../actions/types';

const initialState: RestaurantModel = {
  isFetching: false,
  data: {},
  error: {},
  failure: false,
  languages:[],

};
export default function restaurantUpdateReducer(state = initialState, action: any): RestaurantModel {
  switch (action.type) {
    case types.RESTAURANT_UPDATE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {}
      };
    case types.RESTAURANT_UPDATE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {}
      };
    case types.RESTAURANT_UPDATE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        data:action.err,
        error: action.err
      };
    default:
      return state;
  }
}
