import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, FormGroup, Input, Label, Media } from "reactstrap";
import image from "../../../assets/images/icon/image.png";
import { OpenMediaManager } from "../media-manager-card/media-manager-card";

export default function RestaurantBrandingCard(props: any) {
  const { t } = useTranslation();

  const setRecipeImageEdit = (event: any) => {
    onShowMediaManager();
  };

  const onShowMediaManager = async () => {
    let mediaItem = await getUuidFromMediaManager(props.restaurantLogo?.uuid, [
      "image",
    ]);
    if (mediaItem) {
      props.updateLogo(mediaItem);
    }
  };

  const getUuidFromMediaManager = async (
    defaultUuid: any = null,
    filter: any = [],
    entityType: string = "media"
  ) => {
    return await OpenMediaManager({
      restaurantUuid: props.restaurantUuid,
      typeFilter: filter,
      predefinedMediaUuid: defaultUuid,
      apiFilters: {
        pageNo: 1,
        pageSize: 50,
        filter: "type=" + filter,
      },
      entityType: entityType,
    });
  };

  return (
    <>
      <div className="child-box mb-3">
        <Form>
          <FormGroup className="pl-1 row">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {t("restaurant.corporateColor")}
              </Label>
            </Col>
            <Col sm="6" lg="6">
              <Input
                type="color"
                name="corporateColor"
                placeholder={t("common.color")}
                value={props.corporateColor}
                onChange={props.handleChangeSetting}
              />
            </Col>
          </FormGroup>
          <FormGroup className="pl-1 row">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {t("restaurant.logo")}
              </Label>
            </Col>
            <Col sm="6" lg="6">
              <Media left className="img-block mb-2">
                <img
                  onClick={setRecipeImageEdit}
                  data-toggle="tooltip"
                  data-placement="left"
                  title={t("restaurant.logo")}
                  alt=""
                  src={
                    props.restaurantLogo?.thumbnail
                      ? props.restaurantLogo?.thumbnail
                      : image
                  }
                  className="pointer-cursor"
                />
              </Media>
            </Col>
          </FormGroup>
        </Form>
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button
          className="btn btn-info"
          type="button"
          onClick={props.handleSubmit}
        >
          {t("common.save")}
        </Button>
      </div>
    </>
  );
}
