import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function currencyRequest(credentials: object): ActionModel {
  return {
    type: types.CURRENCY.REQUEST,
    credentials,
  };
}

export function currencySuccess(data: object): ActionModel {
  return {
    type: types.CURRENCY.SUCCESS,
    data,
  };
}

export function currencyFailure(err: object): ActionModel {
  return {
    type: types.CURRENCY.FAILURE,
    err,
  };
}

