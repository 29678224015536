import apiCall from "./api.call.config";

async function restaurantDetail(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.credentials.restaurantuuid + "/details"
    );
  } catch (e) {
    throw e;
  }
}

export async function getRestaurantItemMetaDetails(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/get?sort=name:asc&components=" +
        params.credentials.items
    );
  } catch (e) {
    throw e;
  }
}

export async function restaurantInfo(url: string, params: any) {
  try {
    return await apiCall.get(url + "/" + params.restaurantuuid + "/info");
  } catch (e) {
    throw e;
  }
}

export default {
  restaurantDetail,
};
