import { unitModalPayload } from "./units.model";

export interface TaskModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}

export interface TaskState {
  activeStorageDetails: any;
  barcode: string;
  task: any;
  isFetching: boolean;
  editmodal: boolean;
  addmodal: boolean;
  addstepmodal?: boolean;
  contentList: any;
  stepmodal?: boolean;
  uuid: string;
  isUpdated: boolean;
  imagemodal: boolean;
  filedata: any;
  unitdata: any;
  name: string;
  description: string;
  stationuuid: string;
  machineuuid: string;
  outputunituuid: string;
  workduration: string;
  buffertime: string;
  outputquantity: string;
  chefinvolved: any;
  deletetask: boolean;
  deletetaskstep?: boolean;
  stepdescription?: string;
  stepposition?: any;
  stationdata: any;
  machinedata: any;
  currentstepuuid?: string;
  recipedata: any;
  recipeuuid: string;
  tasklist: any;
  parenttask: any;
  ingredientmodal: boolean;
  quantitylist: any;
  recipelist: any;
  recipecheck: any;
  recipeingredient: any;
  delrecipeingredient: boolean;
  recipeingredientquantity: any;
  recipeingredientmodel: boolean;
  collapse: any;
  addtaskingredientmodal: boolean;
  taskingredientlist: any;
  taskingredientmodel: boolean;
  taskuuid: string;
  deltask: boolean;
  recipemodal: boolean;
  imagerecipemodal: boolean;
  inheritTags: boolean;
  allingredient: any;
  interventionIngredients: boolean;
  timeFactor: boolean;
  toggleStorage: boolean;
  storage: any;
  isSequential: any;
  ingredientname: string;
  ingredientdescription: string;
  addRecipe: boolean;
  recipename: string;
  recipedescription: string;
  recipeDetailsArray: any;
  printername: string;
  color: any;
  labels: any;
  selectedOption: any;
  selectedOptionAdd: any;
  label_uuid: any;
  displayColorPicker: boolean;
  searchByName: string;
  searchList: any;
  rooms: any;
  activeTab: any;
  fiterFlag: boolean;
  deletestoragemodal: boolean;
  storageuuid: any;
  roomuuid: string;
  rackuuid: string;
  placeuuid: string;
  recipedetaildata: any;
  supplierList: any;
  supplierId: any;
  ingredientId: string;
  isManged: any;
  isRecipeEditable: boolean;
  isEditModalShown: boolean;
  imageUuid: string;
  deleteTaskItem: any;
  printLabel: boolean;
  interventionPrintLabel: boolean;
  confirmationCompletion: boolean;
  conversionId: string;
  conversionOptions: any;
  defaultValue: any;
  deleteConversion: boolean;
  tagId: string;
  deleteUnit: boolean;
  errorMsg: string;
  isRecipeUpdated: boolean;
  isAddItemModalOpen: boolean;
  isStorageUpdated: boolean;
  isSubstituteListUpdated: boolean;
  moreValues: any;
  otherUnit: any;
  override: boolean;
  parameter: string;
  package_quantity: string;
  package_unit_uuid: string;
  posName: string;
  printerName: string;
  recipeSubstituteUuid: string;
  recipeSubstitute: any;
  selectedLabels: any;
  recipeQuantity: string;
  showAddTag: boolean;
  showDeleteTag: boolean;
  showEditModal: boolean;
  showModal: boolean;
  showSubstituteModal: boolean;
  symbol: string;
  tagName: any;
  tagValue: any;
  toggleDelete: boolean;
  unitId: string;
  unitName: string;
  units: any;
  recipeStorages: any;
  richText: boolean;
  storageFormData: any;
  toggleStorageModal: boolean;
  recipeServiceSets: any;
  showServiceSetModal: boolean;
  selectedServiceSetData: any;
  grossWeight: string;
  grossWeightUnitSelection: any;
}

export const TaskInitState = {
  activeStorageDetails: {},
  barcode: "",
  task: [],
  isFetching: false,
  editmodal: false,
  addmodal: false,
  contentList: [],
  uuid: "",
  isUpdated: false,
  imagemodal: false,
  filedata: [],
  unitdata: [],
  name: "",
  description: "",
  stationuuid: "",
  machineuuid: "",
  outputunituuid: "",
  workduration: "",
  buffertime: "",
  outputquantity: "",
  chefinvolved: false,
  deletetask: false,
  stationdata: [],
  machinedata: [],
  recipedata: [],
  recipeuuid: "",
  tasklist: [],
  parenttask: [],
  ingredientmodal: false,
  quantitylist: [],
  recipelist: [],
  recipecheck: [],
  recipeingredient: [],
  delrecipeingredient: false,
  recipeingredientquantity: "",
  recipeingredientmodel: false,
  collapse: {},
  addtaskingredientmodal: false,
  taskingredientlist: [],
  taskingredientmodel: false,
  taskuuid: "",
  deltask: false,
  recipename: "",
  recipedescription: "",
  recipeDetailsArray: [],
  posName: "",
  printername: "",
  recipemodal: false,
  imagerecipemodal: false,
  inheritTags: false,
  allingredient: [],
  interventionIngredients: false,
  timeFactor: false,
  isSequential: false,
  toggleStorage: false,
  storage: [],
  ingredientId: "",
  ingredientname: "",
  ingredientdescription: "",
  addRecipe: false,
  color: "#CCCCCC",
  labels: [],
  selectedOption: [],
  selectedOptionAdd: [],
  label_uuid: [],
  displayColorPicker: false,
  searchByName: "",
  searchList: [],
  rooms: [],
  activeTab: "1",
  fiterFlag: false,
  deletestoragemodal: false,
  storageuuid: "",
  roomuuid: "",
  rackuuid: "",
  placeuuid: "",
  recipedetaildata: [],
  supplierList: [],
  supplierId: "",
  isManged: "1",
  imageUuid: "",
  isRecipeEditable: false,
  isEditModalShown: false,
  deleteTaskItem: null,
  printLabel: false,
  interventionPrintLabel: false,
  confirmationCompletion: false,
  conversionId: "",
  conversionOptions: [],
  deleteConversion: false,
  tagId: "",
  deleteUnit: false,
  defaultValue: false,
  errorMsg: "",
  isRecipeUpdated: false,
  isAddItemModalOpen: false,
  isStorageUpdated: false,
  isSubstituteListUpdated: false,
  moreValues: [{ index: "", value: "" }],
  otherUnit: {},
  override: false,
  package_quantity: "",
  package_unit_uuid: "",
  parameter: "",
  printerName: "",
  recipeSubstituteUuid: "",
  recipeSubstitute: {},
  recipeQuantity: "",
  selectedLabels: [],
  showAddTag: false,
  showDeleteTag: false,
  showEditModal: false,
  showModal: false,
  showSubstituteModal: false,
  symbol: "",
  tagName: {},
  tagValue: "",
  unitId: "",
  toggleDelete: false,
  unitName: "",
  units: [],
  recipeStorages: [],
  richText: true,
  storageFormData: {},
  toggleStorageModal: false,
  recipeServiceSets: {},
  showServiceSetModal: false,
  selectedServiceSetData: {},
  grossWeight: "",
  grossWeightUnitSelection: null,
};

export interface TaskListState {
  isUpdated: boolean;
  currentTaskIngredient: any;
  searchList: any;
  searchByName: string;
  mouseOver: boolean;
  renderData: any;
  printLabel: boolean;
}

export const TaskListInitState = {
  isUpdated: false,
  currentTaskIngredient: [],
  searchList: [],
  searchByName: "",
  mouseOver: false,
  renderData: {},
  printLabel: false,
};

export interface TaskInterventionState {
  collapsed: boolean;
  mouseOver: boolean;
  renderData: any;
}

export const TaskInterventionInitState = {
  collapsed: true,
  mouseOver: false,
  renderData: {},
};

export interface RecipeDetailsState {
  activeStorageDetails: any;
  contentList: Array<any>;
  formData: {
    area: string;
    barcode: string;
    color: string;
    confirmationCompletion: any;
    description: string;
    formType: string;
    handleAddItemClick: string;
    inheritTags: boolean;
    labelOptions: Array<any>;
    name: string;
    outputQuantity: string;
    outputUnitUuid: string;
    packageQuantity: string;
    packageUnitUuid: string;
    posName: string;
    printerName: string;
    printLabel: boolean;
    selectedLabels: Array<any>;
    shelfLife: any;
    sku: string | undefined;
    type: number | null;
    replacementItem: string;
    outputQuantityUpdateFormula: "same" | "changed"
  };
  isAddItemModalOpen: boolean; // Need to remove
  isEditItemModalOpen: boolean;
  isFetching: boolean;
  isInterventionUpdated: string;
  isOutputQtyUpdate: boolean;
  isStorageModalOpen: boolean;
  isStorageUpdated: boolean;
  isUnitModalOpen: boolean;
  isUpdated: boolean;
  labels: Array<any>;
  machineData: Array<any>;
  newUnit: any;
  outputQuantity: string;
  recipeDetails: {
    copied_from: string;
    status: number | null;
    version: number | null;
  };
  recipeStorages: Array<any>;
  rooms: Array<any>;
  stationData: any;
  storageFormData: any;
  storages: Array<any>;
  supplierId: "";
  supplierList: Array<any>;
  unitModalPayload: unitModalPayload;
}

export const RecipeDetailsInitState: RecipeDetailsState = {
  activeStorageDetails: {},
  contentList: [],
  formData: {
    area: "recipe",
    barcode: "",
    color: "#FFFFFF",
    confirmationCompletion: "",
    description: "",
    formType: "edit",
    handleAddItemClick: "",
    inheritTags: false,
    labelOptions: [],
    name: "",
    outputQuantity: "",
    outputUnitUuid: "",
    packageQuantity: "",
    packageUnitUuid: "",
    posName: "",
    printerName: "",
    printLabel: false,
    selectedLabels: [],
    shelfLife: "",
    sku: undefined,
    type: null,
    replacementItem: "",
    outputQuantityUpdateFormula: "same"
  },
  isAddItemModalOpen: false, // Need to remove
  isEditItemModalOpen: false,
  isFetching: false,
  isInterventionUpdated: "",
  isOutputQtyUpdate: false,
  isStorageModalOpen: false,
  isStorageUpdated: false,
  isUnitModalOpen: false,
  isUpdated: false,
  labels: [],
  machineData: [],
  newUnit: null,
  outputQuantity: "",
  recipeDetails: {
    copied_from: "",
    status: null,
    version: null,
  },
  recipeStorages: [],
  rooms: [],
  stationData: {},
  storageFormData: {},
  storages: [],
  supplierId: "",
  supplierList: [],
  unitModalPayload: {
    grossWeight: "",
    grossWeightUnitSelection: null,
    hasGrossWeight: true,
    name: "",
    symbol: "",
  },
};

export interface RecipeDetailsProps {
  storeProps: {
    recipeDetails: {
      copied_from: string;
      status: number | null;
      version: number | null;
    };
    recipeServiceSets: Array<any>;
    units: Array<any>;
  };
  dispatchActions: {};
}

export interface RecipeTasksAndIngredientsWrapperProps {
  addNewIngredientItem: Function;
  allRecipes: Array<any>;
  baseKitchenUnit: any;
  // cardItemSelectionData: any;
  getRelatedUnits: Function;
  getSupplierDetails: any;
  handleIngredientsSave: Function;
  handleIngredientsSorting: Function;
  handlePrepSetChange: Function;
  handleTaskInterventionDelete: Function;
  handleTaskInterventionSave: Function;
  handleTaskItemRemove: Function;
  handleTaskItemSave: Function;
  handleTaskItemUpdate: Function;
  handleTaskStepSave: Function;
  isInterventionUpdated: string;
  ingredients: Array<any>;
  labels: Array<any>;
  machines: Array<any>;
  onItemModify: Function;
  profiles: Array<any>;
  recipeDetails: any;
  restaurantId: string;
  restaurantLang: Array<any>;
  showErrorToast: Function;
  showLoader: Function;
  stations: Array<any>;
  tasks: Array<any>;
  units: Array<any>;
  assembleTaskLocales?: { title: string; description: string };
}

export interface RecipeIngredientsProps {
  addNewIngredientItem: Function;
  cancelIngredientEdit: Function;
  cardItemSelectionData: any;
  currentRecipeDetails: any;
  deleteIngredient: Function;
  getInputReadOnlyLabels: Function;
  getQuantityRelatedDetails: Function;
  getRecipeDetails: Function;
  getRecipeDetailsAsync: Function;
  getRecipeIcon: any;
  getRelatedUnitOptions: Function;
  getSupplierDetails: Function;
  handleIngredientEdit: Function;
  handleIngredientsSave: Function;
  handleIngredientsSorting: Function;
  handleOutputUnitSelection: Function;
  handleTaskIngredientSelection: Function;
  ingredientDraft: any;
  ingredients: Array<any>;
  ingredientsQuantityData: any;
  labels: Array<any>;
  onEditIngredients: Function;
  otherRecipesLists: Array<any>;
  outputQuantity: any;
  parentCardItemSelectionData: any;
  prepAndRecipeQuantities: any;
  selectedOutputUnit: any;
  selectedRecipeChoices: Array<any>;
  selectedTaskDetails: any;
  setSelectedCardItem: Function;
}

export interface RecipeIngredientsCardProps {
  cardItemData: any;
  cardItemId: string;
  cardItemSelectionData: any;
  choiceItems: any;
  deleteIngredient: Function;
  getChoiceItemDetails: Function;
  getQuantityRelatedDetails: Function;
  getRecipeIcon: any;
  getRelatedUnitOptions: Function;
  handleIngredientEdit: any;
  handleOutputUnitSelection: Function;
  handleTaskIngredientSelection: Function;
  isNewCardItem: boolean;
  otherRecipesLists: Array<any>;
  openRecipeSelectionModal: Function;
  prepAndRecipeQuantities: any;
  quantityAssignData: any;
  saveCard: Function;
  selectedRecipeDetails?: any;
  selectedTaskDetails: any;
}
export interface RecipeIngredientsModalProps {
  addNewIngredientItem?: Function;
  allIngredients: Array<any>;
  isOpen: boolean;
  labelOptions: Array<any>;
  savedIngredients?: Array<any>;
  saveRecipeSelection: Function;
  toggleModal: any;
  title?: string;
  multiSelect?: boolean;
  hideArticles?: boolean;
}

export interface RecipeTasksProps {
  cardItemSelectionData: any;
  onDeleteTaskItem: Function;
  getInputReadOnlyLabels: Function;
  getUnitDetails: Function;
  getUuidFromMediaManager: Function;
  handleTaskInterventionDelete: Function;
  handleTaskDependencySelection: Function;
  handleTaskInterventionSave: Function;
  handleTaskSelection: Function;
  handleTaskStepSave: Function;
  itemDraft: any;
  machinesOptions: Array<any>;
  onCancelEdit: Function; //REMOVE
  onItemModify: Function;
  onSaveTaskItem: Function; //REMOVE
  onTaskEdit: Function; //REMOVE
  recipeStatus: number;
  restLang: any;
  selectedTaskDetails: any;
  stationsOptions: any;
  taskDraft: any;
  taskWithIngDraft: any;
  tasks: Array<any>;
  unitOptions: Array<any>;
  assembleTaskLocales: { title: string; description: string };
  showNewtaskWithIng: boolean;
}

export interface RecipeTasksCardProps {
  cardItemData: any;
  cardItemId: string;
  cardItemSelectionData: any;
  getInputReadOnlyLabels: Function;
  getSortedInterventions: any;
  getUnitDetails: Function;
  getUuidFromMediaManager: Function;
  handleTaskDependencySelection: Function;
  handleTaskInterventionDelete: Function;
  handleTaskInterventionSave: Function;
  handleTaskStepSave: Function;
  machinesOptions: Array<any>;
  isNewCardItem: boolean;
  isRecipeEditable: boolean;
  itemDraft: any;
  onCancelEdit: Function;
  onDeleteTaskItem: Function;
  onItemModify: Function;
  onSaveTaskItem: Function;
  restLang: any;
  selectedTaskDetails: any;
  selectThisTask: Function;
  stationsOptions: any;
  unitOptions: Array<any>;
  preselect?: boolean;
  assembleTaskLocales?: { title: string; description: string };
}

export interface RecipeTaskInterventionCardProps {
  cardItemData: any;
  cardItemId: string;
  cardItemSelectionData: any;
  handleCardSelection: Function;
  handleTaskInterventionDelete: Function;
  getUuidFromMediaManager: Function;
  handleTaskInterventionSave: Function;
  isNewCardItem: boolean;
  lastIntervention?: any;
  onCancelEdit: Function;
  onContentEditChange: Function;
  onItemModify: Function;
  parentCardItemSelectionData: any;
  providedDraggable: any;
  restLang: any;
}
export interface RecipeTaskStepCardProps {
  cardItemData: any;
  cardItemId: string;
  cardItemSelectionData: any;
  getUuidFromMediaManager: Function;
  handleCardSelection: Function;
  handleTaskStepSave: Function;
  isNewCardItem: boolean;
  onCancelEdit: Function;
  onItemModify: Function;
  parentCardItemSelectionData: any;
  restLang: any;
}

export interface prepSetProps {
  payload: {
    calculationQty: string;
    newPrepSet: string;
    preparationSetsOptions: Array<any>;
    prepSetCalcUnitsOptions: Array<any>;
    prepSetEditEnabled: boolean;
    selectedCalcUnit: any;
    selectedPrepSet: any;
    maintainIngQty: boolean;
    prepSetCurrentAction: string;
  };
  prepSetActions: {
    activatePrepSet: any;
    clonePrepSet: Function;
    deletePrepSet: Function;
    handleChange: any;
    handlePrepSetCalcUnit: any;
    handlePrepSetSelection: any;
    savePrepSet: any;
    togglePrepEdit: any;
  };
}

export enum taskEditType {
  INTERVENTION = "intervention",
  INTERVENTION_STEP = "interventionStep",
  STEP = "step",
  TASK = "task",
}
