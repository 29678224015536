import { call, put, takeLatest } from 'redux-saga/effects';

import { LOGIN_REQUEST } from '../config/api.config';
import {
  ACCESS_TOKEN,
  PROFILE,
  PROFILEUUID,
  REFRESH_TOKEN,
  RESTAURANTUUIDS,
} from "../constant/constant";
import { loginFailure, loginSuccess } from "../redux/actions/login.action";
import * as types from "../redux/actions/types";
import apiCall from "../services/api.call.config";
import login from "../services/login.service";
import handleResponse from "../services/response.service";
import commonService from "../services/common.service";
import i18next from "i18next";

const loginCall = (credentials: any) => login.login(LOGIN_REQUEST, credentials);

function* handleLoginRequest(credentials: object) {
  try {
    let result;
    result = yield call(loginCall, credentials);
    yield handleLogin200(result);
  } catch (error) {
    throw error;
  }
}
const handleLogin200 = function* handleLogin200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    apiCall.api.setHeaders({
      Authorization: "Bearer " + result.data.data.access_token,
    });
    if (!!result.data.data.details.enabled) {
      localStorage.setItem(ACCESS_TOKEN, result.data.data.access_token);
      localStorage.setItem(REFRESH_TOKEN, result.data.data.refresh_token);
      localStorage.setItem(PROFILEUUID, result.data.data.details.uuid);
      localStorage.setItem(PROFILE, JSON.stringify(result.data.data.details));
      localStorage.setItem(
        RESTAURANTUUIDS,
        JSON.stringify(result.data.data.details?.restaurants)
      );
    } else {
      commonService.toastService(
        i18next.t("toast.mailConfirmationNotCompleted"),
        "warning"
      );
    }
    yield put(loginSuccess(result.data));
  } else {
    yield put(loginFailure(result.data));
    handleResponse(result);
  }
};

export default function* loginSaga() {
    yield takeLatest(types.LOGIN.REQUEST, handleLoginRequest);
}
