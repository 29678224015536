import apiCall from './api.call.config';

async function storageRack(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.get(url + '/' + params.credentials.restaurantuuid + '/storage/' + params.credentials.storageuuid
            + '/rack?sort=name:asc');
    } catch (e) {
        throw e;
    }
}

async function addStorageRack(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url + '/' + params.credentials.restaurantuuid + '/storage/' + params.credentials.storageuuid
            + '/rack', params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function updateStorageRack(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.put(url + '/' + params.credentials.restaurantuuid + '/storage/' + params.credentials.storageuuid
            + '/rack/' + params.credentials.uuid, params.credentials.data)
    } catch (e) {
        throw e;
    }
}

async function removeStorageRack(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.remove(url + '/' + params.credentials.restaurantuuid + '/storage/' + params.credentials.storageuuid
            + '/rack/' + params.credentials.uuid)
    } catch (e) {
        throw e;
    }
}

export default {
    storageRack,
    addStorageRack,
    updateStorageRack,
    removeStorageRack
}