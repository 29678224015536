import apiCall from '../services/api.call.config';

async function login(url: string, credentials: any) {
  try {
    return await apiCall.post(url, credentials.credentials);
  } catch (e) {
    throw e;
  }
}

export default { login }
