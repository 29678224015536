import { faEdit, faStop, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { Badge, Col, CustomInput, Label, Row } from "reactstrap";
import { DARK_GRAY, GRAY, GREEN } from "../../../constant/constant";

export default function StationCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <div className="child-box mb-3">
      <span style={{ marginTop: 20 }}>
        <div style={{ float: "right", display: "flex" }}>
          <span
            onClick={props.setStationEditable}
            className="pointer-cursor"
            data-toggle="tooltip"
            data-placement="left"
            title={t("common.edit")}
          >
            <FontAwesomeIcon
              style={{ color: GREEN, fontSize: 20 }}
              icon={faEdit}
            />
          </span>
          <span
            onClick={props.setStationDelete}
            className="pointer-cursor"
            data-toggle="tooltip"
            data-placement="left"
            title={t("common.delete")}
          >
            <FontAwesomeIcon
              style={{ color: GRAY, fontSize: 20, marginLeft: 10 }}
              icon={faTrashAlt}
            />
          </span>
        </div>
      </span>
      <span style={{ display: "flex", marginTop: 5 }}>
        <Col className="d-flex">
          <FontAwesomeIcon
            className="color-box-one"
            style={{ color: `${props.color}`, fontSize: 22 }}
            icon={faStop}
          />
          <h6 style={{ color: DARK_GRAY, paddingLeft: 10 }}>
            {props.locales.name}
          </h6>
        </Col>
        <Col>
          <Row>
            <Col>
              <CustomInput
                id={"task" + props.stationData.uuid}
                type="radio"
                name={props.stationData.uuid}
                value="1"
                defaultChecked={
                  props.stationData.operation_mode === 1 ? true : false
                }
                onChange={props.handleModeChange}
                label={t("common.taskMode")}
                className="b-cursor-p "
              />
            </Col>
            <Col>
              <CustomInput
                id={"delivery" + props.stationData.uuid}
                type="radio"
                name={props.stationData.uuid}
                value="2"
                defaultChecked={
                  props.stationData.operation_mode === 2 ? true : false
                }
                onChange={props.handleModeChange}
                label={t("common.deliveryMode")}
                className="b-cursor-p "
              />
            </Col>
          </Row>
        </Col>
      </span>

      <Row className="app-row-padding">
        <Col xs="8" sm="4">
          <Label>{t("room.room")} : </Label> {props.roomName}
        </Col>
        <Col xs="8" sm="4">
          <Label>{t("common.printOrder")} : </Label>{" "}
          {props.printerName != "" ? props.printerName : t("common.noPrinting")}
        </Col>
        <Col xs="8" sm="4">
          <Label>{t("station.pickingStation")} : </Label>{" "}
          <Badge className="text-dark" color="light">
            {props?.pickingLocation?.label ? props.pickingLocation.label : ""}
          </Badge>
        </Col>
      </Row>
    </div>
  );
}
