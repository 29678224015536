import "../../../styles/navigation-header.scss";

import React, { useEffect } from "react";
import { Nav, NavItem } from "reactstrap";
import { ISSUPERADMIN, USER_RIGHTS } from "../../../constant/constant";

import { Link, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import commonService from "../../../services/common.service";

const RestaurantHeaderCard = withRouter((props: any) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    localStorage.setItem("RESTAURANT_ID", props.match.params.uuid);
  }, []);
  let isSuperAdmin = localStorage.getItem(ISSUPERADMIN);
  const userRightSupplierOfferingOnly = commonService.hasOnlyPermission(
    USER_RIGHTS.SUPPLIER_OFFERING_ONLY,
    props.match.params.uuid
  );
  const hasMinimalRight = commonService.hasAnyPermission(
    [
      USER_RIGHTS.VIEW_RECIPES,
      USER_RIGHTS.VIEW_INGREDIENTS,
      USER_RIGHTS.VIEW_LABELS,
      USER_RIGHTS.SUPPLIER_OFFERING_ONLY,
    ],
    props.match.params.uuid
  );
  return (
    <>
      <Nav navbar>
        <NavItem>
          <Link
            to={`/restaurant/${props.uuid}/home`}
            className={`${props.concept === "home" ? "active" : " "} nav-link`}
          >
            {t("common.home")}
          </Link>
        </NavItem>
        {(props.hasAccess || hasMinimalRight) && (
          <NavItem>
            <Link
              to={`/restaurant/${props.uuid}/${
                userRightSupplierOfferingOnly
                  ? "supplierOffering"
                  : "ingredients"
              }`}
              className={`${
                props.concept === "concept" ? "active" : " "
              } nav-link`}
            >
              {t("common.concept")}
            </Link>
          </NavItem>
        )}
        {props.hasAccess && (
          <>
            <NavItem>
              <Link
                to={`/restaurant/${props.uuid}/users`}
                className={`${
                  !!props.staff && props.staff !== "pos" ? "active" : " "
                } nav-link`}
              >
                {t("common.resources")}
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to={`/restaurant/${props.uuid}/pos`}
                className={`${props.staff === "pos" ? "active" : " "} nav-link`}
              >
                {t("common.plan")}
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to={`/restaurant/${props.uuid}/reports`}
                className={`${
                  props.operate === "operate" ? "active" : " "
                } nav-link`}
              >
                {t("common.operate")}
              </Link>
            </NavItem>
          </>
        )}
        {isSuperAdmin === "true" && (
          <NavItem>
            <Link
              to={`/restaurant/${props.uuid}/support`}
              className={`${
                props.support === "support" ? "active" : " "
              } nav-link`}
            >
              {t("common.support")}
            </Link>
          </NavItem>
        )}
      </Nav>
    </>
  );
});
export default RestaurantHeaderCard;
