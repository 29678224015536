import {
  PrinterTemplateModel,
  PrinterTemplateModelInitState,
} from "../../models/printer.template.model";
import * as types from "../actions/types";

export function printerTemplateReducer(
  state = PrinterTemplateModelInitState,
  action: any
): PrinterTemplateModel {
  switch (action.type) {
    case types.PRINTER_TEMPLATE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.PRINTER_TEMPLATE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.PRINTER_TEMPLATE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function printerTemplateAddReducer(
  state = PrinterTemplateModelInitState,
  action: any
): PrinterTemplateModel {
  switch (action.type) {
    case types.PRINTER_TEMPLATE_ADD.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.PRINTER_TEMPLATE_ADD.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.PRINTER_TEMPLATE_ADD.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
export function printerTemplateDetailReducer(
  state = PrinterTemplateModelInitState,
  action: any
): PrinterTemplateModel {
  switch (action.type) {
    case types.PRINTER_TEMPLATE_DETAILS.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.PRINTER_TEMPLATE_DETAILS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.PRINTER_TEMPLATE_DETAILS.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
export function printerTemplateRemoveReducer(
  state = PrinterTemplateModelInitState,
  action: any
): PrinterTemplateModel {
  switch (action.type) {
    case types.PRINTER_TEMPLATE_REMOVE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.PRINTER_TEMPLATE_REMOVE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.PRINTER_TEMPLATE_REMOVE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function printerTemplateUpdateReducer(
  state = PrinterTemplateModelInitState,
  action: any
): PrinterTemplateModel {
  switch (action.type) {
    case types.PRINTER_TEMPLATE_UPDATE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.PRINTER_TEMPLATE_UPDATE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.PRINTER_TEMPLATE_UPDATE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
