import React, { Component } from "react";

import TableComponent from "../../components/table/table";

class TablePage extends Component {
  render() {
    return <TableComponent />;
  }
}
export default TablePage;
