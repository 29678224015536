export interface StoragePlaceModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface StoragePlaceState {
  uuid: string,
  storageplacename: string,
  editstorageplacename: string,
  addstorageplacemodal: boolean,
  editstorageplacemodal: boolean,
  deletestorageplacemodal: boolean,
  isUpdated: boolean,
  isFetching: boolean
}

export const StoragePlaceInitState = {
  uuid: '',
  storageplacename: '',
  editstorageplacename: '',
  addstorageplacemodal: false,
  editstorageplacemodal: false,
  deletestorageplacemodal: false,
  isUpdated: false,
  isFetching: false
};