import "./index.css";
import "./scss/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "./i18n";

import App from "./app/app";
import { store } from "./redux/store/store";
import * as serviceWorker from "./serviceWorker";

// ReactDOM.render(routing, document.getElementById('root'));
ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback="">
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
