import React, { Component } from "react";

import ProfileSettingComponent from "../../components/profile-settings/profile-settings";
import { setLanguage } from "../../config/lang.config";

class ProfileSettingPage extends Component {
  render() {
    setLanguage(localStorage.getItem("USERLANGUAGE"));
    return <ProfileSettingComponent />;
  }
}
export default ProfileSettingPage;
