import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Col, Row } from "reactstrap";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import JsonTableComponent from "../table/jsonTable";
import { getProducedBatchDetails } from "../../services/report.service";
import { RED } from "../../constant/constant";

interface ProducedBatchDetailsComponentProps {
  restaurantUuid: string;
  itemUuid: string;
  showTitle: boolean;
}

interface batchData {
  uuid: string;
  recipeName: string;
  quantity: string;
  preparedBy: string;
  outputQuantity: string;
  outputUnitName: string;
  completedAt: string;
  expirationDate: string;
  meta: object;
}

const ProducedBatchDetailsComponent: React.FC<
  ProducedBatchDetailsComponentProps
> = (props) => {
  const { restaurantUuid, itemUuid, showTitle } = props;
  const { t } = useTranslation();
  const [batchData, setBatchData] = useState<batchData>();

  useEffect(() => {
    getProducedBatchDetails(RESTAURANT_REQUEST, {
      credentials: {
        restaurantuuid: restaurantUuid,
        data: {
          uuid: itemUuid,
        },
      },
    }).then((response: any) => {
      setBatchData(response?.data?.data);
    });
  }, [restaurantUuid, itemUuid]);

  return (
    <>
      {showTitle && <h4>{t("reports.batchDetails")}</h4>}
      <div className="child-box mb-3">
        <Row className="app-row-padding">
          <Col xs="6">
            <div>
              <h6>{batchData?.recipeName}</h6>
              <div>
                {t("reports.completedAt")}:
                <Badge className="text-dark py-1 px-2" color="light" pill>
                  {batchData?.completedAt}
                </Badge>
              </div>
              <div>
                {t("reports.expirationDate")}:
                <Badge className="text-dark py-1 px-2" color="light" pill>
                  {batchData?.expirationDate &&
                  new Date(batchData.expirationDate) < new Date() ? (
                    <span style={{ color: RED }}>
                      {batchData.expirationDate}
                    </span>
                  ) : (
                    batchData?.expirationDate
                  )}
                </Badge>
              </div>
              <div>
                {t("reports.preparedBy")}:
                <Badge className="text-dark py-1 px-2" color="light" pill>
                  {batchData?.preparedBy}
                </Badge>
              </div>
            </div>
          </Col>
          <Col xs="6">
            <div>
              <h6 className="mb-4"> </h6>
              <div>
                {t("common.quantity")}:
                <Badge className="text-dark py-1 px-2" color="light" pill>
                  {batchData?.quantity}
                </Badge>
              </div>
              <div>
                {t("reports.outputQunatityUnit")}:
                <Badge className="text-dark py-1 px-2" color="light" pill>
                  {batchData?.outputQuantity} {batchData?.outputUnitName}
                </Badge>
              </div>
            </div>
          </Col>
        </Row>
        {batchData?.meta ? (
          <Row className="mt-2">
            <Col xs="12">
              <h6>{t("common.additionalInformation")}</h6>
              <div>
                <JsonTableComponent jsonData={batchData.meta} />
              </div>
            </Col>
          </Row>
        ) : null}
      </div>
    </>
  );
};

export default ProducedBatchDetailsComponent;
