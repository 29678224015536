import * as types from './types';
import { ActionModel } from '../../models/action.model';

export function registrationRequest(credentials: object): ActionModel {
  return {
    type: types.REGISTRATION.REQUEST,
    credentials
  };
}

export function registrationSuccess(data: object): ActionModel {
  return {
    type: types.REGISTRATION.SUCCESS,
    data
  };
}

export function registrationFailure(err: object): ActionModel {
  return {
    type: types.REGISTRATION.FAILURE,
    err
  };
}
