import "../../../styles/navigation-right.scss";

import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { create } from "apisauce";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { GiftedChat } from "react-web-gifted-chat";
import {
  PopoverBody,
  PopoverHeader,
  Spinner,
  UncontrolledPopover,
} from "reactstrap";

import image from "../../../assets/images/icon/image.png";
import { BASE_URL } from "../../../config/app.config";
import { ACCESS_TOKEN, PROFILEUUID } from "../../../constant/constant";
import handleResponse from "../../../services/response.service";
import i18next from "i18next";
import { withTranslation } from "react-i18next";

const api = create({
  baseURL: BASE_URL,
});

class NavigationRightComponent extends Component<any> {
  state: any;
  refs: any;
  constructor(props: any) {
    super(props);
    this.state = {
      messages: [],
      isFetching: false,
    };
  }

  private chatBot = {
    id: localStorage.getItem(PROFILEUUID),
    name: "SmartTONi",
    avatar: image,
  };
  private ids = 1;

  render() {
    const { t } = this.props;
    api.setHeaders({
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Cache-Control": "no-cache",
      Locale: "en-gb",
      "content-type": "application/json",
    });
    return (
      <div
        className="b-sticky-below-header"
        style={{
          display: !!localStorage.getItem("WEBVIEW") ? "none" : "block",
        }}
      >
        <div className="white-box mb-3 mt-0">
          <p>{t("navRight.shortTimeStaffing")}</p>
        </div>
      </div>
    );
  }

  public renderChat() {
    return (
      <GiftedChat
        placeholder={this.props.t("common.TypeMessage")}
        renderFooter={this.state.isFetching ? this.loadingResponse : undefined}
        messages={this.state.messages}
        onSend={(messages: any) => this.onSend(messages)}
        user={{
          id: 1,
        }}
      />
    );
  }

  public loadingResponse(): any {
    return (
      <div
        style={{
          alignContent: "center",
          margin: 10,
          fontSize: 12,
          color: "#90979e",
        }}
      >
        <span>{this.props.t("navRight.typing")} </span>
        <Spinner
          style={{ width: 10, height: 10, color: "#07767c" }}
          type="grow"
        />
        <Spinner
          style={{ width: 10, height: 10, color: "#07767c" }}
          type="grow"
        />
        <Spinner
          style={{ width: 10, height: 10, color: "#07767c" }}
          type="grow"
        />
      </div>
    );
  }

  onSend(messages: any[] = []) {
    this.ids++;
    this.setState((previousState: any) => ({
      messages: GiftedChat.append(previousState.messages, messages),
      isFetching: true,
    }));
    let query = messages.length && messages[0].text ? messages[0].text : "Hi";
    api
      .post("/api/1.0/chat/relay", {
        query,
        session: localStorage.getItem(PROFILEUUID),
      })
      .then((response: any) => {
        if (response.status === 200) {
          let message = {
            id: this.ids++,
            text: response.data.response,
            createdAt: new Date(),
            user: this.chatBot,
          };
          this.setState((previousState: any) => ({
            messages: GiftedChat.append(previousState.messages, [message]),
          }));
        } else {
          handleResponse(response);
        }
        this.setState({
          isFetching: false,
        });
      });
  }

  public showVertical = () => {
    this.refs.vertical.instance.show();
  };

  componentDidMount() {
    this.setState({
      messages: [
        {
          id: 1,
          text: this.props.t("questions.help"),
          createdAt: new Date(),
          user: this.chatBot,
        },
      ],
    });
  }
}

export default withTranslation()(withRouter(NavigationRightComponent));

const styles: any = {
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    height: "40vh",
    top: "95%",
  },
  chat: {
    display: "flex",
    flex: 3,
    flexDirection: "column",
  },
};
