import apiCall from "./api.call.config";

async function channelsDetails(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "channel/" + params.credentials.uuid + "/details"
    );
  } catch (e) {
    throw e;
  }
}

async function channelsList(url: string, params: any) {
  try {
    return await apiCall.get(url + "channel");
  } catch (e) {
    throw e;
  }
}

async function addRestaurantsTochannels(url: string, params: any) {
  try {
    return await apiCall.put(
      url + "channel/" + params.credentials.uuid + "/restaurants",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function restaurantChannelList(url: string) {
  try {
    return await apiCall.get(url + "restaurant-channels");
  } catch (e) {
    throw e;
  }
}

export default {
  channelsDetails,
  channelsList,
  addRestaurantsTochannels,
  restaurantChannelList,
};
