import "../../styles/login.scss";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";

import mobiscroll from "@mobiscroll/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Label,
  Row,
} from "reactstrap";

import smartToniLogin from "../../assets/images/logo/smartToniLogin.png";
import { USERNAME } from "../../constant/constant";
import { LoginInitState, LoginState } from "../../models/login.model";
import { loginRequest } from "../../redux/actions/login.action";
import validationService from "../../services/validation.service";
import LoaderComponent from "../loader/loader";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import { getCorporateSettings } from "../../services/restaurant.service";
import { PUBLIC_REQUEST } from "../../config/api.config";
import handleResponse from "../../services/response.service";

class LoginComponent extends Component<any> {
  state: LoginState;
  constructor(props: any) {
    super(props);
    this.state = LoginInitState;
    this._setFunctionBindings();
  }
  render() {
    const { t } = this.props;
    const isLoggedIn = this.state.validate;
    let validation;

    if (!!isLoggedIn) {
      validation = (
        <InputGroup>
          <Label className="validation">Please enter valid email address</Label>
        </InputGroup>
      );
    } else {
      validation = "";
    }
    return (
      <div
        className="login-style"
        style={
          this.state.corporateColor !== ""
            ? { background: this.state.corporateColor }
            : {}
        }
      >
        <LoaderComponent display={!!this.state.isFetching} />
        <Container>
          <Row>
            <Col>
              <img
                className="img-home-fluid"
                src={this.state.restaurantLogo}
                alt="Logo"
              />
            </Col>
          </Row>
          <Row>
            <Col className="login-text">
              <label>{t("common.welcomeMessage")}</label>
            </Col>
          </Row>
          <Row className="login-form-container">
            <Col>
              <Form onSubmit={this.handleSubmit}>
                <mobiscroll.Input
                  inputStyle="box"
                  type="email"
                  name="username"
                  placeholder={t("common.username")}
                  value={this.state.username}
                  onChange={this.handleUsernameInputChange}
                />
                {validation}
                <mobiscroll.Input
                  inputStyle="box"
                  type="password"
                  name="Password"
                  placeholder={t("password.password")}
                  passwordToggle={true}
                  icon="none"
                  iconAlign="right"
                  value={this.state.password}
                  onChange={this.handlePasswordInputChange}
                />
                <Col className="remember-me" onClick={this.toggleChange}>
                  <input
                    type="checkbox"
                    name="rememberme"
                    checked={this.state.rememberMe}
                    onChange={this.toggleChange}
                  />{" "}
                  <label>{t("common.rememberUsername")}</label>
                </Col>
                <Button
                  className="login-button"
                  block
                  disabled={
                    !validationService.validateEmail(this.state.username) ||
                    !this.state.password
                  }
                  style={
                    this.state.corporateColor !== ""
                      ? { backgroundColor: this.state.corporateColor }
                      : {}
                  }
                >
                  {t("common.login")}
                </Button>
              </Form>
              <Link
                to={
                  this.state.shopLogin
                    ? `/restaurant/${this.props.match.params.uuid}/forgotpassword`
                    : "/forgotpassword"
                }
              >
                <Button className="link-text" color="link">
                  {t("common.forgotPassword")}
                </Button>
              </Link>
              <Button
                onClick={this.navigateToRegister}
                className="link-text"
                color="link"
              >
                {t("common.newToSmartTONi")}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  componentDidMount() {
    this.languageAutoSwitcher();
    this.setState({
      username: localStorage.getItem(USERNAME)
        ? localStorage.getItem(USERNAME)
        : "",
      restaurantLogo: smartToniLogin,
    });
    this.initShopLogin();
  }

  private initShopLogin() {
    if (this.props.match.params?.uuid) {
      this.setState({
        shopLogin: true,
      });
      getCorporateSettings(PUBLIC_REQUEST, this.props?.match?.params.uuid).then(
        (response: any) => {
          const responseData: any = handleResponse(response);
          if (responseData.ok) {
            if (responseData.data?.corporate_color !== "") {
              this._setStates(
                "corporateColor",
                responseData.data?.corporate_color
              );
            }
            if (responseData.data?.restaurant_logo !== "") {
              this._setStates(
                "restaurantLogo",
                responseData.data?.restaurant_logo.thumbnail
              );
            }
          }
        }
      );
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    this._setStates("isFetching", newProps.isFetching);
    this._navigateToHome(newProps);
  }

  handleSubmit(event: any) {
    event.preventDefault();
    this.props.login({
      username: this.state.username,
      password: this.state.password,
    });
  }

  public languageAutoSwitcher() {
    let browserLanguage = navigator.language;
    const [languageCode, languageRegion] = browserLanguage.split("-");
    let availLanguages = ["en", "de"];
    if (availLanguages.includes(languageCode)) {
      i18next.changeLanguage(languageCode);
    } else {
      i18next.changeLanguage("de");
    }
  }

  public navigateToRegister(): void {
    this.props.history.push("/registration", { type: "registration" });
  }

  private _setFunctionBindings(): void {
    this.handleUsernameInputChange = this.handleUsernameInputChange.bind(this);
    this.handlePasswordInputChange = this.handlePasswordInputChange.bind(this);
    this.navigateToRegister = this.navigateToRegister.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  public toggleChange = () => {
    this._setStates("rememberMe", !this.state.rememberMe);
  };

  private _navigateToHome(newProps: any): void {
    if (!newProps.failure) {
      this._setRememberMe();
    }
  }

  private _setRememberMe(): void {
    if (!!this.state.rememberMe) {
      localStorage.setItem(USERNAME, this.state.username);
    }
  }

  public handleUsernameInputChange(event: any): void {
    if (!!validationService.validateEmail(event.target.value)) {
      this.setState({
        username: event.target.value,
        validate: false,
      });
    } else {
      this.setState({
        username: event.target.value,
        validate: true,
      });
    }
  }

  public handlePasswordInputChange(event: any): void {
    this._setStates("password", event.target.value);
  }
}

const mapStateToProps: any = (state: any) => {
  return {
    data: state.login.data,
    isFetching: state.login.isFetching,
    failure: state.login.failure,
    isAuthenticated: state.login.isAuthenticated,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    login: (credentials: any) => {
      dispatch(loginRequest(credentials));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(LoginComponent)));
