import { ApiResponse } from "apisauce";
import { useCallback, useEffect, useState } from "react";

interface CustomApiResponse<ResponseData, RequestType> {
  data: ApiResponse<ResponseData> | null;
  error: string;
  loading: boolean;
  request: (...args: any[]) => Promise<void>;
}

const useApi = <ResponseData = unknown, Payload = unknown>(
  apiFunc: (...args: any[]) => Promise<ApiResponse<ResponseData>>,
  lazy = false
): CustomApiResponse<ResponseData, Payload> => {
  const [data, setData] = useState<ApiResponse<ResponseData> | null>(null);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const request = useCallback(
    async (...args: any[]) => {
      setLoading(true);
      try {
        const result = await apiFunc(...args);
        setData(result);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message || "Unexpected Error!");
        } else if (err instanceof Response) {
          setError("API request failed: " + err.statusText);
        } else {
          setError("An unexpected error occurred.");
        }
      } finally {
        setLoading(false);
      }
    },
    [apiFunc]
  );

  useEffect(() => {
    if (!lazy) {
      request();
    }
  }, [request, lazy]);

  return {
    data,
    error,
    loading,
    request,
  };
};

export default useApi;
