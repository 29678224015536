import '../../styles/user-verification.scss';

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import smartToniLogin from '../../assets/images/logo/smartToniLogin.png';
import { ACCESS_TOKEN, PROFILE, PROFILEUUID } from '../../constant/constant';
import LoaderComponent from '../loader/loader';

class WebViewComponent extends Component<any> {
    render() {
        return (
            <div>
                <LoaderComponent display={!localStorage.getItem(ACCESS_TOKEN)} />
                <Container>
                    <Row>
                        <Col><img className="img-home-fluid" src={smartToniLogin} alt="Logo" /></Col>
                    </Row>
                </Container>
            </div>
        );
    }

    componentDidMount() {
        if (
            !!localStorage.getItem(ACCESS_TOKEN)
            && !!localStorage.getItem(PROFILE)
            && !!localStorage.getItem(PROFILEUUID) &&
            this.props.match.params.navigate === 'profile') {
            this.props.history.push('/profile');
        } else if (
            !!localStorage.getItem(ACCESS_TOKEN)
            && !!localStorage.getItem(PROFILE)
            && !!localStorage.getItem(PROFILEUUID) &&
            this.props.match.params.navigate === 'units' && this.props.match.params.uuid !== '') {
            // eslint-disable-next-line
            this.props.history.push(`/restaurant/${this.props.match.params.uuid}/units`);
        }
    }
}

export default (withRouter(WebViewComponent));
