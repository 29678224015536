import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { QRCodeRoomState } from "../../models/qrcode.model";
import QRCodeRow from "./qrcode-row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";
import commonService from "../../services/common.service";
import _ from "lodash";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
} from "reactstrap";

const grid = 8;

const getItemStyle = (isDragging: any, draggableStyle: any) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 0,
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "none",

    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver
    ? "rgba(66, 209, 182, 0.3)"
    : "rgba(7, 118, 124, 0.15)",
  padding: grid,
  minHeight: "60px",
});

class QRCodePlaceBlock extends Component<any> {
  state: QRCodeRoomState;
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: props.data?.isOpen || true,
    };
  }
  getId(uuid: string) {
    let numb: any = uuid.match(/\d/g);
    numb = numb.join("");
    return Number(numb);
  }
  getSortedArray = (data: any) => {
    const sortedItem: Array<any> = [];
    data?.forEach((item: any) => {
      if (!item["name"]) {
        item["nameSorted"] = commonService.applyLocalization(
          "restaurant",
          "name",
          item.locales
        )["name"];
      } else {
        item["nameSorted"] = item["name"];
      }
      item["nameSorted"] = item["nameSorted"].toLowerCase();
      sortedItem.push(item);
    });
    return _.sortBy(sortedItem, "nameSorted");
  };

  handleCollapseToggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  getName(data: any) {
    let nameLocale = commonService.applyLocalization(
      "restaurant",
      "name",
      data.locales
    );
    return nameLocale.name;
  }

  render() {
    const { t } = this.props;
    return (
      <Card bg="light" key={this.props.data.uuid} className="mb-4">
        <CardHeader
          onClick={() => this.handleCollapseToggle()}
          className="b-cursor-p"
        >
          {this.props.data.area === "station" || this.props.data.area === "room"
            ? this.getName(this.props.data)
            : this.props.data.name}
        </CardHeader>
        <Collapse isOpen={this.state.isOpen}>
          <CardBody className="p-0">
            <div className="p-4">
              <div className="mb-4">
                <Droppable droppableId={"droppable_" + this.props.data.uuid}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      data-id={"droppable_" + this.props.data.uuid}
                      data-area={this.props.data.area}
                    >
                      {this.props.data?.codes.map((item: any, index: any) => (
                        <Draggable
                          key={item.uuid}
                          draggableId={"draggable_" + item.uuid}
                          index={this.getId(item.uuid)}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              className="b-draggable-row mb-3"
                            >
                              <QRCodeRow
                                data={item}
                                dummy={{
                                  one: snapshot.isDragging,
                                  two: provided.draggableProps.style,
                                }}
                                onRemoveCode={this.props.onRemoveCode}
                                onSelection={this.props.onSelection}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </div>

              {this.props.qrCodeBaseUrl.length > 0 && (
                <Button
                  block
                  color="light"
                  className="text-center shadow "
                  title={t("qrCode.addQrCode")}
                  onClick={() => {
                    this.props.onNewCode(
                      this.props.data.uuid,
                      this.props.data.area
                    );
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      fontSize: 20,
                      float: "none",
                    }}
                    icon={faPlus}
                  />
                </Button>
              )}
              {this.props.qrCodeBaseUrl.length === 0 && (
                <Alert className="mb-0" variant="warning">
                  {t("qrCode.baseUrlMissingInfo")}
                </Alert>
              )}
            </div>
            {this.props.data.child &&
              this.getSortedArray(this.props.data.child).map(
                (data: any, key: any) => (
                  <div className="p-4" key={key}>
                    <QRCodePlaceBlock
                      key={key}
                      data={data}
                      qrCodeBaseUrl={this.props.qrCodeBaseUrl}
                      onNewCode={this.props.onNewCode}
                      onRemoveCode={this.props.onRemoveCode}
                      onSelection={this.props.onSelection}
                      t={this.props.t}
                    />
                  </div>
                )
              )}
          </CardBody>
        </Collapse>
      </Card>
    );
  }
}
export default withTranslation()(withRouter(QRCodePlaceBlock));
