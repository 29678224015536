import React from "react";
import { Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import commonService from "../../services/common.service";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function TagsList(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <Table responsive hover>
      <thead>
        <tr>
          <th>{t("common.name")} </th>
          <th>{t("common.key")}</th>
          <th>{t("tag.tagType")}</th>
          <th>{t("common.range")}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.tagList &&
          props.tagList.map((item: any, index: any) => {
            return (
              <tr key={item.uuid}>
                <td>{item.name}</td>
                <td>{item.key}</td>
                <td>
                  {item.meta.type === 1
                    ? t("common.range")
                    : item.meta.type === 2
                    ? t("common.boolean")
                    : item.meta.type === 3
                    ? t("common.text")
                    : t("common.link")}
                </td>
                <td>
                  {item.meta.type === 1
                    ? item.meta.from + " - " + item.meta.to
                    : "-"}
                </td>

                <td className="text-right text-nowrap ">
                  {item.global && (
                    <span className="badge badge-info">
                      {t("common.global")}
                    </span>
                  )}
                  {!item.global && (
                    <>
                      <span
                        className="px-1 pointer-cursor"
                        onClick={() => {
                          props.onEdit(item.uuid);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{
                            fontSize: 16,
                          }}
                        />
                      </span>

                      <span
                        className="px-1 pointer-cursor"
                        onClick={() => {
                          props.onDelete(item.uuid);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          style={{
                            fontSize: 16,
                          }}
                        />
                      </span>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
}
