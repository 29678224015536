import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ModalFactory from "react-modal-promise";
import LoaderComponent from "../../components/loader/loader";
import {
  ITEM_ACTIONS,
  MediaManagerInitState,
  MediaManagerState,
  MEDIA_TYPE,
} from "../../models/media.manager.model";
import SettingsHeaderComponent from "../../components/navigation/navigation-header/settings-header/settings-header";
import { Button, Col, Input, Row } from "reactstrap";
import SettingsNavComponent from "../../components/navigation/navigation-left/settings-navigation/settings-navigation";
import { withRouter } from "react-router-dom";
import { FileDropZone } from "../../components/card-components/media-manager-card/media-manager-card";
import { postMedia } from "../../database/media.database";
import PaginationComponent from "../../components/service-item/shared/pagination";
import { compose } from "redux";
import { connect } from "react-redux";
import { getMedia, removeMedia } from "../../services/database.service";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import handleResponse from "../../services/response.service";
import MediaList from "../../components/media-manager/media-list";
import { OpenDeleteModal } from "../../components/card-components/delete-card/delete-modal";
import _ from "lodash";

class MediaManagerPage extends Component<any> {
  state: MediaManagerState;
  previousUploads: any = {};
  constructor(props: any) {
    super(props);
    this.state = MediaManagerInitState;
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this._setFunctionBindings();
    this.loadMedia();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      this.state.isUpdated &&
      !this.props.storeProps.isFetching &&
      this.props.storeProps.mediaUpdateDetails.status &&
      !_.isEqual(
        this.props.storeProps.mediaUpdateDetails?.items,
        this.previousUploads?.items
      )
    ) {
      this.previousUploads = _.cloneDeep(
        this.props.storeProps.mediaUpdateDetails
      );
      this.setState({ isUpdated: false });
      this.loadMedia(1, true);
    }
  }

  private _setFunctionBindings() {
    this.onUpload = this.onUpload.bind(this);
  }

  handleFilter = (element: any) => {
    this.loadMedia(1, true, element.target.value);
  };

  handleItemDelete = (itemIds: any) => {
    OpenDeleteModal().then(() => {
      this.showLoading(true);
      const payload = {
        credentials: {
          data: { media_uuid: itemIds },
          restaurantuuid: this.props.match.params.uuid,
        },
      };
      removeMedia(RESTAURANT_REQUEST, payload).then((data) => {
        this.showLoading(false);
        const response: any = handleResponse(data);
        if (data.status === 200) {
          this.loadMedia(this.state.currentPage, true);
        }
      });
    });
  };

  handleItemSelection = (data: any) => {
    const { itemId, checked } = data;
    const selections: Array<string> = [...this.state.selectedItems];

    if (checked) selections.push(itemId);
    else {
      const newSelections: Array<string> = _.remove(
        selections,
        (item: string) => item === itemId
      );
    }
    this.setState({
      selectedItems: selections,
    });
  };

  handleMediaListActions = (actionType: string, data: any) => {
    switch (actionType) {
      case ITEM_ACTIONS.DELETE:
        this.handleItemDelete(data);
        break;
      case ITEM_ACTIONS.SELECTION:
        this.handleItemSelection(data);
        break;
    }
  };

  handleSelectedDelete = () => {
    this.handleItemDelete(this.state.selectedItems);
  };

  handlePostUpload = () => {
    // this.showLoading(false);
  };

  loadMedia = (
    pageNo: number = 1,
    force: boolean = false,
    filter: any = ""
  ) => {
    if (this.state.items[pageNo] && !force) {
      this.setState({
        currentPage: pageNo,
      });
      return;
    }
    this.showLoading(true);
    const pageSize: number = 60;
    const payload = {
      credentials: {
        restaurantuuid: this.props.match.params.uuid,
        URLSearchParams: `pageNo=${pageNo}&pageSize=${pageSize}`,
      },
    };
    if (filter !== "")
      payload.credentials.URLSearchParams += `&filter=type=${[filter]}`;
    getMedia(RESTAURANT_REQUEST, payload).then((data) => {
      const response: any = handleResponse(data);
      this.showLoading(false);
      if (data.status === 200) {
        const items: any = { ...this.state.items };
        items[response.data.pageNo] = response.data.items;
        this.setState({
          currentPage: response.data.pageNo,
          items: items,
          totalPages: Math.ceil(
            Number(response.data.total) / response.data.pageSize
          ),
        });
      }
    });
  };

  private async onUpload(
    imageFiles: any,
    videoFiles: any,
    files: Array<any>,
    entityType: string
  ) {
    this.showLoading(true);
    this.setState({
      isUpdated: true,
    });
    return this.props.dispatchActions.postMedia(
      this.props.match.params.uuid,
      imageFiles,
      videoFiles,
      files,
      entityType
    );
  }

  setCurrentPage = (pageNo: number) => {
    this.loadMedia(pageNo);
  };

  showLoading = (status: boolean) => {
    this.setState({ isFetching: status });
  };

  render() {
    // const acceptableTypes: string ="image/*, video/*";
    return (
      <div>
        <ModalFactory />
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <SettingsHeaderComponent settings={"settings"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <SettingsNavComponent
                display={"media-manager"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <div className="white-box mb-3">
                <h4>{this.props.t("mediaManager.mediaManager")}</h4>

                <section className="bf-media-list-wrapper">
                  <div className="clearfix mb-4">
                    <FileDropZone
                      onUpload={this.onUpload}
                      // accept={acceptableTypes}
                      setLoading={(val: boolean) => this.showLoading(val)}
                      entityType={"media"}
                      handlePostUpload={this.handlePostUpload}
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <span className="mr-2">
                        {this.props.t("common.filter")}
                      </span>
                      <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        onChange={this.handleFilter}
                      >
                        <option value={""}>
                          {this.props.t("common.allFileTypes")}
                        </option>
                        <option value={MEDIA_TYPE.IMAGE}>
                          {this.props.t("common.images")}
                        </option>
                        <option value={MEDIA_TYPE.VIDEO}>
                          {this.props.t("common.videos")}
                        </option>
                        <option value={MEDIA_TYPE.FILES}>
                          {this.props.t("common.files")}
                        </option>
                      </Input>
                    </div>
                    {this.state.selectedItems.length > 0 && (
                      <div>
                        <Button
                          color="danger"
                          onClick={() => this.handleSelectedDelete()}
                        >
                          {this.props.t("mediaManager.deleteSelected")}
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="bf-media-list my-3">
                    <MediaList
                      items={this.state.items[this.state.currentPage]}
                      actions={this.handleMediaListActions}
                    />
                  </div>
                  <PaginationComponent
                    currentPage={this.state.currentPage}
                    filterParams={this.props.filterParams}
                    getItems={() => {}}
                    setCurrentPage={this.setCurrentPage}
                    setUpdateList={() => {}}
                    totalPages={this.state.totalPages}
                  />
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
const mapStateToProps: any = (state: any, ownProps: any) => {
  const restId = ownProps.computedMatch.params.uuid;
  const mediaUpdate: any = state.database[`restaurant/${restId}/media_media`];

  return {
    storeProps: {
      isFetching: mediaUpdate?.isFetching || false,
      isError: mediaUpdate?.isError || false,
      mediaUpdateDetails: mediaUpdate?.data || {},
    },
  };
};
const mapDispatchToProps: object = (dispatch: any) => {
  return {
    dispatchActions: {
      postMedia: (
        restaurantUuid: any,
        imageFiles: any,
        videoFiles: any,
        files: Array<any>,
        entityType: string
      ) => {
        dispatch(
          postMedia(restaurantUuid, imageFiles, videoFiles, files, entityType)
        );
      },
    },
  };
};
const enhance: any = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);
export default withTranslation()(enhance(MediaManagerPage));
