import React, { Component } from 'react';
import {withCardItemSelectionState} from "./CardItemSelectionState";


export function withCardItemSelectionStateManager(cardItemSelectionManagerID: string) {
    return (WrappedComponent: any) => {
        return addCardItemSelectionStateManager(cardItemSelectionManagerID, withCardItemSelectionState("Root", 0)(WrappedComponent))
    }
}

function addCardItemSelectionStateManager(cardItemSelectionManagerID: string, WrappedComponent: any) {
    return class extends Component<any, any> {

        cardItemSelectionManagerID = cardItemSelectionManagerID;

        constructor(props: any) {
            super(props);
            this.state = {
                selectedItem: [],
                mode: "default"
            }
        }

        public setSelectedCardItem = (item: any, mode: any) => {
            this.setState({
                selectedItem: item,
                mode: mode
            });
        }

        render() {

            return <WrappedComponent
                {...this.props}
                cardItemSelectionCallback={this.setSelectedCardItem}
                cardItemSelectionState={this.state}
                cardItemSelectionManagerID={this.cardItemSelectionManagerID}
            />;
        }
    }
}