import React from "react";
import OrderInterface from "../../components/shop-interface/order-interface";
import SettingsHeaderComponent from "../../components/navigation/navigation-header/settings-header/settings-header";
import SettingsNavComponent from "../../components/navigation/navigation-left/settings-navigation/settings-navigation";
import NavigationRightComponent from "../../components/navigation/navigation-right/navigation-right";
import { Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";

const ShopInterfacePage: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();

  return (
    <div>
      <div className="container-fluid">
        <SettingsHeaderComponent settings={"settings"} />
        <Row className="main light-theme">
          <Col xl={2} lg={3} className="hide-left-max">
            <SettingsNavComponent
              display={"shop-interface"}
              restaurantuuid={uuid}
            />
          </Col>
          <Col xl={8} lg={6}>
            <OrderInterface restaurantUuid={uuid} />
          </Col>
          <Col xl={2} lg={3}>
            <NavigationRightComponent />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ShopInterfacePage;
