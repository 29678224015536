import { UserLanguageModel } from "../../models/user.language.model";
import * as types from "../actions/types";

const initialState: UserLanguageModel = {
  isFetching: false,
  data: [],
  error: {},
  failure: false,
};
export function userLanguageUpdateReducer(
  state = initialState,
  action: any
): UserLanguageModel {
  switch (action.type) {
    case types.USER_LANGUAGE_UPDATE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.USER_LANGUAGE_UPDATE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        failure: false,
        error: {},
      };
    case types.USER_LANGUAGE_UPDATE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
