import apiCall from './api.call.config';

async function forgotpassword(url: string, credentials: any) {
  try {
    return await apiCall.post(url, credentials.credentials);
  } catch (e) {
    throw e;
  }
}

export default { forgotpassword }
