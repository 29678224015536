import "../../../styles/storage.scss";

import {
  faEdit,
  faEllipsisV,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from "reactstrap";

import { GRAY, GREEN } from "../../../constant/constant";
import {
  StoragePlaceInitState,
  StoragePlaceState,
} from "../../../models/storage.place.model";
import DeleteModalCard from "../delete-card/delete-card";
import StoragePlaceAddCard from "../storage-place-add-card/storage-place-add-card";
import StoragePlaceEditCard from "../storage-place-edit-card/storage-place-edit-card";
import { withTranslation } from "react-i18next";
import commonService from "../../../services/common.service";

class StorageRackCard extends Component<any> {
  state: StoragePlaceState;
  constructor(props: any) {
    super(props);
    this.state = StoragePlaceInitState;
    this._setFunctionBindings();
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        {/*  Add Storage Place Modal */}

        <StoragePlaceAddCard
          state={this.state}
          addStoragePlaceToggle={this.addStoragePlaceToggle}
          handleChange={this.handleChange}
          saveStoragePlaceAdd={this.saveStoragePlaceAdd}
        />

        {/*  Edit Storage Place Modal */}

        <StoragePlaceEditCard
          state={this.state}
          editStoragePlaceToggle={this.editStoragePlaceToggle}
          handleChange={this.handleChange}
          saveStoragePlaceEdit={this.saveStoragePlaceEdit}
        />

        {/* Delete Storage Place Modal*/}

        <DeleteModalCard
          isOpen={this.state.deletestorageplacemodal}
          isFetching={!!this.state.isFetching}
          okDelete={this.okDeleteStoragePlace}
          cancelDelete={this.deleteStoragePlaceToggle}
        />
        <div>
          <Row className="app-row-padding">
            <Col className="col-lg-10 col-xl-10">
              <h6>{this.props.storagerack.name}</h6>
            </Col>
            <Col className="col-lg-2 col-xl-2">
              <div style={{ float: "right" }}>
                <span
                  onClick={this.props.setStorageRackEditable}
                  className="pointer-cursor"
                  data-toggle="tooltip"
                  data-placement="left"
                  title={t("common.edit")}
                >
                  <FontAwesomeIcon
                    style={{ color: GREEN, fontSize: 20 }}
                    icon={faEdit}
                  />
                </span>
                <span
                  style={{ margin: 10 }}
                  onClick={this.props.setStorageRackDelete}
                  className="pointer-cursor"
                  data-toggle="tooltip"
                  data-placement="left"
                  title={t("common.delete")}
                >
                  <FontAwesomeIcon
                    style={{ color: GRAY, fontSize: 20 }}
                    icon={faTrashAlt}
                  />
                </span>
                <UncontrolledDropdown className="mr-1">
                  <DropdownToggle
                    tag="a"
                    className={"nav-link"}
                    style={{ marginLeft: "94%" }}
                  >
                    <FontAwesomeIcon
                      style={{ color: "#3f3f3f" }}
                      icon={faEllipsisV}
                      pull="right"
                    />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      tag="a"
                      onClick={this.props.toggleLabelPrintModal(
                        this.props.storagerack.uuid,
                        "rack"
                      )}
                    >
                      {t("common.printRackLocation")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          </Row>
        </div>
        <Label className="storage-place-header" id={this.props.index}>
          {" "}
          {t("storage.storagePlaces")}
        </Label>
        <UncontrolledCollapse className="toggler" toggler={this.props.index}>
          {this.props.storagerack.places.map((place: any, index: number) => {
            return (
              <div className="child-box mb-3" key={index}>
                <Row className="app-row-padding">
                  <Col className="col-lg-10 col-xl-10">
                    <h6>{place.name}</h6>
                  </Col>
                  <Col className="col-lg-2 col-xl-2">
                    <div style={{ float: "right" }}>
                      <span
                        onClick={this.setStoragePlaceEditable.bind(this, place)}
                        className="pointer-cursor"
                        data-toggle="tooltip"
                        data-placement="left"
                        title={t("common.edit")}
                      >
                        <FontAwesomeIcon
                          style={{ color: GREEN, fontSize: 20 }}
                          icon={faEdit}
                        />
                      </span>
                      <span
                        style={{ margin: 10 }}
                        onClick={this.setStoragePlaceDelete.bind(this, place)}
                        className="pointer-cursor"
                        data-toggle="tooltip"
                        data-placement="left"
                        title={t("common.delete")}
                      >
                        <FontAwesomeIcon
                          style={{ color: GRAY, fontSize: 20 }}
                          icon={faTrashAlt}
                        />
                      </span>
                      <UncontrolledDropdown className="mr-1">
                        <DropdownToggle
                          tag="a"
                          className={"nav-link"}
                          style={{ marginLeft: "94%" }}
                        >
                          <FontAwesomeIcon
                            style={{ color: "#3f3f3f" }}
                            icon={faEllipsisV}
                            pull="right"
                          />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem
                            tag="a"
                            onClick={this.props.toggleLabelPrintModal(
                              place.uuid,
                              "place"
                            )}
                          >
                            {t("common.printPlaceLocation")}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
          <Label
            style={{ paddingLeft: 10, paddingTop: 0, paddingBottom: 0 }}
            color="info"
            className={"label-block"}
            onClick={this.addStoragePlaceToggle}
          >
            {t("storage.createStoragePlaces")}
          </Label>
        </UncontrolledCollapse>
      </div>
    );
  }

  componentDidMount() {
    document.body.className = "light-theme";
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
    }

    if (!!this.state.isUpdated && !newProps.failure && !newProps.isFetching) {
      this.props.getStorage({
        restaurantuuid: this.props.match.params.uuid,
      });
      this._setStates("isUpdated", false);
    }
    if (
      !!newProps.storageplaceadd.status &&
      !!this.state.addstorageplacemodal &&
      !newProps.isFetching
    ) {
      this.addStoragePlaceToggle();
    } else if (
      !!newProps.storageplaceupdate.status &&
      !!this.state.editstorageplacemodal &&
      !newProps.isFetching
    ) {
      this.editStoragePlaceToggle();
    }
  }

  private _setFunctionBindings(): void {
    this._setStates = this._setStates.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addStoragePlaceToggle = this.addStoragePlaceToggle.bind(this);
    this.editStoragePlaceToggle = this.editStoragePlaceToggle.bind(this);
    this.deleteStoragePlaceToggle = this.deleteStoragePlaceToggle.bind(this);
    this.saveStoragePlaceAdd = this.saveStoragePlaceAdd.bind(this);
    this.saveStoragePlaceEdit = this.saveStoragePlaceEdit.bind(this);
    this.okDeleteStoragePlace = this.okDeleteStoragePlace.bind(this);
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  public handleChange(e: any): void {
    this._setStates(e.target.name, e.target.value);
  }

  public addStoragePlaceToggle(): void {
    this.setState((prevState: any) => ({
      addstorageplacemodal: !prevState.addstorageplacemodal,
      storageplacename: "",
    }));
  }

  public editStoragePlaceToggle(): void {
    this.setState((prevState: any) => ({
      editstorageplacemodal: !prevState.editstorageplacemodal,
    }));
  }

  public deleteStoragePlaceToggle(): void {
    this.setState((prevState: any) => ({
      deletestorageplacemodal: !prevState.deletestorageplacemodal,
    }));
  }

  public saveStoragePlaceAdd(): void {
    this.props.addStoragePlace({
      restaurantuuid: this.props.match.params.uuid,
      storageuuid: this.props.storageuuid,
      rackuuid: this.props.storagerack.uuid,
      data: {
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.storageplacename,
          },
        },
      },
    });
    this._setStates("isUpdated", true);
  }

  public saveStoragePlaceEdit(): void {
    this.props.updateStoragePlace({
      uuid: this.state.uuid,
      restaurantuuid: this.props.match.params.uuid,
      storageuuid: this.props.storageuuid,
      rackuuid: this.props.storagerack.uuid,
      data: {
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.editstorageplacename,
          },
        },
      },
    });
    this._setStates("isUpdated", true);
  }

  public okDeleteStoragePlace(): void {
    this.props.removeStoragePlace({
      uuid: this.state.uuid,
      restaurantuuid: this.props.match.params.uuid,
      storageuuid: this.props.storageuuid,
      rackuuid: this.props.storagerack.uuid,
    });
    this._setStates("isUpdated", true);
    this.deleteStoragePlaceToggle();
  }

  public setStoragePlaceEditable(placedata: any): void {
    this.setState({
      uuid: placedata.uuid,
      editstorageplacename: placedata.name,
    });
    this.editStoragePlaceToggle();
  }

  public setStoragePlaceDelete(placedata: any): void {
    this._setStates("uuid", placedata.uuid);
    this.deleteStoragePlaceToggle();
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.storageplaceadd.isFetching ||
    state.storageplaceupdate.isFetching ||
    state.storageplaceremove.isFetching;
  let failure =
    state.storageplaceadd.failure ||
    state.storageplaceupdate.failure ||
    state.storageplaceremove.failure;
  return {
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    storageplaceadd: state.storageplaceadd.data,
    storageplaceupdate: state.storageplaceupdate.data,
    isFetching: isFetching,
    failure: failure,
  };
};

export default withTranslation()(
  connect(mapStateToProps)(withRouter(StorageRackCard))
);
