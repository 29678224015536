import { InstanceProps, create } from "react-modal-promise";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Label,
  ModalFooter,
  Button,
  Input,
  Form,
  FormGroup,
  CustomInput,
} from "reactstrap";
import React, {
  useState,
  useCallback,
  ChangeEvent,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { StDropZone } from "../../st-drop-zone/st-drop-zone";
import { Apk } from "../../../Types/Apk";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toggleItemInArray } from "../../../helpers/commonHelper";
import { find } from "ramda";

export type SubmitPayload = {
  device_uuid: string;
  apps: string[];
  machine?: string;
  show_on_home: boolean;
  autostart: boolean;
  machineOptions: any[];
};
const AddDeviceAppModal = (props) => {
  const {
    isOpen,
    onReject,
    onResolve,
    onSubmit,
    isEdit,
    appOptions,
    selectedOptions,
    deviceUuid,
    formData,
    machineOptions,
  } = props;
  const { t } = useTranslation();
  const [apps, setApps] =
    useState<{ label: string; value: string }[]>(selectedOptions);
  const [autoStart, setAutoStart] = useState<string[]>([]);
  const [showOnHome, setShowOnHome] = useState<string[]>([]);
  const selectedMachineOption = useMemo(
    () =>
      find((mo) => mo.value === formData.device.machine_uuid, machineOptions),
    [machineOptions, formData]
  );
  const [selectedMachine, setSelectedMachine] = useState<{
    label: string;
    value: string;
  }>(selectedMachineOption);

  useEffect(() => {
    const autoStartApps = formData.device.apps.filter((app) => app.autostart);
    const uuids = autoStartApps.map((app) => app.app.uuid);
    setAutoStart(uuids);

    const showHomeApps = formData.device.apps.filter((app) => app.show_on_home);
    const ids = showHomeApps.map((app) => app.app.uuid);
    setShowOnHome(ids);
  }, [selectedOptions, formData]);

  const handleAppSelection = (selected: { label: string; value: string }[]) => {
    setApps(selected || []);
  };
  const handleMachineSelection = (selected: {
    label: string;
    value: string;
  }) => {
    setSelectedMachine(selected);
  };

  const onChangeAutoStart = (appUuid: string) => {
    setAutoStart((auto) => toggleItemInArray(auto, appUuid));
  };

  const onChangeShowOnHome = (appUuid: string) =>
    setShowOnHome((s) => toggleItemInArray(s, appUuid));

  const onSubmitForm = async () => {
    await onSubmit({
      device_uuid: deviceUuid,
      machine_uuid: selectedMachine?.value,
      apps: apps.map((a) => ({
        uuid: a.value,
        show_on_home: showOnHome.indexOf(a.value) > -1,
        autostart: autoStart.indexOf(a.value) > -1,
      })),
    });
    onResolve(true);
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader className="modal-header">{t("apps.addApps")}</ModalHeader>
      <ModalBody>
        <div>
          <Form className="bf-add-timing">
            <FormGroup className="mb-0">
              <Label for="room.room">{t("devices.machine")}</Label>
              <Select
                options={machineOptions}
                onChange={handleMachineSelection}
                name="machines"
                value={selectedMachine}
              />
            </FormGroup>
            <FormGroup className="mb-0">
              <Label for="room.room">{t("devices.apps")}</Label>
              <Select
                options={appOptions}
                isMulti
                onChange={handleAppSelection}
                name="apps"
                value={apps}
              />
            </FormGroup>
            {apps.map((app) => {
              const selectedApp = formData.device.apps.find(
                (ap) => ap.app.uuid === app.value
              );
              return (
                <div className="d-flex d-row align-items-center justify-content-between">
                  <Label>{app.label}</Label>
                  <div>
                    <FormGroup className="mt-4">
                      <CustomInput
                        id={`${app.value}-autostart`}
                        type="checkbox"
                        name={`${app.value}-autostart`}
                        onChange={() => onChangeAutoStart(app.value)}
                        label={t("devices.autostart")}
                        defaultChecked={selectedApp && selectedApp.autostart}
                      />
                    </FormGroup>
                    <FormGroup>
                      <CustomInput
                        id={`${app.value}-showOnHome`}
                        type="checkbox"
                        name={`${app.value}-showOnHome`}
                        onChange={() => onChangeShowOnHome(app.value)}
                        label={t("devices.showOnHome")}
                        defaultChecked={selectedApp && selectedApp.show_on_home}
                      />
                    </FormGroup>
                  </div>
                </div>
              );
            })}
          </Form>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onReject}>
          <option value="">{t("common.cancel")}</option>
        </Button>
        <Button color="info" onClick={onSubmitForm} disabled={false}>
          <option value="">{t("common.submit")}</option>
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export const addDeviceAppsModal = create(AddDeviceAppModal);
