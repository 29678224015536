import apiCall from './api.call.config';

async function users(url: string, params: any) {
    try {
        return await apiCall.get(url + '/' + params.credentials.restaurantuuid + '/users');
    } catch (e) {
        throw e;
    }
}

async function removeUsers(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.remove(url + '/' + params.credentials.restaurantuuid + '/users/' + params.credentials.uuid);
    } catch (e) {
        throw e;
    }
}

async function addUserRole(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url + '/' + params.credentials.restaurantuuid + '/user/' + params.credentials.uuid
            + '/user-role', params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function addStaff(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.restaurantuuid + "/users/add-staff",
      params.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  users,
  removeUsers,
  addUserRole,
  addStaff,
};
