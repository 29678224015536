import { faEdit, faStop, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Col, Label, Row } from "reactstrap";
import { DARK_GRAY, GRAY, GREEN } from "../../../constant/constant";
import commonService from "../../../services/common.service";
import "../../../styles/units.scss";
class LabelCard extends Component<any> {
  render() {
    const { t } = this.props;
    return (
      <div className="child-box mb-3">
        <span style={{ marginTop: 20 }}>
          <div style={{ float: "right", display: "flex" }}>
            <span
              onClick={this.props.setEditable}
              className="pointer-cursor"
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.edit")}
            >
              <FontAwesomeIcon
                style={{ color: GREEN, fontSize: 20, margin: 5 }}
                icon={faEdit}
              />
            </span>
            <span
              onClick={this.props.setDelete}
              className="pointer-cursor"
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.delete")}
            >
              <FontAwesomeIcon
                style={{ color: GRAY, fontSize: 20, margin: 5 }}
                icon={faTrashAlt}
              />
            </span>
          </div>
        </span>
        <span style={{ display: "flex", marginTop: 5 }}>
          <FontAwesomeIcon
            className="color-box-one"
            style={{ color: `${this.props.locales.color}`, fontSize: 22 }}
            icon={faStop}
          />
          <h6 style={{ color: DARK_GRAY, paddingLeft: 10 }}>
            {
              commonService.applyLocalization(
                "restaurant",
                "name",
                this.props.locales?.locales
              )["name"]
            }
          </h6>
        </span>
        <Row className="app-row-padding">
          <Col xs="12" sm="6">
            <Label>{t("printer.parentLabels")}:</Label>{" "}
            {this.props.locales.parent_labels.length > 0
              ? this.props.locales.parent_labels.map((parent: any) => {
                  return this.labelName(parent);
                })
              : "None"}
          </Col>
          <Col xs="12" sm="6">
            <Label>{t("printer.orderPrinter")} : </Label>{" "}
            {this.props.printerName != ""
              ? this.props.printerName
              : t("common.noPrinting")}
          </Col>
        </Row>
      </div>
    );
  }

  public labelName(uuid: any): any {
    let allLabels = this.props.state.labels.length
      ? this.props.state.labels
      : [];
    let name;
    if (!!allLabels) {
      // eslint-disable-next-line
      name = allLabels.map((item: any, i: number) => {
        if (uuid === item.uuid) {
          return (
            <span
              key={i}
              style={{
                backgroundColor: item.color,
                color: commonService.getFontContrast(item.color),
              }}
              className="more-text label-text"
            >
              {
                commonService.applyLocalization(
                  "restaurant",
                  "name",
                  item?.locales
                ).name
              }
            </span>
          );
        }
      });
      return name;
    }
  }
}
export default withTranslation()(withRouter(LabelCard));
