import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import "../../styles/units.scss";
import { useTranslation } from "react-i18next";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../styles/draft-js.scss";
import { isEmpty } from "lodash";

export default function ContentManagementAddEditModal(props: any) {
  const { t, i18n } = useTranslation();
  const [description, setDescription] = useState<any>("");
  const [name, setName] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    setName(props.activeContent.name);
    if (!isEmpty(props.activeContent.description)) {
      const blocksFromHTML = convertFromHTML(props.activeContent.description);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [props.activeContent]);

  const handleChange = (element: any) => {
    setName(element.target.value);
  };

  const handleSave = () => {
    props.onSave({
      name: name,
      description,
      uuid: props.activeContent?.uuid || "",
    });
  };

  const handleEditorChange = (newState: any) => {
    setEditorState(newState);
    setDescription(convertToHTML(newState.getCurrentContent()));
  };

  return (
    <Modal isOpen={props.addModal} size={"lg"}>
      <ModalHeader toggle={props.toggle} className="modal-header">
        {props.isEdit
          ? t("contentManagement.editContent")
          : t("contentManagement.addContent")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="name">{t("common.name")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name="name"
              autoComplete="name"
              placeholder={t("common.name")}
              value={name}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="percentage">{t("contentManagement.content")}</Label>
            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              wrapperClassName="draft-js-wrapper"
              editorClassName="draft-js-editor"
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>
          {t("common.cancel")}
        </Button>
        <Button color="info" onClick={handleSave} disabled={name.trim() === ""}>
          {t("common.save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
