import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { GUEST_GROUP } from "../../constant/constant";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import commonService from "../../services/common.service";
import guestGroupService from "../../services/guest.group.service";
import GuestGroupListCard from "../card-components/guest-group-card/guest-group-list";

const GuestGroupComponent = (props: any) => {
  const {
    guestGroupList,
    restaurantuuid,
    table,
    setDelete,
    performShopOrder,
    setGuestGroupList,
    domain,
  } = props;

  const { t } = useTranslation();

  const createGuestGroup = (table: any) => () => {
    guestGroupService
      .createGuestGroup(RESTAURANT_REQUEST, {
        restaurantuuid: restaurantuuid,
        data: { table: table.uuid, domain: domain },
      })
      .then((response: any) => {
        if (response.status === 200) {
          setGuestGroupList([...guestGroupList, response.data.data]);
          commonService.toastService(response.data.flash, "success");
          performShopOrder(response.data.data)();
        }
      })
      .catch((error: any) => {
        commonService.toastService("", "danger", JSON.stringify(error.error));
      });
  };

  return (
    <>
      {guestGroupList
        .filter(
          (g: any) =>
            g.status === GUEST_GROUP.STATUS_TYPE_OPEN &&
            g.table.uuid === table.uuid
        )
        .map((guestGroup: any, index: number) => {
          return (
            <GuestGroupListCard
              key={index}
              setDelete={setDelete(guestGroup)}
              performShopOrder={performShopOrder(guestGroup)}
              guestGroup={guestGroup}
            ></GuestGroupListCard>
          );
        })}

      <Button
        block
        color="light"
        className="text-center shadow mt-3"
        title={t("guestGroup.addGuestGroup")}
        onClick={createGuestGroup(table)}
      >
        <FontAwesomeIcon
          style={{
            fontSize: 20,
            float: "none",
          }}
          icon={faPlus}
        />
      </Button>
    </>
  );
};

export default GuestGroupComponent;
