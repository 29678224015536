import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

import commonService from "../../services/common.service";
import { TagsState, TagsInitState } from "../../models/tags.model";
import LoaderComponent from "../loader/loader";
import ConceptHeaderComponent from "../navigation/navigation-header/concept-header/concept-header";
import RestaurantNavComponent from "../navigation/navigation-left/restaurant-navigation/restaurant-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import TagsCreateEditModal from "./tagsCreateEditModal";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import TagsList from "./tagsList";
import {
  tagAdd,
  tagRemove,
  tagsRequest,
  tagUpdate,
} from "../../redux/actions/tags.action";

import "../../styles/units.scss";
import { withTranslation } from "react-i18next";
import _ from "lodash";

class TagsComponent extends Component<any> {
  isAddEditTagModalOpen: boolean = false;
  isRemoveTagModalOpen: boolean = false;
  state: TagsState;
  constructor(props: any) {
    super(props);
    this.state = TagsInitState;
    this.bindFunctions();
  }
  bindFunctions() {
    this.CreateEditTagModalToggle = this.CreateEditTagModalToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.EditTag = this.EditTag.bind(this);
    this.RemoveTag = this.RemoveTag.bind(this);
    this.RemoveTagModalToggle = this.RemoveTagModalToggle.bind(this);
    this.SaveTag = this.SaveTag.bind(this);
  }
  componentDidMount() {
    this.props.getTags({ restaurantuuid: this.props.match.params.uuid });
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
      if (!!this.state.isUpdated && !newProps.isFetching) {
        this.props.getTags({ restaurantuuid: this.props.match.params.uuid });
        this._setStates("isUpdated", false);
      }
    }
  }

  public handleTagChange = (event: any, name: any, index: any) => {
    if (name === "tagName") {
      this._setStates("defaultError", "");
      this._setStates("tagName", event);
      this._setStates("tagValue", "");
      this._setStates(
        "defaultValue",
        event.meta.type === 1 ? "" : event.meta.type === 2 ? false : ""
      );
      if (event.meta.type === 1)
        this._setStates("tagValue", [event.meta.from, event.meta.to]);
      else if (event.meta.type === 3) {
        this._setStates(
          "moreValues",
          event.meta?.options?.map((option: any, index: any) => {
            return {
              index: index,
              value: option,
            };
          })
        );
      }
    } else {
      if (name === "text") {
        let moreValues = this.state.moreValues;
        const defaultValue = "";
        if (event && event.target.value.trim() !== "")
          moreValues[index] = { index: index, value: event.target.value };
        else {
          moreValues[index] = { index: index, value: "" };
        }
        this._setStates("moreValues", moreValues);
        this._setStates("defaultValue", defaultValue);
      } else if (name === "freeText") {
        let moreValues: Array<any> = [];
        const defaultValue = { value: "" };
        if (!index) {
          moreValues = [{ 0: "", value: "" }];
        }
        this.setState({
          defaultValue: defaultValue,
          moreValues: moreValues,
          override: true,
        });
      } else if (name === "content") {
        this.setState({
          defaultValue: event,
        });
      }
    }
  };

  public addMoreValues = (e: any) => {
    this._setStates("moreValues", [
      ...this.state.moreValues,
      { index: "", value: "" },
    ]);
  };

  getSortedTags = () => {
    const tags: Array<any> = [];
    this.props.tags?.forEach((tag: any) => {
      tags.push({
        ...tag,
        name: commonService.applyLocalization(
          "restaurant",
          "name",
          tag?.locales
        ).name,
      });
    });
    return _.sortBy(tags, "name");
  };

  public removeValue = (index: any, item?: any) => {
    let moreValues = this.state.moreValues;
    if (moreValues[index].value === item.defaultValue?.value) {
      item.defaultValue = "";
    }
    moreValues.splice(index, 1);
    this._setStates("moreValues", moreValues);
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <TagsCreateEditModal
          addMoreValues={this.addMoreValues}
          handleChange={this.handleChange}
          handleTagChange={this.handleTagChange}
          isOpen={this.state.isAddEditTagModalOpen}
          onCancel={this.CreateEditTagModalToggle}
          onSave={this.SaveTag}
          removeValue={this.removeValue}
          state={this.state}
        />
        <DeleteModalCard
          isOpen={this.state.isRemoveTagModalOpen}
          uuid={this.state.uuid}
          isFetching={!!this.state.isFetching}
          okDelete={this.RemoveTag}
          cancelDelete={this.RemoveTagModalToggle}
        />
        <div className="container-fluid">
          <ConceptHeaderComponent concept={"concept"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <RestaurantNavComponent
                display={"tags"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={8} lg={6}>
              <div className="white-box mb-3">
                <h4>
                  {t("tag.tag")} {this.state.isFetching}
                  <Button
                    className="ml-2"
                    color="primary"
                    size="sm"
                    onClick={this.CreateEditTagModalToggle}
                  >
                    {t("tag.addNewTag")}
                  </Button>
                </h4>
                <TagsList
                  restaurantLang={this.props.restaurantLang}
                  tagList={this.getSortedTags()}
                  onEdit={this.EditTag}
                  onDelete={this.RemoveTagModalToggle}
                />
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }
  public CreateEditTagModalToggle() {
    this.setState((prevState: any) => ({
      isAddEditTagModalOpen: !prevState.isAddEditTagModalOpen,
      moreValues: [{ index: "", value: "" }],
    }));
    if (this.state.isAddEditTagModalOpen) {
      this.setState(() => ({
        isEdit: false,
        tags_key: "",
        tags_name: "",
        tags_range_from: 1,
        tags_range_to: 10,
        tags_type: 1,
        uuid: "",
      }));
    }
  }
  public EditTag(tagId: string) {
    const tagData = this.props.tags.filter((data: any) => {
      return data.uuid === tagId;
    });
    this.setState((prevState: any) => ({
      isAddEditTagModalOpen: !prevState.isAddEditTagModalOpen,
      isEdit: true,
      tags_key: tagData[0].key,
      tags_name: commonService.applyLocalization(
        "restaurant",
        "name",
        tagData[0].locales
      )["name"],
      tags_type: tagData[0].meta.type,
      uuid: tagId,
    }));
    if (tagData[0].meta.type === 1) {
      this.setState((prevState: any) => ({
        tags_range_from: tagData[0].meta.from,
        tags_range_to: tagData[0].meta.to,
      }));
    } else if (tagData[0].meta.type === 3) {
      this.setState({
        moreValues: tagData[0].meta?.options?.map((option: any, index: any) => {
          return {
            value: option,
            index: index,
          };
        }),
      });
    }
  }

  public handleChange(e: any) {
    this._setStates(e.target.name, e.target.value);
  }

  public RemoveTag() {
    this.props.removeTag({
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.state.uuid,
    });
    this._setStates("isUpdated", true);
    this.RemoveTagModalToggle();
  }
  public RemoveTagModalToggle(tagId?: string) {
    this.setState((prevState: any) => ({
      isRemoveTagModalOpen: !prevState.isRemoveTagModalOpen,
    }));
    if (this.state.isRemoveTagModalOpen) {
      this._setStates("uuid", "");
    } else {
      this._setStates("uuid", tagId);
    }
  }
  public SaveTag() {
    const formState: TagsState = this.state;
    let meta: any = {};

    if (Number(formState.tags_type) === 1) {
      meta = {
        from: Number(formState.tags_range_from),
        to: Number(formState.tags_range_to),
      };
    } else if (Number(formState.tags_type) === 3) {
      meta = {
        options:
          this.state.moreValues?.length > 0
            ? this.state.moreValues.map((value: any) => {
                return value.value;
              })
            : [],
      };
    }

    if (!this.state.isEdit) {
      meta.type = Number(formState.tags_type);
    }

    if (this.state.isEdit) {
      let payload: any = {
        data: {
          locales: {
            [this.props.restaurantLang[0].code]: {
              name: formState.tags_name,
            },
          },
          key: formState?.tags_key,
          score: 2,
        },
        restaurantuuid: this.props.match.params.uuid,
        uuid: this.state.uuid,
      };
      if (Number(formState.tags_type) === 1) {
        payload.data.meta = meta;
      } else if (Number(formState.tags_type) === 3) {
        payload.data.meta = {
          type: Number(formState.tags_type),
          options:
            this.state.moreValues?.length > 0
              ? this.state.moreValues.map((value: any) => {
                  return value.value;
                })
              : [],
        };
      }
      this.props.updateTag(payload);
    } else {
      this.props.addTag({
        restaurantuuid: this.props.match.params.uuid,
        data: {
          locales: {
            [this.props.restaurantLang[0].code]: {
              name: formState.tags_name,
            },
          },
          key: formState?.tags_key,
          meta: meta,
          score: 2,
        },
      });
    }
    this.CreateEditTagModalToggle();
    this._setStates("isUpdated", true);
  }
}
const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.tagAdd.isFetching ||
    state.tagRemove.isFetching ||
    state.tagUpdate.isFetching ||
    state.tags.isFetching;
  let failure =
    state.tagAdd.failure || state.tagRemove.failure || state.tags.failure;
  return {
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    tags: state.tags.data,
    tagAddStatus: state.tagAdd.data,
    tagRemoveStatus: state.tagRemove.data,
    tagUpdateStatus: state.tagUpdate.data,
    isFetching: isFetching,
    failure: failure,
  };
};
const mapDispatchToProps: object = (dispatch: any) => {
  return {
    addTag: (credentials: any) => {
      dispatch(tagAdd(credentials));
    },
    getTags: (credentials: any) => {
      dispatch(tagsRequest(credentials));
    },
    removeTag: (credentials: any) => {
      dispatch(tagRemove(credentials));
    },
    updateTag: (credentials: any) => {
      dispatch(tagUpdate(credentials));
    },
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(TagsComponent))
);
