import { call, put, takeLatest } from "redux-saga/effects";

import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  periodicityAddFailure,
  periodicityAddSuccess,
  periodicityDetailsFailure,
  periodicityDetailsSuccess,
  periodicityFailure,
  periodicityRemoveSuccess,
  periodicityRemoveFailure,
  periodicitySuccess,
  periodicityUpdateFailure,
  periodicityUpdateSuccess,
} from "../redux/actions/periodicity.action";

import * as types from "../redux/actions/types";
import periodicity from "../services/periodicity.service";
import handleResponse from "../services/response.service";

const periodicityListCall = (params: any) =>
  periodicity.periodicityList(RESTAURANT_REQUEST, params);
const addPeriodicityCall = (params: any) =>
  periodicity.addPeriodicity(RESTAURANT_REQUEST, params);
const getPeriodicityDetailsCall = (params: any) =>
  periodicity.periodicityDetails(RESTAURANT_REQUEST, params);
const removePeriodicityCall = (params: any) =>
  periodicity.removePeriodicity(RESTAURANT_REQUEST, params);
const updatePeriodicityCall = (params: any) =>
  periodicity.updatePeriodicity(RESTAURANT_REQUEST, params);

function* handlePeriodicityRequest(params: object) {
  try {
    let result: any;
    result = yield call(periodicityListCall, params);
    yield handlePeriodicityRequest200(result);
  } catch (error) {
    throw error;
  }
}

const handlePeriodicityRequest200 = function* handlePeriodicityRequest200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(periodicitySuccess(result.data));
  } else {
    yield put(periodicityFailure(result.data));
    handleResponse(result);
  }
};

function* handlePeriodicityAdd(params: object) {
  try {
    let result;
    result = yield call(addPeriodicityCall, params);
    yield handlePeriodicityAdd200(result);
  } catch (error) {
    throw error;
  }
}

const handlePeriodicityAdd200 = function* handlePeriodicityAdd200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(periodicityAddSuccess(result.data));
  } else {
    yield put(periodicityAddFailure(result.data));
  }
  handleResponse(result);
};

function* handlePeriodicityDetails(params: object) {
  try {
    let result;
    result = yield call(getPeriodicityDetailsCall, params);
    yield handlePeriodicityDetails200(result);
  } catch (error) {
    throw error;
  }
}

const handlePeriodicityDetails200 = function* handlePeriodicityDetails200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(periodicityDetailsSuccess(result.data));
  } else {
    yield put(periodicityDetailsFailure(result.data));
  }
  handleResponse(result);
};

function* handlePeriodicityRemove(params: object) {
  try {
    let result;
    result = yield call(removePeriodicityCall, params);
    yield handlePeriodicityRemove200(result);
  } catch (error) {
    throw error;
  }
}

const handlePeriodicityRemove200 = function* handlePeriodicityRemove200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(periodicityRemoveSuccess(result.data));
  } else {
    yield put(periodicityRemoveFailure(result.data));
  }
  handleResponse(result);
};
function* handlePeriodicityUpdate(params: object) {
  try {
    let result;
    result = yield call(updatePeriodicityCall, params);
    yield handlePeriodicityUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handlePeriodicityUpdate200 = function* handlePeriodicityUpdate200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(periodicityUpdateSuccess(result.data));
  } else {
    yield put(periodicityUpdateFailure(result.data));
  }
  handleResponse(result);
};

export default function* periodicitySaga() {
  yield takeLatest(types.PERIODICITY.REQUEST, handlePeriodicityRequest);
  yield takeLatest(types.PERIODICITY_ADD.REQUEST, handlePeriodicityAdd);
  yield takeLatest(types.PERIODICITY_DETAILS.REQUEST, handlePeriodicityDetails);
  yield takeLatest(types.PERIODICITY_REMOVE.REQUEST, handlePeriodicityRemove);
  yield takeLatest(types.PERIODICITY_UPDATE.REQUEST, handlePeriodicityUpdate);
}
