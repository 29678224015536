interface filterParamsTypes {
  name: string;
  labelArray: any;
  supplierArray?: any;
  showArchived: boolean;
  pageNo?: number;
  isBrowserNav?: boolean;
}

export const UrlSyncHelper = {
  setStateFromUrl: (
    setPageState: Function,
    setFilterParams: Function,
    location: any
  ): { pageNo: number; filter: filterParamsTypes } => {
    const searchParams = new URLSearchParams(location.search);
    const urlPageNo = parseInt(searchParams.get("pageNo") || `${1}`, 10);
    const labelArray = searchParams.get("label") || "";
    const supplierArray = searchParams.get("supplier") || "";
    setPageState("currentPage", urlPageNo);
    setPageState("isBrowserNav", true);

    const filterParams = {
      name: searchParams.get("name") || "",
      labelArray:
        labelArray !== ""
          ? labelArray?.split(",").map((uuid: string) => {
              return { value: uuid };
            })
          : [],
      supplierArray:
        supplierArray !== ""
          ? supplierArray?.split(",").map((uuid: string) => {
              return { value: uuid };
            })
          : [],
      showArchived: searchParams.get("archive")
        ? searchParams.get("archive") === "true"
        : false,
      setPageState: setPageState,
    };
    setFilterParams(filterParams);

    return { pageNo: urlPageNo, filter: filterParams };
  },

  setUrlFromState: (filterParams: filterParamsTypes, history: any): any => {
    const searchParams = new URLSearchParams();
    searchParams.set("pageNo", `${filterParams.pageNo}`);
    searchParams.set("label", `${filterParams.labelArray}`);
    searchParams.set("name", `${filterParams.name}`);
    searchParams.set("supplier", `${filterParams.supplierArray ?? ""}`);
    searchParams.set("archive", `${filterParams.showArchived ?? ""}`);
    history.push({ search: searchParams.toString() });
  },
};
