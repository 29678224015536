import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Media, Row } from "reactstrap";
import image from "../../../assets/images/icon/image.png";
import "../../../styles/profile.scss";
import commonService from "../../../services/common.service";

export default function ProfileCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <Media>
      <Media left className="img-block mb-2">
        <img
          onClick={props.setImageEdit}
          data-toggle="tooltip"
          data-placement="left"
          title="Upload Profile Image"
          src={
            props.state.profile.image_url
              ? props.state.profile.image_url
              : image
          }
          alt=""
        />
      </Media>
      <Media body>
        <Row className="profile-row-padding">
          <Col sm="2">{t("common.name")}: </Col>
          <Col sm="auto">
            {props.state.profile.first_name} {props.state.profile.last_name}
          </Col>
        </Row>
        <Row className="profile-row-padding">
          <Col sm="2">{t("common.city")}: </Col>
          <Col sm="auto">{props.state.profile.city}</Col>
        </Row>
        <Row className="profile-row-padding">
          <Col sm="2">{t("common.country")}: </Col>
          <Col sm="auto">{props.state.countryname}</Col>
        </Row>
        {!commonService.isGeneratedEmail(props.state.profile.email) && (
          <Row className="profile-row-padding">
            <Col sm="2">{t("common.email")}: </Col>
            <Col sm="auto">{props.state.profile.email}</Col>
          </Row>
        )}
      </Media>
    </Media>
  );
}
