import "../../styles/page404.scss";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Alert, Col, Container, Row, Table } from "reactstrap";

import smartToniLogin from "../../assets/images/logo/smartToniLogin.png";
import { withTranslation } from "react-i18next";
import { LIGHT_GRAY } from "../../constant/constant";
import moment from "moment";
import _ from "lodash";
import commonService from "../../services/common.service";
class OpenTransactionQualityCheck extends Component<any> {
  render() {
    const { t, data } = this.props;
    return (
      <>
        <section className="mt-3 mb-5">
          {!data.status && (
            <Alert color="danger">{t("reports.checkError")}</Alert>
          )}
          {!!data.status && data?.data?.length === 0 && (
            <Alert color="success ">{t("reports.qualityCheckSuccess")}</Alert>
          )}
          {!!data.status && data?.data?.length !== 0 && (
            <>
              <Alert color="danger">{t("reports.qualityCheckFailed")}</Alert>
              <h5>1. {t("reports.grossRevenues")}</h5>
              <Table bordered striped>
                <thead>
                  <tr className="bg-light">
                    <th>{t("reports.timestamp")}</th>
                    <th>{t("reports.bookingType")}</th>
                    <th>{t("common.currency")}</th>
                    <th>{t("common.amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((innerData: any) => {
                    return (
                      <tr>
                        <td>
                          {moment(innerData.timestamp, "x").format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>{innerData.bookingType}</td>
                        <td>{innerData.currency}</td>
                        <td>
                          {" "}
                          {commonService.displayDigitalAmount(innerData.amount)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          )}
        </section>
      </>
    );
  }
}
export default withTranslation()(withRouter(OpenTransactionQualityCheck));
