import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function taskInterventionRequest(credentials: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION.REQUEST,
    credentials
  };
}

export function taskInterventionSuccess(data: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION.SUCCESS,
    data
  };
}

export function taskInterventionFailure(err: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION.FAILURE,
    err
  };
}

export function taskInterventionAdd(credentials: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_ADD.REQUEST,
    credentials
  };
}

export function taskInterventionAddSuccess(data?: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_ADD.SUCCESS,
    data
  };
}

export function taskInterventionAddFailure(err: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_ADD.FAILURE,
    err
  };
}

export function taskInterventionRemove(credentials: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_REMOVE.REQUEST,
    credentials
  };
}

export function taskInterventionRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_REMOVE.SUCCESS,
    data
  };
}

export function taskInterventionRemoveFailure(err: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_REMOVE.FAILURE,
    err
  };
}

export function taskInterventionUpdate(credentials: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_UPDATE.REQUEST,
    credentials
  };
}

export function taskInterventionUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_UPDATE.SUCCESS,
    data
  };
}

export function taskInterventionUpdateFailure(err: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_UPDATE.FAILURE,
    err
  };
}
