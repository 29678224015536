import "../../../../styles/navigation-left.scss";

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import i18next from "i18next";
import PrintersNavigationComponent from "../settings-navigation/printers-navigation";
import InfractructureNavigationComponent from "../settings-navigation/infrastructure-navigation";
import { withTranslation } from "react-i18next";
import commonService from "../../../../services/common.service";
import { USER_RIGHTS } from "../../../../constant/constant";

class UsersLeftComponent extends Component<any> {
  render() {
    const { t } = this.props;
    return (
      <div className="b-sticky-below-header b-side-navbar">
        <Link to={`/restaurant/${this.props.restaurantuuid}/users`}>
          <Button
            className={
              this.props.display === "users"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("user.users")}
          </Button>
        </Link>
        <Link to={`/restaurant/${this.props.restaurantuuid}/roles`}>
          <Button
            className={
              this.props.display === "roles"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("role.rolesAndRights")}
          </Button>
        </Link>
        <Link to={`/restaurant/${this.props.restaurantuuid}/suppliers`}>
          <Button
            className={
              this.props.display === "suppliers"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("supplier.suppliers")}
          </Button>
        </Link>
        <div className="child-btn-light mb-3 ">
          <PrintersNavigationComponent
            restaurantuuid={this.props.restaurantuuid}
            display={this.props.display}
          />
        </div>
        {commonService.hasPermissionToAccess(
          USER_RIGHTS.VIEW_ITEM_PRICES,
          this.props.restaurantuuid
        ) && (
          <Link to={`/restaurant/${this.props.restaurantuuid}/resource-cost`}>
            <Button
              className={
                this.props.display === "resource-cost"
                  ? "btn-dark mb-3"
                  : "btn-light mb-3"
              }
            >
              {t("resourceCost.resourceCost")}
            </Button>
          </Link>
        )}
        <div className="child-btn-light mb-3 ">
          <InfractructureNavigationComponent
            restaurantuuid={this.props.restaurantuuid}
            display={this.props.display}
          />
        </div>
        <Link to={`/restaurant/${this.props.restaurantuuid}/transport`}>
          <Button
            className={
              this.props.display === "transport"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("task.transportTask")}
          </Button>
        </Link>
        <Link to={`/restaurant/${this.props.restaurantuuid}/apps`}>
          <Button
            className={
              this.props.display === "apps" ? "btn-dark mb-3" : "btn-light mb-3"
            }
          >
            {t("apps.apps")}
          </Button>
        </Link>
        <Link to={`/restaurant/${this.props.restaurantuuid}/devices`}>
          <Button
            className={
              this.props.display === "devices"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("devices.devices")}
          </Button>
        </Link>
      </div>
    );
  }
}

export default withTranslation()(withRouter(UsersLeftComponent));
