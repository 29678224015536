import React from "react";
import { useRnWebviewPost } from "../hooks/useRnWebViewPost";

export type WebViewPostHocProps = {};

export const WebViewPostHoc =
  <P extends WebViewPostHocProps>(
    Component: React.ComponentType<P>
  ): React.SFC<Omit<P, keyof WebViewPostHocProps>> =>
  (props: Pick<P, Exclude<keyof P, keyof WebViewPostHocProps>>) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const postData = useRnWebviewPost();

    return <Component {...props} deviceData={postData} />;
  };
