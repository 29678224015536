import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function contentManagementRequest(credentials: object): ActionModel {
  return {
    type: types.CONTENT_MANAGEMENT.REQUEST,
    credentials,
  };
}

export function contentManagementSuccess(data: object): ActionModel {
  return {
    type: types.CONTENT_MANAGEMENT.SUCCESS,
    data,
  };
}

export function contentManagementFailure(err: object): ActionModel {
  return {
    type: types.CONTENT_MANAGEMENT.FAILURE,
    err,
  };
}

export function contentManagementAdd(credentials: object): ActionModel {
  return {
    type: types.CONTENT_MANAGEMENT_ADD.REQUEST,
    credentials,
  };
}

export function contentManagementAddSuccess(data?: object): ActionModel {
  return {
    type: types.CONTENT_MANAGEMENT_ADD.SUCCESS,
    data,
  };
}

export function contentManagementAddFailure(err: object): ActionModel {
  return {
    type: types.CONTENT_MANAGEMENT_ADD.FAILURE,
    err,
  };
}

export function contentManagementRemove(credentials: object): ActionModel {
  return {
    type: types.CONTENT_MANAGEMENT_REMOVE.REQUEST,
    credentials,
  };
}

export function contentManagementRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.CONTENT_MANAGEMENT_REMOVE.SUCCESS,
    data,
  };
}

export function contentManagementRemoveFailure(err: object): ActionModel {
  return {
    type: types.CONTENT_MANAGEMENT_REMOVE.FAILURE,
    err,
  };
}

export function contentManagementUpdate(credentials: object): ActionModel {
  return {
    type: types.CONTENT_MANAGEMENT_UPDATE.REQUEST,
    credentials,
  };
}

export function contentManagementUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.CONTENT_MANAGEMENT_UPDATE.SUCCESS,
    data,
  };
}

export function contentManagementUpdateFailure(err: object): ActionModel {
  return {
    type: types.CONTENT_MANAGEMENT_UPDATE.FAILURE,
    err,
  };
}
