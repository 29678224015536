import * as React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const DragAndDropWrapper = (props: any) => {
  const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver
      ? "rgb(7 118 124 / 30%)"
      : "rgb(7 118 124 / 15%)",
    padding: "15px 15px 05px 15px",
    minHeight: "60px",
    borderRadius: 6,
  });

  const getItemStyle = (isDragging: any, draggableStyle: any) => {
    return {
      userSelect: "none",
      background: isDragging ? "lightgreen" : "grey",
      ...draggableStyle,
      padding: 0,
    };
  };

  return (
    <DragDropContext
      onDragEnd={props.onDragEnd}
      onDragStart={props.onDragStart}
    >
      <Droppable
        droppableId={props.droppableId ? props.droppableId : "droppable"}
        isDropDisabled={props.isDropDisabled ? props.isDropDisabled : false}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            className={props?.wrapperClasses}
          >
            {!!props.itemsList &&
              props.itemsList.length !== 0 &&
              props.itemsList.map((card: any, index: number) => {
                return (
                  <Draggable
                    key={index}
                    draggableId={"draggable_id_" + index}
                    index={index}
                    isDragDisabled={
                      props.isDragDisabled ? props.isDragDisabled : false
                    }
                  >
                    {(provided, snapshot) => (
                      <div
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={props?.itemClasses}
                      >
                        {React.cloneElement(
                          props.children,
                          props.getChildrenProps(card, index)
                        )}
                      </div>
                    )}
                  </Draggable>
                );
              })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDropWrapper;
