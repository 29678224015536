import '../../styles/email-confirmation.scss';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import smartToniLogin from '../../assets/images/logo/smartToniLogin.png';
import { EmailConfirmationInitState, EmailConfirmationState } from '../../models/email.confirmation.model';
import { emailconfirmationRequest } from '../../redux/actions/email.confirmation.action';
import LoaderComponent from '../loader/loader';

class EmailConfirmationComponent extends Component<any> {
    state: EmailConfirmationState;
    constructor(props: any) {
        super(props);
        this.state = EmailConfirmationInitState;
    }
    render() {
        return (
            <div>
                <LoaderComponent display={!!this.state.isFetching} />
                <Container>
                    <Row>
                        <Col className="emailconfirmation-image"><img className="img-home-fluid" src={smartToniLogin} alt="Logo" /></Col>
                    </Row>
                </Container>
            </div>
        );
    }

    UNSAFE_componentWillReceiveProps(newProps: any) {
        this._setStates('isFetching', newProps.isFetching);
        if (!newProps.isFetching) {
            this.props.history.push("/");
        }
    }

    private _setStates(name: string, value: any): void {
        this.setState({ [name]: value });
    }

    componentDidMount() {
        this.props.emailconfirmation({
            verification_token: this.props.match.params.token,
        });
    }
}

const mapStateToProps: any = (state: any) => {
    return {
        data: state.emailconfirmation.data,
        isFetching: state.emailconfirmation.isFetching,
        failure: state.emailconfirmation.failure,
        isAuthenticated: state.emailconfirmation.isAuthenticated
    };
};

const mapDispatchToProps: object = (dispatch: any) => {
    return {
        emailconfirmation: (credentials: any) => {
            if (!!credentials) {
                dispatch(emailconfirmationRequest(credentials));
            }
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps)(withRouter(EmailConfirmationComponent));
