import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import { faEdit, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import {
  CARD_SELECTION_STATE,
  GRAY,
  RECIPE_STATUS,
  RECIPE_TYPE,
} from "../../../constant/constant";
import {
  RecipeIngredientsProps,
  taskEditType,
} from "../../../models/task.model";
import commonService from "../../../services/common.service";
import {
  CardItemCardComponent,
  CardItemContainer,
} from "../../card-components/card-item";
import { withCardItemSelectionStateManager } from "../../cardItemSelectionManager";
import ConditionalLinkArea from "../../conditionalLinkArea/conditionalLinkArea";
import DragAndDropWrapper from "../../drag-drop-component";
import RecipeIngredientsCard from "./recipe-ingredients-card";
import RecipeIngredientsModal from "./recipe-ingredients-modal";

function RecipeIngredients(props: RecipeIngredientsProps) {
  const {
    addNewIngredientItem,
    cancelIngredientEdit,
    cardItemSelectionData,
    currentRecipeDetails,
    deleteIngredient,
    getQuantityRelatedDetails,
    getRecipeDetails,
    getRecipeDetailsAsync,
    getRecipeIcon,
    getRelatedUnitOptions,
    getSupplierDetails,
    handleIngredientEdit,
    handleIngredientsSave,
    handleIngredientsSorting,
    handleOutputUnitSelection,
    handleTaskIngredientSelection,
    ingredientDraft,
    ingredientsQuantityData,
    labels,
    onEditIngredients,
    otherRecipesLists,
    prepAndRecipeQuantities,
    selectedOutputUnit,
    selectedTaskDetails,
  } = props;

  const { t, i18n } = useTranslation();

  const [choiceItemsList, setChoiceItemsList] = useState<any>({});
  const [ingredients, setIngredients] = useState<any>([]);
  const [isRecipeSelectionModalOpen, setRecipeSelectionModalOpen] =
    useState<boolean>(false);
  const [selectedRecipeDraftDetails, setSelectedRecipeDraftDetails] = useState(
    {}
  );

  useEffect(() => {
    props.ingredients.forEach((ingredient: any) => {
      if (ingredient.type === RECIPE_TYPE.CHOICE) {
        setChoiceItems(ingredient.recipeUuid);
      }
    });

    const ings = _.cloneDeep(props.ingredients);
    setIngredients(ings);
  }, [props.ingredients]);

  const cancelEdit = () => {
    cancelIngredientEdit();
    props.cardItemSelectionData.setSelectedCardItem([], "default");
  };

  const editIngredients = () => {
    props.cardItemSelectionData.setSelectedCardItem(
      [{ type: "recipeIngredients", id: "allIngEdit" }],
      "edit"
    );
    onEditIngredients(props.cardItemSelectionData);
  };

  const getChildrenProps = (card: any, index: any) => {
    let taskDetails: any = {};
    if (_.size(selectedTaskDetails) > 0) {
      taskDetails.isActive = true;
      taskDetails.parentTask = selectedTaskDetails?.parentTask;
      taskDetails.parentIntervention = selectedTaskDetails?.parentIntervention;
      taskDetails.type = selectedTaskDetails?.type;
      taskDetails.ingredient =
        _.cloneDeep(selectedTaskDetails).ingredients?.find(
          (ing: any) => card.recipeUuid === ing.recipe_uuid
        ) || {};
      taskDetails.isEdit = selectedTaskDetails?.isEdit || false;
    }

    if (card?.type === RECIPE_TYPE.INGREDIENT) {
      const recipeDetails: any = getRecipeDetails(card.recipeUuid);
      if (recipeDetails && recipeDetails?.supplier_uuid) {
        const supplierName: string = getSupplierDetails(
          recipeDetails.supplier_uuid
        );
        if (supplierName) card["supplierName"] = supplierName;
      }
    }

    return {
      indx: index,
      key: card.uuid,
      cardItemData: card,
      cardItemId: card.uuid,
      selectedTaskDetails: taskDetails,
      quantityAssignData: ingredientsQuantityData?.[card.recipeUuid],
    };
  };

  const getChoiceItemDetails = (
    recipe: any,
    selection: string,
    isNewCardItem: boolean
  ) => {
    const itemDetails: any = {};
    let selectedItem: any = {};
    const choices: Array<any> = [];
    let items: any = recipe?.items || [];

    if (isNewCardItem && typeof recipe === "string") {
      setChoiceItems(recipe);
    }
    items?.forEach((choice: any) => {
      const recipeDetails = otherRecipesLists?.find(
        (rec: any) => rec.uuid === choice.recipe_uuid
      );
      if (recipeDetails) {
        const choiceDetails = {
          ..._.cloneDeep(recipeDetails),
          ..._.cloneDeep(choice),
          ...{ value: choice.uuid },
        };
        choices.push(choiceDetails);
        if (choice.uuid === selection) {
          selectedItem = choiceDetails;
        }
      }
    });
    if (choices) itemDetails.choiceItemsList = choices;

    if (selectedItem?.recipe_uuid) {
      const defaultRecipeDetails: any = getRecipeDetails(
        selectedItem.recipe_uuid
      );
      if (defaultRecipeDetails) {
        itemDetails.selected = defaultRecipeDetails;
      }
    }
    return itemDetails;
  };

  const getLabelOptions = () => {
    const options: Array<any> = [];
    labels?.forEach((label: any) => {
      const locales: any = commonService.applyLocalization(
        "restaurant",
        "name",
        label?.locales
      );
      options.push({
        label: locales["name"],
        value: label.uuid,
      });
    });
    return options;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const itemsList = reorder(
      ingredients,
      result.source.index,
      result.destination.index
    );
    setIngredients(itemsList);
    handleIngredientsSorting(itemsList);
  };

  const renderCards = () => {
    const showQuantity: boolean = true;
    const quantityError = {
      type: "stop",
      text: t("toast.valueGT0"),
    };
    const MaxQuantityError = {
      type: "stop",
      text: t("toast.maxQtyGreaterThanMin"),
    };
    const DefQuantityError = {
      type: "stop",
      text: t("toast.defaultQtyBtwMinAndMax"),
    };

    return (
      <div className="px-4">
        {!!ingredients.length && (
          <DragAndDropWrapper
            droppableId="recipeIngredientDraggable"
            getChildrenProps={getChildrenProps}
            isDragDisabled={
              cardItemSelectionData.selectionState.mode ===
                CARD_SELECTION_STATE.EDIT || _.size(selectedTaskDetails) > 0
            }
            onDragEnd={onDragEnd}
            onDragStart={() => {
              cardItemSelectionData.deselectCardItem();
            }}
            itemClasses={"mx-n4"}
            itemsList={ingredients}
            wrapperClasses={""}
          >
            <RecipeIngredientsCard
              choiceItems={choiceItemsList}
              deleteIngredient={deleteIngredient}
              getChoiceItemDetails={getChoiceItemDetails}
              getInputReadOnlyLabels={() => {}}
              getQuantityRelatedDetails={getQuantityRelatedDetails}
              getRecipeIcon={getRecipeIcon}
              getRelatedUnitOptions={getRelatedUnitOptions}
              handleIngredientEdit={handleIngredientEdit}
              handleOutputUnitSelection={handleOutputUnitSelection}
              handleTaskIngredientSelection={handleTaskIngredientSelection}
              openRecipeSelectionModal={toggleRecipeSelectionModalOpen}
              otherRecipesLists={otherRecipesLists}
              parentCardItemSelectionData={props.cardItemSelectionData}
              prepAndRecipeQuantities={prepAndRecipeQuantities}
              readOnly={false}
              saveButton={false}
              // saveCard={saveCard}
              selectCard2={selectCard}
              selectedOutputUnit={selectedOutputUnit}
            />
          </DragAndDropWrapper>
        )}
        {!selectedTaskDetails?.isEdit && (
          <div className="mx-n4">
            <RecipeIngredientsCard
              key={"newIngredient"}
              cardItemData={ingredientDraft}
              cardItemId={"newIngredient"}
              choiceItems={choiceItemsList}
              deleteCallback={deleteIngredient}
              getChoiceItemDetails={getChoiceItemDetails}
              getInputReadOnlyLabels={() => {}}
              getQuantityRelatedDetails={getQuantityRelatedDetails}
              getRelatedUnitOptions={getRelatedUnitOptions}
              handleIngredientEdit={handleIngredientEdit}
              handleOutputUnitSelection={handleOutputUnitSelection}
              isNewCardItem={true}
              onDeleteClick={deleteIngredient}
              openRecipeSelectionModal={toggleRecipeSelectionModalOpen}
              otherRecipesLists={otherRecipesLists}
              parentCardItemSelectionData={props.cardItemSelectionData}
              prepAndRecipeQuantities={prepAndRecipeQuantities}
              quantityAssignData={null}
              // saveButton={true}
              saveCard={saveCard}
              // selectCard2={selectCard}
              selectedOutputUnit={selectedOutputUnit}
              selectedRecipeDetails={selectedRecipeDraftDetails}
            />
          </div>
        )}
      </div>
    );
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const saveIngredients = (ingredient?: any) => {
    let hasWarnings: boolean = false;
    ingredients.every((ing: any) => {
      if (Object.keys(ing.warnings).length > 0) {
        hasWarnings = true;
        return false;
      }
      return true;
    });
    if (!hasWarnings) {
      props.cardItemSelectionData.setSelectedCardItem([], "default");
      handleIngredientsSave(ingredient?.recipeUuid ? ingredient : null);
    }
  };

  const saveIngredientSelection = (formData: any) => {
    setSelectedRecipeDraftDetails(formData);
  };

  const saveCard = (ingredient: any) => {
    const ingLength = ingredients.length;
    const newIngredient: any = {
      quantity: ingredient.quantity,
      recipeUuid: ingredient.recipeUuid,
      sort_order: ingLength,
      unitConversion: ingredient.unitConversion,
    };
    if (ingredient.type === RECIPE_TYPE.CHOICE) {
      newIngredient.default_item = ingredient.default_item;
      newIngredient.min_choice_count = ingredient.min_choice_count;
      newIngredient.max_choice_count = ingredient.max_choice_count;
      newIngredient.default_choice_count = ingredient.default_choice_count;
    }
    saveIngredients(newIngredient);
  };

  const selectCard = (taskItem: any, mode: string) => {
    // cardItemSelectionData.selectCardItem();
    // props.cardItemSelectionData.setSelectedCardItem();
  };

  const setChoiceItems = (ingredientId: string) => {
    if (!choiceItemsList[ingredientId]) {
      const choiceItem: any = getRecipeDetailsAsync(ingredientId);
      Promise.resolve(choiceItem)?.then((res: any) => {
        setChoiceItemsList((prevState: any) => {
          const update: any = { ...prevState };
          update[ingredientId] = res;
          return update;
        });
      });
    }
  };

  const toggleRecipeSelectionModalOpen = () => {
    setRecipeSelectionModalOpen(!isRecipeSelectionModalOpen);
  };

  const isAllMetaEdit: boolean =
    cardItemSelectionData.selectionState.selectedItem?.[0]?.id === "allIngEdit";
  const savedIngredients: Array<any> = ingredients?.map(
    (ingredient: any) => ingredient.uuid
  );
  return (
    <>
      <RecipeIngredientsModal
        addNewIngredientItem={addNewIngredientItem}
        allIngredients={otherRecipesLists}
        isOpen={isRecipeSelectionModalOpen}
        labelOptions={getLabelOptions()}
        savedIngredients={savedIngredients}
        saveRecipeSelection={saveIngredientSelection}
        toggleModal={toggleRecipeSelectionModalOpen}
      />
      <div className="d-flex">
        <h4>{t("ingredient.ingredients")}</h4>
        <div className="ml-auto">
          {!isAllMetaEdit ? (
            <ConditionalLinkArea
              type="span"
              activeLink={true}
              hide={selectedTaskDetails?.isEdit}
              className="pointer-cursor"
              onClick={editIngredients}
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.edit")}
            >
              <FontAwesomeIcon
                style={{
                  color: GRAY,
                  fontSize: 20,
                  marginRight: 10,
                  marginTop: 5,
                }}
                icon={faEdit}
              />
            </ConditionalLinkArea>
          ) : (
            <>
              <ConditionalLinkArea
                type="span"
                activeLink={true}
                className="pointer-cursor"
                onClick={saveIngredients}
                data-toggle="tooltip"
                data-placement="left"
                title={t("common.save")}
              >
                <FontAwesomeIcon
                  style={{
                    color: GRAY,
                    fontSize: 20,
                    marginRight: 10,
                    marginTop: 5,
                  }}
                  icon={faSave}
                />
              </ConditionalLinkArea>
              <ConditionalLinkArea
                type="span"
                activeLink={true}
                className="pointer-cursor"
                onClick={cancelEdit}
                data-toggle="tooltip"
                data-placement="left"
                title={t("common.cancel")}
              >
                <FontAwesomeIcon
                  style={{
                    color: GRAY,
                    fontSize: 20,
                    marginRight: 10,
                    marginTop: 5,
                  }}
                  icon={faTimes}
                />
              </ConditionalLinkArea>
            </>
          )}
        </div>
      </div>
      <div className="mx-n4">{renderCards()}</div>
    </>
  );
}

export default withCardItemSelectionStateManager("RecipeIngredients")(
  RecipeIngredients
);
