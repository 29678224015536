export interface TransportState {
  isFetching: boolean;
  name: any;
  uuid: string;
  isUpdated: boolean;
  transportlist: any;
  roomlist: any;
  stationlist: any;
  machinelist: any;
  storagelist: any;
  racklist: any;
  placelist: any;
  deletemodal: boolean;
  addmodal: boolean;
  fromtype: string;
  fromvalue: any;
  totype: string;
  tovalue: any;
  transportmode: any;
  editmodal: boolean;
  alllists: any;
  responsibleStation: string;
}

export const TransportInitState = {
  name: "",
  isFetching: false,
  transportlist: [],
  roomlist: [],
  stationlist: [],
  machinelist: [],
  storagelist: [],
  racklist: [],
  placelist: [],
  uuid: "",
  isUpdated: false,
  deletemodal: false,
  addmodal: false,
  fromtype: "",
  fromvalue: "",
  totype: "",
  tovalue: "",
  transportmode: "",
  editmodal: false,
  alllists: [],
  responsibleStation: "",
};
