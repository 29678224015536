import { ProfileModel } from "../../models/profile.model";
import * as types from "../actions/types";

const initialState: ProfileModel = {
    isAuthenticated: false,
    isFetching: false,
    data: {},
    error: {},
    failure: false
};

export default function profileReducer(
  state = initialState,
  action: any
): ProfileModel {
  switch (action.type) {
    case types.PROFILE.REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        failure: false,
        error: {},
      };
    case types.PROFILE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        data: action,
        failure: false,
        error: {},
      };
    case types.PROFILE.FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        failure: true,
        error: action.err,
      };
    case types.PROFILE_UPDATE.REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        failure: false,
        error: {},
      };
    case types.PROFILE_UPDATE.SUCCESS:
      action.data = state.data.data.languages;

      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.PROFILE_UPDATE.FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
