import React, { useEffect, useState } from "react";
import PrinterService from "../../services/recipe.printer.service";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import commonService from "../../services/common.service";
import { Button, FormGroup, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";

export interface KeyValue {
  key: string;
  value: string;
  resource_type: string;
  resource_id: string;
  uuid?: string;
  action?: string;
  invalid?: boolean;
}

interface KeyValueComponentProps {
  restaurantUuid: string;
  initialData: KeyValue[];
  resourceType: string;
  resourceId: string;
  fetchData: Function;
}

const KeyValueComponent: React.FC<KeyValueComponentProps> = ({
  restaurantUuid,
  initialData,
  resourceType,
  resourceId,
  fetchData,
}) => {
  const { t, i18n } = useTranslation();
  const [keyValues, setKeyValues] = useState<KeyValue[]>([]);

  useEffect(() => {
    addNewRow(
      initialData.map((item: KeyValue) => {
        item.action = "add";
        item.invalid = false;
        return item;
      })
    );
  }, [initialData]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    field: "key" | "value"
  ) => {
    const updatedKeyValues = [...keyValues];
    updatedKeyValues[index] = {
      ...updatedKeyValues[index],
      [field]: e.target.value,
      action: "add",
      invalid: field === "value" && isEmpty(e.target.value),
    };
    setKeyValues(updatedKeyValues);
  };

  const handleDelete = (index: number) => {
    const updatedKeyValues = [...keyValues];
    if (updatedKeyValues[index].uuid) {
      updatedKeyValues[index].action = "remove";
    } else {
      updatedKeyValues.splice(index, 1);
    }
    setKeyValues(updatedKeyValues);
  };

  const handleAddNewRow = () => {
    addNewRow(keyValues);
  };

  const addNewRow = (rows: KeyValue[]) => {
    setKeyValues([
      ...rows,
      {
        key: "",
        value: "",
        resource_type: resourceType,
        resource_id: resourceId,
        action: "add",
      },
    ]);
  };

  const handleSubmit = () => {
    const keyValueData = keyValues
      .map((item: KeyValue) => {
        delete item.invalid;
        delete item.uuid;
        return item;
      })
      .filter((i) => !(i.action === "add" && isEmpty(i.value)));
    const payLoad = {
      credentials: {
        restaurantuuid: restaurantUuid,
        data: { options: keyValueData },
      },
    };

    PrinterService.updateKeyValueOptions(RESTAURANT_REQUEST, payLoad).then(
      (response: any) => {
        if (response.data?.status) {
          commonService.toastService(response.data?.flash, "success");
          fetchData();
        } else {
          commonService.toastService(response.data.flash, "danger");
        }
      }
    );
  };

  return (
    <FormGroup>
      <div className="b-minHeight-100">
        {keyValues.map((pair: any, index: any) => {
          return (
            pair.action !== "remove" && (
              <div className="d-flex align-items-center mb-2" key={index}>
                <div className="col-lg-4 col-xl-4">
                  <Input
                    placeholder={t("printer.enterKey")}
                    min="0"
                    value={pair.key}
                    readOnly={!!pair?.uuid}
                    onChange={(e) => handleInputChange(e, index, "key")}
                  />
                </div>
                <div className="col-lg-7 col-xl-7">
                  <Input
                    type="textarea"
                    placeholder={t("printer.enterVal")}
                    style={{ height: "40px" }}
                    value={pair.value}
                    onChange={(e) => handleInputChange(e, index, "value")}
                    invalid={pair.invalid}
                  />
                </div>
                <div className="col-lg-1 col-xl-1">
                  {index < keyValues.length - 1 && (
                    <FontAwesomeIcon
                      style={{ fontSize: 20 }}
                      icon={faTimes}
                      className="b-cursor-p"
                      onClick={() => handleDelete(index)}
                    />
                  )}
                  {index + 1 === keyValues.length && (
                    <FontAwesomeIcon
                      style={{ fontSize: 20 }}
                      icon={faPlusCircle}
                      className="b-cursor-p"
                      onClick={handleAddNewRow}
                    />
                  )}
                </div>
              </div>
            )
          );
        })}
      </div>
      <div className="text-right my-2">
        <Button color="info" onClick={handleSubmit}>
          {t("common.save")}
        </Button>
      </div>
    </FormGroup>
  );
};

export default KeyValueComponent;
