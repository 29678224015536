import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function userInvitationRequest(credentials: object): ActionModel {
  return {
    type: types.USERS_INVITATION.REQUEST,
    credentials
  };
}

export function userInvitationSuccess(data: object): ActionModel {
  return {
    type: types.USERS_INVITATION.SUCCESS,
    data
  };
}

export function userInvitationFailure(err: object): ActionModel {
  return {
    type: types.USERS_INVITATION.FAILURE,
    err
  };
}

export function userInvitationAdd(credentials: object): ActionModel {
  return {
    type: types.USERS_INVITATION_ADD.REQUEST,
    credentials
  };
}

export function userInvitationAddSuccess(data?: object): ActionModel {
  return {
    type: types.USERS_INVITATION_ADD.SUCCESS,
    data
  };
}

export function userInvitationAddFailure(err: object): ActionModel {
  return {
    type: types.USERS_INVITATION_ADD.FAILURE,
    err
  };
}

export function userInvitationResend(credentials: object): ActionModel {
  return {
    type: types.USERS_INVITATION_RESEND.REQUEST,
    credentials
  };
}

export function userInvitationResendSuccess(data?: object): ActionModel {
  return {
    type: types.USERS_INVITATION_RESEND.SUCCESS,
    data
  };
}

export function userInvitationResendFailure(err: object): ActionModel {
  return {
    type: types.USERS_INVITATION_RESEND.FAILURE,
    err
  };
}

export function userInvitationRemove(credentials: object): ActionModel {
  return {
    type: types.USERS_INVITATION_REMOVE.REQUEST,
    credentials
  };
}

export function userInvitationRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.USERS_INVITATION_REMOVE.SUCCESS,
    data
  };
}

export function userInvitationRemoveFailure(err: object): ActionModel {
  return {
    type: types.USERS_INVITATION_REMOVE.FAILURE,
    err
  };
}
