import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function userLanguageUpdate(credentials: object): ActionModel {
  return {
    type: types.USER_LANGUAGE_UPDATE.REQUEST,
    credentials,
  };
}

export function userLanguageUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.USER_LANGUAGE_UPDATE.SUCCESS,
    data,
  };
}

export function userLanguageUpdateFailure(err: object): ActionModel {
  return {
    type: types.USER_LANGUAGE_UPDATE.FAILURE,
    err,
  };
}
