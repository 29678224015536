import "../../styles/printer.scss";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";

import {
  PrinterInitState,
  PrinterState,
} from "../../models/recipe.printer.model";
import {
  recipePrinterAdd,
  recipePrinterCleanAdd,
  recipePrinterCleanUpdate,
  recipePrinterRemove,
  recipePrinterRequest,
  recipePrinterUpdate,
} from "../../redux/actions/recipe.printer.action";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import RecipePrinterListCard from "../card-components/recipe-printer/list";
import RecipePrinterAddCard from "../card-components/recipe-printer/new";
import RecipePrinterEditCard from "../card-components/recipe-printer/edit";
import LoaderComponent from "../loader/loader";
import SettingsHeaderComponent from "../navigation/navigation-header/settings-header/settings-header";
import UsersLeftComponent from "../../components/navigation/navigation-left/users-navigation/users-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import { withTranslation } from "react-i18next";
import _, { String } from "lodash";
import { printerTemplateRequest } from "../../redux/actions/printer.template.action";
import commonService from "../../services/common.service";
import PrinterService from "../../services/recipe.printer.service";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import { sort, ascend, prop } from "ramda";

class RecipePrinterComponent extends Component<any> {
  state: PrinterState;

  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.state = PrinterInitState;
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.printer({ restaurantuuid: this.props.match.params.uuid });
    this.props.getPrinterTemplate({
      restaurantuuid: this.props.match.params.uuid,
    });
    this._setStates("restaurantuuid", this.props.match.params.uuid);
  }

  render() {
    const { t } = this.props;
    const printerTypesUnsorted = [
      {
        label: t("printer.types.escPosSocket"),
        value: "esc-pos-socket",
      },
      {
        label: t("printer.types.escPosXml"),
        value: "esc-pos-xml",
      },
      {
        label: t("printer.types.pdfSocket"),
        value: "pdf-socket",
      },
      {
        label: t("printer.types.rawTextSocket"),
        value: "raw-text-socket",
      },
      {
        label: t("printer.types.rawTextConsole"),
        value: "raw-text-console",
      },
      {
        label: t("printer.types.pdfIpp"),
        value: "pdf-ipp",
      },
      {
        label: t("printer.types.rawBytes"),
        value: "raw-byte-socket",
      },
    ];
    const printerTypes = sort(ascend(prop("label")), printerTypesUnsorted);
    const templateTypes = [
      {
        label: t("printer.templateTypes.course_call"),
        value: "course_call",
      },
      {
        label: t("printer.templateTypes.guest_group_invoice"),
        value: "guest_group_invoice",
      },
      {
        label: t("printer.templateTypes.meal_complete"),
        value: "meal_complete",
      },
      {
        label: t("printer.templateTypes.order_cancel"),
        value: "order_cancel",
      },
      {
        label: t("printer.templateTypes.order_receive"),
        value: "order_receive",
      },
      {
        label: t("printer.templateTypes.payment"),
        value: "payment",
      },
      {
        label: t("printer.templateTypes.restaurant_closure"),
        value: "restaurant_closure",
      },
      {
        label: t("printer.templateTypes.room_closure"),
        value: "room_closure",
      },
      {
        label: t("printer.templateTypes.staff_report"),
        value: "staff_report",
      },
      {
        label: t("printer.shippingNote"),
        value: "shipping_note",
      },
    ];

    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <SettingsHeaderComponent settings={"settings"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <UsersLeftComponent
                display={"recipePrinter"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <Link
                to="#"
                color="info"
                className="printer-add"
                onClick={this.addPrinterToggle}
              >
                {t("printer.addReceiptPrinters")}
              </Link>
              <div className="white-box mb-3 shadow">
                <h4>{this.props.t("printer.receiptPrinters")}</h4>

                {this.printerList(
                  this.state.printer,
                  printerTypes,
                  templateTypes
                )}
              </div>
            </Col>

            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>

        {/*  Add Printer Modal */}

        <RecipePrinterAddCard
          state={this.state}
          addPrinterToggle={this.addPrinterToggle}
          handleChange={this.handleChange}
          printerTypes={printerTypes}
          savePrinterAdd={this.savePrinterAdd}
        />

        {/*  Edit Printer Modal */}

        <RecipePrinterEditCard
          state={this.state}
          editPrinterToggle={this.editPrinterToggle}
          handleChange={this.handleChange}
          printerTypes={printerTypes}
          savePrinterEdit={this.savePrinterEdit}
        />

        {/* Dlete Printer Modal*/}

        <DeleteModalCard
          isOpen={this.state.deletemodal}
          isFetching={!!this.state.isFetching}
          okDelete={this.okDeletePrinter}
          cancelDelete={this.deletePrinterToggle}
        />
      </div>
    );
  }

  public printerList(
    printerData: any,
    printerTypes: Array<any>,
    templateTypes: Array<any>
  ) {
    let printers;
    const printerTemplates: Array<any> = [];

    this.props.printerTemplate?.forEach((temp: any) => {
      const option: any = {
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          temp.locales
        )["name"],
        value: temp.uuid,
      };
      printerTemplates.push(option);
    });

    let sortedTemplates: Array<any> = _.sortBy(printerTemplates, [
      (option: any) => option.label.toLowerCase(),
    ]);
    sortedTemplates = [
      {
        label: this.props.t("common.select"),
        value: undefined,
      },
      ...sortedTemplates,
    ];
    if (!!printerData && printerData.length > 0) {
      printers = _.sortBy(printerData, (p: any) => p.name.toLowerCase()).map(
        (printer: any, i: number) => {
          return (
            <RecipePrinterListCard
              key={i}
              getKeyValuePairs={this.getKeyValuePairs}
              handleKeyValueChange={this.handleKeyValueChange}
              handleKeyValues={this.handleKeyValues}
              keyValuePairs={this.state.keyValuePairs[printer.uuid] || []}
              newKeyError={this.state.newKeyError[printer.uuid]}
              newKey={this.state.newKey[printer.uuid]}
              newValue={this.state.newValue[printer.uuid]}
              printer={printer}
              printerTemplates={sortedTemplates}
              printerTypes={printerTypes}
              saveKeyValues={this.saveKeyValues}
              saveTemplates={this.saveTemplates}
              setPrinterEditable={this.setPrinterEditable.bind(
                this,
                printer,
                printerTypes
              )}
              setPrinterDelete={this.setPrinterDelete.bind(
                this,
                printer,
                printerTypes
              )}
              templateTypes={templateTypes}
            />
          );
        }
      );
      return printers;
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.props.t("common.noRecords")}
        </div>
      );
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
    }
    if (!!this.state.isUpdated && !newProps.failure && !newProps.isFetching) {
      this.props.printer({ restaurantuuid: this.props.match.params.uuid });
      this._setStates("isUpdated", false);
    }
    if (!!newProps.printers && !newProps.isFetching && !newProps.failure) {
      this._setStates("printer", newProps.printers);
    }
    if (
      !!newProps.printerupdate.status &&
      !!this.state.editmodal &&
      !newProps.isFetching
    ) {
      this.props.recipePrinterUpdateClean();
      this.editPrinterToggle();
    }
    if (
      !!newProps.printeradd.status &&
      !!this.state.addmodal &&
      !newProps.isFetching
    ) {
      this.props.recipePrinterAddClean();
      this.addPrinterToggle();
    }
  }

  // Function binding

  private _setFunctionBindings(): void {
    this.deletePrinterToggle = this.deletePrinterToggle.bind(this);
    this.okDeletePrinter = this.okDeletePrinter.bind(this);
    this.addPrinterToggle = this.addPrinterToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.savePrinterAdd = this.savePrinterAdd.bind(this);
    this.editPrinterToggle = this.editPrinterToggle.bind(this);
    this.savePrinterEdit = this.savePrinterEdit.bind(this);
  }

  getKeyValuePairs = (printerId: string, isUpdate?: boolean) => {
    if (!this.state.keyValuePairs[printerId] || isUpdate) {
      this.setState({ isFetching: true });
      const payLoad = {
        credentials: {
          resource_type: "printer",
          resource_id: printerId,
          restaurantuuid: this.props.match.params.uuid,
        },
      };
      PrinterService.getKeyValueOptions(RESTAURANT_REQUEST, payLoad)
        .then((response: any) => {
          this.setState((prevState: any) => {
            const keyValuePairs: any = prevState.keyValuePairs;
            keyValuePairs[printerId] = response.data;
            return {
              keyValuePairs: keyValuePairs,
              isFetching: false,
            };
          });
        })
        .catch((error: any) => {});
    }
  };

  public handleChange(e: any): void {
    this._setStates(e.target.name, e.target.value);
  }

  public handleKeyValueChange = (
    e: any,
    index: number,
    printerId: string,
    area: string
  ) => {
    if (area === "new") {
      if (e.target.name === "newKey") {
        const newKeys: any = this.state.newKey;
        newKeys[printerId] = e.target.value;
        const newKeysError: any = this.state.newKeyError;
        if (!!e.target.value.trim()) {
          newKeysError[printerId] = false;
        }
        this.setState({ newKey: newKeys, newKeyError: newKeysError });
      } else {
        const newValue: any = this.state.newValue;
        newValue[printerId] = e.target.value;
        this.setState({ newValue });
      }
    } else {
      const keyValueFields: Array<any> = e.target.name.split("_");
      const keyValuePairs: any = _.cloneDeep(this.state.keyValuePairs);
      if (keyValueFields[0] === "newKey") {
        keyValuePairs[printerId][index]["key"] = e.target.value;
      } else {
        keyValuePairs[printerId][index]["value"] = e.target.value;
      }
      this.setState({ keyValuePairs });
    }
  };

  public handleKeyValues = (
    area: string,
    printerId: any,
    index: number
  ): void => {
    if (area === "delete") {
      const keyValuePairs: any = _.cloneDeep(this.state.keyValuePairs);
      const currentKeyValue: any = keyValuePairs[printerId];

      currentKeyValue[index]["isDeleted"] = true;

      this.setState({
        keyValuePairs,
      });
      return;
    }
    if (this.state.newKey[printerId]?.trim() === "") {
      const errorKeys: any = this.state.newKeyError;
      errorKeys[printerId] = true;
      this.setState({ newKeyError: errorKeys });
    } else {
      if (area === "new") {
        const existKey: any = this.state.keyValuePairs?.[printerId]?.find(
          (item: any) => {
            return item.key === this.state.newKey[printerId];
          }
        );
        if (existKey) {
          const errorKeys: any = this.state.newKeyError;
          errorKeys[printerId] = true;
          this.setState({ newKeyError: errorKeys });
        } else {
          const keyValuePairs: Array<any> = _.cloneDeep(
            this.state.keyValuePairs
          );
          keyValuePairs[printerId].push({
            key: this.state.newKey[printerId],
            value: this.state.newValue[printerId],
            isNew: true,
          });

          const errorKeys: any = this.state.newKeyError;
          const newKey: any = this.state.newKey;
          const newValue: any = this.state.newValue;
          errorKeys[printerId] = false;
          newKey[printerId] = "";
          newValue[printerId] = "";
          this.setState({
            keyValuePairs,
            newKey,
            newValue,
            errorKeys,
          });
        }
      }
    }
  };

  public editPrinterToggle(): void {
    this.setState((prevState: any) => ({
      editmodal: !prevState.editmodal,
    }));
  }

  saveKeyValues = (printerId: string) => {
    this.setState({ isFetching: true });
    const keyValues: Array<any> = [];
    this.state.keyValuePairs[printerId].forEach((item: any) => {
      if (!item.key) return;
      const data: any = {
        action: item?.isDeleted ? "remove" : "add",
        key: item.key,
        value: item.value,
        resource_type: "printer",
        resource_id: printerId,
      };
      keyValues.push(data);
    });

    if (
      this.state.newKey[printerId] &&
      this.state.newKey[printerId]?.trim() !== ""
    ) {
      const data: any = {
        action: "add",
        key: this.state.newKey[printerId],
        value: this.state.newValue[printerId] || "",
        resource_type: "printer",
        resource_id: printerId,
      };
      keyValues.push(data);
    }

    const payLoad = {
      credentials: {
        restaurantuuid: this.props.match.params.uuid,
        data: { options: keyValues },
      },
    };

    PrinterService.updateKeyValueOptions(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        if (response.data.status) {
          commonService.toastService(response.data.flash, "success");
          this.getKeyValuePairs(printerId, true);
          const newKey: any = this.state.newKey;
          const newValue: any = this.state.newValue;
          newKey[printerId] = "";
          newValue[printerId] = "";
          this.setState({ newKey, newValue });
        } else {
          commonService.toastService(response.data.flash, "danger");
        }
        this.setState({ isFetching: false });
      })
      .catch((error: any) => {});
  };

  saveTemplates = (templates: any, printerId: string) => {
    const currentPrint: any = this.props.printers.find(
      (print: any) => print.uuid === printerId
    );
    currentPrint["templates"] = templates;
    this.props.updatePrinter({
      uuid: printerId,
      restaurantuuid: this.state.restaurantuuid,
      data: {
        name: currentPrint.name,
        printer_ip: currentPrint.printer_ip,
        printer_port: currentPrint.printer_port,
        printer_type: currentPrint.printer_type,
        options: currentPrint.options,
        templates: templates,
      },
    });
    this._setStates("isUpdated", true);
  };

  public setPrinterEditable(event: any, printerTypes: Array<any>): void {
    const printerType = printerTypes.find(
      (printer: any) => printer.value === event.printer_type
    );

    this.setState({
      uuid: event.uuid,
      name: event.name,
      printerip: event.printer_ip,
      printerport: event.printer_port,
      printerType: printerType,
      templates: event.templates,
    });
    this.editPrinterToggle();
  }

  public savePrinterEdit(): void {
    this.props.updatePrinter({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
      data: {
        name: this.state.name,
        printer_ip: this.state.printerip,
        printer_port: this.state.printerport,
        printer_type: this.state.printerType?.value || "",
        templates: this.state.templates,
      },
    });
    this._setStates("isUpdated", true);
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  public addPrinterToggle(): void {
    this.setState((prevState: any) => ({
      addmodal: !prevState.addmodal,
      name: "",
      printerip: "",
      printerport: "",
      printerType: "",
    }));
  }

  public savePrinterAdd(): void {
    this.props.addPrinter({
      restaurantuuid: this.state.restaurantuuid,
      data: {
        name: this.state.name,
        printer_ip: this.state.printerip,
        printer_port: this.state.printerport,
        printer_type: this.state.printerType?.value || "",
      },
    });

    this._setStates("isUpdated", true);
  }

  public setPrinterDelete(event: any): void {
    this._setStates("uuid", event.uuid);
    this.deletePrinterToggle();
  }

  public deletePrinterToggle(): void {
    this.setState((prevState: any) => ({
      deletemodal: !prevState.deletemodal,
    }));
  }

  public okDeletePrinter(): void {
    this.props.removePrinter({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
    });
    this._setStates("isUpdated", true);
    this.deletePrinterToggle();
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.printerTemplate.isFetching ||
    state.recipeprinter.isFetching ||
    state.recipeprinteradd.isFetching ||
    state.recipeprinterremove.isFetching ||
    state.recipeprinterupdate.isFetching;
  let failure =
    state.printerTemplate.failure ||
    state.recipeprinter.failure ||
    state.recipeprinteradd.failure ||
    state.recipeprinterremove.failure ||
    state.recipeprinterupdate.failure;

  return {
    failure: failure,
    isFetching: isFetching,
    printeradd: state.recipeprinteradd.data,
    printers: state.recipeprinter.data,
    printerTemplate: state.printerTemplate.data,
    printerupdate: state.recipeprinterupdate.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    printer: (credentials: any) => {
      dispatch(recipePrinterRequest(credentials));
    },
    removePrinter: (credentials: any) => {
      dispatch(recipePrinterRemove(credentials));
    },
    addPrinter: (credentials: any) => {
      dispatch(recipePrinterAdd(credentials));
    },
    getPrinterTemplate: (credentials: any) => {
      dispatch(printerTemplateRequest(credentials));
    },
    recipePrinterAddClean: (credentials: any) => {
      dispatch(recipePrinterCleanAdd());
    },
    recipePrinterUpdateClean: (credentials: any) => {
      dispatch(recipePrinterCleanUpdate());
    },
    updatePrinter: (credentials: any) => {
      dispatch(recipePrinterUpdate(credentials));
    },
  };
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(RecipePrinterComponent))
);
