import React, { Component } from "react";
import { withCardItemSelectionState } from "../cardItemSelectionManager";
import {
  CardItemCardComponent,
  CardItemContainer,
} from "../card-components/card-item";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input-field";

import commonService from "../../services/common.service";

import recipeIcon from "../../assets/images/icon/recipeIcon.svg";
import ingredientIcon from "../../assets/images/icon/ingredientsIcon.svg";

import WarningComment, {
  getMandatoryWarning,
} from "../warning-comment/warning-comment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  PHILIPPINE_GRAY,
  RECIPE_STATUS,
  RECIPE_TYPE,
  WHITE,
} from "../../constant/constant";
import {
  faCalendarDay,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faEllipsisV,
  faExclamationTriangle,
  faListOl,
  faReceipt,
  faSortAmountDown,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";
import {
  ServiceSetDetailsRecipesInitState,
  ServiceSetDetailsRecipesState,
} from "../../models/serviceset.details.model";
import _ from "lodash";

class ServiceSetRecipeCard extends Component<any, any> {
  state: ServiceSetDetailsRecipesState;
  hasRecipe: boolean;
  constructor(props: any) {
    super(props);
    this.state = ServiceSetDetailsRecipesInitState;
    this.hasRecipe = false;
  }
  componentDidMount() {
    this._loadValues();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.cardItemSelectionData.selectionState.isBeingEdited !==
      this.props?.cardItemSelectionData.selectionState.isBeingEdited
    ) {
      this._loadValues();
    }
  }
  private _loadValues() {
    if (this.props.isNewCardItem) {
      this.setState(ServiceSetDetailsRecipesInitState);
      this.hasRecipe = false;
    } else {
      const { cardItemData } = this.props;
      const recipe: any = this.props.allRecipes.find((r: any) => {
        return r.uuid === cardItemData.recipe_uuid;
      });
      const relatedUnits: Array<any> =
        commonService.getRelateUnitDropdowns(
          recipe?.output_unit_uuid,
          this.props?.units,
          this.props?.units,
          1,
          cardItemData.recipe_uuid
        ) || [];
      const offeredUnitDetails: any = relatedUnits?.find(
        (u: any) => u.value === cardItemData.offered_unit
      );
      const newStates: ServiceSetDetailsRecipesState = {
        currentRecipe: {
          label: this.getRecipeLabel(cardItemData.recipe_uuid),
          value: cardItemData.recipe_uuid,
        },
        currentCourse: !!cardItemData?.default_course
          ? {
              label: this.getCourseLabel(),
              value: cardItemData.default_course,
            }
          : {},
        currentPrice: commonService.displayDigitalAmount(cardItemData.price),
        currentVat: !!cardItemData?.vat_class_uuid
          ? {
              label: this.getVatLabel(),
              value: cardItemData.vat_class_uuid,
            }
          : {},
        isActive: !!cardItemData.status,
        isAppears: cardItemData.appear_on_bill,
        isSelectable: cardItemData.selectable,
        itemPrices: {},
        warnings: {
          currentRecipe: null,
          offeredUnit: null,
        },
        offeredUnit: cardItemData.offered_unit,
        units: relatedUnits,
      };
      this.setState(newStates);
      if (Object.keys(this.props.allRecipes).length > 0) {
        const [currentRecipe]: any = this.props.allRecipes.filter(
          (recipe: any) => {
            return recipe.uuid === this.props.cardItemData.recipe_uuid;
          }
        );
        if (currentRecipe?.type === 1 || currentRecipe?.type === 4) {
          this.hasRecipe = true;
        }
      }
    }
  }

  private selectThisItem = () => {
    const [currentRecipe]: any = this.props.allRecipes.filter((recipe: any) => {
      return recipe.uuid === this.props.cardItemData.recipe_uuid;
    });
    if (currentRecipe?.type === 1 || currentRecipe?.type === 4) {
      this.hasRecipe = true;
      this.props.getCurrentItemDetails(currentRecipe.uuid, "choice");
    }
    this.props.cardItemSelectionData.selectCardItem();
  };

  render() {
    const { t } = this.props;
    const minimalCashDenomination = JSON.parse(
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
    )?.minimal_cash_denomination;
    const minorUnit = JSON.parse(
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
    )?.minor_units;

    const currencyCode = JSON.parse(
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
    )?.currency_code;

    const restaurantLanguage: any = localStorage.getItem("i18nextLng");

    return (
      <CardItemContainer className={"task-type px-0 mx-n4"}>
        <CardItemCardComponent
          cardItemSelectionData={this.props.cardItemSelectionData}
          className={`task-type assigned ${
            !this.props.cardItemData.status ? "disabled " : ""
          } `}
          cancelCallback={this.cancelCard}
          deleteCallback={this.deleteCard}
          onDeleteClick={this.deleteCard}
          readOnly={false}
          saveCallback={this.saveCard}
          selectCallback={this.selectThisItem}
          saveButton={false}
        >
          {!this.props.cardItemSelectionData.selectionState.isItemSelected && (
            <Row id={"item_" + this.props.cardItemData.recipe_uuid}>
              <Col md={6} xl={4} className="d-flex align-items-center">
                {this.props?.hasSorting && (
                  <span className="mr-2">
                    <FontAwesomeIcon
                      className="b-drag-icon mr-1"
                      style={{
                        color:
                          this.props.cardItemSelectionData.className !== ""
                            ? WHITE
                            : PHILIPPINE_GRAY,
                      }}
                      icon={faEllipsisV}
                    />
                  </span>
                )}
                <span className="mr-2 d-flex ">
                  <FontAwesomeIcon
                    className={`b-drag-icon mr-1 ${
                      this.props.cardItemData.selectable
                        ? "text-success"
                        : "text-secondary  opacity-2"
                    }`}
                    icon={faCheckCircle}
                  />
                  <FontAwesomeIcon
                    className={`b-drag-icon mr-1 ${
                      this.props.cardItemData.appear_on_bill
                        ? "text-success"
                        : "text-secondary opacity-2"
                    }`}
                    icon={faReceipt}
                  />
                </span>
                <span className="mr-2  d-flex">
                  {this.props.cardItemData?.type === RECIPE_TYPE.RECIPE && (
                    <img
                      src={recipeIcon}
                      className={"b-icon-sm b-fill-green"}
                    />
                  )}
                  {this.props.cardItemData?.type === RECIPE_TYPE.INGREDIENT && (
                    <img
                      src={ingredientIcon}
                      className={"b-icon-sm b-fill-green"}
                    />
                  )}
                  {this.props.cardItemData?.type ===
                    RECIPE_TYPE.INGREDIENT_CLASS && (
                    <FontAwesomeIcon
                      className="b-drag-icon mr-1 text-success"
                      icon={faSortAmountDown}
                    />
                  )}
                  {this.props.cardItemData?.type === RECIPE_TYPE.CHOICE && (
                    <FontAwesomeIcon
                      className="b-drag-icon mr-1 text-success"
                      icon={faListOl}
                    />
                  )}
                  {this.props.cardItemData?.type === RECIPE_TYPE.DATE_ITEM && (
                    <FontAwesomeIcon
                      className="b-drag-icon mr-1 text-success"
                      icon={faCalendarDay}
                    />
                  )}
                </span>
                <span className="d-flex">
                  {this.getRecipeLabel(this.props.cardItemData.recipe_uuid)}
                </span>
              </Col>
              <Col md={2} xl={2}>
                {this.getOfferedUnit()}
              </Col>
              <Col md={2} xl={2}>
                {commonService.displayDigitalAmount(
                  this.props.cardItemData?.price
                )}
                <span className="px-2">
                  {!!this.props.cardItemData?.currency
                    ? this.props.cardItemData?.currency
                    : this.props.currencyCode}
                </span>
              </Col>
              <Col md={3} xl={2}>
                {this.getVatLabel()}
              </Col>
              <Col md={2} xl={2}>
                {this.getCourseLabel()}
              </Col>
            </Row>
          )}
          {this.props.cardItemSelectionData.selectionState.isItemSelected && (
            <>
              {this.props.cardItemSelectionData.selectionState.isBeingEdited ? (
                <Row>
                  <Col md={6} lg={6} className="pl-0">
                    <WarningComment
                      data={this.state.warnings.currentRecipe}
                      displayType={"bottom"}
                    >
                      <Select
                        name="currentRecipe"
                        value={this.state.currentRecipe}
                        onChange={(val) =>
                          this.handleSelectChange(val, "currentRecipe")
                        }
                        options={this.getOptions("recipe")}
                        maxMenuHeight={200}
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            color: "#000",
                          }),
                        }}
                      />
                    </WarningComment>
                  </Col>
                  <Col md={6} lg={6}>
                    <Row className="p-0">
                      <Col lg={5} md={5}>
                        {t("common.offeredUnit")}
                      </Col>
                      <Col lg={7} md={7}>
                        <Input
                          type="select"
                          id="offeredUnit"
                          required
                          name="offeredUnit"
                          value={this.state.offeredUnit}
                          onChange={(e) => {
                            this.setState({
                              offeredUnit: e.target.value,
                            });
                          }}
                        >
                          <option value="">{t("unit.selectUnits")} ...</option>
                          {Object.keys(this.state?.units || {}).length > 0 &&
                            this.state?.units.map(
                              (unit: any, index: number) => (
                                <option value={unit.value} key={index}>
                                  {unit.label}
                                </option>
                              )
                            )}
                        </Input>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <>
                  <Row>
                    <Col md={12} lg={12} className="pl-0">
                      <span className="mr-2">
                        {this.props.cardItemData?.type ===
                          RECIPE_TYPE.INGREDIENT && (
                          <img
                            src={ingredientIcon}
                            className={"b-icon-sm b-fill-white"}
                          />
                        )}
                        {this.props.cardItemData?.type ===
                          RECIPE_TYPE.INGREDIENT_CLASS && (
                          <FontAwesomeIcon
                            className="b-drag-icon mr-1 text-light"
                            icon={faSortAmountDown}
                          />
                        )}
                        {this.props.cardItemData?.type ===
                          RECIPE_TYPE.RECIPE && (
                          <img
                            src={recipeIcon}
                            className={"b-icon-sm b-fill-white"}
                          />
                        )}
                        {this.props.cardItemData?.type ===
                          RECIPE_TYPE.CHOICE && (
                          <FontAwesomeIcon
                            className="b-drag-icon mr-1 text-light"
                            icon={faListOl}
                          />
                        )}
                        {this.props.cardItemData?.type ===
                          RECIPE_TYPE.DATE_ITEM && (
                          <FontAwesomeIcon
                            className="b-drag-icon mr-1 text-light"
                            icon={faCalendarDay}
                          />
                        )}
                      </span>

                      {this.getRecipeLabel(this.props.cardItemData.recipe_uuid)}
                    </Col>
                  </Row>
                  <Row className="pt-0 pb-0">
                    <Col lg={2}>{t("common.offeredUnit")}</Col>
                    <Col lg={4}>{this.getOfferedUnit()}</Col>
                  </Row>
                </>
              )}

              <Row>
                <Col md={6} lg={6}>
                  {this.state.isAppears && (
                    <>
                      <Row className="p-0">
                        <Col lg={4}>{t("common.price")}</Col>
                        <Col lg={6}>
                          {this.props.cardItemSelectionData.selectionState
                            .isBeingEdited && this.state.isAppears ? (
                            <span
                              onClick={() => {
                                if (
                                  String(this.state.currentPrice) === "0" ||
                                  String(this.state.currentPrice) === "0.00"
                                ) {
                                  this.handleChange("");
                                }
                              }}
                              onBlur={(e: any) => {
                                if (String(this.state.currentPrice) === "") {
                                  this.handleChange(0);
                                }
                              }}
                            >
                              <CurrencyInput
                                intlConfig={{
                                  locale: restaurantLanguage,
                                  currency: currencyCode,
                                }}
                                className="form-control  d-inline-block"
                                decimalScale={minorUnit}
                                decimalsLimit={minorUnit}
                                id="currentPrice"
                                name="currentPrice"
                                value={this.state.currentPrice}
                                onValueChange={(value, name) => {
                                  this.handleChange(value);
                                }}
                                prefix=" "
                              />
                            </span>
                          ) : (
                            <>
                              {commonService.displayDigitalAmount(
                                this.props.cardItemData?.price
                              )}
                              <span className="px-2">
                                {!!this.props.cardItemData?.currency
                                  ? this.props.cardItemData?.currency
                                  : this.props.currencyCode}
                              </span>
                            </>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>{t("vat.vat")}</Col>
                        <Col lg={6}>
                          {this.props.cardItemSelectionData.selectionState
                            .isBeingEdited ? (
                            <Select
                              name="currentVat"
                              value={this.state.currentVat}
                              onChange={(val) =>
                                this.handleSelectChange(val, "currentVat")
                              }
                              options={this.getOptions("vat")}
                              maxMenuHeight={200}
                              styles={{
                                menu: (provided) => ({
                                  ...provided,
                                  color: "#000",
                                }),
                              }}
                            />
                          ) : (
                            this.getVatLabel()
                          )}
                        </Col>
                      </Row>
                    </>
                  )}

                  {!this.state.isAppears &&
                    !this.props.cardItemSelectionData.selectionState
                      .isBeingEdited && (
                      <>
                        <Row className="p-0">
                          <Col lg={4}>{t("common.price")}</Col>
                          <Col lg={6}>
                            {commonService.displayDigitalAmount(
                              this.props.cardItemData?.price
                            )}
                            <span className="px-2">
                              {!!this.props.cardItemData?.currency
                                ? this.props.cardItemData?.currency
                                : this.props.currencyCode}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4}>{t("vat.vat")}</Col>
                          <Col lg={6}>{this.getVatLabel()}</Col>
                        </Row>
                      </>
                    )}

                  <Row className="p-0">
                    <Col lg={4}>{t("serviceSet.defaultCourse")}</Col>
                    <Col lg={6}>
                      {" "}
                      {this.props.cardItemSelectionData.selectionState
                        .isBeingEdited ? (
                        <Select
                          name="currentCourse"
                          value={this.state.currentCourse}
                          onChange={(val) =>
                            this.handleSelectChange(val, "currentCourse")
                          }
                          options={this.getOptions("course")}
                          maxMenuHeight={200}
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              color: "#000",
                            }),
                          }}
                        />
                      ) : (
                        this.getCourseLabel()
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col md={6} lg={{ size: 5 }}>
                  <Row className="p-0">
                    <Col sm={12} className="mb-2 pb-2">
                      <FormGroup check inline>
                        <Label check className="b-cursor-p">
                          {this.props.cardItemSelectionData.selectionState
                            .isBeingEdited ? (
                            <Input
                              type="checkbox"
                              name="isActive"
                              checked={this.state.isActive}
                              onChange={this.handleCheckboxChange}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className={`b-drag-icon mr-1 ${
                                this.props.cardItemData.status
                                  ? "text-success"
                                  : "text-secondary opacity-2"
                              }`}
                              icon={faCheck}
                            />
                          )}
                          {t("common.active")}
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm={12} className="mb-2 pb-2">
                      <FormGroup check inline>
                        <Label check className="b-cursor-p">
                          {this.props.cardItemSelectionData.selectionState
                            .isBeingEdited ? (
                            <Input
                              type="checkbox"
                              name="isSelectable"
                              checked={this.state.isSelectable}
                              onChange={this.handleCheckboxChange}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className={`b-drag-icon mr-1 ${
                                this.props.cardItemData.selectable
                                  ? "text-success"
                                  : "text-secondary  opacity-2"
                              }`}
                              icon={faCheckCircle}
                            />
                          )}
                          {t("common.selectable")}
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm={12} className="mb-2 pt-2">
                      <FormGroup check inline>
                        <Label check className="b-cursor-p">
                          {this.props.cardItemSelectionData.selectionState
                            .isBeingEdited ? (
                            <Input
                              type="checkbox"
                              name="isAppears"
                              checked={this.state.isAppears}
                              onChange={this.handleCheckboxChange}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className={`b-drag-icon mr-1 ${
                                this.props.cardItemData.appear_on_bill
                                  ? "text-success"
                                  : "text-secondary opacity-2"
                              }`}
                              icon={faReceipt}
                            />
                          )}
                          {t("serviceSet.appearsOnBill")}
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {this.getRecipeRelatedItems(this.props.currentItemDetails)
                .length > 0 && (
                <Card className="mt-4 text-dark">
                  <CardHeader>
                    {t("serviceSet.itemsRelatedSelection")}
                  </CardHeader>
                  <CardBody>
                    {this.getRecipeRelatedItems(
                      this.props.currentItemDetails
                    ).map((item: any, index: number) => {
                      const isAdded = this.props.checkItemsInTheServiceSet(
                        item.recipe_uuid
                      );
                      return (
                        <div
                          className="border-secondary border-bottom p-2"
                          key={index}
                        >
                          <Row className="py-0">
                            <Col
                              lg={5}
                              xl={4}
                              className="d-flex align-items-center"
                            >
                              <FontAwesomeIcon
                                className={`mr-1 ${
                                  !isAdded ? "text-warning" : "text-success"
                                }`}
                                icon={
                                  !isAdded
                                    ? faExclamationTriangle
                                    : faCheckSquare
                                }
                              />
                              <div>
                                {isAdded && (
                                  <a
                                    href={"item_" + item.recipe_uuid}
                                    onClick={this.handleScroll}
                                  >
                                    {this.getRecipeLabel(item.recipe_uuid)}
                                  </a>
                                )}
                                {!isAdded && (
                                  <span>
                                    {this.getRecipeLabel(item.recipe_uuid)}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col className="d-flex align-items-center position-relative">
                              {!isAdded && (
                                <>
                                  <span className="badge badge-warning mr-3 p-2">
                                    {t("serviceSet.itemNotInServiceSet")}
                                  </span>
                                  <span
                                    className="d-flex align-items-center"
                                    onClick={() => {
                                      if (
                                        String(
                                          this.state.itemPrices?.recipe_uuid
                                        ) === "0" ||
                                        String(
                                          this.state.itemPrices?.recipe_uuid
                                        ) === "0.00"
                                      ) {
                                        this.handlePriceChange(
                                          "",
                                          item.recipe_uuid
                                        );
                                      }
                                    }}
                                  >
                                    {t("common.price")}

                                    <CurrencyInput
                                      intlConfig={{
                                        locale: restaurantLanguage,
                                        currency: currencyCode,
                                      }}
                                      className="b-width-75 mx-2"
                                      id={"itemPrice_" + item.recipe_uuid}
                                      name={"itemPrice_" + item.recipe_uuid}
                                      value={this.state.itemPrices?.recipe_uuid}
                                      onValueChange={(value: any) =>
                                        this.handlePriceChange(
                                          value,
                                          item.recipe_uuid
                                        )
                                      }
                                      decimalScale={minorUnit}
                                      decimalsLimit={minorUnit}
                                      prefix=" "
                                    />
                                  </span>

                                  <Button
                                    color="info"
                                    onClick={() =>
                                      this.saveItem(item.recipe_uuid)
                                    }
                                  >
                                    {t("serviceSet.addToServiceSet")}
                                  </Button>
                                </>
                              )}
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </CardBody>
                </Card>
              )}
            </>
          )}
        </CardItemCardComponent>
      </CardItemContainer>
    );
  }

  cancelCard = () => {
    this.hasRecipe = false;
    this.props.cardItemSelectionData.cardItem.isNewCardItem
      ? this.props.cardItemSelectionData.deselectCardItem()
      : this.props.cardItemSelectionData.selectCardItem();
  };
  deleteCard = () => {
    this.props.onDeleteCard(this.props.cardItemId);
  };

  getCourseLabel = () => {
    if (
      this.props.cardItemData.type !== "newCard" &&
      Object.keys(this.props.courseList).length !== 0 &&
      !!this.props.cardItemData.default_course
    ) {
      const [currentRecipe] = this.props.courseList.filter((course: any) => {
        return course.uuid === this.props.cardItemData.default_course;
      });
      return this.getLocale(currentRecipe.locales);
    }

    return "";
  };
  getLocale = (locale: any) => {
    return commonService.applyLocalization("restaurant", "name", locale)[
      "name"
    ];
  };

  getOptions = (area: string) => {
    let options: Array<object> = [];
    let itemArray: Array<object> = [];
    if (area === "recipe") {
      itemArray = this.props?.allRecipes?.filter(
        (recipe: any) => recipe.status === RECIPE_STATUS.PUBLISHED
      );
    } else if (area === "vat") {
      itemArray = this.props.vatList;
    } else if (area === "course") {
      itemArray = this.props.courseList;
    }

    Object.keys(itemArray).length !== 0 &&
      itemArray.map((recipe: any) => {
        options.push({
          value: recipe.uuid,
          label: recipe?.recipeName
            ? recipe?.recipeName
            : this.getLocale(recipe.locales),
          type: recipe?.type || undefined,
        });
      });
    options = _.sortBy(options, [
      (option: any) => option?.label?.toLowerCase(),
    ]);
    options.unshift({
      value: "",
      label: this.props.t("common.select"),
    });
    return options;
  };

  getRecipeLabel = (recipeUuid: string) => {
    if (!!recipeUuid && !_.isEmpty(this.props?.allRecipes)) {
      const [currentRecipe] = this.props?.allRecipes?.filter((recipe: any) => {
        return recipe.uuid === recipeUuid;
      });
      if (currentRecipe?.recipeName) return currentRecipe.recipeName;
      else if (!!currentRecipe?.locales) {
        return this.getLocale(currentRecipe.locales);
      }
    }
    return "";
  };

  getRecipeRelatedItems = (itemData: any) => {
    let recipeItems: Array<any> = [];
    if (this.hasRecipe) {
      if (itemData?.type === 4) {
        recipeItems = itemData?.items;
      } else {
        itemData?.ingredients?.map((item: any) => {
          const [currentRecipe] = this.props?.allRecipes?.filter(
            (recipe: any) => {
              return recipe.uuid === item.recipe_uuid && recipe.type === 4;
            }
          );
          if (!!currentRecipe) {
            currentRecipe.recipe_uuid = currentRecipe.uuid;
            recipeItems.push(currentRecipe);
          }
        });
      }
    }
    return recipeItems;
  };

  getVatLabel = () => {
    if (
      this.props.cardItemData.type !== "newCard" &&
      Object.keys(this.props.vatList).length !== 0 &&
      !!this.props.cardItemData.vat_class_uuid
    ) {
      const [currentVat] = this.props.vatList.filter((vat: any) => {
        return vat.uuid === this.props.cardItemData.vat_class_uuid;
      });
      const vatLabel = this.getLocale(currentVat?.locales);
      return vatLabel + " (" + currentVat?.percentage + "%)";
    }

    return "";
  };

  getOfferedUnit = (): string => {
    if (
      this.props.cardItemData.type !== "newCard" &&
      Object.keys(this.props?.units).length !== 0 &&
      !!this.props.cardItemData.offered_unit
    ) {
      const relatedUnits: Array<any> =
        commonService.getRelateUnitDropdowns(
          this.props.cardItemData?.recipe_output_unit,
          this.props?.units,
          this.props?.units,
          1,
          this.props.cardItemData?.recipe_uuid
        ) || [];
      const offeredUnitDetails: any = relatedUnits?.find(
        (u: any) => u.value === this.props.cardItemData.offered_unit
      );
      return offeredUnitDetails?.label;
    }

    return "";
  };

  handleChange = (value: any) => {
    this.setState({
      currentPrice: value,
    });
  };

  handleCheckboxChange = (elem: any) => {
    let name = elem.target.name;
    let currentState: boolean = false;
    if (name === "isAppears") {
      currentState = this.state.isAppears;
      if (!elem.target.checked) {
        this.setState({
          currentPrice: 0,
          currentVat: {
            value: "",
            label: this.props.t("common.select"),
          },
        });
      }
    } else if (name === "isSelectable") currentState = this.state.isSelectable;
    else if (name === "isActive") currentState = this.state.isActive;
    this.setState({
      [name]: !currentState,
    });
  };

  handlePriceChange = (value: any, itemId: string) => {
    const itemPrices: any = { ...this.state.itemPrices };
    itemPrices[itemId] = value;
    this.setState({
      itemPrices,
    });
  };

  handleSelectChange = (value: any, area: string) => {
    this.setState({ [area]: value });
    if (area === "currentRecipe") {
      const [currentRecipe]: any = this.props.allRecipes.filter(
        (recipe: any) => {
          return recipe.uuid === value.value;
        }
      );
      const warnings: any = { ...this.state.warnings };
      warnings.currentRecipe = getMandatoryWarning(value.value);
      this.setState({ warnings });
      if (currentRecipe?.type === 1 || currentRecipe?.type === 4) {
        this.hasRecipe = true;
        this.props.getCurrentItemDetails(currentRecipe.uuid, "choice");
      } else {
        this.hasRecipe = false;
        this.props.getCurrentItemDetails(currentRecipe?.uuid, "null");
      }
      if (currentRecipe) {
        this.setState({
          offeredUnit: currentRecipe.output_unit_uuid,
          units:
            commonService.getRelateUnitDropdowns(
              currentRecipe?.output_unit_uuid,
              this.props?.units,
              this.props?.units,
              1,
              currentRecipe?.uuid
            ) || [],
        });
      }
    }
  };

  handleScroll = (elem: any) => {
    elem.preventDefault();
    const itemId: any = elem.currentTarget.getAttribute("href");
    document.getElementById(itemId)?.scrollIntoView();
  };

  saveCard = () => {
    let minorUnit = JSON.parse(
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
    )?.minor_units;
    if (
      typeof this.state.currentRecipe.value === "undefined" ||
      this.state.currentRecipe.value === ""
    ) {
      const warnings: any = { ...this.state.warnings };
      warnings.currentRecipe = getMandatoryWarning("");
      this.setState({ warnings });
      return;
    }
    if (this.state.offeredUnit === "") {
      return;
    }
    const currentRecipe: any = this.props.allRecipes.find(
      (r: any) => r.uuid === this.state.currentRecipe.value
    );
    const formData: object = {
      recipe_uuid: this.state.currentRecipe.value,
      price:
        (this.state.currentPrice * Math.pow(10, minorUnit))?.toFixed(
          minorUnit
        ) || 0,
      vat_class_uuid: this.state.currentVat.value,
      selectable: this.state.isSelectable,
      appear_on_bill: this.state.isAppears,
      default_course: this.state.currentCourse.value,
      status: !!this.state.isActive ? 1 : 0,
      uuid: this.props.isNewCardItem
        ? "newCard_" + Math.random()
        : this.props.cardItemData.uuid,
      type: this.state.currentRecipe?.type || undefined,
      offered_unit: this.state.offeredUnit,
      recipe_output_unit: currentRecipe?.output_unit_uuid,
    };
    this.props.onSaveCard(formData, this.props.cardItemId, "mainItem");
  };

  saveItem = (itemId: string) => {
    const minorUnit = JSON.parse(
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
    )?.minor_units;
    const itemPrice = this.state.itemPrices[itemId] || 0;
    const currentRecipe: any = this.props.allRecipes.find(
      (r: any) => r.uuid === this.state.currentRecipe.value
    );
    const formData: object = {
      appear_on_bill: true,
      default_course: undefined,
      price: (parseFloat(itemPrice) * Math.pow(10, minorUnit))?.toFixed(
        minorUnit
      ),
      recipe_uuid: itemId,
      selectable: false,
      status: 1,
      uuid: "newCard_" + Math.random(),
      vat_class_uuid: undefined,
      type: this.state.currentRecipe?.type || undefined,
      offered_unit: this.state.offeredUnit,
      recipe_output_unit: currentRecipe?.output_unit_uuid,
    };
    this.props.onSaveCard(formData, "newCard", "subItem");
  };
}

export default withTranslation()(
  withRouter(
    withCardItemSelectionState("ItemListCard", 1)(ServiceSetRecipeCard)
  )
);
