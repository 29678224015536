import { call, put, takeLatest } from 'redux-saga/effects';

import { USER_VERIFY } from '../config/api.config';
import * as types from '../redux/actions/types';
import { userverificationFailure, userverificationSuccess } from '../redux/actions/user.verification.action';
import handleResponse from '../services/response.service';
import userverification from '../services/user.verification.service';

const userverificationCall = (credentials: object) => userverification.userverification(USER_VERIFY, credentials);

function* handleUserVerifictionRequest(credentials: object) {
    try {
        let result;
        result = yield call(userverificationCall, credentials);
        yield handleUserVerifiction200(result);
    } catch (error) {
        throw error;
    }
}
const handleUserVerifiction200 = function* handleUserVerifiction200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(userverificationSuccess(result.data));
        handleResponse(result)
    } else {
        yield put(userverificationFailure(result.data));
        handleResponse(result);
    }
}


export default function* userVerificationSaga() {
    yield takeLatest(types.USER_VERIFICATION.REQUEST, handleUserVerifictionRequest);
}
