import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import {
    taskInterventionAddFailure,
    taskInterventionAddSuccess,
    taskInterventionFailure,
    taskInterventionRemoveFailure,
    taskInterventionRemoveSuccess,
    taskInterventionSuccess,
    taskInterventionUpdateFailure,
    taskInterventionUpdateSuccess,
} from '../redux/actions/task.intervention.action';
import * as types from '../redux/actions/types';
import handleResponse from '../services/response.service';
import intervention from '../services/task.intervention.service';

const taskInterventionCall = (params: any) => intervention.taskIntervention(RESTAURANT_REQUEST, params);
const addTaskInterventionCall = (params: any) => intervention.addTaskIntervention(RESTAURANT_REQUEST, params);
const removeTaskInterventionCall = (params: any) => intervention.removeTaskIntervention(RESTAURANT_REQUEST, params);
const updateTaskInterventionCall = (params: any) => intervention.updateTaskIntervention(RESTAURANT_REQUEST, params);

// Task Intervention Get

function* handleTaskInterventionRequest(params: object) {
    try {
        let result;
        result = yield call(taskInterventionCall, params);
        yield handleTaskIntervention200(result);
    } catch (error) {
        throw error;
    }
}
const handleTaskIntervention200 = function* handleTaskIntervention200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskInterventionSuccess(result.data));
    } else {
        yield put(taskInterventionFailure(result.data));
        handleResponse(result);
    }
}

function* handleTaskInterventionAdd(params: object) {
    try {
        let result;
        result = yield call(addTaskInterventionCall, params);
        yield handleTaskInterventionAdd200(result);
    } catch (error) {
        throw error;
    }
}

const handleTaskInterventionAdd200 = function* handleTaskInterventionAdd200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskInterventionAddSuccess(result.data));
    } else {
        yield put(taskInterventionAddFailure(result.data));
    }
    handleResponse(result);
}

function* handleTaskInterventionRemove(params: object) {
    try {
        let result;
        result = yield call(removeTaskInterventionCall, params);
        yield handleTaskInterventionRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handleTaskInterventionRemove200 = function* handleTaskInterventionRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskInterventionRemoveSuccess(result.data));
    } else {
        yield put(taskInterventionRemoveFailure(result.data));
    }
    handleResponse(result);
}

function* handleTaskInterventionUpdate(params: object) {
    try {
        let result;
        result = yield call(updateTaskInterventionCall, params);
        yield handleTaskInterventionUpdate200(result);
    } catch (error) {
        throw error;
    }
}

const handleTaskInterventionUpdate200 = function* handleTaskInterventionUpdate200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskInterventionUpdateSuccess(result.data));
    } else {
        yield put(taskInterventionUpdateFailure(result.data));
    }
    handleResponse(result);
}

export default function* taskInterventionSaga() {
    yield takeLatest(types.TASK_INTERVENTION.REQUEST, handleTaskInterventionRequest);
    yield takeLatest(types.TASK_INTERVENTION_ADD.REQUEST, handleTaskInterventionAdd);
    yield takeLatest(types.TASK_INTERVENTION_REMOVE.REQUEST, handleTaskInterventionRemove);
    yield takeLatest(types.TASK_INTERVENTION_UPDATE.REQUEST, handleTaskInterventionUpdate);
}
