import { call, put, takeLatest } from "redux-saga/effects";

import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  restaurantDetailFailure,
  restaurantDetailSuccess,
} from "../redux/actions/restaurant.detail.action";
import * as types from "../redux/actions/types";
import restaurantDetail from "../services/restaurant.detail.service";
import handleResponse from "../services/response.service";

const restaurantDetailCall = (params: object) =>
  restaurantDetail.restaurantDetail(RESTAURANT_REQUEST, params);

function* handleRestaurantDetailRequest(params: object) {
  try {
    let result;
    result = yield call(restaurantDetailCall, params);
    yield handleRestaurantDetail200(result);
  } catch (error) {
    throw error;
  }
}

const handleRestaurantDetail200 = function* handleRestaurantDetail200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(restaurantDetailSuccess(result.data));
  } else {
    yield put(restaurantDetailFailure(result.data));
    handleResponse(result);
  }
};

export default function* restaurantDetailSaga() {
  yield takeLatest(
    types.RESTAURANT_DETAIL.REQUEST,
    handleRestaurantDetailRequest
  );
}
