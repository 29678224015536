import apiCall from './api.call.config';

async function printerProtocol(url: string, params: any) {
    try {
        return await apiCall.get(url + '/' + params.credentials.restaurantuuid + '/printer-protocol');
    } catch (e) {
        throw e;
    }
}

async function addPrinterProtocol(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url + '/' + params.credentials.restaurantuuid + '/printer-protocol', params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function updatePrinterProtocol(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.put(url + '/' + params.credentials.restaurantuuid + '/printer-protocol/'
            + params.credentials.uuid, params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function removePrinterProtocol(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.remove(url + '/' + params.credentials.restaurantuuid + '/printer-protocol/' + params.credentials.uuid);
    } catch (e) {
        throw e;
    }
}

export default {
    printerProtocol,
    updatePrinterProtocol,
    addPrinterProtocol,
    removePrinterProtocol
}
