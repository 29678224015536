import {
  faChevronDown,
  faChevronRight,
  faEdit,
  faPlusCircle,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _, { map } from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import {
  Button,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { DARK_GRAY, GRAY, GREEN } from "../../../constant/constant";
import {
  PrinterListCardInitState,
  PrinterListCardState,
} from "../../../models/printer.model";
import "../../../styles/printer.scss";

class RecipePrinterListCard extends Component<any, any> {
  state: PrinterListCardState;

  constructor(props: any) {
    super(props);
    this.state = PrinterListCardInitState;
  }

  componentDidMount() {
    const selectedTemplates: any = {};
    this.props.printer?.templates?.forEach((template: any) => {
      const templateOption = this.props.printerTemplates.find(
        (t: any) => t.value === template.template
      );
      selectedTemplates[template.type] = templateOption;
    });
    this.setState({
      selectedTemplates,
      shippingNote: this.props.printer?.shipping_note || "",
    });
  }

  getPrinterType = () => {
    const printer: any = this.props.printerTypes.find(
      (p: any) => p.value === this.props.printer.printer_type
    );
    return printer?.label || "";
  };

  handleTab = (tabId: string) => {
    this.setState({
      activeTab: tabId,
    });
    if (tabId === "keyValuePairs")
      this.props.getKeyValuePairs(this.props.printer.uuid);
  };

  handleTemplateChange = (option: any, name: string) => {
    const selectedTemplates: any = _.cloneDeep(this.state.selectedTemplates);
    selectedTemplates[name] = option;
    this.setState({
      selectedTemplates,
    });
  };

  save = () => {
    const selectedTemplates: any = [];
    Object.keys(this.state.selectedTemplates).forEach((key: any) => {
      const option: any = {
        type: key,
        template: this.state.selectedTemplates[key]?.value || "",
      };
      selectedTemplates.push(option);
    });

    this.props.saveTemplates(selectedTemplates, this.props.printer.uuid);
  };

  toggleCollapse = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  render() {
    const { t } = this.props;
    return (
      <div className="mb-4  p-3">
        <h6 className="pl-0 mb-2" style={{ color: DARK_GRAY, paddingLeft: 10 }}>
          {this.props.printer.name}
        </h6>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={`b-cursor-p  ${
                this.state.activeTab === "details" ? "active" : ""
              }`}
              onClick={() => this.handleTab("details")}
            >
              {t("common.details")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`b-cursor-p  ${
                this.state.activeTab === "templates" ? "active" : ""
              }`}
              onClick={() => this.handleTab("templates")}
            >
              {t("printer.manageTemplates")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`b-cursor-p  ${
                this.state.activeTab === "keyValuePairs" ? "active" : ""
              }`}
              onClick={() => this.handleTab("keyValuePairs")}
            >
              {t("printer.keyValuePairs")}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="details" className="pt-3">
            <div className="d-flex justify-content-between">
              <div className="flex-fill">
                <Row className="app-row-padding"></Row>
                <Row className="app-row-padding">
                  <Col sm={{ size: 2, offset: 1 }} xs="4">
                    {t("printer.printerIP")}
                  </Col>
                  <Col sm="4" xs="4">
                    {this.props.printer.printer_ip}
                  </Col>
                </Row>
                <Row className="app-row-padding">
                  <Col sm={{ size: 2, offset: 1 }} xs="4">
                    {t("printer.printerPort")}
                  </Col>
                  <Col sm="4" xs="4">
                    {this.props.printer.printer_port}
                  </Col>
                </Row>
                <Row className="app-row-padding">
                  <Col sm={{ size: 2, offset: 1 }} xs="4">
                    {t("printer.type")}
                  </Col>
                  <Col sm="4" xs="4">
                    {this.getPrinterType()}
                  </Col>
                </Row>
              </div>
              <div>
                <span
                  onClick={this.props.setPrinterEditable}
                  className="pointer-cursor"
                  data-toggle="tooltip"
                  data-placement="left"
                  title={t("common.edit")}
                >
                  <FontAwesomeIcon
                    style={{ color: GREEN, fontSize: 20 }}
                    icon={faEdit}
                  />
                </span>
                <span
                  style={{ margin: 10 }}
                  onClick={this.props.setPrinterDelete}
                  className="pointer-cursor"
                  data-toggle="tooltip"
                  data-placement="left"
                  title={t("common.delete")}
                >
                  <FontAwesomeIcon
                    style={{ color: GRAY, fontSize: 20 }}
                    icon={faTrashAlt}
                  />
                </span>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="templates" className="pt-3">
            <Form className="col">
              {this.props.templateTypes?.map((template: any, index: number) => {
                return (
                  <FormGroup
                    row
                    key={this.props.printer.uuid + "_" + index}
                    className="py-0"
                  >
                    <Label for="exampleEmail" lg={4}>
                      {template.label}
                    </Label>
                    <Col sm={8}>
                      <Select
                        name={template.value}
                        value={
                          this.state.selectedTemplates?.[template.value] || null
                        }
                        onChange={(e) =>
                          this.handleTemplateChange(e, template.value)
                        }
                        placeholder={t("printer.selectTemplateType")}
                        options={this.props.printerTemplates}
                        maxMenuHeight={200}
                        className=" flex-fill"
                        styles={{
                          menu: (provided: any) => ({
                            ...provided,
                            color: "#000",
                          }),
                        }}
                      />
                    </Col>
                  </FormGroup>
                );
              })}

              <div className="text-right my-2">
                <Button color="info" onClick={() => this.save()}>
                  {t("common.save")}
                </Button>
              </div>
            </Form>
          </TabPane>
          <TabPane tabId="keyValuePairs" className="pt-3">
            <FormGroup>
              <div className="b-minHeight-100">
                {this.props.keyValuePairs?.map((item: any, index: any) => {
                  return (
                    !item?.isDeleted && (
                      <div
                        className="d-flex align-items-center mb-2"
                        key={index}
                      >
                        <Input
                          name={"newKey_" + index}
                          placeholder={t("printer.enterKey")}
                          min="0"
                          value={item.key}
                          readOnly={!item?.isNew}
                          onChange={(e: any) =>
                            this.props.handleKeyValueChange(
                              e,
                              index,
                              this.props.printer.uuid,
                              "edit"
                            )
                          }
                          className="mr-2 w-25"
                        />
                        <Input
                          name={"newValue_" + index}
                          placeholder={t("printer.enterVal")}
                          min="0"
                          value={item.value}
                          onChange={(e: any) =>
                            this.props.handleKeyValueChange(
                              e,
                              index,
                              this.props.printer.uuid,
                              "edit"
                            )
                          }
                          className="mr-2 w-75"
                        />
                        <FontAwesomeIcon
                          style={{ fontSize: 20 }}
                          icon={faTimes}
                          className="b-cursor-p"
                          onClick={() =>
                            this.props.handleKeyValues(
                              "delete",
                              this.props.printer.uuid,
                              index
                            )
                          }
                        />
                      </div>
                    )
                  );
                })}
                <div className="d-flex align-items-center">
                  <Input
                    name="newKey"
                    placeholder={t("printer.enterKey")}
                    min="0"
                    value={this.props.newKey}
                    onChange={(e: any) =>
                      this.props.handleKeyValueChange(
                        e,
                        0,
                        this.props.printer.uuid,
                        "new"
                      )
                    }
                    className={`mr-2 w-25 ${
                      this.props.newKeyError ? "border-danger" : ""
                    }`}
                  />
                  <Input
                    name="newValue"
                    placeholder={t("printer.enterVal")}
                    min="0"
                    value={this.props.newValue}
                    onChange={(e: any) =>
                      this.props.handleKeyValueChange(
                        e,
                        0,
                        this.props.printer.uuid,
                        "new"
                      )
                    }
                    className="mr-2 w-75"
                  />
                  <FontAwesomeIcon
                    style={{ fontSize: 20 }}
                    icon={faPlusCircle}
                    onClick={() =>
                      this.props.handleKeyValues("new", this.props.printer.uuid)
                    }
                  />
                </div>
              </div>
              <div className="text-right my-2">
                <Button
                  color="info"
                  onClick={() =>
                    this.props.saveKeyValues(this.props.printer.uuid)
                  }
                >
                  {t("common.save")}
                </Button>
              </div>
            </FormGroup>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
export default withTranslation()(RecipePrinterListCard);
