import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import * as types from '../redux/actions/types';
import handleResponse from '../services/response.service';
import intervention from '../services/task.intervention.ingredients.service';
import { taskInterventionIngredientAddSuccess, taskInterventionIngredientAddFailure } from '../redux/actions/task.intervention.ingredient.action ';

const addTaskInterventionIngredientCall = (params: any) => intervention.addTaskInterventionIngredients(RESTAURANT_REQUEST, params);
function* handleTaskInterventionIngredientAdd(params: object) {
    try {
        let result;
        result = yield call(addTaskInterventionIngredientCall, params);
        yield handleTaskInterventionIngredientAdd201(result);
    } catch (error) {
        throw error;
    }
}
const handleTaskInterventionIngredientAdd201 = function* handleTaskInterventionIngredientAdd201(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 201) {
        yield put(taskInterventionIngredientAddSuccess(result.data));
    } else {
        yield put(taskInterventionIngredientAddFailure(result.data));
    }
    handleResponse(result);
}

export default function* taskInterventionIngredientSaga() {
    yield takeLatest(types.TASK_INTERVENTION_INGREDIENT_ADD.REQUEST, handleTaskInterventionIngredientAdd);
}
