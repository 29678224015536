import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { create } from "apisauce";
import ModalFactory from "react-modal-promise";

import LoaderComponent from "../../components/loader/loader";
import handleResponse from "../../services/response.service";
import ConceptHeaderComponent from "../../components/navigation/navigation-header/concept-header/concept-header";
import RestaurantNavComponent from "../../components/navigation/navigation-left/restaurant-navigation/restaurant-navigation";
import NavigationRightComponent from "../../components/navigation/navigation-right/navigation-right";
import PeriodicityAddModal from "../../components/periodicity/periodicityAddModal";
import { BASE_URL } from "../../config/app.config";
import { ACCESS_TOKEN } from "../../constant/constant";
import { OpenDeleteModal } from "../../components/card-components/delete-card/delete-modal";

import {
  periodicityAdd,
  periodicityRemove,
  periodicityRequest,
  periodicityUpdate,
} from "../../redux/actions/periodicity.action";
import {
  PeriodicityState,
  PeriodicityInitState,
} from "../../models/periodicity.model";
import moment from "moment";
import commonService from "../../services/common.service";
import { transportRequest } from "../../redux/actions/transport.action";
import { withTranslation } from "react-i18next";
import ImageTitleComponentWrapper from "../../components/card-components/image-title-component/imageTitleComponentWrapper";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

const api = create({
  baseURL: BASE_URL,
});

class PeriodicityPage extends Component<any> {
  state: PeriodicityState;
  constructor(props: any) {
    super(props);
    this.state = PeriodicityInitState;
    api.setHeaders({
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Cache-Control": "no-cache",
      Locale: localStorage.getItem("i18nextLng") || "en-gb",
      "content-type": "application/json",
    });
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.loadMetaData();
    this.props.periodicity({ restaurantuuid: this.props.match.params.uuid });
    this.setState({ restaurantuuid: this.props.match.params.uuid });
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      (this.state.addItemData !== {} &&
        prevState.addItemData !== this.state.addItemData) ||
      (this.state.updatePeriodicityData !== {} &&
        prevState.updatePeriodicityData !== this.state.updatePeriodicityData)
    ) {
      this.setState({
        isAddItemModalOpen: false,
        itemModalData: {
          name: "",
          date: "",
        },
        selectedItemId: null,
        updatePeriodicityData: {},
      });
      this.props.periodicity({ restaurantuuid: this.props.match.params.uuid });
    }
    if (
      prevState.isDeleted &&
      Object.keys(this.state.removeItemData).length !== 0 &&
      this.state.removeItemData !== prevState.removeItemData
    ) {
      this.setState({ isDeleted: false, removeItemData: {} });
      this.props.periodicity({ restaurantuuid: this.props.match.params.uuid });
    }
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    let update: any = {};
    if (nextProps.isFetching !== prevState.isFetching) {
      update.isFetching = nextProps.isFetching;
    }
    if (nextProps.items !== prevState.items) {
      update.items = nextProps.items;
    }
    if (
      nextProps.addItemData &&
      nextProps.addItemData !== prevState.addItemData
    ) {
      update.addItemData = nextProps.addItemData;
    }
    if (
      Object.keys(nextProps.removeItemData).length !== 0 &&
      nextProps.removeItemData !== prevState.removeItemData
    ) {
      update.removeItemData = nextProps.removeItemData;
    }
    if (
      !!nextProps.updatePeriodicityData &&
      nextProps.updatePeriodicityData !== prevState.updatePeriodicityData
    ) {
      update.updatePeriodicityData = nextProps.updatePeriodicityData;
    }
    return update;
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <div>
          <ModalFactory />
          <LoaderComponent display={this.state.isFetching} />

          <PeriodicityAddModal
            formData={this.state.itemModalData}
            isModalOpen={this.state.isAddItemModalOpen}
            handleCancel={this.handleItemUpdateCancel}
            handleSubmit={this.handleSubmit}
          />
          <div className="container-fluid">
            <ConceptHeaderComponent concept={"concept"} />
            <Row className="main light-theme">
              <Col xl={2} lg={3} className="hide-left-max">
                <RestaurantNavComponent
                  display={"date-item"}
                  restaurantuuid={this.props.match.params.uuid}
                />
              </Col>
              <Col xl={8} lg={6}>
                <ImageTitleComponentWrapper
                  elementId={this.state.elementId}
                  extraNavButtons={this.getExtraNavButtons}
                  getExtraDetails={this.getExtraDetails}
                  cardType="title"
                  hasDetails={true}
                  hasTag={false}
                  itemData={this.state.items}
                  itemsDetails={this.state.itemsDetails}
                  labels={this.state.labels}
                  onAddNewItem={this.handleAddItemClick}
                  onItemDelete={this.handleItemDelete}
                  onItemEdit={this.handleItemEdit}
                  onMoveToDetailPage={this.moveToDetailPage}
                  pageText={this.getPageTitle()}
                  setItemId={this.setItemId}
                />
              </Col>
              <Col xl={2} lg={3}>
                <NavigationRightComponent />
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }

  public getExtraDetails = (data: any) => {
    return (
      <div className="d-flex">
        <div>
          {this.props.t("date.startDate")} {" : "}{" "}
          {moment(data.start_date).format("DD-MM-YYYY")}
        </div>
      </div>
    );
  };

  public getExtraNavButtons = () => {
    return (
      <Link
        to={`/restaurant/${this.props.match.params.uuid}/date-item`}
        className="mx-2"
      >
        <Button color={"info"} size={"sm"}>
          {this.props.t("common.backToDateItem")}
        </Button>
      </Link>
    );
  };

  public getPageTitle = () => {
    return {
      addButtonTitle: this.props.t("periodicity.addPeriodicity"),
      pageTitle: this.props.t("periodicity.periodicities"),
    };
  };
  public handleAddItemClick = (data: any) => {
    this.setState({ isAddItemModalOpen: !this.state.isAddItemModalOpen });
  };

  public handleItemDelete = (itemId: any) => {
    OpenDeleteModal().then(() => {
      this.setState({ isDeleted: true });
      this.props.removePeriodicity({
        restaurantuuid: this.props.match.params.uuid,
        uuid: itemId,
      });
    });
  };

  public handleItemEdit = (itemId: any) => {
    const [selectedItem]: any = this.state.items.filter((item: any) => {
      return item.uuid === itemId;
    });
    const name = commonService.applyLocalization(
      "restaurant",
      "name",
      selectedItem.locales
    ).name;

    this.setState({
      isAddItemModalOpen: true,
      itemModalData: {
        name: name,
        date: new Date(selectedItem.start_date),
        isEdit: true,
      },
      selectedItemId: itemId,
    });
  };
  public handleItemUpdateCancel = () => {
    this.setState({
      isAddItemModalOpen: false,
      itemModalData: {
        name: "",
        date: "",
        isEdit: false,
      },
      selectedItemId: "",
    });
  };

  public handleSubmit = (data: any) => {
    if (!!this.state.selectedItemId) {
      this.props.updatePeriodicity({
        data,
        restaurantuuid: this.props.match.params.uuid,
        uuid: this.state.selectedItemId,
        items: [],
      });
    } else {
      this.props.addPeriodicity({
        data,
        restaurantuuid: this.props.match.params.uuid,
      });
    }
  };

  public loadMetaData = () => {
    api
      .get(
        "/api/1.0/restaurant/" +
          this.props.match.params.uuid +
          "/get??sort=name:desc&components=unit, storage, label, room",
        {}
      )
      .then((response: any) => {
        if (!!response.status) {
          let options: Array<any> = [];
          const globalUnits = response.data?.unit.filter((data: any) => {
            return data.recipe_uuid === undefined;
          });
          response.data?.label.map((labl: any) => {
            options.push({ value: labl.uuid, label: labl.name });
          });
          this.setState({
            globalUnits,
            labelOptions: options,
            labels: response.data.label ? response.data.label : [],
            rooms: response.data.room ? response.data.room : [],
            storage: response.data.storage ? response.data.storage : [],
            units: response.data.unit ? response.data.unit : [],
          });
        } else {
          handleResponse(response);
        }
      });
  };

  public moveToDetailPage = (itemId: string) => {
    this.props.history.push(
      `/restaurant/${this.props.match.params.uuid}/periodicity/${itemId}/details`
    );
  };

  public setItemId = (itemId: string) => {
    this.setState({
      elementId: itemId,
    });
  };
}
const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.periodicity.isFetching ||
    state.periodicityAdd.isFetching ||
    state.periodicityRemove.isFetching ||
    state.periodicityUpdate.isFetching;
  let failure =
    state.periodicity.failure ||
    state.periodicityAdd.failure ||
    state.periodicityRemove.failure ||
    state.periodicityUpdate.failure;

  let states: any = {
    addItemData: state.periodicityAdd.data,
    failure: failure,
    isFetching: isFetching,
    items: state.periodicity.data,
    removeItemData: state.periodicityRemove.data,
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    updatePeriodicityData: state.periodicityUpdate.data,
  };
  return states;
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    addPeriodicity: (credentials: any) => {
      dispatch(periodicityAdd(credentials));
    },
    periodicity: (credentials: any) => {
      dispatch(periodicityRequest(credentials));
    },
    removePeriodicity: (credentials: any) => {
      dispatch(periodicityRemove(credentials));
    },
    updatePeriodicity: (credentials: any) => {
      dispatch(periodicityUpdate(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(PeriodicityPage))
);
