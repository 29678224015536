import React, { Component } from 'react';

import LabelsComponent from '../../components/labels/labels';

class LabelsPage extends Component {

    render() {
        return (
            <LabelsComponent/>
        );
    }
}
export default LabelsPage;
