import { combineReducers } from "redux";
import changePasswordReducer from "./change.password.reducer";
import recipeItemsReducer from "./recipe.items.reducer";
import { ChatInitReducer, ChatUpdateReducer } from "./chat.reducer";
import {
  ChannelsReducer,
  ChannelsDetailReducer,
  ChannelsAddRestaurantsReducer,
  RestaurantsChannelsListReducer,
} from "./channels.reducer";
import choiceListReducer from "./choice.list.reducer";
import choiceUpdateReducer from "./choice.item.update.reducer";
import componentReducer from "./component.reducer";
import conversionAddReducer from "./conversion.add.reducer";
import conversionEditReducer from "./conversion.edit.reducer";
import conversionDeleteReducer from "./conversion.delete.reducer";

import emailConfirmationReducer from "./email.confirmation.reducer";
import addEventReducer from "./event.add.reducer";
import eventListReducer from "./event.list.reducer";
import eventReducer from "./event.reducer";
import updateEventReducer from "./event.update.reducer";
import forgotpasswordReducer from "./forgotpassword.reducer";
import ingredientClassListReducer from "./ingredient.class.list.reducer";
import ingredientsAddReducer from "./ingredients.add.reducer";
import ingredientsDetailReducer, {
  ingredientCostReducer,
  ingredientCostUpdateReducer,
} from "./ingredients.detail.reducer";
import ingredientsReducer from "./ingredients.reducer";
import ingredientsRemoveReducer from "./ingredients.remove.reducer";
import ingredientsUpdateReducer from "./ingredients.update.reducer";
import inventoryReducer from "./inventory.reducer";
import labelsAddReducer from "./labels.add.reducer";
import labelsReducer from "./labels.reducer";
import labelsRemoveReducer from "./labels.remove.reducer";
import labelsUpdateReducer from "./labels.update.reducer";
import { languageReducer, languageUpdateReducer } from "./language.reducer";
import { currencyReducer } from "./currency.reducer";
import { userLanguageUpdateReducer } from "./user.language.reducer";

import loginReducer from "./login.reducer";
import machineAddReducer from "./machine.add.reducer";
import machineReducer from "./machine.reducer";
import machineRemoveReducer from "./machine.remove.reducer";
import machineUpdateReducer from "./machine.update.reducer";
import printerAddReducer from "./printer.add.reducer";
import printerMessageReducer from "./printer.message.reducer";
import printerMessageRemoveAllReducer from "./printer.message.remove.all.reducer";
import printerMessageRemoveReducer from "./printer.message.remove.reducer";
import printerProtocolAddReducer from "./printer.protocol.add.reducer";
import printerProtocolReducer from "./printer.protocol.reducer";
import printerProtocolRemoveReducer from "./printer.protocol.remove.reducer";
import printerProtocolUpdateReducer from "./printer.protocol.update.reducer";
import printerReducer from "./printer.reducer";
import printerRemoveReducer from "./printer.remove.reducer";
import printerUpdateReducer from "./printer.update.reducer";
import recipePrinterReducer from "./recipe.printer.reducer";
import recipePrinterAddReducer from "./recipe.printer.add.reducer";
import recipePrinterUpdateReducer from "./recipe.printer.update.reducer";
import recipePrinterRemoveReducer from "./recipe.printer.remove.reducer";
import profileReducer from "./profile.reducer";
import profileSettingsReducer from "./profile.settings.reducer";
import recipeAddReducer from "./recipe.add.reducer";
import recipeAllReducer from "./recipe.all.reducer";
import recipeCopyReducer from "./recipe.copy.reducer";
import recipeDetailsReducer from "./recipe.details.reducer";
import recipeEditReducer from "./recipe.edit.reducer";
import recipeIngredientAddReducer from "./recipe.ingredient.add.reducer";
import recipeIngredientReducer from "./recipe.ingredient.reducer";
import recipeIngredientRemoveReducer from "./recipe.ingredient.remove.reducer";
import recipeIngredientUpdateReducer from "./recipe.ingredient.update.reducer";
import recipePublishReducer from "./recipe.publish..reducer";
import recipeReducer from "./recipe.reducer";
import recipeRemoveReducer from "./recipe.remove.reducer";
import recipeUpdateReducer from "./recipe.update.reducer";
import registrationReducer from "./registration.reducer";
import resetpasswordReducer from "./resetpassword.reducer";
import restaurantAddReducer from "./restaurant.add.reducer";
import restaurantReducer from "./restaurant.reducer";
import restaurantRemoveReducer from "./restaurant.remove.reducer";
import restaurantUpdateReducer from "./restaurant.update.reducer";
import restaurantDetailReducer from "./restaurant.detail.reducer";
import roleAddReducer from "./role.add.reducer";
import roleRemoveReducer from "./role.remove.reducer";
import roleRightAddReducer from "./role.right.add.reducer";
import roleRightReducer from "./role.right.reducer";
import roleUpdateReducer from "./role.update.reducer";
import rolesReducer from "./roles.reducers";
import roomAddReducer from "./room.add.reducer";
import roomReducer from "./room.reducer";
import roomRemoveReducer from "./room.remove.reducer";
import roomUpdateReducer from "./room.update.reducer";
import serverReducer from "./servers.reducer";
import serverUpdateReducer from "./servers.update.reducer";
import settingsAddReducer from "./settings.add.reducer";
import settingsReducer from "./settings.reducer";
import stationsAddReducer from "./stations.add.reducer";
import serviceSetTimeAddReducer from "./serviceset.time.add.reducer";
import stationsOperationModeReducer from "./stations.operation.mode.update.reducer";
import stationsReducer from "./stations.reducer";
import stationsRemoveReducer from "./stations.remove.reducer";
import stationsUpdateReducer from "./stations.update.reducer";
import storageAddReducer from "./storage.add.reducer";
import storagePlaceAddReducer from "./storage.place.add.reducer";
import storagePlaceRemoveReducer from "./storage.place.delete.reducer";
import storagePlaceUpdateReducer from "./storage.place.update.reducer";
import storageRackAddReducer from "./storage.rack.add.reducer";
import storageRackReducer from "./storage.rack.reducer";
import storageRackRemoveReducer from "./storage.rack.remove.reducer";
import storageRackUpdateReducer from "./storage.rack.update.reducer";
import storageReducer from "./storage.reducer";
import storageRemoveReducer from "./storage.remove.reducer";
import storageUpdateReducer from "./storage.update.reducer";
import {
  tableAddReducer,
  tableRemoveReducer,
  tableReducer,
  tableUpdateReducer,
} from "./table.reducer";
import {
  tagAddReducer,
  tagAssignReducer,
  tagRemoveReducer,
  tagsReducer,
  tagUpdateReducer,
} from "./tags.reducer";
import taskAddReducer from "./task.add.reducer";
import taskIngredientAddReducer from "./task.ingredient.add.reducer";
import taskIngredientReducer from "./task.ingredient.reducer";
import taskIngredientRemoveReducer from "./task.ingredient.remove.reducer";
import taskIngredientUpdateReducer from "./task.ingredient.update.reducer";
import taskInterventionAddReducer from "./task.intervention.add.reducer";
import taskInterventionIngredientAddReducer from "./task.intervention.ingredient.add.reducer";
import taskInterventionReducer from "./task.intervention.reducer";
import taskInterventionRemoveReducer from "./task.intervention.remove.reducer";
import taskInterventionStepAddReducer from "./task.intervention.step.add.reducer";
import taskInterventionStepReducer from "./task.intervention.step.reducer";
import taskInterventionStepRemoveReducer from "./task.intervention.step.remove.reducer";
import taskInterventionStepUpdateReducer from "./task.intervention.step.update.reducer";
import taskInterventionUpdateReducer from "./task.intervention.update.reducer";
import taskReducer from "./task.reducer";
import taskRemoveReducer from "./task.remove.reducer";
import taskStepAddReducer from "./task.step.add.reducer";
import taskStepRemoveReducer from "./task.step.remove.reducer";
import taskStepUpdateReducer from "./task.step.update.reducer";
import taskUpdateReducer from "./task.update.reducer";
import transportAddReducer from "./transport.add.reducer";
import transportReducer from "./transport.reducer";
import transportRemoveReducer from "./transport.remove.reducer";
import transportUpdateReducer from "./transport.update.reducer";
import translationReducer from "./translation.reducer";
import unitsAddReducer from "./units.add.reducer";
import unitsReducer from "./units.reducer";
import unitsRemoveReducer from "./units.remove.reducer";
import unitsUpdateReducer from "./units.update.reducer";
import uploadReducer from "./upload.reducer";
import userInvitationAddReducer from "./user.add.invitation.reducer";
import userInvitationReducer from "./user.invitation.reducer";
import userInvitationResendReducer from "./user.invitation.resend.reducer";
import userInvitationRemoveReducer from "./user.remove.invitation.reducer";
import userRoleAddReducer from "./user.role.add.reducer";
import userVerificationReducer from "./user.verification.reducer";
import usersReducer from "./users.reducer";
import usersRemoveReducer from "./users.remove.reducer";
import { databaseReducer } from "../../services/database.service";
import serviceSetReducer, {
  serviceSetUpdateChannelReducer,
} from "./serviceset.reducer";
import { SERVICESET_UPDATE } from "../actions/types";
import serviceSetUpdateReducer from "./serviceset.update.reducer";
import serviceSetAddReducer from "./serviceset.add.reducer";
import serviceSetRemoveReducer from "./serviceset.remove.reducer";
import middlewareReducer from "../../middleware/middleware.reducer";
import supportReducer from "./support.reducer";
import vatAddReducer from "./vat.add.reducer";
import vatReducer from "./vat.reducer";
import vatRemoveReducer from "./vat.remove.reducer";
import vatUpdateReducer from "./vat.update.reducer";
import qrCodeReducer, {
  getRoomDetailsReducer,
  qrCodeAddReducer,
  qrCodeRemoveReducer,
  qrCodeUpdateReducer,
} from "./qrcode.reducer";
import {
  periodicityReducer,
  periodicityAddReducer,
  periodicityDetailReducer,
  periodicityRemoveReducer,
  periodicityUpdateReducer,
} from "./periodicity.reducer";
import {
  paymentProviderReducer,
  paymentProviderAddReducer,
  paymentProviderDetailReducer,
  paymentProviderRemoveReducer,
  paymentProviderUpdateReducer,
} from "./payment.provider.reducer";

import {
  printerTemplateAddReducer,
  printerTemplateDetailReducer,
  printerTemplateReducer,
  printerTemplateRemoveReducer,
  printerTemplateUpdateReducer,
} from "./printer.template.reducer";
import {
  courseListReducer,
  courseListUpdateReducer,
} from "./course.list.reducer";
import dateItemListReducer from "./date.item.reducer";
import serviceSetDetailsReducer, {
  serviceSetUpdateRecipesReducer,
} from "./serviceset.details.reducer";
import {
  contentManagementAddReducer,
  contentManagementReducer,
  contentManagementRemoveReducer,
  contentManagementUpdateReducer,
} from "./content.management.reducer";
import {
  reportOpenTransactionQlty,
  reportPaymentTransactionQlty,
  reportPrintGuestGroup,
  reportRestaurantClosure,
  reportRoomClosure,
} from "./report.reducer";
import recipeSubstituteReducer from "./recipe.substitute.reducer";
import recipeSubstituteAddReducer from "./recipe.substitute.add.reducer";
import recipeSubstituteUpdateReducer from "./recipe.substitute.update.reducer";
import recipeSubstituteRemoveReducer from "./recipe.substitute.remove.reducer";
import {
  guestGroupReducer,
  guestGroupUpdateReducer,
} from "./guest.group.reducer";
import assembleTaskLocalesReducer from "./assemble-task-locales.reducer";
const rootReducer = combineReducers({
  chatInit: ChatInitReducer,
  chatUpdate: ChatUpdateReducer,
  channels: ChannelsReducer,
  ChannelDetails: ChannelsDetailReducer,
  ChannelsRestaurants: ChannelsAddRestaurantsReducer,
  RestaurantsChannelsList: RestaurantsChannelsListReducer,
  login: loginReducer,
  registration: registrationReducer,
  emailconfirmation: emailConfirmationReducer,
  profile: profileReducer,
  profilesettings: profileSettingsReducer,
  changepassword: changePasswordReducer,

  allRecipeItems: recipeItemsReducer,
  choices: choiceListReducer,
  choiceUpdate: choiceUpdateReducer,

  dateItem: dateItemListReducer,

  restaurant: restaurantReducer,
  restaurantupdate: restaurantUpdateReducer,
  restaurantadd: restaurantAddReducer,
  restaurantremove: restaurantRemoveReducer,

  stations: stationsReducer,
  stationsupdate: stationsUpdateReducer,
  stationsremove: stationsRemoveReducer,
  stationsadd: stationsAddReducer,
  operationmode: stationsOperationModeReducer,
  settings: settingsReducer,
  settingsadd: settingsAddReducer,
  assembleTaskLocales: assembleTaskLocalesReducer,

  machine: machineReducer,
  machineupdate: machineUpdateReducer,
  machineremove: machineRemoveReducer,
  machineadd: machineAddReducer,

  ingredientClass: ingredientClassListReducer,
  ingredientCost: ingredientCostReducer,
  ingredientCostUpdate: ingredientCostUpdateReducer,
  ingredients: ingredientsReducer,
  ingredientsupdate: ingredientsUpdateReducer,
  ingredientsremove: ingredientsRemoveReducer,
  ingredientsadd: ingredientsAddReducer,
  ingredientsDetail: ingredientsDetailReducer,
  units: unitsReducer,
  unitsupdate: unitsUpdateReducer,
  unitsadd: unitsAddReducer,
  unitsremove: unitsRemoveReducer,
  conversionAdd: conversionAddReducer,
  conversionEdit: conversionEditReducer,
  conversionDelete: conversionDeleteReducer,

  serviceset: serviceSetReducer,
  servicesetupdate: serviceSetUpdateReducer,
  servicesetadd: serviceSetAddReducer,
  servicesetremove: serviceSetRemoveReducer,
  serviceSetDetails: serviceSetDetailsReducer,
  serviceSetRecipeUpdate: serviceSetUpdateRecipesReducer,
  serviceSetTimeUpdate: serviceSetTimeAddReducer,
  serviceSetChannelUpdate: serviceSetUpdateChannelReducer,

  servers: serverReducer,
  serverupdate: serverUpdateReducer,

  upload: uploadReducer,

  support: supportReducer,

  printer: printerReducer,
  printerupdate: printerUpdateReducer,
  printeradd: printerAddReducer,
  printerremove: printerRemoveReducer,

  recipeprinter: recipePrinterReducer,
  recipeprinterupdate: recipePrinterUpdateReducer,
  recipeprinteradd: recipePrinterAddReducer,
  recipeprinterremove: recipePrinterRemoveReducer,

  printermessage: printerMessageReducer,
  printermessageremove: printerMessageRemoveReducer,
  printermessageremoveall: printerMessageRemoveAllReducer,

  printerprotocol: printerProtocolReducer,
  printerprotocolupdate: printerProtocolUpdateReducer,
  printerprotocoladd: printerProtocolAddReducer,
  printerprotocolremove: printerProtocolRemoveReducer,

  users: usersReducer,
  usersremove: usersRemoveReducer,
  userroleadd: userRoleAddReducer,

  userinvitation: userInvitationReducer,
  userinvitationresend: userInvitationResendReducer,
  userinvitationadd: userInvitationAddReducer,
  userinvitationremove: userInvitationRemoveReducer,

  userverification: userVerificationReducer,

  forgotpassword: forgotpasswordReducer,

  resetpassword: resetpasswordReducer,

  recipepublish: recipePublishReducer,
  recipeedit: recipeEditReducer,
  recipe: recipeReducer,
  recipeupdate: recipeUpdateReducer,
  reciperemove: recipeRemoveReducer,
  recipeadd: recipeAddReducer,
  recipedetails: recipeDetailsReducer,
  recipeingredient: recipeIngredientReducer,
  recipeingredientadd: recipeIngredientAddReducer,
  recipeingredientupdate: recipeIngredientUpdateReducer,
  recipeingredientremove: recipeIngredientRemoveReducer,
  allrecipe: recipeAllReducer,
  recipecopy: recipeCopyReducer,

  task: taskReducer,
  taskupdate: taskUpdateReducer,
  taskadd: taskAddReducer,
  taskremove: taskRemoveReducer,

  inventory: inventoryReducer,

  storage: storageReducer,
  storageadd: storageAddReducer,
  storageupdate: storageUpdateReducer,
  storageremove: storageRemoveReducer,
  taskstepadd: taskStepAddReducer,
  taskstepupdate: taskStepUpdateReducer,
  taskstepremove: taskStepRemoveReducer,

  storagerack: storageRackReducer,
  storagerackadd: storageRackAddReducer,
  storagerackupdate: storageRackUpdateReducer,
  storagerackremove: storageRackRemoveReducer,
  storageplaceadd: storagePlaceAddReducer,
  storageplaceupdate: storagePlaceUpdateReducer,
  storageplaceremove: storagePlaceRemoveReducer,

  taskingredient: taskIngredientReducer,
  taskingredientadd: taskIngredientAddReducer,
  taskingredientremove: taskIngredientRemoveReducer,
  taskingredientupdate: taskIngredientUpdateReducer,

  role: rolesReducer,
  roleupdate: roleUpdateReducer,
  roleadd: roleAddReducer,
  roleremove: roleRemoveReducer,
  component: componentReducer,
  roleright: roleRightReducer,
  rolerightadd: roleRightAddReducer,

  taskintervention: taskInterventionReducer,
  taskinterventionadd: taskInterventionAddReducer,
  taskinterventionremove: taskInterventionRemoveReducer,
  taskinterventionupdate: taskInterventionUpdateReducer,

  taskinterventionstep: taskInterventionStepReducer,
  taskinterventionstepadd: taskInterventionStepAddReducer,
  taskinterventionstepremove: taskInterventionStepRemoveReducer,
  taskinterventionstepupdate: taskInterventionStepUpdateReducer,
  taskinterventioningredientadd: taskInterventionIngredientAddReducer,

  eventlist: eventListReducer,
  addevent: addEventReducer,
  event: eventReducer,
  updateevent: updateEventReducer,

  room: roomReducer,
  roomupdate: roomUpdateReducer,
  roomadd: roomAddReducer,
  roomremove: roomRemoveReducer,

  labels: labelsReducer,
  labelsupdate: labelsUpdateReducer,
  labelsadd: labelsAddReducer,
  labelsremove: labelsRemoveReducer,

  transport: transportReducer,
  transportupdate: transportUpdateReducer,
  transportadd: transportAddReducer,
  transportremove: transportRemoveReducer,

  database: databaseReducer,
  middleware: middlewareReducer,

  tags: tagsReducer,
  tagAdd: tagAddReducer,
  tagAssign: tagAssignReducer,
  tagRemove: tagRemoveReducer,
  tagUpdate: tagUpdateReducer,

  translation: translationReducer,
  tables: tableReducer,
  tableAdd: tableAddReducer,
  tableRemove: tableRemoveReducer,
  tableUpdate: tableUpdateReducer,

  languages: languageReducer,
  currency: currencyReducer,
  languageUpdate: languageUpdateReducer,

  restaurantDetail: restaurantDetailReducer,

  userLanguageUpdate: userLanguageUpdateReducer,

  qrCodes: qrCodeReducer,
  qrCodeAdd: qrCodeAddReducer,
  qrCodeGetRoomDetails: getRoomDetailsReducer,
  qrCodeRemove: qrCodeRemoveReducer,
  qrCodeUpdate: qrCodeUpdateReducer,

  vatClass: vatReducer,
  vatClassAdd: vatAddReducer,
  vatClassRemove: vatRemoveReducer,
  vatClassUpdate: vatUpdateReducer,

  periodicity: periodicityReducer,
  periodicityAdd: periodicityAddReducer,
  periodicityDetails: periodicityDetailReducer,
  periodicityRemove: periodicityRemoveReducer,
  periodicityUpdate: periodicityUpdateReducer,

  paymentProvider: paymentProviderReducer,
  paymentProviderAdd: paymentProviderAddReducer,
  paymentProviderDetails: paymentProviderDetailReducer,
  paymentProviderRemove: paymentProviderRemoveReducer,
  paymentProviderUpdate: paymentProviderUpdateReducer,

  printerTemplate: printerTemplateReducer,
  printerTemplateAdd: printerTemplateAddReducer,
  printerTemplateDetails: printerTemplateDetailReducer,
  printerTemplateRemove: printerTemplateRemoveReducer,
  printerTemplateUpdate: printerTemplateUpdateReducer,
  courseList: courseListReducer,
  courseListUpdate: courseListUpdateReducer,

  contents: contentManagementReducer,
  contentAdd: contentManagementAddReducer,
  contentRemove: contentManagementRemoveReducer,
  contentUpdate: contentManagementUpdateReducer,

  reportOpenTransactionQlty: reportOpenTransactionQlty,
  reportPaymentTransactionQlty: reportPaymentTransactionQlty,
  reportRestaurantClosure: reportRestaurantClosure,
  reportRoomClosure: reportRoomClosure,
  reportPrintGuestGroup: reportPrintGuestGroup,

  recipeSubstitute: recipeSubstituteReducer,
  recipeSubstituteAdd: recipeSubstituteAddReducer,
  recipeSubstituteUpdate: recipeSubstituteUpdateReducer,
  recipeSubstituteRemove: recipeSubstituteRemoveReducer,

  guestGroup: guestGroupReducer,
  guestGroupUpdate: guestGroupUpdateReducer,
});

export default rootReducer;
