import apiCall from "./api.call.config";

async function addRecipeSubstitute(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/product-substitute/" +
        params.credentials.uuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function getRecipeSubstitute(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/product-substitute"
    );
  } catch (e) {
    throw e;
  }
}

async function removeRecipeSubstitute(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/product-substitute/" +
        params.credentials.productsubstituteuuid +
        "/item/" +
        params.credentials.recipeuuid
    );
  } catch (e) {
    throw e;
  }
}

async function updateRecipeSubstitute(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/product-substitute/" +
        params.credentials.productsubstituteuuid +
        "/item/" +
        params.credentials.recipeuuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  addRecipeSubstitute,
  getRecipeSubstitute,
  updateRecipeSubstitute,
  removeRecipeSubstitute,
};
