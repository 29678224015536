import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18next from "i18next";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { GREEN } from "../../../constant/constant";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faEye, faEyeSlash, faRedo } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import _ from "lodash";
import { filter, map, pipe, update } from "ramda";

export default function MachineAddCard(props: any) {
  const { t, i18n } = useTranslation();
  const [hasNewPassword, setNewPassword] = useState(true);
  const [isValidUsername, setIsValidUsername] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [keyValuePairs, setKeyValuePairs] = useState<any>([]);
  const [machineName, setMachineName] = useState("");
  const [machineType, setMachineType] = useState<any>(null);
  const [password, setPassword] = useState("");
  const [newKey, setNewKey] = useState("");
  const [newKeyError, setNewKeyError] = useState(false);
  const [newValue, setNewValue] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordType, setPasswordType] = useState<boolean>(true);
  const [room, setRoom] = useState("");
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [locations, setLocations] = useState(props.formData.locations || []);

  useEffect(() => {
    setIsValidPassword(true);
    setIsValidUsername(true);
    setMachineName(props.formData.machineName || "");
    setMachineType(props.formData.machineType || null);
    setKeyValuePairs(props.formData?.keyValuePairs || []);
    setNewKey("");
    setNewKeyError(false);
    setNewValue("");
    setPassword(props.formData.password || "");
    setRoom(props.formData.room || "");
    setUsername(props.formData.username || "");
    setPasswordError("");
    setUsernameError("");
    setNewPassword(props.formData.uuid === "");
    // setLocationId(props.formData.locationUuid);
    // setLocationType(props.formData.locationType);
  }, [props.formData, props.getOptionsForType]);

  const USERNAME_REGEXP = /^[a-zA-Z\d-]+$/gm;
  const PASSWORD_REGEXP =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d~!@#$%^&*]{8,}$/gm;

  const handleChange = (data: any) => {
    if (data?.target?.name === "machineName") {
      setMachineName(data.target.value);
    } else if (data?.target?.name === "username") {
      setUsernameError("");
      setUsername(data.target.value);
      if (data?.target?.value.trim() !== "") {
        if (USERNAME_REGEXP.test(data?.target?.value)) {
          setIsValidUsername(true);
          setUsernameError("");
        } else {
          setIsValidUsername(false);
          setUsernameError(t("robot.validation.username"));
        }
      }
    } else if (data?.target?.name === "password") {
      setPassword(data.target.value);
      if (data?.target?.value.trim() !== "") {
        if (PASSWORD_REGEXP.test(data?.target?.value)) {
          setIsValidPassword(true);
          setPasswordError("");
        } else {
          setPasswordError(t("robot.validation.password"));
        }
      } else {
        setPasswordError("");
      }
    } else if (data?.target?.name === "room") {
      setRoom(data.target.value);
    } else if (data?.target?.name === "machineType") {
      setMachineType(data.target.value);
    } else if (data?.target?.name === "newKey") {
      setNewKey(data.target.value);
    } else if (data?.target?.name === "newValue") {
      setNewValue(data.target.value);
    }
  };

  const handlePasswordViewToggle = () => {
    setPasswordType(!passwordType);
  };

  const isSubmitDisabled = () => {
    let isPasswordValid = true;
    let isUsernameValid = true;

    if (hasNewPassword && !isValidPassword) isPasswordValid = false;
    if (username.trim() !== "" && !isValidUsername) isUsernameValid = false;

    if (
      isUsernameValid &&
      machineName.trim() !== "" &&
      // locationId !== "" &&
      // locationType !== "" &&
      isPasswordValid
    )
      return false;
    return true;
  };

  const getLocationsForForm = () => {
    return pipe(
      filter((loc) => !!loc.locationType && !!loc.locationUuid),
      map((l) => ({
        location_type: l.locationType,
        location_uuid: l.locationUuid,
      }))
    )(locations);
  };
  const handleSave = () => {
    const keyValues: any = _.cloneDeep(keyValuePairs);
    if (newKey.trim() !== "") {
      keyValues.push({
        [newKey]: newValue,
      });
    }

    const formData: any = {
      machineName,
      room,
      uuid: props.formData.uuid,
      keyValuePairs: keyValues,
      locations: getLocationsForForm(),
      // locationType,
      // locationUuid: locationId,
    };
    if (hasNewPassword && password.trim() !== "")
      formData.password = password.trim();
    if (username.trim() !== "") formData.username = username.trim();
    if (Array.isArray(machineType) && machineType.length > 0)
      formData.machineType = machineType;
    props.saveMachineAdd(formData);
  };

  const addLocationField = () => {
    setLocations((locs) => {
      return [...locs, { locationUuid: "", locationType: "" }];
    });
  };

  const renderLocationPicker = (location, index) => {
    return (
      <div className="row pr-3 pl-3" key={location.locationUuid}>
        <Input
          className="col-4"
          type="select"
          name={`fromtype${index}`}
          onChange={(e) => {
            e.persist();
            setLocations((loc) => {
              return update(
                index,
                { ...loc[index], locationType: e.target?.value },
                loc
              );
            });
          }}
          required
          value={locations[index].locationType}
        >
          <option value="">{t("common.select")}</option>
          <option value="room">{t("room.room")} </option>
          <option value="storage">{t("common.storage")} </option>
          <option value="rack">{t("common.rack")} </option>
          <option value="place">{t("common.places")} </option>
          <option value="station">{t("station.station")} </option>
        </Input>
        <div className="col-1" />
        <Input
          className="col-6"
          type="select"
          name="fromvalue"
          id="fromvalue"
          onChange={(e) => {
            e.persist();
            setLocations((loc) => {
              return update(
                index,
                { ...loc[index], locationUuid: e.target?.value },
                loc
              );
            });
          }}
          value={location.locationUuid}
          required
        >
          <option value="">{t("common.select")}</option>
          {props.getOptionsForType(locations[index].locationType)}
        </Input>
        <div className="col-1">
          {index === locations.length - 1 && (
            <FontAwesomeIcon
              style={{ fontSize: 20 }}
              icon={faPlus}
              onClick={addLocationField}
            />
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    setLocations(props.formData.locations || []);
  }, [props.formData]);

  return (
    <Modal isOpen={props.isModalOpen}>
      <ModalHeader toggle={props.addMachineToggle} className="modal-header">
        {props.formData?.uuid === ""
          ? t("machine.addMachine")
          : t("machine.editMachine")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="machineName">{t("machine.machineName")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name="machineName"
              autoComplete="machineName"
              placeholder={t("machine.enterMachineName")}
              value={machineName}
              onChange={handleChange}
            />
          </FormGroup>

          <FormGroup>
            <Label for="room">{t("machine.location")}</Label>
            <Label className="required-star-style">*</Label>
            {locations.map((loc, index) => {
              return renderLocationPicker(loc, index);
            })}
          </FormGroup>

          {/* <FormGroup>
            <Label for="room">{t("room.room")}</Label>
            <Label className="required-star-style">*</Label>
            <CustomInput
              type="select"
              id="room"
              name="room"
              onChange={handleChange}
              value={room}
            >
              <option value="">{t("room.selectRoom")}</option>
              {props.roomList}
            </CustomInput>
          </FormGroup> */}
          <FormGroup>
            <Label for="machineType">{t("machine.machineType")}</Label>

            <Select
              value={machineType}
              onChange={(e: any) =>
                handleChange({
                  target: {
                    name: "machineType",
                    value: e,
                  },
                })
              }
              options={props.machineTypeOptions}
              isMulti
              maxMenuHeight={200}
              placeholder={t("machine.selectMachineType")}
            />
          </FormGroup>
          <FormGroup>
            <Label for="username">{t("common.username")}</Label>
            <Input
              name="username"
              onChange={handleChange}
              placeholder={t("common.enterUsername")}
              type="text"
              value={username}
            />
            {usernameError !== "" && (
              <span className="text-danger bf-font-size-12">
                {usernameError}
              </span>
            )}
          </FormGroup>
          <FormGroup>
            {hasNewPassword ? (
              <>
                <Label for="password">{t("common.password")}</Label>
                <InputGroup>
                  <Input
                    className="border-right-0"
                    name="password"
                    onChange={handleChange}
                    placeholder={t("common.enterPassword")}
                    type={passwordType ? "password" : "text"}
                    value={password}
                  />
                  <InputGroupAddon
                    addonType="append"
                    onClick={handlePasswordViewToggle}
                  >
                    <InputGroupText className="bg-transparent border-left-0 cursor-pointer ">
                      <FontAwesomeIcon
                        style={{ color: GREEN }}
                        icon={passwordType ? faEyeSlash : faEye}
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                  {props.formData.uuid !== "" && (
                    <FontAwesomeIcon
                      style={{ fontSize: 20 }}
                      icon={faRedo}
                      className="b-cursor-p ml-2 mt-2"
                      onClick={() => setNewPassword(!hasNewPassword)}
                    />
                  )}
                </InputGroup>
              </>
            ) : (
              <Button
                color="link"
                onClick={() => setNewPassword(!hasNewPassword)}
              >
                {t("password.changePassword")}
              </Button>
            )}
            {passwordError !== "" && (
              <span className="text-danger bf-font-size-12">
                {passwordError}
              </span>
            )}
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.addMachineToggle}>
          {t("common.cancel")}
        </Button>
        <Button color="info" onClick={handleSave} disabled={isSubmitDisabled()}>
          {t("common.save")}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
