import { unitModalPayload } from "./units.model";

export interface DateItemModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export const DateItemModelInitState = {
  isFetching: false,
  data: {},
  error: {},
  failure: false,
};

export interface DateItemListState {
  color: string;
  description: string;
  elementId: string;
  fetchData: boolean;
  globalUnits: Array<object>;
  isAddItemModalOpen: boolean;
  isUnitModalOpen: boolean;
  isEdit: boolean;
  isFetching: boolean;
  isUpdated: boolean;
  items: Array<object>;
  itemsDetails: Array<object>;
  itemId: string;
  labelOptions: Array<object>;
  labels: Array<object>;
  name: string;
  newUnit: any;
  outputquantity: string;
  outputUnitUuid: string;
  posName: string;
  printerName: string;
  recipeDetails: any;
  recipeServiceSets: any;
  rooms: Array<object>;
  selectedLabels: Array<object>;
  storage: Array<object>;
  tags: Array<object>;
  unitModalPayload: unitModalPayload;
  units: Array<object>;
  replacementItem: string;
}

export const DateItemListInitState = {
  color: "#FFFFFF",
  description: "",
  elementId: "",
  fetchData: false,
  globalUnits: [],
  isAddItemModalOpen: false,
  isEdit: false,
  isFetching: false,
  isUnitModalOpen: false,
  isUpdated: false,
  items: [],
  itemsDetails: [],
  itemId: "",
  labelOptions: [],
  labels: [],
  name: "",
  newUnit: null,
  outputquantity: "",
  outputUnitUuid: "",
  posName: "",
  printerName: "",
  recipeDetails: {},
  recipeServiceSets: {},
  rooms: [],
  selectedLabels: [],
  storage: [],
  tags: [],
  unitModalPayload: {
    grossWeight: "",
    grossWeightUnitSelection: null,
    hasGrossWeight: true,
    name: "",
    symbol: "",
  },
  units: [],
  replacementItem: "",
};

export interface DateItemDetailState {
  color: string;
  dateItemDetails: any;
  dateItemUpdate: any;
  description: string;
  elementId: string;
  fetchData: boolean;
  isAddItemModalOpen: boolean;
  isDeleted: boolean;
  isEdit: boolean;
  isFetching: boolean;
  isJustMounted: boolean;
  isSaveButtonDisable: boolean;
  isUnitModalOpen: boolean;
  isUpdated: boolean;
  itemsDetails: any;
  itemId: string;
  itemsList: Array<object>;
  labelOptions: Array<object>;
  labels: Array<object>;
  name: string;
  newUnit: any;
  outputquantity: string;
  outputQuantity: string;
  outputUnitUuid: string;
  outputunituuid: string;
  posName: string;
  printerName: string;
  periodicity: string;
  recipeServiceSets: any;
  removeItemData: any;
  rooms: Array<object>;
  selectedLabels: Array<object>;
  selectedPeriodicity: Array<any>;
  selectedPeriodicityData: any;
  selectedPeriodicityErrors: any;
  storage: Array<object>;
  units: Array<any>;
  unitModalPayload: unitModalPayload;
  replacementItem: string;
}
export const DateItemDetailInitState = {
  color: "#FFFFFF",
  dateItemDetails: {},
  dateItemUpdate: {},
  description: "",
  elementId: "",
  fetchData: false,
  isAddItemModalOpen: false,
  isDeleted: false,
  isEdit: false,
  isFetching: false,
  isJustMounted: false,
  isSaveButtonDisable: true,
  isUnitModalOpen: false,
  isUpdated: false,
  itemsDetails: [],
  itemId: "",
  itemsList: [],
  labelOptions: [],
  labels: [],
  name: "",
  newUnit: null,
  outputquantity: "",
  outputQuantity: "",
  outputunituuid: "",
  outputUnitUuid: "",
  posName: "",
  printerName: "",
  periodicity: "",
  recipeServiceSets: {},
  removeItemData: {},
  rooms: [],
  selectedLabels: [],
  selectedPeriodicity: [],
  selectedPeriodicityData: {},
  selectedPeriodicityErrors: {},
  storage: [],
  unitModalPayload: {
    grossWeight: "",
    grossWeightUnitSelection: null,
    hasGrossWeight: true,
    name: "",
    symbol: "",
  },
  units: [],
  replacementItem: "",
};

export interface DateItemListCardState {
  cardItemData: any;
  component_price: boolean;
  output_quantity: string;
  output_unit: string;
  periodicity_item_uuid: string;
  recipe_uuid: string;
  selectedPeriodicity: Array<string>;
  selectedRecipe: any;
  type?: string;
  warnings: any;
}
export const DateItemListCardInitState = {
  cardItemData: null,
  component_price: false,
  output_quantity: "",
  output_unit: "",
  periodicity_item_uuid: "",
  recipe_uuid: "",
  selectedPeriodicity: [],
  selectedRecipe: {},
  warnings: {
    output_quantity: null,
    periodicity_item_uuid: null,
    selectedRecipe: null,
  },
};
