import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MediaListProps } from "../../models/media.manager.model";
import MediaListItem from "./media-list-item";

function MediaList(props: MediaListProps) {
  const { items, actions } = props;
  return (
    <ul className="b-media-list w-100 list-unstyled no-gutters row">
      {items?.map((item: any) => {
        return <MediaListItem key={item.uuid} item={item} actions={actions} />;
      })}
    </ul>
  );
}

export default MediaList;
