import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import "../../../styles/units.scss";
import { useTranslation } from "react-i18next";
export default function VatAddEditCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <Modal isOpen={props.addModal}>
      <ModalHeader toggle={props.toggle} className="modal-header">
        {props.isEdit ? t("vat.editVat") : t("vat.addVat")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="name">{t("common.name")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name="name"
              autoComplete="name"
              placeholder={t("vat.enterVatName")}
              value={props.name}
              onChange={props.handleChange}
            />
            <Label className="required-star-style">{props.errorName}</Label>
          </FormGroup>
          <FormGroup>
            <Label for="percentage">{t("vat.vatPercentage")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name="percentage"
              autoComplete="percentage"
              placeholder={t("vat.enterVatPercentage")}
              value={props.percentage}
              onChange={props.handleChange}
            />
            <Label className="required-star-style">
              {props.errorPercentage}
            </Label>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={props.saveAdd}
          disabled={
            !props.name ||
            !props.percentage ||
            props.isDisabled ||
            !!props.isFetching
          }
        >
          {t("common.save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
