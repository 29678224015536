import {
  PeriodicityModel,
  PeriodicityModelInitState,
} from "../../models/periodicity.model";
import * as types from "../actions/types";

export function periodicityReducer(
  state = PeriodicityModelInitState,
  action: any
): PeriodicityModel {
  switch (action.type) {
    case types.PERIODICITY.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.PERIODICITY.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.PERIODICITY.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function periodicityAddReducer(
  state = PeriodicityModelInitState,
  action: any
): PeriodicityModel {
  switch (action.type) {
    case types.PERIODICITY_ADD.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.PERIODICITY_ADD.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.PERIODICITY_ADD.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
export function periodicityDetailReducer(
  state = PeriodicityModelInitState,
  action: any
): PeriodicityModel {
  switch (action.type) {
    case types.PERIODICITY_DETAILS.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.PERIODICITY_DETAILS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.PERIODICITY_DETAILS.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
export function periodicityRemoveReducer(
  state = PeriodicityModelInitState,
  action: any
): PeriodicityModel {
  switch (action.type) {
    case types.PERIODICITY_REMOVE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.PERIODICITY_REMOVE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.PERIODICITY_REMOVE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function periodicityUpdateReducer(
  state = PeriodicityModelInitState,
  action: any
): PeriodicityModel {
  switch (action.type) {
    case types.PERIODICITY_UPDATE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.PERIODICITY_UPDATE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.PERIODICITY_UPDATE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
