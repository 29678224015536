import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import commonService from "../../services/common.service";

export default function TableModal(props: any) {
  const { t, i18n } = useTranslation();
  const { className, isOpen } = props;
  const [isRangeTye, setRangeType] = useState(true);

  const handleStationChange = (event: any) => {
    props.handleChange(event);
  };

  const handleRangeToChange = (event: any) => {
    props.handleChange(event);
  };
  const isNotValidForm = () => {
    if (props.state.tableName.trim() === "") {
      return true;
    } else if (props.state.tableStation === "") {
      return true;
    }
    return false;
  };
  return (
    <div>
      <Modal isOpen={isOpen} className={className}>
        <ModalHeader toggle={props.onCancel}>
          {props.state.isEdit ? t("table.editTable") : t("table.addTable")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row className="p-0">
              <Label className="text-right" for="tableName" sm={4}>
                {t("common.name")}{" "}
                <span className="required-star-style">*</span>
              </Label>
              <Col sm={8}>
                <Input
                  type="text"
                  name="tableName"
                  placeholder={t("table.tableName")}
                  value={props.state.tableName}
                  onChange={props.handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="p-0">
              <Label className="text-right" for="tableStation" sm={4}>
                {t("station.station")}{" "}
                <span className="required-star-style">*</span>
              </Label>
              <Col sm={8}>
              <Input
                  type="select"
                  name="tableStation"
                  onChange={handleStationChange}
                  value={props.state.tableStation}
                >
                  <option value="">{t("common.select")}</option>
                  {Object.keys(props.stations).length > 0 &&
                    props.stations?.map((station: any, indx: number) =>
                    {let stationLocale = commonService.applyLocalization(
                      "restaurant",
                      "name",
                      station.locales
                    )
                    return (<option key={indx} value={station.uuid}>     
                    {stationLocale.name}
                  </option>)
                      }
                    )}
                  ) )
                </Input>
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.onCancel}>
            {t("common.cancel")}
          </Button>
          <Button
            color="info"
            onClick={props.onSave}
            disabled={isNotValidForm()}
          >
            {t("common.save")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
