
import {
  CourseListModel,
  CourseListModelInitState,
} from "../../models/course.list.model";
import * as types from "../actions/types";

export function courseListReducer(
  state = CourseListModelInitState,
  action: any
): CourseListModel {
  switch (action.type) {
    case types.COURSE_LIST.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.COURSE_LIST.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.COURSE_LIST.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function courseListUpdateReducer(
  state = CourseListModelInitState,
  action: any
): CourseListModel {
  switch (action.type) {
    case types.COURSE_LIST_UPDATE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.COURSE_LIST_UPDATE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.COURSE_LIST_UPDATE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
