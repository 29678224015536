import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Badge, Media } from "reactstrap";
import image from "../../../assets/images/icon/image.png";
import commonService from "../../../services/common.service";
import "../../../styles/ingredients.scss";
import "../../../styles/inventory.scss";
class InventoryCard extends Component<any> {
  render() {
    return <div className="child-box mb-3">{this.labelName()}</div>;
  }

  public labelName(): any {
    let allRecipes = this.props.recipe.length ? this.props.recipe : [];
    let name;
    const batch = this.props.inventory.batch?.split("-");

    if (!!allRecipes) {
      // eslint-disable-next-line
      const [inventoryRecipe]: any = allRecipes.filter(
        (rec: any) => this.props.inventory.recipe === rec.uuid
      );

      name = (
        <div className="d-flex">
          <Media left className="img-block mb-2">
            <img
              alt=""
              src={
                inventoryRecipe && inventoryRecipe?.image_url
                  ? inventoryRecipe.image_url
                  : image
              }
            />
          </Media>
          <div>
            {inventoryRecipe ? (
              <h6>
                {
                  commonService.applyLocalization(
                    "restaurant",
                    "name",
                    inventoryRecipe?.locales
                  )["name"]
                }
                {inventoryRecipe?.name} ({this.props.inventory.quantity}{" "}
                {this.getUnitName(inventoryRecipe?.output_unit_uuid)})
              </h6>
            ) : (
              <h6>
                {this.props.t("inventory.deletedRecipe")} (
                {this.props.inventory.quantity}{" "}
                {this.getUnitName(inventoryRecipe?.output_unit_uuid)})

              </h6>
            )}
            <div>
              {this.props.t("inventory.batch")}:{" "}
              <Badge
                title={this.props.inventory.batch}
                className="text-dark py-1 px-2"
                color="light"
                pill
              >
                {batch?.pop() || ""}
              </Badge>
            </div>
            <div>
              {this.props.t("inventory.producedAt")}:{" "}
              <Badge
                className="text-dark py-1 px-2"
                color="light"
                pill
              >
                {commonService.getDate(this.props.inventory.producedAt)}
              </Badge>
            </div>
            <div>
              {this.props.t("inventory.expiringAt")}:{" "}
              <Badge
                className="text-dark py-1 px-2"
                color="light"
                pill
              >
                {commonService.getDate(this.props.inventory.expiringAt)}
              </Badge>
            </div>
          </div>
        </div>
      );

      return name;
    }
  }

  public getUnitName(unit_uuid: any): any {
    if (this.props.units && this.props.units.length > 0) {
      // eslint-disable-next-line
      return this.props.units.map((unit: any, ind: number) => {
        if (unit.uuid === unit_uuid) {
          const unitLocale = commonService.applyLocalization(
            "restaurant",
            "name",
            unit.locales
          );
          return <span key={ind}>{unitLocale.symbol}</span>;
        }
      });
    }
  }
}
export default withTranslation()(withRouter(InventoryCard));
