import apiCall from "./api.call.config";

async function stations(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.get(
      url + "/" + params.credentials.uuid + "/station?sort=name:asc"
    );
  } catch (e) {
    throw e;
  }
}

async function updateStations(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/station/" +
        params.credentials.uuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function addStations(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/station",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function removeStations(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/station/" +
        params.credentials.uuid
    );
  } catch (e) {
    throw e;
  }
}

async function updateStationsOperationMode(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/station/operation-mode",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function getSettings(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.get(
      url + "/" + params.credentials.restaurantuuid + "/settings"
    );
  } catch (e) {
    throw e;
  }
}

async function getAssembleTaskLocales(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.get(
      url + "/" + params.credentials.restaurantuuid + "/assemble-task-locales"
    );
  } catch (e) {
    throw e;
  }
}

async function addSettings(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/settings",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  stations,
  updateStations,
  addStations,
  removeStations,
  updateStationsOperationMode,
  getSettings,
  getAssembleTaskLocales,
  addSettings,
};
