import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import ModalFactory from "react-modal-promise";
import {
  ItemWrapperInitState,
  itemWrapperProps,
  ItemWrapperState,
} from "../../../models/service.item.model";
import { faFilter, faSortAlphaDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import {
  Card,
  CardBody,
  Collapse,
  Input,
  Row,
  Col,
  Button,
  CustomInput,
} from "reactstrap";
import commonService from "../../../services/common.service";
import {
  conversionAdd,
  conversionEdit,
  conversionDelete,
  unitsRequest,
  unitsAdd,
  unitsRemove,
  unitsUpdate,
} from "../../../redux/actions/units.action";
import { recipeAdd, recipeUpdate } from "../../../redux/actions/recipe.action";

import {
  recipeSubstituteAdd,
  recipeSubstituteRequest,
  recipeSubstituteUpdate,
  recipeSubstituteRemove,
} from "../../../redux/actions/recipe.substitutes.action";
import { labelRequest } from "../../../redux/actions/labels.action";
import { recipeDetailsRequest } from "../../../redux/actions/recipe.action";
import { OpenDeleteModal } from "../../card-components/delete-card/delete-modal";
import UnitsCard from "../../card-components/units-card/units-card";
import UnitsAddCard from "../../card-components/units-add-card/units-add-card";
import UnitsEditCard from "../../card-components/units-edit-card/units-edit-card";
import ItemCard from "./itemCard";
import TagAssignCard from "../../card-components/tag-assign-card/tag-assign-card";
import { tagAssign, tagsRequest } from "../../../redux/actions/tags.action";
import { paymentProviderRequest } from "../../../redux/actions/payment.provider.action";
import { withTranslation } from "react-i18next";
import { contentManagementRequest } from "../../../redux/actions/content.management.action";
import {
  GRAY,
  LIGHT_GREEN,
  PAGE_VIEW_MODE,
  RECIPE_STATUS,
  RECIPE_TYPE,
} from "../../../constant/constant";

import PaginationComponent from "../shared/pagination";
import SubstituteAssignCard from "../../card-components/substitute-assign/substitute-assign";
import OrderModalCard from "../../../components/card-components/order-modal-card/order-modal-card";
import ShoppingCartModalCard from "../../../components/card-components/shopping-cart-modal-card/shopping-cart-modal-card";
import ServiceSetAssignCard from "../../card-components/serviceset-assign-card/serviceset-assign-card";
import { servicesetRequest } from "../../../redux/actions/serviceset.action";
import { updateServiceSetRecipe } from "../../../services/service.set";
import { RESTAURANT_REQUEST } from "../../../config/api.config";
import LoaderComponent from "../../loader/loader";
import { compose } from "redux";
import { withCardItemSelectionStateManager } from "../../cardItemSelectionManager";
import { addUnits as addUnitService } from "../../../services/units.service";
import { settingsRequest } from "../../../redux/actions/stations.action";
import ItemAddModal from "./itemAddModal";
import { OpenMediaManager } from "../../card-components/media-manager-card/media-manager-card";
import { printerTemplateRequest } from "../../../redux/actions/printer.template.action";
import { importAllSupplierItems } from "../../../services/supplier.restaurants.service";
import handleResponse from "../../../services/response.service";
import { OpenConfirmModal } from "../../card-components/confirm-card/confirm-modal";

class ItemWrapper extends Component<itemWrapperProps> {
  filterList: any = [];
  filterWithLabel: any = [];
  hasNewSubstituteAdded: boolean = false;
  supplierOptions: any = {};
  rack: any = "";
  place: any = "";
  options: any;
  room: any = "";
  state: ItemWrapperState;
  recipeServiceSets: any = {};
  stackedUnits: Array<string> = [];
  stackedUnitsConversions: Array<any> = [];
  tempUnit: any;
  isItTempUnit: boolean = false;
  constructor(props: any) {
    super(props);
    this.state = ItemWrapperInitState;
  }

  componentDidMount() {
    /* TODO Need to update itemWrapper to avoid unwanted call and state changes */
    if (this.props?.type) {
      this.props.getTags({ restaurantuuid: this.props.match.params.uuid });
      this.props.getContent({ restaurantuuid: this.props.match.params.uuid });
      this.props.getServiceSet({
        restaurantuuid: this.props.match.params.uuid,
      });
    }
    this.props.getSettings({ restaurantuuid: this.props.match.params.uuid });
    if (!this.props.isSupplierOffering) {
      this.props.getLabel({
        restaurantuuid: this.props.match.params.uuid,
      });
      this.props.getUnits({ restaurantuuid: this.props.match.params.uuid });
    }
    this.props.getPrinterTemplates({
      restaurantuuid: this.props.match.params.uuid,
    });
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    commonService.stickyEventListener();
    if (prevState.tagOptions.length !== this.props.tags.length) {
      let tagOptions =
        Object.keys(this.props.tags).length > 0
          ? this.props.tags.map((item: any) => {
              return {
                value: item.uuid,
                label: commonService.applyLocalization(
                  "restaurant",
                  "name",
                  item.locales
                )["name"],
                meta: item.meta,
                key: item.key,
              };
            })
          : [];
      this._setStates("tagOptions", _.sortBy(tagOptions, "label"));
    }
    if (
      !!this.props?.type &&
      !!this.props.contents.length &&
      prevState.contentList.length !== this.props.contents.length
    ) {
      let contentListOptions =
        Object.keys(this.props.contents).length > 0
          ? this.props.contents.map((item: any) => {
              return {
                value: item.uuid,
                label: commonService.applyLocalization(
                  "restaurant",
                  "name",
                  item.locales
                )["name"],
                meta: item.meta,
              };
            })
          : [];
      this.setState({
        contentList: contentListOptions,
      });
    }
    if (
      !!this.props.units &&
      prevState.conversionOptions?.length !== this.props.units.length &&
      !!this.props.units.length
    ) {
      let conversionOptions =
        Object.keys(this.props.units).length > 0
          ? this.props.units.map((item: any) => {
              let unitLocale = commonService.applyLocalization(
                "restaurant",
                "name",
                item.locales
              );
              return {
                value: item.uuid,
                label: unitLocale.name,
                recipeId: item.recipe_uuid,
              };
            })
          : [];
      this._setStates("conversionOptions", conversionOptions);
    }

    if (
      !!this.props.labels &&
      prevState.labels?.length !== this.props.labels.length &&
      !!this.props.labels.length
    ) {
      this.setState({
        labels: this.props.labels,
      });
    }
    if (
      this.props.itemData &&
      (prevProps.itemData.length !== this.props.itemData.length ||
        JSON.stringify(prevProps.itemData) !==
          JSON.stringify(this.props.itemData) ||
        this.state.updateList)
    ) {
      if (
        !this.toggleArchivedItemsVisibility(
          !!this.state.showArchived || !!this.props.isDetailPage,
          false
        )
      ) {
        this.setState({
          searchList: this.props.itemData,
          updateList: false,
        });
      }
    }
    if (!!this.state.fetchData) {
      this.props.paymentProvider({
        restaurantuuid: this.props.match.params.uuid,
      });
      this.props.fetchData();
      this.props.getUnits({ restaurantuuid: this.props.match.params.uuid });

      this.props.getRecipeDetail({
        restaurantuuid: this.props.match.params.uuid,
        recipeuuid: this.props.elementId,
      });
      this.setState({
        fetchData: false,
      });
    }

    if (!!this.state.isRecipeUpdated && !this.props.isFetching) {
      this.props.getRecipeSubstitutes({
        restaurantuuid: this.props.match.params.uuid,
        recipeuuid: this.props.elementId,
      });
      this.setState({
        isRecipeUpdated: false,
      });
    }

    if (
      !!this.state.isSubstituteListUpdated &&
      !this.props.isFetching &&
      this.hasNewSubstituteAdded
    ) {
      this.props.getSubstitutes({
        uuid: this.props.match.params.uuid,
        type: RECIPE_TYPE.INGREDIENT_CLASS,
      });
      this.hasNewSubstituteAdded = false;
    }

    if (
      !!this.state.isSubstituteListUpdated &&
      !this.props.isFetching &&
      JSON.stringify(prevProps.substitutes) !==
        JSON.stringify(this.props.substitutes)
    ) {
      let [newSubstitute] = this.props.substitutes.filter(
        (item: any) =>
          prevProps.substitutes.findIndex(
            (substitute: any) => substitute.uuid === item.uuid
          ) === -1
      );

      this.setState({
        isSubstituteListUpdated: false,
        recipeSubstitute: {
          label: commonService.applyLocalization(
            "restaurant",
            "name",
            newSubstitute.locales
          )?.name,
          value: newSubstitute.uuid,
        },
      });
    }

    if (
      this.props.isSupplierOffering &&
      JSON.stringify(prevProps.supplierLabels) !==
        JSON.stringify(this.props.supplierLabels)
    ) {
      this.getLabelList(this.props.supplierLabels);
    } else {
      if (
        JSON.stringify(prevState.labels) !== JSON.stringify(this.state.labels)
      ) {
        this.getLabelList(this.state.labels);
      }

      if (
        JSON.stringify(prevProps.labels) !== JSON.stringify(this.props.labels)
      ) {
        this.getLabelList(this.props.labels);
      }
    }
    if (!!this.state.isUpdated && !this.props.isFetching) {
      this.props.getUnits({ restaurantuuid: this.props.match.params.uuid });
      this.setState({ isUpdated: false, isFetching: false });
    }

    if (this.props.isUnitModalOpen !== prevProps.isUnitModalOpen) {
      this.setUnitModal(this.props.isUnitModalOpen);
    }

    this.setStateFromFilterParam(prevProps);
    this.setSelectedSupplierFromFilterParam(prevProps);
    this.setSelectedFilterOptionsFromFilterParam(prevProps, prevState);
    this.handleFilterChanges(prevState);
    this.setOfferSupplier(prevProps);
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    let update: any = {};
    if (!!prevState.isUpdated) {
      if (
        !!nextProps.unitsUpdate?.status &&
        !!prevState.editmodal &&
        !nextProps.isFetching
      ) {
        update.editmodal = !prevState.editmodal;
        update.isUpdated = false;
        update.fetchData = true;
      }

      if (
        (!!nextProps.unitsRemove?.status && !nextProps.isFetching) ||
        (!!nextProps.conversionDelete?.status && !nextProps.isFetching)
      ) {
        update.isUpdated = false;
        update.fetchData = true;
      }
      if (
        !!nextProps.unitsAdd?.status &&
        !!prevState.addmodal &&
        !nextProps.isFetching
      ) {
        update.addmodal = !prevState.addmodal;
        update.isUpdated = false;
        update.fetchData = true;
      }

      if (!!prevState.showModal && !nextProps.isFetching) {
        update.showModal = !prevState.showModal;
        update.isUpdated = false;
        update.fetchData = true;
      }
      if (nextProps.tagAssign?.status && !nextProps.isFetching) {
        update.showAddTag = false;
        update.isUpdated = false;
        update.fetchData = true;
      }
      if (nextProps.upload?.status && !nextProps.isFetching) {
        update.isUpdated = false;
        update.fetchData = true;
      }
    }

    return update;
  }

  setStateFromFilterParam = (prevProps: any) => {
    if (
      this.props?.isBrowserNav &&
      JSON.stringify(prevProps.filterParams) !==
        JSON.stringify(this.props.filterParams)
    ) {
      this.setState({ searchByName: this.props.filterParams.name });
      this.setState({ showArchived: this.props.filterParams.showArchived });
      if (
        !this.state.fiterFlag &&
        (this.props.filterParams?.name !== "" ||
          this.props.filterParams?.labelArray?.length > 0 ||
          this.props.filterParams?.supplierArray?.length > 0 ||
          this.props.filterParams?.showArchived === true)
      ) {
        this.setState({ fiterFlag: true });
      }
      if (
        this.props?.isSupplierOffering &&
        this.props.filterParams?.supplierArray?.length === 0
      ) {
        this.setState({ offeringSupplier: {}, fiterFlag: false });
      }
    }
  };

  setSelectedSupplierFromFilterParam = (prevProps: any) => {
    if (
      this.props?.isBrowserNav &&
      !this.props?.isSupplierOffering &&
      this.props.supplierOptions &&
      (JSON.stringify(prevProps.supplierOptions) !==
        JSON.stringify(this.props.supplierOptions) ||
        JSON.stringify(prevProps.filterParams) !==
          JSON.stringify(this.props.filterParams))
    ) {
      const selectedSupplier =
        this.props.filterParams?.supplierArray.length > 0
          ? this.props.filterParams?.supplierArray?.map((supplier: any) => {
              return this.props.supplierOptions?.find(
                (option: any) => option.value === supplier.value
              );
            })
          : [];
      this.setState({ selectedSupplier });
    }
  };

  setSelectedFilterOptionsFromFilterParam = (
    prevProps: any,
    prevState: any
  ) => {
    if (
      this.props?.isBrowserNav &&
      this.state.parentLabels &&
      (JSON.stringify(prevState.parentLabels) !==
        JSON.stringify(this.state.parentLabels) ||
        JSON.stringify(prevProps.filterParams) !==
          JSON.stringify(this.props.filterParams))
    ) {
      const selectedFilterOptions =
        this.props.filterParams?.labelArray.length > 0
          ? this.props.filterParams?.labelArray.map((option: any) => {
              return this.state.parentLabels?.find(
                (label: any) => label.value === option.value
              );
            })
          : [];
      this.setState({ selectedFilterOptions });
    }
  };

  public addMoreValues = () => {
    this.setState({
      moreValues: [...this.state.moreValues, { index: "", value: "" }],
    });
  };

  getAllUnits = (RecipeUnits: Array<any>) => {
    const allUnits: Array<any> = RecipeUnits;
    const stackedUnits: Array<any> = RecipeUnits.map((unit: any) => unit.uuid);
    this.props.units.forEach((unit: any) => {
      if (!unit?.recipe_uuid && !stackedUnits.includes(unit.uuid)) {
        stackedUnits.push(unit.uuid);
        allUnits.push(unit);
      }
    });
    return allUnits;
  };

  getBaseUnitOptions = () => {
    if (!Array.isArray(this.props.units)) return;
    let units: Array<any> = [];
    const baseUnit: any = this.props.units?.find(
      (unit: any) => unit.uuid === this.props.settings.base_weight_unit
    );
    if (baseUnit) {
      units = this.getRelateUnitDropdowns(baseUnit.uuid, [], 1) || [];
    }
    return _.sortBy(units, [(option: any) => option.label.toLowerCase()]);
  };

  getUuidFromMediaManager = async (
    defaultUuid: any = null,
    filter: any = [],
    entityType: string = "media"
  ) => {
    return await OpenMediaManager({
      restaurantUuid: this.props.match.params.uuid,
      typeFilter: filter,
      predefinedMediaUuid: defaultUuid,
      apiFilters: {
        pageNo: 1,
        pageSize: 50,
        filter: "type=" + filter,
      },
      entityType: entityType,
    });
  };

  public handleChange = (e: any) => {
    if (e.target.name === "grossWeightUnitSelection") {
      this._setStates(e.target.name, e.target.value);
    } else if (e.target.name === "showArchived") {
      this._setStates(e.target.name, !this.state.showArchived);
    } else if (e.target.value.trim() !== "") {
      this._setStates(e.target.name, e.target.value);
    } else {
      this._setStates(e.target.name, "");
    }
    if (e.target.name === "searchByName") {
      if (
        this.props.type !== "newIngredientList" &&
        !this.props.isSupplierOffering
      ) {
        if (this.filterWithLabel.length > 0) {
          let searchLabel: any = [];
          this.filterWithLabel.forEach((element: any) => {
            let nameLocale: any = commonService.applyLocalization(
              "restaurant",
              "name",
              element?.locales
            )["name"];
            if (
              nameLocale.toLowerCase().indexOf(e.target.value.toLowerCase()) >
              -1
            ) {
              searchLabel.push(element);
            }
          });
          this.setState({
            searchList: searchLabel,
          });
        } else {
          this.filterList = [];
          if (
            this.state.selectedFilterOptions?.length === 0 ||
            !this.state.selectedFilterOptions
          ) {
            this.props.itemData.forEach((element: any) => {
              let nameLocale: any =
                this.props.type !== "newIngredientList"
                  ? commonService.applyLocalization(
                      "restaurant",
                      "name",
                      element?.locales
                    )["name"]
                  : element.name;
              if (
                nameLocale
                  .toLowerCase()
                  .indexOf(e.target.value?.toLowerCase()) > -1
              ) {
                this.filterList.push(element);
              }
            });
          }

          if (!!e.target.value || e.target.value.trim() === "") {
            this.setState({
              searchList: this.filterList,
            });
          } else {
            this.filterList = [];
            this.setState({
              searchList: this.props.itemData,
            });
          }
        }
        this.toggleArchivedItemsVisibility(!!this.state.showArchived);
      }
    } else if (e.target.name === "override") {
      this._setStates(e.target.name, e.target.checked);
    } else if (e.target.name === "defaultValue") {
      if (this.state.tagName.meta.type === 1) {
        if (isNaN(e.target.value)) {
          this._setStates(
            "defaultError",
            this.props.t("error.defafaultValueNumeric")
          );
        } else if (Number(e.target.value) < 0) {
          this._setStates(
            "defaultError",
            this.props.t("error.defafaultValueNotLTzero")
          );
        } else if (e.target.value.indexOf(".") !== -1) {
          this._setStates(
            "defaultError",
            this.props.t("error.defafaultValueNotDecimal")
          );
        } else if (
          Number(e.target.value) < Number(this.state.tagValue[0]) ||
          Number(e.target.value) > Number(this.state.tagValue[1])
        ) {
          this._setStates(
            "defaultError",
            this.props.t("error.defaultValueInRange") +
              parseInt(this.state.tagValue[0]) +
              " to " +
              parseInt(this.state.tagValue[1])
          );
        } else {
          this._setStates("defaultError", "");
        }
      } else if (this.state.tagName.meta.type === 2) {
        this._setStates("defaultValue", e.target.checked);
      } else {
        this._setStates(e.target.name, e.target.value);
      }
    }
    if (e.target.name === "showArchived") {
      this.toggleArchivedItemsVisibility(!this.state.showArchived);
    }
  };

  handleNameKeyPress = (event: any) => {
    if (
      event.key === "Enter" &&
      (this.props.type === "newIngredientList" || this.props.isSupplierOffering)
    ) {
      this.applyFilterToIngredientList();
    }
  };

  public toggleArchivedItemsVisibility = (
    showArchived: boolean,
    updateList = true
  ): boolean => {
    if (
      this.props.type !== "newIngredientList" &&
      !this.props.isSupplierOffering
    ) {
      const searchList =
        this.filterList && this.filterList.length > 0
          ? this.filterList
          : this.props.itemData;
      this.setState({
        searchList:
          showArchived === true
            ? searchList
            : searchList.filter(
                (item: any) => item.status !== RECIPE_STATUS.ARCHIVED
              ),
        updateList: updateList,
      });
      return true;
    }
    return false;
  };

  public handleConversionCancel = () => {
    this.isItTempUnit = false;
  };

  handleNewUnitChange = (e: any) => {
    const unitData: any = { ...this.state.unitModalPayload };
    unitData[e.target.name] = e.target.value;
    this.setState({
      unitModalPayload: unitData,
    });
  };

  handleNewUnitModalActions = (action: string, data?: any) => {
    if (action === "cancel") {
      this.props.handleUnitUpdate(false, "visibility");
      this.setState({
        isUnitModalOpen: false,
      });
    } else if (action === "getBaseUnitOptions") {
      return this.getBaseUnitOptions();
    } else if (action === "save") {
      this.saveNewUnit();
    }
  };

  handleUnitUnitEdit = (formData: any, unitId: any) => {
    this.setUnitEditable(formData, unitId);
  };

  public saveTag = (type?: string, isOverridable?: boolean) => {
    this._setStates("tagId", "");
    let recipeTags: any;
    let updatedTag: any = {};
    // TODO Need cleanup due to SR-1044 changes
    if (type === "delete" || type === "edit") {
      recipeTags = this.props.itemsDetails
        .filter((element: any) => element.uuid === this.props.elementId)[0]
        .tags?.filter((item: any) => item.tag.uuid !== this.state.tagId)
        .map((tag: any) => {
          return {
            tag: tag.tag?.uuid,
            meta: {
              from:
                tag.tag.meta.type === 1 ? tag.recipe_tag_meta.from : undefined,
              to: tag.tag.meta.type === 1 ? tag.recipe_tag_meta.to : undefined,
              values:
                tag.tag.meta.type === 3
                  ? tag.recipe_tag_meta.values
                  : undefined,

              default:
                tag.tag.meta.type === 1
                  ? parseInt(tag.recipe_tag_meta.default)
                  : tag.recipe_tag_meta.default,
              override: tag.recipe_tag_meta.override,
              value:
                tag.tag.meta.type === 4 ? tag.recipe_tag_meta.value : undefined,
              type:
                tag.tag.meta.type === 4 ? tag.recipe_tag_meta.type : undefined,
              richText:
                tag.tag.meta.type === 3
                  ? tag.recipe_tag_meta.richText
                  : undefined,
            },
          };
        });
    } else {
      recipeTags =
        this.props.itemsDetails
          .filter((item: any) => item.uuid === this.props.elementId)[0]
          ?.tags.map((tag: any) => {
            return {
              tag: tag.tag?.uuid,
              meta: {
                from:
                  tag.tag.meta.type === 1
                    ? tag.recipe_tag_meta.from
                    : undefined,
                to:
                  tag.tag.meta.type === 1 ? tag.recipe_tag_meta.to : undefined,
                values:
                  tag.tag.meta.type === 3
                    ? tag.recipe_tag_meta.values
                    : undefined,

                default:
                  tag.tag.meta.type === 1
                    ? parseInt(tag.recipe_tag_meta.default)
                    : tag.recipe_tag_meta.default,
                override: tag.recipe_tag_meta.override,
                value:
                  tag.tag.meta.type === 4
                    ? tag.recipe_tag_meta.value
                    : undefined,
                type:
                  tag.tag.meta.type === 4
                    ? tag.recipe_tag_meta.type
                    : undefined,
                richText:
                  tag.tag.meta.type === 3
                    ? tag.recipe_tag_meta.richText
                    : undefined,
              },
            };
          }) || [];
    }
    if (type !== "delete") {
      updatedTag = {
        tag: this.state.tagName.value,
        meta: {
          default:
            this.state.tagName.meta.type === 1
              ? parseInt(this.state.defaultValue)
              : this.state.tagName.meta.type === 3
              ? !!this.state.defaultValue.value &&
                this.state.defaultValue.value != ""
                ? this.state.defaultValue.value
                : typeof this.state.defaultValue === "string"
                ? this.state.defaultValue
                : undefined
              : this.state.defaultValue,
          override: this.state.override,
          richText:
            this.state.tagName.meta.type === 3
              ? this.state.richText
              : undefined,
        },
      };
      switch (this.state.tagName.meta.type) {
        case 1:
          updatedTag.meta["from"] = parseInt(this.state.tagValue[0]);
          updatedTag.meta["to"] = parseInt(this.state.tagValue[1]);
          break;
        case 3:
          updatedTag.meta["values"] =
            this.state.tagName.meta?.options?.length > 0
              ? this.state.tagValue
                  .filter((item: any) => !!item.value)
                  .map((element: any) => element.value)
              : [];

          // TODO Need cleanup due to SR-1044 changes
          if (isOverridable) {
            updatedTag.meta.override = true;
          }
          break;
        case 4:
          delete updatedTag.meta.default;
          delete updatedTag.meta.override;
          updatedTag.meta["type"] = "content";
          updatedTag.meta["value"] = this.state.defaultValue.value;
          break;
      }
    }
    const updatedTags = [updatedTag, ...recipeTags];

    this.props.assignTags({
      restaurantuuid: this.props.match.params.uuid,
      recipeuuid: this.props.elementId,
      data:
        type === "delete"
          ? {
              tags: [...recipeTags],
            }
          : {
              tags: updatedTags,
            },
    });
    this._setStates("isUpdated", true);
  };

  public removeValue = (index: any, item?: any) => {
    let moreValues = this.state.moreValues;
    if (moreValues[index].value === item.defaultValue?.value) {
      item.defaultValue = "";
    }
    moreValues.splice(index, 1);
    this._setStates("moreValues", moreValues);
  };

  public handleTagChange = (
    event: any,
    name: any,
    index: any,
    richText?: boolean
  ) => {
    if (name === "tagName") {
      this._setStates("defaultError", "");
      this._setStates("tagName", event);
      this._setStates("tagValue", "");
      this._setStates("richText", false);
      this._setStates(
        "defaultValue",
        event.meta.type === 1 ? "" : event.meta.type === 2 ? false : ""
      );
      if (event.meta.type === 1)
        this._setStates("tagValue", [event.meta.from, event.meta.to]);
      else if (event.meta.type === 3) {
        this._setStates(
          "moreValues",
          event.meta?.options?.map((option: any, index: any) => {
            return {
              index: index,
              value: option,
            };
          })
        );

        this._setStates("tagValue", []);
      }
    } else if (name === "defaultValue") {
      this._setStates(
        "defaultValue",
        this.state.tagName.meta.type === 2 ? event.checked : event
      );
    } else {
      if (name === "range") {
        this._setStates("tagValue", event);
        if (
          (this.state.defaultValue &&
            Number(this.state.defaultValue) < Number(event[0])) ||
          Number(this.state.defaultValue) > Number(event[1])
        ) {
          this._setStates(
            "defaultError",
            "Default value should be in the range " +
              parseInt(this.state.tagValue[0]) +
              " to " +
              parseInt(this.state.tagValue[1])
          );
        } else {
          this._setStates("defaultError", "");
        }
      } else if (name === "text") {
        if (
          !event ||
          event?.findIndex(
            (value: any) => value.value === this.state.defaultValue.value
          ) === -1
        )
          this.setState({
            defaultValue: {},
          });
        this.setState({
          tagValue: event,
        });
      } else if (name === "freeText") {
        let moreValues: Array<any> = [];
        const defaultValue = index
          ? event
          : richText
          ? event
          : event.target.value; //.replace(/<[^>]*>?/gm, "");
        this.setState({
          defaultValue: defaultValue,
          moreValues: moreValues,
          richText: richText,
        });
      } else if (name === "content") {
        this.setState({
          defaultValue: event,
        });
      }
    }
  };

  public setActiveItem = (index: any) => {
    if (index === this.state.activeKey)
      this.setState({
        activeKey: "",
      });
    else
      this.setState({
        activeKey: index,
      });
  };

  handleSelectChange = (selectedOption: any) => {
    const selectedLabels: Array<any> =
      selectedOption != null ? selectedOption : [];
    this.setState({
      selectedFilterOptions: selectedLabels,
      parentLabels: [...this.state.parentLabels].map((item) => {
        return {
          ...item,
          isClicked: false,
        };
      }),
    });
    this.doInDataLabelfilter(selectedOption);
  };

  doInDataLabelfilter = (selectedOption: any) => {
    let labelFilter: any = [];
    if (
      this.props.type !== "newIngredientList" &&
      !this.props.isSupplierOffering
    ) {
      this.filterList = [];
      this.filterWithLabel = [];
      this.props.itemData.forEach((element: any) => {
        (!!selectedOption ? selectedOption : []).forEach((item: any) => {
          let found = element.label_uuids.find(
            (label: any) => label === item.value
          );
          if (!!found && found === item.value) {
            labelFilter.push(element);
          }
        });
      });
      this.filterList = labelFilter.filter(
        (a: any, b: any) => labelFilter.indexOf(a) === b
      );
      this.filterWithLabel = this.filterList;
      if (
        (!selectedOption && this.state.searchByName === "") ||
        (!!selectedOption &&
          selectedOption.length === 0 &&
          this.state.searchByName === "")
      ) {
        this.filterList = [];
        this.setState({
          searchList: this.props.itemData,
        });
      } else if (
        (!selectedOption && this.state.searchByName !== "") ||
        (!!selectedOption &&
          selectedOption.length === 0 &&
          this.state.searchByName !== "")
      ) {
        this.filterList = [];
        this.props.itemData.forEach((element: any) => {
          let nameLocale: any = commonService.applyLocalization(
            "restaurant",
            "name",
            element?.locales
          )["name"];
          if (
            nameLocale
              .toLowerCase()
              .indexOf(this.state.searchByName.toLowerCase()) > -1
          ) {
            this.filterList.push(element);
          }
        });
        this.setState({
          searchList: this.filterList,
        });
      } else if (this.state.searchByName !== "" && !!selectedOption) {
        let labelFilters: any = [];
        let filterWithLabels: any = [];
        this.props.itemData.forEach((element: any) => {
          (!!selectedOption ? selectedOption : []).forEach((item: any) => {
            let found = element.label_uuids.find(
              (label: any) => label === item.value
            );
            if (!!found && found === item.value) {
              labelFilters.push(element);
            }
          });
        });
        filterWithLabels = labelFilters.filter(
          (a: any, b: any) => labelFilters.indexOf(a) === b
        );
        this.filterList = [];
        filterWithLabels.forEach((element: any) => {
          let nameLocale: any = commonService.applyLocalization(
            "restaurant",
            "name",
            element?.locales
          )["name"];
          if (
            nameLocale
              .toLowerCase()
              .indexOf(this.state.searchByName.toLowerCase()) > -1
          ) {
            this.filterList.push(element);
          }
        });
        this.setState({
          searchList: this.filterList,
        });
      } else {
        this.setState({
          searchList: this.filterList,
        });
      }
    }
  };

  handleQuickLabelFilter = (label: any) => () => {
    const isNewSelection: boolean =
      this.state.selectedFilterOptions.length === 0 ||
      label.value !== this.state.selectedFilterOptions?.at(0).value;
    const selectedOption: Array<any> = isNewSelection ? [label] : [];
    this.setState({
      selectedFilterOptions: selectedOption,
      parentLabels: [...this.state.parentLabels].map((item) => {
        return {
          ...item,
          isClicked: isNewSelection ? item.value === label.value : false,
        };
      }),
    });

    this.doInDataLabelfilter(selectedOption);
  };

  filterHandler = () => {
    this.setState((prevState: any) => ({
      fiterFlag: !prevState.fiterFlag,
    }));
  };

  public getLabelList(allLabels: Array<any>) {
    const labelOptions: Array<any> = allLabels?.map((item: any) => {
      return {
        value: item.uuid,
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          item?.locales
        ).name,
        isParent: item.parent_labels.length === 0,
        isClicked: false,
        serviceSet: item?.service_set,
      };
    });
    const sortKey: Array<string> = this.props.isSupplierOffering
      ? ["serviceSet", "label"]
      : ["label"];
    this.options =
      labelOptions.length > 0 ? _.sortBy(labelOptions, sortKey) : [];
    this._setStates("parentLabels", [...this.options]);
  }

  getRelateUnitDropdowns = (
    unitId: string,
    allUnits: Array<any> = [],
    conversionFactor: number = 1,
    recipeId?: string
  ) => {
    const recipeUnit = Array.isArray(this.props.units)
      ? this.props.units?.find((unit: any) => {
          if (unit?.recipe_uuid)
            return unit.uuid === unitId && unit.recipe_uuid === recipeId;
          return unit.uuid === unitId && !unit?.recipe_uuid;
        })
      : [];

    if (!recipeUnit) return [];
    let units: Array<any> = [
      {
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          recipeUnit.locales
        )["name"],
        value: recipeUnit.uuid,
        symbol: commonService.applyLocalization(
          "restaurant",
          "name",
          recipeUnit.locales
        )["symbol"],
        conversionFactor,
      },
    ];
    const fromUnits = recipeUnit?.from_conversions;
    const toUnits = recipeUnit?.to_conversions;

    if (fromUnits?.length > 0) {
      fromUnits.forEach((from: any) => {
        if (
          (recipeId && from?.recipe_uuid && recipeId !== from.recipe_uuid) ||
          (!recipeId && from?.recipe_uuid)
        )
          return;
        const itsExist = allUnits?.filter(
          (allUnit: any) => allUnit?.value === from.toUnit
        );
        if (!itsExist?.length && recipeUnit.uuid !== from.toUnit) {
          const allUnitsMerged = _.concat(allUnits, units);
          const fromUnit: any = this.getRelateUnitDropdowns(
            from.toUnit,
            allUnitsMerged,
            conversionFactor / from.conversion_factor,
            recipeId
          );

          if (!!fromUnit) units = _.concat(units, fromUnit);
        }
      });
    }

    if (toUnits?.length > 0) {
      toUnits.forEach((to: any) => {
        if (
          (recipeId && to?.recipe_uuid && recipeId !== to.recipe_uuid) ||
          (!recipeId && to?.recipe_uuid)
        )
          return;
        const itsExist = allUnits?.filter(
          (allUnit: any) => allUnit?.value === to.fromUnit
        );
        if (!itsExist?.length && recipeUnit.uuid !== to.fromUnit) {
          const allUnitsMerged = _.concat(allUnits, units);
          const toUnit: any = this.getRelateUnitDropdowns(
            to.fromUnit,
            allUnitsMerged,
            conversionFactor * to.conversion_factor,
            recipeId
          );

          if (!!toUnit) units = _.concat(units, toUnit);
        }
      });
    }
    return _.uniqBy(units, function (e) {
      return e.value;
    });
  };
  getRelatedUnits = (unit: any, anchorUnit: any, recipeId: string) => {
    const isExist: boolean = this.stackedUnits.includes(unit.uuid);
    if (isExist) {
      return unit;
    }
    const allUnits: Array<any> = this.props.units;
    const item: any = unit;
    if (unit.to_conversions?.length > 0) {
      unit.to_conversions.forEach((to: any) => {
        if (
          this.stackedUnitsConversions[anchorUnit][to.fromUnit] ||
          (to?.recipe_uuid && to.recipe_uuid !== recipeId)
        )
          return;
        const toUnit = allUnits?.find(
          (unit: any) =>
            unit.uuid === to.fromUnit &&
            (!unit?.recipe_uuid ||
              (unit?.recipe_uuid && unit.recipe_uuid === recipeId))
        );

        if (toUnit && toUnit.uuid !== anchorUnit) {
          toUnit["conversionUuid"] = to.uuid;
          toUnit["isRelateTo"] = unit.uuid;
          toUnit["conversionFactor"] = to.conversion_factor;
          toUnit["quantity"] = to.quantity;
          toUnit["grossWeight"] = to.gross_weight;
          toUnit["isRecipeUnit"] = !!toUnit?.recipe_uuid;
          this.stackedUnitsConversions[anchorUnit][toUnit.uuid] = {
            ...to,
            ...toUnit,
          };

          this.getRelatedUnits(toUnit, anchorUnit, recipeId);
        }
      });
    }

    if (
      anchorUnit &&
      unit.from_conversions?.length > 0 &&
      !this.stackedUnits.includes(unit.uuid)
    ) {
      unit.from_conversions.forEach((from: any) => {
        if (
          this.stackedUnitsConversions[anchorUnit][from.toUnit] ||
          (from?.recipe_uuid && from.recipe_uuid !== recipeId)
        )
          return;
        const fromUnit = allUnits?.find(
          (unit: any) =>
            unit.uuid === from.toUnit &&
            (!unit?.recipe_uuid ||
              (unit?.recipe_uuid && unit.recipe_uuid === recipeId))
        );
        if (fromUnit && fromUnit.uuid !== anchorUnit) {
          fromUnit["conversionUuid"] = from.uuid;
          fromUnit["isRelateTo"] = unit.uuid;
          fromUnit["conversionFactor"] = 1 / from.conversion_factor;
          fromUnit["quantity"] = from.quantity;
          fromUnit["grossWeight"] = from.gross_weight;
          fromUnit["isRecipeUnit"] = !!fromUnit?.recipe_uuid;

          this.stackedUnitsConversions[anchorUnit][fromUnit.uuid] = {
            ...from,
            ...fromUnit,
          };

          this.getRelatedUnits(fromUnit, anchorUnit, recipeId);
        }
      });
    }
    return item;
  };

  public getSavedServiceSets = (serviceSets: Array<any>) => {
    const items: Array<any> = serviceSets?.map((serviceSet: any) => {
      const serviceSetDetails = Array.isArray(this.props.serviceset)
        ? this.props.serviceset?.filter(
            (ss: any) => ss.uuid === serviceSet.service_set_uuid
          )
        : [];
      if (serviceSetDetails?.length > 0) {
        serviceSet["name"] = commonService.applyLocalization(
          "restaurant",
          "name",
          serviceSetDetails[0].locales
        )["name"];
      }
      return serviceSet;
    });
    const sortedItems = items || [];
    return _.sortBy(sortedItems, [(item: any) => item.name]);
  };

  public getServiceSetOptions = (serviceSets: Array<any>) => {
    const options: Array<any> = [];
    if (!!Object.keys(serviceSets).length) {
      serviceSets?.forEach((serviceSet: any) => {
        let isItSaved: Array<any> = [];

        if (
          this.props.recipeServiceSet &&
          this.state.selectedServiceSetData.itemId
        ) {
          isItSaved = this.props.recipeServiceSet[
            this.state.selectedServiceSetData.itemId
          ]?.filter((set: any) => set.service_set_uuid === serviceSet.uuid);
        }
        options.push({
          label: commonService.applyLocalization(
            "restaurant",
            "name",
            serviceSet.locales
          )["name"],
          value: serviceSet.uuid,
          disabled: isItSaved?.length > 0,
        });
      });
    }
    return _.sortBy(options, [(option: any) => option.label]);
  };

  public handleDefaultTag = (field: any, elementId: string) => {
    this.props.setItemId(elementId);
    var isChecked = !this.props.itemsDetails
      .filter((element: any) => element.uuid === elementId)[0]
      ?.tags?.some((item: any) => {
        if (item.tag.key === field) {
          return true;
        }
      });
    let [selectedTag] = this.props.tags.filter((item: any) => {
      if (item.key === field) {
        return true;
      }
    });
    this.setState(
      {
        tagId: selectedTag.uuid,
        tagName: {
          label: commonService.applyLocalization(
            "restaurant",
            "name",
            selectedTag.locales
          )["name"],
          meta: selectedTag.meta,
          value: selectedTag.uuid,
        },
        tagValue: [],
        moreValues: [],
      },
      () => {
        this.saveTag(isChecked ? "add" : "delete", true);
      }
    );
  };

  handleSubstituteChange = (value: any, name: any) => {
    if (name === "substitute")
      this.setState({
        recipeSubstitute: value,
      });
    else {
      this.setState({
        recipeQuantity: value,
      });
    }
  };

  saveSubstitutes = () => {
    this.setState({
      showSubstituteModal: false,
    });
    if (this.state.recipeSubstituteUuid) {
      this.props.upDateRecipeSubstitute({
        productsubstituteuuid: this.state.recipeSubstitute.value,
        recipeuuid: this.props.elementId,
        restaurantuuid: this.props.match.params.uuid,
        data: {
          output_quantity: this.state.recipeQuantity,
        },
      });
    } else {
      this.props.saveRecipeSubstitute({
        uuid: this.state.recipeSubstitute.value,
        restaurantuuid: this.props.match.params.uuid,
        data: {
          recipe_uuid: this.props.elementId,
          output_quantity: this.state.recipeQuantity,
        },
      });
    }

    this.setState({
      isRecipeUpdated: true,
    });
  };

  public handleSubstituteDelete = (
    recipeSubstituteUuid: string,
    itemId: string
  ) => {
    this.props.setItemId(itemId);
    OpenDeleteModal().then(() => {
      this.props.removeRecipeSubstitute({
        productsubstituteuuid: recipeSubstituteUuid,
        recipeuuid: itemId,
        restaurantuuid: this.props.match.params.uuid,
      });
      this.setState({
        isRecipeUpdated: true,
      });
    });
  };

  handleItemAddModalOpen = () => {
    this.setState((prevState: any) => ({
      isAddItemModalOpen: !prevState.isAddItemModalOpen,
      color: "#FFFFFF",
      name: "",
      description: "",
      outputunit: "",
      outputquantity: "",
    }));
  };

  handleServiceSetDelete = (serviceSetId: string, elementId: string) => {
    OpenDeleteModal().then(() => {
      this.setState({
        isFetching: true,
      });
      this.updateServiceSet({ itemId: elementId, serviceSetId }, "DELETE");
    });
  };

  handleSubstituteValues = (data: any) => {
    this.setState({
      [data.target.name]: data.target.value,
    });
  };

  handleNewSubstitute = () => {
    let parent: any;
    if (this.state.selectedLabels) {
      parent = this.state.selectedLabels.map((item: any) => {
        return item.value;
      });
    }
    let data: any = {};
    data = {
      type: 3,
      output_unit_uuid: this.state.outputunituuid,
      output_quantity: this.state.outputquantity,
      color: this.state.color,
      label_uuid: parent,
      locales: {
        [this.props.restaurantLang[0].code]: {
          name: this.state.name,
          description: this.state.description,
          pos_name: this.state.posName,
          printer_name: this.state.printerName,
        },
      },
    };
    this.props.addSubstitute({
      restaurantuuid: this.props.match.params.uuid,
      data: data,
    });
    setTimeout(() => {
      this.hasNewSubstituteAdded = true;
      this.setState({
        isSubstituteListUpdated: true,
        isAddItemModalOpen: false,
      });
    }, 200);
  };

  public handleSubstituteModal = (recipeId: string, item?: any) => {
    this.props.setItemId(recipeId);
    this.setState((prevState: any) => ({
      recipeSubstituteUuid: item ? item.uuid : "",
      recipeSubstitute: item
        ? {
            label: commonService.applyLocalization(
              "restaurant",
              "name",
              item.locales
            )?.name,
            value: item.uuid,
          }
        : {},
      recipeQuantity: item ? item?.quantity : "",
      showSubstituteModal: !prevState.showSubstituteModal,
    }));
  };

  public handleLabelSelect = (selectedLabels: any) => {
    if (selectedLabels != null) {
      this.setState({
        selectedLabels,
      });
    } else {
      this.setState({
        selectedLabels: [],
      });
    }
  };

  public handleUnitChange = (e: any) => {
    this.props.units.forEach((unit: any) => {
      if (unit.uuid === e.target.value) {
        this._setStates("outputunituuid", unit.uuid);
      } else if (e.target.value === "") {
        this.setState({
          outputunituuid: "",
        });
      }
    });
  };

  public getAddModalLabel = () => {
    return {
      modalTitle: this.props.t("ingredient.addIngredientClass"),
    };
  };

  public handleSupplierChange = (selectedOption: any) => {
    const selectedSupplier: Array<any> =
      selectedOption != null ? selectedOption : [];
    this.setState({
      selectedSupplier: selectedSupplier,
    });
  };

  setUnitModal = (state: boolean) => {
    this.setState({
      isUnitModalOpen: state,
      unitModalPayload: ItemWrapperInitState.unitModalPayload,
    });
  };

  setUpdateList = () => {
    this.setState({
      updateList: true,
    });
  };

  handleFilterChanges = (prevState: any) => {
    if (
      !this.props.isBrowserNav &&
      (this.props.type === "newIngredientList" ||
        this.props.isSupplierOffering) &&
      (prevState.selectedFilterOptions !== this.state.selectedFilterOptions ||
        prevState.selectedSupplier !== this.state.selectedSupplier ||
        prevState.showArchived !== this.state.showArchived)
    ) {
      this.applyFilterToIngredientList();
    }
  };

  setOfferSupplier = (prevProps: any) => {
    if (
      prevProps.restaurantOptions?.length !==
      this.props.restaurantOptions?.length
    ) {
      if (this.props.restaurantOptions.length === 1) {
        const supplier = this.props.restaurantOptions.at(0);
        this.selectOfferingSupplier(supplier);
      }
    }
  };

  selectOfferingSupplier = (supplier: any) => {
    this.setState({
      offeringSupplier: supplier,
    });
    this.props.handleSelectChange(supplier);
  };

  applyFilterToIngredientList = () => {
    this.props.setFilterParams({
      labelArray: this.state.selectedFilterOptions,
      name: this.state.searchByName,
      supplierArray: this.state.selectedSupplier,
      showArchived: this.state.showArchived,
    });
    this.props.getItems(1, {
      labelArray: this.state.selectedFilterOptions,
      name: this.state.searchByName,
      supplierArray: this.state.selectedSupplier,
      showArchived: this.state.showArchived,
    });
    this.setState({
      updateList: true,
    });
  };

  addAllSupplierItems = () => {
    OpenConfirmModal({
      title: this.props.t("questions.addAllSupplierItems"),
    }).then((accepted) => {
      const payload: any = {
        restaurantuuid: this.props.match.params.uuid,
        data: {
          supplier: this.state.offeringSupplier?.value,
        },
      };
      importAllSupplierItems(RESTAURANT_REQUEST, payload).then((data) => {
        const responseData: any = handleResponse(data);
        if (responseData?.ok) {
          const supplierActiveImports = this.props.supplierActiveImports
            ? [...this.props?.supplierActiveImports, responseData.data.data]
            : [responseData.data.data];
          this.props?.handleChange({
            target: {
              name: "supplierActiveImports",
              value: supplierActiveImports,
            },
          });
        }
      });
    });
  };

  renderUtilityButtons = () => {
    const isBoxView =
      this.props.match.params?.pageViewMode === PAGE_VIEW_MODE.BOX_VIEW;

    return isBoxView
      ? this.renderLastOrdersButton()
      : this.renderAddAllButton();
  };

  renderAddAllButton = () => {
    const activeImport = this.props.supplierActiveImports?.find(
      (imp) => imp.supplier_restaurant === this.state.offeringSupplier?.value
    );
    const importPerc = activeImport
      ? Math.floor(
          (parseInt(activeImport.imported_items) * 100) /
            parseInt(activeImport.total_items)
        )
      : 0;
    const title = activeImport
      ? activeImport.status === 1
        ? this.props.t("common.scheduled")
        : `${this.props.t("common.started")} ${importPerc}%`
      : this.props.t("common.addAll");
    return (
      this.state.searchList.length > 0 && (
        <Button
          color="info"
          size="sm"
          onClick={this.addAllSupplierItems}
          disabled={activeImport ? true : false}
          title={title}
        >
          {this.props.t("common.addAll")}
        </Button>
      )
    );
  };

  renderLastOrdersButton = () => {
    return (
      <Link
        to={`/restaurant/${this.props.match.params.uuid}/reports/supplier-orders-for-timeframe/boxView`}
        target="_blank"
      >
        <Button color="info" size="sm">
          {this.props.t("common.myLastOrders")}
        </Button>
      </Link>
    );
  };

  render() {
    const { t } = this.props;
    const recipeSubstitutes =
      typeof this.props?.recipeSubstitutes !== "undefined"
        ? this.props.recipeSubstitutes.find(
            (item: any) => item.uuid === this.props.elementId
          )
        : [];
    return (
      <>
        <div>
          <ModalFactory />
          <LoaderComponent
            display={this.state.isFetching || this.props.isFetching}
          />
          <Link
            to="#"
            color="info"
            className="unit-add"
            onClick={this.props.onAddNewItem}
          >
            {this.props.pageText.addButtonTitle}
          </Link>
          {!!this.props?.extraNavButtons && this.props.extraNavButtons()}
        </div>
        <div className="white-box mb-3">
          {(this.props.area === "ingredient" ||
            this.props.area === "supplier-offering") && (
            <>
              <OrderModalCard
                handleChange={this.props.handleChange}
                handleTagChange={this.props.handleTagChange}
                isOpen={this.props.showOrderModal}
                saveOrder={this.props.saveOrder}
                selectedRecipe={this.props.selectedRecipe}
                toggle={this.props.toggleOrderModal}
                quantity={this.props.quantity}
                recipeDetails={this.props.itemsDetails}
                unitName={this.unitName}
                units={this.props.units}
                isSupplierOffering={this.props.isSupplierOffering}
                restaurantuuid={this.props.match.params.uuid}
              />
              <ShoppingCartModalCard
                clearShoppingCart={this.props.clearShoppingCart}
                deliveryDate={this.props.deliveryDate}
                orderComment={this.props.orderComment}
                editCartItem={this.props.editCartItem}
                handleDateChange={this.props.handleDateChange}
                handleCommentChange={this.props.handleCommentChange}
                isOpen={this.props.showCartModal}
                placeOrder={this.props.placeOrder}
                removeCartItem={this.props.removeCartItem}
                shoppingCartArray={this.props.shoppingCartArray}
                toggle={this.props.toggleCartModal}
                unitName={this.unitName}
              />
            </>
          )}
          {/* Image Upload Modal */}
          <ItemAddModal
            // area={this.props?.area}
            color={this.state.color}
            description={this.state.description}
            getRelatedUnits={(str: any) => {
              return [];
            }}
            handleCancel={this.handleItemAddModalOpen}
            handleChange={this.handleSubstituteValues}
            handleLabelSelect={this.handleLabelSelect}
            handleUnitChange={this.handleUnitChange}
            isFetching={this.state.isFetching}
            isModalOpen={this.state.isAddItemModalOpen}
            labelOptions={this.options}
            name={this.state.name}
            outputquantity={this.state.outputquantity}
            outputunituuid={this.state.outputunituuid}
            package_quantity={""}
            package_unit_uuid={""}
            printerName={this.state.printerName}
            saveItem={this.handleNewSubstitute}
            textLabels={this.getAddModalLabel()}
            units={this.props.units}
            replacementItem={this.state.replacementItem}
          />
          <TagAssignCard
            addMoreValues={this.addMoreValues}
            addTagModal={this.addTagModal}
            contentList={this.state.contentList}
            handleChange={this.handleChange.bind(this)}
            handleTagChange={this.handleTagChange}
            ingredientTags={this.props.tags}
            removeValue={this.removeValue}
            recipeId={this.props.elementId}
            saveTag={this.saveTag}
            setAdvanceEdits={this.setAdvanceEdits}
            showAddTag={this.state.showAddTag}
            state={{
              ...this.state,
              recipedetaildata: [this.props.recipeDetails],
            }}
            type="recipe"
          />
          <ServiceSetAssignCard
            showModal={this.state.showServiceSetModal}
            serviceSetData={this.state.selectedServiceSetData}
            serviceSetList={this.getServiceSetOptions(this.props.serviceset)}
            toggleModal={this.toggleServiceSetModal}
            updateServiceSet={this.updateServiceSet}
            units={this.props.units}
          />
          {(this.props.area === "ingredient" ||
            this.props.area === "recipe" ||
            this.props.area === "recipeDetails") &&
            typeof this.props?.recipeSubstitutes !== "undefined" && (
              <SubstituteAssignCard
                handleItemAddModalOpen={this.handleItemAddModalOpen}
                handleSubstituteChange={this.handleSubstituteChange}
                handleSubstituteModal={this.handleSubstituteModal}
                itemId={this.props.elementId}
                recipeSubstitutes={this.props.recipeSubstitutes
                  .find((item: any) => item.uuid === this.props.elementId)
                  ?.substitutes?.map((item: any) => {
                    return {
                      ...item,
                      ...this.props.substitutes.find(
                        (element: any) =>
                          element.uuid === item.product_substitute
                      ),
                      quantity: item.output_quantity,
                    };
                  })}
                recipeSubstituteUuid={this.state.recipeSubstituteUuid}
                recipeSubstitute={this.state.recipeSubstitute}
                recipeQuantity={this.state.recipeQuantity}
                showSubstituteModal={this.state.showSubstituteModal}
                saveSubstitutes={this.saveSubstitutes}
                substitutes={this.props.substitutes}
              />
            )}

          <UnitsAddCard
            handleChange={this.handleNewUnitChange}
            isModalOpen={this.state.isUnitModalOpen}
            payload={this.state.unitModalPayload}
            unitModalActions={this.handleNewUnitModalActions}
          />
          {/*  Edit Units  */}
          <UnitsEditCard
            state={this.state}
            toggle={this.toggle}
            getBaseUnitOptions={this.getBaseUnitOptions()}
            handleChange={this.handleChange}
            hasGrossWeight={true}
            saveEdit={this.saveEdit}
          />
          <div className="bf-sticky-filter position-sticky p-2">
            <h4>
              {this.props.pageText.pageTitle}
              {this.props.showItemCount
                ? this.props.type === "newIngredientList" ||
                  this.props.isSupplierOffering
                  ? "(" + this.props.totalCount + ")"
                  : "(" + this.state.searchList.length + ")"
                : ""}
              {!this.props?.isDetailPage && (
                <span onClick={this.filterHandler.bind(this)}>
                  <FontAwesomeIcon
                    className="cursor-pointer mt-2"
                    style={{
                      color: `${!!this.state.fiterFlag ? LIGHT_GREEN : GRAY}`,
                      fontSize: 20,
                    }}
                    title={t("common.filter")}
                    icon={faFilter}
                    pull="right"
                  />
                </span>
              )}
              {!this.props?.isDetailPage && this.props.isSupplierOffering && (
                <FontAwesomeIcon
                  className="cursor-pointer mt-2 mr-2"
                  style={{
                    color: `${
                      !!this.props.alphabeticalSortFlag ? LIGHT_GREEN : GRAY
                    }`,
                    fontSize: 20,
                  }}
                  title={t("common.sortAlphabetically")}
                  icon={faSortAlphaDown}
                  pull="right"
                  onClick={() => {
                    if (this.props.setAlphabeticalSortFlag)
                      this.props.setAlphabeticalSortFlag();
                  }}
                />
              )}
            </h4>
            {!this.props?.isDetailPage && this.props.isSupplierOffering && (
              <div className="w-100">
                {this.props.restaurantOptions.length === 1 ? (
                  this.props.restaurantOptions[0].label
                ) : (
                  <Select
                    className="mb-3"
                    onChange={this.selectOfferingSupplier}
                    options={this.props.restaurantOptions}
                    maxMenuHeight={200}
                    placeholder={t("common.selectSupplierRestaurant")}
                    isSearchable={
                      this.props.restaurantOptions?.length === 0 ? false : true
                    }
                    value={this.state.offeringSupplier}
                  />
                )}
              </div>
            )}
            {!this.props?.isDetailPage && (
              <>
                <Collapse isOpen={this.state.fiterFlag}>
                  <Card className="border-0 card-radius  mb-3 ">
                    <CardBody className="p-0 align-items-center flex-wrap">
                      {!this.props.hideFilter && (
                        <>
                          <Row className="g-0 pb-0">
                            <Col xs={12} sm={6} md={4} lg={3} className="my-2">
                              <Select
                                className="w-100"
                                value={this.state.selectedFilterOptions}
                                onChange={this.handleSelectChange}
                                options={this.options}
                                isMulti
                                maxMenuHeight={200}
                                placeholder={t("label.searchByLabel")}
                              />
                            </Col>

                            <Col xs={12} sm={6} md={4} lg={3} className="my-2">
                              <Input
                                className="mr-2"
                                type="search"
                                name="searchByName"
                                placeholder={t("label.searchByName")}
                                onChange={this.handleChange.bind(this)}
                                value={this.state.searchByName}
                                onKeyPress={this.handleNameKeyPress.bind(this)}
                              />
                            </Col>

                            {this.props.area === "ingredient" && (
                              <Col
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                className="my-2"
                              >
                                <Select
                                  className="mr-2"
                                  value={this.state.selectedSupplier}
                                  onChange={(value: any) =>
                                    this.handleSupplierChange(value)
                                  }
                                  options={_.sortBy(
                                    this.props.supplierOptions,
                                    "label"
                                  )}
                                  isMulti
                                  maxMenuHeight={200}
                                  placeholder={t("label.searchBySupplier")}
                                />{" "}
                              </Col>
                            )}
                            {!this.props.isSupplierOffering && (
                              <Col
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                className="my-2 pt-2"
                              >
                                <CustomInput
                                  checked={this.state.showArchived}
                                  label={t("label.showArchived")}
                                  id="showArchived"
                                  name="showArchived"
                                  onChange={this.handleChange.bind(this)}
                                  type="switch"
                                />
                              </Col>
                            )}
                          </Row>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Collapse>
                {this.state.parentLabels?.length > 0 &&
                  (!this.props.isSupplierOffering ||
                    (this.props.isSupplierOffering &&
                      this.state.offeringSupplier?.value)) && (
                    <Row className="pt-0">
                      <Col lg={12}>
                        {this.state.parentLabels.map(
                          (item: any, index: number) => {
                            return (
                              item.isParent && (
                                <>
                                  <Button
                                    className="mb-1 pt-0 pb-0"
                                    size="sm"
                                    key={index}
                                    color={
                                      item.isClicked ? "info" : "secondary"
                                    }
                                    onClick={this.handleQuickLabelFilter(item)}
                                  >
                                    {item.label}
                                  </Button>{" "}
                                </>
                              )
                            );
                          }
                        )}
                      </Col>
                    </Row>
                  )}
              </>
            )}
          </div>
          {this.props.isSupplierOffering &&
            this.state.offeringSupplier?.value && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  position: "relative",
                  width: "100%",
                }}
                className="pb-2"
              >
                <div
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "-60px",
                  }}
                >
                  {this.state.offeringSupplier?.branding?.restaurantLogo
                    ?.thumbnail && (
                    <img
                      className="img-home-fluid"
                      width={120}
                      src={
                        this.state.offeringSupplier?.branding?.restaurantLogo
                          ?.permalink
                      }
                      alt="Logo"
                    />
                  )}
                </div>
                {this.renderUtilityButtons()}
              </div>
            )}
          {!!this.state.searchList &&
            this.state.searchList.length > 0 &&
            this.state.searchList?.map((item: any, index: number) => {
              var recipeStorages: any = [];
              var cost = 0;
              if (this.props.type === "newIngredientList") {
                cost = this.props.ingredientsCost[item.uuid] || 0;
                recipeStorages = JSON.parse(
                  JSON.stringify(this.props.recipeStorage[item.uuid] || [])
                );
              }

              var recipeSubstitutes: any = [];
              var substituteArray = [];
              if (Object.keys(this.props.substitutes || {}).length > 0) {
                [recipeSubstitutes] =
                  Object.keys(this.props.recipeSubstitutes || {}).length > 0
                    ? this.props.recipeSubstitutes.filter(
                        (substitute: any) => substitute.uuid === item.uuid
                      )
                    : [];

                substituteArray =
                  recipeSubstitutes && Object.keys(recipeSubstitutes).length > 0
                    ? recipeSubstitutes?.substitutes?.map((item: any) => {
                        return {
                          ...item,
                          ...this.props.substitutes.find(
                            (element: any) =>
                              element.uuid === item.product_substitute
                          ),
                          quantity: item.output_quantity,
                        };
                      })
                    : [];
              }
              return (
                <React.Fragment key={index}>
                  <ItemCard
                    key={index}
                    activeKey={this.state.activeKey}
                    allRooms={
                      Object.keys(this.props.rooms || {}).length > 0
                        ? JSON.parse(JSON.stringify(this.props.rooms))
                        : []
                    }
                    addNewStorage={this.props.addNewStorage}
                    allStorage={this.props.allStorage}
                    area={this.props?.area}
                    cost={cost}
                    addTagModal={this.addTagModal.bind(this, item.uuid)}
                    contentList={this.state.contentList}
                    extraDetails={
                      this.props?.getExtraDetails
                        ? this.props.getExtraDetails(item)
                        : ""
                    }
                    editStorage={this.props.editStorage}
                    editKeyValue={this.props.editKeyValue}
                    getBestMatchUnits={this.props.getBestMatchUnits}
                    getSupplierDetails={this.props?.getSupplierDetails}
                    getUnitLabelName={this.props?.getUnitLabelName}
                    handleCostUpdate={this.props?.handleCostUpdate}
                    handleSelect={this.props.handleSelect}
                    handleIngredientImport={this.props.handleIngredientImport}
                    handleServiceSetDelete={this.handleServiceSetDelete}
                    hasCopy={this.props?.hasCopy}
                    hasDetails={this.props?.hasDetails}
                    handleDefaultTag={this.handleDefaultTag}
                    hasTag={this.props.hasTag}
                    handleSubstituteModal={this.handleSubstituteModal}
                    handleSubstituteDelete={this.handleSubstituteDelete}
                    isDetailPage={this.props.isDetailPage}
                    isFetching={this.props.isFetching}
                    isSupplierOffering={this.props.isSupplierOffering}
                    isUpdated={this.props.isUpdated}
                    labels={this.props.labels}
                    index={index}
                    ingredient={this.props.itemData}
                    itemData={item}
                    itemDetails={this.getItemDetails(item.uuid)}
                    onDeleteClick={this.onDeleteClick}
                    openOrderModal={this.props?.openOrderModal}
                    onItemCopy={this.props?.onItemCopy}
                    onItemDelete={this.props.onItemDelete}
                    onItemEdit={this.props.onItemEdit}
                    onMoveToDetailPage={this.props.onMoveToDetailPage}
                    removeStorage={this.props.removeStorage}
                    renderStatusBadge={this.props?.renderStatusBadge}
                    recipeServiceSets={
                      !this.props.isSupplierOffering
                        ? this.getSavedServiceSets(
                            _.cloneDeep(this.props.recipeServiceSet[item.uuid])
                          )
                        : []
                    }
                    recipeSubstitutes={substituteArray}
                    setActiveItem={this.setActiveItem}
                    setEditableConversion={this.setEditableConversion.bind(
                      this
                    )}
                    setImageEdit={this.setRecipeImageEdit}
                    showItemStatus={this.props?.showItemStatus}
                    storages={recipeStorages}
                    updateTagModel={this.updateTagModel}
                    tags={this.props?.tags}
                    type={this.props.type ? this.props.type : "recipe"}
                    units={this.props?.units}
                    unitCards={this.unitCards}
                    unitName={this.unitName.bind(this)}
                    updateList={this.state.updateList}
                    updateServiceSets={this.updateServiceSets}
                    importedItems={this.props.importedItems}
                    labelOptions={this.options}
                    fetchData={this.props.fetchData}
                    supplierInventoryInfo={this.props?.supplierInventoryInfo}
                    printerTemplates={this.props.printerTemplate}
                  />
                </React.Fragment>
              );
            })}
          {this.props.itemData.length === 0 && (
            <div style={{ width: "100%", textAlign: "center" }}>
              {this.props.t("common.noRecords")}
            </div>
          )}
          {this.props.hasPagination && this.props.totalPages !== 0 && (
            <div
              style={{ width: "100%", textAlign: "center" }}
              className="d-flex justify-content-between align-items-center"
            >
              {this.props.hasPagination && this.state.searchList.length > 0 && (
                <p className="m-0 text-right bf-font-size-14">
                  {this.props.t("common.showing")}{" "}
                  {(this.props.currentPage - 1) * 20 + 1}{" "}
                  {this.props.t("common.to")?.toLowerCase()}{" "}
                  {this.state.searchList.length > 20
                    ? (this.props.currentPage - 1) * 20 + 20
                    : (this.props.currentPage - 1) * 20 +
                      this.state.searchList.length}{" "}
                  {this.props.t("common.of")} {this.props.totalCount}
                </p>
              )}
              <PaginationComponent
                currentPage={this.props.currentPage}
                getItems={this.props.getItems}
                setCurrentPage={this.props.setCurrentPage}
                totalPages={this.props.totalPages}
                filterParams={this.props.filterParams}
                setUpdateList={this.setUpdateList}
              />
            </div>
          )}
        </div>
      </>
    );
  }

  public onDeleteClick = (type: string, id: string, itemId?: String) => {
    this.props.setItemId(itemId);
    OpenDeleteModal().then(() => {
      if (type == "tag") {
        this.setState(
          {
            tagId: id,
          },
          () => {
            this.saveTag("delete");
          }
        );
      } else if (type == "unit") {
        this.props.removeUnits({
          uuid: id,
          restaurantuuid: this.props.match.params.uuid,
        });
        this.setState({
          isUpdated: true,
        });
      }
    });
  };

  public saveNewUnit = () => {
    if (this.isItTempUnit) {
      this.tempUnit = {
        label: this.state.unitModalPayload.name,
        value: "NEW_TEMP_UNIT",
        symbol: this.state.unitModalPayload.symbol,
      };
      this.addUnitModalToggle(this.props.elementId);
    } else {
      const payload: any = {
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.unitModalPayload.name,
            symbol: this.state.unitModalPayload.symbol,
          },
        },
        uuid: "NEW_RECIPE_UNIT",
      };
      if (Number(this.state.unitModalPayload.grossWeight) > 0) {
        const gross_weight: number =
          (Number(this.state.unitModalPayload.grossWeight) || 0) *
          (this.state.unitModalPayload.grossWeightUnitSelection
            ?.conversionFactor || 1);
        payload["gross_weight"] = gross_weight;
      }
      this.props.handleUnitUpdate(payload, "save");
    }
  };

  public saveEdit = () => {
    const payload: any = {
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.state.unitId,
      data: {
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.name,
            symbol: this.state.symbol,
          },
        },
        recipe_uuid: this.props.elementId,
      },
    };
    if (Number(this.state.grossWeight) > 0) {
      const gross_weight: number =
        (Number(this.state.grossWeight) || 0) *
        (this.state.grossWeightUnitSelection?.conversionFactor || 1);
      payload.data["gross_weight"] = gross_weight;
    }
    this.props.updateUnits(payload);
    this._setStates("isUpdated", true);
  };

  public addNewItem = () => {};

  public updateServiceSets = (data: any) => {
    this.setState({
      selectedServiceSetData: data,
      showServiceSetModal: true,
    });
  };

  public addTagModal = (itemId: string) => {
    this.props.setItemId(itemId);

    this.setState((prevState: any) => ({
      defaultValue: false,
      moreValues: [{ index: "", value: "" }],
      override: false,
      richText: false,
      showAddTag: !prevState.showAddTag,
      tagId: "",
      tagName: "",
      tagValue: "",
    }));
  };

  public addUnitModalToggle = (itemId: string) => {
    this.props.setItemId(itemId);
    this.setState((prevState: any) => ({
      isUnitModalOpen: !prevState.isUnitModalOpen,
      unitModalPayload: ItemWrapperInitState.unitModalPayload,
    }));
  };

  public cancelUnitAdd = () => {
    this.isItTempUnit = false;
    this.addUnitModalToggle("");
  };

  public copyRecipeToggle(): void {
    this.setState((prevState: any) => ({
      copymodel: !prevState.copymodel,
    }));
  }

  public deleteRecipeToggle = () => {
    this.setState((prevState: any) => ({
      deleterecipemodal: !prevState.deleterecipemodal,
    }));
  };

  public deleteUnitToggle = () => {
    this.setState((prevState: any) => ({
      deleteUnit: !prevState.deleteUnit,
    }));
  };

  public deleteConversionToggle = () => {
    this.setState((prevState: any) => ({
      toggleDelete: !prevState.toggleDelete,
    }));
  };

  public getItemDetails(itemUuid: string) {
    const [data] = this.props.itemsDetails.filter((item: any) => {
      return item.uuid === itemUuid;
    });
    return data;
  }

  public setConversion = (event: any, itemId: any) => {
    this.props.setItemId(itemId);

    this.setState({
      uuid: event.uuid,

      errorMsg: "",
      otherUnit: "",
      parameter: "",
      recipeSpecific: event.recipe_uuid,
    });
    this.toggleModal();
  };

  public setConversionDelete(
    item: any,
    recipe_uuid: any,
    conversionId: any
  ): void {
    this.props.setItemId(recipe_uuid);
    OpenDeleteModal().then(() => {
      this.props.deleteConversion({
        conversionuuid: conversionId,
        restaurantuuid: this.props.match.params.uuid,
      });
      this.setState({
        isUpdated: true,
      });
    });
  }

  public setDelete(event: any, itemId: string): void {
    this.setState({ unitId: event.uuid });
    this.onDeleteClick("unit", event.uuid, itemId);
  }

  public setEditableConversion(
    event: any,
    itemId: string,
    type: any,
    unitId: any
  ): void {
    this.props.setItemId(itemId);

    let conversionFactor: string = "";
    let conversionKey: string = "";
    let conversionId: string = "";

    let key: string = "";

    let otherUnit: any = {};
    if (type == "convertTo") {
      key = "from_conversions";
      conversionKey = "toUnit";
    } else {
      key = "to_conversions";
      conversionKey = "fromUnit";
    }
    otherUnit = event[key]
      .filter((item: any) => {
        if (item.uuid === unitId) {
          conversionFactor =
            key == "to_conversions"
              ? 1 / item.conversion_factor
              : item.conversion_factor;
          conversionId = item.uuid;

          return item;
        }
      })
      .map((element: any) => {
        return {
          value: element[conversionKey],
          label: commonService.applyLocalization(
            "restaurant",
            "name",
            this.props.units.filter(
              (unit: any) => unit.uuid === element[conversionKey]
            )[0].locales
          )["name"],
        };
      });
    {
      /* TODO SR-74: Temporary fix for the JS calculation precision issue, need to be cleaned up in future */
    }
    this.setState({
      uuid: event.uuid,
      otherUnit: otherUnit[0],
      errorMsg: "",
      parameter:
        type == "convertTo"
          ? conversionFactor
          : String(conversionFactor).includes(".999")
          ? Math.round(Number(conversionFactor))
          : conversionFactor,
      conversion: [],
      conversionId: conversionId,
      recipeSpecific: event.recipe_uuid,
    });
  }

  public setRecipeDelete(event: any): void {
    this.setState({ recipeuuid: event.uuid });
    this.deleteRecipeToggle();
  }

  public setRecipeImageEdit = (event: any) => {
    this.props.setItemId(event.uuid);
    this.onShowMediaManager();
  };

  onShowMediaManager = async () => {
    let mediaItem = await this.getUuidFromMediaManager(this.state.filedata, [
      "image",
    ]);
    if (mediaItem) {
      this.props.handleImageUpdate(mediaItem.mediaUuid);
    }
  };

  public setUnitEditable = (unit: any, itemId: any) => {
    let grossWeightUnitSelection: any = null;
    const baseUnit: any = this.props.units?.find(
      (unit: any) => unit.uuid === this.props.settings.base_weight_unit
    );
    if (baseUnit) {
      let unitLocale = commonService.applyLocalization(
        "restaurant",
        "name",
        baseUnit.locales
      );
      grossWeightUnitSelection = {
        value: baseUnit.uuid,
        label: unitLocale.name,
      };
    }
    this.props.setItemId(itemId);
    let unitLocale = commonService.applyLocalization(
      "restaurant",
      "name",
      unit.locales
    );
    this.setState((prevState: any) => ({
      ...prevState,
      grossWeight: unit?.gross_weight ? String(unit.gross_weight) : "",
      grossWeightUnitSelection: grossWeightUnitSelection,
      name: unitLocale.name,
      symbol: unitLocale.symbol,
      unitId: unit.uuid,
    }));
    this.toggle();
  };

  public toggle = () => {
    this.setState((prevState: any) => ({
      editmodal: !prevState.editmodal,
    }));
  };

  public toggleModal = () => {
    let showModal = this.state.showModal;
    this.setState({ showModal: !showModal });
  };

  public toggleServiceSetModal = () => {
    this.setState({ showServiceSetModal: !this.state.showServiceSetModal });
  };

  onNewUnitSelection = (itemId: string) => {
    this.isItTempUnit = true;
    this.addUnitModalToggle(itemId);
  };

  public saveConversion = (
    formData: {
      quantity?: number;
      conversion_factor: string;
      from_unit: string;
      grossWeight: string;
      grossWeightUnit: any;
      to_unit: string;
      uuid?: string;
      sku?: string;
      barcode: string;
    },
    itemId: string
  ) => {
    if (formData.from_unit === "NEW_TEMP_UNIT") {
      this.saveTempUnit(formData, itemId);
      return;
    }

    let body: any = {
      data: {
        quantity: formData?.quantity,
        conversion_factor: formData.conversion_factor,
        from_unit: formData.from_unit,
        to_unit: formData.to_unit,
        recipe_uuid: itemId,
        sku: formData.sku,
        barcode: formData.barcode,
      },
      restaurantuuid: this.props.match.params.uuid,
    };
    if (formData.grossWeight) {
      const grossWeight =
        Number(formData.grossWeight) *
        formData.grossWeightUnit.conversionFactor;
      body.data["gross_weight"] = grossWeight;
    }
    if (!!formData.uuid) {
      body.conversionuuid = formData.uuid;
      this.props.editConversion(body);
    } else {
      this.props.addConversion(body);
    }
    this.props.cardItemSelectionData.deselectCardItem();
    this._setStates("isUpdated", true);
  };

  saveTempUnit = (
    formData: {
      conversion_factor: string;
      from_unit: string;
      grossWeight: string;
      to_unit: string;
      uuid?: string;
    },
    itemId: string
  ) => {
    const conversionData: any = formData;
    const payLoad = {
      credentials: {
        restaurantuuid: this.props.match.params.uuid,
        data: {
          locales: {
            [this.props.restaurantLang[0].code]: {
              name: this.tempUnit.label,
              symbol: this.tempUnit.symbol,
            },
          },
          recipe_uuid: this.props.elementId,
        },
      },
    };
    addUnitService(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        conversionData.from_unit = response.data.data.uuid;
        this.setState({ isFetching: false });
        this.saveConversion(conversionData, itemId);
      })
      .catch(() => {});
  };

  public unitCards = (allItemUnits: any, itemId: string) => {
    let anchorUnits: Array<any> = [];
    const unitList: Array<any> = [];
    let units;
    const itemDetails: any = this.props.itemData.find(
      (item: any) => item.uuid === itemId
    );
    if (!!allItemUnits && allItemUnits.length > 0) {
      this.stackedUnits = [];
      let stackedConversions: Array<any> = [];
      allItemUnits.forEach((unit: any) => {
        if (!unit?.from_conversions && !unit?.to_conversions) {
          const isExist: boolean = this.stackedUnits.includes(unit.uuid);
          if (!isExist) {
            anchorUnits.push(unit);
            this.stackedUnits.push(unit.uuid);
          }
        } else if (!unit?.from_conversions) {
          const isExist: boolean = this.stackedUnits.includes(unit.uuid);
          if (!isExist) {
            this.stackedUnitsConversions[unit.uuid] = {};

            const newUnits: any = _.cloneDeep(
              this.getRelatedUnits(unit, unit.uuid, itemId)
            );

            this.stackedUnits.push(unit.uuid);
            const conversions: Array<any> = Object.keys(
              this.stackedUnitsConversions[unit.uuid]
            ).map(
              (key: string) => this.stackedUnitsConversions[unit.uuid][key]
            );
            newUnits["conversions"] = conversions;

            const conversionsIds: Array<string> = conversions.map(
              (conversion: any) => conversion.uuid
            );
            stackedConversions = [...stackedConversions, ...conversionsIds];
            if (!stackedConversions.includes(newUnits.uuid))
              anchorUnits.push(newUnits);
          }
        } else {
          if (unit.uuid === itemDetails?.output_unit_uuid) {
            const isExist: boolean = this.stackedUnits.includes(unit.uuid);
            if (!isExist) {
              this.stackedUnitsConversions[unit.uuid] = {};
              const newUnits: any = _.cloneDeep(
                this.getRelatedUnits(unit, unit.uuid, itemId)
              );
              this.stackedUnits.push(unit.uuid);
              const conversions: Array<any> = Object.keys(
                this.stackedUnitsConversions[unit.uuid]
              ).map(
                (key: string) => this.stackedUnitsConversions[unit.uuid][key]
              );
              newUnits["conversions"] = conversions;
              const conversionsIds: Array<string> = conversions.map(
                (conversion: any) => conversion.uuid
              );
              stackedConversions = [...stackedConversions, ...conversionsIds];
              if (!stackedConversions.includes(newUnits.uuid))
                anchorUnits.push(newUnits);
            }
          }
        }
      });

      if (!!anchorUnits && anchorUnits.length > 0) {
        anchorUnits.forEach((unit: any) => {
          unit["nameLower"] = commonService
            .applyLocalization("restaurant", "name", unit.locales)
            ["name"].toLowerCase();
          unitList.push(unit);
        });
      }
      units = _.sortBy(unitList, "nameLower").map((unit: any) => {
        return (
          <UnitsCard
            key={"unit" + unit.uuid}
            allAnchorUnits={this.getAllUnits(unitList)}
            allUnits={this.props.units}
            area={"recipe"}
            baseWeightUnit={this.props.settings.base_weight_unit}
            restaurantCode={this.props.settings?.settings?.restaurant_code}
            cardItemSelectionData={this.props.cardItemSelectionData}
            getBaseUnitOptions={this.getBaseUnitOptions()}
            handleUnitUnitEdit={(formData: any) =>
              this.handleUnitUnitEdit(formData, itemId)
            }
            isItActiveUnit={unit.uuid === itemDetails?.output_unit_uuid}
            isSupplierOffering={this.props.isSupplierOffering}
            onConversionCancel={this.handleConversionCancel}
            onNewUnitSelection={() => this.onNewUnitSelection(itemId)}
            saveConversion={(payload: any) =>
              this.saveConversion(payload, itemId)
            }
            setConversion={this.setConversion.bind(this, unit, itemId)}
            setConversionDelete={this.setConversionDelete.bind(
              this,
              unit,
              itemId
            )}
            setDelete={this.setDelete.bind(this, unit, itemId)}
            setEditable={this.setUnitEditable.bind(this, unit, itemId)}
            setEditableConversion={this.setEditableConversion.bind(
              this,
              unit,
              itemId
            )}
            showModal={this.state.showModal}
            unit={unit}
            unitName={this.unitName}
            tempUnit={this.tempUnit}
          />
        );
      });
      return units;
    }
  };

  public unitName = (unitId: any, type: any) => {
    let name: any = "";
    name =
      this.props.units && Object.keys(this.props.units).length > 0
        ? this.props.units?.filter((unit: any) => unit.uuid === unitId)
        : [];
    const locales: any = commonService.applyLocalization(
      "restaurant",
      "name",
      name[0]?.locales
    );
    let unitName: string = locales["name"];
    if (type === "fullUnit") {
      unitName += `(${locales["symbol"]})`;
    } else if (type === "symbol") {
      unitName = locales["symbol"];
    }
    return unitName;
  };

  public updateServiceSet = (formData: any, type: string) => {
    this.setState({ isFetching: true });
    let minorUnit = JSON.parse(
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
    )?.minor_units;

    let serviceSets =
      _.cloneDeep(this.props.recipeServiceSet?.[formData.itemId] || []) || [];
    if (type === "NEW") {
      const currentItem: any = {
        uuid: "",
        price: Number(
          (parseFloat(formData.price) * Math.pow(10, minorUnit))?.toFixed(
            minorUnit
          ) || 0
        ),
        appear_on_bill: true,
        selectable: true,
        service_set_uuid: formData.serviceSet.value,
        status: formData.status ? 1 : 0,
        offered_unit: formData.offeredUnit,
      };
      serviceSets.push(currentItem);
    } else if (type === "DELETE") {
      serviceSets = serviceSets.filter(
        (ss: any) => ss.uuid !== formData.serviceSetId
      );
    } else if (type === "EDIT") {
      const [currentServiceSet] = serviceSets.filter(
        (ss: any) => ss.uuid === formData.selectedServiceSetId
      );
      if (!!currentServiceSet) {
        currentServiceSet["price"] = Number(
          (parseFloat(formData.price) * Math.pow(10, minorUnit))?.toFixed(
            minorUnit
          ) || 0
        );
        currentServiceSet["status"] = formData.status ? 1 : 0;
        currentServiceSet["offered_unit"] = formData.offeredUnit;
      }
    }
    serviceSets = serviceSets.map((serviceSet: any) => {
      delete serviceSet.currency;
      return serviceSet;
    });
    updateServiceSetRecipe(RESTAURANT_REQUEST, {
      credentials: {
        restaurantuuid: this.props.match.params.uuid,
        uuid: formData?.itemId,
        data: { service_sets: serviceSets },
      },
    })
      .then((response: any) => {
        handleResponse(response);
        if (response.data.status) {
          this.setState({
            showServiceSetModal: false,
          });
        }
        this.props.getUpdatedCostPrices(formData?.itemId);
        this.setState({
          isFetching: false,
        });
      })
      .catch((error: any) => {
        this.setState({
          isFetching: false,
        });
        commonService.toastService("", "danger", JSON.stringify(error.error));
      });
  };

  public updateTagModel = (tag: any, itemId: string) => {
    this.props.setItemId(itemId);
    let [content] = this.state.contentList.filter(
      (content: any) => content.value === tag.recipe_tag_meta.value
    );

    this.setState((prevState: any) => ({
      defaultValue:
        tag.tag?.meta.type === 3 && tag?.recipe_tag_meta?.values?.length > 0
          ? {
              label: tag?.recipe_tag_meta?.default,
              value: tag?.recipe_tag_meta?.default,
            }
          : tag.tag.meta.type === 4
          ? content
          : tag.recipe_tag_meta?.default,
      moreValues:
        tag.tag?.meta.type === 3 &&
        tag.tag.meta.options?.map((item: any, index: any) => ({
          index: index,
          value: item,
        })),
      override: tag.recipe_tag_meta?.override,
      showAddTag: !prevState.showAddTag,
      tagId: tag?.tag?.uuid,
      tagName: {
        value: tag?.tag?.uuid,
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          tag?.tag?.locales
        )["name"],
        meta: tag.tag?.meta,
      },
      tagValue:
        tag.tag.meta.type === 1
          ? [tag.recipe_tag_meta.from, tag.recipe_tag_meta.to]
          : tag.tag.meta.type === 3
          ? tag.recipe_tag_meta?.values.map((value: any) => {
              return {
                label: value,
                value: value,
              };
            })
          : undefined,
      richText: tag.recipe_tag_meta.richText,
    }));
  };

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  setAdvanceEdits = (enableAdvanceEdits: boolean) => {
    this.setState((prevState: any) => ({
      richText: enableAdvanceEdits,
    }));
  };
}

const mapStateToProps: any = (state: any) => {
  // TODO Conversion add loader is not working, Need to fix
  let isFetching =
    state.contents.isFetching ||
    state.units.isFetching ||
    state.unitsremove.isFetching ||
    state.unitsadd.isFetching ||
    state.unitsupdate.isFetching ||
    state.conversionAdd.isFetching ||
    state.conversionEdit.isFetching ||
    state.conversionDelete.isFetching ||
    state.recipeSubstitute.isFetching ||
    state.recipeSubstituteAdd.isFetching ||
    state.recipeSubstituteUpdate.isFetching ||
    state.recipeSubstituteRemove.isFetching ||
    state.serviceset.isFetching ||
    state.settings.isFetching ||
    state.tags.isFetching ||
    state.tagAssign.isFetching ||
    state.upload.isFetching ||
    state.printerTemplate.isFetching;
  let failure =
    state.contents.failure ||
    state.units.failure ||
    state.unitsremove.failure ||
    state.unitsadd.failure ||
    state.unitsupdate.failure ||
    state.conversionEdit.failure ||
    state.conversionDelete.failure ||
    state.recipeSubstitute.failure ||
    state.recipeSubstituteAdd.failure ||
    state.recipeSubstituteUpdate.failure ||
    state.recipeSubstituteRemove.failure ||
    state.serviceset.failure ||
    state.settings.failure ||
    state.tags.failure ||
    state.tagAssign.failure ||
    state.upload.failure ||
    state.printerTemplate.failure;

  return {
    isFetching: isFetching,
    failure: failure,
    contents: state.contents.data,
    conversionDelete: state.conversionDelete.data,
    labels: state.labels.data,
    recipeDetails: state.recipedetails.data,
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    settings: state.settings.data,
    tags: state.tags.data,
    tagAssign: state.tagAssign.data,
    upload: state.upload.data,
    unitsAdd: state.unitsadd.data,
    unitsRemove: state.unitsremove.data,
    unitsUpdate: state.unitsupdate.data,
    serviceset: state.serviceset.data,
    printerTemplate: state.printerTemplate.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    addConversion: (credentials: any) => {
      dispatch(conversionAdd(credentials));
    },
    addSubstitute: (credentials: any) => {
      dispatch(recipeAdd(credentials));
    },
    addUnits: (credentials: any) => {
      dispatch(unitsAdd(credentials));
    },
    assignTags: (credentials: any) => {
      dispatch(tagAssign(credentials));
    },
    editConversion: (credentials: any) => {
      dispatch(conversionEdit(credentials));
    },
    deleteConversion: (credentials: any) => {
      dispatch(conversionDelete(credentials));
    },
    getRecipeDetail: (credentials: any) => {
      dispatch(recipeDetailsRequest(credentials));
    },
    getRecipeSubstitutes: (credentials: any) => {
      dispatch(recipeSubstituteRequest(credentials));
    },
    getContent: (credentials: any) => {
      dispatch(contentManagementRequest(credentials));
    },
    getLabel: (credentials: any) => {
      dispatch(labelRequest(credentials));
    },
    getServiceSet: (credentials: any) => {
      dispatch(servicesetRequest(credentials));
    },
    getSettings: (credentials: any) => {
      dispatch(settingsRequest(credentials));
    },
    getTags: (credentials: any) => {
      dispatch(tagsRequest(credentials));
    },
    getUnits: (credentials: any) => {
      dispatch(unitsRequest(credentials));
    },
    removeUnits: (credentials: any) => {
      dispatch(unitsRemove(credentials));
    },
    removeRecipeSubstitute: (credentials: any) => {
      dispatch(recipeSubstituteRemove(credentials));
    },
    saveRecipeSubstitute: (credentials: any) => {
      dispatch(recipeSubstituteAdd(credentials));
    },
    updateRecipe: (credentials: any) => {
      dispatch(recipeUpdate(credentials));
    },
    updateUnits: (credentials: any) => {
      dispatch(unitsUpdate(credentials));
    },
    upDateRecipeSubstitute: (credentials: any) => {
      dispatch(recipeSubstituteUpdate(credentials));
    },
    paymentProvider: (credentials: any) => {
      dispatch(paymentProviderRequest(credentials));
    },
    getPrinterTemplates: (credentials: any) => {
      dispatch(printerTemplateRequest(credentials));
    },
  };
};

const enhance = compose(
  withCardItemSelectionStateManager("Units"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);

export default withTranslation()(enhance(ItemWrapper));

// export default withTranslation()(
//   connect(mapStateToProps, mapDispatchToProps)(withRouter(ItemWrapper))
// );
