import { call, put, takeLatest } from "redux-saga/effects";

import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  assembleTaskLocalesFailure,
  assembleTaskLocalesSuccess,
  settingsAddFailure,
  settingsAddSuccess,
  settingsFailure,
  settingsSuccess,
  stationsAddFailure,
  stationsAddSuccess,
  stationsFailure,
  stationsOperationModeUpdateFailure,
  stationsOperationModeUpdateSuccess,
  stationsRemoveFailure,
  stationsRemoveSuccess,
  stationsSuccess,
  stationsUpdateFailure,
  stationsUpdateSuccess,
} from "../redux/actions/stations.action";
import * as types from "../redux/actions/types";
import handleResponse from "../services/response.service";
import stations from "../services/stations.service";

const stationsCall = (params: any) =>
  stations.stations(RESTAURANT_REQUEST, params);
const updateStationsCall = (params: any) =>
  stations.updateStations(RESTAURANT_REQUEST, params);
const addStationsCall = (params: any) =>
  stations.addStations(RESTAURANT_REQUEST, params);
const removeStationsCall = (params: any) =>
  stations.removeStations(RESTAURANT_REQUEST, params);
const updateStationsOperationModeCall = (params: any) =>
  stations.updateStationsOperationMode(RESTAURANT_REQUEST, params);
const settingsCall = (params: any) =>
  stations.getSettings(RESTAURANT_REQUEST, params);
const assembleTaskLocalesCall = (params: any) =>
  stations.getAssembleTaskLocales(RESTAURANT_REQUEST, params);
const addSettingsCall = (params: any) =>
  stations.addSettings(RESTAURANT_REQUEST, params);

function* handleStationsRequest(params: object) {
  try {
    let result;
    result = yield call(stationsCall, params);
    yield handleStations200(result);
  } catch (error) {
    throw error;
  }
}

const handleStations200 = function* handleStations200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(stationsSuccess(result.data));
  } else {
    yield put(stationsFailure(result.data));
    handleResponse(result);
  }
};

function* handleStationsUpdate(params: object) {
  try {
    let result;
    result = yield call(updateStationsCall, params);
    yield handleStationsUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleStationsUpdate200 = function* handleStationsUpdate200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(stationsUpdateSuccess(result.data));
  } else {
    yield put(stationsUpdateFailure(result.data));
  }
  handleResponse(result);
};

function* handleStationsAdd(params: object) {
  try {
    let result;
    result = yield call(addStationsCall, params);
    yield handleStationsAdd200(result);
  } catch (error) {
    throw error;
  }
}

const handleStationsAdd200 = function* handleStationsAdd200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(stationsAddSuccess(result.data));
  } else {
    yield put(stationsAddFailure(result.data));
  }
  handleResponse(result);
};

function* handleStationsRemove(params: object) {
  try {
    let result;
    result = yield call(removeStationsCall, params);
    yield handleStationsRemove200(result);
  } catch (error) {
    throw error;
  }
}

const handleStationsRemove200 = function* handleStationsRemove200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(stationsRemoveSuccess(result.data));
  } else {
    yield put(stationsRemoveFailure(result.data));
  }
  handleResponse(result);
};

function* handleStationsOperationModeUpdate(params: object) {
  try {
    let result;
    result = yield call(updateStationsOperationModeCall, params);
    yield handleStationsOperationModeUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleStationsOperationModeUpdate200 =
  function* handleStationsOperationModeUpdate200(result: any) {
    if (!result) {
      return;
    }
    if (result.status === 200) {
      yield put(stationsOperationModeUpdateSuccess(result.data));
    } else {
      yield put(stationsOperationModeUpdateFailure(result.data));
    }
    handleResponse(result);
  };

function* handleSettingsRequest(params: object) {
  try {
    let result;
    result = yield call(settingsCall, params);
    yield handleSettings200(result);
  } catch (error) {
    throw error;
  }
}

function* handleAssembleTaskLocalesRequest(params: object) {
  try {
    let result;
    result = yield call(assembleTaskLocalesCall, params);
    yield handleAssembleTaskLocales200(result);
  } catch (error) {
    throw error;
  }
}
const handleAssembleTaskLocales200 = function* handleAssembleTaskLocales200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(assembleTaskLocalesSuccess(result.data));
  } else {
    yield put(assembleTaskLocalesFailure(result.data));
    handleResponse(result);
  }
};

const handleSettings200 = function* handleSettings200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(settingsSuccess(result.data));
  } else {
    yield put(settingsFailure(result.data));
    handleResponse(result);
  }
};

function* handleSettingsAdd(params: object) {
  try {
    let result;
    result = yield call(addSettingsCall, params);
    yield handleSettingsAdd200(result);
  } catch (error) {
    throw error;
  }
}

const handleSettingsAdd200 = function* handleSettingsAdd200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(settingsAddSuccess(result.data));
  } else {
    yield put(settingsAddFailure(result.data));
  }
  handleResponse(result);
};

export default function* stationsSaga() {
  yield takeLatest(
    types.ASSEMBLE_TASK_LOCALES.REQUEST,
    handleAssembleTaskLocalesRequest
  );
  yield takeLatest(types.STATIONS.REQUEST, handleStationsRequest);
  yield takeLatest(types.STATIONS_UPDATE.REQUEST, handleStationsUpdate);
  yield takeLatest(types.STATIONS_ADD.REQUEST, handleStationsAdd);
  yield takeLatest(types.STATIONS_REMOVE.REQUEST, handleStationsRemove);
  yield takeLatest(
    types.STATIONS_OPERATION_MODE_UPDATE.REQUEST,
    handleStationsOperationModeUpdate
  );
  yield takeLatest(types.SETTINGS.REQUEST, handleSettingsRequest);
  yield takeLatest(types.SETTINGS_ADD.REQUEST, handleSettingsAdd);
}
