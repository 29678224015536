import apiCall from './api.call.config';

async function getTaskInterventionIngredients(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.get(url + '/' + params.credentials.restaurantuuid + '/recipe/' + params.credentials.recipeuuid
            + '/task/' + params.credentials.taskuuid + '/intervention/' + params.credentials.interventionuuid + 'ingredient');
    } catch (e) {
        throw e;
    }
}

async function addTaskInterventionIngredients(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url + '/' + params.credentials.restaurantuuid + '/recipe/' + params.credentials.recipeuuid + '/task/'
            + params.credentials.taskuuid + '/intervention/' + params.credentials.interventionuuid
            + '/ingredient/manage', params.credentials.data);
    } catch (e) {
        throw e;
    }
}

export default {
    getTaskInterventionIngredients,
    addTaskInterventionIngredients,
}
