import { call, put, takeLatest } from 'redux-saga/effects';

import { USER_REQUEST } from '../config/api.config';
import { changePasswordFailure, changePasswordSuccess } from '../redux/actions/change.password.action';
import * as types from '../redux/actions/types';
import changePassword from '../services/change.password.service';
import handleResponse from '../services/response.service';

const changePasswordCall = (params: any) => changePassword.changePassword(USER_REQUEST, params);

function* handleChangePassword(params: object) {
    try {
        let result;
        result = yield call(changePasswordCall, params);
        yield handlechangePassword200(result);
    } catch (error) {
        throw error;
    }
}

const handlechangePassword200 = function* handlechangePassword200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(changePasswordSuccess(result.data));
    } else {
        yield put(changePasswordFailure(result.data));
    }
    handleResponse(result);
}

export default function* changePasswordSaga() {
    yield takeLatest(types.CHANGE_PASSWORD.REQUEST, handleChangePassword);
}
