import React, { Component } from 'react';

import PrinterProtocolComponent from '../../components/printer-protocol/printer-protocol';

class PrinterProtocolPage extends Component {

    render() {
        return (
            <PrinterProtocolComponent/>
        );
    }
}
export default PrinterProtocolPage;
