import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import "../../../styles/media-manager.scss";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  mediaSelector,
  fetchMediaIfRequired,
  postMedia,
  fetchPaginatedMedia,
} from "../../../database/media.database";
import LoaderComponent from "../../loader/loader";
import { createModal } from "react-modal-promise";
import Dropzone, { useDropzone } from "react-dropzone";
import commonService from "../../../services/common.service";
import PaginationComponent from "../../service-item/shared/pagination";
import { Translation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";

export const OpenMediaManager = async (props: object) => {
  const res = await myMediaManagerCard({
    ...props,
  });
  return res;
};

interface states {
  currentPage: number;
  selectedMediaItem: any;
  selectedMediaItemUuid: string;
  init: boolean;
  isFetching: boolean;
}
class MediaManagerCard extends Component<any, any> {
  state: states;
  isUpdated: boolean = false;
  constructor(props: any) {
    super(props);
    this._setFunctionBindings();

    this.state = {
      currentPage: Number(this.props?.media?.data?.pageNo) || 1,
      selectedMediaItem: null,
      selectedMediaItemUuid: "",
      init: false,
      isFetching: false,
    };
  }

  private _setFunctionBindings() {
    this.onModalOk = this.onModalOk.bind(this);
    this.onModalCancel = this.onModalCancel.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.prepareDataForRendering = this.prepareDataForRendering.bind(this);
    this.onMediaItemClick = this.onMediaItemClick.bind(this);
  }

  componentDidMount() {
    const filters = `pageNo=${this.props.apiFilters.pageNo}&pageSize=${this.props.apiFilters.pageSize}&filter=${this.props.apiFilters.filter}`;
    this.props.fetchMediaIfRequired(
      this.props.restaurantUuid,
      filters,
      this.props.entityType
    );
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      Number(this.props.media.data.total) ===
        Number(prevProps.media.data.total) &&
      this.isUpdated
    ) {
      this.getPaginatedItems(this.state.currentPage);
    }
  }

  static getDerivedStateFromProps(props: any, prevState: any): any {
    if (!prevState.init && props.predefinedMediaUuid) {
      return { selectedMediaItemUuid: props.predefinedMediaUuid, init: true };
    } else if (!prevState.init) {
      return { selectedMediaItemUuid: "", init: true };
    }
  }

  getPaginatedItems = (pageNo: any, filter?: any) => {
    if (this.state.currentPage === pageNo && !this.isUpdated) {
      return;
    }
    this.isUpdated = false;
    const filters = `pageNo=${pageNo}&pageSize=${this.props.apiFilters.pageSize}&filter=${this.props.apiFilters.filter}`;
    this.props.fetchPaginatedMedia(
      this.props.restaurantUuid,
      filters,
      this.props.entityType
    );
    this.setState({
      currentPage: pageNo,
      isFetching: false,
    });
  };

  handlePostUpload = () => {
    this.isUpdated = true;
  };
  private renderDropzone(filter: any) {
    let acceptFilter = "";
    if (!filter || filter.length === 0) {
      acceptFilter = "image/*, video/*";
    } else {
      for (let i = 0; i < filter.length; i++) {
        acceptFilter += filter[i] + "/*, ";
      }
    }
    return (
      <Dropzone accept={acceptFilter}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <p>
                <Translation>
                  {(t, { i18n }) => t("mediaManager.dragAndDropFiles")}
                </Translation>{" "}
              </p>
            </div>
          </section>
        )}
      </Dropzone>
    );
  }

  private async onUpload(
    imageFiles: any,
    videoFiles: any,
    files: Array<any>,
    entityType: string
  ) {
    return this.props.postMedia(
      this.props.restaurantUuid,
      imageFiles,
      videoFiles,
      files,
      entityType
    );
  }

  private onMediaItemClick(item: any, e: any) {
    this.setState({
      selectedMediaItem: item,
      selectedMediaItemUuid: item.uuid,
    });
  }

  private onModalCancel() {
    this.props.close(null);
  }

  private onModalOk() {
    if (this.state?.selectedMediaItem) {
      this.props.close({
        ...this.state.selectedMediaItem,
        mediaUuid: this.state.selectedMediaItemUuid,
      });
    }
    this.props.close({
      mediaUuid: this.state.selectedMediaItemUuid,
    });
  }

  lazyImage = (mediaItem: any) => {
    let selected =
      this.state.selectedMediaItemUuid &&
      mediaItem.uuid === this.state.selectedMediaItemUuid;

    if (mediaItem.type === "image") {
      return (
        <div
          className={"media-item" + (selected ? " selected" : "")}
          onClick={this.onMediaItemClick.bind(this, mediaItem)}
        >
          <img
            className="lazyload"
            data-src={
              mediaItem.thumbnail && mediaItem.thumbnail !== ""
                ? mediaItem.thumbnail
                : mediaItem.permalink
            }
            style={{ width: 220 }}
          />
        </div>
      );
    } else if (mediaItem.type === "video") {
      return (
        <div
          className={"media-item" + (selected ? " selected" : "")}
          onClick={this.onMediaItemClick.bind(this, mediaItem)}
        >
          <video
            preload={"none"}
            muted={true}
            controls={!selected}
            data-autoPlay={false}
            data-poster={mediaItem.thumbnail}
            data-src={mediaItem.permalink}
            className="lazyload"
            style={{ width: 220 }}
          />
        </div>
      );
    } else if (mediaItem.type === "files") {
      let selectedFile = selected;
      if (this.props.multiSelection) {
        selectedFile = this.props.selectedFiles?.includes(mediaItem.uuid);
      }
      return (
        <div
          className={
            "media-item p-2 b-cursor-p mb-2 mr-2" +
            (selected ? " selected " : "") +
            (selectedFile ? " border border-info " : "") +
            (this.props.multiSelection && selectedFile ? " b-cursor-none" : "")
          }
          onClick={this.onMediaItemClick.bind(this, mediaItem)}
        >
          <FontAwesomeIcon
            style={{ fontSize: 20, marginRight: 10, marginTop: 5 }}
            icon={faFile}
          />
          <span>{mediaItem.original_filename}</span>
        </div>
      );
    } else {
      return "";
    }
  };

  private prepareDataForRendering() {
    let media = [];
    if (this.props.media.data?.items) {
      for (let i = this.props.media.data.items.length - 1; i >= 0; i--) {
        if (
          !(this.props.typeFilter && this.props.typeFilter.length > 0) ||
          this.props.typeFilter.includes(this.props.media.data.items[i].type)
        ) {
          media.push(this.props.media.data.items[i]);
        }
      }
    }

    return {
      media: media,
    };
  }

  setCurrentPage = (pageNo: number) => {};

  render() {
    let renderingData = this.prepareDataForRendering();
    const acceptableTypes: string =
      this.props?.acceptableTypes || "image/*, video/*";

    const totalPage = Math.ceil(
      Number(this.props.media?.data?.total) /
        Number(this.props.media?.data?.pageSize)
    );
    return (
      <Modal
        isOpen={this.props.isOpen}
        autoFocus={true}
        scrollable={true}
        size={"lg"}
        style={{ height: "100%" }}
        cssModule={{ "modal-title": "w-100 text-center" }}
      >
        <LoaderComponent
          display={
            !this.props.media.isValid ||
            this.props.media.isFetching ||
            this.state.isFetching
          }
        />

        <div className="modal-header">
          <div>
            <h5 className="modal-title">
              <Translation>
                {(t, { i18n }) => t("mediaManager.mediaManager")}
              </Translation>{" "}
            </h5>
          </div>
          <div>
            <FileDropZone
              onUpload={this.onUpload}
              accept={acceptableTypes}
              setLoading={(val: boolean) => {
                this.setState({ isFetching: val });
              }}
              entityType={this.props.entityType}
              handlePostUpload={this.handlePostUpload}
            />
          </div>
        </div>
        <ModalBody>
          <div className={"media-item-container"}>
            {renderingData.media.map((mediaItem: any, index: number) => {
              return (
                <React.Fragment key={"media_" + index}>
                  {this.lazyImage(mediaItem)}
                </React.Fragment>
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <PaginationComponent
            currentPage={this.state.currentPage}
            getItems={this.getPaginatedItems}
            setCurrentPage={this.setCurrentPage}
            totalPages={totalPage}
            filterParams={this.props.filterParams}
            setUpdateList={() => {
              // "this.setUpdateList"
            }}
          />
          <div>
            <Button
              color="secondary"
              style={{ margin: 5 }}
              onClick={this.onModalCancel}
            >
              <Translation>{(t, { i18n }) => t("common.cancel")}</Translation>
            </Button>
            <Button
              color="info"
              style={{ margin: 5 }}
              disabled={this.state.selectedMediaItemUuid === ""}
              onClick={this.onModalOk}
            >
              <Translation>{(t, { i18n }) => t("common.ok")}</Translation>
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export function FileDropZone(props: any) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: props.accept,
  });

  const fileList = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {file.path} - {Math.floor(file.size / 1024)} kB
    </li>
  ));

  const onUpload = () => {
    const videoFiles: any[] = [];
    const imageFiles: any[] = [];
    const files: any[] = [];
    acceptedFiles.map((file: any) => {
      if (file.type.startsWith("image")) {
        imageFiles.push(file);
      } else if (file.type.startsWith("video")) {
        videoFiles.push(file);
      } else if (
        file.name.endsWith(".csv") ||
        file.name.endsWith(".xls") ||
        file.name.endsWith(".xlsx")
      ) {
        files.push(file);
      }
    });
    props.setLoading(true);
    props.onUpload(imageFiles, videoFiles, files, props.entityType).then(
      () => {
        acceptedFiles.splice(0, acceptedFiles.length);

        props.handlePostUpload();
      },
      () => {
        props.setLoading(false);
        commonService.toastService("File-Upload not successful", "danger");
      }
    );
  };

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <h5>
          <Translation>
            {(t, { i18n }) => t("mediaManager.dragAndDropFiles")}
          </Translation>{" "}
        </h5>
      </div>
      <aside
        className={"filelist"}
        style={{ display: acceptedFiles.length > 0 ? "inherit" : "none" }}
      >
        <h6>
          <Translation>{(t, { i18n }) => t("mediaManager.files")}</Translation>{" "}
        </h6>
        <ul>{fileList}</ul>
      </aside>
      <div style={{ float: "right" }}>
        <Button
          color="info"
          style={{ margin: 5 }}
          onClick={onUpload}
          disabled={acceptedFiles.length === 0}
        >
          <Translation>{(t, { i18n }) => t("mediaManager.upload")}</Translation>
        </Button>
      </div>
    </section>
  );
}

const mapStateToProps: any = (state: any, ownProps: any) => {
  return {
    media: mediaSelector(state, ownProps.restaurantUuid, ownProps?.entityType),
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    fetchMediaIfRequired: (
      restaurantUuid: any,
      filters: any,
      entityType: string
    ) => {
      dispatch(fetchMediaIfRequired(restaurantUuid, filters, entityType));
    },
    fetchPaginatedMedia: (
      restaurantUuid: any,
      filters: any,
      entityType: string
    ) => {
      dispatch(fetchPaginatedMedia(restaurantUuid, filters, entityType));
    },

    postMedia: (
      restaurantUuid: any,
      imageFiles: any,
      videoFiles: any,
      files: Array<any>,
      entityType: string
    ) => {
      dispatch(
        postMedia(restaurantUuid, imageFiles, videoFiles, files, entityType)
      );
    },
  };
};

const myMediaManagerCard = createModal(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(MediaManagerCard))
);
