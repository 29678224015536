import React, { Component } from 'react';

import StorageComponent from '../../components/storage/storage'

class StoragePage extends Component {

    render() {
        return <StorageComponent />
    }
}
export default StoragePage;