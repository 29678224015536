import '../../styles/user-verification.scss';

import React, { Component } from 'react';

import WebViewComponent from '../../components/web-view/web-view';

class WebViewPage extends Component {
    render() {
        return (
            <div className="userverification-style">
                <WebViewComponent/>
            </div>
        );
    }
}
export default WebViewPage;
