import { call, put, takeLatest } from 'redux-saga/effects';

import { RESET_PASSWORD } from '../config/api.config';
import { resetpasswordFailure, resetpasswordSuccess } from '../redux/actions/resetpassword.action';
import * as types from '../redux/actions/types';
import resetpassword from '../services/resetpassword.service';
import handleResponse from '../services/response.service';

const resetpasswordCall = (params: any) => resetpassword.resetpassword(RESET_PASSWORD, params);

function* handleResetPassword(params: object) {
    try {
        let result;
        result = yield call(resetpasswordCall, params);
        yield handleResetPassword200(result);
    } catch (error) {
        throw error;
    }
}

const handleResetPassword200 = function* handleResetPassword200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(resetpasswordSuccess(result.data));
    } else {
        yield put(resetpasswordFailure(result.data));
    }
    handleResponse(result);
}

export default function* resetpasswordSaga() {
    yield takeLatest(types.RESET_PASSWORD.REQUEST, handleResetPassword);
}
