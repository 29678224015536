import { RegistrationModel } from '../../models/registration.model';
import * as types from '../actions/types';

const initialState: RegistrationModel = {
  isAuthenticated: false,
  isFetching: false,
  user: {},
  error: {},
  failure: false
};
export default function registrationReducer(state = initialState, action: any): RegistrationModel {
  switch (action.type) {
    case types.REGISTRATION.REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        failure: false,
        error: {}
      };
    case types.REGISTRATION.SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        user: action.user,
        failure: false,
        error: {}
      };
    case types.REGISTRATION.FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        failure: true,
        error: action.err
      };
    default:
      return state;
  }
}
