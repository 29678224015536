import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faCopy,
  faEdit,
  faPen,
  faSave,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { ServiceSetListCardProps } from "../../models/serviceset.model";
import commonService from "../../services/common.service";

function ServiceSetListCard(props: ServiceSetListCardProps) {
  const { t, i18n } = useTranslation();
  // const [isSelected, setSelected] = useState(false);

  const { actions, restaurantId, selections, serviceSet } = props;

  const itemLocale = commonService.applyLocalization(
    "restaurant",
    "name",
    serviceSet.locales
  );

  const isAssigned: boolean =
    selections.isRecipeSelected && serviceSet.isAssigned;
  return (
    <div
      className={`d-flex align-items-center mb-2 cursor-pointer ${
        selections.isSelected && "b-service-set-sticky"
      }`}
    >
      {(selections.isAssignee || selections.isRecipeSelected) && (
        <FontAwesomeIcon
          className={"checkbox b-color-green "}
          style={{ fontSize: 20, marginRight: 10, marginTop: 5 }}
          icon={isAssigned ? faCheckSquare : faSquare}
          onClick={() => actions.handleSelection(serviceSet.uuid)}
        />
      )}
      <Card
        className={`rounded shadow w-100 ${
          selections.isSelected ? "b-border-green" : ""
        }`}
      >
        <CardBody className="p-0">
          <CardTitle
            tag="h6"
            className="m-0 d-flex justify-content-between align-items-center"
          >
            <div
              className="px-3 py-3 flex-fill"
              onClick={() => actions.handleSelection(serviceSet.uuid)}
            >
              {itemLocale?.name}
            </div>
            <div>
              {!selections.isEdit && !selections.isAssignee && (
                <Link
                  to={`/restaurant/${restaurantId}/service-sets/${serviceSet.uuid}/details`}
                  className="mx-2"
                >
                  <Button color={"info"} size={"sm"}>
                    {t("common.details")}
                  </Button>
                </Link>
              )}
              {!selections.isEdit &&
                !selections.isAssignee &&
                selections.isSelected && (
                  <>
                    <FontAwesomeIcon
                      className={"checkbox b-color-green mt-2 mx-2"}
                      onClick={() => actions.handleCopy(serviceSet.uuid)}
                      style={{ fontSize: 20 }}
                      icon={faCopy}
                    />
                    <FontAwesomeIcon
                      className={"checkbox b-color-green mt-2 mx-2"}
                      onClick={() => actions.handleRecipeEdit(serviceSet.uuid)}
                      style={{ fontSize: 20 }}
                      icon={faEdit}
                    />
                    <FontAwesomeIcon
                      className={"checkbox mt-2 mx-2"}
                      onClick={() => actions.handleEdit(serviceSet.uuid)}
                      style={{ fontSize: 20 }}
                      icon={faPen}
                    />
                    <FontAwesomeIcon
                      className={"checkbox mt-2 mx-2"}
                      onClick={() => actions.handleDelete(serviceSet.uuid)}
                      style={{ fontSize: 20 }}
                      icon={faTrash}
                    />
                  </>
                )}
              {selections.isEdit && selections.isSelected && (
                <div className="mt-2 mx-2">
                  <FontAwesomeIcon
                    style={{ fontSize: 20 }}
                    className={"mr-3"}
                    icon={faTimes}
                    onClick={() => actions.handleCancel(serviceSet.uuid)}
                  />
                  <FontAwesomeIcon
                    style={{ fontSize: 20 }}
                    icon={faSave}
                    onClick={() => actions.handleSave(serviceSet.uuid)}
                  />
                </div>
              )}
            </div>
          </CardTitle>
        </CardBody>
      </Card>
    </div>
  );
}
export default ServiceSetListCard;
// export default withCardItemSelectionState(
//   "ServiceSetListCard",
//   1
// )(ServiceSetListCard);
