import { INVENTORY_TYPE } from "../constant/constant";

export interface ServiceSetModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface ServiceSetState {
  addTiming: boolean;
  allRecipes: any;
  allRecipesChecked: any;
  color: string;
  currencyCode: any;
  currentRoom: any;
  currentVat: any;
  description: any;
  editTime: boolean;
  endTime: any;
  endTimeIndex: any;
  isEdit: boolean;
  isFetching: boolean;
  isNewServiceSet: boolean;
  isUpdated: boolean;
  isValidSelect: any;
  isWeekDayOpen: any;
  name: string;
  open: boolean;
  recipeList: any;
  restaurantuuid: string;
  room_uuid: any;
  rooms: any;
  searchName: string;
  selectable: boolean;
  selectedOption: any;
  SelectedWeekIndex: any;
  serviceset: any;
  serviceSets: any;
  serviceSetRooms: any;
  serviceSetVat: any;
  startTime: any;
  startTimeIndex: any;
  timeValue: string;
  uuid: string;
  vat_class_uuid: any;
  vat: any;
  weekDayIndex: any;
  weekDays: any;
}

export const ServiceSetInitState = {
  addTiming: false,
  allRecipes: [],
  allRecipesChecked: true,
  color: "#CCCCCC",
  currencyCode: "",
  currentRoom: {},
  currentVat: {},
  description: "",
  editTime: false,
  endTime: [],
  endTimeIndex: "",
  isEdit: false,
  isFetching: false,
  isNewServiceSet: false,
  isUpdated: false,
  isValidSelect: true,
  isWeekDayOpen: false,
  name: "",
  open: false,
  recipeList: [],
  restaurantuuid: "",
  room_uuid: "",
  rooms: [],
  searchName: "",
  selectable: false,
  selectedOption: "select",
  SelectedWeekIndex: "",
  serviceset: [],
  serviceSets: [],
  serviceSetRooms: [],
  serviceSetVat: [],
  startTime: [],
  startTimeIndex: "",
  timeValue: "",
  uuid: "",
  vat_class_uuid: "",
  vat: [],
  weekDayIndex: [],
  weekDays: [
    {
      id: 1,
      day: "Monday",
      isSelected: false,
    },
    {
      id: 2,
      day: "Tuesday",
      isSelected: false,
    },
    {
      id: 3,
      day: "Wednesday",
      isSelected: false,
    },
    { id: 4, day: "Thursday", isSelected: false },
    {
      id: 5,
      day: "Friday",
      isSelected: false,
    },
    {
      id: 6,
      day: "Saturday",
      isSelected: false,
    },
    {
      id: 7,
      day: "Sunday",
      isSelected: false,
    },
  ],
};

export enum serviceSetOverviewMode {
  NONE,
  SERVICE_SET_SELECTION,
  SERVICE_SET_EDIT,
  RECIPE_SELECTION,
  RECIPE_EDIT,
}

export interface ServiceSetOverviewState {
  activeRecipe: string;
  activeServiceSet: string;
  activeStorageDetails: {
    area: string;
    data: any;
    ingredientId: string;
    isEdit: boolean;
  };
  isEdit: boolean;
  isFetching: boolean;
  isModalOpen: boolean;
  isStorageModalOpen: boolean;
  isUpdated: boolean;
  itemModalData: {
    name: string;
    description: string;
    status: number;
    vatClass: any;
  };
  pageMode: serviceSetOverviewMode;
  recipeServiceSetsData: any;
  serviceSetRecipesData: any;
  serviceSetRecipes: Array<any>;
  serviceSetRecipesClone: Array<any>;
  selectedItemId: string;
  storageFormData: {
    inventory_type: number;
    main_storage: boolean;
    min_quantity: string;
    max_quantity: string;
    output_quantity: string;
    output_unit: string;
  };
  supplierList: Array<any>;
}
export interface restaurantMetaInfo {
  labels: Array<any>;
  locations: any;
  rooms: Array<any>;
  storages: Array<any>;
}
export const ServiceSetOverviewInitState: ServiceSetOverviewState = {
  activeRecipe: "",
  activeServiceSet: "",
  activeStorageDetails: {
    area: "",
    data: {},
    ingredientId: "",
    isEdit: false,
  },
  isEdit: false,
  isFetching: false,
  isModalOpen: false,
  isUpdated: false,
  isStorageModalOpen: false,
  itemModalData: {
    name: "",
    description: "",
    status: 1,
    vatClass: {},
  },
  pageMode: serviceSetOverviewMode.NONE,
  recipeServiceSetsData: {},
  selectedItemId: "",
  serviceSetRecipesData: {},
  serviceSetRecipes: [],
  serviceSetRecipesClone: [],
  storageFormData: {
    inventory_type: INVENTORY_TYPE.MANAGED,
    main_storage: false,
    min_quantity: "",
    max_quantity: "",
    output_quantity: "",
    output_unit: "",
  },
  supplierList: [],
};

export interface ServiceSetOverviewListWrapperProps {
  actions: {
    handleServiceSetCancel: Function;
    handleServiceSetDelete: Function;
    handleServiceSetEdit: Function;
    handleServiceSetRecipeEdit: Function;
    handleServiceSetSave: Function;
    handleServiceSetCopy: Function;
    handleServiceSetSelection: Function;
  };
  metaData: {
    activeRecipe: string;
    activeServiceSet: string;
    pageMode: serviceSetOverviewMode;
    recipeServiceSetsData: any;
    restaurantId: string;
  };
  serviceSets: Array<any>;
}

export interface ServiceSetOverviewItemListWrapperProps {
  actions: {
    getSupplierDetails: Function;
    handleRecipeCancel: Function;
    handleRecipeEdit: Function;
    handleRecipeSave: Function;
    handleRecipeSelection: Function;
    setServiceSetRecipes: Function;
    storageActions: {
      addNewStorage: Function;
      editStorage: Function;
      removeStorage: Function;
      getBestMatchUnits: Function;
    };
  };
  allRecipes: Array<any>;
  metaData: {
    activeRecipe: string;
    activeServiceSet: string;
    allLocations: any;
    pageMode: serviceSetOverviewMode;
    recipesLocationsData: any;
    serviceSetRecipesData: any;
    serviceSetRecipes: Array<any>;
  };
  labels: Array<any>;
  serviceSetRecipes: Array<any>;
  units: Array<any>;
  settings: any;
  fetchAllUnits: Function;
}
interface serviceSetCardActions {
  handleCancel: Function;
  handleDelete: Function;
  handleEdit: Function;
  handleRecipeEdit: Function;
  handleSave: Function;
  handleCopy: Function;
  handleSelection: Function;
}

export interface ServiceSetListCardProps {
  actions: serviceSetCardActions;
  restaurantId: string;
  selections: {
    isAssignee: boolean;
    isEdit: boolean;
    isRecipeSelected: boolean;
    isSelected: boolean;
  };
  serviceSet: any;
}

interface ServiceSetItemListCardStorageActions extends serviceSetCardActions {
  storageActions: {
    addNewStorage: Function;
    editStorage: Function;
    removeStorage: Function;
    getBestMatchUnits: Function;
  };
  setServiceSetRecipes: Function;
}

export interface ServiceSetItemListCardProps {
  actions: ServiceSetItemListCardStorageActions;
  metaData: {
    allLocations: any;
    recipeLocations: any;
    serviceSetRecipes: Array<any>;
  };
  recipeData: any;
  selections: {
    isAssignee: boolean;
    isEdit: boolean;
    isRecipeSelected: boolean;
    isSelected: boolean;
    isServiceSetSelected: boolean;
  };
  serviceSetRecipes: Array<any>;
  units: Array<any>;
  settings: any;
  cardItemSelectionData: any;
  fetchAllUnits: Function;
}
