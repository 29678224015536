import {
  PaymentProviderModel,
  PaymentProviderModelInitState,
} from "../../models/payment.provider.model";
import * as types from "../actions/types";

export function paymentProviderReducer(
  state = PaymentProviderModelInitState,
  action: any
): PaymentProviderModel {
  switch (action.type) {
    case types.PAYMENT_PROVIDER.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.PAYMENT_PROVIDER.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.PAYMENT_PROVIDER.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function paymentProviderAddReducer(
  state = PaymentProviderModelInitState,
  action: any
): PaymentProviderModel {
  switch (action.type) {
    case types.PAYMENT_PROVIDER_ADD.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.PAYMENT_PROVIDER_ADD.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.PAYMENT_PROVIDER_ADD.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
export function paymentProviderDetailReducer(
  state = PaymentProviderModelInitState,
  action: any
): PaymentProviderModel {
  switch (action.type) {
    case types.PAYMENT_PROVIDER_DETAILS.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.PAYMENT_PROVIDER_DETAILS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.PAYMENT_PROVIDER_DETAILS.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
export function paymentProviderRemoveReducer(
  state = PaymentProviderModelInitState,
  action: any
): PaymentProviderModel {
  switch (action.type) {
    case types.PAYMENT_PROVIDER_REMOVE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.PAYMENT_PROVIDER_REMOVE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.PAYMENT_PROVIDER_REMOVE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function paymentProviderUpdateReducer(
  state = PaymentProviderModelInitState,
  action: any
): PaymentProviderModel {
  switch (action.type) {
    case types.PAYMENT_PROVIDER_UPDATE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.PAYMENT_PROVIDER_UPDATE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.PAYMENT_PROVIDER_UPDATE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
