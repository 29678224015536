import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import {
    printerMessageFailure,
    printerMessageRemoveAllFailure,
    printerMessageRemoveAllSuccess,
    printerMessageRemoveFailure,
    printerMessageRemoveSuccess,
    printerMessageSuccess,
} from '../redux/actions/printer.message.action';
import * as types from '../redux/actions/types';
import printer from '../services/printer.message.service';
import handleResponse from '../services/response.service';

const printerMessageCall = (params: any) => printer.printerMessage(RESTAURANT_REQUEST, params);
const removePrinterMessageCall = (params: any) => printer.removePrinterMessage(RESTAURANT_REQUEST, params);
const removeAllPrinterMessageCall = (params: any) => printer.removeAllPrinterMessage(RESTAURANT_REQUEST, params);

function* handlePrinterMessageRequest(params: object) {
    try {
        let result;
        result = yield call(printerMessageCall, params);
        yield handlePrinterMessage200(result);
    } catch (error) {
        throw error;
    }
}

const handlePrinterMessage200 = function* handlePrinterMessage200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(printerMessageSuccess(result.data));
    } else {
        yield put(printerMessageFailure(result.data));
        handleResponse(result);
    }
}

function* handlePrinterMessageRemove(params: object) {
    try {
        let result;
        result = yield call(removePrinterMessageCall, params);
        yield handlePrinterMessageRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handlePrinterMessageRemove200 = function* handlePrinterMessageRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(printerMessageRemoveSuccess(result.data));
    } else {
        yield put(printerMessageRemoveFailure(result.data));
    }
    handleResponse(result);
}

function* handlePrinterMessageRemoveAll(params: object) {
    try {
        let result;
        result = yield call(removeAllPrinterMessageCall, params);
        yield handlePrinterMessageRemoveAll200(result);
    } catch (error) {
        throw error;
    }
}

const handlePrinterMessageRemoveAll200 = function* handlePrinterMessageRemoveAll200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(printerMessageRemoveAllSuccess(result.data));
    } else {
        yield put(printerMessageRemoveAllFailure(result.data));
    }
    handleResponse(result);
}

export default function* printerMessageSaga() {
    yield takeLatest(types.PRINTER_MESSAGE.REQUEST, handlePrinterMessageRequest);
    yield takeLatest(types.PRINTER_MESSAGE_REMOVE_ALL.REQUEST, handlePrinterMessageRemoveAll);
    yield takeLatest(types.PRINTER_MESSAGE_REMOVE.REQUEST, handlePrinterMessageRemove);
}
