import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import "../../../styles/ingredients.scss";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class PaginationComponent extends Component<any, any> {
  handleClick(e: any, page: number) {
    e.preventDefault();
    this.props.setCurrentPage(page);
    const { filterParams } = this.props;
    if (page !== this.props.currentPage)
      this.props.getItems(page, {
        labelArray: filterParams?.labelArray || "",
        name: filterParams?.name || "",
        supplierArray: filterParams?.supplierArray || "",
        showArchived: filterParams?.showArchived || "",
      });
    this.props.setUpdateList();
  }

  handleNextClick = (e: any, page: number) => {
    e.preventDefault();
    this.props.setCurrentPage(page + 1);
    const { filterParams } = this.props;

    this.props.getItems(page + 1, {
      labelArray: filterParams?.labelArray || "",
      name: filterParams?.name || "",
      supplierArray: filterParams?.supplierArray || "",
      showArchived: filterParams?.showArchived || "",
    });
    this.props.setUpdateList();
  };

  handlePreviousClick = (e: any, page: number) => {
    e.preventDefault();
    this.props.setCurrentPage(page - 1);
    const { filterParams } = this.props;

    this.props.getItems(page - 1, {
      labelArray: filterParams?.labelArray || "",
      name: filterParams?.name || "",
      supplierArray: filterParams?.supplierArray || "",
      showArchived: filterParams?.showArchived || "",
    });
  };

  getPaginationGroup = () => {
    const { currentPage } = this.props;
    let start = Math.floor((currentPage - 1) / 10) * 10;
    return new Array(
      start + 10 > this.props.totalPages ? this.props.totalPages - start : 10
    )
      .fill(0)
      .map((_, idx) => start + idx + 1);
  };
  render() {
    const { currentPage, totalPages } = this.props;
    return (
      <div className={`b-common-pagination ${totalPages <= 1 ? "d-none" : ""}`}>
        <Pagination>
          <PaginationItem disabled={currentPage == 1}>
            <PaginationLink
              first
              href="#"
              onClick={(e) => this.handleClick(e, 1)}
            />
          </PaginationItem>

          <PaginationItem disabled={currentPage == 1}>
            <PaginationLink
              href="#"
              previous
              onClick={(e) => this.handlePreviousClick(e, currentPage)}
            />
          </PaginationItem>

          {this.getPaginationGroup().map((page: any, i: any) => (
            <PaginationItem key={i} active={currentPage === page}>
              <PaginationLink
                className={currentPage == page ? "b-background-green" : ""}
                href="#"
                onClick={(e) => this.handleClick(e, page)}
              >
                {page}
              </PaginationLink>
            </PaginationItem>
          ))}
          <PaginationItem disabled={currentPage >= totalPages}>
            <PaginationLink
              href="#"
              onClick={(e) => this.handleNextClick(e, currentPage)}
              next
            />
          </PaginationItem>
          <PaginationItem disabled={currentPage >= totalPages}>
            <PaginationLink
              href="#"
              last
              onClick={(e) => this.handleClick(e, totalPages)}
            />
          </PaginationItem>
        </Pagination>
      </div>
    );
  }
}

export default withTranslation()(connect()(withRouter(PaginationComponent)));
