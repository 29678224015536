export interface ImageTitleComponentState {
  addmodal: boolean;
  editmodal: boolean;
  showEditModal: boolean;
  showModal: boolean;
}

export const ImageTitleComponentInitState = {
  addmodal: false,
  editmodal: false,
  showEditModal: false,
  showModal: false,
};
