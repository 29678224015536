import React from "react";

import "./restaurant-language.scss";
export default function RestaurantLangCard(props: any) {
  return (
    <div className="bf-lang-select">
      {props.languages?.map((item: any, index: any) => {
        return (
          <div
            key={item.uuid}
            className={
              "child-box mb-3 d-flex align-items-center " +
              (item.isChecked ? " " : "bf-card-bg ") +
              (props.restaurantLanguage === item.uuid
                ? " border-info bg-light"
                : "")
            }
          >
            {props.restaurantLanguage !== item.uuid ? (
              <>
                <div className="custom-control custom-checkbox">
                  <input
                    checked={item.isChecked}
                    type="checkbox"
                    className="custom-control-input"
                    id={item.uuid}
                    name="language"
                    onChange={() => {
                      props.handleCheckBoxChange(item);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={item.uuid}
                  ></label>
                </div>
                <span
                  onClick={() => {
                    props.handleCheckBoxChange(item);
                  }}
                  className="pointer-cursor"
                >
                  {" "}
                  {item.name}
                </span>
              </>
            ) : (
              <span> {item.name}</span>
            )}
          </div>
        );
      })}
    </div>
  );
}
