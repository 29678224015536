import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faCalendarDay,
  faChevronDown,
  faChevronUp,
  faEdit,
  faListOl,
  faMinusSquare,
  faPlusSquare,
  faSave,
  faSortAmountDown,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardTitle, Collapse, Input } from "reactstrap";
import { GREEN, RECIPE_TYPE } from "../../constant/constant";
import { ServiceSetItemListCardProps } from "../../models/serviceset.model";
import recipeIcon from "../../assets/images/icon/recipeIcon.svg";
import ingredientIcon from "../../assets/images/icon/ingredientsIcon.svg";
import RecipeLocationTreeCardWrapper from "../service-item/shared/RecipeLocationTreeCardWrapper";
import CurrencyInput from "react-currency-input-field";
import commonService from "../../services/common.service";
import UnitConversionAddModal from "../card-components/units-add-card/unit-conversion-add-modal";
import { withCardItemSelectionStateManager } from "../cardItemSelectionManager";
import handleResponse from "../../services/response.service";

function ServiceSetItemCard(props: ServiceSetItemListCardProps) {
  const { t, i18n } = useTranslation();
  const [isOpen, setOpen] = useState(true);
  const {
    actions,
    metaData,
    selections,
    recipeData,
    serviceSetRecipes,
    units,
  } = props;
  const { uuid } = useParams<{ uuid: string }>();
  const isActivatedOnce = useRef(false);
  const isAssigned: boolean =
    selections.isServiceSetSelected && recipeData.isAssigned;
  const isSelectable: boolean =
    selections.isAssignee || !selections.isServiceSetSelected;
  const isActive: boolean =
    selections.isRecipeSelected && !selections.isServiceSetSelected;
  const locationTreeActions: any = {
    addNewStorage: actions.storageActions.addNewStorage,
    editStorage: actions.storageActions.editStorage,
    getBestMatchUnits: actions.storageActions.getBestMatchUnits,
    removeStorage: actions.storageActions.removeStorage,
  };
  const currencyCode = JSON.parse(
    localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
  )?.currency_code;
  const minorUnit = JSON.parse(
    localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
  )?.minor_units;
  const restaurantLanguage: any = localStorage.getItem("i18nextLng");
  const [isUnitModalOpen, setIsUnitModalOpen] = useState<boolean>(false);
  const [relatedUnits, setRelatedUnits] = useState<Array<any>>([]);
  const [activeServiceSetRecipe, setActiveServiceSetRecipe] = useState<any>({});

  useEffect(() => {
    const unitOptions: Array<any> =
      commonService.getRelateUnitDropdowns(
        recipeData?.output_unit_uuid,
        units,
        units,
        1,
        recipeData.uuid
      ) || [];
    unitOptions.push({
      label: t("unit.newUnit"),
      value: "NEW_UNIT",
    });
    setRelatedUnits(unitOptions);
  }, [units, recipeData, t]);

  useEffect(() => {
    if (isActive) isActivatedOnce.current = true;
    setOpen(isActive);
  }, [isActive]);

  const getItemIcon = () => {
    let icon: any;
    switch (recipeData.type) {
      case RECIPE_TYPE.RECIPE:
        icon = <img src={recipeIcon} className={"b-icon-sm b-fill-green"} />;
        break;
      case RECIPE_TYPE.INGREDIENT:
        icon = (
          <img src={ingredientIcon} className={"b-icon-sm b-fill-green"} />
        );
        break;
      case RECIPE_TYPE.CHOICE:
        icon = (
          <FontAwesomeIcon
            className="b-drag-icon mr-1 text-success"
            icon={faListOl}
          />
        );
        break;
      case RECIPE_TYPE.DATE_ITEM:
        icon = (
          <FontAwesomeIcon
            className="b-drag-icon mr-1 text-success"
            icon={faCalendarDay}
          />
        );
        break;
      case RECIPE_TYPE.INGREDIENT_CLASS:
        icon = (
          <FontAwesomeIcon
            className="b-drag-icon mr-1 text-success"
            icon={faSortAmountDown}
          />
        );
        break;
    }

    return icon;
  };

  const hasLocations: boolean =
    !selections.isAssignee &&
    !selections.isServiceSetSelected &&
    isActivatedOnce.current &&
    (recipeData.type === RECIPE_TYPE.INGREDIENT ||
      recipeData.type === RECIPE_TYPE.RECIPE);

  const newPriceUnitListing = () => {
    const newRecipe = {
      uuid: "UUID_" + new Date().valueOf(),
      appear_on_bill: true,
      currency: currencyCode,
      offered_unit: recipeData.output_unit_uuid,
      price: 0,
      recipe_output_unit: recipeData.output_unit_uuid,
      recipe_uuid: recipeData.uuid,
      selectable: true,
      status: 1,
      type: recipeData.type,
    };

    actions.setServiceSetRecipes([...serviceSetRecipes, newRecipe]);
  };

  const removePriceUnitListing = (serviceSetRecipe: any) => () => {
    const recipes = serviceSetRecipes.filter(
      (r) => r.uuid !== serviceSetRecipe.uuid
    );
    actions.setServiceSetRecipes(recipes);
  };

  const handleChange = (name: string, value: any, serviceSetRecipe: any) => {
    const recipes = serviceSetRecipes.map((recipe: any) => {
      if (recipe.uuid === serviceSetRecipe.uuid) {
        if (name === "currentPrice") {
          recipe.price = value;
        }
        if (name === "offeredUnit") {
          if (value === "NEW_UNIT") {
            setIsUnitModalOpen(true);
            setActiveServiceSetRecipe(serviceSetRecipe);
          } else {
            recipe.offered_unit = value;
          }
        }
      }
      return recipe;
    });
    actions.setServiceSetRecipes(recipes);
  };

  const getBaseUnitOptions = () => {
    if (!Array.isArray(props.units)) return [];
    let units: Array<any> = [];
    const baseUnit: any = props.units?.find(
      (unit: any) => unit.uuid === props.settings?.base_weight_unit
    );
    if (baseUnit) {
      units =
        commonService.getRelateUnitDropdowns(
          baseUnit.uuid,
          props.units,
          props.units,
          1,
          recipeData.uuid
        ) || [];
    }
    return _.sortBy(units, [(option: any) => option.label.toLowerCase()]);
  };

  const unitConversionSaveCallback = (response: any) => {
    const responseData: any = handleResponse(response);
    if (responseData.ok) {
      props.fetchAllUnits();
      handleChange(
        "offeredUnit",
        responseData.data.data.from_uuid,
        activeServiceSetRecipe
      );
    }
    setIsUnitModalOpen(false);
  };

  const renderPriceUnit = () => {
    const recipeListings = serviceSetRecipes.filter(
      (r) => r.recipe_uuid === recipeData.uuid
    );
    if (recipeListings.length === 0) {
      newPriceUnitListing();
      return "";
    }
    return recipeListings.map((serviceSetRecipe: any, index: number) => {
      return (
        <div key={index} className="d-flex w-100 mb-2">
          <span
            className="flex-shrink-0 pt-2"
            style={{
              width: "50%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={recipeData?.name}
          >
            {getItemIcon()} {recipeData?.name}
          </span>
          <span className="flex-fill text-center" style={{ width: "28%" }}>
            <Input
              type="select"
              id="offeredUnit"
              required
              name="offeredUnit"
              value={serviceSetRecipe.offered_unit}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value, serviceSetRecipe);
              }}
            >
              <option value="">{t("unit.selectUnits")} ...</option>
              {Object.keys(relatedUnits || {}).length > 0 &&
                relatedUnits.map((unit: any, index: number) => (
                  <option value={unit.value} key={index}>
                    {unit.label}
                  </option>
                ))}
            </Input>
          </span>
          <span
            className="flex-fill text-center pl-2 pr-2"
            style={{ width: "19%" }}
          >
            <CurrencyInput
              className="form-control"
              intlConfig={{
                locale: restaurantLanguage,
                currency: currencyCode,
              }}
              decimalScale={minorUnit}
              decimalsLimit={minorUnit}
              id="currentPrice"
              name="currentPrice"
              value={serviceSetRecipe.price}
              onValueChange={(value, name) => {
                handleChange("currentPrice", value, serviceSetRecipe);
              }}
              prefix=" "
            />
          </span>
          <span className="flex-fill text-center pt-2" style={{ width: "3%" }}>
            {index === 0 && (
              <FontAwesomeIcon
                onClick={newPriceUnitListing}
                icon={faPlusSquare}
                color={GREEN}
              />
            )}
            {index !== 0 && (
              <FontAwesomeIcon
                onClick={removePriceUnitListing(serviceSetRecipe)}
                icon={faMinusSquare}
                color={GREEN}
              />
            )}
          </span>
        </div>
      );
    });
  };

  return (
    <>
      <div
        className={`d-flex align-items-center mb-2  ${
          isSelectable ? "cursor-pointer" : ""
        }  ${isActive && "b-service-item-sticky"}`}
      >
        {(selections.isAssignee || selections.isServiceSetSelected) && (
          <FontAwesomeIcon
            className={`checkbox  ${
              isSelectable ? "b-color-green" : "text-muted"
            }`}
            style={{ fontSize: 20, marginRight: 10, marginTop: 5 }}
            icon={isAssigned ? faCheckSquare : faSquare}
            onClick={() => actions.handleSelection(recipeData.uuid)}
          />
        )}
        <Card
          className={`rounded shadow w-100 ${
            isAssigned || isActive ? "b-border-green" : ""
          }`}
        >
          <CardBody className=" p-0">
            <CardTitle
              tag="h6"
              className="m-0 d-flex align-items-center justify-content-between"
            >
              <div className="w-100 px-2 py-2 flex-fill">
                {(!isSelectable || !isAssigned) && (
                  <div>
                    {getItemIcon()} {recipeData?.name}
                  </div>
                )}
                {isSelectable && isAssigned && renderPriceUnit()}
              </div>

              <div>
                {!selections.isEdit && isActive && (
                  <FontAwesomeIcon
                    className={"checkbox b-color-green mx-2"}
                    onClick={() => actions.handleEdit(recipeData.uuid)}
                    style={{ fontSize: 20 }}
                    icon={faEdit}
                  />
                )}

                {!selections.isEdit && hasLocations && (
                  <FontAwesomeIcon
                    className={" b-color-green  mx-2"}
                    onClick={() => setOpen(!isOpen)}
                    style={{ fontSize: 20 }}
                    icon={isOpen ? faChevronUp : faChevronDown}
                  />
                )}

                {selections.isEdit && isActive && (
                  <div className=" mx-2">
                    <FontAwesomeIcon
                      style={{ fontSize: 20 }}
                      className={"mr-3"}
                      icon={faTimes}
                      onClick={() => actions.handleCancel(recipeData.uuid)}
                    />
                    <FontAwesomeIcon
                      style={{ fontSize: 20 }}
                      icon={faSave}
                      onClick={() => actions.handleSave(recipeData.uuid)}
                    />
                  </div>
                )}
              </div>
            </CardTitle>
            {hasLocations && (
              <div>
                <Collapse isOpen={isOpen}>
                  <div className="p-3 bg-light">
                    <h6>{t("common.location")}</h6>
                    <div className="pre-scrollable">
                      <RecipeLocationTreeCardWrapper
                        actions={locationTreeActions}
                        allLocations={metaData.allLocations}
                        recipeId={recipeData.uuid}
                        savedLocations={metaData.recipeLocations}
                      />
                    </div>
                  </div>
                </Collapse>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <UnitConversionAddModal
        isModalOpen={isUnitModalOpen}
        setIsModalOpen={setIsUnitModalOpen}
        area="recipe"
        allUnits={units}
        recipeOutputUnit={recipeData.output_unit_uuid}
        recipeOutputQuantity={recipeData?.output_quantity}
        getBaseUnitOptions={getBaseUnitOptions()}
        settings={props.settings}
        relatedUnits={relatedUnits.slice(0, -1)}
        cardItemSelectionData={props.cardItemSelectionData}
        recipeUuid={recipeData?.uuid}
        restaurantLangCode={restaurantLanguage.toLowerCase()}
        restaurantuuid={uuid}
        unitConversionSaveCallback={unitConversionSaveCallback}
      />
    </>
  );
}
const enhance = withCardItemSelectionStateManager("Units");
export default enhance(ServiceSetItemCard);
