import "../../styles/loader.scss";

import React, { Component } from "react";
import { Spinner } from "reactstrap";

class LoaderComponent extends Component<any> {
  render() {
    return (
      <div
        className="loader-container"
        style={!!this.props.display ? { display: "flex" } : { display: "none" }}
      >
        <Spinner
          animation="grow"
          variant="info"
          style={{ width: "4rem", height: "4rem" }}
        />
      </div>
    );
  }
}
export default LoaderComponent;
