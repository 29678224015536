import "../../../styles/users.scss";

import React from "react";
import {
  Col,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Label,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GRAY, DARK_GRAY } from "../../../constant/constant";
import { faTrashAlt, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import commonService from "../../../services/common.service";
import { useTranslation } from "react-i18next";

export default function UsersCard(props: any) {
  const { t, i18n } = useTranslation();
  let roleName: any = [];
  if (props.roleList.length > 0) {
    props.roleList.forEach((element: any) => {
      props.data.roles.forEach((roles: any) => {
        if (roles === element.uuid) {
          roleName.push(
            commonService.applyLocalization(
              "restaurant",
              "name",
              element?.locales
            )["name"] + ", "
          );
        }
      });
    });
  }
  let name = roleName.map((locales: any, index: number) => {
    return <span key={index}>{locales}</span>;
  });

  return (
    <React.Fragment>
      <span style={{ marginTop: 20 }}>
        <div style={{ float: "right", display: "flex" }}>
          <span
            onClick={props.setDelete}
            className="pointer-cursor"
            data-toggle="tooltip"
            data-placement="left"
            title={t("common.delete")}
          >
            <FontAwesomeIcon
              style={{ color: GRAY, fontSize: 20, margin: 5 }}
              icon={faTrashAlt}
            />
          </span>
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="pointer-cursor">
              <FontAwesomeIcon
                style={{
                  color: "#3f3f3f",
                  fontSize: 20,
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop: 20,
                }}
                icon={faEllipsisV}
              />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag="a" onClick={props.manageRoles}>
                {t("role.manageRoles")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </span>
      <Row className="app-row-padding">
        <Col style={{ paddingLeft: 0, paddingRight: 0, marginTop: 0 }}>
          <h6 style={{ color: DARK_GRAY }}>
            {" "}
            {props.data.user.first_name} {props.data.user.last_name}
          </h6>
        </Col>
      </Row>
      <Row className="app-row-padding">
        <Col
          xs="12"
          sm="6"
          style={{ paddingLeft: 0, paddingRight: 0, marginTop: 0 }}
        >
          {!commonService.isGeneratedEmail(props.data.user.email) && (
            <>
              <Label>{t("common.email")} : </Label> {props.data.user.email}
            </>
          )}
        </Col>
        <Col
          xs="12"
          sm="6"
          style={{ paddingLeft: 0, paddingRight: 0, marginTop: 0 }}
        >
          <Label>{t("role.roles")} : </Label> {name}
        </Col>
      </Row>
    </React.Fragment>
  );
}
