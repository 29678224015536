import {
  faEdit,
  faEllipsisV,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { GRAY, GREEN } from "../../../constant/constant";
import commonService from "../../../services/common.service";

export default function RoleCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <div className="child-box mb-3">
      <span style={{ marginTop: 20 }}>
        <div style={{ float: "right", display: "flex" }}>
          <span
            onClick={props.setEditable}
            className="pointer-cursor"
            data-toggle="tooltip"
            data-placement="left"
            title={t("common.edit")}
          >
            <FontAwesomeIcon
              style={{ color: GREEN, fontSize: 20, margin: 5 }}
              icon={faEdit}
            />
          </span>
          <span
            onClick={props.setDelete}
            className="pointer-cursor"
            data-toggle="tooltip"
            data-placement="left"
            title={t("common.delete")}
          >
            <FontAwesomeIcon
              style={{ color: GRAY, fontSize: 20, margin: 5 }}
              icon={faTrashAlt}
            />
          </span>
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="pointer-cursor">
              <FontAwesomeIcon
                style={{
                  color: "#3f3f3f",
                  fontSize: 20,
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop: 20,
                }}
                icon={faEllipsisV}
              />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                tag="a"
                onClick={props.managerighttoggle.bind(
                  props,
                  props.locales.right_uuids,
                  props.locales.uuid
                )}
                className="b-cursor-p"
              >
                {t("common.manageRights")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </span>
      <Row className="app-row-padding">
        <Col style={{ paddingLeft: 0, paddingRight: 0, marginTop: 0 }}>
          <h6 style={{ margin: 0 }}>
            {
              commonService.applyLocalization(
                "restaurant",
                "name",
                props.locales.locales
              )["name"]
            }
          </h6>
        </Col>
      </Row>
    </div>
  );
}
