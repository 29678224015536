import apiCall from "./api.call.config";

async function getOpenTransactionQlty(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/restaurant-closure/open-transactions-quality-check"
    );
  } catch (e) {
    throw e;
  }
}

async function getPaymentTransactionQlty(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/restaurant-closure/payment-transactions-quality-check"
    );
  } catch (e) {
    throw e;
  }
}

export async function getReportingTemplates(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.credentials.restaurantuuid + "/reporting-templates"
    );
  } catch (e) {
    throw e;
  }
}

async function getRestaurantClosure(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/restaurant-closure",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function getRoomClosure(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/room-closure",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function getCorrectedTransactions(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/corrected-payment-transactions",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function getCostReports(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/report/cost",
      params.credentials.data,
      true
    );
  } catch (e) {
    throw e;
  }
}

export async function getServiceSetReport(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/report/service-set",
      params.credentials.data,
      true
    );
  } catch (e) {
    throw e;
  }
}

export async function getInventoryReports(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/report/inventory",
      params.credentials.data,
      true
    );
  } catch (e) {
    throw e;
  }
}

export async function getOnlineInventoryReports(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/report/online-inventory",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function exportOnlineInventoryReports(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/report/export-online-inventory",
      params.credentials.data,
      true
    );
  } catch (e) {
    throw e;
  }
}

export async function getRevenuePerPaymentType(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/revenue-per-payment-type",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function getRevenuePerLabel(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/revenue-per-label",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function getRevenuePerProductGroup(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/revenue-per-product-group",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function getRevenuePerRecipe(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/revenue-per-recipe",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function getListOfGuestGroups(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/guest-group-report",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function getListOfOrderRequests(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/order-requests-report",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function getSupplierOrders(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/supplier-orders-report",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function printGuestGroupReport(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/print-receipt",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function getListOfProducedBatches(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/produced-batches-report",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function getProducedBatchDetails(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/produced-batch-details",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function setReportingTemplates(url: string, params: any) {
  try {
    return await apiCall.put(
      url + "/" + params.credentials.restaurantuuid + "/reporting-templates",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  getListOfGuestGroups,
  getCorrectedTransactions,
  getOpenTransactionQlty,
  getPaymentTransactionQlty,
  getRestaurantClosure,
  getRoomClosure,
  getRevenuePerPaymentType,
  printGuestGroupReport,
  getListOfProducedBatches,
  getSupplierOrders,
};
