import React, { Component } from "react";

import RecipePrinterComponent from "../../components/printer/recipe-printer";

class RecipePrinterPage extends Component {
  render() {
    return <RecipePrinterComponent />;
  }
}
export default RecipePrinterPage;
