export interface EmailConfirmationState {
  isFetching: boolean;
}
export interface EmailConfirmationModel {
  isFetching: boolean,
  data: any,
  error: any,
  failure: boolean
}

export const EmailConfirmationInitState = {
  isFetching: false
};