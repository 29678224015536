import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function servicesetRequest(credentials: object): ActionModel {
  return {
    type: types.SERVICESET.REQUEST,
    credentials,
  };
}

export function servicesetSuccess(data: object): ActionModel {
  return {
    type: types.SERVICESET.SUCCESS,
    data,
  };
}

export function servicesetFailure(err: object): ActionModel {
  return {
    type: types.SERVICESET.FAILURE,
    err,
  };
}

export function servicesetUpdate(credentials: object): ActionModel {
  return {
    type: types.SERVICESET_UPDATE.REQUEST,
    credentials,
  };
}

export function servicesetUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.SERVICESET_UPDATE.SUCCESS,
    data,
  };
}

export function servicesetUpdateFailure(err: object): ActionModel {
  return {
    type: types.SERVICESET_UPDATE.FAILURE,
    err,
  };
}

export function servicesetAdd(credentials: object): ActionModel {
  return {
    type: types.SERVICESET_ADD.REQUEST,
    credentials,
  };
}

export function servicesetClear(): ActionModel {
  return {
    type: types.SERVICESET_ADD.REMOVE,
  };
}

export function servicesetAddSuccess(data?: object): ActionModel {
  return {
    type: types.SERVICESET_ADD.SUCCESS,
    data,
  };
}

export function servicesetClean(): ActionModel {
  return {
    type: types.SERVICESET_UPDATE.REMOVE,
  };
}
export function servicesetAddFailure(err: object): ActionModel {
  return {
    type: types.SERVICESET_ADD.FAILURE,
    err,
  };
}

export function servicesetRemove(credentials: object): ActionModel {
  return {
    type: types.SERVICESET_REMOVE.REQUEST,
    credentials,
  };
}

export function servicesetRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.SERVICESET_REMOVE.SUCCESS,
    data,
  };
}

export function servicesetRemoveFailure(err: object): ActionModel {
  return {
    type: types.SERVICESET_REMOVE.FAILURE,
    err,
  };
}

export function serviceSetTimeAdd(credentials: object): ActionModel {
  return {
    type: types.SERVICESET_TIME_ADD.REQUEST,
    credentials,
  };
}

export function serviceSetTimeAddSuccess(data?: object): ActionModel {
  return {
    type: types.SERVICESET_TIME_ADD.SUCCESS,
    data,
  };
}

export function serviceSetTimeAddFailure(err: object): ActionModel {
  return {
    type: types.SERVICESET_TIME_ADD.FAILURE,
    err,
  };
}

export function serviceSetUpdateChannel(credentials: object): ActionModel {
  return {
    type: types.SERVICE_SET_CHANNEL_UPDATE.REQUEST,
    credentials,
  };
}

export function serviceSetUpdateChannelSuccess(data?: object): ActionModel {
  return {
    type: types.SERVICE_SET_CHANNEL_UPDATE.SUCCESS,
    data,
  };
}

export function serviceSetUpdateChannelFailure(err: object): ActionModel {
  return {
    type: types.SERVICE_SET_CHANNEL_UPDATE.FAILURE,
    err,
  };
}
