import React from "react";
import { useTranslation } from "react-i18next";
import { create, InstanceProps } from "react-modal-promise";
import DeleteModalCard from "../card-components/delete-card/delete-card";

type AreYouSureModalProps = InstanceProps<unknown> & {
  onConfirm: () => Promise<void>;
  onCancel?: () => void;
};
const AreYouSureModal: React.FC<AreYouSureModalProps> = (props) => {
  const { isOpen, onResolve, onConfirm, onCancel = () => null } = props;
  const { t } = useTranslation();

  const onConfirmPress = async () => {
    await onConfirm();
    onResolve(true);
  };

  const onCancelPress = async () => {
    onCancel && onCancel();
    onResolve(false);
  };
  return (
    <DeleteModalCard
      isOpen={isOpen}
      okDelete={onConfirmPress}
      cancelDelete={onCancelPress}
    />
  );
};

export const areYouSureModal = create(AreYouSureModal);
