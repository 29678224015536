export interface OperationModeModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface  ServerState {
  stations: any;
  isFetching: boolean;
  isUpdated: boolean
  serverList: any,
}

export const ServerInitState = {
  stations: [],
  isFetching: false,
  isUpdated: false,
  serverList: [],
};
