import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function tagsRequest(credentials: object): ActionModel {
  return {
    type: types.TAGS.REQUEST,
    credentials,
  };
}

export function tagsSuccess(data: object): ActionModel {
  return {
    type: types.TAGS.SUCCESS,
    data,
  };
}

export function tagsFailure(err: object): ActionModel {
  return {
    type: types.TAGS.FAILURE,
    err,
  };
}

export function tagAdd(credentials: object): ActionModel {
  return {
    type: types.TAG_ADD.REQUEST,
    credentials,
  };
}

export function tagAddSuccess(data?: object): ActionModel {
  return {
    type: types.TAG_ADD.SUCCESS,
    data,
  };
}

export function tagAddFailure(err: object): ActionModel {
  return {
    type: types.TAG_ADD.FAILURE,
    err,
  };
}

export function tagAssign(credentials: object): ActionModel {
  return {
    type: types.TAG_ASSIGN.REQUEST,
    credentials,
  };
}

export function tagAssignSuccess(data?: object): ActionModel {
  return {
    type: types.TAG_ASSIGN.SUCCESS,
    data,
  };
}

export function tagAssignFailure(err: object): ActionModel {
  return {
    type: types.TAG_ASSIGN.FAILURE,
    err,
  };
}

export function tagRemove(credentials: object): ActionModel {
  return {
    type: types.TAG_REMOVE.REQUEST,
    credentials,
  };
}

export function tagRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.TAG_REMOVE.SUCCESS,
    data,
  };
}

export function tagRemoveFailure(err: object): ActionModel {
  return {
    type: types.TAG_REMOVE.FAILURE,
    err,
  };
}

export function tagUpdate(credentials: object): ActionModel {
  return {
    type: types.TAG_UPDATE.REQUEST,
    credentials,
  };
}

export function tagUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.TAG_UPDATE.SUCCESS,
    data,
  };
}

export function tagUpdateFailure(err: object): ActionModel {
  return {
    type: types.TAG_UPDATE.FAILURE,
    err,
  };
}
