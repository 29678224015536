import React, { Component } from 'react';

import StationComponent from '../../components/station/station';

class StationPage extends Component {

    render() {
        return (
            <StationComponent />
        );
    }
}
export default StationPage;
