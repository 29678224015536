export interface StorageRackModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface StorageRackState {
  uuid: string;
  storageracks: Array<any>;
  storagerackname: string;
  editstoragerackname: string;
  addstoragerackmodal: boolean;
  editstoragerackmodal: boolean;
  deletestoragerackmodal: boolean;
  isUpdated: boolean;
  isFetching: boolean;
  openLabelPrintModal: boolean;
  printPageUrl: string;
}

export const StorageRackInitState = {
  uuid: "",
  storageracks: [],
  storagerackname: "",
  editstoragerackname: "",
  addstoragerackmodal: false,
  editstoragerackmodal: false,
  deletestoragerackmodal: false,
  isUpdated: false,
  isFetching: false,
  openLabelPrintModal: false,
  printPageUrl: "",
};
