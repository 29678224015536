export interface RoomModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface RoomState {
  room: any;
  isFetching: boolean;
  name: any;
  editmodal: boolean;
  addmodal: boolean;
  open: boolean;
  uuid: string;
  isUpdated: boolean;
  restaurantuuid: string;
  pickingLocation: any;
  roomOptionList: Array<any>;
}

export const RoomInitState = {
  name: "",
  isFetching: false,
  room: [],
  editmodal: false,
  addmodal: false,
  open: false,
  uuid: "",
  isUpdated: false,
  restaurantuuid: "",
  pickingLocation: null,
  roomOptionList: [],
};
