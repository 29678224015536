import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { Col, Form, FormGroup } from "reactstrap";
import { printerTemplateRequest } from "../../../redux/actions/printer.template.action";
import commonService from "../../../services/common.service";

interface EmailTemplateSelectionProps {
  selectLabel: string;
  selectedTemplate: string;
  handleEmailTemplateChange: Function;
}

interface RootState {
  printerTemplate: {
    data: any;
  };
}

const EmailTemplateSelectionCard: React.FC<EmailTemplateSelectionProps> = (
  props
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { uuid } = useParams<any>();
  const [state, setState] = useState({
    printerTemplate: { data: [] },
  });
  const [emailTemplateOptions, setEmailTemplateOptions] = useState<Array<any>>(
    []
  );
  const [selectedTemplate, setSelectedTemplate] = useState<object>();
  const emailTemplates = useSelector(
    (state: RootState) => state.printerTemplate.data
  );
  useEffect(() => {
    setState((prevState) => ({ ...prevState, restaurantuuid: uuid }));
    dispatch(
      printerTemplateRequest({ restaurantuuid: uuid, templateType: "MAIL" })
    );
  }, [dispatch, uuid]);

  useEffect(() => {
    const template = (
      Object.keys(emailTemplates).length === 0 ? [] : emailTemplates
    )?.find((i: any) => i.uuid === props.selectedTemplate);
    setSelectedTemplate({
      value: template ? template.uuid : "",
      label: template
        ? commonService.applyLocalization(
            "restaurant",
            "name",
            template.locales
          ).name
        : t("common.select"),
    });
  }, [emailTemplates, props.selectedTemplate]);

  useEffect(() => {
    let templateOptions = (
      Object.keys(emailTemplates).length === 0 ? [] : emailTemplates
    ).map((i: any) => {
      return {
        value: i.uuid,
        label: commonService.applyLocalization("restaurant", "name", i.locales)
          .name,
      };
    });
    templateOptions = [
      { value: "", label: t("common.select") },
      ...templateOptions,
    ];
    setEmailTemplateOptions(templateOptions);
  }, [emailTemplates, t]);

  const renderCard = () => {
    return (
      <div className="child-box mb-3">
        <Form>
          <FormGroup className="row">
            <Col sm="8">
              <Select
                id="emailTemplate"
                name="emailTemplate"
                onChange={props.handleEmailTemplateChange}
                maxMenuHeight={200}
                options={emailTemplateOptions}
                placeholder={t(props.selectLabel)}
                value={selectedTemplate}
              />
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  };

  return renderCard();
};

export default EmailTemplateSelectionCard;
