import mobiscroll from "@mobiscroll/react";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

export default function ImageModalCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Modal isOpen={props.state.imagemodal}>
        <ModalHeader toggle={props.editImageToggle} className="modal-header">
          {!!props.state.image_url
            ? t("image.profileImageUpload")
            : t("image.imageUpload")}
        </ModalHeader>
        <ModalBody>
          <Row className="app-row-padding">
            <Col sm="8">
              <mobiscroll.Input
                type="file"
                name="filedata"
                inputStyle="outline"
                style={{ borderWidth: 1, borderStyle: "solid" }}
                onChange={props.handleImageChange}
                placeholder={t("common.selectFile")}
              ></mobiscroll.Input>
            </Col>
            <Col sm="4" style={{ padding: 0, textAlign: "center" }}>
              <div style={{ float: "right" }}>
                <Button
                  color="secondary"
                  style={{ margin: 5 }}
                  onClick={props.editImageToggle}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  color="info"
                  style={{ margin: 5 }}
                  onClick={props.saveImage}
                  disabled={
                    !!props.isFetching ||
                    !props.state.filedata ||
                    props.state.filedata.length === 0
                  }
                >
                  {t("common.ok")}
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
}
