import { StorageRackModel } from '../../models/storage.rack.model';
import * as types from '../actions/types';

const initialState: StorageRackModel = {
    isFetching: false,
    data: {},
    error: {},
    failure: false,
};
export default function storageRackRemoveReducer(state = initialState, action: any): StorageRackModel {
    switch (action.type) {
        case types.STORAGE_RACK_REMOVE.REQUEST:
            return {
                ...state,
                isFetching: true,
                failure: false,
                error: {}
            };
        case types.STORAGE_RACK_REMOVE.SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.data,
                failure: false,
                error: {}
            };
        case types.STORAGE_RACK_REMOVE.FAILURE:
            return {
                ...state,
                isFetching: false,
                failure: true,
                error: action.err
            };
        default:
            return state;
    }
}