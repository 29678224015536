import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function qrCodeRequest(credentials: object): ActionModel {
  return {
    type: types.QRCODE.REQUEST,
    credentials,
  };
}

export function qrCodeSuccess(data: object): ActionModel {
  return {
    type: types.QRCODE.SUCCESS,
    data,
  };
}

export function qrCodeFailure(err: object): ActionModel {
  return {
    type: types.QRCODE.FAILURE,
    err,
  };
}

export function qrCodeAdd(credentials: object): ActionModel {
  return {
    type: types.QRCODE_ADD.REQUEST,
    credentials,
  };
}

export function qrCodeAddSuccess(data?: object): ActionModel {
  return {
    type: types.QRCODE_ADD.SUCCESS,
    data,
  };
}

export function qrCodeAddFailure(err: object): ActionModel {
  return {
    type: types.QRCODE_ADD.FAILURE,
    err,
  };
}

export function getRoomDetails(credentials: object): ActionModel {
  return {
    type: types.QRCODE_GET_ROOM_DTLS.REQUEST,
    credentials,
  };
}

export function getRoomDetailsSuccess(data?: object): ActionModel {
  return {
    type: types.QRCODE_GET_ROOM_DTLS.SUCCESS,
    data,
  };
}

export function getRoomDetailsFailure(err: object): ActionModel {
  return {
    type: types.QRCODE_GET_ROOM_DTLS.FAILURE,
    err,
  };
}

export function qrCodeRemove(credentials: object): ActionModel {
  return {
    type: types.QRCODE_REMOVE.REQUEST,
    credentials,
  };
}

export function qrCodeRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.QRCODE_REMOVE.SUCCESS,
    data,
  };
}

export function qrCodeRemoveFailure(err: object): ActionModel {
  return {
    type: types.QRCODE_REMOVE.FAILURE,
    err,
  };
}

export function qrCodeUpdate(credentials: object): ActionModel {
  return {
    type: types.QRCODE_UPDATE.REQUEST,
    credentials,
  };
}

export function qrCodeUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.QRCODE_UPDATE.SUCCESS,
    data,
  };
}

export function qrCodeUpdateFailure(err: object): ActionModel {
  return {
    type: types.QRCODE_UPDATE.FAILURE,
    err,
  };
}
