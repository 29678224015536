export const LOGIN_REQUEST = "/public/user/login";
export const REGISTRATION_REQUEST = "/public/user/register";
export const EMAIL_CONFORMATION_REQUEST = "/public/user/verify/email";
export const USER_VERIFY = "/public/user/verify/invitation";
export const FORGOT_PASSWORD = "/public/user/forgot-password";
export const RESET_PASSWORD = "/public/user/reset-password";
export const REFRESH_TOKEN = "/public/user/refresh";
export const PUBLIC_REQUEST = "/public";
export const USER_REQUEST = "/api/1.0/user/";
export const RESTAURANT_REQUEST = "/api/1.0/restaurant";

export const UPLOAD_FILE = "/api/1.0/assets/upload";

export const COMPONENT_REQUEST = "/api/1.0/component";

export const GLOBAL_REQUEST = "/api/1.0/";
