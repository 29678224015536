import { call, put, takeLatest } from 'redux-saga/effects';
import { RESTAURANT_REQUEST } from '../config/api.config';
import {
    roleRightAddFailure,
    roleRightAddSuccess,
    roleRightFailure,
    roleRightSuccess,
} from '../redux/actions/roleright.action';
import * as types from '../redux/actions/types';
import handleResponse from '../services/response.service';
import roleRightList from '../services/roleright.service';

const roleRightCall = (params: any) => roleRightList.roleRightList(RESTAURANT_REQUEST, params);
const addRoleRightCall = (params: any) => roleRightList.addRoleRight(RESTAURANT_REQUEST, params);

function* handleRoleRightRequest(params: object) {
    try {
        let result;
        result = yield call(roleRightCall, params);
        yield handlRoleRight200(result);
    } catch (error) {
        throw error;
    }
}

const handlRoleRight200 = function* handlRoleRight200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(roleRightSuccess(result.data));
    } else {
        yield put(roleRightFailure(result.data));
        handleResponse(result);
    }
}

function* handleRoleRightAdd(params: object) {
    try {
        let result;
        result = yield call(addRoleRightCall, params);
        yield handleRoleRightAdd201(result);
    } catch (error) {
        throw error;
    }
}

const handleRoleRightAdd201 = function* handleRoleRightAdd201(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 201) {
        yield put(roleRightAddSuccess(result.data));
    } else {
        yield put(roleRightAddFailure(result.data));
    }
    handleResponse(result);
}

export default function* roleRightSaga() {
    yield takeLatest(types.ROLE_RIGHT.REQUEST, handleRoleRightRequest);
    yield takeLatest(types.ROLE_RIGHT_ADD.REQUEST, handleRoleRightAdd);
}