import { ChangePasswordModel } from '../../models/profile.model';
import * as types from '../actions/types';

const initialState: ChangePasswordModel = {
    isAuthenticated: false,
    isFetching: false,
    data: {},
    error: {},
    failure: false
};

export default function changePasswordReducer(state = initialState, action: any): ChangePasswordModel {
    switch (action.type) {
        case types.CHANGE_PASSWORD.REQUEST:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false,
                failure: false,
                error: {}
            };
        case types.CHANGE_PASSWORD.SUCCESS:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: true,
                data: action.data,
                failure: false,
                error: {}
            };
        case types.CHANGE_PASSWORD.FAILURE:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                failure: true,
                error: action.err
            };
        default:
            return state;
    }
}
