import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function resetpasswordRequest(credentials: object): ActionModel {
  return {
    type: types.RESET_PASSWORD.REQUEST,
    credentials
  };
}

export function resetpasswordSuccess(data?: object): ActionModel {
  return {
    type: types.RESET_PASSWORD.SUCCESS,
    data
  };
}

export function resetpasswordFailure(err: object): ActionModel {
  return {
    type: types.RESET_PASSWORD.FAILURE,
    err
  };
}
