import apiCall from "./api.call.config";

async function addPrinterTemplate(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/printer-template",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}
async function printerTemplateDetails(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/printer-template/" +
        params.credentials.uuid +
        "/details"
    );
  } catch (e) {
    throw e;
  }
}

async function printerTemplateList(url: string, params: any) {
  let endpoint =
    params?.credentials?.templateType === "MAIL"
      ? "/mail-template"
      : "/printer-template";
  try {
    return await apiCall.get(
      url + "/" + params.credentials.restaurantuuid + endpoint
    );
  } catch (e) {
    throw e;
  }
}
async function removePrinterTemplate(url: string, params: any) {
  try {
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/printer-template/" +
        params.credentials.uuid
    );
  } catch (e) {
    throw e;
  }
}

async function updatePrinterTemplate(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/printer-template/" +
        params.credentials.uuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  addPrinterTemplate,
  printerTemplateDetails,
  printerTemplateList,
  removePrinterTemplate,
  updatePrinterTemplate,
};
