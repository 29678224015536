import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { TRAFFICLIGHT_YELLOW } from "../../../constant/constant";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import DeleteModalCard from "../../card-components/delete-card/delete-card";
import { saveSupplierIngredients } from "../../../services/supplier.restaurants.service";
import { RESTAURANT_REQUEST } from "../../../config/api.config";
import handleResponse from "../../../services/response.service";
import commonService from "../../../services/common.service";

export interface importedItemInt {
  uuid: string;
  restaurantUuid: string;
  isSupplied: boolean;
  hasReplacementItem: boolean;
  replacementItem: any;
  supplierOffering: {
    modified: boolean;
    data: Array<any>;
  };
}

export default function ItemReplacementCard(props: any) {
  const { itemData, importedItems, itemDeleteHandler, fetchData } = props;
  const { t } = useTranslation();
  const [modelCardOpen, setModelCardOpen] = useState<boolean>(false);
  const [isSupplied, setIsSupplied] = useState<boolean>(true);
  const [importedItem, setImportedItem] = useState<importedItemInt>();
  const [supplierOfferingModified, setSupplierOfferingModified] =
    useState<boolean>(false);
  const [supplierOfferingData, setSupplierOfferingData] = useState<Array<any>>(
    []
  );

  useEffect(() => {
    if (importedItems.hasOwnProperty(itemData.uuid)) {
      setImportedItem(importedItems[itemData.uuid]);
      setIsSupplied(
        itemData?.imported_from_id && importedItem?.isSupplied === true
      );
      const supplierOfferingModified: boolean =
        itemData?.imported_from_id &&
        importedItem?.supplierOffering?.modified === true;
      const supplierOfferingData: Array<any> = supplierOfferingModified
        ? importedItem.supplierOffering.data
        : [];
      setSupplierOfferingModified(supplierOfferingModified);
      setSupplierOfferingData(supplierOfferingData);
    }
  }, [itemData, importedItems, importedItem]);

  const showModal = (event: any) => {
    setModelCardOpen(true);
    event.stopPropagation();
  };

  const confirmReplace = () => {
    itemDeleteHandler(itemData.uuid, "", false);
    saveReplacementItem(
      importedItem?.replacementItem,
      importedItem?.restaurantUuid
    );
  };

  const confirmAdjust = () => {
    saveReplacementItem(supplierOfferingData[0], importedItem?.restaurantUuid);
  };

  const saveReplacementItem = (supplierItem: any, restaurantUuid: any) => {
    const payLoad = {
      credentials: {
        supplieruuid: supplierItem.restaurantUuid,
        restaurantuuid: restaurantUuid,
        data: {
          ingredients: [
            {
              service_set: supplierItem.serviceSetUuid,
              service_set_recipe: supplierItem.serviceSetRecipeUuid,
              ingredient: supplierItem.uuid,
              product_substitute: [],
            },
          ],
        },
      },
    };
    saveSupplierIngredients(RESTAURANT_REQUEST, payLoad).then((data) => {
      handleResponse(data);
      if (data.status === 200) {
        setModelCardOpen(false);
        fetchData();
      }
    });
  };

  const cancelModal = () => {
    setModelCardOpen(false);
  };

  const minorUnit = JSON.parse(
    localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
  )?.minor_units;

  return (
    <>
      {!isSupplied && (
        <div>
          <small title={t("common.noLongerSupplied")}>
            <FontAwesomeIcon
              style={{ color: TRAFFICLIGHT_YELLOW }}
              icon={faExclamationTriangle}
            />
          </small>
          <small className="pl-1">
            {t("common.noLongerSupplied")}
            {importedItem?.hasReplacementItem && (
              <span
                role={"button"}
                onClick={showModal}
                className="link-color pl-1"
              >
                {t("common.useReplacementArticle")}
              </span>
            )}
          </small>

          <DeleteModalCard
            qnTitle={t("common.useReplacementArticle")}
            isOpen={modelCardOpen}
            isFetching={false}
            okDelete={confirmReplace}
            cancelDelete={cancelModal}
          />
        </div>
      )}
      {supplierOfferingModified && (
        <div>
          <small title={t("common.acceptAdjustedOffering")}>
            <FontAwesomeIcon
              style={{ color: TRAFFICLIGHT_YELLOW }}
              icon={faExclamationTriangle}
            />
          </small>
          <small className="pl-1">
            {t("common.qtyPriceOldToNew")
              .replace(
                "[OLD_QUANTITY]",
                supplierOfferingData[0]["currentSymbol"]
              )
              .replace(
                "[OLD_PRICE]",
                parseFloat(
                  commonService.displayDigitalAmount(
                    supplierOfferingData[0]["currentPrice"]
                  )
                )?.toFixed(minorUnit)
              )
              .replace(
                "[NEW_QUANTITY]",
                supplierOfferingData[0]["updatedSymbol"]
              )
              .replace(
                "[NEW_PRICE]",
                parseFloat(
                  commonService.displayDigitalAmount(
                    supplierOfferingData[0]["updatedPrice"]
                  )
                )?.toFixed(minorUnit)
              )}
            <span
              role={"button"}
              onClick={showModal}
              className="link-color pl-1"
            >
              {t("common.acceptAdjustedOffering")}
            </span>
          </small>
          <DeleteModalCard
            qnTitle={t("common.acceptAdjustedOffering")}
            isOpen={modelCardOpen}
            isFetching={false}
            okDelete={confirmAdjust}
            cancelDelete={cancelModal}
          />
        </div>
      )}
    </>
  );
}
