import "../../../styles/storage.scss";

import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  UncontrolledDropdown,
} from "reactstrap";

import {
  StorageRackInitState,
  StorageRackState,
} from "../../../models/storage.rack.model";
import DeleteModalCard from "../delete-card/delete-card";
import StorageRackAddCard from "../storage-rack-add-card/storage-rack-add-card";
import StorageRackCard from "../storage-rack-card/storage-rack-card";
import StorageRackEditCard from "../storage-rack-edit-card/storage-rack-edit-card";
import { withTranslation } from "react-i18next";
import commonService from "../../../services/common.service";
import PrintLabelModal from "../../printer/print-label-modal";

class StorageCard extends Component<any> {
  state: StorageRackState;
  constructor(props: any) {
    super(props);
    this.state = StorageRackInitState;
    this._setFunctionBindings();
  }

  toggleLabelPrintModal = (storageUuid: string, storageType?: string) => () => {
    const printPageUrl = `/restaurant/${this.props.match.params.uuid}/${
      storageType ? "location/" + storageType + "/" : "storage/"
    }${storageUuid}/print-label/`;
    this.setState({
      printPageUrl: printPageUrl,
      openLabelPrintModal: !this.state.openLabelPrintModal,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {/*  Add Storage Rack Modal */}

        <StorageRackAddCard
          state={this.state}
          addStorageRackToggle={this.addStorageRackToggle}
          handleChange={this.handleChange}
          saveStorageRackAdd={this.saveStorageRackAdd}
        />

        {/*  Edit Storage Rack Modal */}

        <StorageRackEditCard
          state={this.state}
          editStorageRackToggle={this.editStorageRackToggle}
          handleChange={this.handleChange}
          saveStorageRackEdit={this.saveStorageRackEdit}
        />

        {/* Delete Storage Rack Modal*/}

        <DeleteModalCard
          isOpen={this.state.deletestoragerackmodal}
          isFetching={!!this.state.isFetching}
          okDelete={this.okDeleteStorageRack}
          cancelDelete={this.deleteStorageRackToggle}
        />

        <PrintLabelModal
          title="printer.selectPrinterTemplate"
          printerTemplates={this.props.printerTemplates}
          isOpen={this.state.openLabelPrintModal}
          toggleModal={this.toggleLabelPrintModal(this.props.storage.uuid)}
          printPage={this.state.printPageUrl}
        />

        <h5>
          {this.props.storage.name}
          <UncontrolledDropdown>
            <DropdownToggle
              tag="a"
              className={"nav-link"}
              style={{ marginLeft: "94%" }}
            >
              <FontAwesomeIcon
                style={{ color: "#3f3f3f" }}
                icon={faEllipsisV}
                pull="right"
              />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                tag="a"
                onClick={this.toggleLabelPrintModal(this.props.storage.uuid)}
              >
                {t("common.printLabel")}
              </DropdownItem>
              <DropdownItem
                tag="a"
                onClick={this.toggleLabelPrintModal(
                  this.props.storage.uuid,
                  "storage"
                )}
              >
                {t("common.printStorageLocation")}
              </DropdownItem>
              <DropdownItem tag="a" onClick={this.props.setStorageEditable}>
                {t("common.edit")}
              </DropdownItem>
              <DropdownItem tag="a" onClick={this.props.setStorageDelete}>
                {t("common.delete")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </h5>
        <div>
          <span>{t("room.room")}:</span>
          <span> {this.props.roomName}</span>
        </div>
        <div>
          <span>{t("station.responsibleStation")}:</span>
          <span> {this.props.stationName}</span>
        </div>
        <div className="mt-3">
          {this.storageRackList(this.state.storageracks)}
        </div>
        <Label
          color="info"
          className={"label-block"}
          onClick={this.addStorageRackToggle}
        >
          {t("rack.createStorageRack")}
        </Label>
      </React.Fragment>
    );
  }

  public storageRackList(storageRackData: any): any {
    let storageRacks;
    if (!!storageRackData) {
      storageRacks = storageRackData.map((storagerack: any, index: number) => {
        return (
          <div className="child-box mb-3" key={index}>
            <StorageRackCard
              index={`${this.props.index + "-" + index}`}
              storagerack={storagerack}
              setStorageRackEditable={this.setStorageRackEditable.bind(
                this,
                storagerack
              )}
              storageuuid={this.props.storage.uuid}
              setStorageRackDelete={this.setStorageRackDelete.bind(
                this,
                storagerack
              )}
              getStorage={this.props.getStorage}
              addStoragePlace={this.props.addStoragePlace}
              updateStoragePlace={this.props.updateStoragePlace}
              removeStoragePlace={this.props.removeStoragePlace}
              roomName={this.props.roomName}
              toggleLabelPrintModal={this.toggleLabelPrintModal}
            />
          </div>
        );
      });
      return storageRacks;
    }
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this._setStates("storageracks", this.props.storage.storageracks);
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
      this._setStates("storageracks", newProps.storage.storageracks);
    }

    if (!!this.state.isUpdated && !newProps.failure && !newProps.isFetching) {
      this.props.getStorage({
        restaurantuuid: this.props.match.params.uuid,
      });
      this._setStates("isUpdated", false);
    }

    if (
      !!newProps.storagerackadd.status &&
      !!this.state.addstoragerackmodal &&
      !newProps.isFetching
    ) {
      this.addStorageRackToggle();
    } else if (
      !!newProps.storagerackupdate.status &&
      !!this.state.editstoragerackmodal &&
      !newProps.isFetching
    ) {
      this.editStorageRackToggle();
    }
  }

  private _setFunctionBindings(): void {
    this._setStates = this._setStates.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.saveStorageRackAdd = this.saveStorageRackAdd.bind(this);
    this.addStorageRackToggle = this.addStorageRackToggle.bind(this);
    this.editStorageRackToggle = this.editStorageRackToggle.bind(this);
    this.deleteStorageRackToggle = this.deleteStorageRackToggle.bind(this);
    this.saveStorageRackEdit = this.saveStorageRackEdit.bind(this);
    this.okDeleteStorageRack = this.okDeleteStorageRack.bind(this);
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  public handleChange(e: any): void {
    this._setStates(e.target.name, e.target.value);
  }

  public saveStorageRackAdd(): void {
    this.props.addStorageRack({
      restaurantuuid: this.props.match.params.uuid,
      storageuuid: this.props.storage.uuid,
      data: {
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.storagerackname,
          },
        },
      },
    });
    this._setStates("isUpdated", true);
    this.addStorageRackToggle();
  }

  public saveStorageRackEdit(): void {
    this.props.updateStorageRack({
      uuid: this.state.uuid,
      restaurantuuid: this.props.match.params.uuid,
      storageuuid: this.props.storage.uuid,
      data: {
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.editstoragerackname,
          },
        },
      },
    });
    this._setStates("isUpdated", true);
  }

  public okDeleteStorageRack(): void {
    this.props.removeStorageRack({
      uuid: this.state.uuid,
      restaurantuuid: this.props.match.params.uuid,
      storageuuid: this.props.storage.uuid,
    });
    this._setStates("isUpdated", true);
    this.deleteStorageRackToggle();
  }

  public addStorageRackToggle(): void {
    this.setState((prevState: any) => ({
      addstoragerackmodal: !prevState.addstoragerackmodal,
      storagerackname: "",
    }));
  }

  public editStorageRackToggle(): void {
    this.setState((prevState: any) => ({
      editstoragerackmodal: !prevState.editstoragerackmodal,
    }));
  }

  public deleteStorageRackToggle(): void {
    this.setState((prevState: any) => ({
      deletestoragerackmodal: !prevState.deletestoragerackmodal,
    }));
  }

  public setStorageRackEditable(storagerackdata: any): void {
    this.setState({
      uuid: storagerackdata.uuid,
      editstoragerackname: storagerackdata.name,
    });
    this.editStorageRackToggle();
  }

  public setStorageRackDelete(storagerackdata: any): void {
    this._setStates("uuid", storagerackdata.uuid);
    this.deleteStorageRackToggle();
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.storagerackadd.isFetching ||
    state.storagerackupdate.isFetching ||
    state.storagerackremove.isFetching;
  let failure =
    state.storagerackadd.failure ||
    state.storagerackupdate.failure ||
    state.storagerackremove.failure;
  return {
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    storagerackadd: state.storagerackadd.data,
    storagerackupdate: state.storagerackupdate.data,
    isFetching: isFetching,
    failure: failure,
  };
};

export default withTranslation()(
  connect(mapStateToProps)(withRouter(StorageCard))
);
