import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function changePasswordRequest(credentials: object): ActionModel {
    return {
        type: types.CHANGE_PASSWORD.REQUEST,
        credentials
    };
}

export function changePasswordSuccess(data?: object): ActionModel {
    return {
        type: types.CHANGE_PASSWORD.SUCCESS,
        data
    };
}

export function changePasswordFailure(err: object): ActionModel {
    return {
        type: types.CHANGE_PASSWORD.FAILURE,
        err
    };
}
