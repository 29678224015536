import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function printerTemplateRequest(credentials: object): ActionModel {
  return {
    type: types.PRINTER_TEMPLATE.REQUEST,
    credentials,
  };
}

export function printerTemplateSuccess(data: object): ActionModel {
  return {
    type: types.PRINTER_TEMPLATE.SUCCESS,
    data,
  };
}

export function printerTemplateFailure(err: object): ActionModel {
  return {
    type: types.PRINTER_TEMPLATE.FAILURE,
    err,
  };
}
export function printerTemplateAdd(credentials: object): ActionModel {
  return {
    type: types.PRINTER_TEMPLATE_ADD.REQUEST,
    credentials,
  };
}

export function printerTemplateAddSuccess(data?: object): ActionModel {
  return {
    type: types.PRINTER_TEMPLATE_ADD.SUCCESS,
    data,
  };
}

export function printerTemplateAddFailure(err: object): ActionModel {
  return {
    type: types.PRINTER_TEMPLATE_ADD.FAILURE,
    err,
  };
}

export function printerTemplateDetails(credentials: object): ActionModel {
  return {
    type: types.PRINTER_TEMPLATE_DETAILS.REQUEST,
    credentials,
  };
}

export function printerTemplateDetailsSuccess(data?: object): ActionModel {
  return {
    type: types.PRINTER_TEMPLATE_DETAILS.SUCCESS,
    data,
  };
}

export function printerTemplateDetailsFailure(err: object): ActionModel {
  return {
    type: types.PRINTER_TEMPLATE_DETAILS.FAILURE,
    err,
  };
}

export function printerTemplateRemove(credentials: object): ActionModel {
  return {
    type: types.PRINTER_TEMPLATE_REMOVE.REQUEST,
    credentials,
  };
}

export function printerTemplateRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.PRINTER_TEMPLATE_REMOVE.SUCCESS,
    data,
  };
}

export function printerTemplateRemoveFailure(err: object): ActionModel {
  return {
    type: types.PRINTER_TEMPLATE_REMOVE.FAILURE,
    err,
  };
}
export function printerTemplateUpdate(credentials: object): ActionModel {
  return {
    type: types.PRINTER_TEMPLATE_UPDATE.REQUEST,
    credentials,
  };
}

export function printerTemplateUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.PRINTER_TEMPLATE_UPDATE.SUCCESS,
    data,
  };
}

export function printerTemplateUpdateFailure(err: object): ActionModel {
  return {
    type: types.PRINTER_TEMPLATE_UPDATE.FAILURE,
    err,
  };
}
