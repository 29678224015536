import apiCall from './api.call.config';

async function storage(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.get(url + '/' + params.credentials.restaurantuuid + '/storage?sort=name:asc');
    } catch (e) {
        throw e;
    }
}

async function addStorage(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url + '/' + params.credentials.restaurantuuid + '/storage', params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function updateStorage(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.put(url + '/' + params.credentials.restaurantuuid + '/storage/'
            + params.credentials.uuid, params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function removeStorage(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.remove(url + '/' + params.credentials.restaurantuuid + '/storage/' + params.credentials.uuid);
    } catch (e) {
        throw e;
    }
}

export async function storagePrintLabel(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.restaurantuuid +
        "/storage/" +
        params.storageUuid +
        "/print-label",
      params.data
    );
  } catch (e) {
    throw e;
  }
}

export async function locationPrintLabel(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.restaurantuuid +
        "/location/" +
        params.storageType +
        "/" +
        params.storageUuid +
        "/print-label",
      params.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  storage,
  addStorage,
  updateStorage,
  removeStorage,
  storagePrintLabel,
  locationPrintLabel,
};
