import { ProfileSettingsModel } from '../../models/profile.model';
import * as types from '../actions/types';

const initialState: ProfileSettingsModel = {
    isAuthenticated: false,
    isFetching: false,
    data: {},
    error: {},
    failure: false
};

export default function profileSettingsReducer(state = initialState, action: any): ProfileSettingsModel {
    switch (action.type) {
        case types.PROFILE_SETTINGS.REQUEST:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false,
                failure: false,
                error: {}
            };
        case types.PROFILE_SETTINGS.SUCCESS:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: true,
                data: action.data,
                failure: false,
                error: {}
            };
        case types.PROFILE_SETTINGS.FAILURE:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                failure: true,
                error: action.err
            };

        case types.PROFILE_SETTINGS_UPDATE.REQUEST:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false,
                failure: false,
                error: {}
            };
        case types.PROFILE_SETTINGS_UPDATE.SUCCESS:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: true,
                data: action.data,
                failure: false,
                error: {}
            };
        case types.PROFILE_SETTINGS_UPDATE.FAILURE:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                failure: true,
                error: action.err
            };
        default:
            return state;
    }
}