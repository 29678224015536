import { getIngredientsReplacement } from "../services/ingredients.new.service";
import handleResponse from "../services/response.service";
import { RESTAURANT_REQUEST } from "../config/api.config";

export const ItemReplacementHelper = {
  setImportedItems: (
    items: Array<any>,
    restaurantUuid: string,
    callback: Function
  ) => {
    const importedItems: Array<string> = items?.reduce((accumulator, item) => {
      if (item?.imported_from_id) {
        accumulator.push(item?.uuid);
      }
      return accumulator;
    }, []);
    ItemReplacementHelper.setImportedFromUuidsReplacement(
      importedItems,
      restaurantUuid,
      callback
    );
  },

  setImportedFromUuidsReplacement: (
    importedItems: Array<string>,
    restaurantUuid: string,
    callback: Function
  ): void => {
    if (!importedItems || importedItems.length === 0) return;
    const body: any = {
      restaurantuuid: restaurantUuid,
      data: {
        recipe_uuids: importedItems,
      },
    };
    getIngredientsReplacement(RESTAURANT_REQUEST, body).then((data: any) => {
      handleResponse(data);
      if (data.status === 200) {
        callback(data?.data);
      }
    });
  },
};
