import '../../styles/forgotpassword.scss';

import React, { Component } from 'react';

import ForgotpasswordComponent from '../../components/forgotpassword/forgotpassword';

class ForgotpasswordPage extends Component {

    render() {
        return <ForgotpasswordComponent />;
    }
}
export default ForgotpasswordPage;
