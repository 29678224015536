import apiCall from './api.call.config';

async function componentList(url: string) {
    try {
        return await apiCall.get(url + '?sort=name:asc');
    } catch (e) {
        throw e;
    }
}

export default { componentList }
