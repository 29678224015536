import "../../styles/page404.scss";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Alert, Col, Container, Label, Row, Table } from "reactstrap";

import smartToniLogin from "../../assets/images/logo/smartToniLogin.png";
import { withTranslation } from "react-i18next";
import { GRAY } from "../../constant/constant";
import moment from "moment";
import commonService from "../../services/common.service";
import { template } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
class ReportingTemplates extends Component<any> {
  render() {
    const { t, templates } = this.props;
    return (
      <>
        {templates?.length == 0 && (
          <Alert className="mt-3 " color="info">
            {t("common.noRecords")}
          </Alert>
        )}
        {templates?.length > 0 && (
          <>
            <section className="mt-3 mb-5">
              {templates.map((template: any, index: any) => {
                return (
                  <div className="child-box mb-3" key={index}>
                    <span style={{ marginTop: 20 }}>
                      <div style={{ float: "right", display: "flex" }}>
                        <span
                          onClick={() =>
                            this.props.deleteTemplate(template.uuid)
                          }
                          className="pointer-cursor"
                          data-toggle="tooltip"
                          data-placement="left"
                          title={t("common.delete")}
                        >
                          <FontAwesomeIcon
                            style={{
                              color: GRAY,
                              fontSize: 20,
                              marginLeft: 10,
                            }}
                            icon={faTrashAlt}
                          />
                        </span>
                      </div>
                    </span>
                    <Row className="app-row-padding">
                      <Col xs="8" sm="4">
                        {template.filename}
                      </Col>
                      <Col xs="8" sm="4">
                        <Label> </Label>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </section>
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withRouter(ReportingTemplates));
