import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { create } from "apisauce";
import ModalFactory from "react-modal-promise";

import LoaderComponent from "../../components/loader/loader";
import SettingsHeaderComponent from "../../components/navigation/navigation-header/settings-header/settings-header";
import SettingsNavComponent from "../../components/navigation/navigation-left/settings-navigation/settings-navigation";
import NavigationRightComponent from "../../components/navigation/navigation-right/navigation-right";
import PaymentOptionsAddEditModal from "../../components/payment-options/paymentOptionsAddEditModal";
import { BASE_URL } from "../../config/app.config";
import { ACCESS_TOKEN } from "../../constant/constant";
import { OpenDeleteModal } from "../../components/card-components/delete-card/delete-modal";

import {
  PaymentProviderState,
  PaymentProviderInitState,
} from "../../models/payment.provider.model";
import commonService from "../../services/common.service";
import { withTranslation } from "react-i18next";
import {
  paymentProviderAdd,
  paymentProviderRemove,
  paymentProviderRequest,
  paymentProviderUpdate,
} from "../../redux/actions/payment.provider.action";
import ImageTitleComponentWrapper from "../../components/card-components/image-title-component/imageTitleComponentWrapper";
import { Col, Row } from "reactstrap";

const api = create({
  baseURL: BASE_URL,
});

class PaymentOptionsComponent extends Component<any> {
  state: PaymentProviderState;
  constructor(props: any) {
    super(props);
    this.state = PaymentProviderInitState;
    api.setHeaders({
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Cache-Control": "no-cache",
      Locale: localStorage.getItem("i18nextLng") || "en-gb",
      "content-type": "application/json",
    });
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.paymentProvider({
      restaurantuuid: this.props.match.params.uuid,
    });
    this.setState({ restaurantuuid: this.props.match.params.uuid });
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      (this.state.addItemData !== {} &&
        prevState.addItemData !== this.state.addItemData) ||
      (this.state.updateItemData !== {} &&
        prevState.updateItemData !== this.state.updateItemData)
    ) {
      this.setState({
        isAddItemModalOpen: false,
        itemModalData: {
          name: "",
          description: "",
        },
        selectedItemId: null,
        updateItemData: {},
      });
      this.props.paymentProvider({
        restaurantuuid: this.props.match.params.uuid,
      });
    }
    if (
      prevState.isDeleted &&
      Object.keys(this.state.removeItemData).length !== 0 &&
      this.state.removeItemData !== prevState.removeItemData
    ) {
      this.setState({ isDeleted: false, removeItemData: {} });
      this.props.paymentProvider({
        restaurantuuid: this.props.match.params.uuid,
      });
    }
    if (!!this.state.fetchData) {
      this.props.paymentProvider({
        restaurantuuid: this.props.match.params.uuid,
      });
      this.setState({
        fetchData: false,
      });
    }
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    let update: any = {};
    if (nextProps.isFetching !== prevState.isFetching) {
      update.isFetching = nextProps.isFetching;
    }
    if (nextProps.items !== prevState.items) {
      update.items = nextProps.items;
    }
    if (
      nextProps.addItemData &&
      nextProps.addItemData !== prevState.addItemData
    ) {
      update.addItemData = nextProps.addItemData;
    }
    if (
      Object.keys(nextProps.removeItemData).length !== 0 &&
      nextProps.removeItemData !== prevState.removeItemData
    ) {
      update.removeItemData = nextProps.removeItemData;
    }
    if (
      !!nextProps.updateItemData &&
      nextProps.updateItemData !== prevState.updateItemData
    ) {
      update.updateItemData = nextProps.updateItemData;
    }
    if (
      !!prevState.isAddItemModalOpen &&
      !nextProps.isFetching &&
      !!prevState.isUpdated
    ) {
      update.isAddItemModalOpen = false;
      update.isUpdated = false;
      update.fetchData = true;
    }
    if (!nextProps.isFetching && !!prevState.isUpdated) {
      update.isUpdated = false;
      update.fetchData = true;
    }
    return update;
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <div>
          <ModalFactory />
          <LoaderComponent display={this.state.isFetching} />

          <PaymentOptionsAddEditModal
            formData={this.state.itemModalData}
            handleCancel={this.handleItemUpdateCancel}
            handleSubmit={this.handleSubmit}
            isModalOpen={this.state.isAddItemModalOpen}
          />
          <div className="container-fluid">
            <SettingsHeaderComponent settings={"settings"} />
            <Row className="main light-theme">
              <Col xl={2} lg={3} className="hide-left-max">
                <SettingsNavComponent
                  display={"paymentOptions"}
                  restaurantuuid={this.props.match.params.uuid}
                />
              </Col>
              <Col xl={8} lg={6}>
                <ImageTitleComponentWrapper
                  cardType="image-title"
                  elementId={this.state.elementId}
                  fetchData={this.fetchData}
                  getExtraDetails={this.getExtraDetails}
                  itemData={this.state.items}
                  itemsDetails={this.state.itemsDetails}
                  onAddNewItem={this.handleAddItemClick}
                  onItemDelete={this.handleItemDelete}
                  onItemEdit={this.handleItemEdit}
                  pageText={this.getPageTitle()}
                  setItemId={this.setItemId}
                />
              </Col>
              <Col xl={2} lg={3}>
                <NavigationRightComponent />
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }

  public fetchData = () => {
    this.setState({
      fetchData: true,
    });
  };

  public setItemId = (itemId: string) => {
    this.setState({
      elementId: itemId,
    });
  };

  public getExtraDetails = (data: any) => {
    let localeData = commonService.applyLocalization(
      "restaurant",
      "description",
      data.locales
    );

    return <p className="text-break">{localeData.description}</p>;
  };

  public getPageTitle = () => {
    return {
      addButtonTitle: this.props.t("payment.addPaymentOption"),
      pageTitle: this.props.t("payment.paymentOptions"),
    };
  };
  public handleAddItemClick = (data: any) => {
    this.setState({ isAddItemModalOpen: !this.state.isAddItemModalOpen });
  };

  public handleItemDelete = (itemId: any) => {
    OpenDeleteModal().then(() => {
      this.setState({ isDeleted: true });
      this.props.removePaymentProvider({
        restaurantuuid: this.props.match.params.uuid,
        uuid: itemId,
      });
    });
  };

  public handleItemEdit = (itemId: any) => {
    const [selectedItem]: any = this.state.items.filter((item: any) => {
      return item.uuid === itemId;
    });
    const name = commonService.applyLocalization(
      "restaurant",
      "name",
      selectedItem.locales
    ).name;

    const description = commonService.applyLocalization(
      "restaurant",
      "description",
      selectedItem.locales
    ).description;

    this.setState({
      isAddItemModalOpen: true,
      itemModalData: {
        name,
        description,
        isEdit: true,
      },
      selectedItemId: itemId,
    });
  };
  public handleItemUpdateCancel = () => {
    this.setState({
      isAddItemModalOpen: false,
      itemModalData: {
        name: "",
        description: "",
        isEdit: false,
      },
      selectedItemId: "",
    });
  };

  public handleSubmit = (data: any) => {
    if (!!this.state.selectedItemId) {
      this.props.updatePaymentProvider({
        data,
        restaurantuuid: this.props.match.params.uuid,
        uuid: this.state.selectedItemId,
      });
    } else {
      this.props.addPaymentProvider({
        data,
        restaurantuuid: this.props.match.params.uuid,
      });
    }
  };
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.paymentProvider.isFetching ||
    state.paymentProviderAdd.isFetching ||
    state.paymentProviderRemove.isFetching ||
    state.paymentProviderUpdate.isFetching ||
    state.upload.isFetching;
  let failure =
    state.paymentProvider.failure ||
    state.paymentProviderAdd.failure ||
    state.paymentProviderRemove.failure ||
    state.paymentProviderUpdate.failure ||
    state.upload.failure;
  let states: any = {
    addItemData: state.paymentProviderAdd.data,
    failure: failure,
    isFetching: isFetching,
    items: state.paymentProvider.data,
    removeItemData: state.paymentProviderRemove.data,
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    updateItemData: state.paymentProviderUpdate.data,
  };
  return states;
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    addPaymentProvider: (credentials: any) => {
      dispatch(paymentProviderAdd(credentials));
    },
    paymentProvider: (credentials: any) => {
      dispatch(paymentProviderRequest(credentials));
    },
    removePaymentProvider: (credentials: any) => {
      dispatch(paymentProviderRemove(credentials));
    },
    updatePaymentProvider: (credentials: any) => {
      dispatch(paymentProviderUpdate(credentials));
    },
  };
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(PaymentOptionsComponent))
);
