import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import users from "../../services/users.service";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import commonService from "../../services/common.service";

interface AddStaffProps {
  isOpen: boolean;
  toggleModal: React.MouseEventHandler<any>;
  restaurantUuid: string;
  invokeParentUpdate: Function;
}

const initFormData = {
  first_name: "",
  last_name: "",
  password: "",
};

const AddStaff: React.FC<AddStaffProps> = (props) => {
  const { isOpen, toggleModal, restaurantUuid, invokeParentUpdate } = props;
  const { t } = useTranslation();
  const [formData, setFormData] = useState(initFormData);
  const [passwordForm, setPasswordForm] = useState<boolean>(false);

  useEffect(() => {
    setFormData(initFormData);
    setPasswordForm(false);
  }, [isOpen]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const showPasswordForm = (e: any) => {
    e.preventDefault();
    if (formData.first_name !== "" && formData.last_name !== "") {
      setPasswordForm(true);
    }
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    users
      .addStaff(RESTAURANT_REQUEST, {
        restaurantuuid: restaurantUuid,
        data: formData,
      })
      .then((response: any) => {
        if (response.status === 200 && response.data.status === true) {
          commonService.toastService(response.data.flash, "success");
          toggleModal(e);
          invokeParentUpdate();
        } else {
          commonService.toastService(
            "",
            "danger",
            JSON.stringify(response.data.errors)
          );
        }
      })
      .catch((error: any) => {
        commonService.toastService("", "danger", JSON.stringify(error.error));
      });
  };

  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={toggleModal} className="modal-header">
          {passwordForm ? t("common.setPassword") : t("common.addStaff")}
        </ModalHeader>
        <ModalBody>
          <Form>
            {!passwordForm && (
              <>
                <FormGroup>
                  <Label for="first_name">{t("common.firstName")}</Label>
                  <Label className="required-star-style">*</Label>
                  <Input
                    type="text"
                    required
                    name="first_name"
                    placeholder={t("common.firstName")}
                    value={formData.first_name}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="last_name">{t("common.lastName")}</Label>
                  <Label className="required-star-style">*</Label>
                  <Input
                    type="text"
                    required
                    name="last_name"
                    placeholder={t("common.lastName")}
                    value={formData.last_name}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </>
            )}
            {passwordForm && (
              <FormGroup>
                <Label for="password">{t("common.password")}</Label>
                <Label className="required-star-style">*</Label>
                <Input
                  type="password"
                  required
                  name="password"
                  placeholder={t("common.password")}
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </FormGroup>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            {t("common.cancel")}
          </Button>
          {passwordForm ? (
            <Button
              color="info"
              onClick={handleFormSubmit}
              disabled={!formData.password}
            >
              {t("common.save")}
            </Button>
          ) : (
            <Button
              color="info"
              onClick={showPasswordForm}
              disabled={!formData.first_name}
            >
              {t("common.ok")}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddStaff;
