import { RoleModel } from '../../models/roles.model';
import * as types from '../actions/types';

const initialState: RoleModel = {
    isFetching: false,
    data: {},
    error: {},
    failure: false,
  };

  export default function rolesReducer(state = initialState, action: any): RoleModel {
    switch (action.type) {
        case types.ROLES.REQUEST:
            return {
              ...state,
              isFetching: true,
              failure: false,
              error: {}
            };
          case types.ROLES.SUCCESS:
            return {
              ...state,
              isFetching: false,
              data: action.data,
              failure: false,
              error: {}
            };
          case types.ROLES.FAILURE:
            return {
              ...state,
              isFetching: false,
              failure: true,
              error: action.err
            };
          default:
            return state;
        }
}