import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import {
    machineAddFailure,
    machineAddSuccess,
    machineFailure,
    machineRemoveFailure,
    machineRemoveSuccess,
    machineSuccess,
    machineUpdateFailure,
    machineUpdateSuccess,
} from '../redux/actions/machine.action';
import * as types from '../redux/actions/types';
import machine from '../services/machine.service';
import handleResponse from '../services/response.service';

const machineCall = (params: any) => machine.machine(RESTAURANT_REQUEST, params);
const updateMachineCall = (params: any) => machine.updateMachine(RESTAURANT_REQUEST, params);
const addMachineCall = (params: any) => machine.addMachine(RESTAURANT_REQUEST, params);
const removeMachineCall = (params: any) => machine.removeMachine(RESTAURANT_REQUEST, params);

function* handleMachineRequest(params: object) {
    try {
        let result;
        result = yield call(machineCall, params);
        yield handleMachine200(result);
    } catch (error) {
        throw error;
    }
}

const handleMachine200 = function* handleMachine200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(machineSuccess(result.data));
    } else {
        yield put(machineFailure(result.data));
        handleResponse(result);
    }
}

function* handleMachineUpdate(params: object) {
    try {
        let result;
        result = yield call(updateMachineCall, params);
        yield handleMachineUpdate200(result);
    } catch (error) {
        throw error;
    }
}

const handleMachineUpdate200 = function* handleMachineUpdate200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(machineUpdateSuccess(result.data));
    } else {
        yield put(machineUpdateFailure(result.data));
    }
    handleResponse(result);
}

function* handleMachineAdd(params: object) {
    try {
        let result;
        result = yield call(addMachineCall, params);
        yield handleMachineAdd200(result);
    } catch (error) {
        throw error;
    }
}

const handleMachineAdd200 = function* handleMachineAdd200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(machineAddSuccess(result.data));
    } else {
        yield put(machineAddFailure(result.data));
    }
    handleResponse(result);
}

function* handleMachineRemove(params: object) {
    try {
        let result;
        result = yield call(removeMachineCall, params);
        yield handleMachineRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handleMachineRemove200 = function* handleMachineRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(machineRemoveSuccess(result.data));
    } else {
        yield put(machineRemoveFailure(result.data));
    }
    handleResponse(result);
}
export default function* machineSaga() {
    yield takeLatest(types.MACHINE.REQUEST, handleMachineRequest);
    yield takeLatest(types.MACHINE_UPDATE.REQUEST, handleMachineUpdate);
    yield takeLatest(types.MACHINE_ADD.REQUEST, handleMachineAdd);
    yield takeLatest(types.MACHINE_REMOVE.REQUEST, handleMachineRemove);
}
