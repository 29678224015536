import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import commonService from "../../services/common.service";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";

export default function ServiceSetAddModal(props: any) {
  const { t, i18n } = useTranslation();
  const statusOptions: Array<any> = [
    {
      label: t("common.active"),
      value: "1",
    },
    {
      label: t("common.inactive"),
      value: "0",
    },
  ];
  const [isSubmitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(statusOptions[0]);
  const [vatClass, setVatClass] = useState({
    label: "",
    value: "",
  });

  useEffect(() => {
    if (!!Object.keys(props.formData)) {
      setName(props.formData.name);
      setDescription(props.formData.description);
      const status: any = statusOptions.find(
        (st: any) => st.value === String(props.formData.status)
      );

      setStatus(status ? status : statusOptions[0]);

      let vat =
        Object.keys(props.vatList || "{}").length > 0
          ? props.vatList
              .filter(
                (item: any) => item.uuid === String(props.formData.vatClass)
              )
              .map((vat: any) => {
                return {
                  label: commonService.applyLocalization(
                    "restaurant",
                    "name",
                    vat?.locales
                  )["name"],
                  value: vat.uuid,
                };
              })
          : {
              label: "",
              value: "",
            };
      setVatClass(vat[0]);
      if (!!props.formData?.isEdit) {
        setSubmitBtnDisabled(false);
      } else {
        setSubmitBtnDisabled(true);
      }
    }
  }, [props.formData]);

  const handleChange = (data: any) => {
    setSubmitBtnDisabled(true);
    if (data?.target?.name === "name") {
      if (data.target.value.trim() !== "") setName(data.target.value);
      else setName("");

      if (data.target.value.trim() !== "" && !!vatClass) {
        setSubmitBtnDisabled(false);
      }
    } else if (data?.target?.name === "description") {
      setDescription(data.target.value);
      if (name.trim() !== "" && Object.keys(vatClass || "{}").length > 0)
        setSubmitBtnDisabled(false);
    } else {
      setVatClass(data);
      if (data.value !== "" && !!name) {
        setSubmitBtnDisabled(false);
      }
    }
  };

  const handleSubmit = () => {
    let locales: any = {};
    locales[props.restaurantLang[0].code] = { name, description };
    props.handleSubmit({
      locales,
      vat_class_uuid: vatClass?.value,
      status: parseInt(status.value),
    });
  };

  const getVatOptions = () => {
    return Object.keys(props.vatList).length > 0
      ? props.vatList?.map((vat: any) => {
          return {
            value: vat.uuid,
            label: commonService.applyLocalization(
              "restaurant",
              "name",
              vat?.locales
            )["name"],
          };
        })
      : [];
  };

  const { textLabels } = props;
  return (
    <React.Fragment>
      <Modal isOpen={props.isModalOpen}>
        <ModalHeader toggle={props.handleCancel} className="modal-header">
          {textLabels?.modalTitle}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="name">
                {t("common.name")} <span className="text-danger">*</span>
              </Label>
              <Input
                name="name"
                required
                placeholder={t("common.enterName")}
                type="text"
                value={name}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">{t("common.description")}</Label>

              <Input
                type="textarea"
                style={{ minHeight: 200 }}
                required
                name="description"
                autoComplete="description"
                placeholder={t("common.enterDescription")}
                value={description}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="vat">
                {t("vat.vat")}
                <span className="text-danger">*</span>
              </Label>
              <Select
                options={getVatOptions()}
                onChange={handleChange}
                value={vatClass}
                name="vat"
              />
            </FormGroup>
            <FormGroup>
              <Label for="vat">
                {t("common.status")}
                <span className="text-danger">*</span>
              </Label>
              <Select
                options={statusOptions}
                onChange={(e: any) => setStatus(e)}
                value={status}
                name="status"
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.handleCancel}>
            {t("common.cancel")}
          </Button>
          <Button
            color="info"
            onClick={handleSubmit}
            disabled={isSubmitBtnDisabled}
          >
            {t("common.save")}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
