import apiCall from "./api.call.config";

async function tables(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.credentials.restaurantuuid + "/table"
    );
  } catch (e) {
    throw e;
  }
}

async function addTable(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/table",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function removeTable(url: string, params: any) {
  try {
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/table/" +
        params.credentials.uuid
    );
  } catch (e) {
    throw e;
  }
}

async function updateTable(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/table/" +
        params.credentials.uuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  tables,
  addTable,
  removeTable,
  updateTable,
};
