import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function machineRequest(credentials: object): ActionModel {
  return {
    type: types.MACHINE.REQUEST,
    credentials
  };
}

export function machineSuccess(data: object): ActionModel {
  return {
    type: types.MACHINE.SUCCESS,
    data
  };
}

export function machineFailure(err: object): ActionModel {
  return {
    type: types.MACHINE.FAILURE,
    err
  };
}

export function machineUpdate(credentials: object): ActionModel {
  return {
    type: types.MACHINE_UPDATE.REQUEST,
    credentials
  };
}

export function machineUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.MACHINE_UPDATE.SUCCESS,
    data
  };
}

export function machineUpdateFailure(err: object): ActionModel {
  return {
    type: types.MACHINE_UPDATE.FAILURE,
    err
  };
}

export function machineAdd(credentials: object): ActionModel {
  return {
    type: types.MACHINE_ADD.REQUEST,
    credentials
  };
}

export function machineAddSuccess(data?: object): ActionModel {
  return {
    type: types.MACHINE_ADD.SUCCESS,
    data
  };
}

export function machineAddFailure(err: object): ActionModel {
  return {
    type: types.MACHINE_ADD.FAILURE,
    err
  };
}

export function machineRemove(credentials: object): ActionModel {
  return {
    type: types.MACHINE_REMOVE.REQUEST,
    credentials
  };
}

export function machineRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.MACHINE_REMOVE.SUCCESS,
    data
  };
}

export function machineRemoveFailure(err: object): ActionModel {
  return {
    type: types.MACHINE_REMOVE.FAILURE,
    err
  };
}
