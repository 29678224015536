export interface supplierOfferingState {
  cost: number;
  currentItem: any;
  currentPage: number;
  deliveryDate: any;
  orderComment: string;
  filterParams: any;
  hasPagination: boolean;
  hideFilter: boolean;
  isFetching: boolean;
  isFilterApplied: boolean;
  isModalOpen: boolean;
  isUpdated: boolean;
  itemsDetails: any;
  recipeDetails: any;
  recipeSubstituteArray: any;
  selectedIndex: any;
  selectedRecipe: any;
  shoppingCartArray: any;
  showCartModal: boolean;
  showOrderModal: boolean;
  supplier: any;
  supplierIngredients: any;
  supplierRestaurants: any;
  supplierUnits: any;
  totalCount: number;
  totalPages: number;
  labels: Array<any>;
  alphabeticalSortFlag: boolean;
  supplierActiveImports: Array<any>;
  isBrowserNav: boolean;
}
export const supplierOfferingStateInit = {
  cost: 0,
  currentItem: {},
  currentPage: 1,
  deliveryDate: "",
  orderComment: "",
  filterParams: {},
  hasPagination: false,
  hideFilter: true,
  isFetching: false,
  isFilterApplied: false,
  isModalOpen: false,
  isUpdated: false,
  itemsDetails: [],
  recipeDetails: [],
  recipeSubstituteArray: [],
  selectedIndex: "",
  selectedRecipe: {},
  shoppingCartArray: [],
  showCartModal: false,
  showOrderModal: false,
  supplier: {},
  supplierIngredients: [],
  supplierRestaurants: [],
  supplierUnits: [],
  totalCount: 0,
  totalPages: 0,
  labels: [],
  alphabeticalSortFlag: false,
  supplierActiveImports: [],
  isBrowserNav: false,
};
