import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import * as types from '../redux/actions/types';
import handleResponse from '../services/response.service';
import storagePlace from '../services/storage.place.service';
import {
    storagePlaceAddSuccess,
    storagePlaceAddFailure,
    storagePlaceUpdateSuccess,
    storagePlaceUpdateFailure,
    storagePlaceRemoveSuccess,
    storagePlaceRemoveFailure
} from '../redux/actions/storage.place.action';

const addStoragePlaceCall = (params: any) => storagePlace.addStoragePlace(RESTAURANT_REQUEST, params);
const updateStoragePlaceCall = (params: any) => storagePlace.updateStoragePlace(RESTAURANT_REQUEST, params);
const removeStoragePlaceCall = (params: any) => storagePlace.removeStoragePlace(RESTAURANT_REQUEST, params);

function* handleStoragePlaceAdd(params: object) {
    try {
        let result;
        result = yield call(addStoragePlaceCall, params);
        yield handleStoragePlaceAdd200(result);
    } catch (error) {
        throw error;
    }
}

const handleStoragePlaceAdd200 = function* handleStoragePlaceAdd200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(storagePlaceAddSuccess(result.data));
    } else {
        yield put(storagePlaceAddFailure(result.data));
    }
    handleResponse(result);
}

function* handleStoragePlaceUpdate(params: object) {
    try {
        let result;
        result = yield call(updateStoragePlaceCall, params);
        yield handleStoragePlaceUpdate200(result);
    } catch (error) {
        throw error;
    }
}

const handleStoragePlaceUpdate200 = function* handleStoragePlaceUpdate200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(storagePlaceUpdateSuccess(result.data));
    } else {
        yield put(storagePlaceUpdateFailure(result.data));
    }
    handleResponse(result);
}
function* handleStoragePlaceRemove(params: object) {
    try {
        let result;
        result = yield call(removeStoragePlaceCall, params);
        yield handleStoragePlaceRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handleStoragePlaceRemove200 = function* handleStoragePlaceRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(storagePlaceRemoveSuccess(result.data));
    } else {
        yield put(storagePlaceRemoveFailure(result.data));
    }
    handleResponse(result);
}

export default function* storagePlaceSaga() {
    yield takeLatest(types.STORAGE_PLACE_ADD.REQUEST, handleStoragePlaceAdd);
    yield takeLatest(types.STORAGE_PLACE_UPDATE.REQUEST, handleStoragePlaceUpdate);
    yield takeLatest(types.STORAGE_PLACE_REMOVE.REQUEST, handleStoragePlaceRemove);
}