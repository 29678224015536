export interface RecipePrinterModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface PrinterState {
  addmodal: boolean;
  deletemodal: boolean;
  editmodal: boolean;
  isFetching: boolean;
  isUpdated: boolean;
  keyValuePairs: any;
  name: string;
  newKey: any;
  newKeyError: any;
  newValue: any;
  open: boolean;
  printer: any;
  printerip: string;
  printerport: string;
  printerType: any;
  restaurantuuid: string;
  uuid: string;
  templates: any;
}

export const PrinterInitState = {
  addmodal: false,
  editmodal: false,
  deletemodal: false,
  isFetching: false,
  isUpdated: false,
  keyValuePairs: {},
  name: "",
  newKey: {},
  newKeyError: {},
  newValue: {},
  open: false,
  printer: [],
  printerip: "",
  printerport: "",
  printerType: null,
  restaurantuuid: "",
  uuid: "",
  templates: [],
};
