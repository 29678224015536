import "../../../styles/printer.scss";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";

import { faArrowLeft, faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mobiscroll from "@mobiscroll/react";
import React, { Component } from "react";
import {
  Badge,
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { GREEN } from "../../../constant/constant";
import i18next from "i18next";
import { useTranslation, withTranslation } from "react-i18next";

class POSRecipeAddCard extends Component<any> {
  render() {
    const { t } = this.props;
    return (
      <Modal
        isOpen={this.props.state.nestedModal}
        toggle={this.props.toggleNested}
        className="modal-dialog-centered modal-lg"
        backdrop="static"
      >
        <ModalBody>
          <Row className="app-row-padding">
            <Col xs="6" className="border-rt">
              <div>
                <div style={{ borderColor: "#333333e0", margin: 10 }}>
                  <h6 style={{ marginBottom: 0 }}>
                    <Label color="info">{t("common.deliveryTo")}:</Label>
                  </h6>
                  <Row className="app-row-padding">
                    <Col
                      sm={{ size: "auto", offset: 1 }}
                      style={{ marginTop: 5 }}
                    >
                      <Input
                        type="select"
                        name="deliveryto"
                        id="deliveryto"
                        onChange={this.props.handleDeliveryChange.bind(this)}
                        style={{
                          display: !this.props.state.editOrder
                            ? "block"
                            : "none",
                        }}
                        value={this.props.state.deliveryto}
                      >
                        <option value="inventory">
                          {t("inventory.inventory")}
                        </option>
                        <option value="order">{t("table.table")}</option>
                      </Input>
                      <h5
                        style={{
                          display: !!this.props.state.editOrder
                            ? "block"
                            : "none",
                        }}
                      >
                        <Badge color="secondary" pill>
                          {!!this.props.state.eventdetail.is_inventory
                            ? t("inventory.inventory")
                            : t("table.table")}
                        </Badge>
                      </h5>
                    </Col>
                    <Col
                      sm={{ size: 7, offset: 1 }}
                      style={{
                        display:
                          this.props.state.deliveryto === "order"
                            ? "block"
                            : "none",
                        marginTop: 5,
                      }}
                    >
                      <Input
                        type="text"
                        value={this.props.state.table}
                        name="table"
                        placeholder={t("table.tableNumber")}
                        onChange={this.props.handleChange.bind(this)}
                      />
                    </Col>
                  </Row>
                </div>
                <div style={{ borderColor: "#333333e0", margin: 10 }}>
                  <h6 style={{ marginBottom: 0 }}>
                    <Label color="info">{t("common.deliveringAt")}:</Label>
                  </h6>
                  <Row className="app-row-padding">
                    <Col
                      sm={{ size: "auto", offset: 1 }}
                      style={{ marginTop: 5 }}
                    >
                      <Input
                        type="select"
                        name="deliveringat"
                        id="deliveringat"
                        onChange={this.props.handleDeliveringAtChange.bind(
                          this
                        )}
                        value={this.props.state.deliveringat}
                      >
                        <option value="asap">{t("common.asap")}</option>
                        <option
                          value="oncall"
                          style={{
                            display:
                              this.props.state.deliveryto === "order"
                                ? "block"
                                : "none",
                          }}
                        >
                          On Call
                        </option>
                        <option value="deliverat">
                          {t("common.deliveringAt")}
                        </option>
                      </Input>
                    </Col>
                    <Col
                      style={{
                        marginTop: 5,
                        display:
                          this.props.state.deliveringat === "deliverat"
                            ? "block"
                            : "none",
                      }}
                      sm={{ size: 7, offset: 1 }}
                    >
                      <mobiscroll.Datetime
                        className="demo-non-form"
                        value={this.props.state.date}
                        onSet={this.props.onSet}
                        theme="ios"
                        themeVariant="light"
                        display="center"
                        touchUi={false}
                        timeFormat="HH:ii"
                        disabled={
                          this.props.state.deliveringat === "deliverat"
                            ? false
                            : true
                        }
                      >
                        <Input />
                      </mobiscroll.Datetime>
                    </Col>
                  </Row>
                </div>
                <div
                  style={{
                    maxHeight: 180,
                    overflowX: "hidden",
                    display:
                      this.props.state.mealslist.length >= 0 ? "block" : "none",
                  }}
                >
                  {this.props.RECIPEBOX}
                </div>
                <div
                  style={{
                    marginTop: 15,
                  }}
                >
                  <Button
                    outline
                    color="info"
                    block
                    onClick={this.props.addRecipeBox}
                    data-toggle="tooltip"
                    data-placement="left"
                    title={t('common.addMeals')}
                  >
                    <FontAwesomeIcon
                      style={{ color: "#3f3f3f" }}
                      icon={faPlus}
                    />
                  </Button>{" "}
                </div>
              </div>
            </Col>

            {/* Labels, ingredient and recipe List  */}

            <Col xs="6">
              <div
                style={{
                  height: "40px",
                }}
              >
                <span
                  onClick={this.props.goBack}
                  style={{
                    float: "left",
                    display:
                      this.props.state.stack.length > 0 ? "inline" : "none",
                  }}
                >
                  <FontAwesomeIcon
                    style={{ color: GREEN, fontSize: 18 }}
                    icon={faArrowLeft}
                  />
                </span>
                <span
                  onClick={this.props.goHome}
                  style={{
                    float: "right",
                    fontSize: 18,
                    display:
                      this.props.state.stack.length > 0 ? "inline" : "none",
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      color: GREEN,
                    }}
                    icon={faHome}
                  />
                </span>
              </div>
              <Row
                style={{
                  maxHeight: 400,
                  overflowX: "hidden",
                }}
                className="app-row-padding"
              >
                {this.props.ALLRECIPE}
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.cancelNested}>
            {t("common.cancel")}
          </Button>{" "}
          <Button color="info" onClick={this.props.saveEvent}>
            {t("common.ok")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withTranslation()(POSRecipeAddCard);
