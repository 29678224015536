const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";
const REMOVE = "REMOVE";

const DefaultActionTypes = [REQUEST, SUCCESS, FAILURE, REMOVE];

function createRequestTypes(
  base: string,
  types: Array<string> = DefaultActionTypes
): any {
  const res: any = [];
  types.forEach((type) => (res[type] = `${base}_${type}`));
  return res;
}

export const CHOICE = createRequestTypes("CHOICE", [...DefaultActionTypes]);

export const RECIPE_ITEMS = createRequestTypes("RECIPE_ITEMS", [
  ...DefaultActionTypes,
]);

export const RECIPE_SUBSTITUTE = createRequestTypes("RECIPE_SUBSTITUTE", [
  ...DefaultActionTypes,
]);
export const RECIPE_SUBSTITUTE_ADD = createRequestTypes(
  "RECIPE_SUBSTITUTE_ADD",
  [...DefaultActionTypes]
);
export const RECIPE_SUBSTITUTE_UPDATE = createRequestTypes(
  "RECIPE_SUBSTITUTE_UPDATE",
  [...DefaultActionTypes]
);

export const RECIPE_SUBSTITUTE_REMOVE = createRequestTypes(
  "RECIPE_SUBSTITUTE_REMOVE",
  [...DefaultActionTypes]
);

export const CHOICE_UPDATE = createRequestTypes("CHOICE_UPDATE", [
  ...DefaultActionTypes,
]);

export const LOGIN = createRequestTypes("LOGIN", [...DefaultActionTypes]);
export const REGISTRATION = createRequestTypes("REGISTRATION", [
  ...DefaultActionTypes,
]);
export const EMAIL_CONFORMATION = createRequestTypes("EMAIL_CONFORMATION", [
  ...DefaultActionTypes,
]);
export const FORGOT_PASSWORD = createRequestTypes("FORGOT_PASSWORD", [
  ...DefaultActionTypes,
]);
export const RESET_PASSWORD = createRequestTypes("RESET_PASSWORD", [
  ...DefaultActionTypes,
]);

export const PROFILE = createRequestTypes("PROFILE", [...DefaultActionTypes]);
export const PROFILE_UPDATE = createRequestTypes("PROFILE_UPDATE", [
  ...DefaultActionTypes,
]);
export const CHANGE_PASSWORD = createRequestTypes("CHANGE_PASSWORD", [
  ...DefaultActionTypes,
]);
export const PROFILE_SETTINGS = createRequestTypes("PROFILE_SETTINGS", [
  ...DefaultActionTypes,
]);
export const PROFILE_SETTINGS_UPDATE = createRequestTypes(
  "PROFILE_SETTINGS_UPDATE",
  [...DefaultActionTypes]
);

export const RESTAURANT = createRequestTypes("RESTAURANT", [
  ...DefaultActionTypes,
]);
export const RESTAURANT_UPDATE = createRequestTypes("RESTAURANT_UPDATE", [
  ...DefaultActionTypes,
]);
export const RESTAURANT_ADD = createRequestTypes("RESTAURANT_ADD", [
  ...DefaultActionTypes,
]);
export const RESTAURANT_REMOVE = createRequestTypes("RESTAURANT_REMOVE", [
  ...DefaultActionTypes,
]);

export const COURSE_LIST = createRequestTypes("COURSE_LIST", [
  ...DefaultActionTypes,
]);
export const COURSE_LIST_UPDATE = createRequestTypes("COURSE_LIST_UPDATE", [
  ...DefaultActionTypes,
]);

export const VAT = createRequestTypes("VAT", [...DefaultActionTypes]);
export const VAT_UPDATE = createRequestTypes("VAT_UPDATE", [
  ...DefaultActionTypes,
]);
export const VAT_ADD = createRequestTypes("VAT_ADD", [...DefaultActionTypes]);
export const VAT_REMOVE = createRequestTypes("VAT_REMOVE", [
  ...DefaultActionTypes,
]);

export const STATIONS = createRequestTypes("STATIONS", [...DefaultActionTypes]);
export const STATIONS_UPDATE = createRequestTypes("STATIONS_UPDATE", [
  ...DefaultActionTypes,
]);
export const STATIONS_ADD = createRequestTypes("STATIONS_ADD", [
  ...DefaultActionTypes,
]);
export const STATIONS_REMOVE = createRequestTypes("STATIONS_REMOVE", [
  ...DefaultActionTypes,
]);
export const STATIONS_OPERATION_MODE_UPDATE = createRequestTypes(
  "STATIONS_OPERATION_MODE_UPDATE",
  [...DefaultActionTypes]
);

export const MACHINE = createRequestTypes("MACHINE", [...DefaultActionTypes]);
export const MACHINE_UPDATE = createRequestTypes("MACHINE_UPDATE", [
  ...DefaultActionTypes,
]);
export const MACHINE_ADD = createRequestTypes("MACHINE_ADD", [
  ...DefaultActionTypes,
]);
export const MACHINE_REMOVE = createRequestTypes("MACHINE_REMOVE", [
  ...DefaultActionTypes,
]);

export const INGREDIENT_CLASS = createRequestTypes("INGREDIENT_CLASS", [
  ...DefaultActionTypes,
]);

export const INGREDIENT_CLASS_UPDATE = createRequestTypes(
  "INGREDIENT_CLASS_UPDATE",
  [...DefaultActionTypes]
);

export const INGREDIENT_COST = createRequestTypes("INGREDIENT_COST", [
  ...DefaultActionTypes,
]);
export const INGREDIENT_COST_UPDATE = createRequestTypes(
  "INGREDIENT_COST_UPDATE",
  [...DefaultActionTypes]
);

export const INGREDIENTS = createRequestTypes("INGREDIENTS", [
  ...DefaultActionTypes,
]);
export const INGREDIENTS_UPDATE = createRequestTypes("INGREDIENTS_UPDATE", [
  ...DefaultActionTypes,
]);
export const INGREDIENTS_ADD = createRequestTypes("INGREDIENTS_ADD", [
  ...DefaultActionTypes,
]);
export const INGREDIENTS_REMOVE = createRequestTypes("INGREDIENTS_REMOVE", [
  ...DefaultActionTypes,
]);

export const INGREDIENTS_DETAIL = createRequestTypes("INGREDIENTS_DETAIL", [
  ...DefaultActionTypes,
]);

export const UNITS = createRequestTypes("UNITS", [...DefaultActionTypes]);
export const UNITS_UPDATE = createRequestTypes("UNITS_UPDATE", [
  ...DefaultActionTypes,
]);
export const UNITS_ADD = createRequestTypes("UNITS_ADD", [
  ...DefaultActionTypes,
]);
export const UNITS_REMOVE = createRequestTypes("UNITS_REMOVE", [
  ...DefaultActionTypes,
]);
export const CONVERSION_ADD = createRequestTypes("CONVERSION_ADD", [
  ...DefaultActionTypes,
]);
export const CONVERSION_EDIT = createRequestTypes("CONVERSION_EDIT", [
  ...DefaultActionTypes,
]);
export const CONVERSION_DELETE = createRequestTypes("CONVERSION_DELETE", [
  ...DefaultActionTypes,
]);

export const SUPPORT = createRequestTypes("SUPPORT", [...DefaultActionTypes]);

export const SERVICESET = createRequestTypes("SERVICESET", [
  ...DefaultActionTypes,
]);
export const SERVICESET_UPDATE = createRequestTypes("SERVICESET_UPDATE", [
  ...DefaultActionTypes,
]);
export const SERVICESET_ADD = createRequestTypes("SERVICESET_ADD", [
  ...DefaultActionTypes,
]);
export const SERVICESET_REMOVE = createRequestTypes("SERVICESET_REMOVE", [
  ...DefaultActionTypes,
]);
export const SERVICE_SET_DETAILS = createRequestTypes("SERVICE_SET_DETAILS", [
  ...DefaultActionTypes,
]);
export const SERVICE_SET_RECIPES_UPDATE = createRequestTypes(
  "SERVICE_SET_RECIPES_UPDATE",
  [...DefaultActionTypes]
);
export const SERVICE_SET_CHANNEL_UPDATE = createRequestTypes(
  "SERVICE_SET_CHANNEL_UPDATE",
  [...DefaultActionTypes]
);

export const SERVICESET_TIME_ADD = createRequestTypes("SERVICESET_TIME_ADD", [
  ...DefaultActionTypes,
]);

export const UPLOAD = createRequestTypes("UPLOAD", [...DefaultActionTypes]);

export const PRINTER = createRequestTypes("PRINTER", [...DefaultActionTypes]);
export const PRINTER_UPDATE = createRequestTypes("PRINTER_UPDATE", [
  ...DefaultActionTypes,
]);
export const PRINTER_ADD = createRequestTypes("PRINTER_ADD", [
  ...DefaultActionTypes,
]);
export const PRINTER_REMOVE = createRequestTypes("PRINTER_REMOVE", [
  ...DefaultActionTypes,
]);

export const RECIPE_PRINTER = createRequestTypes("RECIPE_PRINTER", [
  ...DefaultActionTypes,
]);
export const RECIPE_PRINTER_UPDATE = createRequestTypes(
  "RECIPE_PRINTER_UPDATE",
  [...DefaultActionTypes]
);
export const RECIPE_PRINTER_ADD = createRequestTypes("RECIPE_PRINTER_ADD", [
  ...DefaultActionTypes,
]);
export const RECIPE_PRINTER_REMOVE = createRequestTypes(
  "RECIPE_PRINTER_REMOVE",
  [...DefaultActionTypes]
);

export const PRINTER_MESSAGE = createRequestTypes("PRINTER_MESSAGE", [
  ...DefaultActionTypes,
]);
export const PRINTER_MESSAGE_REMOVE = createRequestTypes(
  "PRINTER_MESSAGE_REMOVE",
  [...DefaultActionTypes]
);
export const PRINTER_MESSAGE_REMOVE_ALL = createRequestTypes(
  "PRINTER_MESSAGE_REMOVE_ALL",
  [...DefaultActionTypes]
);

export const PRINTER_PROTOCOL = createRequestTypes("PRINTER_PROTOCOL", [
  ...DefaultActionTypes,
]);
export const PRINTER_PROTOCOL_UPDATE = createRequestTypes(
  "PRINTER_PROTOCOL_UPDATE",
  [...DefaultActionTypes]
);
export const PRINTER_PROTOCOL_ADD = createRequestTypes("PRINTER_PROTOCOL_ADD", [
  ...DefaultActionTypes,
]);
export const PRINTER_PROTOCOL_REMOVE = createRequestTypes(
  "PRINTER_PROTOCOL_REMOVE",
  [...DefaultActionTypes]
);

export const USERS = createRequestTypes("USERS", [...DefaultActionTypes]);
export const USERS_REMOVE = createRequestTypes("USERS_REMOVE", [
  ...DefaultActionTypes,
]);
export const USER_ROLE_ADD = createRequestTypes("USER_ROLE_ADD", [
  ...DefaultActionTypes,
]);

export const USERS_INVITATION = createRequestTypes("USERS_INVITATION", [
  ...DefaultActionTypes,
]);
export const USERS_INVITATION_RESEND = createRequestTypes(
  "USERS_INVITATION_RESEND",
  [...DefaultActionTypes]
);
export const USERS_INVITATION_ADD = createRequestTypes("USERS_INVITATION_ADD", [
  ...DefaultActionTypes,
]);
export const USERS_INVITATION_REMOVE = createRequestTypes(
  "USERS_INVITATION_REMOVE",
  [...DefaultActionTypes]
);

export const USER_VERIFICATION = createRequestTypes("USER_VERIFICATION", [
  ...DefaultActionTypes,
]);

export const RECIPE = createRequestTypes("RECIPE", [...DefaultActionTypes]);
export const RECIPE_UPDATE = createRequestTypes("RECIPE_UPDATE", [
  ...DefaultActionTypes,
]);
export const RECIPE_ADD = createRequestTypes("RECIPE_ADD", [
  ...DefaultActionTypes,
]);
export const RECIPE_REMOVE = createRequestTypes("RECIPE_REMOVE", [
  ...DefaultActionTypes,
]);
export const RECIPE_DETAILS = createRequestTypes("RECIPE_DETAILS", [
  ...DefaultActionTypes,
]);
export const RECIPE_EDIT = createRequestTypes("RECIPE_EDIT", [
  ...DefaultActionTypes,
]);
export const RECIPE_PUBLISH = createRequestTypes("RECIPE_PUBLISH", [
  ...DefaultActionTypes,
]);

export const TASK = createRequestTypes("TASK", [...DefaultActionTypes]);
export const TASK_UPDATE = createRequestTypes("TASK_UPDATE", [
  ...DefaultActionTypes,
]);
export const TASK_ADD = createRequestTypes("TASK_ADD", [...DefaultActionTypes]);
export const TASK_REMOVE = createRequestTypes("TASK_REMOVE", [
  ...DefaultActionTypes,
]);

export const TASK_STEP_ADD = createRequestTypes("TASK_STEP_ADD", [
  ...DefaultActionTypes,
]);
export const TASK_STEP_UPDATE = createRequestTypes("TASK_STEP_UPDATE", [
  ...DefaultActionTypes,
]);
export const TASK_STEP_REMOVE = createRequestTypes("TASK_STEP_REMOVE", [
  ...DefaultActionTypes,
]);

export const INVENTORY = createRequestTypes("INVENTORY", [
  ...DefaultActionTypes,
]);

export const STORAGE = createRequestTypes("STORAGE", [...DefaultActionTypes]);
export const STORAGE_ADD = createRequestTypes("STORAGE_ADD", [
  ...DefaultActionTypes,
]);
export const STORAGE_UPDATE = createRequestTypes("STORAGE_UPDATE", [
  ...DefaultActionTypes,
]);
export const STORAGE_REMOVE = createRequestTypes("STORAGE_REMOVE", [
  ...DefaultActionTypes,
]);

export const STORAGE_RACK = createRequestTypes("STORAGE_RACK", [
  ...DefaultActionTypes,
]);
export const STORAGE_RACK_ADD = createRequestTypes("STORAGE_RACK_ADD", [
  ...DefaultActionTypes,
]);
export const STORAGE_RACK_UPDATE = createRequestTypes("STORAGE_RACK_UPDATE", [
  ...DefaultActionTypes,
]);
export const STORAGE_RACK_REMOVE = createRequestTypes("STORAGE_RACK_REMOVE", [
  ...DefaultActionTypes,
]);

export const STORAGE_PLACE_ADD = createRequestTypes("STORAGE_PLACE_ADD", [
  ...DefaultActionTypes,
]);
export const STORAGE_PLACE_UPDATE = createRequestTypes("STORAGE_PLACE_UPDATE", [
  ...DefaultActionTypes,
]);
export const STORAGE_PLACE_REMOVE = createRequestTypes("STORAGE_PLACE_REMOVE", [
  ...DefaultActionTypes,
]);

export const RECIPE_INGREDIENT = createRequestTypes("RECIPE_INGREDIENT", [
  ...DefaultActionTypes,
]);
export const RECIPE_INGREDIENT_UPDATE = createRequestTypes(
  "RECIPE_INGREDIENT_UPDATE",
  [...DefaultActionTypes]
);
export const RECIPE_INGREDIENT_ADD = createRequestTypes(
  "RECIPE_INGREDIENT_ADD",
  [...DefaultActionTypes]
);
export const RECIPE_INGREDIENT_REMOVE = createRequestTypes(
  "RECIPE_INGREDIENT_REMOVE",
  [...DefaultActionTypes]
);
export const RECIPE_COPY = createRequestTypes("RECIPE_COPY", [
  ...DefaultActionTypes,
]);

export const TASK_INGREDIENT = createRequestTypes("TASK_INGREDIENT", [
  ...DefaultActionTypes,
]);
export const TASK_INGREDIENT_UPDATE = createRequestTypes(
  "TASK_INGREDIENT_UPDATE",
  [...DefaultActionTypes]
);
export const TASK_INGREDIENT_ADD = createRequestTypes("TASK_INGREDIENT_ADD", [
  ...DefaultActionTypes,
]);
export const TASK_INGREDIENT_REMOVE = createRequestTypes(
  "TASK_INGREDIENT_REMOVE",
  [...DefaultActionTypes]
);

export const ROLES = createRequestTypes("ROLES", [...DefaultActionTypes]);
export const ROLES_UPDATE = createRequestTypes("ROLES_UPDATE", [
  ...DefaultActionTypes,
]);
export const ROLES_ADD = createRequestTypes("ROLES_ADD", [
  ...DefaultActionTypes,
]);
export const ROLES_REMOVE = createRequestTypes("ROLES_REMOVE", [
  ...DefaultActionTypes,
]);

export const COMPONENT = createRequestTypes("COMPONENT", [
  ...DefaultActionTypes,
]);
export const ROLE_RIGHT = createRequestTypes("ROLE_RIGHT", [
  ...DefaultActionTypes,
]);
export const ROLE_RIGHT_ADD = createRequestTypes("ROLE_RIGHT_ADD", [
  ...DefaultActionTypes,
]);

export const TASK_INTERVENTION = createRequestTypes("TASK_INTERVENTION", [
  ...DefaultActionTypes,
]);
export const TASK_INTERVENTION_UPDATE = createRequestTypes(
  "TASK_INTERVENTION_UPDATE",
  [...DefaultActionTypes]
);
export const TASK_INTERVENTION_ADD = createRequestTypes(
  "TASK_INTERVENTION_ADD",
  [...DefaultActionTypes]
);
export const TASK_INTERVENTION_REMOVE = createRequestTypes(
  "TASK_INTERVENTION_REMOVE",
  [...DefaultActionTypes]
);

export const RECIPE_ALL = createRequestTypes("RECIPE_ALL", [
  ...DefaultActionTypes,
]);

export const TASK_INTERVENTION_STEP = createRequestTypes(
  "TASK_INTERVENTION_STEP",
  [...DefaultActionTypes]
);
export const TASK_INTERVENTION_STEP_UPDATE = createRequestTypes(
  "TASK_INTERVENTION_STEP_UPDATE",
  [...DefaultActionTypes]
);
export const TASK_INTERVENTION_STEP_ADD = createRequestTypes(
  "TASK_INTERVENTION_STEP_ADD",
  [...DefaultActionTypes]
);
export const TASK_INTERVENTION_STEP_REMOVE = createRequestTypes(
  "TASK_INTERVENTION_STEP_REMOVE",
  [...DefaultActionTypes]
);

export const TASK_INTERVENTION_INGREDIENT = createRequestTypes(
  "TASK_INTERVENTION_INGREDIENT",
  [...DefaultActionTypes]
);
export const TASK_INTERVENTION_INGREDIENT_UPDATE = createRequestTypes(
  "TASK_INTERVENTION_INGREDIENT_UPDATE",
  [...DefaultActionTypes]
);
export const TASK_INTERVENTION_INGREDIENT_ADD = createRequestTypes(
  "TASK_INTERVENTION_INGREDIENT_ADD",
  [...DefaultActionTypes]
);
export const TASK_INTERVENTION_INGREDIENT_REMOVE = createRequestTypes(
  "TASK_INTERVENTION_INGREDIENT_REMOVE",
  [...DefaultActionTypes]
);

export const LIST_EVENT = createRequestTypes("LIST_EVENT", [
  ...DefaultActionTypes,
]);
export const ADD_EVENT = createRequestTypes("ADD_EVENT", [
  ...DefaultActionTypes,
]);
export const GET_EVENT = createRequestTypes("GET_EVENT", [
  ...DefaultActionTypes,
]);
export const UPDATE_EVENT = createRequestTypes("UPDATE_EVENT", [
  ...DefaultActionTypes,
]);

export const ROOM = createRequestTypes("ROOM", [...DefaultActionTypes]);
export const ROOM_UPDATE = createRequestTypes("ROOM_UPDATE", [
  ...DefaultActionTypes,
]);
export const ROOM_ADD = createRequestTypes("ROOM_ADD", [...DefaultActionTypes]);
export const ROOM_REMOVE = createRequestTypes("ROOM_REMOVE", [
  ...DefaultActionTypes,
]);

export const LABELS = createRequestTypes("LABELS", [...DefaultActionTypes]);
export const LABELS_UPDATE = createRequestTypes("LABELS_UPDATE", [
  ...DefaultActionTypes,
]);
export const LABELS_ADD = createRequestTypes("LABELS_ADD", [
  ...DefaultActionTypes,
]);
export const LABELS_REMOVE = createRequestTypes("LABELS_REMOVE", [
  ...DefaultActionTypes,
]);

export const SETTINGS = createRequestTypes("SETTINGS", [...DefaultActionTypes]);
export const ASSEMBLE_TASK_LOCALES = createRequestTypes(
  "ASSEMBLE_TASK_LOCALES",
  [...DefaultActionTypes]
);
export const SETTINGS_ADD = createRequestTypes("SETTINGS_ADD", [
  ...DefaultActionTypes,
]);

export const TRANSPORT = createRequestTypes("TRANSPORT", [
  ...DefaultActionTypes,
]);
export const TRANSPORT_UPDATE = createRequestTypes("TRANSPORT_UPDATE", [
  ...DefaultActionTypes,
]);
export const TRANSPORT_ADD = createRequestTypes("TRANSPORT_ADD", [
  ...DefaultActionTypes,
]);
export const TRANSPORT_REMOVE = createRequestTypes("TRANSPORT_REMOVE", [
  ...DefaultActionTypes,
]);

export const SERVERS = createRequestTypes("SERVERS", [...DefaultActionTypes]);
export const SERVERS_UPDATE = createRequestTypes("SERVERS_UPDATE", [
  ...DefaultActionTypes,
]);

export const TABLES = createRequestTypes("TABLES", [...DefaultActionTypes]);
export const TABLE_ADD = createRequestTypes("TABLE_ADD", [
  ...DefaultActionTypes,
]);
export const TABLE_REMOVE = createRequestTypes("TABLE_REMOVE", [
  ...DefaultActionTypes,
]);
export const TABLE_UPDATE = createRequestTypes("TABLE_UPDATE", [
  ...DefaultActionTypes,
]);

export const TAGS = createRequestTypes("TAGS", [...DefaultActionTypes]);
export const TAG_ADD = createRequestTypes("TAG_ADD", [...DefaultActionTypes]);
export const TAG_REMOVE = createRequestTypes("TAG_REMOVE", [
  ...DefaultActionTypes,
]);
export const TAG_UPDATE = createRequestTypes("TAG_UPDATE", [
  ...DefaultActionTypes,
]);
export const TAG_ASSIGN = createRequestTypes("TAG_ASSIGN", [
  ...DefaultActionTypes,
]);
export const TRANSLATION = createRequestTypes("TRANSLATION", [
  ...DefaultActionTypes,
]);

export const CURRENCY = createRequestTypes("CURRENCY", [...DefaultActionTypes]);

export const LANGUAGE = createRequestTypes("LANGUAGE", [...DefaultActionTypes]);

export const LANGUAGE_UPDATE = createRequestTypes("LANGUAGE_UPDATE", [
  ...DefaultActionTypes,
]);

export const USER_LANGUAGE = createRequestTypes("USER_LANGUAGE", [
  ...DefaultActionTypes,
]);

export const USER_LANGUAGE_UPDATE = createRequestTypes("USER_LANGUAGE_UPDATE", [
  ...DefaultActionTypes,
]);

export const RESTAURANT_DETAIL = createRequestTypes("RESTAURANT_DETAIL", [
  ...DefaultActionTypes,
]);
export const QRCODE = createRequestTypes("QRCODE", [...DefaultActionTypes]);
export const QRCODE_ADD = createRequestTypes("QRCODE_ADD", [
  ...DefaultActionTypes,
]);
export const QRCODE_REMOVE = createRequestTypes("QRCODE_REMOVE", [
  ...DefaultActionTypes,
]);
export const QRCODE_GET_ROOM_DTLS = createRequestTypes("QRCODE_GET_ROOM_DTLS", [
  ...DefaultActionTypes,
]);
export const QRCODE_UPDATE = createRequestTypes("QRCODE_UPDATE", [
  ...DefaultActionTypes,
]);

export const PERIODICITY = createRequestTypes("PERIODICITY", [
  ...DefaultActionTypes,
]);
export const PERIODICITY_ADD = createRequestTypes("PERIODICITY_ADD", [
  ...DefaultActionTypes,
]);
export const PERIODICITY_DETAILS = createRequestTypes("PERIODICITY_DETAILS", [
  ...DefaultActionTypes,
]);
export const PERIODICITY_REMOVE = createRequestTypes("PERIODICITY_REMOVE", [
  ...DefaultActionTypes,
]);
export const PERIODICITY_UPDATE = createRequestTypes("PERIODICITY_UPDATE", [
  ...DefaultActionTypes,
]);

export const DATE_ITEM = createRequestTypes("DATE_ITEM", [
  ...DefaultActionTypes,
]);
export const DATE_ITEM_UPDATE = createRequestTypes("DATE_ITEM_UPDATE", [
  ...DefaultActionTypes,
]);

export const PAYMENT_PROVIDER = createRequestTypes("PAYMENT_PROVIDER", [
  ...DefaultActionTypes,
]);
export const PAYMENT_PROVIDER_ADD = createRequestTypes("PAYMENT_PROVIDER_ADD", [
  ...DefaultActionTypes,
]);
export const PAYMENT_PROVIDER_DETAILS = createRequestTypes(
  "PAYMENT_PROVIDER_DETAILS",
  [...DefaultActionTypes]
);
export const PAYMENT_PROVIDER_REMOVE = createRequestTypes(
  "PAYMENT_PROVIDER_REMOVE",
  [...DefaultActionTypes]
);
export const PAYMENT_PROVIDER_UPDATE = createRequestTypes(
  "PAYMENT_PROVIDER_UPDATE",
  [...DefaultActionTypes]
);

export const ROBOT_USERS = createRequestTypes("ROBOT_USERS", [
  ...DefaultActionTypes,
]);
export const ROBOT_USERS_ADD = createRequestTypes("ROBOT_USERS_ADD", [
  ...DefaultActionTypes,
]);
export const ROBOT_USERS_REMOVE = createRequestTypes("ROBOT_USERS_REMOVE", [
  ...DefaultActionTypes,
]);
export const ROBOT_USERS_UPDATE = createRequestTypes("ROBOT_USERS_UPDATE", [
  ...DefaultActionTypes,
]);

export const PRINTER_TEMPLATE = createRequestTypes("PRINTER_TEMPLATE", [
  ...DefaultActionTypes,
]);
export const PRINTER_TEMPLATE_ADD = createRequestTypes("PRINTER_TEMPLATE_ADD", [
  ...DefaultActionTypes,
]);
export const PRINTER_TEMPLATE_DETAILS = createRequestTypes(
  "PRINTER_TEMPLATE_DETAILS",
  [...DefaultActionTypes]
);
export const PRINTER_TEMPLATE_REMOVE = createRequestTypes(
  "PRINTER_TEMPLATE_REMOVE",
  [...DefaultActionTypes]
);
export const PRINTER_TEMPLATE_UPDATE = createRequestTypes(
  "PRINTER_TEMPLATE_UPDATE",
  [...DefaultActionTypes]
);

export const CHANNELS = createRequestTypes("CHANNELS", [...DefaultActionTypes]);

export const CHANNELS_DETAILS = createRequestTypes("CHANNELS_DETAILS", [
  ...DefaultActionTypes,
]);

export const CHANNELS_ADD_RESTAURANTS = createRequestTypes(
  "CHANNELS_ADD_RESTAURANTS",
  [...DefaultActionTypes]
);
export const RESTAURANTS_CHANNELS_LIST = createRequestTypes(
  "RESTAURANTS_CHANNELS_LIST",
  [...DefaultActionTypes]
);

export const CONTENT_MANAGEMENT = createRequestTypes("CONTENT_MANAGEMENT", [
  ...DefaultActionTypes,
]);
export const CONTENT_MANAGEMENT_ADD = createRequestTypes(
  "CONTENT_MANAGEMENT_ADD",
  [...DefaultActionTypes]
);
export const CONTENT_MANAGEMENT_REMOVE = createRequestTypes(
  "CONTENT_MANAGEMENT_REMOVE",
  [...DefaultActionTypes]
);
export const CONTENT_MANAGEMENT_UPDATE = createRequestTypes(
  "CONTENT_MANAGEMENT_UPDATE",
  [...DefaultActionTypes]
);

export const CHAT_INIT = createRequestTypes("CHAT_INIT", [
  ...DefaultActionTypes,
]);

export const CHAT_UPDATE = createRequestTypes("CHAT_UPDATE", [
  ...DefaultActionTypes,
]);

export const REPORT_OPEN_TRANSACTION_QUALITY = createRequestTypes(
  "REPORT_OPEN_TRANSACTION_QUALITY",
  [...DefaultActionTypes]
);

export const REPORT_PAYMENT_TRANSACTION_QUALITY = createRequestTypes(
  "REPORT_PAYMENT_TRANSACTION_QUALITY",
  [...DefaultActionTypes]
);

export const REPORT_RESTAURANT_CLOSURE = createRequestTypes(
  "REPORT_RESTAURANT_CLOSURE",
  [...DefaultActionTypes]
);

export const REPORT_ROOM_CLOSURE = createRequestTypes("REPORT_ROOM_CLOSURE", [
  ...DefaultActionTypes,
]);

export const REPORT_GUEST_GROUP_PRINT = createRequestTypes(
  "REPORT_GUEST_GROUP_PRINT",
  [...DefaultActionTypes]
);

export const GUEST_GROUPS = createRequestTypes("GUEST_GROUPS", [
  ...DefaultActionTypes,
]);
export const GUEST_GROUP_UPDATE = createRequestTypes("GUEST_GROUP_UPDATE", [
  ...DefaultActionTypes,
]);
