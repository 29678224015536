import apiCall from './api.call.config';

async function transport(url: string, params: any) {
    try {
        return await apiCall.get(url + '/' + params.credentials.restaurantuuid + '/transport-task?sort=name:asc');
    } catch (e) {
        throw e;
    }
}

async function updateTransport(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.put(url + '/' + params.credentials.restaurantuuid + '/transport-task/'
            + params.credentials.uuid, params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function addTransport(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url + '/' + params.credentials.restaurantuuid + '/transport-task', params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function removeTransport(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.remove(url + '/' + params.credentials.restaurantuuid + '/transport-task/' + params.credentials.uuid);
    } catch (e) {
        throw e;
    }
}

export default {
    transport,
    updateTransport,
    addTransport,
    removeTransport
}
