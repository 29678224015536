import apiCall from "./api.call.config";

async function addRecipe(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/recipe",
      params.credentials.data,
    );
  } catch (e) {
    throw e;
  }
}

async function addRecipeIngredient(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/ingredient/manage",
      params.credentials.data,
    );
  } catch (e) {
    throw e;
  }
}

async function editRecipe(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.uuid +
        "/edit",
      params.credentials.data,
    );
  } catch (e) {
    throw e;
  }
}

async function publishRecipe(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.uuid +
        "/status",
      params.data
    );
  } catch (e) {
    throw e;
  }
}

async function recipe(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.uuid +
        "/recipe/list?sort=name:asc&&type=1&&includeDrafts=true",
    );
  } catch (e) {
    throw e;
  }
}

async function recipeCopy(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/copy",
      {},
    );
  } catch (e) {
    throw e;
  }
}

async function recipeItems(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.uuid +
        "/recipe/list?sort=name:asc&&type=" +
        params.credentials.type,
    );
  } catch (e) {
    throw e;
  }
}

async function recipeIngredient(url: string, params: any) {
  try {
    let urlParams: string = "/ingredient?sort=name:asc";
    if (params.credentials?.prepSetId)
      urlParams += "&&ps=" + params.credentials?.prepSetId;
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        urlParams,
    );
  } catch (e) {
    throw e;
  }
}

async function removeRecipe(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.uuid,
    );
  } catch (e) {
    throw e;
  }
}

async function removeRecipeIngredient(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/ingredient/" +
        params.credentials.uuid,
    );
  } catch (e) {
    throw e;
  }
}

async function updateIngredientRecipe(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/ingredient/" +
        params.credentials.uuid,
      params.credentials.data,
    );
  } catch (e) {
    throw e;
  }
}

async function updateRecipe(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.uuid,
      params.credentials.data,
    );
  } catch (e) {
    throw e;
  }
}

export async function activatePreparationSet(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantUuid +
        "/recipe/" +
        params.credentials.recipeUuid +
        "/preparation-set/" +
        params.credentials.prepId +
        "/activate",
    );
  } catch (e) {
    throw e;
  }
}

export async function allRecipe(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/list?sort=name:asc && type=1,2,3,4,5",
    );
  } catch (e) {
    throw e;
  }
}

export async function copyPreparationSet(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantUuid +
        "/recipe/" +
        params.credentials.recipeUuid +
        "/preparation-set/" +
        params.credentials.prepId +
        "/copy",
    );
  } catch (e) {
    throw e;
  }
}
export async function getPreparationSets(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantUuid +
        "/recipe/" +
        params.credentials.recipeUuid +
        "/preparation-set",
    );
  } catch (e) {
    throw e;
  }
}

export async function getRecipeStorageDetails(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/storage-association",
    );
  } catch (e) {
    throw e;
  }
}

export async function recipeDetails(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/details",
    );
  } catch (e) {
    throw e;
  }
}

export async function removePrepSet(url: string, params: any) {
  try {
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantUuid +
        "/recipe/" +
        params.credentials.recipeUuid +
        "/preparation-set/" +
        params.credentials.prepId,
    );
  } catch (e) {
    throw e;
  }
}

export async function setPreparationSets(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantUuid +
        "/recipe/" +
        params.credentials.recipeUuid +
        "/preparation-set",
      params.credentials.data,
    );
  } catch (e) {
    throw e;
  }
}

export async function updatePreparationSets(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantUuid +
        "/recipe/" +
        params.credentials.recipeUuid +
        "/preparation-set/" +
        params.credentials.prepId,
      params.credentials.data,
    );
  } catch (e) {
    throw e;
  }
}

export async function updateRecipeQuantity(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/quantity",
      params.credentials.data,
    );
  } catch (e) {
    throw e;
  }
}

export async function updateRecipeStorage(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/storage-association",
      params.credentials.data,
    );
  } catch (e) {
    throw e;
  }
}

export const addRecipeStorageKeyValue = async (url: string, params: any) => {
  return await apiCall.post(
    `${url}/${params.credentials.restaurantuuid}/recipe/${params.credentials.recipeuuid}/storage-association-key-value`,
    params.credentials.data,
  );
};

export async function recipeContainingRecipes(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/containing-recipes",
    );
  } catch (e) {
    throw e;
  }
}

export async function replaceIngredient(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/replace-ingredient",
      params.credentials.data,
    );
  } catch (e) {
    throw e;
  }
}

export async function recipeSupplierOfferings(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/supplier-offerings"
    );
  } catch (e) {
    throw e;
  }
}

export default {
  addRecipe,
  addRecipeIngredient,
  allRecipe,
  editRecipe,
  copyPreparationSet,
  publishRecipe,
  recipe,
  recipeCopy,
  recipeDetails,
  recipeIngredient,
  recipeItems,
  removeRecipe,
  removeRecipeIngredient,
  updateIngredientRecipe,
  updateRecipe,
  recipeContainingRecipes,
  replaceIngredient,
  recipeSupplierOfferings,
};
