import "../../styles/units.scss";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import commonService from "../../services/common.service";
import React, { Component } from "react";
import ModalFactory from "react-modal-promise";
import { withTranslation } from "react-i18next";
import LoaderComponent from "../../components/loader/loader";
import SettingsHeaderComponent from "../../components/navigation/navigation-header/settings-header/settings-header";
import NavigationRightComponent from "../../components/navigation/navigation-right/navigation-right";
import SettingsNavComponent from "../../components/navigation/navigation-left/settings-navigation/settings-navigation";
import ContentCard from "../../components/contentManagement/content-card";
import ContentManagementAddEditModal from "../../components/contentManagement/contentManagementAddEditModal";
import {
  contentManagementAdd,
  contentManagementRemove,
  contentManagementRequest,
  contentManagementUpdate,
} from "../../redux/actions/content.management.action";
import {
  ContentManagementInitState,
  ContentManagementState,
} from "../../models/content.management.model";
import { OpenDeleteModal } from "../../components/card-components/delete-card/delete-modal";
import _ from "lodash";

class ContentManagementPage extends Component<any> {
  state: ContentManagementState;
  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.state = ContentManagementInitState;
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this._setStates("restaurantuuid", this.props.match.params.uuid);
    this.props.getContent({ restaurantuuid: this.props.match.params.uuid });
  }

  // TODO need to remove
  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
      if (!!newProps && !newProps.isFetching) {
        this._setStates("vat", newProps.vat);
      }
      if (!!this.state.isUpdated && !newProps.isFetching) {
        this._setStates("isUpdated", false);
        this.props.getContent({ restaurantuuid: this.props.match.params.uuid });
      }
      if (
        !!newProps.contentUpdateStatus.status &&
        !!this.state.addModal &&
        !newProps.isFetching
      ) {
        this.toggle();
      }
      if (
        !!newProps.contentAddStatus.status &&
        !!this.state.addModal &&
        !newProps.isFetching
      ) {
        this.toggle();
      }
    }
  }

  public addNewContent(): void {
    this.setState((prevState: any) => ({
      activeContent: {
        name: "",
        description: "",
        uuid: "",
      },
      addModal: !prevState.addModal,
      isEdit: false,
    }));
  }

  public deleteContent(content: any): void {
    OpenDeleteModal().then(() => {
      this.props.removeContent({
        uuid: content.uuid,
        restaurantuuid: this.props.match.params.uuid,
      });
      this.setState({
        isUpdated: true,
      });
    });
  }
  public editContent(content: any): void {
    const localizedContent = {
      ...this.getLocaleContent(content),
      uuid: content.uuid,
    };
    this.setState({
      isEdit: true,
      activeContent: localizedContent,
    });
    this.toggle();
  }

  public getLocaleContent(locales: any) {
    let locale = commonService.applyLocalization(
      "restaurant",
      "name",
      locales.locales
    );
    return locale;
  }

  public saveNewContent(data: any): void {
    this.props.addContent({
      restaurantuuid: this.state.restaurantuuid,
      data: {
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: data.name,
            description: data.description,
          },
        },
      },
    });
    this.setState({
      isUpdated: true,
    });
  }

  public saveUpdatedContent(data: any): void {
    this.props.updateContent({
      restaurantuuid: this.state.restaurantuuid,
      uuid: data.uuid,
      data: {
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: data.name,
            description: data.description,
          },
        },
      },
    });
    this._setStates("isUpdated", true);
  }

  public toggle(): void {
    this.setState({
      addModal: !this.state.addModal,
    });
  }

  public toggleModal = (type: any = "") => {
    let showModal = this.state.showModal;
    this._setStates("showModal", !showModal);
  };

  public contentList(data: any): any {
    let content;
    if (!!data && data.length > 0) {
      const contentList: Array<any> = [];
      data.forEach((cnt: any) => {
        cnt["nameLower"] = commonService
          .applyLocalization("restaurant", "name", cnt.locales)
          ["name"].toLowerCase();
        contentList.push(cnt);
      });

      // eslint-disable-next-line
      content = _.sortBy(contentList, "nameLower").map(
        (contentItem: any, i: number) => {
          let content = this.getLocaleContent(contentItem);
          return (
            <ContentCard
              key={i}
              deleteContent={this.deleteContent.bind(this, contentItem)}
              editContent={this.editContent.bind(this, contentItem)}
              content={content}
            />
          );
        }
      );
      return content;
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.props.t("common.noRecords")}
        </div>
      );
    }
  }

  private _setFunctionBindings(): void {
    this.addNewContent = this.addNewContent.bind(this);
    this.saveNewContent = this.saveNewContent.bind(this);
    this.saveUpdatedContent = this.saveUpdatedContent.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <ModalFactory />
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <SettingsHeaderComponent settings={"settings"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <SettingsNavComponent
                display={"content-management"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <Link
                to="#"
                color="info"
                className="unit-add"
                onClick={this.addNewContent}
              >
                {t("contentManagement.addContent")}
              </Link>
              <div className="white-box mb-3">
                <h4>{t("contentManagement.contents")}</h4>
                {this.contentList(this.props.contents)}
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>

        <ContentManagementAddEditModal
          activeContent={this.state.activeContent}
          addModal={this.state.addModal}
          isEdit={this.state.isEdit}
          isFetching={this.state.isFetching}
          onSave={
            this.state.isEdit ? this.saveUpdatedContent : this.saveNewContent
          }
          toggle={this.toggle}
        />
      </div>
    );
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.contents.isFetching ||
    state.contentAdd.isFetching ||
    state.contentRemove.isFetching ||
    state.contentUpdate.isFetching;
  let failure =
    state.contents.failure ||
    state.contentAdd.failure ||
    state.contentRemove.failure ||
    state.contentUpdate.failure;
  return {
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    failure: failure,
    isFetching: isFetching,
    contents: state.contents.data,
    contentAddStatus: state.contentAdd.data,
    contentRemoveStatus: state.contentRemove.data,
    contentUpdateStatus: state.contentUpdate.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    addContent: (credentials: any) => {
      dispatch(contentManagementAdd(credentials));
    },
    getContent: (credentials: any) => {
      dispatch(contentManagementRequest(credentials));
    },
    removeContent: (credentials: any) => {
      dispatch(contentManagementRemove(credentials));
    },
    updateContent: (credentials: any) => {
      dispatch(contentManagementUpdate(credentials));
    },
  };
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(ContentManagementPage))
);
