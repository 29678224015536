import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";

import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GRAY } from "../../constant/constant";
// export class TagsCreateModal extends Component<any> {
export default function TagsCreateModal(props: any) {
  const { t, i18n } = useTranslation();
  const { className, isOpen } = props;
  const [isRangeTye, setRangeType] = useState(true);
  const [isFreeText, setIsFreeText] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (props.state.isEdit) {
      if (!props.state.moreValues || props.state.moreValues.length === 0) {
        setIsFreeText(true);
      } else {
        setIsFreeText(false);
      }
    }
  });
  const handleTypeChange = (event: any) => {
    setIsFreeText(false);
    if (Number(event.target.value) === 3)
      props.handleTagChange(null, "text", 0);
    Number(event.target.value) === 1 ? setRangeType(true) : setRangeType(false);
    props.handleChange(event);
  };

  const handleMount = () => {
    if (props.state.tags_type === 1) {
      setRangeType(true);
    } else {
      setRangeType(false);
    }
    setErrorMsg("");
  };
  const handleRangeToChange = (event: any) => {
    props.handleChange(event);
  };
  const isNotValidForm = () => {
    const tagKeyRegex = /^([a-z.0-9-_]+)$/;
    if (props.state.tags_name.trim() === "") {
      return true;
    } else if (props.state.tags_key.trim() === "") {
      return true;
    } else if (!tagKeyRegex.test(props.state.tags_key.trim())) {
      return true;
    } else if (Number(props.state.tags_type) === 1) {
      if (
        props.state.tags_range_from === "" ||
        props.state.tags_range_to === ""
      ) {
        return true;
      } else if (
        Number(props.state.tags_range_from) >=
          Number(props.state.tags_range_to) ||
        Number(props.state.tags_range_from) < 0
      ) {
        return true;
      }
    } else if (Number(props.state.tags_type) === 3) {
      if (
        props.state.moreValues?.every((value: any) => !!!value.value) &&
        !isFreeText
      ) {
        return true;
      }
    }
    return false;
  };

  const handleKeyChange = (e: any) => {
    props.handleChange(e);
    const tagKeyRegex = /^([a-z.0-9-_]+)$/;
    if (!tagKeyRegex.test(e.target.value.trim())) {
      setErrorMsg(t("tag.tagKeyError"));
    } else {
      setErrorMsg("");
    }
  };

  const handleFreeText = () => {
    if (isFreeText) {
      props.handleChange({
        target: {
          name: "override",
          checked: false,
          value: "",
        },
      });
      props.handleChange({
        target: {
          name: "defaultValue",
          value: "",
        },
      });
      setIsDisabled(getSaveButtonStatus());
    }
    props.handleTagChange(null, "freeText", !isFreeText);
    setIsFreeText(!isFreeText);
  };

  const getSaveButtonStatus = () => {
    return props.state.tagName?.meta?.type === 1
      ? !props.state.defaultValue
      : props.state.tagName?.meta?.type === 3
      ? (!props.state.defaultValue && !isFreeText) ||
        (props.state.moreValues.some((item: any) => item.value === "") &&
          !isFreeText)
      : false;
  };

  return (
    <div>
      <Modal isOpen={isOpen} className={className} onOpened={handleMount}>
        <ModalHeader toggle={props.onCancel}>
          {props.state.isEdit ? t("tag.editTag") : t("tag.createNewTag")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row className="p-0">
              <Label className="text-right" for="tags_name" sm={4}>
                {t("common.name")}
                <span className="required-star-style">*</span>
              </Label>
              <Col sm={8}>
                <Input
                  type="text"
                  name="tags_name"
                  placeholder="Tag name"
                  value={props.state.tags_name}
                  onChange={props.handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="p-0">
              <Label className="text-right" for="tags_key" sm={4}>
                {t("common.key")}
                <span className="required-star-style">*</span>
              </Label>
              <Col sm={8}>
                <Input
                  type="text"
                  name="tags_key"
                  placeholder="Tag key"
                  value={props.state.tags_key}
                  onChange={handleKeyChange}
                />
                <span className="d-flex bf-font-size-12 text-danger mt-1">
                  {errorMsg}
                </span>
              </Col>
            </FormGroup>
            {!props.state.isEdit && (
              <FormGroup row className="p-0">
                <Label className="text-right" for="tags_type" sm={4}>
                  {t("tag.tagType")}
                  <span className="required-star-style">*</span>
                </Label>
                <Col sm={8}>
                  <Input
                    type="select"
                    name="tags_type"
                    onChange={handleTypeChange}
                    value={props.state.tags_type}
                  >
                    <option value="1">{t("common.range")}</option>
                    <option value="2">{t("common.boolean")}</option>
                    <option value="3">{t("common.text")}</option>
                    <option value="4">{t("common.link")}</option>
                  </Input>
                </Col>
              </FormGroup>
            )}

            {isRangeTye && (
              <FormGroup row className="p-0">
                <Label className="text-right" sm={4}>
                  {t("common.range")}{" "}
                  <span className="required-star-style">*</span>
                </Label>
                <Col sm={8}>
                  <div className="row p-0">
                    <div className="col d-flex align-items-center">
                      <span className="mr-2">{t("common.from")}</span>
                      <Input
                        name="tags_range_from"
                        type="number"
                        value={props.state.tags_range_from}
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="col d-flex align-items-center pl-0">
                      <span className="mr-2">{t("common.to")}</span>
                      <Input
                        name="tags_range_to"
                        type="number"
                        value={props.state.tags_range_to}
                        onChange={handleRangeToChange}
                      />
                    </div>
                  </div>
                </Col>
              </FormGroup>
            )}

            {Number(props.state.tags_type) === 3 &&
              !isFreeText &&
              props.state.moreValues &&
              props.state.moreValues?.length > 0 && (
                <FormGroup row className="p-0">
                  <Label className="text-right" for="tagValue" sm={4}>
                    {t("common.defaultValue")}
                    <span className="required-star-style">*</span>
                  </Label>
                  <Col sm={8} className="pt-2">
                    {Number(props.state.tags_type) === 3 &&
                      props.state.moreValues?.map((item: any, index: any) => (
                        <div
                          key={index}
                          className="align-items-center d-flex flex-wrap"
                        >
                          <Input
                            key={index}
                            className={index !== 0 ? "mt-3 w-75" : "w-75"}
                            name="tagValue"
                            onChange={(val: any) =>
                              props.handleTagChange(val, "text", index)
                            }
                            type="text"
                            value={props.state.moreValues[index].value}
                          />
                          {index !== 0 && (
                            <span
                              className="d-block pl-3"
                              onClick={() => {
                                props.removeValue(index, props.state);
                              }}
                            >
                              <FontAwesomeIcon
                                role="button"
                                className="mt-3 "
                                style={{ color: GRAY }}
                                icon={faTrashAlt}
                              />
                            </span>
                          )}
                        </div>
                      ))}
                  </Col>

                  <Col className="d-flex justify-content-end mt-3">
                    <Button
                      color="info"
                      disabled={props.state.moreValues?.some(
                        (item: any) => item.value === ""
                      )}
                      onClick={(e: any) => {
                        props.addMoreValues(e);
                      }}
                    >
                      {t("common.addMoreValues")}
                    </Button>
                  </Col>
                </FormGroup>
              )}
            {Number(props.state.tags_type) === 3 && (
              <Row className="p-0">
                <Col sm={{ size: 8, offset: 4 }}>
                  {!isFreeText && (
                    <Button
                      color="link"
                      className="p-0"
                      onClick={handleFreeText}
                    >
                      {t("tag.setAsFreeText")}
                    </Button>
                  )}
                  {isFreeText && (
                    <Button
                      color="link"
                      className="p-0"
                      onClick={handleFreeText}
                    >
                      {t("tag.setAsRegularText")}
                    </Button>
                  )}
                </Col>
              </Row>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.onCancel}>
            {t("common.cancel")}
          </Button>
          <Button
            color="info"
            onClick={props.onSave}
            disabled={isNotValidForm()}
          >
            {t("tag.saveTag")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
