import React, { Component } from 'react';

import RoomComponent from '../../components/room/room';

class RoomPage extends Component {

    render() {
        return (
            <RoomComponent/>
        );
    }
}
export default RoomPage;
