import * as types from "../actions/types";

const initialState: any = {
  isFetching: false,
  data: {},
  error: {},
  failure: false,
};
export default function serviceSetTimeAddReducer(
  state = initialState,
  action: any
): any {
  switch (action.type) {
    case types.SERVICESET_TIME_ADD.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.SERVICESET_TIME_ADD.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.SERVICESET_TIME_ADD.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        data: action.err,
        error: action.err,
      };
    default:
      return state;
  }
}
