import "../../styles/login.scss";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

class ErrorFallback extends Component<any, any> {
  componentDidMount() {
    document.body.className = "dark-theme";
  }

  render() {
    const { t } = this.props;
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        <h1 className="text-white text-center">
          {t("fallback.oops")}! <br />
          {t("fallback.somethingWentWrong")} :(
        </h1>
      </div>
    );
  }
}
export default withTranslation()(withRouter(ErrorFallback));
