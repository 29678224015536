export interface ChannelsModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export const ChannelsModelInitState = {
  isFetching: false,
  data: [],
  error: {},
  failure: false,
};

export interface ChannelsState {
  addItemData: any;
  cardItemData:any;
  channelRestaurants:Array<any>;
  elementId:any;
  fetchData: any;
  isAddItemModalOpen: boolean;
  isDeleted: boolean;
  isFetching: boolean;
  isSaveButtonDisabled:boolean;
  isUpdated:any;
  item:any;
  itemDetails: any;
  itemModalData: any;
  items: Array<any>;
  itemsDetails: any;
  removeItemData: any;
  restaurant:any;
  restaurantArray:any;
  selectedItemId: string;
  type: string;
  updateChannels: any;
  updateItemData: any;
  warnings:any;
}

export const ChannelsInitState = {
  addItemData: {},
  cardItemData:[],
  channelRestaurants:[],
  elementId:"",
  fetchData: false,
  isAddItemModalOpen: false,
  isDeleted: false,
  isFetching: false,
  isSaveButtonDisabled:true,
  isUpdated:false,
  item:{},
  itemDetails: [],
  itemModalData: {
    date: "",
    isEdit: false,
    name: "",
  },
  items: [],
  itemsDetails: [],
  removeItemData: {},
  restaurant:[],
  restaurantArray:[],
  selectedItemId: "",
  type: "",
  updateChannels: {},
  updateItemData: {},
  warnings:{},
  
};

