export interface PeriodicityModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export const PeriodicityModelInitState = {
  isFetching: false,
  data: [],
  error: {},
  failure: false,
};

export interface PeriodicityState {
  addItemData: any;
  elementId: string;
  globalUnits: Array<any>;
  isAddItemModalOpen: boolean;
  isDeleted: boolean;
  isFetching: boolean;
  itemDetails: any;
  itemModalData: any;
  items: Array<any>;
  itemsDetails: any;
  labelOptions: Array<any>;
  labels: Array<any>;
  periodicityUpdateData: any;
  periodicity: Array<any>;
  removeItemData: any;
  selectedItemId: string;
  updatePeriodicity: any;
  updatePeriodicityData: any;
}

export const PeriodicityInitState = {
  addItemData: {},
  elementId: "",
  globalUnits: [],
  isAddItemModalOpen: false,
  isDeleted: false,
  isFetching: false,
  itemDetails: [],
  itemModalData: {
    name: "",
    date: "",
    isEdit: false,
  },
  items: [],
  itemsDetails: [],
  labelOptions: [],
  labels: [],
  periodicity: [],
  periodicityUpdateData: "",
  removeItemData: {},
  selectedItemId: "",
  updatePeriodicity: {},
  updatePeriodicityData: {},
};

export interface PeriodicityDetailState {
  editItemData: any;
  isDeleted: boolean;
  isEditModalOpen: boolean;
  isFetching: boolean;
  isSaveButtonDisabled:boolean;
  isUpdated: boolean;
  periodicityData: any;
  periodicityItems: Array<any>;
  removeItemData: any;
  renderData: any;
  task: Array<any>;
  updatePeriodicityData: any;
}

export const PeriodicityDetailInitState = {
  editItemData: {
    name: "",
    date: "",
    isEdit: true,
  },
  isDeleted: false,
  isEditModalOpen: false,
  isFetching: false,
  isSaveButtonDisabled:true,
  isUpdated: false,
  periodicityData: {},
  periodicityItems: [],
  removeItemData: {},
  renderData: {},
  task: [],
  updatePeriodicityData: {},
};
