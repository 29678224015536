import React, { Component } from 'react';

import UnitsComponent from '../../components/units/units';

class UnitsPage extends Component {

    render() {
        return (
            <UnitsComponent/>
        );
    }
}
export default UnitsPage;
