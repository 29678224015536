import { call, put, takeLatest } from "redux-saga/effects";

import { USER_REQUEST } from "../config/api.config";
import { PROFILE } from "../constant/constant";
import {
  profileFailure,
  profileSuccess,
  profileUpdateFailure,
  profileUpdateSuccess,
} from "../redux/actions/profile.action";
import * as types from "../redux/actions/types";
import profile from "../services/profile.service";
import handleResponse from "../services/response.service";

const getProfileCall = () => profile.getProfile(USER_REQUEST);
const updateProfileCall = (params: any) =>
  profile.updateProfile(USER_REQUEST, params);

function* handleProfileRequest(params: object) {
  try {
    let result;
    result = yield call(getProfileCall);
    yield handleProfile200(result);
  } catch (error) {
    throw error;
  }
}

const handleProfile200 = function* handleProfile200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    localStorage.setItem(
      "LANG",
      JSON.stringify(
        result.data?.languages?.length > 0
          ? result.data?.languages[0].code
          : "en-gb"
      )
    );
    localStorage.setItem(
      "USERLANGUAGE",
      JSON.stringify(result.data?.languages)
    );

    localStorage.setItem(PROFILE, JSON.stringify(result.data));
    yield put(profileSuccess(result.data));
  } else {
    yield put(profileFailure(result.data));
    handleResponse(result);
  }
};

function* handleProfileUpdate(params: object) {
  try {
    let result;
    result = yield call(updateProfileCall, params);
    yield handleProfileUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleProfileUpdate200 = function* handleProfileUpdate200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(profileUpdateSuccess(result.data));
  } else {
    yield put(profileUpdateFailure(result.data));
  }
  handleResponse(result);
};

export default function* ProfileSaga() {
  yield takeLatest(types.PROFILE.REQUEST, handleProfileRequest);
  yield takeLatest(types.PROFILE_UPDATE.REQUEST, handleProfileUpdate);
}
