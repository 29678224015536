import { call, put, takeLatest } from 'redux-saga/effects';

import { REGISTRATION_REQUEST } from '../config/api.config';
import { registrationFailure, registrationSuccess } from '../redux/actions/registration.action';
import * as types from '../redux/actions/types';
import registration from '../services/registration.service';
import handleResponse from '../services/response.service';

const registrationCall = (credentials: object) => registration.registration(REGISTRATION_REQUEST, credentials);
var type: any;

function* handleRegistrationRequest(credentials: object) {
    try {
        let result;
        result = yield call(registrationCall, credentials);
        type = credentials
        yield handleRegistration200(result);
    } catch (error) {
        throw error;
    }
}
const handleRegistration200 = function* handleRegistration200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(registrationSuccess(result.data));
        if (type.credentials.type === 'registration') {
            handleResponse(result);
        } else {
            handleResponse(result);
        }
    } else {
        yield put(registrationFailure(result.data));
        handleResponse(result);
    }
}

export default function* registrationSaga() {
    yield takeLatest(types.REGISTRATION.REQUEST, handleRegistrationRequest);
}
