import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { create } from "apisauce";
import ModalFactory from "react-modal-promise";

import LoaderComponent from "../../components/loader/loader";
import RestaurantLeftNavComponent from "../../components/navigation/navigation-left/restaurant-navigation/restaurant-left-navigation";
import NavigationRightComponent from "../../components/navigation/navigation-right/navigation-right";
import RestaurantHeaderComponent from "../../components/navigation/navigation-header/restaurant-header/restaurant-header";

import { BASE_URL } from "../../config/app.config";
import { ACCESS_TOKEN } from "../../constant/constant";

import { ChannelsState, ChannelsInitState } from "../../models/channels.model";
import { withTranslation } from "react-i18next";
import { channelsRequest } from "../../redux/actions/channels.action";
import ImageTitleComponentWrapper from "../../components/card-components/image-title-component/imageTitleComponentWrapper";
import commonService from "../../services/common.service";
import { Col, Row } from "reactstrap";

const api = create({
  baseURL: BASE_URL,
});

class ChannelsComponent extends Component<any> {
  state: ChannelsState;
  constructor(props: any) {
    super(props);
    this.state = ChannelsInitState;
    api.setHeaders({
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Cache-Control": "no-cache",
      Locale: localStorage.getItem("i18nextLng") || "en-gb",
      "content-type": "application/json",
    });
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.channels();
    this.setState({ restaurantuuid: this.props.match.params.uuid });
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (!!this.state.fetchData) {
      this.props.channels();
      this.setState({
        fetchData: false,
      });
    }
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    let update: any = {};
    if (nextProps.isFetching !== prevState.isFetching) {
      update.isFetching = nextProps.isFetching;
    }
    if (nextProps.items !== prevState.items) {
      update.items = nextProps.items;
    }
    return update;
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <div>
          <ModalFactory />
          <LoaderComponent display={this.state.isFetching} />
          <div className="container-fluid">
            <RestaurantHeaderComponent />
            <Row className="main light-theme">
              <Col xl={2} lg={3} className="hide-left-max">
                <RestaurantLeftNavComponent display="channels" />
              </Col>
              <Col xl={8} lg={6}>
                <ImageTitleComponentWrapper
                  hasDetails={true}
                  title="channels"
                  cardType="title"
                  elementId={this.state.elementId}
                  fetchData={this.fetchData}
                  itemData={this.state.items}
                  pageText={this.getPageTitle()}
                  setItemId={this.setItemId}
                  itemsDetails={this.state.itemsDetails}
                  onMoveToDetailPage={this.moveToDetailPage}
                />
              </Col>
              <Col xl={2} lg={3}>
                <NavigationRightComponent />
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }

  public moveToDetailPage = (itemId: string) => {
    this.props.history.push(`/channels/${itemId}/details`);
  };

  public fetchData = () => {
    this.setState({
      fetchData: true,
    });
  };

  public setItemId = (itemId: string) => {
    this.setState({
      elementId: itemId,
    });
  };

  public getPageTitle = () => {
    return {
      //   addButtonTitle: this.props.t("restaurant.addChannels"),
      pageTitle: this.props.t("restaurant.channels"),
    };
  };
}

const mapStateToProps: any = (state: any) => {
  let isFetching = state.channels.isFetching;
  let failure = state.channels.failure;
  let states: any = {
    failure: failure,
    isFetching: isFetching,
    items: state.channels.data,
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
  };
  return states;
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    channels: () => {
      dispatch(channelsRequest());
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ChannelsComponent))
);
