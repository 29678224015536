import React, { Component } from "react";
import { Alert, Badge, Button, Col, Input, InputGroup, Row } from "reactstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { create } from "apisauce";

import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

import {
  ACCESS_TOKEN,
  BLACK,
  CARD_SELECTION_STATE,
  GREEN,
  INVENTORY_TYPE,
  RECIPE_STATUS,
  RECIPE_TYPE,
} from "../../../constant/constant";
import { BASE_URL } from "../../../config/app.config";

import RestaurantNavComponent from "../../../components/navigation/navigation-left/restaurant-navigation/restaurant-navigation";
import ConceptHeaderComponent from "../../../components/navigation/navigation-header/concept-header/concept-header";
import { withCardItemSelectionStateManager } from "../../../components/cardItemSelectionManager";

import ModalFactory from "react-modal-promise";
import _ from "lodash";

import {
  RecipeDetailsInitState,
  RecipeDetailsState,
  taskEditType,
} from "../../../models/task.model";
import LoaderComponent from "../../../components/loader/loader";
import commonService from "../../../services/common.service";
import i18next from "i18next";
import {
  allRecipeRequest,
  recipeAdd,
  recipeDetailsRequest,
  recipeIngredientRequest,
  recipeItemsRequest,
  recipeRemove,
  recipeUpdate,
} from "../../../redux/actions/recipe.action";
import ItemWrapper from "../../../components/service-item/shared/itemWrapper";
import { ingredientCostRequest } from "../../../redux/actions/ingredients.action";
import { assembleTaskLocalesRequest } from "../../../redux/actions/stations.action";
import {
  getRecipeStorageDetails,
  updateRecipeQuantity,
  updateRecipeStorage,
} from "../../../services/recipe.service";
import { RESTAURANT_REQUEST } from "../../../config/api.config";
import ItemAddModal from "../../../components/service-item/shared/itemAddModal";
import { tagsRequest } from "../../../redux/actions/tags.action";
import {
  unitsAdd,
  unitsRemove,
  unitsRequest,
} from "../../../redux/actions/units.action";
import { recipeSubstituteRequest } from "../../../redux/actions/recipe.substitutes.action";
import ManageStorageStorage from "../../../components/manage-storage/manage-storage";
import { OpenDeleteModal } from "../../../components/card-components/delete-card/delete-modal";
import RecipeTasksAndIngredientsWrapper from "../../../components/service-item/recipe/recipe-tasks-ingredients-wrapper";
import { taskRequest } from "../../../redux/actions/task.action";
import RecipeServices from "../../../services/recipe.service";
import TaskServices from "../../../services/task.service";
import TaskInterventionServices from "../../../services/task.intervention.service";
import TaskInterventionStepServices from "../../../services/task.intervention.step.service";
import TaskStepServices from "../../../services/task.step.service";
import handleResponse from "../../../services/response.service";
import "../../../styles/task.scss";
import UnitsAddCard from "../../../components/card-components/units-add-card/units-add-card";
import { storageType } from "../../../models/storage.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faSave,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { getRestaurantSuppliers } from "../../../services/supplier.restaurants.service";
import { RecipeEditHelper } from "../../../helpers/recipeEditHelper";

const api = create({
  baseURL: BASE_URL,
});
class RecipeDetailsPage extends Component<any> {
  locale = (localStorage.getItem("i18nextLng") || "en-gb").toLowerCase();
  changedItems: any = {};
  editDataTask: any = [];
  expandedTasks: Set<any> = new Set();
  rack: any = "";
  recipesRelatedUnits: any = {};
  state: RecipeDetailsState;
  editItemData: any = {};
  isNewUnitAdded: boolean = false;
  newRecipeIngredient: any = null;
  constructor(props: any) {
    super(props);
    this.changedItems = {
      allContent: new Set(),
      inlineContent: new Set(),
    };
    this.state = RecipeDetailsInitState;
    api.setHeaders({
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Cache-Control": "no-cache",
      Locale: localStorage.getItem("i18nextLng") || "en-gb",
      "content-type": "application/json",
    });
  }

  componentDidMount() {
    document.body.className = "light-theme";
    const recipeId: string = this.props.match.params.recipeuuid;
    const restaurantId: string = this.props.match.params.uuid;
    this.props.dispatchActions.getUnits({
      restaurantuuid: restaurantId,
    });
    this.getSubstitutes();
    this.getSuppliers();
    this.getRecipeDetail(recipeId, restaurantId);
    this.loadMetaData();
    this.getCostDetails(recipeId, restaurantId);
    this.getStorageDetails(recipeId, restaurantId);
    this.getRecipeIngredient(recipeId, restaurantId);
    this.getTaskList(recipeId, restaurantId);
    this.getAllRecipes();
    this.getAssembletaskLocales();
    this.props.dispatchActions.getTags({
      restaurantuuid: restaurantId,
    });
    this.props.dispatchActions.getTags({
      restaurantuuid: restaurantId,
    });
    this.props.dispatchActions.getRecipeSubstitutes({
      restaurantuuid: restaurantId,
      recipeuuid: recipeId,
    });
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      !this.props.storeProps.isFetching &&
      this.state.isEditItemModalOpen &&
      this.state.isUpdated &&
      _.cloneDeep(this.props.storeProps.recipeDetails) !==
        _.cloneDeep(prevProps.recipeDetails)
    ) {
      this.setState({
        isEditItemModalOpen: false,
        isUpdated: false,
      });

      this.props.dispatchActions.getUnits({
        restaurantuuid: this.props.match.params.uuid,
      });
      this.getRecipeDetail(
        this.props.match.params.recipeuuid,
        this.props.match.params.uuid
      );
    }

    if (
      this.props.storeProps.recipeAdd?.status &&
      this.newRecipeIngredient?.isUpdated &&
      !this.props.storeProps.isFetching
    ) {
      this.newRecipeIngredient = null;
      this.getAllRecipes();
      this.setState({
        formData: RecipeDetailsInitState.formData,
        isEditItemModalOpen: false,
      });
    }

    if (
      this.props.storeProps.editRecipeStatus?.status &&
      this.state.isUpdated
    ) {
      this.setState({
        isUpdated: false,
        isEditItemModalOpen: false,
      });
      // TODO need to remove the timeout and identify the issue on edit save refresh
      setTimeout(() => {
        this.props.dispatchActions.getUnits({
          restaurantuuid: this.props.match.params.uuid,
        });
        this.getRecipeDetail(
          this.props.match.params.recipeuuid,
          this.props.match.params.uuid
        );
        this.getStorageDetails(
          this.props.match.params.recipeuuid,
          this.props.match.params.uuid,
          true
        );
      }, 500);
    }

    if (
      !this.props.storeProps.isFetching &&
      this.props.storeProps.unitsAdd.status &&
      this.isNewUnitAdded
    ) {
      this.props.dispatchActions.getUnits({
        restaurantuuid: this.props.match.params.uuid,
      });
      this.isNewUnitAdded = false;
      const formData: any = { ...this.state.formData };
      formData["outputUnitUuid"] = this.props.storeProps.unitsAdd.data.uuid;
      this.setState({
        newUnit: this.props.storeProps.unitsAdd.data,
        formData: formData,
        isUnitModalOpen: false,
      });

      this.showLoading(false);
    }

    if (
      prevProps.storeProps.editRecipeStatus !==
      this.props.storeProps.editRecipeStatus
    ) {
      RecipeEditHelper.redirectNewRecipe(
        this.props.storeProps.editRecipeStatus,
        this.props.match.params.recipeuuid,
        this.props.history
      );
    }
  }

  addNewStorage = (ingredientId: string, area: string, data: any) => {
    const mainStorageDetails = this.state.recipeStorages.find(
      (item: any) => item.main_storage
    );
    this.setState({
      activeStorageDetails: {
        area,
        data,
        ingredientId,
      },

      storageFormData: {
        inventory_type:
          mainStorageDetails?.inventory_type || INVENTORY_TYPE.MANAGED,
        main_storage: false,
        min_quantity: "",
        max_quantity: "",
        output_quantity: "",
        output_unit: "",
      },
      isStorageModalOpen: true,
    });
  };

  addTagModal = () => {};

  addToggle = () => {};

  backToCopied = () => {
    this.props.history.replace(
      `/restaurant/${this.props.match.params.uuid}/recipe/${this.state.recipeDetails.copied_from}/details`
    );
    window.location.reload();
  };

  deleteTagToggle = () => {};

  editStorage = (ingredientId: string, uuid: string) => {
    let allRecipeStorages = _.clone(this.state.recipeStorages);
    const editData = allRecipeStorages.find((st: any) => st.uuid === uuid);
    let quantityUpdated: any = editData?.output_quantity;
    let minQuantity: any = editData?.min_quantity;
    let maxQuantity: any = editData?.max_quantity;
    const currentItemOutputUnit: string =
      this.props.storeProps.recipeDetails?.output_unit_uuid || "";
    if (currentItemOutputUnit !== "") {
      const relatedUnits: Array<any> = this.getRelatedUnits(
        currentItemOutputUnit,
        ingredientId
      );
      const savedUnitDetails: any = relatedUnits.find(
        (u: any) => u.value === editData?.output_unit
      );
      if (savedUnitDetails) {
        quantityUpdated = commonService.getSignificantDigit(
          Number(quantityUpdated) / savedUnitDetails.conversionFactor
        );
        minQuantity = commonService.getSignificantDigit(
          Number(minQuantity) / savedUnitDetails.conversionFactor
        );
        maxQuantity = commonService.getSignificantDigit(
          Number(maxQuantity) / savedUnitDetails.conversionFactor
        );
      }
    }

    this.setState({
      activeStorageDetails: {
        isEdit: true,
        editData,
        ingredientId,
      },
      storageFormData: {
        inventory_type: editData?.inventory_type,
        main_storage: editData?.main_storage,
        min_quantity: minQuantity,
        max_quantity: maxQuantity,
        output_quantity: quantityUpdated,
        output_unit: editData?.output_unit,
      },
      isStorageModalOpen: true,
    });
  };

  setUpdated = () => {
    this.setState({
      isUpdated: true,
    });
  };

  generateSKU = () => {
    let state: any = this.state.formData;
    state["sku"] = this.randomString(
      12,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    );
    this.setState({
      formData: state,
    });
  };

  getAddModalLabel = (area: string, type: string) => {
    let modalTitle: any = this.props.t("recipe.editRecipe");

    if (type === "create" && this.state.isEditItemModalOpen) {
      switch (area) {
        case "ingredient":
          modalTitle = this.props.t("article.addArticle");
          break;
        case "choiceItem":
          modalTitle = this.props.t("choiceItem.addChoice");
          break;
        case "abstractIngredient":
          modalTitle = this.props.t("abstractIngredient.addAbstractIngredient");
          break;
        default:
          modalTitle = this.props.t("recipe.addRecipe");
          break;
      }
    }
    return {
      modalTitle: modalTitle,
    };
  };

  getAllRecipes = () => {
    this.props.dispatchActions.getAllRecipe({
      restaurantuuid: this.props.match.params.uuid,
    });
  };

  getBaseUnitOptions = () => {
    if (!Array.isArray(this.props.storeProps.units)) return;
    let units: Array<any> = [];

    const baseUnit: any = this.props.storeProps.units?.find(
      (unit: any) =>
        unit.uuid === this.props.storeProps.settings.base_weight_unit
    );
    if (baseUnit) {
      units =
        commonService.getRelateUnitDropdowns(
          baseUnit.uuid,
          this.props.storeProps.units,
          this.props.storeProps.units,
          1,
          this.props.elementId
        ) || [];
    }
    return _.sortBy(units, [(option: any) => option.label.toLowerCase()]);
  };

  getBestMatchUnits = (quantity: string, unit: string, recipeId: string) => {
    if (quantity) {
      let quantityUpdated: any = quantity;
      const currentItemOutputUnit: string =
        this.props.storeProps.recipeDetails?.output_unit_uuid || "";
      if (currentItemOutputUnit !== "") {
        const relatedUnits: Array<any> = this.getRelatedUnits(
          currentItemOutputUnit,
          recipeId
        );
        const savedUnitDetails: any = relatedUnits.find(
          (u: any) => u.value === unit
        );
        if (savedUnitDetails) {
          quantityUpdated = commonService.getSignificantDigit(
            Number(quantityUpdated) / savedUnitDetails.conversionFactor
          );
        }
      }
      const currentUnit =
        Array.isArray(this.props.storeProps.units) &&
        this.props.storeProps.units?.find(
          (unitData: any) => unitData.uuid === unit
        );
      const data: any = {
        quantity: quantityUpdated,
      };

      if (currentUnit) {
        const locales: any = commonService.applyLocalization(
          "restaurant",
          "name",
          currentUnit?.locales
        );
        data["unit"] = {
          label: locales.name,
          symbol: locales.symbol,
          value: currentUnit.uuid,
        };
      }
      return data;
    }
    return;
  };

  getAssembletaskLocales = () => {
    this.props.dispatchActions.assembleTaskLocales({
      restaurantuuid: this.props.match.params.uuid,
    });
  };

  getCostDetails = (ingredientId: string, restaurantId?: string) => {
    const restId: string = restaurantId
      ? restaurantId
      : this.props.match.params.uuid;
    this.props.dispatchActions.getIngredientCost({
      restaurantuuid: restId,
      uuid: ingredientId,
    });
  };

  getExtraDetails = (data: any) => {
    return (
      <>
        <Row className="mb-2 py-0 ">
          <Col lg={4} sm={6}>
            {this.props.t("common.outputQuantity")}{" "}
          </Col>
          <Col lg={6} sm={6}>
            <>
              <span className="mr-2">
                {data.output_quantity}{" "}
                {this.getUnitSymbol(data.output_unit_uuid)}
              </span>
            </>
          </Col>
        </Row>
        <Row className="mb-2 py-0">
          <Col lg={4} sm={6}>
            {this.props.t("common.boxQuantity")}{" "}
          </Col>
          <Col lg={6} sm={6}>
            {data.package_quantity} {this.getUnitSymbol(data.package_unit_uuid)}
          </Col>
        </Row>
        <Row className="mb-2 py-0">
          <Col lg={4} sm={6}>
            {this.props.t("common.printLabel")}{" "}
          </Col>
          <Col lg={6} sm={6} className="text-capitalize">
            <Badge color={`${data.print_label ? "primary" : "secondary"}`}>
              {JSON.stringify(!!data.print_label)}
            </Badge>
          </Col>
        </Row>
        <Row className="mb-2 py-0">
          <Col lg={4} sm={6}>
            {this.props.t("recipe.verifyOutputQuantity")}
          </Col>
          <Col lg={6} sm={6}>
            {data?.completion_quantity_correction === 0
              ? this.props.t("recipe.noVerification")
              : data?.completion_quantity_correction === 1
              ? this.props.t("recipe.verifyTotalQuantity")
              : data?.completion_quantity_correction === 2
              ? this.props.t("recipe.adjustPerLabel")
              : ""}
          </Col>
        </Row>
      </>
    );
  };

  getLabelName = (parent: any, items: any) => {
    let name;
    if (!!items) {
      name = items.map((item: any, i: number) => {
        if (parent === item.uuid) {
          return commonService.applyLocalization(
            "restaurant",
            "name",
            item?.locales
          ).name;
        }
      });
      return name;
    }
  };

  getLabelOptions = () => {
    let options: Array<any> = [];
    this.state.labels?.map((labl: any) => {
      options.push({
        value: labl.uuid,
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          labl?.locales
        ).name,
      });
    });
    return options;
  };

  getPageTitle = () => {
    return {
      pageTitle: this.props.t("recipe.recipeDetails"),
    };
  };

  getRecipeDetail = (recipeId: string, restaurantId: string) => {
    this.props.dispatchActions.getRecipeDetails({
      restaurantuuid: restaurantId,
      recipeuuid: recipeId,
    });
  };

  getRecipeIngredient(
    recipeId: string,
    restaurantId: string,
    prepSetId?: string
  ) {
    this.props.dispatchActions.getRecipeIngredient({
      prepSetId: prepSetId,
      recipeuuid: recipeId,
      restaurantuuid: restaurantId,
    });
  }

  getRelatedUnitsForStorage = () => {
    if (this.props.storeProps.recipeDetails?.output_unit_uuid) {
      return this.getRelatedUnits(
        this.props.storeProps.recipeDetails?.output_unit_uuid,
        this.props.storeProps.recipeDetails?.uuid
      );
    }
    return [];
  };

  getRelatedUnits = (
    unit: any,
    recipeuuid: string,
    includeRecipeUnits: boolean = true
  ) => {
    if (unit) {
      const units: Array<any> = this.getRelatedUnitDropdowns(
        unit,
        recipeuuid,
        [],
        1,
        includeRecipeUnits
      );
      return units;
    }
    return [];
  };

  getRelatedUnitDropdowns = (
    unitId: string,
    recipeuuid: string = this.props.match.params.recipeuuid,
    allUnits: Array<any> = [],
    conversionFactor: number = 1,
    includeRecipeUnits: boolean = true
  ) => {
    const recipeUnit = Array.isArray(this.props.storeProps.units)
      ? this.props.storeProps.units?.find((unit: any) => {
          if (unit?.recipe_uuid) {
            return unit.uuid === unitId && unit?.recipe_uuid === recipeuuid;
          }
          return unit.uuid === unitId;
        })
      : null;

    if (!recipeUnit) return [];
    let units: Array<any> = [
      {
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          recipeUnit.locales
        )["name"],
        value: recipeUnit.uuid,
        symbol: commonService.applyLocalization(
          "restaurant",
          "name",
          recipeUnit.locales
        )["symbol"],
        conversionFactor,
      },
    ];
    const fromUnits = recipeUnit?.from_conversions;
    const toUnits = recipeUnit?.to_conversions;

    if (fromUnits?.length > 0) {
      fromUnits.forEach((from: any) => {
        if (
          (recipeuuid &&
            from?.recipe_uuid &&
            recipeuuid !== from.recipe_uuid) ||
          (!recipeuuid && from?.recipe_uuid) ||
          (!includeRecipeUnits && from?.recipe_uuid)
        )
          return;
        const itsExist = allUnits?.filter(
          (allUnit: any) => allUnit?.value === from.toUnit
        );
        if (!itsExist?.length && recipeUnit.uuid !== from.toUnit) {
          const allUnitsMerged = _.concat(allUnits, units);
          const fromUnit: any = this.getRelatedUnitDropdowns(
            from.toUnit,
            recipeuuid,
            allUnitsMerged,
            conversionFactor / from.conversion_factor,
            includeRecipeUnits
          );

          if (!!fromUnit) units = _.concat(units, fromUnit);
        }
      });
    }

    if (toUnits?.length > 0) {
      toUnits.forEach((to: any) => {
        if (
          (to?.recipe_uuid && to.recipe_uuid !== recipeuuid) ||
          (!includeRecipeUnits && to?.recipe_uuid)
        )
          return;
        const itsExist = allUnits?.filter(
          (allUnit: any) => allUnit?.value === to.fromUnit
        );
        if (!itsExist?.length && recipeUnit.uuid !== to.fromUnit) {
          const allUnitsMerged = _.concat(allUnits, units);
          const toUnit: any = this.getRelatedUnitDropdowns(
            to.fromUnit,
            recipeuuid,
            allUnitsMerged,
            conversionFactor * to.conversion_factor,
            includeRecipeUnits
          );

          if (!!toUnit) units = _.concat(units, toUnit);
        }
      });
    }

    return _.uniqBy(units, function (e) {
      return e.value;
    });
  };

  getStorageDetails = (
    recipeId: string,
    restaurantId: string,
    forceReset: boolean = false
  ) => {
    if (
      !forceReset &&
      !!this.state.recipeStorages &&
      this.state.recipeStorages.length > 0 &&
      !this.state.isStorageUpdated
    )
      return;
    this.setState({ isFetching: true, isUpdated: false });
    const payload = {
      credentials: {
        recipeuuid: recipeId,
        restaurantuuid: restaurantId,
      },
    };

    getRecipeStorageDetails(RESTAURANT_REQUEST, payload).then((data) => {
      if (data.status === 200) {
        // TODO Remove position from storage get API
        // const storage: any = data.data?.map((st: any) => {
        //   delete st.position;
        //   return st;
        // });
        this.setState({
          recipeStorages: data.data,
          isFetching: false,
        });
      }
    });
  };

  getSubstitutes = () => {
    this.props.dispatchActions.getSubstitutes({
      uuid: this.props.match.params.uuid,
      type: "3",
    });
  };

  getSupplierDetails = (supplierId: string) => {
    const supplierDetails = this.state.supplierList.find(
      (supplier: any) => supplier.uuid === supplierId
    );
    return commonService.applyLocalization(
      "restaurant",
      "name",
      supplierDetails?.locales
    )["name"];
  };

  getSuppliers = () => {
    const recipeId: string = this.props.match.params.recipeuuid;
    const restaurantId: string = this.props.match.params.uuid;
    const payload = {
      credentials: {
        recipeuuid: recipeId,
        restaurantuuid: restaurantId,
      },
    };
    getRestaurantSuppliers(RESTAURANT_REQUEST, payload).then((data) => {
      const responseData: any = handleResponse(data);
      if (responseData?.ok) {
        this.setState({
          supplierList: responseData.data,
        });
      }
    });
  };

  getTaskList = (
    recipeId: string,
    restaurantId: string,
    prepSetId?: string
  ) => {
    this.props.dispatchActions.getTask({
      prepSetId: prepSetId,
      recipeuuid: recipeId,
      restaurantuuid: restaurantId,
    });
  };

  getUnits = (type: string) => {
    if (type === "create") {
      return (
        this.props.storeProps.units?.filter(
          (unit: any) => !unit?.recipe_uuid
        ) || []
      );
    }
    return this.props.storeProps.units || [];
  };

  getUnitSymbol = (unitId: string) => {
    if (
      this.props.storeProps?.units &&
      Object.keys(this.props.storeProps?.units).length > 0
    ) {
      let unit = this.props.storeProps.units?.filter(
        (unit: any) => unit.uuid === unitId
      )[0];
      return commonService.applyLocalization(
        "restaurant",
        "name",
        unit?.locales
      )["symbol"];
    }
  };

  handleAddNewIngredient = (itemType: number) => {
    const formData: any = _.cloneDeep(RecipeDetailsInitState.formData);
    formData["type"] = itemType;
    formData["formType"] = "create";
    formData["area"] =
      itemType === RECIPE_TYPE.RECIPE
        ? "recipe"
        : itemType === RECIPE_TYPE.INGREDIENT
        ? "ingredient"
        : itemType === RECIPE_TYPE.CHOICE
        ? "choiceItem"
        : "abstractIngredient";
    formData["barcode"] =
      itemType === RECIPE_TYPE.RECIPE || itemType === RECIPE_TYPE.INGREDIENT
        ? ""
        : undefined;
    formData["labelOptions"] = this.getLabelOptions();
    this.setState({
      isEditItemModalOpen: true,
      formData: formData,
    });
  };

  handleImageUpdate = (imageId: string) => {
    const selectedItem: any = this.props.storeProps.recipeDetails;
    let locale = commonService.applyLocalization(
      "restaurant",
      "name",
      selectedItem?.locales
    );
    const payload: any = {
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.props.match.params.recipeuuid,
      data: {
        image_uuid: imageId,
        label_uuid: selectedItem?.label_uuids || [],
        color: selectedItem?.color,
        locales: {
          [this.props.storeProps.restaurantLang[0].code]: {
            name: locale.name,
            description: locale.description,
            pos_name: locale.pos_name,
            printer_name: locale.printer_name,
          },
        },
        output_quantity: selectedItem.output_quantity,
        output_unit_uuid: selectedItem.output_unit_uuid,
        product_barcode: selectedItem?.product_barcode || "",
        shelf_life: selectedItem?.shelf_life || undefined,
        sku: selectedItem.sku,
        supplier_uuid: selectedItem?.supplier_uuid
          ? selectedItem?.supplier_uuid
          : undefined,
      },
    };

    this.props.dispatchActions.updateRecipe(payload);
    this.setState({ isUpdated: true });
  };

  handleIngredientsSave = (
    ingredients: Array<any>,
    preparation_set: string,
    recipeQtyRatio: number = 1
  ) => {
    this.showLoading(true);
    const restaurantId: string = this.props.match.params.uuid;
    const recipeId: string = this.props.match.params.recipeuuid;
    const ingredientsArray: Array<any> = _.cloneDeep(ingredients)?.map(
      (ingredient: any, index: number) => {
        let qty: number = (ingredient.quantity || 0) / recipeQtyRatio;
        if (
          ingredient.unitConversion &&
          ingredient.unitConversion.conversionFactor
        ) {
          qty = qty * ingredient.unitConversion.conversionFactor;
        } else if (ingredient?.unitConversionDetails?.conversionDetails) {
          qty =
            qty *
              ingredient.unitConversionDetails.conversionDetails
                ?.conversionFactor || 1;
        }

        const payload: any = {
          default_item: ingredient?.default_item,
          quantity: qty,
          recipe_uuid: ingredient.recipeUuid,
          sort_order: index + 1,
        };

        if (
          ingredient.default_choice_count &&
          ingredient.default_choice_count !== ""
        )
          payload.default_choice_count = Number(
            ingredient.default_choice_count
          );
        if (ingredient.max_choice_count && ingredient.max_choice_count !== "")
          payload.max_choice_count = Number(ingredient.max_choice_count);
        if (ingredient.min_choice_count && ingredient.min_choice_count !== "")
          payload.min_choice_count = Number(ingredient.min_choice_count);

        return payload;
      }
    );

    const payload = {
      credentials: {
        recipeuuid: recipeId,
        restaurantuuid: restaurantId,
        data: { ingredients: ingredientsArray, preparation_set },
      },
    };

    RecipeServices.addRecipeIngredient(RESTAURANT_REQUEST, payload)
      .then((response: any) => {
        const responseData: any = handleResponse(response);
        this.setState({
          isFetching: false,
        });
        if (responseData.ok) {
          commonService.toastService(response.data.flash, "success");
        }
        this.getRecipeIngredient(recipeId, restaurantId, preparation_set);
      })
      .catch(() => {
        this.setState({
          isFetching: false,
        });
        // this.showErrorToast(null, true);
      });
  };

  handleItemModify = (itemId: string, formData: any) => {
    this.showLoading(true);
    const restaurantId: string = this.props.match.params.uuid;
    const recipeId: string = this.props.match.params.recipeuuid;
    const payload: any = {
      credentials: {
        recipeuuid: recipeId,
        restaurantuuid: restaurantId,
        taskuuid: formData.parentTaskId,
      },
    };
    const responseRequestData: any = {
      restaurantId,
      recipeId,
      preparationSet: formData.preparationSet,
    };
    if (formData.area === taskEditType.STEP) {
      if (formData.action === CARD_SELECTION_STATE.DELETE) {
        payload.credentials["uuid"] = itemId;
        this.handleTaskStepRemove(payload, responseRequestData);
      }
    } else if (formData.area === taskEditType.INTERVENTION_STEP) {
      if (formData.action === CARD_SELECTION_STATE.DELETE) {
        payload.credentials["interventionuuid"] = formData.interventionId;
        payload.credentials["uuid"] = itemId;
        this.handleInterventionStepRemove(payload, responseRequestData);
      }
    }
  };

  handleItemSave = () => {
    if (this.state.formData.formType === "create") {
      this.handleNewIngredientSave();
      return;
    }

    const recipeId: string = this.props.match.params.recipeuuid;

    const shelfLifeMs: number = commonService.getTimeInMs(
      this.state.formData.shelfLife
    );
    const body: any = {
      restaurantuuid: this.props.match.params.uuid,
      uuid: recipeId,
      data: {
        completion_quantity_correction: Number(
          this.state.formData.confirmationCompletion
        ),

        label_uuid: this.state.formData.selectedLabels.map(
          (item: any) => item.value
        ),
        output_quantity_update_formula:
          this.state.formData.outputQuantityUpdateFormula,
        output_quantity: Number(this.state.formData.outputQuantity),
        output_unit_uuid: this.state.formData.outputUnitUuid,
        color: this.state.formData.color,
        supplier_uuid: !!this.state.supplierId
          ? this.state.supplierId
          : undefined,
        sku: this.state.formData.sku,
        product_barcode: this.state.formData.barcode,
        inherit_tags_and_labels: this.state.formData.inheritTags,
        locales: {
          [this.props.storeProps.restaurantLang[0].code]: {
            name: this.state.formData.name,
            description: this.state.formData.description,
            pos_name: this.state.formData.posName,
            printer_name: this.state.formData.printerName,
          },
        },
        package_quantity: Number(this.state.formData.packageQuantity || ""),
        package_unit_uuid: this.state.formData.packageUnitUuid,
        print_label: this.state.formData.printLabel,
        shelf_life: shelfLifeMs,
        replacement_item: this.state.formData.replacementItem,
      },
    };

    if (body.data.output_unit_uuid === "NEW_RECIPE_UNIT") {
      body.data.output_unit_uuid = "";
      body.data["output_unit"] = this.state.newUnit;
      delete body.data.output_unit.uuid;
    }
    RecipeEditHelper.updateRecipe(
      this.editItemData,
      body,
      this.props.dispatchActions.updateRecipe,
      this
    );

    this.setState({ isUpdated: true });
  };

  handleNewIngredientSave = () => {
    let body: any = {
      restaurantuuid: this.props.match.params.uuid,
      data: {
        completion_quantity_correction:
          this.state.formData.confirmationCompletion,

        label_uuid: this.state.formData.selectedLabels.map(
          (item: any) => item.value
        ),
        output_quantity: parseInt(this.state.formData.outputQuantity),
        output_unit_uuid: this.state.formData.outputUnitUuid,
        color: this.state.formData.color,
        supplier_uuid: !!this.state.supplierId
          ? this.state.supplierId
          : undefined,
        sku: this.state.formData.sku,
        product_barcode: this.state.formData.barcode,
        inherit_tags_and_labels: this.state.formData.inheritTags,
        locales: {
          [this.props.storeProps.restaurantLang[0].code]: {
            name: this.state.formData.name,
            description: this.state.formData.description,
            pos_name: this.state.formData.posName,
            printer_name: this.state.formData.printerName,
          },
        },
        package_quantity: parseInt(this.state.formData.packageQuantity || ""),
        package_unit_uuid: this.state.formData.packageUnitUuid,
        type: this.state.formData.type,
        replacement_item: this.state.formData.replacementItem,
      },
    };
    if (this.state.formData.type === RECIPE_TYPE.RECIPE)
      body.data["status"] = RECIPE_STATUS.PUBLISHED;
    this.newRecipeIngredient = body;
    this.newRecipeIngredient["isUpdated"] = true;
    this.props.dispatchActions.addRecipe(body);
  };

  handlePrepSetChange = (selection: any) => {
    if (selection?.value) {
      const recipeId: string = this.props.match.params.recipeuuid;
      const restaurantId: string = this.props.match.params.uuid;

      this.getTaskList(recipeId, restaurantId, selection.value);
      this.getRecipeIngredient(recipeId, restaurantId, selection.value);
    }
  };

  handleQuantityUpdateSave = () => {
    if (Number(this.state.outputQuantity) > 0) {
      const restaurantId: string = this.props.match.params.uuid;
      const recipeId: string = this.props.match.params.recipeuuid;
      const payload = {
        credentials: {
          recipeuuid: recipeId,
          restaurantuuid: restaurantId,
          data: { quantity: Number(this.state.outputQuantity) },
        },
        data: {
          output_quantity: Number(this.state.outputQuantity),
        },
      };
      RecipeEditHelper.updateRecipe(
        this.props.storeProps.recipeDetails,
        payload,
        this.initiateUpdateRecipeQuantity(recipeId, restaurantId),
        this
      );
    }
  };

  initiateUpdateRecipeQuantity =
    (recipeId: string, restaurantId: string) => (payload: any) => {
      updateRecipeQuantity(RESTAURANT_REQUEST, payload).then((data: any) => {
        const response: any = handleResponse(data);

        if (response.ok) {
          this.getRecipeDetail(recipeId, restaurantId);
        }
        this.showLoading(false);
      });
      this.setState({ isOutputQtyUpdate: false });
    };

  handleRecipeDelete = (itemId: any, element: string) => {
    OpenDeleteModal().then(() => {
      this.showLoading(true);
      const payload = {
        credentials: {
          uuid: itemId,
          restaurantuuid: this.props.match.params.uuid,
        },
      };
      RecipeServices.removeRecipe(RESTAURANT_REQUEST, payload)
        .then((response: any) => {
          if (response.data.status) {
            commonService.toastService(response.data.flash, "success");
            this.props.history.push(
              `/restaurant/${this.props.match.params.uuid}/recipes`
            );
          } else {
            this.showErrorToast(response.data.flash);
          }
        })
        .catch(() => {
          this.setState({
            isFetching: false,
          });
          this.showErrorToast(null, true);
        });
    });
  };

  handleRecipeEdit = (data: any) => {
    const recipeDetails: any = this.props.storeProps.recipeDetails;
    this.editItemData = recipeDetails;
    let allLabels = this.state.labels?.length ? this.state.labels : [];
    let recipeDataLocale = commonService.applyLocalization(
      "restaurant",
      "name",
      recipeDetails.locales
    );
    const shelf_life: string = commonService.getMsToDate(
      recipeDetails?.shelf_life || 0
    );
    let options = this.getLabelOptions();
    this.setState({
      isEditItemModalOpen: true,
      formData: {
        ...this.state.formData,
        barcode: recipeDetails?.product_barcode || "",
        color: recipeDetails.color,
        confirmationCompletion: recipeDetails?.completion_quantity_correction,
        description: recipeDataLocale.description,
        inheritTags: recipeDetails.inherit_tags_and_labels,
        labelOptions: options,
        name: recipeDataLocale?.name,
        outputQuantity: recipeDetails.output_quantity,
        outputUnitUuid: recipeDetails.output_unit_uuid,
        packageQuantity: recipeDetails?.package_quantity,
        packageUnitUuid: recipeDetails?.package_unit_uuid,
        posName: recipeDataLocale?.pos_name,
        printerName: recipeDataLocale?.printer_name,
        printLabel: recipeDetails?.print_label || false,
        selectedLabels: recipeDetails.label_uuids
          ? recipeDetails?.label_uuids?.map((label: any) => {
              return {
                value: label,
                label: this.getLabelName(label, allLabels),
              };
            })
          : [],
        shelfLife: shelf_life,
        sku: recipeDetails?.sku,
        type: "edit",
        replacementItem: recipeDetails.replacement_item_uuid,
      },
      newUnit: null,
    });
  };

  handleOutputQuantityUpdateFormula = (event) => {
    const value = event.target.value;
    const formula = value === "1" ? "same" : "changed";
    const formData = {
      ...this.state.formData,
      outputQuantityUpdateFormula: formula,
    };
    this.setState({ ...this.state, formData });
  };

  handleRecipeEditChanges = (data: any, area?: string) => {
    if (area === "outputQuantity") {
      this.setState({
        outputQuantity: data.target.value,
      });
      return;
    }
    let state: any = this.state.formData;
    //  TODO need cleanup once <ItemAddModal> reworked
    switch (data.target.name) {
      case "inheritTags":
        state["inheritTags"] = data.target.checked;
        break;
      case "printLabel":
        state["printLabel"] = data.target.checked;
        break;
      case "outputquantity":
        state["outputQuantity"] = data.target.value;
        break;
      case "package_quantity":
        state["packageQuantity"] = data.target.value;
        break;
      case "package_unit_uuid":
        state["packageUnitUuid"] = data.target.value;
        break;
      default:
        state[data.target.name] = data.target.value;
    }
    this.setState({
      formData: state,
    });
  };

  handleRecipeEditCancel = () => {
    this.setState({
      isEditItemModalOpen: false,
      // formData: RecipeDetailsInitState.formData
    });
  };

  handleRecipeLabelSelect = (selectedLabels: Array<any>) => {
    let labels: Array<any> = [];
    if (selectedLabels != null) {
      labels = selectedLabels;
    }
    // TODO check to update state without setState
    const formData = _.cloneDeep(this.state.formData);
    formData.selectedLabels = labels;
    this.setState({
      formData,
    });
  };

  handleRecipeUnitChange = (e: any) => {
    if (e.target.value === "NEW_UNIT") {
      this.openNewUnitModal();
      return;
    } else if (e.target.value === "NEW_RECIPE_UNIT") {
      const formData: any = { ...this.state.formData };
      formData["outputUnitUuid"] = e.target.value;
      this.setState({
        formData: formData,
        isUnitModalOpen: false,
      });
    } else {
      let outputUnit: string = "";
      let packageUnitUuid: string = this.state.formData.packageUnitUuid;
      this.props.storeProps.units.forEach((unit: any) => {
        if (unit.uuid === e.target.value) {
          outputUnit = unit.uuid;
          packageUnitUuid = unit.uuid;
        }
      });

      const formData = _.cloneDeep(this.state.formData);
      formData.packageUnitUuid = packageUnitUuid;
      formData.outputUnitUuid = outputUnit;
      this.setState({
        formData: formData,
      });
    }
  };

  handleResponse = (response: any, formData: any) => {
    const { recipeId, restaurantId, preparationSet } = formData;
    this.setState({
      isFetching: false,
    });
    if (response.data.status) {
      commonService.toastService(response.data.flash, "success");
      this.setState({
        isInterventionUpdated: Math.random(),
      });
      this.getTaskList(recipeId, restaurantId, preparationSet);
      this.getRecipeIngredient(recipeId, restaurantId, preparationSet);
    } else {
      this.showErrorToast(response.data.errors);
    }
  };

  handleSelect = () => {};

  handleTaskInterventionDelete = (
    interventionId: string,
    taskId: string,
    preparationSet: string
  ) => {
    this.showLoading(true);
    const restaurantId: string = this.props.match.params.uuid;
    const recipeId: string = this.props.match.params.recipeuuid;
    const payload: any = {
      credentials: {
        recipeuuid: recipeId,
        restaurantuuid: restaurantId,
        taskuuid: taskId,
        uuid: interventionId,
      },
    };
    TaskInterventionServices.removeTaskIntervention(RESTAURANT_REQUEST, payload)
      .then((response: any) => {
        this.showLoading(false);
        if (response.data.status) {
          commonService.toastService(response.data.flash, "success");
          this.setState({
            isInterventionUpdated: Math.random(),
          });
          this.getTaskList(recipeId, restaurantId, preparationSet);
          this.getRecipeIngredient(recipeId, restaurantId, preparationSet);
        } else {
          this.showErrorToast(response.data.errors);
        }
      })
      .catch(() => this.showErrorToast(null, true));
  };

  handleTaskInterventionSave = (formData: any, params: any) => {
    this.showLoading(true);
    const restaurantId: string = this.props.match.params.uuid;
    const recipeId: string = this.props.match.params.recipeuuid;
    const responseRequestData: any = {
      restaurantId,
      recipeId,
      preparationSet: params.preparationSet,
    };
    const payload: any = {
      credentials: {
        recipeuuid: recipeId,
        restaurantuuid: restaurantId,
        taskuuid: params.taskId,
        data: formData,
      },
    };
    if (formData?.type && formData.type === taskEditType.INTERVENTION_STEP) {
      this.handleTaskInterventionStepSave(
        payload,
        params.interventionId,
        responseRequestData
      );
      return;
    }
    if (params.interventionId === "newIntervention") {
      TaskInterventionServices.addTaskIntervention(RESTAURANT_REQUEST, payload)
        .then((response: any) => {
          const responseData: any = handleResponse(response);
          if (responseData.ok)
            this.handleResponse(response, responseRequestData);
          else this.showLoading(false);
        })
        .catch(() => this.showErrorToast(null, true));
    } else {
      payload.credentials["uuid"] = params.interventionId;
      TaskInterventionServices.updateTaskIntervention(
        RESTAURANT_REQUEST,
        payload
      )
        .then((response: any) => {
          const responseData: any = handleResponse(response);
          if (responseData.ok)
            this.handleResponse(response, responseRequestData);
          else this.showLoading(false);
        })
        .catch(() => this.showErrorToast(null, true));
    }
  };

  handleInterventionStepRemove = (payload: any, responseRequestData: any) => {
    TaskInterventionStepServices.removeTaskInterventionStep(
      RESTAURANT_REQUEST,
      payload
    )
      .then((response: any) => {
        const responseData: any = handleResponse(response);
        if (responseData.ok) this.handleResponse(response, responseRequestData);
        else this.showLoading(false);
      })
      .catch(() => this.showErrorToast(null, true));
  };

  handleTaskInterventionStepSave = (
    formData: any,
    interventionId: string,
    responseRequestData: any
  ) => {
    const itemId: string = formData.credentials.data.itemId;
    const payload: any = { ...formData };
    delete payload.credentials.data.itemId;
    delete payload.credentials.data.type;
    delete payload.credentials.data.interventionId;
    payload.credentials["interventionuuid"] = interventionId;

    if (itemId === "newInterventionStep") {
      TaskInterventionStepServices.addTaskInterventionStep(
        RESTAURANT_REQUEST,
        payload
      )
        .then((response: any) => {
          this.handleResponse(response, responseRequestData);
        })
        .catch(() => this.showErrorToast(null, true));
    } else {
      payload.credentials["uuid"] = itemId;
      TaskInterventionStepServices.updateTaskInterventionStep(
        RESTAURANT_REQUEST,
        payload
      )
        .then((response: any) => {
          this.handleResponse(response, responseRequestData);
        })
        .catch(() => this.showErrorToast(null, true));
    }
  };

  handleTaskItemRemove = (taskId: string, preparationSet: string) => {
    const restaurantId: string = this.props.match.params.uuid;
    const recipeId: string = this.props.match.params.recipeuuid;
    this.showLoading(true);
    const payload = {
      credentials: {
        recipeuuid: recipeId,
        restaurantuuid: restaurantId,
        uuid: taskId,
      },
    };
    TaskServices.removeTask(RESTAURANT_REQUEST, payload)
      .then((response: any) => {
        this.setState({
          isFetching: false,
        });
        if (response.data.status) {
          commonService.toastService(response.data.flash, "success");
          this.getTaskList(recipeId, restaurantId, preparationSet);
        } else {
          this.showErrorToast(response.data.errors);
        }
      })
      .catch(() => this.showErrorToast(null, true));
  };

  handleTaskItemSave = (newTask: any) => {
    this.showLoading();
    const restaurantId: string = this.props.match.params.uuid;
    const recipeId: string = this.props.match.params.recipeuuid;
    const payload = {
      credentials: {
        recipeuuid: recipeId,
        restaurantuuid: restaurantId,
        data: newTask,
      },
    };
    TaskServices.addTask(RESTAURANT_REQUEST, payload)
      .then((response: any) => {
        this.showLoading(false);
        if (response.data.status) {
          commonService.toastService(response.data.flash, "success");

          this.getRecipeIngredient(
            recipeId,
            restaurantId,
            newTask.preparation_set
          );
          this.getTaskList(recipeId, restaurantId, newTask.preparation_set);
        } else {
          this.showErrorToast(response.data.errors);
        }
      })
      .catch(() => {
        this.showLoading(false);
        this.showErrorToast(null, true);
      });
  };

  handleTaskItemUpdate = (task: any, task_uuid: string) => {
    this.showLoading();
    const restaurantId: string = this.props.match.params.uuid;
    const recipeId: string = this.props.match.params.recipeuuid;
    const payload = {
      credentials: {
        recipeuuid: recipeId,
        restaurantuuid: restaurantId,
        data: task,
        uuid: task_uuid,
      },
    };
    TaskServices.updateTask(RESTAURANT_REQUEST, payload)
      .then((response: any) => {
        this.showLoading(false);
        if (response.data.status) {
          commonService.toastService(response.data.flash, "success");
          this.getTaskList(recipeId, restaurantId, task.preparation_set);
          this.getRecipeIngredient(
            recipeId,
            restaurantId,
            task.preparation_set
          );
        } else {
          this.showErrorToast(response.data.errors);
        }
      })
      .catch(() => {
        this.showLoading(false);
        this.showErrorToast(null, true);
      });
  };

  handleTaskStepRemove = (payload: any, responseRequestData: any) => {
    this.showLoading();
    TaskStepServices.removeTaskStep(RESTAURANT_REQUEST, payload)
      .then((response: any) => {
        this.handleResponse(response, responseRequestData);
      })
      .catch(() => this.showErrorToast(null, true));
  };

  handleTaskStepSave = (formData: any, params: any) => {
    this.showLoading(true);
    const restaurantId: string = this.props.match.params.uuid;
    const recipeId: string = this.props.match.params.recipeuuid;
    const payload: any = {
      credentials: {
        recipeuuid: recipeId,
        restaurantuuid: restaurantId,
        taskuuid: params.taskId,
        data: formData,
      },
    };
    const responseRequestData: any = {
      restaurantId,
      recipeId,
      preparationSet: params.preparationSet,
    };
    if (params.stepId === "newStep") {
      TaskStepServices.addTaskStep(RESTAURANT_REQUEST, payload)
        .then((response: any) => {
          const responseData: any = handleResponse(response);
          if (responseData.ok)
            this.handleResponse(response, responseRequestData);
          else this.showLoading(false);
        })
        .catch(() => this.showErrorToast(null, true));
    } else {
      payload.credentials["uuid"] = params.stepId;
      TaskStepServices.updateTaskStep(RESTAURANT_REQUEST, payload)
        .then((response: any) => {
          this.handleResponse(response, responseRequestData);
        })
        .catch(() => this.showErrorToast(null, true));
    }
  };

  handleUnitUpdate = (value: any, area: string) => {
    if (area === "visibility") {
      this.setState({
        isUnitModalOpen: value,
        newUnit: null,
      });
    } else if (area === "save") {
      const formData: any = { ...this.state.formData };
      formData["outputUnitUuid"] = value.uuid;
      this.setState({
        newUnit: value,
        formData: formData,
        isUnitModalOpen: false,
      });
    }
  };

  loadMetaData = () => {
    api
      .get(
        "/api/1.0/restaurant/" +
          this.props.match.params.uuid +
          "/get??sort=name:desc&components=unit, storage, label, room,station,machine",
        {}
      )
      .then((response: any) => {
        if (!!response.status) {
          const { room, storage } = response.data;
          const rooms: any = {};

          room.forEach((r: any) => {
            rooms[r.uuid] = r;
            const name: string = commonService
              .applyLocalization("restaurant", "name", r.locales)
              ["name"].toLowerCase();
            rooms[r.uuid]["nameLower"] = name.toLowerCase();
            rooms[r.uuid]["name"] = name;
          });
          storage.forEach((st: any) => {
            if (!rooms[st.room_uuid]?.["storage"]) {
              rooms[st.room_uuid]["storage"] = [];
            }
            rooms[st.room_uuid]["storage"].push(st);
          });

          this.setState({
            labels: response.data.label ? response.data.label : [],
            machineData: response.data.machine ? response.data.machine : [],
            rooms: _.sortBy(rooms, "nameLower"),
            stationData: response.data.station ? response.data.station : [],
            storages: response.data.storage ? response.data.storage : [],
            units: response.data.unit ? response.data.unit : [],
          });
        } else {
          handleResponse(response);
        }
      });
  };

  openNewUnitModal = () => {
    this.setState({
      isUnitModalOpen: true,
    });
  };

  publishRecipe = () => {
    let error: boolean = false;

    // this.taskWarning.forEach((item: any) => {
    //   if (item.type === "stop") {
    //     error = true;
    //   }ss
    // });

    if (error) {
      commonService.toastService(
        i18next.t("toast.recipeCriticalErrors"),
        "danger"
      );
      return;
    }

    this.setState({
      isRecipeEditable: false,
    });
    this.props.publishRecipe({
      uuid: this.props.match.params.recipeuuid,
      restaurantuuid: this.props.match.params.uuid,
    });
    // this._setStates("isUpdated", true);
  };

  randomString(length: number, chars: string) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  removeStorage = (ingredientId: string, uuid: string) => {
    OpenDeleteModal()
      .then(() => {
        this.showLoading(true);
        let allRecipeStorages = _.clone(this.state.recipeStorages);

        const index = _.findIndex(allRecipeStorages, function (storage: any) {
          return storage.uuid === uuid;
        });
        if (index >= 0) {
          allRecipeStorages.splice(index, 1);
          this.setState({ isStorageUpdated: true, recipeStorages: [] });

          this.updateRecipeStorage(allRecipeStorages, ingredientId);
        } else {
          this.setState({ isFetching: false });
        }
      })
      .catch(() => {
        return;
      });
  };

  renderManaged = () => {
    let list = [
      {
        managedId: 1,
        title: this.props.t("common.managed"),
      },
      {
        managedId: 2,
        title: this.props.t("common.noStock"),
      },
      {
        managedId: 3,
        title: this.props.t("common.infinite"),
      },
    ];

    let managedList;
    if (!!list) {
      managedList = list.map((sup: any, i: number) => {
        return (
          <option key={i} value={sup.managedId}>
            {sup.title}
          </option>
        );
      });
      return managedList;
    }
  };

  saveRecipeStorage = (formData: any) => {
    this.setState({
      isFetching: true,
      toggleStorage: false,
      storageFormData: {},
    });
    let updatedStorage: any = [];
    const { ingredientId } = this.state.activeStorageDetails;
    let allRecipeStorages = _.clone(this.state.recipeStorages || []);
    if (!!this.state.activeStorageDetails?.isEdit) {
      const { editData }: any = this.state.activeStorageDetails;
      const index = _.findIndex(allRecipeStorages, (storage: any) => {
        return storage.uuid === editData.uuid;
      });
      if (!!formData.main_storage && !editData.main_storage) {
        const index = _.findIndex(allRecipeStorages, (storage: any) => {
          return storage.main_storage === true;
        });
        if (index >= 0) {
          allRecipeStorages[index]["main_storage"] = false;
        }
      }
      allRecipeStorages[index]["main_storage"] = formData.main_storage;
      if (!!formData.min_quantity && Number(formData.min_quantity) >= 0) {
        allRecipeStorages[index]["min_quantity"] =
          Number(formData.min_quantity) *
          (Number(formData.conversionFactor) || 1);
      } else {
        allRecipeStorages[index]["min_quantity"] = undefined;
      }
      if (!!formData.max_quantity && Number(formData.max_quantity) > 0) {
        allRecipeStorages[index]["max_quantity"] =
          Number(formData.max_quantity) *
          (Number(formData.conversionFactor) || 1);
      } else {
        allRecipeStorages[index]["max_quantity"] = undefined;
      }
      if (!!formData.inventory_type) {
        allRecipeStorages[index]["inventory_type"] = formData.inventory_type;
      } else {
        delete allRecipeStorages[index]["inventory_type"];
      }

      const outputQty: number =
        (Number(formData.conversionFactor) || 1) * formData.output_quantity;
      allRecipeStorages[index].output_quantity = outputQty;
      allRecipeStorages[index].output_unit = formData.output_unit_uuid;
      updatedStorage = allRecipeStorages;
    } else {
      const { area, data } = this.state.activeStorageDetails;
      let storage: any = {};
      if (area === storageType.ROOM) {
        storage.room = data[storageType.ROOM];
      } else if (area === storageType.STORAGE) {
        storage.room = data[storageType.ROOM];
        storage.storage = data[storageType.STORAGE];
      } else if (area === storageType.STORAGE_RACK) {
        storage.room = data[storageType.ROOM];
        storage.storage = data[storageType.STORAGE];
        storage.storage_rack = data[storageType.STORAGE_RACK];
      } else if (area === storageType.STORAGE_PLACE) {
        storage.room = data[storageType.ROOM];
        storage.storage = data[storageType.STORAGE];
        storage.storage_rack = data[storageType.STORAGE_RACK];
        storage.storage_place = data[storageType.STORAGE_PLACE];
      }
      if (!!formData.main_storage) {
        const index = _.findIndex(allRecipeStorages, (storage: any) => {
          return storage.main_storage === true;
        });
        if (index >= 0) {
          allRecipeStorages[index]["main_storage"] = false;
        }
        storage.main_storage = true;
      }
      if (!!formData.min_quantity && Number(formData.min_quantity) >= 0) {
        storage.min_quantity =
          Number(formData.min_quantity) *
          (Number(formData.conversionFactor) || 1);
      }
      if (!!formData.max_quantity && Number(formData.max_quantity) > 0) {
        storage.max_quantity =
          Number(formData.max_quantity) *
          (Number(formData.conversionFactor) || 1);
      }
      if (!!formData.inventory_type) {
        storage.inventory_type = formData.inventory_type;
      } else {
        delete storage.inventory_type;
      }
      const outputQty: number =
        (Number(formData.conversionFactor) || 1) * formData.output_quantity;
      storage.output_quantity = outputQty;
      storage.output_unit = formData.output_unit_uuid;
      updatedStorage = [...allRecipeStorages, ...[storage]];
    }
    this.updateRecipeStorage(updatedStorage, ingredientId);
  };

  setItemId = () => {};

  showEditPopPup = () => {
    this.setState({
      isEditModalShown: true,
    });
  };

  showErrorToast = (
    errors: any,
    isStatic: boolean = false,
    showDetails = true
  ) => {
    if (isStatic) {
      commonService.toastService(
        this.props.t("common.updatedFailed"),
        "danger"
      );
    } else if (errors) {
      const json = JSON.stringify(errors);
      const errorsString = json.replace(/[{}]/g, "");
      if (showDetails) {
        commonService.toastService("", "danger", errorsString);
      } else {
        commonService.toastService(errorsString, "danger");
      }
    }
  };

  showLoading = (state: boolean = true) => {
    this.setState({ isFetching: state });
  };

  toggleOutputQtyUpdate = (value: string) => {
    this.setState({
      isOutputQtyUpdate: !this.state.isOutputQtyUpdate,
      outputQuantity: value,
    });
  };

  toggleRecipeStatus = () => {
    this.showLoading(true);
    const restaurantId: string = this.props.match.params.uuid;
    const recipeId: string = this.props.match.params.recipeuuid;
    const payload = {
      credentials: {
        uuid: recipeId,
        restaurantuuid: restaurantId,
      },
      data: {},
    };
    RecipeServices.publishRecipe(RESTAURANT_REQUEST, payload)
      .then((response: any) => {
        this.setState({
          isFetching: false,
        });
        if (response.status === 200) {
          commonService.toastService(response.data.flash, "success");
          this.getRecipeDetail(recipeId, restaurantId);
        } else {
          this.showErrorToast(response.data.flash, false, false);
        }
      })
      .catch((er) => {
        this.setState({
          isFetching: false,
        });
        this.showErrorToast(null, true);
      });
  };

  toggleStorageMenu = () => {
    this.setState({
      isStorageModalOpen: !this.state.isStorageModalOpen,
    });
  };

  updateRecipeStorage(data: any, recipeId: string) {
    const restaurantId: string = this.props.match.params.uuid;
    const payload = {
      credentials: {
        recipeuuid: recipeId,
        restaurantuuid: restaurantId,
        data: { storage: data },
      },
    };

    updateRecipeStorage(RESTAURANT_REQUEST, payload).then((data: any) => {
      const responseData: any = handleResponse(data);
      if (responseData.ok) {
        commonService.toastService(
          this.props.t("storage.storageUpdateSuccess"),
          "success"
        );
        this.setState({
          isStorageUpdated: true,
          isStorageModalOpen: false,
        });
        this.getStorageDetails(recipeId, restaurantId);
      } else {
        this.setState({ isFetching: false });
      }
    });
  }

  getAssembleLocales = () => {
    if (!this.props.storeProps.assembleTaskLocales.locales) {
      return { title: "", description: "" };
    }

    return this.props.storeProps.assembleTaskLocales.locales[this.locale];
  };

  render() {
    const { t } = this.props;
    const recipeUuid: string = this.props.match.params.recipeuuid;
    const restaurantId: string = this.props.match.params.uuid;
    const ingredientCost: any = {};
    ingredientCost[recipeUuid] = this.props.storeProps.ingredientCost;
    let managedList = this.renderManaged();
    const isFetching =
      this.state.isFetching || this.props.storeProps.isFetching;
    const substitutes: object = [
      {
        substitutes: Array.isArray(this.props.storeProps.recipeSubstitutes)
          ? this.props.storeProps.recipeSubstitutes
          : [],
        uuid: recipeUuid,
      },
    ];
    return (
      <>
        <ModalFactory />
        <LoaderComponent display={isFetching} />
        <ItemAddModal
          area="recipe"
          barcode={this.state.formData.barcode}
          color={this.state.formData.color}
          confirmationCompletion={this.state.formData.confirmationCompletion}
          description={this.state.formData.description}
          elementId={recipeUuid}
          getRelatedUnits={this.getRelatedUnits}
          handleCancel={this.handleRecipeEditCancel}
          handleChange={this.handleRecipeEditChanges}
          handleOutputQuantityUpdateFormula={
            this.handleOutputQuantityUpdateFormula
          }
          handleLabelSelect={this.handleRecipeLabelSelect}
          handleUnitChange={this.handleRecipeUnitChange}
          isFetching={isFetching}
          isModalOpen={this.state.isEditItemModalOpen}
          isUnitDisabled={RecipeEditHelper.isUnitDisabled(
            this.state.isEditItemModalOpen,
            this.props.match.params.recipeuuid,
            this.props.storeProps.units
          )}
          labelOptions={this.state.formData.labelOptions}
          labels={this.state.labels}
          managedList={managedList}
          name={this.state.formData.name}
          newUnit={this.state.newUnit}
          outputquantity={this.state.formData.outputQuantity}
          outputunituuid={this.state.formData.outputUnitUuid}
          inheritTags={this.state.formData.inheritTags}
          package_quantity={this.state.formData.packageQuantity}
          package_unit_uuid={this.state.formData.packageUnitUuid}
          posName={this.state.formData.posName}
          printLabel={this.state.formData.printLabel}
          printerName={this.state.formData.printerName}
          saveItem={this.handleItemSave}
          selectedLabels={this.state.formData.selectedLabels}
          shelfLife={this.state.formData.shelfLife}
          sku={this.state.formData.sku}
          setSKU={this.generateSKU}
          textLabels={this.getAddModalLabel(
            this.state.formData.area,
            this.state.formData.formType
          )}
          type="newIngredientList"
          units={this.getUnits(this.state.formData.formType)}
          replacementItem={this.state.formData.replacementItem}
          isEdit
        />
        <ManageStorageStorage
          toggleStorageMenu={this.toggleStorageMenu}
          toggleStorage={this.state.isStorageModalOpen}
          saveRecipeStorage={this.saveRecipeStorage}
          storageFormData={this.state.storageFormData}
          units={this.getRelatedUnitsForStorage()}
        />

        <div className="container-fluid">
          <ConceptHeaderComponent concept={"concept"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <RestaurantNavComponent
                display={"recipe"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={10} lg={9}>
              <div>
                {this.props.storeProps.recipeDetails?.status ===
                RECIPE_STATUS.DRAFT ? (
                  <span className={"top-level-label"}>
                    {t("common.draftRecipe")}
                  </span>
                ) : (
                  <span className={"top-level-label"}>
                    {t("common.publishedRecipe")}
                  </span>
                )}
                {this.props.storeProps.recipeDetails?.status ===
                RECIPE_STATUS.DRAFT ? (
                  <Button
                    className={"top-level-button"}
                    onClick={this.toggleRecipeStatus}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={
                      this.props.storeProps.recipeTasks.length === 0
                        ? t("recipe.noTaskAdded")
                        : ""
                    }
                  >
                    {t("recipe.setAsPublish")}
                    {this.props.storeProps.recipeTasks.length === 0 && (
                      <span className="ml-1">
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="mr-2"
                        />
                      </span>
                    )}
                  </Button>
                ) : (
                  ""
                )}
              </div>
              {!_.isEmpty(this.props.storeProps.recipeDetails) &&
                this.props.storeProps?.units && (
                  <ItemWrapper
                    addNewStorage={this.addNewStorage}
                    allStorage={this.state.storages}
                    area="recipeDetails"
                    editStorage={this.editStorage}
                    elementId={recipeUuid}
                    fetchData={this.setUpdated}
                    getBestMatchUnits={this.getBestMatchUnits}
                    getExtraDetails={this.getExtraDetails}
                    getSubstitutes={this.getSubstitutes}
                    getUpdatedCostPrices={this.getCostDetails}
                    handleImageUpdate={this.handleImageUpdate}
                    handleUnitUpdate={this.handleUnitUpdate}
                    hasDetails={false}
                    hasTag={true}
                    ingredientsCost={ingredientCost}
                    isDetailPage={true}
                    isUnitModalOpen={this.state.isUnitModalOpen}
                    itemData={[this.props.storeProps.recipeDetails]}
                    itemsDetails={[this.props.storeProps.recipeDetails]}
                    labels={this.state.labels}
                    handleSelect={this.handleSelect}
                    onItemDelete={this.handleRecipeDelete}
                    onItemEdit={this.handleRecipeEdit}
                    pageText={this.getPageTitle()}
                    recipeServiceSet={{
                      [recipeUuid]: this.props.storeProps.recipeServiceSets,
                    }}
                    recipeStorage={{ [recipeUuid]: this.state.recipeStorages }}
                    recipeSubstitutes={substitutes}
                    removeStorage={this.removeStorage}
                    rooms={this.state.rooms}
                    setItemId={this.setItemId}
                    substitutes={this.props.storeProps.substitutes}
                    tags={this.props.storeProps.tags}
                    type="newIngredientList"
                    units={this.props.storeProps.units}
                  />
                )}

              {Array.isArray(this.props.storeProps.units) && (
                <RecipeTasksAndIngredientsWrapper
                  addNewIngredientItem={this.handleAddNewIngredient}
                  allRecipes={
                    Array.isArray(this.props.storeProps.allRecipes)
                      ? this.props.storeProps.allRecipes
                      : []
                  }
                  baseKitchenUnit={
                    this.props.storeProps.settings.base_kitchen_unit
                  }
                  getRelatedUnits={this.getRelatedUnits}
                  getSupplierDetails={this.getSupplierDetails}
                  handleIngredientsSave={this.handleIngredientsSave}
                  handleIngredientsSorting={this.handleIngredientsSave}
                  handlePrepSetChange={this.handlePrepSetChange}
                  handleTaskInterventionDelete={
                    this.handleTaskInterventionDelete
                  }
                  handleTaskInterventionSave={this.handleTaskInterventionSave}
                  handleTaskItemRemove={this.handleTaskItemRemove}
                  handleTaskItemSave={this.handleTaskItemSave}
                  handleTaskItemUpdate={this.handleTaskItemUpdate}
                  handleTaskStepSave={this.handleTaskStepSave}
                  ingredients={this.props.storeProps.recipeIngredients}
                  isInterventionUpdated={this.state.isInterventionUpdated}
                  labels={this.state.labels}
                  machines={this.state.machineData}
                  onItemModify={this.handleItemModify}
                  profiles={this.props.storeProps.recipeTasks}
                  recipeDetails={this.props.storeProps.recipeDetails}
                  restaurantId={restaurantId}
                  restaurantLang={this.props.storeProps.restaurantLang}
                  showErrorToast={this.showErrorToast}
                  showLoader={this.showLoading}
                  stations={this.state.stationData}
                  tasks={this.props.storeProps.recipeTasks}
                  units={this.props.storeProps.units}
                  assembleTaskLocales={this.getAssembleLocales()}
                />
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps: any = (state: any) => {
  let failure =
    state.allrecipe.failure ||
    state.allRecipeItems.failure ||
    state.ingredientCost.failure ||
    state.labels.failure ||
    state.recipeadd.failure ||
    state.recipedetails.failure ||
    state.recipeingredient.failure ||
    state.recipeSubstitute.failure ||
    state.recipeupdate.failure ||
    state.tags.failure ||
    state.task.failure ||
    state.units.failure ||
    state.unitsadd.failure ||
    state.assembleTaskLocales.failure ||
    state.unitsremove.failure;
  let isFetching =
    state.allrecipe.isFetching ||
    state.allRecipeItems.isFetching ||
    state.ingredientCost.isFetching ||
    state.labels.isFetching ||
    state.recipeadd.isFetching ||
    state.recipedetails.isFetching ||
    state.recipeingredient.isFetching ||
    state.recipeSubstitute.isFetching ||
    state.recipeupdate.isFetching ||
    state.tags.isFetching ||
    state.task.isFetching ||
    state.units.isFetching ||
    state.unitsadd.isFetching ||
    state.assembleTaskLocales.isFetching ||
    state.unitsremove.isFetching;
  return {
    storeProps: {
      allRecipes: state.allrecipe.data,
      editRecipeStatus: state.recipeupdate.data,
      failure,
      ingredientCost: state.ingredientCost.data.cost,
      isFetching,
      labels: state.labels.data,
      recipeAdd: state.recipeadd.data,
      recipeDetails: state.recipedetails.data,
      recipeIngredients: state.recipeingredient.data,
      recipeServiceSets: state.ingredientCost.data.serviceSets,
      recipeSubstitutes: state.recipeSubstitute.data,
      recipeTasks: state.task.data,
      restaurantLang: commonService.getRestaurantDetails()?.languages || [],
      settings: state.settings.data,
      substitutes: state.allRecipeItems.data,
      tags: state.tags.data,
      units: state.units.data || [],
      unitsAdd: state.unitsadd.data,
      unitsRemove: state.unitsremove.data,
      assembleTaskLocales: state.assembleTaskLocales.data,
    },
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    dispatchActions: {
      addRecipe: (credentials: any) => {
        dispatch(recipeAdd(credentials));
      },
      addUnits: (credentials: any) => {
        dispatch(unitsAdd(credentials));
      },
      getAllRecipe: (credentials: any) => {
        dispatch(allRecipeRequest(credentials));
      },
      getIngredientCost: (credentials: any) => {
        dispatch(ingredientCostRequest(credentials));
      },
      assembleTaskLocales: (credentials: any) => {
        dispatch(assembleTaskLocalesRequest(credentials));
      },
      getRecipeDetails: (credentials: any) => {
        dispatch(recipeDetailsRequest(credentials));
      },
      getRecipeIngredient: (credentials: any) => {
        dispatch(recipeIngredientRequest(credentials));
      },
      getRecipeSubstitutes: (credentials: any) => {
        dispatch(recipeSubstituteRequest(credentials));
      },
      getSubstitutes: (credentials: any) => {
        dispatch(recipeItemsRequest(credentials));
      },
      getTags: (credentials: any) => {
        dispatch(tagsRequest(credentials));
      },
      getTask: (credentials: any) => {
        dispatch(taskRequest(credentials));
      },
      getUnits: (credentials: any) => {
        dispatch(unitsRequest(credentials)); //No conversion in get load meta api
      },
      updateRecipe: (credentials: any) => {
        dispatch(recipeUpdate(credentials));
      },
    },
  };
};
const enhance = compose(
  withCardItemSelectionStateManager("Task"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);
export default withTranslation()(enhance(RecipeDetailsPage));
