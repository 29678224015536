import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function courseListRequest(credentials: object): ActionModel {
  return {
    type: types.COURSE_LIST.REQUEST,
    credentials,
  };
}

export function courseListSuccess(data: object): ActionModel {
  return {
    type: types.COURSE_LIST.SUCCESS,
    data,
  };
}

export function courseListFailure(err: object): ActionModel {
  return {
    type: types.COURSE_LIST.FAILURE,
    err,
  };
}

export function courseListUpdate(credentials: object): ActionModel {
  return {
    type: types.COURSE_LIST_UPDATE.REQUEST,
    credentials,
  };
}

export function courseListUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.COURSE_LIST_UPDATE.SUCCESS,
    data,
  };
}

export function courseListUpdateFailure(err: object): ActionModel {
  return {
    type: types.COURSE_LIST_UPDATE.FAILURE,
    err,
  };
}
