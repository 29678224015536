import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { ACCESS_TOKEN,ISSUPERADMIN } from '../../constant/constant';

class AuthorizedUser extends Component<any> {
  render() {
    const { component: Component, pending, logged,superAdmin, ...rest } = this.props
    return (
      <Route {...rest} render={props => {
        if (pending) return <Redirect to="/" />
        return logged && superAdmin === "true"
          ? <Component {...this.props} />
          : <Redirect to="/" />
      }} />
    )
  }
}

const stateToProps = () => ({
  pending: !localStorage.getItem(ACCESS_TOKEN),
  logged: localStorage.getItem(ACCESS_TOKEN),
  superAdmin: localStorage.getItem(ISSUPERADMIN),
})

export default connect(stateToProps)(AuthorizedUser)