export interface StorageModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface StorageState {
  addstoragemodal: boolean;
  deletestoragemodal: boolean;
  editstoragemodal: boolean;
  isFetching: boolean;
  isUpdated: boolean;
  roomList: any;
  roomuuid: String;
  station: string;
  storagename: string;
  storages: Array<any>;
  uuid: string;
}

export const StorageInitState = {
  addstoragemodal: false,
  deletestoragemodal: false,
  editstoragemodal: false,
  isFetching: false,
  isUpdated: false,
  roomList: [],
  roomuuid: "",
  station: "",
  storagename: "",
  storages: [],
  uuid: "",
};

export enum storageType {
  ROOM = "room",
  STORAGE = "storage",
  STORAGE_RACK = "storageracks",
  STORAGE_PLACE = "places",
}
