import "../../styles/page404.scss";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Alert, Col, Container, Row, Table } from "reactstrap";

import { withTranslation } from "react-i18next";
import moment from "moment";
import commonService from "../../services/common.service";
class PaymentTransactionsQualityCheckLast30Days extends Component<any> {
  render() {
    const { t, data } = this.props;
    return (
      <>
        <section className="mt-3 mb-5">
          <h5>1. {t("reports.refundedPaymentTransactions")}</h5>
          {(data?.length === 0 ||
            data?.refundedPaymentTransactions?.length === 0) && (
            <Alert color="info">
              {t("reports.noSuchTransactionsWithinTimeFrame")}
            </Alert>
          )}
          {data?.refundedPaymentTransactions?.length > 0 && (
            <Table bordered>
              <thead>
                <tr className="bg-light">
                  <th>{t("reports.timestamp")}</th>
                  <th>{t("reports.invoiceReceiptKey")}</th>
                  <th>{t("common.currency")}</th>
                  <th>{t("common.amount")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.refundedPaymentTransactions?.map((innerData: any) => {
                  return (
                    <tr>
                      <td>
                        {moment(innerData.timestamp, "x").format("DD-MM-YYYY")}
                      </td>
                      <td>{innerData.bookingKey}</td>
                      <td>{innerData.currency}</td>
                      <td>
                        {" "}
                        {commonService.displayDigitalAmount(innerData.amount)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </section>
        <section className="mt-3 mb-5">
          <h5>2. {t("reports.cancelledPaymentTransactions")}</h5>
          {(data?.length === 0 ||
            data?.cancelledPaymentTransactions?.length === 0) && (
            <Alert color="info">
              {t("reports.noSuchTransactionsWithinTimeFrame")}
            </Alert>
          )}
          {data?.cancelledPaymentTransactions?.length > 0 && (
            <Table bordered>
              <thead>
                <tr className="bg-light">
                  <th>{t("reports.timestamp")}</th>
                  <th>{t("reports.invoiceReceiptKey")}</th>
                  <th>{t("common.currency")}</th>
                  <th>{t("common.amount")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.cancelledPaymentTransactions?.map((innerData: any) => {
                  return (
                    <tr>
                      <td>
                        {moment(innerData.timestamp, "x").format("DD-MM-YYYY")}
                      </td>
                      <td>{innerData.bookingKey}</td>
                      <td>{innerData.currency}</td>
                      <td>
                        {" "}
                        {commonService.displayDigitalAmount(innerData.amount)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </section>
        <section className="mt-3 mb-5">
          <h5>3. {t("reports.pendingPaymentTransactions")}</h5>
          {(data?.length === 0 ||
            data?.pendingPaymentTransactions?.length === 0) && (
            <Alert color="info">
              {t("reports.noSuchTransactionsWithinTimeFrame")}
            </Alert>
          )}
          {data?.pendingPaymentTransactions?.length > 0 && (
            <Table bordered>
              <thead>
                <tr className="bg-light">
                  <th>{t("reports.timestamp")}</th>
                  <th>{t("reports.invoiceReceiptKey")}</th>
                  <th>{t("common.currency")}</th>
                  <th>{t("common.amount")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.pendingPaymentTransactions?.map((innerData: any) => {
                  return (
                    <tr>
                      <td>
                        {moment(innerData.timestamp, "x").format("DD-MM-YYYY")}
                      </td>
                      <td>{innerData.bookingKey}</td>
                      <td>{innerData.currency}</td>
                      <td>
                        {" "}
                        {commonService.displayDigitalAmount(innerData.amount)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </section>
      </>
    );
  }
}
export default withTranslation()(
  withRouter(PaymentTransactionsQualityCheckLast30Days)
);
