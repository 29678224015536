import apiCall from './api.call.config';

async function printer(url: string, params: any) {
    try {
        return await apiCall.get(url + '/' + params.credentials.restaurantuuid + '/printer-interface');
    } catch (e) {
        throw e;
    }
}

async function addPrinter(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url + '/' + params.credentials.restaurantuuid + '/printer-interface', params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function updatePrinter(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.put(url + '/' + params.credentials.restaurantuuid + '/printer-interface/'
            + params.credentials.uuid, params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function removePrinter(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.remove(url + '/' + params.credentials.restaurantuuid + '/printer-interface' + '/' + params.credentials.uuid);
    } catch (e) {
        throw e;
    }
}

export default {
    printer,
    updatePrinter,
    addPrinter,
    removePrinter
}
