import "@mobiscroll/react/dist/css/mobiscroll.min.css";

import mobiscroll from "@mobiscroll/react";

import commonService from "./common.service";

mobiscroll.settings = {
  theme: "ios",
  themeVariant: "light",
};

interface Response {
  ok: boolean;
  status: number;
  data: any;
}

export default function handleResponse(res: Response) {
  if (!res.ok) {
    handleErrorResponse(res, commonService.toastService);
  } else {
    handleSuccessResponse(res, commonService.toastService);
  }

  return res;
}

function handleErrorResponse(res: Response, notifyService: Function): void {
  const { status, data } = res;
  if (!status || !data) return;
  if (status === 400) {
    handle400Error(data, notifyService);
  } else if (status === 401 && data?.error_description) {
    notifyService(data.error_description, "danger");
  } else if ([403, 404, 500].includes(status) && data?.errors) {
    notifyService(data.errors[0], "danger");
  } else {
    notifyService("", "danger", JSON.stringify(data));
  }
}

function handle400Error(data: any, notifyService: Function): void {
  if (data?.error_description) {
    notifyService(data.error_description, "danger");
  } else if (data?.errors) {
    const errorMessage = getErrorMessage(data, notifyService);
    if (errorMessage !== "") {
      notifyService(errorMessage, "danger");
    }
  } else if (data?.flash) {
    notifyService(data?.flash, "danger");
  }
}

function getErrorMessage(data: any, notifyService: Function): string {
  if (typeof data.errors === "string") {
    return flattenObject(data.errors);
  }
  const errorValues = Object.values(data.errors);
  if (errorValues.length === 1 && typeof errorValues[0] === "string") {
    return errorValues[0];
  }
  if (data.errors[0]) {
    return data.errors[0];
  }
  notifyService("", "danger", JSON.stringify(data));

  return "";
}

function handleSuccessResponse(res: Response, notifyService: Function): void {
  const { data } = res;
  if (!data) return;
  if (data?.messages?.length > 0 || (data?.status && data?.flash)) {
    handleMessages(data, notifyService);
  }
  if (data.warnings?.length > 0) {
    handleWarnings(data, notifyService);
  }
}

function handleMessages(data: any, notifyService: Function): void {
  const successMessage = data?.messages ? data.messages.join("") : data?.flash;
  notifyService(successMessage, "success");
}

function handleWarnings(data: any, notifyService: Function): void {
  const warningMessage = data?.warnings.join("");
  if (data.messages?.length > 0) {
    setTimeout(() => {
      notifyService(warningMessage, "warning");
    }, 5000);
  } else {
    notifyService(warningMessage, "warning");
  }
}

function flattenObject(ob: any): string {
  const flattened: { [key: string]: any } = {};
  function recurse(current: any, property: string) {
    if (Object(current) !== current) {
      flattened[property] = current;
    } else {
      for (const p in current) {
        recurse(current[p], property ? `${property}.${p}` : p);
      }
    }
  }
  recurse(ob, "");

  return Object.values(flattened).join(", ");
}
