import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function getOpenTransactionQltyRequest(
  credentials: object
): ActionModel {
  return {
    type: types.REPORT_OPEN_TRANSACTION_QUALITY.REQUEST,
    credentials,
  };
}

export function getOpenTransactionQltySuccess(data: object): ActionModel {
  return {
    type: types.REPORT_OPEN_TRANSACTION_QUALITY.SUCCESS,
    data,
  };
}

export function getOpenTransactionQltyFailure(err: object): ActionModel {
  return {
    type: types.REPORT_OPEN_TRANSACTION_QUALITY.FAILURE,
    err,
  };
}

export function getPaymentTransactionQltyRequest(
  credentials: object
): ActionModel {
  return {
    type: types.REPORT_PAYMENT_TRANSACTION_QUALITY.REQUEST,
    credentials,
  };
}

export function getPaymentTransactionQltySuccess(data: object): ActionModel {
  return {
    type: types.REPORT_PAYMENT_TRANSACTION_QUALITY.SUCCESS,
    data,
  };
}

export function getPaymentTransactionQltyFailure(err: object): ActionModel {
  return {
    type: types.REPORT_PAYMENT_TRANSACTION_QUALITY.FAILURE,
    err,
  };
}

export function getRestaurantClosureRequest(credentials: object): ActionModel {
  return {
    type: types.REPORT_RESTAURANT_CLOSURE.REQUEST,
    credentials,
  };
}

export function getRestaurantClosureSuccess(data: object): ActionModel {
  return {
    type: types.REPORT_RESTAURANT_CLOSURE.SUCCESS,
    data,
  };
}

export function getRestaurantClosureFailure(err: object): ActionModel {
  return {
    type: types.REPORT_RESTAURANT_CLOSURE.FAILURE,
    err,
  };
}

export function getRoomClosureRequest(credentials: object): ActionModel {
  return {
    type: types.REPORT_ROOM_CLOSURE.REQUEST,
    credentials,
  };
}

export function getRoomClosureSuccess(data: object): ActionModel {
  return {
    type: types.REPORT_ROOM_CLOSURE.SUCCESS,
    data,
  };
}

export function getRoomClosureFailure(err: object): ActionModel {
  return {
    type: types.REPORT_ROOM_CLOSURE.FAILURE,
    err,
  };
}

export function printGuestGroupReportRequest(credentials: object): ActionModel {
  return {
    type: types.REPORT_GUEST_GROUP_PRINT.REQUEST,
    credentials,
  };
}

export function printGuestGroupReportSuccess(data: object): ActionModel {
  return {
    type: types.REPORT_GUEST_GROUP_PRINT.SUCCESS,
    data,
  };
}

export function printGuestGroupReportFailure(err: object): ActionModel {
  return {
    type: types.REPORT_GUEST_GROUP_PRINT.FAILURE,
    err,
  };
}
