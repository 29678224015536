import {
  faExternalLinkAlt,
  faFile,
  faFileVideo,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Input } from "reactstrap";
import {
  ITEM_ACTIONS,
  MediaListItemProps,
  MediaListProps,
  MEDIA_TYPE,
} from "../../models/media.manager.model";

function MediaListItem(props: MediaListItemProps) {
  const { item, actions } = props;
  const [selected, setSelection] = useState(false);

  return (
    <li
      className={`b-media-list-item col-lg-2  ${selected ? "selected" : ""} `}
    >
      <div className="flex-fill h-100 b-cursor-p position-relative">
        <div className="b-action-buttons d-flex justify-content-between flex-column position-absolute w-100 h-100">
          <div className="justify-content-between align-items-start  d-flex ">
            <Input
              type="checkbox"
              className="position-relative m-2 b-checkbox"
              onChange={(e: any) => {
                actions(ITEM_ACTIONS.SELECTION, {
                  itemId: item.uuid,
                  checked: e.target.checked,
                });
                setSelection(e.target.checked);
              }}
            />
            <div className="b-icon">
              <FontAwesomeIcon
                style={{ fontSize: 20 }}
                icon={faTrashAlt}
                onClick={() => actions(ITEM_ACTIONS.DELETE, [item.uuid])}
              />
            </div>
          </div>
          <a
            href={item?.permalink || "#"}
            className="align-self-center"
            target="_blank"
          >
            <FontAwesomeIcon
              style={{ fontSize: 30 }}
              icon={faExternalLinkAlt}
            />{" "}
          </a>
          <div></div>
        </div>
        {item.type === MEDIA_TYPE.IMAGE ? (
          <img src={item.thumbnail} className="b-object-fit w-100" />
        ) : (
          <div className="b-placeholder d-flex align-items-center h-100 justify-content-center">
            <FontAwesomeIcon
              color={"#bbb"}
              style={{ fontSize: 60 }}
              icon={item.type === MEDIA_TYPE.VIDEO ? faFileVideo : faFile}
            />
          </div>
        )}
      </div>
    </li>
  );
}

export default MediaListItem;
