import React from 'react';

class ConditionalLinkArea extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this._setFunctionBindings();
    }


    private _setFunctionBindings(): void {
        this.onClick = this.onClick.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
    }

    public onClick(e: any) {
        if (this.props.preventDefault) {
            e.preventDefault()
        }
        if (this.props.stopPropagation) {
            e.stopPropagation();
        }
        if (this.props.onClick) {
            if (this.props.activeLink) {
                this.props.onClick(e);
            }
        }
    }

    public onMouseDown(e: any) {
        if (this.props.preventDefault) {
            e.preventDefault()
        }
        if (this.props.stopPropagation) {
            e.stopPropagation();
        }
        if (this.props.onMouseDown) {
            if (this.props.activeLink) {
                this.props.onMouseDown(e);
            }
        }
    }

    render() {
        // eslint-disable-next-line
        let {preventDefault, stopPropagation, suppressPointerEvents=false, activeLink=true, hide=false, onClick=null, onMouseDown=null, type='div', style, children, ...other} = this.props;
        if (typeof(style) === 'undefined') {
            style={};
        }

        if (activeLink) {
            let {display, pointerEvents, cursor, ...oldstyle} = style;
            if (hide) {
                display = "none";
            }
            style = {display: display, pointerEvents: 'inherit', cursor:'pointer', ...oldstyle}

            let newProps = {onMouseDown:this.onMouseDown, onClick:this.onClick, style:style, ...other}
            return (
                React.createElement(type, newProps, children)
            )
        } else {

            let {display, cursor, pointerEvents, ...oldstyle} = style;
            if (hide) {
                display = "none";
            }
            if (suppressPointerEvents) {
                style = {display: display, cursor: 'inherit', pointerEvents: 'none', ...oldstyle}
            } else {
                style = {display: display, cursor: 'inherit', pointerEvents: 'inherit', ...oldstyle}
            }

            let newProps = {onMouseDown:this.onMouseDown, onClick:this.onClick, style:style, ...other};
            return (
                React.createElement(type, newProps, children)
            )
        }
    }
}

export default ConditionalLinkArea;