import apiCall from './api.call.config';

async function userInvitation(url: string, params: any) {
    try {
        return await apiCall.get(url + '/' + params.credentials.restaurantuuid + '/invitation');
    } catch (e) {
        throw e;
    }
}

async function addUserInvitation(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url + '/' + params.credentials.restaurantuuid + '/invitation', params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function reSendUserInvitation(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url + '/' + params.credentials.restaurantuuid + '/invitation/' + params.credentials.uuid);
    } catch (e) {
        throw e;
    }
}

async function removeUserInvitation(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.remove(url + '/' + params.credentials.restaurantuuid + '/invitation/' + params.credentials.uuid);
    } catch (e) {
        throw e;
    }
}

export default {
    userInvitation,
    reSendUserInvitation,
    addUserInvitation,
    removeUserInvitation
}
