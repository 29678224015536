import React from "react";
import { useTranslation } from "react-i18next";
import {
  Col,
  Button,
  CustomInput,
  Form,
  Row,
  FormGroup,
  Label,
} from "reactstrap";
import commonService from "../../../services/common.service";
import Select from "react-select";

export default function PosSettingsCard(props: any) {
  const getLabelOptions = () => {
    let labelList = props.labelList?.map((label: any) => {
      return {
        value: label.uuid,
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          label?.locales
        )["name"],
      };
    });
    labelList.unshift({
      value: "",
      label: t("common.select"),
    });

    return labelList;
  };
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="child-box mb-3">
        <Form>
          <FormGroup controlId="posDefaultLabel" className="pl-1 row">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {t("common.defaultLabel")}
              </Label>
            </Col>
            <Col sm="6" lg="6">
              <Select
                name="posDefaultLabel"
                value={props.posDefaultLabel}
                onChange={props.handleDefaultLabelChange}
                maxMenuHeight={200}
                options={getLabelOptions()}
                placeholder={t("common.selectLabel")}
              />
            </Col>
          </FormGroup>
          <FormGroup className="m-0 pl-3 row" controlId="showFirstLevelLabels">
            <Col sm="4" lg="4">
              <CustomInput
                id={"showFirstLevelLabels"}
                type="checkbox"
                name={"showFirstLevelLabels"}
                checked={props.showFirstLevelLabels}
                onChange={props.handleChangeSetting}
                label={t("common.showFirstLevelLabels")}
              />
            </Col>
          </FormGroup>
        </Form>
      </div>

      <div className="d-flex justify-content-end mt-3">
        <Button
          className="btn btn-info"
          type="button"
          onClick={props.handleSubmit}
        >
          {t("common.save")}
        </Button>
      </div>
    </>
  );
}
