import { CurrencyModel } from "../../models/currency.model";
import * as types from '../actions/types';

const initialState: CurrencyModel = {
  isFetching: false,
  data: [],
  error: {},
  failure: false,
};
export function currencyReducer(
  state = initialState,
  action: any
): CurrencyModel {
  switch (action.type) {
    case types.CURRENCY.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.CURRENCY.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.CURRENCY.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

