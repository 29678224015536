import "../../styles/page404.scss";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Alert, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { GRAY, RED } from "../../constant/constant";
import ProducedBatchDetailsComponent from "./producedBatchesDetails";

interface ListOfProducedBatchesState {
  isOpen: boolean;
  restaurantUuid: string;
  itemUuid: string;
}

class ListOfProducedBatches extends Component<any> {
  state: ListOfProducedBatchesState;
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      restaurantUuid: "",
      itemUuid: "",
    };
  }

  public navigateToDetailsPage = (itemUuid: string) => {
    window.open(
      `/restaurant/${this.props.match.params.uuid}/reports/produced-batches/details/${itemUuid}`,
      "_blank"
    );
  };

  public openDetailsPage = (itemUuid: string) => {
    this.setState({
      restaurantUuid: this.props.match.params.uuid,
      itemUuid: itemUuid,
    });
    this.toggleModal();
  };

  public toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { t, data } = this.props;
    return (
      <>
        {data?.length === 0 && (
          <Alert color="info">{t("common.noRecords")}</Alert>
        )}
        {data?.length > 0 && (
          <>
            <h5>{t("reports.ProducedBatchesPerTimeframe")}</h5>
            <section className="mt-3 mb-5">
              <Table bordered striped>
                <thead>
                  <tr className="bg-light">
                    <th className="w-24">{t("recipe.recipeName")}</th>
                    <th className="w-13">{t("reports.completedAt")}</th>
                    <th className="w-13">{t("reports.expirationDate")}</th>
                    <th className="w-19">{t("reports.preparedBy")}</th>
                    <th className="w-10">{t("common.quantity")}</th>
                    <th className="w-21">{t("reports.outputQunatityUnit")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((record: any) => {
                    return (
                      <tr>
                        <td>
                          <FontAwesomeIcon
                            onClick={() => this.openDetailsPage(record.uuid)}
                            style={{ color: GRAY, cursor: "pointer" }}
                            icon={faInfoCircle}
                            className="mr-1"
                          />
                          {record.recipeName}
                        </td>
                        <td>{record.completedAt}</td>
                        <td>
                          {new Date(record.expirationDate) < new Date() ? (
                            <span style={{ color: RED }}>
                              {record.expirationDate}
                            </span>
                          ) : (
                            record.expirationDate
                          )}
                        </td>
                        <td>{record.preparedBy}</td>
                        <td>{record.quantity}</td>
                        <td>
                          {record.outputQuantity} {record.outputUnitName}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </section>
          </>
        )}

        <Modal size="xl" isOpen={this.state.isOpen}>
          <ModalHeader toggle={this.toggleModal} className="modal-header">
            {t("reports.batchDetails")}
            <FontAwesomeIcon
              onClick={() => {
                this.navigateToDetailsPage(this.state.itemUuid);
              }}
              style={{ color: GRAY, cursor: "pointer" }}
              icon={faExternalLinkAlt}
              className="p-0 ml-1 mb-1"
              size="xs"
            />
          </ModalHeader>
          <ModalBody>
            <ProducedBatchDetailsComponent
              restaurantUuid={this.state.restaurantUuid}
              itemUuid={this.state.itemUuid}
              showTitle={false}
            />
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(withRouter(ListOfProducedBatches));
