export interface PrinterMessageModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface PrinterMessageState {
  printermessage: any;
  isFetching: boolean;
  uuid: string;
  isUpdated: boolean,
  restaurantuuid: string,
  deletemodal: boolean,
  deleteallmodal: boolean,
  protocolmodal: boolean,
  printermessageuuid: string,
  format: string,
  name: string
}

export const PrinterMessageInitState = {
  isFetching: false,
  printermessage: [],
  restaurantuuid: '',
  isUpdated: false,
  uuid: '',
  deletemodal: false,
  deleteallmodal: false,
  protocolmodal: false,
  printermessageuuid: '',
  format: '',
  name: ''
};
