import _ from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ModalFactory from "react-modal-promise";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { compose } from "redux";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import {
  formData,
  MachineInitState,
  MachineState,
} from "../../models/machine.model";
import {
  machineAdd,
  machineRemove,
  machineRequest,
  machineUpdate,
} from "../../redux/actions/machine.action";
import { roomRequest } from "../../redux/actions/room.action";
import commonService from "../../services/common.service";
import {
  addMachineType,
  getMachineTypes,
  removeMachineType,
  updateMachineType,
} from "../../services/machine.service";
import "../../styles/infrastructure.scss";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import { OpenDeleteModal } from "../card-components/delete-card/delete-modal";
import MachineAddCard from "../card-components/machine-add-card/machine-add-card";
import MachineCard from "../card-components/machine-card/machine-card";
import MachineTypeCard from "../card-components/machine-card/machine-type-card";
import { withCardItemSelectionStateManager } from "../cardItemSelectionManager";
import LoaderComponent from "../loader/loader";
import StaffHeaderComponent from "../navigation/navigation-header/staff-header/staff-header";
import UsersLeftComponent from "../navigation/navigation-left/users-navigation/users-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";

import PrinterService from "../../services/recipe.printer.service";
import { stationsRequest } from "../../redux/actions/stations.action";
import { storageRequest } from "../../redux/actions/storage.action";
import { isEmpty } from "lodash";
class MachineComponent extends Component<any> {
  state: MachineState;
  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.state = MachineInitState;
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.setState({
      restaurantuuid: this.props.match.params.uuid,
    });
    this.props.getMachine({ uuid: this.props.match.params.uuid });
    this.props.getRoom({ restaurantuuid: this.props.match.params.uuid });
    this.props.getStorage({ restaurantuuid: this.props.match.params.uuid });
    this.props.getStations({ uuid: this.props.match.params.uuid });
    this.loadMachineTypes();
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps && !newProps.isFetching) {
      let racks: any = [];
      let palces: any = [];
      let alldata: any = [];
      if (!isEmpty(newProps.storages)) {
        newProps.storages.forEach((storage: any) => {
          racks = racks.concat(storage.storageracks);
        });
        racks.forEach((rack: any) => {
          palces = palces.concat(rack.places);
        });
        alldata = alldata.concat(
          newProps.rooms,
          newProps.stations,
          newProps.machines,
          newProps.storages,
          racks,
          palces
        );
      }
      if (newProps.machine && newProps.room && !newProps.isFetching) {
        this.setState({
          machine: newProps.machine,
          roomList: newProps.room,
          stationlist: newProps.stations,
          machinelist: newProps.machines,
          storagelist: newProps.storages,
          racklist: racks,
          placelist: palces,
          alllists: alldata,
        });
      }

      if (!!this.state.isUpdated && !newProps.isFetching) {
        this.props.getMachine({ uuid: this.props.match.params.uuid });
        this.setState({
          isUpdated: !this.state.isUpdated,
        });
      }

      if (
        (!!newProps.machinsEditStatus.status ||
          !!newProps.machinsAddStatus.status) &&
        !!this.state.isModalOpen &&
        !newProps.isFetching
      ) {
        this.toggleModal();
      }
    }
  }
  public selectFromType = (type: any) => {
    if (type === "room") {
      let data = this.state.roomList.length > 0 ? this.state.roomList : [];
      let option;
      if (!!data) {
        // eslint-disable-next-line
        option = data.map((item: any, i: number) => {
          if (this.state.tovalue !== item.uuid) {
            let locales = commonService.applyLocalization(
              "restaurant",
              "name",
              item.locales
            );
            return (
              <option key={i} value={item.uuid}>
                {locales.name}
              </option>
            );
          }
        });
        return option;
      }
    } else if (type === "station") {
      let data =
        this.state.stationlist.length > 0 ? this.state.stationlist : [];
      let option;
      if (!!data) {
        // eslint-disable-next-line
        option = data.map((item: any, i: number) => {
          if (this.state.tovalue !== item.uuid) {
            let locales = commonService.applyLocalization(
              "restaurant",
              "name",
              item.locales
            );
            return (
              <option key={i} value={item.uuid}>
                {locales.name}
              </option>
            );
          }
        });
        return option;
      }
    } else if (type === "machine") {
      let data =
        this.state.machinelist.length > 0 ? this.state.machinelist : [];
      let option;
      if (!!data) {
        // eslint-disable-next-line
        option = data.map((item: any, i: number) => {
          if (this.state.tovalue !== item.uuid) {
            return (
              <option key={i} value={item.uuid}>
                {item.name}
              </option>
            );
          }
        });
        return option;
      }
    } else if (type === "storage") {
      let data =
        this.state.storagelist.length > 0 ? this.state.storagelist : [];
      let option;
      if (!!data) {
        // eslint-disable-next-line
        option = data.map((item: any, i: number) => {
          if (this.state.tovalue !== item.uuid) {
            return (
              <option key={i} value={item.uuid}>
                {item.name}
              </option>
            );
          }
        });
        return option;
      }
    } else if (type === "rack") {
      let data = this.state.racklist.length > 0 ? this.state.racklist : [];
      let option;
      if (!!data) {
        // eslint-disable-next-line
        option = data.map((item: any, i: number) => {
          if (this.state.tovalue !== item.uuid) {
            return (
              <option key={i} value={item.uuid}>
                {item.name}
              </option>
            );
          }
        });
        return option;
      }
    } else if (type === "place") {
      let data = this.state.placelist.length > 0 ? this.state.placelist : [];
      let option;
      if (!!data) {
        // eslint-disable-next-line
        option = data.map((item: any, i: number) => {
          if (this.state.tovalue !== item.uuid) {
            return (
              <option key={i} value={item.uuid}>
                {item.name}
              </option>
            );
          }
        });
        return option;
      }
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <ModalFactory />
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <StaffHeaderComponent staff={"resources"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <UsersLeftComponent
                display={"machine"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <Link
                to="#"
                color="info"
                className="unit-add "
                onClick={() => this.openModal("create")}
              >
                {t("machine.addMachine")}
              </Link>
              <div className="white-box mb-3">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={`b-cursor-p  ${
                        this.state.activeTab === "machines" ? "active" : ""
                      }`}
                      onClick={() => this.handleTab("machines")}
                    >
                      {t("machine.machines")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`b-cursor-p  ${
                        this.state.activeTab === "machineTypes" ? "active" : ""
                      }`}
                      onClick={() => this.handleTab("machineTypes")}
                    >
                      {t("machine.machineTypes")}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="machines" className="pt-3">
                    {this.machineList(this.state.machine)}
                  </TabPane>
                  <TabPane tabId="machineTypes" className="pt-3">
                    {this.renderMachineType()}
                  </TabPane>
                </TabContent>
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>

        <MachineAddCard
          addMachineToggle={this.toggleModal}
          formData={this.state.formData}
          handleChange={this.handleChange}
          isFetching={this.state.isFetching}
          isModalOpen={this.state.isModalOpen}
          machineTypeOptions={this.getMachineTypeOptions()}
          roomList={this.optionList()}
          saveMachineAdd={this.saveMachineAdd}
          getOptionsForType={this.selectFromType}
        />

        <DeleteModalCard
          isOpen={this.state.open}
          isFetching={!!this.state.isFetching}
          okDelete={this.okDeleteMachine}
          cancelDelete={this.deleteMachineToggle}
        />
      </div>
    );
  }

  getMachineTypeOptions = () => {
    const options: Array<any> = [];
    this.state.machineTypes.forEach((machine: any) => {
      const name: string =
        commonService.applyLocalization("restaurant", "name", machine.locales)
          ?.name || "";
      const option: any = {
        label: name.toLowerCase(),
        labelLower: name.toLowerCase(),
        value: machine.uuid,
      };
      options.push(option);
    });
    return _.sortBy(options, "labelLower");
  };

  public optionList() {
    let dataList = this.state.roomList.length ? this.state.roomList : [];
    let options;
    if (!!dataList) {
      options = dataList.map((option: any, i: number) => {
        let roomLocale = commonService.applyLocalization(
          "restaurant",
          "name",
          option.locales
        );
        return (
          <option key={i} value={option.uuid}>
            {roomLocale.name}
          </option>
        );
      });
      return options;
    }
  }

  public roomName(room: any) {
    let dataList = this.state.roomList.length ? this.state.roomList : [];
    let options;
    if (!!dataList) {
      // eslint-disable-next-line
      options = dataList.map((option: any, i: number) => {
        let roomLocale = commonService.applyLocalization(
          "restaurant",
          "name",
          option.locales
        );
        if (room.room_uuid === option.uuid) {
          return roomLocale.name;
        }
      });
      return options;
    }
  }

  getKeyValuePairs = (machineId: string, isUpdate?: boolean) => {
    if (!this.state.keyValuePairs[machineId] || isUpdate) {
      this.setState({ isFetching: true });
      const payLoad = {
        credentials: {
          resource_type: "machine",
          resource_id: machineId,
          restaurantuuid: this.props.match.params.uuid,
        },
      };
      PrinterService.getKeyValueOptions(RESTAURANT_REQUEST, payLoad)
        .then((response: any) => {
          this.setState((prevState: any) => {
            const keyValuePairs: any = prevState.keyValuePairs;
            keyValuePairs[machineId] = response.data;
            return {
              keyValuePairs: keyValuePairs,
              isFetching: false,
            };
          });
        })
        .catch((error: any) => {});
    }
  };
  handleKeyValueChange = (
    e: any,
    index: number,
    printerId: string,
    area: string
  ) => {
    if (area === "new") {
      if (e.target.name === "newKey") {
        const newKeys: any = this.state.newKey;
        newKeys[printerId] = e.target.value;
        const newKeysError: any = this.state.newKeyError;
        if (!!e.target.value.trim()) {
          newKeysError[printerId] = false;
        }
        this.setState({ newKey: newKeys, newKeyError: newKeysError });
      } else {
        const newValue: any = this.state.newValue;
        newValue[printerId] = e.target.value;
        this.setState({ newValue });
      }
    } else {
      const keyValueFields: Array<any> = e.target.name.split("_");
      const keyValuePairs: any = _.cloneDeep(this.state.keyValuePairs);
      if (keyValueFields[0] === "newKey") {
        keyValuePairs[printerId][index]["key"] = e.target.value;
      } else {
        keyValuePairs[printerId][index]["value"] = e.target.value;
      }
      this.setState({ keyValuePairs });
    }
  };

  handleKeyValues = (area: string, printerId: any, index: number): void => {
    if (area === "delete") {
      const keyValuePairs: any = _.cloneDeep(this.state.keyValuePairs);
      const currentKeyValue: any = keyValuePairs[printerId];

      currentKeyValue[index]["isDeleted"] = true;

      this.setState({
        keyValuePairs,
      });
      return;
    }
    if (this.state.newKey[printerId]?.trim() === "") {
      const errorKeys: any = this.state.newKeyError;
      errorKeys[printerId] = true;
      this.setState({ newKeyError: errorKeys });
    } else {
      if (area === "new") {
        const existKey: any = this.state.keyValuePairs?.[printerId]?.find(
          (item: any) => {
            return item.key === this.state.newKey[printerId];
          }
        );
        if (existKey) {
          const errorKeys: any = this.state.newKeyError;
          errorKeys[printerId] = true;
          this.setState({ newKeyError: errorKeys });
        } else {
          const keyValuePairs: Array<any> = _.cloneDeep(
            this.state.keyValuePairs
          );
          keyValuePairs[printerId].push({
            key: this.state.newKey[printerId],
            value: this.state.newValue[printerId],
            isNew: true,
          });

          const errorKeys: any = this.state.newKeyError;
          const newKey: any = this.state.newKey;
          const newValue: any = this.state.newValue;
          errorKeys[printerId] = false;
          newKey[printerId] = "";
          newValue[printerId] = "";
          this.setState({
            keyValuePairs,
            newKey,
            newValue,
            errorKeys,
          });
        }
      }
    }
  };

  machineList(data: any) {
    let machines;
    if (!!data && data.length > 0) {
      machines = _.orderBy(
        data,
        [
          (item) =>
            commonService
              .applyLocalization("restaurant", "name", item.locales)
              ?.name?.toLowerCase(),
        ],
        ["asc"]
      )?.map((machine: any, i: number) => {
        let machineLocale = commonService.applyLocalization(
          "restaurant",
          "name",
          machine.locales
        );
        return (
          <MachineCard
            key={i}
            getKeyValuePairs={this.getKeyValuePairs}
            handleKeyValueChange={this.handleKeyValueChange}
            handleKeyValues={this.handleKeyValues}
            keyValuePairs={this.state.keyValuePairs[machine.uuid] || []}
            setMachineEditable={this.setMachineEditable.bind(this, machine)}
            machineLocales={machineLocale}
            machine={machine}
            newKey={this.state.newKey[machine.uuid] || ""}
            newKeyError={this.state.newKeyError[machine.uuid]}
            newValue={this.state.newValue[machine.uuid] || ""}
            setMachineDelete={this.setMachineDelete.bind(this, machine)}
            roomName={this.roomName(machine)}
            saveKeyValues={this.saveKeyValues}
          />
        );
      });
      return machines;
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.props.t("common.noRecords")}
        </div>
      );
    }
  }

  saveKeyValues = (machineId: string) => {
    this.setState({ isFetching: true });
    const keyValues: Array<any> = [];
    this.state.keyValuePairs[machineId].forEach((item: any) => {
      if (!item.key) return;
      const data: any = {
        action: item?.isDeleted ? "remove" : "add",
        key: item.key,
        value: item.value,
        resource_type: "machine",
        resource_id: machineId,
      };
      keyValues.push(data);
    });

    if (
      this.state.newKey[machineId] &&
      this.state.newKey[machineId]?.trim() !== ""
    ) {
      const data: any = {
        action: "add",
        key: this.state.newKey[machineId],
        value: this.state.newValue[machineId] || "",
        resource_type: "machine",
        resource_id: machineId,
      };
      keyValues.push(data);
    }

    const payLoad = {
      credentials: {
        restaurantuuid: this.props.match.params.uuid,
        data: { options: keyValues },
      },
    };

    PrinterService.updateKeyValueOptions(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        if (response.data.status) {
          commonService.toastService(response.data.flash, "success");
          this.getKeyValuePairs(machineId, true);
          const newKey: any = this.state.newKey;
          const newValue: any = this.state.newValue;
          newKey[machineId] = "";
          newValue[machineId] = "";
          this.setState({ newKey, newValue });
        } else {
          commonService.toastService(response.data.flash, "danger");
        }
        this.setState({ isFetching: false });
      })
      .catch((error: any) => {});
  };

  private _setFunctionBindings(): void {
    this.handleChange = this.handleChange.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.deleteMachineToggle = this.deleteMachineToggle.bind(this);
    this.okDeleteMachine = this.okDeleteMachine.bind(this);
    this.saveMachineAdd = this.saveMachineAdd.bind(this);
    this.saveMachineEdit = this.saveMachineEdit.bind(this);
    this.optionList = this.optionList.bind(this);
  }

  public okDeleteMachine(): void {
    this.props.removeMachine({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
    });
    this.setState({
      isUpdated: true,
    });
    this.deleteMachineToggle();
  }

  public setMachineDelete(event: any): void {
    this.setState({
      uuid: event.uuid,
    });
    this.deleteMachineToggle();
  }

  public deleteMachineToggle(): void {
    this.setState((prevState: any) => ({
      open: !prevState.open,
    }));
  }

  openModal = (area: string) => {
    if (area === "create") {
      this.setState({
        isModalOpen: !this.state.isModalOpen,
        formData: {
          machineCapacity: "",
          machineName: "",
          machineType: "",
          password: "",
          room: "",
          locations: [{ locationUuid: "", locationType: "" }],
          username: "",
          uuid: "",
        },
        modalType: "create",
        roomUuid: "",
      });
    }
  };

  toggleModal = () => {
    this.setState((prevState: any) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  saveMachineAdd = (formData: formData) => {
    console.log("form data", formData);
    const machineTypes =
      formData.machineType?.map((machine: any) => machine.value) || [];

    const payLoad: any = {
      locales: {
        [this.props.restaurantLang[0].code]: {
          name: formData.machineName,
        },
      },
      capacity: 1,
      locations: formData.locations,
      // location_uuid: formData.locationUuid,
      // location_type: formData.locationType,
    };
    if (formData?.username) payLoad.username = formData.username;
    if (formData?.password) payLoad.password = formData.password;
    if (machineTypes.length > 0) payLoad.types = machineTypes;

    if (formData.uuid === "") {
      this.props.addMachine({
        restaurantuuid: this.state.restaurantuuid,
        data: payLoad,
      });
    } else {
      this.saveMachineEdit(payLoad, formData.uuid);
    }
    this.setState({
      isUpdated: true,
    });
  };

  public handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  loadMachineTypes = () => {
    getMachineTypes(RESTAURANT_REQUEST, {
      credentials: {
        uuid: this.props.match.params.uuid,
      },
    })
      .then((response: any) => {
        this.setState({
          machineTypes: response.data,
          isFetching: false,
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  public saveMachineEdit(formData: formData, machineId: string): void {
    this.props.updateMachine({
      uuid: machineId,
      restaurantuuid: this.state.restaurantuuid,
      data: formData,
    });
  }

  deleteMachineType = (typeId: string) => {
    OpenDeleteModal().then(() => {
      this.setState({
        isFetching: true,
      });
      const restaurantId = this.props.match.params.uuid;
      removeMachineType(RESTAURANT_REQUEST, {
        credentials: {
          uuid: restaurantId,
          data: {
            typeId: typeId,
          },
        },
      })
        .then((response: any) => {
          commonService.toastService(response.data.flash, "success");
          this.loadMachineTypes();
        })
        .catch((error: any) => {
          commonService.toastService("", "danger", JSON.stringify(error.error));
        });
    });
  };

  dispatchMachineTypeSave = (formData: any, restaurantId: string) => {
    addMachineType(RESTAURANT_REQUEST, {
      credentials: {
        uuid: restaurantId,
        data: formData,
      },
    })
      .then((response: any) => {
        commonService.toastService(response.data.flash, "success");
        this.loadMachineTypes();
      })
      .catch((error: any) => {
        this.setState({
          isFetching: false,
        });
        commonService.toastService("", "danger", JSON.stringify(error.error));
      });
  };

  dispatchMachineTypeUpdate = (
    formData: any,
    restaurantId: string,
    typeId: string
  ) => {
    updateMachineType(RESTAURANT_REQUEST, {
      credentials: {
        uuid: restaurantId,
        data: formData,
        typeId: typeId,
      },
    })
      .then((response: any) => {
        commonService.toastService(response.data.flash, "success");
        this.loadMachineTypes();
      })
      .catch((error: any) => {
        this.setState({
          isFetching: false,
        });
        commonService.toastService("", "danger", JSON.stringify(error.error));
      });
  };

  saveMachineType = (data: any) => {
    this.setState({
      isFetching: true,
    });
    const restaurantId = this.props.match.params.uuid;
    const formData = {
      locales: {
        [this.props.restaurantLang[0].code]: {
          name: data.name,
        },
      },
    };
    if (data.id === "newCard") {
      this.dispatchMachineTypeSave(formData, restaurantId);
    } else {
      this.dispatchMachineTypeUpdate(formData, restaurantId, data.id);
    }
    this.props.cardItemSelectionData.deselectCardItem();
  };

  public setMachineEditable(machine: any): void {
    let stationLocale = commonService.applyLocalization(
      "restaurant",
      "name",
      machine.locales
    );
    const types = machine?.types?.split(",");
    const machineTypes: Array<any> = [];
    const machineTypeOptions: Array<any> = this.getMachineTypeOptions();
    types?.forEach((type: any) => {
      const option = machineTypeOptions?.find((m: any) => m.value === type);
      if (option) machineTypes.push(option);
    });
    this.setState({
      formData: {
        isModalOpen: true,
        machineCapacity: machine.capacity,
        machineName: stationLocale.name,
        machineType: machineTypes,
        password: "",
        room: machine.room_uuid,
        locations: machine.locations,
        username: machine.username,
        uuid: machine.uuid,
      },
    });
    this.toggleModal();
  }

  public handleChanges(e: any): void {
    this.setState({
      machineName: e.target.value,
    });
  }

  public handleChange(e: any): void {
    this.setState({
      machineName: e.target.value,
    });
  }

  handleTab = (area: string) => {
    this.setState({
      activeTab: area,
    });
  };

  renderMachineType = () => {
    let machineType: any = _.orderBy(
      this.state.machineTypes,
      [
        (item) =>
          commonService
            .applyLocalization("restaurant", "name", item.locales)
            ?.name?.toLowerCase(),
      ],
      ["asc"]
    )?.map((type: any, i: number) => {
      return (
        <MachineTypeCard
          key={type.uuid}
          cardItemData={type}
          cardItemId={type.uuid}
          onDeleteCard={this.deleteMachineType}
          onSaveCard={this.saveMachineType}
          parentCardItemSelectionData={this.props.cardItemSelectionData}
        />
      );
    });

    // return machineType;
    return (
      <>
        {machineType}
        <MachineTypeCard
          key={"newCard"}
          cardItemData={{ type: "newCard" }}
          cardItemId={"newCard"}
          isNewCardItem={true}
          onDeleteCard={this.deleteMachineType}
          onSaveCard={this.saveMachineType}
          parentCardItemSelectionData={this.props.cardItemSelectionData}
        />
      </>
    );
  };
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.storage.isfetching ||
    state.stations.isFetching ||
    state.machine.isFetching ||
    state.machineremove.isFetching ||
    state.machineadd.isFetching ||
    state.machineupdate.isFetching ||
    state.room.isFetching;
  let failure =
    state.machine.failure ||
    state.machineremove.failure ||
    state.machineadd.failure ||
    state.machineupdate.failure ||
    state.room.failure;
  return {
    room: state.room.data,
    stations: state.stations.data,
    storages: state.storage.data,
    machine: state.machine.data,
    isFetching: isFetching,
    failure: failure,
    machinsEditStatus: state.machineupdate.data,
    machinsAddStatus: state.machineadd.data,
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getMachine: (credentials: any) => {
      dispatch(machineRequest(credentials));
    },
    updateMachine: (credentials: any) => {
      dispatch(machineUpdate(credentials));
    },
    addMachine: (credentials: any) => {
      dispatch(machineAdd(credentials));
    },
    removeMachine: (credentials: any) => {
      dispatch(machineRemove(credentials));
    },
    getRoom: (credentials: any) => {
      dispatch(roomRequest(credentials));
    },
    getStations: (credentials: any) => {
      dispatch(stationsRequest(credentials));
    },
    getStorage: (credentials: any) => {
      dispatch(storageRequest(credentials));
    },
  };
};

const enhance = compose(
  withCardItemSelectionStateManager("machines"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);

export default withTranslation()(enhance(MachineComponent));
