import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowsAltV,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import MaskedInput from "react-maskedinput";
import {
  Col,
  Collapse,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { CARD_SELECTION_STATE, GRAY, WHITE } from "../../../constant/constant";
import { RecipeTasksCardProps, taskEditType } from "../../../models/task.model";
import commonService from "../../../services/common.service";
import {
  CardItemCardComponent,
  CardItemContainer,
} from "../../card-components/card-item";
import CardMedia from "../../card-media/card-media";
import { withCardItemSelectionState } from "../../cardItemSelectionManager";
import ConditionalLinkArea from "../../conditionalLinkArea/conditionalLinkArea";
import InlineEditInput from "../../inline-edit-input/inline-edit-input";
import WarningComment, {
  getMandatoryWarning,
} from "../../warning-comment/warning-comment";
import RecipeTasksInterventionCard from "./recipe-tasks-intervention-card";
import RecipeTasksStepsCard from "./recipe-tasks-steps-card";
import { sort, sortBy, prop } from "ramda";

function RecipeTasksCard(props: RecipeTasksCardProps) {
  const [cardOpen, setCardOpen] = useState<boolean>(false);
  const [interventions, setInterventions] = useState<any>({});
  const [isStepOpen, setStepOpen] = useState<boolean>(true);
  const [machine, setMachine] = useState<string>("");
  const [outputQuantity, setOutputQuantity] = useState("");
  const [outputUnit, setOutputUnit] = useState("");
  const [printLabel, setPrintLabel] = useState<boolean>(false);
  const [shelfLife, setShelfLife] = useState<string>("");
  const [stationName, setStationName] = useState<string>("");
  const [taskDescription, setTaskDescription] = useState<string>("");
  const [taskImage, setTaskImage] = useState<any>("");
  const [taskName, setTaskName] = useState<string>("");
  const [warnings, setWarnings] = useState<any>({});
  const [workingDuration, setWorkingDuration] = useState<string>("");
  const [taskThumbnails, setTaskThumbnails] = useState<string>("");
  let isInterventionDragging: any = useRef(false);

  const { t, i18n } = useTranslation();
  const {
    cardItemData,
    cardItemId,
    cardItemSelectionData,
    getInputReadOnlyLabels,
    getSortedInterventions,
    getUnitDetails,
    getUuidFromMediaManager,
    handleTaskDependencySelection,
    handleTaskInterventionDelete,
    handleTaskInterventionSave,
    handleTaskStepSave,
    isNewCardItem,
    isRecipeEditable,
    itemDraft,
    machinesOptions,
    onCancelEdit,
    onDeleteTaskItem,
    onItemModify,
    onSaveTaskItem,
    restLang,
    selectedTaskDetails,
    selectThisTask,
    stationsOptions,
    unitOptions,
    preselect = false,
    assembleTaskLocales = { title: "", description: "" },
  } = props;

  const isAddTaskWithIng = cardItemId === "newTaskWithIng";

  useEffect(() => {
    if (stationsOptions.length === 2) {
      setStationName(stationsOptions[1].props.value);
    }
  }, [stationsOptions]);

  useEffect(() => {
    if (isAddTaskWithIng) {
      setTaskName(assembleTaskLocales.title);
      setTaskDescription(assembleTaskLocales.description);
    }
  }, [assembleTaskLocales, isAddTaskWithIng]);

  const isEdit: boolean = cardItemSelectionData.selectionState.isBeingEdited;
  let isTaskEdit: boolean = false;
  const isCardSelected: boolean =
    cardItemSelectionData.selectionState.isItemSelected ||
    selectedTaskDetails?.uuid === cardItemId ||
    selectedTaskDetails?.parentTask?.uuid === cardItemId;

  const isCardOpen: boolean = isCardSelected || cardOpen;
  let isTaskDependencyEnabled: boolean =
    cardItemSelectionData.selectionState.mode === "edit";

  if (
    isTaskDependencyEnabled &&
    cardItemSelectionData.selectionState.selectedItem.length > 1
  ) {
    isTaskDependencyEnabled =
      selectedTaskDetails?.parentTask?.uuid ===
      cardItemSelectionData.selectionState.selectedItem[0].id;
  } else if (
    isTaskDependencyEnabled &&
    cardItemSelectionData.selectionState.selectedItem.length === 1
  ) {
    if (
      isTaskDependencyEnabled &&
      cardItemSelectionData.selectionState.selectedItem[0].id === "newTask"
    ) {
      isTaskDependencyEnabled = true;
      isTaskEdit = true;
    } else {
      const isItCurrentTaskEdit: boolean =
        selectedTaskDetails?.uuid ===
        cardItemSelectionData.selectionState.selectedItem[0].id;
      isTaskDependencyEnabled = isItCurrentTaskEdit;
      isTaskEdit = isItCurrentTaskEdit;
    }
  }
  const hasDependencyTask =
    selectedTaskDetails?.dependent_tasks?.filter(
      (dTask: any) => dTask.task_uuid === cardItemId,
    ) || [];

  useEffect(() => {
    if (isInterventionDragging.current || cardItemData?.isDraftEdit) return;

    loadCardItemData();
  }, [cardItemData]);

  useEffect(() => {
    applyWarning();
  }, [
    taskName,
    taskDescription,
    stationName,
    outputQuantity,
    outputUnit,
    isEdit,
  ]);

  const applyWarning = () => {
    const warnings: any = {};
    const nameWarning: any = getMandatoryWarning(taskName || "");
    // const descriptionWarning: any = getMandatoryWarning(taskDescription || "");
    const stationWarning: any = getMandatoryWarning(stationName || "");
    const quantityWarning: any = getMandatoryWarning(outputQuantity || "");
    const unitWarning: any = getMandatoryWarning(outputUnit || "");
    if (nameWarning) warnings.name = nameWarning;
    // if (descriptionWarning) warnings.description = descriptionWarning;
    if (stationWarning) warnings.station = stationWarning;
    if (unitWarning) warnings.unit = unitWarning;
    if (quantityWarning) warnings.quantity = quantityWarning;
    cardItemData.warnings = warnings;
    setWarnings(warnings);
  };

  const clearImage = () => {
    setTaskThumbnails("");
    setTaskImage("");
  };

  const onShowMediaManager = async (isEdit: boolean) => {
    if (!isEdit) return;
    let mediaItem = await getUuidFromMediaManager(taskImage, ["image"]);
    if (mediaItem) {
      setTaskImage(mediaItem.mediaUuid);
    }
  };

  const getFormGroup = (label: string, element: any) => {
    return (
      <FormGroup row className="py-0" key={label}>
        <Label sm={4}>{label}:</Label>
        <Col sm={8} className="align-items-center d-flex">
          {element}
        </Col>
      </FormGroup>
    );
  };

  const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver
      ? "rgb(7 118 124 / 30%)"
      : "rgb(7 118 124 / 15%)",
    padding: "15px 15px 05px 15px",
    minHeight: "60px",
    borderRadius: "6px",
  });

  const getItemStyle = (isDragging: any, draggableStyle: any) => {
    return {
      position: "relative",
      userSelect: "none",
      ...draggableStyle,
      padding: 0,
    };
  };

  const handleCancel = () => {
    cardItemSelectionData.deselectCardItem();
    loadCardItemData();
    onCancelEdit();
  };

  const handleDelete = () => {
    onDeleteTaskItem(cardItemData.uuid);
  };

  const handleEdit = () => {
    onItemModify(cardItemId, {
      action: "edit",
      area: "task",
      parentTaskId: "",
    });
    cardItemSelectionData.editCardItem();
  };

  const handleInterventionDelete = (interventionId: string) => {
    handleTaskInterventionDelete(interventionId, cardItemId);
  };

  const handleInterventionSave = (formData: any, interventionId: string) => {
    handleTaskInterventionSave(formData, interventionId, cardItemId);
  };

  const handleCardSelection = (itemDetails: any, area: string) => {
    selectThisTask(cardItemData.uuid, area, itemDetails);
    setCardOpen(true);
  };

  const handleSave = () => {
    if (Object.keys(warnings).length > 0) return;
    const shelfLifeMs: number = commonService.getTimeInMs(shelfLife);
    const work_durationMs: number = commonService.getTimeInMs(workingDuration);

    const formData: any = {
      buffer_time: isNaN(shelfLifeMs) ? 0 : shelfLifeMs,
      image_uuid: taskImage,
      locales: {
        [restLang]: {
          name: taskName,
          description: taskDescription,
        },
      },
      output_quantity: outputQuantity,
      output_unit_uuid: outputUnit,
      print_label: printLabel,
      station_uuid: stationName,
      work_duration: isNaN(work_durationMs) ? 0 : work_durationMs,
    };
    if (machine !== "cook") {
      formData["machine_uuid"] = machine;
    } else {
      formData["chef_involved"] = true;
    }
    onSaveTaskItem(formData, isNewCardItem, cardItemId);
    // cardItemSelectionData.deselectCardItem();
  };

  const handleSelect = () => {
    selectThisTask(cardItemData.uuid);
    cardItemSelectionData.selectCardItem();
  };

  const handleTaskStepSelection = (stepId: string) => {
    selectThisTask(cardItemData.uuid, taskEditType.STEP, stepId);
  };

  const handleStepSave = (formData: any, stepId: string) => {
    handleTaskStepSave(formData, stepId, cardItemId);
  };

  const loadCardItemData = () => {
    const locales = commonService.applyLocalization(
      "restaurant",
      "name",
      cardItemData?.locales,
    );

    setMachine(
      cardItemData?.chef_involved ? "cook" : cardItemData?.machine_uuid || "",
    );
    setOutputQuantity(cardItemData.output_quantity);

    if (getInputReadOnlyLabels(cardItemData.output_unit_uuid, "unit") !== "")
      setOutputUnit(cardItemData.output_unit_uuid);
    setPrintLabel(cardItemData?.print_label || false);
    setShelfLife(commonService.getMsToDate(cardItemData?.buffer_time || 0));
    setStationName(cardItemData.station_uuid);
    if (!isAddTaskWithIng) {
      setTaskDescription(locales["description"]);
    }
    setTaskImage(cardItemData.image_uuid);
    if (!isAddTaskWithIng) {
      setTaskName(locales["name"]);
    }
    // setTaskName(locales["name"]);
    setWorkingDuration(commonService.getMsToDate(cardItemData.work_duration));
    const interventionsList = getSortedInterventions(props.cardItemData);
    setInterventions(interventionsList);
    setTaskThumbnails(cardItemData?.thumbnail_url || "");
  };

  const onInterventionDragEnd = (result: any) => {
    if (result.destination !== null) {
      const itemsList: any = reorder(
        _.cloneDeep(interventions),
        result.source,
        result.destination,
      );
      itemsList.interventions = itemsList.interventions?.map(
        (intervention: any, index: number) => {
          const update: any = { ...intervention };
          if (index === 0) {
            update.intervention_position = 1;
            delete update?.intervention_parent_uuid;
          } else {
            update.intervention_position = 2;
            update.intervention_parent_uuid =
              itemsList.interventions[index - 1].uuid;
          }
          return update;
        },
      );
      if (_.size(itemsList.endIntervention) > 0) {
        itemsList.endIntervention.intervention_position = 3;
        delete itemsList.endIntervention?.intervention_parent_uuid;
      }

      setInterventions(itemsList);
    }

    isInterventionDragging.current = false;
  };

  const renderInterventions = () => {
    return (
      <div key={"intervention_" + cardItemId}>
        <Row className="app-row-padding">
          <Col>
            <h5>{t("common.interventions")}</h5>
          </Col>
        </Row>
        <div>
          <DragDropContext
            onDragEnd={onInterventionDragEnd}
            onDragStart={() => {
              isInterventionDragging.current = true;
            }}
          >
            {(props.cardItemData?.interventions?.length > 0 ||
              isInterventionDragging.current) && (
              <Droppable
                droppableId={"taskInterventionDroppable_" + cardItemData.uuid}
                isDropDisabled={isTaskEdit}
              >
                {(providedDroppable, snapshot) => (
                  <div
                    ref={providedDroppable.innerRef}
                    {...providedDroppable.droppableProps}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {interventions.interventions?.map(
                      (intervention: any, index: number) => (
                        <React.Fragment
                          key={"taskInterventionDroppable_" + index}
                        >
                          <Draggable
                            key={index}
                            draggableId={
                              "dragableIntervention_" + intervention.uuid
                            }
                            index={index}
                            isDragDisabled={isTaskEdit}
                          >
                            {(providedDraggable, snapshot: any) => (
                              <div
                                ref={providedDraggable.innerRef}
                                {...providedDraggable.draggableProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  providedDraggable.draggableProps.style,
                                )}
                              >
                                <RecipeTasksInterventionCard
                                  key={intervention.uuid}
                                  cardItemData={intervention}
                                  cardItemId={intervention.uuid}
                                  getUuidFromMediaManager={
                                    getUuidFromMediaManager
                                  }
                                  handleTaskInterventionDelete={
                                    handleInterventionDelete
                                  }
                                  handleCardSelection={handleCardSelection}
                                  handleTaskInterventionSave={
                                    handleInterventionSave
                                  }
                                  isNewCardItem={false}
                                  onCancelEdit={onCancelEdit}
                                  onItemModify={onItemModify}
                                  parentCardItemSelectionData={
                                    cardItemSelectionData
                                  }
                                  providedDraggable={providedDraggable}
                                  restLang={restLang}
                                />
                              </div>
                            )}
                          </Draggable>
                        </React.Fragment>
                      ),
                    )}
                    <div>{providedDroppable.placeholder}</div>
                  </div>
                )}
              </Droppable>
            )}
            {!isTaskEdit && (
              <div>
                <RecipeTasksInterventionCard
                  key={"newIntervention_" + cardItemId}
                  cardItemData={{
                    ...itemDraft,
                    ...{ uuid: "newIntervention_" + cardItemId },
                  }}
                  cardItemId={"newIntervention_" + cardItemId}
                  getUuidFromMediaManager={getUuidFromMediaManager}
                  handleCardSelection={handleCardSelection}
                  handleTaskInterventionSave={handleInterventionSave}
                  isNewCardItem={true}
                  lastIntervention={
                    interventions?.interventions?.length
                      ? interventions?.interventions[
                          interventions?.interventions?.length - 1
                        ]
                      : undefined
                  }
                  onCancelEdit={onCancelEdit}
                  onItemModify={onItemModify}
                  parentCardItemSelectionData={cardItemSelectionData}
                  providedDraggable={null}
                  restLang={restLang}
                />
              </div>
            )}

            <Droppable
              droppableId={"taskInterventionEndDroppable_" + cardItemData.uuid}
              isDropDisabled={
                _.size(interventions?.endIntervention) > 0 || isTaskEdit
              }
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    ...getListStyle(snapshot.isDraggingOver),
                    ...{
                      display:
                        isTaskEdit &&
                        _.size(interventions?.endIntervention) === 0
                          ? "none"
                          : "block",
                    },
                  }}
                >
                  {_.size(interventions?.endIntervention) > 0 && (
                    <Draggable
                      draggableId={
                        "dragableIntervention_" +
                        interventions.endIntervention?.uuid
                      }
                      index={interventions.interventions.length}
                      key={interventions.interventions.length + 1}
                      isDragDisabled={isTaskEdit}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <RecipeTasksInterventionCard
                            key={"endIntervention" + cardItemId}
                            cardItemData={interventions.endIntervention}
                            cardItemId={
                              interventions.endIntervention?.uuid || ""
                            }
                            getUuidFromMediaManager={getUuidFromMediaManager}
                            handleTaskInterventionDelete={
                              handleInterventionDelete
                            }
                            handleCardSelection={handleCardSelection}
                            handleTaskInterventionSave={handleInterventionSave}
                            onCancelEdit={onCancelEdit}
                            onItemModify={onItemModify}
                            parentCardItemSelectionData={cardItemSelectionData}
                            providedDraggable={provided}
                            restLang={restLang}
                          />
                        </div>
                      )}
                    </Draggable>
                  )}

                  <div>{provided.placeholder}</div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <Row></Row>
      </div>
    );
  };

  const taskSteps = sortBy(prop('position'), cardItemData?.task_step || [])
  const renderSteps = () => {
    return (
      <div key={"steps_" + cardItemId}>
        <Row className="app-row-padding">
          <Col>
            <h5
              onClick={() => toggleStepsCollapse()}
              className="cursor-pointer"
            >
              {t("common.steps")}
            </h5>
          </Col>
        </Row>
        <Collapse isOpen={isStepOpen} className="px-2">
          {taskSteps.map((step: any, index: number) => (
            <RecipeTasksStepsCard
              key={index}
              position={index}
              cardItemData={step}
              cardItemId={"step" + step.uuid}
              getUuidFromMediaManager={getUuidFromMediaManager}
              handleCardSelection={handleCardSelection}
              handleTaskStepSelection={handleTaskStepSelection}
              handleTaskStepSave={handleStepSave}
              isNewCardItem={false}
              onCancelEdit={onCancelEdit}
              onItemModify={onItemModify}
              parentCardItemSelectionData={cardItemSelectionData}
              restLang={restLang}
            />
          ))}

          <div>
            <RecipeTasksStepsCard
              position={(cardItemData?.task_step || []).length}
              cardItemData={{
                ...itemDraft,
                ...{ uuid: "newStep_" + cardItemId },
              }}
              cardItemId={"newStep_" + cardItemId}
              key={"newStep_" + cardItemId}
              getUuidFromMediaManager={getUuidFromMediaManager}
              handleCardSelection={handleCardSelection}
              handleTaskStepSelection={handleCardSelection}
              handleTaskStepSave={handleStepSave}
              isNewCardItem={true}
              onCancelEdit={onCancelEdit}
              onItemModify={onItemModify}
              parentCardItemSelectionData={cardItemSelectionData}
              restLang={restLang}
            />
          </div>
        </Collapse>
      </div>
    );
  };

  const reorder = (interventionsData: any, source: any, destination: any) => {
    const result: any = interventionsData;
    const startIndex: number = source.index;
    const endIndex: number = destination.index;
    const startArea: string = source.droppableId.split("_")?.[0];
    if (
      startArea !== "taskInterventionDroppable" &&
      source.droppableId !== destination.droppableId
    ) {
      const removed = interventionsData.endIntervention;
      result.interventions.splice(endIndex, 0, removed);
      result.endIntervention = {};
    } else if (
      startArea === "taskInterventionDroppable" &&
      source.droppableId === destination.droppableId
    ) {
      const [removed] = result.interventions.splice(startIndex, 1);
      result.interventions.splice(endIndex, 0, removed);
    } else if (
      startArea === "taskInterventionDroppable" &&
      source.droppableId !== destination.droppableId
    ) {
      const [removed] = result.interventions.splice(startIndex, 1);
      result.endIntervention = removed;
    }
    return result;
  };

  const toggleTaskDependencySelection = (currentState: boolean) => {
    handleTaskDependencySelection(cardItemId, currentState);
  };

  const toggleCollapse = (collapse: boolean) => {
    if (isCardSelected && !cardItemSelectionData.selectionState.isBeingEdited) {
      handleCancel();
      setCardOpen(false);
    } else {
      setCardOpen(!collapse);
    }
  };

  const toggleStepsCollapse = () => {
    setStepOpen(!isStepOpen);
  };



  return (
    <CardItemContainer className={""}>
      {!cardItemSelectionData.cardItem.isNewCardItem ? (
        <div className={"task-type collapseIcon"}>
          {!isCardOpen ? (
            <span onClick={() => toggleCollapse(isCardOpen)}>
              <FontAwesomeIcon style={{ fontSize: 20 }} icon={faCaretDown} />
            </span>
          ) : (
            <span onClick={() => toggleCollapse(isCardOpen)}>
              <FontAwesomeIcon style={{ fontSize: 20 }} icon={faCaretUp} />
            </span>
          )}
        </div>
      ) : (
        ""
      )}
      <CardItemCardComponent
        cardItemSelectionData={cardItemSelectionData}
        className={
          "bf-recipe-ing-task-card  ingredient-type ingredient assigned"
        }
        cancelCallback={handleCancel}
        deleteCallback={handleDelete}
        editCallback={handleEdit}
        isNewCardItem={isNewCardItem}
        readOnly={isRecipeEditable}
        selectCallback={handleSelect}
        saveCallback={handleSave}
        newTaskWithIng={isAddTaskWithIng}
      >
        <div className="d-flex justify-content-between">
          <div className="flex-fill">
            <>
              <div className={`bf-title ${isEdit ? "mb-2 mr-2" : ""}`}>
                <WarningComment
                  data={warnings?.name || null}
                  displayType={"bottom"}
                >
                  <InlineEditInput
                    key={"taskName" + cardItemId}
                    id={"taskName" + cardItemId}
                    name={"taskName" + cardItemId}
                    value={taskName}
                    editElement={Input}
                    edit={isEdit}
                    style={{ display: "block", maxWidth: "100%" }}
                    propsEdit={{
                      key: "taskName" + cardItemId,
                      type: "text",
                      placeholder: t("task.whatIsPreparedTask"),
                    }}
                    onChange={(name: string, val: any) => setTaskName(val)}
                  />
                </WarningComment>
              </div>
              <div className={"inline-input-group bf-quantity"}>
                <WarningComment
                  data={warnings?.quantity || null}
                  displayType={"bottom"}
                  validationStyle="iconOnly"
                  key="outputQuantity"
                >
                  <InlineEditInput
                    id={"outputQuantity" + cardItemId}
                    key={"outputQuantity" + cardItemId}
                    name={"outputQuantity" + cardItemId}
                    value={outputQuantity}
                    readOnlyContentCallback={(quantity: string) =>
                      commonService.getSignificantDigit(parseFloat(quantity)) ||
                      0
                    }
                    editElement={Input}
                    edit={isEdit}
                    style={{ display: "inline" }}
                    styleEdit={{
                      textAlign: "right",
                      width: "7em",
                      marginRight: "15px",
                    }}
                    styleReadOnly={{ textAlign: "left", marginRight: "5px" }}
                    propsEdit={{
                      key: "outputQuantity" + cardItemId,
                      type: "text",
                      placeholder: t("common.quantity"),
                    }}
                    onChange={(name: string, val: any) =>
                      setOutputQuantity(val)
                    }
                    pattern={"^(?:[1-9]\\d*|0)?(?:\\.\\d*)?$"} // positive decimal, including empty and point
                  />
                </WarningComment>
                <WarningComment
                  data={warnings?.unit || null}
                  displayType={"bottom"}
                  key="outputUnit"
                >
                  <InlineEditInput
                    id={"unit" + cardItemId}
                    name={"unit" + cardItemId}
                    key={"unit" + cardItemId}
                    value={outputUnit}
                    editElement={CustomInput}
                    edit={isEdit}
                    style={{ display: "inline" }}
                    // styleEdit={{}}
                    propsEdit={{
                      key: "unit" + cardItemId,
                      type: "select",
                      placeholder: t("unit.units"),
                      children: unitOptions,
                    }}
                    onChange={(name: string, val: any) => setOutputUnit(val)}
                    readOnlyContentCallback={(id: string) =>
                      getInputReadOnlyLabels(id, "unit")
                    }
                  />
                </WarningComment>
                {cardItemData.isLastTask &&
                  cardItemData.showLastTaskQuantityWarning &&
                  !isEdit && (
                    <WarningComment
                      data={{
                        type: "attention",
                        text: t("common.calculationQtyNoMatch"),
                      }}
                    />
                  )}
              </div>{" "}
              {isCardOpen && (
                <div className="mt-2">
                  <InlineEditInput
                    key={"description" + cardItemId}
                    id={"description" + cardItemId}
                    name={"description" + cardItemId}
                    value={taskDescription}
                    editElement={Input}
                    edit={isEdit}
                    propsEdit={{
                      key: "description" + cardItemId,
                      type: "textarea",
                      placeholder: t("questions.HowProductPrepared"),
                    }}
                    oldClassName="mb-2"
                    styleReadOnly={{ minHeight: 100, width: "100%" }}
                    onChange={(name: string, val: any) =>
                      setTaskDescription(val)
                    }
                    styleEdit={{ minHeight: 100, width: "100%" }}
                  />
                  <div>
                    <CardMedia
                      clearImage={() => clearImage()}
                      mediaUuid={taskImage}
                      onClick={() => {
                        onShowMediaManager(isEdit);
                      }}
                      activeLink={isEdit}
                      entityType="media"
                      thumbnail={taskThumbnails}
                    />
                  </div>
                  {getFormGroup(
                    t("station.station"),
                    <WarningComment
                      data={warnings?.station || null}
                      displayType={"bottom"}
                      containerClassName={"flex-fill"}
                    >
                      <InlineEditInput
                        id={"station" + cardItemId}
                        key={"station" + cardItemId}
                        name={"station" + cardItemId}
                        value={stationName}
                        editElement={CustomInput}
                        edit={isEdit}
                        propsEdit={{
                          key: "station" + cardItemId,
                          type: "select",
                          placeholder: t("station.station"),
                          children: stationsOptions,
                        }}
                        styleContainer={{ width: "100%", display: "block" }}
                        readOnlyContentCallback={(id: string) =>
                          getInputReadOnlyLabels(id, "station")
                        }
                        onChange={(name: string, val: any) =>
                          setStationName(val)
                        }
                      />
                    </WarningComment>
                  )}
                  {getFormGroup(
                    t("common.resource"),
                    <InlineEditInput
                      id={"resource" + cardItemId}
                      name={"resource" + cardItemId}
                      key={"resource" + cardItemId}
                      value={machine}
                      editElement={CustomInput}
                      edit={isEdit}
                      propsEdit={{
                        key: "resource" + cardItemId,
                        type: "select",
                        placeholder: t("common.resource"),
                        children: machinesOptions,
                      }}
                      readOnlyContentCallback={(id: string) =>
                        getInputReadOnlyLabels(id, "machine", {
                          chef_involved: cardItemData?.chef_involved,
                        })
                      }
                      styleContainer={{ width: "100%", display: "block" }}
                      onChange={(name: string, val: any) => setMachine(val)}
                    />
                  )}
                  {getFormGroup(
                    t("common.workingDuration"),

                    <InlineEditInput
                      id={"workDuration" + cardItemId}
                      name={"workDuration" + cardItemId}
                      key={"workDuration" + cardItemId}
                      value={workingDuration}
                      editElement={MaskedInput}
                      edit={isEdit}
                      readOnlyContentCallback={commonService.getDateCut}
                      propsEdit={{
                        key: "workDuration" + cardItemId,
                        type: "text",
                        className: "masked-input",
                        mask: "11d 11:11:11",
                      }}
                      onChange={(name: string, val: any) =>
                        setWorkingDuration(val)
                      }
                      styleContainer={{ width: "100%", display: "block" }}
                    />
                  )}
                  {getFormGroup(
                    t("common.shelfLife"),

                    <InlineEditInput
                      id={"shelflife" + cardItemId}
                      name={"shelflife" + cardItemId}
                      key={"shelflife" + cardItemId}
                      value={shelfLife}
                      editElement={MaskedInput}
                      edit={isEdit}
                      readOnlyContentCallback={commonService.getDateCut}
                      propsEdit={{
                        key: "shelflife" + cardItemId,
                        type: "text",
                        className: "masked-input",
                        mask: "11d 11:11:11",
                      }}
                      onChange={(name: string, val: any) => setShelfLife(val)}
                      styleContainer={{ width: "100%", display: "block" }}
                      // onChange={this.props.onContentEditChange}
                      // conversionCallback={this.cleanDateTimeString}
                    />
                  )}
                  {!isNewCardItem && (
                    <>
                      {renderSteps()}
                      {renderInterventions()}
                    </>
                  )}
                  {}
                  <Label
                    className={`mb-2 px-4 ${
                      isEdit ? "pointer-cursor" : " b-cursor-none"
                    }`}
                    for="printLabel"
                    onClick={() => isEdit && setPrintLabel(!printLabel)}
                  >
                    <Input
                      name="printLabel"
                      type={"checkbox"}
                      checked={printLabel}
                      disabled={!isEdit}
                    />
                    {t("common.printLabel")}
                  </Label>
                </div>
              )}
            </>
          </div>
          {!isNewCardItem &&
            !isCardSelected &&
            _.size(selectedTaskDetails) > 0 && (
              <div style={{ float: "right", display: "flex" }}>
                <ConditionalLinkArea
                  type="span"
                  activeLink={true}
                  data-toggle="tooltip"
                  data-placement="right"
                  onClick={() => {
                    isTaskDependencyEnabled &&
                      toggleTaskDependencySelection(
                        _.size(hasDependencyTask) > 0
                      );
                  }}
                >
                  <FontAwesomeIcon
                    className={"ingredient-type assigned checkbox"}
                    style={{ fontSize: 40, marginRight: 10, marginTop: 5 }}
                    icon={
                      _.size(hasDependencyTask) > 0 ? faCheckSquare : faSquare
                    }
                  />
                </ConditionalLinkArea>
              </div>
            )}
        </div>
      </CardItemCardComponent>
    </CardItemContainer>
  );
}

export default withCardItemSelectionState(
  "RecipeTasksCard",
  1,
)(RecipeTasksCard);
