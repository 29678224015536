import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Label,
  Row,
  UncontrolledCollapse,
} from "reactstrap";
import { GREEN } from "../../constant/constant";
import {
  country,
  ProfileInitState,
  ProfileState,
} from "../../models/profile.model";
import { changePasswordRequest } from "../../redux/actions/change.password.action";
import {
  profileRequest,
  profileUpdate,
} from "../../redux/actions/profile.action";
import { languageRequest } from "../../redux/actions/language.action";
import { userLanguageUpdate } from "../../redux/actions/user.language.action";
import { upload } from "../../redux/actions/upload.action";
import commonService from "../../services/common.service";
import validationService from "../../services/validation.service";
import "../../styles/profile.scss";
import ImageModalCard from "../card-components/image-card/image-card";
import ProfileCard from "../card-components/profile-card/profile-card";
import ProfileEditModalCard from "../card-components/profile-edit-card/profile-edit-card";
import ProfileSettingsCard from "../card-components/profile-settings-card/profile-settings-card";
import LoaderComponent from "../loader/loader";
import ProfileHeaderComponent from "../navigation/navigation-header/profile-header/profile-header";
import ProfileNavLeftComponent from "../navigation/navigation-left/profile-navigation/profile-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import { ISSUPERADMIN } from "../../constant/constant";
import RestaurantLangCard from "../card-components/restaurant-language/restaurant-language";

import i18next from "i18next";
import i18n from "../../i18n";
import { setLanguage } from "../../config/lang.config";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

class ProfileComponent extends Component<any> {
  private userLanguages: any[] = ["de", "en"];
  state: ProfileState;
  countryarray: { countrycode: string; countryname: string }[];
  constructor(props: any) {
    super(props);
    this.state = ProfileInitState;
    this.countryarray = country;
    this._setFunctionBindings();
  }

  componentDidMount() {
    document.body.className = "dark-theme";
    this.props.getLanguages();
    setLanguage(localStorage.getItem("USERLANGUAGE"));
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
      // eslint-disable-next-line
      if (
        !!newProps.profile.data ||
        (!!newProps.uploads.data && !newProps.isFetching && !newProps.failure)
      ) {
        let selectedcountry: any;
        selectedcountry = this.countryarray.find(
          (item) => item.countrycode === newProps.profile.data.country_code
        );
        this.setState({
          upload: newProps.uploads.data,
          profile: newProps.profile.data,
          countryname: selectedcountry.countryname,
        });
        localStorage.setItem(
          ISSUPERADMIN,
          !!newProps.profile.data.is_super_admin
            ? newProps.profile.data.is_super_admin
            : false
        );
      }
      if (
        !!newProps.languages &&
        !newProps.isFetching &&
        !this.state.isUpdated
      ) {
        let globalLanguages = newProps?.languages?.filter(
          (item: any) =>
            newProps.profile?.data?.languages?.findIndex(
              (element: any) => element.uuid === item.uuid
            ) === -1
        );
        let languages = [
          ...(newProps?.profile?.data?.languages || []),
          ...globalLanguages,
        ];

        if (this.state.currentLanguage !== newProps.profile?.data?.languages) {
          this.setState({
            currentLanguage: newProps.profile?.data?.languages,
          });
          setLanguage(newProps.profile?.data?.languages);
        }

        languages.forEach((item: any) => {
          if (
            newProps.profile?.data?.languages?.findIndex(
              (element: any) => element.uuid === item.uuid
            ) !== -1
          )
            item.isChecked = true;
          else item.isChecked = false;
        });
        this.setState({
          languages: languages,
        });
      }
      if (!!this.state.isUpdated && !newProps.failure && !newProps.isFetching) {
        this.props.getProfile();
        this._setStates("isUpdated", false);
      }

      if (!!this.state.editmodal && !newProps.isFetching) {
        this.editToggle();
      }
      if (
        !!newProps.changePasswordStatus.status &&
        !newProps.failure &&
        !newProps.isFetching
      ) {
        this.setState({
          current_password: "",
          new_password: "",
          confirm_password: "",
        });
      }
    }
  }

  changeOrder = (result: any) => {
    const { languages } = this.state;
    const [removed] = languages.splice(result?.source?.index, 1);
    languages.splice(result?.destination?.index, 0, removed);
    this.setState({
      languages: languages,
    });
  };

  handleCheckBoxChange = (item: any) => {
    item.isChecked = !item.isChecked;
    let { languages } = this.state;
    this.setState({
      languages: languages,
    });
  };

  updateLanguages = () => {
    this.props.updateLanguage({
      useruuid: this.state.profile?.uuid,
      data: {
        languages: this.state.languages
          .filter((item: any) => item.isChecked)
          .map((element: any) => element.uuid),
      },
    });
    this.setState({
      isUpdated: true,
    });
  };

  disableButton = () => {
    let selectedLang = this.state.languages.filter(
      (item: any) => item.isChecked
    );
    if (
      (selectedLang.every((item: any, index: any) =>
        this.props.profile?.data?.languages.find(
          (element: any, number: any) =>
            element.uuid === item.uuid && index === number
        )
      ) &&
        selectedLang.length === this.props.profile?.data?.languages?.length) ||
      selectedLang.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  render() {
    const { t } = this.props;
    const { languages } = this.state;
    let passwordMisMatch;
    if (
      !!validationService.confirmPassword(
        this.state.new_password,
        this.state.confirm_password
      ) ||
      (this.state.confirm_password === "" && this.state.new_password === "")
    ) {
      passwordMisMatch = "";
    } else {
      passwordMisMatch = (
        <Label className="changePassValidation">
          {t("password.passwordMisMatch")}
        </Label>
      );
    }
    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <ProfileHeaderComponent home={"home"} />
          <Row className="dark-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <ProfileNavLeftComponent display={"profile"} />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <div className="white-box mb-3">
                <Form name="form">
                  <span style={{ marginTop: 20 }}>
                    <div style={{ float: "right", display: "flex" }}>
                      <span
                        onClick={this.setEditable.bind(
                          this,
                          this.state.profile
                        )}
                        className="pointer-cursor"
                        data-toggle="tooltip"
                        data-placement="left"
                        title={t("common.edit")}
                      >
                        <FontAwesomeIcon
                          style={{ color: GREEN, fontSize: 20, margin: 5 }}
                          icon={faEdit}
                        />
                      </span>
                    </div>
                  </span>
                  <Row
                    className="app-row-padding"
                    data-toggle="tooltip"
                    data-placement="left"
                    title={t("recipe.recipeDetails")}
                  >
                    <Col
                      style={{ paddingLeft: 0, paddingRight: 0, marginTop: 0 }}
                    >
                      <h4>{t("common.personalInformation")}</h4>
                    </Col>
                  </Row>
                  <ProfileCard
                    state={this.state}
                    countryname={this.state.countryname}
                    setImageEdit={this.setImageEdit.bind(
                      this,
                      this.state.profile
                    )}
                  />
                </Form>
              </div>
              <div className="white-box mb-3">
                <h4>{t("password.passwordSettings")}</h4>
                <Label className="storage-place-header" id={"profile-settings"}>
                  {t("password.changePassword")}
                </Label>
                <UncontrolledCollapse
                  className="toggler"
                  toggler={"profile-settings"}
                >
                  <ProfileSettingsCard
                    state={this.state}
                    changePasswordHandle={this.changePasswordHandle}
                    handleConfirmPassword={this.handleConfirmPassword}
                    passwordMisMatch={passwordMisMatch}
                    validationService={validationService}
                    changePassword={this.props.changePassword}
                  />
                </UncontrolledCollapse>
              </div>
              <div className="white-box mb-3">
                <h4>{t("common.languageSettings")}</h4>
                <RestaurantLangCard
                  changeOrder={this.changeOrder}
                  handleCheckBoxChange={this.handleCheckBoxChange}
                  languages={this.state.languages}
                  selectedLang={this.state.selectedLang}
                />
                <div className="d-flex justify-content-end">
                  <Button
                    color="info"
                    disabled={this.disableButton()}
                    onClick={this.updateLanguages}
                  >
                    {t("common.save")}
                  </Button>{" "}
                </div>
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent></NavigationRightComponent>
            </Col>
          </Row>
        </div>

        {/* Image Upload Modal */}

        <ImageModalCard
          state={this.state}
          editImageToggle={this.editImageToggle}
          handleImageChange={this.handleImageChange}
          isFetching={this.props.isFetching}
          saveImage={this.saveImage}
        />

        {/*  Edit Profile Modal */}

        <ProfileEditModalCard
          state={this.state}
          editToggle={this.editToggle}
          handleChange={this.handleChange}
          saveEdit={this.saveEdit}
          OPTIONS={this.countryList()}
        />
      </div>
    );
  }

  public countryList() {
    let units;
    units = this.countryarray.map(
      (country: { countrycode: string; countryname: string }, i: number) => {
        return (
          <option value={country.countrycode} key={i}>
            {country.countryname}
          </option>
        );
      }
    );
    return units;
  }

  public changePasswordHandle(event: any): void {
    this._setStates(event.target.name, event.target.value);
  }

  public handleConfirmPassword = (event: any) => {
    if (event.target.name === "new_password") {
      if (event.target.value !== this.state.confirm_password) {
        this.setState({
          new_password: event.target.value,
          validate: false,
        });
      } else {
        this.setState({
          new_password: event.target.value,
          validate: true,
        });
      }
    } else {
      if (event.target.value !== this.state.new_password) {
        this.setState({
          confirm_password: event.target.value,
          validate: true,
        });
      } else {
        this.setState({
          confirm_password: event.target.value,
          validate: true,
        });
      }
    }
  };

  public setImageEdit(event: any): void {
    this.setEditState(event);
    this.editImageToggle();
  }

  public editToggle(): void {
    this.setState((prevState: any) => ({
      editmodal: !prevState.editmodal,
    }));
  }

  private setEditState(event: any) {
    this.setState({
      first_name: event.first_name,
      last_name: event.last_name,
      city: event.city,
      country_code: event.country_code,
      email: event.email,
    });
  }

  public saveEdit(): void {
    this.props.updateProfile({
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      city: this.state.city,
      country_code: this.state.country_code,
    });
    this._setStates("isUpdated", true);
  }

  public setEditable(event: any): void {
    this.setEditState(event);
    this.editToggle();
  }

  public editImageToggle(): void {
    this.setState((prevState: any) => ({
      imagemodal: !prevState.imagemodal,
      filedata: [],
    }));
  }

  public handleImageChange(e: any): void {
    let files = e.target.files[0];
    this._setStates("filedata", files);
  }

  public saveImage(): void {
    let formData = commonService.createImageFormData({
      object_type: "user",
      object_uuid: this.state.profile.uuid,
      images: this.state.filedata,
      entity_type: "user_media",
      entity_uuid: this.state.profile.uuid,
    });
    this.props.upload(formData);
    this._setStates("isUpdated", true);
    this.editImageToggle();
  }

  // Function binding

  private _setFunctionBindings(): void {
    this.setEditable = this.setEditable.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._setStates = this._setStates.bind(this);
    this.changePasswordHandle = this.changePasswordHandle.bind(this);
    this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
    this.saveEdit = this.saveEdit.bind(this);
    this.editToggle = this.editToggle.bind(this);
    this.editImageToggle = this.editImageToggle.bind(this);
    this.saveImage = this.saveImage.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  public handleChange(e: { target: { name: string; value: string } }): void {
    this._setStates(e.target.name, e.target.value);
  }
}
const mapStateToProps: any = (state: any) => {
  return {
    languages: state.languages.data,
    uploads: state.upload.data,
    profile: state.profile.data,
    language: localStorage.getItem("LANG"),
    isFetching:
      state.profile.isFetching ||
      state.changepassword.isFetching ||
      state.upload.isFetching ||
      state.userLanguageUpdate.isFetching,
    failure:
      state.profile.failure ||
      state.changepassword.failure ||
      state.upload.failure,
    changePasswordStatus:
      state.changepassword.data || state.userLanguageUpdate.failure,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    changePassword: (credentials: any) => {
      dispatch(
        changePasswordRequest({
          current_password: credentials.current_password,
          new_password: credentials.new_password,
        })
      );
    },
    getProfile: () => {
      dispatch(profileRequest());
    },
    getLanguages: (credentials: any) => {
      dispatch(languageRequest(credentials));
    },
    updateProfile: (credentials: any) => {
      dispatch(profileUpdate(credentials));
    },
    updateLanguage: (credentials: any) => {
      dispatch(userLanguageUpdate(credentials));
    },

    upload: (credentials: any) => {
      dispatch(upload(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileComponent))
);
