import i18next from "i18next";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
  PrinterProtocolInitState,
  PrinterProtocolState,
} from "../../models/printer.protocol.model";
import {
  printerProtocolAdd,
  printerProtocolRemove,
  printerProtocolRequest,
  printerProtocolUpdate,
} from "../../redux/actions/printer.protocol.action";
import "../../styles/printer.scss";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import PrinterProtocolCard from "../card-components/printer-protocol-card/printer-protocol-card";
import ProtocolAddCard from "../card-components/protocol-add-card/protocol-add-card";
import ProtocolEditCard from "../card-components/protocol-edit-card/protocol-edit-card";
import LoaderComponent from "../loader/loader";
import SettingsHeaderComponent from "../navigation/navigation-header/settings-header/settings-header";
import SettingsNavComponent from "../navigation/navigation-left/settings-navigation/settings-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";

class PrinterProtocolComponent extends Component<any> {
  state: PrinterProtocolState;
  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.state = PrinterProtocolInitState;
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <SettingsHeaderComponent settings={"settings"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <SettingsNavComponent
                display={"protocol"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <Link
                to="#"
                color="info"
                className="printer-add"
                onClick={this.addPrinterToggle}
              >
                {t("printer.addPrinterProtocol")}
              </Link>
              <div className="white-box mb-3 shadow">
                <h4>{t("printer.printerProtocols")}</h4>
                {this.printerList(this.state.printer)}
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>

        {/*  Add Printer Protocol Modal */}

        <ProtocolAddCard
          state={this.state}
          addPrinterToggle={this.addPrinterToggle}
          handleChange={this.handleChange}
          savePrinterAdd={this.savePrinterAdd}
        />

        {/*  Edit Printer Protocol Modal */}

        <ProtocolEditCard
          state={this.state}
          editPrinterToggle={this.editPrinterToggle}
          handleChange={this.handleChange}
          savePrinterEdit={this.savePrinterEdit}
        />

        {/* Dlete Printer Protocol Modal*/}

        <DeleteModalCard
          isOpen={this.state.deletemodal}
          isFetching={!!this.state.isFetching}
          okDelete={this.okDeletePrinter}
          cancelDelete={this.deletePrinterToggle}
        />
      </div>
    );
  }

  public printerList(printerData: any) {
    let printer;
    if (!!printerData && printerData.length > 0) {
      printer = printerData.map((locales: any, i: number) => {
        return (
          <div className="white-box mb-3 shadow" key={i}>
            <PrinterProtocolCard
              printer={locales}
              setPrinterEditable={this.setPrinterEditable.bind(this, locales)}
              setPrinterDelete={this.setPrinterDelete.bind(this, locales)}
            ></PrinterProtocolCard>
          </div>
        );
      });
      return printer;
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.props.t("common.noRecords")}
        </div>
      );
    }
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.printerProtocol({
      restaurantuuid: this.props.match.params.uuid,
    });
    this._setStates("restaurantuuid", this.props.match.params.uuid);
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
    }
    if (!!this.state.isUpdated && !newProps.failure && !newProps.isFetching) {
      this.props.printerProtocol({
        restaurantuuid: this.props.match.params.uuid,
      });
      this._setStates("isUpdated", false);
    }
    if (
      !!newProps.printerprotocol &&
      !newProps.isFetching &&
      !newProps.failure
    ) {
      this._setStates("printer", newProps.printerprotocol);
    }
  }

  // Function binding

  private _setFunctionBindings(): void {
    this.deletePrinterToggle = this.deletePrinterToggle.bind(this);
    this.okDeletePrinter = this.okDeletePrinter.bind(this);
    this.addPrinterToggle = this.addPrinterToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.savePrinterAdd = this.savePrinterAdd.bind(this);
    this.editPrinterToggle = this.editPrinterToggle.bind(this);
    this.savePrinterEdit = this.savePrinterEdit.bind(this);
  }

  public handleChange(e: any): void {
    this._setStates(e.target.name, e.target.value);
  }

  public editPrinterToggle(): void {
    this.setState((prevState: any) => ({
      editmodal: !prevState.editmodal,
    }));
  }

  public setPrinterEditable(event: any): void {
    this.setState({
      uuid: event.uuid,
      name: event.name,
      format: event.format,
    });
    this.editPrinterToggle();
  }

  public savePrinterEdit(): void {
    this.props.updatePrinterProtocol({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
      data: {
        name: this.state.name,
        format: this.state.format,
      },
    });
    this._setStates("isUpdated", true);
    this.editPrinterToggle();
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  public addPrinterToggle(): void {
    this.setState((prevState: any) => ({
      addmodal: !prevState.addmodal,
      name: "",
      format: "",
    }));
  }

  public savePrinterAdd(): void {
    this.props.addPrinterProtocol({
      restaurantuuid: this.state.restaurantuuid,
      data: {
        name: this.state.name,
        format: this.state.format,
      },
    });
    this._setStates("isUpdated", true);
    this.addPrinterToggle();
  }

  public setPrinterDelete(event: any): void {
    this._setStates("uuid", event.uuid);
    this.deletePrinterToggle();
  }

  public deletePrinterToggle(): void {
    this.setState((prevState: any) => ({
      deletemodal: !prevState.deletemodal,
    }));
  }

  public okDeletePrinter(): void {
    this.props.removePrinterProtocol({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
    });
    this._setStates("isUpdated", true);
    this.deletePrinterToggle();
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.printerprotocol.isFetching ||
    state.printerprotocoladd.isFetching ||
    state.printerprotocolremove.isFetching ||
    state.printerprotocolupdate.isFetching;
  let failure =
    state.printerprotocol.failure ||
    state.printerprotocoladd.failure ||
    state.printerprotocolremove.failure ||
    state.printerprotocolupdate.failure;
  return {
    printerprotocol: state.printerprotocol.data,
    isFetching: isFetching,
    failure: failure,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    printerProtocol: (credentials: any) => {
      dispatch(printerProtocolRequest(credentials));
    },
    removePrinterProtocol: (credentials: any) => {
      dispatch(printerProtocolRemove(credentials));
    },
    addPrinterProtocol: (credentials: any) => {
      dispatch(printerProtocolAdd(credentials));
    },
    updatePrinterProtocol: (credentials: any) => {
      dispatch(printerProtocolUpdate(credentials));
    },
  };
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(PrinterProtocolComponent))
);
