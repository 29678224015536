import "../../../../styles/navigation-left.scss";

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import i18next from "i18next";
import { withTranslation } from "react-i18next";

class PosLeftComponent extends Component<any> {
  render() {
    const { t } = this.props;
    return (
      <div className="b-sticky-below-header b-side-navbar">
        <Link to={`/restaurant/${this.props.restaurantuuid}/pos`}>
          <Button
            className={
              this.props.display === "pos" ? "btn-dark mb-3" : "btn-light mb-3"
            }
          >
            {t("common.orders")}
          </Button>
        </Link>
      </div>
    );
  }
}

export default withTranslation()(withRouter(PosLeftComponent));
