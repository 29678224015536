import { call, put, takeLatest } from 'redux-saga/effects';

import { UPLOAD_FILE } from '../config/api.config';
import * as types from '../redux/actions/types';
import { uploadFailure, uploadSuccess } from '../redux/actions/upload.action';
import handleResponse from '../services/response.service';
import uploadFile from '../services/upload.service';


const uploadFilesCall = (params: any) => uploadFile.uploadFile(UPLOAD_FILE, params);

function* handleUpload(params: object) {
    try {
        let result;
        result = yield call(uploadFilesCall, params);
        yield handleUpload200(result);
    } catch (error) {
        throw error;
    }
}

const handleUpload200 = function* handleUpload200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(uploadSuccess(result.data));
    } else {
        yield put(uploadFailure(result.data));
        handleResponse(result);
    }
}

export default function* uploadSaga() {
    yield takeLatest(types.UPLOAD.REQUEST, handleUpload);
}
