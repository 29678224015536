import React from "react";
import LoaderComponent from "../loader/loader";
import OperateHeaderComponent from "../../components/navigation/navigation-header/operate-header/operate-header";
import OperateLeftComponent from "../../components/navigation/navigation-left/operate-navigation/operate-navigation";
import { Col, Row } from "reactstrap";
import ProducedBatchDetailsComponent from "./producedBatchesDetails";

interface ReportDetailsComponentProps {
  restaurantUuid: string;
  report: string;
  itemUuid: string;
}
const ReportDetailsComponent: React.FC<ReportDetailsComponentProps> = (
  props
) => {
  const { restaurantUuid, report, itemUuid } = props;

  return (
    <>
      <div className="container-fluid">
        <LoaderComponent display={false} />
        <OperateHeaderComponent operate={"operate"} />
        <Row className="main light-theme">
          <Col xl={2} lg={3} className="hide-left-max">
            <OperateLeftComponent
              display={"report-details"}
              restaurantuuid={restaurantUuid}
            />
          </Col>
          <Col xl={8} lg={6}>
            <div className="white-box mb-3">
              {report === "produced-batches" ? (
                <ProducedBatchDetailsComponent
                  restaurantUuid={restaurantUuid}
                  itemUuid={itemUuid}
                  showTitle={true}
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportDetailsComponent;
