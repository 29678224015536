import "../../../styles/navigation-header.scss";

import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import HeaderCard from "../header-card/header-card";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import commonService from "../../../services/common.service";
import { USER_RIGHTS } from "../../../constant/constant";

export default function RightHeaderCard(props: any) {
  return (
    <div
      className="d-flex justify-content-end"
      style={{ display: !!localStorage.getItem("WEBVIEW") ? "none" : "block" }}
    >
      <div className="d-flex align-items-center">
        {props.hasAccess && (
          <div
            className="settings-icon mr-2 b-cursor-p"
            onClick={props.goToSettings}
          >
            <FontAwesomeIcon
              style={{
                color: `${
                  props.settings === "settings" ? "#8fd5e0" : "#FFFFFF"
                }`,
                fontSize: 28,
              }}
              icon={faCog}
            />
          </div>
        )}
        <HeaderCard
          restaurantName={props.restaurantName}
          RESTAURANT={props.RESTAURANT}
          modalToggle={props.modalToggle}
          goToProfile={props.goToProfile}
        />
      </div>
    </div>
  );
}
