import { RestaurantDetailModel } from "../../models/restaurant.detail.model";
import * as types from "../actions/types";

const initialState: RestaurantDetailModel = {
  isFetching: false,
  data: {},
  error: {},
  failure: false,
  
};
export default function restaurantDetailReducer(
  state = initialState,
  action: any
): RestaurantDetailModel {
  switch (action.type) {
    case types.RESTAURANT_DETAIL.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.RESTAURANT_DETAIL.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.RESTAURANT_DETAIL.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
