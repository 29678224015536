import apiCall from './api.call.config';

async function inventory(url: string, params: any) {
    try {
        return await apiCall.get(url + '/' + params.credentials.restaurantuuid + '/inventory');
    } catch (e) {
        throw e;
    }
}

export default { inventory }
