import { LoginModel } from '../../models/login.model';
import * as types from '../actions/types';

const initialState: LoginModel = {
  isAuthenticated: false,
  isFetching: false,
  data: {},
  error: {},
  failure: false
};

export default function loginReducer(state = initialState, action: any): LoginModel {
  switch (action.type) {
    case types.LOGIN.REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        failure: false,
        error: {}
      };
    case types.LOGIN.SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        data: action,
        failure: false,
        error: {}
      };
    case types.LOGIN.FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        failure: true,
        error: action.err
      };
    default:
      return state;
  }
}
