import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import handleResponse from "../../services/response.service";
import LoaderComponent from "../../components/loader/loader";

const LabelPrint: React.FC<{
  payload: any;
  printFn: Function;
}> = ({ payload, printFn }) => {
  const { t } = useTranslation();
  const [loding, setLoading] = useState<boolean>(true);
  const [printContent, setPrintContent] = useState<string>("");
  useEffect(() => {
    printFn(RESTAURANT_REQUEST, payload).then((response: any) => {
      const responseData: any = handleResponse(response);
      if (responseData.ok && response?.data.status === true) {
        setPrintContent(response.data.content);
      }
      setLoading(false);
    });
  }, [payload, printFn]);
  const style = `<style> body{ background: none;}</style>`;
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: style }} />
      <LoaderComponent display={loding} />
      <div dangerouslySetInnerHTML={{ __html: printContent }} />
    </>
  );
};

export default LabelPrint;
