import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  CustomInput,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { DARK_GRAY, GRAY, GREEN } from "../../constant/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row, Table } from "reactstrap";
import {
  faCopy,
  faEdit,
  faStop,
  faTrashAlt,
  faPlus,
  faSave,
  faUndo,
  faCheck,
  faCheckCircle,
  faMinusCircle,
  faTh,
  faThLarge,
} from "@fortawesome/free-solid-svg-icons";
import image from "../../assets/images/icon/image.png";
import "../../styles/ingredients.scss";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";

import commonService from "../../services/common.service";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import {
  ServiceSetCardInitState,
  ServiceSetCardState,
} from "../../models/serviceSetNew.model";
import DragAndDropWrapper from "../drag-drop-component";
import ServiceSetLabelCard from "./ServiceSetLabelCard";
import {
  withCardItemSelectionState,
  withCardItemSelectionStateManager,
} from "../cardItemSelectionManager";
import { compose } from "redux";
import _ from "lodash";

class ServiceSetCard extends Component<any> {
  state: ServiceSetCardState;
  activeChannels: Array<any>;
  activeRestaurants: Array<any>;
  activeInventoryAccess: boolean;
  constructor(props: any) {
    super(props);
    this.state = ServiceSetCardInitState;
    this.activeChannels = [];
    this.activeRestaurants = [];
    this.activeInventoryAccess = false;
  }

  componentDidMount() {
    this.handleChannelChanges = this.handleChannelChanges.bind(this);
    this.getChannelOptions = this.getChannelOptions.bind(this);
    const selectedChannels = this.getChannelOptions(this.props.channels);
    const selectedRestaurants = this.getActiveRestaurants();
    const inventoryAccessToClient = this.props.itemData?.inventory_access;
    this.activeChannels = selectedChannels;
    this.activeRestaurants = selectedRestaurants;
    this.activeInventoryAccess = inventoryAccessToClient;
    this.setState({
      selectedChannels,
      selectedRestaurants,
      inventoryAccessToClient,
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      !!this.props.hasLabelUpdate &&
      prevProps.hasLabelUpdate !== this.props.hasLabelUpdate
    ) {
      this.handleCardComponent();
    }
    if (!_.isEqual(prevProps.labels, this.props.labels)) {
      this.setState({
        labels: this.props.labels,
        labelsUuids: this.props.labels.map((label: any) => label.label_uuid),
      });
    }
    if (
      !_.isEqual(prevProps.itemData?.customers, this.props.itemData?.customers)
    ) {
      const selectedRestaurants = this.getActiveRestaurants();
      this.activeRestaurants = selectedRestaurants;
      this.setState({
        selectedRestaurants,
      });
    }
    if (
      prevProps.itemData?.inventory_access !==
      this.props.itemData?.inventory_access
    ) {
      const inventoryAccessToClient = this.props.itemData?.inventory_access;
      this.activeInventoryAccess = inventoryAccessToClient;
      this.setState({
        inventoryAccessToClient,
      });
    }
  }

  getActiveRestaurants = () => {
    const customers: Array<any> = [];
    this.props.restaurantList?.forEach((rest: any) => {
      const selectedItem = this.props.itemData.customers?.includes(rest.uuid);
      const option = {
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          rest?.locales
        ).name,
        value: rest.uuid,
      };
      if (selectedItem) customers.push(option);
    });
    return customers;
  };

  getChannelList(data: any, area: string = "channel") {
    let items: string = "";
    if (!!!data || data.length === 0) {
      items =
        area === "channel"
          ? this.props.t("restaurant.noChannelSelected")
          : this.props.t("restaurant.noRestaurantSelected");
    } else {
      items = data
        .reduce(
          (accumulator: string, item: any) => accumulator + item.label + ", ",
          ""
        )
        .replace(/,\s$/, "");
    }
    return items;
  }

  getChannelOptions(data: Array<any>, area: string = "channel") {
    const options: any = [];
    _.cloneDeep(data).map((data: any) => {
      let label: string = "";
      let value: any = {};
      if (area === "channel") {
        label =
          this.props.channelList.filter((channel: any) => {
            return data === channel.uuid;
          })[0]?.name || "";
        value = data;
      } else if (area === "restaurant") {
        label = commonService.applyLocalization(
          "restaurant",
          "name",
          data?.locales
        ).name;
        value = data.uuid;
      }
      let newOption: any = {
        value: value,
        label,
      };
      options.push(newOption);
    });
    return options;
  }

  getChildrenProps = (card: any, index: any) => {
    return {
      indx: index,
      key: card.uuid,
      cardItemData: card,
      cardItemId: card.uuid,
      recipeuuid: card.recipe_uuid,
    };
  };

  handleCancel = (area: string) => {
    if (area === "channel") {
      this.setState({
        isChannelEditable: false,
        selectedChannels: this.activeChannels,
      });
    }
    if (area === "restaurant") {
      this.setState({
        isRestaurantEditable: false,
        selectedRestaurants: this.activeRestaurants,
      });
    }
    if (area === "inventoryAccess") {
      this.setState({
        isInventoryAccessEditable: false,
        inventoryAccessToClient: this.activeInventoryAccess,
      });
    }
  };
  handleCardComponent = () => {
    this.props.cardItemSelectionData.deselectCardItem();
  };
  handleChannelChanges(value: any) {
    this.setState({ selectedChannels: value });
  }
  handleEdit = (area: string) => {
    const isDetailPage = this.props?.isDetailPage || false;
    const isServiceItemsUpdate = this.props?.isServiceItemsUpdate || false;

    if (
      !isDetailPage ||
      !isServiceItemsUpdate ||
      (isDetailPage &&
        isServiceItemsUpdate &&
        window.confirm(this.props.t("common.routeChangeAlert")) &&
        !!this.props?.isServiceItemsUpdate)
    ) {
      if (area === "channel") {
        this.activeChannels = this.state.selectedChannels;
        this.setState({ isChannelEditable: true });
      }
      if (area === "restaurant") {
        this.activeRestaurants = this.state.selectedRestaurants;
        this.setState({ isRestaurantEditable: true });
      }
      if (area === "inventoryAccess") {
        this.activeInventoryAccess = this.state.inventoryAccessToClient;
        this.setState({ isInventoryAccessEditable: true });
      }
    }
  };
  handleRestaurantChanges = (value: any) => {
    this.setState({ selectedRestaurants: value });
  };

  handleInventoryAccessChange = (e: any) => {
    this.setState({
      inventoryAccessToClient: !this.state.inventoryAccessToClient,
    });
  };

  public onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const itemsList = this.reorder(
      this.state.labels,
      result.source.index,
      result.destination.index
    );
    this.setState({
      labels: itemsList,
    });
    this.props.onLabelUpdate(itemsList, this.props.itemData.uuid, "sort");
  };
  public reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  setKey = (key: any) => {
    this.setState({
      activeTab: key,
    });
    this.props.setSelectedItem(this.props.itemData.uuid);
  };

  render() {
    const { t } = this.props;

    const itemLocale = commonService.applyLocalization(
      "restaurant",
      "name",
      this.props.itemData.locales
    );
    const itemTimings = this.props.itemTimings;
    let vat =
      Object.keys(this.props.vatList).length > 0
        ? this.props.vatList?.filter(
            (vat: any) => vat.uuid === this.props.itemData?.vat_class_uuid
          )[0]
        : [];
    const vatClass = commonService.applyLocalization(
      "restaurant",
      "name",
      vat?.locales
    )["name"];
    return (
      <section className="border rounded p-3 mb-3">
        <header className="d-flex justify-content-between">
          <div className="col-left d-flex">
            <h6 style={{ color: DARK_GRAY, paddingLeft: 10 }}>
              <FontAwesomeIcon
                className={`color-box-one border-0 mr-1 ${
                  !!this.props.itemData.status
                    ? "text-success"
                    : "text-secondary"
                } `}
                style={{
                  fontSize: 16,
                }}
                icon={faThLarge}
              />
              {itemLocale?.name}
            </h6>
          </div>
          <div className="col-right">
            <span
              onClick={() => {
                this.props.onItemEdit(this.props.itemData.uuid);
              }}
              className="pointer-cursor"
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.edit")}
            >
              <FontAwesomeIcon
                style={{ color: GREEN, margin: 5 }}
                icon={faEdit}
              />
            </span>
            <span
              onClick={this.props.onItemDelete}
              className="pointer-cursor"
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.delete")}
            >
              <FontAwesomeIcon
                style={{ color: GRAY, margin: 5 }}
                icon={faTrashAlt}
              />
            </span>
          </div>
        </header>
        <div className="recipe-dtls p-0 row">
          <div className="col-12 col-sm">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={
                    this.state.activeTab === "details"
                      ? "active"
                      : "" + " b-cursor-p"
                  }
                  onClick={() => {
                    this.setKey("details");
                  }}
                >
                  {t("common.details")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    this.state.activeTab === "labels"
                      ? "active"
                      : "" + " b-cursor-p"
                  }
                  onClick={() => {
                    this.setKey("labels");
                  }}
                >
                  {t("label.labels")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    this.state.activeTab === "time"
                      ? "active"
                      : "" + " b-cursor-p"
                  }
                  onClick={() => {
                    this.setKey("time");
                  }}
                >
                  {t("serviceSet.timeAndLocation")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    this.state.activeTab === "price"
                      ? "active"
                      : "" + " b-cursor-p"
                  }
                  onClick={() => {
                    this.setKey("price");
                  }}
                >
                  {t("serviceSet.priceSettings")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    this.state.activeTab === "channel"
                      ? "active"
                      : "" + " b-cursor-p"
                  }
                  onClick={() => {
                    this.setKey("channel");
                  }}
                >
                  {t("restaurant.channels")}
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="details">
                <div className="b-minHeight-100">
                  <div className="recipe-description mt-3 mb-3 pl-1">
                    {itemLocale?.description}
                    <div className="my-2">
                      {t("common.status")} :{" "}
                      <Badge
                        color={
                          !!this.props.itemData.status ? "success" : "secondary"
                        }
                      >
                        {!!this.props.itemData.status
                          ? t("common.active")
                          : t("common.inactive")}
                      </Badge>
                    </div>
                  </div>

                  {!this.props?.isDetailPage && (
                    <Row className="justify-content-end p-3">
                      <Button
                        className="btn btn-info"
                        onClick={() => {
                          this.props.onMoveToDetailPage(
                            this.props.itemData.uuid
                          );
                        }}
                      >
                        {t("common.viewMoreDetails")}
                      </Button>
                    </Row>
                  )}
                </div>
              </TabPane>
              <TabPane tabId="labels">
                <div className="b-minHeight-100 mt-3">
                  <div>
                    {this.state.labels.length === 0 ? (
                      <div className="p-2 text-center">
                        {t("common.noRecords")}
                      </div>
                    ) : (
                      <DragAndDropWrapper
                        getChildrenProps={this.getChildrenProps}
                        onDragEnd={this.onDragEnd}
                        itemsList={this.state.labels}
                      >
                        <ServiceSetLabelCard
                          cardItemData={this.props.cardItemData}
                          cardItemId={this.props.cardItemId}
                          cardItemSelectionData={
                            this.props.cardItemSelectionData
                          }
                          isNewCardItem={false}
                          labelOptions={this.props.labelOptions}
                          onSaveCard={this.props.onLabelSave}
                          onDeleteCard={this.props.onLabelDelete}
                          parentCardItemSelectionData={
                            this.props.cardItemSelectionData
                          }
                          savedLabels={this.state.labelsUuids}
                          serviceSetId={this.props.itemData.uuid}
                        />
                      </DragAndDropWrapper>
                    )}
                    <div className="mt-3">
                      <ServiceSetLabelCard
                        cardItemData={{ type: "newCard" }}
                        cardItemId={"newCardLabel"}
                        cardItemSelectionData={this.props.cardItemSelectionData}
                        isNewCardItem={true}
                        labelOptions={this.props.labelOptions}
                        onSaveCard={this.props.onLabelSave}
                        onDeleteCard={this.props.onLabelDelete}
                        parentCardItemSelectionData={
                          this.props.cardItemSelectionData
                        }
                        savedLabels={this.state.labelsUuids}
                        serviceSetId={this.props.itemData.uuid}
                      />
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="time">
                <div className="recipe-description mt-2 mb-3 b-minHeight-100">
                  {itemTimings.length === 0 ? (
                    <div className="d-flex align-items-center mt-3 ">
                      {t("time.noTimeAndLocation")}
                      <Button
                        className="ml-2 px-2 py-1"
                        color="primary"
                        size="sm"
                        onClick={() => {
                          this.props.setSelectedItem(this.props.itemData.uuid);
                          this.props.addRoomToggle();
                        }}
                      >
                        {t("common.addOne")}
                      </Button>
                    </div>
                  ) : (
                    <>
                      <Label
                        className="d-flex align-center font-weight-bold mt-2"
                        onClick={this.props.addRoomToggle}
                      >
                        {t("serviceSet.timeAndLocation")} :
                        <Button
                          className="mx-2 p-0 px-1"
                          size="sm"
                          color="outline-primary"
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{ fontSize: 16 }}
                          />
                        </Button>
                      </Label>

                      <Table responsive className="table-striped text-nowrap">
                        <thead>
                          <tr>
                            <th>{t("common.days")}</th>
                            <th>{t("time.time")}</th>
                            <th>{t("room.rooms")}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {itemTimings?.map((element: any, index: any) => (
                            <tr key={index}>
                              <td>
                                {this.props.renderDays(element.week_days)}
                              </td>
                              <td>
                                {element.start_time}-{element.end_time}
                              </td>
                              <td>{this.props.renderRooms(element?.room)}</td>
                              <td className="text-right">
                                <span
                                  className="pointer-cursor"
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  title={t("common.edit")}
                                >
                                  <FontAwesomeIcon
                                    style={{
                                      color: GREEN,
                                    }}
                                    icon={faEdit}
                                    className="m-1"
                                    onClick={() => {
                                      this.props.setSelectedItem(
                                        this.props.itemData.uuid
                                      );
                                      this.props.handleTimeEdit(
                                        element,
                                        this.props.ItemData?.uuid
                                      );
                                    }}
                                  />
                                </span>

                                <span
                                  onClick={() => {
                                    this.props.setSelectedItem(
                                      this.props.itemData.uuid
                                    );
                                    this.props.handleTimeUpdate(
                                      "delete",
                                      element
                                    );
                                  }}
                                  className="pointer-cursor"
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  title={t("common.delete")}
                                >
                                  <FontAwesomeIcon
                                    style={{
                                      color: GRAY,
                                    }}
                                    className="m-1"
                                    icon={faTrashAlt}
                                  />
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  )}
                </div>
              </TabPane>
              <TabPane tabId="price">
                <div className="row pl-2  b-minHeight-100">
                  <div className="col-3 recipe-description mt-3 mb-3">
                    {t("serviceSet.defaultVATClass")}
                  </div>
                  <div className="col-6 recipe-description mt-3 mb-3">
                    {" "}
                    {vatClass}
                  </div>{" "}
                  <div className="col-3 recipe-description mt-3 mb-3 text-right">
                    <span
                      onClick={() => {
                        this.props.onItemEdit(this.props.itemData.uuid);
                      }}
                      className="pointer-cursor"
                      data-toggle="tooltip"
                      data-placement="left"
                      title={t("common.edit")}
                    >
                      <FontAwesomeIcon
                        style={{ color: GREEN, margin: 5 }}
                        icon={faEdit}
                      />
                    </span>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="channel">
                <div className="row pl-2 mb-2 ">
                  <div className="col-3 recipe-description mt-3">
                    {t("restaurant.channels")}
                  </div>
                  <div className="col-6 recipe-description mt-3">
                    {!this.state.isChannelEditable &&
                      this.getChannelList(this.state.selectedChannels)}
                    {this.state.isChannelEditable && (
                      <Select
                        value={this.state.selectedChannels}
                        onChange={this.handleChannelChanges}
                        options={this.getChannelOptions(
                          this.props.restaurantChannelList
                        )}
                        isMulti
                        maxMenuHeight={200}
                        placeholder={t("common.select")}
                      />
                    )}
                  </div>
                  <div className="col-3 recipe-description mt-3 text-right">
                    {!this.state.isChannelEditable && (
                      <span
                        onClick={() => this.handleEdit("channel")}
                        className="pointer-cursor"
                        data-toggle="tooltip"
                        data-placement="left"
                        title={t("common.cancel")}
                      >
                        <FontAwesomeIcon
                          style={{ color: GREEN, margin: 5 }}
                          icon={faEdit}
                        />
                      </span>
                    )}
                    {this.state.isChannelEditable && (
                      <div>
                        <span
                          onClick={() => this.handleCancel("channel")}
                          className="pointer-cursor"
                          data-toggle="tooltip"
                          data-placement="left"
                          title={t("common.cancel")}
                        >
                          <FontAwesomeIcon
                            style={{ color: GREEN, margin: 5 }}
                            icon={faUndo}
                          />
                        </span>
                        <span
                          onClick={() => {
                            this.setState({
                              isChannelEditable: false,
                            });
                            this.props.saveChannels(
                              this.state.selectedChannels
                            );
                          }}
                          className="pointer-cursor"
                          data-toggle="tooltip"
                          data-placement="left"
                          title={t("common.save")}
                        >
                          <FontAwesomeIcon
                            style={{ color: GREEN, margin: 5 }}
                            icon={faSave}
                          />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row pl-2  ">
                  <div className="col-3 recipe-description mt-3">
                    {t("restaurant.restaurants")}
                  </div>
                  <div className="col-6 recipe-description mt-3">
                    {!this.state.isRestaurantEditable &&
                      this.getChannelList(
                        this.state.selectedRestaurants,
                        "restaurant"
                      )}
                    {this.state.isRestaurantEditable && (
                      <Select
                        name="restaurant"
                        value={this.state.selectedRestaurants}
                        onChange={this.handleRestaurantChanges}
                        options={this.getChannelOptions(
                          this.props.restaurantList,
                          "restaurant"
                        )}
                        isMulti
                        maxMenuHeight={200}
                        placeholder={t("common.selectClientRestaurant")}
                      />
                    )}
                  </div>
                  <div className="col-3 recipe-description mt-3 text-right">
                    {!this.state.isRestaurantEditable && (
                      <span
                        onClick={() => this.handleEdit("restaurant")}
                        className="pointer-cursor"
                        data-toggle="tooltip"
                        data-placement="left"
                        title={t("common.cancel")}
                      >
                        <FontAwesomeIcon
                          style={{ color: GREEN, margin: 5 }}
                          icon={faEdit}
                        />
                      </span>
                    )}
                    {this.state.isRestaurantEditable && (
                      <div>
                        <span
                          onClick={() => this.handleCancel("restaurant")}
                          className="pointer-cursor"
                          data-toggle="tooltip"
                          data-placement="left"
                          title={t("common.cancel")}
                        >
                          <FontAwesomeIcon
                            style={{ color: GREEN, margin: 5 }}
                            icon={faUndo}
                          />
                        </span>
                        <span
                          onClick={() => {
                            this.setState({
                              isRestaurantEditable: false,
                            });
                            this.props.saveRestaurants(
                              this.state.selectedRestaurants,
                              this.props.itemData.uuid
                            );
                          }}
                          className="pointer-cursor"
                          data-toggle="tooltip"
                          data-placement="left"
                          title={t("common.save")}
                        >
                          <FontAwesomeIcon
                            style={{ color: GREEN, margin: 5 }}
                            icon={faSave}
                          />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row pl-2">
                  <div className="col-3 mt-3"></div>
                  <div className="col-7 mt-3">
                    <CustomInput
                      id={"inventoryAccessToClient"}
                      type="checkbox"
                      name={"inventoryAccessToClient"}
                      checked={this.state.inventoryAccessToClient}
                      onClick={(e: any) => {
                        if (this.state.isInventoryAccessEditable) {
                          this.handleInventoryAccessChange(e);
                        }
                      }}
                      label={t(
                        "supplier.allowInventoryAccessToClientRestaurants"
                      )}
                    />
                  </div>
                  <div className="col-2 mt-3 text-right">
                    {!this.state.isInventoryAccessEditable && (
                      <span
                        onClick={() => this.handleEdit("inventoryAccess")}
                        className="pointer-cursor"
                        data-toggle="tooltip"
                        data-placement="left"
                        title={t("common.cancel")}
                      >
                        <FontAwesomeIcon
                          style={{ color: GREEN, margin: 5 }}
                          icon={faEdit}
                        />
                      </span>
                    )}
                    {this.state.isInventoryAccessEditable && (
                      <div>
                        <span
                          onClick={() => this.handleCancel("inventoryAccess")}
                          className="pointer-cursor"
                          data-toggle="tooltip"
                          data-placement="left"
                          title={t("common.cancel")}
                        >
                          <FontAwesomeIcon
                            style={{ color: GREEN, margin: 5 }}
                            icon={faUndo}
                          />
                        </span>
                        <span
                          onClick={() => {
                            this.setState({
                              isInventoryAccessEditable: false,
                            });
                            this.props.saveInventoryAccessToClient(
                              this.state.inventoryAccessToClient,
                              this.props.itemData.uuid
                            );
                          }}
                          className="pointer-cursor"
                          data-toggle="tooltip"
                          data-placement="left"
                          title={t("common.save")}
                        >
                          <FontAwesomeIcon
                            style={{ color: GREEN, margin: 5 }}
                            icon={faSave}
                          />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(
  connect()(
    withRouter(withCardItemSelectionStateManager("labelCard")(ServiceSetCard))
  )
);
