import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function labelRequest(credentials: object): ActionModel {
  return {
    type: types.LABELS.REQUEST,
    credentials
  };
}

export function labelSuccess(data: object): ActionModel {
  return {
    type: types.LABELS.SUCCESS,
    data
  };
}

export function labelFailure(err: object): ActionModel {
  return {
    type: types.LABELS.FAILURE,
    err
  };
}

export function labelUpdate(credentials: object): ActionModel {
  return {
    type: types.LABELS_UPDATE.REQUEST,
    credentials
  };
}

export function labelUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.LABELS_UPDATE.SUCCESS,
    data
  };
}

export function labelUpdateFailure(err: object): ActionModel {
  return {
    type: types.LABELS_UPDATE.FAILURE,
    err
  };
}

export function labelAdd(credentials: object): ActionModel {
  return {
    type: types.LABELS_ADD.REQUEST,
    credentials
  };
}

export function labelAddSuccess(data?: object): ActionModel {
  return {
    type: types.LABELS_ADD.SUCCESS,
    data
  };
}

export function labelAddFailure(err: object): ActionModel {
  return {
    type: types.LABELS_ADD.FAILURE,
    err
  };
}

export function labelRemove(credentials: object): ActionModel {
  return {
    type: types.LABELS_REMOVE.REQUEST,
    credentials
  };
}

export function labelRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.LABELS_REMOVE.SUCCESS,
    data
  };
}

export function labelRemoveFailure(err: object): ActionModel {
  return {
    type: types.LABELS_REMOVE.FAILURE,
    err
  };
}
