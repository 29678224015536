import { Label } from "@mobiscroll/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { SERVER_GREEN, GREEN, ORANGE, RED } from "../../constant/constant";
import { ServerInitState, ServerState } from "../../models/server.model";
import {
  serversRequest,
  serversUpdate,
} from "../../redux/actions/servers.action";
import "../../styles/operation-mode.scss";
import LoaderComponent from "../loader/loader";
import SettingsHeaderComponent from "../navigation/navigation-header/settings-header/settings-header";
import SettingsNavComponent from "../navigation/navigation-left/settings-navigation/settings-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStopCircle } from "@fortawesome/free-solid-svg-icons";
import commonService from "../../services/common.service";
import { withTranslation } from "react-i18next";

class ServerComponent extends Component<any> {
  state: ServerState;
  option: any = [];
  settingstatus: any = 0;
  restuarantUuid: any = "";
  constructor(props: any) {
    super(props);
    this.state = ServerInitState;
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <SettingsHeaderComponent settings={"settings"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <SettingsNavComponent
                display={"servers"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <div className="white-box mb-3">
                <h4>{t("common.servers")}</h4>
                {this.renderServers()}
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  public renderServers(): any {
    let option;
    if (this.state.serverList.length > 0) {
      // eslint-disable-next-line
      option = this.state.serverList.map((server: any, i: number) => {
        let statusColor = server.status === 1 ? SERVER_GREEN : RED;
        let statusTitle = server.status === 1 ? "Running" : "Stopped";
        return (
          <div className="child-box mb-3">
            <Row className="app-row-padding">
              <Col>
                <Label style={{ color: GREEN }}>{server.uuid}</Label>
              </Col>
              <Col>
                <Label style={{ color: GREEN }}>
                  {!!server.ipAddress ? server.ipAddress : ""}
                </Label>
              </Col>
              <Col>
                <Label style={{ color: GREEN }}>
                  {!!server.createdAt
                    ? commonService.getDate(server.createdAt)
                    : ""}
                </Label>
              </Col>
              <Col>
                <div className="rectangle">
                  <div
                    className="innerCircle"
                    style={{ backgroundColor: statusColor }}
                    title={statusTitle}
                  ></div>
                </div>
                {server.status == 1 ? (
                  <div
                    className="pointer-cursor inline-indicator ml-2"
                    title={this.props.t('common.stopServer')}
                    onClick={() => this.stopServer(server.uuid)}
                  >
                    <FontAwesomeIcon
                      style={{ color: RED, fontSize: 23 }}
                      icon={faStopCircle}
                    />
                  </div>
                ) : null}
              </Col>
            </Row>
          </div>
        );
      });
      return (
        <div>
          <div className="child-box mb-3">
            <Row className="app-row-padding">
              <Col>{this.props.t("server.serverSignature")}</Col>
              <Col>{this.props.t("common.ipAddress")}</Col>
              <Col>{this.props.t("common.created")}</Col>
              <Col>{this.props.t("common.status")}</Col>
            </Row>
          </div>
          {option}
        </div>
      );
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.props.t("common.noRecords")}
        </div>
      );
    }
  }

  public stopServer = (uuid: string) => {
    this.props.updateServer({
      restaurantuuid: this.props.match.params.uuid,
      uuid: uuid,
    });
    this.setState({
      isUpdated: true,
    });
  };

  componentDidMount() {
    document.body.className = "light-theme";
    this.restuarantUuid = this.props.match.params.uuid;
    this.props.getServers({ restaurantuuid: this.props.match.params.uuid });
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this.setState({
        isFetching: newProps.isFetching,
      });
    }
    if (!!newProps.servers && !newProps.isFetching) {
      this.setState({
        serverList: newProps.servers,
      });
    }
    if (!!this.state.isUpdated && !newProps.isFetching) {
      this.props.getServers({ restaurantuuid: this.props.match.params.uuid });
      this.setState({
        isUpdated: false,
      });
    }
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching = state.servers.isFetching || state.serverupdate.isFetching;

  let failure = state.servers.failure || state.serverupdate.failure;

  return {
    servers: state.servers.data,
    isFetching: isFetching,
    failure: failure,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getServers: (credentials: any) => {
      dispatch(serversRequest(credentials));
    },
    updateServer: (credentials: any) => {
      dispatch(serversUpdate(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ServerComponent))
);
