import { call, put, takeLatest } from "redux-saga/effects";

import { GLOBAL_REQUEST, RESTAURANT_REQUEST } from "../config/api.config";
import {
  currencyFailure,
  currencySuccess
} from "../redux/actions/currency.action";
import * as types from "../redux/actions/types";
import currency from "../services/currency.service";
import handleResponse from "../services/response.service";

const currencyCall = (params: object) =>
  currency.currency(GLOBAL_REQUEST, params);

function* handleCurrencyRequest(params: object) {
  try {
    let result;
    result = yield call(currencyCall, params);
    yield handleCurrency200(result);
  } catch (error) {
    throw error;
  }
}

const handleCurrency200 = function* handleCurrency200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(currencySuccess(result.data));
  } else {
    yield put(currencyFailure(result.data));
    handleResponse(result);
  }
};

export default function* currencySaga() {
  yield takeLatest(types.CURRENCY.REQUEST, handleCurrencyRequest);

}
