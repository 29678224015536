import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import * as types from '../redux/actions/types';
import {
    unitsAddFailure,
    unitsAddSuccess,
    unitsFailure,
    unitsRemoveFailure,
    unitsRemoveSuccess,
    unitsSuccess,
    unitsUpdateFailure,
    unitsUpdateSuccess,
    conversionAddSuccess,
    conversionAddFailure,
    conversionEditSuccess,
    conversionEditFailure,
    conversionDeleteFailure,
    conversionDeleteSuccess
} from '../redux/actions/units.action';
import handleResponse from '../services/response.service';
import units from '../services/units.service';

const unitsCall = (params: any) => units.units(RESTAURANT_REQUEST, params);
const updateUnitsCall = (params: any) => units.updateUnits(RESTAURANT_REQUEST, params);
const addUnitsCall = (params: any) => units.addUnits(RESTAURANT_REQUEST, params);
const removeUnitsCall = (params: any) => units.removeUnits(RESTAURANT_REQUEST, params);
const addConversionCall = (params: any) => units.addConversion(RESTAURANT_REQUEST, params);
const editConversionCall = (params: any) => units.editConversion(RESTAURANT_REQUEST, params);
const deleteConversionCall = (params: any) => units.deleteConversion(RESTAURANT_REQUEST, params);

function* handleUnitsRequest(params: object) {
    try {
        let result;
        result = yield call(unitsCall, params);
        yield handleUnits200(result);
    } catch (error) {
        throw error;
    }
}

const handleUnits200 = function* handleUnits200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(unitsSuccess(result.data));
    } else {
        yield put(unitsFailure(result.data));
        handleResponse(result);
    }
}

function* handleUnitsUpdate(params: object) {
    try {
        let result;
        result = yield call(updateUnitsCall, params);
        yield handleUnitsUpdate200(result);
    } catch (error) {
        throw error;
    }
}

const handleUnitsUpdate200 = function* handleUnitsUpdate200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(unitsUpdateSuccess(result.data));
    } else {
        yield put(unitsUpdateFailure(result.data));
    }
    handleResponse(result);
}

function* handleUnitsAdd(params: object) {
    try {
        let result;
        result = yield call(addUnitsCall, params);
        yield handleUnitsAdd200(result);
    } catch (error) {
        throw error;
    }
}

const handleUnitsAdd200 = function* handleUnitsAdd200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(unitsAddSuccess(result.data));
    } else {
        yield put(unitsAddFailure(result.data));
    }
    handleResponse(result);
}

function* handleConversionAdd(params: object) {
    try {
        let result;
        result = yield call(addConversionCall, params);
        yield handleConversionAdd200(result);
    } catch (error) {
        throw error;
    }
}

const handleConversionAdd200 = function* handleConversionAdd200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(conversionAddSuccess(result.data));
    } else {
        yield put(conversionAddFailure(result.data));
    }
    handleResponse(result);
}

function* handleConversionEdit(params: object) {
    try {
        let result;
        result = yield call(editConversionCall, params);
        yield handleConversionEdit200(result);
    } catch (error) {
        throw error;
    }
}

const handleConversionEdit200 = function* handleConversionEdit200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(conversionEditSuccess(result.data));
    } else {
        yield put(conversionEditFailure(result.data));
    }
    handleResponse(result);
}

function* handleConversionDelete(params: object) {
    try {
        let result;
        result = yield call(deleteConversionCall, params);
        yield handleConversionDelete200(result);
    } catch (error) {
        throw error;
    }
}

const handleConversionDelete200 = function* handleConversionDelete200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(conversionDeleteSuccess(result.data));
    } else {
        yield put(conversionDeleteFailure(result.data));
    }
    handleResponse(result);
}

function* handleUnitsRemove(params: object) {
    try {
        let result;
        result = yield call(removeUnitsCall, params);
        yield handleUnitsRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handleUnitsRemove200 = function* handleUnitsRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(unitsRemoveSuccess(result.data));
    } else {
        yield put(unitsRemoveFailure(result.data));
    }
    handleResponse(result);
}
export default function* unitsSaga() {
    yield takeLatest(types.UNITS.REQUEST, handleUnitsRequest);
    yield takeLatest(types.UNITS_UPDATE.REQUEST, handleUnitsUpdate);
    yield takeLatest(types.UNITS_ADD.REQUEST, handleUnitsAdd);
    yield takeLatest(types.UNITS_REMOVE.REQUEST, handleUnitsRemove);
    yield takeLatest(types.CONVERSION_ADD.REQUEST, handleConversionAdd);
    yield takeLatest(types.CONVERSION_EDIT.REQUEST, handleConversionEdit);
    yield takeLatest(types.CONVERSION_DELETE.REQUEST, handleConversionDelete);


}
