import React, { Component } from "react";
import "../../styles/inline-edit-input.scss";

class InlineEditInput extends Component<any, any> {
  inputRef: any;
  innerInputRef: any;
  doFocus: boolean = false;
  focused: boolean = false;
  manuallyChangedValue: any = "";

  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.inputRef = React.createRef();
    this.innerInputRef = React.createRef();
    this.state = { editValue: "", storedValue: "" };
  }

  private _setFunctionBindings(): void {
    this.onChange = this.onChange.bind(this);
    this.focus = this.focus.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.isFocused = this.isFocused.bind(this);
    this.blur = this.blur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  public isFocused() {
    return this.focused;
  }

  private onChange(e: any) {
    let value = e.target.value;
    if (this.props.pattern === null || RegExp(this.props.pattern).test(value)) {
      this.setState({ editValue: value });
      if (typeof this.props.conversionCallback !== "undefined") {
        value = this.props.conversionCallback(value);
      }
      this.manuallyChangedValue = value;
      this.setState({ storedValue: value });
      if (typeof this.props.onChange !== "undefined") {
        this.props.onChange(this.props.name, value);
      }
    }
  }

  public focus(select: boolean = false) {
    this.doFocus = true;
    if (
      this.innerInputRef.current !== null &&
      typeof this.innerInputRef.current.focus === "function"
    ) {
      this.innerInputRef.current.focus();
    } else if (
      this.inputRef.current !== null &&
      typeof this.inputRef.current.focus === "function"
    ) {
      this.inputRef.current.focus();
    }
  }

  public blur() {
    if (
      this.innerInputRef.current !== null &&
      typeof this.innerInputRef.current.blur === "function"
    ) {
      this.innerInputRef.current.blur();
    } else if (
      this.inputRef.current !== null &&
      typeof this.inputRef.current.blur === "function"
    ) {
      this.inputRef.current.blur();
    }
  }

  public onFocus() {
    this.doFocus = false;
    this.focused = true;
    if (typeof this.props.onFocus !== "undefined") {
      this.props.onFocus();
    }
  }

  public onBlur() {
    this.focused = false;
    this.setState({ editValue: this.props.value });
    if (typeof this.props.onBlur !== "undefined") {
      this.props.onBlur();
    }
  }

  public onKeyDown(e: any) {
    if (e.keyCode === 13) {
      this.blur();
    }
  }

  componentDidMount() {
    if (
      this.doFocus &&
      this.innerInputRef.current !== null &&
      typeof this.innerInputRef.current.focus === "function"
    ) {
      this.innerInputRef.current.focus();
    } else if (
      this.doFocus &&
      this.inputRef.current !== null &&
      typeof this.inputRef.current.focus === "function"
    ) {
      this.inputRef.current.focus();
    }
    if (this.props.selectAfterExternalChange && this.props.edit) {
      if (
        this.innerInputRef.current !== null &&
        typeof this.innerInputRef.current.select === "function"
      ) {
        this.innerInputRef.current.select();
      } else if (
        this.inputRef.current !== null &&
        typeof this.inputRef.current.select === "function"
      ) {
        this.inputRef.current.select();
      }
    }
    this.setState({ editValue: this.props.value });
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.value !== prevState.storedValue) {
      return { editValue: nextProps.value, storedValue: nextProps.value };
    }
    return null;
  }

  componentDidUpdate(prevProps: any) {
    if (this.doFocus) {
      if (
        this.innerInputRef.current !== null &&
        typeof this.innerInputRef.current.focus === "function"
      ) {
        this.innerInputRef.current.focus();
      } else if (
        this.inputRef.current !== null &&
        typeof this.inputRef.current.focus === "function"
      ) {
        this.inputRef.current.focus();
      }
    }

    if (
      this.props.selectAfterExternalChange &&
      prevProps &&
      prevProps.value !== this.props.value &&
      this.inputRef.current &&
      this.props.value !== this.manuallyChangedValue &&
      this.props.edit
    ) {
      if (
        this.innerInputRef.current !== null &&
        typeof this.innerInputRef.current.select === "function"
      ) {
        this.innerInputRef.current.select();
      } else if (
        this.inputRef.current !== null &&
        typeof this.inputRef.current.select === "function"
      ) {
        this.inputRef.current.select();
      }
      this.manuallyChangedValue = this.props.value;
    }
  }

  render() {
    let {
      key,
      value,
      edit,
      readOnlyElement = "span",
      readOnlyValueProperty = "children",
      readOnlyContentCallback = null,
      propsEdit = {},
      propsReadOnly = {},
      editElement = "input",
      editValueProperty = "value",
      conversionCallback,
      pattern,
      selectAfterExternalChange,
      style = {},
      styleContainer = {},
      styleEdit = {},
      styleReadOnly = {},
      onChange,
      onFocus,
      onBlur,
      oldClassName = "",
      ...other
    } = this.props;

    let { display = "inherit" } = style;

    styleEdit = { display: edit ? display : "none", ...styleEdit };
    styleReadOnly = { display: !edit ? display : "none", ...styleReadOnly };

    propsEdit = {
      className: "inline-edit-input input " + oldClassName,
      style: { ...style, ...styleEdit },
      onChange: this.onChange,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      onKeyDown: this.onKeyDown,
      ref: this.inputRef,
      innerRef: this.innerInputRef,
      ...propsEdit,
      ...other,
    };
    propsReadOnly = {
      className: "inline-edit-input readonly " + oldClassName,
      style: { ...style, ...styleReadOnly },
      ...propsReadOnly,
      ...other,
    };
    let readOnlyContent: any = [];
    readOnlyContent[readOnlyValueProperty] =
      readOnlyContentCallback !== null
        ? readOnlyContentCallback(this.state.editValue)
        : this.state.editValue;

    let editContent: any = [];
    editContent[editValueProperty] = this.state.editValue;

    if (propsEdit.props && !propsEdit?.props?.key) {
      propsEdit.props["key"] = propsEdit?.key;
    }
    let editElementInst = React.createElement(editElement, {
      ...propsEdit,
      ...editContent,
      key,
    });
    let readOnlyElementInst = React.createElement(readOnlyElement, {
      ...propsReadOnly,
      ...readOnlyContent,
      key,
    });

    return (
      <span style={styleContainer} key={this.props.id}>
        {editElementInst}
        {readOnlyElementInst}
      </span>
    );
  }
}

export default InlineEditInput;

// Conversion-Callback
export function getNumber(value: any): number {
  if (!isNaN(Number(value))) {
    return Number(value);
  } else {
    return 0;
  }
}

// Warning required-Callback
export function requiredWarning(value: any): any {
  if (value.trim() === "") {
    return { warning: "stop", warningText: "Data required!" };
  } else {
    return { warning: "none" };
  }
}

// Prepare options for dropdown
export function optionList(data: any) {
  let dataList = data.length ? data : [];
  let options;
  if (!!dataList && dataList.length > 0) {
    options = dataList.map((option: any, i: number) => {
      return (
        <option key={i} value={option.id}>
          {option.name}
        </option>
      );
    });
    return options;
  }
}
