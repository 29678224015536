import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function vatRequest(credentials: object): ActionModel {
  return {
    type: types.VAT.REQUEST,
    credentials
  };
}

export function vatSuccess(data: object): ActionModel {
  return {
    type: types.VAT.SUCCESS,
    data
  };
}

export function vatFailure(err: object): ActionModel {
  return {
    type: types.VAT.FAILURE,
    err
  };
}

export function vatUpdate(credentials: object): ActionModel {
  return {
    type: types.VAT_UPDATE.REQUEST,
    credentials
  };
}

export function vatUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.VAT_UPDATE.SUCCESS,
    data
  };
}

export function vatUpdateFailure(err: object): ActionModel {
  return {
    type: types.VAT_UPDATE.FAILURE,
    err
  };
}

export function vatAdd(credentials: object): ActionModel {
  return {
    type: types.VAT_ADD.REQUEST,
    credentials
  };
}

export function vatAddSuccess(data?: object): ActionModel {
  return {
    type: types.VAT_ADD.SUCCESS,
    data
  };
}

export function vatAddFailure(err: object): ActionModel {
  return {
    type: types.VAT_ADD.FAILURE,
    err
  };
}

export function vatRemove(credentials: object): ActionModel {
  return {
    type: types.VAT_REMOVE.REQUEST,
    credentials
  };
}

export function vatRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.VAT_REMOVE.SUCCESS,
    data
  };
}

export function vatRemoveFailure(err: object): ActionModel {
  return {
    type: types.VAT_REMOVE.FAILURE,
    err
  };
}
