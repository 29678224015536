export interface PosState {
  modal: any,
  event: any,
  nestedModal: any,
  isFetching: any,
  mealslist: any,
  boxindex: any,
  boxItem: any,
  recipeList: any,
  currentDate: any,
  eventlist: any
  changeDate: any,
  isUpdated: boolean,
  eventdetail: any,
  mealsuuid: any,
  coureseUuid: String
  quantity: number,
  qtyList: any,
  validate: boolean,
  deliveryto: string,
  deliveringat: string,
  table: string,
  toMobile: boolean,
  editOrder: boolean,
  date: any,
  labelList: any,
  stack: any
}

export const PosInitState = {
  modal: false,
  changeDate: '',
  event: [],
  nestedModal: false,
  isFetching: false,
  mealslist: [],
  boxindex: 0,
  boxItem: [],
  recipeList: [],
  currentDate: '',
  eventlist: [],
  isUpdated: false,
  eventdetail: [],
  mealsuuid: [],
  coureseUuid: '',
  quantity: 1,
  qtyList: [],
  validate: false,
  deliveryto: 'order',
  deliveringat: 'asap',
  table: '',
  toMobile: false,
  editOrder: false,
  date: new Date(),
  labelList: [],
  stack: []
};
