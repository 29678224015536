import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { LabelsInitState, LabelsState } from "../../models/labels.model";
import {
  labelAdd,
  labelRemove,
  labelRequest,
  labelUpdate,
} from "../../redux/actions/labels.action";
import "../../styles/units.scss";
import commonService from "../../services/common.service";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import LabelCard from "../card-components/label-card/label-card";
import LabeldAddCard from "../card-components/labels-add-card/labels-add-card";
import LoaderComponent from "../loader/loader";
import { recipePrinterRequest } from "../../redux/actions/recipe.printer.action";
import ConceptHeaderComponent from "../navigation/navigation-header/concept-header/concept-header";
import RestaurantNavComponent from "../navigation/navigation-left/restaurant-navigation/restaurant-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";

import i18next from "i18next";
import { withTranslation } from "react-i18next";
import _ from "lodash";
class LabelsComponent extends Component<any> {
  state: LabelsState;
  options: any;
  uuid: String = "";
  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.state = LabelsInitState;
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <ConceptHeaderComponent concept={"concept"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <RestaurantNavComponent
                display={"labels"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={8} lg={6}>
              <Link
                to="#"
                color="info"
                className="unit-add"
                onClick={this.setToggle}
              >
                {t("label.addLabel")}
              </Link>
              <div className="white-box mb-3">
                <h4>{t("label.labels")}</h4>
                {this.labelList(this.state.labels)}
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>

        {/*  Add Label  */}

        <LabeldAddCard
          PRINTERLIST={this.printerList()}
          handlePrinterChange={this.handlePrinterChange.bind(this)}
          options={this.options}
          handleSelectChange={this.handleSelectChange}
          state={this.state}
          AddToggle={this.AddToggle}
          handleChange={this.handleChange}
          saveAdd={this.saveAdd}
          handleClick={this.handleClick}
        />

        {/* Delete Label */}

        <DeleteModalCard
          isOpen={this.state.open}
          isFetching={!!this.state.isFetching}
          okDelete={this.deleteConfirmToggle}
          cancelDelete={this.deleteToggle}
        />
      </div>
    );
  }

  public handleSelectChange = (selectedOption: any) => {
    if (!selectedOption) {
      this.setState({
        selectedOption: [],
      });
    } else {
      this.setState({
        selectedOption,
      });
    }
  };

  public handlePrinterChange(e: any): void {
    this.state.printerList.forEach((printer: any) => {
      if (printer.uuid === e.target.value) {
        this.setState({
          printerUuid: printer.uuid,
        });
      }
      if (e.target.value === "") {
        this.setState({
          printerUuid: "",
        });
      }
    });
  }

  public handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  public labelList(data: any): any {
    let labels;
    const sortedData: Array<any> =
      _.sortBy(data, [
        (item) =>
          commonService
            .applyLocalization("restaurant", "name", item.locales)
            ?.name?.toLowerCase(),
      ]) || [];
    if (!!sortedData && sortedData.length > 0) {
      labels = sortedData.map((locales: any, i: number) => {
        return (
          <LabelCard
            printerName={this.getPrinterName(locales)}
            key={i}
            setEditable={this.setEditable.bind(this, locales)}
            setDelete={this.setDelete.bind(this, locales)}
            locales={locales}
            state={this.state}
          />
        );
      });
      return labels;
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.props.t("common.noRecords")}
        </div>
      );
    }
  }

  public printerList() {
    let dataList = this.state.printerList.length ? this.state.printerList : [];
    let options;
    if (!!dataList) {
      options = dataList.map((option: any, i: number) => {
        return (
          <option key={i} value={option.uuid}>
            {option.name}
          </option>
        );
      });
      return options;
    }
  }

  public getPrinterName(data: any) {
    let dataList = this.state.printerList.length ? this.state.printerList : [];
    let options = "";
    if (!!dataList) {
      // eslint-disable-next-line
      dataList.map((option: any, i: number) => {
        if (data.printer_uuid === option.uuid) {
          options = option.name;
        }
      });
      return options;
    }
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.getLabel({ restaurantuuid: this.props.match.params.uuid });
    this._setStates("restaurantuuid", this.props.match.params.uuid);
    this.props.getPrinterList({ restaurantuuid: this.props.match.params.uuid });
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
      if (!!newProps && !newProps.isFetching) {
        this._setStates("labels", newProps.labels);
      }
      if (!!newProps.printers && !newProps.isFetching && !newProps.failure) {
        this._setStates("printerList", newProps.printers);
      }
      if (!!this.state.isUpdated && !newProps.isFetching) {
        this.props.getLabel({ restaurantuuid: this.props.match.params.uuid });
        this._setStates("isUpdated", false);
      }
      if (
        !!newProps.addlabelsstatus.status &&
        !!this.state.addmodal &&
        !newProps.isFetching
      ) {
        this.AddToggle();
      }
      if (
        !!newProps.updatelabelsstatus.status &&
        !!this.state.editModal &&
        !newProps.isFetching
      ) {
        this.setState((prevState: any) => ({
          editModal: !!prevState.editModal,
          name: "",
          labeldescription: "",
          uuid: "",
        }));
      }
    }
  }

  public saveEdit(): void {
    let parent: any;
    parent = this.state.selectedOption.map((item: any) => {
      return item.value;
    });
    this.props.updatelabels({
      restaurantuuid: this.state.restaurantuuid,
      uuid: this.state.uuid,
      data: {
        color: this.state.color,
        parent_label_uuids: parent,
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.name,
            description: this.state.labeldescription,
          },
        },
        printer_uuid: this.state.printerUuid,
      },
    });
    this._setStates("isUpdated", true);
  }

  public saveAdd(): void {
    if (!!this.state.uuid) {
      this.saveEdit();
    } else {
      this.saveNewLabel();
    }
  }

  public saveNewLabel() {
    let parent: any;
    parent = this.state.selectedOption.map((item: any) => {
      return item.value;
    });
    this.props.addLabels({
      restaurantuuid: this.state.restaurantuuid,
      data: {
        color: this.state.color,
        parent_label_uuids: parent,
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.name,
            description: this.state.labeldescription,
          },
        },
        printer_uuid: this.state.printerUuid,
      },
    });
    this._setStates("isUpdated", true);
  }

  public deleteToggle(): void {
    this.setState((prevState: any) => ({
      editModal: false,
      open: !prevState.open,
    }));
  }

  public deleteConfirmToggle(): void {
    this.props.removeLabels({
      uuid: this.uuid,
      restaurantuuid: this.state.restaurantuuid,
    });
    this._setStates("isUpdated", true);
    this.deleteToggle();
  }

  public AddToggle(): void {
    this.setState((prevState: any) => ({
      addmodal: prevState.addmodal ? !prevState.addmodal : prevState.addmodal,
      editModal: prevState.editModal
        ? !prevState.editModal
        : prevState.editModal,
      name: "",
      labeldescription: "",
      uuid: "",
      displayColorPicker: false,
      selectedOption: [],
      printerUuid: "",
    }));
  }

  public setToggle(): void {
    this.options = [];
    let allLabels = this.state.labels.length ? this.state.labels : [];
    this.options = allLabels.map((item: any) => {
      return {
        value: item.uuid,
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          item?.locales
        ).name,
      };
    });
    this.setState((prevState: any) => ({
      editModal: prevState.editModal
        ? !prevState.editModal
        : prevState.editModal,
      addmodal: !prevState.addmodal,
      name: "",
      labeldescription: "",
      uuid: "",
      displayColorPicker: false,
      color: "#FFFFFF",
      printerUuid: "",
    }));
  }

  private _setFunctionBindings(): void {
    this.setEditable = this.setEditable.bind(this);
    this.AddToggle = this.AddToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteConfirmToggle = this.deleteConfirmToggle.bind(this);
    this.deleteToggle = this.deleteToggle.bind(this);
    this.saveEdit = this.saveEdit.bind(this);
    this.saveAdd = this.saveAdd.bind(this);
    this.setDelete = this.setDelete.bind(this);
    this.setToggle = this.setToggle.bind(this);
  }

  public setEditable(event: any): void {
    let allLabels = this.state.labels.length ? this.state.labels : [];
    let filterResult: any = [];
    this.options = [];
    if (!!allLabels) {
      filterResult = allLabels.filter((item: any) => event.uuid !== item.uuid);
    }
    filterResult.forEach((item: any) => {
      this.options.push({
        value: item.uuid,
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          item?.locales
        ).name,
      });
    });
    if (!!event.parent_labels) {
      let selectedOption: any = [];
      // eslint-disable-next-line
      selectedOption = event.parent_labels.map((parent: any) => {
        return { value: parent, label: this.labelName(parent, filterResult) };
      });
      this.setState({
        selectedOption,
      });
    }
    let locale = commonService.applyLocalization(
      "restaurant",
      "name",
      event?.locales
    );
    this.setState({
      displayColorPicker: false,
      labeldescription: locale.description,
      name: locale.name,
      uuid: event.uuid,
      editModal: this.state.editModal ? !this.state.editModal : true,
      color: event.color,
      printerUuid: event.printer_uuid ? event.printer_uuid : "",
    });
  }

  public labelName(parent: any, filterResult: any) {
    let name;
    if (!!filterResult) {
      // eslint-disable-next-line
      name = filterResult.map((item: any, i: number) => {
        if (parent === item.uuid) {
          return commonService.applyLocalization(
            "restaurant",
            "name",
            item?.locales
          ).name;
        }
      });
      return name;
    }
  }

  public setDelete(event: any): void {
    this.uuid = event.uuid;
    this.setState({
      uuid: event.uuid,
    });
    this.deleteToggle();
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  public handleChange(e: any) {
    this._setStates(e.target.name, e.target.value);
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.labelsupdate.isFetching ||
    state.labels.isFetching ||
    state.labelsremove.isFetching ||
    state.labelsadd.isFetching;
  let failure =
    state.labelsupdate.failure ||
    state.labels.failure ||
    state.labelsremove.failure ||
    state.labelsadd.failure;
  return {
    labels: state.labels.data,
    addlabelsstatus: state.labelsadd.data,
    updatelabelsstatus: state.labelsupdate.data,
    isFetching: isFetching,
    failure: failure,
    printers: state.recipeprinter.data,
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getLabel: (credentials: any) => {
      dispatch(labelRequest(credentials));
    },
    updatelabels: (credentials: any) => {
      dispatch(labelUpdate(credentials));
    },
    addLabels: (credentials: any) => {
      dispatch(labelAdd(credentials));
    },
    removeLabels: (credentials: any) => {
      dispatch(labelRemove(credentials));
    },
    getPrinterList: (credentials: any) => {
      dispatch(recipePrinterRequest(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(LabelsComponent))
);
