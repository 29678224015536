import apiCall from "./api.call.config";

async function addMachine(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/machine",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function addMachineType(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.uuid + "/machine-type",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function getMachineTypes(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.get(
      url + "/" + params.credentials.uuid + "/machine-type?sort=name:asc"
    );
  } catch (e) {
    throw e;
  }
}

async function machine(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.get(
      url + "/" + params.credentials.uuid + "/machine?sort=name:asc"
    );
  } catch (e) {
    throw e;
  }
}

async function removeMachine(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/machine/" +
        params.credentials.uuid
    );
  } catch (e) {
    throw e;
  }
}

export async function removeMachineType(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.uuid +
        "/machine-type/" +
        params.credentials.data.typeId
    );
  } catch (e) {
    throw e;
  }
}

async function updateMachine(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/machine/" +
        params.credentials.uuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function updateMachineType(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.uuid +
        "/machine-type/" +
        params.credentials.typeId,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  addMachine,
  machine,
  removeMachine,
  updateMachine,
};
