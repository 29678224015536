import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { RecipeTasksProps } from "../../../models/task.model";
import { withCardItemSelectionStateManager } from "../../cardItemSelectionManager";

import RecipeTasksCard from "./recipe-tasks-card";
import { find } from "ramda";

function useOutsideAlerter(ref: any, onClickOut: Function) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOut();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOut]);
}

function RecipeTasks(props: RecipeTasksProps) {
  const {
    cardItemSelectionData,
    onDeleteTaskItem,
    getInputReadOnlyLabels,
    getUnitDetails,
    getUuidFromMediaManager,
    handleTaskInterventionDelete,
    handleTaskDependencySelection,
    handleTaskInterventionSave,
    handleTaskSelection,
    handleTaskStepSave,
    itemDraft,
    machinesOptions,
    onCancelEdit,
    onItemModify,
    onSaveTaskItem,
    onTaskEdit,
    restLang,
    selectedTaskDetails,
    stationsOptions,
    taskDraft,
    taskWithIngDraft,
    tasks,
    unitOptions,
    showNewTaskWithIng = true,
  } = props;

  const { t, i18n } = useTranslation();
  let isEditInProgress: any = useRef(false);

  useEffect(() => {
    if (_.size(selectedTaskDetails) === 0) {
      cardItemSelectionData.deselectCardItem();
    }
  }, [selectedTaskDetails]);

  const getSortedInterventions = (task: any) => {
    if (!task?.interventions)
      return {
        interventions: [],
        endIntervention: {},
      };
    let value: any = null;
    let newInterventions: any[] = task.interventions.slice(0);
    let endIntervention: any = null;
    if (
      newInterventions.length > 0 &&
      newInterventions[newInterventions.length - 1].intervention_position === 3
    ) {
      endIntervention = newInterventions.splice(
        newInterventions.length - 1,
        1
      )[0];
    }

    if (value !== null) {
      let dragIntervention = null;
      if (
        endIntervention !== null &&
        value.draggableId.substring(21) === endIntervention.uuid
      ) {
        dragIntervention = endIntervention;
        endIntervention = null;
      } else {
        let index = newInterventions.findIndex(
          (intervention) =>
            intervention.uuid === value.draggableId.substring(21)
        );
        dragIntervention = newInterventions.splice(index, 1)[0];
      }

      if (
        value.destination.droppableId ===
        "taskInterventionEndDroppable_" + task.uuid
      ) {
        endIntervention = dragIntervention;
      } else {
        newInterventions.splice(value.destination.index, 0, dragIntervention);
      }
    }

    return {
      interventions: newInterventions,
      endIntervention: endIntervention,
    };
  };

  const handleItemModify = (cardItemId: any, formData: any) => {
    if (formData.action === "edit") {
      onTaskEdit(cardItemId, formData);
    } else {
      onItemModify(cardItemId, {
        ...formData,
        ...{ preparationSet: selectedTaskDetails?.selectedTaskDetails },
      });
    }
  };

  const onClickOut = useCallback(() => {
    if (!!_.size(selectedTaskDetails) && !selectedTaskDetails.isEdit) {
      onCancelEdit();
      cardItemSelectionData.deselectCardItem();
    }
  }, [cardItemSelectionData, onCancelEdit, selectedTaskDetails]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClickOut);

  return (
    <div ref={wrapperRef}>
      {tasks?.map((task: any, key: number) => {
        return (
          <RecipeTasksCard
            key={"task" + task.uuid}
            cardItemData={task}
            cardItemId={task.uuid}
            onDeleteTaskItem={onDeleteTaskItem}
            getInputReadOnlyLabels={getInputReadOnlyLabels}
            getSortedInterventions={getSortedInterventions}
            getUnitDetails={getUnitDetails}
            getUuidFromMediaManager={getUuidFromMediaManager}
            handleTaskInterventionDelete={handleTaskInterventionDelete}
            handleTaskInterventionSave={handleTaskInterventionSave}
            handleTaskDependencySelection={handleTaskDependencySelection}
            handleTaskStepSave={handleTaskStepSave}
            isNewCardItem={false}
            itemDraft={itemDraft}
            machinesOptions={machinesOptions}
            onCancelEdit={onCancelEdit}
            onItemModify={handleItemModify}
            onSaveTaskItem={onSaveTaskItem}
            parentCardItemSelectionData={cardItemSelectionData}
            restLang={restLang}
            selectedTaskDetails={selectedTaskDetails}
            selectThisTask={handleTaskSelection}
            stationsOptions={stationsOptions}
            unitOptions={unitOptions}
	    selectedPreparationSet={props.selectedPreparationSet}
          />
        );
      })}

      <RecipeTasksCard
        key={"newTask"}
        cardItemData={taskDraft}
        cardItemId={"newTask"}
        onDeleteTaskItem={onDeleteTaskItem}
        getInputReadOnlyLabels={getInputReadOnlyLabels}
        getSortedInterventions={getSortedInterventions}
        getUnitDetails={getUnitDetails}
        getUuidFromMediaManager={getUuidFromMediaManager}
        handleTaskDependencySelection={handleTaskDependencySelection}
        isNewCardItem={true}
        itemDraft={itemDraft}
        machinesOptions={machinesOptions}
        onCancelEdit={onCancelEdit}
        onItemModify={handleItemModify}
        onSaveTaskItem={onSaveTaskItem}
        parentCardItemSelectionData={cardItemSelectionData}
        restLang={restLang}
        selectedTaskDetails={selectedTaskDetails}
        selectThisTask={handleTaskSelection}
        stationsOptions={stationsOptions}
        unitOptions={unitOptions}
      />
      {showNewTaskWithIng ? (
        <RecipeTasksCard
          key={"newTaskWithIng"}
          cardItemData={taskWithIngDraft}
          cardItemId={"newTaskWithIng"}
          onDeleteTaskItem={onDeleteTaskItem}
          getInputReadOnlyLabels={getInputReadOnlyLabels}
          getSortedInterventions={getSortedInterventions}
          getUnitDetails={getUnitDetails}
          getUuidFromMediaManager={getUuidFromMediaManager}
          handleTaskDependencySelection={handleTaskDependencySelection}
          isNewCardItem={true}
          itemDraft={itemDraft}
          machinesOptions={machinesOptions}
          onCancelEdit={onCancelEdit}
          onItemModify={handleItemModify}
          onSaveTaskItem={onSaveTaskItem}
          parentCardItemSelectionData={cardItemSelectionData}
          restLang={restLang}
          selectedTaskDetails={selectedTaskDetails}
          selectThisTask={handleTaskSelection}
          stationsOptions={stationsOptions}
          unitOptions={unitOptions}
          assembleTaskLocales={props.assembleTaskLocales}
          preselct
        />
      ) : null}
    </div>
  );
}

export default withCardItemSelectionStateManager("RecipeTasks")(RecipeTasks);
