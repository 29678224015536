import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useTranslation } from "react-i18next";

import "react-datepicker/dist/react-datepicker.css";

export default function ItemAddModal(props: any) {
  const { t, i18n } = useTranslation();
  const [isSubmitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    setName(props.formData.name);
    setDescription(props.formData.description);
    if (!!props.formData?.isEdit) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }
  }, [props.formData]);

  const handleChange = (data: any) => {
    setSubmitBtnDisabled(true);
    if (data?.target?.name === "paymentOptionName") {
      setName(data.target.value);
      if (data.target.value.trim() !== "") {
        setSubmitBtnDisabled(false);
      }
    }
    if (data?.target?.name === "paymentOptionDescription") {
      setDescription(data.target.value);
      name.trim() !== '' && setSubmitBtnDisabled(false);
    }
  };
  const handleSubmit = () => {
    const lang = localStorage.getItem("i18nextLng") || "de-ch";
    let locales: any = {};
    locales[lang] = { name, description };

    props.handleSubmit({
      locales,
    });
  };
  return (
    <React.Fragment>
      <Modal isOpen={props.isModalOpen}>
        <ModalHeader toggle={props.handleCancel} className="modal-header">
          {!props.formData?.isEdit
            ? t("payment.addPaymentOption")
            : t("payment.editPaymentOption")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="paymentOptionName" sm={6} lg={4}>
                {t("common.name")} <span className="text-danger">*</span>
              </Label>
              <Col sm={6} lg={8}>
                <Input
                  name="paymentOptionName"
                  onChange={handleChange}
                  placeholder={t('payment.enterPaymentOptionName')}
                  required
                  type="text"
                  value={name}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="paymentOptionImage" sm={6} lg={4}>
                {t("common.description")}
              </Label>
              <Col sm={6} lg={8}>
                <Input
                  name="paymentOptionDescription"
                  onChange={handleChange}
                  placeholder={t('payment.enterPaymentOptionDescription')}
                  type="textarea"
                  rows={5}
                  value={description}
                />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.handleCancel}>
            {t("common.cancel")}
          </Button>
          <Button
            color="info"
            onClick={handleSubmit}
            disabled={isSubmitBtnDisabled}
          >
            {t("common.save")}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
