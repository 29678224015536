export interface InventoryFilterSettingsState {
  formData: any;
  isFetching: boolean;
  labels: Array<any>;
  locations: Array<any>;
  serviceSets: Array<any>;
  stations: Array<any>;
  suppliers: Array<any>;
}

export const InventoryFilterSettingsInitState: InventoryFilterSettingsState = {
  formData: {},
  isFetching: false,
  labels: [],
  locations: [],
  serviceSets: [],
  stations: [],
  suppliers: [],
};

export interface InventoryFilterItemRowProps {
  actions: {
    handleChange: Function;
  };
  itemId: string;
  label: string;
  metaData: {
    defaultItem?: any;
    formData: {
      default?: any;
      enable: boolean;
      pinned: boolean;
    };
    optionList: Array<any>;
  };
}
