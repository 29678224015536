import { create } from "apisauce";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { BASE_URL } from "../../config/app.config";
import { ACCESS_TOKEN } from "../../constant/constant";
import commonService from "../../services/common.service";
import { supplierState, supplierStateInit } from "../../models/suppliers.model";
import handleResponse from "../../services/response.service";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import SupplierAddCard from "../card-components/supplier-add-card/supplier-add-card";
import SupplierCard from "../card-components/supplier-card/supplier-card";
import SupplierEditCard from "../card-components/supplier-edit-card/supplier-edit-card";
import LoaderComponent from "../loader/loader";
import StaffHeaderComponent from "../navigation/navigation-header/staff-header/staff-header";
import UsersLeftComponent from "../navigation/navigation-left/users-navigation/users-navigation";
import { withTranslation } from "react-i18next";
import _, { isEmpty } from "lodash";
import printerTemplate from "../../services/printer.template.service";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import { restaurantInfo } from "../../services/restaurant.detail.service";
import { isValidCron } from "cron-validator";

const api = create({
  baseURL: BASE_URL,
});

class SupplierComponent extends Component<any> {
  state: supplierState;
  manageRightList: any = [];
  constructor(props: any) {
    super(props);
    api.setHeaders({
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Cache-Control": "no-cache",
      Locale: localStorage.getItem("i18nextLng") || "en-gb",
      "content-type": "application/json",
    });
    this.state = supplierStateInit;
    this.buildEmailTemplateOptions();
  }

  buildEmailTemplateOptions = () => {
    printerTemplate
      .printerTemplateList(RESTAURANT_REQUEST, {
        credentials: {
          restaurantuuid: this.props.match.params.uuid,
          templateType: "MAIL",
        },
      })
      .then((response: any) => {
        const emailTemplateOptions =
          Object.keys(response.data).length > 0
            ? response.data.map((item: any) => {
                return {
                  value: item.uuid,
                  label: commonService.applyLocalization(
                    "restaurant",
                    "name",
                    item?.locales
                  )["name"],
                };
              })
            : [];
        emailTemplateOptions.unshift({
          value: "",
          label: this.props.t("email.noEmail"),
        });
        this._setStates("allEmailTemplates", emailTemplateOptions);
        this._setStates("emailTemplateOptions", emailTemplateOptions);
      });
  };

  handleSelectChange = (value: any, action: any) => {
    if (action.name === "restaurant") {
      this.resetFormFields();
      if (!isEmpty(value?.value)) {
        this.setSupplierInfo(value.value);
      }
    }
    this._setStates(action.name, value);
  };

  resetFormFields = () => {
    this._setStates("name", "");
    this._setStates("email", "");
    this._setStates("phoneNumber", "");
    this._setStates("emailTemplateOptions", this.state.allEmailTemplates);
    this._setStates("emailTemplate", "");
    this._setStates("deliverySchedule", "");
    this._setStates("restaurantAllowPartialDelivery", false);
  };

  setSupplierInfo = (uuid: string) => {
    restaurantInfo(RESTAURANT_REQUEST, { restaurantuuid: uuid }).then(
      (response: any) => {
        const responseData: any = handleResponse(response);
        if (responseData.ok) {
          const supplier = response.data;
          this._setStates(
            "name",
            commonService.applyLocalization(
              "restaurant",
              "name",
              supplier?.locales
            )["name"]
          );
          this._setStates("email", supplier.email ?? "");
          this._setStates("phoneNumber", supplier.phoneNumber ?? "");
          if (supplier?.orderEmailTemplate) {
            const template = {
              value: supplier.orderEmailTemplate.uuid,
              label: commonService.applyLocalization(
                "restaurant",
                "name",
                supplier.orderEmailTemplate?.locales
              )["name"],
            };
            this._setStates("emailTemplateOptions", [template]);
            this._setStates("emailTemplate", template);
          }
          this._setStates("deliverySchedule", supplier?.deliverySchedule ?? "");
        }
      }
    );
  };

  buildRestaurantOptions = (supplierRestaurant: string) => {
    const activeRestaurant = JSON.parse(
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
    )?.uuid;
    const selectedRestaurant = supplierRestaurant
      ? supplierRestaurant
      : this.state.restaurant.value;
    const restaurantOptions =
      Object.keys(this.props.restaurants).length > 0
        ? this.props.restaurants
            ?.filter(
              (element: any) =>
                element.uuid !== activeRestaurant &&
                this.state.supplierList.findIndex(
                  (supplier: any) =>
                    supplier.supplier_restaurant === element.uuid &&
                    supplier.supplier_restaurant !== selectedRestaurant
                ) === -1
            )
            ?.map((item: any) => {
              return {
                value: item.uuid,
                label: commonService.applyLocalization(
                  "restaurant",
                  "name",
                  item?.locales
                )["name"],
              };
            })
        : [];
    this.setState({
      restaurantOptions: [
        { value: "", label: this.props.t("common.select") },
        ...restaurantOptions,
      ],
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <StaffHeaderComponent staff={"suppliers"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <UsersLeftComponent
                display={"suppliers"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={8} lg={6}>
              <Link
                to="#"
                color="info"
                className="unit-add"
                onClick={this.AddToggle}
              >
                {t("supplier.addSuppliers")}
              </Link>
              <div className="white-box mb-3">
                <h4>{t("supplier.suppliers")}</h4>
                {this.renderSupplierList(this.state.supplierList)}
                {!this.state.isFetching && this.state.supplierList.length === 0
                  ? t("common.noRecords")
                  : ""}
              </div>
            </Col>
          </Row>
        </div>

        {/*  Edit Supplier  */}
        <SupplierEditCard
          restaurantOptions={this.state.restaurantOptions}
          emailTemplateOptions={this.state.emailTemplateOptions}
          state={this.state}
          toggle={this.toggle}
          handleChange={this.handleChange}
          handleSelectChange={this.handleSelectChange}
          saveEdit={this.saveEdit}
        />

        {/*  Add Supplier  */}
        <SupplierAddCard
          restaurantOptions={this.state.restaurantOptions}
          emailTemplateOptions={this.state.emailTemplateOptions}
          state={this.state}
          AddToggle={this.AddToggle}
          handleChange={this.handleChange}
          handleSelectChange={this.handleSelectChange}
          saveSupplier={this.saveSupplier}
        />

        {/* Delete Supplier */}
        <DeleteModalCard
          isOpen={this.state.open}
          isFetching={!!this.state.isFetching}
          okDelete={this.deleteConfirmToggle}
          cancelDelete={this.deleteToggle}
        />
      </div>
    );
  }

  public renderSupplierList(data: any): any {
    let supplierList;
    let supplierData =
      data.length > 0
        ? data?.map((supplier: any) => {
            supplier["name"] = commonService
              .applyLocalization("restaurant", "name", supplier?.locales)
              .name.toLowerCase();
            return supplier;
          })
        : [];
    supplierData = _.sortBy(supplierData, "name");

    if (!!data && data.length > 0) {
      supplierList = supplierData.map((locales: any, i: number) => {
        return (
          <SupplierCard
            key={i}
            setEditable={this.setEditable.bind(this, locales)}
            setDelete={this.setDelete.bind(this, locales)}
            locales={locales}
          />
        );
      });
      return supplierList;
    }
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.setState({
      isFetching: true,
    });
    this.loadSupplierList();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      this.props.restaurants.length !== prevProps.restaurants.length ||
      this.state.supplierList.length !== prevState.supplierList.length ||
      JSON.stringify(this.props.restaurants) !==
        JSON.stringify(prevProps.restaurants) ||
      JSON.stringify(this.state.supplierList) !==
        JSON.stringify(prevState.supplierList)
    ) {
      this.buildRestaurantOptions("");
    }
  }

  loadSupplierList = () => {
    api
      .get(
        "/api/1.0/restaurant/" +
          this.props.match.params.uuid +
          "/supplier?sort=name:asc",
        {}
      )
      .then((response: any) => {
        if (!!response.status) {
          this.setState({
            supplierList: response.data,
          });
        } else {
          handleResponse(response);
        }
        this.setState({
          isFetching: false,
        });
      });
  };
  public AddToggle = () => {
    this.resetFormFields();
    this.setState((prevState: any) => ({
      addmodal: !prevState.addmodal,
      restaurant: {},
    }));
    this.buildRestaurantOptions("");
  };

  public saveSupplier = () => {
    this.setState({
      isFetching: true,
    });
    api
      .post(
        "/api/1.0/restaurant/" + this.props.match.params.uuid + "/supplier ",
        {
          email: this.state.email,
          phone_number: this.state.phoneNumber,
          vendor_number: this.state.vendorNumber,
          locales: {
            [this.props.restaurantLang[0].code]: {
              name: this.state.name,
            },
          },
          email_template: this.state.emailTemplate.value,
          supplier_restaurant: this.state.restaurant.value,
          restaurant_inventory_access: this.state.restaurantInventoryAccess,
          restaurant_allow_partial_delivery:
            this.state.restaurantAllowPartialDelivery,
          delivery_schedule: this.state.deliverySchedule,
        }
      )
      .then((response: any) => {
        this.setState({
          isFetching: false,
        });
        if (!!response.data && !!response.data.status) {
          this.AddToggle();
          this.loadSupplierList();
        }
        handleResponse(response);
      })
      .catch((error: Error) => {
        this.setState({
          isFetching: false,
        });
      });
  };

  public saveEdit = () => {
    this.setState({
      isFetching: true,
    });
    api
      .put(
        "/api/1.0/restaurant/" +
          this.props.match.params.uuid +
          "/supplier/" +
          this.state.uuid,
        {
          email: this.state.email,
          phone_number: this.state.phoneNumber,
          vendor_number: this.state.vendorNumber,
          locales: {
            [this.props.restaurantLang[0].code]: {
              name: this.state.name,
            },
          },
          email_template: this.state.emailTemplate.value,
          supplier_restaurant: this.state.restaurant.value,
          restaurant_inventory_access: this.state.restaurantInventoryAccess,
          restaurant_allow_partial_delivery:
            this.state.restaurantAllowPartialDelivery,
          delivery_schedule: this.state.deliverySchedule,
        }
      )
      .then((response: any) => {
        this.setState({
          isFetching: false,
        });
        if (!!response.data && !!response.data.status) {
          this.toggle();
          this.loadSupplierList();
        }
        handleResponse(response);
      })
      .catch((error: Error) => {
        this.setState({
          isFetching: false,
        });
      });
  };

  public toggle = () => {
    this.setState((prevState: any) => ({
      editmodal: !prevState.editmodal,
    }));
  };

  public cancelManagetoggle = () => {
    this.setState((prevState: any) => ({
      managemodal: !prevState.managemodal,
    }));
  };

  public addRighttoggle = () => {
    this.setState((prevState: any) => ({
      addrightmodal: !prevState.addrightmodal,
    }));
  };

  public managerighttoggle(event: any, roler_uuid: any): void {
    this.setState((prevState: any) => ({
      managemodal: !prevState.managemodal,
      manageright: event,
      roler_uuid: roler_uuid,
    }));
    this.manageRightList = event;
  }

  public setDelete = (event: any) => {
    this._setStates("uuid", event.uuid);
    this.deleteToggle();
  };

  public deleteToggle = () => {
    this.setState((prevState: any) => ({
      open: !prevState.open,
    }));
  };

  public deleteConfirmToggle = () => {
    this.setState({
      isFetching: true,
    });
    api
      .delete(
        "/api/1.0/restaurant/" +
          this.props.match.params.uuid +
          "/supplier/" +
          this.state.uuid,
        {}
      )
      .then((response: any) => {
        this.setState({
          isFetching: false,
        });
        if (!!response.data && !!response.data.status) {
          this.deleteToggle();
          this.loadSupplierList();
        }
        handleResponse(response);
      })
      .catch((error: Error) => {
        this.setState({
          isFetching: false,
        });
      });
    this._setStates("isUpdated", true);
  };

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  public setEditable(event: any): void {
    this._setStates("emailTemplateOptions", this.state.allEmailTemplates);
    this.setState({
      name: commonService.applyLocalization(
        "restaurant",
        "name",
        event.locales
      )["name"],
      uuid: event.uuid,
      email: event.email,
      phoneNumber: event.phone_number,
      vendorNumber: event.vendor_number,
      emailTemplate: {
        label: !!event?.email_template
          ? this.state.allEmailTemplates.find(
              (item: any) => item.value === event.email_template
            )?.label
          : this.props.t("email.noEmail"),
        value: !!event?.email_template ? event?.email_template : "",
      },
      restaurant: event.supplier_restaurant
        ? {
            label: commonService.applyLocalization(
              "restaurant",
              "name",
              this.props.restaurants.find(
                (item: any) => item.uuid === event.supplier_restaurant
              )?.locales
            )["name"],
            value: event.supplier_restaurant,
          }
        : "",
      restaurantInventoryAccess: event.restaurant_inventory_access,
      restaurantAllowPartialDelivery: event.allow_partial_delivery,
    });
    this.handleChange({
      target: {
        name: "deliverySchedule",
        value: event?.delivery_schedule ?? "",
      },
    });
    if (event.supplier_restaurant) {
      this.setSupplierInfo(event.supplier_restaurant);
    }
    this.buildRestaurantOptions(event.supplier_restaurant);
    this.toggle();
  }

  public handleChange = (e: any) => {
    if (e.target.name === "deliverySchedule") {
      if (e.target.value === "") {
        this._setStates("isValidCrontab", true);
      } else {
        this._setStates(
          "isValidCrontab",
          isValidCron(e.target.value, {
            seconds: true,
            alias: true,
            allowBlankDay: true,
          })
        );
      }
    }
    if (e.target.name === "restaurantAllowPartialDelivery") {
      this._setStates(e.target.name, e.target.checked);
    } else {
      this._setStates(e.target.name, e.target.value);
    }
  };
}

const mapStateToProps: any = (state: any) => {
  return {
    restaurants: state.restaurant.data,
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
  };
};
const mapDispatchToProps: object = (dispatch: any) => {
  return {};
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(SupplierComponent))
);
