export interface StationsModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface StationsState {
  stations: any;
  isFetching: boolean;
  open: boolean;
  uuid: string;
  isUpdated: boolean;
  addmodal: boolean;
  restaurantuuid: string;
  stationname: string;
  color: string;
  capacity: any;
  stationuuid: string;
  station_uuid: string;
  addstationmodal: boolean;
  editstationmodal: boolean;
  openstation: boolean;
  displayColorPicker: boolean;
  roomuuid: String;
  roomList: any;
  printerList: any;
  printerUuid: any;
  pickingLocation: any;
  stationOptionList: Array<any>;
}

export const StationInitState = {
  stations: [],
  isFetching: false,
  open: false,
  uuid: "",
  isUpdated: false,
  addmodal: false,
  restaurantuuid: "",
  stationname: "",
  color: "#FFFFFF",
  capacity: "",
  stationuuid: "",
  station_uuid: "",
  addstationmodal: false,
  editstationmodal: false,
  openstation: false,
  displayColorPicker: false,
  roomuuid: "",
  roomList: [],
  printerList: [],
  printerUuid: "",
  pickingLocation: null,
  stationOptionList: [],
};
