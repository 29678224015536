import '../../styles/forgotpassword.scss';

import React, { Component } from 'react';

import ResetPasswordComponent from '../../components/reset-password/reset-password';

class ResetPasswordPage extends Component {

    render() {
        return (
            <div className="forgotpassword-style">
                <ResetPasswordComponent/>
            </div>
        );
    }
}
export default ResetPasswordPage;
