export interface qrCodeModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}

export interface QRCodeState {
  isDeleteModalOpen: boolean;
  isFetching: boolean;
  isUpdated: boolean;
  qrCodeBaseUrl: Array<any>;
  qrCodes: Array<object>;
  rooms: Object;
  roomsGrouped: Array<object>;
  selectedCodes: Array<string>;
  selectedItemId: Array<string>;
}

export const QRCodeInitState = {
  isDeleteModalOpen: false,
  isFetching: false,
  isUpdated: false,
  qrCodeBaseUrl: [],
  qrCodes: [],
  rooms: {},
  roomsGrouped: [],
  selectedCodes: [],
  selectedItemId: [],
};
export interface QRCodeListState {
  items: any;
  rooms: any;
  selected: any;
  stations: any;
  tables: any;
}

export const QRCodeListInitState = {
  items: [],
  rooms: [],
  selected: [],
  stations: [],
  tables: [],
};

export interface QRCodeRoomState {
  isOpen: boolean;
}

export const QRCodeRoomInitState = {
  isOpen: false,
};

export interface QRCodeRowState {
  isChecked: boolean;
}
