import { call, put, takeLatest } from "redux-saga/effects";
import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  guestGroupFailure,
  guestGroupSuccess,
  guestGroupUpdateFailure,
  guestGroupUpdateSuccess,
} from "../redux/actions/guest.group.action";

import * as types from "../redux/actions/types";
import guestGroupService from "../services/guest.group.service";
import handleResponse from "../services/response.service";

const guestGroupCall = (params: any) =>
  guestGroupService.guestGroup(RESTAURANT_REQUEST, params);

const updateGuestGroupCall = (params: any) =>
  guestGroupService.updateGuestGroup(RESTAURANT_REQUEST, params);

function* handleGuestGroupRequest(params: object) {
  try {
    let result;
    result = yield call(guestGroupCall, params);
    yield handleGuestGroupRequest200(result);
  } catch (error) {
    throw error;
  }
}

const handleGuestGroupRequest200 = function* handleGuestGroupRequest200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(guestGroupSuccess(result.data));
  } else {
    yield put(guestGroupFailure(result.data));
    handleResponse(result);
  }
};

function* handleGuestGroupUpdate(params: object) {
  try {
    let result: any;
    result = yield call(updateGuestGroupCall, params);
    yield handleGuestGroupUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleGuestGroupUpdate200 = function* handleGuestGroupUpdate200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(guestGroupUpdateSuccess(result.data));
  } else {
    yield put(guestGroupUpdateFailure(result.data));
  }
  handleResponse(result);
};

export default function* guestGroupSaga() {
  yield takeLatest(types.GUEST_GROUPS.REQUEST, handleGuestGroupRequest);
  yield takeLatest(types.GUEST_GROUP_UPDATE.REQUEST, handleGuestGroupUpdate);
}
