export interface CourseListModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export const CourseListModelInitState = {
  isFetching: false,
  data: {},
  error: {},
  failure: false,
};

export interface CourseListState {
  color: string;
  description: string;
  elementId: string;
  fetchData: boolean;
  globalUnits: Array<object>;
  isAddItemModalOpen: boolean;
  isEdit: boolean;
  isFetching: boolean;
  isSaveButtonDisabled: boolean;
  items: Array<object>;
  itemsDetails: Array<object>;
  labelOptions: Array<object>;
  labels: Array<object>;
  name: string;
  outputquantity: string;
  outputUnitUuid: string;
  printerName: string;
  recipeDetails: any;
  rooms: Array<object>;
  selectedLabels: Array<object>;
  storage: Array<object>;
  tags: Array<object>;
  units: Array<object>;
  itemsList: any;
  removeItemData: any;
  isJustMounted: boolean;
  isDeleted: boolean;
  failure: boolean;
}

export const CourseListInitState = {
  color: "#FFFFFF",
  description: "",
  elementId: "",
  fetchData: false,
  globalUnits: [],
  isAddItemModalOpen: false,
  isEdit: false,
  isFetching: false,
  isSaveButtonDisabled: true,
  items: [],
  itemsDetails: [],
  labelOptions: [],
  labels: [],
  name: "",
  outputquantity: "",
  outputUnitUuid: "",
  printerName: "",
  recipeDetails: {},
  rooms: [],
  selectedLabels: [],
  storage: [],
  tags: [],
  units: [],
  itemsList: "",
  removeItemData: {},
  isJustMounted: false,
  isDeleted: false,
  failure: false,
};

export interface CourseListCardState {
  courseListName: string;
  courseListNames: Array<any>;
  cardItemData: any;
  output_quantity: string;
  output_unit: string;
  periodicity_item_uuid: string;
  recipe_uuid: string;
  selectedPeriodicity: Array<string>;
  selectedRecipe: any;
  type?: string;
  warnings: any;
}
export const CourseListCardInitState = {
  courseListName: "",
  courseListNames: [],
  cardItemData: null,
  output_quantity: "",
  output_unit: "",
  periodicity_item_uuid: "",
  recipe_uuid: "",
  selectedPeriodicity: [],
  selectedRecipe: {},
  warnings: {
    output_quantity: null,
    periodicity_item_uuid: null,
    selectedRecipe: null,
  },
};
