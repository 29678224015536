import React, { Component } from "react";

import QRCodesComponent from "../../components/qrcodes/qrcodes";

class QRCodesPage extends Component {
  render() {
    return <QRCodesComponent />;
  }
}
export default QRCodesPage;
