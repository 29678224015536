import { USERNAME } from "../constant/constant";

export interface LoginModel {
  readonly isAuthenticated: boolean;
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface LoginState {
  isFetching: boolean;
  username: any;
  password: any;
  validate: boolean;
  rememberMe: boolean;
  shopLogin: boolean;
  corporateColor: string;
  restaurantLogo: string;
}

export const LoginInitState = {
  isFetching: false,
  username: localStorage.getItem(USERNAME)
    ? localStorage.getItem(USERNAME)
    : "",
  password: "",
  validate: false,
  rememberMe: false,
  shopLogin: false,
  corporateColor: "",
  restaurantLogo: "",
};
