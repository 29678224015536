import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function clearServiceSetDetailsRequest(
  credentials: object
): ActionModel {
  return {
    type: types.SERVICE_SET_DETAILS.REMOVE,
    credentials,
  };
}

export function serviceSetDetailsRequest(credentials: object): ActionModel {
  return {
    type: types.SERVICE_SET_DETAILS.REQUEST,
    credentials,
  };
}

export function serviceSetDetailsSuccess(data: object): ActionModel {
  return {
    type: types.SERVICE_SET_DETAILS.SUCCESS,
    data,
  };
}

export function serviceSetDetailsFailure(err: object): ActionModel {
  return {
    type: types.SERVICE_SET_DETAILS.FAILURE,
    err,
  };
}

export function serviceSetUpdateRecipes(credentials: object): ActionModel {
  return {
    type: types.SERVICE_SET_RECIPES_UPDATE.REQUEST,
    credentials,
  };
}

export function serviceSetUpdateRecipesSuccess(data: object): ActionModel {
  return {
    type: types.SERVICE_SET_RECIPES_UPDATE.SUCCESS,
    data,
  };
}

export function serviceSetUpdateRecipesFailure(err: object): ActionModel {
  return {
    type: types.SERVICE_SET_RECIPES_UPDATE.FAILURE,
    err,
  };
}
