import apiCall from "./api.call.config";

async function addTask(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/task",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function addTaskIngredients(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/task/" +
        params.credentials.taskuuid +
        "/ingredient/manage",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function removeTask(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/task/" +
        params.credentials.uuid
    );
  } catch (e) {
    throw e;
  }
}
async function removeTaskIngredients(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/task/" +
        params.credentials.taskuuid +
        "/ingredient/" +
        params.credentials.uuid
    );
  } catch (e) {
    throw e;
  }
}

async function task(url: string, params: any) {
  try {
    // eslint-disable-next-line
    let urlParams: string = "";
    if (params.credentials?.prepSetId)
      urlParams += "?ps=" + params.credentials?.prepSetId;
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/task" +
        urlParams
    );
  } catch (e) {
    throw e;
  }
}

async function taskIngredients(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/task/" +
        params.credentials.taskuuid +
        "/ingredient"
    );
  } catch (e) {
    throw e;
  }
}

async function updateTask(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/task/" +
        params.credentials.uuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function updateTaskIngredients(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/task/" +
        params.credentials.taskuuid +
        "/ingredient/" +
        params.credentials.uuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  addTask,
  addTaskIngredients,
  removeTask,
  removeTaskIngredients,
  task,
  taskIngredients,
  updateTask,
  updateTaskIngredients,
};
