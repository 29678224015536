import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function eventListRequest(credentials: object): ActionModel {
    return {
        type: types.LIST_EVENT.REQUEST,
        credentials
    };
}

export function eventListSuccess(data?: object): ActionModel {
    return {
        type: types.LIST_EVENT.SUCCESS,
        data
    };
}

export function eventListFailure(err: object): ActionModel {
    return {
        type: types.LIST_EVENT.FAILURE,
        err
    };
}

export function addEventRequest(credentials: object): ActionModel {
    return {
        type: types.ADD_EVENT.REQUEST,
        credentials
    };
}

export function addEventSuccess(data?: object): ActionModel {
    return {
        type: types.ADD_EVENT.SUCCESS,
        data
    };
}

export function addEventFailure(err: object): ActionModel {
    return {
        type: types.ADD_EVENT.FAILURE,
        err
    };
}

export function eventRequest(credentials: object): ActionModel {
    return {
        type: types.GET_EVENT.REQUEST,
        credentials
    };
}

export function eventSuccess(data: object): ActionModel {
    return {
        type: types.GET_EVENT.SUCCESS,
        data
    };
}

export function eventFailure(err: object): ActionModel {
    return {
        type: types.GET_EVENT.FAILURE,
        err
    };
}

export function eventUpdateRequest(credentials: object): ActionModel {
    return {
        type: types.UPDATE_EVENT.REQUEST,
        credentials
    };
}

export function eventUpdateSuccess(data?: object): ActionModel {
    return {
        type: types.UPDATE_EVENT.SUCCESS,
        data
    };
}

export function eventUpdateFailure(err: object): ActionModel {
    return {
        type: types.UPDATE_EVENT.FAILURE,
        err
    };
}
