import * as types from './types';
import { ActionModel } from '../../models/action.model';

export function emailconfirmationRequest(credentials: any): ActionModel {
  return {
    type: types.EMAIL_CONFORMATION.REQUEST,
    credentials
  };
}

export function emailconfirmationSuccess(data: any): ActionModel {
  return {
    type: types.EMAIL_CONFORMATION.SUCCESS,
    data
  };
}

export function emailconfirmationFailure(err: any): ActionModel {
  return {
    type: types.EMAIL_CONFORMATION.FAILURE,
    err
  };
}
