import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function supportRequest(credentials: object): ActionModel {
  return {
    type: types.SUPPORT.REQUEST,
    credentials
  };
}

export function supportSuccess(data: object): ActionModel {
  return {
    type: types.SUPPORT.SUCCESS,
    data
  };
}

export function supportFailure(err: object): ActionModel {
  return {
    type: types.SUPPORT.FAILURE,
    err
  };
}







