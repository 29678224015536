import { ServiceSetDetailsModel } from "../../models/serviceset.details.model";
import * as types from "../actions/types";

const initialState: ServiceSetDetailsModel = {
  isFetching: false,
  data: {},
  error: {},
  failure: false,
};

export default function serviceSetDetailsReducer(
  state = initialState,
  action: any
): any {
  switch (action.type) {
    case types.SERVICE_SET_DETAILS.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.SERVICE_SET_DETAILS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.SERVICE_SET_DETAILS.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    case types.SERVICE_SET_DETAILS.REMOVE:
      return {
        ...state,
        isFetching: false,
        data: {},
        failure: false,
        error: {},
      };
    default:
      return state;
  }
}

export function serviceSetUpdateRecipesReducer(
  state = initialState,
  action: any
): any {
  switch (action.type) {
    case types.SERVICE_SET_RECIPES_UPDATE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.SERVICE_SET_RECIPES_UPDATE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.SERVICE_SET_RECIPES_UPDATE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
