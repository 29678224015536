import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function translationRequest(credentials: object): ActionModel {
  return {
    type: types.TRANSLATION.REQUEST,
    credentials,
  };
}

export function translationSuccess(data: object): ActionModel {
  return {
    type: types.TRANSLATION.SUCCESS,
    data,
  };
}

export function translationFailure(err: object): ActionModel {
  return {
    type: types.TRANSLATION.FAILURE,
    err,
  };
}
