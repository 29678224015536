import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function unitsRequest(credentials: object): ActionModel {
  return {
    type: types.UNITS.REQUEST,
    credentials
  };
}

export function unitsSuccess(data: object): ActionModel {
  return {
    type: types.UNITS.SUCCESS,
    data
  };
}

export function unitsFailure(err: object): ActionModel {
  return {
    type: types.UNITS.FAILURE,
    err
  };
}

export function unitsUpdate(credentials: object): ActionModel {
  return {
    type: types.UNITS_UPDATE.REQUEST,
    credentials
  };
}

export function unitsUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.UNITS_UPDATE.SUCCESS,
    data
  };
}

export function unitsUpdateFailure(err: object): ActionModel {
  return {
    type: types.UNITS_UPDATE.FAILURE,
    err
  };
}

export function unitsAdd(credentials: object): ActionModel {
  return {
    type: types.UNITS_ADD.REQUEST,
    credentials
  };
}

export function unitsAddSuccess(data?: object): ActionModel {
  return {
    type: types.UNITS_ADD.SUCCESS,
    data
  };
}

export function unitsAddFailure(err: object): ActionModel {
  return {
    type: types.UNITS_ADD.FAILURE,
    err
  };
}

export function unitsRemove(credentials: object): ActionModel {
  return {
    type: types.UNITS_REMOVE.REQUEST,
    credentials
  };
}

export function unitsRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.UNITS_REMOVE.SUCCESS,
    data
  };
}

export function unitsRemoveFailure(err: object): ActionModel {
  return {
    type: types.UNITS_REMOVE.FAILURE,
    err
  };
}
export function conversionAdd(credentials: object): ActionModel {
  return {
    type: types.CONVERSION_ADD.REQUEST,
    credentials
  };
}

export function conversionAddSuccess(data?: object): ActionModel {
  return {
    type: types.CONVERSION_ADD.SUCCESS,
    data
  };
}

export function conversionAddFailure(err: object): ActionModel {
  return {
    type: types.CONVERSION_ADD.FAILURE,
    err
  };
}

export function conversionEdit(credentials: object): ActionModel {
  return {
    type: types.CONVERSION_EDIT.REQUEST,
    credentials
  };
}

export function conversionEditSuccess(data?: object): ActionModel {
  return {
    type: types.CONVERSION_EDIT.SUCCESS,
    data
  };
}

export function conversionEditFailure(err: object): ActionModel {
  return {
    type: types.CONVERSION_EDIT.FAILURE,
    err
  };
}

export function conversionDelete(credentials: object): ActionModel {
  return {
    type: types.CONVERSION_DELETE.REQUEST,
    credentials
  };
}

export function conversionDeleteSuccess(data?: object): ActionModel {
  return {
    type: types.CONVERSION_DELETE.SUCCESS,
    data
  };
}

export function conversionDeleteFailure(err: object): ActionModel {
  return {
    type: types.CONVERSION_DELETE.FAILURE,
    err
  };
}