import apiCall from './api.call.config';

async function taskInterventionStep(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.get(url + '/' + params.credentials.restaurantuuid + '/recipe/' + params.credentials.recipeuuid
            + '/task/' + params.credentials.taskuuid + '/intervention/' + params.credentials.interventionuuid + '/step');
    } catch (e) {
        throw e;
    }
}

async function addTaskInterventionStep(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url + '/' + params.credentials.restaurantuuid + '/recipe/' + params.credentials.recipeuuid
            + '/task/' + params.credentials.taskuuid + '/intervention/' + params.credentials.interventionuuid
            + '/step', params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function updateTaskInterventionStep(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.put(url + '/' + params.credentials.restaurantuuid + '/recipe/' + params.credentials.recipeuuid
            + '/task/' + params.credentials.taskuuid + '/intervention/' + params.credentials.interventionuuid + '/step/'
            + params.credentials.uuid, params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function removeTaskInterventionStep(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.remove(url + '/' + params.credentials.restaurantuuid + '/recipe/' + params.credentials.recipeuuid
            + '/task/' + params.credentials.taskuuid + '/intervention/' + params.credentials.interventionuuid + '/step/'
            + params.credentials.uuid);
    } catch (e) {
        throw e;
    }
}

export default {
    taskInterventionStep,
    addTaskInterventionStep,
    updateTaskInterventionStep,
    removeTaskInterventionStep
}
