import "../../styles/page404.scss";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Alert, Button, Col, Table } from "reactstrap";
import { ORDER_STATUS, ORDER_TYPE, WHITE } from "../../constant/constant";

import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import commonService from "../../services/common.service";
class ListOfRequests extends Component<any> {
  totalCost = () => {
    const total = this.props.data?.reduce(
      (tCost: number, r: any) => tCost + r.totalPrice,
      0
    );
    return commonService.displayDigitalAmount(total);
  };

  render() {
    const activeRestaurant = JSON.parse(
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
    );
    const { t, data } = this.props;
    return (
      <>
        {data?.length === 0 && (
          <Alert color="info">{t("common.noRecords")}</Alert>
        )}
        {data?.length > 0 && (
          <>
            <h5>{t("reports.requestsForTimeFrame")}</h5>
            {data?.map((record: any, index: any) => {
              const orderStatus = ORDER_STATUS.find(
                (s) => s.value === record.status
              );
              const orderType = ORDER_TYPE.find((t) => t.value === record.type);
              const title = orderType
                ? orderType.value === 2
                  ? record.supplierRestaurantName
                  : orderType.value === 0
                  ? record.tableName + " / " + record.courseName
                  : t(orderType.label)
                : null;
              return (
                <section className="mt-4">
                  <div className="d-flex  align-items-center justify-content-between">
                    <h6 className=" mb-2">
                      {title && title + " - "}
                      {record.uuid.slice(-8)}
                    </h6>
                    <div
                      style={{ minWidth: "auto" }}
                      className="d-flex align-items-center justify-content-between"
                    >
                      {orderStatus &&
                        orderType &&
                        orderStatus.value === 1 &&
                        (orderType.value === 0 || orderType.value === 2) && (
                          <Button className=" mb-2" color="info" size="sm">
                            {t("reports.printShippingNote") + " "}
                            <FontAwesomeIcon
                              style={{ color: WHITE }}
                              icon={faPrint}
                            />
                          </Button>
                        )}
                      <h6
                        className="ml-2 mb-2"
                        style={{ width: 120, textAlign: "right" }}
                      >
                        {`${commonService.displayDigitalAmount(
                          record.totalPrice
                        )} ${activeRestaurant?.currency_code}`}
                      </h6>
                    </div>
                  </div>
                  <Table bordered striped>
                    <thead>
                      <tr className="bg-light">
                        <th className="w-16">
                          {t("reports.expectedDeliveryTime")}
                        </th>
                        <th className="w-14">{t("common.status")}</th>
                        <th className="w-15">{t("common.type")}</th>
                        <th className="w-55">{t("reports.requestItems")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <td>{record.expectedDeliveryTime}</td>
                      <td>{orderStatus && t(orderStatus.label)}</td>
                      <td>{orderType && t(orderType.label)}</td>
                      <td className="p-0">
                        <Table striped className="mb-0">
                          <tbody>
                            {record?.recipes?.map((recipe: any) => {
                              return (
                                <tr
                                  style={
                                    recipe.rejected ? { color: "red" } : {}
                                  }
                                >
                                  <td style={{ width: 50, textAlign: "right" }}>
                                    {recipe.orderQuantity}
                                  </td>
                                  <td>{`${recipe.recipeName} (${recipe.outputQuantity} ${recipe.orderUnit})`}</td>
                                  <td
                                    style={{ width: 140, textAlign: "right" }}
                                  >{`${
                                    recipe.price !== 0
                                      ? commonService.displayDigitalAmount(
                                          recipe.price
                                        ) +
                                        " " +
                                        activeRestaurant?.currency_code
                                      : ""
                                  }`}</td>
                                  <td
                                    style={{ width: 140, textAlign: "right" }}
                                  >{`${
                                    recipe.totalPrice !== 0
                                      ? commonService.displayDigitalAmount(
                                          recipe.totalPrice
                                        ) +
                                        " " +
                                        activeRestaurant?.currency_code
                                      : ""
                                  }`}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </td>
                    </tbody>
                  </Table>
                </section>
              );
            })}
            <Col className="col-lg-12 mb-2 pr-0">
              <div style={{ textAlign: "right" }}>
                <strong>
                  {`${t(
                    "reports.totalTurnoverForDefinedFilter"
                  )}: ${this.totalCost()} ${activeRestaurant?.currency_code}`}
                </strong>
              </div>
            </Col>
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withRouter(ListOfRequests));
