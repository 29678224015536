import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function ingredientsRequest(credentials: object): ActionModel {
  return {
    type: types.INGREDIENTS.REQUEST,
    credentials,
  };
}

export function ingredientsSuccess(data: object): ActionModel {
  return {
    type: types.INGREDIENTS.SUCCESS,
    data,
  };
}

export function ingredientsFailure(err: object): ActionModel {
  return {
    type: types.INGREDIENTS.FAILURE,
    err,
  };
}

export function ingredientsUpdate(credentials: object): ActionModel {
  return {
    type: types.INGREDIENTS_UPDATE.REQUEST,
    credentials,
  };
}

export function ingredientsUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.INGREDIENTS_UPDATE.SUCCESS,
    data,
  };
}

export function ingredientsUpdateFailure(err: object): ActionModel {
  return {
    type: types.INGREDIENTS_UPDATE.FAILURE,
    err,
  };
}

export function ingredientsAdd(credentials: object): ActionModel {
  return {
    type: types.INGREDIENTS_ADD.REQUEST,
    credentials,
  };
}

export function ingredientsAddSuccess(data?: object): ActionModel {
  return {
    type: types.INGREDIENTS_ADD.SUCCESS,
    data,
  };
}

export function ingredientsAddFailure(err: object): ActionModel {
  return {
    type: types.INGREDIENTS_ADD.FAILURE,
    err,
  };
}

export function ingredientsRemove(credentials: object): ActionModel {
  return {
    type: types.INGREDIENTS_REMOVE.REQUEST,
    credentials,
  };
}

export function ingredientsRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.INGREDIENTS_REMOVE.SUCCESS,
    data,
  };
}

export function ingredientsRemoveFailure(err: object): ActionModel {
  return {
    type: types.INGREDIENTS_REMOVE.FAILURE,
    err,
  };
}

export function ingredientDetailRequest(credentials: object): ActionModel {
  return {
    type: types.INGREDIENTS_DETAIL.REQUEST,
    credentials,
  };
}

export function ingredientDetailRequestSuccess(data?: object): ActionModel {
  return {
    type: types.INGREDIENTS_DETAIL.SUCCESS,
    data,
  };
}

export function ingredientDetailRequestFailure(err: object): ActionModel {
  return {
    type: types.INGREDIENTS_DETAIL.FAILURE,
    err,
  };
}

export function ingredientCostRequest(credentials: object): ActionModel {
  return {
    type: types.INGREDIENT_COST.REQUEST,
    credentials,
  };
}

export function ingredientCostRequestSuccess(data?: object): ActionModel {
  return {
    type: types.INGREDIENT_COST.SUCCESS,
    data,
  };
}

export function ingredientCostRequestFailure(err: object): ActionModel {
  return {
    type: types.INGREDIENT_COST.FAILURE,
    err,
  };
}

export function ingredientCostUpdate(credentials: object): ActionModel {
  return {
    type: types.INGREDIENT_COST_UPDATE.REQUEST,
    credentials,
  };
}

export function ingredientCostUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.INGREDIENT_COST_UPDATE.SUCCESS,
    data,
  };
}

export function ingredientCostUpdateFailure(err: object): ActionModel {
  return {
    type: types.INGREDIENT_COST_UPDATE.FAILURE,
    err,
  };
}
