import React, { Component } from "react";
import { withCardItemSelectionState } from "../cardItemSelectionManager";
import {
  CardItemCardComponent,
  CardItemContainer,
} from "../card-components/card-item";
import WarningComment, {
  getMandatoryWarning,
} from "../warning-comment/warning-comment";
import InlineEditInput from "../inline-edit-input/inline-edit-input";
import { Col, Input, Row } from "reactstrap";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import commonService from "../../services/common.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PHILIPPINE_GRAY, WHITE } from "../../constant/constant";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

interface IiTemState {
  cardItemData: any;
  days: any;
  name: any;
  type?: string;
  warnings?: any;
}
class ItemListCard extends Component<any, any> {
  state: IiTemState;
  constructor(props: any) {
    super(props);
    this.state = {
      cardItemData: props.cardItemData,
      days: null,
      name: "",
      warnings: {
        name: null,
        days: null,
      },
    };
    this._setFunctionBindings();
  }

  componentDidMount() {
    this._loadValues();
  }
  private _loadValues() {
    if (this.state?.type === "newCard") {
      this.setState({ days: null, name: "" });
    } else if (
      this.props.cardItemSelectionData.selectionState.isItemSelected &&
      this.props.cardItemSelectionData.selectionState.mode === "edit"
    ) {
      const name = commonService.applyLocalization(
        "restaurant",
        "name",
        this.state.cardItemData.locales
      )["name"];
      this.setState({
        days: this.state.cardItemData.days,
        name: name,
        warnings: {
          name: getMandatoryWarning(name),
          days: getMandatoryWarning(this.state.cardItemData.days),
        },
      });
    } else {
      this._setInitialValues();
    }
  }
  private _setFunctionBindings(): void {
    this._loadValues = this._loadValues.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  private _setInitialValues() {
    const name = commonService.applyLocalization(
      "restaurant",
      "name",
      this.props.cardItemData.locales
    )["name"];
    this.setState({
      days: this.props.cardItemData.days,
      name: name,
      warnings: {
        name: getMandatoryWarning(name),
        days: getMandatoryWarning(this.props.cardItemData.days),
      },
    });
  }

  render() {
    const { t } = this.props;
    return (
      <CardItemContainer className={"task-type px-0"}>
        <CardItemCardComponent
          cardItemSelectionData={this.props.cardItemSelectionData}
          className={"task-type assigned"}
          deleteCallback={this.deleteCard}
          onDeleteClick={this.deleteCard}
          readOnly={false}
          saveCallback={this.saveCard}
          selectCallback={this.selectCard}
          saveButton={true}
        >
          <Row>
            <Col lg={6}>
              <WarningComment
                data={this.state.warnings?.name}
                displayType={"bottom"}
              >
                <div className="d-flex">
                  {!this.props.cardItemSelectionData.selectionState
                    .isBeingEdited && (
                    <FontAwesomeIcon
                      className="mr-1 mt-1"
                      style={{
                        color:
                          this.props.cardItemSelectionData.className !== ""
                            ? WHITE
                            : PHILIPPINE_GRAY,
                      }}
                      icon={faEllipsisV}
                    />
                  )}
                  <InlineEditInput
                    name="name"
                    value={this.state.name}
                    editElement={Input}
                    edit={
                      this.props.cardItemSelectionData.selectionState
                        .isBeingEdited
                    }
                    style={{ display: "block" }}
                    propsEdit={{
                      type: "text",
                      placeholder: t("periodicity.name"),
                    }}
                    onChange={this.handleChange}
                  />
                </div>
              </WarningComment>
            </Col>
            <Col lg={4}>
              <WarningComment
                data={this.state.warnings?.days}
                displayType={"bottom"}
              >
                <div className="d-flex align-items-center">
                  <InlineEditInput
                    name="days"
                    value={this.state.days}
                    editElement={Input}
                    edit={
                      this.props.cardItemSelectionData.selectionState
                        .isBeingEdited
                    }
                    style={{ display: "block" }}
                    propsEdit={{
                      type: "number",
                      placeholder: t("common.noOfDays"),
                    }}
                    pattern={"^([0-9]{0,5})$"}
                    onChange={this.handleChange}
                  />
                  <span className="px-2">({t("common.days")})</span>
                </div>
              </WarningComment>
            </Col>
          </Row>
        </CardItemCardComponent>
      </CardItemContainer>
    );
  }

  deleteCard = () => {
    this.props.onDeleteCard(this.props.indx);
  };

  handleChange = (inputName: any, value: any) => {
    const warnings: any = { ...this.state.warnings };
    if (inputName === "name") {
      this.setState({ name: value });
      warnings.name = getMandatoryWarning(value.trim());
    } else {
      this.setState({ days: parseInt(value) });
      const isValid = parseInt(value) < 0 ? null : value;
      warnings.days = getMandatoryWarning(isValid);
    }
    this.setState({ warnings });
  };
  saveCard = () => {
    if (!this.state.name || !this.state.days) {
      this.setState({
        warnings: {
          days: getMandatoryWarning(this.state.days || ""),
          name: getMandatoryWarning(this.state.name || ""),
        },
      });
    }

    if (
      this.state.warnings.name === null &&
      this.state.warnings.days === null &&
      this.state.days &&
      this.state.name
    ) {
      const lang = localStorage.getItem("i18nextLng") || "de-ch";
      let locales: any = {};
      locales[lang] = { name: this.state.name };
      let formData: any = {
        locales,
        days: Number(this.state.days),
      };
      if (this.props?.indx >= 0) {
        formData.indx = this.props.indx;
      }
      this.props.onSaveCard(formData);
      if (this.props.cardItemId === "newCard") {
        this.setState({
          name: null,
          days: null,
          warnings: {
            days: getMandatoryWarning(0),
            name: getMandatoryWarning(0),
          },
        });
      }
    }
  };
  selectCard = () => {
    if (this.props.cardItemSelectionData.selectionState.mode === "edit") {
      this._loadValues();
    }
    this.props.cardItemSelectionData.selectCardItem();
  };
}

export default withTranslation()(
  withRouter(withCardItemSelectionState("ItemListCard", 1)(ItemListCard))
);
