export interface ForgotPasswordModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface ForgotPasswordState {
  isFetching: boolean;
  email: any;
  password: string;
  new_password: string;
  validate: boolean;
  shopLogin: boolean;
  corporateColor: string;
  restaurantLogo: string;
}

export const ForgotPasswordInitState = {
  isFetching: false,
  email: "",
  password: "",
  new_password: "",
  validate: false,
  shopLogin: false,
  corporateColor: "",
  restaurantLogo: "",
};
