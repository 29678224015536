import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function recipeSubstituteRequest(credentials: object): ActionModel {
  return {
    type: types.RECIPE_SUBSTITUTE.REQUEST,
    credentials,
  };
}

export function recipeSubstituteSuccess(data: object): ActionModel {
  return {
    type: types.RECIPE_SUBSTITUTE.SUCCESS,
    data,
  };
}

export function recipeSubstituteFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_SUBSTITUTE.FAILURE,
    err,
  };
}
export function recipeSubstituteAdd(credentials: object): ActionModel {
  return {
    type: types.RECIPE_SUBSTITUTE_ADD.REQUEST,
    credentials,
  };
}

export function recipeSubstituteAddSuccess(data?: object): ActionModel {
  return {
    type: types.RECIPE_SUBSTITUTE_ADD.SUCCESS,
    data,
  };
}
export function recipeSubstituteAddFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_SUBSTITUTE_ADD.FAILURE,
    err,
  };
}

export function recipeSubstituteUpdate(credentials: object): ActionModel {
  return {
    type: types.RECIPE_SUBSTITUTE_UPDATE.REQUEST,
    credentials,
  };
}

export function recipeSubstituteUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.RECIPE_SUBSTITUTE_UPDATE.SUCCESS,
    data,
  };
}

export function recipeSubstituteUpdateFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_SUBSTITUTE_UPDATE.FAILURE,
    err,
  };
}

export function recipeSubstituteRemove(credentials: object): ActionModel {
  return {
    type: types.RECIPE_SUBSTITUTE_REMOVE.REQUEST,
    credentials,
  };
}

export function recipeSubstituteRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.RECIPE_SUBSTITUTE_REMOVE.SUCCESS,
    data,
  };
}

export function recipeSubstituteRemoveFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_SUBSTITUTE_REMOVE.FAILURE,
    err,
  };
}
