import "../../../../styles/navigation-left.scss";

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import commonService from "../../../../services/common.service";

interface NavState {
  collapse: boolean;
}
class ProfileNavLeftComponent extends Component<any> {
  state: NavState;
  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.state = { collapse: false };
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        <Link to={`/profile`}>
          <Button
            className={
              this.props.display === "profile"
                ? "btn-light mb-3"
                : "btn-dark mb-3"
            }
          >
            {t("common.personalInformation")}
          </Button>
        </Link>

        <Link to={`/profiles/settings`}>
          <Button
            className={
              this.props.display === "profilesetting"
                ? "btn-light mb-3"
                : "btn-dark mb-3"
            }
            onClick={this.toggle}
          >
            {t("profile.profileSettings")}
          </Button>
        </Link>
      </div>
    );
  }

  private _setFunctionBindings(): void {
    this.toggle = this.toggle.bind(this);
  }

  public toggle(): void {
    this.setState((prevState: any) => ({
      collapse: !prevState.collapse,
    }));
  }
}
export default withTranslation()(withRouter(ProfileNavLeftComponent));
