import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { TableInitState, TableState } from "../../models/table.model";
import { stationsRequest } from "../../redux/actions/stations.action";
import {
  tablesRequest,
  tableAdd,
  tableUpdate,
  tableRemove,
} from "../../redux/actions/table.action";
import "../../styles/room.scss";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import LoaderComponent from "../loader/loader";

import NavigationRightComponent from "../navigation/navigation-right/navigation-right";

import TableCard from "../card-components/table-card/table-card";
import StaffHeaderComponent from "../navigation/navigation-header/staff-header/staff-header";
import UsersLeftComponent from "../navigation/navigation-left/users-navigation/users-navigation";
import TableModal from "./tableCreateEdit";
import { withTranslation } from "react-i18next";
import commonService from "../../services/common.service";
import _ from "lodash";

class TableComponent extends Component<any> {
  state: TableState;
  constructor(props: any) {
    super(props);
    this.state = TableInitState;
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.getTables({ restaurantuuid: this.props.match.params.uuid });
    this.props.getStations({ uuid: this.props.match.params.uuid });
    this._setStates("restaurantuuid", this.props.match.params.uuid);
  }

  modalToggle = (isAdd?: any) => {
    if (isAdd) {
      this.setState({
        isEdit: false,
        tableName: "",
        tableStation: "",
        uuid: "",
      });
    }
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  saveTable = () => {
    if (!this.state.isEdit) {
      this.props.addTable({
        restaurantuuid: this.props.match.params.uuid,
        data: {
          name: this.state.tableName,
          station: this.state.tableStation,
        },
      });
    } else {
      this.props.updateTable({
        restaurantuuid: this.props.match.params.uuid,
        uuid: this.state.uuid,
        data: {
          name: this.state.tableName,
          station: this.state.tableStation,
        },
      });
    }
    this._setStates("isUpdated", true);
  };
  render() {
    const { t } = this.props;
    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <StaffHeaderComponent staff={"resources"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <UsersLeftComponent
                display={"table"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <Link
                to="#"
                color="info"
                className="room-add"
                onClick={() => {
                  this.modalToggle(true);
                }}
              >
                {t("table.addTable")}
              </Link>
              <div className="white-box mb-3">
                <h4>{t("table.tables")}</h4>
                {this.tableList(this.props.tables)}
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>

        {/*  Add /EditTable  */}

        <TableModal
          handleChange={this.handleChange}
          isOpen={this.state.showModal}
          onCancel={this.modalToggle}
          onSave={this.saveTable}
          state={this.state}
          stations={this.props.stations}
        />

        {/* Delete Table*/}

        <DeleteModalCard
          isOpen={this.state.open}
          isFetching={!!this.state.isFetching}
          okDelete={this.deleteConfirmToggle}
          cancelDelete={this.deleteToggle}
        />
      </div>
    );
  }

  public tableList(data: any): any {
    let table;
    if (!!data && data.length > 0) {
      table = _.orderBy(
        data,
        [(item) => item.name.toLowerCase()],
        ["asc"]
      )?.map((locales: any, i: number) => {
        return (
          <TableCard
            key={i}
            locales={locales}
            stations={this.props.stations}
            setEditable={this.setEditable.bind(this, locales)}
            setDelete={this.setDelete.bind(this, locales)}
          />
        );
      });
      return table;
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.props.t("common.noRecords")}
        </div>
      );
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
      if (!!newProps && !newProps.isFetching) {
      }
      if (!!this.state.isUpdated && !newProps.isFetching) {
        this.props.getTables({ restaurantuuid: this.props.match.params.uuid });
        this._setStates("isUpdated", false);
      }
      if (
        !!newProps.tableUpdateStatus &&
        !!this.state.showModal &&
        !newProps.isFetching
      ) {
        this.modalToggle();
      }
      if (
        !!newProps.tableAddStatus &&
        !!this.state.showModal &&
        !newProps.isFetching
      ) {
        this.modalToggle();
      }
    }
  }

  public deleteToggle = () => {
    this.setState((prevState: any) => ({
      open: !prevState.open,
    }));
  };

  public deleteConfirmToggle = () => {
    this.props.removeTable({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
    });
    this._setStates("isUpdated", true);
    this.deleteToggle();
  };

  public setEditable = (event: any) => {
    this.setState({
      isEdit: true,
      tableName: event.name,
      tableStation: event.station,
      uuid: event.uuid,
    });
    this.modalToggle();
  };

  public setDelete = (event: any) => {
    this._setStates("uuid", event.uuid);
    this.deleteToggle();
  };

  private _setStates = (name: string, value: any) => {
    this.setState({ [name]: value });
  };

  public handleChange = (e: any) => {
    this._setStates(e.target.name, e.target.value);
  };
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.tables.isFetching ||
    state.tableAdd.isFetching ||
    state.tableRemove.isFetching ||
    state.tableUpdate.isFetching;
  let failure =
    state.tables.failure ||
    state.tableAdd.failure ||
    state.tableRemove.failure ||
    state.tableUpdate.failure;
  return {
    failure: failure,
    isFetching: isFetching,
    stations: state.stations.data,
    tables: state.tables.data,
    tableAddStatus: state.tableAdd.data,
    tableRemoveStatus: state.tableRemove.data,
    tableUpdateStatus: state.tableUpdate.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    addTable: (credentials: any) => {
      dispatch(tableAdd(credentials));
    },
    getStations: (credentials: any) => {
      dispatch(stationsRequest(credentials));
    },
    getTables: (credentials: any) => {
      dispatch(tablesRequest(credentials));
    },
    removeTable: (credentials: any) => {
      dispatch(tableRemove(credentials));
    },
    updateTable: (credentials: any) => {
      dispatch(tableUpdate(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(TableComponent))
);
