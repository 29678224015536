import apiCall from "./api.call.config";

async function userUpdateLanguage(url: string, params: any) {
  try {
    return await apiCall.put(
      url + params.credentials.useruuid + "/languages",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  userUpdateLanguage,
};
