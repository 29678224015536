import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function upload(credentials: object): ActionModel {
  return {
    type: types.UPLOAD.REQUEST,
    credentials
  };
}

export function uploadSuccess(data?: object): ActionModel {
  return {
    type: types.UPLOAD.SUCCESS,
    data
  };
}

export function uploadFailure(err: object): ActionModel {
  return {
    type: types.UPLOAD.FAILURE,
    err
  };
}
