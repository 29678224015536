import { call, put, takeLatest } from "redux-saga/effects";

import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  paymentProviderAddFailure,
  paymentProviderAddSuccess,
  paymentProviderDetailsFailure,
  paymentProviderDetailsSuccess,
  paymentProviderFailure,
  paymentProviderRemoveSuccess,
  paymentProviderRemoveFailure,
  paymentProviderSuccess,
  paymentProviderUpdateFailure,
  paymentProviderUpdateSuccess,
} from "../redux/actions/payment.provider.action";

import * as types from "../redux/actions/types";
import paymentProvider from "../services/payment.provider.service";
import handleResponse from "../services/response.service";

const paymentProviderListCall = (params: any) =>
  paymentProvider.paymentProviderList(RESTAURANT_REQUEST, params);
const addPaymentProviderCall = (params: any) =>
  paymentProvider.addPaymentProvider(RESTAURANT_REQUEST, params);
const getPaymentProviderDetailsCall = (params: any) =>
  paymentProvider.paymentProviderDetails(RESTAURANT_REQUEST, params);
const removePaymentProviderCall = (params: any) =>
  paymentProvider.removePaymentProvider(RESTAURANT_REQUEST, params);
const updatePaymentProviderCall = (params: any) =>
  paymentProvider.updatePaymentProvider(RESTAURANT_REQUEST, params);

function* handlePaymentProviderRequest(params: object) {
  try {
    let result: any;
    result = yield call(paymentProviderListCall, params);
    yield handlePaymentProviderRequest200(result);
  } catch (error) {
    throw error;
  }
}

const handlePaymentProviderRequest200 = function* handlePaymentProviderRequest200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(paymentProviderSuccess(result.data));
  } else {
    yield put(paymentProviderFailure(result.data));
    handleResponse(result);
  }
};

function* handlePaymentProviderAdd(params: object) {
  try {
    let result;
    result = yield call(addPaymentProviderCall, params);
    yield handlePaymentProviderAdd200(result);
  } catch (error) {
    throw error;
  }
}

const handlePaymentProviderAdd200 = function* handlePaymentProviderAdd200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(paymentProviderAddSuccess(result.data));
  } else {
    yield put(paymentProviderAddFailure(result.data));
  }
  handleResponse(result);
};

function* handlePaymentProviderDetails(params: object) {
  try {
    let result;
    result = yield call(getPaymentProviderDetailsCall, params);
    yield handlePaymentProviderDetails200(result);
  } catch (error) {
    throw error;
  }
}

const handlePaymentProviderDetails200 = function* handlePaymentProviderDetails200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(paymentProviderDetailsSuccess(result.data));
  } else {
    yield put(paymentProviderDetailsFailure(result.data));
  }
  handleResponse(result);
};

function* handlePaymentProviderRemove(params: object) {
  try {
    let result;
    result = yield call(removePaymentProviderCall, params);
    yield handlePaymentProviderRemove200(result);
  } catch (error) {
    throw error;
  }
}

const handlePaymentProviderRemove200 = function* handlePaymentProviderRemove200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(paymentProviderRemoveSuccess(result.data));
  } else {
    yield put(paymentProviderRemoveFailure(result.data));
  }
  handleResponse(result);
};
function* handlePaymentProviderUpdate(params: object) {
  try {
    let result;
    result = yield call(updatePaymentProviderCall, params);
    yield handlePaymentProviderUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handlePaymentProviderUpdate200 = function* handlePaymentProviderUpdate200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(paymentProviderUpdateSuccess(result.data));
  } else {
    yield put(paymentProviderUpdateFailure(result.data));
  }
  handleResponse(result);
};

export default function* paymentProviderSaga() {
  yield takeLatest(types.PAYMENT_PROVIDER.REQUEST, handlePaymentProviderRequest);
  yield takeLatest(types.PAYMENT_PROVIDER_ADD.REQUEST, handlePaymentProviderAdd);
  yield takeLatest(types.PAYMENT_PROVIDER_DETAILS.REQUEST, handlePaymentProviderDetails);
  yield takeLatest(types.PAYMENT_PROVIDER_REMOVE.REQUEST, handlePaymentProviderRemove);
  yield takeLatest(types.PAYMENT_PROVIDER_UPDATE.REQUEST, handlePaymentProviderUpdate);
}
