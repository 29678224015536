import React, { Component } from "react";
import { connect } from "react-redux";
import ModalFactory from "react-modal-promise";
import { withRouter } from "react-router";
import { create } from "apisauce";

import { ACCESS_TOKEN } from "../../../constant/constant";
import { BASE_URL } from "../../../config/app.config";
import ConceptHeaderComponent from "../../../components/navigation/navigation-header/concept-header/concept-header";
import commonService from "../../../services/common.service";
import ItemWrapper from "../../../components/service-item/shared/itemWrapper";
import ItemAddModal from "../../../components/service-item/shared/itemAddModal";

import handleResponse from "../../../services/response.service";
import LoaderComponent from "../../../components/loader/loader";
import { OpenDeleteModal } from "../../../components/card-components/delete-card/delete-modal";
import RestaurantNavComponent from "../../../components/navigation/navigation-left/restaurant-navigation/restaurant-navigation";
import NavigationRightComponent from "../../../components/navigation/navigation-right/navigation-right";
import {
  recipeAdd,
  recipeRemove,
  recipeRequest,
  recipeUpdate,
  recipeDetailsRequest,
} from "../../../redux/actions/recipe.action";
import { tagsRequest } from "../../../redux/actions/tags.action";
import {
  unitsAdd,
  unitsRemove,
  unitsRequest,
} from "../../../redux/actions/units.action";
import { withTranslation } from "react-i18next";
import { dateItemRequest } from "../../../redux/actions/date.item.action";
import {
  DateItemListInitState,
  DateItemListState,
} from "../../../models/date.item.model";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import _ from "lodash";
import { ingredientCostRequest } from "../../../redux/actions/ingredients.action";
import UnitsAddCard from "../../../components/card-components/units-add-card/units-add-card";
import { RecipeEditHelper } from "../../../helpers/recipeEditHelper";

const api = create({
  baseURL: BASE_URL,
});

class DateItemPage extends Component<any> {
  state: DateItemListState;
  editItemData: any = {};
  constructor(props: any) {
    super(props);
    this.state = DateItemListInitState;
    api.setHeaders({
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Cache-Control": "no-cache",
      Locale: localStorage.getItem("i18nextLng") || "en-gb",
      "content-type": "application/json",
    });
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.loadMetaData();
    this.props.getDateItems({ uuid: this.props.match.params.uuid });
    this.setState({ restaurantuuid: this.props.match.params.uuid });
    this.props.getTags({ restaurantuuid: this.props.match.params.uuid });
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.recipeDetails !== this.state.recipeDetails) {
      let recipeDetail: any = [];
      recipeDetail = this.state.itemsDetails.filter(
        (item: any) => item.uuid !== this.state.recipeDetails.uuid
      );
      if (recipeDetail.length > 0) {
        this.setState({
          itemsDetails: [...recipeDetail, this.state.recipeDetails],
        });
      } else if (
        this.state.recipeDetails &&
        Object.keys(this.state.recipeDetails).length !== 0 &&
        this.state.recipeDetails.constructor === Object
      ) {
        this.setState({
          itemsDetails: [this.state.recipeDetails],
        });
      }
    }

    if (
      JSON.stringify(prevProps.recipeServiceSets) !==
        JSON.stringify(this.props.recipeServiceSets) ||
      this.state.recipeServiceSets[this.state.elementId] !==
        this.props.recipeServiceSets
    ) {
      let serviceSets: any = {};
      serviceSets[this.state.elementId] = this.props.recipeServiceSets;
      const allServiceSets = {
        ...this.state.recipeServiceSets,
        ...serviceSets,
      };
      this.setState({
        recipeServiceSets: allServiceSets,
      });
    }

    if (!!this.state.fetchData) {
      this.props.getDateItems({ uuid: this.props.match.params.uuid });
      this.props.getUnits({ restaurantuuid: this.props.match.params.uuid });

      this.setState({
        fetchData: false,
        newUnit: null,
      });
    }
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    let update: any = {};

    if (nextProps.isFetching !== prevState.isFetching) {
      update.isFetching = nextProps.isFetching;
    }

    if (
      (nextProps.items?.length || nextProps.items?.length === 0) &&
      nextProps.items !== prevState.items
    ) {
      update.items = nextProps.items;
    }

    if (nextProps.units && !_.isEqual(nextProps.units, prevState.units)) {
      update.units = nextProps.units;
    }
    if (nextProps.tags && nextProps.tags !== prevState.tags) {
      update.tags = nextProps.tags;
    }
    if (
      !!nextProps.recipeDetails &&
      nextProps.recipeDetails !== prevState.recipeDetails
    ) {
      update.recipeDetails = nextProps.recipeDetails;
    }
    if (
      !!prevState.isAddItemModalOpen &&
      !nextProps.isFetching &&
      !!prevState.isUpdated
    ) {
      update.isAddItemModalOpen = false;
      update.isUpdated = false;
      update.fetchData = true;
    }
    if (!nextProps.isFetching && !!prevState.isUpdated) {
      update.isUpdated = false;
      update.fetchData = true;
    }
    return update;
  }

  render() {
    return (
      <>
        <div>
          <ModalFactory />
          <LoaderComponent display={this.state.isFetching} />
          <ItemAddModal
            color={this.state.color}
            description={this.state.description}
            elementId={this.state.elementId}
            handleCancel={this.handleAddItemClick}
            handleChange={this.handleChange}
            handleLabelSelect={this.handleLabelSelect}
            handleUnitChange={this.handleUnitChange}
            isFetching={this.state.isFetching}
            isModalOpen={this.state.isAddItemModalOpen}
            isUnitDisabled={RecipeEditHelper.isUnitDisabled(
              this.state.isAddItemModalOpen,
              this.state.itemId,
              this.props.units
            )}
            labelOptions={this.state.labelOptions}
            labels={this.state.labels}
            name={this.state.name}
            newUnit={this.state.newUnit}
            outputquantity={this.state.outputquantity}
            outputunituuid={this.state.outputUnitUuid}
            posName={this.state.posName}
            printerName={this.state.printerName}
            saveItem={this.handleItemSave}
            selectedLabels={this.state.selectedLabels}
            textLabels={this.getAddModalLabel()}
            units={this.props.units}
            replacementItem={this.state.replacementItem}
          />

          <div className="container-fluid">
            <ConceptHeaderComponent concept={"concept"} />
            <Row className="main light-theme">
              <Col xl={2} lg={3} className="hide-left-max">
                <RestaurantNavComponent
                  display={"date-item"}
                  restaurantuuid={this.props.match.params.uuid}
                />
              </Col>
              <Col xl={8} lg={6}>
                <ItemWrapper
                  elementId={this.state.elementId}
                  extraNavButtons={this.getExtraNavButtons}
                  fetchData={this.fetchData}
                  getExtraDetails={this.getExtraDetails}
                  getUpdatedCostPrices={this.getCostDetails}
                  handleImageUpdate={this.handleImageUpdate}
                  handleSelect={this.handleSelect}
                  handleUnitUpdate={this.handleUnitUpdate}
                  hasDetails={true}
                  hasTag={true}
                  isUnitModalOpen={this.state.isUnitModalOpen}
                  itemData={this.state.items}
                  itemsDetails={this.state.itemsDetails}
                  labels={this.state.labels}
                  rooms={this.state.rooms}
                  setItemId={this.setItemId}
                  units={this.state.units}
                  onAddNewItem={this.handleAddItemClick}
                  onMoveToDetailPage={this.moveToDetailPage}
                  onItemDelete={this.handleItemDelete}
                  onItemEdit={this.handleItemEdit}
                  pageText={this.getPageTitle()}
                  recipeServiceSet={this.state.recipeServiceSets}
                  showItemCount={true}
                  tags={this.props?.tags}
                  type="dateItem"
                />
              </Col>
              <Col xl={2} lg={3}>
                <NavigationRightComponent />
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }

  public getAddModalLabel = () => {
    return {
      modalTitle: this.state.isEdit
        ? this.props.t("dateItem.editDateItem")
        : this.props.t("dateItem.addDateItem"),
    };
  };

  getBaseUnitOptions = () => {
    if (!Array.isArray(this.props.units)) return;
    let units: Array<any> = [];
    const baseUnit: any = this.props.units?.find(
      (unit: any) => unit.uuid === this.props.settings.base_weight_unit
    );
    if (baseUnit) {
      units =
        commonService.getRelateUnitDropdowns(
          baseUnit.uuid,
          this.props.units,
          this.props.units,
          1,
          this.props.elementId
        ) || [];
    }
    return _.sortBy(units, [(option: any) => option.label.toLowerCase()]);
  };

  public getCostDetails = (ingredientId: any) => {
    this.props.getIngredientCost({
      restaurantuuid: this.props.match.params.uuid,
      uuid: ingredientId,
    });
  };

  public getPageTitle = () => {
    return {
      addButtonTitle: this.props.t("dateItem.addDateItem"),
      pageTitle: this.props.t("dateItem.dateItems"),
    };
  };

  public fetchData = () => {
    this.setState({
      fetchData: true,
    });
  };

  public setItemId = (itemId: string) => {
    this.setState({
      elementId: itemId,
    });
  };

  public displayUnitName = (unitId: string) => {
    if (Object.keys(this.state.globalUnits).length > 0) {
      let choiceUnit: any = this.state.globalUnits?.filter(
        (unit: any) => unit.uuid === unitId
      )[0];
      return commonService.applyLocalization(
        "restaurant",
        "name",
        choiceUnit?.locales
      )["symbol"];
    }
  };

  public handleItemDelete = (itemId: any, element: string) => {
    OpenDeleteModal().then(() => {
      this.props.removeDateItem({
        uuid: itemId,
        restaurantuuid: this.props.match.params.uuid,
      });
      this.setState({
        isUpdated: true,
        selectedElement: element,
        selectedItemId: itemId,
      });
    });
  };

  public moveToDetailPage = (itemId: string) => {
    this.setState({
      uuid: itemId,
    });
    this.props.history.push(
      `/restaurant/${this.props.match.params.uuid}/date-item/${itemId}/details`
    );
  };

  public getExtraDetails = (data: any) => {
    return (
      <Row>
        <Col lg={3} sm={6}>
          {this.props.t("common.outputQuantity")}{" "}
        </Col>
        <Col lg={6} sm={6}>
          {data.output_quantity} {this.displayUnitName(data.output_unit_uuid)}
        </Col>
      </Row>
    );
  };

  public getExtraNavButtons = () => {
    return (
      <Link
        to={`/restaurant/${this.props.match.params.uuid}/periodicity`}
        className="mx-2"
      >
        <Button color={"info"} size={"sm"}>
          {this.props.t("periodicity.managePeriodicity")}
        </Button>
      </Link>
    );
  };

  public getDetails = (recipeId: any) => {
    this.setState({ isFetching: true });
    this.props.getRecipeDetail({
      restaurantuuid: this.props.match.params.uuid,
      recipeuuid: recipeId,
    });
  };

  public handleAddItemClick = () => {
    this.setState({
      color: "#FFFFFF",
      description: "",
      elementId: "",
      isAddItemModalOpen: !this.state.isAddItemModalOpen,
      isEdit: false,
      itemId: "",
      name: "",
      newUnit: null,
      posName: "",
      printerName: "",
      outputquantity: "",
      outputUnitUuid: "",
      isManged: "",
      selectedLabels: [],
      replacementItem: "",
    });
  };

  handleImageUpdate = (imageId: string) => {
    const [selectedItem]: any = this.state.items.filter((item: any) => {
      return item.uuid === this.state.elementId;
    });
    let locale = commonService.applyLocalization(
      "restaurant",
      "name",
      selectedItem?.locales
    );
    const payload: any = {
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.state.elementId,
      data: {
        image_uuid: imageId,
        label_uuid: selectedItem?.label_uuids || [],
        color: selectedItem.color,
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: locale.name,
            description: locale.description,
            pos_name: locale.pos_name,
            printer_name: locale.printer_name,
          },
        },
        output_quantity: selectedItem.output_quantity,
        output_unit_uuid: selectedItem.output_unit_uuid,
        product_barcode: selectedItem?.product_barcode || "",
        shelf_life: selectedItem?.shelf_life || undefined,
        sku: selectedItem.sku,
        supplier_uuid: selectedItem?.supplier_uuid
          ? selectedItem?.supplier_uuid
          : undefined,
      },
    };

    this.props.updateDateItem(payload);
    this.setState({ isUpdated: true });
  };

  public handleLabelSelect = (selectedLabels: any) => {
    if (selectedLabels != null) {
      this.setState({
        selectedLabels,
      });
    } else {
      this.setState({
        selectedLabels: [],
      });
    }
  };

  public handleChange = (data: any) => {
    this.setState({
      [data.target.name]: data.target.value,
    });
  };

  public handleItemEdit = (itemId: any) => {
    const selectedItem: any = this.state.items.find((item: any) => {
      return item.uuid === itemId;
    });
    this.editItemData = selectedItem;
    let itemLocale = commonService.applyLocalization(
      "restaurant",
      "name",
      selectedItem?.locales
    );
    let allLabels = this.state.labels?.length ? this.state?.labels : [];
    this.setState({
      color: selectedItem.color,
      description: itemLocale.description,
      elementId: selectedItem.uuid,
      isAddItemModalOpen: true,
      isEdit: true,
      itemId: selectedItem.uuid,
      name: itemLocale.name,
      newUnit: null,
      posName: itemLocale.pos_name,
      printerName: itemLocale.printer_name,
      outputquantity: selectedItem.output_quantity,
      outputUnitUuid: selectedItem.output_unit_uuid,
      isManged: selectedItem.managed,
      selectedLabels: selectedItem.label_uuids.map((label: any) => {
        return { value: label, label: this.labelName(label, allLabels) };
      }),
      replacementItem: selectedItem.replacement_item_uuid,
    });
  };
  public handleItemSave = () => {
    let body: any = {
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.state.isEdit ? this.state.elementId : undefined,
      data: {
        label_uuid: this.state.selectedLabels.map((item: any) => item.value),
        output_quantity: Number(this.state.outputquantity),
        output_unit_uuid: this.state.outputUnitUuid,
        color: this.state.color,
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.name,
            description: this.state.description,
            pos_name: this.state.posName,
            printer_name: this.state.printerName,
          },
        },
        replacement_item: this.state.replacementItem,
      },
    };

    if (body.data.output_unit_uuid === "NEW_RECIPE_UNIT") {
      body.data.output_unit_uuid = "";
      body.data["output_unit"] = this.state.newUnit;
      delete body.data.output_unit.uuid;
    }

    if (this.state.isEdit) {
      this.props.updateDateItem(body);
    } else {
      body.data.type = 5;
      this.props.addDateItem(body);
    }
    this.setState({ isUpdated: true });
  };

  public handleSelect = (key: any, recipeId: any) => {
    if (key === "tags") {
      if (
        !this.state.itemsDetails.some((item: any) => item.uuid === recipeId)
      ) {
        this.getDetails(recipeId);
        this.setState({ isUpdated: true });
      }
    } else if (key === "cost") {
      this.setState({ isFetching: true });
      this.getCostDetails(recipeId);
      this.setState({ isUpdated: true });
    }
    this.setState({ elementId: recipeId });
  };
  public handleUnitChange = (e: any) => {
    if (e.target.value === "NEW_UNIT") {
      this.openNewUnitModal();
    } else if (e.target.value === "NEW_RECIPE_UNIT") {
      this.setState({
        outputUnitUuid: e.target.value,
        isUnitModalOpen: false,
      });
    } else {
      let outputUnit: string = "";
      this.props.units.forEach((unit: any) => {
        if (unit.uuid === e.target.value) {
          outputUnit = unit.uuid;
        } else if (e.target.value === "") {
          outputUnit = "";
        }
      });
      this.setState({
        outputUnitUuid: outputUnit,
        isUnitModalOpen: false,
      });
    }
  };

  handleUnitUpdate = (value: any, area: string) => {
    if (area === "visibility") {
      this.setState({
        isUnitModalOpen: value,
        newUnit: null,
      });
    } else if (area === "save") {
      this.setState({
        newUnit: value,
        outputUnitUuid: value.uuid,
        isUnitModalOpen: false,
      });
    }
  };

  public loadMetaData = () => {
    api
      .get(
        "/api/1.0/restaurant/" +
          this.props.match.params.uuid +
          "/get??sort=name:desc&components=unit, storage, label, room",
        {}
      )
      .then((response: any) => {
        const responseData: any = handleResponse(response);
        if (responseData.ok) {
          let options: Array<any> = [];
          const globalUnits = response.data?.unit;
          response.data?.label.map((labl: any) => {
            options.push({
              value: labl.uuid,
              label: commonService.applyLocalization(
                "restaurant",
                "name",
                labl?.locales
              ).name,
            });
          });
          this.setState({
            globalUnits,
            labelOptions: _.sortBy(options, "label"),
            labels: response.data.label ? response.data.label : [],
            rooms: response.data.room ? response.data.room : [],
            storage: response.data.storage ? response.data.storage : [],
            units: response.data.unit ? response.data.unit : [],
          });
        } else {
          handleResponse(response);
        }
      });
  };

  openNewUnitModal = () => {
    this.setState({
      isUnitModalOpen: true,
    });
  };

  showLoading = (state: boolean = true) => {
    this.setState({ isFetching: state });
  };

  public labelName = (parent: any, filterResult: any) => {
    let name;
    if (!!filterResult) {
      name = filterResult.map((item: any, i: number) => {
        if (parent === item.uuid) {
          return commonService.applyLocalization(
            "restaurant",
            "name",
            item?.locales
          ).name;
        }
      });
      return name;
    }
  };
}
const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.dateItem.isFetching ||
    state.ingredientCost.isFetching ||
    state.recipe.isFetching ||
    state.reciperemove.isFetching ||
    state.recipeupdate.isFetching ||
    state.recipeadd.isFetching ||
    state.recipedetails.isFetching ||
    state.recipeupdate.isFetching ||
    state.tagAssign.isFetching ||
    state.units.isFetching ||
    state.unitsremove.isFetching ||
    state.upload.isFetching;
  let failure =
    state.dateItem.failure ||
    state.ingredientCost.failure ||
    state.recipe.failure ||
    state.reciperemove.failure ||
    state.recipeupdate.failure ||
    state.recipeadd.failure ||
    state.recipedetails.failure ||
    state.recipeupdate.failure ||
    state.units.failure ||
    state.unitsremove.failure ||
    state.upload.failure;
  return {
    editRecipeStatus: state.recipeupdate.data,
    items: state.dateItem.data,
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    isFetching: isFetching,
    failure: failure,
    addRecipeStatus: state.recipeadd.data,
    recipeDetails: state.recipedetails.data,
    recipeDetailsRequest: state.recipeedit.data,
    recipeEdit: state.recipeedit.data,
    recipeRemove: state.reciperemove.data,
    recipeServiceSets: state.ingredientCost.data.serviceSets,
    settings: state.settings.data,
    tags: state.tags.data,
    units: state.units.data,
    unitsRemove: state.unitsremove.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    addDateItem: (credentials: any) => {
      dispatch(recipeAdd(credentials));
    },
    getRecipeDetail: (credentials: any) => {
      dispatch(recipeDetailsRequest(credentials));
    },
    getDateItems: (credentials: any) => {
      dispatch(dateItemRequest(credentials));
    },
    getIngredientCost: (credentials: any) => {
      dispatch(ingredientCostRequest(credentials));
    },
    getTags: (credentials: any) => {
      dispatch(tagsRequest(credentials));
    },
    getUnits: (credentials: any) => {
      dispatch(unitsRequest(credentials));
    },
    removeDateItem: (credentials: any) => {
      dispatch(recipeRemove(credentials));
    },
    updateDateItem: (credentials: any) => {
      dispatch(recipeUpdate(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(DateItemPage))
);
