import { qrCodeModel } from "../../models/qrcode.model";
import * as types from "../actions/types";

const initialState: qrCodeModel = {
  isFetching: false,
  data: [],
  error: {},
  failure: false,
};

export default function qrCodeReducer(
  state = initialState,
  action: any
): qrCodeModel {
  switch (action.type) {
    case types.QRCODE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.QRCODE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.QRCODE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function qrCodeAddReducer(
  state = initialState,
  action: any
): qrCodeModel {
  switch (action.type) {
    case types.QRCODE_ADD.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.QRCODE_ADD.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.QRCODE_ADD.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
export function qrCodeRemoveReducer(
  state = initialState,
  action: any
): qrCodeModel {
  switch (action.type) {
    case types.QRCODE_REMOVE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.QRCODE_REMOVE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.QRCODE_REMOVE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function qrCodeUpdateReducer(
  state = initialState,
  action: any
): qrCodeModel {
  switch (action.type) {
    case types.QRCODE_UPDATE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.QRCODE_UPDATE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.QRCODE_UPDATE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function getRoomDetailsReducer(
  state = initialState,
  action: any
): qrCodeModel {
  switch (action.type) {
    case types.QRCODE_GET_ROOM_DTLS.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.QRCODE_GET_ROOM_DTLS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.QRCODE_GET_ROOM_DTLS.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
