import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function recipePrinterRequest(credentials: object): ActionModel {
  return {
    type: types.RECIPE_PRINTER.REQUEST,
    credentials
  };
}

export function recipePrinterSuccess(data: object): ActionModel {
  return {
    type: types.RECIPE_PRINTER.SUCCESS,
    data
  };
}

export function recipePrinterFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_PRINTER.FAILURE,
    err
  };
}

export function recipePrinterUpdate(credentials: object): ActionModel {
  return {
    type: types.RECIPE_PRINTER_UPDATE.REQUEST,
    credentials
  };
}
export function recipePrinterCleanUpdate(): ActionModel {
  return {
    type: types.RECIPE_PRINTER_UPDATE.REMOVE,
  };
}

export function recipePrinterCleanAdd(): ActionModel {
  return {
    type: types.RECIPE_PRINTER_ADD.REMOVE,
  };
}
export function recipePrinterUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.RECIPE_PRINTER_UPDATE.SUCCESS,
    data
  };
}

export function recipePrinterUpdateFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_PRINTER_UPDATE.FAILURE,
    err
  };
}

export function recipePrinterAdd(credentials: object): ActionModel {
  return {
    type: types.RECIPE_PRINTER_ADD.REQUEST,
    credentials
  };
}

export function recipePrinterAddSuccess(data?: object): ActionModel {
  return {
    type: types.RECIPE_PRINTER_ADD.SUCCESS,
    data
  };
}

export function recipePrinterAddFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_PRINTER_ADD.FAILURE,
    err
  };
}

export function recipePrinterRemove(credentials: object): ActionModel {
  return {
    type: types.RECIPE_PRINTER_REMOVE.REQUEST,
    credentials
  };
}

export function recipePrinterRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.RECIPE_PRINTER_REMOVE.SUCCESS,
    data
  };
}

export function recipePrinterRemoveFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_PRINTER_REMOVE.FAILURE,
    err
  };
}
