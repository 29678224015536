import apiCall from "./api.call.config";

async function ingredients(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.credentials.uuid + "/recipe/list?type=2"
    );
  } catch (e) {
    throw e;
  }
}

async function updateIngredients(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.uuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function addIngredients(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/recipe",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function removeIngredients(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.uuid
    );
  } catch (e) {
    throw e;
  }
}

async function getIngredientDetail(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.uuid +
        "/details"
    );
  } catch (e) {
    throw e;
  }
}

export async function getIngredientCost(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.uuid +
        "/cost-price"
    );
  } catch (e) {
    throw e;
  }
}

async function updateIngredientCost(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.uuid +
        "/cost",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}
export default {
  ingredients,
  updateIngredients,
  addIngredients,
  removeIngredients,
  getIngredientDetail,
  getIngredientCost,
  updateIngredientCost,
};
