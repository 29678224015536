import "../../../styles/navigation-header.scss";
import { PROFILE } from "../../../constant/constant";
import image from "../../../assets/images/icon/image.png";

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  UncontrolledDropdown,
} from "reactstrap";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import commonService from "../../../services/common.service";
import _ from "lodash";
export default function HeaderCard(props: any) {
  const { t, i18n } = useTranslation();

  const loadImage = () => {
    const profile: any = localStorage.getItem(PROFILE);
    if (!!JSON.parse(profile) && JSON.parse(profile).image_url) {
      return (
        <img
          className="user-icon b-profile-avatar"
          src={JSON.parse(profile).image_url}
          alt=""
        />
      );
    } else {
      return <img className="user-icon b-profile-avatar" src={image} alt="" />;
    }
  };
  const restaurants = _.sortBy(props.RESTAURANT, (item: any) =>
    item.props.children.toLowerCase()
  );
  return (
    <div>
      <div className="hide-left-max">
        <Nav className="rightbar align-items-center flex-nowrap" navbar>
          <UncontrolledDropdown inNavbar>
            <DropdownToggle color="transparent" className="p-0">
              <div
                className="rest-button short-text"
                data-toggle="tooltip"
                data-placement="left"
                title={
                  props.restaurantName
                    ? commonService.applyLocalization(
                        "restaurant",
                        "name",
                        props.restaurantName?.locales
                      )["name"]
                    : t("restaurant.chooseRestaurant")
                }
              >
                {props.restaurantName
                  ? commonService.applyLocalization(
                      "restaurant",
                      "name",
                      props.restaurantName?.locales
                    )["name"]
                  : t("restaurant.chooseRestaurant")}
              </div>
            </DropdownToggle>
            <DropdownMenu className="header-link-common">
              {restaurants}
              <DropdownItem>
                <Link to={`/restaurant`}>{t("common.allRestaurant")}</Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          <UncontrolledDropdown nav inNavbar className="ml-2">
            <DropdownToggle className="p-0" nav>
              {loadImage()}
            </DropdownToggle>
            <DropdownMenu right className="header-link-common">
              <DropdownItem
                className="profile-link"
                onClick={props.goToProfile}
              >
                {t("profile.profile")}
              </DropdownItem>
              <DropdownItem onClick={props.modalToggle}>
                {t("common.logout")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </div>
      {/* Device below 991px */}
      <div className="hide-left-min">
        <UncontrolledDropdown inNavbar>
          <DropdownToggle nav className={"nav-link"}>
            {loadImage()}
          </DropdownToggle>
          <DropdownMenu className="header-link-common position-relative w-100 dropdown-menu">
            <DropdownItem className="profile-link" onClick={props.goToProfile}>
              {t("profile.profile")}
            </DropdownItem>
            <DropdownItem onClick={props.modalToggle}>
              {t("common.logout")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown inNavbar>
          <DropdownToggle nav className={"p-0"}>
            <Button className="rest-button d-block w-100">
              {props.restaurantName
                ? commonService.applyLocalization(
                    "restaurant",
                    "name",
                    props.restaurantName?.locales
                  )["name"]
                : t("restaurant.chooseRestaurant")}
            </Button>
          </DropdownToggle>
          <DropdownMenu className="header-link-common position-relative w-100">
            {props.RESTAURANT}
            <DropdownItem>
              <Link to={`/restaurant`}>{t("common.allRestaurant")}</Link>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>
  );
}
