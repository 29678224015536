import { call, put, takeLatest } from "redux-saga/effects";

import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  printerTemplateAdd,
  printerTemplateAddFailure,
  printerTemplateAddSuccess,
  printerTemplateDetails,
  printerTemplateDetailsFailure,
  printerTemplateDetailsSuccess,
  printerTemplateFailure,
  printerTemplateRemove,
  printerTemplateRemoveFailure,
  printerTemplateRemoveSuccess,
  printerTemplateRequest,
  printerTemplateSuccess,
  printerTemplateUpdate,
  printerTemplateUpdateFailure,
  printerTemplateUpdateSuccess
} from "../redux/actions/printer.template.action";

import * as types from "../redux/actions/types";
import printerTemplate from "../services/printer.template.service";
import handleResponse from "../services/response.service";

const printerTemplateListCall = (params: any) =>
  printerTemplate.printerTemplateList(RESTAURANT_REQUEST, params);
const addPrinterTemplateCall = (params: any) =>
  printerTemplate.addPrinterTemplate(RESTAURANT_REQUEST, params);
const getPrinterTemplateDetailsCall = (params: any) =>
  printerTemplate.printerTemplateDetails(RESTAURANT_REQUEST, params);
const removePrinterTemplateCall = (params: any) =>
  printerTemplate.removePrinterTemplate(RESTAURANT_REQUEST, params);
const updatePrinterTemplateCall = (params: any) =>
  printerTemplate.updatePrinterTemplate(RESTAURANT_REQUEST, params);

function* handlePrinterTemplateRequest(params: object) {
  try {
    let result: any;
    result = yield call(printerTemplateListCall, params);
    yield handlePrinterTemplateRequest200(result);
  } catch (error) {
    throw error;
  }
}

const handlePrinterTemplateRequest200 = function* handlePrinterTemplateRequest200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(printerTemplateSuccess(result.data));
  } else {
    yield put(printerTemplateFailure(result.data));
    handleResponse(result);
  }
};

function* handlePrinterTemplateAdd(params: object) {
  try {
    let result;
    result = yield call(addPrinterTemplateCall, params);
    yield handlePrinterTemplateAdd200(result);
  } catch (error) {
    throw error;
  }
}

const handlePrinterTemplateAdd200 = function* handlePrinterTemplateAdd200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(printerTemplateAddSuccess(result.data));
  } else {
    yield put(printerTemplateAddFailure(result.data));
  }
  handleResponse(result);
};

function* handlePrinterTemplateDetails(params: object) {
  try {
    let result;
    result = yield call(getPrinterTemplateDetailsCall, params);
    yield handlePrinterTemplateDetails200(result);
  } catch (error) {
    throw error;
  }
}

const handlePrinterTemplateDetails200 = function* handlePrinterTemplateDetails200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(printerTemplateDetailsSuccess(result.data));
  } else {
    yield put(printerTemplateDetailsFailure(result.data));
  }
  handleResponse(result);
};

function* handlePrinterTemplateRemove(params: object) {
  try {
    let result;
    result = yield call(removePrinterTemplateCall, params);
    yield handlePrinterTemplateRemove200(result);
  } catch (error) {
    throw error;
  }
}

const handlePrinterTemplateRemove200 = function* handlePrinterTemplateRemove200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(printerTemplateRemoveSuccess(result.data));
  } else {
    yield put(printerTemplateRemoveFailure(result.data));
  }
  handleResponse(result);
};
function* handlePrinterTemplateUpdate(params: object) {
  try {
    let result;
    result = yield call(updatePrinterTemplateCall, params);
    yield handlePrinterTemplateUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handlePrinterTemplateUpdate200 = function* handlePrinterTemplateUpdate200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(printerTemplateUpdateSuccess(result.data));
  } else {
    yield put(printerTemplateUpdateFailure(result.data));
  }
  handleResponse(result);
};

export default function* printerTemplateSaga() {
  yield takeLatest(types.PRINTER_TEMPLATE.REQUEST, handlePrinterTemplateRequest);
  yield takeLatest(types.PRINTER_TEMPLATE_ADD.REQUEST, handlePrinterTemplateAdd);
  yield takeLatest(types.PRINTER_TEMPLATE_DETAILS.REQUEST, handlePrinterTemplateDetails);
  yield takeLatest(types.PRINTER_TEMPLATE_REMOVE.REQUEST, handlePrinterTemplateRemove);
  yield takeLatest(types.PRINTER_TEMPLATE_UPDATE.REQUEST, handlePrinterTemplateUpdate);
}
