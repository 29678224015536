import { call, put, takeLatest } from "redux-saga/effects";

import { GLOBAL_REQUEST } from "../config/api.config";
import {
  chatInitFailure,
  chatInitSuccess,
  chatUpdateFailure,
  chatUpdateSuccess
} from "../redux/actions/chat.action";

import * as types from "../redux/actions/types";
import chat from "../services/chat.service";
import handleResponse from "../services/response.service";

const chatInitCall = (params: any) =>
  chat.chatInit(GLOBAL_REQUEST, params);

  const chatUpdateCall = (params: any) =>
  chat.chatRestoreIdUpdate(GLOBAL_REQUEST, params);


function* chatInitGetRequest(params: object) {
  try {
    let result: any;
    result = yield call(chatInitCall, params);
    yield chatInitGetRequest200(result);
  } catch (error) {
    throw error;
  }
}

const chatInitGetRequest200 = function* chatInitGetRequest200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(chatInitSuccess(result.data));
  } else {
    yield put(chatInitFailure(result.data));
    handleResponse(result);
  }
};

function* chatRestoreIdUpdateRequest(params: object) {
  try {
    let result: any;
    result = yield call(chatUpdateCall, params);
    yield chatRestoreIdUpdateRequest200(result);
  } catch (error) {
    throw error;
  }
}

const chatRestoreIdUpdateRequest200 = function* chatRestoreIdUpdateRequest(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(chatUpdateSuccess(result.data));
  } else {
    yield put(chatUpdateFailure(result.data));
    handleResponse(result);
  }
};


export default function* chatSaga() {
  yield takeLatest(types.CHAT_INIT.REQUEST, chatInitGetRequest);
  yield takeLatest(types.CHAT_UPDATE.REQUEST, chatRestoreIdUpdateRequest);
}
