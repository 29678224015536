import { UploadModel } from '../../models/upload.model';
import * as types from '../actions/types';

const initialState: UploadModel = {
  isFetching: false,
  data: {},
  error: {},
  failure: false,
};

export default function uploadReducer(state = initialState, action: any): UploadModel {
  switch (action.type) {
    case types.UPLOAD.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {}
      };
    case types.UPLOAD.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {}
      };
    case types.UPLOAD.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err
      };
    default:
      return state;
  }
}
