import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { GRAY, GREEN } from "../../../constant/constant";
import commonService from "../../../services/common.service";
import "../../../styles/printer.scss";

class PrinterMessageCard extends Component<any> {
  render() {
    const { t } = this.props;
    let receivedAt = new Date(this.props.printer.received_at);
    return (
      <div className="child-box">
        <Row className="app-row-padding">
          <Col></Col>
          <div style={{ float: "right" }}>
            <span
              onClick={this.props.setPrinterProtocolEditable}
              className="pointer-cursor"
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.edit")}
            >
              <FontAwesomeIcon
                style={{ color: GREEN, fontSize: 20 }}
                icon={faEdit}
              />
            </span>
            <span
              style={{ margin: 10 }}
              onClick={this.props.setPrinterDelete}
              className="pointer-cursor"
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.delete")}
            >
              <FontAwesomeIcon
                style={{ color: GRAY, fontSize: 20 }}
                icon={faTrashAlt}
              />
            </span>
          </div>
        </Row>
        <Row className="app-row-padding">
          <Col sm={{ size: 2, offset: 1 }} xs="4">
            {t("common.ipAddress")}
          </Col>
          <Col sm="4" xs="4">
            {this.props.printer.ip_address}
          </Col>
        </Row>
        <Row className="app-row-padding">
          <Col sm={{ size: 2, offset: 1 }} xs="4">
            {t("common.message")}
          </Col>
          <Col sm={{ size: "8", offset: 1 }} xs="4">
            <span className="long-text">{this.props.printer.message}</span>
          </Col>
        </Row>
        <Row className="app-row-padding">
          <Col sm={{ size: 2, offset: 1 }} xs="4">
            {t("common.receivedAt")}
          </Col>
          <Col sm="4" xs="4">
            <span className="long-text">
              {commonService.dateFormat(receivedAt)}
            </span>
          </Col>
        </Row>
        <Link
          to="#"
          color="info"
          className="printer-add"
          onClick={this.props.setPrinterMessageDelete}
        >
          {t("common.delete")}
        </Link>
      </div>
    );
  }
}
export default withTranslation()(PrinterMessageCard);
