import React, { Component } from "react";

import ShopAccessComponent from "../../components/shop-access/ShopAccess";

class ShopAccessPage extends Component {
  render() {
    return <ShopAccessComponent />;
  }
}
export default ShopAccessPage;
