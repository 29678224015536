export interface TranslationModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface TranslationState {
  additionalLang: any;
  confirmDelete: boolean;
  isDelete: boolean;
  isFetching: boolean;
  isUpdated: boolean;
  restaurantLang: any;
  recipeuuid: any;
  ingredientuuid:any;
  notValid: boolean;
  selectedItem: any;
  selectedLang: string;
  selectedCategory: any;
  showForm: boolean;
  showList: boolean;
  showModal: boolean;
  tasks: any;
  taskInterventions: any;
  taskSteps: any;
}

export const TranslationInitState = {
  additionalLang: [],
  confirmDelete: false,
  isDelete: false,
  isFetching: false,
  isUpdated: false,
  notValid: false,
  restaurantLang: [],
  recipeuuid: "",
  ingredientuuid: "",
  selectedItem: {},
  selectedCategory: [],
  selectedLang: "",
  showForm: false,
  showList: false,
  showModal: false,
  tasks: [],
  taskInterventions: [],
  taskSteps: [],
};
