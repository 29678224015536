
import * as types from '../actions/types';
import { RoleRightModel } from '../../models/roleright.model';

const initialState: RoleRightModel = {
    isFetching: false,
    data: {},
    error: {},
    failure: false,
  };

  export default function roleRightReducer(state = initialState, action: any): RoleRightModel {
    switch (action.type) {
        case types.ROLE_RIGHT.REQUEST:
            return {
              ...state,
              isFetching: true,
              failure: false,
              error: {}
            };
          case types.ROLE_RIGHT.SUCCESS:
            return {
              ...state,
              isFetching: false,
              data: action.data,
              failure: false,
              error: {}
            };
          case types.ROLE_RIGHT.FAILURE:
            return {
              ...state,
              isFetching: false,
              failure: true,
              error: action.err
            };
          default:
            return state;
        }
}