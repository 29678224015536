import { faCopy, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Media, Row, Label, Alert } from "reactstrap";
import image from "../../../assets/images/icon/image.png";
import { GRAY, GREEN } from "../../../constant/constant";
import "../../../styles/restaurant.scss";
import commonService from "../../../services/common.service";

export default function RestaurantCard(props: any) {
  const { t, i18n } = useTranslation();
  const restaurantLocale = commonService.applyLocalization(
    "user",
    "name",
    props?.locales?.locales
  );
  return (
    <div className="child-box mb-3">
      <Media>
        <Media left className="img-block mb-2">
          <img
            onClick={props.setImageEdit}
            data-toggle="tooltip"
            data-placement="left"
            title={t("restaurant.uploadRestaurantImage")}
            alt=""
            src={props.locales.image_url ? props.locales.image_url : image}
            className="pointer-cursor"
          />
        </Media>

        <Media body>
          <div style={{ float: "left", width: "85%" }}>
            <Row className="app-row-padding">
              <Col>
                <h6>{restaurantLocale?.name}</h6>
              </Col>
            </Row>
            <Row className="app-row-padding">
              <Col>
                {t("common.address")} : {restaurantLocale?.address}
              </Col>
            </Row>
            <Row className="app-row-padding">
              <Col>
                {t("common.description")} : {restaurantLocale?.description}
              </Col>
            </Row>
            {props.locales.copy_status === 1 ? (
              <Label
                style={{
                  alignSelf: "right",
                  borderWidth: 10,
                  borderColor: "#000",
                  color: "#F70707",
                }}
              >
                <Alert className="p-1" color="warning">
                  {t("common.scheduledToCopy")}
                </Alert>
              </Label>
            ) : null}
            {props.locales.copy_status === 2 ? (
              <Label
                style={{
                  alignSelf: "right",
                  borderWidth: 10,
                  borderColor: "#000",
                  color: "#F70707",
                }}
              >
                <Alert className="p-1" color="info">
                  {t("common.copyStarted")}
                </Alert>
              </Label>
            ) : null}
            {props.locales.copy_status === 4 ? (
              <Label
                style={{
                  alignSelf: "right",
                  borderWidth: 10,
                  borderColor: "#000",
                  color: "#F70707",
                }}
              >
                <Alert className="p-1" color="danger">
                  {t("common.copyFailed")}
                </Alert>
              </Label>
            ) : null}
          </div>
          <div style={{ float: "right", width: "auto" }}>
            {props.isSuperAdmin === "true" &&
            props.locales.copy_status !== 1 ? (
              <span
                style={{ margin: 15 }}
                onClick={props.setRestuarantCopy}
                className="pointer-cursor"
                data-toggle="tooltip"
                data-placement="left"
                title={t("restaurant.restaurantCopy")}
              >
                <FontAwesomeIcon
                  style={{ color: GREEN, fontSize: 20 }}
                  icon={faCopy}
                />
              </span>
            ) : null}
            <span
              onClick={props.setEditable}
              className="pointer-cursor"
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.edit")}
            >
              <FontAwesomeIcon
                style={{ color: GREEN, fontSize: 20 }}
                icon={faEdit}
              />
            </span>
            <span
              style={{ margin: 10 }}
              onClick={props.setDelete}
              className="pointer-cursor"
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.delete")}
            >
              <FontAwesomeIcon
                style={{ color: GRAY, fontSize: 20 }}
                icon={faTrashAlt}
              />
            </span>
          </div>
        </Media>
      </Media>
    </div>
  );
}
