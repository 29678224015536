import "../../styles/units.scss";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { VatInitState, VatState } from "../../models/vat.model";
import { withTranslation } from "react-i18next";
import commonService from "../../services/common.service";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import LoaderComponent from "../loader/loader";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import React, { Component } from "react";
import SettingsHeaderComponent from "../navigation/navigation-header/settings-header/settings-header";
import SettingsNavComponent from "../navigation/navigation-left/settings-navigation/settings-navigation";
import VatAddEditCard from "../card-components/vat-card/vat-add-edit-card";
import VatCard from "../card-components/vat-card/vat-card";
import {
  vatAdd,
  vatRequest,
  vatRemove,
  vatUpdate,
} from "../../redux/actions/vat.action";
import _ from "lodash";

class VatComponent extends Component<any> {
  state: VatState;
  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.state = VatInitState;
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this._setStates("restaurantuuid", this.props.match.params.uuid);
    this.props.getVat({ restaurantuuid: this.props.match.params.uuid });
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
      if (!!newProps && !newProps.isFetching) {
        this._setStates("vat", newProps.vat);
      }
      if (!!this.state.isUpdated && !newProps.isFetching) {
        this._setStates("isUpdated", false);
        this.props.getVat({ restaurantuuid: this.props.match.params.uuid });
      }
      if (
        !!newProps.vatClassUpdateStatus.status &&
        !!this.state.addModal &&
        !newProps.isFetching
      ) {
        this.toggle();
      }
      if (
        !!newProps.vatClassAddStatus.status &&
        !!this.state.addModal &&
        !newProps.isFetching
      ) {
        this.toggle();
      }
    }
  }

  public addToggle(): void {
    this.setState((prevState: any) => ({
      addModal: !prevState.addModal,
      name: "",
      percentage: "",
      isEdit: false,
    }));
  }

  public deleteConfirmToggle(type: any): void {
    this.props.removeVat({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
    });
    this._setStates("isUpdated", true);
    this.deleteToggle();
  }

  public deleteToggle(): void {
    this.setState((prevState: any) => ({
      open: !prevState.open,
    }));
  }

  public getVatClassLocale(locales: any) {
    let vatLocale = commonService.applyLocalization(
      "restaurant",
      "name",
      locales.locales
    );
    return vatLocale;
  }

  public handleChange(e: any) {
    if (e.target.name === "name") {
      if (e.target.value.trim() === "") {
        this._setStates("errorName", this.props.t("vat.enterVatName"));
      } else {
        this._setStates("errorName", "");
      }
    }

    let validNumber = new RegExp(/^\d*\.?\d*$/);

    if (e.target.name === "percentage") {
      if (e.target.value.trim() !== "") {
        this._setStates("errorPercentage", "");
        this.setState({ isDisabled: true });
        if (!validNumber.test(e.target.value)) {
          this.setState({ isDisabled: true });
          this._setStates(
            "errorPercentage",
            this.props.t("vat.enterOnlyNumbersDecimal")
          );
        } else {
          this.setState({ isDisabled: false });
        }
      } else {
        this.setState({ isDisabled: true });
        this._setStates(
          "errorPercentage",
          this.props.t("vat.enterVatPercentage")
        );
      }
    }

    this._setStates(e.target.name, e.target.value);
  }

  public saveVatAdd(): void {
    this.props.addVat({
      restaurantuuid: this.state.restaurantuuid,
      data: {
        percentage: this.state.percentage,
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.name,
          },
        },
      },
    });
    this._setStates("isUpdated", true);
  }

  public saveVatEdit(): void {
    this.props.updateVat({
      restaurantuuid: this.state.restaurantuuid,
      uuid: this.state.uuid,
      data: {
        percentage: this.state.percentage,
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.name,
          },
        },
      },
    });
    this._setStates("isUpdated", true);
  }

  private setEditState(event: any) {
    let vatLocale = this.getVatClassLocale(event);
    this.setState({
      isDisabled: false,
      isEdit: true,
      name: vatLocale.name,
      percentage: event.percentage,
      uuid: event.uuid,
    });
  }

  public setEditable(event: any): void {
    let vatLocale = this.getVatClassLocale(event);
    this.setState({
      isEdit: true,
      name: vatLocale.name,
      percentage: event.percentage,
      uuid: event.uuid,
    });
    this.addToggle();
    this.setEditState(event);
  }

  public toggle(): void {
    this.setState({
      addModal: !this.state.addModal,
    });
  }

  public toggleModal = (type: any = "") => {
    let showModal = this.state.showModal;
    this._setStates("showModal", !showModal);
  };

  public toggleEditModal = () => {
    let showEditModal = this.state.showEditModal;
    this._setStates("showEditModal", !showEditModal);
  };

  public setDelete(event: any): void {
    this._setStates("uuid", event.uuid);
    this.deleteToggle();
  }

  public vatList(data: any): any {
    let vat;
    if (!!data && data.length > 0) {
      // eslint-disable-next-line
      const vatList: Array<any> = [];
      data.forEach((vat: any) => {
        vat["nameLower"] = commonService
          .applyLocalization("restaurant", "name", vat.locales)
          ["name"].toLowerCase();
        vatList.push(vat);
      });

      vat = _.sortBy(vatList, "nameLower").map((vatitem: any, i: number) => {
        let vatLocale = this.getVatClassLocale(vatitem);
        return (
          <VatCard
            key={i}
            percentage={vatitem.percentage}
            setStationDelete={this.setDelete.bind(this, vatitem)}
            setStationEditable={this.setEditable.bind(this, vatitem)}
            vatClass={vatLocale}
          />
        );
      });
      return vat;
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.props.t("common.noRecords")}
        </div>
      );
    }
  }

  private _setFunctionBindings(): void {
    this.addToggle = this.addToggle.bind(this);
    this.deleteConfirmToggle = this.deleteConfirmToggle.bind(this);
    this.deleteToggle = this.deleteToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.saveVatAdd = this.saveVatAdd.bind(this);
    this.saveVatEdit = this.saveVatEdit.bind(this);
    this.setDelete = this.setDelete.bind(this);
    this.setEditable = this.setEditable.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <SettingsHeaderComponent settings={"settings"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <SettingsNavComponent
                display={"vat"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <Link
                to="#"
                color="info"
                className="unit-add"
                onClick={this.addToggle}
              >
                {t("vat.addVat")}
              </Link>
              <div className="white-box mb-3">
                <h4>{t("vat.vat")}</h4>
                {this.vatList(this.props.vat)}
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>

        <VatAddEditCard
          addModal={this.state.addModal}
          addToggle={this.addToggle}
          handleChange={this.handleChange}
          isEdit={this.state.isEdit}
          isFetching={this.state.isFetching}
          name={this.state.name}
          percentage={this.state.percentage}
          saveAdd={this.state.isEdit ? this.saveVatEdit : this.saveVatAdd}
          toggle={this.toggle}
          errorPercentage={this.state.errorPercentage}
          errorName={this.state.errorName}
          isDisabled={this.state.isDisabled}
        />

        <DeleteModalCard
          cancelDelete={this.deleteToggle}
          isFetching={!!this.state.isFetching}
          isOpen={this.state.open}
          okDelete={this.deleteConfirmToggle}
        />
      </div>
    );
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.vatClass.isFetching ||
    state.vatClassAdd.isFetching ||
    state.vatClassRemove.isFetching ||
    state.vatClassUpdate.isFetching;
  let failure =
    state.vatClass.failure ||
    state.vatClassRemove.failure ||
    state.vatClassUpdate.failure ||
    state.vatClassAdd.failure;
  return {
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    failure: failure,
    isFetching: isFetching,
    vat: state.vatClass.data,
    vatClassAddStatus: state.vatClassAdd.data,
    vatClassRemoveStatus: state.vatClassRemove.data,
    vatClassUpdateStatus: state.vatClassUpdate.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    addVat: (credentials: any) => {
      dispatch(vatAdd(credentials));
    },
    getVat: (credentials: any) => {
      dispatch(vatRequest(credentials));
    },
    removeVat: (credentials: any) => {
      dispatch(vatRemove(credentials));
    },
    updateVat: (credentials: any) => {
      dispatch(vatUpdate(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(VatComponent))
);
