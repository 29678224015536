import "../../styles/page404.scss";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Alert, Table } from "reactstrap";

import { withTranslation } from "react-i18next";
import moment from "moment";
import commonService from "../../services/common.service";
class RevenuePerProductGroup extends Component<any> {
  render() {
    const { t, data } = this.props;
    return (
      <>
        {Object.keys(data?.revenuePerProductGroup || {})?.length === 0 && (
          <Alert color="info">{t("common.noRecords")}</Alert>
        )}
        {Object.keys(data?.revenuePerProductGroup || {})?.length > 0 && (
          <>
            <section className="mt-3 mb-5">
              <h5>{t("reports.revenuePerProductGroup")}</h5>

              <Table bordered striped>
                <thead>
                  <tr className="bg-light">
                    <th className="w-25">{t("reports.productGroup")}</th>

                    <th className="w-25">{t("common.currency")}</th>
                    <th className="w-25">{t("common.amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data?.revenuePerProductGroup || {})?.map(
                    (innerData: any) => {
                      return (
                        <tr>
                          <td>{innerData}</td>
                          <td>{data.restaurantCurrency}</td>
                          <td>
                            {commonService.displayDigitalAmount(
                              data?.revenuePerProductGroup
                                ? data?.revenuePerProductGroup[innerData]
                                : 0
                            )}
                          </td>
                        </tr>
                      );
                    }
                  )}
                  {Object.keys(data?.revenuePerProductGroup || {})?.length ===
                    0 && (
                    <tr>
                      <td colSpan={4} className="text-center">
                        {t("common.noRecords")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </section>
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withRouter(RevenuePerProductGroup));
