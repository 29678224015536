import { call, put, takeLatest } from "redux-saga/effects";

import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  tagAddSuccess,
  tagAddFailure,
  tagAssignFailure,
  tagAssignSuccess,
  tagRemoveSuccess,
  tagRemoveFailure,
  tagUpdateSuccess,
  tagUpdateFailure,
  tagsFailure,
  tagsSuccess,
} from "../redux/actions/tags.action";
import * as types from "../redux/actions/types";
import tags from "../services/tags.service";
import handleResponse from "../services/response.service";

const tagsCall = (params: object) => tags.tags(RESTAURANT_REQUEST, params);
const addTagCall = (params: any) => tags.addTag(RESTAURANT_REQUEST, params);
const removeTagCall = (params: any) =>
  tags.removeTag(RESTAURANT_REQUEST, params);
const updateTagCall = (params: any) =>
  tags.updateTag(RESTAURANT_REQUEST, params);

const assignTagCall = (params: any) =>
  tags.assignTag(RESTAURANT_REQUEST, params);
function* handleTagsRequest(params: object) {
  try {
    let result;
    result = yield call(tagsCall, params);
    yield handleTags200(result);
  } catch (error) {
    throw error;
  }
}

const handleTags200 = function* handleTags200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(tagsSuccess(result.data));
  } else {
    yield put(tagsFailure(result.data));
    handleResponse(result);
  }
};

function* handleTagAdd(params: object) {
  try {
    let result;
    result = yield call(addTagCall, params);
    yield handleTagAdd200(result);
  } catch (error) {
    throw error;
  }
}

const handleTagAdd200 = function* handleTagAdd200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(tagAddSuccess(result.data));
  } else {
    yield put(tagAddFailure(result.data));
  }
  handleResponse(result);
};

function* handleTagRemove(params: object) {
  try {
    let result;
    result = yield call(removeTagCall, params);
    yield handleTagRemove200(result);
  } catch (error) {
    throw error;
  }
}

const handleTagRemove200 = function* handleTagRemove200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(tagRemoveSuccess(result.data));
  } else {
    yield put(tagRemoveFailure(result.data));
  }
  handleResponse(result);
};

function* handleTagUpdate(params: object) {
  try {
    let result;
    result = yield call(updateTagCall, params);
    yield handleTagUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleTagUpdate200 = function* handleTagUpdate200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(tagUpdateSuccess(result.data));
  } else {
    yield put(tagUpdateFailure(result.data));
  }
  handleResponse(result);
};

function* handleTagAssign(params: object) {
  try {
    let result;
    result = yield call(assignTagCall, params);
    yield handleTagAssign200(result);
  } catch (error) {
    throw error;
  }
}

const handleTagAssign200 = function* handleTagUpdate200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(tagAssignSuccess(result.data));
  } else {
    yield put(tagAssignFailure(result.data));
  }
  handleResponse(result);
};

export default function* tagsSaga() {
  yield takeLatest(types.TAG_ADD.REQUEST, handleTagAdd);
  yield takeLatest(types.TAG_ASSIGN.REQUEST, handleTagAssign);
  yield takeLatest(types.TAGS.REQUEST, handleTagsRequest);
  yield takeLatest(types.TAG_REMOVE.REQUEST, handleTagRemove);
  yield takeLatest(types.TAG_UPDATE.REQUEST, handleTagUpdate);
}
