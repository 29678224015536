import { call, put, takeLatest } from 'redux-saga/effects';

import { EMAIL_CONFORMATION_REQUEST } from '../config/api.config';
import { emailconfirmationFailure, emailconfirmationSuccess } from '../redux/actions/email.confirmation.action';
import * as types from '../redux/actions/types';
import emailconfirmation from '../services/email.confirmation.service';
import handleResponse from '../services/response.service';

const emailconfirmationCall = (credentials: object) => emailconfirmation.emailconfirmation(EMAIL_CONFORMATION_REQUEST, credentials);

function* handleEmailConfirmationRequest(credentials: object) {
    try {
        let result;
        result = yield call(emailconfirmationCall, credentials);
        yield handleEmailConfirmation200(result);
    } catch (error) {
        throw error;
    }
}
const handleEmailConfirmation200 = function* handleEmailConfirmation200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(emailconfirmationSuccess(result.data));
    } else {
        yield put(emailconfirmationFailure(result.data));
    }
    handleResponse(result);
}

export default function* emailconfirmationSaga() {
    yield takeLatest(types.EMAIL_CONFORMATION.REQUEST, handleEmailConfirmationRequest);
}
