export interface ProfileState {
  first_name: string;
  last_name: string;
  city: string;
  country_code: string;
  countryname: string;
  email: string;
  isFetching: boolean;
  current_password: string;
  new_password: string;
  confirm_password: string;
  validate: boolean;
  editmodal: boolean;
  profile: any;
  imagemodal: boolean;
  filedata: any;
  isUpdated: boolean;
  image_url: string;
  languages: any;
  selectedLang: any;
  currentLanguage: string;
}

export const ProfileInitState = {
  first_name: "",
  last_name: "",
  city: "",
  country_code: "",
  countryname: "",
  email: "",
  isFetching: false,
  current_password: "",
  new_password: "",
  confirm_password: "",
  validate: true,
  editmodal: false,
  profile: [],
  imagemodal: false,
  filedata: [],
  isUpdated: false,
  image_url: "",
  languages: [],
  selectedLang: [],
  currentLanguage: "",
};

export const country = [
  { countrycode: "ch", countryname: "Switzerland" },
  { countrycode: "de", countryname: "Germany" },
  { countrycode: "in", countryname: "India" },
  { countrycode: "us", countryname: "United States" },
  { countrycode: "is", countryname: "Iceland" },
];
export interface ProfileModel {
  readonly isAuthenticated: boolean;
  readonly isFetching: boolean;
  readonly data: any;
  readonly error: object;
  readonly failure: boolean;
}
export interface ChangePasswordModel {
  readonly isAuthenticated: boolean;
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface NavHeader {
  isOpen: boolean;
  open: boolean;
  focusAfterClose: boolean;
  isFetching: boolean;
  restaurant: any;
  image_url: string;
  leftOpen: boolean;
  rightOpen: boolean;
  noOpen: boolean;
}

export const NavHeaderInitState = {
  isOpen: false,
  open: false,
  focusAfterClose: true,
  isFetching: false,
  restaurant: [],
  image_url: "",
  leftOpen: false,
  rightOpen: false,
  noOpen: false,
};
export interface ProfileSettingState {
  auto_assign: boolean;
  task_auto_open: boolean;
  step_auto_open: boolean;
  voice_commands: boolean;
  voice_commands_task: boolean;
  voice_commands_details_description: boolean;
  voice_commands_step_description: boolean;
  voice_commands_details_ingredients: boolean;
  voice_commands_step_ingredients: boolean;
  voice_recognition: boolean;
  isOpen: boolean;
  focusAfterClose: boolean;
  isFetching: boolean;
}

export const ProfileSettingInitState = {
  auto_assign: false,
  task_auto_open: false,
  step_auto_open: false,
  voice_commands: false,
  voice_commands_task: false,
  voice_commands_details_description: false,
  voice_commands_step_description: false,
  voice_commands_details_ingredients: false,
  voice_commands_step_ingredients: false,
  voice_recognition: false,
  isOpen: false,
  focusAfterClose: true,
  isFetching: false,
};

export interface ProfileSettingsModel {
  readonly isAuthenticated: boolean;
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
