import React, { Component, useRef } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ModalFactory from "react-modal-promise";
import commonService from "../../services/common.service";
import {
  ServiceSetInitState,
  ServiceSetState,
} from "../../models/serviceSetNew.model";
import { roomRequest } from "../../redux/actions/room.action";
import {
  servicesetAdd,
  servicesetClean,
  servicesetClear,
  servicesetRemove,
  servicesetRequest,
  servicesetUpdate,
  serviceSetTimeAdd,
  serviceSetUpdateChannel,
} from "../../redux/actions/serviceset.action";
import LoaderComponent from "../loader/loader";
import { OpenDeleteModal } from "../card-components/delete-card/delete-modal";
import ServiceSetCard from "./serviceSetCard";
import ServiceSetAddModal from "./serviceSetAddModal";
import ServiceSetAddRooms from "./serviceSetAddRooms";
import "./serviceSet.scss";
import { vatRequest } from "../../redux/actions/vat.action";
import { withTranslation } from "react-i18next";
import { weekdays } from "moment";
import {
  channelsRequest,
  restaurantChannelList,
} from "../../redux/actions/channels.action";
import _ from "lodash";
import { labelRequest } from "../../redux/actions/labels.action";
import {
  getSuppliersRestaurants,
  setServiceSetInventoryAccess,
  setServiceSetRestaurants,
  updateServiceSetLabel,
} from "../../services/service.set";
import * as LabelService from "../../services/labels.service";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faColumns } from "@fortawesome/free-solid-svg-icons";
import handleResponse from "../../services/response.service";

class ServiceSetWrapper extends Component<any> {
  endTime: any;
  endTimeRef: any;
  startTime: any;
  startTimeRef: any;
  state: ServiceSetState;
  constructor(props: any) {
    super(props);
    this.state = ServiceSetInitState;
  }

  componentDidMount() {
    this.props.vatList({ restaurantuuid: this.props.match.params.uuid });
    this.props.getRooms({
      restaurantuuid: this.props.match.params.uuid,
    });
    this.props.getRestaurantsChannelList({
      restaurantuuid: this.props.match.params.uuid,
    });
    this.props.getChannels({
      restaurantuuid: this.props.match.params.uuid,
    });
    this.setState({
      addLocationTitle: this.props.t("time.addTiming"),
    });
    this.getLabels();
    this.getSupplierList();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.isUpdated && !this.props.isFetching) this.fetchData();

    if (
      !this.props.isFetching &&
      this.state.isUpdated &&
      this.state.isDeleted &&
      !!this.props.servicesetRemove.status
    ) {
      this.setState({
        isDeleted: false,
      });

      this.props.history.push(
        `/restaurant/${this.props.match.params.uuid}/service-sets`
      );
    }

    if (
      !_.isEqual(prevProps.itemData, this.props.itemData) ||
      (prevProps.itemData.length > 0 &&
        Object.keys(prevState.allSavedLabels).length === 0)
    ) {
      this.handleDataStates();
    }
  }

  addRoomToggle = () => {
    this.setState({
      addLocationTitle: this.props.t("time.addTiming"),
      isRoomModal: !this.state.isRoomModal,
      itemModalData: {
        endTime: "00:00:00",
        rooms: [],
        startTime: "00:00:00",
        weekDays: [],
      },
    });
  };

  onDaySelected = (id: string) => {
    this.state.weekDays.forEach((days: any) => {
      if (id === days.id) {
        days.isSelected = !days.isSelected;
      }
    });
    this.setState({
      weekDays: this.state.weekDays,
    });
  };

  fetchData = () => {
    this.props.getServiceSet({
      restaurantuuid: this.props.match.params.uuid,
    });
    this.setState({
      isUpdated: false,
    });
    this.props?.setUpdated();
  };

  generateLabelOptions = (labels: Array<any>) => {
    let labelOptions: Array<any> = [];
    labels?.map((label: any) => {
      const option = {
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          label?.locales
        ).name,
        value: label.uuid,
        color: label.color,
      };
      labelOptions.push(option);
    });

    this.setState({
      labelOptions: _.sortBy(labelOptions, "label"),
    });
  };

  public getAddModalLabel = () => {
    return {
      modalTitle: this.state.isEdit
        ? this.props.t("serviceSet.editServiceSet")
        : this.props.t("serviceSet.addServiceSet"),
    };
  };

  getLabels = () => {
    const payLoad = {
      credentials: {
        restaurantuuid: this.props.match.params.uuid,
      },
    };
    LabelService.default
      .label(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        if (response.status === 200) {
          this.setState({ alLabels: response.data });
          this.generateLabelOptions(response.data);
        } else {
        }
      })
      .catch((e) => console.log(e));
  };
  getSortedServiceSets = (sets: Array<any>) => {
    return _.sortBy(sets, [
      (data: any) =>
        commonService
          .applyLocalization("restaurant", "name", data?.locales)
          .name.toLowerCase(),
    ]);
  };
  getSupplierList = () => {
    getSuppliersRestaurants(RESTAURANT_REQUEST, {
      credentials: {
        restaurantUuid: this.props.match.params.uuid,
      },
    })
      .then((response: any) => {
        this.setState({
          supplierRestaurants: response.data,
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  };
  handleDataStates = () => {
    const labels: any = {};
    this.props.itemData?.forEach((item: any) => {
      labels[item.uuid] = item.labels;
    });
    this.setState({
      allSavedLabels: labels,
    });
  };

  handleLabelDelete = (deleteId: any, serviceSet: string) => {
    OpenDeleteModal().then(() => {
      this.setState({
        isFetching: true,
      });
      let currentLabels: Array<any> = this.state.allSavedLabels[serviceSet];
      currentLabels = currentLabels
        .filter((label: any) => label.uuid !== deleteId)
        .map((label: any) => label?.label_uuid);

      this.handleLabelApiUpdate(currentLabels, serviceSet);
    });
  };

  handleLabelSave = (
    label: any,
    serviceSet: string,
    cardId: string | undefined
  ) => {
    this.setState({
      isFetching: true,
    });

    let currentLabels: Array<any> = this.state.allSavedLabels[serviceSet];
    if (!cardId) {
      currentLabels = currentLabels.map((lbl: any) => {
        return lbl?.label_uuid;
      });
      currentLabels.push(label.value);
    } else {
      currentLabels = currentLabels.map((lbl: any) => {
        if (lbl.uuid === cardId) {
          return label?.value;
        }
        return lbl?.label_uuid;
      });
    }

    this.handleLabelApiUpdate(currentLabels, serviceSet);
  };

  handleLabelUpdate = (data: any, serviceSet: string, type: string) => {
    this.setState({
      isFetching: true,
    });
    if (type === "sort") {
      const sortedLabels = data.map((label: any) => label.label_uuid);
      this.handleLabelApiUpdate(sortedLabels, serviceSet);
    }
  };

  handleLabelApiUpdate = (labels: any, serviceSet: string) => {
    const payLoad = {
      credentials: {
        restaurantuuid: this.props.match.params.uuid,
        serviceSetUuid: serviceSet,
        data: { label: labels },
      },
    };

    updateServiceSetLabel(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        if (response.status === 200) {
          const allSavedLabels = { ...this.state.allSavedLabels };
          allSavedLabels[serviceSet] = response.data.data.labels;
          this.setState({
            hasLabelUpdated: Math.floor(Math.random() * 1000 + 100),
            isFetching: false,
            allSavedLabels,
          });
          commonService.toastService(
            this.props.t("serviceSet.labelUpdatedSuccess"),
            "success"
          );
        }
      })

      .catch((error: any) => {
        this.setState({
          isFetching: false,
        });
        commonService.toastService("", "danger", JSON.stringify(error.error));
      });
  };

  public handleSubmit = (data: any) => {
    if (!!this.state.selectedItemId) {
      this.props.updateServiceSet({
        data,
        restaurantuuid: this.props.match.params.uuid,
        uuid: this.state.selectedItemId,
        items: [],
      });
    } else {
      this.props.addServiceSet({
        data,
        restaurantuuid: this.props.match.params.uuid,
      });
    }
    this.onAddNewItem();
    this.setState({
      isUpdated: true,
    });
  };

  public handleTimeSubmit = (type: string, data?: any) => {
    let serviceSet = [];
    serviceSet = this.props.itemData.filter(
      (item: any) => item.uuid === this.state.selectedItemId
    )[0];
    serviceSet?.times.forEach((time: any) => {
      let weekDays: any = [];
      time.week_days.forEach((day: any) => {
        day = Number(day);
        weekDays = [...weekDays, Number(day)];
      });
      time.week_days = weekDays;
    });
    let body: any;
    if (type === "add") {
      body = {
        time: [...serviceSet.times, ...data?.time],
      };
    } else if (type === "delete") {
      body = {
        time: [
          ...serviceSet.times.filter((time: any) => time.uuid !== data.uuid),
        ],
      };
    } else {
      body = {
        time: [
          ...serviceSet.times.filter(
            (time: any) => time.uuid !== data.time[0]?.uuid
          ),
          ...data?.time,
        ],
      };
    }

    this.props.addServiceSetTime({
      data: body,
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.state.selectedItemId,
    });
    if (type !== "delete") this.addRoomToggle();
    this.setState({
      isUpdated: true,
    });
    if (this.props?.handleTimeUpdate) {
      this.props.handleTimeUpdate(true);
    }
  };

  handleTimeUpdate = (type: string, element: any) => {
    OpenDeleteModal().then(() => {
      this.handleTimeSubmit("delete", element);
    });
  };

  public onAddNewItem = () => {
    this.setState((prevState: any) => ({
      isModalOpen: !prevState.isModalOpen,
      selectedItemId: "",
      isEdit: "",
      itemModalData: {
        name: "",
        description: "",
        status: 1,
        vatClass: {},
      },
    }));
  };

  handleTimeEdit = (time: any) => {
    const isDetailPage = this.props?.isDetailPage || false;
    const isServiceItemsUpdate = this.props?.isServiceItemsUpdate || false;
    if (
      !isDetailPage ||
      !isServiceItemsUpdate ||
      (isDetailPage &&
        isServiceItemsUpdate &&
        window.confirm(this.props.t("common.routeChangeAlert")) &&
        !!this.props?.isServiceItemsUpdate)
    ) {
      this.setState((prevState: any) => ({
        addLocationTitle: this.props.t("time.editTiming"),
        isRoomModal: !this.state.isRoomModal,
        itemModalData: {
          endTime: time.end_time,
          isEdit: true,
          rooms: time.room,
          startTime: time.start_time,
          weekDays: time.week_days,
          uuid: time.uuid,
        },
      }));
    }
  };

  public onItemDelete = (itemId: string) => {
    OpenDeleteModal().then(() => {
      this.props.removeServiceSet({
        restaurantuuid: this.props.match.params.uuid,
        uuid: itemId,
      });
      this.setState({
        isUpdated: true,
        isDeleted: true,
      });
    });
  };

  public onItemEdit = (itemId: any) => {
    const isDetailPage = this.props?.isDetailPage || false;
    const isServiceItemsUpdate = this.props?.isServiceItemsUpdate || false;
    if (
      !isDetailPage ||
      !isServiceItemsUpdate ||
      (isDetailPage &&
        isServiceItemsUpdate &&
        window.confirm(this.props.t("common.routeChangeAlert")) &&
        !!this.props?.isServiceItemsUpdate)
    ) {
      const [selectedItem]: any = this.props.itemData.filter((item: any) => {
        return item.uuid === itemId;
      });
      const locale = commonService.applyLocalization(
        "restaurant",
        "name",
        selectedItem.locales
      );

      this.setState({
        isEdit: true,
        isModalOpen: true,
        itemModalData: {
          isEdit: true,
          name: locale?.name,
          description: locale?.description,
          status: selectedItem.status,
          vatClass: selectedItem.vat_class_uuid,
        },
        selectedItemId: itemId,
      });
    }
  };

  public setTimeOn = () => {
    this.state.weekDays.forEach((element: any) => {
      element.isSelected = false;
    });
    this.setState({
      addTiming: !this.state.addTiming,
      startTimeIndex: "",
      endTimeIndex: "",
    });
  };
  public onEndTimeClicked = (event: any) => {
    if (this.state.editTime) {
      this.endTime = event.valueText;
    } else {
      this.endTime = event.valueText;
    }
  };

  public onstartTimeClicked = (event: any) => {
    if (this.state.editTime) {
      if (event.valueText > this.state.endTime[this.state.startTimeIndex]) {
        commonService.toastService(
          this.props.t("toast.startTimeNotGTendTime"),
          "warning"
        );
      } else {
        this.startTime = event.valueText;
      }
    } else if (this.state.startTime.length != this.state.endTime.length) {
      commonService.toastService(
        this.props.t("toast.pleaseChooseEndDate"),
        "warning"
      );
    } else {
      this.startTime = event.valueText;
      if (this.endTimeRef != null) {
        this.endTimeRef.instance.show();
      }
    }
  };

  public renderDays = (days: any) => {
    let weekDays = this.state.weekDays;
    let serviceSetDays = "";
    days?.forEach((day: string, index: any) => {
      let matchingDay = weekDays.find(
        (item: any) => String(item.id) === String(day)
      );
      if (index !== 0) serviceSetDays = serviceSetDays + ", ";
      if (Object.keys(matchingDay || {}).length > 0)
        serviceSetDays = serviceSetDays + matchingDay.day;
    });
    return serviceSetDays;
  };

  public renderRooms = (rooms: any) => {
    let serviceSetRooms = "";
    if (Object.keys(this.props.rooms).length > 0)
      rooms?.forEach((room: string, index: any) => {
        let matchingRoom = this.props?.rooms?.find(
          (item: any) => item.uuid === room
        );
        if (index !== 0) serviceSetRooms = serviceSetRooms + ", ";
        if (Object.keys(matchingRoom).length > 0)
          serviceSetRooms =
            serviceSetRooms +
            commonService.applyLocalization(
              "restaurant",
              "name",
              matchingRoom?.locales
            )["name"];
      });

    return serviceSetRooms;
  };

  saveRestaurants = (restaurants: Array<any>, serviceSetId: string) => {
    this.setState({
      isFetching: true,
    });
    const savedRestaurants =
      restaurants?.map((restaurant: any) => {
        return restaurant.value;
      }) || [];

    setServiceSetRestaurants(RESTAURANT_REQUEST, {
      credentials: {
        restaurantUuid: this.props.match.params.uuid,
        serviceSetUuid: serviceSetId,
        data: {
          customers: savedRestaurants,
        },
      },
    })
      .then((response: any) => {
        this.setState({
          isFetching: false,
        });
        if (response.data.status) {
          commonService.toastService(
            this.props.t("common.updatedSuccessFully"),
            "success"
          );
        } else {
          commonService.toastService(
            this.props.t("common.updatedFailed"),
            "danger"
          );
        }
      })
      .catch((error: any) => {
        this.setState({
          isFetching: false,
        });
        commonService.toastService("", "danger", JSON.stringify(error.error));
      });
  };

  saveInventoryAccessToClient = (
    inventoryAccess: boolean,
    serviceSetId: string
  ) => {
    this.setState({
      isFetching: true,
    });
    setServiceSetInventoryAccess(RESTAURANT_REQUEST, {
      credentials: {
        restaurantUuid: this.props.match.params.uuid,
        serviceSetUuid: serviceSetId,
        data: {
          inventoryAccess: inventoryAccess,
        },
      },
    })
      .then((response: any) => {
        this.setState({
          isFetching: false,
        });
        handleResponse(response);
      })
      .catch((error: any) => {
        this.setState({
          isFetching: false,
        });
        commonService.toastService("", "danger", JSON.stringify(error.error));
      });
  };

  public saveServiceSetChannel = (values: Array<any>) => {
    let updatedChannels: Array<any> = [];
    if (!!values && values.length > 0) {
      updatedChannels = values.map((value) => {
        return value.value;
      });
    }
    this.props.updateServiceSetChannel({
      data: { channels: updatedChannels },
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.state.selectedItemId,
    });
  };

  public setSelectedItem = (itemId: string) => {
    this.setState({
      selectedItemId: itemId,
    });
  };

  render() {
    const { t } = this.props;
    const { isModalOpen, isRoomModal } = this.state;
    return (
      <>
        <ModalFactory />
        <LoaderComponent
          display={this.props.isFetching || this.state.isFetching}
        />
        {!this.props?.isDetailPage && (
          <div className="justify-content-between d-flex">
            <Link
              to="#"
              color="info"
              className="unit-add"
              onClick={this.onAddNewItem}
            >
              {this.props.pageText.addButtonTitle}
            </Link>
            <Link
              to={`/restaurant/${this.props.match.params.uuid}/service-sets`}
              className="mx-2"
            >
              <FontAwesomeIcon
                className={" b-color-green mt-2 mx-2"}
                style={{ fontSize: 20 }}
                icon={faColumns}
              />
            </Link>
          </div>
        )}
        <ServiceSetAddModal
          formData={this.state.itemModalData}
          handleCancel={this.onAddNewItem}
          handleSubmit={this.handleSubmit}
          isEdit={this.state.isEdit}
          isModalOpen={isModalOpen}
          onEndTimeClicked={this.onEndTimeClicked}
          restaurantLang={this.props.restaurantLang}
          textLabels={this.getAddModalLabel()}
          vatList={this.props.vat}
        />
        <ServiceSetAddRooms
          formData={this.state.itemModalData}
          handleCancel={this.addRoomToggle}
          handleTimeSubmit={this.handleTimeSubmit}
          isRoomModal={isRoomModal}
          roomList={this.props.rooms}
          weekDays={this.state.weekDays}
          title={this.state.addLocationTitle}
        />
        <div className="white-box mb-3">
          <h4>{this.props.pageText.pageTitle}</h4>
          {this.props.cardType !== "image-title" &&
            this.props.cardType !== "title" &&
            this.getSortedServiceSets(this.props.itemData)?.map(
              (item: any, index: number) => (
                <React.Fragment key={index}>
                  <ServiceSetCard
                    addRoomToggle={this.addRoomToggle}
                    channelList={this.props?.channels || []}
                    channels={item?.channels || []}
                    handleTimeUpdate={this.handleTimeUpdate}
                    handleTimeEdit={this.handleTimeEdit}
                    hasLabelUpdate={this.state.hasLabelUpdated}
                    isDetailPage={this.props?.isDetailPage || false}
                    isServiceItemsUpdate={
                      this.props?.isServiceItemsUpdate || false
                    }
                    itemData={item}
                    itemTimings={item.times}
                    labels={this.state.allSavedLabels[item.uuid] || []}
                    labelOptions={this.state.labelOptions}
                    onMoveToDetailPage={this.props.onMoveToDetailPage}
                    onItemEdit={this.onItemEdit}
                    onItemDelete={this.onItemDelete.bind(this, item.uuid)}
                    onLabelDelete={this.handleLabelDelete}
                    onLabelSave={this.handleLabelSave}
                    onLabelUpdate={this.handleLabelUpdate}
                    renderDays={this.renderDays}
                    renderRooms={this.renderRooms}
                    restaurantList={this.state.supplierRestaurants}
                    restaurantChannelList={
                      this.props?.restaurantChannelList || []
                    }
                    setSelectedItem={this.setSelectedItem}
                    saveChannels={this.saveServiceSetChannel}
                    saveRestaurants={this.saveRestaurants}
                    saveInventoryAccessToClient={
                      this.saveInventoryAccessToClient
                    }
                    vatList={this.props.vat}
                  />
                </React.Fragment>
              )
            )}

          {this.props.itemData.length === 0 && (
            <div style={{ width: "100%", textAlign: "center" }}>
              {this.props.t("common.noRecords")}
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps: any = (state: any) => {
  let failure =
    state.channels.failure ||
    state.RestaurantsChannelsList.failure ||
    state.serviceSetChannelUpdate.failure ||
    state.serviceset.failure ||
    state.servicesetremove.failure ||
    state.servicesetadd.failure ||
    state.serviceSetTimeUpdate.failure ||
    state.servicesetupdate.failure;
  let isFetching =
    state.channels.isFetching ||
    state.RestaurantsChannelsList.isFetching ||
    state.serviceSetChannelUpdate.isFetching ||
    state.serviceset.isFetching ||
    state.servicesetremove.isFetching ||
    state.servicesetadd.isFetching ||
    state.serviceSetTimeUpdate.isFetching ||
    state.servicesetupdate.isFetching;
  return {
    failure: failure,
    isFetching: isFetching,
    channels: state.channels.data,
    restaurantChannelList:
      Object.keys(state.RestaurantsChannelsList.data).length > 0
        ? state.RestaurantsChannelsList.data?.filter(
            (item: any) =>
              item.restaurant === localStorage.getItem("RESTAURANT_ID")
          )[0]?.channels || []
        : [],
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    rooms: state.room.data,
    servicesetRemove: state.servicesetremove.data,
    vat: state.vatClass.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    addServiceSet: (credentials: any) => {
      dispatch(servicesetAdd(credentials));
    },
    addServiceSetTime: (credentials: any) => {
      dispatch(serviceSetTimeAdd(credentials));
    },
    getChannels: () => {
      dispatch(channelsRequest());
    },
    getRestaurantsChannelList: (credentials: any) => {
      dispatch(restaurantChannelList(credentials));
    },
    getRooms: (credentials: any) => {
      dispatch(roomRequest(credentials));
    },
    removeServiceSet: (credentials: any) => {
      dispatch(servicesetRemove(credentials));
    },
    serviceSets: (credentials: any) => {
      dispatch(servicesetRequest(credentials));
    },
    updateServiceSet: (credentials: any) => {
      dispatch(servicesetUpdate(credentials));
    },
    updateServiceSetChannel: (credentials: any) => {
      dispatch(serviceSetUpdateChannel(credentials));
    },
    vatList: (credentials: any) => {
      dispatch(vatRequest(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ServiceSetWrapper))
);
