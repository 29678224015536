import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function restaurantDetailRequest(credentials: object): ActionModel {
  return {
    type: types.RESTAURANT_DETAIL.REQUEST,
    credentials,
  };
}

export function restaurantDetailSuccess(data: object): ActionModel {
  return {
    type: types.RESTAURANT_DETAIL.SUCCESS,
    data,
  };
}

export function restaurantDetailFailure(err: object): ActionModel {
  return {
    type: types.RESTAURANT_DETAIL.FAILURE,
    err,
  };
}
