import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import commonService from "../../services/common.service";

interface PrintLabelModalProps {
  title: string;
  printerTemplates: Array<any>;
  isOpen: boolean;
  toggleModal: any;
  printPage: string;
}

export default function PrintLabelModal(props: PrintLabelModalProps) {
  const { title, printerTemplates, isOpen, toggleModal, printPage } = props;
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState<number>();

  const handlePrint =
    (index: number, item: any) =>
    (e: any): void => {
      setSelected(index);
      window.open(printPage + item.uuid);
      toggleModal();
    };

  const PrinterTemplatesList = () => {
    return (
      <ListGroup>
        {printerTemplates.length > 0 ? (
          <div className="modal-max-height">
            {printerTemplates.map((item: any, index: number) => {
              return (
                <ListGroupItem
                  key={index}
                  className={`
                    pointer-cursor ingredient-item-row  ${
                      selected === index ? "selected" : ""
                    }`}
                  onClick={handlePrint(index, item)}
                >
                  {
                    commonService.applyLocalization(
                      "restaurant",
                      "name",
                      item?.locales
                    )["name"]
                  }
                </ListGroupItem>
              );
            })}
          </div>
        ) : (
          <ListGroupItem>{t("common.noPrinterTemplates")}</ListGroupItem>
        )}
      </ListGroup>
    );
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggleModal} className="modal-header">
        {t(title)}
      </ModalHeader>
      <ModalBody>{PrinterTemplatesList()}</ModalBody>
    </Modal>
  );
}
