import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";

import {
  ACCESS_TOKEN,
  AUTHORIZED_PATHS,
  REDIRECT_URL,
} from "../../constant/constant";
import commonService from "../../services/common.service";

class AuthorizedRoute extends Component<any> {
  componentDidMount(): void {
    if (!this.props.logged) {
      const data: any = {
        url: this.props.computedMatch.url,
        restaurantUuid: this.props.computedMatch.params.uuid,
      };
      localStorage.setItem(REDIRECT_URL, JSON.stringify(data));
    }
  }

  authorizedPath = (): boolean => {
    const authorizePath = AUTHORIZED_PATHS.find(
      (route) => route.path === this.props.computedMatch.path
    );
    if (authorizePath) {
      return commonService.hasPermissionToAccess(
        authorizePath.right,
        this.props.computedMatch.params.uuid
      ) === true
        ? true
        : false;
    }
    return true;
  };

  render() {
    const { component: Component, pending, logged, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          if (pending || !this.authorizedPath()) return <Redirect to="/" />;
          return logged ? <Component {...this.props} /> : <Redirect to="/" />;
        }}
      />
    );
  }
}

const stateToProps = () => ({
  pending: !localStorage.getItem(ACCESS_TOKEN),
  logged: localStorage.getItem(ACCESS_TOKEN),
});

export default connect(stateToProps)(withRouter(AuthorizedRoute));
