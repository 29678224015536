import React, { Component } from "react";
import { faEdit, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import ModalFactory from "react-modal-promise";

import {
  PeriodicityDetailState,
  PeriodicityDetailInitState,
} from "../../models/periodicity.model";
import { withTranslation } from "react-i18next";
import {
  periodicityDetails,
  periodicityRemove,
  periodicityUpdate,
} from "../../redux/actions/periodicity.action";

import { withCardItemSelectionStateManager } from "../../components/cardItemSelectionManager";
import commonService from "../../services/common.service";
import ConceptHeaderComponent from "../../components/navigation/navigation-header/concept-header/concept-header";

import LoaderComponent from "../../components/loader/loader";
import moment from "moment";
import NavigationRightComponent from "../../components/navigation/navigation-right/navigation-right";
import RestaurantNavComponent from "../../components/navigation/navigation-left/restaurant-navigation/restaurant-navigation";
import WarningComment from "../../components/warning-comment/warning-comment";

import PeriodicityAddModal from "../../components/periodicity/periodicityAddModal";
import { compose } from "redux";
import PeriodsListCard from "../../components/periodicity/periodsListCard";
import { OpenDeleteModal } from "../../components/card-components/delete-card/delete-modal";
import DragAndDropWrapper from "../../components/drag-drop-component";
class PeriodicityDetailsComponent extends Component<any> {
  changedItems: any = {};
  editDataTask: any = [];
  expandedTasks: Set<any> = new Set();
  state: PeriodicityDetailState;
  constructor(props: any) {
    super(props);
    this.changedItems = {
      allContent: new Set(),
      inlineContent: new Set(),
    };
    this.state = PeriodicityDetailInitState;
  }
  componentDidMount() {
    document.body.className = "light-theme";
    this.props.getPeriodicityDetails({
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.props.match.params.itemUuid,
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (!this.state.isFetching && this.state.isUpdated) {
      this.props.cardItemSelectionData.deselectCardItem();
      this.setState({
        isEditModalOpen: false,
        isUpdated: false,
      });
      this.props.getPeriodicityDetails({
        restaurantuuid: this.props.match.params.uuid,
        uuid: this.props.match.params.itemUuid,
      });
    }
    if (this.state.isDeleted && this.state.removeItemData) {
      this.setState({
        isDeleted: false,
        removeItemData: {},
      });
      this.props.history.push(
        `/restaurant/${this.props.match.params.uuid}/periodicity`
      );
    }
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    let update: any = {};
    if (nextProps.isFetching !== prevState.isFetching) {
      update.isFetching = nextProps.isFetching;
    }
    if (
      JSON.stringify(nextProps.periodicityData) !==
      JSON.stringify(prevState.periodicityData)
    ) {
      update.periodicityData = nextProps.periodicityData;
      update.periodicityItems = nextProps.periodicityData?.items || [];
    }

    if (JSON.stringify(nextProps.removeItemData)) {
      update.removeItemData = nextProps.removeItemData;
    }

    return update;
  }

  render() {
    const { t } = this.props;
    const hasLoaded =
      this.state.periodicityData &&
      Object.keys(this.state.periodicityData).length !== 0 &&
      this.state.periodicityData.constructor === Object;
    return (
      <React.Fragment>
        <ModalFactory />
        <LoaderComponent display={!!this.state.isFetching} />

        <PeriodicityAddModal
          formData={this.state.editItemData}
          isModalOpen={this.state.isEditModalOpen}
          handleCancel={this.handleItemUpdateCancel}
          handleSubmit={this.handlePeriodSubmit}
        />
        <div className="container-fluid">
          <ConceptHeaderComponent concept={"concept"} />
          {hasLoaded && (
            <Row className="main light-theme">
              <Col xl={2} lg={3} className="hide-left-max">
                <RestaurantNavComponent
                  display={"date-item"}
                  restaurantuuid={this.props.match.params.uuid}
                />
              </Col>
              <Col xl={8} lg={6}>
                <div className="white-box mb-3">
                  <header className="d-flex justify-content-between px-0">
                    <h4>
                      {
                        commonService.applyLocalization(
                          "restaurant",
                          "name",
                          this.state.periodicityData.locales
                        )["name"]
                      }
                    </h4>

                    <div className="col-right">
                      <Button
                        color="link"
                        className="px-1 py-0"
                        onClick={() => {
                          this.handleEditPeriodicity();
                        }}
                        title={this.props.t("periodicity.editPeriodicity")}
                      >
                        <FontAwesomeIcon size="lg" icon={faEdit} />
                      </Button>
                      <Button
                        color="link"
                        className="px-1 py-0"
                        onClick={() => {
                          this.handleDeletePeriodicity();
                        }}
                        title={this.props.t("periodicity.removePeriodicity")}
                      >
                        <FontAwesomeIcon size="lg" icon={faTrashAlt} />
                      </Button>
                    </div>
                  </header>
                  <div>
                    <div className="child-box mb-3">
                      <Row className="p-0">
                        <label className="col-lg-3 col-sm-5 form-label">
                          {this.props.t("date.startDate")}
                        </label>
                        <Col lg={6} sm={6}>
                          {moment(this.state.periodicityData.start_date).format(
                            "DD-MM-YYYY"
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>

                <div className="dim-box mb-3">
                  <Row className="app-row-padding">
                    <Col xs={12}>
                      <h4>
                        {t("periodicity.periodicityItems")}
                        <WarningComment data={null} />
                      </h4>
                      {this.renderItemsBlock()}
                    </Col>
                  </Row>
                  <div className="my-3 text-right">
                    <Button
                      color="info"
                      onClick={this.handlePageSubmit}
                      disabled={
                        this.state.isFetching ||
                        this.state.isSaveButtonDisabled ||
                        this.props.cardItemSelectionData.selectionState.mode ===
                          "edit"
                      }
                    >
                      {t("common.save")}
                    </Button>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3}>
                <NavigationRightComponent />
              </Col>
            </Row>
          )}
        </div>
      </React.Fragment>
    );
  }

  public handleDeletePeriodicity = () => {
    OpenDeleteModal().then(() => {
      this.setState({ isDeleted: true });
      this.props.removePeriodicity({
        restaurantuuid: this.props.match.params.uuid,
        uuid: this.props.match.params.itemUuid,
      });
    });
  };
  public handleEditPeriodicity = () => {
    const name = commonService.applyLocalization(
      "restaurant",
      "name",
      this.state.periodicityData.locales
    ).name;

    this.setState({
      isEditModalOpen: true,
      editItemData: {
        name: name,
        date: new Date(this.state.periodicityData.start_date),
        isEdit: true,
      },
    });
  };
  public handleItemAddSubmit = (data: any) => {
    this.setState({ isSaveButtonDisabled: false });
    if (data?.indx >= 0) {
      const updatedArray: any = [...this.state.periodicityItems];
      updatedArray[data.indx] = data;
      delete updatedArray[data.indx].indx;
      this.setState(
        {
          periodicityItems: updatedArray,
        },
        this.handlePageSubmit
      );
    } else {
      this.setState(
        {
          periodicityItems: [...this.state.periodicityItems, data],
        },
        this.handlePageSubmit
      );
    }
  };
  public handleItemRemove = (indx: any) => {
    OpenDeleteModal().then(() => {
      const newItems = [...this.state.periodicityItems];
      newItems.splice(indx, 1);
      this.setState(
        {
          periodicityItems: newItems,
          isSaveButtonDisabled: false,
        },
        this.handlePageSubmit
      );
    });
  };
  public handleItemUpdateCancel = () => {
    this.setState({
      editItemData: {
        name: "",
        date: "",
        isEdit: true,
      },
      isEditModalOpen: false,
    });
  };
  public handlePageSubmit = () => {
    let data: any = { ...this.state.periodicityData };
    data.items = this.state.periodicityItems;
    this.props.cardItemSelectionData.deselectCardItem();
    // TODO Need to remove below codes, added temporary
    delete data.uuid;
    this.setState({ isUpdated: true });
    this.props.updatePeriodicity({
      data,
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.props.match.params.itemUuid,
    });
  };
  public handlePeriodSubmit = (data: any) => {
    const updatedData = {
      ...this.state.periodicityData,
      locales: data.locales,
      start_date: data.start_date,
    };

    this.setState({ isUpdated: true });
    // TODO Need to remove below codes, added temporary
    delete updatedData.uuid;
    this.props.updatePeriodicity({
      data: updatedData,
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.props.match.params.itemUuid,
    });
  };

  public reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  public onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    this.setState({ isSaveButtonDisabled: false });
    const itemsList = this.reorder(
      this.state.periodicityItems,
      result.source.index,
      result.destination.index
    );
    this.setState({
      periodicityItems: itemsList,
    });
  };

  public getPeriodicityLabel = (
    periodicityList: Array<any>,
    periodicity_item_uuid: string
  ) => {
    const [periodicity] = periodicityList.filter((periodicity: any) => {
      return periodicity.uuid === periodicity_item_uuid;
    });
    const label =
      commonService.applyLocalization(
        "restaurant",
        "name",
        periodicity?.locales
      )["name"] || null;
    return label;
  };

  public getChildrenProps = (card: any, index: any) => {
    const label = this.getPeriodicityLabel(
      this.state.periodicityItems || [],
      card.periodicity_item_uuid
    );

    return {
      indx: index,
      key: card.uuid,
      periodicityLabel: label,
      cardItemData: card,
      cardItemId: card.uuid,
    };
  };

  public renderItemsBlock = () => {
    return (
      <div>
        <DragAndDropWrapper
          getChildrenProps={this.getChildrenProps}
          onDragEnd={this.onDragEnd}
          itemsList={this.state.periodicityItems}
        >
          <PeriodsListCard
            key={this.props.key}
            indx={this.props.indx}
            cardItemData={this.props.cardItemData}
            cardItemId={this.props.cardItemId}
            isNewCardItem={false}
            onDeleteCard={this.handleItemRemove}
            onSaveCard={this.handleItemAddSubmit}
            parentCardItemSelectionData={this.props.cardItemSelectionData}
          />
        </DragAndDropWrapper>
        <div className="mx-n4 mt-3">
          <PeriodsListCard
            key={"newCard"}
            cardItemData={{ type: "newCard" }}
            cardItemId={"newCard"}
            isNewCardItem={true}
            onDeleteCard={this.handleItemRemove}
            onSaveCard={this.handleItemAddSubmit}
            parentCardItemSelectionData={this.props.cardItemSelectionData}
          />
        </div>
      </div>
    );
  };
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.periodicityDetails.isFetching ||
    state.periodicityRemove.isFetching ||
    state.periodicityUpdate.isFetching;
  let failure =
    state.periodicityDetails.failure ||
    state.periodicityRemove.failure ||
    state.periodicityUpdate.failure;

  return {
    isFetching: isFetching,
    failure: failure,
    periodicityData: state.periodicityDetails.data,
    removeItemData: state.periodicityRemove.data,
    updatePeriodicityData: state.periodicityUpdate.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getPeriodicityDetails: (credentials: any) => {
      dispatch(periodicityDetails(credentials));
    },
    removePeriodicity: (credentials: any) => {
      dispatch(periodicityRemove(credentials));
    },
    updatePeriodicity: (credentials: any) => {
      dispatch(periodicityUpdate(credentials));
    },
  };
};
const enhance = compose(
  withCardItemSelectionStateManager("Task"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);

export default withTranslation()(enhance(PeriodicityDetailsComponent));
