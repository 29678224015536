import React from "react";
import { withRouter, useParams } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import { recipePrintLabel } from "../../../services/recipe.printer.service";
import LabelPrint from "../../../components/printer/label-print";

const RecipeLabelPrintPage: React.FC = (props: any) => {
  const { uuid, recipeUuid, templateUuid } = useParams<{
    uuid: string;
    recipeUuid: string;
    templateUuid: string;
  }>();
  const { t } = useTranslation();
  const payload = {
    restaurantuuid: uuid,
    recipeUuid: recipeUuid,
    data: {
      template_uuid: templateUuid,
    },
  };
  return <LabelPrint payload={payload} printFn={recipePrintLabel} />;
};

export default withTranslation()(withRouter(RecipeLabelPrintPage));
