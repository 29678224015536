import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { QRCodeListState } from "../../models/qrcode.model";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import QRCodePlaceBlock from "./qrcode-place-block";
import { truncate } from "fs";
import { withTranslation } from "react-i18next";
import commonService from "../../services/common.service";
import _ from "lodash";
import { Container } from "reactstrap";

const getLocation = (id: number, locations: any) => {
  if (locations == null) {
    return null;
  }
  for (let location of locations) {
    for (let code of location.codes) {
      if (String(code.id) === String(id)) {
        return [location.id];
      }
    }
    let result: any = getLocation(id, location.child);
    if (result != null && result.length > 0) {
      result.unshift(location.id);
      return result;
    }
  }
  return null;
};

const getItems = (count: any, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `${k + offset}`,
    content: Math.random().toString(36).substring(7),
  }));

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const indexes: any = getLocation(startIndex, list);
  let currentArray;
  let newArray: any = list;
  const roomIndx: any = list.findIndex((data: any) => {
    return String(data.id) === String(indexes[0]);
  });
  if (indexes.length === 3) {
    const stationIndx: any = list[roomIndx]?.child.findIndex((data: any) => {
      return String(data.id) === String(indexes[1]);
    });
    const tableIndx: any = list[roomIndx]?.child[stationIndx]?.child.findIndex(
      (data: any) => {
        return String(data.id) === String(indexes[2]);
      }
    );
    currentArray = list[roomIndx].child[stationIndx].child[tableIndx];

    let result: any = Array.from(currentArray.codes);
    const [removed] = result.splice({ id: startIndex }, 1);
    result.splice(endIndex, 0, removed);
    newArray[roomIndx].child[stationIndx].child[tableIndx].codes = result;
  } else if (indexes.length === 2) {
    const stationIndx: any = list[roomIndx]?.child.findIndex((data: any) => {
      return String(data.id) === String(indexes[1]);
    });
    currentArray = list[roomIndx].child[stationIndx];
    let result: any = Array.from(currentArray.codes);
    const [removed] = result.splice({ id: startIndex }, 1);
    result.splice(endIndex, 0, removed);
    newArray[roomIndx].child[stationIndx].codes = result;
  } else if (indexes.length === 1) {
    currentArray = list[roomIndx];
    let result: any = Array.from(currentArray.codes);
    const [removed] = result.splice({ id: startIndex }, 1);
    result.splice(endIndex, 0, removed);
    newArray[roomIndx].codes = result;
  }
  return newArray;
};
// Move item from one list to other
const move = (list: any, sourceId: any, destId: any) => {
  const sourceArray = getLocation(sourceId, list);
  const destArray = getLocation(destId, list);
  let plukkedArray: any;
  let newList: any = [...list];
  let movedArray: any;
  const sourceRoomIndx: any = list.findIndex((data: any) => {
    return String(data.id) === String(sourceArray[0]);
  });
  const destRoomIndx: any = list.findIndex((data: any) => {
    return String(data.id) === String(destArray[0]);
  });

  if (sourceArray.length === 3) {
    const sourceStationIndx: any = list[sourceRoomIndx]?.child.findIndex(
      (data: any) => {
        return String(data.id) === String(sourceArray[1]);
      }
    );
    const sourceTableIndx: any = list[sourceRoomIndx]?.child[
      sourceStationIndx
    ]?.child.findIndex((data: any) => {
      return String(data.id) === String(sourceArray[2]);
    });

    const movedItemParent =
      list[sourceRoomIndx].child[sourceStationIndx].child[sourceTableIndx];
    let result: any = Array.from(movedItemParent.codes);
    movedArray = result.splice({ id: sourceId }, 1);
    newList[sourceRoomIndx].child[sourceStationIndx].child[
      sourceTableIndx
    ].codes = result;
  } else if (sourceArray.length === 2) {
    const sourceStationIndx: any = list[sourceRoomIndx]?.child.findIndex(
      (data: any) => {
        return String(data.id) === String(sourceArray[1]);
      }
    );

    const movedItemParent = list[sourceRoomIndx].child[sourceStationIndx];
    let result: any = Array.from(movedItemParent.codes);
    movedArray = result.splice({ id: sourceId }, 1);
    newList[sourceRoomIndx].child[sourceStationIndx].codes = result;
  } else if (sourceArray.length === 1) {
    const movedItemParent = list[sourceRoomIndx];
    let result: any = Array.from(movedItemParent.codes);
    movedArray = result.splice({ id: sourceId }, 1);
    newList[sourceRoomIndx].codes = result;
  }
  if (destArray.length === 3) {
    const destStationIndx: any = list[destRoomIndx]?.child.findIndex(
      (data: any) => {
        return String(data.id) === String(destArray[1]);
      }
    );
    const destTableIndx: any = list[destRoomIndx]?.child[
      destStationIndx
    ]?.child.findIndex((data: any) => {
      return String(data.id) === String(destArray[2]);
    });

    const movedItemParent =
      list[destRoomIndx].child[destStationIndx].child[destTableIndx];
    let result: any = Array.from(movedItemParent.codes);
    const destIndx = result.findIndex((data: any) => {
      return String(data.id) === String(destId);
    });
    result.splice(destIndx, 0, movedArray[0]);
    newList[destRoomIndx].child[destStationIndx].child[destTableIndx].codes =
      result;
  } else if (destArray.length === 2) {
    const destStationIndx: any = list[destRoomIndx]?.child.findIndex(
      (data: any) => {
        return String(data.id) === String(destArray[1]);
      }
    );

    const movedItemParent = list[destRoomIndx].child[destStationIndx];
    let result: any = Array.from(movedItemParent.codes);
    const destIndx = result.findIndex((data: any) => {
      return String(data.id) === String(destId);
    });
    result.splice(destIndx, 0, movedArray[0]);
    newList[destRoomIndx].child[destStationIndx].codes = result;
  } else if (destArray.length === 1) {
    const movedItemParent = list[destRoomIndx];
    let result: any = Array.from(movedItemParent.codes);
    const destIndx = result.findIndex((data: any) => {
      return String(data.id) === String(destId);
    });
    result.splice(destIndx, 0, movedArray[0]);
    newList[destRoomIndx].codes = result;
  }

  return newList;
};
const grid = 8;
class QRCodeLists extends Component<any> {
  state: QRCodeListState;
  constructor(props: any) {
    super(props);
    this.state = {
      items: getItems(3),
      rooms: this.props.roomsGrouped,
      selected: getItems(5, 10),
      stations: [],
      tables: [],
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }
  onDragEnd(result: any) {
    const { source, destination } = result;
    const sourceId: any = source.droppableId.split("_")[1];
    const destinationId: any = destination?.droppableId.split("_")[1];
    const draggableId: any = result.draggableId.split("_")[1];
    if (!destination) {
      return;
    }
    if (sourceId === destinationId) {
      // TODO : enable if we want sorting
      // const result = reorder(this.props.rooms, source.index, destination.index);
      // this.setState({ rooms: result });
    }
    // Interlist movement
    else {
      const destinationElem: any = document.querySelector(
        "[data-id='" + destination.droppableId + "']"
      ) as any;
      const destinationArea = destinationElem.getAttribute("data-area");
      this.props.onUpdateQrCodes(
        { destinationArea, destinationId },
        draggableId
      );
      //const result = move(this.props.rooms, source.index, destination.index);

      this.setState({ rooms: result });
    }
  }

  render() {
    const roomList: Array<any> = [];
    this.props.rooms.forEach((room: any) => {
      room["name"] = commonService
        .applyLocalization("restaurant", "name", room.locales)
        ["name"].toLowerCase();
      roomList.push(room);
    });
    return (
      <Container>
        <DragDropContext onDragEnd={this.onDragEnd}>
          {_.sortBy(roomList, "name").map((room: any, index: number) => (
            <QRCodePlaceBlock
              key={index}
              data={room}
              qrCodeBaseUrl={this.props.qrCodeBaseUrl}
              onNewCode={this.props.onNewCode}
              onRemoveCode={this.props.onRemoveCode}
              onSelection={this.props.onSelection}
            />
          ))}
        </DragDropContext>
      </Container>
    );
  }
}
export default withTranslation()(withRouter(QRCodeLists));
