import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";

import "react-datepicker/dist/react-datepicker.css";

export default function ItemAddModal(props: any) {
  const { t, i18n } = useTranslation();
  const [isSubmitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    setName(props.formData.name);
    setStartDate(props.formData.date);
    if (!!props.formData?.isEdit) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }
  }, [props.formData]);

  const handleChange = (data: any) => {
    setSubmitBtnDisabled(true);
    if (data?.target?.name === "periodicityName") {
      setName(data.target.value);
      if (data.target.value.trim() !== "" && !!startDate) {
        setSubmitBtnDisabled(false);
      }
    } else {
      setStartDate(data);
      if (!!data && name.trim() !== "") {
        setSubmitBtnDisabled(false);
      }
    }
  };
  const handleSubmit = () => {
    const lang = localStorage.getItem("i18nextLng") || "de-ch";
    let locales: any = {};
    locales[lang] = { name };

    props.handleSubmit({
      locales,
      start_date: moment(startDate, "YYYY-MM-DD|kk:mm").format(),
    });
  };
  return (
    <React.Fragment>
      <Modal isOpen={props.isModalOpen}>
        <ModalHeader toggle={props.handleCancel} className="modal-header">
          {t("periodicity.addPeriodicity")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="periodicityName" sm={6} lg={5}>
                {t("periodicity.title")} <span className="text-danger">*</span>
              </Label>
              <Col sm={6} lg={7}>
                <Input
                  name="periodicityName"
                  required
                  type="text"
                  value={name}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="startDate" sm={6} lg={5}>
                {t("date.startDate")} <span className="text-danger">*</span>
              </Label>

              <Col sm={6} lg={7}>
                <DatePicker
                  className="form-control w-100"
                  dateFormat="dd/MM/yyyy"
                  minDate={moment().toDate()}
                  disabledKeyboardNavigation
                  selected={startDate}
                  startDate={startDate}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.handleCancel}>
            {t("common.cancel")}
          </Button>
          <Button
            color="info"
            onClick={handleSubmit}
            disabled={isSubmitBtnDisabled}
          >
            {t("common.save")}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
