import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  Button,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

export default function StationAddCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <React.Fragment>
      <Modal isOpen={props.addstationmodal}>
        <ModalHeader toggle={props.addStationToggle} className="modal-header">
          {t("station.addStation")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="firstname">{t("station.stationName")}</Label>
              <Label className="required-star-style">*</Label>
              <Input
                type="text"
                required
                name="stationname"
                autoComplete="stationname"
                placeholder={t("station.enterStationName")}
                value={props.stationname}
                onChange={props.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="stationuuid">{t("room.room")}</Label>
              <Label className="required-star-style">*</Label>
              <CustomInput
                type="select"
                id="stationuuid"
                name="stationuuid"
                onChange={props.handleStationChange}
              >
                <option value="">{t("room.selectRoom")} ...</option>
                {props.ROMMLIST}
              </CustomInput>
            </FormGroup>
            <FormGroup>
              <Label for="printerUuid">{t("common.printOrder")}</Label>
              <CustomInput
                type="select"
                id="printerUuid"
                name="printerUuid"
                onChange={props.handlePrinterChange}
              >
                <option value="">{t("common.noPrinting")}...</option>
                {props.PRINTERLIST}
              </CustomInput>
            </FormGroup>
            <FormGroup>
              <Label for="color">{t("label.labelColor")}</Label>
              <Input
                type="color"
                name="color"
                placeholder="Color"
                value={props.color}
                onChange={props.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="pickingLocation">{t("station.pickingStation")}</Label>
              <Select
                options={props.otherStationList}
                autosize={true}
                menuPlacement="auto"
                menuPosition="fixed"
                className="w-100"
                onChange={(data) =>
                  props.handleChange({
                    target: { name: "pickingLocation", value: data },
                  })
                }
                value={props?.pickingLocation}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.addStationToggle}>
            {t("common.cancel")}
          </Button>
          <Button
            color="info"
            onClick={props.saveStationAdd}
            disabled={
              !props.stationname || !props.roomuuid || !!props.isFetching
            }
          >
            {t("common.save")}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
