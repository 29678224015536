import apiCall from "./api.call.config";

async function addRecipePrinter(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/printer",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function getKeyValueOptions(url: string, params: any) {
  try {
    let urlString: string =
      url +
      "/" +
      params.credentials.restaurantuuid +
      "/options?resource_type=" +
      params.credentials.resource_type +
      "&resource_id=" +
      params.credentials.resource_id;
    if (params.credentials?.key) urlString += "&key=" + params.credentials.key;
    return await apiCall.get(urlString);
  } catch (e) {
    throw e;
  }
}

async function recipePrinter(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.credentials.restaurantuuid + "/printer"
    );
  } catch (e) {
    throw e;
  }
}

async function removeRecipePrinter(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/printer" +
        "/" +
        params.credentials.uuid
    );
  } catch (e) {
    throw e;
  }
}

async function updateKeyValueOptions(url: string, params: any) {
  try {
    return await apiCall.put(
      url + "/" + params.credentials.restaurantuuid + "/options",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function updateRecipePrinter(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/printer/" +
        params.credentials.uuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function recipePrintLabel(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.restaurantuuid +
        "/recipe/" +
        params.recipeUuid +
        "/print-label",
      params.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  addRecipePrinter,
  getKeyValueOptions,
  recipePrinter,
  removeRecipePrinter,
  updateKeyValueOptions,
  updateRecipePrinter,
  recipePrintLabel,
};
