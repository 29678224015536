import apiCall from '../services/api.call.config';

async function registration(url: string, params: any) {
    try {
        return await apiCall.post(url, params.credentials);
    } catch (e) {
        throw e;
    }
}

export default { registration }
