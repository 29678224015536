import React from 'react';
import '../../../styles/card-item.scss';
import {CardItemContainer} from "./card-item-container";
import {CardItemCardComponent} from "./card-item-card-component";

function CardItem(props: any) {
    return (
        <CardItemContainer className={props.className}>
            <CardItemCardComponent {...props} />
        </CardItemContainer>
    )
}

export default CardItem;
