import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Collapse,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { GRAY, LIGHT_GREEN, RECIPE_TYPE } from "../../../constant/constant";
import { RecipeIngredientsModalProps } from "../../../models/task.model";

export default function RecipeIngredientsModal(
  props: RecipeIngredientsModalProps
) {
  const {
    addNewIngredientItem,
    allIngredients,
    isOpen,
    labelOptions,
    savedIngredients,
    saveRecipeSelection,
    toggleModal,
    title,
    multiSelect,
    hideArticles,
  } = props;
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [selectedLabelOptions, setSelectedLabelOptions] = useState<any>(null);
  const [searchByName, setSearchByName] = useState("");
  const [searchList, setSearchList] = useState<Array<any>>([]);
  const [selectedRecipes, setSetSelectedRecipes] = useState<Array<any>>([]);

  useEffect(() => {
    if (!isOpen) {
      setSetSelectedRecipes([]);
    }
  }, [isOpen]);

  useEffect(() => {
    handleTabSwitch();
  }, [allIngredients]);

  useEffect(() => {
    handleTabSwitch();
  }, [activeTab]);

  const toggleFilter = () => {
    setFilterOpen(!isFilterOpen);
  };

  const handleChange = (e: any) => {
    setSearchByName(e.target.value);
    handleSearch(e.target.value, selectedLabelOptions);
  };

  const handleFilterLabelChange = (selectedOptions: any) => {
    setSelectedLabelOptions(selectedOptions);
    handleSearch(searchByName, selectedOptions);
  };

  const handleSave = () => {
    if (selectedRecipes?.length > 0) {
      saveRecipeSelection(multiSelect ? selectedRecipes : selectedRecipes[0]);
      toggleModal();
    }
  };

  const handleSearch = (name: string, labels: Array<any>) => {
    const filteredItems: Array<any> = [];

    allIngredients.forEach((ingredient: any) => {
      if (ingredient.type !== activeTab) return;
      let currentItem: any = null;
      if (name.trim() !== "") {
        if (ingredient.label.toLowerCase().indexOf(name.toLowerCase()) > -1) {
          currentItem = ingredient;
        }
      } else {
        currentItem = ingredient;
      }
      if (currentItem !== null) {
        if (labels && labels.length > 0) {
          labels.forEach((item: any) => {
            let found = ingredient.label_uuids.find(
              (label: any) => label === item.value
            );
            if (!!found && found === item.value) {
              filteredItems.push(currentItem);
            }
          });
        } else {
          filteredItems.push(currentItem);
        }
      }
    });
    setSearchList(filteredItems);
  };

  const handleSelection = (item: any) => {
    setSetSelectedRecipes(
      multiSelect ? addOrRemoveItem([...selectedRecipes], item) : [item]
    );
  };

  const addOrRemoveItem = (recipes: Array<any>, item: any): Array<any> => {
    const itemIndex = recipes?.findIndex((i) => i.uuid === item.uuid);
    if (itemIndex !== -1) {
      recipes.splice(itemIndex, 1);
    } else {
      recipes.push(item);
    }

    return recipes;
  };

  const handleTabSwitch = () => {
    const filteredItems: Array<any> = allIngredients.filter(
      (ingredient: any) =>
        ingredient.type === activeTab &&
        (_.isUndefined(savedIngredients) ||
          !savedIngredients.includes(ingredient.uuid))
    );
    setSearchList(filteredItems);
    setSearchByName("");
    setSelectedLabelOptions([]);
  };

  const manageRecipeIngList = () => {
    return (
      <ListGroup>
        {searchList.length > 0 ? (
          <div className="modal-max-height">
            {searchList.map((item: any, index: number) => {
              const itemIndex = selectedRecipes?.findIndex(
                (i) => i.uuid === item.uuid
              );
              return (
                <ListGroupItem
                  key={index}
                  className={`
                    pointer-cursor ingredient-item-row  ${
                      itemIndex !== -1 ? "selected" : ""
                    }`}
                  onClick={() => handleSelection(item)}
                >
                  {item.label}
                </ListGroupItem>
              );
            })}
          </div>
        ) : (
          <ListGroupItem>{t("common.noRecords")}</ListGroupItem>
        )}
      </ListGroup>
    );
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggleModal} className="modal-header">
        {t(title ? title : "recipe.AddRecipeIngredient")}
      </ModalHeader>
      <ModalBody>
        <Nav tabs style={{ margin: 10 }}>
          <NavItem>
            <NavLink
              className={
                activeTab === RECIPE_TYPE.RECIPE
                  ? "active"
                  : "" + " pointer-cursor"
              }
              onClick={() => {
                setActiveTab(RECIPE_TYPE.RECIPE);
              }}
            >
              {t("recipe.recipes")}
            </NavLink>
          </NavItem>
          {!hideArticles ? (
            <NavItem>
              <NavLink
                className={
                  activeTab === RECIPE_TYPE.INGREDIENT
                    ? "active"
                    : "" + " pointer-cursor"
                }
                onClick={() => {
                  setActiveTab(RECIPE_TYPE.INGREDIENT);
                }}
              >
                {t("article.articles")}
              </NavLink>
            </NavItem>
          ) : null}
          <NavItem>
            <NavLink
              className={
                activeTab === RECIPE_TYPE.CHOICE
                  ? "active"
                  : "" + " pointer-cursor"
              }
              onClick={() => {
                setActiveTab(RECIPE_TYPE.CHOICE);
              }}
            >
              {t("choiceItem.choiceItems")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={
                activeTab === RECIPE_TYPE.INGREDIENT_CLASS
                  ? "active"
                  : "" + " pointer-cursor"
              }
              onClick={() => {
                setActiveTab(RECIPE_TYPE.INGREDIENT_CLASS);
              }}
            >
              {t("ingredient.ingredientClass")}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} style={{ margin: 10 }}>
          <div className="modal-top-button">
            <div className="align-items-center d-flex justify-content-between">
              {!_.isUndefined(addNewIngredientItem) && (
                <Button
                  color="info"
                  className="mx-n3"
                  onClick={() => addNewIngredientItem(activeTab)}
                >
                  {activeTab === RECIPE_TYPE.RECIPE
                    ? t("recipe.addRecipe")
                    : activeTab === RECIPE_TYPE.INGREDIENT
                    ? t("article.addArticle")
                    : activeTab === RECIPE_TYPE.CHOICE
                    ? t("choiceItem.addChoice")
                    : t("substituteGroup.addSubstituteGroup")}
                </Button>
              )}
              <div className="text-right">
                <FontAwesomeIcon
                  style={{
                    color: `${isFilterOpen ? LIGHT_GREEN : GRAY}`,
                    fontSize: 20,
                  }}
                  icon={faFilter}
                  onClick={toggleFilter}
                  className="pointer-cursor"
                />
              </div>
            </div>

            <Collapse isOpen={isFilterOpen}>
              <div className="search-box">
                <h6 style={{ padding: 5 }}> {t("label.searchNameAndLabel")}</h6>
                <div style={{ margin: 5 }}>
                  <Select
                    value={selectedLabelOptions}
                    onChange={handleFilterLabelChange}
                    options={labelOptions}
                    isMulti
                    maxMenuHeight={200}
                    placeholder={t("label.searchByLabel")}
                  />
                </div>
                <div style={{ margin: 5 }}>
                  <Input
                    type="search"
                    name="searchByName"
                    placeholder={t("label.searchByName")}
                    onChange={handleChange}
                    value={searchByName}
                  />
                </div>
              </div>
            </Collapse>
          </div>
          {manageRecipeIngList()}
        </TabContent>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={() => handleSave()}
          disabled={selectedRecipes?.length === 0}
        >
          {t("common.save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
