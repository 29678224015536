import apiCall from "./api.call.config";

export async function getRolesRights(url: string, restaurantuuid: string) {
  try {
    return await apiCall.get(url + "/" + restaurantuuid + "/rights");
  } catch (e) {
    throw e;
  }
}

export default {
  getRolesRights,
};
