import React, { Component } from "react";
import { Button, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { create } from "apisauce";

import ModalFactory from "react-modal-promise";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

import { ACCESS_TOKEN } from "../../constant/constant";
import { BASE_URL } from "../../config/app.config";
import commonService from "../../services/common.service";
import { OpenDeleteModal } from "../../components/card-components/delete-card/delete-modal";
import LoaderComponent from "../../components/loader/loader";

import NavigationRightComponent from "../../components/navigation/navigation-right/navigation-right";

import { withCardItemSelectionStateManager } from "../../components/cardItemSelectionManager";

import {
  CourseListInitState,
  CourseListState,
} from "../../models/course.list.model";

import SettingsNavComponent from "../../components/navigation/navigation-left/settings-navigation/settings-navigation";
import SettingsHeaderComponent from "../../components/navigation/navigation-header/settings-header/settings-header";
import CourseListCard from "../../components/course-list/CourseListCard";
import {
  courseListRequest,
  courseListUpdate,
} from "../../redux/actions/course.list.action";

import DragAndDropWrapper from "../../components/drag-drop-component";

const api = create({
  baseURL: BASE_URL,
});
class CourseListPage extends Component<any> {
  changedItems: any = {};
  editDataTask: any = [];
  expandedTasks: Set<any> = new Set();
  state: CourseListState;
  constructor(props: any) {
    super(props);
    this.changedItems = {
      allContent: new Set(),
      inlineContent: new Set(),
    };
    this.state = CourseListInitState;
    api.setHeaders({
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Cache-Control": "no-cache",
      Locale: localStorage.getItem("i18nextLng") || "en-gb",
      "content-type": "application/json",
    });
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.getCourseList({
      restaurantuuid: this.props.match.params.uuid,
    });
    this.setState({ isJustMounted: true });
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (!this.state.isFetching && this.state.isJustMounted) {
      this.props.getCourseList({
        restaurantuuid: this.props.match.params.uuid,
      });
      this.setState({ isJustMounted: false, itemsList: this.props.courseList });
    }
    if (
      this.state.isDeleted &&
      JSON.stringify(prevState.removeItemData) !==
        JSON.stringify(this.state.removeItemData)
    ) {
      this.setState({
        isDeleted: false,
        removeItemData: {},
      });
      this.props.history.push(
        `/restaurant/${this.props.match.params.uuid}/course-list`
      );
    }
    if (this.state.fetchData && !this.state.isFetching) {
      this.props.getCourseList({
        restaurantuuid: this.props.match.params.uuid,
      });
      this.setState({ isJustMounted: false, fetchData: false });
    }
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    let update: any = {};
    if (nextProps.isFetching !== prevState.isFetching) {
      update.isFetching = nextProps.isFetching;
    }
    if (
      !!Object.keys(nextProps.courseList).length &&
      nextProps.courseList !== prevState.courseList
    ) {
      update.courseList = nextProps.courseList;
      update.itemsList = nextProps.courseList;
    }
    if (JSON.stringify(nextProps.removeItemData)) {
      update.removeItemData = nextProps.removeItemData;
    }
    return update;
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <ModalFactory />
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <SettingsHeaderComponent settings={"settings"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <SettingsNavComponent
                display={"courseList"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={8} lg={6}>
              <div className="dim-box mb-3 bg-white">
                <h4>{t("course.courseList")}</h4>
                {!this.state.isJustMounted && (
                  <div>
                    <Row className="app-row-padding">
                      <Col xs={12}>
                        {!this.state.isJustMounted && this.renderItemsBlock()}
                      </Col>
                    </Row>
                    <div className="my-3 text-right">
                      <Button
                        color="info"
                        onClick={this.handleDataItemSave}
                        disabled={
                          this.state.isFetching ||
                          this.state.isSaveButtonDisabled ||
                          this.props.cardItemSelectionData.selectionState
                            .mode === "edit"
                        }
                      >
                        {t("common.save")}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }

  public fetchData = () => {
    this.setState({
      fetchData: true,
    });
  };

  public handleDataItemSave = () => {
    this.setState({ isFetching: true });
    let dataItems: any = [];
    !!this.state.itemsList &&
      this.state.itemsList.length > 0 &&
      this.state.itemsList.map((item: any) => {
        if (!!item) {
          delete item.sort_order;
          if (!!item?.uuid && item?.uuid?.includes("sampleid")) {
            delete item.uuid;
          }
          Object.keys(item.locales).forEach((key) => {
            delete item.locales[key].uuid;
          });
          dataItems.push(item);
        }
      });

    this.props.courseListUpdate({
      restaurantuuid: this.props.match.params.uuid,
      data: dataItems,
    });
    this.fetchData();
  };

  public handleItemSave = (data: any, cardId: any, index: any) => {
    this.setState({ isSaveButtonDisabled: false });
    let dataItems: any = [];
    this.state.itemsList &&
      this.state.itemsList.length > 0 &&
      this.state.itemsList.map((item: any) => {
        dataItems.push(item);
      });
    if (cardId === "newCard") {
      dataItems.push(data);
    } else {
      dataItems[index] = data;
    }
    this.props.cardItemSelectionData.deselectCardItem();
    this.setState({
      itemsList: dataItems,
    });
  };

  public handleItemRemove = (indx: any) => {
    OpenDeleteModal().then(() => {
      let items = [...this.state.itemsList];
      this.props.cardItemSelectionData.deselectCardItem();
      items.splice(indx, 1);
      this.setState({
        itemsList: items,
        isSaveButtonDisabled: false,
      });
    });
  };

  public handleItemUpdateCancel = () => {
    this.setState({
      editItemData: {
        name: "",
        isEdit: true,
      },
    });
  };

  public reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  public onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    this.setState({ isSaveButtonDisabled: false });
    const itemsList = this.reorder(
      this.state.itemsList,
      result.source.index,
      result.destination.index
    );
    this.setState({
      itemsList,
    });
  };

  public getChildrenProps = (card: any, index: any) => {
    let courseListLocale = commonService.applyLocalization(
      "restaurant",
      "name",
      card.locales
    );

    return {
      indx: index,
      key: card.uuid,
      courseListName: courseListLocale.name,
      cardItemData: card,
      cardItemId: card.uuid,
    };
  };

  public renderItemsBlock = () => {
    return (
      <div>
        <DragAndDropWrapper
          getChildrenProps={this.getChildrenProps}
          onDragEnd={this.onDragEnd}
          itemsList={this.state.itemsList}
        >
          <CourseListCard
            key={this.props.key}
            itemsList={this.state.itemsList}
            isNewCardItem={false}
            onDeleteCard={this.handleItemRemove}
            onSaveCard={this.handleItemSave}
            parentCardItemSelectionData={this.props.cardItemSelectionData}
            saveButton={false}
          />
        </DragAndDropWrapper>
        <CourseListCard
          key={"newCard"}
          cardItemData={{ type: "newCard" }}
          cardItemId={"newCard"}
          isNewCardItem={true}
          itemsList={this.state.itemsList}
          onDeleteCard={this.handleItemRemove}
          onSaveCard={this.handleItemSave}
          parentCardItemSelectionData={this.props.cardItemSelectionData}
        />
      </div>
    );
  };

  public setItemId = (itemId: string) => {
    this.setState({
      elementId: itemId,
    });
  };
}

const mapStateToProps: any = (state: any) => {
  return {
    isFetching:
      state.courseList.isFetching || state.courseListUpdate.isFetching,
    failure: state.courseList.failure || state.courseListUpdate.failure,
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    courseList: state.courseList.data,
    courseListUpdate: state.courseListUpdate.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getCourseList: (credentials: any) => {
      dispatch(courseListRequest(credentials));
    },
    courseListUpdate: (credentials: any) => {
      dispatch(courseListUpdate(credentials));
    },
  };
};
const enhance = compose(
  withCardItemSelectionStateManager("Task"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);

export default withTranslation()(enhance(CourseListPage));
