export interface PrinterTemplateModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export const PrinterTemplateModelInitState = {
  isFetching: false,
  data: [],
  error: {},
  failure: false,
};

export interface PrinterTemplateState {
  addItemData: any;
  elementId: any;
  fetchData: any;
  isAddItemModalOpen: boolean;
  isDeleted: boolean;
  isFetching: boolean;
  itemDetails: any;
  itemModalData: any;
  items: Array<any>;
  itemsDetails: any;
  removeItemData: any;
  selectedItemId: string;
  updateItemData: any;
  updatePrinterTemplate: any;
}

export const PrinterTemplateInitState = {
  addItemData: {},
  elementId: "",
  fetchData: false,
  isAddItemModalOpen: false,
  isDeleted: false,
  isFetching: false,
  itemDetails: [],
  itemModalData: {
    date: "",
    isEdit: false,
    name: "",
  },
  items: [],
  itemsDetails: [],
  removeItemData: {},
  selectedItemId: "",
  updateItemData: {},
  updatePrinterTemplate: {},
};

