import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function storageRequest(credentials: object): ActionModel {
    return {
      type: types.STORAGE.REQUEST,
      credentials
    };
  }
  
  export function storageSuccess(data: object): ActionModel {
    return {
      type: types.STORAGE.SUCCESS,
      data
    };
  }
  
  export function storageFailure(err: object): ActionModel {
    return {
      type: types.STORAGE.FAILURE,
      err
    };
  }

  export function storageAdd(credentials: object): ActionModel {
    return {
      type: types.STORAGE_ADD.REQUEST,
      credentials
    };
  }
  
  export function storageAddSuccess(data?: object): ActionModel {
    return {
      type: types.STORAGE_ADD.SUCCESS,
      data
    };
  }
  
  export function storageAddFailure(err: object): ActionModel {
    return {
      type: types.STORAGE_ADD.FAILURE,
      err
    };
  }

  export function storageUpdate(credentials: object): ActionModel {
    return {
      type: types.STORAGE_UPDATE.REQUEST,
      credentials
    };
  }
  
  export function storageUpdateSuccess(data?: object): ActionModel {
    return {
      type: types.STORAGE_UPDATE.SUCCESS,
      data
    };
  }
  
  export function storageUpdateFailure(err: object): ActionModel {
    return {
      type: types.STORAGE_UPDATE.FAILURE,
      err
    };
  }
  export function storageRemove(credentials: object): ActionModel {
    return {
      type: types.STORAGE_REMOVE.REQUEST,
      credentials
    };
  }
  
  export function storageRemoveSuccess(data?: object): ActionModel {
    return {
      type: types.STORAGE_REMOVE.SUCCESS,
      data
    };
  }
  
  export function storageRemoveFailure(err: object): ActionModel {
    return {
      type: types.STORAGE_REMOVE.FAILURE,
      err
    };
  }
