import apiCall from "./api.call.config";

async function getDateItemList(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.credentials.uuid + "/recipe/list?sort=name:asc&&type=5"
    );
  } catch (e) {
    throw e;
  }
}

export default {
  getDateItemList,
};
