import { PrinterMessageModel } from '../../models/printer.message.model';
import * as types from '../actions/types';

const initialState: PrinterMessageModel = {
  isFetching: false,
  data: {},
  error: {},
  failure: false,
};
export default function printerMessageRemoveReducer(state = initialState, action: any): PrinterMessageModel {
  switch (action.type) {
    case types.PRINTER_MESSAGE_REMOVE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {}
      };
    case types.PRINTER_MESSAGE_REMOVE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {}
      };
    case types.PRINTER_MESSAGE_REMOVE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err
      };
    default:
      return state;
  }
}
