import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { Badge, Col, Label, Row } from "reactstrap";
import { GRAY, GREEN } from "../../../constant/constant";
import "../../../styles/units.scss";
import commonService from "../../../services/common.service";

export default function RoomCard(props: any) {
  const { t, i18n } = useTranslation();
  let roomLocale = commonService.applyLocalization(
    "restaurant",
    "name",
    props.locales.locales
  );
  return (
    <div className="child-box mb-3">
      <Row className="app-row-padding">
        <Col>
          <h6>{roomLocale.name}</h6>
        </Col>
        <div style={{ float: "right" }}>
          <span
            onClick={props.setEditable}
            className="pointer-cursor"
            data-toggle="tooltip"
            data-placement="left"
            title={t("common.edit")}
          >
            <FontAwesomeIcon
              style={{ color: GREEN, fontSize: 20 }}
              icon={faEdit}
            />
          </span>
          <span
            style={{ margin: 10 }}
            onClick={props.setDelete}
            className="pointer-cursor"
            data-toggle="tooltip"
            data-placement="left"
            title={t("common.delete")}
          >
            <FontAwesomeIcon
              style={{ color: GRAY, fontSize: 20 }}
              icon={faTrashAlt}
            />
          </span>
        </div>
      </Row>
      <Row className="app-row-padding">
        <Col xs="68" sm="3">
          <Label>{t("station.station")}:</Label> ({props.locales.station_count})
        </Col>
        <Col xs="6" sm="3">
          <Label>{t("machine.machines")}:</Label> ({props.locales.machine_count}
          )
        </Col>
        <Col xs="6" sm="3">
          <Label>{t("storage.storage")}:</Label> ({props.locales.storage_count})
        </Col>
        <Col xs="6" sm="3">
          <Label>{t("room.pickingRoom")}:</Label>{" "}
          <Badge className="text-dark" color="light">
            {props?.pickingLocation?.label ? props.pickingLocation.label : ""}
          </Badge>
        </Col>
      </Row>
    </div>
  );
}
