import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function recipeRequest(credentials: object): ActionModel {
  return {
    type: types.RECIPE.REQUEST,
    credentials,
  };
}

export function recipeSuccess(data: object): ActionModel {
  return {
    type: types.RECIPE.SUCCESS,
    data,
  };
}

export function recipeFailure(err: object): ActionModel {
  return {
    type: types.RECIPE.FAILURE,
    err,
  };
}

export function recipeUpdate(credentials: object): ActionModel {
  return {
    type: types.RECIPE_UPDATE.REQUEST,
    credentials,
  };
}

export function recipeUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.RECIPE_UPDATE.SUCCESS,
    data,
  };
}

export function recipeUpdateFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_UPDATE.FAILURE,
    err,
  };
}
export function recipePublish(credentials: object): ActionModel {
  return {
    type: types.RECIPE_PUBLISH.REQUEST,
    credentials,
  };
}

export function recipePublishSuccess(data?: object): ActionModel {
  return {
    type: types.RECIPE_PUBLISH.SUCCESS,
    data,
  };
}

export function recipePublishFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_PUBLISH.FAILURE,
    err,
  };
}
export function recipeEdit(credentials: object): ActionModel {
  return {
    type: types.RECIPE_EDIT.REQUEST,
    credentials,
  };
}
export function recipeRemoved(): ActionModel {
  return {
    type: types.RECIPE_EDIT.REMOVE,
  };
}
export function recipeEditSuccess(data?: object): ActionModel {
  return {
    type: types.RECIPE_EDIT.SUCCESS,
    data,
  };
}

export function recipeEditFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_EDIT.FAILURE,
    err,
  };
}
export function recipeAdd(credentials: object): ActionModel {
  return {
    type: types.RECIPE_ADD.REQUEST,
    credentials,
  };
}

export function recipeAddSuccess(data?: object): ActionModel {
  return {
    type: types.RECIPE_ADD.SUCCESS,
    data,
  };
}

export function recipeAddFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_ADD.FAILURE,
    err,
  };
}

export function recipeRemove(credentials: object): ActionModel {
  return {
    type: types.RECIPE_REMOVE.REQUEST,
    credentials,
  };
}

export function recipeRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.RECIPE_REMOVE.SUCCESS,
    data,
  };
}

export function recipeRemoveFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_REMOVE.FAILURE,
    err,
  };
}

export function recipeIngredientRequest(credentials: object): ActionModel {
  return {
    type: types.RECIPE_INGREDIENT.REQUEST,
    credentials,
  };
}

export function recipeIngredientSuccess(data: object): ActionModel {
  return {
    type: types.RECIPE_INGREDIENT.SUCCESS,
    data,
  };
}

export function recipeIngredientFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_INGREDIENT.FAILURE,
    err,
  };
}

export function recipeIngredientAdd(credentials: object): ActionModel {
  return {
    type: types.RECIPE_INGREDIENT_ADD.REQUEST,
    credentials,
  };
}

export function recipeIngredientAddSuccess(data?: object): ActionModel {
  return {
    type: types.RECIPE_INGREDIENT_ADD.SUCCESS,
    data,
  };
}

export function recipeIngredientAddFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_INGREDIENT_ADD.FAILURE,
    err,
  };
}

export function recipeIngredientRemove(credentials: object): ActionModel {
  return {
    type: types.RECIPE_INGREDIENT_REMOVE.REQUEST,
    credentials,
  };
}

export function recipeIngredientRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.RECIPE_INGREDIENT_REMOVE.SUCCESS,
    data,
  };
}

export function recipeIngredientRemoveFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_INGREDIENT_REMOVE.FAILURE,
    err,
  };
}

export function recipeIngredientUpdate(credentials: object): ActionModel {
  return {
    type: types.RECIPE_INGREDIENT_UPDATE.REQUEST,
    credentials,
  };
}

export function recipeIngredientUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.RECIPE_INGREDIENT_UPDATE.SUCCESS,
    data,
  };
}

export function recipeIngredientUpdateFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_INGREDIENT_UPDATE.FAILURE,
    err,
  };
}

export function recipeDetailsRequest(credentials: object): ActionModel {
  return {
    type: types.RECIPE_DETAILS.REQUEST,
    credentials,
  };
}

export function recipeDetailsSuccess(data: object): ActionModel {
  return {
    type: types.RECIPE_DETAILS.SUCCESS,
    data,
  };
}

export function recipeDetailsFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_DETAILS.FAILURE,
    err,
  };
}

export function clearRecipeDetails(credentials: object): ActionModel {
  return {
    type: types.RECIPE_DETAILS.REMOVE,
    credentials,
  };
}

export function allRecipeRequest(credentials: object): ActionModel {
  return {
    type: types.RECIPE_ALL.REQUEST,
    credentials,
  };
}

export function allRecipeSuccess(data: object): ActionModel {
  return {
    type: types.RECIPE_ALL.SUCCESS,
    data,
  };
}

export function allRecipeFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_ALL.FAILURE,
    err,
  };
}

export function recipeCopy(credentials: object): ActionModel {
  return {
    type: types.RECIPE_COPY.REQUEST,
    credentials,
  };
}

export function recipeCopySuccess(data?: object): ActionModel {
  return {
    type: types.RECIPE_COPY.SUCCESS,
    data,
  };
}

export function recipeCopyFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_COPY.FAILURE,
    err,
  };
}

export function recipeItemsRequest(credentials: object): ActionModel {
  return {
    type: types.RECIPE_ITEMS.REQUEST,
    credentials,
  };
}

export function recipeItemsSuccess(data: object): ActionModel {
  return {
    type: types.RECIPE_ITEMS.SUCCESS,
    data,
  };
}

export function recipeItemsFailure(err: object): ActionModel {
  return {
    type: types.RECIPE_ITEMS.FAILURE,
    err,
  };
}

export function clearRecipeItems(credentials: object): ActionModel {
  return {
    type: types.RECIPE_ITEMS.REMOVE,
    credentials,
  };
}
