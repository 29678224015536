import { call, put, takeLatest } from 'redux-saga/effects';

import { FORGOT_PASSWORD } from '../config/api.config';
import { forgotpasswordFailure, forgotpasswordSuccess } from '../redux/actions/forgotpassword.action';
import * as types from '../redux/actions/types';
import forgotpassword from '../services/forgotpassword.service';
import handleResponse from '../services/response.service';

const forgotpasswordCall = (params: any) => forgotpassword.forgotpassword(FORGOT_PASSWORD, params);

function* handleForgotpassword(params: object) {
    try {
        let result;
        result = yield call(forgotpasswordCall, params);
        yield handleForgotpassword200(result);
    } catch (error) {
        throw error;
    }
}

const handleForgotpassword200 = function* handleForgotpassword200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(forgotpasswordSuccess(result.data));
    } else {
        yield put(forgotpasswordFailure(result.data));
    }
    handleResponse(result);
}

export default function* forgotpasswordSaga() {
    yield takeLatest(types.FORGOT_PASSWORD.REQUEST, handleForgotpassword);
}
