import { call, put, takeLatest } from 'redux-saga/effects';
import { RESTAURANT_REQUEST } from '../config/api.config';
import handleResponse from '../services/response.service';
import {
    vatAddFailure,
    vatAddSuccess,
    vatFailure,
    vatRemoveFailure,
    vatRemoveSuccess,
    vatSuccess,
    vatUpdateFailure,
    vatUpdateSuccess,
} from '../redux/actions/vat.action';
import vat from '../services/vat.service';
import * as types from '../redux/actions/types';

const addVatCall = (params: any) => vat.addVat(RESTAURANT_REQUEST, params);
const removeVatCall = (params: any) => vat.removeVat(RESTAURANT_REQUEST, params);
const updateVatCall = (params: any) => vat.updateVat(RESTAURANT_REQUEST, params);
const vatCall = (params: any) => vat.vat(RESTAURANT_REQUEST, params);

function* handleVatRequest(params: object) {
    try {
        let result;
        result = yield call(vatCall, params);
        yield handleVat200(result);
    } catch (error) {
        throw error;
    }
}

const handleVat200 = function* handleVat200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(vatSuccess(result.data));
    } else {
        yield put(vatFailure(result.data));
        handleResponse(result);
    }
}

function* handleVatUpdate(params: object) {
    try {
        let result;
        result = yield call(updateVatCall, params);
        yield handleVatUpdate200(result);
    } catch (error) {
        throw error;
    }
}

const handleVatUpdate200 = function* handleVatUpdate200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(vatUpdateSuccess(result.data));
    } else {
        yield put(vatUpdateFailure(result.data));
    }
    handleResponse(result);
}

function* handleVatAdd(params: object) {
    try {
        let result;
        result = yield call(addVatCall, params);
        yield handleVatAdd200(result);
    } catch (error) {
        throw error;
    }
}

const handleVatAdd200 = function* handleVatAdd200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(vatAddSuccess(result.data));
    } else {
        yield put(vatAddFailure(result.data));
    }
    handleResponse(result);
}

function* handleVatRemove(params: object) {
    try {
        let result;
        result = yield call(removeVatCall, params);
        yield handleVatRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handleVatRemove200 = function* handleVatRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(vatRemoveSuccess(result.data));
    } else {
        yield put(vatRemoveFailure(result.data));
    }
    handleResponse(result);
}
export default function* vatSaga() {
    yield takeLatest(types.VAT.REQUEST, handleVatRequest);
    yield takeLatest(types.VAT_UPDATE.REQUEST, handleVatUpdate);
    yield takeLatest(types.VAT_ADD.REQUEST, handleVatAdd);
    yield takeLatest(types.VAT_REMOVE.REQUEST, handleVatRemove);
}
