import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function profileSettingsRequest(): ActionModel {
    return {
        type: types.PROFILE_SETTINGS.REQUEST,
    };
}

export function profileSettingsSuccess(data?: object): ActionModel {
    return {
        type: types.PROFILE_SETTINGS.SUCCESS,
        data
    };
}

export function profileSettingsFailure(err: object): ActionModel {
    return {
        type: types.PROFILE_SETTINGS.FAILURE,
        err
    };
}

export function profileSettingsUpdate(credentials: object): ActionModel {
    return {
        type: types.PROFILE_SETTINGS_UPDATE.REQUEST,
        credentials
    };
}

export function profileSettingsUpdateSuccess(data?: object): ActionModel {
    return {
        type: types.PROFILE_SETTINGS_UPDATE.SUCCESS,
        data
    };
}

export function profileSettingsUpdateFailure(err: object): ActionModel {
    return {
        type: types.PROFILE_SETTINGS_UPDATE.FAILURE,
        err
    };
}