import apiCall from "./api.call.config";

async function guestGroup(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.credentials.restaurantuuid + "/guest-group"
    );
  } catch (e) {
    throw e;
  }
}

async function updateGuestGroup(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/guest-group/" +
        params.credentials.guestgroupuuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function createGuestGroup(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.restaurantuuid + "/guest-group",
      params.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  guestGroup,
  updateGuestGroup,
  createGuestGroup,
};
