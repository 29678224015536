import { faSearch, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  CustomInput,
  Input,
} from "reactstrap";
import {
  CARD_SELECTION_STATE,
  RECIPE_TYPE,
  TRAFFICLIGHT_RED,
} from "../../../constant/constant";
import {
  RecipeIngredientsCardProps,
  taskEditType,
} from "../../../models/task.model";
import commonService from "../../../services/common.service";
import {
  CardItemCardComponent,
  CardItemContainer,
} from "../../card-components/card-item";
import { withCardItemSelectionState } from "../../cardItemSelectionManager";
import ConditionalLinkArea from "../../conditionalLinkArea/conditionalLinkArea";
import InlineEditInput from "../../inline-edit-input/inline-edit-input";
import WarningComment, {
  getMandatoryWarning,
} from "../../warning-comment/warning-comment";
import _, { find, size } from "lodash";

function RecipeIngredientsCard(props: RecipeIngredientsCardProps) {
  const { t, i18n } = useTranslation();
  const [choiceDefaultQty, setChoiceDefaultQty] = useState<string>("");
  const [choiceMaxQty, setChoiceMaxQty] = useState<string>("");
  const [choiceMinQty, setChoiceMinQty] = useState<string>("");
  const [ingName, setIngName] = useState<string>("");
  const [ingIcon, setIngIcon] = useState<any>("");
  const [outputQuantity, setOutputQuantity] = useState<string>("");
  const [outputUnits, setOutputUnits] = useState<Array<any>>([]);
  const [selectedChoice, setSelectedChoice] = useState<any>(null);
  const [selectedOutputUnit, setSelectedOutputUnit] = useState<any>(null);
  const [selectedRecipe, setSelectedRecipe] = useState<any>(null);
  const [warnings, setWarnings] = useState<any>({});
  const {
    cardItemData,
    cardItemId,
    cardItemSelectionData,
    choiceItems,
    deleteIngredient,
    getChoiceItemDetails,
    getQuantityRelatedDetails,
    getRecipeIcon,
    getRelatedUnitOptions,
    handleIngredientEdit,
    handleOutputUnitSelection,
    handleTaskIngredientSelection,
    isNewCardItem,
    otherRecipesLists,
    openRecipeSelectionModal,
    prepAndRecipeQuantities,
    quantityAssignData,
    saveCard,
    selectedRecipeDetails,
    selectedTaskDetails,
  } = props;

  let errors: any = {};
  let isIngQtyEditFirstTime: any = useRef(false);
  let isTaskBeingEdited: any = useRef(false);

  const isEdit: boolean =
    props.cardItemSelectionData.selectionState.isBeingEdited;

  const isTaskIngredient: boolean = _.size(selectedTaskDetails) > 0;
  const isTaskIngredientEdit: boolean = selectedTaskDetails?.isEdit || false;
  const isIngredientAddedToTheTask: boolean =
    _.size(selectedTaskDetails?.ingredient) > 0 || false;
  const isIngredientAddedToTheParentTask: boolean =
    _.size(selectedTaskDetails?.parentTask?.ingredients) > 0 || false;

  const isAllMetaEdit: boolean =
    cardItemSelectionData.selectionState.selectedItem?.[0]?.id === "allIngEdit";

  useEffect(() => {
    const locales = commonService.applyLocalization(
      "restaurant",
      "name",
      cardItemData?.locales
    );

    setIngName(locales.name);
    if (cardItemData?.type !== "newIngredient") {
      setIngIcon(getRecipeIcon(cardItemData?.type));
    }
    if (!isTaskIngredient) {
      setQuantities(
        cardItemData.quantity,
        cardItemData?.default_choice_count,
        cardItemData?.max_choice_count,
        cardItemData?.min_choice_count
      );
    }
    setOutputUnitsData();
    applyWarning();
  }, [cardItemData, cardItemData.unitUuid]);

  useEffect(() => {
    if (isTaskIngredient) {
      if (isIngQtyEditFirstTime.current) {
        // TODO - Need to cleanup
        // const qty: number = quantityAssignData?.availableQuantity;
        // handleIngredientEdit(
        //   "outputQuantity_" + cardItemId + "_" + cardItemData.recipeUuid,
        //   qty
        // );
        isIngQtyEditFirstTime.current = false;
      } else {
        setQuantities(
          selectedTaskDetails?.ingredient.quantity,
          selectedTaskDetails?.ingredient?.default_choice_count,
          selectedTaskDetails?.ingredient?.max_choice_count,
          selectedTaskDetails?.ingredient?.min_choice_count
        );
      }

      if (
        isTaskIngredientEdit &&
        !isTaskBeingEdited.current &&
        selectedTaskDetails?.ingredient?.unitConversionDetails
          ?.conversionDetails?.value &&
        !selectedTaskDetails?.ingredient?.unitConversion &&
        selectedTaskDetails?.ingredient?.unitConversionDetails
          ?.conversionDetails?.value !== selectedOutputUnit?.value
      ) {
        isTaskBeingEdited.current = true;
        setSelectedOutputUnit(
          selectedTaskDetails.ingredient.unitConversionDetails.conversionDetails
        );
      }
      if (!isTaskIngredientEdit && isTaskBeingEdited.current) {
        isTaskBeingEdited.current = false;
      }
    } else {
      setQuantities(
        cardItemData.quantity,
        cardItemData?.default_choice_count,
        cardItemData?.max_choice_count,
        cardItemData?.min_choice_count
      );
    }
  }, [selectedTaskDetails, isIngQtyEditFirstTime.current]);

  useEffect(() => {
    let noIngQty: boolean = false;
    if (typeof selectedTaskDetails?.ingredient?.quantity === "undefined") {
      noIngQty = true;
    }

    if (
      isTaskIngredientEdit &&
      isIngredientAddedToTheTask &&
      noIngQty &&
      quantityAssignData?.availableQuantity > 0 &&
      !isIngQtyEditFirstTime.current
    ) {
      isIngQtyEditFirstTime.current = true;
      const qty: number = quantityAssignData?.availableQuantity;
      handleIngredientEdit(
        "outputQuantity_" + cardItemId + "_" + cardItemData.recipeUuid,
        qty
      );
      if (quantityAssignData?.unit)
        handleOutputUnitSelection(
          cardItemData.recipeUuid,
          quantityAssignData.unit,
          cardItemData.uuid
        );
    } else if (
      isTaskIngredientEdit &&
      isIngredientAddedToTheTask &&
      noIngQty &&
      !isIngQtyEditFirstTime.current &&
      !selectedTaskDetails?.ingredient?.unitConversion &&
      quantityAssignData?.unit
    ) {
      isIngQtyEditFirstTime.current = true;
      handleOutputUnitSelection(
        cardItemData.recipeUuid,
        quantityAssignData.unit,
        cardItemData.uuid
      );
    } else if (
      isTaskIngredientEdit &&
      isIngredientAddedToTheTask &&
      !selectedTaskDetails?.ingredient?.unitConversion &&
      selectedTaskDetails?.ingredient?.unitConversionDetails?.conversionDetails
    ) {
      handleOutputUnitSelection(
        cardItemData.recipeUuid,
        selectedTaskDetails.ingredient.unitConversionDetails.conversionDetails,
        cardItemData.uuid
      );
    }
  }, [
    isTaskIngredientEdit && isIngredientAddedToTheTask && quantityAssignData,
  ]);

  useEffect(() => {
    if (isNewCardItem) {
      handleRecipeSelection(selectedRecipeDetails);
    }
  }, [selectedRecipeDetails]);

  useEffect(() => {
    if (cardItemData.recipeUuid && otherRecipesLists.length > 0) {
      const selectedRecipe = otherRecipesLists.find(
        (recipe: any) => recipe.uuid === cardItemData.recipeUuid
      );

      if (selectedRecipe) setSelectedRecipe(selectedRecipe);
    }
  }, [cardItemData.recipeUuid, otherRecipesLists, cardItemData.unitUuid]);

  useEffect(() => {
    if (cardItemData?.default_item && !isAllMetaEdit && !isEdit) {
      if (choiceItemDetails?.choiceItemsList && choiceItemDetails?.selected) {
        const selected = choiceItemDetails.choiceItemsList?.find(
          (choice: any) =>
            choice?.recipe_uuid === choiceItemDetails?.selected?.uuid
        );
        if (selected) setSelectedChoice(selected);
      }
    }
  }, [choiceItems]);

  useEffect(() => {
    if (
      cardItemSelectionData.selectionState.selectedItem?.[0]?.id ===
      "allIngEdit"
    ) {
      if (!cardItemData?.unitConversion) {
        handleOutputUnitSelection(
          cardItemData.recipeUuid,
          cardItemData.unitConversionDetails.conversionDetails,
          cardItemData.uuid
        );
      }
      setOutputUnitsData();
      applyWarning();
    }
    if (
      cardItemSelectionData.selectionState.isItemSelected &&
      cardItemData?.type !== "newIngredient"
    ) {
      setIngIcon(getRecipeIcon(cardItemData?.type, "white"));
    } else if (cardItemData?.type !== "newIngredient")
      setIngIcon(getRecipeIcon(cardItemData?.type));
  }, [cardItemSelectionData.selectionState.selectedItem]);

  const applyWarning = (recipe?: any) => {
    const warnings: any = {};
    if (isNewCardItem) {
      const recipeWarning: any = getMandatoryWarning(
        recipe ? recipe : selectedRecipe
      );
      if (recipeWarning) warnings.recipe = recipeWarning;
    }
    const quantityWarning: any = getMandatoryWarning(
      cardItemData?.quantity || ""
    );
    if (quantityWarning) warnings.quantity = quantityWarning;
    cardItemData.warnings = warnings;
    setWarnings(warnings);
  };

  const getStyleEdit = (area: string) => {
    const defaultQty = Number(choiceDefaultQty);
    const minQty = Number(choiceMinQty);
    const maxQty = Number(choiceMaxQty);
    const errorStyle = { borderColor: TRAFFICLIGHT_RED };

    let style: any = {};

    if (
      area === "defaultQuantity" &&
      choiceDefaultQty !== "" &&
      ((choiceMaxQty !== "" && defaultQty > maxQty) ||
        (choiceMinQty !== "" && defaultQty < minQty))
    ) {
      style = errorStyle;
    } else if (
      area === "maxQty" &&
      choiceMaxQty !== "" &&
      (maxQty <= 0 ||
        (choiceMaxQty !== "" && minQty > maxQty) ||
        (choiceDefaultQty !== "" && defaultQty > maxQty))
    ) {
      style = errorStyle;
    } else if (
      area === "minQty" &&
      minQty > 0 &&
      ((choiceMaxQty !== "" && minQty > maxQty) ||
        (choiceDefaultQty !== "" && defaultQty < minQty))
    ) {
      style = errorStyle;
    }
    if (_.size(style) > 0) errors[area] = true;
    else delete errors[area];
    return style;
  };

  const getWarningWithUnit = (warning: any, unit: any) => {
    const warningData: any = _.cloneDeep(warning) || {};
    // const recipeUnitId: string = cardItemData?.recipeUnitUuid
    //   ? cardItemData.recipeUnitUuid
    //   : cardItemData.unitUuid;
    // const recipeOutputUnit: any = outputUnits.find(
    //   (unit: any) => unit.value === recipeUnitId
    // );
    // const unitSymbol: string = recipeOutputUnit?.symbol
    //   ? recipeOutputUnit.symbol
    //   : "";

    if (isTaskIngredientEdit && isIngredientAddedToTheTask) {
      // const conversionFactor: number =
      //   cardItemData?.unitConversion?.conversionFactor || 1;
      // const assignedQuantity: number =
      //   quantityAssignData?.assignedQuantity * conversionFactor;
      // const remainingQuantity: number =
      //   assignedQuantity - quantityAssignData?.totalRecipeQuantity;

      // TODO REMOVE RECIPE_UNIT REPLACE
      if (warning) {
        // if (warningData?.text)
        //   warningData["text"] = warningData.text.replace("RECIPE_UNIT", unit);
      }
      // if (remainingQuantity > 0) {
      //   warningData["type"] = "attention";
      //   warningData["text"] = `${
      //     remainingQuantity *
      //     prepAndRecipeQuantities.prepSetOutputQtyWithConversion
      //   }  ${unitSymbol}  ${t("warning.willBeAssignedRecipe")}`;
      // } else if (warning) {
      //   if (warningData?.text)
      //     warningData["text"] = warningData.text.replace(
      //       "RECIPE_UNIT",
      //       unitSymbol
      //     );
      // }
    } else if (warning) {
      // // TODO REMOVE RECIPE_UNIT REPLACE
      // if (warningData?.text)
      //   warningData["text"] = warningData.text.replace("RECIPE_UNIT", unit);
    }
    if (_.size(warningData) > 0) return [warningData];

    return null;
  };

  const handleCancel = () => {
    isIngQtyEditFirstTime.current = false;
    cardItemSelectionData.deselectCardItem();
    resetValues();
  };

  const handleChoiceRecipeSelection = (selection: any, element: any) => {
    handleIngredientEdit(element.name, selection.value);
    setSelectedChoice(selection);
  };

  const handleRecipeSelection = (recipe: any) => {
    setSelectedRecipe(recipe);
    if (recipe) {
      setOutputUnitsData(recipe);
      applyWarning(recipe);
    }
  };

  const handleSave = () => {
    if (_.size(warnings) > 0 || _.size(errors) > 0) return;

    isIngQtyEditFirstTime.current = false;
    let recipeData: any = {
      quantity: outputQuantity,
      recipeUuid: selectedRecipe.uuid,
      unitConversion: selectedOutputUnit,
      type: selectedRecipe.type,
    };
    if (selectedRecipe?.type === RECIPE_TYPE.CHOICE) {
      recipeData = {
        ...recipeData,
        ...{
          min_choice_count: Number(choiceMinQty) || undefined,
          max_choice_count: Number(choiceMaxQty) || undefined,
          default_choice_count: Number(choiceDefaultQty) || undefined,
        },
      };
      if (selectedChoice && selectedChoice?.value)
        recipeData.default_item = selectedChoice.value;
    }
    saveCard(recipeData);
    resetValues();
  };

  const { Option } = components;
  const IconOption = (props: any) => (
    <Option {...props}>
      {/* <img
        src={require("./" + props.data.icon)}
        style={{ width: 36 }}
        alt={props.data.label}
      /> */}
      <div className="d-flex align-items-center b-cursor-p">
        <span className="mr-2"> {props.data.icon}</span>
        {props.data.label}
      </div>
    </Option>
  );

  const resetValues = () => {
    setChoiceDefaultQty("");
    setChoiceMaxQty("");
    setChoiceMinQty("");
    setSelectedRecipe(null);
    setSelectedOutputUnit(null);
    setOutputUnits([]);
    setOutputQuantity("");
    setIngName("");
    setIngIcon("");
  };

  const setOutputUnitsData = (recipe?: any) => {
    let baseRecipeUnit: string = cardItemData?.unitConversionDetails?.realUnit
      ? cardItemData.unitConversionDetails.realUnit
      : cardItemData?.recipeUnitUuid
      ? cardItemData?.recipeUnitUuid
      : recipe?.output_unit_uuid
      ? recipe.output_unit_uuid
      : selectedRecipe?.output_unit_uuid
      ? selectedRecipe.output_unit_uuid
      : cardItemData.unitUuid;

    let unitUuid: string = selectedTaskDetails?.ingredient?.unitConversion
      ?.value
      ? selectedTaskDetails.ingredient.unitConversion?.value
      : cardItemData?.unitConversion?.value
      ? cardItemData.unitConversion.value
      : cardItemData?.unitConversionDetails?.conversionDetails?.value
      ? cardItemData.unitConversionDetails.conversionDetails.value
      : cardItemData?.unitUuid
      ? cardItemData.unitUuid
      : recipe?.output_unit_uuid
      ? recipe.output_unit_uuid
      : selectedRecipe?.output_unit_uuid;
    let recipeUuid: string = cardItemData.recipeUuid;
    let relatedUnits: any = cardItemData?.relatedUnits;

    if (isNewCardItem && (recipe?.uuid || selectedRecipe?.uuid)) {
      recipeUuid = recipe?.uuid || selectedRecipe?.uuid;
    }
    if (!relatedUnits?.length) {
      relatedUnits = getRelatedUnitOptions(baseRecipeUnit, recipeUuid);
    }

    const outputUnit =
      relatedUnits?.find((unit: any) => unit.value === unitUuid) || {};

    setSelectedOutputUnit(outputUnit);
    setOutputUnits(relatedUnits);
  };

  const setQuantities = (
    quantity: number,
    default_choice_count: number,
    max_choice_count: number,
    min_choice_count: number
  ) => {
    setOutputQuantity(quantity >= 0 ? String(quantity) : "");
    setChoiceDefaultQty(String(default_choice_count || ""));
    setChoiceMaxQty(String(max_choice_count || ""));
    setChoiceMinQty(String(min_choice_count || ""));
  };

  const toggleTaskIngredientSelection = () => {
    const hasIngredients: boolean =
      typeof selectedTaskDetails.ingredient?.recipe_uuid === "string";

    handleTaskIngredientSelection(
      cardItemId,
      hasIngredients,
      cardItemData.recipeUuid
    );
  };

  const quantityData = getQuantityRelatedDetails(
    cardItemData.recipeUuid,
    isTaskIngredient
      ? selectedTaskDetails?.ingredient.quantity
      : cardItemData.quantity,
    cardItemData.unitUuid
  );

  const isChoiceItem: boolean = isNewCardItem
    ? selectedRecipe?.type === RECIPE_TYPE.CHOICE
    : cardItemData.type === RECIPE_TYPE.CHOICE;

  let choiceItemDetails: any;
  if (
    isChoiceItem &&
    isNewCardItem &&
    selectedRecipe &&
    selectedRecipe?.value
  ) {
    if (choiceItems[selectedRecipe.value]) {
      choiceItemDetails = getChoiceItemDetails(
        choiceItems[selectedRecipe.value],
        cardItemData?.default_item,
        false
      );
    } else {
      choiceItemDetails = getChoiceItemDetails(selectedRecipe.value, "", true);
    }
  } else if (isChoiceItem) {
    choiceItemDetails = getChoiceItemDetails(
      choiceItems[cardItemData.recipeUuid],
      cardItemData?.default_item,
      false
    );
  }

  let conversionDetails: any = selectedTaskDetails?.ingredient
    ?.unitConversionDetails?.conversionDetails
    ? selectedTaskDetails.ingredient.unitConversionDetails.conversionDetails
    : cardItemData?.unitConversionDetails?.conversionDetails;
  if (!conversionDetails) {
    conversionDetails = cardItemData?.relatedUnits?.find(
      (u: any) => u.value === cardItemData?.unitUuid
    );
  }

  const isItTaskCard: boolean =
    !selectedTaskDetails?.type ||
    selectedTaskDetails?.type === taskEditType.TASK ||
    selectedTaskDetails?.type === "newTask" ||
    selectedTaskDetails?.type === "newTaskWithIng";

  const unitSymbol: string = quantityAssignData?.unit?.symbol
    ? quantityAssignData.unit.symbol
    : conversionDetails?.symbol || "";

  return (
    <CardItemContainer
      className={"bf-recipe-ing-task-card ingredient-type px-0"}
    >
      <CardItemCardComponent
        cancelCallback={handleCancel}
        cardItemSelectionData={props.cardItemSelectionData}
        className={"ingredient-type ingredient assigned"}
        deleteCallback={() => deleteIngredient(cardItemData.uuid)}
        hideDelete={isTaskIngredient}
        hideEdit={true}
        isNewCardItem={isNewCardItem}
        saveButton={false}
        saveCallback={handleSave}
      >
        <div className="d-flex justify-content-between">
          <div className="flex-fill">
            <>
              <div className="bf-title d-flex justify-content-between">
                {!isEdit ? (
                  <span className="d-flex-inline align-items-center">
                    {ingIcon !== "" && <span className="mr-2">{ingIcon}</span>}
                    <span>{ingName}</span>
                    {cardItemData?.supplierName && (
                      <small className="mx-2">
                        ({cardItemData?.supplierName})
                      </small>
                    )}
                  </span>
                ) : (
                  <div className="w-100 mb-2 mr-2">
                    <WarningComment
                      data={warnings?.recipe || null}
                      displayType={"bottom"}
                      itemWrapperClass={"d-flex  align-items-center"}
                    >
                      <div className="flex-fill">
                        <Select
                          options={otherRecipesLists}
                          autosize={true}
                          menuPlacement="auto"
                          menuPosition="fixed"
                          styles={{
                            control: (provided) => {
                              return {
                                ...provided,
                              };
                            },
                            menu: (provided) => {
                              return {
                                ...provided,
                                color: "#000",
                                fontWeight: 400,
                                fontSize: "16px",
                              };
                            },
                            placeholder: (provided) => {
                              return {
                                ...provided,
                                fontWeight: 400,
                                fontSize: "16px",
                              };
                            },
                          }}
                          onChange={(value: any, id: any) =>
                            handleRecipeSelection(value)
                          }
                          value={selectedRecipe}
                          components={{ Option: IconOption }}
                        />
                      </div>
                      <div
                        className={"card-item-button pointer-cursor"}
                        onClick={() => openRecipeSelectionModal()}
                      >
                        <FontAwesomeIcon icon={faSearch} pull="right" />
                      </div>
                    </WarningComment>
                  </div>
                )}
              </div>

              {((isNewCardItem && !!selectedRecipe) || !isNewCardItem) && (
                <>
                  <div className={"inline-input-group flex-fill bf-quantity"}>
                    <WarningComment
                      data={warnings?.quantity || null}
                      displayType={"bottom"}
                      validationStyle="iconOnly"
                    >
                      <InlineEditInput
                        id={"outputQuantity" + cardItemId}
                        name={
                          "outputQuantity_" +
                          cardItemId +
                          "_" +
                          cardItemData.recipeUuid
                        }
                        value={outputQuantity}
                        editElement={Input}
                        edit={
                          isAllMetaEdit ||
                          isEdit ||
                          (isTaskIngredientEdit && isIngredientAddedToTheTask)
                        }
                        style={{ display: "inline" }}
                        styleEdit={{
                          textAlign: "right",
                          width: "7em",
                          marginRight: 15,
                        }}
                        styleReadOnly={{ textAlign: "left" }}
                        propsEdit={{
                          key: "outputQuantity" + cardItemId,
                          type: "number",
                          placeholder: t("common.quantity"),
                        }}
                        onChange={handleIngredientEdit}
                        // conversionCallback={getNumber}
                      />
                    </WarningComment>
                    {isAllMetaEdit ||
                    isEdit ||
                    (isTaskIngredientEdit && isIngredientAddedToTheTask) ? (
                      <div className="flex-grow-1">
                        <Select
                          options={outputUnits}
                          autosize={true}
                          menuPlacement="auto"
                          menuPosition="fixed"
                          styles={{
                            control: (provided) => {
                              return {
                                ...provided,
                              };
                            },
                            menu: (provided) => {
                              return {
                                ...provided,
                                color: "#000",
                                fontWeight: 400,
                                fontSize: "16px",
                              };
                            },
                            placeholder: (provided) => {
                              return {
                                ...provided,
                                fontWeight: 400,
                                fontSize: "16px",
                              };
                            },
                          }}
                          onChange={(value: any, id: any) =>
                            handleOutputUnitSelection(
                              cardItemData.recipeUuid,
                              value,
                              cardItemData.uuid
                            )
                          }
                          value={selectedOutputUnit}
                        ></Select>
                      </div>
                    ) : (
                      <>
                        {outputQuantity && (
                          <span className="mx-2">
                            {conversionDetails?.symbol
                              ? conversionDetails.symbol
                              : ""}
                          </span>
                        )}

                        {isItTaskCard && (
                          <>
                            <WarningComment
                              data={
                                getWarningWithUnit(
                                  quantityAssignData?.warnings?.taskWarnings
                                    ?.notAssigned,
                                  unitSymbol
                                ) || null
                              }
                            />
                          </>
                        )}

                        {(isIngredientAddedToTheTask ||
                          isIngredientAddedToTheParentTask) && (
                          <WarningComment
                            data={
                              getWarningWithUnit(
                                quantityAssignData?.warnings
                                  ?.stepInterventionWarnings?.notAssigned,
                                unitSymbol
                              ) || null
                            }
                          />
                        )}
                      </>
                    )}
                  </div>

                  {isTaskIngredientEdit && isIngredientAddedToTheTask && (
                    <>
                      <WarningComment
                        data={
                          getWarningWithUnit(
                            quantityAssignData?.warnings?.taskWarnings
                              ?.notAssigned,
                            unitSymbol
                          ) || null
                        }
                      />
                      <WarningComment
                        data={
                          getWarningWithUnit(
                            quantityAssignData?.warnings
                              ?.stepInterventionWarnings?.notAssigned,
                            unitSymbol
                          ) || null
                        }
                      />
                    </>
                  )}
                  <div>
                    <WarningComment
                      data={quantityData?.recommendation || null}
                    />
                  </div>
                </>
              )}
            </>
            {/* )} */}
          </div>
          {!isNewCardItem && isTaskIngredient && (
            <div style={{ float: "right", display: "flex" }}>
              <ConditionalLinkArea
                type="span"
                activeLink={true}
                data-toggle="tooltip"
                data-placement="right"
                onClick={() => {
                  isTaskIngredientEdit && toggleTaskIngredientSelection();
                }}
              >
                <FontAwesomeIcon
                  className={"checkbox"}
                  style={{ fontSize: 40, marginRight: 10, marginTop: 5 }}
                  icon={
                    _.size(selectedTaskDetails.ingredient)
                      ? faCheckSquare
                      : faSquare
                  }
                />
              </ConditionalLinkArea>
            </div>
          )}
        </div>
        {isChoiceItem && (
          <Card className="my-2" color="light">
            <CardBody className="p-2">
              {isAllMetaEdit || isEdit ? (
                <div className="card-text ">
                  <WarningComment
                    data={warnings?.defaultChoiceItem || null}
                    displayType={"bottom"}
                    validationStyle="iconOnly"
                    containerClassName={"mb-3"}
                    itemWrapperClass={"flex-fill align-items-center d-flex"}
                  >
                    <div className="mr-1">{t("common.defaultItem")}:</div>
                    <div className="flex-fill">
                      <Select
                        name={"defaultChoice_" + cardItemId}
                        options={choiceItemDetails?.choiceItemsList || []}
                        autosize={true}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        styles={{
                          control: (provided) => {
                            return {
                              ...provided,
                            };
                          },
                          menu: (provided) => {
                            return {
                              ...provided,
                              color: "#000",
                              fontWeight: 400,
                              fontSize: "16px",
                            };
                          },
                          placeholder: (provided) => {
                            return {
                              ...provided,
                              fontWeight: 400,
                              fontSize: "16px",
                            };
                          },
                        }}
                        onChange={(value: any, id: any) =>
                          handleChoiceRecipeSelection(value, id)
                        }
                        value={selectedChoice}
                      />
                    </div>
                  </WarningComment>
                  <div className="d-flex mb-3  align-items-center">
                    <span className="mr-1">
                      {t("common.defaultQuantity")} :
                    </span>
                    <InlineEditInput
                      name={"defaultQty_" + cardItemId}
                      area={"default_choice_count"}
                      value={choiceDefaultQty}
                      edit={true}
                      editElement={Input}
                      pattern={"^(?:[1-9]\\d*|0)?(?:\\.\\d*)?$"} // positive decimal, including empty and point
                      // onChange={(id: any, value: any) =>
                      //   setChoiceDefaultQty(value)
                      // }

                      onChange={handleIngredientEdit}
                      styleEdit={getStyleEdit("defaultQuantity")}
                    />
                  </div>
                  <div className="mb-2 d-flex  align-items-center">
                    <div className="mr-2 d-flex align-items-center">
                      <span className="mr-1">{t("common.min")}: </span>
                      <InlineEditInput
                        name={"minQty_" + cardItemId}
                        area={"min_choice_count"}
                        edit={true}
                        value={choiceMinQty}
                        editElement={Input}
                        pattern={"^(?:[1-9]\\d*|0)?(?:\\.\\d*)?$"} // positive decimal, including empty and point
                        // onChange={(id: any, value: any) =>
                        //   setChoiceMinQty(value)
                        // }

                        onChange={handleIngredientEdit}
                        styleEdit={getStyleEdit("minQty")}
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="mr-1">{t("common.max")}: </span>

                      <InlineEditInput
                        name={"maxQty_" + cardItemId}
                        area={"max_choice_count"}
                        value={choiceMaxQty}
                        edit={true}
                        editElement={Input}
                        pattern={"^(?:[1-9]\\d*|0)?(?:\\.\\d*)?$"} // positive decimal, including empty and point
                        onChange={handleIngredientEdit}
                        styleEdit={getStyleEdit("maxQty")}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <CardTitle tag="h5">
                    {choiceItemDetails?.selected?.locales ? (
                      commonService.applyLocalization(
                        "restaurant",
                        "name",
                        choiceItemDetails?.selected?.locales
                      )["name"]
                    ) : (
                      <small>{t("recipe.noDefaultItem")}</small>
                    )}
                  </CardTitle>
                  <div className="card-text d-flex justify-content-between">
                    <div>
                      {t("common.defaultQuantity")} :{" "}
                      {cardItemData?.default_choice_count || "-"}{" "}
                    </div>
                    <div>
                      <span className="mr-2">
                        {t("common.min")} :{" "}
                        {cardItemData?.min_choice_count || "-"}{" "}
                      </span>{" "}
                      <span>
                        {" "}
                        {t("common.max")}:{" "}
                        {cardItemData?.max_choice_count || "-"}{" "}
                      </span>{" "}
                    </div>{" "}
                  </div>
                </>
              )}
            </CardBody>
          </Card>
        )}
      </CardItemCardComponent>
    </CardItemContainer>
  );
}

export default withCardItemSelectionState(
  "RecipeIngredientsCard",
  1
)(RecipeIngredientsCard);
