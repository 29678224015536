import '../../styles/page404.scss';

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import smartToniLogin from '../../assets/images/logo/smartToniLogin.png';

class Page404Component extends Component<any> {
    render() {
        return (
            <Container>
                <Row>
                    <Col><img className="img-home-fluid" src={smartToniLogin} alt="Logo" /></Col>
                </Row>
                <Row>
                    <Col className="page-404-text">
                        <label className="text-muted ">The page you are looking for was not found..</label>
                    </Col>
                </Row>
                <Row>
                    <Col className="page-404-text">
                        <h1 className="display-3 mr-4">404</h1>
                    </Col>
                </Row>
            </Container>
        );
    }
}
export default withRouter(Page404Component);
