import React, { Component } from 'react';

import PrinterMessageComponent from '../../components/printer-message/printer-message';

class PrinterMessagePage extends Component {

    render() {
        return (
            <PrinterMessageComponent/>
        );
    }
}
export default PrinterMessagePage;
