import React, { Component } from "react";
import { withCardItemSelectionState } from "../cardItemSelectionManager";
import {
  CardItemCardComponent,
  CardItemContainer,
} from "../card-components/card-item";
import Select from "react-select";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

import WarningComment, {
  getMandatoryWarning,
} from "../warning-comment/warning-comment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PHILIPPINE_GRAY, WHITE } from "../../constant/constant";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import {
  ServiceSetCardLabelInitState,
  ServiceSetLabelCardState,
} from "../../models/serviceSetNew.model";
import { Col, Row } from "reactstrap";

interface Props {
  cardItemData: any;
  cardItemId: string;
  cardItemSelectionData: any;
  labelOptions: Array<any>;
  onDeleteCard: Function;
  onSaveCard: Function;
  savedLabels: Array<any>;
  serviceSetId: string;
}
class serviceSetLabelCard extends Component<any, any> {
  state: ServiceSetLabelCardState;
  constructor(props: Props) {
    super(props);
    this.state = ServiceSetCardLabelInitState;
  }

  componentDidMount() {
    this.loadValues();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.cardItemSelectionData.selectionState.isBeingEdited !==
      this.props?.cardItemSelectionData.selectionState.isBeingEdited
    ) {
      this.loadValues();
    }
  }

  getLabelOptions = () => {
    const labelOptions = this.props.labelOptions.filter((label: any) => {
      return !this.props.savedLabels.includes(label.value);
    });
    return labelOptions;
  };

  getCurrentLabelOption = () => {
    const [labelOption] = this.props.labelOptions.filter(
      (label: any) => label.value === this.props.cardItemData.label_uuid
    );

    return labelOption;
  };

  private loadValues = () => {
    const { cardItemId } = this.props;
    this.setState({
      label: cardItemId === "newCardLabel" ? [] : this.getCurrentLabelOption(),
      warnings: {
        label: getMandatoryWarning(this.props.cardItemData.label_uuid),
      },
    });
  };

  render() {
    const labelOption = this.getCurrentLabelOption();
    const { t } = this.props;
    return (
      <CardItemContainer className={"task-type px-0 mx-n4"}>
        <CardItemCardComponent
          cardItemSelectionData={this.props.cardItemSelectionData}
          className={"bf-card-component"}
          deleteCallback={this.deleteCard}
          onDeleteClick={this.deleteCard}
          readOnly={false}
          saveCallback={this.saveCard}
          saveButton={true}
        >
          {!this.props.cardItemSelectionData.selectionState.isBeingEdited && (
            <div
              className="bf-label-color-box"
              style={{ backgroundColor: labelOption?.color }}
            ></div>
          )}
          <Row className="p-0 pl-1">
            <Col md={4} lg={6} xl={4}>
              <WarningComment
                data={this.state.warnings?.label}
                displayType={"bottom"}
              >
                {this.props.cardItemSelectionData.selectionState
                  .isBeingEdited ? (
                  <Select
                    name="recipe"
                    value={this.state.label}
                    onChange={this.handleSelectChange}
                    options={this.getLabelOptions()}
                    maxMenuHeight={200}
                    placeholder={t("common.select")}
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                ) : (
                  <div>
                    <FontAwesomeIcon
                      className="b-drag-icon mr-1"
                      style={{
                        color:
                          this.props.cardItemSelectionData.className !== ""
                            ? WHITE
                            : PHILIPPINE_GRAY,
                      }}
                      icon={faEllipsisV}
                    />
                    {labelOption?.label}
                  </div>
                )}
              </WarningComment>
            </Col>
          </Row>
        </CardItemCardComponent>
      </CardItemContainer>
    );
  }

  deleteCard = () => {
    this.props.onDeleteCard(this.props.cardItemId, this.props.serviceSetId);
  };

  handleSelectChange = (value: any) => {
    const warnings: any = { ...this.state.warnings };

    this.setState({ label: value });
    warnings.label = getMandatoryWarning(value.value);

    this.setState({ warnings });
  };

  saveCard = () => {
    const cardId = !!this.getCurrentLabelOption()
      ? this.props.cardItemId
      : undefined;
    this.props.onSaveCard(this.state.label, this.props.serviceSetId, cardId);
  };
}

export default withTranslation()(
  withRouter(withCardItemSelectionState("labelCard", 1)(serviceSetLabelCard))
);
