import { LabelsModel } from '../../models/labels.model';
import * as types from '../actions/types';

const initialState: LabelsModel = {
  isFetching: false,
  data: {},
  error: {},
  failure: false,

};
export default function labelsRemoveReducer(state = initialState, action: any): LabelsModel {
  switch (action.type) {
    case types.LABELS_REMOVE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {}
      };
    case types.LABELS_REMOVE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {}
      };
    case types.LABELS_REMOVE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err
      };
    default:
      return state;
  }
}
