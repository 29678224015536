import "../../styles/page404.scss";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Alert, Col, Container, Row, Table } from "reactstrap";

import smartToniLogin from "../../assets/images/logo/smartToniLogin.png";
import { withTranslation } from "react-i18next";
import { LIGHT_GRAY } from "../../constant/constant";
import moment from "moment";
import commonService from "../../services/common.service";
class EndOfDayRestaurantClosure extends Component<any> {
  render() {
    const { t, data } = this.props;
    return (
      <>
        {data?.grossRevenueRestaurant.length == 0 && (
          <Alert color="info">{t("common.noRecords")}</Alert>
        )}
        {data?.grossRevenueRestaurant.length > 0 && (
          <>
            <section className="mt-3 mb-5">
              <h5>1. {t("reports.grossRevenues")}</h5>

              <Table bordered striped>
                <thead>
                  <tr className="bg-light">
                    <th className="w-25">{t("reports.timestamp")}</th>
                    <th className="w-25">{t("reports.productGroup")}</th>
                    <th className="w-25">{t("common.currency")}</th>
                    <th className="w-25">{t("common.amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.grossRevenueRestaurant?.map((innerData: any) => {
                    return (
                      <tr>
                        <td>
                          {moment(innerData.timestamp, "x").format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>{innerData.productGroup}</td>
                        <td>{innerData.currency}</td>
                        <td>
                          {commonService.displayDigitalAmount(innerData.amount)}
                        </td>
                      </tr>
                    );
                  })}
                  {data?.grossRevenueRestaurant.length === 0 && (
                    <tr>
                      <td colSpan={4} className="text-center">
                        {t("common.noRecords")}
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <th>{t("common.total")}</th>
                    <th></th>
                    <th>{data?.restaurantCurrency}</th>
                    <th>
                      {commonService.displayDigitalAmount(
                        data?.totalGrossRevenueRestaurant
                      )}
                    </th>
                  </tr>
                </tfoot>
              </Table>
            </section>
            <section className="mt-3 mb-5">
              <h5>2. {t("reports.VATLiabilities")}</h5>
              <Table bordered striped>
                <thead>
                  <tr className="bg-light">
                    <th className="w-25">{t("reports.timestamp")}</th>
                    <th className="w-25">{t("vat.vat")}</th>
                    <th className="w-25">{t("common.currency")}</th>
                    <th className="w-25">{t("common.amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.VatLiability?.map((innerData: any) => {
                    return (
                      <tr>
                        <td>
                          {moment(innerData.timestamp, "x").format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>{innerData.vatClass}</td>
                        <td>{innerData.currency}</td>
                        <td>
                          {" "}
                          {commonService.displayDigitalAmount(innerData.amount)}
                        </td>
                      </tr>
                    );
                  })}
                  {data?.VatLiability?.length === 0 && (
                    <tr>
                      <td colSpan={4} className="text-center">
                        {t("common.noRecords")}
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <th>{t("common.total")}</th>
                    <th></th>
                    <th>{data?.restaurantCurrency}</th>
                    <th>
                      {commonService.displayDigitalAmount(
                        data?.totalVatLiabilities
                      )}
                    </th>
                  </tr>
                </tfoot>
              </Table>
            </section>
            <section className="mt-3 mb-5">
              <h5>3a. {t("reports.tipGrossRevenues")}</h5>
              <Table bordered striped>
                <thead>
                  <tr className="bg-light">
                    <th className="w-25">{t("reports.timestamp")}</th>
                    <th className="w-25"> </th>
                    <th className="w-25">{t("common.currency")}</th>
                    <th className="w-25">{t("common.amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {/* TODO need to update */}
                  {data?.tipGrossRevenue.map((innerData: any) => {
                    return (
                      <tr>
                        <td>
                          {moment(innerData.timestamp, "x").format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td></td>
                        <td>{innerData.currency}</td>
                        <td>
                          {" "}
                          {commonService.displayDigitalAmount(innerData.amount)}
                        </td>
                      </tr>
                    );
                  })}
                  {data?.tipGrossRevenue.length === 0 && (
                    <tr>
                      <td colSpan={4} className="text-center">
                        {t("common.noRecords")}
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <th>{t("common.total")}</th>
                    <th></th>
                    <th>{data?.restaurantCurrency}</th>
                    <th>
                      {commonService.displayDigitalAmount(
                        data?.tipGrossRevenue?.length > 0
                          ? data?.tipGrossRevenue[0]?.amount
                          : 0
                      )}
                    </th>
                  </tr>
                </tfoot>
              </Table>
            </section>
            <section className="mt-3 mb-5">
              <h5>3b. {t("reports.tipStaffLiabilities")}</h5>
              <Table bordered striped>
                <thead>
                  <tr className="bg-light">
                    <th className="w-25">{t("reports.timestamp")}</th>
                    <th className="w-25">{t("common.staff")} </th>
                    <th className="w-25">{t("common.currency")}</th>
                    <th className="w-25">{t("common.amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.tipStaffLiability.map((innerData: any) => {
                    return (
                      <tr>
                        <td>
                          {moment(innerData.timestamp, "x").format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>{innerData.staff}</td>
                        <td>{innerData.currency}</td>
                        <td>
                          {" "}
                          {commonService.displayDigitalAmount(innerData.amount)}
                        </td>
                      </tr>
                    );
                  })}
                  {data?.tipStaffLiability.length === 0 && (
                    <tr>
                      <td colSpan={4} className="text-center">
                        {t("common.noRecords")}
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <th>{t("common.total")}</th>
                    <th></th>
                    <th>{data?.restaurantCurrency}</th>
                    <th>
                      {commonService.displayDigitalAmount(
                        data?.totalTipStaffLiabilities
                      )}
                    </th>
                  </tr>
                </tfoot>
              </Table>
            </section>
            <section className="mt-3 mb-5">
              <h5>4. {t("reports.cashIncome")}</h5>
              <Table bordered striped>
                <thead>
                  <tr className="bg-light">
                    <th className="w-25">{t("reports.timestamp")}</th>
                    <th className="w-25"> </th>
                    <th className="w-25">{t("common.currency")}</th>
                    <th className="w-25">{t("common.amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {/* TODO need to update */}
                  {data?.cashIncome.map((innerData: any) => {
                    return (
                      <tr>
                        <td>
                          {moment(innerData.timestamp, "x").format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>{innerData.staff}</td>
                        <td>{innerData.currency}</td>
                        <td>
                          {" "}
                          {commonService.displayDigitalAmount(innerData.amount)}
                        </td>
                      </tr>
                    );
                  })}
                  {data?.cashIncome.length === 0 && (
                    <tr>
                      <td colSpan={4} className="text-center">
                        {t("common.noRecords")}
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <th>{t("common.total")}</th>
                    <th></th>
                    <th>{data?.restaurantCurrency}</th>
                    <th>
                      {" "}
                      {data?.cashIncome.length > 0
                        ? commonService.displayDigitalAmount(
                            data?.cashIncome[0]?.amount
                          )
                        : 0}
                    </th>
                  </tr>
                </tfoot>
              </Table>
            </section>
            <section className="mt-3 mb-5">
              <h5>5. {t("reports.receivablesIncome")}</h5>
              <Table bordered striped>
                <thead>
                  <tr className="bg-light">
                    <th className="w-25">{t("reports.timestamp")}</th>
                    <th className="w-25">{t("reports.paymentType")}</th>
                    <th className="w-25">{t("common.currency")}</th>
                    <th className="w-25">{t("common.amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.receivableIncome.map((innerData: any) => {
                    return (
                      <tr>
                        <td>
                          {moment(innerData.timestamp, "x").format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>{innerData.paymentType}</td>
                        <td>{innerData.currency}</td>
                        <td>
                          {" "}
                          {commonService.displayDigitalAmount(innerData.amount)}
                        </td>
                      </tr>
                    );
                  })}
                  {data?.receivableIncome.length === 0 && (
                    <tr>
                      <td colSpan={4} className="text-center">
                        {t("common.noRecords")}
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <th>{t("common.total")}</th>
                    <th></th>
                    <th>{data?.restaurantCurrency}</th>
                    <th>
                      {commonService.displayDigitalAmount(
                        data?.totalReceivablesIncome
                      )}
                    </th>
                  </tr>
                </tfoot>
              </Table>
            </section>
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withRouter(EndOfDayRestaurantClosure));
