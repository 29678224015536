import {
  faEdit,
  faPlusSquare,
  faTrash,
  faListUl,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "reactstrap";
import { BLACK, GREEN } from "../../../constant/constant";

interface props {
  addFormData?: any;
  addNewStorage?: any;
  editFormData?: any;
  editStorage?: any;
  editKeyValue?: any;
  isActive: boolean;
  removeFormData?: any;
  removeStorage?: any;
}

export default function StorageActionButtons(props: props) {
  return props.isActive ? (
    <>
      <Button
        className="ml-3 p-0 border-radius-0 bg-transparent border-0 shadow-none"
        onClick={() =>
          props.editStorage(props.editFormData[0], props.editFormData[1])
        }
      >
        <FontAwesomeIcon icon={faEdit} size={"sm"} color={GREEN} />
      </Button>
      <Button
        className="ml-3 p-0 border-radius-0 bg-transparent border-0 shadow-none"
        onClick={() =>
          props.editKeyValue(props.editFormData[0], props.editFormData[1])
        }
      >
        <FontAwesomeIcon icon={faListUl} size={"sm"} color={GREEN} />
      </Button>
      <Button
        className="mx-3 p-0 border-radius-0 bg-transparent border-0 shadow-none"
        onClick={() =>
          props.removeStorage(props.removeFormData[0], props.removeFormData[1])
        }
      >
        <FontAwesomeIcon icon={faTrash} size={"sm"} color={BLACK} />
      </Button>
    </>
  ) : (
    <Button
      className="mx-3 p-0 border-radius-0 bg-transparent border-0 shadow-none"
      onClick={() =>
        props.addNewStorage(
          props.addFormData[0],
          props.addFormData[1],
          props.addFormData[2],
        )
      }
    >
      <FontAwesomeIcon icon={faPlusSquare} color={GREEN} />
    </Button>
  );
}
