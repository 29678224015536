import apiCall from "./api.call.config";

async function getContent(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.credentials.restaurantuuid + "/content"
    );
  } catch (e) {
    throw e;
  }
}

async function addContent(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/content",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function removeContent(url: string, params: any) {
  try {
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/content/" +
        params.credentials.uuid
    );
  } catch (e) {
    throw e;
  }
}

async function updateContent(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/content/" +
        params.credentials.uuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  getContent,
  addContent,
  removeContent,
  updateContent,
};
