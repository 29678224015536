import apiCall from './api.call.config';

async function printerMessage(url: string, params: any) {
    try {
        return await apiCall.get(url + '/' + params.credentials.restaurantuuid + '/printer-message');
    } catch (e) {
        throw e;
    }
}

async function removeAllPrinterMessage(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.remove(url + '/' + params.credentials.restaurantuuid + '/printer-message/all');
    } catch (e) {
        throw e;
    }
}

async function removePrinterMessage(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.remove(url + '/' + params.credentials.restaurantuuid + '/printer-message/' + params.credentials.uuid);
    } catch (e) {
        throw e;
    }
}

export default {
    printerMessage,
    removeAllPrinterMessage,
    removePrinterMessage
}
