import "../../../../styles/navigation-left.scss";

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import { withTranslation } from "react-i18next";
class OperateLeftComponent extends Component<any> {
  render() {
    const { t } = this.props;
    return (
      <div className="b-sticky-below-header b-side-navbar">
        <Link to={`/restaurant/${this.props.restaurantuuid}/reports`}>
          <Button
            className={
              this.props.display === "reports"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("common.reports")}
          </Button>
        </Link>
        <Link to={`/restaurant/${this.props.restaurantuuid}/shop-access`}>
          <Button
            className={
              this.props.display === "shop-access"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("common.performShopOrder")}
          </Button>
        </Link>
        <Link to={`/restaurant/${this.props.restaurantuuid}/qrcodes`}>
          <Button
            className={
              this.props.display === "qr-codes"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("qrCode.qrCodes")}
          </Button>
        </Link>
        <Link to={`/restaurant/${this.props.restaurantuuid}/inventory`}>
          <Button
            className={
              this.props.display === "inventory"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("inventory.batchArchive")}
          </Button>
        </Link>
      </div>
    );
  }
}

export default withTranslation()(withRouter(OperateLeftComponent));
