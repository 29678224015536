import "../../styles/units.scss";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import HeadingComponent from "./heading";
import TextInputComponent from "./textInput";
import "./translation.css";
import { withTranslation } from "react-i18next";
import { Card, CardBody, CardHeader } from "reactstrap";
class RecipeComponent extends Component<any> {
  requiredFields = ["name"];
  render() {
    const {
      t,
      additionalLang,
      handleChange,
      interventions,
      notValid,
      restaurantLang,
      steps,
      selectedItem,
      selectedLang,
    } = this.props;

    return (
      <>
        <TextInputComponent
          data={selectedItem}
          defaultLang={selectedLang}
          field="name"
          name="name"
          notValid={notValid}
          restaurantLang={restaurantLang}
          text={t("common.title")}
          type="text"
          value={
            selectedItem?.locales && selectedItem?.locales[selectedLang]
              ? selectedItem?.locales[selectedLang].name
              : ""
          }
          handleChange={handleChange}
          requiredFields={this.requiredFields}
        />

        <TextInputComponent
          data={selectedItem}
          defaultLang={selectedLang}
          field="description"
          name="description"
          restaurantLang={restaurantLang}
          text={t("common.description")}
          value={
            selectedItem?.locales && selectedItem?.locales[selectedLang]
              ? selectedItem?.locales[selectedLang].description
              : ""
          }
          handleChange={handleChange}
        />
        <TextInputComponent
          data={selectedItem}
          defaultLang={selectedLang}
          field="printer_name"
          name="printer_name"
          restaurantLang={restaurantLang}
          text={t("printer.printerName")}
          type="text"
          value={
            selectedItem?.locales && selectedItem?.locales[selectedLang]
              ? selectedItem?.locales[selectedLang].printer_name
              : ""
          }
          handleChange={handleChange}
        />

        <TextInputComponent
          data={selectedItem}
          defaultLang={selectedLang}
          field="pos_name"
          name="pos_name"
          restaurantLang={restaurantLang}
          text={t("common.posName")}
          type="text"
          value={
            selectedItem?.locales && selectedItem?.locales[selectedLang]
              ? selectedItem?.locales[selectedLang].pos_name
              : ""
          }
          handleChange={handleChange}
        />
        {this.props.tasks?.length > 0 && (
          <Card className="mt-3">
            <CardHeader>
              {" "}
              <HeadingComponent title={t("task.task")} />
            </CardHeader>
            <CardBody>
              {this.props.tasks?.length > 0 &&
                this.props.tasks.map((task: any, index: any) => (
                  <React.Fragment key={index}>
                    <TextInputComponent
                      data={task}
                      defaultLang={selectedLang}
                      field="name"
                      name="task_name"
                      restaurantLang={restaurantLang}
                      text={t("common.title")}
                      type="text"
                      value={
                        task?.locales && task?.locales[selectedLang]
                          ? task?.locales[selectedLang].name
                          : ""
                      }
                      handleChange={(e: any) => {
                        handleChange(e, task.uuid);
                      }}
                    />

                    <TextInputComponent
                      data={task}
                      defaultLang={selectedLang}
                      field="description"
                      name="task_description"
                      restaurantLang={restaurantLang}
                      text={t("common.description")}
                      type="textarea"
                      value={
                        task?.locales && task?.locales[selectedLang]
                          ? task?.locales[selectedLang].description
                          : ""
                      }
                      handleChange={(e: any) => {
                        handleChange(e, task.uuid);
                      }}
                    />
                    {this.props.tasks?.length - 1 !== index && (
                      <hr className="w-100" />
                    )}
                  </React.Fragment>
                ))}
            </CardBody>
          </Card>
        )}
        {steps.length > 0 && (
          <Card className="mt-3">
            <CardHeader>
              <HeadingComponent title="Steps" />
            </CardHeader>
            <CardBody>
              {steps.length > 0 &&
                steps.map((step: any, index: any) => (
                  <React.Fragment key={index}>
                    <TextInputComponent
                      data={step}
                      defaultLang={selectedLang}
                      field="description"
                      name="step_description"
                      restaurantLang={restaurantLang}
                      text={t("common.title")}
                      type="text"
                      value={
                        step?.locales && step?.locales[selectedLang]
                          ? step?.locales[selectedLang].description
                          : ""
                      }
                      handleChange={(e: any) => {
                        handleChange(e, step.uuid);
                      }}
                    />
                    {steps?.length - 1 !== index && <hr className="w-100" />}
                  </React.Fragment>
                ))}
            </CardBody>
          </Card>
        )}
        {interventions.length > 0 && (
          <Card className="mt-3">
            <CardHeader>
              <HeadingComponent title="Interventions" />
            </CardHeader>
            <CardBody>
              {interventions.length > 0 &&
                interventions.map((intervention: any, index: any) => (
                  <React.Fragment key={index}>
                    <TextInputComponent
                      data={intervention}
                      defaultLang={selectedLang}
                      field="description"
                      name="intervention_description"
                      restaurantLang={restaurantLang}
                      text={t("common.title")}
                      type="text"
                      value={
                        intervention?.locales &&
                        intervention?.locales[selectedLang]
                          ? intervention?.locales[selectedLang].description
                          : ""
                      }
                      handleChange={(e: any) => {
                        handleChange(e, intervention.uuid);
                      }}
                    />
                    {interventions?.length - 1 !== index && (
                      <hr className="w-100" />
                    )}
                  </React.Fragment>
                ))}
            </CardBody>
          </Card>
        )}
      </>
    );
  }
}

export default withTranslation()(withRouter(RecipeComponent));
