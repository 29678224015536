export interface UnitsModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface UnitsState {
  addmodal: boolean;
  activeTab: string;
  allRecipe: any;
  baseKitchenUnit: any;
  baseUnit: any;
  conversionOptions: any;
  conversionId: string;
  deleteConversion: string;
  editmodal: boolean;
  errorMsg: string;
  isBaseUnitChanged: boolean;
  isFetching: boolean;
  isGrossUnitDeleted: boolean;
  isUnitModalOpen: boolean;
  isUpdated: boolean;
  name: any;
  open: boolean;
  otherUnit: any;
  parameter: any;
  restaurantuuid: string;
  selectedRecipe: any;
  showEditModal: boolean;
  showModal: boolean;
  symbol: string;
  toggleDelete: Boolean;
  unitModalPayload: unitModalPayload;
  units: any;
  uuid: string;
}

export const UnitsInitState = {
  activeTab: "units",
  addmodal: false,
  allRecipe: [],
  baseKitchenUnit: null,
  baseUnit: null,
  conversionOptions: [],
  conversionId: "",
  deleteConversion: "",
  editmodal: false,
  errorMsg: "",
  isBaseUnitChanged: false,
  isFetching: false,
  isGrossUnitDeleted: false,
  isUnitModalOpen: false,
  isUpdated: false,
  name: "",
  open: false,
  otherUnit: {},
  parameter: "",
  restaurantuuid: "",
  selectedRecipe: {},
  showEditModal: false,
  showModal: false,
  symbol: "",
  toggleDelete: false,
  unitModalPayload: {
    name: "",
    symbol: "",
  },
  units: [],
  uuid: "",
};

export interface UnitsCardState {
  allAnchorUnits: Array<any>;
  quantity: string;
  conversionFactor: string;
  fromUnit: {
    label: string;
    value: string;
    conversionFactor: number;
  } | null;
  grossWeight: string;
  grossWeightUnit: {
    label: string;
    value: string;
    conversionFactor: number;
  } | null;
  toUnit: {
    label: string;
    value: string;
    conversionFactor: number;
  } | null;
  warnings: any;
  sku: string;
  barcode: string;
  fromUnitName?: string;
  fromUnitSymbol?: string;
}

export const UnitsCardInitState: UnitsCardState = {
  allAnchorUnits: [],
  quantity: "",
  conversionFactor: "",
  fromUnit: null,
  grossWeight: "",
  grossWeightUnit: null,
  toUnit: null,
  warnings: {},
  sku: "",
  barcode: "",
};

export interface UnitsCardProps {
  allAnchorUnits?: Array<any>;
  relatedUnits?: Array<any>;
  allUnits: Array<any>;
  area?: string;
  baseWeightUnit?: string;
  cardItemData: any;
  cardItemId: string;
  cardItemSelectionData: any;
  conversionsList: Array<any>;
  getBaseUnitOptions?: any;
  getConversionFactor: Function;
  getUnitName: Function;
  grossWeight: string;
  grossWeightUnit: string;
  handleUnitUnitEdit: Function;
  isNewCardItem: string;
  isParentGlobalUnit: boolean;
  onDeleteCard: Function;
  onConversionCancel: Function;
  onNewUnitSelection: Function;
  onSaveCard: Function;
  parentCardItemSelectionData: any;
  saveButton: boolean;
  t: any;
  tempUnit: any;
  type?: string;
  unit: any;
  restaurantCode: string;
  isAddOnly?: boolean;
  recipeOutputUnit?: string;
  recipeOutputQuantity?: any;
}
export interface UnitCardProps {
  allAnchorUnits: Array<any>;
  allUnits: Array<any>;
  area?: string;
  baseWeightUnit?: string;
  cardItemSelectionData: any;
  getBaseUnitOptions?: any;
  handleUnitUnitEdit: Function;
  isItActiveUnit?: boolean;
  isRecipeEditable?: boolean;
  isSupplierOffering?: boolean;
  onConversionCancel: Function;
  onNewUnitSelection: Function;
  saveConversion: Function;
  setConversion: Function;
  setConversionDelete: Function;
  setDelete: Function;
  setEditable: Function;
  setEditableConversion: Function;
  showModal: boolean;
  tempUnit: any;
  type?: string;
  unit: any;
  unitName: Function;
  restaurantCode: string;
}

export interface unitModalPayload {
  grossWeight?: string;
  grossWeightUnitSelection?: any;
  hasGrossWeight?: boolean;
  hasNewUnitAdded?: boolean;
  name: string;
  symbol: string;
  type?: string;
}

export interface unitModalProps {
  payload: unitModalPayload;
  handleChange: any;
  isModalOpen: boolean;
  unitModalActions: any;
}

export interface unitConversionAddModalProps {
  isModalOpen: boolean;
  setIsModalOpen: Function;
  area: string;
  allUnits: Array<any>;
  recipeOutputUnit: string;
  recipeOutputQuantity: any;
  getBaseUnitOptions: Array<any>;
  settings: any;
  relatedUnits: Array<any>;
  cardItemSelectionData: any;
  recipeUuid: string;
  restaurantuuid: string;
  restaurantLangCode: string;
  unitConversionSaveCallback: Function;
}
