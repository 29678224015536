import apiCall from './api.call.config';

async function getEventList(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url + '/' + params.credentials.restaurantuuid + '/calendar', params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function addEventList(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url + '/' + params.credentials.restaurantuuid + '/order', params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function getEvent(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.get(url + '/' + params.credentials.restaurantuuid + '/order/'
            + params.credentials.orderuuid + '/details');
    } catch (e) {
        throw e;
    }
}

async function updateEvent(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.put(url + '/' + params.credentials.restaurantuuid + '/order/' + params.credentials.orderuuid, params.credentials.data);
    } catch (e) {
        throw e;
    }
}

export default {
    getEventList,
    addEventList,
    getEvent,
    updateEvent
}
