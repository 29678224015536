import {
  DateItemModel,
  DateItemModelInitState,
} from "../../models/date.item.model";
import * as types from "../actions/types";

export default function dateItemListReducer(
  state = DateItemModelInitState,
  action: any
): DateItemModel {
  switch (action.type) {
    case types.DATE_ITEM.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.DATE_ITEM.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.DATE_ITEM.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        data: action.err,
        error: action.err,
      };
    default:
      return state;
  }
}
