import React, { Component } from "react";

import TagsComponent from "../../components/tags/tags";

class TagsPage extends Component {
  componentDidMount() {
    document.body.className = "light-theme";
  }

  render() {
    return <TagsComponent />;
  }
}
export default TagsPage;
