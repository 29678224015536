import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function userverificationRequest(credentials: any): ActionModel {
  return {
    type: types.USER_VERIFICATION.REQUEST,
    credentials
  };
}

export function userverificationSuccess(data: any): ActionModel {
  return {
    type: types.USER_VERIFICATION.SUCCESS,
    data
  };
}

export function userverificationFailure(err: any): ActionModel {
  return {
    type: types.USER_VERIFICATION.FAILURE,
    err
  };
}
