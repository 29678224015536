import commonService from "../services/common.service";
import { RESTAURANT_REQUEST } from "../config/api.config";
import { getRestaurantSuppliers } from "../services/supplier.restaurants.service";
import handleResponse from "../services/response.service";

export const SupplierHelper = {
  getSuppliers: (restaurantuuid: string, listCallback: Function): void => {
    const payload = {
      credentials: {
        restaurantuuid: restaurantuuid,
      },
    };
    getRestaurantSuppliers(RESTAURANT_REQUEST, payload).then((data: any) => {
      const responseData: any = handleResponse(data);
      if (responseData?.ok) {
        listCallback(responseData.data);
      }
    });
  },

  getSupplierDetails: (supplierId: string, supplierList: any): string => {
    const supplierDetails = supplierList.find(
      (supplier: any) => supplier.uuid === supplierId
    );
    return commonService.applyLocalization(
      "restaurant",
      "name",
      supplierDetails?.locales
    )["name"];
  },
};
