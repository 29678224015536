import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function channelsRequest(): ActionModel {
  return {
    type: types.CHANNELS.REQUEST,
  };
}

export function channelsSuccess(data: object): ActionModel {
  return {
    type: types.CHANNELS.SUCCESS,
    data,
  };
}

export function channelsFailure(err: object): ActionModel {
  return {
    type: types.CHANNELS.FAILURE,
    err,
  };
}

export function channelsDetails(credentials: object): ActionModel {
  return {
    type: types.CHANNELS_DETAILS.REQUEST,
    credentials,
  };
}

export function channelsDetailsSuccess(data?: object): ActionModel {
  return {
    type: types.CHANNELS_DETAILS.SUCCESS,
    data,
  };
}

export function channelsDetailsFailure(err: object): ActionModel {
  return {
    type: types.CHANNELS_DETAILS.FAILURE,
    err,
  };
}

export function channelsAddRestaurants(credentials: object): ActionModel {
  return {
    type: types.CHANNELS_ADD_RESTAURANTS.REQUEST,
    credentials,
  };
}

export function channelsAddRestaurantsSuccess(data?: object): ActionModel {
  return {
    type: types.CHANNELS_ADD_RESTAURANTS.SUCCESS,
    data,
  };
}

export function channelsAddRestaurantsFailure(err: object): ActionModel {
  return {
    type: types.CHANNELS_ADD_RESTAURANTS.FAILURE,
    err,
  };
}

export function restaurantChannelList(credentials: object): ActionModel {
  return {
    type: types.RESTAURANTS_CHANNELS_LIST.REQUEST,
    credentials,
  };
}

export function restaurantChannelListSuccess(data?: object): ActionModel {
  return {
    type: types.RESTAURANTS_CHANNELS_LIST.SUCCESS,
    data,
  };
}

export function restaurantChannelListFailure(err: object): ActionModel {
  return {
    type: types.RESTAURANTS_CHANNELS_LIST.FAILURE,
    err,
  };
}
