import apiCall from './api.call.config';

async function uploadFile(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url, params.credentials);
    } catch (e) {
        throw e;
    }
}

export default { uploadFile }
