import apiCall from './api.call.config';

async function roleList(url: string, params: any) {
    try {
        return await apiCall.get(url + '/' + params.credentials.restaurantuuid + '/role');
    } catch (e) {
        throw e;
    }
}

async function updateRole(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.put(url + '/' + params.credentials.restaurantuuid + '/role/'
            + params.credentials.uuid, params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function addRole(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url + '/' + params.credentials.restaurantuuid + '/role', params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function removeRole(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.remove(url + '/' + params.credentials.restaurantuuid + '/role/' + params.credentials.uuid);
    } catch (e) {
        throw e;
    }
}

export default {
    roleList,
    updateRole,
    addRole,
    removeRole
}