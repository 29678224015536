import "../../styles/page404.scss";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Alert, Table } from "reactstrap";
import { withTranslation } from "react-i18next";
import commonService from "../../services/common.service";
import { ORDER_STATUS } from "../../constant/constant";
class supplierOrders extends Component<any> {
  activeRestaurant = JSON.parse(
    localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
  );

  totalCost = () => {
    const total = this.props.data?.reduce(
      (tCost: number, r: any) => tCost + r.totalPrice,
      0
    );
    return commonService.displayDigitalAmount(total);
  };

  render() {
    const { t, data } = this.props;
    return (
      <>
        {data?.length === 0 && (
          <Alert color="info">{t("common.noRecords")}</Alert>
        )}

        {data?.length > 0 && (
          <div className="d-flex justify-content-between">
            <h5>{t("reports.supplierOrdersForTimeframe")}</h5>
          </div>
        )}

        {data?.length > 0 && (
          <>
            <section className="mt-3">
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <b>{`${t(
                  "reports.totalCostForTheDefinedTimeframe"
                )}: ${this.totalCost()} ${this.activeRestaurant
                  ?.currency_code}`}</b>
              </div>
            </section>
            {data?.map((record: any) => {
              return (
                <section className="mt-4">
                  <h6 className=" mb-2">
                    <span style={{ float: "left" }}>{`${
                      record.supplierName
                    } - ${record.uuid.slice(-8)}`}</span>
                    <span
                      style={{ float: "right" }}
                    >{`${commonService.displayDigitalAmount(
                      record.totalPrice
                    )} ${this.activeRestaurant?.currency_code}`}</span>
                  </h6>
                  <Table bordered>
                    <thead>
                      <tr className="bg-light">
                        <th style={{ width: 210 }}>
                          {t("reports.expectedDeliveryTime")}
                        </th>
                        <th style={{ width: 100 }}>{t("common.status")}</th>
                        <th>{t("article.articles")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{record.expectedDeliveryTime}</td>
                        <td>
                          {t(
                            ORDER_STATUS.find((s) => s.value === record.status)
                              ?.label
                          )}
                        </td>
                        <td className="p-0">
                          <Table striped className="mb-0">
                            <tbody>
                              {record?.recipes?.map((recipe: any) => {
                                return (
                                  <tr
                                    style={
                                      recipe.rejected ? { color: "red" } : {}
                                    }
                                  >
                                    <td
                                      style={{ width: 50, textAlign: "right" }}
                                    >
                                      {recipe.orderQuantity}
                                    </td>
                                    <td>{`${recipe.recipeName} (${recipe.outputQuantity} ${recipe.orderUnit})`}</td>
                                    <td
                                      style={{ width: 140, textAlign: "right" }}
                                    >{`${
                                      recipe.price !== 0
                                        ? commonService.displayDigitalAmount(
                                            recipe.price
                                          ) +
                                          " " +
                                          this.activeRestaurant?.currency_code
                                        : ""
                                    }`}</td>
                                    <td
                                      style={{ width: 140, textAlign: "right" }}
                                    >{`${
                                      recipe.totalPrice !== 0
                                        ? commonService.displayDigitalAmount(
                                            recipe.totalPrice
                                          ) +
                                          " " +
                                          this.activeRestaurant?.currency_code
                                        : ""
                                    }`}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </section>
              );
            })}
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withRouter(supplierOrders));
