


import apiCall from './api.call.config';

async function support(url: string, params: any) {
    try {
        return await apiCall.get(url + '/' + params.credentials.restaurantuuid + '/cobrowse/token')

    } catch (e) {
        throw e;
    }
}



export default {
    support,
   
}
