import "../../../styles/restaurant.scss";
import { Country, City } from "country-state-city";

import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Select from "react-select";

export default function RestaurantAddCard(props: any) {
  const { t, i18n } = useTranslation();
  const [countryList, setCountries] = useState<any>([]);
  const [languageList, setLanguages] = useState<any>([]);

  useEffect(() => {
    getCountryOptions();
  }, []);

  useEffect(() => {
    getLanguageOptions();
  }, [props.languages]);

  const getCountryOptions = () => {
    let countries = Country.getAllCountries().map((country: any) => {
      return {
        value: country.name,
        label: country.name,
      };
    });
    setCountries(countries);
  };
  const getLanguageOptions = () => {
    let languages = props.languages?.map((language: any) => {
      return {
        value: language.uuid,
        label: language.name,
      };
    });
    setLanguages(languages);
  };
  return (
    <Modal isOpen={props.state.addmodal}>
      <ModalHeader toggle={props.addToggle} className="modal-header">
        {t("restaurant.addRestaurant")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="name">
              {t("common.name")}
              <span className="text-danger ml-1">*</span>
            </Label>
            <Input
              type="text"
              required
              name="name"
              autoComplete="name"
              placeholder={t("restaurant.enterRestaurantName")}
              value={props.state.name}
              onChange={props.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="address">{t("common.address")}</Label>
            <Input
              type="textarea"
              style={{ minHeight: 200 }}
              required
              name="address"
              autoComplete="editaddress"
              placeholder={t("restaurant.enterRestaurantAddress")}
              value={props.state.address}
              onChange={props.handleChange}
            />
          </FormGroup>

          <FormGroup>
            <Label for="country">{t("common.country")}</Label>
            <Select
              name="country"
              value={props.state.country}
              onChange={props.handleCountryChange}
              maxMenuHeight={200}
              options={countryList}
              placeholder={t("restaurant.selectCountry")}
            />
          </FormGroup>
          <FormGroup>
            <Label for="city">{t("common.city")}</Label>
            <Input
              type="text"
              required
              name="city"
              autoComplete="editcity"
              placeholder={t("restaurant.enterRestaurantCity")}
              value={props.state.city}
              onChange={props.handleChange}
            />
          </FormGroup>

          <FormGroup>
            <Label for="zip">{t("common.zip")}</Label>
            <Input
              type="text"
              required
              name="zip"
              autoComplete="editzip"
              maxLength={20}
              placeholder={t("restaurant.enterRestaurantZip")}
              value={props.state.zip}
              onChange={props.handleChange}
            />
          </FormGroup>

          <FormGroup>
            <Label for="phone">{t("common.phone")}</Label>
            <Input
              type="text"
              required
              name="phone"
              autoComplete="editphone"
              maxLength={15}
              placeholder={t("restaurant.enterRestaurantPhone")}
              pattern="^[A-Z]*$"
              value={props.state.phone}
              onChange={props.handleChange}
            />
            <span className="bf-font-size-11 pl-1 text-danger">
              {props.state.errors?.phone}
            </span>
          </FormGroup>

          <FormGroup>
            <Label for="vatCode">{t("common.vatCode")}</Label>
            <Input
              type="text"
              required
              name="vatCode"
              autoComplete="editvatcode"
              placeholder={t("restaurant.enterRestaurantVatCode")}
              value={props.state.vatCode}
              onChange={props.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="description">{t("common.description")}</Label>
            <Input
              type="textarea"
              style={{ minHeight: 200 }}
              required
              name="description"
              autoComplete="description"
              placeholder={t("restaurant.enterRestaurantDescription")}
              value={props.state.description}
              onChange={props.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">
              {t("common.email")}
              <span className="text-danger ml-1">*</span>
            </Label>

            <Input
              type="text"
              required
              name="email"
              autoComplete="email"
              placeholder={t("restaurant.enterRestaurantEmail")}
              value={props.state.email}
              onChange={props.handleChange}
            />
            <span className="bf-font-size-11 pl-1 text-danger">
              {props.state.errors?.email}
            </span>
          </FormGroup>
          <FormGroup>
            <Label for="language">
              {t("common.language")}
              <span className="text-danger ml-1">*</span>
            </Label>
            <Select
              name="language"
              value={props.state.language}
              onChange={props.handleLanguageChange}
              maxMenuHeight={200}
              options={languageList}
              placeholder={t("common.selectLanguage")}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.addToggle}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={props.saveAdd}
          disabled={
            !props.state.name ||
            !props.state.email ||
            !!props.state.isFetching ||
            !!!props.state?.language?.value
          }
        >
          {t("common.save")}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
