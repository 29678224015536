import React from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import "../../../styles/units.scss";
import Select from "react-select";
import { useTranslation } from "react-i18next";

export default function AddLanguageCard(props: any) {
  const { t, i18n } = useTranslation();
  const { additionalLang, handleAddLanguage } = props;

  const langList = () => {
    if (props.languages) {
      let languages = props.languages
        .filter(
          (lang: any) =>
            props.restaurantLang?.findIndex(
              (item: any) => item.uuid === lang.uuid
            ) === -1
        )
        .map((element: any, index: any) => {
          return {
            ...element,
            value: element.code,
            label: element.name,
          };
        });
      return languages;
    }
  };
  return (
    <Modal isOpen={props.showModal}>
      <ModalHeader toggle={props.toggleModal} className="modal-header">
        {t("common.addLanguage")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row className="p-0">
            <Label for="language" sm={4} className="text-right">
              {t("common.language")}{" "}
              <span className="required-star-style">*</span>
            </Label>
            <Col sm={8}>
              <Select
                maxMenuHeight={200}
                name="language"
                options={langList()}
                onChange={(val: any) => {
                  handleAddLanguage(val, "select");
                }}
                isMulti
              />
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggleModal}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          disabled={additionalLang.length === 0}
          onClick={() => {
            handleAddLanguage("save");
          }}
        >
          {t("common.add")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
