import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import { inventoryFailure, inventorySuccess } from '../redux/actions/inventory.action';
import * as types from '../redux/actions/types';
import inventory from '../services/inventory.service';
import handleResponse from '../services/response.service';

const inventoryCall = (params: any) => inventory.inventory(RESTAURANT_REQUEST, params);

function* handleInventoryRequest(params: object) {
    try {
        let result;
        result = yield call(inventoryCall, params);
        yield handleInventory200(result);
    } catch (error) {
        throw error;
    }
}

const handleInventory200 = function* handleInventory200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(inventorySuccess(result.data));
    } else {
        yield put(inventoryFailure(result.data));
        handleResponse(result);
    }
}

export default function* inventorySaga() {
    yield takeLatest(types.INVENTORY.REQUEST, handleInventoryRequest);
}
