import React, { Component } from 'react';

import PrinterComponent from '../../components/printer/printer';


class PrinterPage extends Component {

    render() {
        return (
            <PrinterComponent/>
        );
    }
}
export default PrinterPage;