export interface ResourceCostPageState {
  currentSettingsData: any;
  hourlyCooksRate: any;
  isFetching: boolean;
}

export const ResourceCostPageInitState: ResourceCostPageState = {
  currentSettingsData: {},
  hourlyCooksRate: "",
  isFetching: false,
};
