import apiCall from './api.call.config';

async function addStoragePlace(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url + '/' + params.credentials.restaurantuuid + '/storage/' + params.credentials.storageuuid
            + '/rack/' + params.credentials.rackuuid + '/place', params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function updateStoragePlace(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.put(url + '/' + params.credentials.restaurantuuid + '/storage/' + params.credentials.storageuuid
            + '/rack/' + params.credentials.rackuuid + '/place/' + params.credentials.uuid, params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function removeStoragePlace(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.remove(url + '/' + params.credentials.restaurantuuid + '/storage/' + params.credentials.storageuuid
            + '/rack/' + params.credentials.rackuuid + '/place/' + params.credentials.uuid);
    } catch (e) {
        throw e;
    }
}

export default {
    addStoragePlace,
    updateStoragePlace,
    removeStoragePlace
}