import { TagsModel } from "../../models/tags.model";
import * as types from "../actions/types";

const initialState: TagsModel = {
  isFetching: false,
  data: [],
  error: {},
  failure: false,
};
export function tagsReducer(state = initialState, action: any): TagsModel {
  switch (action.type) {
    case types.TAGS.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.TAGS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.TAGS.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function tagAddReducer(state = initialState, action: any): TagsModel {
  switch (action.type) {
    case types.TAG_ADD.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.TAG_ADD.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.TAG_ADD.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
export function tagRemoveReducer(state = initialState, action: any): TagsModel {
  switch (action.type) {
    case types.TAG_REMOVE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.TAG_REMOVE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        failure: false,
        error: {},
      };
    case types.TAG_REMOVE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
export function tagUpdateReducer(state = initialState, action: any): TagsModel {
  switch (action.type) {
    case types.TAG_UPDATE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.TAG_UPDATE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        failure: false,
        error: {},
      };
    case types.TAG_UPDATE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function tagAssignReducer(state = initialState, action: any): TagsModel {
  switch (action.type) {
    case types.TAG_ASSIGN.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.TAG_ASSIGN.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.TAG_ASSIGN.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
