import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function loginRequest(credentials: object): ActionModel {
  return {
    type: types.LOGIN.REQUEST,
    credentials
  };
}

export function loginSuccess(data?: object): ActionModel {
  return {
    type: types.LOGIN.SUCCESS,
    data
  };
}

export function loginFailure(err: object): ActionModel {
  return {
    type: types.LOGIN.FAILURE,
    err
  };
}
