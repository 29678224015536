import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { GRAY, GREEN } from "../../../constant/constant";
import { MachineTypeCardProps } from "../../../models/machine.model";
import commonService from "../../../services/common.service";
import { withCardItemSelectionState } from "../../cardItemSelectionManager";
import { CardItemCardComponent, CardItemContainer } from "../card-item";

function MachineTypeCard(props: MachineTypeCardProps) {
  const [type, setType] = useState<string>("");
  const { t, i18n } = useTranslation();
  const { cardItemData, cardItemId, cardItemSelectionData } = props;

  useEffect(() => {
    if (cardItemSelectionData.selectionState.isBeingEdited) {
      const name: string =
        commonService.applyLocalization(
          "restaurant",
          "name",
          cardItemData.locales
        )?.name || "";
      setType(name);
    }
  }, [
    cardItemData.locales,
    cardItemSelectionData.selectionState.isBeingEdited,
  ]);

  const deleteCard = () => {
    props.onDeleteCard(cardItemId);
  };

  const handleChange = (element: any) => {
    setType(element.target.value);
  };

  const loadValues = () => {};

  const saveCard = () => {
    props.onSaveCard({ id: cardItemId, name: type });
    setType("");
  };

  const selectCard = () => {
    if (cardItemSelectionData.selectionState.mode === "edit") {
      loadValues();
    }
    cardItemSelectionData.selectCardItem();
  };

  const machineLocale = commonService.applyLocalization(
    "restaurant",
    "name",
    cardItemData.locales
  );

  return (
    <CardItemContainer className={"task-type px-0"}>
      <CardItemCardComponent
        cardItemSelectionData={cardItemSelectionData}
        className={"task-type assigned"}
        deleteCallback={deleteCard}
        onDeleteClick={deleteCard}
        readOnly={false}
        saveCallback={saveCard}
        selectCallback={selectCard}
        saveButton={false}
      >
        <Row>
          {!cardItemSelectionData.selectionState.isItemSelected && (
            <Col>{machineLocale.name}</Col>
          )}
          {cardItemSelectionData.selectionState.isItemSelected && (
            <Form inline>
              <FormGroup>
                {cardItemSelectionData.selectionState.isBeingEdited ? (
                  <>
                    <Label className="mr-2">{t("machine.machineType")}</Label>
                    <Input onChange={handleChange} value={type} />
                  </>
                ) : (
                  <>
                    <Label className="mr-2">{machineLocale.name}</Label>
                  </>
                )}
              </FormGroup>
            </Form>
          )}
        </Row>
      </CardItemCardComponent>
    </CardItemContainer>
  );
}
export default withCardItemSelectionState(
  "MachineTypeCard",
  1
)(MachineTypeCard);
