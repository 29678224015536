import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import {
  ACCESS_TOKEN,
  REDIRECT_URL,
  RESTAURANTUUIDS,
  SUPPLIERUUID,
} from "../../constant/constant";

class AuthorizedToken extends Component<any> {
  public getRedirectUrl(props: any) {
    if (
      props.computedMatch.params?.uuid &&
      localStorage.getItem(RESTAURANTUUIDS)
    ) {
      localStorage.setItem(SUPPLIERUUID, props.computedMatch.params?.uuid);
      const restaurantUuid = JSON.parse(
        localStorage.getItem(RESTAURANTUUIDS) || "{}"
      )[0];
      return "/restaurant/" + restaurantUuid + "/home";
    } else if (localStorage.getItem(REDIRECT_URL)) {
      const urlData: any = JSON.parse(
        localStorage.getItem(REDIRECT_URL) || "{}"
      );
      if (urlData?.restaurantUuid) {
        return "/restaurant/" + urlData.restaurantUuid + "/home";
      }
    }
    return "/profile";
  }

  render() {
    const { component: Component, pending, logged, ...rest } = this.props;
    const redirectUrl = this.getRedirectUrl(this.props);
    return (
      <Route
        {...rest}
        render={(props) => {
          if (pending) return <Component {...this.props} />;
          return logged ? (
            <Redirect to={redirectUrl} />
          ) : (
            <Component {...this.props} />
          );
        }}
      />
    );
  }
}

const stateToProps = () => ({
  pending: !localStorage.getItem(ACCESS_TOKEN),
  logged: localStorage.getItem(ACCESS_TOKEN),
});

export default connect(stateToProps)(AuthorizedToken);
