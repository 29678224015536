import React, { Component } from 'react';

import VatComponent from '../../components/vat/vat';

class VatPage extends Component {

    render() {
        return (
            <VatComponent/>
        );
    }
}
export default VatPage;
