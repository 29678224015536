export interface OperationModeModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface SettingsState {
  currentSettingsData: any;
  deliveryStation: any;
  stations: any;
  isFetching: boolean;
  isSettingUpdated: boolean;
  isUpdated: boolean;
  operationmode: any;
  setting: any;
  recipe_edit_mode: any;
  meals_to_same_chef: any;
  languages: any;
  selectedLang: any;
  country: object;
  currentLanguage: string;
  currencyList: any;
  currencyCode: String;
  currency: Object;
  hasError: boolean;
  hourlyCooksRate: Number;
  labelList: Array<any>;
  minorUnit: String;
  minimalDigitalDenomination: String;
  minimalCashDenomination: String;
  posDefaultLabel: String;
  reportTemplates: Array<any>;
  reservation: String;
  showFirstLevelLabels: boolean;
  url: any;
  assembleTaskTitleEn: string;
  assembleTaskTitleDe: string;
  assembleTaskDescriptionEn: string;
  assembleTaskDescriptionDe: string;
  corporateColor: string;
  restaurantLogo: any;
}

export const SettingsInitState: SettingsState = {
  currentSettingsData: {},
  deliveryStation: null,
  stations: [],
  isFetching: false,
  isSettingUpdated: false,
  isUpdated: false,
  operationmode: [],
  setting: [],
  recipe_edit_mode: 0,
  meals_to_same_chef: 0,
  languages: [],
  selectedLang: [],
  country: {},
  currencyCode: "",
  currentLanguage: "",
  currencyList: [],
  currency: {},
  hasError: false,
  hourlyCooksRate: 0,
  labelList: [],
  minorUnit: "",
  minimalDigitalDenomination: "",
  minimalCashDenomination: "",
  posDefaultLabel: "",
  reportTemplates: [],
  reservation: "",
  showFirstLevelLabels: false,
  url: [],
  assembleTaskTitleEn: "",
  assembleTaskTitleDe: "",
  assembleTaskDescriptionEn: "",
  assembleTaskDescriptionDe: "",
  corporateColor: "",
  restaurantLogo: {},
};
