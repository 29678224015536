import apiCall from "./api.call.config";

async function addPaymentProvider(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/payment-option",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}
async function paymentProviderDetails(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/payment-option/" +
        params.credentials.uuid +
        "/details"
    );
  } catch (e) {
    throw e;
  }
}

async function paymentProviderList(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.credentials.restaurantuuid + "/payment-option"
    );
  } catch (e) {
    throw e;
  }
}
async function removePaymentProvider(url: string, params: any) {
  try {
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/payment-option/" +
        params.credentials.uuid
    );
  } catch (e) {
    throw e;
  }
}

async function updatePaymentProvider(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/payment-option/" +
        params.credentials.uuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  addPaymentProvider,
  paymentProviderDetails,
  paymentProviderList,
  removePaymentProvider,
  updatePaymentProvider,
};
