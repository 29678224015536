import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function periodicityRequest(credentials: object): ActionModel {
  return {
    type: types.PERIODICITY.REQUEST,
    credentials,
  };
}

export function periodicitySuccess(data: object): ActionModel {
  return {
    type: types.PERIODICITY.SUCCESS,
    data,
  };
}

export function periodicityFailure(err: object): ActionModel {
  return {
    type: types.PERIODICITY.FAILURE,
    err,
  };
}
export function periodicityAdd(credentials: object): ActionModel {
  return {
    type: types.PERIODICITY_ADD.REQUEST,
    credentials,
  };
}

export function periodicityAddSuccess(data?: object): ActionModel {
  return {
    type: types.PERIODICITY_ADD.SUCCESS,
    data,
  };
}

export function periodicityAddFailure(err: object): ActionModel {
  return {
    type: types.PERIODICITY_ADD.FAILURE,
    err,
  };
}

export function periodicityDetails(credentials: object): ActionModel {
  return {
    type: types.PERIODICITY_DETAILS.REQUEST,
    credentials,
  };
}

export function periodicityDetailsSuccess(data?: object): ActionModel {
  return {
    type: types.PERIODICITY_DETAILS.SUCCESS,
    data,
  };
}

export function periodicityDetailsFailure(err: object): ActionModel {
  return {
    type: types.PERIODICITY_DETAILS.FAILURE,
    err,
  };
}

export function periodicityRemove(credentials: object): ActionModel {
  return {
    type: types.PERIODICITY_REMOVE.REQUEST,
    credentials,
  };
}

export function periodicityRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.PERIODICITY_REMOVE.SUCCESS,
    data,
  };
}

export function periodicityRemoveFailure(err: object): ActionModel {
  return {
    type: types.PERIODICITY_REMOVE.FAILURE,
    err,
  };
}
export function periodicityUpdate(credentials: object): ActionModel {
  return {
    type: types.PERIODICITY_UPDATE.REQUEST,
    credentials,
  };
}

export function periodicityUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.PERIODICITY_UPDATE.SUCCESS,
    data,
  };
}

export function periodicityUpdateFailure(err: object): ActionModel {
  return {
    type: types.PERIODICITY_UPDATE.FAILURE,
    err,
  };
}
