import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React from "react";
import { storageType } from "../../../models/storage.model";
import StorageActionButtons from "../../card-components/ingredients-card/storageActionButtons";
import StorageQuantityLabels from "../../card-components/ingredients-card/storageQuantityLabels";

interface Props {
  actions: {
    addNewStorage: Function;
    editStorage: Function;
    editKeyValue: Function;
    getBestMatchUnits: Function;
    getClasses: Function;
    removeStorage: Function;
  };
  area: string;
  metaData: {
    recipeId: string;
    parentData?: any;
  };
  storage: any;
}

const RecipeLocationTreeCard = (props: Props) => {
  const { storage, actions, metaData, area } = props;
  let childArea: string = "";

  const parentData: any = { ...(metaData?.parentData || {}) };

  if (area === storageType.ROOM) {
    childArea = storageType.STORAGE;
    parentData[storageType.ROOM] = storage.uuid;
  } else if (area === storageType.STORAGE) {
    childArea = storageType.STORAGE_RACK;
    parentData[storageType.STORAGE] = storage.uuid;
  } else if (area === storageType.STORAGE_RACK) {
    childArea = storageType.STORAGE_PLACE;
    parentData[storageType.STORAGE_RACK] = storage.uuid;
  } else {
    parentData[storageType.STORAGE_PLACE] = storage.uuid;
  }
  const childMetaData: any = { ...metaData };
  childMetaData["parentData"] = parentData;

  const renderChildStorage = (storages: Array<any>, area: string) => {
    return (
      <ul className="b_tree_child">
        {_.sortBy(storages, [(st: any) => st.name.toLowerCase()])?.map(
          (storage: any, indx: number) => {
            return (
              <RecipeLocationTreeCard
                actions={actions}
                key={indx}
                metaData={childMetaData}
                storage={storage}
                area={area}
              />
            );
          },
        )}
      </ul>
    );
  };

  return (
    <li key={storage.uuid}>
      {storage[childArea]?.length > 0 ? (
        <>
          <input
            type="checkbox"
            id={`${area}_${storage.uuid}_${metaData.recipeId}`}
          />
          <label
            className={actions.getClasses(
              storage?.selection?.hasNode,
              !!storage?.selection?.isActive
            )}
            htmlFor={`${area}_${storage.uuid}_${metaData.recipeId}`}
          >
            {storage?.selection?.main_storage && (
              <span className="mr-2">
                <FontAwesomeIcon className="text-info" icon={faCheckCircle} />
              </span>
            )}
            {storage.name}
            <StorageQuantityLabels
              min_quantity={actions.getBestMatchUnits(
                storage?.selection?.min_quantity,
                storage?.selection?.output_unit,
                metaData.recipeId
              )}
              max_quantity={actions.getBestMatchUnits(
                storage?.selection?.max_quantity,
                storage?.selection?.output_unit,
                metaData.recipeId
              )}
              inventory_type={storage?.selection?.inventory_type}
              output_quantity={actions.getBestMatchUnits(
                storage?.selection?.output_quantity,
                storage?.selection?.output_unit,
                metaData.recipeId
              )}
            />
            <StorageActionButtons
              addNewStorage={actions.addNewStorage}
              isActive={storage?.selection?.isActive}
              addFormData={[metaData.recipeId, area, parentData]}
              editFormData={[metaData.recipeId, storage?.selection?.uuid]}
              removeFormData={[metaData.recipeId, storage?.selection?.uuid]}
              editStorage={actions.editStorage}
              editKeyValue={actions.editKeyValue}
              removeStorage={actions.removeStorage}
            />
          </label>
          {renderChildStorage(storage?.[childArea], childArea)}
        </>
      ) : (
        <>
          <span
            className={actions.getClasses(
              storage?.selection?.hasNode,
              !!storage?.selection?.isActive
            )}
          >
            {storage?.selection?.main_storage && (
              <span className="mr-2">
                <FontAwesomeIcon className="text-info" icon={faCheckCircle} />
              </span>
            )}{" "}
            {storage.name}
            <StorageQuantityLabels
              min_quantity={actions.getBestMatchUnits(
                storage?.selection?.min_quantity,
                storage?.selection?.output_unit,
                metaData.recipeId
              )}
              max_quantity={actions.getBestMatchUnits(
                storage?.selection?.max_quantity,
                storage?.selection?.output_unit,
                metaData.recipeId
              )}
              inventory_type={storage?.selection?.inventory_type}
              output_quantity={actions.getBestMatchUnits(
                storage?.selection?.output_quantity,
                storage?.selection?.output_unit,
                metaData.recipeId
              )}
            />
            <StorageActionButtons
              addNewStorage={actions.addNewStorage}
              isActive={!!storage?.selection?.isActive}
              addFormData={[metaData.recipeId, area, parentData]}
              editFormData={[metaData.recipeId, storage?.selection?.uuid]}
              removeFormData={[metaData.recipeId, storage?.selection?.uuid]}
              editStorage={actions.editStorage}
              editKeyValue={actions.editKeyValue}
              removeStorage={actions.removeStorage}
            />
          </span>
        </>
      )}
    </li>
  );
};

export default RecipeLocationTreeCard;
