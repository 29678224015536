import "../../../styles/card-item.scss";
import React from "react";

function CardItemContainer(props: any) {
  return (
    <div
      className={props.className + " px-md-4 mb-3"}
      style={{ position: "relative" }}
    >
      {props.children}
    </div>
  );
}

export { CardItemContainer };
