import '../../../../styles/navigation-left.scss';

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';

class OperateLeftComponent extends Component<any> {
    render() {
        return (
            <div>
                    <Button className={this.props.display === 'support' ? "btn-dark mb-3" : "btn-light mb-3"}>Support</Button>
            </div>
        );
    }
}

export default withRouter(OperateLeftComponent);
