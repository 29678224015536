
export interface RegistrationState {
  country_code: string,
  first_name: string,
  last_name: string,
  email: string,
  password: string,
  isFetching: boolean,
  validate: boolean,
  country: string
}

export const RegistrationInitState = {
  country_code: 'CH',
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  isFetching: false,
  validate: false,
  country: ''
};
export interface RegistrationModel {
  isAuthenticated: boolean,
  isFetching: boolean,
  user: any,
  error: any,
  failure: boolean
}
