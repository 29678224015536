import "../../styles/transport.scss";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Col, Row, Table } from "reactstrap";

import {
  TransportInitState,
  TransportState,
} from "../../models/transport.model";
import { machineRequest } from "../../redux/actions/machine.action";
import { roomRequest } from "../../redux/actions/room.action";
import { stationsRequest } from "../../redux/actions/stations.action";
import { storageRequest } from "../../redux/actions/storage.action";
import {
  transportAdd,
  transportRemove,
  transportRequest,
  transportUpdate,
} from "../../redux/actions/transport.action";
import commonService from "../../services/common.service";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import TransportAddCard from "../card-components/transport-add-card/transport-add-card";
import TransportCard from "../card-components/transport-card/transport-card";
import TransportEditCard from "../card-components/transport-edit-card/transport-edit-card";
import LoaderComponent from "../loader/loader";
import SettingsHeaderComponent from "../navigation/navigation-header/settings-header/settings-header";
import UsersLeftComponent from "../navigation/navigation-left/users-navigation/users-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import { withTranslation } from "react-i18next";
class TransportTaskComponent extends Component<any> {
  state: TransportState;
  constructor(props: any) {
    super(props);
    this.state = TransportInitState;
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        <LoaderComponent display={!!this.props.isFetching} />
        <div className="container-fluid">
          <SettingsHeaderComponent settings={"settings"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <UsersLeftComponent
                display={"transport"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <Link
                to="#"
                color="info"
                className="transport-add"
                onClick={this.addToggle.bind(this)}
              >
                {t("common.addRouter")}
              </Link>
              <div className="white-box mb-3">
                <h4>{t("task.transportTask")}</h4>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>{t("common.from")}</th>
                      <th>{t("common.to")}</th>
                      <th>{t("task.transportTask")}</th>
                      <th>{t("station.responsibleStation")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  {this.getTransportList()}
                </Table>
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>

        {/* Delete transport task */}

        <DeleteModalCard
          isOpen={this.state.deletemodal}
          isFetching={!!this.state.isFetching}
          okDelete={this.deleteConfirmToggle.bind(this)}
          cancelDelete={this.deleteToggle.bind(this)}
        />

        <TransportAddCard
          addToggle={this.addToggle.bind(this)}
          addmodal={this.state.addmodal}
          fromtype={this.state.fromtype}
          fromvalue={this.state.fromvalue}
          handleChange={this.handleChange.bind(this)}
          handleTypeChange={this.handleTypeChange.bind(this)}
          responsibleStation={this.state.responsibleStation}
          saveAdd={this.saveAdd.bind(this)}
          selectFromType={this.selectFromType}
          selectToType={this.selectToType}
          totype={this.state.totype}
          tovalue={this.state.tovalue}
          transportmode={this.state.transportmode}
        />

        <TransportEditCard
          editmodal={this.state.editmodal}
          editToggle={this.editToggle.bind(this)}
          fromtype={this.state.fromtype}
          fromvalue={this.state.fromvalue}
          handleChange={this.handleChange.bind(this)}
          handleTypeChange={this.handleTypeChange.bind(this)}
          responsibleStation={this.state.responsibleStation}
          saveEdit={this.saveEdit.bind(this)}
          selectFromType={this.selectFromType}
          selectToType={this.selectToType}
          totype={this.state.totype}
          tovalue={this.state.tovalue}
          transportmode={this.state.transportmode}
        />
      </div>
    );
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.getTransport({ restaurantuuid: this.props.match.params.uuid });
    this.props.getRooms({ restaurantuuid: this.props.match.params.uuid });
    this.props.getStations({ uuid: this.props.match.params.uuid });
    this.props.getMachine({ uuid: this.props.match.params.uuid });
    this.props.getStorage({ restaurantuuid: this.props.match.params.uuid });
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps && !newProps.isFetching) {
      let racks: any = [];
      let palces: any = [];
      let alldata: any = [];
      newProps.storages.forEach((storage: any) => {
        racks = racks.concat(storage.storageracks);
      });
      racks.forEach((rack: any) => {
        palces = palces.concat(rack.places);
      });
      alldata = alldata.concat(
        newProps.rooms,
        newProps.stations,
        newProps.machines,
        newProps.storages,
        racks,
        palces
      );
      this.setState({
        transportlist: newProps.transport,
        roomlist: newProps.rooms,
        stationlist: newProps.stations,
        machinelist: newProps.machines,
        storagelist: newProps.storages,
        racklist: racks,
        placelist: palces,
        alllists: alldata,
      });
    }
    if (!!this.state.isUpdated && !newProps.isFetching) {
      this.props.getTransport({ restaurantuuid: this.props.match.params.uuid });
      this.setState({ isUpdated: false });
    }

    if (
      !!newProps.transportadd.status &&
      !!this.state.addmodal &&
      !newProps.isfetching
    ) {
      this.addToggle();
    }

    if (
      !!newProps.transportupdate.status &&
      !!this.state.editmodal &&
      !newProps.isfetching
    ) {
      this.editToggle();
    }
  }

  public addToggle(): void {
    this.setState((prevState: any) => ({
      addmodal: !prevState.addmodal,
      fromtype: "",
      fromvalue: "",
      responsibleStation: "",
      totype: "",
      tovalue: "",
      transportmode: 3,
    }));
  }

  public saveEdit(): void {
    let exist = false;
    this.state.transportlist.forEach((item: any) => {
      if (
        item.from_uuid === this.state.fromvalue &&
        item.to_uuid === this.state.tovalue &&
        this.state.uuid !== item.uuid
      ) {
        exist = true;
        commonService.toastService(
          this.props.t("toast.transportTaskExits"),
          "warning"
        );
      }
    });
    if (
      !this.state.fromtype ||
      !this.state.fromvalue ||
      !this.state.totype ||
      !this.state.tovalue ||
      !this.state.transportmode
    ) {
      commonService.toastService(
        this.props.t("toast.selectAllMandatoryFields"),
        "warning"
      );
    } else if (!exist) {
      let data: any = {
        from_type: this.state.fromtype,
        from_uuid: this.state.fromvalue,
        to_type: this.state.totype,
        to_uuid: this.state.tovalue,
        transport_mode: parseInt(this.state.transportmode),
      };
      if (
        (data.transport_mode === 2 || data.transport_mode === 3) &&
        this.state.responsibleStation !== ""
      ) {
        data["responsible_station"] = this.state.responsibleStation;
      } else data["responsible_station"] = "";
      this.props.updateTransport({
        restaurantuuid: this.props.match.params.uuid,
        uuid: this.state.uuid,
        data: data,
      });
      this._setStates("isUpdated", true);
    }
  }

  public saveAdd(): void {
    let exist = false;
    this.state.transportlist.forEach((item: any) => {
      if (
        item.from_uuid === this.state.fromvalue &&
        item.to_uuid === this.state.tovalue
      ) {
        exist = true;
        commonService.toastService(
          this.props.t("toast.transportTaskExits"),
          "warning"
        );
      }
    });
    if (
      !this.state.fromtype ||
      !this.state.fromvalue ||
      !this.state.totype ||
      !this.state.tovalue ||
      !this.state.transportmode
    ) {
      commonService.toastService(
        this.props.t("toast.selectAllMandatoryFields"),
        "warning"
      );
    } else if (!exist) {
      let data: any = {
        from_type: this.state.fromtype,
        from_uuid: this.state.fromvalue,
        to_type: this.state.totype,
        to_uuid: this.state.tovalue,
        transport_mode: parseInt(this.state.transportmode),
      };
      if (
        (data.transport_mode === 2 || data.transport_mode === 3) &&
        this.state.responsibleStation !== ""
      ) {
        data["responsible_station"] = this.state.responsibleStation;
      }
      this.props.addTransport({
        restaurantuuid: this.props.match.params.uuid,
        data: data,
      });
      this._setStates("isUpdated", true);
    }
  }

  public setDelete(event: any): void {
    this.setState({ uuid: event.uuid });
    this.deleteToggle();
  }

  public deleteToggle(): void {
    this.setState((prevState: any) => ({
      deletemodal: !prevState.deletemodal,
    }));
  }

  public deleteConfirmToggle(): void {
    this.props.removeTransport({
      uuid: this.state.uuid,
      restaurantuuid: this.props.match.params.uuid,
    });
    this.setState({ isUpdated: true });
    this.deleteToggle();
  }

  public getNames(uuid: string) {
    let option;
    if (!!this.state.alllists && this.state.alllists.length > 0) {
      // eslint-disable-next-line
      option = this.state.alllists.map((item: any, i: number) => {
        if (item.uuid === uuid) {
          let name = item.name;
          if (!name) {
            name = commonService.applyLocalization(
              "restaurant",
              "name",
              item.locales
            )["name"];
          }
          return <span key={i}>{name}</span>;
        }
      });
      return option;
    }
  }

  public getTransportList(): any {
    let transport;
    if (!!this.state.transportlist && this.state.transportlist.length > 0) {
      transport = this.state.transportlist.map(
        (locales: any, index: number) => {
          return (
            <TransportCard
              locales={locales}
              key={index}
              getNames={this.getNames.bind(this)}
              setEdit={this.setEdit.bind(this, locales)}
              setDelete={this.setDelete.bind(this, locales)}
            />
          );
        }
      );
      return transport;
    } else if (!this.props.isFetching) {
      return (
        <React.Fragment>
          <tbody>
            <tr>
              <td>{this.props.t("common.noRecords")}</td>
            </tr>
          </tbody>
        </React.Fragment>
      );
    }
  }

  public selectFromType = (type: any) => {
    if (type === "room") {
      let data = this.state.roomlist.length > 0 ? this.state.roomlist : [];
      let option;
      if (!!data) {
        // eslint-disable-next-line
        option = data.map((item: any, i: number) => {
          if (this.state.tovalue !== item.uuid) {
            let locales = commonService.applyLocalization(
              "restaurant",
              "name",
              item.locales
            );
            return (
              <option key={i} value={item.uuid}>
                {locales.name}
              </option>
            );
          }
        });
        return option;
      }
    } else if (type === "station") {
      let data =
        this.state.stationlist.length > 0 ? this.state.stationlist : [];
      let option;
      if (!!data) {
        // eslint-disable-next-line
        option = data.map((item: any, i: number) => {
          if (this.state.tovalue !== item.uuid) {
            let locales = commonService.applyLocalization(
              "restaurant",
              "name",
              item.locales
            );
            return (
              <option key={i} value={item.uuid}>
                {locales.name}
              </option>
            );
          }
        });
        return option;
      }
    } else if (type === "machine") {
      let data =
        this.state.machinelist.length > 0 ? this.state.machinelist : [];
      let option;
      if (!!data) {
        // eslint-disable-next-line
        option = data.map((item: any, i: number) => {
          if (this.state.tovalue !== item.uuid) {
            return (
              <option key={i} value={item.uuid}>
                {item.name}
              </option>
            );
          }
        });
        return option;
      }
    } else if (type === "storage") {
      let data =
        this.state.storagelist.length > 0 ? this.state.storagelist : [];
      let option;
      if (!!data) {
        // eslint-disable-next-line
        option = data.map((item: any, i: number) => {
          if (this.state.tovalue !== item.uuid) {
            return (
              <option key={i} value={item.uuid}>
                {item.name}
              </option>
            );
          }
        });
        return option;
      }
    } else if (type === "rack") {
      let data = this.state.racklist.length > 0 ? this.state.racklist : [];
      let option;
      if (!!data) {
        // eslint-disable-next-line
        option = data.map((item: any, i: number) => {
          if (this.state.tovalue !== item.uuid) {
            return (
              <option key={i} value={item.uuid}>
                {item.name}
              </option>
            );
          }
        });
        return option;
      }
    } else if (type === "place") {
      let data = this.state.placelist.length > 0 ? this.state.placelist : [];
      let option;
      if (!!data) {
        // eslint-disable-next-line
        option = data.map((item: any, i: number) => {
          if (this.state.tovalue !== item.uuid) {
            return (
              <option key={i} value={item.uuid}>
                {item.name}
              </option>
            );
          }
        });
        return option;
      }
    }
  };

  public selectToType = (type: any) => {
    if (type === "room") {
      let data = this.state.roomlist.length > 0 ? this.state.roomlist : [];
      let option;
      if (!!data) {
        // eslint-disable-next-line

        option = data.map((item: any, i: number) => {
          if (this.state.fromvalue !== item.uuid) {
            let locales = commonService.applyLocalization(
              "restaurant",
              "name",
              item.locales
            );
            return (
              <option key={i} value={item.uuid}>
                {locales.name}
              </option>
            );
          }
        });
        return option;
      }
    } else if (type === "station") {
      let data =
        this.state.stationlist.length > 0 ? this.state.stationlist : [];
      let option;
      if (!!data) {
        // eslint-disable-next-line
        option = data.map((item: any, i: number) => {
          if (this.state.fromvalue !== item.uuid) {
            let locales = commonService.applyLocalization(
              "restaurant",
              "name",
              item.locales
            );
            return (
              <option key={i} value={item.uuid}>
                {locales.name}
              </option>
            );
          }
        });
        return option;
      }
    } else if (type === "machine") {
      let data =
        this.state.machinelist.length > 0 ? this.state.machinelist : [];
      let option;
      if (!!data) {
        // eslint-disable-next-line
        option = data.map((item: any, i: number) => {
          if (this.state.fromvalue !== item.uuid) {
            return (
              <option key={i} value={item.uuid}>
                {item.name}
              </option>
            );
          }
        });
        return option;
      }
    } else if (type === "storage") {
      let data =
        this.state.storagelist.length > 0 ? this.state.storagelist : [];
      let option;
      if (!!data) {
        // eslint-disable-next-line
        option = data.map((item: any, i: number) => {
          if (this.state.fromvalue !== item.uuid) {
            return (
              <option key={i} value={item.uuid}>
                {item.name}
              </option>
            );
          }
        });
        return option;
      }
    } else if (type === "rack") {
      let data = this.state.racklist.length > 0 ? this.state.racklist : [];
      let option;
      if (!!data) {
        // eslint-disable-next-line
        option = data.map((item: any, i: number) => {
          if (this.state.fromvalue !== item.uuid) {
            return (
              <option key={i} value={item.uuid}>
                {item.name}
              </option>
            );
          }
        });
        return option;
      }
    } else if (type === "place") {
      let data = this.state.placelist.length > 0 ? this.state.placelist : [];
      let option;
      if (!!data) {
        // eslint-disable-next-line
        option = data.map((item: any, i: number) => {
          if (this.state.fromvalue !== item.uuid) {
            return (
              <option key={i} value={item.uuid}>
                {item.name}
              </option>
            );
          }
        });
        return option;
      }
    } else if (type === "responsibleStation") {
      let data =
        this.state.stationlist.length > 0 ? this.state.stationlist : [];
      let option;
      if (!!data) {
        option = data.map((item: any, i: number) => {
          let locales = commonService.applyLocalization(
            "restaurant",
            "name",
            item.locales
          );
          return (
            <option key={i} value={item.uuid}>
              {locales.name}
            </option>
          );
        });
        return option;
      }
    }
  };

  public setEdit(event: any) {
    this.editToggle();
    this.setState({
      uuid: event.uuid,
      fromtype: event.from_type,
      fromvalue: event.from_uuid,
      totype: event.to_type,
      tovalue: event.to_uuid,
      transportmode: event.transport_mode,
      responsibleStation: event?.responsible_station || "",
    });
  }

  public editToggle(): void {
    this.setState((prevState: any) => ({
      editmodal: !prevState.editmodal,
    }));
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  public handleTypeChange(e: any) {
    if (e.target.name === "fromtype") {
      this.setState({
        fromvalue: "",
        fromtype: e.target.value,
      });
    } else {
      this.setState({
        tovalue: "",
        totype: e.target.value,
      });
    }
  }

  public handleChange(e: any) {
    this._setStates(e.target.name, e.target.value);
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.transport.isFetching ||
    state.transportupdate.isFetching ||
    state.transportadd.isFetching ||
    state.transportremove.isFetching ||
    state.room.isFetching ||
    state.stations.isFetching ||
    state.machine.isFetching ||
    state.storage.isFetching;
  let failure =
    state.transport.failure ||
    state.transportupdate.failure ||
    state.transportadd.failure ||
    state.transportremove.failure ||
    state.room.failure ||
    state.stations.failure ||
    state.machine.failure ||
    state.storage.failure;
  return {
    transport: state.transport.data,
    rooms: state.room.data,
    stations: state.stations.data,
    machines: state.machine.data,
    storages: state.storage.data,
    transportadd: state.transportadd.data,
    transportupdate: state.transportupdate.data,
    isFetching: isFetching,
    failure: failure,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getTransport: (credentials: any) => {
      dispatch(transportRequest(credentials));
    },
    removeTransport: (credentials: any) => {
      dispatch(transportRemove(credentials));
    },
    addTransport: (credentials: any) => {
      dispatch(transportAdd(credentials));
    },
    updateTransport: (credentials: any) => {
      dispatch(transportUpdate(credentials));
    },
    getRooms: (credentials: any) => {
      dispatch(roomRequest(credentials));
    },
    getStations: (credentials: any) => {
      dispatch(stationsRequest(credentials));
    },
    getMachine: (credentials: any) => {
      dispatch(machineRequest(credentials));
    },
    getStorage: (credentials: any) => {
      dispatch(storageRequest(credentials));
    },
  };
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(TransportTaskComponent))
);
