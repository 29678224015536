import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import "../../../styles/profile.scss";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import commonService from "../../../services/common.service";

export default function ProfileEditModalCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <Modal isOpen={props.state.editmodal} className="modal-dialog-centered">
      <ModalHeader toggle={props.editToggle} className="modal-header">
        {t("common.editPersonalInformation")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstname">{t("common.firstName")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              required
              type="text"
              name="first_name"
              placeholder={t("common.firstName")}
              onChange={props.handleChange}
              value={props.state.first_name}
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastname">{t("common.lastName")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              required
              type="text"
              name="last_name"
              placeholder={t("common.lastName")}
              onChange={props.handleChange}
              value={props.state.last_name}
            />
          </FormGroup>
          <FormGroup>
            <Label for="city">{t("common.city")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              required
              type="text"
              name="city"
              placeholder={t("common.city")}
              onChange={props.handleChange}
              value={props.state.city}
            />
          </FormGroup>
          <FormGroup>
            <Label for="country">{t("common.country")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="select"
              id="countrySelect"
              required
              name="country_code"
              onChange={props.handleChange}
              value={props.state.country_code}
            >
              {props.OPTIONS}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="email">{t("common.email")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              name="email"
              onChange={props.handleChange}
              value={
                commonService.isGeneratedEmail(props.state.email)
                  ? ""
                  : props.state.email
              }
              disabled
            />
          </FormGroup>
          <div style={{ float: "right" }}>
            <Button
              color="secondary"
              onClick={props.editToggle}
              style={{ margin: 5 }}
            >
              {t("common.cancel")}
            </Button>
            <Button
              color="info"
              type="submit"
              onClick={props.saveEdit}
              disabled={
                !props.state.city ||
                !props.state.country_code ||
                !props.state.first_name ||
                !props.state.last_name ||
                !!props.state.isFetching
              }
              style={{ margin: 5 }}
            >
              {t("common.save")}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
