import { call, put, takeLatest } from "redux-saga/effects";
import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  dateItemFailure,
  dateItemSuccess,
} from "../redux/actions/date.item.action";

import * as types from "../redux/actions/types";
import dateItem from "../services/date.item.service";
import handleResponse from "../services/response.service";

const dateItemCall = (params: any) =>
  dateItem.getDateItemList(RESTAURANT_REQUEST, params);

function* handleDateItemRequest(params: object) {
  try {
    let result;
    result = yield call(dateItemCall, params);
    yield handleDateItem200(result);
  } catch (error) {
    throw error;
  }
}

const handleDateItem200 = function* handleDateItem200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(dateItemSuccess(result.data));
  } else {
    yield put(dateItemFailure(result.data));
    handleResponse(result);
  }
};

export default function* dateItemSaga() {
  yield takeLatest(types.DATE_ITEM.REQUEST, handleDateItemRequest);
}
