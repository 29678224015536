import apiCall from "./api.call.config";

async function restaurant(url: string) {
  try {
    return await apiCall.get(url);
  } catch (e) {
    throw e;
  }
}

async function updateRestaurant(url: string, params: any) {
  try {
    return await apiCall.put(
      url + "/" + params.credentials.uuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function addRestaurant(url: string, params: any) {
  try {
    return await apiCall.post(url, params.credentials.data);
  } catch (e) {
    throw e;
  }
}

async function removeRestaurant(url: string, params: any) {
  try {
    return await apiCall.remove(url + "/" + params.credentials.uuid);
  } catch (e) {
    throw e;
  }
}

export async function getSuppliedRestaurants(
  url: string,
  restaurantuuid: string
) {
  try {
    return await apiCall.get(
      url + "/" + restaurantuuid + "/supplied-restaurants"
    );
  } catch (e) {
    throw e;
  }
}

export async function getCorporateSettings(url: string, restaurantuuid: any) {
  try {
    return await apiCall.get(
      url + "/restaurant/" + restaurantuuid + "/corporate-settings"
    );
  } catch (e) {
    throw e;
  }
}

export default {
  restaurant,
  updateRestaurant,
  addRestaurant,
  removeRestaurant,
  getCorporateSettings,
};
