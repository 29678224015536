import '../../styles/user-verification.scss';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import smartToniLogin from '../../assets/images/logo/smartToniLogin.png';
import { UserVerificationState } from '../../models/user.verification.model';
import { userverificationRequest } from '../../redux/actions/user.verification.action';
import LoaderComponent from '../loader/loader';

const initState = {
    isFetching: false
};
class UserVerificationComponent extends Component<any> {
    state: UserVerificationState;
    constructor(props: any) {
        super(props);
        this.state = initState;
    }
    render() {
        return (
            <div>
                <LoaderComponent display={!!this.state.isFetching} />
                <Container>
                    <Row>
                        <Col><img className="img-home-fluid" src={smartToniLogin} alt="Logo" /></Col>
                    </Row>
                </Container>
            </div>
        );
    }

    componentDidMount() {
        this.props.userverification({
            verification_token: this.props.match.params.token,
        })
    }

    UNSAFE_componentWillReceiveProps(newProps: any) {
        if (!!newProps.data && !!newProps.data.status) {
            this.props.history.push('/')
        }
        if (!!newProps.error && !!newProps.error.errors && !newProps.error.token_valid) {
            this.props.history.push('/')
        }
        if (!!newProps.data && !!newProps.data.token_valid) {
            this.props.history.push('/registration', { type: 'invitation', token: this.props.match.params.token, email: newProps.data.email })
        }
        this._setStates('isFetching', newProps.isFetching);
    }

    private _setStates(name: string, value: any): void {
        this.setState({ [name]: value });
    }
}

const mapStateToProps: any = (state: any) => {
    return {
        data: state.userverification.data,
        error: state.userverification.error,
        isFetching: state.userverification.isFetching,
        failure: state.userverification.failure
    };
};

const mapDispatchToProps: object = (dispatch: any) => {
    return {
        userverification: (credentials: any) => {
            if (!!credentials) {
                dispatch(userverificationRequest(credentials));
            }
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps)(withRouter(UserVerificationComponent));
