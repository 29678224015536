import React, { Component } from 'react';

export function withCardItemSelectionState(cardItemType: string, hierarchyPosition: number) {

    return (WrappedComponent: any) => {

        return class extends Component<any, any> {
            cardItem: any;
            cardItemSelectionData: any;
            setSelectedCardItemCallback: Function;
            onDeselectCallback: any = null;

            constructor(props: any) {
                super(props);
                if (props.cardItemSelectionCallback) {
                    this.setSelectedCardItemCallback = props.cardItemSelectionCallback;
                } else {
                    this.setSelectedCardItemCallback = props.parentCardItemSelectionData.setSelectedCardItem;
                }
                if (hierarchyPosition > 0) {
                    this.cardItem = {
                        type: cardItemType,
                        itemHierarchyPosition: hierarchyPosition,
                        id: props.cardItemId,
                        isNewCardItem: props.isNewCardItem
                    };
                } else {
                    this.cardItem = null;
                }
                this.cardItemSelectionData = this.calculateCardItemSelectionData();
            }

            public setSelectedCardItem = (item: any, mode: any) => {
                this.setSelectedCardItemCallback(this.cardItem ? [this.cardItem].concat(item) : item, mode);
            }

            public setOnDeselectCallback = (callback: Function) => {
                this.onDeselectCallback = callback
            }

            private calculateCardItemSelectionData = () => {
                let cardItemSelectionManagerID = this.props.parentCardItemSelectionData ? this.props.parentCardItemSelectionData.cardItemSelectionManagerID : this.props.cardItemSelectionManagerID;
                let item = this.props.parentCardItemSelectionData ? this.props.parentCardItemSelectionData.selectionState.selectedItem : this.props.cardItemSelectionState.selectedItem;
                let mode = this.props.parentCardItemSelectionData ? this.props.parentCardItemSelectionData.selectionState.mode : this.props.cardItemSelectionState.mode;

                let selectionState = {
                    selectedItem: item,
                    mode: mode,
                    isBranchSelected:
                        (
                            this.cardItem
                            && item.length > (this.cardItem.itemHierarchyPosition)
                            && item[this.cardItem.itemHierarchyPosition - 1].type === this.cardItem.type
                            && item[this.cardItem.itemHierarchyPosition - 1].id === this.cardItem.id
                        ),
                    isItemSelected:
                        (
                            this.cardItem
                            && item.length === (this.cardItem.itemHierarchyPosition)
                            && item[this.cardItem.itemHierarchyPosition - 1].type === this.cardItem.type
                            && item[this.cardItem.itemHierarchyPosition - 1].id === this.cardItem.id
                        ),
                    canBeSelected: false,
                    isBeingEdited: false
                };

                selectionState.canBeSelected = selectionState.mode !== "edit" && !selectionState.isItemSelected;
                selectionState.isBeingEdited = selectionState.isItemSelected && selectionState.mode === "edit";

                return {
                    cardItemSelectionManagerID: cardItemSelectionManagerID,
                    cardItem: this.cardItem,
                    selectionState: selectionState,
                    setSelectedCardItem: this.setSelectedCardItem,
                    selectCardItem: this.selectCardItem,
                    deselectCardItem: this.deselectCardItem,
                    editCardItem: this.editCardItem,
                    className: this.calculateClassName(selectionState),
                    setOnDeselectCallback: this.setOnDeselectCallback
                }
            }

            private calculateClassName = (selectionState: any): string => {
                let classes = ""
                if (selectionState.isItemSelected) {
                    classes += " selected"
                } else if (selectionState.isBranchSelected) {
                    classes += " selected-branch"
                }
                if (this.cardItem && this.cardItem.isNewCardItem && !(selectionState.isItemSelected && selectionState.mode === "edit")) {
                    classes += " newItemButton"
                }
                return classes;
            }

            public selectCardItem = () => {
                this.setSelectedCardItemCallback(this.cardItem ? [this.cardItem] : [], "default");
            }

            public editCardItem = () => {
                this.setSelectedCardItemCallback(this.cardItem ? [this.cardItem] : [], "edit");
            }

            public deselectCardItem = () => {
                this.setSelectedCardItemCallback([], "default");
                if (this.onDeselectCallback) {
                    this.onDeselectCallback();
                }
            }

            render() {
                this.cardItemSelectionData = this.calculateCardItemSelectionData();

                return <WrappedComponent
                    {...this.props}
                    cardItemSelectionData={this.cardItemSelectionData}
                />;
            }
        }
    }
}
