import { call, put, takeLatest } from "redux-saga/effects";

import { USER_REQUEST } from "../config/api.config";
import {
  userLanguageUpdateSuccess,
  userLanguageUpdateFailure,
} from "../redux/actions/user.language.action";
import * as types from "../redux/actions/types";
import userUpdateLanguage from "../services/user.language.service";
import handleResponse from "../services/response.service";

const userUpdateLanguageCall = (params: any) =>
  userUpdateLanguage.userUpdateLanguage(USER_REQUEST, params);

function* handleUserLanguageUpdate(params: object) {
  try {
    let result;
    result = yield call(userUpdateLanguageCall, params);
    yield handleUserLanguageUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleUserLanguageUpdate200 = function* handleLanguageUpdate200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(userLanguageUpdateSuccess(result.data));
  } else {
    yield put(userLanguageUpdateFailure(result.data));
  }
  handleResponse(result);
};

export default function* userLanguageSaga() {
  yield takeLatest(
    types.USER_LANGUAGE_UPDATE.REQUEST,
    handleUserLanguageUpdate
  );
}
