import React, { useEffect, useState } from "react";
import { Alert, Button, Collapse, Table } from "reactstrap";
import { useTranslation } from "react-i18next";
import { RED, WHITE } from "../../constant/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

interface OnlineInventoryListProps {
  data: any;
  downloadReport: Function;
}

const OnlineInventoryList: React.FC<OnlineInventoryListProps> = (props) => {
  const { data, downloadReport } = props;
  const { t } = useTranslation();
  const [reportData, setReportData] = useState<any>([]);
  useEffect(() => {
    setReportData(data);
  }, [data]);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {reportData?.available?.length === 0 &&
        reportData?.unavailable?.length === 0 && (
          <Alert color="info">{t("common.noRecords")}</Alert>
        )}

      {(reportData?.available?.length > 0 ||
        reportData?.unavailable?.length > 0) && (
        <div className="d-flex justify-content-between">
          <h5>{t("reports.onlineInventoryReport")}</h5>
          <div className="col-right">
            <Button
              size="sm"
              color="info"
              onClick={() => {
                downloadReport("onlineInventoryReport");
              }}
            >
              <FontAwesomeIcon icon={faDownload} color={WHITE} />
            </Button>
          </div>
        </div>
      )}
      {reportData?.available?.length > 0 && (
        <section className="mt-3">
          <Table bordered striped>
            <thead>
              <tr className="bg-light">
                <th className="w-24">{t("recipe.recipeName")}</th>
                <th className="w-13">{t("storage.storagePlace")}</th>
                <th className="w-13">{t("common.quantity")}</th>
                <th className="w-21">{t("reports.outputQunatityUnit")}</th>
                <th className="w-19">{t("reports.storedAt")}</th>
                <th className="w-10">{t("reports.expirationDate")}</th>
              </tr>
            </thead>
            <tbody>
              {reportData?.available?.map((row: any) => {
                return row?.locations?.map((r: any, index: number) => {
                  return (
                    <tr>
                      {index === 0 && (
                        <td rowSpan={row?.locations.length}>
                          {row.recipeName}
                        </td>
                      )}
                      <td>{r.location}</td>
                      <td>{r.quantity}</td>
                      <td>
                        {r.storageOutputQuantity} {r.storageOutputUnitName}
                      </td>
                      <td>{r.createdAt}</td>
                      <td>
                        {r.expiringAt !== "" &&
                        new Date(r.expiringAt) < new Date() ? (
                          <span style={{ color: RED }}>{r.expiringAt}</span>
                        ) : (
                          r.expiringAt
                        )}
                      </td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </Table>
        </section>
      )}
      {reportData?.unavailable?.length > 0 && (
        <section className="mt-3 mb-5">
          <h6 onClick={toggle} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon
              className="bf-accordion-arrow mr-2"
              icon={isOpen ? faChevronDown : faChevronRight}
            />{" "}
            {t("common.unavailableItems")}
          </h6>
          <Collapse isOpen={isOpen}>
            <Table bordered striped>
              <tbody>
                {reportData?.unavailable?.map((row: any) => {
                  return (
                    <tr key={row.uuid}>
                      <td>{row.recipeName}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Collapse>
        </section>
      )}
    </>
  );
};

export default OnlineInventoryList;
