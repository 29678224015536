export interface HomeState {
  hasAccess: boolean;
  isFetching: boolean;
  isEverythingLoaded: boolean;
  restaurantDetails: any;
}

export const HomeInitState = {
  hasAccess: false,
  isFetching: true,
  isEverythingLoaded: false,
  restaurantDetails: {},
};
