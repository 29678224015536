import '../../styles/user-verification.scss';

import React, { Component } from 'react';

import UserVerificationComponent from '../../components/user-verification/user-verification';

class UserVerificationPage extends Component {
    render() {
        return (
            <div className="userverification-style">
                <UserVerificationComponent/>
            </div>
        );
    }
}
export default UserVerificationPage;
