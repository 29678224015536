import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import commonService from "../../services/common.service";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import mobiscroll from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { weekdays } from "moment";

export default function ServiceSetAddRooms(props: any) {
  const { t, i18n } = useTranslation();
  const [isSubmitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [days, setDays] = useState([]);
  const [startTime, setStartTime] = useState("00:00:00");
  const [endTime, setEndTime] = useState("00:00:00");
  const [rooms, setRooms] = useState([]);
  const [errorMsg, setError] = useState("");

  useEffect(() => {
    setStartTime(props.formData?.startTime);
    setEndTime(props.formData?.endTime);
    let rooms =
      Object.keys(props.roomList).length > 0
        ? props.roomList
            .filter(
              (item: any) =>
                props.formData?.rooms?.findIndex(
                  (room: any) => room === item.uuid
                ) > -1
            )
            .map((element: any) => {
              return {
                label: commonService.applyLocalization(
                  "restaurant",
                  "name",
                  element?.locales
                )["name"],
                value: element.uuid,
              };
            })
        : {
            label: "",
            value: "",
          };

    let days =
      Object.keys(props.weekDays).length > 0
        ? props.weekDays
            .filter(
              (item: any) =>
                props.formData?.weekDays?.findIndex(
                  (day: any) => Number(day) === item.id
                ) > -1
            )
            .map((element: any) => {
              return {
                label: element.day,
                value: element.id,
              };
            })
        : {
            label: "",
            value: "",
          };
    setDays(days);
    setRooms(rooms);
    if (!!props.formData?.isEdit) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }
    setError("");
  }, [props.formData]);
  const getRoomOptions = () => {
    return Object.keys(props.roomList).length > 0
      ? props.roomList?.map((room: any) => {
          return {
            value: room.uuid,
            label: commonService.applyLocalization(
              "restaurant",
              "name",
              room?.locales
            )["name"],
          };
        })
      : [];
  };

  const getDaysOptions = () => {
    return Object.keys(props.weekDays).length > 0
      ? props.weekDays?.map((day: any) => {
          return {
            value: day.id,
            label: day.day,
          };
        })
      : [];
  };

  const handleChange = (data: any) => {
    setSubmitBtnDisabled(true);
    setRooms(data);
    if (data?.length > 0 && days?.length > 0) {
      setSubmitBtnDisabled(false);
    }
  };

  const handleDayChange = (data: any) => {
    setSubmitBtnDisabled(true);
    setDays(data);
    if (data?.length > 0 && rooms?.length > 0) {
      setSubmitBtnDisabled(false);
    }
  };

  const onTimeClicked = (e: any, name: string) => {
    let time = e.valueText;
    let otherTime = name === "startTime" ? endTime : startTime;
    setSubmitBtnDisabled(true);
    if (days?.length > 0 && rooms?.length > 0 && time !== otherTime) {
      setSubmitBtnDisabled(false);
    }
    if (name === "startTime") {
      setStartTime(e.valueText);
    } else {
      setEndTime(e.valueText);
    }
  };

  const handleSubmit = () => {
    if (startTime > endTime || startTime === endTime) {
      setError(t("toast.endTimeNotLTstartTime"));
      return;
    }
    let time = [
      {
        room: rooms.map((room: any) => room.value),
        start_time: startTime,
        end_time: endTime,
        week_days: days.map((day: any) => Number(day.value)),
        uuid: props.formData?.uuid,
      },
    ];
    props.handleTimeSubmit(props.formData?.isEdit ? "edit" : "add", {
      time,
    });
  };

  return (
    <React.Fragment>
      <Modal isOpen={props.isRoomModal}>
        <ModalHeader toggle={props.handleCancel} className="modal-header">
          {props.title}
        </ModalHeader>
        <ModalBody>
          <Form className="bf-add-timing">
            <FormGroup className="mb-0">
              <Label for="room.room">
                {t("room.room")}
                <span className="text-danger">*</span>
              </Label>
              <Select
                options={getRoomOptions()}
                isMulti
                onChange={handleChange}
                value={rooms}
                name="vat"
              />
            </FormGroup>
            <FormGroup className="mb-0 mt-2">
              <Label>
                {t("time.startTime")} <span className="text-danger">*</span>
              </Label>
              <div className="w-100 bf-time-container">
                <mobiscroll.Time
                  ref={props.setRef}
                  value={startTime}
                  timeFormat="HH:ii:ss"
                  onSet={(e: any) => {
                    onTimeClicked(e, "startTime");
                  }}
                  display={"bubble"}
                ></mobiscroll.Time>
              </div>
            </FormGroup>
            <FormGroup className="mt-2">
              <Label>
                {t("time.endTime")} <span className="text-danger">*</span>
              </Label>
              <div className="w-100 bf-time-container">
                <mobiscroll.Time
                  ref={props.setEndTimeRef}
                  value={endTime}
                  timeFormat="HH:ii:ss"
                  display={"bubble"}
                  onSet={(e: any) => {
                    onTimeClicked(e, "endTime");
                  }}
                ></mobiscroll.Time>
              </div>
            </FormGroup>

            <FormGroup className="mt-2">
              <Label>
                {t("common.selectDays")} <span className="text-danger">*</span>
              </Label>
              <Select
                isMulti
                options={getDaysOptions()}
                onChange={handleDayChange}
                value={days}
                name="days"
              />
            </FormGroup>
            <div className="bf-font-size-12 text-danger text-center">
              {errorMsg}
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.handleCancel}>
            {t("common.cancel")}
          </Button>
          <Button
            color="info"
            onClick={handleSubmit}
            disabled={isSubmitBtnDisabled}
          >
            {t("common.save")}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
