import "../../../styles/navigation-header.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Collapse } from "reactstrap";

import commonService from "../../../services/common.service";
import HeaderCard from "../header-card/header-card";
import { USER_RIGHTS } from "../../../constant/constant";
import RestaurantHeaderCard from "../restaurant-header-card/restaurant-header-card";
import { faCog } from "@fortawesome/free-solid-svg-icons";

export default function HeaderCollapseCard(props: any) {
  let hasRestaurantManageAccess: any = false;
  if (typeof props?.hasAccess !== "undefined") {
    hasRestaurantManageAccess = props.hasAccess;
  } else {
    hasRestaurantManageAccess = commonService.hasPermissionToAccess(
      USER_RIGHTS.MANAGE_RESTAURANT,
      props?.uuid
    );
  }
  return (
    <React.Fragment>
      <Collapse isOpen={props.isOpen} navbar style={{ paddingTop: "1rem" }}>
        <RestaurantHeaderCard
          uuid={props.uuid}
          concept={props.concept}
          staff={props.staff}
          operate={props.operate}
          support={props.support}
          home={props.home}
          hasAccess={hasRestaurantManageAccess}
        />
        <div
          className="settings-icon b-cursor-p d-inline-block"
          onClick={props.goToSettings}
        >
          <FontAwesomeIcon
            style={{
              color: `${props.settings === "settings" ? "#8fd5e0" : "#FFFFFF"}`,
              fontSize: 35,
              marginTop: 8,
              marginBottom: 8,
            }}
            icon={faCog}
          />
        </div>

        <HeaderCard
          restaurantName={props.restaurantName}
          RESTAURANT={props.RESTAURANT}
          modalToggle={props.modalToggle}
          goToProfile={props.goToProfile}
        />
      </Collapse>
    </React.Fragment>
  );
}
