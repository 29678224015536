import '../../styles/registration.scss';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Button, Col, Container, Form, Input, InputGroup, Label, Row } from 'reactstrap';

import smartToniLogin from '../../assets/images/logo/smartToniLogin.png';
import { RegistrationInitState, RegistrationState } from '../../models/registration.model';
import { registrationRequest } from '../../redux/actions/registration.action';
import validationService from '../../services/validation.service';
import LoaderComponent from '../loader/loader';

class RegistrationComponent extends Component<any> {
    state: RegistrationState;
    constructor(props: any) {
        super(props);
        this.state = RegistrationInitState;
        this._setFunctionBindings();
    }
    render() {
        const isLoggedIn = this.state.validate;
        let button;

        if (!!isLoggedIn) {
            button = <InputGroup ><Label className="validation">Please enter valid email address</Label></InputGroup>;
        } else {
            button = '';
        }
        return (
            <div>
                <LoaderComponent display={!!this.state.isFetching} />
                <Container>
                    <Row>
                        <Col><img className="img-home-fluid" src={smartToniLogin} alt="Logo" /></Col>
                    </Row>
                    <Row>
                        <Col className="registration-text"><label>Welcome! Please Register.</label></Col>
                    </Row>
                    <Row className="registration-form-container">
                        <Col>
                            <Form>
                                <InputGroup className="mb-3">
                                    <Label className="required-star-registration-style">*</Label>
                                    <Input type="text" value={this.state.first_name} required name="first_name" autoComplete="first_name"
                                        placeholder="First Name" onChange={this.handleChange} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <Label className="required-star-registration-style">*</Label>
                                    <Input type="text" value={this.state.last_name} required name="last_name" autoComplete="last_name"
                                        placeholder="Last Name" onChange={this.handleChange} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <Label className="required-star-registration-style">*</Label>
                                    <Input disabled={!!this.props.location.state.email ? true : false} type="text" value={this.state.email}
                                        required name="email" autoComplete="email" placeholder="Enter Email" onChange={this.handleChange} />
                                </InputGroup>
                                {button}
                                <InputGroup className="mb-3">
                                    <Label className="required-star-registration-style">*</Label>
                                    <Input type="password" value={this.state.password} required name="password" autoComplete="password"
                                        placeholder="Password" onChange={this.handleChange} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <Label className="required-star-registration-style">*</Label>
                                    <Input type="select" value={this.state.country} required name="country" id="countrySelect"
                                        onChange={this.handleCountryChange}>
                                        <option>Switzerland</option>
                                        <option>Germany</option>
                                        <option>India</option>
                                        <option>USA</option>
                                        <option>Israel</option>
                                    </Input>
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <Button onClick={this.regisrationSubmit} className="registration-button" block
                                        disabled={!this.state.first_name || !this.state.last_name || !this.state.email
                                            || !this.state.password || !this.state.country_code
                                            || !validationService.validateEmail(this.state.email)}>Register</Button>
                                </InputGroup>
                                <Link to="/">
                                    <Button className="link-text" color="link"><FontAwesomeIcon icon={faArrowLeft} /> Back to Login</Button>
                                </Link>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    componentDidMount() {
        if (!!this.props.location.state.email) {
            this.setState({
                email: this.props.location.state.email
            })
        }
    }

    UNSAFE_componentWillReceiveProps(newProps: any) {
        this.setState({
            isFetching: newProps.isFetching
        });
    }

    private _setFunctionBindings(): void {
        this.handleChange = this.handleChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.regisrationSubmit = this.regisrationSubmit.bind(this);
        this._setStates = this._setStates.bind(this);
    }

    private _setCountryStates(value: string): void {
        switch (value) {
            case 'Switzerland':
                this._setStates('country_code', 'ch');
                this._setStates('country', 'Switzerland');
                break;
            case 'Germany':
                this._setStates('country_code', 'de');
                this._setStates('country', 'Germany');
                break;
            case 'India':
                this._setStates('country_code', 'in');
                this._setStates('country', 'India');
                break;
            case 'USA':
                this._setStates('country_code', 'us');
                this._setStates('country', 'USA');
                break;
            case 'Israel':
                this._setStates('country_code', 'il');
                this._setStates('country', 'Israel');
                break;
            default:
                break;
        }
    }

    private _setProfileStates(name: string, value: string): void {
        switch (name) {
            case 'first_name':
                this._setStates('first_name', value);
                break;
            case 'last_name':
                this._setStates('last_name', value);
                break;
            case 'email':
                if (!!validationService.validateEmail(value)) {
                    this._setStates('email', value);
                    this._setStates('validate', false);
                } else {
                    this._setStates('email', value);
                    this._setStates('validate', true);
                }
                this._setStates('email', value);
                break;
            case 'password':
                this._setStates('password', value);
                break;
            default:
                break;
        }
    }

    private _setStates(name: string, value: any): void {
        this.setState({ [name]: value });
    }

    public regisrationSubmit(): void {
        this.props.registration({
            country_code: this.state.country_code,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            password: this.state.password,
            type: this.props.location.state.type,
            token: this.props.location.state.token
        })
    }

    public handleChange(event: any): void {
        this._setProfileStates(event.target.name, event.target.value)
    }

    public handleCountryChange(event: any): void {
        this._setCountryStates(event.target.value)
    }
}

const mapStateToProps: any = (state: any) => {
    return {
        data: state.registration.data,
        isFetching: state.registration.isFetching,
        failure: state.registration.failure,
        isAuthenticated: state.registration.isAuthenticated
    };
};

const mapDispatchToProps: object = (dispatch: any) => {
    return {
        registration: (credentials: any) => {
            dispatch(registrationRequest(credentials));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps)(withRouter(RegistrationComponent));
