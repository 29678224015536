import "../../styles/login.scss";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class LogoutPage extends Component<any, any> {
  componentDidMount() {
    this.clearUserSession();
  }
  clearUserSession = () => {
    localStorage.clear();
    this.props.history.push("/");
  };
  render() {
    return <></>;
  }
}
export default withRouter(LogoutPage);
