import '../../styles/forgotpassword.scss';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Button, Col, Container, Form, Input, InputGroup, Label, Row } from 'reactstrap';

import smartToniLogin from '../../assets/images/logo/smartToniLogin.png';
import { ForgotPasswordInitState, ForgotPasswordState } from '../../models/forgotpassword.model';
import { forgotpasswordRequest } from '../../redux/actions/forgotpassword.action';
import validationService from '../../services/validation.service';
import LoaderComponent from '../loader/loader';
import { getCorporateSettings } from "../../services/restaurant.service";
import { PUBLIC_REQUEST } from "../../config/api.config";
import handleResponse from "../../services/response.service";

class ForgotpasswordComponent extends Component<any> {
  state: ForgotPasswordState;
  constructor(props: any) {
    super(props);
    this.state = ForgotPasswordInitState;
    this._setFunctionBindings();
  }
  render() {
    const isLoggedIn = this.state.validate;
    let emailValidation;

    if (!!isLoggedIn) {
      emailValidation = (
        <InputGroup>
          <Label className="validation">Please enter valid email address</Label>
        </InputGroup>
      );
    } else {
      emailValidation = "";
    }
    return (
      <div
        className="forgotpassword-style"
        style={
          this.state.corporateColor !== ""
            ? { background: this.state.corporateColor }
            : {}
        }
      >
        <LoaderComponent display={!!this.state.isFetching} />
        <Container>
          <Row>
            <Col>
              <img
                className="img-home-fluid"
                src={this.state.restaurantLogo}
                alt="Logo"
              />
            </Col>
          </Row>
          <Row>
            <Col className="forgotpassword-text">
              <label>Welcome! Please Enter Email.</label>
            </Col>
          </Row>
          <Row className="forgotpassword-form-container">
            <Col>
              <Form>
                <InputGroup className="mb-3">
                  <Input
                    required
                    type="text"
                    placeholder="Enter Email"
                    autoComplete="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleEmailInputChange}
                  />
                </InputGroup>
                {emailValidation}
                <Button
                  className="forgotpasswordButton"
                  onClick={this.forgotpasswordSubmit}
                  block
                  disabled={!validationService.validateEmail(this.state.email)}
                  style={
                    this.state.corporateColor !== ""
                      ? { backgroundColor: this.state.corporateColor }
                      : {}
                  }
                >
                  Forgot password
                </Button>
                <Link
                  to={
                    this.state.shopLogin
                      ? `/restaurant/${this.props.match.params.uuid}/shoplogin`
                      : "/login"
                  }
                >
                  <Button className="link-text" color="link">
                    <FontAwesomeIcon icon={faArrowLeft} /> Back to Login
                  </Button>
                </Link>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    this._setStates("isFetching", newProps.isfetching);
    if (
      !newProps.isfetching &&
      newProps.data &&
      !!newProps.data.data &&
      !!newProps.data.data.status
    ) {
      this.props.history.push("/");
    }
  }

  componentDidMount() {
    this.setState({
      restaurantLogo: smartToniLogin,
    });
    this.initShopLogin();
  }

  private initShopLogin() {
    if (this.props.match.params?.uuid) {
      this.setState({
        shopLogin: true,
      });
      getCorporateSettings(PUBLIC_REQUEST, this.props?.match?.params.uuid).then(
        (response: any) => {
          const responseData: any = handleResponse(response);
          if (responseData.ok) {
            if (responseData.data?.corporate_color !== "") {
              this._setStates(
                "corporateColor",
                responseData.data?.corporate_color
              );
            }
            if (responseData.data?.restaurant_logo !== "") {
              this._setStates(
                "restaurantLogo",
                responseData.data?.restaurant_logo.thumbnail
              );
            }
          }
        }
      );
    }
  }

  private _setFunctionBindings(): void {
    this.handleEmailInputChange = this.handleEmailInputChange.bind(this);
    this.forgotpasswordSubmit = this.forgotpasswordSubmit.bind(this);
  }

  public handleEmailInputChange(event: any): void {
    if (!!validationService.validateEmail(event.target.value)) {
      this.setState({
        email: event.target.value,
        validate: false,
      });
    } else {
      this.setState({
        email: event.target.value,
        validate: true,
      });
    }
  }

  public forgotpasswordSubmit(): void {
    this.props.forgot({
      email: this.state.email,
    });
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }
}

const mapStateToProps: any = (state: any) => {
    return {
        data: state.forgotpassword.data,
        isfetching: state.forgotpassword.isFetching,
        failure: state.forgotpassword.failure
    };
};

const mapDispatchToProps: object = (dispatch: any) => {
    return {
        forgot: (credentials: any) => {
            dispatch(forgotpasswordRequest(credentials));
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps)(withRouter(ForgotpasswordComponent));
