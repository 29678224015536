import React, { Component } from "react";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import "../../../app/app.scss";
import { createModal } from "react-modal-promise";

import i18next from "i18next";
import { Translation } from "react-i18next";

export const OpenDeleteModal = async (data?: any) => {
  return new Promise(async (resolve, reject) => {
    let res = await DeleteModal(data);
    if (res === "ok") {
      resolve("Delete ok");
    } else {
      reject("Delete rejected");
    }
  });
};

class myDeleteModal extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.state = {};
  }

  private _setFunctionBindings() {
    this.close = this.close.bind(this);
    this.ok = this.ok.bind(this);
  }

  private close() {
    this.props.close(null);
  }

  private ok() {
    this.props.close("ok");
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen}>
        <ModalBody>
          <div className="app-row-padding">
            <div style={{ padding: 10, textAlign: "center" }}>
              <h6>
                {!!this.props?.title ? (
                  this.props.title
                ) : (
                  <Translation>
                    {(t, { i18n }) => t("questions.delete")}
                  </Translation>
                )}
              </h6>
            </div>
            <div style={{ padding: 0, textAlign: "center" }}>
              <Button
                color="secondary"
                style={{ margin: 5 }}
                onClick={this.close}
              >
                <Translation>{(t, { i18n }) => t("common.cancel")}</Translation>
              </Button>
              <Button color="info" style={{ margin: 5 }} onClick={this.ok}>
                {!!this.props?.saveButton ? (
                  this.props.saveButton
                ) : (
                  <Translation>{(t, { i18n }) => t("common.ok")}</Translation>
                )}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const DeleteModal = createModal(myDeleteModal);
