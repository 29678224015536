import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function tablesRequest(credentials: object): ActionModel {
  return {
    type: types.TABLES.REQUEST,
    credentials,
  };
}

export function tablesSuccess(data: object): ActionModel {
  return {
    type: types.TABLES.SUCCESS,
    data,
  };
}

export function tablesFailure(err: object): ActionModel {
  return {
    type: types.TABLES.FAILURE,
    err,
  };
}

export function tableAdd(credentials: object): ActionModel {
  return {
    type: types.TABLE_ADD.REQUEST,
    credentials,
  };
}

export function tableAddSuccess(data?: object): ActionModel {
  return {
    type: types.TABLE_ADD.SUCCESS,
    data,
  };
}

export function tableAddFailure(err: object): ActionModel {
  return {
    type: types.TABLE_ADD.FAILURE,
    err,
  };
}

export function tableRemove(credentials: object): ActionModel {
  return {
    type: types.TABLE_REMOVE.REQUEST,
    credentials,
  };
}

export function tableRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.TABLE_REMOVE.SUCCESS,
    data,
  };
}

export function tableRemoveFailure(err: object): ActionModel {
  return {
    type: types.TABLE_REMOVE.FAILURE,
    err,
  };
}

export function tableUpdate(credentials: object): ActionModel {
  return {
    type: types.TABLE_UPDATE.REQUEST,
    credentials,
  };
}

export function tableUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.TABLE_UPDATE.SUCCESS,
    data,
  };
}

export function tableUpdateFailure(err: object): ActionModel {
  return {
    type: types.TABLE_UPDATE.FAILURE,
    err,
  };
}
