import React from "react";
import { withRouter, useParams } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import LabelPrint from "../../components/printer/label-print";
import { locationPrintLabel } from "../../services/storage.service";

const LocationLabelPrintPage: React.FC = (props: any) => {
  const { uuid, storageType, storageUuid, templateUuid } = useParams<{
    uuid: string;
    storageUuid: string;
    storageType: string;
    templateUuid: string;
  }>();
  const { t } = useTranslation();
  const payload = {
    restaurantuuid: uuid,
    storageType: storageType,
    storageUuid: storageUuid,
    data: {
      template_uuid: templateUuid,
    },
  };
  return <LabelPrint payload={payload} printFn={locationPrintLabel} />;
};

export default withTranslation()(withRouter(LocationLabelPrintPage));
