import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  InventoryFilterSettingsState,
  InventoryFilterSettingsInitState,
} from "../../models/inventory-filter-settings.model";
import LoaderComponent from "../../components/loader/loader";
import NavigationRightComponent from "../../components/navigation/navigation-right/navigation-right";
import SettingsNavComponent from "../../components/navigation/navigation-left/settings-navigation/settings-navigation";
import SettingsHeaderComponent from "../../components/navigation/navigation-header/settings-header/settings-header";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import InventoryFilterItemRow from "../../components/inentory-filter-settings/inventory-filter-item-row";
import { getRestaurantItemMetaDetails } from "../../services/restaurant.detail.service";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import handleResponse from "../../services/response.service";
import commonService from "../../services/common.service";
import { servicesetRequest } from "../../redux/actions/serviceset.action";
import { getRestaurantSuppliers } from "../../services/supplier.restaurants.service";
import _ from "lodash";
import {
  settingsAdd,
  settingsRequest,
} from "../../redux/actions/stations.action";

class InventoryFilterSettingsPage extends Component<any> {
  state: InventoryFilterSettingsState;
  restaurantUuid: string = this.props.match.params.uuid;
  itemList: any = [
    {
      key: "recipeIngredient",
      label: this.props.t("common.recipeOrIngredient"),
    },
    {
      key: "location",
      label: this.props.t("common.location"),
    },
    {
      key: "supplier",
      label: this.props.t("supplier.supplier"),
    },
    {
      key: "station",
      label: this.props.t("station.station"),
    },
    {
      key: "label",
      label: this.props.t("label.label"),
    },
    {
      key: "serviceSet",
      label: this.props.t("serviceSet.serviceSet"),
    },
  ];
  constructor(props: any) {
    super(props);
    this.state = InventoryFilterSettingsInitState;
  }

  componentDidMount() {
    document.body.classList.add("light-theme");
    this.props.dispatchActions.getSettings({
      restaurantuuid: this.props.match.params.uuid,
    });
    this.getServiceSetList();
    this.getSuppliers();
    this.loadMetaDetails();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      !_.isEqual(
        prevProps.storeProps.serviceSets,
        this.props.storeProps.serviceSets
      )
    ) {
      this.setFormData();
    }
    if (
      !_.isEqual(
        prevProps.storeProps.settings?.inventory_filter_settings,
        this.props.storeProps.settings?.inventory_filter_settings
      )
    ) {
      this.setFormData();
    }
  }

  getDropdown = (itemData: any) => {
    const options: any = [
      {
        label: this.props.t("common.select"),
        value: "",
      },
    ];
    if (Object.keys(itemData).length === 0) return [];
    _.forEach(itemData, (item: any) => {
      options.push({
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          item.locales
        )["name"],
        value: item.uuid,
      });
    });
    return options;
  };

  getItemActions = () => {
    return {
      handleChange: this.handleChange,
    };
  };

  getMetadata = (area: string) => {
    const itemData: any = {
      defaultItem: "",
      optionList: [],
      formData: this.state.formData?.[area] || {},
    };
    switch (area) {
      case "recipeIngredient":
        delete itemData.defaultItem;
        break;
      case "location":
        itemData.optionList = this.getUpdatedLocations();
        break;
      case "label":
        itemData.optionList = this.getDropdown(this.state.labels);
        break;
      case "serviceSet":
        itemData.optionList = this.getDropdown(
          this.props.storeProps.serviceSets
        );
        break;
      case "supplier":
        itemData.optionList = this.getDropdown(this.state.suppliers);
        break;
      case "station":
        itemData.optionList = this.getDropdown(this.state.stations);
        break;
    }
    if (area === "recipeIngredient") {
      delete itemData.defaultItem;
    }

    return itemData;
  };

  getServiceSetList = () => {
    this.props.dispatchActions.getServiceSet({
      restaurantuuid: this.props.match.params.uuid,
    });
  };

  getSuppliers = () => {
    const recipeId: string = this.props.match.params.recipeuuid;
    const restaurantId: string = this.props.match.params.uuid;
    const payload = {
      credentials: {
        recipeuuid: recipeId,
        restaurantuuid: restaurantId,
      },
    };
    getRestaurantSuppliers(RESTAURANT_REQUEST, payload).then((data: any) => {
      const responseData: any = handleResponse(data);
      if (responseData?.ok) {
        this.setState({
          suppliers: responseData.data,
        });
      }
    });
  };

  getStorageDropDowns = (storages: any, position: number = 0) => {
    const options: Array<any> = [];
    const prefix: string = "-".repeat(position + 1);
    _.forEach(storages, (item: any) => {
      options.push({
        label: prefix + " " + item.name,
        value: item.uuid,
      });
      if (item?.storageracks?.length > 0) {
        const storages: Array<any> = this.getStorageDropDowns(
          item.storageracks,
          position + 1
        );
        options.push(...storages);
      } else if (item?.places?.length > 0) {
        const storages: Array<any> = this.getStorageDropDowns(
          item.places,
          position + 1
        );
        options.push(...storages);
      }
    });
    return options;
  };

  getUpdatedLocations = () => {
    const options: Array<any> = [
      {
        label: this.props.t("common.select"),
        value: "",
      },
    ];
    _.forEach(this.state.locations, (item: any) => {
      options.push({
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          item.locales
        )["name"],
        value: item.uuid,
      });
      if (item?.storages?.length > 0) {
        const storages: Array<any> = this.getStorageDropDowns(item.storages);
        options.push(...storages);
      }
    });
    return options;
  };

  handleChange = (data: any, elementId: string, area: string) => {
    const formData = { ...this.state.formData };
    formData[area] = { ...(formData?.[area] || {}) };
    if (elementId === "default") {
      formData[area][elementId] = [...formData[area]?.[elementId]];

      formData[area][elementId] = data;
    } else {
      formData[area][elementId] = !formData[area]?.[elementId];
    }

    this.setState({
      formData: formData,
    });
  };

  loadMetaDetails = () => {
    const payload: any = {
      credentials: {
        items: "storage,label,room,station",
        restaurantuuid: this.restaurantUuid,
      },
    };

    getRestaurantItemMetaDetails(RESTAURANT_REQUEST, payload)
      .then((response: any) => {
        const responseData: any = handleResponse(response);
        if (responseData.ok) {
          const { room, storage } = response.data;
          const rooms: any = {};

          room.forEach((r: any) => {
            rooms[r.uuid] = r;
            const name: string = commonService
              .applyLocalization("restaurant", "name", r.locales)
              ["name"].toLowerCase();
            rooms[r.uuid]["nameLower"] = name.toLowerCase();
            rooms[r.uuid]["name"] = name;
          });
          storage.forEach((st: any) => {
            if (!rooms[st.room_uuid]?.["storages"]) {
              rooms[st.room_uuid]["storages"] = [];
            }
            rooms[st.room_uuid]["storages"].push(st);
          });

          this.setState(
            {
              labels: responseData.data.label,
              locations: rooms,
              stations: responseData.data.station,
            },
            this.setFormData
          );
        }
        this.showLoader(false);
      })
      .catch((error: any) => this.showLoader(false));
  };

  saveSettings = () => {
    const formData: any = _.cloneDeep(this.state.formData);

    const inventorySettings: any = {};
    _.forEach(formData, (item: any, key: string) => {
      if (item?.default?.length && item.default.length > 0)
        item["default"] = item.default.map((d: any) => d.value);
      else delete item?.default;
      inventorySettings[key] = item;
    });

    let settings: any = {
      inventory_filter_settings: inventorySettings,
    };
    this.props.dispatchActions.updateSettings({
      restaurantuuid: this.props.match.params.uuid,
      data: settings,
    });
  };

  setFormData = () => {
    const formData: any = {};
    const inventoryFilterSettings: any = {
      ...(this.props.storeProps.settings?.inventory_filter_settings || {}),
    };
    this.itemList.forEach((item: any) => {
      formData[item.key] = {
        pinned:
          typeof inventoryFilterSettings?.[item.key]?.pinned !== "undefined"
            ? inventoryFilterSettings?.[item.key]?.pinned
            : false,
        enable:
          typeof inventoryFilterSettings?.[item.key]?.enable !== "undefined"
            ? inventoryFilterSettings?.[item.key]?.enable
            : true,
        default: null,
      };

      const metaData: any = this.getMetadata(item.key);
      const defaultOption: any = metaData?.optionList?.filter((option: any) => {
        const defaultValue: string | Array<any> =
          inventoryFilterSettings?.[item.key]?.default || [];
        if (typeof defaultValue === "string") {
          return option.value === defaultValue;
        } else {
          return defaultValue?.includes(option.value);
        }
      });
      if (defaultOption) formData[item.key]["default"] = defaultOption;
    });

    this.setState({
      formData: formData,
    });
  };

  showLoader = (status: boolean = true) => {
    this.setState({ isFetching: status });
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <LoaderComponent
          display={this.state.isFetching || this.props.storeProps.isFetching}
        />
        <div className="container-fluid">
          <SettingsHeaderComponent settings={"settings"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <SettingsNavComponent
                display={"inventory-filter-settings"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <div className="white-box mb-3">
                <h4>{t("inventory.inventoryFilterSettings")}</h4>

                {this.itemList?.map((item: any) => (
                  <InventoryFilterItemRow
                    key={item.key}
                    itemId={item.key}
                    metaData={this.getMetadata(item.key)}
                    label={item.label}
                    actions={this.getItemActions()}
                  />
                ))}

                <div className="d-flex justify-content-end">
                  <Button color="info" onClick={this.saveSettings}>
                    {t("common.save")}
                  </Button>{" "}
                </div>
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.serviceset.isFetching ||
    state.settings.isFetching ||
    state.settingsadd.isFetching;
  let failure =
    state.serviceset.failure ||
    state.settings.failure ||
    state.settingsadd.failure;

  return {
    storeProps: {
      failure: failure,
      isFetching: isFetching,
      serviceSets: state.serviceset.data,
      settings: state.settings.data,
    },
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    dispatchActions: {
      getServiceSet: (credentials: any) => {
        dispatch(servicesetRequest(credentials));
      },
      getSettings: (credentials: any) => {
        dispatch(settingsRequest(credentials));
      },
      updateSettings: (credentials: any) => {
        dispatch(settingsAdd(credentials));
      },
    },
  };
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(InventoryFilterSettingsPage))
);
