import "../../../../styles/navigation-header.scss";

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Collapse, DropdownItem, Navbar, NavbarToggler } from "reactstrap";
import smartToni from "../../../../assets/images/logo/smartToni.png";
import {
  NavHeader,
  NavHeaderInitState,
} from "../../../../models/profile.model";
import { restaurantRequest } from "../../../../redux/actions/restaurant.action";
import commonService from "../../../../services/common.service";
import HeaderCollapseCard from "../../../card-components/header-collapse-card/header-collapse-card";
import HeaderWithRestaurantCard from "../../../card-components/header-with-restaurant-card/header-with-restaurant-card";
import LogOutModalCard from "../../../card-components/logout-card/logout-card";
import RestaurantNavComponent from "../../navigation-left/restaurant-navigation/restaurant-navigation";
import { setRestaurantLanguage } from "../../../../config/lang.config";
import { withTranslation } from "react-i18next";
import {
  setFreshChatLanguage,
  setTagsForChannels,
  setTagsForFaq,
} from "../../../../app/freshChat";
import { chatInitRequest } from "../../../../redux/actions/chat.action";

let restaurantName: any;
class ConceptHeaderComponent extends Component<any> {
  state: NavHeader;
  constructor(props: any) {
    super(props);
    this.state = NavHeaderInitState;
    this._setFunctionBindings();
  }

  render() {
    const { t } = this.props;
    let RESTAURANT = this.drpdownItem(this.state.restaurant);
    let param = this.props.match.params;
    return (
      <React.Fragment>
        <LogOutModalCard
          logoutModalToggle={this.logoutModalToggle}
          modalToggle={this.modalToggle}
          open={this.state.open}
          focusAfterClose={this.state.focusAfterClose}
        />
        <HeaderWithRestaurantCard
          goToSettings={this.goToSettings}
          settings={this.props.settings}
          restaurantName={restaurantName}
          RESTAURANT={RESTAURANT}
          modalToggle={this.modalToggle}
          goToProfile={this.goToProfile.bind(this)}
          uuid={this.props.match.params.uuid}
          display={this.props.dis}
          concept={this.props.concept}
          staff={this.props.staff}
          support={this.props.support}
          home={this.props.home}
          hasAccess={this.props.hasAccess}
          redirectionURL={this.props.match.url}
        />

        {/* Device below 991px */}

        <div className="hide-left-min b-header-xs-wrapper ">
          <Navbar color="faded">
            <div className="header-align">
              <NavbarToggler onClick={this.leftToggle} />
              <img
                className="img-icon-fluid compact-logo"
                src={smartToni}
                alt=""
              />
              <NavbarToggler onClick={this.rightToggle} />
            </div>
            <Collapse
              isOpen={this.state.leftOpen}
              navbar
              style={{ paddingTop: "1rem" }}
            >
              <RestaurantNavComponent
                restaurantuuid={this.props.match.params.uuid}
                display={Object.keys(param)[1]}
              />
            </Collapse>
            <HeaderCollapseCard
              isOpen={this.state.rightOpen}
              restaurantName={restaurantName}
              RESTAURANT={RESTAURANT}
              modalToggle={this.modalToggle}
              goToProfile={this.goToProfile.bind(this)}
              uuid={this.props.match.params.uuid}
              concept={this.props.concept}
              staff={this.props.staff}
              hasAccess={this.props.hasAccess}
              home={this.props.home}
              goToSettings={this.goToSettings}
              settings={this.props.settings}
            />
          </Navbar>
        </div>
      </React.Fragment>
    );
  }

  public drpdownItem(data: any) {
    let restaurant;
    if (!!data && data.length > 0) {
      let restuarantList: any = [];
      data.forEach((element: any) => {
        if (element.copy_status == undefined || element.copy_status === 3) {
          restuarantList.push(element);
        }
      });
      restaurant = restuarantList.map((res: any, index: number) => {
        if (res.uuid === this.props.match.params.uuid) {
          restaurantName = res;
        }
        return (
          <DropdownItem
            onClick={this.getRestaurantId.bind(this, res)}
            key={index}
          >
            {
              commonService.applyLocalization(
                "restaurant",
                "name",
                res.locales
              )["name"]
            }
          </DropdownItem>
        );
      });
      return restaurant;
    }
  }

  componentDidMount() {
    this.props.chatInitRequest();
    setFreshChatLanguage();
    this.props.getRestaurant();
    let tags: Array<any> = ["concept"];
    setTagsForChannels(tags);
    setTagsForFaq(tags);
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    const { restaurant } = this.props;
    if (restaurant !== newProps.restaurant) {
      setRestaurantLanguage(this.props.match.params.uuid);
      const restaurants: any = JSON.parse(
        localStorage.getItem("RESTAURANT_DETAILS") || ""
      );
      const [restDetails] = restaurants.filter(
        (res: any) => res.uuid === this.props.match.params.uuid
      );
      localStorage.setItem(
        "ACTIVE_RESTAURANT_DETAILS",
        JSON.stringify(restDetails)
      );
    }
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
      if (!!newProps.restaurant && !newProps.isFetching && !newProps.failure) {
        this._setStates("restaurant", newProps.restaurant);
      }
    }
  }

  private _setFunctionBindings(): void {
    this.rightToggle = this.rightToggle.bind(this);
    this.leftToggle = this.leftToggle.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
    this.logoutModalToggle = this.logoutModalToggle.bind(this);
    this.goToSettings = this.goToSettings.bind(this);
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  public rightToggle(): void {
    this.setState((prevState: any) => ({
      rightOpen: !prevState.rightOpen,
      leftOpen: false,
    }));
  }

  public leftToggle(): void {
    this.setState((prevState: any) => ({
      leftOpen: !prevState.leftOpen,
      rightOpen: false,
    }));
  }

  public modalToggle(): void {
    this.setState((prevState: any) => ({
      open: !prevState.open,
    }));
  }

  public logoutModalToggle(): void {
    commonService.clearUserData();
    setTimeout(() => {
      this.props.history.push("/");
    }, 800);
    this.setState((prevState: any) => ({
      open: !prevState.open,
    }));
  }

  public getRestaurantId(event: any): void {
    window.open(`/restaurant/${event.uuid}/home`, "_blank");
  }

  public goToSettings() {
    this.props.history.push(
      `/restaurant/${this.props.match.params.uuid}/units`
    );
  }

  public goToProfile(): void {
    window.open(`/profile`, "_blank");
  }
}

const mapStateToProps: any = (state: any) => {
  return {
    restaurant: state.restaurant.data,
    isFetching: state.restaurant.isFetching,
    failure: state.restaurant.failure,
    chatInit: state.chatInit.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getRestaurant: () => {
      dispatch(restaurantRequest());
    },
    chatInitRequest: () => {
      dispatch(chatInitRequest());
    },
  };
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(ConceptHeaderComponent))
);
