import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "../../../styles/units.scss";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { unitConversionAddModalProps } from "../../../models/units.model";
import UnitItemCard from "../units-card/UnitItemCard";
import { RESTAURANT_REQUEST } from "../../../config/api.config";
import { addUnits, addConversion } from "../../../services/units.service";
export default function UnitConversionAddModal(
  props: unitConversionAddModalProps
) {
  const { t, i18n } = useTranslation();
  const unit = props.allUnits?.find((u) => u.uuid === props.recipeOutputUnit);
  const conversionsList: Array<any> =
    unit?.conversions?.map((unit: any) => unit.uuid) || [];

  const getConversionFactor = (conversion: any) => {
    let conversionData: any = conversion;
    let conversionFactor: number = conversionData.conversion_factor;
    if (conversionData.uuid === conversionData.toUnit)
      conversionFactor = 1 / conversionFactor;
    return String(conversionData.conversion_factor).includes(".999")
      ? Number(Math.round(conversionFactor))
      : Number(conversionFactor);
  };

  const saveConversion = (
    formData: {
      quantity?: number;
      conversion_factor: string;
      from_unit: string;
      grossWeight: string;
      grossWeightUnit: any;
      to_unit: string;
      uuid?: string;
      sku?: string;
      barcode: string;
    },
    fromUnit?: {
      name: string;
      symbol: string;
    }
  ) => {
    if (fromUnit && fromUnit.symbol !== "") {
      saveRecipeUnit(formData, fromUnit);
      return;
    }

    let payLoad: any = {
      credentials: {
        restaurantuuid: props.restaurantuuid,
        data: {
          quantity: formData?.quantity,
          conversion_factor: formData.conversion_factor,
          from_unit: formData.from_unit,
          to_unit: formData.to_unit,
          recipe_uuid: props.recipeUuid,
          sku: formData.sku,
          barcode: formData.barcode,
        },
      },
    };
    if (formData.grossWeight) {
      const grossWeight =
        Number(formData.grossWeight) *
        formData.grossWeightUnit.conversionFactor;
      payLoad.credentials.data["gross_weight"] = grossWeight;
    }

    addConversion(RESTAURANT_REQUEST, payLoad).then((response: any) => {
      props.unitConversionSaveCallback(response);
    });
  };

  const saveRecipeUnit = (
    formData: {
      conversion_factor: string;
      from_unit: string;
      grossWeight: string;
      to_unit: string;
      uuid?: string;
    },
    fromUnit: {
      name: string;
      symbol: string;
    }
  ) => {
    const conversionData: any = formData;
    const payLoad = {
      credentials: {
        restaurantuuid: props.restaurantuuid,
        data: {
          locales: {
            [props.restaurantLangCode]: {
              name: fromUnit.name,
              symbol: fromUnit.symbol,
            },
          },
          recipe_uuid: props.recipeUuid,
        },
      },
    };
    addUnits(RESTAURANT_REQUEST, payLoad).then((response: any) => {
      conversionData.from_unit = response.data.data.uuid;
      saveConversion(conversionData);
    });
  };

  return (
    <Modal isOpen={props.isModalOpen} className="uc-modal">
      <ModalHeader
        toggle={() => props.setIsModalOpen(false)}
        className="modal-header"
      >
        {t("unit.addUnitConversion")}
      </ModalHeader>
      <ModalBody>
        <UnitItemCard
          key={"newCard_" + props.recipeOutputUnit}
          relatedUnits={props.relatedUnits}
          allUnits={props.allUnits}
          area={props.area}
          baseWeightUnit={props.settings?.base_weight_unit}
          restaurantCode={props.settings?.settings?.restaurant_code}
          cardItemData={{ type: "newCard" }}
          cardItemId={"newCard_" + props.recipeOutputUnit}
          conversionsList={conversionsList}
          getBaseUnitOptions={props.getBaseUnitOptions}
          getConversionFactor={getConversionFactor}
          getUnitName={() => {}}
          handleUnitUnitEdit={() => {}}
          isNewCardItem={true}
          onDeleteCard={() => {}}
          onConversionCancel={() => {}}
          onNewUnitSelection={() => {}}
          onSaveCard={saveConversion}
          parentCardItemSelectionData={props.cardItemSelectionData}
          saveButton={false}
          tempUnit={{}}
          unit={unit}
          isAddOnly={true}
          recipeOutputUnit={props.recipeOutputUnit}
          recipeOutputQuantity={props.recipeOutputQuantity}
        />
      </ModalBody>
    </Modal>
  );
}
