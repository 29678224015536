import React, { Component } from "react";

import TranslationComponent from "../../components/translation/translation";

class TranslationPage extends Component {
  render() {
    return <TranslationComponent />;
  }
}
export default TranslationPage;
