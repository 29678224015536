import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Select, { components } from "react-select";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { allRecipeRequest } from "../../../redux/actions/recipe.action";
import { RecipeHelper } from "../../../helpers/recipeHelper";
import { SupplierHelper } from "../../../helpers/supplierHelper";
import RecipeIngredientsModal from "../recipe/recipe-ingredients-modal";

const ItemSelect = (props: any) => {
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [otherRecipesLists, setOtherRecipesLists] = useState<Array<any>>([]);
  const [supplierList, setSupplierList] = useState<Array<any>>([]);
  const [isRecipeSelectionModalOpen, setRecipeSelectionModalOpen] =
    useState<boolean>(false);
  const { Option } = components;
  const { match, value, handleChange, labelOptions } = props;

  useEffect(() => {
    props.recipeAll({
      restaurantuuid: match.params.uuid,
    });
    SupplierHelper.getSuppliers(match.params.uuid, setSupplierList);
  }, []);

  useEffect(() => {
    setOtherRecipesLists(
      RecipeHelper.generateOtherRecipeList(
        props.allrecipes,
        supplierList,
        match.params.recipeuuid
      )
    );
  }, [props.allrecipes, supplierList]);

  useEffect(() => {
    if (otherRecipesLists.length > 0) {
      const item = otherRecipesLists.find(
        (recipe: any) => recipe.uuid === value
      );

      if (item) setSelectedItem(item);
    }
  }, [value, otherRecipesLists]);

  const IconOption = (props: any) => (
    <Option {...props}>
      <div className="d-flex align-items-center b-cursor-p">
        <span className="mr-2"> {props.data.icon}</span>
        {props.data.label}
      </div>
    </Option>
  );

  const toggleRecipeSelectionModal = () => {
    setRecipeSelectionModalOpen(!isRecipeSelectionModalOpen);
  };

  const handleSelection = (option: any): void => {
    handleChange({ target: { name: "replacementItem", value: option.value } });
  };

  return (
    <>
      <div className="bf-title d-flex justify-content-between">
        <div className="flex-fill">
          <Select
            name="replacementItem"
            options={otherRecipesLists}
            autosize={true}
            menuPlacement="auto"
            menuPosition="fixed"
            styles={{
              control: (provided) => {
                return {
                  ...provided,
                };
              },
              menu: (provided) => {
                return {
                  ...provided,
                  color: "#000",
                  fontWeight: 400,
                  fontSize: "16px",
                };
              },
              placeholder: (provided) => {
                return {
                  ...provided,
                  fontWeight: 400,
                  fontSize: "16px",
                };
              },
            }}
            onChange={handleSelection}
            value={selectedItem}
            components={{ Option: IconOption }}
          />
        </div>
        <div
          className={"card-item-button pointer-cursor mt-2 ml-2"}
          onClick={() => toggleRecipeSelectionModal()}
        >
          <FontAwesomeIcon icon={faSearch} pull="right" />
        </div>
      </div>

      <RecipeIngredientsModal
        title="common.selectReplacementArticle"
        allIngredients={otherRecipesLists}
        isOpen={isRecipeSelectionModalOpen}
        labelOptions={labelOptions}
        saveRecipeSelection={handleSelection}
        toggleModal={toggleRecipeSelectionModal}
      />
    </>
  );
};

const mapStateToProps: any = (state: any) => {
  let isFetching = state.allrecipe.isFetching || state.labels.isFetching;
  let failure = state.allrecipe.failure || state.labels.failure;
  return {
    isFetching: isFetching,
    failure: failure,
    allrecipes: state.allrecipe.data,
    labels: state.labels.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    recipeAll: (credentials: any) => {
      dispatch(allRecipeRequest(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ItemSelect))
);
