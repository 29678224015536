import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";
import { GRAY, GREEN } from "../../../constant/constant";
import "../../../styles/transport.scss";

export default function TransportCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <React.Fragment>
      <tbody>
        <tr style={{ textTransform: "capitalize" }}>
          <td className="table-row">
            <Label>
              {props.locales.from_type} {": "}{" "}
              {props.getNames(props.locales.from_uuid)}
            </Label>
          </td>
          <td className="table-row">
            <Label>
              {props.locales.to_type} {": "}{" "}
              {props.getNames(props.locales.to_uuid)}
            </Label>
          </td>
          <td className="table-row">
            <Label>
              {parseInt(props.locales.transport_mode) === 1
                ? "No transport"
                : parseInt(props.locales.transport_mode) === 2
                ? "Immediate Transport"
                : "Use Transport Synergy"}
            </Label>
          </td>
          <td className="table-row">
            <Label className="w-100">
              {props.locales?.responsible_station
                ? props.getNames(props.locales.responsible_station)
                : "-"}
            </Label>
          </td>
          <td className="table-row">
            <span
              onClick={props.setEdit}
              className="pointer-cursor"
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.edit")}
            >
              <FontAwesomeIcon
                style={{ color: GREEN, fontSize: 20 }}
                icon={faEdit}
              />
            </span>
            <span
              style={{ margin: 10 }}
              onClick={props.setDelete}
              className="pointer-cursor"
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.delete")}
            >
              <FontAwesomeIcon
                style={{ color: GRAY, fontSize: 20 }}
                icon={faTrashAlt}
              />
            </span>
          </td>
        </tr>
      </tbody>
    </React.Fragment>
  );
}
