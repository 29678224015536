import { call, put, takeLatest } from "redux-saga/effects";

import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  getOpenTransactionQltyFailure,
  getOpenTransactionQltySuccess,
  getPaymentTransactionQltyFailure,
  getPaymentTransactionQltySuccess,
  getRestaurantClosureFailure,
  getRestaurantClosureSuccess,
  getRoomClosureFailure,
  getRoomClosureSuccess,
  printGuestGroupReportFailure,
  printGuestGroupReportSuccess,
} from "../redux/actions/report.action";
import * as types from "../redux/actions/types";
import report from "../services/report.service";
import handleResponse from "../services/response.service";

const openTransactionQltyCall = (params: any) =>
  report.getOpenTransactionQlty(RESTAURANT_REQUEST, params);
const paymentTransactionQltyCall = (params: any) =>
  report.getPaymentTransactionQlty(RESTAURANT_REQUEST, params);
const restaurantClosureCall = (params: any) =>
  report.getRestaurantClosure(RESTAURANT_REQUEST, params);
const roomClosureCall = (params: any) =>
  report.getRoomClosure(RESTAURANT_REQUEST, params);
const printGuestGroupCall = (params: any) =>
  report.printGuestGroupReport(RESTAURANT_REQUEST, params);

function* handleOpenTransactionQltyRequest(params: object) {
  try {
    let result: any;
    result = yield call(openTransactionQltyCall, params);
    yield handleOpenTransactionQltyRequest200(result);
  } catch (error) {
    throw error;
  }
}

const handleOpenTransactionQltyRequest200 =
  function* handleOpenTransactionQltyRequest200(result: any) {
    if (!result) {
      return;
    }
    if (result.status === 200) {
      yield put(getOpenTransactionQltySuccess(result.data));
    } else {
      yield put(getOpenTransactionQltyFailure(result.data));
      handleResponse(result);
    }
  };

function* handlePaymentTransactionQltyRequest(params: object) {
  try {
    let result: any;
    result = yield call(paymentTransactionQltyCall, params);
    yield handlePaymentTransactionQltyRequest200(result);
  } catch (error) {
    throw error;
  }
}

const handlePaymentTransactionQltyRequest200 =
  function* handleOpenTransactionQltyRequest200(result: any) {
    if (!result) {
      return;
    }
    if (result.status === 200) {
      yield put(getPaymentTransactionQltySuccess(result.data));
    } else {
      yield put(getPaymentTransactionQltyFailure(result.data));
      handleResponse(result);
    }
  };

function* handleRestaurantClosureRequest(params: object) {
  try {
    let result: any;
    result = yield call(restaurantClosureCall, params);
    yield handleRestaurantClosureRequest200(result);
  } catch (error) {
    throw error;
  }
}

const handleRestaurantClosureRequest200 =
  function* handleRestaurantClosureRequest200(result: any) {
    if (!result) {
      return;
    }
    if (result.status === 200) {
      yield put(getRestaurantClosureSuccess(result.data));
    } else {
      yield put(getRestaurantClosureFailure(result.data));
      handleResponse(result);
    }
  };

function* handleRoomClosureRequest(params: object) {
  try {
    let result;
    result = yield call(roomClosureCall, params);
    yield handleRoomClosureCall200(result);
  } catch (error) {
    throw error;
  }
}

const handleRoomClosureCall200 = function* handleRoomClosureCall200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(getRoomClosureSuccess(result.data));
  } else {
    yield put(getRoomClosureFailure(result.data));
  }
  handleResponse(result);
};

function* handlePrintGuestGroupRequest(params: object) {
  try {
    let result;
    result = yield call(printGuestGroupCall, params);
    yield handlePrintGuestGroupCall200(result);
  } catch (error) {
    throw error;
  }
}

const handlePrintGuestGroupCall200 = function* handlePrintGuestGroupCall200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(printGuestGroupReportSuccess(result.data));
  } else {
    yield put(printGuestGroupReportFailure(result.data));
  }
  handleResponse(result);
};

export default function* reportSaga() {
  yield takeLatest(
    types.REPORT_OPEN_TRANSACTION_QUALITY.REQUEST,
    handleOpenTransactionQltyRequest
  );
  yield takeLatest(
    types.REPORT_PAYMENT_TRANSACTION_QUALITY.REQUEST,
    handlePaymentTransactionQltyRequest
  );
  yield takeLatest(
    types.REPORT_RESTAURANT_CLOSURE.REQUEST,
    handleRestaurantClosureRequest
  );
  yield takeLatest(types.REPORT_ROOM_CLOSURE.REQUEST, handleRoomClosureRequest);
  yield takeLatest(
    types.REPORT_GUEST_GROUP_PRINT.REQUEST,
    handlePrintGuestGroupRequest
  );
}
