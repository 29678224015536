import i18next from "i18next";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
  PrinterMessageInitState,
  PrinterMessageState,
} from "../../models/printer.message.model";
import {
  printerMessageRemove,
  printerMessageRemoveAll,
  printerMessageRequest,
} from "../../redux/actions/printer.message.action";
import { printerProtocolAdd } from "../../redux/actions/printer.protocol.action";
import "../../styles/printer.scss";
import AddAsProtocolCard from "../card-components/add-as-protocol-card/add-as-protocol-card";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import PrinterMessageCard from "../card-components/printer-message-card/printer-message-card";
import LoaderComponent from "../loader/loader";
import SettingsHeaderComponent from "../navigation/navigation-header/settings-header/settings-header";
import SettingsNavComponent from "../navigation/navigation-left/settings-navigation/settings-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";

class PrinterMessageComponent extends Component<any> {
  state: PrinterMessageState;
  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.state = PrinterMessageInitState;
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <SettingsHeaderComponent settings={"settings"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <SettingsNavComponent
                display={"message"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <Link
                to="#"
                color="info"
                className="printer-add"
                onClick={
                  this.state.printermessage.length > 0
                    ? this.deleteAllPrinterMessageToggle
                    : undefined
                }
              >
                {this.state.printermessage.length > 0
                  ? this.props.t("common.deleteAllMessage")
                  : ""}
              </Link>
              {this.printerList(this.state.printermessage)}
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>

        {/* Delete Printer Message Modal*/}

        <DeleteModalCard
          isOpen={this.state.deletemodal}
          isFetching={!!this.state.isFetching}
          okDelete={this.okDeletePrinterMessage}
          cancelDelete={this.deletePrinterMessageToggle}
        />

        {/* Delete All Printer Message Modal*/}

        <DeleteModalCard
          isOpen={this.state.deleteallmodal}
          isFetching={!!this.state.isFetching}
          okDelete={this.okDeleteAllPrinterMessage}
          cancelDelete={this.deleteAllPrinterMessageToggle}
        />

        {/*  Add As Protocol Modal */}

        <AddAsProtocolCard
          state={this.state}
          printerProtocolToggle={this.printerProtocolToggle}
          handleChange={this.handleChange}
          savePrinterProtocolEdit={this.savePrinterProtocolEdit}
        />
      </div>
    );
  }

  public printerList(printerData: any) {
    let printer;
    if (!!printerData && printerData.length > 0) {
      printer = printerData.map((locales: any, i: number) => {
        return (
          <div className="white-box mb-3 shadow" key={i}>
            <h4>{this.props.t("printer.printerMessage")}</h4>
            <PrinterMessageCard
              printer={locales}
              setPrinterMessageDelete={this.setPrinterMessageDelete.bind(
                this,
                locales
              )}
              setPrinterProtocolEditable={this.setPrinterProtocolEditable.bind(
                this,
                locales
              )}
            ></PrinterMessageCard>
          </div>
        );
      });
      return printer;
    } else {
      return (
        <div className="white-box mb-3">
          <h4>{this.props.t("printer.printerMessage")}</h4>
          <div style={{ width: "100%", textAlign: "center" }}>
            {this.props.t("common.noRecords")}
          </div>
        </div>
      );
    }
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.printermessage({ restaurantuuid: this.props.match.params.uuid });
    this._setStates("restaurantuuid", this.props.match.params.uuid);
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
    }
    if (!!this.state.isUpdated && !newProps.failure && !newProps.isFetching) {
      this.props.printermessage({
        restaurantuuid: this.props.match.params.uuid,
      });
      this._setStates("isUpdated", false);
    }
    if (
      !!newProps.printermessage &&
      !newProps.isFetching &&
      !newProps.failure
    ) {
      this._setStates("printermessage", newProps.printersmessage);
    }
  }

  // Function binding

  private _setFunctionBindings(): void {
    this.deletePrinterMessageToggle =
      this.deletePrinterMessageToggle.bind(this);
    this.okDeletePrinterMessage = this.okDeletePrinterMessage.bind(this);
    this.okDeleteAllPrinterMessage = this.okDeleteAllPrinterMessage.bind(this);
    this.deleteAllPrinterMessageToggle =
      this.deleteAllPrinterMessageToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.printerProtocolToggle = this.printerProtocolToggle.bind(this);
    this.savePrinterProtocolEdit = this.savePrinterProtocolEdit.bind(this);
  }

  public handleChange(e: any): void {
    this._setStates(e.target.name, e.target.value);
  }

  private _setStates(name: string, value: any) {
    this.setState({ [name]: value });
  }

  public printerProtocolToggle(): void {
    this.setState((prevState: any) => ({
      protocolmodal: !prevState.protocolmodal,
    }));
  }

  public setPrinterProtocolEditable(event: any): void {
    this.setState({
      uuid: event.uuid,
      name: event.name,
      format: event.message,
      printermessageuuid: event.uuid,
    });
    this.printerProtocolToggle();
  }

  public savePrinterProtocolEdit(): void {
    this.props.addPrinterProtocol({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
      data: {
        name: this.state.name,
        format: this.state.format,
        printer_message_uuid: this.state.printermessageuuid,
      },
    });
    this._setStates("isUpdated", true);
    this.printerProtocolToggle();
  }

  public setPrinterMessageDelete(event: any): void {
    this._setStates("uuid", event.uuid);
    this.deletePrinterMessageToggle();
  }

  public deletePrinterMessageToggle(): void {
    this.setState((prevState: any) => ({
      deletemodal: !prevState.deletemodal,
    }));
  }

  public okDeletePrinterMessage(): void {
    this.props.removePrinterMessage({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
    });
    this._setStates("isUpdated", true);
    this.deletePrinterMessageToggle();
  }

  public deleteAllPrinterMessageToggle(): void {
    this.setState((prevState: any) => ({
      deleteallmodal: !prevState.deleteallmodal,
    }));
  }

  public okDeleteAllPrinterMessage(): void {
    this.props.removeAllPrinterMessage({
      restaurantuuid: this.state.restaurantuuid,
    });
    this._setStates("isUpdated", true);
    this.deleteAllPrinterMessageToggle();
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.printermessage.isFetching ||
    state.printermessageremove.isFetching ||
    state.printermessageremoveall.isFetching ||
    state.printerprotocoladd.isFetching;
  let failure =
    state.printermessage.failure ||
    state.printermessageremove.failure ||
    state.printermessageremoveall.failure ||
    state.printerprotocoladd.failure;
  return {
    printersmessage: state.printermessage.data,
    isFetching: isFetching,
    failure: failure,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    printermessage: (credentials: any) => {
      dispatch(printerMessageRequest(credentials));
    },
    removePrinterMessage: (credentials: any) => {
      dispatch(printerMessageRemove(credentials));
    },
    removeAllPrinterMessage: (credentials: any) => {
      dispatch(printerMessageRemoveAll(credentials));
    },
    addPrinterProtocol: (credentials: any) => {
      dispatch(printerProtocolAdd(credentials));
    },
  };
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(PrinterMessageComponent))
);
