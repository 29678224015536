import { call, put, takeLatest } from "redux-saga/effects";

import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  getRoomDetailsFailure,
  getRoomDetailsSuccess,
  qrCodeAddFailure,
  qrCodeAddSuccess,
  qrCodeFailure,
  qrCodeRemoveFailure,
  qrCodeRemoveSuccess,
  qrCodeSuccess,
  qrCodeUpdateFailure,
  qrCodeUpdateSuccess,
} from "../redux/actions/qrcode.action";
import * as types from "../redux/actions/types";
import qrCode from "../services/qrcode.service";
import handleResponse from "../services/response.service";

const qrCodesCall = (params: any) => qrCode.qrCodes(RESTAURANT_REQUEST, params);
const addQrCodeCall = (params: any) =>
  qrCode.addQrCode(RESTAURANT_REQUEST, params);
const getRoomDetailsCall = (params: any) =>
  qrCode.getRoomDetails(RESTAURANT_REQUEST, params);
const removeQrCodeCall = (params: any) =>
  qrCode.removeQrCode(RESTAURANT_REQUEST, params);
const updateQrCodeCall = (params: any) =>
  qrCode.updateQrCode(RESTAURANT_REQUEST, params);

function* handleQrCodeRequest(params: object) {
  try {
    let result: any;
    result = yield call(qrCodesCall, params);
    yield handleQrCodeRequest200(result);
  } catch (error) {
    throw error;
  }
}

const handleQrCodeRequest200 = function* handleQrCodeRequest200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(qrCodeSuccess(result.data));
  } else {
    yield put(qrCodeFailure(result.data));
    handleResponse(result);
  }
};

function* handleQrCodeAdd(params: object) {
  try {
    let result;
    result = yield call(addQrCodeCall, params);
    yield handleQrCodeAdd200(result);
  } catch (error) {
    throw error;
  }
}

const handleQrCodeAdd200 = function* handleQrCodeAdd200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(qrCodeAddSuccess(result.data));
  } else {
    yield put(qrCodeAddFailure(result.data));
  }
  handleResponse(result);
};

function* handleGetRoomDetails(params: object) {
  try {
    let result;
    result = yield call(getRoomDetailsCall, params);
    yield handleGetRoomDetails200(result);
  } catch (error) {
    throw error;
  }
}

const handleGetRoomDetails200 = function* handleGetRoomDetails200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(getRoomDetailsSuccess(result.data));
  } else {
    yield put(getRoomDetailsFailure(result.data));
  }
  handleResponse(result);
};

function* handleQrCodeRemove(params: object) {
  try {
    let result;
    result = yield call(removeQrCodeCall, params);
    yield handleQrCodeRemove200(result);
  } catch (error) {
    throw error;
  }
}

const handleQrCodeRemove200 = function* handleQrCodeRemove200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(qrCodeRemoveSuccess(result.data));
  } else {
    yield put(qrCodeRemoveFailure(result.data));
  }
  handleResponse(result);
};
function* handleQrCodeUpdate(params: object) {
  try {
    let result;
    result = yield call(updateQrCodeCall, params);
    yield handleQrCodeUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleQrCodeUpdate200 = function* handleQrCodeUpdate200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(qrCodeUpdateSuccess(result.data));
  } else {
    yield put(qrCodeUpdateFailure(result.data));
  }
  handleResponse(result);
};

export default function* qrCodeSaga() {
  yield takeLatest(types.QRCODE.REQUEST, handleQrCodeRequest);
  yield takeLatest(types.QRCODE_ADD.REQUEST, handleQrCodeAdd);
  yield takeLatest(types.QRCODE_GET_ROOM_DTLS.REQUEST, handleGetRoomDetails);
  yield takeLatest(types.QRCODE_REMOVE.REQUEST, handleQrCodeRemove);
  yield takeLatest(types.QRCODE_UPDATE.REQUEST, handleQrCodeUpdate);
}
