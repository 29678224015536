import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import * as types from '../redux/actions/types';
import handleResponse from '../services/response.service';
import storageRack from '../services/storage.rack.service';
import {
    storageRackSuccess,
    storageRackFailure,
    storageRackAddSuccess,
    storageRackAddFailure,
    storageRackUpdateSuccess,
    storageRackUpdateFailure,
    storageRackRemoveSuccess,
    storageRackRemoveFailure
} from '../redux/actions/storage.rack.action';

const storageRackCall = (params: any) => storageRack.storageRack(RESTAURANT_REQUEST, params);
const addStorageRackCall = (params: any) => storageRack.addStorageRack(RESTAURANT_REQUEST, params);
const updateStorageRackCall = (params: any) => storageRack.updateStorageRack(RESTAURANT_REQUEST, params);
const removeStorageRackCall = (params: any) => storageRack.removeStorageRack(RESTAURANT_REQUEST, params);

function* handleStorageRackRequest(params: object) {
    try {
        let result;
        result = yield call(storageRackCall, params);     
        yield handleStorageRack200(result);
    } catch (error) {
        throw error;
    }
}

const handleStorageRack200 = function* handleStorageRack200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(storageRackSuccess(result.data));
    } else {
        yield put(storageRackFailure(result.data));
        handleResponse(result);
    }
}

function* handleStorageRackAdd(params: object) {
    try {
        let result;
        result = yield call(addStorageRackCall, params);
        yield handleStorageRackAdd200(result);
    } catch (error) {
        throw error;
    }
}

const handleStorageRackAdd200 = function* handleStorageRackAdd200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(storageRackAddSuccess(result.data));
    } else {
        yield put(storageRackAddFailure(result.data));
    }
    handleResponse(result);
}

function* handleStorageRackUpdate(params: object) {
    try {
        let result;
        result = yield call(updateStorageRackCall, params);
        yield handleStorageRackUpdate200(result);
    } catch (error) {
        throw error;
    }
}

const handleStorageRackUpdate200 = function* handleStorageRackUpdate200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(storageRackUpdateSuccess(result.data));
    } else {
        yield put(storageRackUpdateFailure(result.data));
    }
    handleResponse(result);
}
function* handleStorageRackRemove(params: object) {
    try {
        let result;
        result = yield call(removeStorageRackCall, params);
        yield handleStorageRackRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handleStorageRackRemove200 = function* handleStorageRackRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(storageRackRemoveSuccess(result.data));
    } else {
        yield put(storageRackRemoveFailure(result.data));
    }
    handleResponse(result);
}

export default function* storageRackSaga() {
    yield takeLatest(types.STORAGE_RACK.REQUEST, handleStorageRackRequest);
    yield takeLatest(types.STORAGE_RACK_ADD.REQUEST, handleStorageRackAdd);
    yield takeLatest(types.STORAGE_RACK_UPDATE.REQUEST, handleStorageRackUpdate);
    yield takeLatest(types.STORAGE_RACK_REMOVE.REQUEST, handleStorageRackRemove);
}