import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { GRAY, GREEN } from "../../../constant/constant";
import "../../../styles/printer.scss";

class PrinterCard extends Component<any> {
  render() {
    const { t } = this.props;
    let PROTOCOLNAME = this.protocolList(this.props.printerprotocol);
    return (
      <div className="child-box">
        <Row className="app-row-padding">
          <Col>
            <h6>{this.props.printer.name}</h6>
          </Col>
          <div style={{ float: "right" }}>
            <span
              onClick={this.props.setPrinterEditable}
              className="pointer-cursor"
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.edit")}
            >
              <FontAwesomeIcon
                style={{ color: GREEN, fontSize: 20 }}
                icon={faEdit}
              />
            </span>
            <span
              style={{ margin: 10 }}
              onClick={this.props.setPrinterDelete}
              className="pointer-cursor"
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.delete")}
            >
              <FontAwesomeIcon
                style={{ color: GRAY, fontSize: 20 }}
                icon={faTrashAlt}
              />
            </span>
          </div>
        </Row>
        <Row className="app-row-padding">
          <Col sm={{ size: 2, offset: 1 }} xs="4">
            {t('printer.printerIP')}
          </Col>
          <Col sm="4" xs="4">
            {this.props.printer.printer_ip}
          </Col>
        </Row>
        <Row className="app-row-padding">
          <Col sm={{ size: 2, offset: 1 }} xs="4">
            {t('printer.printerPort')}
          </Col>
          <Col sm="4" xs="4">
            {this.props.printer.printer_port}
          </Col>
        </Row>
        <Row className="app-row-padding">
          <Col sm={{ size: 2, offset: 1 }} xs="4">
            {t('printer.posPort')}
          </Col>
          <Col sm="4" xs="4">
            {this.props.printer.pos_listening_port}
          </Col>
        </Row>
        <Row className="app-row-padding">
          <Col sm={{ size: 2, offset: 1 }} xs="4">
            {t('printer.printerProtocol')}
          </Col>
          <Col sm="4" xs="4">
            <span className="long-text">{PROTOCOLNAME}</span>
          </Col>
        </Row>
      </div>
    );
  }

  public protocolList(protocolData: any): any {
    let protocols;
    if (!!protocolData) {
      // eslint-disable-next-line
      protocols = protocolData.map((protocol: any, i: number) => {
        if (this.props.printer.printer_protocol_uuid === protocol.uuid) {
          return (
            <span key={i} className="long-text">
              {protocol.name}
            </span>
          );
        }
      });
      return protocols;
    }
  }
}
export default withTranslation()(PrinterCard);
