import { EditCacheActionModel, EditCacheEntryModel } from "./models";
import * as types from "./types";


const initialState: EditCacheEntryModel[] = [];

export function editCacheReducer(state = initialState, action: EditCacheActionModel): EditCacheEntryModel[] {
    const index = state.findIndex((element: EditCacheEntryModel) => { return element.id === action.id });
    switch (action.type) {
        case types.EDITCACHE_PUT:
            if (index < 0) {
                return [{id: action.id, data: Object.assign({}, action.data)}, ...state];
            } else {
                let newState = [{id: action.id, data: Object.assign({}, action.data)}, ...state];
                newState.splice(index + 1, 1);
                return newState;
            }
        case types.EDITCACHE_PUT_TAGS:
            if (index < 0) {
                return [{id: action.id, data: Object.assign({}, action.data)}, ...state];
            } else {
                let item = Object.assign({}, state[index]);
                item = Object.assign(item, action.data);
                let newState = [{id: action.id, data: item}, ...state];
                newState.splice(index + 1, 1);
                return newState;
            }
        case types.EDITCACHE_DELETE:
            if (index < 0) {
                return [...state];
            } else {
                let newState = [...state];
                newState.splice(index, 1);
                return newState;
            }
        default:
            return state;
    }
}
