import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { withRouter, useParams } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import PrinterTemplateComponent from "../printer-template/printer-template";
import NavigationRightComponent from "../../components/navigation/navigation-right/navigation-right";
import SettingsHeaderComponent from "../../components/navigation/navigation-header/settings-header/settings-header";
import SettingsNavComponent from "../../components/navigation/navigation-left/settings-navigation/settings-navigation";
import EmailTemplateSelectionCard from "../../components/card-components/email-template/email-template-selection-card";
import { useDispatch, useSelector } from "react-redux";
import {
  settingsAdd,
  settingsAddFailure,
  settingsRequest,
} from "../../redux/actions/stations.action";
import { CILENT_ORDER_EMAIL_TEMPLATE } from "../../constant/constant";

const EmailTemplatesPage: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const { uuid } = useParams<{ uuid: string }>();
  const { t } = useTranslation();
  const [coEmailTemplate, setCoEmailTemplate] = useState<string>("");
  const [state, setState] = useState({
    settings: { data: [] },
  });
  useEffect(() => {
    setState((prevState) => ({ ...prevState, restaurantuuid: uuid }));
    dispatch(settingsRequest({ restaurantuuid: uuid }));
  }, [dispatch, uuid]);
  const settings = useSelector((state: any) => state.settings.data);
  useEffect(() => {
    setCoEmailTemplate(
      settings && settings[CILENT_ORDER_EMAIL_TEMPLATE]
        ? settings[CILENT_ORDER_EMAIL_TEMPLATE]
        : ""
    );
  }, [settings]);
  const handleEmailTemplateChange = (item: any) => {
    setCoEmailTemplate(item.value);
    dispatch(
      settingsAdd({
        restaurantuuid: uuid,
        data: { [CILENT_ORDER_EMAIL_TEMPLATE]: item.value },
      })
    );
  };
  return (
    <div>
      <div className="container-fluid">
        <SettingsHeaderComponent settings={"settings"} />
        <Row className="main light-theme">
          <Col xl={2} lg={3} className="hide-left-max">
            <SettingsNavComponent
              display={"email-templates"}
              restaurantuuid={uuid}
            />
          </Col>
          <Col xl={8} lg={6}>
            <PrinterTemplateComponent templateType="MAIL" />
            <div className="white-box mb-3">
              <h4>{t("email.clientOrderEmailTemplate")}</h4>
              <EmailTemplateSelectionCard
                selectedTemplate={coEmailTemplate}
                selectLabel="email.selectClientOrderEmailTemplate"
                handleEmailTemplateChange={handleEmailTemplateChange}
              />
            </div>
          </Col>
          <Col xl={2} lg={3}>
            <NavigationRightComponent />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withTranslation()(withRouter(EmailTemplatesPage));
