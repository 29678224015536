import { call, put, takeLatest } from "redux-saga/effects";

import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  restaurantAddFailure,
  restaurantAddSuccess,
  restaurantFailure,
  restaurantRemoveFailure,
  restaurantRemoveSuccess,
  restaurantSuccess,
  restaurantUpdateFailure,
  restaurantUpdateSuccess,
} from "../redux/actions/restaurant.action";
import * as types from "../redux/actions/types";
import handleResponse from "../services/response.service";
import restaurant from "../services/restaurant.service";

const restaurantCall = () => restaurant.restaurant(RESTAURANT_REQUEST);
const updateRestaurantCall = (params: any) =>
  restaurant.updateRestaurant(RESTAURANT_REQUEST, params);
const addRestaurantCall = (params: any) =>
  restaurant.addRestaurant(RESTAURANT_REQUEST, params);
const removeRestaurantCall = (params: any) =>
  restaurant.removeRestaurant(RESTAURANT_REQUEST, params);

function* handleRestaurantRequest() {
  try {
    let result;
    result = yield call(restaurantCall);
    yield handleRestaurant200(result);
  } catch (error) {
    throw error;
  }
}
const handleRestaurant200 = function* handleRestaurant200(result: any) {

  if (!result) {
    return;
  }
  if (result.status === 200) {
    let restaurant = result?.data.filter(
      (item: any) => item.uuid === localStorage.getItem("RESTAURANT_ID")
    );
    localStorage.setItem(
      "RESTAURANT_DETAILS",
      JSON.stringify(result?.data)
      );
    
    localStorage.setItem(
      "LANG",
      JSON.stringify(
        restaurant[0]?.languages?.length > 0
          ? restaurant[0]?.languages[0].code
          : "en-gb"
      )
    );
    localStorage.setItem(
      "RESTAURANTLANGUAGE",
      JSON.stringify(!!restaurant[0]?.languages ? restaurant[0]?.languages : [])
    );
    
    yield put(restaurantSuccess(result.data));
  } else {
    yield put(restaurantFailure(result.data));
    handleResponse(result);
  }
};

function* handleRestaurantUpdate(params: object) {
  try {
    let result;
    result = yield call(updateRestaurantCall, params);
    yield handleRestaurantUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleRestaurantUpdate200 = function* handleRestaurantUpdate200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(restaurantUpdateSuccess(result.data));
  } else {
    yield put(restaurantUpdateFailure(result.data));
  }
  handleResponse(result);
};

function* handleRestaurantAdd(params: object) {
  
  try {
    let result;
    result = yield call(addRestaurantCall, params);
    yield handleRestaurantAdd200(result);
  } catch (error) {
    throw error;
  }
}

const handleRestaurantAdd200 = function* handleRestaurantAdd200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(restaurantAddSuccess(result.data));
  } else {
    yield put(restaurantAddFailure(result.data));
  }
  handleResponse(result);
};

function* handleRestaurantRemove(params: object) {
  try {
    let result;
    result = yield call(removeRestaurantCall, params);
    yield handleRestaurantRemove200(result);
  } catch (error) {
    throw error;
  }
}

const handleRestaurantRemove200 = function* handleRestaurantRemove200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(restaurantRemoveSuccess(result.data));
  } else {
    yield put(restaurantRemoveFailure(result.data));
  }
  handleResponse(result);
};
export default function* restaurantSaga() {
  yield takeLatest(types.RESTAURANT.REQUEST, handleRestaurantRequest);
  yield takeLatest(types.RESTAURANT_UPDATE.REQUEST, handleRestaurantUpdate);
  yield takeLatest(types.RESTAURANT_ADD.REQUEST, handleRestaurantAdd);
  yield takeLatest(types.RESTAURANT_REMOVE.REQUEST, handleRestaurantRemove);
}
