import React, { Component } from 'react';

import TransportTaskComponent from '../../components/transport-task/transport-task';

class TransportTaskPage extends Component {

    render() {
        return (
            <TransportTaskComponent/>
        );
    }
}
export default TransportTaskPage;
