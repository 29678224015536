import { call, put, takeLatest } from "redux-saga/effects";

import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  ingredientsAddFailure,
  ingredientsAddSuccess,
  ingredientsFailure,
  ingredientsRemoveFailure,
  ingredientsRemoveSuccess,
  ingredientsSuccess,
  ingredientsUpdateFailure,
  ingredientsUpdateSuccess,
  ingredientDetailRequestFailure,
  ingredientDetailRequestSuccess,
  ingredientCostRequestFailure,
  ingredientCostRequestSuccess,
  ingredientCostUpdateSuccess,
  ingredientCostUpdateFailure,
} from "../redux/actions/ingredients.action";
import * as types from "../redux/actions/types";
import ingredients from "../services/ingredients.service";
import handleResponse from "../services/response.service";

const ingredientsCall = (params: any) =>
  ingredients.ingredients(RESTAURANT_REQUEST, params);
const updateIngredientsCall = (params: any) =>
  ingredients.updateIngredients(RESTAURANT_REQUEST, params);
const addIngredientsCall = (params: any) =>
  ingredients.addIngredients(RESTAURANT_REQUEST, params);
const removeIngredientsCall = (params: any) =>
  ingredients.removeIngredients(RESTAURANT_REQUEST, params);
const getIngredientDetailCall = (params: any) =>
  ingredients.getIngredientDetail(RESTAURANT_REQUEST, params);
const getIngredientCostCall = (params: any) =>
  ingredients.getIngredientCost(RESTAURANT_REQUEST, params);
const updateIngredientCostCall = (params: any) =>
  ingredients.updateIngredientCost(RESTAURANT_REQUEST, params);

function* handleIngredientsRequest(params: object) {
  try {
    let result;
    result = yield call(ingredientsCall, params);
    yield handleIngredients200(result);
  } catch (error) {
    throw error;
  }
}

const handleIngredients200 = function* handleIngredients200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(ingredientsSuccess(result.data));
  } else {
    yield put(ingredientsFailure(result.data));
    handleResponse(result);
  }
};

function* handleIngredientsUpdate(params: object) {
  try {
    let result;
    result = yield call(updateIngredientsCall, params);
    yield handleIngredientsUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleIngredientsUpdate200 = function* handleIngredientsUpdate200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(ingredientsUpdateSuccess(result.data));
  } else {
    yield put(ingredientsUpdateFailure(result.data));
  }
  handleResponse(result);
};

function* handleIngredientsAdd(params: object) {
  try {
    let result;
    result = yield call(addIngredientsCall, params);
    yield handleIngredientsAdd200(result);
  } catch (error) {
    throw error;
  }
}

const handleIngredientsAdd200 = function* handleIngredientsAdd200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(ingredientsAddSuccess(result.data));
  } else {
    yield put(ingredientsAddFailure(result.data));
  }
  handleResponse(result);
};

function* handleIngredientsRemove(params: object) {
  try {
    let result;
    result = yield call(removeIngredientsCall, params);
    yield handleIngredientsRemove200(result);
  } catch (error) {
    throw error;
  }
}

const handleIngredientsRemove200 = function* handleIngredientsRemove200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(ingredientsRemoveSuccess(result.data));
  } else {
    yield put(ingredientsRemoveFailure(result.data));
  }
  handleResponse(result);
};

function* handleIngredientDetail(params: object) {
  try {
    let result;
    result = yield call(getIngredientDetailCall, params);
    yield handleIngredientDetail200(result);
  } catch (error) {
    throw error;
  }
}

const handleIngredientDetail200 = function* handleIngredientDetail200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(ingredientDetailRequestSuccess(result.data));
  } else {
    yield put(ingredientDetailRequestFailure(result.data));
  }
  handleResponse(result);
};

function* handleIngredientCost(params: object) {
  try {
    let result;
    result = yield call(getIngredientCostCall, params);
    yield handleIngredientCost200(result);
  } catch (error) {
    throw error;
  }
}

const handleIngredientCost200 = function* handleIngredientCost200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(ingredientCostRequestSuccess(result.data));
  } else {
    yield put(ingredientCostRequestFailure(result.data));
  }
  handleResponse(result);
};

function* handleUpdateIngredientCost(params: object) {
  try {
    let result;
    result = yield call(updateIngredientCostCall, params);
    yield handleUpdateIngredientCost200(result);
  } catch (error) {
    throw error;
  }
}

const handleUpdateIngredientCost200 = function* handleUpdateIngredientCost200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(ingredientCostUpdateSuccess(result.data));
  } else {
    yield put(ingredientCostUpdateFailure(result.data));
  }
  handleResponse(result);
};
export default function* ingredientsSaga() {
  yield takeLatest(types.INGREDIENTS.REQUEST, handleIngredientsRequest);
  yield takeLatest(types.INGREDIENTS_UPDATE.REQUEST, handleIngredientsUpdate);
  yield takeLatest(types.INGREDIENTS_ADD.REQUEST, handleIngredientsAdd);
  yield takeLatest(types.INGREDIENTS_REMOVE.REQUEST, handleIngredientsRemove);
  yield takeLatest(types.INGREDIENTS_DETAIL.REQUEST, handleIngredientDetail);
  yield takeLatest(types.INGREDIENT_COST.REQUEST, handleIngredientCost);
  yield takeLatest(
    types.INGREDIENT_COST_UPDATE.REQUEST,
    handleUpdateIngredientCost
  );
}
