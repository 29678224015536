import "../../styles/units.scss";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import Select from "react-select";

import AddLanguageCard from "../card-components/add-language/add-language";
import DeleteModalCard from "../card-components/delete-card/delete-card";

import HeadingComponent from "./heading";
import TextInputComponent from "./textInput";
import LoaderComponent from "../loader/loader";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import RecipeComponent from "./recipe";
import { languageRequest } from "../../redux/actions/language.action";
import { labelRequest } from "../../redux/actions/labels.action";
import {
  recipeRequest,
  recipeDetailsRequest,
} from "../../redux/actions/recipe.action";
import { restaurantDetailRequest } from "../../redux/actions/restaurant.detail.action";
import SettingsHeaderComponent from "../navigation/navigation-header/settings-header/settings-header";
import SettingsNavComponent from "../navigation/navigation-left/settings-navigation/settings-navigation";

import { tagsRequest } from "../../redux/actions/tags.action";
import { translationRequest } from "../../redux/actions/translation.action";
import { unitsRequest } from "../../redux/actions/units.action";
import {
  TranslationInitState,
  TranslationState,
} from "../../models/translations.model";
import "./translation.css";
import { withTranslation } from "react-i18next";
import commonService from "../../services/common.service";
import {
  ingredientDetailRequest,
  ingredientsRequest,
} from "../../redux/actions/ingredients.action";
import { RESTAURANT_REQUEST } from "../../config/api.config";

import { stationsRequest } from "../../redux/actions/stations.action";
import { machineRequest } from "../../redux/actions/machine.action";
import { roomRequest } from "../../redux/actions/room.action";
import { paymentProviderRequest } from "../../redux/actions/payment.provider.action";
import { rolesRequest } from "../../redux/actions/roles.action";
import _ from "lodash";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Form,
  Row,
  Table,
} from "reactstrap";
class TranslationComponent extends Component<any> {
  state: TranslationState;
  // options: any =;
  options: any = _.sortBy(
    [
      // { label: this.props.t("common.select"), value: "" },
      { label: this.props.t("recipe.recipe"), value: "recipe" },
      { label: this.props.t("article.article"), value: "ingredient" },
      { label: this.props.t("tag.tag"), value: "tag" },
      { label: this.props.t("unit.unit"), value: "unit" },
      { label: this.props.t("station.station"), value: "station" },
      { label: this.props.t("machine.machine"), value: "machine" },
      { label: this.props.t("label.label"), value: "label" },
      { label: this.props.t("room.room"), value: "room" },
      {
        label: this.props.t("payment.paymentOptions"),
        value: "payment_option",
      },
      { label: this.props.t("role.roles"), value: "roles" },
    ],
    (p: any) => p.label.toLowerCase()
  );
  constructor(props: any) {
    super(props);
    this.state = TranslationInitState;
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.getLanguages();
    this.props.getRestaurantDetail({
      restaurantuuid: this.props.match.params.uuid,
    });
    this.setState({
      selectedCategory: { label: this.props.t("common.select"), value: "" },
      selectedLang:
        this.state.restaurantLang.length > 0 &&
        this.state.restaurantLang[0].code,
    });
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (
      newProps.translation?.status &&
      !newProps.isFetching &&
      this.state.isUpdated
    ) {
      if (this.state.selectedCategory.value === "recipe") {
        this.props.getRecipes({ uuid: this.props.match.params.uuid });
        this.props.recipeDetails({
          restaurantuuid: this.props.match.params.uuid,
          recipeuuid: this.state.recipeuuid,
        });
      } else if (this.state.selectedCategory.value === "ingredient") {
        this.props.getingredients({
          uuid: this.props.match.params.uuid,
        });
        this.props.getIngredientDetail({
          restaurantuuid: this.props.match.params.uuid,
          uuid: this.state.ingredientuuid,
        });
      } else if (this.state.selectedCategory.value === "unit")
        this.props.getUnits({
          restaurantuuid: this.props.match.params.uuid,
        });
      else if (this.state.selectedCategory.value === "station")
        this.props.getStations({ uuid: this.props.match.params.uuid });
      else if (this.state.selectedCategory.value === "machine")
        this.props.getMachine({ uuid: this.props.match.params.uuid });
      else if (this.state.selectedCategory.value === "tag")
        this.props.getTags({
          restaurantuuid: this.props.match.params.uuid,
        });
      else if (this.state.selectedCategory.value === "roles") {
        this.props.getRoleList({
          restaurantuuid: this.props.match.params.uuid,
        });
      }
      this.setState({
        isUpdated: false,
      });
    }
    if (
      !newProps.isFetching &&
      !!newProps.recipeDetail &&
      this.state.selectedCategory.value === "recipe" &&
      !this.state.isUpdated
    ) {
      this.setState(
        {
          selectedItem: JSON.parse(JSON.stringify(newProps.recipeDetail)),
          tasks: newProps.recipeDetail?.recipe_tasks,
        },
        () => {
          this.getSteps();
        }
      );
    }

    if (
      !newProps.isFetching &&
      !!newProps.recipeDetail &&
      this.state.selectedCategory.value === "ingredient" &&
      !this.state.isUpdated
    ) {
      let languages: any = [];
      Object.keys(newProps.recipeDetail.locales || {}).forEach((lang: any) => {
        let isAdditional = newProps.restaurantDetail?.languages.find(
          (item: any) => item.code === lang
        );
        if (!isAdditional) {
          languages.push({
            ...this.props.languages.filter(
              (element: any) => element.code === lang
            )[0],
            isAdditional: true,
          });
        }
      });

      this.setState({
        selectedItem: JSON.parse(JSON.stringify(newProps.ingredientDetail)),
      });
    }

    if (
      !!newProps.restaurantDetail &&
      !newProps.isFetching &&
      !this.state.isUpdated
    ) {
      switch (this.state.selectedCategory.value) {
        case "recipe":
          this.handleLanguages(newProps, newProps.recipeDetail.locales);
          break;
        case "ingredient":
          this.handleLanguages(newProps, newProps.ingredientDetail.locales);
          break;
        default:
          break;
      }
    }
  }

  handleLanguages = (newProps: any, locales: any) => {
    let languages: any = [];
    Object.keys(locales || {}).forEach((lang: any) => {
      let isAdditional = newProps.restaurantDetail?.languages.find(
        (item: any) => item.code === lang
      );
      if (!isAdditional) {
        languages.push({
          ...this.props.languages.filter(
            (element: any) => element.code === lang
          )[0],
          isAdditional: true,
        });
      }
    });
    this.setState({
      isDelete: false,
      restaurantLang: [...newProps.restaurantDetail?.languages, ...languages],
      selectedLang: newProps.restaurantDetail?.languages[0].code,
    });
  };

  handleSelectChange = (e: any) => {
    this.setState({
      selectedCategory: e,
      selectedLang: this.state.restaurantLang[0]?.code,
      showForm: false,
      showList: true,
    });

    switch (e.value) {
      case "recipe":
        this.props.getRecipes({ uuid: this.props.match.params.uuid });
        break;
      case "tag":
        this.props.getTags({ restaurantuuid: this.props.match.params.uuid });
        break;
      case "unit":
        this.props.getUnits({ restaurantuuid: this.props.match.params.uuid });
        break;
      case "station":
        this.props.getStations({ uuid: this.props.match.params.uuid });
        break;
      case "machine":
        this.props.getMachine({ uuid: this.props.match.params.uuid });
        break;
      case "ingredient":
        this.props.getingredients({ uuid: this.props.match.params.uuid });
        break;
      case "label":
        this.props.getLabel({ restaurantuuid: this.props.match.params.uuid });
        break;
      case "room":
        this.props.getRoom({ restaurantuuid: this.props.match.params.uuid });
        break;
      case "payment_option":
        this.props.getPaymentProvider({
          restaurantuuid: this.props.match.params.uuid,
        });
        break;

      case "roles":
        this.props.getRoleList({
          restaurantuuid: this.props.match.params.uuid,
        });
        break;
      default:
        this.setState({ showList: false });
        break;
    }
  };

  handleLangClick = (lang: string) => {
    this.setState({
      selectedLang: lang,
      isDelete: false,
    });
  };

  handleCancel = () => {
    this.setState({
      isDelete: false,
      showForm: false,
      showList: true,
    });
  };

  handleSave = () => {
    let translationArray: any = [];
    if (this.state.selectedCategory.value === "recipe") {
      if (!this.state.isDelete)
        if (
          !this.state.selectedItem.locales[this.state.selectedLang] ||
          this.state.selectedItem.locales[this.state.selectedLang].name === ""
        ) {
          this.setState({
            notValid: true,
          });
          return;
        }
      translationArray.push(
        ...this.state.tasks.map((task: any) => {
          return {
            type: "task",
            uuid: task.uuid,
            locales: task.locales,
          };
        })
      );
      translationArray.push(
        ...this.state.taskSteps.map((step: any) => {
          return {
            type: "step",
            uuid: step.uuid,
            locales: step.locales,
          };
        })
      );
      translationArray.push(
        ...this.state.taskInterventions.map((intervention: any) => {
          return {
            type: "intervention",
            uuid: intervention.uuid,
            locales: intervention.locales,
          };
        })
      );
      translationArray.push({
        type: "recipe",
        uuid: this.state.selectedItem.uuid,
        locales: this.state.selectedItem?.locales,
      });
    }

    switch (this.state.selectedCategory.value) {
      case "ingredient":
        this.handleSaveTranslationData("ingredient", translationArray);
        break;
      case "tag":
        this.handleSaveTranslationData("tag", translationArray);
        break;
      case "unit":
        this.handleSaveTranslationData("unit", translationArray);
        break;
      case "station":
        this.handleSaveTranslationData("station", translationArray);
        break;
      case "machine":
        this.handleSaveTranslationData("machine", translationArray);
        break;
      case "label":
        this.handleSaveTranslationData("label", translationArray);
        break;
      case "room":
        this.handleSaveTranslationData("room", translationArray);
        break;
      case "payment_option":
        this.handleSaveTranslationData("payment_option", translationArray);
        break;

      case "roles":
        this.handleSaveTranslationData("role", translationArray);
        break;
      default:
    }

    this.props.saveTranslation({
      restaurantuuid: this.props.match.params.uuid,
      data: translationArray,
    });
    this.setState({
      isUpdated: true,
      notValid: false,
    });
  };

  handleSaveTranslationData = (item: any, translationArray: any) => {
    if (!this.state.isDelete)
      if (
        !this.state.selectedItem.locales[this.state.selectedLang] ||
        this.state.selectedItem.locales[this.state.selectedLang].name === ""
      ) {
        this.setState({
          notValid: true,
        });
        return;
      }
    translationArray.push({
      type: item,
      uuid: this.state.selectedItem.uuid,
      locales: this.state.selectedItem?.locales,
    });
  };

  handleUpdateClick = (data: any) => {
    this.setState({
      recipeuuid: data.uuid,
      ingredientuuid: data.uuid,
      selectedLang: this.state.restaurantLang[0]?.code,
      showList: !this.state.showList,
      showForm: true,
    });

    switch (this.state.selectedCategory.value) {
      case "recipe":
        this.props.recipeDetails({
          restaurantuuid: this.props.match.params.uuid,
          recipeuuid: data.uuid,
        });
        break;
      case "ingredient":
        this.props.getIngredientDetail({
          restaurantuuid: this.props.match.params.uuid,
          uuid: data.uuid,
        });
        break;
      case "tag":
        this.setState(
          {
            selectedItem: data,
          },
          () => {
            this.handleLanguages(this.props, this.state.selectedItem.locales);
          }
        );
        break;
      case "unit":
        this.setState(
          {
            selectedItem: data,
          },
          () => {
            this.handleLanguages(this.props, this.state.selectedItem.locales);
          }
        );
        break;
      case "station":
        this.setState(
          {
            selectedItem: data,
          },
          () => {
            this.handleLanguages(this.props, this.state.selectedItem.locales);
          }
        );
        break;
      case "machine":
        this.setState(
          {
            selectedItem: data,
          },
          () => {
            this.handleLanguages(this.props, this.state.selectedItem.locales);
          }
        );
        break;
      case "label":
        this.setState(
          {
            selectedItem: data,
          },
          () => {
            this.handleLanguages(this.props, this.state.selectedItem.locales);
          }
        );
        break;
      case "room":
        this.setState(
          {
            selectedItem: data,
          },
          () => {
            this.handleLanguages(this.props, this.state.selectedItem.locales);
          }
        );
        break;
      case "payment_option":
        this.setState(
          {
            selectedItem: data,
          },
          () => {
            this.handleLanguages(this.props, this.state.selectedItem.locales);
          }
        );
        break;

      case "roles":
        this.setState(
          {
            selectedItem: data,
          },
          () => {
            this.handleLanguages(this.props, this.state.selectedItem.locales);
          }
        );
        break;

      default:
        break;
    }
  };

  getSortedItem = (data: any) => {
    let sortedArray: Array<any> = data || [];
    sortedArray = sortedArray?.map((item: any) => {
      item["nameLower"] = commonService
        .applyLocalization("restaurant", "name", item.locales)
        ["name"].toLowerCase();
      return item;
    });
    return _.sortBy(sortedArray, "nameLower");
  };

  getSteps = () => {
    let steps: any = [];
    let interventions: any = [];
    if (this.state.tasks?.length > 0) {
      this.state.tasks.forEach((task: any) => {
        task.task_step.forEach((item: any) => {
          steps = [item, ...steps];
          if (item.interventions?.length > 0) {
            interventions = this.groupIntervention(
              item.interventions,
              interventions,
              steps
            );
          }
        });
        task.interventions.forEach((element: any) => {
          interventions = [element, ...interventions];
          if (element.task_step?.length > 0) {
            steps = this.groupSteps(element.task_step, steps, interventions);
          }
        });
      });
    }
    this.setState({
      taskSteps: steps,
      taskInterventions: interventions,
    });
    return {
      steps: steps,
      interventions: interventions,
    };
  };

  groupSteps = (stepArray: any, steps: any, interventions: any) => {
    stepArray.forEach((item: any) => {
      steps = [item, ...steps];
      if (item.interventions?.length > 0) {
        this.groupIntervention(item.interventions, interventions, steps);
      }
    });

    return steps;
  };

  groupIntervention = (
    interventionArray: any,
    interventions: any,
    steps: any
  ) => {
    interventionArray.forEach((item: any) => {
      interventions = [item, ...interventions];
      if (item.task_step?.length > 0) {
        this.groupSteps(item.task_step, steps, interventions);
      }
    });

    return interventions;
  };

  handleChange = (e: any, id?: string) => {
    e.persist();
    let { selectedItem, selectedLang, tasks, taskSteps, taskInterventions } =
      this.state;
    if (
      e.target.name === "name" ||
      e.target.name === "description" ||
      e.target.name === "symbol" ||
      e.target.name === "printer_name" ||
      e.target.name === "pos_name" ||
      e.target.name === "role_name"
    ) {
      selectedItem.locales[selectedLang] = {
        ...selectedItem.locales[selectedLang],
      };
      selectedItem.locales[selectedLang][e.target.name] = e.target.value;
      this.setState({
        selectedItem: selectedItem,
      });

      if (e.target.name === "name" && !!e.target.value.trim()) {
        this.setState({
          notValid: false,
        });
      }
      if (e.target.name === "symbol" && !!e.target.value.trim()) {
        this.setState({
          notValid: false,
        });
      }
    } else if (e.target.name.includes("task")) {
      tasks.forEach((item: any) => {
        if (item.uuid === id) {
          item.locales[selectedLang] = {
            ...item.locales[selectedLang],
          };
          item.locales[selectedLang][e.target.name.split("_")[1]] =
            e.target.value;
        }
      });
      this.setState({
        tasks: tasks,
      });
    } else if (e.target.name.includes("step")) {
      taskSteps.forEach((item: any) => {
        if (item.uuid === id) {
          item.locales[selectedLang] = {
            ...item.locales[selectedLang],
          };
          item.locales[selectedLang][e.target.name.split("_")[1]] =
            e.target.value;
        }
      });
      this.setState({
        steps: taskSteps,
      });
    } else if (e.target.name.includes("intervention")) {
      taskInterventions.forEach((item: any) => {
        if (item.uuid === id) {
          item.locales[selectedLang] = {
            ...item.locales[selectedLang],
          };
          item.locales[selectedLang][e.target.name.split("_")[1]] =
            e.target.value;
        }
      });
      this.setState({
        taskInterventions: taskInterventions,
      });
    }
  };

  toggleModal = () => {
    this.setState({
      additionalLang: [],
      showModal: !this.state.showModal,
    });
  };

  handleAddLanguage = (val?: any, action?: any) => {
    if (action === "select") {
      val.forEach((item: any) => {
        item.showDelete = true;
        item.isAdditional = true;
      });
      this.setState({
        additionalLang: val,
      });
    } else {
      this.setState({
        restaurantLang: [
          ...this.state.restaurantLang,
          ...this.state.additionalLang,
        ],
        showModal: false,
      });
    }
  };

  handleDeleteClick = () => {
    let { selectedItem, selectedLang, tasks, taskSteps, taskInterventions } =
      this.state;

    let isAdditional = this.state.restaurantLang.findIndex(
      (item: any) => item.isAdditional
    );
    if (this.state.selectedCategory.value === "recipe") {
      if (isAdditional !== -1) {
        selectedItem.locales[selectedLang] = undefined;
        tasks.map((task: any) => {
          task.locales[selectedLang] = undefined;
        });
        taskSteps.map((step: any) => {
          step.locales[selectedLang] = {
            description: "",
          };
        });

        taskInterventions.map((intervention: any) => {
          intervention.locales[selectedLang] = undefined;
        });

        // this.state.restaurantLang.splice(isAdditional, 1);
      } else {
        selectedItem.locales[selectedLang] = {
          name: "",
          description: "",
          pos_name: "",
          printer_name: "",
        };
        tasks.map((task: any) => {
          task.locales[selectedLang] = {
            name: "",
            description: "",
          };
        });
        taskSteps.map((step: any) => {
          step.locales[selectedLang] = {
            description: "",
          };
        });

        taskInterventions.map((intervention: any) => {
          intervention.locales[selectedLang] = {
            description: "",
          };
        });
      }
      this.setState({
        confirmDelete: false,
        notValid: false,
        isDelete: true,
        selectedItem: selectedItem,
        tasks: tasks,
        taskSteps: taskSteps,
        taskInterventions: taskInterventions,
      });
    } else if (this.state.selectedCategory.value === "ingredient") {
      if (isAdditional !== -1) {
        selectedItem.locales[selectedLang] = undefined;
      } else {
        selectedItem.locales[selectedLang] = {
          name: "",
          description: "",
          pos_name: "",
          printer_name: "",
        };
      }
      this.setState({
        confirmDelete: false,
        notValid: false,
        isDelete: true,
        selectedItem: selectedItem,
      });
    } else if (this.state.selectedCategory.value === "tag") {
      if (isAdditional !== -1) {
        selectedItem.locales[selectedLang] = undefined;
      } else {
        selectedItem.locales[selectedLang] = {
          name: "",
        };
      }
      this.setState({
        confirmDelete: false,
        notValid: false,
        isDelete: true,
        selectedItem: selectedItem,
      });
    } else if (this.state.selectedCategory.value === "unit") {
      if (isAdditional !== -1) {
        selectedItem.locales[selectedLang] = undefined;
      } else {
        selectedItem.locales[selectedLang] = {
          name: "",
          symbol: "",
        };
      }
      this.setState({
        confirmDelete: false,
        notValid: false,
        isDelete: true,
        selectedItem: selectedItem,
      });
    } else if (this.state.selectedCategory.value === "station") {
      if (isAdditional !== -1) {
        selectedItem.locales[selectedLang] = undefined;
      } else {
        selectedItem.locales[selectedLang] = {
          name: "",
        };
      }
      this.setState({
        confirmDelete: false,
        notValid: false,
        isDelete: true,
        selectedItem: selectedItem,
      });
    } else if (this.state.selectedCategory.value === "machine") {
      if (isAdditional !== -1) {
        selectedItem.locales[selectedLang] = undefined;
      } else {
        selectedItem.locales[selectedLang] = {
          name: "",
        };
      }
      this.setState({
        confirmDelete: false,
        notValid: false,
        isDelete: true,
        selectedItem: selectedItem,
      });
    } else if (this.state.selectedCategory.value === "label") {
      if (isAdditional !== -1) {
        selectedItem.locales[selectedLang] = undefined;
      } else {
        selectedItem.locales[selectedLang] = {
          name: "",
          description: "",
        };
      }
      this.setState({
        confirmDelete: false,
        notValid: false,
        isDelete: true,
        selectedItem: selectedItem,
      });
    } else if (this.state.selectedCategory.value === "room") {
      if (isAdditional !== -1) {
        selectedItem.locales[selectedLang] = undefined;
      } else {
        selectedItem.locales[selectedLang] = {
          name: "",
        };
      }
      this.setState({
        confirmDelete: false,
        notValid: false,
        isDelete: true,
        selectedItem: selectedItem,
      });
    } else if (this.state.selectedCategory.value === "payment_option") {
      if (isAdditional !== -1) {
        selectedItem.locales[selectedLang] = undefined;
      } else {
        selectedItem.locales[selectedLang] = {
          name: "",
          description: "",
        };
      }
      this.setState({
        confirmDelete: false,
        notValid: false,
        isDelete: true,
        selectedItem: selectedItem,
      });
    } else if (this.state.selectedCategory.value === "roles") {
      if (isAdditional !== -1) {
        selectedItem.locales[selectedLang] = undefined;
      } else {
        selectedItem.locales[selectedLang] = {
          name: "",
          description: "",
        };
      }
      this.setState({
        confirmDelete: false,
        notValid: false,
        isDelete: true,
        selectedItem: selectedItem,
      });
    }
  };

  deleteToggle = () => {
    this.setState({
      confirmDelete: !this.state.confirmDelete,
    });
  };

  disableButton = () => {
    let disable = true;
    if (this.state.restaurantLang.length < 2) return (disable = true);
    this.state.restaurantLang.forEach((lang: any) => {
      if (lang.code !== this.state.selectedLang)
        if (
          !!this.state.selectedItem?.locales &&
          this.state.selectedItem?.locales[lang.code] &&
          !!this.state.selectedItem.locales[lang.code]["name"].trim()
        ) {
          disable = false;
        }
    });
    if (
      !(
        this.state.selectedItem?.locales &&
        this.state.selectedItem?.locales[this.state.selectedLang] &&
        this.state.selectedItem?.locales[this.state.selectedLang][
          "name"
        ]?.trim()
      )
    ) {
      disable = true;
    }

    return disable;
  };
  render() {
    const { t } = this.props;
    const {
      additionalLang,
      restaurantLang,
      selectedItem,
      selectedLang,
      showModal,
      tasks,
      taskSteps,
      taskInterventions,
    } = this.state;
    if (Array.isArray(this.options) && this.options[0]?.value !== "") {
      this.options.unshift({
        label: this.props.t("common.select"),
        value: "",
      });
    }
    return (
      <div className="briefing-translations">
        <LoaderComponent display={!!this.props.isFetching} />
        <AddLanguageCard
          additionalLang={additionalLang}
          handleAddLanguage={this.handleAddLanguage}
          languages={this.props.languages}
          restaurantLang={restaurantLang}
          showModal={showModal}
          toggleModal={this.toggleModal}
        />
        <DeleteModalCard
          isOpen={this.state.confirmDelete}
          isFetching={!!this.state.isFetching}
          okDelete={this.handleDeleteClick}
          cancelDelete={this.deleteToggle}
        />

        <div className="container-fluid">
          <SettingsHeaderComponent settings={"settings"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <SettingsNavComponent
                display={"translation"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>

            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <div className="white-box mb-3">
                <h4>{t("common.translations")}</h4>
                <div>
                  <Select
                    value={this.state.selectedCategory}
                    onChange={this.handleSelectChange}
                    options={this.options}
                    maxMenuHeight={200}
                    placeholder={"Search by Categories"}
                  />
                </div>
                <div>
                  <Collapse isOpen={this.state.showList}>
                    <Table responsive className="table-striped text-nowrap">
                      <thead>
                        <tr>
                          <th>{t("common.item")}</th>
                          {this.props.restaurantDetail?.languages?.map(
                            (lang: any, index: any) => (
                              <th key={index}>{lang.name}</th>
                            )
                          )}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.selectedCategory.value === "recipe" &&
                        this.props.recipes.length > 0 ? (
                          this.getSortedItem(this.props.recipes).map(
                            (data: any, index: any) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {
                                      commonService.applyLocalization(
                                        "restaurant",
                                        "name",
                                        data?.locales
                                      ).name
                                    }
                                  </td>
                                  {this.props.restaurantDetail?.languages?.map(
                                    (lang: any, index: any) => (
                                      <td key={index}>&#10003;</td>
                                    )
                                  )}

                                  <td>
                                    <Button
                                      className="btn btn-sm btn-info mr-3"
                                      onClick={() => {
                                        this.handleUpdateClick(data);
                                      }}
                                    >
                                      {t("common.update")}
                                    </Button>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : this.state.selectedCategory.value === "recipe" ? (
                          <tr>
                            {" "}
                            <td
                              colSpan={
                                2 + this.props.restaurantDetail.languages.length
                              }
                            >
                              {" "}
                              <div className="mb-3">
                                {" "}
                                <div
                                  style={{ width: "100%", textAlign: "center" }}
                                >
                                  {this.props.t("common.noRecords")}
                                </div>{" "}
                              </div>{" "}
                            </td>{" "}
                          </tr>
                        ) : (
                          <></>
                        )}

                        {this.state.selectedCategory.value === "ingredient" &&
                        this.props.ingredients.length > 0 ? (
                          this.getSortedItem(this.props.ingredients).map(
                            (data: any, index: any) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {
                                      commonService.applyLocalization(
                                        "restaurant",
                                        "name",
                                        data?.locales
                                      ).name
                                    }
                                  </td>
                                  {this.props.restaurantDetail?.languages?.map(
                                    (lang: any, index: any) => (
                                      <td key={index}>&#10003;</td>
                                    )
                                  )}

                                  <td>
                                    <Button
                                      className="btn btn-sm btn-info mr-3"
                                      onClick={() => {
                                        this.handleUpdateClick(data);
                                      }}
                                    >
                                      {t("common.update")}
                                    </Button>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : this.state.selectedCategory.value ===
                          "ingredient" ? (
                          <tr>
                            {" "}
                            <td
                              colSpan={
                                2 + this.props.restaurantDetail.languages.length
                              }
                            >
                              {" "}
                              <div className="mb-3">
                                {" "}
                                <div
                                  style={{ width: "100%", textAlign: "center" }}
                                >
                                  {this.props.t("common.noRecords")}
                                </div>{" "}
                              </div>{" "}
                            </td>{" "}
                          </tr>
                        ) : (
                          <></>
                        )}

                        {this.state.selectedCategory.value === "tag" &&
                        this.props.tags.length > 0 ? (
                          this.getSortedItem(this.props.tags).map(
                            (data: any, index: any) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <div className="d-flex">
                                      {" "}
                                      <span
                                        className="d-inline-block font-weight-bold mr-1"
                                        style={{
                                          maxWidth: 250,
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                        }}
                                        title={
                                          commonService.applyLocalization(
                                            "restaurant",
                                            "name",
                                            data?.locales
                                          ).name
                                        }
                                      >
                                        {
                                          commonService.applyLocalization(
                                            "restaurant",
                                            "name",
                                            data?.locales
                                          ).name
                                        }{" "}
                                      </span>
                                      <span className=" font-weight-bold mx-1">
                                        :
                                      </span>
                                      {data.key}
                                    </div>
                                  </td>
                                  {this.props.restaurantDetail?.languages?.map(
                                    (lang: any, index: any) => (
                                      <td>&#10003;</td>
                                    )
                                  )}
                                  <td>
                                    <Button
                                      className="btn btn-sm btn-info mr-3"
                                      onClick={() => {
                                        this.handleUpdateClick(data);
                                      }}
                                    >
                                      {t("common.update")}
                                    </Button>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : this.state.selectedCategory.value === "tag" ? (
                          <tr>
                            {" "}
                            <td
                              colSpan={
                                2 + this.props.restaurantDetail.languages.length
                              }
                            >
                              {" "}
                              <div className="mb-3">
                                {" "}
                                <div
                                  style={{ width: "100%", textAlign: "center" }}
                                >
                                  {this.props.t("common.noRecords")}
                                </div>{" "}
                              </div>{" "}
                            </td>{" "}
                          </tr>
                        ) : (
                          <></>
                        )}

                        {this.state.selectedCategory.value === "station" &&
                        this.props.stations.length > 0 ? (
                          this.getSortedItem(this.props.stations).map(
                            (data: any, index: any) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {
                                      commonService.applyLocalization(
                                        "restaurant",
                                        "name",
                                        data?.locales
                                      ).name
                                    }
                                  </td>
                                  {this.props.restaurantDetail?.languages?.map(
                                    (lang: any, index: any) => (
                                      <td>&#10003;</td>
                                    )
                                  )}

                                  <td>
                                    <Button
                                      className="btn btn-sm btn-info mr-3"
                                      onClick={() => {
                                        this.handleUpdateClick(data);
                                      }}
                                    >
                                      {t("common.update")}
                                    </Button>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : this.state.selectedCategory.value === "station" ? (
                          <tr>
                            {" "}
                            <td
                              colSpan={
                                2 + this.props.restaurantDetail.languages.length
                              }
                            >
                              {" "}
                              <div className="mb-3">
                                {" "}
                                <div
                                  style={{ width: "100%", textAlign: "center" }}
                                >
                                  {this.props.t("common.noRecords")}
                                </div>{" "}
                              </div>{" "}
                            </td>{" "}
                          </tr>
                        ) : (
                          <></>
                        )}

                        {this.state.selectedCategory.value === "machine" &&
                        this.props.machine.length > 0 ? (
                          this.getSortedItem(this.props.machine).map(
                            (data: any, index: any) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {
                                      commonService.applyLocalization(
                                        "restaurant",
                                        "name",
                                        data?.locales
                                      ).name
                                    }
                                  </td>
                                  {this.props.restaurantDetail?.languages?.map(
                                    (lang: any, index: any) => (
                                      <td>&#10003;</td>
                                    )
                                  )}

                                  <td>
                                    <Button
                                      className="btn btn-sm btn-info mr-3"
                                      onClick={() => {
                                        this.handleUpdateClick(data);
                                      }}
                                    >
                                      {t("common.update")}
                                    </Button>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : this.state.selectedCategory.value === "machine" ? (
                          <tr>
                            {" "}
                            <td
                              colSpan={
                                2 + this.props.restaurantDetail.languages.length
                              }
                            >
                              {" "}
                              <div className="mb-3">
                                {" "}
                                <div
                                  style={{ width: "100%", textAlign: "center" }}
                                >
                                  {this.props.t("common.noRecords")}
                                </div>{" "}
                              </div>{" "}
                            </td>{" "}
                          </tr>
                        ) : (
                          <></>
                        )}

                        {this.state.selectedCategory.value === "unit" &&
                        this.props.units.length > 0 ? (
                          this.getSortedItem(this.props.units).map(
                            (data: any, index: any) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {
                                      commonService.applyLocalization(
                                        "restaurant",
                                        "name",
                                        data?.locales
                                      ).name
                                    }
                                  </td>
                                  {this.props.restaurantDetail?.languages?.map(
                                    (lang: any, index: any) => (
                                      <td>&#10003;</td>
                                    )
                                  )}

                                  <td>
                                    <Button
                                      className="btn btn-sm btn-info mr-3"
                                      onClick={() => {
                                        this.handleUpdateClick(data);
                                      }}
                                    >
                                      {t("common.update")}
                                    </Button>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : this.state.selectedCategory.value === "unit" ? (
                          <tr>
                            {" "}
                            <td
                              colSpan={
                                2 + this.props.restaurantDetail.languages.length
                              }
                            >
                              {" "}
                              <div className="mb-3">
                                {" "}
                                <div
                                  style={{ width: "100%", textAlign: "center" }}
                                >
                                  {this.props.t("common.noRecords")}
                                </div>{" "}
                              </div>{" "}
                            </td>{" "}
                          </tr>
                        ) : (
                          <></>
                        )}

                        {this.state.selectedCategory.value === "label" &&
                        this.props.labels.length > 0 ? (
                          this.getSortedItem(this.props.labels).map(
                            (data: any, index: any) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {
                                      commonService.applyLocalization(
                                        "restaurant",
                                        "name",
                                        data?.locales
                                      ).name
                                    }
                                  </td>
                                  {this.props.restaurantDetail?.languages?.map(
                                    (lang: any, index: any) => (
                                      <td>&#10003;</td>
                                    )
                                  )}

                                  <td>
                                    <Button
                                      className="btn btn-sm btn-info mr-3"
                                      onClick={() => {
                                        this.handleUpdateClick(data);
                                      }}
                                    >
                                      {t("common.update")}
                                    </Button>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : this.state.selectedCategory.value === "label" ? (
                          <tr>
                            {" "}
                            <td
                              colSpan={
                                2 + this.props.restaurantDetail.languages.length
                              }
                            >
                              {" "}
                              <div className="mb-3">
                                {" "}
                                <div
                                  style={{ width: "100%", textAlign: "center" }}
                                >
                                  {this.props.t("common.noRecords")}
                                </div>{" "}
                              </div>{" "}
                            </td>{" "}
                          </tr>
                        ) : (
                          <></>
                        )}

                        {this.state.selectedCategory.value === "room" &&
                        this.props.room.length > 0 ? (
                          this.getSortedItem(this.props.room).map(
                            (data: any, index: any) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {
                                      commonService.applyLocalization(
                                        "restaurant",
                                        "name",
                                        data?.locales
                                      ).name
                                    }{" "}
                                  </td>
                                  {this.props.restaurantDetail?.languages?.map(
                                    (lang: any, index: any) => (
                                      <td>&#10003;</td>
                                    )
                                  )}

                                  <td>
                                    <Button
                                      className="btn btn-sm btn-info mr-3"
                                      onClick={() => {
                                        this.handleUpdateClick(data);
                                      }}
                                    >
                                      {t("common.update")}
                                    </Button>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : this.state.selectedCategory.value === "room" ? (
                          <tr>
                            {" "}
                            <td
                              colSpan={
                                2 + this.props.restaurantDetail.languages.length
                              }
                            >
                              {" "}
                              <div className="mb-3">
                                {" "}
                                <div
                                  style={{ width: "100%", textAlign: "center" }}
                                >
                                  {this.props.t("common.noRecords")}
                                </div>{" "}
                              </div>{" "}
                            </td>{" "}
                          </tr>
                        ) : (
                          <></>
                        )}

                        {this.state.selectedCategory.value ===
                          "payment_option" &&
                        this.props.paymentProvider.length > 0 ? (
                          this.getSortedItem(this.props.paymentProvider).map(
                            (data: any, index: any) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {
                                      commonService.applyLocalization(
                                        "restaurant",
                                        "name",
                                        data?.locales
                                      ).name
                                    }
                                  </td>
                                  {this.props.restaurantDetail?.languages?.map(
                                    (lang: any, index: any) => (
                                      <td>&#10003;</td>
                                    )
                                  )}

                                  <td>
                                    <Button
                                      className="btn btn-sm btn-info mr-3"
                                      onClick={() => {
                                        this.handleUpdateClick(data);
                                      }}
                                    >
                                      {t("common.update")}
                                    </Button>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : this.state.selectedCategory.value ===
                          "payment_option" ? (
                          <tr>
                            {" "}
                            <td
                              colSpan={
                                2 +
                                this.props.restaurantDetail.languages?.length
                              }
                            >
                              {" "}
                              <div className="mb-3">
                                {" "}
                                <div
                                  style={{ width: "100%", textAlign: "center" }}
                                >
                                  {this.props.t("common.noRecords")}
                                </div>{" "}
                              </div>{" "}
                            </td>{" "}
                          </tr>
                        ) : (
                          <></>
                        )}
                        {this.state.selectedCategory.value === "roles" &&
                        this.props.roleList?.length > 0 ? (
                          this.getSortedItem(this.props.roleList)?.map(
                            (data: any, index: any) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {
                                      commonService.applyLocalization(
                                        "restaurant",
                                        "name",
                                        data?.locales
                                      ).name
                                    }
                                  </td>
                                  {this.props.restaurantDetail?.languages?.map(
                                    (lang: any, index: any) => (
                                      <td>&#10003;</td>
                                    )
                                  )}

                                  <td>
                                    <Button
                                      className="btn btn-sm btn-info mr-3"
                                      onClick={() => {
                                        this.handleUpdateClick(data);
                                      }}
                                    >
                                      {t("common.update")}
                                    </Button>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : this.state.selectedCategory.value === "roles" ? (
                          <tr>
                            <td
                              colSpan={
                                2 +
                                this.props.restaurantDetail.languages?.length
                              }
                            >
                              <div className="mb-3">
                                <div
                                  style={{ width: "100%", textAlign: "center" }}
                                >
                                  {this.props.t("common.noRecords")}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </Table>
                  </Collapse>
                </div>
              </div>
              <Collapse in={this.state.showForm}>
                <div className="white-box mb-3">
                  <div id="example-collapse-text">
                    <Card>
                      <CardHeader>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center">
                            <HeadingComponent
                              title={this.state.selectedCategory.label}
                            />

                            <Button
                              disabled={this.disableButton()}
                              type="button"
                              className="btn btn-danger ml-3"
                              onClick={this.deleteToggle}
                            >
                              {this.state.restaurantLang.filter(
                                (lang: any) => lang.code === selectedLang
                              )[0]?.isAdditional
                                ? t("translation.deleteTranslation")
                                : t("translation.clearTranslation")}
                            </Button>
                          </div>

                          <div>
                            <div className="d-flex justify-content-end">
                              {this.state.restaurantLang.map(
                                (lang: any, index: any) => {
                                  return (
                                    <div
                                      key={index}
                                      className={
                                        selectedLang === lang.code
                                          ? "briefing-lang-active briefing-lang-btn ml-1"
                                          : "briefing-lang-btn ml-1"
                                      }
                                      onClick={() => {
                                        this.handleLangClick(lang.code);
                                      }}
                                    >
                                      {lang.code?.split("-")[0].toUpperCase()}
                                    </div>
                                  );
                                }
                              )}

                              {this.props.languages.filter(
                                (lang: any) =>
                                  this.state.restaurantLang?.findIndex(
                                    (item: any) => item.uuid === lang.uuid
                                  ) === -1
                              ).length > 0 && (
                                <div
                                  className="briefing-lang-btn ml-1"
                                  onClick={this.toggleModal}
                                >
                                  +
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          {this.state.selectedCategory.value === "recipe" && (
                            <RecipeComponent
                              additionalLang={additionalLang}
                              handleChange={this.handleChange}
                              interventions={taskInterventions}
                              notValid={this.state.notValid}
                              restaurantLang={restaurantLang}
                              selectedItem={selectedItem}
                              selectedLang={selectedLang}
                              steps={taskSteps}
                              state={this.state}
                              tasks={tasks}
                            />
                          )}
                          {this.state.selectedCategory.value ===
                            "ingredient" && (
                            <>
                              <TextInputComponent
                                data={selectedItem}
                                defaultLang={selectedLang}
                                field="name"
                                name="name"
                                restaurantLang={restaurantLang}
                                text="Name"
                                type="text"
                                value={
                                  selectedItem?.locales &&
                                  selectedItem?.locales[selectedLang]
                                    ? selectedItem?.locales[selectedLang].name
                                    : ""
                                }
                                handleChange={this.handleChange}
                              />

                              <TextInputComponent
                                data={selectedItem}
                                defaultLang={selectedLang}
                                field="description"
                                name="description"
                                restaurantLang={restaurantLang}
                                text="Description"
                                type="text"
                                value={
                                  selectedItem?.locales &&
                                  selectedItem?.locales[selectedLang]
                                    ? selectedItem?.locales[selectedLang]
                                        .description
                                    : ""
                                }
                                handleChange={this.handleChange}
                              />
                              <TextInputComponent
                                data={selectedItem}
                                defaultLang={selectedLang}
                                field="pos_name"
                                name="pos_name"
                                restaurantLang={restaurantLang}
                                text="POS name"
                                type="text"
                                value={
                                  selectedItem?.locales &&
                                  selectedItem?.locales[selectedLang]
                                    ? selectedItem?.locales[selectedLang]
                                        .pos_name
                                    : ""
                                }
                                handleChange={this.handleChange}
                              />
                              <TextInputComponent
                                data={selectedItem}
                                defaultLang={selectedLang}
                                field="printer_name"
                                name="printer_name"
                                restaurantLang={restaurantLang}
                                text="Printer name"
                                type="text"
                                value={
                                  selectedItem?.locales &&
                                  selectedItem?.locales[selectedLang]
                                    ? selectedItem?.locales[selectedLang]
                                        .printer_name
                                    : ""
                                }
                                handleChange={this.handleChange}
                              />
                            </>
                          )}
                          {this.state.selectedCategory.value === "tag" && (
                            <>
                              <TextInputComponent
                                data={selectedItem}
                                defaultLang={selectedLang}
                                field="name"
                                name="name"
                                restaurantLang={restaurantLang}
                                text={t("common.name")}
                                type="text"
                                value={
                                  selectedItem?.locales &&
                                  selectedItem?.locales[selectedLang]
                                    ? selectedItem?.locales[selectedLang].name
                                    : ""
                                }
                                handleChange={this.handleChange}
                              />
                            </>
                          )}
                          {this.state.selectedCategory.value === "unit" && (
                            <>
                              <TextInputComponent
                                data={selectedItem}
                                defaultLang={selectedLang}
                                field="name"
                                name="name"
                                restaurantLang={restaurantLang}
                                text={t("common.name")}
                                type="text"
                                value={
                                  selectedItem?.locales &&
                                  selectedItem?.locales[selectedLang]
                                    ? selectedItem?.locales[selectedLang].name
                                    : ""
                                }
                                handleChange={this.handleChange}
                              />
                              <TextInputComponent
                                data={selectedItem}
                                defaultLang={selectedLang}
                                field="symbol"
                                name="symbol"
                                restaurantLang={restaurantLang}
                                text={t("common.symbol")}
                                type="text"
                                value={
                                  selectedItem?.locales &&
                                  selectedItem?.locales[selectedLang]
                                    ? selectedItem?.locales[selectedLang].symbol
                                    : ""
                                }
                                handleChange={this.handleChange}
                              />
                            </>
                          )}
                          {this.state.selectedCategory.value === "station" && (
                            <>
                              <TextInputComponent
                                data={selectedItem}
                                defaultLang={selectedLang}
                                field="name"
                                name="name"
                                restaurantLang={restaurantLang}
                                text={t("common.name")}
                                type="text"
                                value={
                                  selectedItem?.locales &&
                                  selectedItem?.locales[selectedLang]
                                    ? selectedItem?.locales[selectedLang].name
                                    : ""
                                }
                                handleChange={this.handleChange}
                              />
                            </>
                          )}
                          {this.state.selectedCategory.value === "machine" && (
                            <>
                              <TextInputComponent
                                data={selectedItem}
                                defaultLang={selectedLang}
                                field="name"
                                name="name"
                                restaurantLang={restaurantLang}
                                text={t("common.name")}
                                type="text"
                                value={
                                  selectedItem?.locales &&
                                  selectedItem?.locales[selectedLang]
                                    ? selectedItem?.locales[selectedLang].name
                                    : ""
                                }
                                handleChange={this.handleChange}
                              />
                            </>
                          )}
                          {this.state.selectedCategory.value === "label" && (
                            <>
                              <TextInputComponent
                                data={selectedItem}
                                defaultLang={selectedLang}
                                field="name"
                                name="name"
                                restaurantLang={restaurantLang}
                                text="Name"
                                type="text"
                                value={
                                  selectedItem?.locales &&
                                  selectedItem?.locales[selectedLang]
                                    ? selectedItem?.locales[selectedLang].name
                                    : ""
                                }
                                handleChange={this.handleChange}
                              />

                              <TextInputComponent
                                data={selectedItem}
                                defaultLang={selectedLang}
                                field="description"
                                name="description"
                                restaurantLang={restaurantLang}
                                text="Description"
                                value={
                                  selectedItem?.locales &&
                                  selectedItem?.locales[selectedLang]
                                    ? selectedItem?.locales[selectedLang]
                                        .description
                                    : ""
                                }
                                handleChange={this.handleChange}
                              />
                            </>
                          )}
                          {this.state.selectedCategory.value === "room" && (
                            <>
                              <TextInputComponent
                                data={selectedItem}
                                defaultLang={selectedLang}
                                field="name"
                                name="name"
                                restaurantLang={restaurantLang}
                                text={t("common.name")}
                                type="text"
                                value={
                                  selectedItem?.locales &&
                                  selectedItem?.locales[selectedLang]
                                    ? selectedItem?.locales[selectedLang].name
                                    : ""
                                }
                                handleChange={this.handleChange}
                              />
                            </>
                          )}
                          {this.state.selectedCategory.value ===
                            "payment_option" && (
                            <>
                              <TextInputComponent
                                data={selectedItem}
                                defaultLang={selectedLang}
                                field="name"
                                name="name"
                                restaurantLang={restaurantLang}
                                text={t("common.name")}
                                type="text"
                                value={
                                  selectedItem?.locales &&
                                  selectedItem?.locales[selectedLang]
                                    ? selectedItem?.locales[selectedLang].name
                                    : ""
                                }
                                handleChange={this.handleChange}
                              />
                              <TextInputComponent
                                data={selectedItem}
                                defaultLang={selectedLang}
                                field="description"
                                name="description"
                                restaurantLang={restaurantLang}
                                text={t("common.description")}
                                value={
                                  selectedItem?.locales &&
                                  selectedItem?.locales[selectedLang]
                                    ? selectedItem?.locales[selectedLang]
                                        .description
                                    : ""
                                }
                                handleChange={this.handleChange}
                              />
                            </>
                          )}

                          {this.state.selectedCategory.value === "room" && (
                            <>
                              <TextInputComponent
                                data={selectedItem}
                                defaultLang={selectedLang}
                                field="name"
                                name="name"
                                restaurantLang={restaurantLang}
                                text={t("common.name")}
                                type="text"
                                value={
                                  selectedItem?.locales &&
                                  selectedItem?.locales[selectedLang]
                                    ? selectedItem?.locales[selectedLang].name
                                    : ""
                                }
                                handleChange={this.handleChange}
                              />
                            </>
                          )}
                          {this.state.selectedCategory.value === "roles" && (
                            <>
                              <TextInputComponent
                                data={selectedItem}
                                defaultLang={selectedLang}
                                field="name"
                                name="name"
                                restaurantLang={restaurantLang}
                                text={t("role.roleName")}
                                type="text"
                                value={
                                  selectedItem?.locales &&
                                  selectedItem?.locales[selectedLang]
                                    ? selectedItem?.locales[selectedLang].name
                                    : ""
                                }
                                handleChange={this.handleChange}
                              />
                            </>
                          )}
                        </Form>
                        <div className="d-flex justify-content-end pr-3 mt-3">
                          <Button
                            className="btn btn-secondary mr-2"
                            type="button"
                            onClick={() => {
                              this.handleCancel();
                            }}
                          >
                            {t("common.cancel")}
                          </Button>
                          <Button
                            className="btn btn-info"
                            type="button"
                            onClick={() => {
                              this.handleSave();
                            }}
                          >
                            {t("common.save")}
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </Collapse>
            </Col>

            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.machine.isFetching ||
    state.role.isFetching ||
    state.stations.isFetching ||
    state.ingredientsDetail.isFetching ||
    state.ingredients.isFetching ||
    state.languages.isFetching ||
    state.translation.isFetching ||
    state.recipe.isFetching ||
    state.recipedetails.isFetching ||
    state.units.isFetching ||
    state.tags.isFetching ||
    state.room.isFetching ||
    state.paymentProvider.isFetching ||
    state.restaurantDetail.isFetching ||
    state.labels.isFetching;
  let failure =
    state.machine.failure ||
    state.role.failure ||
    state.stations.failure ||
    state.ingredientsDetail.failure ||
    state.ingredients.isFetching ||
    state.languages.failure ||
    state.translation.failure ||
    state.recipe.failure ||
    state.recipedetails.failure ||
    state.units.failure ||
    state.tags.failure ||
    state.room.failure ||
    state.paymentProvider.failure ||
    state.restaurantDetail.failure;

  return {
    machine: state.machine.data,
    stations: state.stations.data,
    ingredientDetail: state.ingredientsDetail.data,
    ingredients: state.ingredients.data,
    isFetching: isFetching,
    failure: failure,
    languages: state.languages.data,
    labels: state.labels.data,
    recipes: state.recipe.data,
    recipeDetail: state.recipedetails.data,
    restaurantDetail: state.restaurantDetail.data,
    tags: state.tags.data,
    translation: state.translation.data,
    units: state.units.data,
    room: state.room.data,
    roleList: state.role.data,
    paymentProvider: state.paymentProvider.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getLabel: (credentials: any) => {
      dispatch(labelRequest(credentials));
    },
    getMachine: (credentials: any) => {
      dispatch(machineRequest(credentials));
    },
    getRoleList: (credentials: any) => {
      dispatch(rolesRequest(credentials));
    },
    getStations: (credentials: any) => {
      dispatch(stationsRequest(credentials));
    },
    getIngredientDetail: (credentials: any) => {
      dispatch(ingredientDetailRequest(credentials));
    },
    getingredients: (credentials: any) => {
      dispatch(ingredientsRequest(credentials));
    },
    getLanguages: (credentials: any) => {
      dispatch(languageRequest(credentials));
    },
    getRecipes: (credentials: any) => {
      dispatch(recipeRequest(credentials));
    },
    getTags: (credentials: any) => {
      dispatch(tagsRequest(credentials));
    },
    getUnits: (credentials: any) => {
      dispatch(unitsRequest(credentials));
    },
    getRoom: (credentials: any) => {
      dispatch(roomRequest(credentials));
    },
    getPaymentProvider: (credentials: any) => {
      dispatch(paymentProviderRequest(credentials));
    },
    getRestaurantDetail: (credentials: any) => {
      dispatch(restaurantDetailRequest(credentials));
    },
    recipeDetails: (credentials: any) => {
      dispatch(recipeDetailsRequest(credentials));
    },
    saveTranslation: (credentials: any) => {
      dispatch(translationRequest(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(TranslationComponent))
);
