import apiCall from "./api.call.config";


async function chatInit(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "chat/init"
    );
  } catch (e) {
    throw e;
  }
}


async function chatRestoreIdUpdate(url: string, params: any) {
  try {
    return await apiCall.put(
      url + "chat/restore_token",
        params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}


export default {
  chatInit,
  chatRestoreIdUpdate
};
