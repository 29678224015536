import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function dateItemRequest(credentials: object): ActionModel {
  return {
    type: types.DATE_ITEM.REQUEST,
    credentials,
  };
}

export function dateItemSuccess(data: object): ActionModel {
  return {
    type: types.DATE_ITEM.SUCCESS,
    data,
  };
}

export function dateItemFailure(err: object): ActionModel {
  return {
    type: types.DATE_ITEM.FAILURE,
    err,
  };
}
