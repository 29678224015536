import '../../styles/login.scss';

import React, { Component } from 'react';

import LoginComponent from '../../components/login/login';
import {closeChatBox} from '../../app/freshChat';

class LoginPage extends Component {
    componentDidMount(){
        closeChatBox();
    }
    render() {
        return <LoginComponent />;
    }
}
export default LoginPage;
