import "../../../styles/role-rights.scss";
import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useTranslation } from "react-i18next";

export default function RoleEditCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <Modal isOpen={props.state.editmodal}>
      <ModalHeader toggle={props.toggle} className="modal-header">
        {t("role.editRole")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstname">{t("role.roleName")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name="name"
              autoComplete="name"
              placeholder={t("role.enterRoleName")}
              value={props.state.name}
              onChange={props.handleChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={props.saveEdit}
          disabled={!props.state.name || !!props.state.isFetching}
        >
          {t("common.save")}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
