import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function stationsRequest(credentials: object): ActionModel {
  return {
    type: types.STATIONS.REQUEST,
    credentials,
  };
}

export function stationsSuccess(data: object): ActionModel {
  return {
    type: types.STATIONS.SUCCESS,
    data,
  };
}

export function stationsFailure(err: object): ActionModel {
  return {
    type: types.STATIONS.FAILURE,
    err,
  };
}

export function stationsUpdate(credentials: object): ActionModel {
  return {
    type: types.STATIONS_UPDATE.REQUEST,
    credentials,
  };
}

export function stationsUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.STATIONS_UPDATE.SUCCESS,
    data,
  };
}

export function stationsUpdateFailure(err: object): ActionModel {
  return {
    type: types.STATIONS_UPDATE.FAILURE,
    err,
  };
}

export function stationsAdd(credentials: object): ActionModel {
  return {
    type: types.STATIONS_ADD.REQUEST,
    credentials,
  };
}

export function stationsAddSuccess(data?: object): ActionModel {
  return {
    type: types.STATIONS_ADD.SUCCESS,
    data,
  };
}

export function stationsAddFailure(err: object): ActionModel {
  return {
    type: types.STATIONS_ADD.FAILURE,
    err,
  };
}

export function stationsRemove(credentials: object): ActionModel {
  return {
    type: types.STATIONS_REMOVE.REQUEST,
    credentials,
  };
}

export function stationsRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.STATIONS_REMOVE.SUCCESS,
    data,
  };
}

export function stationsRemoveFailure(err: object): ActionModel {
  return {
    type: types.STATIONS_REMOVE.FAILURE,
    err,
  };
}

export function stationsOperationModeUpdate(credentials: object): ActionModel {
  return {
    type: types.STATIONS_OPERATION_MODE_UPDATE.REQUEST,
    credentials,
  };
}

export function stationsOperationModeUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.STATIONS_OPERATION_MODE_UPDATE.SUCCESS,
    data,
  };
}

export function stationsOperationModeUpdateFailure(err: object): ActionModel {
  return {
    type: types.STATIONS_OPERATION_MODE_UPDATE.FAILURE,
    err,
  };
}
export function assembleTaskLocalesSuccess(data: object): ActionModel {
  return {
    type: types.ASSEMBLE_TASK_LOCALES.SUCCESS,
    data,
  };
}

export function assembleTaskLocalesFailure(err: object): ActionModel {
  return {
    type: types.ASSEMBLE_TASK_LOCALES.FAILURE,
    err,
  };
}

export function assembleTaskLocalesRequest(credentials: object): ActionModel {
  return {
    type: types.ASSEMBLE_TASK_LOCALES.REQUEST,
    credentials,
  };
}

export function settingsRequest(credentials: object): ActionModel {
  return {
    type: types.SETTINGS.REQUEST,
    credentials,
  };
}

export function settingsSuccess(data: object): ActionModel {
  return {
    type: types.SETTINGS.SUCCESS,
    data,
  };
}

export function settingsFailure(err: object): ActionModel {
  return {
    type: types.SETTINGS.FAILURE,
    err,
  };
}

export function settingsAdd(credentials: object): ActionModel {
  return {
    type: types.SETTINGS_ADD.REQUEST,
    credentials,
  };
}

export function settingsAddSuccess(data?: object): ActionModel {
  return {
    type: types.SETTINGS_ADD.SUCCESS,
    data,
  };
}

export function settingsAddFailure(err: object): ActionModel {
  return {
    type: types.SETTINGS_ADD.FAILURE,
    err,
  };
}
