import '../../styles/email-confirmation.scss';

import React, { Component } from 'react';

import EmailConfirmationComponent from '../../components/email-confirmation/email-confirmation';

class EmailConfirmationPage extends Component {
    render() {
        return (
            <div className="emailconfirmation-style">
                <EmailConfirmationComponent/>
            </div>
        );
    }
}
export default EmailConfirmationPage;
