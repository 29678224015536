import React, { Component } from "react";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import {
  fetchMediaIfRequired,
  mediaSelector,
  postMedia,
} from "../../database/media.database";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import image from "../../assets/images/icon/image.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

class CardMedia extends Component<any> {
  localStoreIsValid = false;
  currentMediaUuid = null;

  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
  }

  componentDidMount() {
    this.props.fetchMediaIfRequired(
      this.props.match.params.uuid,
      `pageNo=1&pageSize=50&filter=type=[image,video]`,
      "media"
    );
  }

  shouldComponentUpdate(
    nextProps: Readonly<any>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    return (
      (this.currentMediaUuid &&
        this.currentMediaUuid !== null &&
        !this.localStoreIsValid) ||
      nextProps.mediaUuid !== this.currentMediaUuid ||
      nextProps.onClick !== this.props.onClick ||
      nextProps.activeLink !== this.props.activeLink
    );
  }

  private _setFunctionBindings(): void {}

  render() {
    let { mediaUuid, onClick, activeLink } = this.props;

    this.currentMediaUuid = mediaUuid;
    let currentMediaItem = null;

    if (this.currentMediaUuid && this.currentMediaUuid !== null) {
      currentMediaItem = this.props.media.data?.items?.find(
        (mediaItem: any) => {
          return mediaItem.uuid === this.currentMediaUuid;
        }
      );
    }

    let classList = "";
    let onClickProp = {};
    if (activeLink) {
      classList += " active pointer-cursor";
      onClickProp = { onClick: onClick };
    } else {
      classList += " inactive";
    }

    if (
      currentMediaItem &&
      typeof currentMediaItem !== "undefined" &&
      currentMediaItem.type === "image"
    ) {
      let src = {};
      if (currentMediaItem.thumbnail && currentMediaItem.thumbnail !== "") {
        Object.assign(src, { datasrc: currentMediaItem.thumbnail });
      }
      return (
        <div className={"media-block position-relative"}>
          {activeLink && this.props?.clearImage && (
            <div className="pointer-cursor bf-clear-image">
              <FontAwesomeIcon
                style={{ fontSize: 20 }}
                icon={faTrashAlt}
                color="#000000"
                onClick={this.props.clearImage}
              />
            </div>
          )}
          <img
            className={"lazyload " + classList}
            data-src={currentMediaItem.permalink}
            {...onClickProp}
            {...src}
          />
        </div>
      );
    } else if (
      currentMediaItem &&
      typeof currentMediaItem !== "undefined" &&
      currentMediaItem.type === "video"
    ) {
      return (
        <div className={"media-block position-relative"}>
          {activeLink && this.props?.clearImage && (
            <div className="pointer-cursor bf-clear-image">
              <FontAwesomeIcon
                style={{ fontSize: 20 }}
                icon={faTrashAlt}
                color="#000000"
                onClick={this.props.clearImage}
              />
            </div>
          )}
          <video
            preload={"none"}
            muted={true}
            controls={!activeLink}
            data-autoPlay={false}
            data-poster={currentMediaItem.thumbnail}
            data-src={currentMediaItem.permalink}
            className={"lazyload " + classList}
            {...onClickProp}
          />
        </div>
      );
    } else {
      return (
        <div className={"media-block"}>
          {this.props?.videoUrl ? (
            <video
              preload={"none"}
              muted={true}
              controls={!activeLink}
              data-autoPlay={false}
              data-src={this.props.videoUrl}
              className={"lazyload " + classList}
              {...onClickProp}
            />
          ) : (
            <img
              className={classList}
              src={this.props?.thumbnail ? this.props.thumbnail : image}
              {...onClickProp}
            />
          )}
        </div>
      );
    }
  }
}

const mapStateToProps: any = (state: any, ownProps: any) => {
  const entity_type = ownProps?.entityType ? ownProps?.entityType : "media";
  return {
    media: mediaSelector(state, ownProps.match.params.uuid, entity_type),
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    fetchMediaIfRequired: (
      restaurantUuid: any,
      filters: any,
      entityType: string
    ) => {
      dispatch(fetchMediaIfRequired(restaurantUuid, filters, entityType));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CardMedia)
) as any;

// TODO comment for staging commit
