import { faEdit, faStop, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Label, Row } from "reactstrap";
import { DARK_GRAY, GRAY, GREEN } from "../../constant/constant";

export default function ContentCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <div className="child-box mb-3">
      <span style={{ marginTop: 20 }}>
        <div style={{ float: "right", display: "flex" }}>
          <span
            onClick={props.editContent}
            className="pointer-cursor"
            data-toggle="tooltip"
            data-placement="left"
            title={t("common.edit")}
          >
            <FontAwesomeIcon
              style={{ color: GREEN, fontSize: 20 }}
              icon={faEdit}
            />
          </span>
          <span
            onClick={props.deleteContent}
            className="pointer-cursor"
            data-toggle="tooltip"
            data-placement="left"
            title={t("common.delete")}
          >
            <FontAwesomeIcon
              style={{ color: GRAY, fontSize: 20, marginLeft: 10 }}
              icon={faTrashAlt}
            />
          </span>
        </div>
      </span>
      <Row className="app-row-padding">
        <Col xs="8" sm="4">
          {props.content.name}
        </Col>
        <Col xs="8" sm="4">
          <Label> </Label>
        </Col>
      </Row>
    </div>
  );
}
