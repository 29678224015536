import "../../styles/page404.scss";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Alert, Button, Table } from "reactstrap";

import { withTranslation } from "react-i18next";
import moment from "moment";
import commonService from "../../services/common.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { DARK_GRAY, WHITE } from "../../constant/constant";
class ListOfGuestGroups extends Component<any> {
  render() {
    const { t, data } = this.props;
    return (
      <>
        {data?.length === 0 && (
          <Alert color="info">{t("common.noRecords")}</Alert>
        )}
        {data?.guestGroups?.length > 0 && (
          <>
            <h5>{t("reports.listOfGuestGroup")}</h5>
            {data?.guestGroups?.map((guestGroup: any, index: any) => {
              return (
                <section className="mt-3 mb-5">
                  {" "}
                  <div className="d-flex  align-items-center justify-content-between">
                    <h6 className=" mb-2">
                      {index + 1}.{guestGroup.name}
                    </h6>
                    <Button
                      className=" mb-2"
                      color="info"
                      size="sm"
                      onClick={() =>
                        this.props.handleGuestPrint(guestGroup, "guestGroup")
                      }
                    >
                      <FontAwesomeIcon
                        style={{ color: WHITE }}
                        icon={faPrint}
                      />
                    </Button>
                  </div>
                  <Table bordered striped>
                    <thead>
                      <tr className="bg-light">
                        <th className="w-25">{t("reports.timestamp")}</th>
                        <th className="w-25">{t("reports.transactionId")}</th>
                        <th className="w-25">{t("reports.paymentType")}</th>
                        <th className="w-25">{t("common.currency")}</th>
                        <th className="w-25">{t("common.amount")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {guestGroup?.transactions?.map((innerData: any) => {
                        return (
                          <tr>
                            <td>
                              {moment(innerData.timestamp, "x").format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                {innerData.transactionId}{" "}
                                <Button
                                  className=" mx-2"
                                  color="link"
                                  size="sm"
                                  onClick={() =>
                                    this.props.handleGuestPrint(
                                      innerData,
                                      "payment"
                                    )
                                  }
                                >
                                  <FontAwesomeIcon
                                    style={{ color: DARK_GRAY }}
                                    icon={faPrint}
                                  />
                                </Button>
                              </div>
                            </td>
                            <td>{innerData.paymentType}</td>
                            <td>{innerData.currency}</td>
                            <td>
                              {commonService.displayDigitalAmount(
                                innerData.amount
                              )}
                            </td>
                          </tr>
                        );
                      })}

                      {(!guestGroup?.transactions ||
                        guestGroup?.transactions.length === 0) && (
                        <tr>
                          <td colSpan={5} className="text-center">
                            {t("common.noRecords")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </section>
              );
            })}
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withRouter(ListOfGuestGroups));
