import { faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

export default function RecipePrinterEditCard(props: any) {
  const { t, i18n } = useTranslation();

  return (
    <Modal isOpen={props.state.editmodal}>
      <ModalHeader toggle={props.editPrinterToggle} className="modal-header">
        {t("printer.editPrinter")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstname">{t("printer.printerName")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name="name"
              autoComplete="name"
              placeholder={t("printer.enterPrinterName")}
              value={props.state.name}
              onChange={props.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastname">{t("printer.printerIP")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name="printerip"
              autoComplete="printerip"
              placeholder={t("printer.enterPrinterIP")}
              value={props.state.printerip}
              onChange={props.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastname">{t("printer.printerPort")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="number"
              required
              name="printerport"
              autoComplete="printerport"
              placeholder={t("printer.enterPrinterPort")}
              min="0"
              value={props.state.printerport}
              onChange={props.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="printerType">{t("printer.type")}</Label>
            <Label className="required-star-style">*</Label>
            <Select
              name={"printerType"}
              value={props.state.printerType}
              onChange={(e) =>
                props.handleChange({
                  target: { name: "printerType", value: e },
                })
              }
              placeholder={t("printer.selectPrinterType")}
              options={props.printerTypes}
              maxMenuHeight={200}
              className=" flex-fill"
              styles={{
                menu: (provided: any) => ({
                  ...provided,
                  color: "#000",
                }),
              }}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.editPrinterToggle}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={props.savePrinterEdit}
          disabled={
            !props.state.name ||
            !props.state.printerip ||
            !props.state.printerport ||
            !props.state.printerType ||
            !!props.state.isFetching
          }
        >
          {t("common.save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
