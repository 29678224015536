import React, { Component } from "react";
import LoaderComponent from "../../components/loader/loader";
import SuppliersComponent from "../../components/suppliers/suppliers";
import StaffHeaderComponent from "../../components/navigation/navigation-header/staff-header/staff-header";
import UsersLeftComponent from "../../components/navigation/navigation-left/users-navigation/users-navigation";
import NavigationRightComponent from "../../components/navigation/navigation-right/navigation-right";
import { Button, Col, Form, FormGroup, Label, Row } from "reactstrap";
import {
  ResourceCostPageInitState,
  ResourceCostPageState,
} from "../../models/resourceCost.model";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";
import SettingsService from "../../services/stations.service";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import commonService from "../../services/common.service";
import { settingsAdd } from "../../redux/actions/stations.action";

class ResourceCostPage extends Component<any> {
  state: ResourceCostPageState;
  constructor(props: any) {
    super(props);
    this.state = ResourceCostPageInitState;
  }
  componentDidMount() {
    document.body.className = "light-theme";
    this.loadSettings();
  }

  handleHourlyCooksRate = (value: any) => {
    const minorUnit = JSON.parse(
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
    )?.minor_units;
    this.setState({
      hourlyCooksRate: value,
      currentSettingsData: {
        ...this.state.currentSettingsData,
        hourly_cooks_rate: Math.round(
          Number(parseFloat(value) * Math.pow(10, minorUnit))
        ),
      },
    });
  };

  loadSettings = () => {
    this.showLoader(true);
    const restaurantId: string = this.props.match.params.uuid;
    const payLoad = {
      credentials: {
        restaurantuuid: restaurantId,
      },
    };
    SettingsService.getSettings(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        this.showLoader(false);
        if (response.status === 200) {
          this.setState({
            hourlyCooksRate: commonService.displayDigitalAmount(
              response.data?.hourly_cooks_rate
                ? response.data.hourly_cooks_rate
                : 0
            ),
            currentSettingsData: response.data.data,
          });
        } else {
          this.showErrorToast(response.flash);
        }
      })
      .catch(() => {
        this.showLoader(false);
        this.showErrorToast(null, true);
      });
  };

  save = () => {
    this.props.settingsAdd({
      restaurantuuid: this.props.match.params.uuid,
      data: this.state.currentSettingsData,
    });
  };

  showErrorToast = (errors: any, isStatic: boolean = false) => {
    if (isStatic) {
      commonService.toastService(
        this.props.t("common.updatedFailed"),
        "danger"
      );
    } else {
      const json = JSON.stringify(errors);
      const errorsString = json.replace(/[{}]/g, "");
      commonService.toastService("", "danger", errorsString);
    }
  };

  showLoader = (state: boolean) => {
    this.setState({ isFetching: state });
  };
  render() {
    const minorUnit = JSON.parse(
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
    )?.minor_units;
    const currencyCode = JSON.parse(
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
    )?.currency_code;
    const restaurantLanguage: any = localStorage.getItem("i18nextLng");
    const { t } = this.props;
    return (
      <>
        <LoaderComponent
          display={this.state.isFetching || this.props.isFetching}
        />
        <div className="container-fluid">
          <StaffHeaderComponent staff={"resources"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <UsersLeftComponent
                display={"resource-cost"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <div className="white-box mb-3">
                <h4>{t("resourceCost.resourceCost")}</h4>

                <div className="child-box mb-3">
                  <Form>
                    <FormGroup controlId="hourlyCooksRate" row>
                      <Col sm="4" lg="4">
                        <Label className="ml-4 mr-1 mt-2 pl-2">
                          {t("common.hourlyCooksRate")}
                        </Label>
                      </Col>
                      <Col
                        sm="6"
                        lg="6"
                        className="d-flex align-items-center position-relative"
                      >
                        <span className="b-currency-code mx-2 position-absolute">
                          {currencyCode}
                        </span>

                        <CurrencyInput
                          intlConfig={{
                            locale: restaurantLanguage,
                            currency: currencyCode,
                          }}
                          className="form-control w-100 "
                          decimalScale={minorUnit}
                          decimalsLimit={minorUnit}
                          value={this.state.hourlyCooksRate}
                          onValueChange={this.handleHourlyCooksRate}
                          prefix=" "
                        />
                      </Col>
                    </FormGroup>

                    <div className="d-flex justify-content-end">
                      <Button color="info" onClick={this.save}>
                        {t("common.save")}
                      </Button>{" "}
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
const mapStateToProps: any = (state: any) => {
  let isFetching = state.settingsadd.isFetching;
  let failure = state.settings.failure;
  return {
    settingsadd: state.settingsadd.data,
    isFetching: isFetching,
    failure: failure,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    settingsAdd: (credentials: any) => {
      dispatch(settingsAdd(credentials));
    },
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ResourceCostPage))
);
