import { call, put, takeLatest } from "redux-saga/effects";
import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  choiceFailure,
  choiceItemUpdateFailure,
  choiceItemUpdateSuccess,
  choiceSuccess,
  ingredientClassFailure,
  ingredientClassSuccess,
} from "../redux/actions/choice.item.action";
import * as types from "../redux/actions/types";
import choice from "../services/choice.item.service";
import handleResponse from "../services/response.service";

const choiceCall = (params: any) => choice.choice(RESTAURANT_REQUEST, params);
const ingredientClassCall = (params: any) =>
  choice.ingredientClass(RESTAURANT_REQUEST, params);

const updateChoiceItemCall = (params: any) =>
  choice.updateChoiceItem(RESTAURANT_REQUEST, params);

function* handleChoiceRequest(params: object) {
  try {
    let result;
    result = yield call(choiceCall, params);
    yield handleChoice200(result);
  } catch (error) {
    throw error;
  }
}

const handleChoice200 = function* handleChoice200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(choiceSuccess(result.data));
  } else {
    yield put(choiceFailure(result.data));
    handleResponse(result);
  }
};

function* ingredientClassRequest(params: object) {
  try {
    let result;
    result = yield call(ingredientClassCall, params);
    yield ingredientClass200(result);
  } catch (error) {
    throw error;
  }
}

const ingredientClass200 = function* ingredientClass200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(ingredientClassSuccess(result.data));
  } else {
    yield put(ingredientClassFailure(result.data));
    handleResponse(result);
  }
};

function* handleChoiceUpdate(params: object) {
  try {
    let result;
    result = yield call(updateChoiceItemCall, params);
    yield handleChoiceUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleChoiceUpdate200 = function* handleChoiceUpdate200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(choiceItemUpdateSuccess(result.data));
  } else {
    yield put(choiceItemUpdateFailure(result.data));
  }
  handleResponse(result);
};

export default function* choiceSaga() {
  yield takeLatest(types.CHOICE.REQUEST, handleChoiceRequest);
  yield takeLatest(types.INGREDIENT_CLASS.REQUEST, ingredientClassRequest);
  yield takeLatest(types.CHOICE_UPDATE.REQUEST, handleChoiceUpdate);
}
