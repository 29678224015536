import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function chatInitRequest(): ActionModel {
  return {
    type: types.CHAT_INIT.REQUEST,
  };
}

export function chatInitSuccess(data: object): ActionModel {
  return {
    type: types.CHAT_INIT.SUCCESS,
    data,
  };
}

export function chatInitFailure(err: object): ActionModel {
  return {
    type: types.CHAT_INIT.FAILURE,
    err,
  };
}

export function chatUpdate(credentials: object): ActionModel {
  return {
    type: types.CHAT_UPDATE.REQUEST,
    credentials,
  };
}

export function chatUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.CHAT_UPDATE.SUCCESS,
    data,
  };
}

export function chatUpdateFailure(err: object): ActionModel {
  return {
    type: types.CHAT_UPDATE.FAILURE,
    err,
  };
}
