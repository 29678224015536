import "../../../styles/storage.scss";

import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useTranslation } from "react-i18next";

export default function StorageRackEditCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <Modal isOpen={props.state.editstoragerackmodal}>
      <ModalHeader
        toggle={props.editStorageRackToggle}
        className="modal-header"
      >
        {t("rack.editRack")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstname">{t("rack.rackName")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name="editstoragerackname"
              autoComplete="editstoragerackname"
              placeholder={t("rack.enterRackName")}
              value={props.state.editstoragerackname}
              onChange={props.handleChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.editStorageRackToggle}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={props.saveStorageRackEdit}
          disabled={
            !props.state.editstoragerackname || !!props.state.isFetching
          }
        >
          {t("common.save")}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
