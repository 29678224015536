import "../../../../styles/navigation-left.scss";

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button } from "reactstrap";

import i18next from "i18next";
import { withTranslation } from "react-i18next";

import { ISSUPERADMIN } from "../../../../constant/constant";

class RestaurantLeftNavComponent extends Component<any> {
  render() {
    const { t } = this.props;
    let isSuperAdmin: any = localStorage.getItem(ISSUPERADMIN);
    return (
      <div className="b-sticky-below-header">
        <Link to="/restaurant">
          <Button
            className={
              this.props.display === "restaurants"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("restaurant.restaurants")}
          </Button>
        </Link>
        {isSuperAdmin === "true" && (
          <Link to="/channels">
            <Button
              className={
                this.props.display === "channels"
                  ? "btn-dark mb-3"
                  : "btn-light mb-3"
              }
            >
              {t("restaurant.channels")}
            </Button>
          </Link>
        )}
      </div>
    );
  }
}

export default withTranslation()(withRouter(RestaurantLeftNavComponent));
