import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function printerProtocolRequest(credentials: object): ActionModel {
  return {
    type: types.PRINTER_PROTOCOL.REQUEST,
    credentials
  };
}

export function printerProtocolSuccess(data: object): ActionModel {
  return {
    type: types.PRINTER_PROTOCOL.SUCCESS,
    data
  };
}

export function printerProtocolFailure(err: object): ActionModel {
  return {
    type: types.PRINTER_PROTOCOL.FAILURE,
    err
  };
}

export function printerProtocolUpdate(credentials: object): ActionModel {
  return {
    type: types.PRINTER_PROTOCOL_UPDATE.REQUEST,
    credentials
  };
}

export function printerProtocolUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.PRINTER_PROTOCOL_UPDATE.SUCCESS,
    data
  };
}

export function printerProtocolUpdateFailure(err: object): ActionModel {
  return {
    type: types.PRINTER_PROTOCOL_UPDATE.FAILURE,
    err
  };
}

export function printerProtocolAdd(credentials: object): ActionModel {
  return {
    type: types.PRINTER_PROTOCOL_ADD.REQUEST,
    credentials
  };
}

export function printerProtocolAddSuccess(data?: object): ActionModel {
  return {
    type: types.PRINTER_PROTOCOL_ADD.SUCCESS,
    data
  };
}

export function printerProtocolAddFailure(err: object): ActionModel {
  return {
    type: types.PRINTER_PROTOCOL_ADD.FAILURE,
    err
  };
}

export function printerProtocolRemove(credentials: object): ActionModel {
  return {
    type: types.PRINTER_PROTOCOL_REMOVE.REQUEST,
    credentials
  };
}

export function printerProtocolRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.PRINTER_PROTOCOL_REMOVE.SUCCESS,
    data
  };
}

export function printerProtocolRemoveFailure(err: object): ActionModel {
  return {
    type: types.PRINTER_PROTOCOL_REMOVE.FAILURE,
    err
  };
}
