import { ComponentModel } from '../../models/component.model';
import * as types from '../actions/types';


const initialState: ComponentModel = {
  isFetching: false,
  data: {},
  error: {},
  failure: false,
};

export default function componentReducer(state = initialState, action: any): ComponentModel {
  switch (action.type) {
    case types.COMPONENT.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {}
      };
    case types.COMPONENT.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {}
      };
    case types.COMPONENT.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err
      };
    default:
      return state;
  }
}