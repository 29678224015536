import apisauce from "apisauce";

import { BASE_URL } from "../config/app.config";
import commonService from "./common.service";
import { REFRESH_TOKEN } from "../config/api.config";

const create = (baseURL: any = BASE_URL) => {
  const api = apisauce.create({
    baseURL,
    timeout: 100000,
  });
  let isRefreshing: any = false;
  let failedQueue: any = [];

  const apiArrayBuffer = apisauce.create({
    baseURL,
    responseType: "arraybuffer",
    timeout: 100000,
  });

  api.setHeaders(commonService.header());

  const headers: any = async () => {
    return commonService.header();
  };
  headers().then((item: any) => {
    api.setHeaders(item);
  });

  const get = <T, U = T>(url: string) => {
    api.setHeaders(commonService.header());
    return api.get<T, U>(url);
  };

  const post = (url: string, body?: any, isArrayBuffer?: boolean) => {
    if (isArrayBuffer) {
      apiArrayBuffer.setHeaders(commonService.header());
      return apiArrayBuffer.post(url, body);
    }
    api.setHeaders(commonService.header());
    return api.post(url, body);
  };

  const put = (url: string, body?: any) => {
    api.setHeaders(commonService.header());
    return api.put(url, body);
  };

  const remove = (url: string, body?: any) => {
    api.setHeaders(commonService.header());
    return api.delete(url, body);
  };

  api.axiosInstance.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return api.axiosInstance(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise((resolve, reject) => {
          api.axiosInstance
            .post(REFRESH_TOKEN, {
              refresh_token: localStorage.getItem("REFRESH_TOKEN"),
            })
            .then((response: any) => {
              localStorage.setItem(
                "ACCESS_TOKEN",
                response.data.data.access_token
              );
              localStorage.setItem(
                "REFRESH_TOKEN",
                response.data.data.refresh_token
              );
              api.axiosInstance.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${response.data.data.access_token}`;
              originalRequest.headers.Authorization = `Bearer ${response.data.data.access_token}`;

              resolve(api.axiosInstance(originalRequest));
              processQueue(null, response.data.data.access_token);
            })
            .catch((err: any) => {
              processQueue(err, null);
              reject(err);
              commonService.clearUserData();
              window.open(`/login`, "_self");
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }
      return Promise.reject(error);
    }
  );

  const processQueue = (error: any, token = null) => {
    failedQueue.forEach((prom: any) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    failedQueue = [];
  };

  return {
    get,
    post,
    remove,
    put,
    api,
  };
};

export default {
  create,
};
