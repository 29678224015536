import apiCall from "./api.call.config";

async function tags(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.credentials.restaurantuuid + "/tag?sort=name:asc"
    );
  } catch (e) {
    throw e;
  }
}

async function addTag(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/tag",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function assignTag(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/tag",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function removeTag(url: string, params: any) {
  try {
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/tag/" +
        params.credentials.uuid
    );
  } catch (e) {
    throw e;
  }
}

async function updateTag(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/tag/" +
        params.credentials.uuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  tags,
  addTag,
  assignTag,
  removeTag,
  updateTag,
};
