export interface RoleModel {
    readonly isFetching: boolean;
    readonly data: object;
    readonly error: object;
    readonly failure: boolean;
}
export interface RoleState {
    rolelist: any;
    locales: string;
    role_rights: any;
    isFetching: boolean;
    uuid: string;
    isUpdated: boolean,
    componentlist: any,
    name: string,
    locale: string;
    editmodal: boolean,
    addmodal: boolean,
    open: boolean,
    right_uuid: any,
    addrightmodal: boolean,
    manageright: any,
    managemodal: boolean,
    roler_uuid: string,
    defaultChecked: boolean,
    rights_array: any
}

export const RoleInitState = {
    isFetching: false,
    rolelist: '',
    uuid: '',
    isUpdated: false,
    componentlist: '',
    name: '',
    locales: '',
    role_rights: '',
    right_uuid: '',
    locale: '',
    editmodal: false,
    addrightmodal: false,
    addmodal: false,
    open: false,
    manageright: [],
    managemodal: false,
    roler_uuid: '',
    defaultChecked: false,
    rights_array: [],
};