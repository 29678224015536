import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import Nouislider from "nouislider-react";
import Select from "react-select";

import "nouislider/distribute/nouislider.css";
import "../../../styles/tag-assign.scss";

import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function ImportIngredientCard(props: any) {
  const { t, i18n } = useTranslation();
  const [isFreeText, setIsFreeText] = useState(false);
  const [substituteList, setSubstituteList] = useState([]);

  useEffect(() => {
    return () => {};
  }, []);
  // TODO Need cleanup due to SR-1044 changes
  useEffect(() => {});

  const onModalOpen = () => {
    setSubstituteList([]);
  };

  return (
    <Modal isOpen={props.isOpen} onOpened={onModalOpen}>
      <ModalHeader toggle={props.toggle} className="modal-header">
        {t("substituteGroup.assignSubstituteGroup")}
      </ModalHeader>
      <ModalBody>
        <Form className="tag-assign">
          <FormGroup row className="p-0">
            <Label className="text-right" for="tagName" sm={4}>
              {t("ingredient.ingredientClass")}
            </Label>
            <Col sm={8}>
              <Select
                maxMenuHeight={200}
                name="substituteList"
                onChange={(val: any) => {
                  setSubstituteList(val);
                }}
                options={props.options}
                value={substituteList}
                isMulti
                placeholder={t("common.select")}
              />
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={() => {
            props.save(substituteList);
          }}
        >
          {t("common.save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
