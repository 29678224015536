import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function printerRequest(credentials: object): ActionModel {
  return {
    type: types.PRINTER.REQUEST,
    credentials
  };
}

export function printerSuccess(data: object): ActionModel {
  return {
    type: types.PRINTER.SUCCESS,
    data
  };
}

export function printerFailure(err: object): ActionModel {
  return {
    type: types.PRINTER.FAILURE,
    err
  };
}

export function printerUpdate(credentials: object): ActionModel {
  return {
    type: types.PRINTER_UPDATE.REQUEST,
    credentials
  };
}

export function printerUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.PRINTER_UPDATE.SUCCESS,
    data
  };
}

export function printerUpdateFailure(err: object): ActionModel {
  return {
    type: types.PRINTER_UPDATE.FAILURE,
    err
  };
}

export function printerAdd(credentials: object): ActionModel {
  return {
    type: types.PRINTER_ADD.REQUEST,
    credentials
  };
}

export function printerAddSuccess(data?: object): ActionModel {
  return {
    type: types.PRINTER_ADD.SUCCESS,
    data
  };
}

export function printerAddFailure(err: object): ActionModel {
  return {
    type: types.PRINTER_ADD.FAILURE,
    err
  };
}

export function printerRemove(credentials: object): ActionModel {
  return {
    type: types.PRINTER_REMOVE.REQUEST,
    credentials
  };
}

export function printerRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.PRINTER_REMOVE.SUCCESS,
    data
  };
}

export function printerRemoveFailure(err: object): ActionModel {
  return {
    type: types.PRINTER_REMOVE.FAILURE,
    err
  };
}
