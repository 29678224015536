import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Col, CustomInput, FormGroup, Input, Label, Row } from "reactstrap";
import { InventoryFilterItemRowProps } from "../../models/inventory-filter-settings.model";

function InventoryFilterItemRow(props: InventoryFilterItemRowProps) {
  const { itemId, label, metaData, actions } = props;
  const { defaultItem, formData, optionList } = metaData;
  const { handleChange } = actions;

  const { t, i18n } = useTranslation();

  return (
    <Row className="py-2 mb-2 child-box align-items-center" key={label}>
      <Label sm={4} lg={4}>
        {label}:
      </Label>
      <Col sm={3} lg={2}>
        <div
          className="d-inline-flex align-items-center b-cursor-p"
          onClick={(data) => handleChange(data, "enable", itemId)}
        >
          <span className="mr-2">{t("common.active")}</span>
          <CustomInput
            checked={formData?.enable || false}
            id={"enable" + itemId}
            label=""
            name="override"
            type="switch"
          />
        </div>
      </Col>
      <Col sm={3} lg={2}>
        <div
          className="d-inline-flex align-items-center b-cursor-p"
          onClick={(data) => handleChange(data, "pinned", itemId)}
        >
          <span className="mr-2">{t("common.pinned")}</span>
          <CustomInput
            checked={formData?.pinned || false}
            id={"pinned" + itemId}
            label=""
            name="override"
            type="switch"
          />
        </div>{" "}
      </Col>
      {typeof defaultItem !== "undefined" && (
        <Col sm={3} lg={4} className="align-items-center d-flex">
          <span className="mr-2">{t("common.default")}</span>
          <Select
            options={optionList}
            autosize={true}
            menuPlacement="auto"
            menuPosition="fixed"
            className="w-100"
            styles={{
              control: (provided) => {
                return {
                  ...provided,
                  width: "100%",
                };
              },
              menu: (provided) => {
                return {
                  ...provided,
                  color: "#000",
                  fontWeight: 400,
                  fontSize: "16px",
                };
              },
              placeholder: (provided) => {
                return {
                  ...provided,
                  fontWeight: 400,
                  fontSize: "16px",
                };
              },
            }}
            onChange={(data) => handleChange(data, "default", itemId)}
            value={formData?.default || null}
            isMulti
          />
        </Col>
      )}
    </Row>
  );
}

export default InventoryFilterItemRow;
