import { RecipeModel } from '../../models/recipe.model';
import * as types from '../actions/types';

const initialState: RecipeModel = {
  isFetching: false,
  data: {},
  error: {},
  failure: false,
};
export default function recipeReducer(state = initialState, action: any): RecipeModel {
  switch (action.type) {
    case types.RECIPE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {}
      };
    case types.RECIPE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {}
      };
    case types.RECIPE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err
      };
    default:
      return state;
  }
}
