export interface TableModel {
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
  readonly isFetching: boolean;
}
export interface TableState {
  isEdit: boolean;
  isFetching: boolean;
  isUpdated: boolean;
  name: any;
  open: boolean;
  restaurantuuid: string;
  showModal: boolean;
  table: any;
  tableName: string;
  tableStation: string;
  uuid: string;
}

export const TableInitState = {
  isEdit: false,
  isFetching: false,
  isUpdated: false,
  name: "",
  open: false,
  restaurantuuid: "",
  showModal: false,
  table: [],
  tableName: "",
  tableStation: "",
  uuid: "",
};
