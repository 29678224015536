import { call, put, takeLatest } from "redux-saga/effects";
import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  serversFailure,
  serversSuccess,
  serversUpdateFailure,
  serversUpdateSuccess,
} from "../redux/actions/servers.action";
import * as types from "../redux/actions/types";

import handleResponse from "../services/response.service";
import server from "../services/servers.service";

const serverCall = (params: any) => server.servers(RESTAURANT_REQUEST, params);
const updateServerCall = (params: any) =>
  server.updateServers(RESTAURANT_REQUEST, params);

function* handleServersRequest(params: object) {
  try {
    let result;
    result = yield call(serverCall, params);
    yield handleServers200(result);
  } catch (error) {
    throw error;
  }
}

const handleServers200 = function* handleServers200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(serversSuccess(result.data));
  } else {
    yield put(serversFailure(result.data));
    handleResponse(result);
  }
};

function* handleServersUpdate(params: object) {
  try {
    let result;
    result = yield call(updateServerCall, params);
    yield handleServersUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleServersUpdate200 = function* handleServersUpdate200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(serversUpdateSuccess(result.data));
  } else {
    yield put(serversUpdateFailure(result.data));
  }
  handleResponse(result);
};

export default function* serversSaga() {
  yield takeLatest(types.SERVERS.REQUEST, handleServersRequest);
  yield takeLatest(types.SERVERS_UPDATE.REQUEST, handleServersUpdate);
}
