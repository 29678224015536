import apiCall from "./api.call.config";

async function courseList(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.credentials.restaurantuuid + "/course-list"
    );
  } catch (e) {
    throw e;
  }
}

async function updateCourseList(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/course-list",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  courseList,
  updateCourseList,
};
