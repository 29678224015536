import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function printerMessageRequest(credentials: object): ActionModel {
  return {
    type: types.PRINTER_MESSAGE.REQUEST,
    credentials
  };
}

export function printerMessageSuccess(data: object): ActionModel {
  return {
    type: types.PRINTER_MESSAGE.SUCCESS,
    data
  };
}

export function printerMessageFailure(err: object): ActionModel {
  return {
    type: types.PRINTER_MESSAGE.FAILURE,
    err
  };
}

export function printerMessageRemove(credentials: object): ActionModel {
  return {
    type: types.PRINTER_MESSAGE_REMOVE.REQUEST,
    credentials
  };
}

export function printerMessageRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.PRINTER_MESSAGE_REMOVE.SUCCESS,
    data
  };
}

export function printerMessageRemoveFailure(err: object): ActionModel {
  return {
    type: types.PRINTER_MESSAGE_REMOVE.FAILURE,
    err
  };
}

export function printerMessageRemoveAll(credentials: object): ActionModel {
  return {
    type: types.PRINTER_MESSAGE_REMOVE_ALL.REQUEST,
    credentials
  };
}

export function printerMessageRemoveAllSuccess(data?: object): ActionModel {
  return {
    type: types.PRINTER_MESSAGE_REMOVE_ALL.SUCCESS,
    data
  };
}

export function printerMessageRemoveAllFailure(err: object): ActionModel {
  return {
    type: types.PRINTER_MESSAGE_REMOVE_ALL.FAILURE,
    err
  };
}
