import { PROFILEUUID } from "../constant/constant";
import apiCall from "../services/api.call.config";

async function getProfile(url: string) {
  try {
    return await apiCall.get(
      url + localStorage.getItem(PROFILEUUID) + "/details"
    );
  } catch (e) {
    throw e;
  }
}
async function updateProfile(url: string, params: any) {
  try {
    return await apiCall.put(
      url + localStorage.getItem(PROFILEUUID) + "/personal-information",
      params.credentials
    );
  } catch (e) {
    throw e;
  }
}

export default {
  getProfile,
  updateProfile,
};
