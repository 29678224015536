import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import {
    addEventFailure,
    addEventSuccess,
    eventFailure,
    eventListFailure,
    eventListSuccess,
    eventSuccess,
    eventUpdateFailure,
    eventUpdateSuccess,
} from '../redux/actions/pos.action';
import * as types from '../redux/actions/types';
import event from '../services/pos.service';
import handleResponse from '../services/response.service';

const getEventListCall = (params: any) => event.getEventList(RESTAURANT_REQUEST, params);
const addEventCall = (params: any) => event.addEventList(RESTAURANT_REQUEST, params);
const getEventCall = (params: any) => event.getEvent(RESTAURANT_REQUEST, params);
const updateEventCall = (params: any) => event.updateEvent(RESTAURANT_REQUEST, params);

function* handleGetEventList(params: object) {
    try {
        let result;
        result = yield call(getEventListCall, params);
        yield handleGetEventList200(result);
    } catch (error) {
        throw error;
    }
}

const handleGetEventList200 = function* handleGetEventList200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(eventListSuccess(result.data));
    } else {
        yield put(eventListFailure(result.data));
        handleResponse(result);
    }
}

function* handleAddEvent(params: object) {
    try {
        let result;
        result = yield call(addEventCall, params);
        yield handleAddEvent200(result);
    } catch (error) {
        throw error;
    }
}

const handleAddEvent200 = function* handleAddEvent200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 201) {
        yield put(addEventSuccess(result.data));
    } else {
        yield put(addEventFailure(result.data));
        handleResponse(result);
    }
}

function* handleGetEventRequest(params: object) {
    try {
        let result;
        result = yield call(getEventCall, params);
        yield handleGetEvent200(result);
    } catch (error) {
        throw error;
    }
}

const handleGetEvent200 = function* handleGetEvent200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(eventSuccess(result.data));
    } else {
        yield put(eventFailure(result.data));
        handleResponse(result);
    }
}

function* handleEventUpdate(params: object) {
    try {
        let result;
        result = yield call(updateEventCall, params);
        yield handleEventUpdate200(result);
    } catch (error) {
        throw error;
    }
}

const handleEventUpdate200 = function* handleEventUpdate200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(eventUpdateSuccess(result.data));
    } else {
        yield put(eventUpdateFailure(result.data));
    }
    handleResponse(result);
}

export default function* eventListSaga() {
    yield takeLatest(types.LIST_EVENT.REQUEST, handleGetEventList);
    yield takeLatest(types.ADD_EVENT.REQUEST, handleAddEvent);
    yield takeLatest(types.GET_EVENT.REQUEST, handleGetEventRequest);
    yield takeLatest(types.UPDATE_EVENT.REQUEST, handleEventUpdate);
}
