import { call, put, takeLatest } from "redux-saga/effects";

import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  tableAddSuccess,
  tableAddFailure,
  tableRemoveSuccess,
  tableRemoveFailure,
  tableUpdateSuccess,
  tableUpdateFailure,
  tablesFailure,
  tablesSuccess,
} from "../redux/actions/table.action";
import * as types from "../redux/actions/types";
import tables from "../services/table.service";
import handleResponse from "../services/response.service";

const tablesCall = (params: object) =>
  tables.tables(RESTAURANT_REQUEST, params);
const addTableCall = (params: any) =>
  tables.addTable(RESTAURANT_REQUEST, params);
const removeTableCall = (params: any) =>
  tables.removeTable(RESTAURANT_REQUEST, params);
const updateTableCall = (params: any) =>
  tables.updateTable(RESTAURANT_REQUEST, params);

function* handleTablesRequest(params: object) {
  try {
    let result;
    result = yield call(tablesCall, params);
    yield handleTables200(result);
  } catch (error) {
    throw error;
  }
}

const handleTables200 = function* handleTables200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(tablesSuccess(result.data));
  } else {
    yield put(tablesFailure(result.data));
    handleResponse(result);
  }
};

function* handleTableAdd(params: object) {
  try {
    let result;
    result = yield call(addTableCall, params);
    yield handleTableAdd200(result);
  } catch (error) {
    throw error;
  }
}

const handleTableAdd200 = function* handleTableAdd200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(tableAddSuccess(result.data));
  } else {
    yield put(tableAddFailure(result.data));
  }
  handleResponse(result);
};

function* handleTableRemove(params: object) {
  try {
    let result;
    result = yield call(removeTableCall, params);
    yield handleTableRemove200(result);
  } catch (error) {
    throw error;
  }
}

const handleTableRemove200 = function* handleTableRemove200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(tableRemoveSuccess(result.data));
  } else {
    yield put(tableRemoveFailure(result.data));
  }
  handleResponse(result);
};

function* handleTableUpdate(params: object) {
  try {
    let result;
    result = yield call(updateTableCall, params);
    yield handleTableUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleTableUpdate200 = function* handleTableUpdate200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(tableUpdateSuccess(result.data));
  } else {
    yield put(tableUpdateFailure(result.data));
  }
  handleResponse(result);
};

export default function* tablesSaga() {
  yield takeLatest(types.TABLE_ADD.REQUEST, handleTableAdd);
  yield takeLatest(types.TABLES.REQUEST, handleTablesRequest);
  yield takeLatest(types.TABLE_REMOVE.REQUEST, handleTableRemove);
  yield takeLatest(types.TABLE_UPDATE.REQUEST, handleTableUpdate);
}
