import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { GRAY, GREEN } from "../../../constant/constant";
import "../../../styles/printer.scss";

class PrinterProtocolCard extends Component<any> {
  render() {
    const { t } = this.props;
    return (
      <div className="child-box">
        <Row className="app-row-padding">
          <Col>
            <h6>{this.props.printer.name}</h6>
          </Col>
          <div style={{ float: "right" }}>
            <span
              onClick={this.props.setPrinterEditable}
              className="pointer-cursor"
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.edit")}
            >
              <FontAwesomeIcon
                style={{ color: GREEN, fontSize: 20 }}
                icon={faEdit}
              />
            </span>
            <span
              style={{ margin: 10 }}
              onClick={this.props.setPrinterDelete}
              className="pointer-cursor"
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.delete")}
            >
              <FontAwesomeIcon
                style={{ color: GRAY, fontSize: 20 }}
                icon={faTrashAlt}
              />
            </span>
          </div>
        </Row>
        <Row className="app-row-padding">
          <Col sm={{ size: 2, offset: 1 }} xs="auto">
            {t("common.format")} :
          </Col>
          <Col sm={{ size: "10", offset: 1 }} xs="8">
            <span>{this.props.printer.format}</span>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withTranslation()(PrinterProtocolCard);
