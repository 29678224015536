import apiCall from "./api.call.config";

async function choice(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.uuid +
        "/recipe/list?sort=name:asc&archive=true&type=4"
    );
  } catch (e) {
    throw e;
  }
}

async function ingredientClass(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.uuid +
        "/recipe/list?sort=name:asc&archive=true&type=3"
    );
  } catch (e) {
    throw e;
  }
}

async function updateChoiceItem(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.uuid +
        "/item",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  choice,
  ingredientClass,
  updateChoiceItem,
};
