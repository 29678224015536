import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import {
    taskStepAddFailure,
    taskStepAddSuccess,
    taskStepRemoveFailure,
    taskStepRemoveSuccess,
    taskStepUpdateFailure,
    taskStepUpdateSuccess,
} from '../redux/actions/task.step.action';
import * as types from '../redux/actions/types';
import handleResponse from '../services/response.service';
import taskstep from '../services/task.step.service';

const addTaskStepCall = (params: any) => taskstep.addTaskStep(RESTAURANT_REQUEST, params);
const updateTaskStepCall = (params: any) => taskstep.updateTaskStep(RESTAURANT_REQUEST, params);
const removeTaskStepCall = (params: any) => taskstep.removeTaskStep(RESTAURANT_REQUEST, params);

function* handleTaskStepAdd(params: object) {
    try {
        let result;
        result = yield call(addTaskStepCall, params);
        yield handleTaskStepAdd200(result);
    } catch (error) {
        throw error;
    }
}

const handleTaskStepAdd200 = function* handleTaskStepAdd200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskStepAddSuccess(result.data));
    } else {
        yield put(taskStepAddFailure(result.data));
    }
    handleResponse(result);
}

function* handleTaskStepUpdate(params: object) {
    try {
        let result;
        result = yield call(updateTaskStepCall, params);
        yield handleTaskStepUpdate200(result);
    } catch (error) {
        throw error;
    }
}

const handleTaskStepUpdate200 = function* handleTaskStepUpdate200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskStepUpdateSuccess(result.data));
    } else {
        yield put(taskStepUpdateFailure(result.data));
    }
    handleResponse(result);
}

function* handleTaskStepRemove(params: object) {
    try {
        let result;
        result = yield call(removeTaskStepCall, params);
        yield handleTaskStepRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handleTaskStepRemove200 = function* handleTaskStepRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskStepRemoveSuccess(result.data));
    } else {
        yield put(taskStepRemoveFailure(result.data));
    }
    handleResponse(result);
}

export default function* taskStepSaga() {
    yield takeLatest(types.TASK_STEP_ADD.REQUEST, handleTaskStepAdd);
    yield takeLatest(types.TASK_STEP_UPDATE.REQUEST, handleTaskStepUpdate);
    yield takeLatest(types.TASK_STEP_REMOVE.REQUEST, handleTaskStepRemove);
}