import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import {
  ServiceSetOverviewListWrapperProps,
  serviceSetOverviewMode,
} from "../../models/serviceset.model";
import commonService from "../../services/common.service";
import ServiceSetListCard from "./ServiceSetListCard";
import { useDispatch, useSelector } from "react-redux";
import {
  settingsAdd,
  settingsRequest,
} from "../../redux/actions/stations.action";
import { useParams } from "react-router-dom";
import { DELIVERY_SCHEDULE, GRAY } from "../../constant/constant";
import { isValidCron } from "cron-validator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

function ServiceSetOverviewListWrapper(
  props: ServiceSetOverviewListWrapperProps
) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { uuid } = useParams<{ uuid: string }>();
  const { actions, metaData, serviceSets } = props;

  const [selectedServiceSets, setSelectedServiceSets] = useState<any>([]);
  const [filteredServiceSets, setFilteredServiceSets] = useState<any>([]);
  const [deliverySchedule, setDeliverySchedule] = useState<string>("");
  const [isValidCrontab, setIsValidCrontab] = useState<boolean>(true);

  const [state, setState] = useState({
    settings: { data: [] },
  });

  useEffect(() => {
    setState((prevState) => ({ ...prevState, restaurantuuid: uuid }));
    dispatch(settingsRequest({ restaurantuuid: uuid }));
  }, [dispatch, uuid]);

  const settings = useSelector((state: any) => state.settings.data);

  useEffect(() => {
    setDeliverySchedule(
      settings && settings[DELIVERY_SCHEDULE] ? settings[DELIVERY_SCHEDULE] : ""
    );
  }, [settings]);

  useEffect(() => {
    filterServiceSets(selectedServiceSets);
  }, [serviceSets, metaData.recipeServiceSetsData, metaData.activeRecipe]);

  const filterServiceSets = (filters: any) => {
    const filtersArray: Array<string> =
      filters?.map((filter: any) => filter.value) || [];
    if (filtersArray.length > 0) {
      const filteredItems: Array<any> = serviceSets.filter((serv: any) =>
        filtersArray.includes(serv.uuid)
      );
      updateServiceSetsWithAssignee(filteredItems);
    } else {
      updateServiceSetsWithAssignee(serviceSets);
    }
  };

  const handleServiceSetFilterSelect = (serviceSets: Array<any>) => {
    setSelectedServiceSets(serviceSets);
    filterServiceSets(serviceSets);
  };

  const getOptions = (optionArray: Array<any>) => {
    const options: Array<any> = [];
    optionArray.forEach((item: any) => {
      const option: any = {
        value: item.uuid,
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          item.locales
        )["name"],
      };

      options.push(option);
    });

    return options;
  };

  const handleServiceSetSelection = (serviceSetId: string) => {
    actions.handleServiceSetSelection(serviceSetId);
  };

  const serviceSetsOptions: Array<any> = useMemo(
    () => getOptions(serviceSets),
    [serviceSets]
  );

  const updateServiceSetsWithAssignee = (serviceSets: Array<any>) => {
    let serviceSetsArray: Array<any> = _.cloneDeep(serviceSets);

    serviceSetsArray = serviceSetsArray.map((serviceSet: any) => {
      const recipeServiceSets: Array<any> =
        metaData.recipeServiceSetsData[metaData?.activeRecipe] || [];
      const name: any = commonService.applyLocalization(
        "restaurant",
        "name",
        serviceSet.locales
      );
      serviceSet.name = name.name;
      if (metaData.activeRecipe !== "") {
        if (recipeServiceSets.includes(serviceSet.uuid)) {
          serviceSet.isAssigned = true;
        } else {
          serviceSet.isAssigned = false;
        }
      }
      return serviceSet;
    });

    setFilteredServiceSets(
      _.sortBy(serviceSetsArray, [(item: any) => item.name])
    );
  };

  const saveDeliverySchedule = () => {
    dispatch(
      settingsAdd({
        restaurantuuid: uuid,
        data: { [DELIVERY_SCHEDULE]: deliverySchedule },
      })
    );
  };

  const handleInputChange = (event: any) => {
    setIsValidCrontab(
      isValidCron(event.target.value, {
        seconds: true,
        alias: true,
        allowBlankDay: true,
      })
    );
    setDeliverySchedule(event.target.value);
  };

  return (
    <div className="m-2 border h-100 p-3">
      <div className="b-sticky-below-header bg-white border-bottom mx-n3 mb-2">
        <Form className="px-3">
          <FormGroup row>
            <Label for="deliverySchedule" md={4} className="text-right-md">
              {t("common.deliverySchedule")}{" "}
              <FontAwesomeIcon
                style={{ color: GRAY, cursor: "pointer" }}
                icon={faInfoCircle}
                onClick={() =>
                  window.open(
                    "https://crontab.guru/",
                    "Crontab Help",
                    "width=1200,height=600"
                  )
                }
              />
            </Label>
            <Col md={6}>
              <Input
                type="text"
                name="deliverySchedule"
                placeholder={t("common.deliverySchedule")}
                value={deliverySchedule}
                onChange={handleInputChange}
                invalid={!isValidCrontab}
              />
            </Col>
            <Col md={2}>
              <Button
                size="sm"
                color="info"
                onClick={saveDeliverySchedule}
                disabled={!deliverySchedule || !isValidCrontab}
                className="mt-1"
              >
                {t("common.save")}
              </Button>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="serviceSets" md={4} className="text-right-md">
              {t("serviceSet.serviceSets")}
            </Label>
            <Col md={8}>
              <Select
                value={selectedServiceSets}
                onChange={handleServiceSetFilterSelect}
                options={serviceSetsOptions}
                isMulti
                maxMenuHeight={200}
                placeholder={t("common.select")}
              />
            </Col>
          </FormGroup>
        </Form>
      </div>
      <div className="h-100">
        {filteredServiceSets.map((serviceSet: any, index: any) => {
          const actionsFns: any = {
            handleCancel: actions.handleServiceSetCancel,
            handleDelete: actions.handleServiceSetDelete,
            handleEdit: actions.handleServiceSetEdit,
            handleRecipeEdit: actions.handleServiceSetRecipeEdit,
            handleSave: actions.handleServiceSetSave,
            handleCopy: actions.handleServiceSetCopy,
            handleSelection: handleServiceSetSelection,
          };
          const selections: any = {
            isEdit:
              metaData.pageMode === serviceSetOverviewMode.SERVICE_SET_EDIT,
            isAssignee:
              metaData.pageMode === serviceSetOverviewMode.RECIPE_EDIT,
            isRecipeSelected: metaData.activeRecipe !== "",
            isSelected: metaData.activeServiceSet === serviceSet.uuid,
          };
          return (
            <ServiceSetListCard
              key={index}
              actions={actionsFns}
              selections={selections}
              serviceSet={serviceSet}
              restaurantId={metaData.restaurantId}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ServiceSetOverviewListWrapper;
