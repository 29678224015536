import { connect } from "react-redux";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import ConceptHeaderComponent from "../../components/navigation/navigation-header/concept-header/concept-header";
import NavigationRightComponent from "../../components/navigation/navigation-right/navigation-right";
import commonService from "../../services/common.service";
import { HomeInitState, HomeState } from "../../models/home.model";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import LoaderComponent from "../../components/loader/loader";
import { getRolesRights } from "../../services/user.rights.service";
import {
  REDIRECT_URL,
  SUPPLIERUUID,
  USER_RIGHTS,
} from "../../constant/constant";
import { profileRequest } from "../../redux/actions/profile.action";
import _ from "lodash";

class Home extends Component<any> {
  private _profileDetails: any = JSON.parse(
    localStorage.getItem("PROFILE") || "{}"
  );
  private _timerId: any;
  public isRedirectLogin: boolean =
    typeof localStorage.getItem(REDIRECT_URL) !== "undefined";
  state: HomeState;
  private isRightsLoaded: boolean = false;
  private _isFetching: any = {
    localStorage: true,
    api: true,
  };
  constructor(props: any) {
    super(props);
    this.state = HomeInitState;
  }

  componentDidMount() {
    if (_.size(this._profileDetails) === 0) {
      this.props.history.push("/login");
    }
    this.setLocalStorages();
    if (!this.isRedirectLogin && !this.isRightsLoaded) {
      this.loadRights();
    }
  }

  componentWillMount() {
    document.body.className = "light-theme";
    this._timerId = this.timer();
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<{}>): void {
    if (
      this.isRedirectLogin &&
      this.props.profile &&
      !this.props.isFetching &&
      !this.isRightsLoaded
    ) {
      this.loadRights();
    }
  }

  componentWillUnmount() {
    clearInterval(this._timerId);
  }

  loadRights = () => {
    this.isRightsLoaded = true;
    getRolesRights(RESTAURANT_REQUEST, this.props.match.params.uuid)
      .then((response: any) => {
        if (response.status === 200) {
          this._isFetching.api = false;
          let currentRights: any = {};
          if (!!localStorage.getItem("USER_RESTAURANT_RIGHTS")) {
            currentRights = JSON.parse(
              localStorage.getItem("USER_RESTAURANT_RIGHTS") || "{}"
            );
          }
          currentRights[this.props.match.params.uuid] = response.data;
          localStorage.setItem(
            "USER_RESTAURANT_RIGHTS",
            JSON.stringify(currentRights)
          );

          if (localStorage.getItem(SUPPLIERUUID)) {
            localStorage.removeItem(SUPPLIERUUID);
            this.props.history.push(
              `/restaurant/${this.props.match.params.uuid}/supplierOffering${
                commonService.hasOnlyPermission(
                  USER_RIGHTS.SUPPLIER_OFFERING_ONLY,
                  this.props.match.params.uuid
                )
                  ? "/boxView"
                  : ""
              }`
            );
          } else if (localStorage.getItem(REDIRECT_URL)) {
            const urlData: any = JSON.parse(
              localStorage.getItem(REDIRECT_URL) || "{}"
            );
            localStorage.removeItem(REDIRECT_URL);

            // window.open(urlData.url, "_self");
            this.props.history.push(urlData.url);
          } else {
            if (
              commonService.hasPermissionToAccess(
                USER_RIGHTS.MANAGE_RESTAURANT,
                this.props.match.params.uuid
              )
            ) {
              this.setState({ hasAccess: true });
            }

            if (!this._isFetching.localStorage) {
              this.setState({
                isFetching: false,
              });
            }
            this.setState({ isEverythingLoaded: true });
          }
        }
      })

      .catch((e) => {
        this.isRightsLoaded = false;
      });
  };

  setLocalStorages = () => {
    const profile: any = localStorage.getItem("USERLANGUAGE");
    if (!profile) {
      this.props.getProfile();
    } else if (!this.isRightsLoaded) {
      this.loadRights();
    }
  };

  timer = () =>
    setInterval(() => {
      const restaurantDetails: any = JSON.parse(
        localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
      );
      if (this.props.match.params.uuid === restaurantDetails?.uuid) {
        this.setState({
          restaurantDetails,
        });
        clearInterval(this._timerId);
        this._isFetching.localStorage = false;
        if (!this._isFetching.api) {
          this.setState({
            isFetching: false,
          });
        }
      }
    }, 500);

  render() {
    const { t } = this.props;
    return (
      <>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          {this.state.isEverythingLoaded && !this.props.isFetching && (
            <ConceptHeaderComponent
              concept={"home"}
              hasAccess={this.state.hasAccess}
            />
          )}
          {this.state.isEverythingLoaded && (
            <Row className="main light-theme">
              <Col xl={10} lg={9}>
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">
                      {t("common.hi")}{" "}
                      <strong>{this._profileDetails?.first_name}</strong>,{" "}
                      {t("common.welcomeTo")}{" "}
                      <strong>
                        {
                          commonService.applyLocalization(
                            "restaurant",
                            "name",
                            this.state.restaurantDetails?.locales
                          ).name
                        }
                      </strong>
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={2} lg={3}>
                <NavigationRightComponent />
              </Col>
            </Row>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps: any = (state: any) => {
  return {
    profile: state.profile.data,
    isFetching: state.profile.failure,
    failure: state.profile.failure,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getProfile: () => {
      dispatch(profileRequest());
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Home))
);
