import { call, put, takeLatest } from "redux-saga/effects";

import { RESTAURANT_REQUEST } from "../config/api.config";
import servicesetDetails from "../services/serviceset.details.service";
import handleResponse from "../services/response.service";
import * as types from "../redux/actions/types";
import {
  serviceSetDetailsFailure,
  serviceSetDetailsSuccess,
  serviceSetUpdateRecipesFailure,
  serviceSetUpdateRecipesSuccess,
} from "../redux/actions/serviceset.details.action";

const serviceSetDetailsCall = (params: any) =>
  servicesetDetails.serviceSetDetails(RESTAURANT_REQUEST, params);
const serviceSetUpdateRecipeCall = (params: any) =>
  servicesetDetails.updateServiceSetRecipe(RESTAURANT_REQUEST, params);

function* handleServiceSetDetailsRequest(params: object) {
  try {
    let result;
    result = yield call(serviceSetDetailsCall, params);
    yield handleServiceSetDetails200(result);
  } catch (error) {
    throw error;
  }
}

const handleServiceSetDetails200 = function* handleServiceSet200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(serviceSetDetailsSuccess(result.data));
  } else {
    yield put(serviceSetDetailsFailure(result.data));
    handleResponse(result);
  }
};

function* handleServiceSetRecipeUpdate(params: object) {
  try {
    let result;
    result = yield call(serviceSetUpdateRecipeCall, params);
    yield handleServiceSetRecipeUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleServiceSetRecipeUpdate200 =
  function* handleServiceSetRecipeUpdate200(result: any) {
    if (!result) {
      return;
    }
    if (result.status === 200) {
      yield put(serviceSetUpdateRecipesSuccess(result.data));
    } else {
      yield put(serviceSetUpdateRecipesFailure(result.data));
    }
    handleResponse(result);
  };

export default function* serviceSetDetailsSaga() {
  yield takeLatest(
    types.SERVICE_SET_DETAILS.REQUEST,
    handleServiceSetDetailsRequest
  );
  yield takeLatest(
    types.SERVICE_SET_RECIPES_UPDATE.REQUEST,
    handleServiceSetRecipeUpdate
  );
}
