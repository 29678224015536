import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function storagePlaceAdd(credentials: object): ActionModel {
  return {
    type: types.STORAGE_PLACE_ADD.REQUEST,
    credentials
  };
}

export function storagePlaceAddSuccess(data?: object): ActionModel {
  return {
    type: types.STORAGE_PLACE_ADD.SUCCESS,
    data
  };
}

export function storagePlaceAddFailure(err: object): ActionModel {
  return {
    type: types.STORAGE_PLACE_ADD.FAILURE,
    err
  };
}

export function storagePlaceUpdate(credentials: object): ActionModel {
  return {
    type: types.STORAGE_PLACE_UPDATE.REQUEST,
    credentials
  };
}

export function storagePlaceUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.STORAGE_PLACE_UPDATE.SUCCESS,
    data
  };
}

export function storagePlaceUpdateFailure(err: object): ActionModel {
  return {
    type: types.STORAGE_PLACE_UPDATE.FAILURE,
    err
  };
}
export function storagePlaceRemove(credentials: object): ActionModel {
  return {
    type: types.STORAGE_PLACE_REMOVE.REQUEST,
    credentials
  };
}

export function storagePlaceRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.STORAGE_PLACE_REMOVE.SUCCESS,
    data
  };
}

export function storagePlaceRemoveFailure(err: object): ActionModel {
  return {
    type: types.STORAGE_PLACE_REMOVE.FAILURE,
    err
  };
}