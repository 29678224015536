import '../../styles/registration.scss';

import React, { Component } from 'react';

import DevelopmentComponent from '../../components/development/development';

class DevelopmentPage extends Component {

    render() {
        return (
            <div className="registration-style">
                <DevelopmentComponent />
            </div>
        );
    }
}
export default DevelopmentPage;
