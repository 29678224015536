import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  recipeSubstituteSuccess,
  recipeSubstituteFailure,
  recipeSubstituteAddFailure,
  recipeSubstituteUpdateFailure,
  recipeSubstituteUpdateSuccess,
  recipeSubstituteAddSuccess,
  recipeSubstituteRemoveFailure,
  recipeSubstituteRemoveSuccess,
} from "../redux/actions/recipe.substitutes.action";
import * as types from "../redux/actions/types";
import recipeSubstitute from "../services/recipe.substitute.service";
import handleResponse from "../services/response.service";

const recipeSubstituteCall = (params: any) =>
  recipeSubstitute.getRecipeSubstitute(RESTAURANT_REQUEST, params);

const addRecipeSubstituteCall = (params: any) =>
  recipeSubstitute.addRecipeSubstitute(RESTAURANT_REQUEST, params);

const updateRecipeSubstituteCall = (params: any) =>
  recipeSubstitute.updateRecipeSubstitute(RESTAURANT_REQUEST, params);

const removeRecipeSubstituteCall = (params: any) =>
  recipeSubstitute.removeRecipeSubstitute(RESTAURANT_REQUEST, params);

function* handleRecipeSubstituteRequest(params: object) {
  try {
    let result;
    result = yield call(recipeSubstituteCall, params);
    yield handleRecipeSubstitute200(result);
  } catch (error) {
    throw error;
  }
}

const handleRecipeSubstitute200 = function* handleRecipeSubstitute200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(recipeSubstituteSuccess(result.data));
  } else {
    yield put(recipeSubstituteFailure(result.data));
    handleResponse(result);
  }
};

function* handleRecipeSubstituteUpdate(params: object) {
  try {
    let result;
    result = yield call(updateRecipeSubstituteCall, params);
    yield handleRecipeSubstituteUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleRecipeSubstituteUpdate200 =
  function* handleRecipeSubstituteUpdate200(result: any) {
    if (!result) {
      return;
    }
    if (result.status === 200) {
      yield put(recipeSubstituteUpdateSuccess(result.data));
    } else {
      yield put(recipeSubstituteUpdateFailure(result.data));
    }
    handleResponse(result);
  };

function* handleRecipeSubstituteAdd(params: object) {
  try {
    let result;
    result = yield call(addRecipeSubstituteCall, params);
    yield handleRecipeSubstituteAdd200(result);
  } catch (error) {
    throw error;
  }
}

const handleRecipeSubstituteAdd200 = function* handleRecipeSubstituteAdd200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(recipeSubstituteAddSuccess(result.data));
  } else {
    yield put(recipeSubstituteAddFailure(result.data));
  }
  handleResponse(result);
};

function* handleRecipeSubstituteRemove(params: object) {
  try {
    let result;
    result = yield call(removeRecipeSubstituteCall, params);
    yield handleRecipeSubstituteRemove200(result);
  } catch (error) {
    throw error;
  }
}

const handleRecipeSubstituteRemove200 =
  function* handleRecipeSubstituteRemove200(result: any) {
    if (!result) {
      return;
    }
    if (result.status === 200) {
      yield put(recipeSubstituteRemoveSuccess(result.data));
    } else {
      yield put(recipeSubstituteRemoveFailure(result.data));
    }
    handleResponse(result);
  };

export default function* recipeSubstituteSaga() {
  yield takeLatest(
    types.RECIPE_SUBSTITUTE.REQUEST,
    handleRecipeSubstituteRequest
  );
  yield takeLatest(
    types.RECIPE_SUBSTITUTE_UPDATE.REQUEST,
    handleRecipeSubstituteUpdate
  );
  yield takeLatest(
    types.RECIPE_SUBSTITUTE_ADD.REQUEST,
    handleRecipeSubstituteAdd
  );
  yield takeLatest(
    types.RECIPE_SUBSTITUTE_REMOVE.REQUEST,
    handleRecipeSubstituteRemove
  );
}
