import React from "react";
import { useParams } from "react-router-dom";
import ReportDetailsComponent from "../../components/reports/reportDetailsComponent";

interface ReportDetailsPageParams {
  uuid: string;
  report: string;
  itemUuid: string;
}

const ReportDetailsPage: React.FC = () => {
  const { uuid, report, itemUuid } = useParams<ReportDetailsPageParams>();

  return (
    <ReportDetailsComponent
      restaurantUuid={uuid}
      report={report}
      itemUuid={itemUuid}
    />
  );
};

export default ReportDetailsPage;
