import apiCall from "./api.call.config";

async function units(url: string, params: any) {
  try {
    let urlsParams: string =
      url + "/" + params.credentials.restaurantuuid + "/unit?sort=name:asc";
    if (params.credentials?.global) urlsParams += "&global=true";
    return await apiCall.get(urlsParams);
  } catch (e) {
    throw e;
  }
}

async function updateUnits(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/unit/" +
        params.credentials.uuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function addUnits(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/unit",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function addConversion(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/unit-conversion",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function editConversion(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/unit-conversion/" +
        params.credentials.conversionuuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function deleteConversion(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/unit-conversion/" +
        params.credentials.conversionuuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function removeUnits(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/unit/" +
        params.credentials.uuid
    );
  } catch (e) {
    throw e;
  }
}

export async function removeGrossUnits(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/unit-conversion/clear-gross-weight"
    );
  } catch (e) {
    throw e;
  }
}

export async function getConversionFactor(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/unit-conversion/conversion-factor",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  units,
  updateUnits,
  addUnits,
  removeUnits,
  addConversion,
  editConversion,
  deleteConversion,
  getConversionFactor,
};
