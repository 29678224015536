import i18next from "i18next";
import _ from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { RoomInitState, RoomState } from "../../models/room.model";
import {
  roomAdd,
  roomRemove,
  roomRequest,
  roomUpdate,
} from "../../redux/actions/room.action";
import commonService from "../../services/common.service";
import "../../styles/room.scss";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import RoomAddCard from "../card-components/room-add-card/room-add-card";
import RoomCard from "../card-components/room-card/room-card";
import RoomEditCard from "../card-components/room-edit-card/room-edit-card";
import LoaderComponent from "../loader/loader";
import StaffHeaderComponent from "../navigation/navigation-header/staff-header/staff-header";
import UsersLeftComponent from "../navigation/navigation-left/users-navigation/users-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import { log } from "console";

class RoomComponent extends Component<any> {
  state: RoomState;
  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.state = RoomInitState;
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <StaffHeaderComponent staff={"resources"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <UsersLeftComponent
                display={"room"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <Link
                to="#"
                color="info"
                className="room-add"
                onClick={this.addToggle}
              >
                {t("room.addRoom")}
              </Link>
              <div className="white-box mb-3">
                <h4>{t("room.room")}</h4>
                {this.roomList(this.state.room)}
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>

        {/*  Edit Room  */}

        <RoomEditCard
          isFetching={this.state.isFetching}
          isOpen={this.state.editmodal}
          name={this.state.name}
          pickingLocation={this.state.pickingLocation}
          otherRoomList={this.getRoomOptions(this.state.uuid)}
          toggle={this.toggle}
          handleChange={this.handleChange}
          saveEdit={this.saveEdit}
        />

        {/*  Add Room  */}

        <RoomAddCard
          isFetching={this.state.isFetching}
          isOpen={this.state.addmodal}
          name={this.state.name}
          pickingLocation={this.state.pickingLocation}
          addToggle={this.addToggle}
          handleChange={this.handleChange}
          otherRoomList={this.getRoomOptions()}
          saveAdd={this.saveAdd}
        />

        {/* Delete Room */}

        <DeleteModalCard
          isOpen={this.state.open}
          isFetching={!!this.state.isFetching}
          okDelete={this.deleteConfirmToggle}
          cancelDelete={this.deleteToggle}
        />
      </div>
    );
  }

  public roomList(data: any): any {
    let room;

    if (!!data && data.length > 0) {
      room = _.orderBy(
        data,
        [
          (item) =>
            commonService
              .applyLocalization("restaurant", "name", item.locales)
              ?.name?.toLowerCase(),
        ],
        ["asc"]
      )?.map((room: any, i: number) => {
        return (
          <RoomCard
            key={i}
            setEditable={this.setEditable.bind(this, room)}
            setDelete={this.setDelete.bind(this, room)}
            locales={room}
            pickingLocation={this.getPickingLocation(room?.picking_location)}
          />
        );
      });
      return room;
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.props.t("common.noRecords")}
        </div>
      );
    }
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.getRooms({ restaurantuuid: this.props.match.params.uuid });
    this._setStates("restaurantuuid", this.props.match.params.uuid);
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
      if (!!newProps && !newProps.isFetching) {
        this._setStates("room", newProps.rooms);
        this.setRoomOptions(newProps.rooms);
      }
      if (!!this.state.isUpdated && !newProps.isFetching) {
        this.props.getRooms({ restaurantuuid: this.props.match.params.uuid });
        this._setStates("isUpdated", false);
      }
      if (
        !!newProps.roomupdate.status &&
        !!this.state.editmodal &&
        !newProps.isFetching
      ) {
        this.toggle();
      }
      if (
        !!newProps.roomadd.status &&
        !!this.state.addmodal &&
        !newProps.isFetching
      ) {
        this.addToggle();
      }
    }
  }

  getPickingLocation = (location: any) => {
    return this.state.roomOptionList.find(
      (room: any) => room.value === location
    );
  };

  getRoomOptions = (activeRoom: string = "") => {
    const rooms: Array<any> = this.state.roomOptionList.filter(
      (opt: any) => opt.value !== activeRoom
    );
    rooms.unshift({
      label: this.props.t("common.select"),
      value: null,
    });
    return rooms;
  };

  public saveEdit(): void {
    this.props.updateRoom({
      restaurantuuid: this.state.restaurantuuid,
      uuid: this.state.uuid,
      data: {
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.name,
          },
        },
        picking_location: this.state.pickingLocation?.value || "",
      },
    });
    this._setStates("isUpdated", true);
  }

  public saveAdd(): void {
    this.props.addRoom({
      restaurantuuid: this.state.restaurantuuid,
      data: {
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.name,
          },
        },
        picking_location: this.state.pickingLocation?.value || "",
      },
    });
    this._setStates("isUpdated", true);
  }

  public toggle(): void {
    this.setState((prevState: any) => ({
      editmodal: !prevState.editmodal,
    }));
  }

  public deleteToggle(): void {
    this.setState((prevState: any) => ({
      open: !prevState.open,
    }));
  }

  public deleteConfirmToggle(): void {
    this.props.removeRoom({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
    });
    this._setStates("isUpdated", true);
    this.deleteToggle();
  }

  public addToggle(): void {
    this.setState((prevState: any) => ({
      addmodal: !prevState.addmodal,
      name: "",
      pickingLocation: null,
    }));
  }

  private _setFunctionBindings(): void {
    this.setEditable = this.setEditable.bind(this);
    this.toggle = this.toggle.bind(this);
    this.addToggle = this.addToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteConfirmToggle = this.deleteConfirmToggle.bind(this);
    this.deleteToggle = this.deleteToggle.bind(this);
    this.saveEdit = this.saveEdit.bind(this);
    this.saveAdd = this.saveAdd.bind(this);
    this.setDelete = this.setDelete.bind(this);
  }

  public setEditable(event: any): void {
    let roomLocale = commonService.applyLocalization(
      "restaurant",
      "name",
      event.locales
    );
    const pickingLocation: any =
      this.state.roomOptionList.find(
        (p: any) => p.value === event?.picking_location
      ) || null;
    this.setState({
      name: roomLocale.name,
      uuid: event.uuid,
      pickingLocation: pickingLocation,
    });
    this.toggle();
  }

  public setDelete(event: any): void {
    this._setStates("uuid", event.uuid);
    this.deleteToggle();
  }

  setRoomOptions = (roomList: Array<any>) => {
    const options: Array<any> = [];
    roomList.forEach((room: any) => {
      options.push({
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          room.locales
        )["name"],
        value: room.uuid,
      });
    });
    this._setStates("roomOptionList", options);
  };

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  public handleChange(e: any) {
    this._setStates(e.target.name, e.target.value);
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.room.isFetching ||
    state.roomremove.isFetching ||
    state.roomadd.isFetching ||
    state.roomupdate.isFetching;
  let failure =
    state.room.failure ||
    state.roomremove.failure ||
    state.roomadd.failure ||
    state.roomupdate.failure;
  return {
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    rooms: state.room.data,
    roomadd: state.roomadd.data,
    roomupdate: state.roomupdate.data,
    isFetching: isFetching,
    failure: failure,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getRooms: (credentials: any) => {
      dispatch(roomRequest(credentials));
    },
    updateRoom: (credentials: any) => {
      dispatch(roomUpdate(credentials));
    },
    addRoom: (credentials: any) => {
      dispatch(roomAdd(credentials));
    },
    removeRoom: (credentials: any) => {
      dispatch(roomRemove(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(RoomComponent))
);
