import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faClipboard,
  faEllipsisV,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { PHILIPPINE_GRAY } from "../../constant/constant";
import commonService from "../../services/common.service";

import { QRCodeRowState } from "../../models/qrcode.model";
import "./qrcode.scss";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import { Button, Card, CardBody } from "reactstrap";
class QRCodeRow extends Component<any> {
  state: any;
  constructor(props: any) {
    super(props);
    this.state = {
      isChecked: this.props?.isChecked || false,
    };
    this.handleSelection = this.handleSelection.bind(this);
  }
  copyCodeToClipboard(data: any) {
    const el = document.createElement("textarea");
    el.value = this.props.data.url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    commonService.toastService(this.props.t("toast.codeCopied"), "secondary");
  }
  handleSelection(key: string) {
    const selection: object = {
      key: key,
      isChecked: !this.state.isChecked,
    };
    this.props.onSelection(selection);
    this.setState({ isChecked: !this.state.isChecked });
  }
  openCodeInWindow(data: any) {
    let encodedUrl = encodeURIComponent(data.url);
    let replacedUrl = encodedUrl.replace(/%2F/gi, "<>");
    const win: any = window.open(
      window.location.origin + "/restaurant/qrcodes/generate/" + replacedUrl,
      "QR Code",
      "toolbar=yes,location=no,directories=no,status=no,menubar=1,scrollbars=yes,resizable=yes,width=400,height=400"
    );
  }
  render() {
    const { t } = this.props;
    return (
      <Card className="shadow rounded py-2">
        <CardBody className="d-flex justify-content-between px-3 py-2 align-items-center">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              className="position-absolute b-drag-icon"
              style={{ color: PHILIPPINE_GRAY }}
              icon={faEllipsisV}
            />
            <div className="custom-control custom-checkbox align-items-center">
              <input
                type="checkbox"
                className="custom-control-input"
                id={this.props.data.uuid}
                name={this.props.data.uuid}
                onChange={() => {
                  this.handleSelection(this.props.data.uuid);
                }}
                checked={this.state.isChecked}
              />
              <label
                className="custom-control-label"
                htmlFor={this.props.data.uuid}
                title={t("qrCode.selectQrCodeBulkAction")}
              >
                {this.props.data.url}
              </label>
            </div>
          </div>
          <div>
            <Button
              color="link"
              className="px-1 py-0"
              onClick={() => {
                this.openCodeInWindow(this.props.data);
              }}
              title={t("qrCode.viewAndPrintQrCode")}
            >
              <FontAwesomeIcon
                style={{
                  float: "none",
                }}
                icon={faEye}
              />
            </Button>
            <Button
              color="link"
              className="px-1 py-0"
              onClick={() => {
                this.copyCodeToClipboard(this.props.data.uuid);
              }}
              title={t("qrCode.copyQrCode")}
            >
              <FontAwesomeIcon icon={faClipboard} />
            </Button>
            <Button
              color="link"
              className="px-1 py-0"
              onClick={() => {
                this.props.onRemoveCode(this.props.data.uuid);
              }}
              title={t("qrCode.removeQrCode")}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
            {/* <Button variant="link" className="px-1 py-0">
              <FontAwesomeIcon
                style={{
                  float: "none",
                }}
                icon={faBars}
              />
            </Button> */}
          </div>
        </CardBody>
      </Card>
    );
  }
}
export default withTranslation()(withRouter(QRCodeRow));
