import apiCall from "./api.call.config";

async function serviceSetDetails(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantUuid +
        "/service-set/" +
        params.credentials.itemUuid +
        "/details"
    );
  } catch (e) {
    throw e;
  }
}
async function updateServiceSetRecipe(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantUuid +
        "/service-set/" +
        params.credentials.itemUuid +
        "/recipe",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  serviceSetDetails,
  updateServiceSetRecipe,
};
