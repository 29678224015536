import {
  databaseSelector,
  fetchDatabaseIfRequired,
  databaseFetchingCallback,
  postToDatabase,
  fetchDatabasePaginated,
} from "../services/database.service";

import { RESTAURANT_REQUEST, UPLOAD_FILE } from "../config/api.config";

const mediaEntity = (restaurantUuid: any) => {
  return {
    reduxEntity: "restaurant/" + restaurantUuid + "/media",
    APIfetchEndpoint: RESTAURANT_REQUEST + "/" + restaurantUuid + "/media",
    APIpostEndpoint: UPLOAD_FILE,
  };
};

export function mediaSelector(
  state: any,
  restaurantUuid: any,
  entityType?: string
) {
  return databaseSelector(
    state,
    entityType
      ? mediaEntity(restaurantUuid).reduxEntity + "_" + entityType
      : mediaEntity(restaurantUuid).reduxEntity,
    mediaInitState
  );
}

export function fetchMediaIfRequired(
  restaurantUuid: any,
  filters: any,
  entityType: string
) {
  return function (dispatch: any, getState: any) {
    return dispatch(
      fetchDatabaseIfRequired(
        mediaEntity(restaurantUuid).reduxEntity,
        mediaEntity(restaurantUuid).APIfetchEndpoint + "?" + filters,
        databaseFetchingCallback,
        entityType
      )
    );
  };
}

export function fetchPaginatedMedia(
  restaurantUuid: any,
  filters: any,
  entityType: string
) {
  return function (dispatch: any, getState: any) {
    return dispatch(
      fetchDatabasePaginated(
        mediaEntity(restaurantUuid).reduxEntity,
        mediaEntity(restaurantUuid).APIfetchEndpoint + "?" + filters,
        databaseFetchingCallback,
        entityType
      )
    );
  };
}

export function postMedia(
  restaurantUuid: any,
  imageFiles: any,
  videoFiles: any,
  files: Array<any>,
  entityType: string
) {
  const fileData = new FormData();
  fileData.append("object_type", "restaurant");
  fileData.append("object_uuid", restaurantUuid);
  for (let i = 0; i < imageFiles.length; i++) {
    fileData.append("images[]", imageFiles[i]);
  }
  for (let i = 0; i < videoFiles.length; i++) {
    fileData.append("videos[]", videoFiles[i]);
  }

  for (let i = 0; i < files.length; i++) {
    fileData.append("files[]", files[i]);
  }
  return function (dispatch: any, getState: any) {
    return dispatch(
      postToDatabase(
        mediaEntity(restaurantUuid).reduxEntity,
        mediaEntity(restaurantUuid).APIpostEndpoint,
        (oldState: any, response: any): any => {
          return Object.assign(oldState || {}, response?.data || {});
        },
        fileData,
        entityType
      )
    );
  };
}

const mediaInitState: any[] = [];
