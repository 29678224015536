import React, { Component } from "react";
import { withRouter } from "react-router";
import SettingsHeaderComponent from "../../components/navigation/navigation-header/settings-header/settings-header";
import UsersLeftComponent from "../../components/navigation/navigation-left/users-navigation/users-navigation";
import NavigationRightComponent from "../../components/navigation/navigation-right/navigation-right";
import { withTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import PrinterTemplateComponent from "../printer-template/printer-template";

class PrinterTemplatePage extends Component<any> {
  render() {
    return (
      <>
        <div>
          <div className="container-fluid">
            <SettingsHeaderComponent settings={"settings"} />
            <Row className="main light-theme">
              <Col xl={2} lg={3} className="hide-left-max">
                <UsersLeftComponent
                  display={"printerTemplate"}
                  restaurantuuid={this.props.match.params.uuid}
                />
              </Col>
              <Col xl={8} lg={6}>
                <PrinterTemplateComponent templateType="PRINT" />
              </Col>
              <Col xl={2} lg={3}>
                <NavigationRightComponent />
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(PrinterTemplatePage));
