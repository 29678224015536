import apiCall from "./api.call.config";

async function serviceSet(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.credentials.restaurantuuid + "/service-set"
    );
  } catch (e) {
    throw e;
  }
}

async function updateServiceSet(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/service-set/" +
        params.credentials.uuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function addServiceSet(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/service-set",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function removeServiceSet(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/service-set/" +
        params.credentials.uuid
    );
  } catch (e) {
    throw e;
  }
}

async function addServiceSetTime(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/service-set/" +
        params.credentials.uuid +
        "/time",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}
async function updateServiceSetChannel(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/service-set/" +
        params.credentials.uuid +
        "/channels",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function updateServiceSetLabel(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/service-set/" +
        params.credentials.serviceSetUuid +
        "/label",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function updateServiceSetRecipe(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.uuid +
        "/service-set",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function getSuppliersRestaurants(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.credentials.restaurantUuid + "/customers"
    );
  } catch (e) {
    throw e;
  }
}

export async function setServiceSetRestaurants(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantUuid +
        "/service-set/" +
        params.credentials.serviceSetUuid +
        "/customers",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function setServiceSetInventoryAccess(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantUuid +
        "/service-set/" +
        params.credentials.serviceSetUuid +
        "/inventory-access",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function copyServiceSet(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/service-set/" +
        params.credentials.serviceSetUuid +
        "/copy",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  serviceSet,
  updateServiceSet,
  addServiceSet,
  addServiceSetTime,
  removeServiceSet,
  updateServiceSetChannel,
  updateServiceSetRecipe,
  getSuppliersRestaurants,
  setServiceSetRestaurants,
  setServiceSetInventoryAccess,
  copyServiceSet,
};
