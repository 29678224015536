import "../../styles/page404.scss";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Alert, Col, Container, Row, Table } from "reactstrap";

import smartToniLogin from "../../assets/images/logo/smartToniLogin.png";
import { withTranslation } from "react-i18next";
import { LIGHT_GRAY } from "../../constant/constant";
import moment from "moment";
import commonService from "../../services/common.service";
class RevenuePerRecipe extends Component<any> {
  render() {
    const { t, data } = this.props;
    return (
      <>
        {Object.keys(data?.costPerRecipe || {})?.length == 0 && (
          <Alert color="info">{t("common.noRecords")}</Alert>
        )}
        {Object.keys(data?.costPerRecipe || {})?.length > 0 && (
          <>
            <section className="mt-3 mb-5">
              <h5>1. {t("reports.revenuePerRecipe")}</h5>

              <Table bordered striped>
                <thead>
                  <tr className="bg-light">
                    {/* <th className="w-25">{t("reports.timestamp")}</th> */}

                    <th className="w-25">{t("recipe.recipe")}</th>
                    <th className="w-25">{t("common.currency")}</th>
                    <th className="w-25">{t("common.amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data?.costPerRecipe || "{}")?.map(
                    (innerData: any) => {
                      return (
                        <tr>
                          {/* <td>
                            {moment(innerData.timestamp, "x").format(
                              "DD-MM-YYYY"
                            )}
                          </td> */}
                          <td>{innerData.innerData}</td>

                          <td>{innerData.currency}</td>
                          <td>
                            {commonService.displayDigitalAmount(
                              data?.costPerRecipe
                                ? data?.costPerRecipe[innerData]
                                : 0
                            )}
                          </td>
                        </tr>
                      );
                    }
                  )}
                  {Object.keys(data?.costPerRecipe || "{}")?.length === 0 && (
                    <tr>
                      <td colSpan={4} className="text-center">
                        {t("common.noRecords")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </section>
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withRouter(RevenuePerRecipe));
