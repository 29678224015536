import React from "react";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function LogOutModalCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <Modal returnFocusAfterClose={props.focusAfterClose} isOpen={props.open}>
      <ModalBody>
        <Row className="app-row-padding">
          <Col sm="8" style={{ padding: 10, textAlign: "center" }}>
            <h6>{t("questions.logout")}</h6>
          </Col>
          <Col sm="4" style={{ padding: 0, textAlign: "center" }}>
            <div style={{ float: "right" }}>
              <Button
                color="secondary"
                style={{ margin: 5 }}
                onClick={props.modalToggle}
              >
                {t("common.cancel")}
              </Button>
              <Button
                color="info"
                style={{ margin: 5 }}
                disabled={props.isFetching}
                onClick={props.logoutModalToggle}
              >
                {t("common.ok")}
              </Button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}
