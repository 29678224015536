import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";

const TableBoxComponent = (props: any) => {
  const { handleTableSelection, tableData } = props;
  return (
    <div
      className="tm-table-box d-flex align-items-center"
      onClick={() => handleTableSelection(tableData.uuid)}
    >
      <FontAwesomeIcon className="mr-2" icon={faCoffee} />
      <span>{tableData?.name || ""}</span>
    </div>
  );
};

export default TableBoxComponent;
