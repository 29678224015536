import { PROFILEUUID } from '../constant/constant';
import apiCall from './api.call.config';

async function changePassword(url: string, params: any) {
    try {
        return await apiCall.put(url + localStorage.getItem(PROFILEUUID)
            + '/change-password', params.credentials);
    } catch (e) {
        throw e;
    }
}

export default { changePassword }
