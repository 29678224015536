import { call, put, takeLatest } from "redux-saga/effects";

import { RESTAURANT_REQUEST } from "../config/api.config";
import * as types from "../redux/actions/types";
import {
  translationSuccess,
  translationFailure,
} from "../redux/actions/translation.action";

import handleResponse from "../services/response.service";
import translation from "../services/translation.service";

const updateTranslationCall = (params: any) =>
  translation.updateTranslation(RESTAURANT_REQUEST, params);

function* handleTranslationUpdate(params: object) {
  try {
    let result;
    result = yield call(updateTranslationCall, params);
    yield handleTranslationUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleTranslationUpdate200 = function* handleTranslationUpdate200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(translationSuccess(result.data));
  } else {
    yield put(translationFailure(result.data));
  }
  handleResponse(result);
};

export default function* translationSaga() {
  yield takeLatest(types.TRANSLATION.REQUEST, handleTranslationUpdate);
}
