import "../../styles/profile.scss";

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import {
  ProfileSettingInitState,
  ProfileSettingState,
} from "../../models/profile.model";
import {
  profileSettingsRequest,
  profileSettingsUpdate,
} from "../../redux/actions/profile.settings.action";
import LoaderComponent from "../loader/loader";
import ProfileHeaderComponent from "../navigation/navigation-header/profile-header/profile-header";
import ProfileNavLeftComponent from "../navigation/navigation-left/profile-navigation/profile-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";

import i18next from "i18next";
import { withTranslation } from "react-i18next";

class ProfileSettingComponent extends Component<any> {
  state: ProfileSettingState;
  constructor(props: Readonly<{}>) {
    super(props);
    this._setFunctionBindings();
    this.state = ProfileSettingInitState;
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <ProfileHeaderComponent home={"home"} />
          <Row className="dark-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <ProfileNavLeftComponent display={"profilesetting"} />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <div className="white-box mb-3">
                <h4>{t("task.tasksOperationMode")}</h4>
                <Form>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      name="auto_assign"
                      id="autoTask"
                      onChange={this.handleChange}
                      checked={this.state.auto_assign}
                    />
                    <Label for="autoTask" check>
                      {t("automatic.automaticTaskAssignment")}
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      name="task_auto_open"
                      id="autoTaskDetails"
                      onChange={this.handleChange}
                      checked={this.state.task_auto_open}
                    />
                    <Label for="autoTaskDetails" check>
                      {t("automatic.automaticTaskDetailsOpening")}
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      name="step_auto_open"
                      id="autoTaskStep"
                      onChange={this.handleChange}
                      checked={this.state.step_auto_open}
                    />
                    <Label for="autoTaskStep" check>
                      {t("automatic.automaticStepDetailsOpening")}
                    </Label>
                  </FormGroup>
                </Form>
              </div>
              <div className="white-box mb-3">
                <h4>
                  {t("speaking.speaking")} & {t("voice.voiceSettings")}
                </h4>
                <Form>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      name="voice_commands"
                      id="speakingInterface"
                      onChange={this.handleChange}
                      checked={this.state.voice_commands}
                    />
                    <Label for="speakingInterface" check>
                      {t("speaking.speakingInterface")}
                    </Label>
                  </FormGroup>
                  <FormGroup check className="ml-4">
                    <Input
                      type="checkbox"
                      name="voice_commands_task"
                      id="readTaskAssign"
                      onChange={this.handleChange}
                      checked={this.state.voice_commands_task}
                      disabled={this.state.voice_commands ? false : true}
                    />
                    <Label for="readTaskAssign" check>
                      {t("read.readTaskWhenAssigned")}
                    </Label>
                  </FormGroup>
                  <FormGroup check className="ml-4">
                    <Input
                      type="checkbox"
                      name="voice_commands_details_description"
                      id="readTaskDesc"
                      onChange={this.handleChange}
                      checked={this.state.voice_commands_details_description}
                      disabled={this.state.voice_commands ? false : true}
                    />
                    <Label for="readTaskDesc" check>
                      {t("read.readTaskDescription")}
                    </Label>
                  </FormGroup>
                  <FormGroup check className="ml-4">
                    <Input
                      type="checkbox"
                      name="voice_commands_step_description"
                      id="readStepDesc"
                      onChange={this.handleChange}
                      checked={this.state.voice_commands_step_description}
                      disabled={this.state.voice_commands ? false : true}
                    />
                    <Label for="readStepDesc" check>
                      {t("read.readStepDescription")}
                    </Label>
                  </FormGroup>
                  <FormGroup check className="ml-4">
                    <Input
                      type="checkbox"
                      name="voice_commands_details_ingredients"
                      id="readTaskIngreds"
                      onChange={this.handleChange}
                      checked={this.state.voice_commands_details_ingredients}
                      disabled={this.state.voice_commands ? false : true}
                    />
                    <Label for="readTaskIngreds" check>
                      {t("read.readTaskIngredients")}
                    </Label>
                  </FormGroup>
                  <FormGroup check className="ml-4">
                    <Input
                      type="checkbox"
                      name="voice_commands_step_ingredients"
                      id="readStepIngreds"
                      onChange={this.handleChange}
                      checked={this.state.voice_commands_step_ingredients}
                      disabled={this.state.voice_commands ? false : true}
                    />
                    <Label for="readStepIngreds" check>
                      {t("read.readStepIngredients")}
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      name="voice_recognition"
                      id="voiceRecognition"
                      onChange={this.handleChange}
                      checked={this.state.voice_recognition}
                    />
                    <Label for="voiceRecognition" check>
                      {t("voice.voiceRecognition")}
                    </Label>
                  </FormGroup>
                </Form>
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent></NavigationRightComponent>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.props.getProfileSettings();
    document.body.className = "dark-theme";
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this.setState({
        isFetching: newProps.isFetching,
      });
      if (!!newProps.data.data && !newProps.isFetching && !newProps.failure) {
        newProps.data.data.forEach((element: any) => {
          let name = element.settings_key;
          switch (name) {
            case "auto_assign":
              let auto_assign = element.settings_value === "1" ? true : false;
              this.setState({ auto_assign: auto_assign });
              break;
            case "task_auto_open":
              let task_auto_open =
                element.settings_value === "1" ? true : false;
              this.setState({ task_auto_open: task_auto_open });
              break;
            case "step_auto_open":
              let step_auto_open =
                element.settings_value === "1" ? true : false;
              this.setState({ step_auto_open: step_auto_open });
              break;
            case "voice_commands":
              let voice_commands =
                element.settings_value === "1" ? true : false;
              this.setState({ voice_commands: voice_commands });
              break;
            case "voice_commands_task":
              let voice_commands_task =
                element.settings_value === "1" ? true : false;
              this.setState({ voice_commands_task: voice_commands_task });
              break;
            case "voice_commands_details_description":
              let voice_commands_details_description =
                element.settings_value === "1" ? true : false;
              this.setState({
                voice_commands_details_description:
                  voice_commands_details_description,
              });
              break;
            case "voice_commands_step_description":
              let voice_commands_step_description =
                element.settings_value === "1" ? true : false;
              this.setState({
                voice_commands_step_description:
                  voice_commands_step_description,
              });
              break;
            case "voice_commands_details_ingredients":
              let voice_commands_details_ingredients =
                element.settings_value === "1" ? true : false;
              this.setState({
                voice_commands_details_ingredients:
                  voice_commands_details_ingredients,
              });
              break;
            case "voice_commands_step_ingredients":
              let voice_commands_step_ingredients =
                element.settings_value === "1" ? true : false;
              this.setState({
                voice_commands_step_ingredients:
                  voice_commands_step_ingredients,
              });
              break;
            case "voice_recognition":
              let voice_recognition =
                element.settings_value === "1" ? true : false;
              this.setState({ voice_recognition: voice_recognition });
              break;
            default:
              break;
          }
        });
      }
    }
  }

  private _setStates(name: string, value: any): void {
    const settings = {
      settings: {
        [name]: value,
      },
    };
    this.props.updateProfileSettings(settings);
  }

  public handleChange(event: any): void {
    let name = event.target.name;
    switch (name) {
      case "auto_assign":
        this.setState({
          auto_assign: !this.state.auto_assign,
        });
        let auto_assign = !this.state.auto_assign === false ? 0 : 1;
        this._setStates(event.target.name, auto_assign);
        break;
      case "task_auto_open":
        this.setState({
          task_auto_open: !this.state.task_auto_open,
        });
        let task_auto_open = !this.state.task_auto_open === false ? 0 : 1;
        this._setStates(event.target.name, task_auto_open);
        break;
      case "step_auto_open":
        this.setState({
          step_auto_open: !this.state.step_auto_open,
        });
        let step_auto_open = !this.state.step_auto_open === false ? 0 : 1;
        this._setStates(event.target.name, step_auto_open);
        break;
      case "voice_commands":
        this.setState({
          voice_commands: !this.state.voice_commands,
        });
        let voice_commands = !this.state.voice_commands === false ? 0 : 1;
        this._setStates(event.target.name, voice_commands);
        break;
      case "voice_commands_task":
        this.setState({
          voice_commands_task: !this.state.voice_commands_task,
        });
        let voice_commands_task =
          !this.state.voice_commands_task === false ? 0 : 1;
        this._setStates(event.target.name, voice_commands_task);
        break;
      case "voice_commands_details_description":
        this.setState({
          voice_commands_details_description:
            !this.state.voice_commands_details_description,
        });
        let voice_commands_details_description =
          !this.state.voice_commands_details_description === false ? 0 : 1;
        this._setStates(event.target.name, voice_commands_details_description);
        break;
      case "voice_commands_step_description":
        this.setState({
          voice_commands_step_description:
            !this.state.voice_commands_step_description,
        });
        let voice_commands_step_description =
          !this.state.voice_commands_step_description === false ? 0 : 1;
        this._setStates(event.target.name, voice_commands_step_description);
        break;
      case "voice_commands_details_ingredients":
        this.setState({
          voice_commands_details_ingredients:
            !this.state.voice_commands_details_ingredients,
        });
        let voice_commands_details_ingredients =
          !this.state.voice_commands_details_ingredients === false ? 0 : 1;
        this._setStates(event.target.name, voice_commands_details_ingredients);
        break;
      case "voice_commands_step_ingredients":
        this.setState({
          voice_commands_step_ingredients:
            !this.state.voice_commands_step_ingredients,
        });
        let voice_commands_step_ingredients =
          !this.state.voice_commands_step_ingredients === false ? 0 : 1;
        this._setStates(event.target.name, voice_commands_step_ingredients);
        break;
      case "voice_recognition":
        this.setState({
          voice_recognition: !this.state.voice_recognition,
        });
        let voice_recognition = !this.state.voice_recognition === false ? 0 : 1;
        this._setStates(event.target.name, voice_recognition);
        break;
      default:
        break;
    }
  }

  private _setFunctionBindings(): void {
    this.handleChange = this.handleChange.bind(this);
    this._setStates = this._setStates.bind(this);
  }
}
const mapStateToProps: any = (state: any) => {
  return {
    data: state.profilesettings.data,
    isFetching: state.profilesettings.isFetching,
    failure: state.profilesettings.failure,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getProfileSettings: () => {
      dispatch(profileSettingsRequest());
    },
    updateProfileSettings: (credentials: any) => {
      dispatch(profileSettingsUpdate(credentials));
    },
  };
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(ProfileSettingComponent))
);
