import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

type StDropZoneProps =
  | {
      accept?: string | string[];
      multiple: true;
      onChangeFile: (file: File[]) => void;
    }
  | {
      accept?: string | string[];
      multiple: false;
      onChangeFile: (file: File[]) => void;
    };
export const StDropZone: React.FC<StDropZoneProps> = (props) => {
  const { onChangeFile, ...rest } = props;
  const { getRootProps, getInputProps, acceptedFiles, isDragActive } =
    useDropzone(rest);
  const { t } = useTranslation();
  useEffect(() => {
    onChangeFile(acceptedFiles);
  }, [acceptedFiles, onChangeFile]);
  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>{t("mediaManager.dropHere")}</p>
        ) : (
          <p>{t("mediaManager.dragAndDropFiles")}</p>
        )}
      </div>
    </section>
  );
};
