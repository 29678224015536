import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function serversRequest(credentials: object): ActionModel {
  return {
    type: types.SERVERS.REQUEST,
    credentials
  };
}

export function serversSuccess(data: object): ActionModel {
  return {
    type: types.SERVERS.SUCCESS,
    data
  };
}

export function serversFailure(err: object): ActionModel {
  return {
    type: types.SERVERS.FAILURE,
    err
  };
}

export function serversUpdate(credentials: object): ActionModel {
  return {
    type: types.SERVERS_UPDATE.REQUEST,
    credentials
  };
}

export function serversUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.SERVERS_UPDATE.SUCCESS,
    data
  };
}

export function serversUpdateFailure(err: object): ActionModel {
  return {
    type: types.SERVERS_UPDATE.FAILURE,
    err
  };
}
