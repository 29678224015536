import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import * as types from '../redux/actions/types';
import {
    userInvitationAddFailure,
    userInvitationAddSuccess,
    userInvitationFailure,
    userInvitationRemoveFailure,
    userInvitationRemoveSuccess,
    userInvitationResendFailure,
    userInvitationResendSuccess,
    userInvitationSuccess,
} from '../redux/actions/user.invitation.action';
import handleResponse from '../services/response.service';
import userInvitation from '../services/user.invitation.service';

const userInvitationCall = (params: any) => userInvitation.userInvitation(RESTAURANT_REQUEST, params);
const addUserInvitationCall = (params: any) => userInvitation.addUserInvitation(RESTAURANT_REQUEST, params);
const removeUserInvitationCall = (params: any) => userInvitation.removeUserInvitation(RESTAURANT_REQUEST, params);
const reSendUserInvitationCall = (params: any) => userInvitation.reSendUserInvitation(RESTAURANT_REQUEST, params);

function* handleuserInvitationRequest(params: object) {
    try {
        let result;
        result = yield call(userInvitationCall, params);
        yield handlUserInvitation200(result);
    } catch (error) {
        throw error;
    }
}

const handlUserInvitation200 = function* handlUserInvitation200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(userInvitationSuccess(result.data));
    } else {
        yield put(userInvitationFailure(result.data));
        handleResponse(result);
    }
}

function* handleUserInvitation(params: object) {
    try {
        let result;
        result = yield call(reSendUserInvitationCall, params);
        yield handleUserInvitation200(result);
    } catch (error) {
        throw error;
    }
}

const handleUserInvitation200 = function* handleUserInvitation200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(userInvitationResendSuccess(result.data));
    } else {
        yield put(userInvitationResendFailure(result.data));
    }
    handleResponse(result);
}

function* handleUserInvitationAdd(params: object) {
    try {
        let result;
        result = yield call(addUserInvitationCall, params);
        yield handleUserInvitationAdd200(result);
    } catch (error) {
        throw error;
    }
}

const handleUserInvitationAdd200 = function* handleUserInvitationAdd200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(userInvitationAddSuccess(result.data));
    } else {
        yield put(userInvitationAddFailure(result.data));
    }
    handleResponse(result);
}

function* handleUserInvitationRemove(params: object) {
    try {
        let result;
        result = yield call(removeUserInvitationCall, params);
        yield handleUserInvitationRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handleUserInvitationRemove200 = function* handleUserInvitationRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(userInvitationRemoveSuccess(result.data));
    } else {
        yield put(userInvitationRemoveFailure(result.data));
    }
    handleResponse(result);
}
export default function* userInvitationSaga() {
    yield takeLatest(types.USERS_INVITATION.REQUEST, handleuserInvitationRequest);
    yield takeLatest(types.USERS_INVITATION_ADD.REQUEST, handleUserInvitationAdd);
    yield takeLatest(types.USERS_INVITATION_REMOVE.REQUEST, handleUserInvitationRemove);
    yield takeLatest(types.USERS_INVITATION_RESEND.REQUEST, handleUserInvitation);
}
