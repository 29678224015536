import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { DARK_GRAY, GRAY, GREEN } from "../../../constant/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faStop, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import image from "../../../assets/images/icon/image.png";
import "../../../styles/ingredients.scss";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";

import commonService from "../../../services/common.service";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button } from "reactstrap";

class ItemImageTitleCard extends Component<any> {
  render() {
    const { t } = this.props;
    const itemLocale = commonService.applyLocalization(
      "restaurant",
      "name",
      this.props.itemData.locales
    );

    return (
      <section className="border rounded p-2 mb-3">
        <header className="d-flex justify-content-between">
          <div className="col d-flex align-items-center pl-0">
            {this.props.cardType === "image-title" && (
              <figure className="img-block custom-res-60x60">
                <img
                  onClick={() => {
                    this.props.setImageEdit(this.props.itemData);
                  }}
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Image Upload"
                  className={"lazyload"}
                  data-src={
                    this.props.itemData.image_url
                      ? this.props.itemData.image_url
                      : image
                  }
                  alt=""
                />
              </figure>
            )}
            <div className="d-flex justify-content-between">
              <h6
                className="text-break"
                style={{ color: DARK_GRAY, paddingLeft: 10 }}
              >
                {this.props?.title !== "robotUsers" && itemLocale?.name}
                {this.props?.title === "robotUsers" &&
                  this.props.itemData.name +
                    " (" +
                    this.props.itemData.username +
                    ")"}
                {this.props?.title === "channels" && this.props.itemData.name}
              </h6>
            </div>
          </div>
          <div className="col d-flex align-items-center">
            {this.props?.extraDetails}
          </div>
          <div className="col d-flex align-items-center justify-content-end pr-0">
            {this.props.hasDetails && (
              <div className="mr-2">
                <Button
                  className="btn btn-info"
                  onClick={() => {
                    this.props.onMoveToDetailPage(this.props.itemData.uuid);
                  }}
                >
                  {t("common.viewMoreDetails")}
                </Button>
              </div>
            )}
            {this.props?.title !== "channels" && (
              <span
                onClick={() => {
                  this.props.onItemEdit(this.props.itemData.uuid);
                }}
                className="pointer-cursor"
                data-toggle="tooltip"
                data-placement="left"
                title={t("common.edit")}
              >
                <FontAwesomeIcon
                  style={{ color: GREEN, margin: 5 }}
                  icon={faEdit}
                />
              </span>
            )}
            {this.props?.title !== "channels" && (
              <span
                onClick={() => {
                  this.props.onItemDelete(
                    this.props.itemData.uuid,
                    "choiceItem"
                  );
                }}
                className="pointer-cursor"
                data-toggle="tooltip"
                data-placement="left"
                title={t("common.delete")}
              >
                <FontAwesomeIcon
                  style={{ color: GRAY, margin: 5 }}
                  icon={faTrashAlt}
                />
              </span>
            )}
          </div>
        </header>
      </section>
    );
  }
}

export default withTranslation()(connect()(withRouter(ItemImageTitleCard)));
