import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Label } from "reactstrap";
import { CARD_SELECTION_STATE } from "../../../constant/constant";
import {
  RecipeTaskStepCardProps,
  taskEditType,
} from "../../../models/task.model";
import commonService from "../../../services/common.service";
import {
  CardItemCardComponent,
  CardItemContainer,
} from "../../card-components/card-item";
import CardMedia from "../../card-media/card-media";
import { withCardItemSelectionState } from "../../cardItemSelectionManager";
import ConditionalLinkArea from "../../conditionalLinkArea/conditionalLinkArea";
import InlineEditInput from "../../inline-edit-input/inline-edit-input";
import WarningComment, {
  getMandatoryWarning,
} from "../../warning-comment/warning-comment";

function RecipeTasksInterventionStepCard(props: RecipeTaskStepCardProps) {
  const [stepDescription, setStepDescription] = useState<string>("");
  const [stepImage, setStepImage] = useState<string>("");
  const [stepVideo, setStepVideo] = useState<string>("");
  const [stepThumbnail, setStepThumbnail] = useState<string>("");
  const [stepVideoThumbnail, setStepVideoThumbnail] = useState<string>("");
  const [warnings, setWarnings] = useState<any>({});
  let isItCardEditInprogress: any = useRef(false);

  const {
    cardItemData,
    cardItemId,
    cardItemSelectionData,
    getUuidFromMediaManager,
    handleCardSelection,
    handleTaskStepSave,
    isNewCardItem,
    onCancelEdit,
    onItemModify,
    parentCardItemSelectionData,
    restLang,
  } = props;
  const { t, i18n } = useTranslation();

  const isEdit: boolean =
    cardItemSelectionData?.selectionState?.isBeingEdited || false;

  useEffect(() => {
    if (isItCardEditInprogress.current) return;
    loadCardItemData();
    isItCardEditInprogress.current = true;
  }, [cardItemData]);

  useEffect(() => {
    applyWarning();
  }, [stepDescription, isEdit]);

  const applyWarning = () => {
    const warnings: any = {};
    const descWarning: any = getMandatoryWarning(stepDescription || "");
    if (descWarning) warnings.description = descWarning;
    setWarnings(warnings);
  };

  const clearImage = () => {
    setStepImage("");
    setStepThumbnail("");
  };

  const clearVideo = () => {
    setStepVideo("");
    setStepVideoThumbnail("");
  };

  const handleCancel = () => {
    isItCardEditInprogress.current = false;
    cardItemSelectionData.deselectCardItem();
    loadCardItemData();
    onCancelEdit();
  };

  const handleDelete = () => {
    onItemModify(cardItemData.uuid, {
      action: CARD_SELECTION_STATE.DELETE,
      area: taskEditType.INTERVENTION_STEP,
      parentTaskId:
        cardItemSelectionData.selectionState.selectedItem[0].id || "",
      interventionId: cardItemSelectionData.selectionState.selectedItem[1].id,
    });
    isItCardEditInprogress.current = false;
  };

  const handleEdit = () => {
    onItemModify(cardItemData.uuid, {
      action: CARD_SELECTION_STATE.EDIT,
      area: taskEditType.STEP,
      parentInterventionId:
        cardItemSelectionData.selectionState.selectedItem[1].id || "",
      parentTaskId:
        cardItemSelectionData.selectionState.selectedItem[0].id || "",
    });
    cardItemSelectionData.editCardItem();
  };

  const handleSave = () => {
    if (Object.keys(warnings).length > 0) return;
    const formData: any = {
      locales: {
        [restLang]: {
          description: stepDescription,
        },
      },
      image_uuid: stepImage,
      ingredients: cardItemData?.ingredients || [],
      prepared_items: cardItemData?.prepared_items || [],
      itemId: cardItemData.uuid,
      type: taskEditType.INTERVENTION_STEP,
      video_uuid: stepVideo,
    };
    isItCardEditInprogress.current = false;
    handleTaskStepSave(formData);
  };

  const handleSelect = () => {
    handleCardSelection(
      {
        interventionId: cardItemSelectionData.selectionState.selectedItem[1].id,
        interventionStepId: cardItemData.uuid,
      },
      taskEditType.INTERVENTION_STEP
    );
    cardItemSelectionData.selectCardItem();
  };

  const loadCardItemData = () => {
    const locales: any = commonService.applyLocalization(
      "restaurant",
      "description",
      cardItemData?.locales
    );

    setStepDescription(locales["description"]);
    setStepImage(cardItemData?.image_uuid || "");
    setStepVideo(cardItemData?.video_uuid || "");
  };

  const onShowMediaManager = async (isEdit: boolean, type: string) => {
    if (!isEdit) return;
    const entityType: string = type === "image" ? "media" : "video";
    let mediaItem = await getUuidFromMediaManager(
      setStepDescription,
      [type],
      entityType
    );
    if (mediaItem) {
      if (type === "image") setStepImage(mediaItem.mediaUuid);
      else setStepVideo(mediaItem.mediaUuid);
    }
  };
  return (
    <CardItemContainer className={""}>
      <CardItemCardComponent
        cardItemSelectionData={cardItemSelectionData}
        className={
          "bf-recipe-ing-task-card  ingredient-type ingredient assigned"
        }
        cancelCallback={handleCancel}
        deleteCallback={handleDelete}
        editCallback={handleEdit}
        isNewCardItem={isNewCardItem}
        parentCardItemSelectionData={parentCardItemSelectionData}
        readOnly={false}
        selectCallback={handleSelect}
        saveCallback={handleSave}
        preventDefault={false}
        stopPropagation={true}
      >
        <div className="">
          <div className="d-flex mb-2">
            <CardMedia
              clearImage={() => clearImage()}
              mediaUuid={stepImage}
              onClick={() => {
                onShowMediaManager(isEdit, "image");
              }}
              activeLink={isEdit}
              entityType="media"
              thumbnail={stepThumbnail}
            />
            <CardMedia
              clearImage={() => clearVideo()}
              mediaUuid={stepVideo}
              onClick={() => {
                onShowMediaManager(isEdit, "video");
              }}
              activeLink={isEdit}
              entityType="video"
              videoUrl={stepVideoThumbnail}
            />
          </div>
          <div>
            <WarningComment
              containerClassName={"d-block w-100 mb-2 mr-2"}
              // itemWrapperClass={"d-block "}
              data={warnings?.description || null}
              displayType={"bottom"}
            >
              <InlineEditInput
                id={"interventionDescription_" + cardItemId}
                name={"interventionDescription_" + cardItemId}
                value={stepDescription}
                editElement={Input}
                edit={isEdit}
                style={{ display: "block" }}
                styleEdit={{ minHeight: 100, width: "100%" }}
                propsEdit={{
                  key: "interventionStepDescription_" + cardItemId,
                  type: "textarea",
                  placeholder: "What needs to be done?",
                }}
                styleContainer={{ width: "100%" }}
                onChange={(name: string, val: any) => setStepDescription(val)}
              />
            </WarningComment>
          </div>
        </div>
      </CardItemCardComponent>
    </CardItemContainer>
  );
}

export default withCardItemSelectionState(
  "interventionStep",
  3
)(RecipeTasksInterventionStepCard);
