import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { GRAY } from "../../constant/constant";
import { Table } from "reactstrap";
import { useTranslation } from "react-i18next";

interface JsonTableComponentProps {
  jsonData: object;
}

const JsonTableComponent: React.FC<JsonTableComponentProps> = ({
  jsonData,
}) => {
  const [expanded, setExpanded] = useState<any>({});
  const { t } = useTranslation();

  const toggleExpand = (key: string) => {
    setExpanded((prevState: any) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const translate = (key: string) => {
    const translated = t(key);
    return translated !== key ? translated : key.replace("common.", "");
  };

  const isImage = (url: any) => {
    return /^https?:\/\/.*/.test(url);
  };

  const renderTable = (obj: object) => {
    return (
      <Table bordered striped>
        <tbody>
          {Object.entries(obj).map(([key, value]) => (
            <tr key={key}>
              <td>{translate("common." + key)}</td>
              <td>
                {value === null || value === undefined ? (
                  ""
                ) : typeof value === "object" ? (
                  <FontAwesomeIcon
                    className="bf-accordion-arrow mr-2"
                    icon={expanded[key] ? faChevronDown : faChevronRight}
                    onClick={() => {
                      toggleExpand(key);
                    }}
                    color={GRAY}
                  />
                ) : isImage(value) ? (
                  <img src={value} alt={key} style={{ maxWidth: "100px" }} />
                ) : (
                  value
                )}
                {expanded[key] && typeof value === "object" && (
                  <JsonTableComponent jsonData={value} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return renderTable(jsonData);
};

export default JsonTableComponent;
