import apiCall from './api.call.config';

async function userverification(url: string, params: any) {
    try {
        return await apiCall.post(url, params.credentials);
    } catch (e) {
        throw e;
    }
}

export default { userverification }
