import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import ModalFactory from "react-modal-promise";
import { withRouter } from "react-router";
import { create } from "apisauce";

import { ACCESS_TOKEN } from "../../constant/constant";
import { BASE_URL } from "../../config/app.config";
import ConceptHeaderComponent from "../../components/navigation/navigation-header/concept-header/concept-header";
import commonService from "../../services/common.service";
import {
  ServiceSetInitState,
  ServiceSetState,
} from "../../models/serviceset.model";
import handleResponse from "../../services/response.service";
import LoaderComponent from "../../components/loader/loader";
import { OpenDeleteModal } from "../../components/card-components/delete-card/delete-modal";
import RestaurantNavComponent from "../../components/navigation/navigation-left/restaurant-navigation/restaurant-navigation";
import NavigationRightComponent from "../../components/navigation/navigation-right/navigation-right";
import {
  servicesetAdd,
  servicesetClean,
  servicesetClear,
  servicesetRemove,
  servicesetRequest,
  servicesetUpdate,
} from "../../redux/actions/serviceset.action";
import ServiceSetWrapper from "../../components/service-set/serviceSetWrapper";
import { withTranslation } from "react-i18next";

const api = create({
  baseURL: BASE_URL,
});

class ServiceSetPageNew extends Component<any> {
  state: ServiceSetState;
  pageText: Object = {
    addButtonTitle: this.props.t("serviceSet.addServiceSet"),
    pageTitle: this.props.t("serviceSet.serviceSets"),
  };
  constructor(props: any) {
    super(props);
    this.state = ServiceSetInitState;
    api.setHeaders({
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Cache-Control": "no-cache",
      Locale: localStorage.getItem("i18nextLng") || "en-gb",
      "content-type": "application/json",
    });
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.setState({ restaurantuuid: this.props.match.params.uuid });
    this.props.getServiceSet({ restaurantuuid: this.props.match.params.uuid });
  }
  componentDidUpdate(prevProps: any, prevState: any) {}

  render() {
    return (
      <>
        <div>
          <ModalFactory />
          <LoaderComponent display={this.state.isFetching} />

          <div className="container-fluid">
            <ConceptHeaderComponent concept={"concept"} />
            <Row className="main light-theme">
              <Col xl={2} lg={3} className="hide-left-max">
                <RestaurantNavComponent
                  display={"service-sets"}
                  restaurantuuid={this.props.match.params.uuid}
                />
              </Col>
              <Col xl={8} lg={6}>
                <ServiceSetWrapper
                  getServiceSet={this.props.getServiceSet}
                  itemData={
                    Object.keys(this.props.serviceSets).length === 0
                      ? []
                      : this.props.serviceSets
                  }
                  pageText={this.pageText}
                  onMoveToDetailPage={this.moveToDetailPage}
                  setUpdated={() => {}}
                />
              </Col>
              <Col xl={2} lg={3}>
                <NavigationRightComponent />
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }

  public getAddModalLabel = () => {
    return {
      modalTitle: this.state.isEdit
        ? this.props.t("serviceSet.editServiceSet")
        : this.props.t("serviceSet.addServiceSet"),
    };
  };

  public getPageTitle = () => {
    return {
      addButtonTitle: this.props.t("serviceSet.addServiceSet"),
      pageTitle: this.props.t("serviceSet.serviceSets"),
    };
  };
  public fetchData = () => {
    this.setState({
      fetchData: true,
    });
  };

  public handleItemDelete = (itemId: any, element: string) => {
    OpenDeleteModal().then(() => {
      this.props.removeRecipe({
        uuid: itemId,
        restaurantuuid: this.props.match.params.uuid,
      });
      this.setState({
        isUpdated: true,
        selectedElement: element,
        selectedItemId: itemId,
      });
    });
  };

  public moveToDetailPage = (itemId: string) => {
    this.setState({
      uuid: itemId,
    });
    this.props.history.push(
      `/restaurant/${this.props.match.params.uuid}/service-sets/${itemId}/details`
    );
  };

  public handleAddItemClick = () => {
    this.setState({
      color: "#FFFFFF",
      description: "",
      // isAddItemModalOpen: !this.state.isAddItemModalOpen,
      isEdit: false,
      name: "",
      itemId: "",
      printerName: "",
      outputquantity: "",
      outputunituuid: "",
      isManged: "",
      selectedLabels: [],
    });
  };
  public handleChange = (data: any) => {
    this.setState({
      [data.target.name]: data.target.value,
    });
  };
  public handleItemEdit = (itemId: any) => {};
  public handleItemSave = () => {
    this.setState({ isUpdated: true });
  };
}
const mapStateToProps: any = (state: any) => {
  let isFetching;
  let failure;
  return {
    failure: failure,
    isFetching: isFetching,
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    serviceSets: state.serviceset.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getServiceSet: (credentials: any) => {
      dispatch(servicesetRequest(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ServiceSetPageNew))
);
