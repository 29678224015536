import React from "react";
import Select from "react-select";
import {
  Button,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import "../../../styles/labels.scss";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function LabeldAddCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <React.Fragment>
      <Modal
        isOpen={props.state.uuid ? props.state.editModal : props.state.addmodal}
      >
        <ModalHeader toggle={props.AddToggle} className="modal-header">
          {props.state.uuid ? t("label.editLabel") : t("label.addLabel")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="name">{t("label.labelName")}</Label>
              <Label className="required-star-style">*</Label>
              <Input
                type="text"
                required
                name="name"
                autoComplete="name"
                placeholder={t("label.enterLabelName")}
                value={props.state.name}
                onChange={props.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="labeldescription">
                {t("label.labelDescription")}
              </Label>
              <Input
                type="textarea"
                style={{ minHeight: 200 }}
                required
                name="labeldescription"
                autoComplete="labeldescription"
                placeholder={t("label.enterLabelDescription")}
                value={props.state.labeldescription}
                onChange={props.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="label">{t("label.parentLabels")}</Label>
              <Select
                value={props.state.selectedOption}
                onChange={props.handleSelectChange}
                options={props.options}
                isMulti
                maxMenuHeight={200}
                placeholder={t('common.select')}
              />
            </FormGroup>
            <FormGroup>
              <Label for="printerUuid">
                {t("printer.orderPrinter")} 
              </Label>
              <CustomInput
                type="select"
                id="printerUuid"
                name="printerUuid"
                onChange={props.handlePrinterChange}
                value={props.state.printerUuid}
              >
                <option value="">
                  {t("common.noPrinting")}
                </option>
                {props.PRINTERLIST}
              </CustomInput>
            </FormGroup>
            <FormGroup>
              <Label for="color">{t("label.labelColor")}</Label>
              <Input
                type="color"
                name="color"
                placeholder="Color"
                value={props.state.color}
                onChange={props.handleChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.AddToggle}>
            {t("common.cancel")}
          </Button>
          <Button
            color="info"
            onClick={props.saveAdd}
            disabled={!props.state.name || !!props.state.isFetching}
          >
            {t("common.save")}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
