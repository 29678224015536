import React, { useCallback, useEffect } from "react";
import { UsersMenuLayout } from "../../components/users-menu-layout/users-menu-layout";
import { withTranslation, useTranslation } from "react-i18next";
import { withRouter, useParams, Link } from "react-router-dom";
import LoaderComponent from "../../components/loader/loader";
import {
  addAppsModal,
  SubmitPayload,
} from "../../components/card-components/add-apps/add-apps-modal";
import apiCall from "../../services/api.call.config";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import useApi from "../../hooks/useApi";
import { DARK_GRAY, GRAY, GREEN } from "../../constant/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Apk } from "../../Types/Apk";
import { areYouSureModal } from "../../components/prompts/AreYouSure";

enum FORM_TYPE {
  ADD_APK,
  UPDATE_APK,
  ADD_PACKAGE,
  UPDATE_PACKAGE,
}

const AppsPage: React.FC = (props) => {
  const { uuid } = useParams<{ uuid: string }>();
  const { t } = useTranslation();

  const { request: postApps, loading: createLoading } = useApi(
    (formData: FormData) =>
      apiCall.post(RESTAURANT_REQUEST + `/${uuid}/apk`, formData),
    true
  );
  const { request: postAppsEdit, loading: editLoading } = useApi(
    (apkUuid: string, formData: FormData) =>
      apiCall.post(RESTAURANT_REQUEST + `/${uuid}/apk/${apkUuid}`, formData),
    true
  );

  const fetchData = useCallback(() => {
    return apiCall.get<Apk[]>(RESTAURANT_REQUEST + `/${uuid}/apks`);
  }, [uuid]);

  const {
    data: apks,
    loading: listLoading,
    request: refreshList,
  } = useApi<Apk[]>(fetchData);

  const openAddEditModall = async (apk: Apk) => {
    await addAppsModal({
      onSubmit: submitForm,
      formData: { apk },
      isEdit: true,
    });
  };
  const createFormdata = () => {
    const formData = new FormData();
    formData.append("object_type", "restaurant");
    formData.append("object_uuid", uuid);
    return formData;
  };

  const openAddAppsModal = async () => {
    await addAppsModal({ onSubmit: submitForm });
  };

  const checkPayload = (payload: SubmitPayload) => {
    if (payload.files && Array.isArray(payload.files)) {
      return FORM_TYPE.ADD_APK;
    }
    if (payload.file) {
      return FORM_TYPE.UPDATE_APK;
    }
    if (payload.packageName && payload.uuid) {
      return FORM_TYPE.UPDATE_PACKAGE;
    } else {
      return FORM_TYPE.ADD_PACKAGE;
    }
  };

  const submitForm = async (payload: SubmitPayload) => {
    try {
      const type = checkPayload(payload);
      switch (type) {
        case FORM_TYPE.ADD_APK:
          if (payload.files && Array.isArray(payload.files)) {
            const formData = createFormdata();
            for (let i = 0; i < payload.files.length; i++) {
              formData.append("apks[]", payload.files[i]);
            }
            await postApps(formData);
          }
          break;
        case FORM_TYPE.UPDATE_APK:
          if (payload.file) {
            const data = createFormdata();
            data.append("apk", payload.file);
            await postAppsEdit(payload.uuid, data);
          }
          break;
        case FORM_TYPE.ADD_PACKAGE:
          if (payload.packageName) {
            const packageFormData = createFormdata();
            packageFormData.append("package", payload.packageName);
            await postApps(packageFormData);
          }
          break;
        case FORM_TYPE.UPDATE_PACKAGE:
          if (payload.packageName && payload.uuid) {
            const data = createFormdata();
            data.append("package", payload.packageName);
            await postAppsEdit(payload.uuid, data);
          }
          break;
      }
      refreshList();
    } catch (er) {
      console.log("e", er);
    }
  };

  const onDelete = async (apk: Apk) => {
    await areYouSureModal({
      onConfirm: async () => {
        await apiCall.remove(
          RESTAURANT_REQUEST + `/${uuid}/apk/${apk.uuid}/delete`
        );
        refreshList();
      },
    });
  };

  useEffect(() => {
    document.body.className = "light-theme";
  }, []);

  const renderCardActionButtons = (apk: Apk) => {
    return (
      <>
        <span
          className="pointer-cursor"
          data-toggle="tooltip"
          data-placement="left"
          title={t("common.edit")}
          onClick={() => openAddEditModall(apk)}
        >
          <FontAwesomeIcon style={{ color: GREEN, margin: 5 }} icon={faEdit} />
        </span>
        <span
          className="pointer-cursor"
          data-toggle="tooltip"
          data-placement="left"
          title={t("common.delete")}
          onClick={() => onDelete(apk)}
        >
          <FontAwesomeIcon
            style={{ color: GRAY, margin: 5 }}
            icon={faTrashAlt}
          />
        </span>
      </>
    );
  };
  const appList = apks?.data || [];

  return (
    <div>
      <LoaderComponent display={listLoading || createLoading || editLoading} />
      <UsersMenuLayout display={"apps"} restaurantUuid={uuid}>
        <LoaderComponent display={false} />
        <Link
          to="#"
          color="info"
          className="transport-add"
          onClick={openAddAppsModal}
        >
          {t("apps.addApps")}
        </Link>
        <div className="white-box mb-3">
          <h4>{t("apps.apps")}</h4>
          {appList.map((app) => (
            <section className="bf-service-item-collapse border rounded mb-3">
              <header className="d-flex align-items-center justify-content-between w-100">
                <div className="col-left d-flex align-items-center  px-3 py-2 w-100 cursor-pointer">
                  <figure className="img-block ml-1 m-0">
                    {app.image && (
                      <img
                        className="d-block"
                        data-toggle="tooltip"
                        data-placement="left"
                        title={app.filename}
                        data-src={app.image}
                        alt=""
                        src={app.image}
                      />
                    )}
                  </figure>
                  <h6
                    className="m-0"
                    style={{
                      color: DARK_GRAY,
                      paddingLeft: 10,
                    }}
                  >
                    <div>{app.filename || app.package_name}</div>
                  </h6>
                </div>
                <span className="d-none d-sm-flex  align-items-center">
                  {renderCardActionButtons(app)}
                </span>
              </header>
            </section>
          ))}
        </div>
      </UsersMenuLayout>
    </div>
  );
};

export default withTranslation()(withRouter(AppsPage));
