import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import {
    printerAddFailure,
    printerAddSuccess,
    printerFailure,
    printerRemoveFailure,
    printerRemoveSuccess,
    printerSuccess,
    printerUpdateFailure,
    printerUpdateSuccess,
} from '../redux/actions/printer.action';
import * as types from '../redux/actions/types';
import printer from '../services/printer.service';
import handleResponse from '../services/response.service';

const printerCall = (params: any) => printer.printer(RESTAURANT_REQUEST, params);
const updatePrinterCall = (params: any) => printer.updatePrinter(RESTAURANT_REQUEST, params);
const addPrinterCall = (params: any) => printer.addPrinter(RESTAURANT_REQUEST, params);
const removePrinterCall = (params: any) => printer.removePrinter(RESTAURANT_REQUEST, params);

function* handlePrinterRequest(params: object) {
    try {
        let result;
        result = yield call(printerCall, params);
        yield handlePrinter200(result);
    } catch (error) {
        throw error;
    }
}

const handlePrinter200 = function* handlePrinter200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(printerSuccess(result.data));
    } else {
        yield put(printerFailure(result.data));
        handleResponse(result);
    }
}

function* handlePrinterUpdate(params: object) {
    try {
        let result;
        result = yield call(updatePrinterCall, params);
        yield handlePrinterUpdate200(result);
    } catch (error) {
        throw error;
    }
}

const handlePrinterUpdate200 = function* handlePrinterUpdate200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(printerUpdateSuccess(result.data));
    } else {
        yield put(printerUpdateFailure(result.data));
    }
    handleResponse(result);
}

function* handlePrinterAdd(params: object) {
    try {
        let result;
        result = yield call(addPrinterCall, params);
        yield handlePrinterAdd201(result);
    } catch (error) {
        throw error;
    }
}

const handlePrinterAdd201 = function* handlePrinterAdd201(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(printerAddSuccess(result.data));
    } else {
        yield put(printerAddFailure(result.data));
    }
    handleResponse(result);
}

function* handlePrinterRemove(params: object) {
    try {
        let result;
        result = yield call(removePrinterCall, params);
        yield handlePrinterRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handlePrinterRemove200 = function* handlePrinterRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(printerRemoveSuccess(result.data));
    } else {
        yield put(printerRemoveFailure(result.data));
    }
    handleResponse(result);
}
export default function* printerSaga() {
    yield takeLatest(types.PRINTER.REQUEST, handlePrinterRequest);
    yield takeLatest(types.PRINTER_UPDATE.REQUEST, handlePrinterUpdate);
    yield takeLatest(types.PRINTER_ADD.REQUEST, handlePrinterAdd);
    yield takeLatest(types.PRINTER_REMOVE.REQUEST, handlePrinterRemove);
}
