import "../../../styles/role-rights.scss";
import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  ModalHeader,
} from "reactstrap";
import { useTranslation } from "react-i18next";

export default function RoleAddCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <Modal isOpen={props.state.addmodal}>
      <ModalHeader className="modal-header">{t("role.addRole")}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstname">{t("role.roleName")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name="name"
              autoComplete="name"
              placeholder={t("role.enterRoleName")}
              onChange={props.handleChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.AddToggle}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          disabled={!props.state.name}
          onClick={props.saveRoleAdd}
        >
          {t("common.save")}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
