import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useTranslation } from "react-i18next";

import "react-datepicker/dist/react-datepicker.css";

export default function ItemAddModal(props: any) {
  const { t, i18n } = useTranslation();
  const [isSubmitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    setName(props.formData.name || "");
    setDescription(props.formData.description || "");
    if (!!props.formData?.isEdit) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }
  }, [props.formData]);

  const handleChange = (data: any) => {
    setSubmitBtnDisabled(true);
    if (data?.target?.name === "name") {
      setName(data.target.value);
      if (data?.target?.value.trim() !== "") {
        description.trim() !== '' && setSubmitBtnDisabled(false);
      }
    }
    if (data?.target?.name === "description") {
      setDescription(data.target.value);
      if (data?.target?.value.trim() !== "") {
        name.trim() !== '' && setSubmitBtnDisabled(false);
      }
    }
  };
  const handleSubmit = () => {
    const lang = (localStorage.getItem("i18nextLng") || "en-gb").toLowerCase();
    let locales: any = {};
    locales[lang] = { name, description };

    props.handleSubmit({
      locales,
    });
  };
  return (
    <React.Fragment>
      <Modal size={"xl"} isOpen={props.isModalOpen}>
        <ModalHeader toggle={props.handleCancel} className="modal-header">
          {!props.formData?.isEdit
            ? t("printer.addPrinterTemplate")
            : t("printer.editPrinterTemplate")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="name">{t('common.name')}<span className="text-danger">*</span></Label>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder={t('common.enterName')}
                onChange={handleChange}
                required
                value={name}
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">{t('common.templateCode')}<span className="text-danger">*</span></Label>
              <Input
                type="textarea"
                name="description"
                id="description"
                rows={12}
                placeholder={t('common.enterTemplateCode')}
                onChange={handleChange}
                required
                value={description}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.handleCancel}>
            {t("common.cancel")}
          </Button>
          <Button
            color="info"
            onClick={handleSubmit}
            disabled={isSubmitBtnDisabled}
          >
            {t("common.save")}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
