import '../../styles/registration.scss';

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Col, Container, CustomInput, Row } from 'reactstrap';

import smartToniLogin from '../../assets/images/logo/smartToniLogin.png';
import { DB, DEBUG, SEGMENT } from '../../constant/constant';
import LoaderComponent from '../loader/loader';

class DevelopmentComponent extends Component<any> {
    state: any;

    constructor(props: any) {
        super(props);
        this.state = {
            segment: false,
            debug: false,
            connect: 'null'
        };
        this._setFunctionBindings();
    }
    render() {
        return (
            <div>
                <LoaderComponent display={!!this.state.isFetching} />
                <Container>
                    <Row>
                        <Col><img className="img-home-fluid" src={smartToniLogin} alt="Logo" /></Col>
                    </Row>
                    <Row className="registration-form-container"
                        style={{ display: localStorage.getItem('MODE') === 'LIVE' ? 'none' : 'block' }}>
                        <Col className='dev-mode'>
                            <CustomInput type="checkbox" id="debug" name="debug" checked={this.state.debug}
                                onChange={this.handleDebugChange.bind(this)} label={'Enable API debugging mode'} />
                            <CustomInput type="checkbox" id="segment" name="segment" checked={this.state.segment}
                                onChange={this.handleSegmentChange.bind(this)} label={'Show task segment visualization'} />
                        </Col>
                    </Row>
                    <Row className="registration-form-container"
                        style={{
                            display: (localStorage.getItem('MODE') === 'LIVE')
                                || (localStorage.getItem('MODE') === 'UAT') ? 'none' : 'block'
                        }}>
                        <Col className='dev-mode'>
                            <h5>Environment</h5>
                            <span style={{ display: localStorage.getItem('MODE') === 'TEST' ? 'none' : 'block' }}>
                                <CustomInput id={'test'} type="radio" name="db"
                                    defaultChecked={localStorage.getItem(DB) === 'TESTDB' ? true : false} value="TESTDB"
                                    onChange={this.handleChange.bind(this)}
                                    label={'Connect with testing DB'} />
                            </span>
                            <CustomInput id={'prelive'} type="radio" name="db" onChange={this.handleChange.bind(this)}
                                defaultChecked={localStorage.getItem(DB) === 'PREDB' ? true : false} value="PREDB"
                                label={'Connect with prelive DB'} />
                            <CustomInput id={'none'} type="radio" name="db" onChange={this.handleChange.bind(this)}
                                defaultChecked={localStorage.getItem(DB) === 'null' ? true : false} value="null"
                                label={'Default'} />
                        </Col>
                        <Button size="sm" color="secondary" block onClick={this.clearState.bind(this)}
                            style={{ display: localStorage.getItem('MODE') === 'LIVE' ? 'none' : 'block', marginTop: 20 }}>Clear State</Button>
                    </Row>
                </Container>
            </div >
        );
    }

    componentDidMount() {
        if (!!localStorage.getItem(SEGMENT)) {
            this.setState({
                segment: localStorage.getItem(SEGMENT) === 'true' ? true : false,
                debug: localStorage.getItem(DEBUG) === 'true' ? true : false,
                connect: localStorage.getItem(DB) === 'null' ? 'null' : localStorage.getItem(DB)
            });
        } else {
            localStorage.setItem(SEGMENT, 'false');
            localStorage.setItem(DEBUG, 'false');
            localStorage.setItem(DB, 'null');
        }
    }

    private _setFunctionBindings(): void {
        this.handleChange = this.handleChange.bind(this);
        this.handleDebugChange = this.handleDebugChange.bind(this);
        this.handleSegmentChange = this.handleSegmentChange.bind(this);
    }

    public clearState() {
        localStorage.clear()
    }

    public handleChange(event: any): void {
        this.setState({
            connect: event.target.value
        })
        localStorage.setItem(DB, event.target.value);
    }

    public handleDebugChange(event: any): void {
        this.setState({
            debug: !this.state.debug
        })
        localStorage.setItem(DEBUG, (!this.state.debug).toString());
    }

    public handleSegmentChange(event: any): void {
        this.setState({
            segment: !this.state.segment
        })
        localStorage.setItem(SEGMENT, (!this.state.segment).toString());
    }
}

export default (withRouter(DevelopmentComponent));
