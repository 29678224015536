import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function restaurantRequest(): ActionModel {
  return {
    type: types.RESTAURANT.REQUEST
  };
}

export function restaurantSuccess(data: object): ActionModel {
  return {
    type: types.RESTAURANT.SUCCESS,
    data
  };
}

export function restaurantFailure(err: object): ActionModel {
  return {
    type: types.RESTAURANT.FAILURE,
    err
  };
}

export function restaurantUpdate(credentials: object): ActionModel {
  return {
      type: types.RESTAURANT_UPDATE.REQUEST,
      credentials
  };
}

export function restaurantUpdateSuccess(data?: object): ActionModel {
  return {
      type: types.RESTAURANT_UPDATE.SUCCESS,
      data
  };
}

export function restaurantUpdateFailure(err: object): ActionModel {
  return {
      type: types.RESTAURANT_UPDATE.FAILURE,
      err
  };
}

export function restaurantAdd(credentials: object): ActionModel {
  return {
      type: types.RESTAURANT_ADD.REQUEST,
      credentials
  };
}

export function restaurantAddSuccess(data?: object): ActionModel {
  return {
      type: types.RESTAURANT_ADD.SUCCESS,
      data
  };
}

export function restaurantAddFailure(err: object): ActionModel {
  return {
      type: types.RESTAURANT_ADD.FAILURE,
      err
  };
}

export function restaurantRemove(credentials: object): ActionModel {
  return {
      type: types.RESTAURANT_REMOVE.REQUEST,
      credentials
  };
}

export function restaurantRemoveSuccess(data?: object): ActionModel {
  return {
      type: types.RESTAURANT_REMOVE.SUCCESS,
      data
  };
}

export function restaurantRemoveFailure(err: object): ActionModel {
  return {
      type: types.RESTAURANT_REMOVE.FAILURE,
      err
  };
}
