import "../../styles/units.scss";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { recipeRequest } from "../../redux/actions/recipe.action";

import "./translation.css";
class HeadingComponent extends Component<any> {
  render() {
    const { title } = this.props;
    return (
      <div>
        <div>
          <span className="font-weight-bodiv">{title}</span>
        </div>
        <div></div>
      </div>
    );
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching = state.recipe.isFetching;
  let failure = state.recipe.failure;
  return {
    isFetching: isFetching,
    failure: failure,
    recipes: state.recipe.data,
    tasks: state.task.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeadingComponent));
