import React, { Component } from 'react';

import SupportComponent from '../../components/support/support';

class SupportPage extends Component {

    render() {
        return (
            <SupportComponent/>
        );
    }
}
export default SupportPage;
