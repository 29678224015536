export interface PaymentProviderModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export const PaymentProviderModelInitState = {
  isFetching: false,
  data: [],
  error: {},
  failure: false,
};

export interface PaymentProviderState {
  addItemData: any;
  elementId:any;
  fetchData: any;
  isAddItemModalOpen: boolean;
  isDeleted: boolean;
  isFetching: boolean;
  itemDetails: any;
  itemModalData: any;
  items: Array<any>;
  itemsDetails: any;
  removeItemData: any;
  selectedItemId: string;
  updateItemData: any;
  updatePaymentProvider: any;
}

export const PaymentProviderInitState = {
  addItemData: {},
  elementId:"",
  fetchData: false,
  isAddItemModalOpen: false,
  isDeleted: false,
  isFetching: false,
  itemDetails: [],
  itemModalData: {
    date: "",
    isEdit: false,
    name: "",
  },
  items: [],
  itemsDetails: [],
  removeItemData: {},
  selectedItemId: "",
  updateItemData: {},
  updatePaymentProvider: {},
};

