import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ModalFactory from "react-modal-promise";
import {
  ImageTitleComponentState,
  ImageTitleComponentInitState,
} from "../../../models/imageTitleComponent.model";
import commonService from "../../../services/common.service";
import { OpenDeleteModal } from "../delete-card/delete-modal";
import ItemImageTitleCard from "../../service-item/shared/ItemImageTitleCard";
import { withTranslation } from "react-i18next";
import _ from "lodash";

class ImageTitleComponent extends Component<any> {
  state: ImageTitleComponentState;
  constructor(props: any) {
    super(props);
    this.state = ImageTitleComponentInitState;
  }

  render() {
    const { t } = this.props;
    const itemData: Array<any> = [];
    this.props.itemData.forEach((item: any) => {
      if (item?.name) {
        item["nameLower"] = item.name?.toLowerCase() ||"";
      } else {
        item["nameLower"] = commonService
          .applyLocalization("restaurant", "name", item.locales)
          ["name"]?.toLowerCase() ||"";
      }
      itemData.push(item);
    });

    return (
      <>
        <div>
          <ModalFactory />
          <Link
            to="#"
            color="info"
            className="unit-add"
            onClick={this.props.onAddNewItem}
          >
            {this.props.pageText.addButtonTitle}
          </Link>
          {!!this.props?.extraNavButtons && this.props.extraNavButtons()}
        </div>
        <div className="white-box mb-3">
          <h4>{this.props.pageText.pageTitle}</h4>
          {this.props?.cardType &&
            _.sortBy(itemData, "nameLower").map((item: any, index: number) => (
              <ItemImageTitleCard
                addToggle={this.addToggle.bind(this, item.uuid)}
                cardType={this.props?.cardType}
                extraDetails={
                  this.props?.getExtraDetails
                    ? this.props.getExtraDetails(item)
                    : ""
                }
                handleSelect={this.props.handleSelect}
                hasDetails={this.props?.hasDetails}
                itemData={item}
                itemDetails={this.getItemDetails(item.uuid)}
                key={index}
                onItemDelete={this.props.onItemDelete}
                onItemEdit={this.props.onItemEdit}
                onMoveToDetailPage={this.props.onMoveToDetailPage}
                title={this.props?.title}
              />
            ))}
          {this.props.itemData.length === 0 && (
            <div style={{ width: "100%", textAlign: "center" }}>
              {this.props.t("common.noRecords")}
            </div>
          )}
        </div>
      </>
    );
  }

  public addToggle = (itemId: string) => {
    this.props.setItemId(itemId);
    this.setState((prevState: any) => ({
      addmodal: !prevState.addmodal,
      name: "",
      symbol: "",
    }));
  };

  public getItemDetails(itemUuid: string) {
    const [data] = this.props.itemsDetails.filter((item: any) => {
      return item.uuid === itemUuid;
    });
    return data;
  }

  public toggle = () => {
    this.setState((prevState: any) => ({
      editmodal: !prevState.editmodal,
    }));
  };

  public toggleModal = () => {
    let showModal = this.state.showModal;
    this.setState({ showModal: !showModal });
  };

  public toggleEditModal = () => {
    let showEditModal = this.state.showEditModal;
    this.setState({ showEditModal: !showEditModal });
  };
}

export default withTranslation()(withRouter(ImageTitleComponent));
