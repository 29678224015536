export interface MediaManagerState {
  currentPage: number;
  isFetching: boolean;
  isUpdated: boolean;
  selectedItems: Array<string>;
  items: any;
  totalPages: number;
}

export const MediaManagerInitState: MediaManagerState = {
  currentPage: 1,
  isFetching: false,
  isUpdated: false,
  selectedItems: [],
  items: {},
  totalPages: 1,
};

export interface MediaListProps {
  items: Array<any>;
  actions: any;
}
export interface MediaListItemProps {
  item: any;
  actions: any;
}

export enum ITEM_ACTIONS {
  DELETE = "delete",
  SELECTION = "selection",
}

export enum MEDIA_TYPE {
  IMAGE = "image",
  VIDEO = "video",
  FILES = "files",
}
