export interface inventoryModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface InventoryState {
  inventory: any;
  isFetching: boolean;
  name: any;
  symbol: string;
  uuid: string;
  support:any;
  baseUrl:string;
  isSuperadmin:boolean;
}

export const InventoryInitState = {
  name: '',
  symbol: '',
  isFetching: false,
  inventory: [],
  uuid: '',
  support:[],
  baseUrl:'https://cobrowse.io/dashboard?token=',
  isSuperadmin: false,
};
