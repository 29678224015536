import React, { Component } from 'react';

import SettingsComponent from '../../components/settings/settings';

class SettingsPage extends Component {

    render() {
        return (
            <SettingsComponent />
        );
    }
}
export default SettingsPage;
