import '../../styles/registration.scss';

import React, { Component } from 'react';

import RegistrationComponent from '../../components/registration/registration';

class RegistrationPage extends Component {

    render() {
        return (
            <div className="registration-style">
                <RegistrationComponent/>
            </div>
        );
    }
}
export default RegistrationPage;
