import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function paymentProviderRequest(credentials: object): ActionModel {
  return {
    type: types.PAYMENT_PROVIDER.REQUEST,
    credentials,
  };
}

export function paymentProviderSuccess(data: object): ActionModel {
  return {
    type: types.PAYMENT_PROVIDER.SUCCESS,
    data,
  };
}

export function paymentProviderFailure(err: object): ActionModel {
  return {
    type: types.PAYMENT_PROVIDER.FAILURE,
    err,
  };
}
export function paymentProviderAdd(credentials: object): ActionModel {
  return {
    type: types.PAYMENT_PROVIDER_ADD.REQUEST,
    credentials,
  };
}

export function paymentProviderAddSuccess(data?: object): ActionModel {
  return {
    type: types.PAYMENT_PROVIDER_ADD.SUCCESS,
    data,
  };
}

export function paymentProviderAddFailure(err: object): ActionModel {
  return {
    type: types.PAYMENT_PROVIDER_ADD.FAILURE,
    err,
  };
}

export function paymentProviderDetails(credentials: object): ActionModel {
  return {
    type: types.PAYMENT_PROVIDER_DETAILS.REQUEST,
    credentials,
  };
}

export function paymentProviderDetailsSuccess(data?: object): ActionModel {
  return {
    type: types.PAYMENT_PROVIDER_DETAILS.SUCCESS,
    data,
  };
}

export function paymentProviderDetailsFailure(err: object): ActionModel {
  return {
    type: types.PAYMENT_PROVIDER_DETAILS.FAILURE,
    err,
  };
}

export function paymentProviderRemove(credentials: object): ActionModel {
  return {
    type: types.PAYMENT_PROVIDER_REMOVE.REQUEST,
    credentials,
  };
}

export function paymentProviderRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.PAYMENT_PROVIDER_REMOVE.SUCCESS,
    data,
  };
}

export function paymentProviderRemoveFailure(err: object): ActionModel {
  return {
    type: types.PAYMENT_PROVIDER_REMOVE.FAILURE,
    err,
  };
}
export function paymentProviderUpdate(credentials: object): ActionModel {
  return {
    type: types.PAYMENT_PROVIDER_UPDATE.REQUEST,
    credentials,
  };
}

export function paymentProviderUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.PAYMENT_PROVIDER_UPDATE.SUCCESS,
    data,
  };
}

export function paymentProviderUpdateFailure(err: object): ActionModel {
  return {
    type: types.PAYMENT_PROVIDER_UPDATE.FAILURE,
    err,
  };
}
