import apiCall from './api.call.config';

async function addTaskStep(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url + '/' + params.credentials.restaurantuuid + '/recipe/' + params.credentials.recipeuuid
            + '/task/' + params.credentials.taskuuid + '/step', params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function updateTaskStep(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.put(url + '/' + params.credentials.restaurantuuid + '/recipe/' + params.credentials.recipeuuid
            + '/task/' + params.credentials.taskuuid + '/step/' + params.credentials.uuid, params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function removeTaskStep(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.remove(url + '/' + params.credentials.restaurantuuid + '/recipe/' + params.credentials.recipeuuid
            + '/task/' + params.credentials.taskuuid + '/step/' + params.credentials.uuid);
    } catch (e) {
        throw e;
    }
}

export default {
    addTaskStep,
    updateTaskStep,
    removeTaskStep
}