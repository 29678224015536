import { InstanceProps, create } from "react-modal-promise";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Label,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import React, { useState, useCallback, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { StDropZone } from "../../st-drop-zone/st-drop-zone";
import { Apk } from "../../../Types/Apk";
import { Link } from "react-router-dom";
export type SubmitPayload = {
  files?: File[];
  file?: File;
  packageName?: string;
  uuid?: string;
};
type AddAppsModalProps = InstanceProps<unknown> &
  (
    | {
        onSubmit: (payload: SubmitPayload) => Promise<void>;
        isEdit?: false;
        formData?: never;
      }
    | {
        onSubmit: (payload: SubmitPayload) => Promise<void>;
        isEdit: true;
        formData: { apk: Apk };
      }
  );
const AddAppsModal: React.FC<AddAppsModalProps> = (props) => {
  const { isOpen, onReject, onResolve, onSubmit, isEdit } = props;
  const { t } = useTranslation();
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
  const [formType, setFormType] = useState<"apk" | "package">(
    isEdit && props.formData?.apk.package_name && !props.formData?.apk.image
      ? "package"
      : "apk"
  );
  const [packageName, setPackageName] = useState<string>(
    isEdit && props.formData?.apk.package_name && !props.formData?.apk.image
      ? props.formData.apk.package_name
      : ""
  );

  const onModalSubmit = useCallback(async () => {
    if (formType === "apk") {
      if (acceptedFiles.length > 0) {
        if (isEdit) {
          await onSubmit({
            file: acceptedFiles[0],
            uuid: props.formData.apk.uuid,
          });
        } else {
          await onSubmit({ files: acceptedFiles });
        }
      } else {
        //show error
      }
    } else {
      if (packageName) {
        await onSubmit({ packageName, uuid: props.formData?.apk.uuid });
      } else {
        //show error
      }
    }
    onResolve();
  }, [
    formType,
    onResolve,
    isEdit,
    onSubmit,
    acceptedFiles,
    props.formData?.apk.uuid,
    packageName,
  ]);
  const onChangeFile = (files: File[]) => {
    setAcceptedFiles(files);
    setPackageName("");
  };

  const disableSubmit =
    (formType === "apk" && acceptedFiles.length === 0) ||
    (formType === "package" && !packageName);

  const renderFile = () => {
    if (isEdit) {
      const { formData } = props;
      const app = formData.apk;
      return (
        <p className="d-flex align-center">
          <img
            className="d-block"
            data-toggle="tooltip"
            data-placement="left"
            title={app.filename}
            data-src={app.image}
            alt=""
            src={app.image}
          />
          <span>{formData.apk.filename}</span>
        </p>
      );
    }
    return null;
  };

  const renderAcceptedFile = (file: File) => {
    return <p>{file.name} </p>;
  };

  const toggleFormType = () => {
    setFormType((type) => {
      return type === "apk" ? "package" : "apk";
    });
  };

  const onChangePackage = (ev: ChangeEvent<HTMLInputElement>) => {
    setPackageName(ev.target.value);
    setAcceptedFiles([]);
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader className="modal-header">{t("apps.addApps")}</ModalHeader>
      <ModalBody>
        <div>
          {!isEdit && (
            <div className="d-flex flex-row-reverse">
              <Link
                to="#"
                color="info"
                className="transport-add align-self-end"
                onClick={toggleFormType}
              >
                {formType === "apk"
                  ? t("apps.addPackagename")
                  : t("apps.uploadApk")}
              </Link>
            </div>
          )}
          {formType === "apk" && (
            <Row className="app-row-padding flex-column">
              <Col>
                <Label>
                  <h6>{t("apps.apks")}</h6>
                </Label>
                <Label className="required-star-style">*</Label>
                {isEdit && acceptedFiles.length === 0
                  ? renderFile()
                  : acceptedFiles.map(renderAcceptedFile)}
                <StDropZone
                  accept={".apk"}
                  multiple
                  onChangeFile={onChangeFile}
                />
              </Col>
            </Row>
          )}
          {formType === "package" && (
            <Row className="app-row-padding flex-column">
              <Col>
                <Label>
                  <h6>{t("apps.packageName")}</h6>
                </Label>
                <Label className="required-star-style">*</Label>
                <Input
                  type="text"
                  onChange={onChangePackage}
                  value={packageName}
                />
              </Col>
            </Row>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onReject}>
          <option value="">{t("common.cancel")}</option>
        </Button>
        <Button color="info" onClick={onModalSubmit} disabled={disableSubmit}>
          <option value="">{t("common.submit")}</option>
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export const addAppsModal = create(AddAppsModal);
