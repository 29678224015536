import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import {
    taskInterventionStepAddFailure,
    taskInterventionStepAddSuccess,
    taskInterventionStepFailure,
    taskInterventionStepRemoveFailure,
    taskInterventionStepRemoveSuccess,
    taskInterventionStepSuccess,
    taskInterventionStepUpdateFailure,
    taskInterventionStepUpdateSuccess,
} from '../redux/actions/task.intervention.step.action';
import * as types from '../redux/actions/types';
import handleResponse from '../services/response.service';
import intervention from '../services/task.intervention.step.service';

const taskInterventionStepCall = (params: any) => intervention.taskInterventionStep(RESTAURANT_REQUEST, params);
const addTaskInterventionStepCall = (params: any) => intervention.addTaskInterventionStep(RESTAURANT_REQUEST, params);
const removeTaskInterventionStepCall = (params: any) => intervention.removeTaskInterventionStep(RESTAURANT_REQUEST, params);
const updateTaskInterventionStepCall = (params: any) => intervention.updateTaskInterventionStep(RESTAURANT_REQUEST, params);

// Task Intervention Get

function* handleTaskInterventionStepRequest(params: object) {
    try {
        let result;
        result = yield call(taskInterventionStepCall, params);
        yield handleTaskInterventionStep200(result);
    } catch (error) {
        throw error;
    }
}
const handleTaskInterventionStep200 = function* handleTaskInterventionStep200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskInterventionStepSuccess(result.data));
    } else {
        yield put(taskInterventionStepFailure(result.data));
        handleResponse(result);
    }
}

function* handleTaskInterventionStepAdd(params: object) {
    try {
        let result;
        result = yield call(addTaskInterventionStepCall, params);
        yield handleTaskInterventionStepAdd200(result);
    } catch (error) {
        throw error;
    }
}

const handleTaskInterventionStepAdd200 = function* handleTaskInterventionStepAdd200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskInterventionStepAddSuccess(result.data));
    } else {
        yield put(taskInterventionStepAddFailure(result.data));
    }
    handleResponse(result);
}

function* handleTaskInterventionStepRemove(params: object) {
    try {
        let result;
        result = yield call(removeTaskInterventionStepCall, params);
        yield handleTaskInterventionStepRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handleTaskInterventionStepRemove200 = function* handleTaskInterventionStepRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskInterventionStepRemoveSuccess(result.data));
    } else {
        yield put(taskInterventionStepRemoveFailure(result.data));
    }
    handleResponse(result);
}

function* handleTaskInterventionStepUpdate(params: object) {
    try {
        let result;
        result = yield call(updateTaskInterventionStepCall, params);
        yield handleTaskInterventionStepUpdate200(result);
    } catch (error) {
        throw error;
    }
}

const handleTaskInterventionStepUpdate200 = function* handleTaskInterventionStepUpdate200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskInterventionStepUpdateSuccess(result.data));
    } else {
        yield put(taskInterventionStepUpdateFailure(result.data));
    }
    handleResponse(result);
}

export default function* taskInterventionStepSaga() {
    yield takeLatest(types.TASK_INTERVENTION_STEP.REQUEST, handleTaskInterventionStepRequest);
    yield takeLatest(types.TASK_INTERVENTION_STEP_ADD.REQUEST, handleTaskInterventionStepAdd);
    yield takeLatest(types.TASK_INTERVENTION_STEP_REMOVE.REQUEST, handleTaskInterventionStepRemove);
    yield takeLatest(types.TASK_INTERVENTION_STEP_UPDATE.REQUEST, handleTaskInterventionStepUpdate);
}
