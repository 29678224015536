export interface PrinterModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface PrinterState {
  printer: any;
  isFetching: boolean;
  editmodal: boolean;
  addmodal: boolean;
  open: boolean;
  uuid: string;
  isUpdated: boolean;
  restaurantuuid: string;
  deletemodal: boolean;
  name: string;
  printerip: string;
  printerport: string;
  poslisteningport: string;
  printerprotocoluuid: string;
  protocol: any;
}

export const PrinterInitState = {
  isFetching: false,
  printer: [],
  restaurantuuid: "",
  isUpdated: false,
  editmodal: false,
  addmodal: false,
  open: false,
  uuid: "",
  deletemodal: false,
  name: "",
  printerip: "",
  printerport: "",
  poslisteningport: "",
  printerprotocoluuid: "",
  protocol: [],
};
export interface PrinterListCardState {
  activeTab: string;
  isOpen: boolean;
  selectedTemplates: any;
}

export const PrinterListCardInitState: PrinterListCardState = {
  activeTab: "details",
  isOpen: false,
  selectedTemplates: {},
};
