export interface VatModel {
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
  readonly isFetching: boolean;
}
export interface VatState {
  addModal: boolean;
  editmodal: boolean;
  errorName:string,
  errorPercentage: string;
  isDisabled:boolean;
  isEdit:boolean;
  isFetching: boolean;
  isUpdated: boolean;
  name: any;
  open: boolean;
  percentage:any;
  restaurantuuid: string;
  showEditModal: boolean;
  showModal: boolean;
  toggleDelete: Boolean;
  uuid: string;
  vat:any;
}

export const VatInitState = {
  addModal: false,
  editmodal: false,
  errorName:"",
  errorPercentage: "",
  isDisabled:true,
  isEdit:false,
  isFetching: false,
  isUpdated: false,
  name: "",
  open: false,
  percentage:"",
  restaurantuuid: "",
  showEditModal: false,
  showModal: false,
  toggleDelete: false,
  uuid: "",
  vat:[],
};
