function validateEmail(value: any): boolean {
  // eslint-disable-next-line
  const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!!value) {
    if (!pattern.test(value)) {
      return false
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function confirmPassword(new_password: string, confirm_password: string): boolean {
  if (!!new_password && !!confirm_password) {
    if (new_password !== confirm_password) {
      return false
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export default {
  validateEmail,
  confirmPassword
};
