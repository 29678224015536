import { PROFILEUUID } from '../constant/constant';
import apiCall from './api.call.config';

async function getProfileSettings(url: string) {
    try {
        return await apiCall.get(url + localStorage.getItem(PROFILEUUID) + '/profile_settings');
    } catch (e) {
        throw e;
    }
}

async function updateProfileSettings(url: string, params: any) {
    try {
        return await apiCall.put(url + localStorage.getItem(PROFILEUUID) + '/profile_settings', params.credentials);
    } catch (e) {
        throw e;
    }
}

export default {
    updateProfileSettings,
    getProfileSettings
}
