import "../../../styles/storage.scss";

import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useTranslation } from "react-i18next";

export default function StoragePlaceEditCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <Modal isOpen={props.state.editstorageplacemodal}>
      <ModalHeader
        toggle={props.editStoragePlaceToggle}
        className="modal-header"
      >
        {t("storage.editStoragePlace")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstname">{t("storage.storagePlaceName")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name="editstorageplacename"
              autoComplete="editstorageplacename"
              placeholder={t("storage.enterStoragePlaceName")}
              value={props.state.editstorageplacename}
              onChange={props.handleChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.editStoragePlaceToggle}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={props.saveStoragePlaceEdit}
          disabled={
            !props.state.editstorageplacename || !!props.state.isFetching
          }
        >
          {t("common.save")}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
