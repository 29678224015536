import React from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";
import { INVENTORY_TYPE } from "../../../constant/constant";

interface props {
  min_quantity: any;
  max_quantity: any;
  inventory_type?: number;
  output_quantity: any;
}

export default function StorageQuantityLabels(props: props) {
  const { inventory_type, max_quantity, min_quantity, output_quantity } = props;

  const { t } = useTranslation();
  const inventoryStyle = {
    fontSize: "11px",
    fontWeight: 400,
  };
  const getInventoryType = (value: number) => {
    let elem: any = "";
    if (value === INVENTORY_TYPE.MANAGED) {
      elem = t("common.managed");
    } else if (value === INVENTORY_TYPE.NO_STOCK) {
      elem = t("common.noStock");
    } else if (value === INVENTORY_TYPE.INFINITY) {
      elem = t("common.infinite");
    }
    return (
      <span
        style={inventoryStyle}
        className="border border-info mx-2 p-1 rounded"
      >
        {elem}
      </span>
    );
  };

  return (
    <>
      {!!output_quantity && (
        <span className="mx-2">
          ({output_quantity?.quantity} {output_quantity?.unit?.symbol})
        </span>
      )}
      {(!!min_quantity || !!max_quantity) && (
        <span className="mx-2 badge badge-light">
          (
          {!!min_quantity && (
            <span>
              {t("common.min")}: {min_quantity?.quantity}
            </span>
          )}
          {!!min_quantity && !!max_quantity && <span className="mx-2">-</span>}
          {!!max_quantity && (
            <span>
              {t("common.max")}: {max_quantity?.quantity}
            </span>
          )}
          )
        </span>
      )}
      {!!inventory_type && getInventoryType(inventory_type)}
    </>
  );
}
