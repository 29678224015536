import apiCall from './api.call.config';

async function vat(url: string, params: any) {
    try {
        return await apiCall.get(url + '/' + params.credentials.restaurantuuid + '/vat-class');
    } catch (e) {
        throw e;
    }
}

async function updateVat(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.put(url + '/' + params.credentials.restaurantuuid + '/vat-class/' + params.credentials.uuid, params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function addVat(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url + '/' + params.credentials.restaurantuuid + '/vat-class', params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function removeVat(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.remove(url + '/' + params.credentials.restaurantuuid + '/vat-class/' + params.credentials.uuid);
    } catch (e) {
        throw e;
    }
}

export default {
    vat,
    updateVat,
    addVat,
    removeVat,
}
