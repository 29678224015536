import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function storageRackRequest(credentials: object): ActionModel {
    return {
      type: types.STORAGE_RACK.REQUEST,
      credentials
    };
  }
  
  export function storageRackSuccess(data: object): ActionModel {
    return {
      type: types.STORAGE_RACK.SUCCESS,
      data
    };
  }
  
  export function storageRackFailure(err: object): ActionModel {
    return {
      type: types.STORAGE_RACK.FAILURE,
      err
    };
  }

  export function storageRackAdd(credentials: object): ActionModel {
    return {
      type: types.STORAGE_RACK_ADD.REQUEST,
      credentials
    };
  }
  
  export function storageRackAddSuccess(data?: object): ActionModel {
    return {
      type: types.STORAGE_RACK_ADD.SUCCESS,
      data
    };
  }
  
  export function storageRackAddFailure(err: object): ActionModel {
    return {
      type: types.STORAGE_RACK_ADD.FAILURE,
      err
    };
  }

  export function storageRackUpdate(credentials: object): ActionModel {
    return {
      type: types.STORAGE_RACK_UPDATE.REQUEST,
      credentials
    };
  }
  
  export function storageRackUpdateSuccess(data?: object): ActionModel {
    return {
      type: types.STORAGE_RACK_UPDATE.SUCCESS,
      data
    };
  }
  
  export function storageRackUpdateFailure(err: object): ActionModel {
    return {
      type: types.STORAGE_RACK_UPDATE.FAILURE,
      err
    };
  }
  export function storageRackRemove(credentials: object): ActionModel {
    return {
      type: types.STORAGE_RACK_REMOVE.REQUEST,
      credentials
    };
  }
  
  export function storageRackRemoveSuccess(data?: object): ActionModel {
    return {
      type: types.STORAGE_RACK_REMOVE.SUCCESS,
      data
    };
  }
  
  export function storageRackRemoveFailure(err: object): ActionModel {
    return {
      type: types.STORAGE_RACK_REMOVE.FAILURE,
      err
    };
  }