import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, Row, Input } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { guestGroupUpdate } from "../../redux/actions/guest.group.action";
import { settingsRequest } from "../../redux/actions/stations.action";
import { roomRequest } from "../../redux/actions/room.action";
import { GUEST_GROUP } from "../../constant/constant";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import _ from "lodash";
import GuestGroup from "./GuestGroup";
import TableBoxComponent from "./TableBox";
import LoaderComponent from "../loader/loader";
import rooms from "../../services/room.service";
import commonService from "../../services/common.service";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import OperateHeaderComponent from "../navigation/navigation-header/operate-header/operate-header";
import OperateLeftComponent from "../navigation/navigation-left/operate-navigation/operate-navigation";
import "./shop-access.scss";

const ShopAccessComponent: React.FC = (props: any) => {
  const [activeRoom, setActiveRoom] = useState<string>("");
  const [tableList, setTableList] = useState<Array<any>>([]);
  const [guestGroupList, setGuestGroupList] = useState<Array<any>>([]);
  const [modelCardOpen, setModelCardOpen] = useState<boolean>(false);
  const [guestGroupUuid, setGuestGroupUuid] = useState<string>();

  useEffect(() => {
    props.getRestaurantSetting({ restaurantuuid: props.match.params.uuid });
    props.getRooms({ restaurantuuid: props.match.params.uuid });
  }, []);

  useEffect(() => {
    if (activeRoom.length === 0) return;

    const requestParams = {
      restaurantuuid: props.match.params.uuid,
      uuid: activeRoom,
    };
    getRoomDetails(requestParams);
    getListOfGuestGroups(requestParams);
  }, [props.match.params.uuid, activeRoom]);

  const getRoomDetails = (params: Object) => {
    rooms
      .details(RESTAURANT_REQUEST, params)
      .then((response: any) => {
        if (response.status === 200) {
          setTableList(_.flatMap(response.data?.stations, "tables"));
        }
      })
      .catch((error: any) => {
        commonService.toastService("", "danger", JSON.stringify(error.error));
      });
  };

  const getListOfGuestGroups = (params: Object) => {
    rooms
      .guestGroups(RESTAURANT_REQUEST, params)
      .then((response: any) => {
        if (response.status === 200) {
          setGuestGroupList(response.data);
        }
      })
      .catch((error: any) => {
        commonService.toastService("", "danger", JSON.stringify(error.error));
      });
  };

  const performShopOrder = (guestGroup: any) => () => {
    const location =
      props.qrCodeBaseUrl[0] +
      "?ggkey=" +
      guestGroup.uuid +
      "&key=" +
      guestGroup.guest_group_qr_codes[0] +
      "&gg=" +
      btoa(guestGroup?.table?.name + " - " + guestGroup?.name) +
      "&_" +
      Date.now();
    window.open(location, "_blank", "noopener,noreferrer");
  };

  const setDelete = (guestGroup: any) => () => {
    setGuestGroupUuid(guestGroup.uuid);
    setModelCardOpen(true);
  };

  const cancelDelete = () => {
    setModelCardOpen(false);
  };

  const confirmDelete = (props: any) => () => {
    props.updateGuestGroup({
      restaurantuuid: props.match.params.uuid,
      guestgroupuuid: guestGroupUuid,
      data: { status: GUEST_GROUP.STATUS_TYPE_CLOSED },
    });
    setGuestGroupList(setGuestGroupStatusToClosed());
    setModelCardOpen(false);
  };

  const setGuestGroupStatusToClosed = () => {
    return _.map(guestGroupList, (guestGroup) => {
      if (guestGroup.uuid === guestGroupUuid) {
        return _.assign({}, guestGroup, {
          status: GUEST_GROUP.STATUS_TYPE_CLOSED,
        });
      }
      return guestGroup;
    });
  };

  const handleRoomSelection = (element: any) => {
    setActiveRoom(element.target.value);
  };

  return (
    <>
      <LoaderComponent display={!!props.isFetching} />
      <div className="container-fluid">
        <OperateHeaderComponent operate={"operate"} />
        <Row className="main light-theme">
          <Col xl={2} lg={3} className="hide-left-max">
            <OperateLeftComponent
              display={"shop-access"}
              restaurantuuid={props.match.params.uuid}
            />
          </Col>
          <Col xl={8} lg={6}>
            <Row className="white-box mb-3">
              <Col xl={12} lg={12}>
                <header className="d-flex justify-content-between px-3">
                  <h4>{props.t("common.performShopOrder")}</h4>
                </header>
              </Col>
              <Col xl={12} lg={12} className="mb-3">
                <div className="d-flex" key="room-select">
                  <Input
                    type="select"
                    name="room-select"
                    id="room-select"
                    className="b-min-width-150"
                    onChange={handleRoomSelection}
                  >
                    <option value="">{props.t("room.selectRoom")}</option>
                    {props.rooms?.map((option: any) => (
                      <option value={option.uuid}>
                        {
                          commonService.applyLocalization(
                            "restaurant",
                            "name",
                            option.locales
                          )["name"]
                        }
                      </option>
                    ))}
                  </Input>
                </div>
              </Col>
              {activeRoom && (
                <>
                  <Col xl={6} lg={6}>
                    <h6>{props.t("common.location")}</h6>
                  </Col>
                  <Col xl={6} lg={6}>
                    <h6>{props.t("guestGroup.guestGroups")}</h6>
                  </Col>
                </>
              )}
              {tableList?.map((table: any, index: number) => {
                return (
                  <>
                    <Col xl={6} lg={6}>
                      <div className="tm-table-selection-wrapper d-flex row flex-wrap">
                        <TableBoxComponent
                          key={index}
                          tableData={table}
                          handleTableSelection={() => {}}
                        />
                      </div>
                    </Col>
                    <Col xl={6} lg={6}>
                      <GuestGroup
                        key={index}
                        table={table}
                        guestGroupList={guestGroupList}
                        restaurantuuid={props.match.params.uuid}
                        setDelete={setDelete}
                        performShopOrder={performShopOrder}
                        setGuestGroupList={setGuestGroupList}
                        domain={props.qrCodeBaseUrl[0]}
                      ></GuestGroup>
                    </Col>
                  </>
                );
              })}
            </Row>
            <DeleteModalCard
              qnTitle={props.t("questions.closeGuestGroup")}
              isOpen={modelCardOpen}
              isFetching={!!props.isFetching}
              okDelete={confirmDelete(props)}
              cancelDelete={cancelDelete}
            />
          </Col>
          <Col xl={2} lg={3}>
            <NavigationRightComponent />
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  let isFetching = state.room.isFetching;
  let failure = state.room.failure;
  return {
    failure: failure,
    isFetching: isFetching,
    rooms: state.room.data,
    qrCodeBaseUrl: state.settings.data?.url,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getRestaurantSetting: (credentials: any) => {
      dispatch(settingsRequest(credentials));
    },
    getRooms: (credentials: any) => {
      dispatch(roomRequest(credentials));
    },
    updateGuestGroup: (credentials: any) => {
      dispatch(guestGroupUpdate(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ShopAccessComponent))
);
