import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function guestGroupRequest(credentials: object): ActionModel {
  return {
    type: types.GUEST_GROUPS.REQUEST,
    credentials,
  };
}

export function guestGroupSuccess(data: object): ActionModel {
  return {
    type: types.GUEST_GROUPS.SUCCESS,
    data,
  };
}

export function guestGroupFailure(err: object): ActionModel {
  return {
    type: types.GUEST_GROUPS.FAILURE,
    err,
  };
}

export function guestGroupUpdate(credentials: object): ActionModel {
  return {
    type: types.GUEST_GROUP_UPDATE.REQUEST,
    credentials,
  };
}

export function guestGroupUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.GUEST_GROUP_UPDATE.SUCCESS,
    data,
  };
}

export function guestGroupUpdateFailure(err: object): ActionModel {
  return {
    type: types.GUEST_GROUP_UPDATE.FAILURE,
    err,
  };
}
