import React, { Component } from 'react';

import MachineComponent from '../../components/machine/machine';

class MachinePage extends Component {

    render() {
        return (
            <MachineComponent/>
        );
    }
}
export default MachinePage;