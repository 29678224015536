import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import "../../../styles/units.scss";
import Select from "react-select";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function UnitsEditCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <Modal isOpen={props.state.editmodal}>
      <ModalHeader toggle={props.toggle} className="modal-header">
        {t("unit.editUnits")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="name">{t("unit.unitName")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name={props.type === "recipe" ? "unitName" : "name"}
              autoComplete="name"
              placeholder={t("unit.enterUnitName")}
              value={
                props.type === "recipe"
                  ? props.state.unitName
                  : props.state.name
              }
              onChange={props.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="symbol">{t("unit.unitSymbol")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required={true}
              name="symbol"
              autoComplete="symbol"
              placeholder={t("unit.enterUnitSymbol")}
              value={props.state.symbol}
              onChange={props.handleChange}
            />
          </FormGroup>
          {props?.hasGrossWeight && (
            <FormGroup>
              <Label for="grossWeight">{t("unit.grossWeight")}</Label>
              <div className="d-flex ">
                <div className="b-width-200 mr-2">
                  <Input
                    type="number"
                    name="grossWeight"
                    placeholder={t("unit.enterGrossWeight")}
                    value={props.state.grossWeight}
                    onChange={props.handleChange}
                  />
                </div>
                <div className="w-75">
                  <Select
                    name={"grossWeightUnitSelection"}
                    value={props.state.grossWeightUnitSelection}
                    onChange={(e) =>
                      props.handleChange({
                        target: {
                          name: "grossWeightUnitSelection",
                          value: e,
                        },
                      })
                    }
                    options={
                      !!props?.getBaseUnitOptions
                        ? props.getBaseUnitOptions
                        : []
                    }
                    maxMenuHeight={200}
                    styles={{
                      menu: (provided: any) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                </div>
              </div>
            </FormGroup>
          )}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={props.saveEdit}
          disabled={
            !(props.state.name || props.state.unitName) ||
            !props.state.symbol ||
            !!props.state.isFetching ||
            (props?.hasGrossWeight &&
              props.state.grossWeight?.trim() !== "" &&
              isNaN(parseInt(props.state.grossWeight?.trim() || "")))
          }
        >
          {t("common.save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
