import React, { useState, useEffect, useMemo } from "react";
import {
  Modal,
  Input,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { pick, reduce, propEq, assoc, map, when, find, filter } from "ramda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListUl,
  faCheck,
  faTrash,
  faPlusCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { GREEN } from "../../constant/constant";
import { generateRandomNumber } from "../../helpers/commonHelper";

type ManageKeyValueProps = {
  isOpen: boolean;
  onPressAdd: (keyValues: { key: string; value: string }[]) => void;
  onEdit: (keyValues: { key: string; value: string }[]) => void;
  onDelete: (keyValues: { key: string; value: string }[]) => Promise<void>;
  onCancel: () => void;
  keyValues: Option[];
};
export type Option = {
  resource_type: string;
  resource_id: string;
  key: string;
  value: string;
  uuid: string;
};

const createId = () => generateRandomNumber(10).toString();

export const ManageKeyvalue: React.FC<ManageKeyValueProps> = (props) => {
  const {
    isOpen,
    onPressAdd,
    onEdit,
    onDelete,
    onCancel,
    keyValues: incomingKeyvalues,
  } = props;

  const [keyValues, setKeyValues] = useState(incomingKeyvalues);
  const { t } = useTranslation();
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [addKeyValues, setAddKeyValues] = useState<
    { id: string; key: string; value: string }[]
  >([{ id: createId(), key: "", value: "" }]);
  const [editKeyValues, setEditKeyValues] = useState<
    { id: string; key: string; value: string }[]
  >([]);
  const [deleteKeyValues, setDeleteKeyValues] = useState<
    { id: string; key: string; value: string }[]
  >([]);
  const optionById = useMemo(
    () =>
      reduce<Option, Record<string, Option>>(
        (acc, el) => {
          acc[el.uuid] = el;
          return acc;
        },
        {},
        keyValues,
      ),
    [keyValues],
  );

  const onChangeKey = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.getAttribute("data-id");
    const key = e.target.value;
    setAddKeyValues((kvs) => {
      return map((kv) => {
        if (kv.id == id) {
          return { ...kv, key };
        }
        return kv;
      }, kvs);
    });
  };

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.getAttribute("data-id");
    const value = e.target.value;
    setAddKeyValues((kvs) => {
      return map((kv) => {
        if (kv.id == id) {
          return { ...kv, value };
        }
        return kv;
      }, kvs);
    });
  };

  const onAdd = () => {};

  const onClickSave = async () => {
    const kvs = filter((kv) => !!kv.key && !!kv.value, addKeyValues);
    const existingKvs = map(pick(["key", "value"]), keyValues);
    onPressAdd([...map(pick(["key", "value"]), kvs), ...existingKvs]);

    if (deleteKeyValues.length > 0) {
      await onDelete(map(pick(["key", "value"]), deleteKeyValues));
    }
  };

  const onUpdateValue = (el: React.ChangeEvent<HTMLInputElement>) => {
    const id = el.target.getAttribute("data-id");
    const value = el.target.value;
    setKeyValues(
      map((kv) => {
        if (kv.uuid === id) {
          return { ...kv, value };
        }
        return kv;
      }),
    );
  };

  const onClickDelete = (id: string) => () => {
    setDeleteKeyValues((dkvs) => {
      return [...dkvs, optionById[id]];
    });
  };

  const onPressAddIcon = () => {
    setAddKeyValues((kv) => {
      return [...kv, { id: createId(), key: "", value: "" }];
    });
    setKey("");
    setValue("");
  };

  const onPressClose = (id) => () => {
    setAddKeyValues(
      reduce((acc, kv) => {
        if (kv.id == id) return acc;
        return [...acc, kv];
      }, []),
    );
  };

  const renderInputRow = () => {
    return addKeyValues.map((keyValue, index) => {
      return (
        <div className="row">
          <div className="col-5">
            <Input
              id={keyValue.key || "key"}
              required
              data-id={keyValue.id}
              name={keyValue.key || "key"}
              value={keyValue.key}
              onChange={onChangeKey}
              placeholder={t("common.key")}
            />
          </div>
          <div className="col-5">
            <Input
              id={keyValue.value || "value"}
              required
              data-id={keyValue.id}
              name={keyValue.value || "value"}
              value={keyValue.value}
              onChange={onChangeValue}
              placeholder={t("common.value")}
            />
          </div>

          <div className="d-flex align-items-center justity-content-center">
            {index === addKeyValues.length - 1 ? (
              <FontAwesomeIcon
                style={{ fontSize: 20 }}
                icon={faPlusCircle}
                onClick={onPressAddIcon}
              />
            ) : (
              <FontAwesomeIcon
                style={{ fontSize: 20 }}
                icon={faTimes}
                data-id={keyValue.id}
                onClick={onPressClose(keyValue.id)}
              />
            )}
          </div>
        </div>
      );
    });
  };
  const isDeleted = (kv) =>
    !!find((dkv) => dkv.uuid === kv.uuid, deleteKeyValues);
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={props.onCancel} className="modal-header">
        {t("common.manageKeyValue")}
      </ModalHeader>
      <ModalBody>
        {keyValues.map((kv) =>
          isDeleted(kv) ? null : (
            <div className="row" key={kv.uuid}>
              <div className="col-6">
                <label>{kv.key}</label>
              </div>
              <div className="col-4">
                <Input
                  id={kv.uuid}
                  required
                  name={kv.uuid}
                  data-id={kv.uuid}
                  value={optionById[kv.uuid].value}
                  onChange={onUpdateValue}
                  placeholder={t("common.value")}
                />
              </div>

              <Button
                className="ml-3 p-0 border-radius-0 bg-transparent border-0 shadow-none"
                onClick={onClickDelete(kv.uuid)}
              >
                <FontAwesomeIcon icon={faTrash} size={"sm"} color={GREEN} />
              </Button>
            </div>
          ),
        )}
        {renderInputRow()}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onCancel}>
          {t("common.cancel")}
        </Button>
        <Button color="info" onClick={onClickSave} disabled={false}>
          {t("common.save")}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};
