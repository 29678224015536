import React, { Component } from 'react';

import UsersComponent from '../../components/users/users';

class UsersPage extends Component {

    render() {
        return (
            <UsersComponent/>
        );
    }
}
export default UsersPage;
