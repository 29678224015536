import React, { Component } from "react";
import { Collapse, Col, Input, Row, Label, FormGroup } from "reactstrap";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

import {
  CardItemCardComponent,
  CardItemContainer,
} from "../../card-components/card-item";
import { withCardItemSelectionState } from "../../cardItemSelectionManager";
import WarningComment, {
  getMandatoryWarning,
} from "../../warning-comment/warning-comment";
import InlineEditInput from "../../inline-edit-input/inline-edit-input";
import commonService from "../../../services/common.service";
import Select from "react-select";
import {
  DateItemListCardInitState,
  DateItemListCardState,
} from "../../../models/date.item.model";

class DateItemListCard extends Component<any, any> {
  state: DateItemListCardState;
  constructor(props: any) {
    super(props);
    this.state = DateItemListCardInitState;
  }

  componentDidMount() {
    this._loadValues();
    this.setState({
      cardItemData: this.props.cardItemData,
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      JSON.stringify(prevProps.selectedPeriodicity) !==
      JSON.stringify(this.props.selectedPeriodicity)
    ) {
      this.setState({
        selectedPeriodicity: this.props.selectedPeriodicity,
        periodicity_item_uuid: this.props.cardItemData.periodicity_item_uuid,
      });
    }
  }

  private _loadValues = () => {
    if (this.props.cardItemData?.type === "newCard") {
      this._setNewCardValue();
    } else {
      this._setInitialValues();
    }
  };

  private _setNewCardValue = () => {
    this.setState({
      component_price: false,
      output_quantity: "",
      output_unit: "",
      periodicity_item_uuid: "",
      selectedPeriodicity: this.props.selectedPeriodicity,
      selectedRecipe: "",
      warnings: {
        output_quantity: getMandatoryWarning(""),
        periodicity_item_uuid: getMandatoryWarning(""),
        selectedRecipe: getMandatoryWarning(""),
      },
    });
  };

  private _setInitialValues() {
    const selectedRecipe = {
      value: this.props.cardItemData.recipe_uuid,
      label: this.getLabels("recipe"),
    };

    const periodicity_item_uuid = this.props.cardItemData.periodicity_item_uuid;

    const output_quantity = this.props.cardItemData.output_quantity;

    this.setState({
      component_price: this.props.cardItemData?.component_price,
      output_quantity,
      output_unit: this.getUnitLabel(this.props.cardItemData.recipe_uuid),
      periodicity_item_uuid,
      selectedPeriodicity: this.props.selectedPeriodicity,
      selectedRecipe,
      warnings: {
        output_quantity: getMandatoryWarning(output_quantity),
        periodicity_item_uuid: getMandatoryWarning(periodicity_item_uuid),
        selectedRecipe: getMandatoryWarning(selectedRecipe),
      },
    });
  }

  render() {
    const { t } = this.props;
    return (
      <CardItemContainer className={"task-type px-0 mx-n4"}>
        <CardItemCardComponent
          cancelCallback={this.cancelEdit}
          cardItemSelectionData={this.props.cardItemSelectionData}
          className={"task-type assigned"}
          deleteCallback={this.deleteCard}
          onDeleteClick={this.deleteCard}
          readOnly={false}
          saveCallback={this.saveCard}
          selectCallback={this.selectCard}
        >
          <Row>
            {!this.props.cardItemSelectionData.selectionState
              .isItemSelected && (
              <>
                <Col md={6} xl={4}>
                  {this.getLabels("recipe")}
                </Col>
                <Col md={6} xl={3}>
                  {this.props.periodicityLabel}
                </Col>
                <Col md={6} xl={3}>
                  {this.props.cardItemData.output_quantity} (
                  {this.state.output_unit})
                </Col>
                <Col md={6} xl={2}>
                  {this.props.cardItemData.component_price &&
                    t("common.hasPrice")}
                </Col>
              </>
            )}
            {this.props.cardItemSelectionData.selectionState.isItemSelected && (
              <Col lg={8} className="pl-0">
                {this.props.cardItemSelectionData.selectionState
                  .isBeingEdited && (
                  <WarningComment
                    data={this.state.warnings.selectedRecipe}
                    displayType={"bottom"}
                  >
                    <Select
                      className="text-dark"
                      maxMenuHeight={200}
                      onChange={this.handleSelectChange}
                      options={this.props.recipeListOptions}
                      placeholder={t("recipe.selectRecipe")}
                      value={this.state.selectedRecipe}
                    />
                  </WarningComment>
                )}
                {!this.props.cardItemSelectionData.selectionState
                  .isBeingEdited && <span> {this.getLabels("recipe")}</span>}
              </Col>
            )}
          </Row>
          <Collapse
            isOpen={
              this.props.cardItemSelectionData.selectionState.isItemSelected
            }
          >
            <Row>
              <Col md={5} xl={3} lg={4}>
                {t("periodicity.periodicityItem")}
              </Col>
              <Col md={7} lg={6}>
                {!this.props.cardItemSelectionData.selectionState
                  .isBeingEdited && <span>{this.props.periodicityLabel}</span>}
                {this.props.cardItemSelectionData.selectionState
                  .isBeingEdited && (
                  <WarningComment
                    data={this.state.warnings.periodicity_item_uuid}
                    displayType={"bottom"}
                  >
                    <Input
                      onChange={this.handleChange}
                      type="select"
                      name="periodicity_item_uuid"
                      id="periodicity_item_uuid"
                      value={this.state.periodicity_item_uuid}
                    >
                      <option value="">{t("common.select")}</option>
                      {Object.keys(this.props.periodicityList).length !== 0 &&
                        this.props.periodicityList.map(
                          (periodicity: any) =>
                            (!this.state.selectedPeriodicity.includes(
                              periodicity.uuid
                            ) ||
                              (this.state.selectedPeriodicity.includes(
                                this.state.periodicity_item_uuid
                              ) &&
                                this.state.periodicity_item_uuid ===
                                  periodicity.uuid)) && (
                              <option
                                key={periodicity.uuid}
                                value={periodicity.uuid}
                              >
                                {
                                  commonService.applyLocalization(
                                    "restaurant",
                                    "name",
                                    periodicity.locales
                                  )["name"]
                                }
                              </option>
                            )
                        )}
                    </Input>
                  </WarningComment>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={5} xl={3} lg={4}>
                {t("common.quantity")}
              </Col>
              <Col md={7} lg={6}>
                {!this.props.cardItemSelectionData.selectionState
                  .isBeingEdited && (
                  <span>
                    {this.props.cardItemData.output_quantity} (
                    {this.state.output_unit})
                  </span>
                )}
                {this.props.cardItemSelectionData.selectionState
                  .isBeingEdited && (
                  <WarningComment
                    data={this.state.warnings.output_quantity}
                    displayType={"bottom"}
                  >
                    <div className="d-flex align-items-center">
                      <Input
                        type="number"
                        name="output_quantity"
                        id="output_quantity"
                        placeholder={t("common.quantity")}
                        onChange={this.handleChange}
                        value={this.state.output_quantity}
                      />

                      <span className="px-2"> ({this.state.output_unit})</span>
                    </div>
                  </WarningComment>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={5} xl={3} lg={4}>
                {t("common.addComponentPrice")}
              </Col>
              <Col md={7} lg={6}>
                <FormGroup check className="align-items-center pl-0 d-flex">
                  {!this.props.cardItemSelectionData.selectionState
                    .isBeingEdited &&
                    this.getPriceLabel(this.props.cardItemData.component_price)}
                  {this.props.cardItemSelectionData.selectionState
                    .isBeingEdited && (
                    <>
                      <input
                        id="component_price"
                        checked={this.state.component_price}
                        className="mt-2"
                        name="component_price"
                        onChange={this.handleComponentPriceChange}
                        type="checkbox"
                      />
                    </>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </Collapse>
        </CardItemCardComponent>
      </CardItemContainer>
    );
  }

  cancelEdit = () => {
    if (this.props.cardItemData?.type === "newCard") {
      this.props.cardItemSelectionData.deselectCardItem();
      this._setNewCardValue();
    } else {
      this.selectCard();
    }
  };

  deleteCard = () => {
    this.props.onDeleteCard(Number(this.props.cardItemId));
  };

  getLabels = (area: string) => {
    if (
      area === "recipe" &&
      this.props.cardItemData.type !== "newCard" &&
      Object.keys(this.props.recipeListOptions).length !== 0
    ) {
      const [selectedRecipe] = this.props.recipeListOptions.filter(
        (recipe: any) => {
          return recipe.value === this.props.cardItemData.recipe_uuid;
        }
      );
      return selectedRecipe?.label;
    }

    return "";
  };

  getPriceLabel = (component_price: boolean) => {
    const { t } = this.props;
    return component_price ? t("common.yes") : t("common.no");
  };

  getUnitLabel = (recipeId: string) => {
    return this.props.getOutputUnit(recipeId);
  };

  handleChange = (elem: any) => {
    let currentWarning: any;
    if (elem.target.name === "output_quantity") {
      const isValid = Number(elem.target.value) < 0 ? null : elem.target.value;
      currentWarning = {
        output_quantity: getMandatoryWarning(isValid),
      };
    } else {
      currentWarning = {
        [elem.target.name]: getMandatoryWarning(elem.target.value),
      };
    }
    const warnings = { ...this.state.warnings, ...currentWarning };
    if (elem.target.name === "periodicity_item_uuid") {
      const prevValue: string = this.state.periodicity_item_uuid;
      const nextVal: string = elem.target.value;
      const selectedPeriodicity: Array<string> = [
        ...this.state.selectedPeriodicity,
      ];
      var index = selectedPeriodicity.indexOf(prevValue);
      selectedPeriodicity.splice(index, 1);
      selectedPeriodicity.push(nextVal);
      this.setState({ selectedPeriodicity });
    }
    this.setState({
      [elem.target.name]: elem.target.value,
      warnings: warnings,
    });
  };

  handleComponentPriceChange = (elem: any) => {
    this.setState({
      component_price: !this.state.component_price,
    });
  };

  handleSelectChange = (value: any, elem: any) => {
    const currentWarning: any = {
      selectedRecipe: getMandatoryWarning(value.value),
    };
    const warnings = { ...this.state.warnings, ...currentWarning };
    this.setState({
      selectedRecipe: value,
      output_unit: this.getUnitLabel(value.value),
      warnings,
    });
  };

  saveCard = () => {
    if (
      this.state.warnings.output_quantity === null &&
      this.state.warnings.periodicity_item_uuid === null &&
      this.state.warnings.selectedRecipe === null
    ) {
      const formData: object = {
        component_price: this.state.component_price,
        recipe_uuid: this.state.selectedRecipe.value,
        output_quantity: Number(this.state.output_quantity),
        periodicity_item_uuid: this.state.periodicity_item_uuid,
      };
      const periodicityValues: any = {
        old: this.props.cardItemData.periodicity_item_uuid,
        new: this.state.periodicity_item_uuid,
      };
      this.props.onSaveCard(formData, this.props.cardItemId, periodicityValues);
      if (this.props.cardItemData?.type === "newCard") {
        this._setNewCardValue();
      }
    }
  };
  selectCard = () => {
    if (this.props.cardItemSelectionData.selectionState.mode === "edit") {
      this._loadValues();
    }
    this.props.cardItemSelectionData.selectCardItem();
  };
}

export default withTranslation()(
  withRouter(
    withCardItemSelectionState("DateItemListCard", 1)(DateItemListCard)
  )
);
