import React, { Component } from 'react';

import InventoryComponent from '../../components/inventory/inventory';

class InventoryPage extends Component {

    render() {
        return (
            <InventoryComponent/>
        );
    }
}
export default InventoryPage;
