import React, { Component } from 'react';

import ServerComponent from '../../components/servers/servers';

class ServerPage extends Component {

    render() {
        return (
            <ServerComponent />
        );
    }
}
export default ServerPage;
