import '../../../styles/profile.scss';

import React from 'react';
import { Button, Card, CardBody, Form, FormGroup, FormText, Input, Label } from 'reactstrap';

export default function ProfileSettingsCard(props: any) {
    return (
        <Card>
            <CardBody >
                <Form>
                    <FormGroup >
                        <Label for="current_password">Current Password</Label>
                        <Label className="required-star-style">*</Label>
                        <Input type="password" value={props.state.current_password} required name="current_password"
                            autoComplete="current_password" placeholder="Enter Current Password" onChange={props.changePasswordHandle} />
                        <FormText>We'll never share your password with anyone else.</FormText>
                    </FormGroup>
                    <FormGroup >
                        <Label for="new_password">New Password</Label>
                        <Label className="required-star-style">*</Label>
                        <Input type="password" value={props.state.new_password} required name="new_password" autoComplete="new_password"
                            placeholder="Enter New Password" onChange={props.handleConfirmPassword} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="confirm_password">Confirm Password</Label>
                        <Label className="required-star-style">*</Label>
                        <Input type="password" value={props.state.confirm_password} required name="confirm_password"
                            autoComplete="confirm_password" placeholder="Confirm Password" onChange={props.handleConfirmPassword} />
                        {props.passwordMisMatch}
                    </FormGroup>
                    <Button color="secondary" id="toggler" className="toggler">Cancel</Button>
                    <Button color="default" onClick={() => props.changePassword({
                        current_password: props.state.current_password,
                        new_password: props.state.new_password,
                        confirm_password: props.state.confirm_password
                    })} disabled={!props.validationService.confirmPassword(props.state.new_password, props.state.confirm_password)
                        || !props.state.current_password || !props.state.confirm_password}>Submit</Button>
                </Form>
            </CardBody>
        </Card>
    )
}
