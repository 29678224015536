import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

export default function ProtocolEditCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <Modal isOpen={props.state.editmodal}>
      <ModalHeader toggle={props.editPrinterToggle} className="modal-header">
        {t("protocol.editPrinterProtocol")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstname">{t("protocol.protocolName")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name="name"
              autoComplete="name"
              placeholder={t("protocol.enterPrinterProtocolName")}
              value={props.state.name}
              onChange={props.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastname">{t("protocol.protocolFormat")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="textarea"
              style={{ minHeight: 200 }}
              required
              name="format"
              autoComplete="format"
              placeholder={t("protocol.enterPrinterProtocolFormat")}
              value={props.state.format}
              onChange={props.handleChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.editPrinterToggle}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={props.savePrinterEdit}
          disabled={
            !props.state.name || !props.state.format || !!props.state.isFetching
          }
        >
          {t("common.save")}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
