import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function componentRequest(): ActionModel {
  return {
    type: types.COMPONENT.REQUEST,
  };
}

export function componentSuccess(data: object): ActionModel {
  return {
    type: types.COMPONENT.SUCCESS,
    data
  };
}

export function componentFailure(err: object): ActionModel {
  return {
    type: types.COMPONENT.FAILURE,
    err
  };
}

