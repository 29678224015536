import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function taskInterventionStepRequest(credentials: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_STEP.REQUEST,
    credentials
  };
}

export function taskInterventionStepSuccess(data: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_STEP.SUCCESS,
    data
  };
}

export function taskInterventionStepFailure(err: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_STEP.FAILURE,
    err
  };
}

export function taskInterventionStepAdd(credentials: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_STEP_ADD.REQUEST,
    credentials
  };
}

export function taskInterventionStepAddSuccess(data?: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_STEP_ADD.SUCCESS,
    data
  };
}

export function taskInterventionStepAddFailure(err: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_STEP_ADD.FAILURE,
    err
  };
}

export function taskInterventionStepUpdate(credentials: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_STEP_UPDATE.REQUEST,
    credentials
  };
}

export function taskInterventionStepUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_STEP_UPDATE.SUCCESS,
    data
  };
}

export function taskInterventionStepUpdateFailure(err: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_STEP_UPDATE.FAILURE,
    err
  };
}

export function taskInterventionStepRemove(credentials: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_STEP_REMOVE.REQUEST,
    credentials
  };
}

export function taskInterventionStepRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_STEP_REMOVE.SUCCESS,
    data
  };
}

export function taskInterventionStepRemoveFailure(err: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_STEP_REMOVE.FAILURE,
    err
  };
}
