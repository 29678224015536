import { LanguageModel } from "../../models/language.model";
import * as types from '../actions/types';

const initialState: LanguageModel = {
  isFetching: false,
  data: [],
  error: {},
  failure: false,
};
export function languageReducer(
  state = initialState,
  action: any
): LanguageModel {
  switch (action.type) {
    case types.LANGUAGE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.LANGUAGE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.LANGUAGE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function languageUpdateReducer(
  state = initialState,
  action: any
): LanguageModel {
  switch (action.type) {
    case types.LANGUAGE_UPDATE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.LANGUAGE_UPDATE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        failure: false,
        error: {},
      };
    case types.LANGUAGE_UPDATE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
