import { call, put, takeLatest } from "redux-saga/effects";

import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  servicesetAddFailure,
  servicesetAddSuccess,
  servicesetFailure,
  servicesetRemoveFailure,
  servicesetRemoveSuccess,
  servicesetSuccess,
  serviceSetTimeAddFailure,
  serviceSetTimeAddSuccess,
  serviceSetUpdateChannelFailure,
  serviceSetUpdateChannelSuccess,
  servicesetUpdateFailure,
  servicesetUpdateSuccess,
} from "../redux/actions/serviceset.action";
import * as types from "../redux/actions/types";
import handleResponse from "../services/response.service";
import serviceSet from "../services/service.set";

const servicesetCall = (params: any) =>
  serviceSet.serviceSet(RESTAURANT_REQUEST, params);
const updateServicesetCall = (params: any) =>
  serviceSet.updateServiceSet(RESTAURANT_REQUEST, params);
const addServicesetCall = (params: any) =>
  serviceSet.addServiceSet(RESTAURANT_REQUEST, params);
const addServiceSetTimeCall = (params: any) =>
  serviceSet.addServiceSetTime(RESTAURANT_REQUEST, params);
const removeServicesetCall = (params: any) =>
  serviceSet.removeServiceSet(RESTAURANT_REQUEST, params);
const updateServiceSetChannelCall = (params: any) =>
  serviceSet.updateServiceSetChannel(RESTAURANT_REQUEST, params);

function* handleServiceSetRequest(params: object) {
  try {
    let result;
    result = yield call(servicesetCall, params);
    yield handleServiceSet200(result);
  } catch (error) {
    throw error;
  }
}

const handleServiceSet200 = function* handleServiceSet200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(servicesetSuccess(result.data));
  } else {
    yield put(servicesetFailure(result.data));
    handleResponse(result);
  }
};

function* handleServiceSetUpdate(params: object) {
  try {
    let result;
    result = yield call(updateServicesetCall, params);
    yield handleServiceSetUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleServiceSetUpdate200 = function* handleServiceSetUpdate200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(servicesetUpdateSuccess(result.data));
  } else {
    yield put(servicesetUpdateFailure(result.data));
  }
  handleResponse(result);
};

function* handleServiceSetAdd(params: object) {
  try {
    let result;
    result = yield call(addServicesetCall, params);
    yield handleServiceSetAdd200(result);
  } catch (error) {
    throw error;
  }
}

const handleServiceSetAdd200 = function* handleServiceSetAdd200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(servicesetAddSuccess(result.data));
  } else {
    yield put(servicesetAddFailure(result.data));
  }
  handleResponse(result);
};

function* handleServiceSetRemove(params: object) {
  try {
    let result;
    result = yield call(removeServicesetCall, params);
    yield handleServiceSetRemove200(result);
  } catch (error) {
    throw error;
  }
}

const handleServiceSetRemove200 = function* handleServiceSetRemove200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(servicesetRemoveSuccess(result.data));
  } else {
    yield put(servicesetRemoveFailure(result.data));
  }
  handleResponse(result);
};

function* handleServiceSetTimeAdd(params: object) {
  try {
    let result;
    result = yield call(addServiceSetTimeCall, params);
    yield handleServiceSetTimeAdd200(result);
  } catch (error) {
    throw error;
  }
}

const handleServiceSetTimeAdd200 = function* handleServiceSetTimeAdd200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(serviceSetTimeAddSuccess(result.data));
  } else {
    yield put(serviceSetTimeAddFailure(result.data));
  }
  handleResponse(result);
};

function* handleServiceSetChannelUpdate(params: object) {
  try {
    let result;
    result = yield call(updateServiceSetChannelCall, params);
    yield handleServiceSetChannelUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleServiceSetChannelUpdate200 =
  function* handleServiceSetChannelUpdate200(result: any) {
    if (!result) {
      return;
    }
    if (result.status === 200) {
      yield put(serviceSetUpdateChannelSuccess(result.data));
    } else {
      yield put(serviceSetUpdateChannelFailure(result.data));
    }
    handleResponse(result);
  };

export default function* serviceSetSaga() {
  yield takeLatest(types.SERVICESET.REQUEST, handleServiceSetRequest);
  yield takeLatest(types.SERVICESET_UPDATE.REQUEST, handleServiceSetUpdate);
  yield takeLatest(types.SERVICESET_ADD.REQUEST, handleServiceSetAdd);
  yield takeLatest(types.SERVICESET_TIME_ADD.REQUEST, handleServiceSetTimeAdd);
  yield takeLatest(types.SERVICESET_REMOVE.REQUEST, handleServiceSetRemove);
  yield takeLatest(
    types.SERVICE_SET_CHANNEL_UPDATE.REQUEST,
    handleServiceSetChannelUpdate
  );
}
