import { TaskStepModel } from '../../models/task.step.model';
import * as types from '../actions/types';

const initialState: TaskStepModel = {
    isFetching: false,
    data: {},
    error: {},
    failure: false,
  };

  export default function taskStepRemoveReducer(state = initialState, action: any): TaskStepModel {
    switch (action.type) {
      case types.TASK_STEP_REMOVE.REQUEST:
        return {
          ...state,
          isFetching: true,
          failure: false,
          error: {}
        };
      case types.TASK_STEP_REMOVE.SUCCESS:
        return {
          ...state,
          isFetching: false,
          data: action.data,
          failure: false,
          error: {}
        };
      case types.TASK_STEP_REMOVE.FAILURE:
        return {
          ...state,
          isFetching: false,
          failure: true,
          error: action.err
        };
      default:
        return state;
    }
  }