import i18next from "i18next";
import _ from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { StationInitState, StationsState } from "../../models/stations.model";
import { recipePrinterRequest } from "../../redux/actions/recipe.printer.action";
import { roomRequest } from "../../redux/actions/room.action";
import {
  stationsAdd,
  stationsOperationModeUpdate,
  stationsRemove,
  stationsRequest,
  stationsUpdate,
} from "../../redux/actions/stations.action";
import commonService from "../../services/common.service";
import "../../styles/infrastructure.scss";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import StationAddCard from "../card-components/station-add-card/station-add-card";
import StationCard from "../card-components/station-card/station-card";
import StationEditCard from "../card-components/station-edit-card/station-edit-card";
import LoaderComponent from "../loader/loader";
import StaffHeaderComponent from "../navigation/navigation-header/staff-header/staff-header";
import UsersLeftComponent from "../navigation/navigation-left/users-navigation/users-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";

class StationComponent extends Component<any> {
  state: StationsState;
  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.state = StationInitState;
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <StaffHeaderComponent staff={"resources"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <UsersLeftComponent
                display={"station"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <Link
                to="#"
                color="info"
                className="add-station"
                onClick={this.addStationToggle}
              >
                {t("station.addStation")}
              </Link>
              <div className="white-box mb-3">
                <h4>{t("station.stations")}</h4>
                {this.stationList(this.state.stations)}
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>
        <StationEditCard
          color={this.state.color}
          editstationmodal={this.state.editstationmodal}
          editStationToggle={this.editStationToggle}
          handleChange={this.handleChange}
          handleClick={this.handleClick}
          handlePrinterChange={this.handlePrinterChange.bind(this)}
          handleStationChange={this.handleStationChange.bind(this)}
          isFetching={this.state.isFetching}
          otherStationList={this.getStationOptions(this.state.uuid)}
          pickingLocation={this.state.pickingLocation}
          PRINTERLIST={this.printerList()}
          printerUuid={this.state.printerUuid}
          ROMMLIST={this.optionList()}
          roomuuid={this.state.roomuuid}
          saveStationEdit={this.saveStationEdit}
          stationname={this.state.stationname}
        />

        <StationAddCard
          addstationmodal={this.state.addstationmodal}
          addStationToggle={this.addStationToggle}
          color={this.state.color}
          handleChange={this.handleChange}
          handleClick={this.handleClick}
          handlePrinterChange={this.handlePrinterChange.bind(this)}
          handleStationChange={this.handleStationChange.bind(this)}
          isFetching={this.state.isFetching}
          otherStationList={this.getStationOptions()}
          pickingLocation={this.state.pickingLocation}
          PRINTERLIST={this.printerList()}
          ROMMLIST={this.optionList()}
          roomuuid={this.state.roomuuid}
          saveStationAdd={this.saveStationAdd}
          stationname={this.state.stationname}
        />

        <DeleteModalCard
          isOpen={this.state.openstation}
          isFetching={!!this.state.isFetching}
          okDelete={this.okDeleteStations}
          cancelDelete={this.deleteStationToggle}
        />
      </div>
    );
  }

  getStationOptions = (activeStation: string = "") => {
    const stations: Array<any> = this.state.stationOptionList.filter(
      (opt: any) => opt.value !== activeStation
    );
    stations.unshift({
      label: this.props.t("common.select"),
      value: null,
    });
    return stations;
  };

  getPickingLocation = (location: any) => {
    return this.state.stationOptionList.find(
      (station: any) => station.value === location
    );
  };

  handleModeChange = (data: any) => {
    const formData = [
      {
        station_uuid: data.target.name,
        operation_mode: parseInt(data.target.value),
      },
    ];
    this.props.updateOperationMode({
      restaurantuuid: this.props.match.params.uuid,
      data: {
        stations: formData,
      },
    });
    this.setState({
      isUpdated: true,
    });
  };

  public handleStationChange(e: any): void {
    this.state.roomList.forEach((room: any) => {
      if (room.uuid === e.target.value) {
        this.setState({
          roomuuid: room.uuid,
        });
      }
      if (e.target.value === "") {
        this.setState({
          roomuuid: "",
        });
      }
    });
  }
  public handlePrinterChange(e: any): void {
    this.state.printerList.forEach((printer: any) => {
      if (printer.uuid === e.target.value) {
        this.setState({
          printerUuid: printer.uuid,
        });
      }
      if (e.target.value === "") {
        this.setState({
          printerUuid: "",
        });
      }
    });
  }
  public optionList() {
    let dataList = this.state.roomList.length ? this.state.roomList : [];
    let options;
    if (!!dataList) {
      options = dataList.map((option: any, i: number) => {
        let roomLocale = commonService.applyLocalization(
          "restaurant",
          "name",
          option.locales
        );
        return (
          <option key={i} value={option.uuid}>
            {roomLocale.name}
          </option>
        );
      });
      return options;
    }
  }

  public printerList() {
    let dataList = this.state.printerList.length ? this.state.printerList : [];
    let options;
    if (!!dataList) {
      options = dataList.map((option: any, i: number) => {
        return (
          <option key={i} value={option.uuid}>
            {option.name}
          </option>
        );
      });
      return options;
    }
  }

  public getPrinterName(data: any) {
    let dataList = this.state.printerList.length ? this.state.printerList : [];
    let options = "";
    if (!!dataList) {
      // eslint-disable-next-line
      dataList.map((option: any, i: number) => {
        if (data.printer_uuid === option.uuid) {
          options = option.name;
        }
      });
      return options;
    }
  }
  public roomName(room: any) {
    let dataList = this.state.roomList.length ? this.state.roomList : [];
    let options;
    if (!!dataList) {
      // eslint-disable-next-line
      options = dataList.map((option: any, i: number) => {
        let roomLocale = commonService.applyLocalization(
          "restaurant",
          "name",
          option.locales
        );
        if (room.room_uuid === option.uuid) {
          return roomLocale.name;
        }
      });
      return options;
    }
  }

  setStationOptions = (roomList: Array<any>) => {
    const options: Array<any> = [];
    roomList.forEach((room: any) => {
      options.push({
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          room.locales
        )["name"],
        value: room.uuid,
      });
    });
    this._setStates("stationOptionList", options);
  };

  public stationList(data: any): any {
    let stations;
    if (!!data && data.length > 0) {
      // eslint-disable-next-line
      stations = _.orderBy(
        data,
        [
          (item) =>
            commonService
              .applyLocalization("restaurant", "name", item.locales)
              ?.name?.toLowerCase(),
        ],
        ["asc"]
      )?.map((station: any, i: number) => {
        let stationLocale = commonService.applyLocalization(
          "restaurant",
          "name",
          station.locales
        );
        return (
          <StationCard
            key={i}
            setStationEditable={this.setStationEditable.bind(this, station)}
            setStationDelete={this.setStationDelete.bind(this, station)}
            locales={stationLocale}
            color={station.color}
            printerName={this.getPrinterName(station)}
            roomName={this.roomName(station)}
            stationData={station}
            handleModeChange={this.handleModeChange}
            pickingLocation={this.getPickingLocation(station?.picking_location)}
          />
        );
      });
      return stations;
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.props.t("common.noRecords")}
        </div>
      );
    }
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this._setStates("restaurantuuid", this.props.match.params.uuid);
    this.props.getStations({ uuid: this.props.match.params.uuid });
    this.props.getRoom({ restaurantuuid: this.props.match.params.uuid });
    this.props.getPrinterList({ restaurantuuid: this.props.match.params.uuid });
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
    }
    if (!!newProps.printers && !newProps.isFetching && !newProps.failure) {
      this._setStates("printerList", newProps.printers);
    }
    if (!!newProps.stations && newProps.room && !newProps.isFetching) {
      this.setState({
        stations: newProps.stations,
        roomList: newProps.room,
      });
      this.setStationOptions(newProps.stations);
    }

    if (!!this.state.isUpdated && !newProps.isFetching) {
      this.props.getStations({ uuid: this.props.match.params.uuid });
      this._setStates("isUpdated", false);
    }

    if (
      !!newProps.stationAddStatus.status &&
      !!this.state.addstationmodal &&
      !newProps.isFetching
    ) {
      this.addStationToggle();
    }
    if (
      !!newProps.stationEditStatus.status &&
      !!this.state.editstationmodal &&
      !newProps.isFetching
    ) {
      this.editStationToggle();
    }
  }

  private _setFunctionBindings(): void {
    this.deleteStationToggle = this.deleteStationToggle.bind(this);
    this.editStationToggle = this.editStationToggle.bind(this);
    this.addStationToggle = this.addStationToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.saveStationEdit = this.saveStationEdit.bind(this);
    this.okDeleteStations = this.okDeleteStations.bind(this);
    this.saveStationAdd = this.saveStationAdd.bind(this);
    this.optionList = this.optionList.bind(this);
  }

  public okDeleteStations(): void {
    this.props.removeStations({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
    });
    this._setStates("isUpdated", true);
    this.deleteStationToggle();
  }

  public setStationDelete(event: any): void {
    this._setStates("uuid", event.uuid);
    this.deleteStationToggle();
  }

  public deleteStationToggle(): void {
    this.setState((prevState: any) => ({
      openstation: !prevState.openstation,
    }));
  }
  public addStationToggle(): void {
    this.setState((prevState: any) => ({
      addstationmodal: !prevState.addstationmodal,
      stationname: "",
      roomuuid: "",
      printerUuid: "",
      color: "#FFFFFF",
      displayColorPicker: false,
      pickingLocation: null,
    }));
  }

  public saveStationAdd(): void {
    this.props.addStations({
      restaurantuuid: this.state.restaurantuuid,
      data: {
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.stationname,
          },
        },
        room_uuid: this.state.roomuuid,
        printer_uuid: this.state.printerUuid ? this.state.printerUuid : "",
        color: this.state.color,
        picking_location: this.state.pickingLocation?.value || "",
      },
    });
    this._setStates("isUpdated", true);
  }

  public editStationToggle(): void {
    this.setState((prevState: any) => ({
      editstationmodal: !prevState.editstationmodal,
    }));
  }

  public setStationEditable(event: any): void {
    let stationLocale = commonService.applyLocalization(
      "restaurant",
      "name",
      event.locales
    );
    const pickingLocation: any =
      this.state.stationOptionList.find(
        (p: any) => p.value === event?.picking_location
      ) || null;

    this.setState({
      uuid: event.uuid,
      roomuuid: event.room_uuid,
      printerUuid: event.printer_uuid,
      stationname: stationLocale.name,
      color: event.color,
      displayColorPicker: false,
      pickingLocation: pickingLocation,
    });
    this.editStationToggle();
  }

  public saveStationEdit(): void {
    this.props.updateStations({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
      data: {
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.stationname,
          },
        },
        room_uuid: this.state.roomuuid,
        printer_uuid: this.state.printerUuid ? this.state.printerUuid : "",
        color: this.state.color,
        picking_location: this.state.pickingLocation?.value || "",
      },
    });
    this._setStates("isUpdated", true);
  }

  public handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  public handleChange(e: any): void {
    this._setStates(e.target.name, e.target.value);
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.recipeprinter.isFetching ||
    state.stations.isFetching ||
    state.stationsupdate.isFetching ||
    state.stationsadd.isFetching ||
    state.stationsremove.isFetching ||
    state.room.isFetching;
  let failure =
    state.recipeprinter.failure ||
    state.stations.failure ||
    state.stationsupdate.failure ||
    state.stationsadd.failure ||
    state.stationsremove.failure ||
    state.room.failure;
  return {
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    room: state.room.data,
    stations: state.stations.data,
    isFetching: isFetching,
    failure: failure,
    stationEditStatus: state.stationsupdate.data,
    stationAddStatus: state.stationsadd.data,
    printers: state.recipeprinter.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getStations: (credentials: any) => {
      dispatch(stationsRequest(credentials));
    },
    updateStations: (credentials: any) => {
      dispatch(stationsUpdate(credentials));
    },
    updateOperationMode: (credentials: any) => {
      dispatch(stationsOperationModeUpdate(credentials));
    },
    addStations: (credentials: any) => {
      dispatch(stationsAdd(credentials));
    },
    removeStations: (credentials: any) => {
      dispatch(stationsRemove(credentials));
    },
    getRoom: (credentials: any) => {
      dispatch(roomRequest(credentials));
    },
    getPrinterList: (credentials: any) => {
      dispatch(recipePrinterRequest(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(StationComponent))
);
