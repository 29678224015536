import { call, put, takeLatest } from 'redux-saga/effects';

import { USER_REQUEST } from '../config/api.config';
import {
    profileSettingsFailure,
    profileSettingsSuccess,
    profileSettingsUpdateFailure,
    profileSettingsUpdateSuccess,
} from '../redux/actions/profile.settings.action';
import * as types from '../redux/actions/types';
import profileSettings from '../services/profile.settings.service';
import handleResponse from '../services/response.service';

const updateProfileSettingsCall = (params: any) => profileSettings.updateProfileSettings(USER_REQUEST, params);
const getProfileSettingsCall = () => profileSettings.getProfileSettings(USER_REQUEST);

function* handleProfileSettings() {
    try {
        let result;
        result = yield call(getProfileSettingsCall);
        yield handleProfileSettings200(result);
    } catch (error) {
        throw error;
    }
}

const handleProfileSettings200 = function* handleProfileSettings200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(profileSettingsSuccess(result.data));
    } else {
        yield put(profileSettingsFailure(result.data));
        handleResponse(result);
    }
}

function* handleProfileSettingsUpdate(params: object) {
    try {
        let result;
        result = yield call(updateProfileSettingsCall, params);
        yield handleProfileSettingsUpdate200(result);
    } catch (error) {
        throw error;
    }
}

const handleProfileSettingsUpdate200 = function* handleProfileSettingsUpdate200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(profileSettingsUpdateSuccess(result.data));
    } else {
        yield put(profileSettingsUpdateFailure(result.data));
        handleResponse(result);
    }
}

export default function* profileSettingsSaga() {
    yield takeLatest(types.PROFILE_SETTINGS.REQUEST, handleProfileSettings);
    yield takeLatest(types.PROFILE_SETTINGS_UPDATE.REQUEST, handleProfileSettingsUpdate);
}
