import React from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import Select from "react-select";
import "nouislider/distribute/nouislider.css";
import "../../../styles/tag-assign.scss";
import { useTranslation } from "react-i18next";
import commonService from "../../../services/common.service";

export default function SubstituteAssignCard(props: any) {
  const { t, i18n } = useTranslation();
  const isInputValid = () => {
    if (!!props.recipeSubstitute.value && !!props.recipeQuantity) {
      return false;
    } else return true;
  };
  return (
    <Modal isOpen={props.showSubstituteModal}>
      <ModalHeader className="modal-header">
        {t("substituteGroup.assignSubstituteGroup")}
      </ModalHeader>
      <ModalBody>
        <Form className="tag-assign">
          {!props.recipeSubstituteUuid && (
            <FormGroup row className="p-0">
              <Label className="text-right" for="substitute" sm={5}>
                {t("ingredient.ingredientClass")}
                <span className="required-star-style">*</span>
              </Label>
              <Col sm={7}>
                <Select
                  maxMenuHeight={200}
                  name="substitute"
                  onChange={(val: any) => {
                    props.handleSubstituteChange(val, "substitute");
                  }}
                  options={
                    props?.substitutes && props.substitutes?.length > 0
                      ? props.substitutes
                          .filter((item: any) =>
                            props?.recipeSubstitutes &&
                            Object.keys(props.recipeSubstitutes).length > 0
                              ? props.recipeSubstitutes.findIndex(
                                  (element: any) =>
                                    element.uuid === item.uuid &&
                                    item.uuid !== props.recipeSubstituteUuid
                                ) === -1
                              : true
                          )
                          ?.map((item: any) => {
                            return {
                              label: commonService.applyLocalization(
                                "restaurant",
                                "name",
                                item.locales
                              )?.name,
                              value: item.uuid,
                            };
                          })
                      : {}
                  }
                  value={props.recipeSubstitute}
                />
              </Col>
            </FormGroup>
          )}
          <FormGroup row className="p-0">
            <Label className={"text-right"} for="quantity" sm={5}>
              {t("common.quantity")}
              <span className="required-star-style">*</span>
            </Label>
            <Col sm={7} className="d-flex pt-2">
              <Input
                name="quantity"
                onChange={(e: any) => {
                  props.handleSubstituteChange(e.target.value, "quantity");
                }}
                type="number"
                value={props.recipeQuantity}
              />
            </Col>
          </FormGroup>
        </Form>
        {!props.recipeSubstituteUuid && (
          <Button
            outline
            color="info"
            size="sm"
            className="d-flex ml-auto"
            onClick={props.handleItemAddModalOpen}
          >
            {t("substituteGroup.addSubstituteGroup")}
          </Button>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            props.handleSubstituteModal(props.itemId);
          }}
        >
          {t("common.cancel")}
        </Button>
        <Button
          disabled={isInputValid()}
          color="info"
          onClick={() => {
            props.saveSubstitutes();
          }}
        >
          {t("common.save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
