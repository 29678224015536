import "../../../styles/restaurant.scss";

import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Country, State, City } from "country-state-city";
import i18next from "i18next";
import Select from "react-select";
import { useTranslation } from "react-i18next";

export default function RestaurantEditCard(props: any) {
  const { t, i18n } = useTranslation();
  const [countryList, setCountries] = useState<any>([]);
  const [language, setLanguage] = useState("");
  useEffect(() => {
    getCountryOptions();
  }, []);

  useEffect(() => {
    getLanguageOptions();
  }, [props.state.editmodal]);

  const getCountryOptions = () => {
    let countries = Country.getAllCountries().map((country: any) => {
      return {
        value: country.name,
        label: country.name,
      };
    });
    setCountries(countries);
  };

  const getLanguageOptions = () => {
    let languages = props.languages?.filter(
      (language: any) => language.uuid === props.state.language
    );

    if (!!languages?.length) setLanguage(languages[0].name);
  };
  return (
    <Modal isOpen={props.state.editmodal}>
      <ModalHeader toggle={props.toggle} className="modal-header">
        {t("restaurant.editRestaurant")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstname">
              {t("common.name")}
              <span className="text-danger ml-1">*</span>
            </Label>
            <Input
              type="text"
              required
              name="name"
              autoComplete="name"
              placeholder={t("restaurant.enterRestaurantName")}
              value={props.state.name}
              onChange={props.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastname">{t("common.address")}</Label>
            <Input
              type="textarea"
              style={{ minHeight: 200 }}
              required={true}
              name="address"
              autoComplete="address"
              placeholder={t("restaurant.enterRestaurantAddress")}
              value={props.state.address}
              onChange={props.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="country">{t("common.country")}</Label>
            <Select
              name="country"
              value={props.state.country}
              onChange={props.handleCountryChange}
              maxMenuHeight={200}
              options={countryList}
              placeholder={t("restaurant.selectCountry")}
            />
          </FormGroup>

          <FormGroup>
            <Label for="city">{t("common.city")}</Label>
            <Input
              type="text"
              required
              name="city"
              autoComplete="editcity"
              placeholder={t("restaurant.enterRestaurantCity")}
              value={props.state.city}
              onChange={props.handleChange}
            />
          </FormGroup>

          <FormGroup>
            <Label for="zip">{t("common.zip")}</Label>
            <Input
              type="text"
              required
              name="zip"
              autoComplete="editzip"
              maxLength={20}
              placeholder={t("restaurant.enterRestaurantZip")}
              value={props.state.zip}
              onChange={props.handleChange}
            />
          </FormGroup>

          <FormGroup>
            <Label for="zip">{t("common.phone")}</Label>
            <Input
              type="text"
              required
              name="phone"
              autoComplete="editphone"
              maxLength={15}
              placeholder={t("restaurant.enterRestaurantPhone")}
              value={props.state.phone}
              onChange={props.handleChange}
            />
            <span className="bf-font-size-11 pl-1 position-absolute  text-danger">
              {props.state.errors?.phone}
            </span>
          </FormGroup>
          <FormGroup>
            <Label for="zip">{t("common.vatCode")}</Label>
            <Input
              type="text"
              required
              name="vatCode"
              autoComplete="editvatcode"
              placeholder={t("restaurant.enterRestaurantVatCode")}
              value={props.state.vatCode}
              onChange={props.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="city">{t("common.description")}</Label>
            <Input
              type="textarea"
              style={{ minHeight: 200 }}
              required={true}
              name="description"
              autoComplete="description"
              placeholder={t("restaurant.enterRestaurantDescription")}
              value={props.state.description}
              onChange={props.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">
              {t("common.email")}
              <span className="text-danger ml-1">*</span>
            </Label>
            <Input
              type="text"
              required
              name="email"
              autoComplete="email"
              placeholder={t("restaurant.enterRestaurantEmail")}
              value={props.state.email}
              onChange={props.handleChange}
            />
            <span className="bf-font-size-11 pl-1 position-absolute text-danger">
              {props.state.errors?.email}
            </span>
          </FormGroup>
          <FormGroup>
            <Label for="language">{t("common.language")}</Label>
          </FormGroup>
          <div>{language}</div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={props.saveEdit}
          disabled={
            !props.state.name || !props.state.email || !!props.state.isFetching
          }
        >
          {t("common.save")}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
