import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import React from "react";
import "../../../styles/card-item.scss";
import { useTranslation } from "react-i18next";

function NewCardItemContent(props: { newTaskWithIng?: boolean }) {
  const { t } = useTranslation();
  return (
    <div style={{ fontWeight: 500, fontSize: 20, textAlign: "center" }}>
      {props.newTaskWithIng ? (
        <span>
          <FontAwesomeIcon style={{ fontSize: 20 }} icon={faPlus} />
          <span className="ml-2">
            {t("recipe.addTaskWithremainingIngredient")}
          </span>
        </span>
      ) : (
        <FontAwesomeIcon style={{ fontSize: 20 }} icon={faPlus} />
      )}
    </div>
  );
}

export { NewCardItemContent };
