import { all } from "redux-saga/effects";

import changePasswordSaga from "./change.password.saga";
import channelsSaga from "./channels.saga";
import chatSaga from "./chat.saga";
import choiceSaga from "./choice.item.saga";
import componentSaga from "./component.saga";
import emailconfirmationSaga from "./email.confirmation.saga";
import forgotpasswordSaga from "./forgotpassword.saga";
import ingredientsSaga from "./ingredients.saga";
import inventorySaga from "./inventory.saga";
import labelsSaga from "./labels.saga";
import loginSaga from "./login.saga";
import languageSaga from "./language.saga";
import machineSaga from "./machine.saga";
import eventListSaga from "./pos.saga";
import printerMessageSaga from "./printer.message.saga";
import printerProtocolSaga from "./printer.protocol.saga";
import printerSaga from "./printer.saga";
import recipePrinterSaga from "./recipe.printer.saga";

import profileSaga from "./profile.saga";
import profileSettingsSaga from "./profile.settings.saga";
import recipeSaga from "./recipe.saga";
import registrationSaga from "./registration.saga";
import resetpasswordSaga from "./resetpassword.saga";
import restaurantSaga from "./restaurant.saga";
import restaurantDetailSaga from "./restaurant.detail.saga";
import roleRightSaga from "./roleright.saga";
import userRolesSaga from "./roles.saga";
import roomSaga from "./room.saga";
import serversSaga from "./servers.saga";
import serviceSetSaga from "./serviceset.saga";
import stationsSaga from "./stations.saga";
import storagePlaceSaga from "./storage.place.saga";
import storageRackSaga from "./storage.rack.saga";
import storageSaga from "./storage.saga";
import taskInterventionIngredientSaga from "./task.intervention.ingredient.saga";
import taskInterventionSaga from "./task.intervention.saga";
import taskInterventionStepSaga from "./task.intervention.step.saga";
import taskSaga from "./task.saga";
import taskStepSaga from "./task.step.saga";
import transportSaga from "./transport.saga";
import unitsSaga from "./units.saga";
import uploadSaga from "./upload.saga";
import userInvitationSaga from "./user.invitation.saga";
import userVerificationSaga from "./user.verification.saga";
import usersSaga from "./users.saga";
import userLanguageSaga from "./user.language.saga";
import vatSaga from "./vat.saga";

import supportSaga from "./support.saga";
import tablesSaga from "./table.saga";
import tagsSaga from "./tags.saga";
import translationSaga from "./translation.saga";
import qrCodeSaga from "./qrcode.saga";
import currencySaga from "./currency.saga";
import periodicitySaga from "./periodicity.saga";
import paymentProviderSaga from "./payment.provider.saga";
import printerTemplateSaga from "./printer.template.saga";
import dateItemSaga from "./dateItem.saga";
import courseListSaga from "./course.list.saga";
import serviceSetDetailsSaga from "./serviceset.details.saga";
import contentManagementSaga from "./content.management.saga";
import reportSaga from "./report.saga";
import recipeSubstituteSaga from "./recipe.substitute.saga";
import guestGroupSaga from "./guest.group.saga";
const rootSaga = function* root() {
  yield all([
    chatSaga(),
    channelsSaga(),
    choiceSaga(),
    dateItemSaga(),
    loginSaga(),
    languageSaga(),
    currencySaga(),
    registrationSaga(),
    restaurantDetailSaga(),
    emailconfirmationSaga(),
    profileSaga(),
    ingredientsSaga(),
    profileSettingsSaga(),
    restaurantSaga(),
    changePasswordSaga(),
    stationsSaga(),
    machineSaga(),
    unitsSaga(),
    serversSaga(),
    uploadSaga(),
    printerSaga(),
    printerMessageSaga(),
    printerProtocolSaga(),
    usersSaga(),
    userInvitationSaga(),
    userVerificationSaga(),
    forgotpasswordSaga(),
    resetpasswordSaga(),
    recipeSaga(),
    taskSaga(),
    inventorySaga(),
    storageSaga(),
    taskStepSaga(),
    storageRackSaga(),
    storagePlaceSaga(),
    userRolesSaga(),
    componentSaga(),
    roleRightSaga(),
    taskInterventionSaga(),
    taskInterventionStepSaga(),
    taskInterventionIngredientSaga(),
    eventListSaga(),
    roomSaga(),
    labelsSaga(),
    transportSaga(),
    serviceSetSaga(),
    supportSaga(),
    recipePrinterSaga(),
    tablesSaga(),
    tagsSaga(),
    translationSaga(),
    userLanguageSaga(),
    qrCodeSaga(),
    vatSaga(),
    periodicitySaga(),
    paymentProviderSaga(),
    printerTemplateSaga(),
    courseListSaga(),
    serviceSetDetailsSaga(),
    contentManagementSaga(),
    reportSaga(),
    recipeSubstituteSaga(),
    guestGroupSaga(),
  ]);
};
export default rootSaga;
