import React, { Component } from "react";

import ProfileComponent from "../../components/profile/profile";
import { init, setFreshChatLanguage } from "../../app/freshChat";
import { connect } from "react-redux";
import { chatInitRequest } from "../../redux/actions/chat.action";

class ProfilePage extends Component<any> {
  componentDidMount() {
    this.props.chatInitRequest();
    setFreshChatLanguage();
  }

  render() {
    return <ProfileComponent />;
  }
}

const mapStateToProps = (state: any) => {
  return { chatInit: state.chatInit.data };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    chatInitRequest: () => {
      dispatch(chatInitRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
