import '../../styles/forgotpassword.scss';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Button, Col, Container, Form, Input, InputGroup, Row } from 'reactstrap';

import smartToniLogin from '../../assets/images/logo/smartToniLogin.png';
import { ForgotPasswordInitState, ForgotPasswordState } from '../../models/forgotpassword.model';
import { resetpasswordRequest } from '../../redux/actions/resetpassword.action';
import LoaderComponent from '../loader/loader';

class ResetPasswordComponent extends Component<any> {
    state: ForgotPasswordState;
    constructor(props: any) {
        super(props);
        this.state = ForgotPasswordInitState;
        this._setFunctionBindings();
    }
    render() {

        return (
            <div>
                <LoaderComponent display={!!this.state.isFetching} />
                <Container>
                    <Row>
                        <Col><img className="img-home-fluid" src={smartToniLogin} alt="Logo" /></Col>
                    </Row>
                    <Row>
                        <Col className="forgotpassword-text"><label>Welcome! Please Enter New Password.</label></Col>
                    </Row>
                    <Row className="forgotpassword-form-container">
                        <Col>
                            <Form>
                                <InputGroup className="mb-3">
                                    <Input required type="password" placeholder="Enter New Password" autoComplete="password" name="password" value={this.state.password} onChange={this.handleChange} />
                                </InputGroup>
                                <Button className="forgotpasswordButton" onClick={this.resetpasswordSubmit} block disabled={!this.state.password || !this.props.match.params.token}>Reset password</Button>
                                <Link to="/">
                                    <Button className="link-text" color="link"><FontAwesomeIcon icon={faArrowLeft} />Back to Login</Button>
                                </Link>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    UNSAFE_componentWillReceiveProps(newProps: any) {
        this._setStates('isFetching', newProps.isfetching);
    }

    private _setFunctionBindings(): void {
        this.handleChange = this.handleChange.bind(this);
        this.resetpasswordSubmit = this.resetpasswordSubmit.bind(this);
    }

    public handleChange(event: any): void {
        this._setStates('password', event.target.value);
    }

    public resetpasswordSubmit(): void {
        this.props.reset({
            new_password: this.state.password,
            verification_token: this.props.match.params.token
        })
    }

    private _setStates(name: string, value: any): void {
        this.setState({ [name]: value });
    }
}

const mapStateToProps: any = (state: any) => {
    return {
        data: state.resetpassword.data,
        isfetching: state.resetpassword.isFetching,
        failure: state.resetpassword.failure
    };
};

const mapDispatchToProps: object = (dispatch: any) => {
    return {
        reset: (credentials: any) => {
            dispatch(resetpasswordRequest(credentials));
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps)(withRouter(ResetPasswordComponent));
