import { ActionModel } from '../../models/action.model';
import * as types from './types';


export function rolesRequest(credentials: object): ActionModel {
    return {
        type: types.ROLES.REQUEST,
    credentials
    };
}

export function rolesSuccess(data: object): ActionModel {
    return {
      type: types.ROLES.SUCCESS,
      data
    };
  }
  
  export function rolesFailure(err: object): ActionModel {
    return {
      type: types.ROLES.FAILURE,
      err
    };
  }

  export function rolesUpdate(credentials: object): ActionModel {
    return {
      type: types.ROLES_UPDATE.REQUEST,
      credentials
    };
  }
  
  export function rolesUpdateSuccess(data?: object): ActionModel {
    return {
      type: types.ROLES_UPDATE.SUCCESS,
      data
    };
  }
  
  export function rolesUpdateFailure(err: object): ActionModel {
    return {
      type: types.ROLES_UPDATE.FAILURE,
      err
    };
  }
  
  export function rolesAdd(credentials: object): ActionModel {
    return {
      type: types.ROLES_ADD.REQUEST,
      credentials
    };
  }
  
  export function rolesAddSuccess(data?: object): ActionModel {
    return {
      type: types.ROLES_ADD.SUCCESS,
      data
    };
  }
  
  export function rolesAddFailure(err: object): ActionModel {
    return {
      type: types.ROLES_ADD.FAILURE,
      err
    };
  }
  
  export function rolesRemove(credentials: object): ActionModel {
    return {
      type: types.ROLES_REMOVE.REQUEST,
      credentials
    };
  }
  
  export function rolesRemoveSuccess(data?: object): ActionModel {
    return {
      type: types.ROLES_REMOVE.SUCCESS,
      data
    };
  }
  
  export function rolesRemoveFailure(err: object): ActionModel {
    return {
      type: types.ROLES_REMOVE.FAILURE,
      err
    };
  }