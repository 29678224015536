import "../../styles/storage.scss";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { StorageInitState, StorageState } from "../../models/storage.model";
import { roomRequest } from "../../redux/actions/room.action";
import {
  storageAdd,
  storageRemove,
  storageRequest,
  storageUpdate,
} from "../../redux/actions/storage.action";
import {
  storagePlaceAdd,
  storagePlaceRemove,
  storagePlaceUpdate,
} from "../../redux/actions/storage.place.action";
import {
  storageRackAdd,
  storageRackRemove,
  storageRackUpdate,
} from "../../redux/actions/storage.rack.action";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import StorageAddCard from "../card-components/storage-add-card/storage-add-card";
import StorageCard from "../card-components/storage-card/storage-card";
import StorageEditCard from "../card-components/storage-edit-card/storage-edit-card";
import LoaderComponent from "../loader/loader";
import StaffHeaderComponent from "../navigation/navigation-header/staff-header/staff-header";
import UsersLeftComponent from "../navigation/navigation-left/users-navigation/users-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import { withTranslation } from "react-i18next";
import commonService from "../../services/common.service";
import { stationsRequest } from "../../redux/actions/stations.action";
import _ from "lodash";
import { printerTemplateRequest } from "../../redux/actions/printer.template.action";

class StorageComponent extends Component<any> {
  state: StorageState;
  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.state = StorageInitState;
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.getStorage({
      restaurantuuid: this.props.match.params.uuid,
    });
    this.props.getRooms({ restaurantuuid: this.props.match.params.uuid });
    this.props.getStations({ uuid: this.props.match.params.uuid });
    this.props.getPrinterTemplates({
      restaurantuuid: this.props.match.params.uuid,
    });
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
      if (!!newProps && !newProps.isFetching) {
        this._setStates("storages", newProps.storages);
      }
    }
    if (newProps.room && !newProps.isFetching) {
      this.setState({
        roomList: newProps.room,
      });
    }
    if (!!this.state.isUpdated && !newProps.isFetching) {
      this.props.getStorage({
        restaurantuuid: this.props.match.params.uuid,
      });
      this._setStates("isUpdated", false);
    }
    if (
      !!newProps.storageadd.status &&
      !!this.state.addstoragemodal &&
      !newProps.isFetching
    ) {
      this.addStorageToggle();
    } else if (
      !!newProps.storageupdate.status &&
      !!this.state.editstoragemodal &&
      !newProps.isFetching
    ) {
      this.editStorageToggle();
    }
  }

  private _setFunctionBindings(): void {
    this.addStorageToggle = this.addStorageToggle.bind(this);
    this._setStates = this._setStates.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.saveStorageAdd = this.saveStorageAdd.bind(this);
    this.editStorageToggle = this.editStorageToggle.bind(this);
    this.saveStorageEdit = this.saveStorageEdit.bind(this);
    this.deleteStorageToggle = this.deleteStorageToggle.bind(this);
    this.okDeleteStorage = this.okDeleteStorage.bind(this);
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <StaffHeaderComponent staff={"resources"} />

          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <UsersLeftComponent
                display={"storage"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              <Link
                to="#"
                color="info"
                className="add-station"
                onClick={this.addStorageToggle}
              >
                {t("storage.addStorage")}
              </Link>
              {this.storageList(this.state.storages)}
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>

        {/*  Add Storage Modal */}

        <StorageAddCard
          addStorageToggle={this.addStorageToggle}
          getStationList={this.getStationList}
          handleChange={this.handleChange}
          handleRoomChange={this.handleRoomChange.bind(this)}
          roomList={this.optionList(
            this.state.roomList.length ? this.state.roomList : []
          )}
          saveStorageAdd={this.saveStorageAdd}
          state={this.state}
        />

        {/*  Edit Storage Modal */}

        <StorageEditCard
          editStorageToggle={this.editStorageToggle}
          getStationList={this.getStationList}
          handleChange={this.handleChange}
          handleRoomChange={this.handleRoomChange.bind(this)}
          roomList={this.optionList(
            this.state.roomList.length ? this.state.roomList : []
          )}
          saveStorageEdit={this.saveStorageEdit}
          state={this.state}
        />

        {/* Delete Storage Modal*/}

        <DeleteModalCard
          isOpen={this.state.deletestoragemodal}
          isFetching={!!this.state.isFetching}
          okDelete={this.okDeleteStorage}
          cancelDelete={this.deleteStorageToggle}
        />
      </div>
    );
  }
  public handleRoomChange(e: any): void {
    this.state.roomList.forEach((room: any) => {
      if (room.uuid === e.target.value) {
        this.setState({
          roomuuid: room.uuid,
        });
      }
      if (e.target.value === "") {
        this.setState({
          roomuuid: "",
        });
      }
    });
  }
  public optionList(list: Array<any>) {
    let options;
    if (!!list) {
      options = list.map((option: any, i: number) => {
        let roomLocale = commonService.applyLocalization(
          "restaurant",
          "name",
          option.locales
        );
        return (
          <option key={i} value={option.uuid}>
            {roomLocale.name}
          </option>
        );
      });
      return options;
    }
  }

  public roomName(list: Array<any>, uuid: any) {
    let options;
    if (!!list) {
      // eslint-disable-next-line
      options = list.map((option: any, i: number) => {
        let locale = commonService.applyLocalization(
          "restaurant",
          "name",
          option.locales
        );
        if (uuid === option.uuid) {
          return locale.name;
        }
      });
      return options;
    }
  }

  public storageList(storageData: any): any {
    let storage;
    if (!!storageData && storageData.length > 0) {
      storage = storageData.map((storage: any, index: number) => {
        return (
          <div className="white-box mb-3 shadow" key={index}>
            <StorageCard
              storage={storage}
              setStorageEditable={this.setStorageEditable.bind(this, storage)}
              setStorageDelete={this.setStorageDelete.bind(this, storage)}
              index={`${"storage-" + index}`}
              getStorage={this.props.getStorage}
              addStoragePlace={this.props.addStoragePlace}
              updateStoragePlace={this.props.updateStoragePlace}
              removeStoragePlace={this.props.removeStoragePlace}
              updateStorageRack={this.props.updateStorageRack}
              removeStorageRack={this.props.removeStorageRack}
              addStorageRack={this.props.addStorageRack}
              roomName={this.roomName(
                this.state.roomList.length ? this.state.roomList : [],
                storage.room_uuid
              )}
              stationName={this.roomName(
                this.props.stations.length ? this.props.stations : [],
                storage.station
              )}
              printerTemplates={this.props.printerTemplate}
            />
          </div>
        );
      });
      return storage;
    } else {
      return (
        <div className="white-box mb-3">
          <div style={{ width: "100%", textAlign: "center" }}>
            {this.props.t("common.noRecords")}
          </div>
        </div>
      );
    }
  }

  getStationList = (roomId: string = "") => {
    let stationOptions: any;
    const roomStations = Array.isArray(this.props.stations)
      ? roomId === ""
        ? this.props.stations
        : this.props.stations?.filter(
            (station: any) => station.room_uuid === roomId
          )
      : [];
    if (roomStations.length > 0) stationOptions = this.optionList(roomStations);

    return stationOptions;
  };

  public handleChange(e: any): void {
    this._setStates(e.target.name, e.target.value);
  }

  public addStorageToggle(): void {
    this.setState((prevState: any) => ({
      addstoragemodal: !prevState.addstoragemodal,
      storagename: "",
      roomuuid: "",
    }));
  }

  public editStorageToggle(): void {
    this.setState((prevState: any) => ({
      editstoragemodal: !prevState.editstoragemodal,
    }));
  }

  public deleteStorageToggle(): void {
    this.setState((prevState: any) => ({
      deletestoragemodal: !prevState.deletestoragemodal,
    }));
  }

  public setStorageEditable(storagedata: any): void {
    this.setState({
      uuid: storagedata.uuid,
      storagename: storagedata.name,
      roomuuid: storagedata.room_uuid,
      station: storagedata.station,
    });
    this.editStorageToggle();
  }

  public saveStorageEdit(): void {
    this.props.updateStorage({
      uuid: this.state.uuid,
      restaurantuuid: this.props.match.params.uuid,
      data: {
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.storagename,
          },
        },
        room_uuid: this.state.roomuuid,
        station: this.state.station,
      },
    });
    this._setStates("isUpdated", true);
  }

  public setStorageDelete(storagedata: any): void {
    this._setStates("uuid", storagedata.uuid);
    this.deleteStorageToggle();
  }

  public okDeleteStorage(): void {
    this.props.removeStorage({
      uuid: this.state.uuid,
      restaurantuuid: this.props.match.params.uuid,
    });
    this._setStates("isUpdated", true);
    this.deleteStorageToggle();
  }

  public saveStorageAdd(): void {
    this.props.addStorage({
      restaurantuuid: this.props.match.params.uuid,
      data: {
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.storagename,
          },
        },
        room_uuid: this.state.roomuuid,
        station: this.state.station,
      },
    });
    this._setStates("isUpdated", true);
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.stations.isFetching ||
    state.storage.isFetching ||
    state.storageadd.isFetching ||
    state.storageupdate.isFetching ||
    state.storageremove.isFetching ||
    state.storageplaceadd.isFetching ||
    state.storageplaceupdate.isFetching ||
    state.storageplaceremove.isFetching ||
    state.storagerackadd.isFetching ||
    state.storagerackupdate.isFetching ||
    state.storagerackremove.isFetching ||
    state.room.isFetching ||
    state.printerTemplate.isFetching;
  let failure =
    state.stations.failure ||
    state.storage.failure ||
    state.storageadd.failure ||
    state.storageupdate.failure ||
    state.storageremove.failure ||
    state.storageplaceadd.failure ||
    state.storageplaceupdate.failure ||
    state.storageplaceremove.failure ||
    state.storagerackadd.failure ||
    state.storagerackupdate.failure ||
    state.storagerackremove.failure ||
    state.room.failure ||
    state.printerTemplate.failure;
  return {
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    room: state.room.data,
    stations: state.stations.data,
    storageadd: state.storageadd.data,
    storageupdate: state.storageupdate.data,
    storages: state.storage.data,
    isFetching: isFetching,
    failure: failure,
    printerTemplate: state.printerTemplate.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getStations: (credentials: any) => {
      dispatch(stationsRequest(credentials));
    },
    getStorage: (credentials: any) => {
      dispatch(storageRequest(credentials));
    },
    addStorage: (credentials: any) => {
      dispatch(storageAdd(credentials));
    },
    updateStorage: (credentials: any) => {
      dispatch(storageUpdate(credentials));
    },
    removeStorage: (credentials: any) => {
      dispatch(storageRemove(credentials));
    },
    addStoragePlace: (credentials: any) => {
      dispatch(storagePlaceAdd(credentials));
    },
    updateStoragePlace: (credentials: any) => {
      dispatch(storagePlaceUpdate(credentials));
    },
    removeStoragePlace: (credentials: any) => {
      dispatch(storagePlaceRemove(credentials));
    },
    addStorageRack: (credentials: any) => {
      dispatch(storageRackAdd(credentials));
    },
    updateStorageRack: (credentials: any) => {
      dispatch(storageRackUpdate(credentials));
    },
    removeStorageRack: (credentials: any) => {
      dispatch(storageRackRemove(credentials));
    },
    getRooms: (credentials: any) => {
      dispatch(roomRequest(credentials));
    },
    getPrinterTemplates: (credentials: any) => {
      dispatch(printerTemplateRequest(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(StorageComponent))
);
