import "../../../../styles/navigation-header.scss";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Col,
  Collapse,
  DropdownItem,
  Form,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";

import image from "../../../../assets/images/icon/image.png";
import smartToni from "../../../../assets/images/logo/smartToni.png";
import { PROFILE } from "../../../../constant/constant";
import {
  NavHeader,
  NavHeaderInitState,
} from "../../../../models/profile.model";
import { restaurantRequest } from "../../../../redux/actions/restaurant.action";
import commonService from "../../../../services/common.service";
import HeaderCard from "../../../card-components/header-card/header-card";
import LogOutModalCard from "../../../card-components/logout-card/logout-card";
import { withTranslation } from "react-i18next";
import {
  setFreshChatLanguage,
  setTagsForChannels,
  setTagsForFaq,
} from "../../../../app/freshChat";
import { chatInitRequest } from "../../../../redux/actions/chat.action";

let restaurantName: any;
class RestaurantHeaderComponent extends Component<any> {
  state: NavHeader;
  constructor(props: any) {
    super(props);
    this.state = NavHeaderInitState;
    this._setFunctionBindings();
  }

  render() {
    const { t } = this.props;
    let RESTAURANT = this.drpdownItem(this.state.restaurant);
    return (
      <React.Fragment>
        <LogOutModalCard
          logoutModalToggle={this.logoutModalToggle}
          modalToggle={this.modalToggle}
          open={this.state.open}
          focusAfterClose={this.state.focusAfterClose}
        />
        <div className="hide-left-max b-header-wrapper sticky">
          <Navbar className="row py-2" expand="lg">
            <Col className="col-lg-3 col-xl-2">
              <div className="d-flex smart-logo-width pl-15">
                <img
                  className="img-fluid compact-logo"
                  src={smartToni}
                  alt=""
                />
              </div>
            </Col>
            <Col className="col-lg-6 col-xl-8">
              <Col className="d-flex  p-0 align-items-center">
                <Nav navbar>
                  <NavItem active>
                    <NavLink className={"active"} href="#">
                      {t("common.home")}
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Col>
            <Col className="col-lg-3 col-xl-2">
              <div className="d-flex justify-content-end pr-15">
                <div className="d-flex align-items-center">
                  <HeaderCard
                    restaurantName={restaurantName}
                    RESTAURANT={RESTAURANT}
                    modalToggle={this.modalToggle}
                    goToProfile={this.goToProfile.bind(this)}
                  />
                </div>
              </div>
            </Col>
          </Navbar>
        </div>

        {/* Device below 991px */}

        <div className="hide-left-min b-header-xs-wrapper">
          <Navbar color="faded">
            <div className="header-align">
              <NavbarToggler onClick={this.leftToggle} />
              <img
                className="img-icon-fluid compact-logo"
                src={smartToni}
                alt=""
              />
              <NavbarToggler onClick={this.rightToggle} />
            </div>
            <Collapse
              isOpen={this.state.leftOpen}
              navbar
              style={{ paddingTop: "1rem" }}
            >
              <Link to="#">
                <Button className={"btn-light mb-3"}>
                  {t("restaurant.restaurants")}
                </Button>
              </Link>
            </Collapse>
            <Collapse
              isOpen={this.state.rightOpen}
              navbar
              style={{ paddingTop: "1rem" }}
            >
              <HeaderCard
                restaurantName={restaurantName}
                RESTAURANT={RESTAURANT}
                modalToggle={this.modalToggle}
                goToProfile={this.goToProfile.bind(this)}
              />
            </Collapse>
          </Navbar>
        </div>
      </React.Fragment>
    );
  }

  public drpdownItem(data: any) {
    let restaurant;
    if (!!data && data.length > 0) {
      let restuarantList: any = [];
      data.forEach((element: any) => {
        if (element.copy_status == undefined || element.copy_status === 3) {
          restuarantList.push(element);
        }
      });
      restaurant = restuarantList.map((res: any, index: number) => {
        if (res.uuid === this.props.match.params.uuid) {
          restaurantName = res;
        }
        return (
          <DropdownItem
            onClick={this.getRestaurantId.bind(this, res)}
            key={index}
          >
            {
              commonService.applyLocalization("user", "name", res?.locales)[
                "name"
              ]
            }
          </DropdownItem>
        );
      });
      return restaurant;
    }
  }

  componentDidMount() {
    this.props.chatInitRequest();
    setFreshChatLanguage();
    this.props.getRestaurant();
    let tags: Array<any> = ["settings"];
    setTagsForChannels(tags);
    setTagsForFaq(tags);
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
      if (!!newProps.restaurant && !newProps.isFetching && !newProps.failure) {
        this._setStates("restaurant", newProps.restaurant);
      }
    }
  }

  private _setFunctionBindings(): void {
    this.rightToggle = this.rightToggle.bind(this);
    this.leftToggle = this.leftToggle.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
    this.logoutModalToggle = this.logoutModalToggle.bind(this);
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  public rightToggle(): void {
    this.setState((prevState: any) => ({
      rightOpen: !prevState.rightOpen,
      leftOpen: false,
    }));
  }

  public leftToggle(): void {
    this.setState((prevState: any) => ({
      leftOpen: !prevState.leftOpen,
      rightOpen: false,
    }));
  }

  public modalToggle(): void {
    this.setState((prevState: any) => ({
      open: !prevState.open,
    }));
  }

  public logoutModalToggle(): void {
    commonService.clearUserData();
    setTimeout(() => {
      this.props.history.push("/");
    }, 800);
    this.setState((prevState: any) => ({
      open: !prevState.open,
    }));
  }

  public getRestaurantId(event: any): void {
    window.open(`/restaurant/${event.uuid}/home`, "_blank");
  }

  public goToProfile(): void {
    window.open(`/profile`, "_blank");
  }
}

const mapStateToProps: any = (state: any) => {
  return {
    restaurant: state.restaurant.data,
    isFetching: state.restaurant.isFetching,
    failure: state.restaurant.failure,
    chatInit: state.chatInit.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getRestaurant: () => {
      dispatch(restaurantRequest());
    },
    chatInitRequest: () => {
      dispatch(chatInitRequest());
    },
  };
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(RestaurantHeaderComponent))
);
