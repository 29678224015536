import React from "react";
import { useTranslation } from "react-i18next";
import { create, InstanceProps } from "react-modal-promise";
import { ManageKeyvalue, Option } from "../manage-key-value/manage-key-value";

type ManagekeyValuePairProps = InstanceProps<unknown> & {
  onConfirm: (keyValues: { key: string; value: string }[]) => Promise<void>;
  onEdit: (keyValues: { key: string; value: string }[]) => Promise<void>;
  onDelete: (keyvalues: { key: string; value: string }[]) => Promise<void>;
  onCancel?: () => void;
  keyValues: Option[];
};
export const ManageKeyValuePairPrompt: React.FC<ManagekeyValuePairProps> = (
  props,
) => {
  const {
    isOpen,
    onResolve,
    onConfirm,
    onEdit,
    onDelete,
    keyValues,
    onCancel = () => null,
  } = props;
  const { t } = useTranslation();

  const onConfirmPress = async (keyValues:{key: string, value: string}[]) => {
    await onConfirm(keyValues);
    onResolve(true);
  };

  const onCancelPress = async () => {
    onCancel && onCancel();
    onResolve(false);
  };

  const onValueEdit = async (keyValues:{key: string, value: string}[]) => {
    await onEdit(keyValues);
    onResolve(true);
  };

  const onRemove = async (keyValues:{key: string, value: string}[]) => {
    await onDelete(keyValues);
    onResolve(true);
  };
  return (
    <ManageKeyvalue
      isOpen={isOpen}
      keyValues={keyValues}
      onPressAdd={onConfirmPress}
      onCancel={onCancelPress}
      onEdit={onValueEdit}
      onDelete={onRemove}
    />
  );
};

export const manageKeyValuePairPrompt = create(ManageKeyValuePairPrompt);
