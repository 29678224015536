export interface ServiceSetDetailsModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}

export interface ServiceSetDetailsState {
  allRecipes: Array<any>;
  currentItemDetails: any;
  currencyCode: String;
  isFetching: boolean;
  isUpdated: boolean;
  nonSelectableCollapse: boolean;
  savedServiceRecipes: any;
  selectableCollapse: boolean;
  serviceSetDetails: any;
  serviceRecipes: Array<object>;
  serviceRecipesGrouped: any;
  supplierList: Array<any>;
  labels: Array<any>;
  selectedLabelOptions: Array<any>;
  searchByName: string;
}
export const ServiceSetDetailsInitState: ServiceSetDetailsState = {
  allRecipes: [],
  currencyCode: "",
  currentItemDetails: {},
  isFetching: false,
  isUpdated: false,
  nonSelectableCollapse: false,
  savedServiceRecipes: [],
  selectableCollapse: false,
  serviceSetDetails: {},
  serviceRecipes: [],
  serviceRecipesGrouped: {
    false: [],
    true: [],
  },
  supplierList: [],
  labels: [],
  selectedLabelOptions: [],
  searchByName: "",
};

export interface ServiceSetDetailsRecipesState {
  currentRecipe: any;
  currentCourse: any;
  currentPrice: number;
  currentVat: any;
  isActive: boolean;
  isAppears: boolean;
  isSelectable: boolean;
  itemPrices: any;
  warnings: any;
  offeredUnit: string;
  units: Array<any>;
}
export const ServiceSetDetailsRecipesInitState = {
  currentRecipe: {},
  currentCourse: {},
  currentPrice: 0,
  currentVat: {},
  isActive: true,
  isAppears: true,
  isSelectable: false,
  itemPrices: {},
  warnings: {
    currentRecipe: null,
  },
  offeredUnit: "",
  units: [],
};
