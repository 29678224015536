import "../../../styles/role-rights.scss";

import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function RightsAddCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader toggle={props.cancelManagetoggle} className="modal-header">
        {t("common.manageRights")}
      </ModalHeader>
      <ModalBody>{props.componentList}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.cancelManagetoggle}>
          {t("common.cancel")}
        </Button>
        <Button color="info" onClick={props.saveManageRight}>
          {t("common.save")}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
