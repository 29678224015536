export interface reportModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}

export interface reportState {
  activeReport: string;
  fromDate: Date;
  guestPrintData: {
    type: string;
    data: any;
  };
  isFetching: boolean;
  isPrinterModalOpen: boolean;
  labelList: Array<any>;
  reportData: any;
  recipeList: Array<any>;
  reportSelection: string;
  reportTemplates: Array<any>;
  selectedDate: Date;
  selectedPrinter: string;
  selectedRoom: string;
  selectedTemplate: string;
  toDate: Date;
  selectedRestaurant: string;
  requestType: string;
  selectedSupplier: string;
  clientRestaurant: string;
  serviceSet: string;
}

export const reportInitState = {
  activeReport: "",
  fromDate: new Date(),
  guestPrintData: {
    type: "",
    data: {},
  },
  isFetching: false,
  isPrinterModalOpen: false,
  labelList: [],
  reportData: [],
  recipeList: [],
  reportSelection: "",
  reportTemplates: [],
  selectedDate: new Date(),
  selectedPrinter: "",
  selectedRoom: "",
  selectedTemplate: "",
  toDate: new Date(),
  selectedRestaurant: "",
  requestType: "",
  selectedSupplier: "",
  clientRestaurant: "",
  serviceSet: "",
};
