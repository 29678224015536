export interface PrinterProtocolModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface PrinterProtocolState {
  printer: any;
  isFetching: boolean;
  editmodal: boolean,
  addmodal: boolean,
  uuid: string;
  isUpdated: boolean,
  restaurantuuid: string,
  deletemodal: boolean,
  name: string,
  format: string
}
export const PrinterProtocolInitState = {
  isFetching: false,
  printer: [],
  restaurantuuid: '',
  isUpdated: false,
  editmodal: false,
  addmodal: false,
  uuid: '',
  deletemodal: false,
  name: '',
  format: ''
};
