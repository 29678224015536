import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default function PrinterAddCard(props: any) {
    const { t, i18n } = useTranslation();
    return (
        <Modal isOpen={props.state.addmodal}>
            <ModalHeader toggle={props.addPrinterToggle} className="modal-header">{t('printer.addPrinter')}</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="name">{t('printer.printerName')}</Label>
                        <Label className="required-star-style">*</Label>
                        <Input type="text" required name="name" autoComplete="name" placeholder={t('printer.enterPrinterName')}
                            value={props.state.name} onChange={props.handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="printerip">{t('printer.printerIP')}</Label>
                        <Label className="required-star-style">*</Label>
                        <Input type="text" required name="printerip" autoComplete="printerip" placeholder={t('printer.enterPrinterIP')}
                            value={props.state.printerip} onChange={props.handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="printerport">{t('printer.printerPort')}</Label>
                        <Label className="required-star-style">*</Label>
                        <Input type="number" required name="printerport" autoComplete="printerport" placeholder={t('printer.enterPrinterPort')} min="0"
                            value={props.state.printerport} onChange={props.handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="poslisteningport">{t('printer.posListeningPort')}</Label>
                        <Label className="required-star-style">*</Label>
                        <Input type="number" required name="poslisteningport" autoComplete="poslisteningport" placeholder={t('printer.enterPOSListeningPort')} min="0"
                            value={props.state.poslisteningport} onChange={props.handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="printerprotocoluuid">{t('printer.printerProtocol')}</Label>
                        <Label className="required-star-style">*</Label>
                        <Input type="select" id="countrySelect" required name="printerprotocoluuid" onChange={props.handleProtocolChange}>
                            <option value="">{t('printer.selectPrinterProtocol')} ...</option>
                            {props.OPTIONS}
                        </Input>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={props.addPrinterToggle}>{t('common.cancel')}</Button>
                <Button color="info" onClick={props.savePrinterAdd}
                    disabled={!props.state.name || !props.state.printerip || !props.state.printerport
                        || !props.state.poslisteningport || !!props.state.isFetching || props.state.printerprotocoluuid === ''}
                >{t('common.save')}</Button>
            </ModalFooter>
        </Modal>
    )
}
