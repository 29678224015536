import React, { Component } from "react";
import { withCardItemSelectionState } from "../../cardItemSelectionManager";
import {
  CardItemCardComponent,
  CardItemContainer,
} from "../../card-components/card-item";
import { Col, CustomInput, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

import commonService from "../../../services/common.service";
import InlineEditInput from "../../inline-edit-input/inline-edit-input";
import {
  ItemChoiceInitState,
  ItemChoiceState,
} from "../../../models/itemChoice.model";
import WarningComment, {
  getMandatoryWarning,
} from "../../warning-comment/warning-comment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PHILIPPINE_GRAY, WHITE } from "../../../constant/constant";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

class ChoiceItemListCard extends Component<any, any> {
  state: ItemChoiceState;
  constructor(props: any) {
    super(props);
    this.state = ItemChoiceInitState;
  }
  componentDidMount() {
    this._loadValues();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.choiceOptions.length !== this.props.choiceOptions.length ||
      prevState.cardItemData !== this.props.cardItemData
    )
      this._loadValues();
  }
  private _loadValues = () => {
    this.setState({
      cardItemData: this.props.cardItemData,
    });
    if (this.state?.type === "newCard") {
      this.setState({
        component_price: false,
        outputQuantity: null,
        recipe: "",
      });
    } else if (
      this.props.cardItemSelectionData.selectionState.isItemSelected &&
      this.props.cardItemSelectionData.selectionState.mode === "edit"
    ) {
      const recipe =
        commonService.applyLocalization(
          "restaurant",
          "name",
          this.state.cardItemData.locales
        )["name"] || "";

      this.setState({
        component_price: this.props.cardItemData?.component_price,
        outputQuantity: this.state.cardItemData.outputQuantity,
        recipe: recipe,
        warnings: {
          recipe: getMandatoryWarning(this.state.cardItemData.recipe_uuid),
          outputQuantity: getMandatoryWarning(
            this.state.cardItemData.outputQuantity
          ),
        },
      });
    } else {
      let recipe =
        this.props.choiceOptions &&
        this.props.choiceOptions?.filter(
          (item: any) => item.value === this.state.cardItemData.recipe_uuid
        )[0];

      this.setState({
        component_price: this.props.cardItemData?.component_price,
        outputQuantity: this.state.cardItemData.output_quantity,
        recipe: recipe,
        warnings: {
          recipe: getMandatoryWarning(this.state.cardItemData.recipe_uuid),
          outputQuantity: getMandatoryWarning(
            this.state.cardItemData.output_quantity
          ),
        },
      });
    }
  };

  private selectThisChoice = () => {
    let recipe =
      this.props.choiceOptions &&
      this.props.choiceOptions?.filter(
        (item: any) => item.value === this.state.cardItemData.recipe_uuid
      )[0];
    this.props.cardItemSelectionData.selectCardItem();
    this.setState({
      recipe: recipe,
      outputQuantity: this.state.cardItemData.output_quantity,
      warnings: {
        recipe: getMandatoryWarning(this.state.cardItemData.recipe_uuid),
        outputQuantity: getMandatoryWarning(
          this.state.cardItemData.output_quantity
        ),
      },
    });
  };

  render() {
    const { t } = this.props;
    return (
      <CardItemContainer className={"task-type px-0 mx-n4"}>
        <CardItemCardComponent
          cardItemSelectionData={this.props.cardItemSelectionData}
          className={"task-type assigned"}
          deleteCallback={this.deleteCard}
          onDeleteClick={this.deleteCard}
          readOnly={false}
          saveCallback={this.saveCard}
          selectCallback={this.selectThisChoice}
          saveButton={true}
        >
          <Row>
            <Col md={4} lg={6} xl={4}>
              <WarningComment
                data={this.state.warnings?.recipe}
                displayType={"bottom"}
              >
                {this.props.cardItemSelectionData.selectionState
                  .isBeingEdited ? (
                  <Select
                    name="recipe"
                    value={this.state.recipe}
                    onChange={this.handleSelectChange}
                    options={this.props.choiceOptions}
                    maxMenuHeight={200}
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                ) : (
                  <div>
                    <FontAwesomeIcon
                      className="b-drag-icon mr-1"
                      style={{
                        color:
                          this.props.cardItemSelectionData.className !== ""
                            ? WHITE
                            : PHILIPPINE_GRAY,
                      }}
                      icon={faEllipsisV}
                    />
                    {this.state.recipe?.label}
                  </div>
                )}
              </WarningComment>
            </Col>
            <Col md={4} lg={6} xl={3}>
              <WarningComment
                data={this.state.warnings?.outputQuantity}
                displayType={"bottom"}
              >
                <div className="d-flex align-items-center">
                  <InlineEditInput
                    name="outputQuantity"
                    value={this.state.outputQuantity}
                    editElement={Input}
                    edit={
                      this.props.cardItemSelectionData.selectionState
                        .isBeingEdited
                    }
                    style={{ display: "block" }}
                    propsEdit={{
                      type: "number",
                      placeholder: t("common.quantity"),
                    }}
                    pattern={"^([0-9,.]{0,6})$"}
                    onChange={this.handleChange}
                  />
                  <span className="px-2">
                    {this.props.displayUnitName(this.state.recipe?.outputUnit)}
                  </span>
                </div>
              </WarningComment>
            </Col>
            {this.props.hasPrice && (
              <Col
                md={4}
                lg={12}
                xl={5}
                className="d-flex align-items-center mt-lg-3 mt-xl-0"
              >
                {this.props.cardItemData.component_price &&
                  !this.props.cardItemSelectionData.selectionState
                    .isBeingEdited &&
                  t("common.hasPrice")}
                {this.props.cardItemSelectionData.selectionState
                  .isBeingEdited && (
                  <>
                    <span className="d-inline-flex pl-4 ml-2 align-items-center">
                      <Input
                        id="component_price"
                        checked={this.state.component_price}
                        name="component_price"
                        onChange={this.handleComponentPriceChange}
                        type="checkbox"
                      />
                      <Label for="component_price" check>
                        {t("common.addComponentPrice")}
                      </Label>
                    </span>
                  </>
                )}
              </Col>
            )}
          </Row>
        </CardItemCardComponent>
      </CardItemContainer>
    );
  }

  deleteCard = () => {
    this.props.onDeleteCard(this.props.cardItemId);
  };

  handleSelectChange = (value: any) => {
    const warnings: any = { ...this.state.warnings };

    this.setState({ recipe: value });
    warnings.recipe = getMandatoryWarning(value.value);
    this.setState({ warnings });
  };

  handleChange = (inputName: any, value: any) => {
    const warnings: any = { ...this.state.warnings };
    if (inputName === "recipe") {
      this.setState({ recipe: value.option?.value });
      warnings.recipe = getMandatoryWarning(value.option?.value);
    } else {
      this.setState({ outputQuantity: parseFloat(value) < 0 ? "" : value });
      const isValid =
        parseFloat(value) < 0 || parseFloat(value) === 0 ? "" : value;
      warnings.outputQuantity = getMandatoryWarning(isValid);
    }
    this.setState({ warnings });
  };

  handleComponentPriceChange = (elem: any) => {
    this.setState({
      component_price: !this.state.component_price,
    });
  };

  saveCard = () => {
    if (!this.state.recipe || !this.state.outputQuantity) {
      this.setState({
        warnings: {
          recipe: getMandatoryWarning(this.state.recipe || ""),
          outputQuantity: getMandatoryWarning(this.state.outputQuantity || ""),
        },
      });
    }

    if (
      this.state.warnings.recipe === null &&
      this.state.warnings.outputQuantity === null &&
      this.state.recipe &&
      this.state.outputQuantity
    ) {
      let formData: any = {
        component_price: this.props.hasPrice
          ? this.state.component_price
          : undefined,
        recipe_uuid: this.state.recipe.value,
        output_quantity: Number(this.state.outputQuantity),
        uuid: this.props.cardItemData.uuid,
      };
      this.props.onSaveCard(formData);
      if (this.props.cardItemId === "newCard") {
        this.setState({
          recipe: null,
          outputQuantity: null,
        });
      }
    }
  };
}

export default withTranslation()(
  withRouter(withCardItemSelectionState("ItemListCard", 1)(ChoiceItemListCard))
);
