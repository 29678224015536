import i18next from "i18next";
import commonService from "../services/common.service";

let GLOBAL_LANGUAGES = ["en-gb", "de-ch"];

//setRestaurantLanguage
export const setRestaurantLanguage = (id: any) => {
  let resUUID = id;
  const restaurant = JSON.parse(
    localStorage.getItem("RESTAURANT_DETAILS") || "{}"
  );
  const userLanguages: any = JSON.parse(
    localStorage.getItem("USERLANGUAGE") || "{}"
  );
  let filteredData =
    restaurant &&
    restaurant.length > 0 &&
    restaurant.find((item: any) => {
      return item.uuid === resUUID;
    });
  const doUserHasRestLang: any =
    userLanguages?.find(
      (uLang: any) => uLang.uuid === filteredData?.language
    ) || {};

  if (doUserHasRestLang?.code) {
    i18next.changeLanguage(doUserHasRestLang.code);
  } else {
    i18next.changeLanguage(userLanguages[0].code);
  }
  commonService.setRestaurantDetails(filteredData);
};

export const setLanguage = (langCode: any) => {
  let lang = JSON.stringify(langCode);
  try {
    let arrayData = JSON.parse(lang || "");
    if (!!arrayData) {
      for (let i = 0; i < arrayData.length; i++) {
        if (GLOBAL_LANGUAGES.includes(arrayData[i].code)) {
          i18next.changeLanguage(arrayData[i].code);
          break;
        }
      }
    } else {
      i18next.changeLanguage("de-ch");
    }
  } catch (er) {
    i18next.changeLanguage("de-ch");
  }
};
