import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import * as types from '../redux/actions/types';
import handleResponse from '../services/response.service';
import storage from '../services/storage.service';
import {
    storageSuccess,
    storageFailure,
    storageAddSuccess,
    storageAddFailure,
    storageUpdateSuccess,
    storageUpdateFailure,
    storageRemoveSuccess,
    storageRemoveFailure
} from '../redux/actions/storage.action';

const storageCall = (params: any) => storage.storage(RESTAURANT_REQUEST, params);
const addStorageCall = (params: any) => storage.addStorage(RESTAURANT_REQUEST, params);
const updateStorageCall = (params: any) => storage.updateStorage(RESTAURANT_REQUEST, params);
const removeStorageCall = (params: any) => storage.removeStorage(RESTAURANT_REQUEST, params);

function* handleStorageRequest(params: object) {
    try {
        let result;
        result = yield call(storageCall, params);
        yield handleStorage200(result);
    } catch (error) {
        throw error;
    }
}

const handleStorage200 = function* handleStorage200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(storageSuccess(result.data));
    } else {
        yield put(storageFailure(result.data));
        handleResponse(result);
    }
}

function* handleStorageAdd(params: object) {
    try {
        let result;
        result = yield call(addStorageCall, params);
        yield handleStorageAdd200(result);
    } catch (error) {
        throw error;
    }
}

const handleStorageAdd200 = function* handleStorageAdd200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(storageAddSuccess(result.data));
    } else {
        yield put(storageAddFailure(result.data));
    }
    handleResponse(result);
}

function* handleStorageUpdate(params: object) {
    try {
        let result;
        result = yield call(updateStorageCall, params);
        yield handleStorageUpdate200(result);
    } catch (error) {
        throw error;
    }
}

const handleStorageUpdate200 = function* handleStorageUpdate200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(storageUpdateSuccess(result.data));
    } else {
        yield put(storageUpdateFailure(result.data));
    }
    handleResponse(result);
}
function* handleStorageRemove(params: object) {
    try {
        let result;
        result = yield call(removeStorageCall, params);
        yield handleStorageRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handleStorageRemove200 = function* handleStorageRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(storageRemoveSuccess(result.data));
    } else {
        yield put(storageRemoveFailure(result.data));
    }
    handleResponse(result);
}

export default function* storageSaga() {
    yield takeLatest(types.STORAGE.REQUEST, handleStorageRequest);
    yield takeLatest(types.STORAGE_ADD.REQUEST, handleStorageAdd);
    yield takeLatest(types.STORAGE_UPDATE.REQUEST, handleStorageUpdate);
    yield takeLatest(types.STORAGE_REMOVE.REQUEST, handleStorageRemove);
}