import { call, put, takeLatest } from 'redux-saga/effects';
import { RESTAURANT_REQUEST } from '../config/api.config';
import { supportFailure, supportSuccess } from '../redux/actions/support.action';
import * as types from '../redux/actions/types';
import handleResponse from '../services/response.service';
import supports from '../services/support.service';


const supportCall = (params: any) => supports.support(RESTAURANT_REQUEST, params);


function* handleSupportRequest(params: object) {
    try {
        let result;
        result = yield call(supportCall, params);
        yield handleSupport200(result);
    } catch (error) {
        throw error;
    }
}

const handleSupport200 = function* handleSupport200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {        
        yield put(supportSuccess(result.data));
    } else {
        yield put(supportFailure(result.data));
        handleResponse(result);
    }
}

export default function* unitsSaga() {
    yield takeLatest(types.SUPPORT.REQUEST, handleSupportRequest);
  
}
