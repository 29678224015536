import apiCall from "./api.call.config";

async function addTaskIntervention(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/task/" +
        params.credentials.taskuuid +
        "/intervention",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function removeTaskIntervention(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/task/" +
        params.credentials.taskuuid +
        "/intervention/" +
        params.credentials.uuid
    );
  } catch (e) {
    throw e;
  }
}

async function taskIntervention(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/task/" +
        params.credentials.taskuuid +
        "/intervention"
    );
  } catch (e) {
    throw e;
  }
}

async function updateTaskIntervention(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/recipe/" +
        params.credentials.recipeuuid +
        "/task/" +
        params.credentials.taskuuid +
        "/intervention/" +
        params.credentials.uuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}
export default {
  addTaskIntervention,
  removeTaskIntervention,
  taskIntervention,
  updateTaskIntervention,
};
