import Logo from "../assets/images/logo/smartToni.png";
import jwt_decode from "jwt-decode";
import { store } from "../redux/store/store";
import { chatUpdate } from "../redux/actions/chat.action";

export const init = (params) => {
  var token = !!params ? params.token : null;
  var decoded = !!token ? jwt_decode(token) : "";

  var profileData = localStorage.getItem("PROFILE");
  var restaurantId = localStorage.getItem("RESTAURANT_ID");
  var restoreId = !!decoded ? decoded.restore_token : null;
  var parsedData = JSON.parse(profileData);

  window.fcWidget?.init({
    token: !!decoded ? decoded.token : null,
    host: !!decoded ? decoded.host : null,
    restoreId: restoreId, 
    externalId: !!parsedData ? parsedData.email : null, 
    firstName: !!parsedData ? parsedData.first_name : null, 
    lastName: !!parsedData ? parsedData.last_name : null, 
    email: !!parsedData ? parsedData.email : null,
    config: {
      disableEvents: true,
      cssNames: {
        widget: "fc_frame",
        open: "fc_open",
        expanded: "fc_expanded",
      },
      showFAQOnOpen: false,
      hideFAQ: false,
      agent: {
        hideName: false,
        hidePic: false,
        hideBio: true,
      },
      headerProperty: {
        backgroundColor: "#07767c",
        foregroundColor: "#FFFF",
        backgroundImage: Logo,
        color: "#FFF",
      },
      content: {
        placeholders: {
          search_field: "Search",
          reply_field: "Reply",
          csat_reply: "Add your comments here",
        },
        actions: {
          csat_yes: "Yes",
          csat_no: "No",
          push_notify_yes: "Yes",
          push_notify_no: "No",
          tab_faq: "Solutions",
          tab_chat: "Chat",
          csat_submit: "Submit",
        },
        headers: {
          chat: "Chat with Us",
          chat_help: "Reach out to us if you have any questions",
          faq: "Solution Articles",
          faq_help: "Browse our articles",
          faq_not_available: "No Articles Found",
          faq_search_not_available: "No articles were found for {{query}}",
          faq_useful: "Was this article helpful?",

          faq_message_us: "Message Us",
          push_notification:
            "Don't miss out on any replies! Allow push notifications?",
          csat_question: "Did we address your concerns??",
          csat_yes_question: "How would you rate this interaction?",
          csat_no_question: "How could we have helped better?",
          csat_thankyou: "Thanks for the response",
          csat_rate_here: "Submit your rating here",
          channel_response: {
            offline: "We are currently away. Please leave us a message",
            online: {
              minutes: {
                one: "Currently replying in {!time!} minutes ",

                more: "Typically replies in {!time!} minutes",
              },
              hours: {
                one: "Currently replying in under an hour",
                more: "Typically replies in {!time!} hours",
              },
            },
          },
        },
      },
    },
  });

  window.fcWidget?.user.get(function (resp) {
    var status = resp && resp.status,
      data = resp && resp.data;
    if (status !== 200) {
      window.fcWidget.user.setProperties({
        firstName: !!parsedData ? parsedData.first_name : null,
        lastName: !!parsedData ? parsedData.last_name : null, 
        email: !!parsedData ? parsedData.email : null,
        restaurant: !!restaurantId ? restaurantId : null,
        page: window.location.href, 
      });
      window.fcWidget.on("user:created", function (resp) {
        var status = resp && resp.status,
          data = resp && resp.data;
        if (status === 200) {
          if (data.restoreId) {
            store.dispatch(
              chatUpdate({
                data: {
                  token: data.restoreId,
                },
              })
            );
          }
        }
      });
    }
  });
};

export const closeChatBox = () => {
  window.fcWidget.destroy();
};

export const setFreshChatLanguage = () => {
  let userLanguage = localStorage.getItem("USERLANGUAGE");
  let locale = !!userLanguage && JSON.parse(userLanguage)[0]?.code?.split("-")[0];
  window.fcWidget.user.setLocale(locale === "de" ? "de" : "en");
};

export const setTagsForChannels = (tags) => {
  window.fcWidget.setTags(tags);
};

export const setTagsForFaq = (tags) => {
  window.fcWidget.setFaqTags({
    tags: tags,
    filterType: "category",
  });
};
