import apiCall from "./api.call.config";

async function updateTranslation(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url + "/" + params.credentials.restaurantuuid + "/translations",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  updateTranslation,
};
