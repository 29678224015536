import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import QRCode from "qrcode";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import { Button } from "reactstrap";
class QRCodeGenerate extends Component<any> {
  state: any;
  constructor(props: any) {
    super(props);
  }
  componentDidMount() {
    const replacedUrl = this.props.match.params.key.replace(/<>/gi, "%2F");
    const url = decodeURIComponent(replacedUrl);
    QRCode.toCanvas(document.getElementById("canvas"), url, {
      version: 5,
      width: 400,
    });
  }
  render() {
    const { t } = this.props;
    return (
      <div
        style={{
          backgroundColor: "#fff",
          textAlign: "center",
          height: "100%",
          position: "relative",
        }}
      >
        <canvas
          id="canvas"
          style={{
            minWidth: "400px",
            minHeight: "400px",
            maxWidth: "400px",
            maxHeight: "400px",
            pointerEvents: "none",
          }}
        ></canvas>
        <Button
          style={{
            position: "absolute",
            bottom: "10px",
            margin: "auto",
            left: "calc((100% - 59.8px)/2)",
            right: 0,
          }}
          onClick={() => {
            window.print();
          }}
        >
          {t("common.print")}
        </Button>
      </div>
    );
  }
}
export default withTranslation()(withRouter(QRCodeGenerate));
