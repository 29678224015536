import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import {
    recipePrinterAddFailure,
    recipePrinterAddSuccess,
    recipePrinterFailure,
    recipePrinterRemoveFailure,
    recipePrinterRemoveSuccess,
    recipePrinterSuccess,
    recipePrinterUpdateFailure,
    recipePrinterUpdateSuccess,
} from '../redux/actions/recipe.printer.action';
import * as types from '../redux/actions/types';
import printer from '../services/recipe.printer.service';
import handleResponse from '../services/response.service';

const printerCall = (params: any) => printer.recipePrinter(RESTAURANT_REQUEST, params);
const updatePrinterCall = (params: any) => printer.updateRecipePrinter(RESTAURANT_REQUEST, params);
const addPrinterCall = (params: any) => printer.addRecipePrinter(RESTAURANT_REQUEST, params);
const removePrinterCall = (params: any) => printer.removeRecipePrinter(RESTAURANT_REQUEST, params);

function* handlePrinterRequest(params: object) {
    try {
        let result;
        result = yield call(printerCall, params);
        yield handlePrinter200(result);
    } catch (error) {
        throw error;
    }
}

const handlePrinter200 = function* handlePrinter200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(recipePrinterSuccess(result.data));
    } else {
        yield put(recipePrinterFailure(result.data));
        handleResponse(result);
    }
}

function* handlePrinterUpdate(params: object) {
    try {
        let result;
        result = yield call(updatePrinterCall, params);
        yield handlePrinterUpdate200(result);
    } catch (error) {
        throw error;
    }
}

const handlePrinterUpdate200 = function* handlePrinterUpdate200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(recipePrinterUpdateSuccess(result.data));
    } else {
        yield put(recipePrinterUpdateFailure(result.data));
    }
    handleResponse(result);
}

function* handlePrinterAdd(params: object) {
    try {
        let result;
        result = yield call(addPrinterCall, params);
        yield handlePrinterAdd201(result);
    } catch (error) {
        throw error;
    }
}

const handlePrinterAdd201 = function* handlePrinterAdd201(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(recipePrinterAddSuccess(result.data));
    } else {
        yield put(recipePrinterAddFailure(result.data));
    }
    handleResponse(result);
}

function* handlePrinterRemove(params: object) {
    try {
        let result;
        result = yield call(removePrinterCall, params);
        yield handlePrinterRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handlePrinterRemove200 = function* handlePrinterRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(recipePrinterRemoveSuccess(result.data));
    } else {
        yield put(recipePrinterRemoveFailure(result.data));
    }
    handleResponse(result);
}
export default function* printerSaga() {
    yield takeLatest(types.RECIPE_PRINTER.REQUEST, handlePrinterRequest);
    yield takeLatest(types.RECIPE_PRINTER_UPDATE.REQUEST, handlePrinterUpdate);
    yield takeLatest(types.RECIPE_PRINTER_ADD.REQUEST, handlePrinterAdd);
    yield takeLatest(types.RECIPE_PRINTER_REMOVE.REQUEST, handlePrinterRemove);
}
