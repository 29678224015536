import apiCall from "./api.call.config";

async function currency(url: string, params: any) {
  try {
    return await apiCall.get(url + "currency");
  } catch (e) {
    throw e;
  }
}

export default {
  currency,
};
