import {
  ChannelsModel,
  ChannelsModelInitState,
} from "../../models/channels.model";
import * as types from "../actions/types";

export function ChannelsReducer(
  state = ChannelsModelInitState,
  action: any
): ChannelsModel {
  switch (action.type) {
    case types.CHANNELS.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.CHANNELS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.CHANNELS.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function ChannelsDetailReducer(
  state = ChannelsModelInitState,
  action: any
): ChannelsModel {
  switch (action.type) {
    case types.CHANNELS_DETAILS.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.CHANNELS_DETAILS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.CHANNELS_DETAILS.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function ChannelsAddRestaurantsReducer(
  state = ChannelsModelInitState,
  action: any
): ChannelsModel {
  switch (action.type) {
    case types.CHANNELS_ADD_RESTAURANTS.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.CHANNELS_ADD_RESTAURANTS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.CHANNELS_ADD_RESTAURANTS.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
export function RestaurantsChannelsListReducer(
  state = ChannelsModelInitState,
  action: any
): ChannelsModel {
  switch (action.type) {
    case types.RESTAURANTS_CHANNELS_LIST.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.RESTAURANTS_CHANNELS_LIST.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.RESTAURANTS_CHANNELS_LIST.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
