import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function roleRightRequest(credentials: object): ActionModel {
  return {
    type: types.ROLE_RIGHT.REQUEST,
    credentials
  };
}

export function roleRightSuccess(data: object): ActionModel {
  return {
    type: types.ROLE_RIGHT.SUCCESS,
    data
  };
}

export function roleRightFailure(err: object): ActionModel {
  return {
    type: types.ROLE_RIGHT.FAILURE,
    err
  };
}

export function roleRightAdd(credentials: object): ActionModel {
  return {
    type: types.ROLE_RIGHT_ADD.REQUEST,
    credentials
  };
}

export function roleRightAddSuccess(data?: object): ActionModel {
  return {
    type: types.ROLE_RIGHT_ADD.SUCCESS,
    data
  };
}

export function roleRightAddFailure(err: object): ActionModel {
  return {
    type: types.ROLE_RIGHT_ADD.FAILURE,
    err
  };
}

