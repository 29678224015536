import "../../../../styles/navigation-left.scss";

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button } from "reactstrap";

import InfractructureNavigationComponent from "./infrastructure-navigation";
import PrinterInterfaceNavigationComponent from "./printer-interface-navigation";
import i18next from "i18next";
import { withTranslation } from "react-i18next";

class SettingsNavComponent extends Component<any> {
  render() {
    const { t } = this.props;
    return (
      <div className="b-sticky-below-header b-side-navbar">
        <Link to={`/restaurant/${this.props.restaurantuuid}/units`}>
          <Button
            className={
              this.props.display === "units"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("unit.units")}
          </Button>
        </Link>
        <Link to={`/restaurant/${this.props.restaurantuuid}/vat`}>
          <Button
            className={
              this.props.display === "vat" ? "btn-dark mb-3" : "btn-light mb-3"
            }
          >
            {t("vat.vat")}
          </Button>
        </Link>
        <Link to={`/restaurant/${this.props.restaurantuuid}/payment-options`}>
          <Button
            className={
              this.props.display === "paymentOptions"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("payment.paymentOptions")}
          </Button>
        </Link>
        <Link to={`/restaurant/${this.props.restaurantuuid}/course-list`}>
          <Button
            className={
              this.props.display === "courseList"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("course.courseList")}
          </Button>
        </Link>
        <Link
          to={`/restaurant/${this.props.restaurantuuid}/content-management`}
        >
          <Button
            className={
              this.props.display === "content-management"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("contentManagement.contentManagement")}
          </Button>
        </Link>
        <Link to={`/restaurant/${this.props.restaurantuuid}/media-manager`}>
          <Button
            className={
              this.props.display === "media-manager"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("mediaManager.mediaManager")}
          </Button>
        </Link>
        {this.printerInterfaceList()}
        <Link to={`/restaurant/${this.props.restaurantuuid}/email-templates`}>
          <Button
            className={
              this.props.display === "email-templates"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("email.emailTemplates")}
          </Button>
        </Link>
        <Link
          to={`/restaurant/${this.props.restaurantuuid}/inventory-filter-settings`}
        >
          <Button
            className={
              this.props.display === "inventory-filter-settings"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("inventory.filter")}
          </Button>
        </Link>
        <Link to={`/restaurant/${this.props.restaurantuuid}/settings`}>
          <Button
            className={
              this.props.display === "settings"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("common.settings")}
          </Button>
        </Link>
        <Link to={`/restaurant/${this.props.restaurantuuid}/servers`}>
          <Button
            className={
              this.props.display === "servers"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("common.servers")}
          </Button>
        </Link>
        <Link to={`/restaurant/${this.props.restaurantuuid}/translation`}>
          <Button
            className={
              this.props.display === "translation"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("common.translations")}
          </Button>
        </Link>
        <Link to={`/restaurant/${this.props.restaurantuuid}/shop-interface`}>
          <Button
            className={
              this.props.display === "shop-interface"
                ? "btn-dark mb-3"
                : "btn-light mb-3"
            }
          >
            {t("common.shopInterface")}
          </Button>
        </Link>
      </div>
    );
  }

  public printerInterfaceList() {
    return (
      <div className="child-btn-light mb-3 ">
        <PrinterInterfaceNavigationComponent
          restaurantuuid={this.props.restaurantuuid}
          display={this.props.display}
        />
      </div>
    );
  }
}

export default withTranslation()(withRouter(SettingsNavComponent));
