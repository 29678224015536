import "../../styles/units.scss";

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import commonService from "../../services/common.service";

import "./translation.css";
import { withTranslation } from "react-i18next";
import { FormText, Input } from "reactstrap";
class TextInputComponent extends Component<any> {
  render() {
    const {
      t,
      data,
      defaultLang,
      handleChange,
      field,
      name,
      restaurantLang,
      requiredFields,
      notValid,
      text,
      type,
      value,
    } = this.props;
    return (
      <div className="row p-1">
        <div className="col-sm-3">
          <span>{text}</span>
        </div>
        <div className="col-sm-8">
          {type === "text" ? (
            <>
              <Input
                className={
                  requiredFields?.includes(name) && notValid && !!!value
                    ? "col-md-5 briefing-input-error"
                    : "col-md-5"
                }
                name={name}
                placeholder={t("common.enter") + " " + text.toLowerCase()}
                type="text"
                value={value}
                onChange={(e: any) => {
                  handleChange(e);
                }}
              />
              <FormText className="text-muted">
                {
                  commonService.applyLocalization(
                    "translate",
                    field,
                    data?.locales || [],
                    defaultLang
                  )[field]
                }{" "}
              </FormText>
            </>
          ) : (
            <>
              <textarea
                className="form-control"
                name={name}
                placeholder={t("common.enter") + " " + text.toLowerCase()}
                value={value}
                onChange={(e: any) => {
                  handleChange(e);
                }}
              ></textarea>
              <FormText className="text-muted">
                {
                  commonService.applyLocalization(
                    "translate",
                    field,
                    data?.locales || [],
                    defaultLang
                  )[field]
                }{" "}
              </FormText>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching = state.recipe.isFetching;
  let failure = state.recipe.failure;
  return {
    isFetching: isFetching,
    failure: failure,
    recipes: state.recipe.data,
    tasks: state.task.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {};
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(TextInputComponent))
);
