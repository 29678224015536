import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function taskInterventionIngredintRequest(credentials: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_INGREDIENT.REQUEST,
    credentials
  };
}

export function taskInterventionIngredientSuccess(data: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_INGREDIENT.SUCCESS,
    data
  };
}

export function taskInterventionIngredientFailure(err: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_INGREDIENT.FAILURE,
    err
  };
}

export function taskInterventionIngredientAdd(credentials: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_INGREDIENT_ADD.REQUEST,
    credentials
  };
}

export function taskInterventionIngredientAddSuccess(data?: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_INGREDIENT_ADD.SUCCESS,
    data
  };
}

export function taskInterventionIngredientAddFailure(err: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_INGREDIENT_ADD.FAILURE,
    err
  };
}

export function taskInterventionIngredientRemove(credentials: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_INGREDIENT_REMOVE.REQUEST,
    credentials
  };
}

export function taskInterventionIngredientRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_INGREDIENT_REMOVE.SUCCESS,
    data
  };
}

export function taskInterventionIngredientRemoveFailure(err: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_INGREDIENT_REMOVE.FAILURE,
    err
  };
}

export function taskInterventionIngredientUpdate(credentials: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_INGREDIENT_UPDATE.REQUEST,
    credentials
  };
}

export function taskInterventionIngredientUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_INGREDIENT_UPDATE.SUCCESS,
    data
  };
}

export function taskInterventionUpdateFailure(err: object): ActionModel {
  return {
    type: types.TASK_INTERVENTION_INGREDIENT_UPDATE.FAILURE,
    err
  };
}
