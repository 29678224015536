import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mobiscroll from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import i18next from "i18next";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardText,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { setRestaurantLanguage } from "../../config/lang.config";
import { PosInitState, PosState } from "../../models/pos.model";
import { labelRequest } from "../../redux/actions/labels.action";
import {
  addEventRequest,
  eventListRequest,
  eventRequest,
  eventUpdateRequest,
} from "../../redux/actions/pos.action";
import { allRecipeRequest } from "../../redux/actions/recipe.action";
import commonService from "../../services/common.service";
import "../../styles/pos.scss";
import POSRecipeAddCard from "../card-components/pos-recipe-add-card/pos-recipe-add-card";
import LoaderComponent from "../loader/loader";
import PosHeaderComponent from "../navigation/navigation-header/pos-header/pos-header";
import PosLeftComponent from "../navigation/navigation-left/pos-navigation/pos-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";

class PosComponent extends Component<any> {
  state: PosState;
  qtyAll: any = [];
  qList: any = [];
  setBox: boolean = false;
  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.state = PosInitState;
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <PosHeaderComponent staff={"pos"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <PosLeftComponent
                display={"pos"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={8} lg={6}>
              <div className="white-box mb-3">
                <Label className="user-add">{t("common.orders")}</Label>
                {/*<Button
                  color="info"
                  size="sm"
                  style={{
                    float: "right",
                  }}
                  onClick={this.addNewEvent.bind(this)}
                >
                  {t("common.createNewOrder")}
                </Button>*/ }
                <div className="child-box mb-3">
                  {this.renderCalandarView()}
                </div>
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>
        <Modal isOpen={this.state.modal} className="modal-dialog-centered">
          <ModalBody style={{ padding: 0 }}>
            <div>
              <div style={{ margin: 10 }}>
                <Row className="order-status">
                  <Col>
                    <h6>
                      <Label style={{ flex: 1 }} color="info">
                        {t("common.deliveryTo")}:
                      </Label>
                    </h6>
                  </Col>
                  <Col style={{ marginLeft: 50 }}>
                    <Label
                      style={{
                        flex: 1,
                        fontWeight: "bold",
                        color:
                          this.state.eventdetail.status === 0
                            ? "#1A7C08"
                            : this.state.eventdetail.status === 1
                              ? "#000000"
                              : this.state.eventdetail.status === 2
                                ? "#EC080F"
                                : "#FFC300",
                      }}
                    >
                      {this.state.eventdetail.status === 0
                        ? "Open"
                        : this.state.eventdetail.status === 1
                          ? "Completed"
                          : this.state.eventdetail.status === 2
                            ? "Deleted"
                            : this.state.eventdetail.status === 3
                              ? "Started"
                              : ""}
                    </Label>
                  </Col>
                </Row>
                <h6>
                  <Label color="red">{t("common.deliveryTo")}:</Label>
                </h6>
                <Row className="app-row-padding">
                  <Col sm={{ size: "auto", offset: 1 }}>
                    <h5>
                      <Badge style={{ backgroundColor: "#6c757d" }} pill>
                        {!this.state.isFetching
                          ? !!this.state.eventdetail.inventoryOrder
                            ? "Inventory"
                            : "Table"
                          : ""}
                      </Badge>
                    </h5>
                  </Col>
                  <Col sm={{ size: "auto", offset: 1 }}>
                    {!this.state.isFetching ? this.state.eventdetail.table : ""}
                  </Col>
                </Row>
                <h6>
                  <Label color="info">{t("common.deliveringAt")}:</Label>
                </h6>
                <Row className="app-row-padding">
                  <Col sm={{ size: "auto", offset: 1 }}>
                    <h5>
                      <Badge style={{ backgroundColor: "#6c757d" }} pill>
                        {!this.state.isFetching
                          ? !!this.state.eventdetail.courses &&
                            this.state.eventdetail.courses.length > 0 &&
                            !!this.state.eventdetail.courses[0].onCall
                            ? "On Call"
                            : "Delivery At"
                          : ""}
                      </Badge>
                    </h5>
                  </Col>
                  <Col sm={{ size: "auto", offset: 1 }}>
                    {this.state.eventdetail.courses && this.state.eventdetail.courses[0].expectedDeliveryTime
                      ? commonService.dateFormat(
                        new Date(this.state.eventdetail.courses[0].expectedDeliveryTime)
                      )
                      : ""}
                  </Col>
                </Row>
              </div>
              <div
                className="app-row-padding"
                style={{
                  maxHeight: 250,
                  overflowX: "hidden",
                  msOverflowStyle: "none",
                  marginBottom: 10,
                }}
              >
                {this.recipeDetailsBox(this.state.eventdetail)}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              {t("common.cancel")}
            </Button>
            {/*
            <Button
              disabled={!(this.state.eventdetail.status === 0)}
              outline
              color="info"
              onClick={this.toggleNested}
            >
               {t("common.editOrder")} 
          </Button>
          */}
          </ModalFooter>
        </Modal>
      </div >
    );
  }

  public renderCalandarView() {
    return (
      <div>
        {this.addRecipeEvent()}
        <mobiscroll.Eventcalendar
          timeFormat={"H:ii"}
          theme="ios"
          themeVariant="light"
          display="inline"
          calendarHeight={614}
          view={{
            calendar: {
              labels: true,
            },
          }}
          onEventSelect={this.onEventSelect}
          data={this.setDataFormat()}
          showEventCount={true}
          onDayChange={this.onSetDate}
          onPageLoading={this.onPageLoaded}
          onPageChange={this.onPageChange}
        />
      </div>
    );
  }

  public recipeIngredientList(pageContent: any) {
    let border: any = "";
    if (pageContent && pageContent.length > 0) {
      let ITEMS = pageContent.map((res: any, i: number) => {
        if (
          this.state.mealslist[this.state.boxindex] &&
          this.state.mealslist[this.state.boxindex].length > 0
        ) {
          this.state.mealslist[this.state.boxindex].forEach((recipeId: any) => {
            this.state.mealslist[this.state.boxindex].includes(res.uuid)
              ? (border = "#626467")
              : (border = "");
          });
        }
        return (
          <Col
            key={i}
            data-toggle="tooltip"
            data-placement="left"
            title={this.props.t('recipe.selectMealAddRecipe')}
          >
            {res && res.name ? (
              <div
                className="short-recipe"
                style={{
                  backgroundColor: border ? border : res.color,
                  margin: 10,
                  borderRadius: 30,
                  padding: 5,
                  color: commonService.getFontContrast(
                    res.color ? res.color : "#CCCCCC"
                  ),
                  textAlign: "center",
                  borderColor: border ? "#f5504ee6" : "#CCCCCC",
                  borderStyle: "solid",
                  width: 100,
                }}
                onClick={
                  this.state.boxindex >= 0
                    ? this.setCurrentBoxRecipe.bind(this, res)
                    : undefined
                }
              >
                {res.name}
              </div>
            ) : null}
          </Col>
        );
      });
      return ITEMS;
    } else {
      return (
        <React.Fragment>
          <div style={{ width: "100%", textAlign: "center" }}>
            {this.props.t("common.noRecords")}
          </div>
        </React.Fragment>
      );
    }
  }

  public recipeBox(box: any): any {
    let boxList = box.length ? box : Object.entries(box);
    let BOX;
    if (!!boxList) {
      // eslint-disable-next-line
      BOX = boxList.map((pos: any, index: number) => {
        return (
          <div
            key={index}
            style={{
              backgroundColor:
                this.state.boxindex === index ? "#626467" : "#333333",
              borderColor: this.state.boxindex === index ? "#f5504ee6" : "",
              borderRadius: 20,
              color: "#FFFFFF",
              borderStyle: "solid",
              display: "flex",
            }}
          >
            <div
              className="name-style"
              style={{
                float: "left",
                width: "90%",
                padding: 10,
              }}
              onClick={this.setCurrentBox.bind(this, index)}
            >
              {this.recipeBoxName(this.state.mealslist[index], index)}
            </div>

            <div
              className="close-style"
              style={{
                float: "right",
                width: "10%",
                padding: 10,
                height: "10%",
              }}
              onClick={this.removeRecipeBox.bind(this, index)}
            >
              <FontAwesomeIcon
                style={{ color: "#FFFFFF" }}
                icon={faTimesCircle}
                pull="right"
              />
            </div>
          </div>
        );
      });
      return BOX;
    }
  }

  public recipeBoxName(boxItem: any, index: any): any {
    let recipeList = boxItem.length ? boxItem : [];
    let posRecipeIngList = this.props.allrecipes.length
      ? this.props.allrecipes
      : [];
    let qtyData: any = [];
    let ITEMS = recipeList.map((allItem: any, x: number) => {
      // eslint-disable-next-line
      return posRecipeIngList.map((item: any, y: number) => {
        qtyData = this.qtyAll[index];
        if (item.uuid === allItem) {
          return (
            <Row key={x} className="app-row-padding">
              <Col
                sm={{ size: 4, offset: 1 }}
                xs="auto"
                className="short-recipe"
              >
                {item.name}
              </Col>
              <Col sm={{ size: 8, offset: 1 }} xs="auto">
                <Input
                  type="number"
                  name="quantity"
                  autoComplete="quantity"
                  placeholder={this.props.t("common.quantity")}
                  min={1}
                  max={1000}
                  onChange={this.handleQtyChange.bind(this, x)}
                  value={!!qtyData ? qtyData[x] : ""}
                  style={{ marginBottom: 5 }}
                />
              </Col>
            </Row>
          );
        }
      });
    });
    return ITEMS;
  }

  public recipeDetailsBox(boxItem: any): any {
    if (!!boxItem && !!boxItem.courses && !!boxItem.courses[0].meals) {
      let ITEMS = boxItem.courses[0].meals.map((courseitem: any, y: number) => {
        return (
          <Card body inverse className="order-details-box" key={y}>
            {this.mealsItems(courseitem)}
          </Card>
        );
      });
      return ITEMS;
    }
  }

  public mealsItems(meals: any) {
    let posRecipeIngList = this.props.allrecipes.length
      ? this.props.allrecipes
      : [];
    // eslint-disable-next-line
    let ITEMS = posRecipeIngList.map((allItem: any, x: number) => {
      // eslint-disable-next-line
      return meals.components.map((item: any, y: number) => {
        if (allItem.uuid === item.recipe) {
          return (
            <React.Fragment key={y}>
              {item.recipeName} ({item.quantity})<br></br>
            </React.Fragment>
          );
        }
      });
    });
    return ITEMS;
  }

  public handleQtyChange(index: any, event: any): void {
    if (event.target.value > 1000) {
      commonService.toastService(
        this.props.t("toast.valueLTorE1000"),
        "warning"
      );
    } else if (!!event.target.value && parseInt(event.target.value) === 0) {
      commonService.toastService(this.props.t("toast.valueGT0"), "warning");
    } else if (!!event.target.value && event.target.value < 0) {
      commonService.toastService(this.props.t("toast.valueGT0"), "warning");
    } else {
      this.qList[index] = event.target.value;
      this.qtyAll[this.state.boxindex] = this.qList;
      let qtyList = Object.assign(this.state.qtyList);
      qtyList[index] = event.target.value;
      this.setState({ qtyList });
    }
  }

  public handleChange(e: any): void {
    this.setState({ [e.target.name]: e.target.value });
  }

  public mobileOrder = (event: any) => {
    this.setState({
      toMobile: event.target.checked,
    });
  };

  public OrderDetails(meals: any): any {
    if (!!meals && !!meals.order_courses) {
      let ITEMS: [] = meals.order_courses[0].meals[0].meal_recipes.map(
        (item: any, x: number) => {
          return (
            <React.Fragment>
              <Card
                body
                inverse
                style={{
                  backgroundColor: "#333333e0",
                  borderColor: "#333333e0",
                  margin: 10,
                  borderRadius: 20,
                }}
              >
                <CardText>{this.recipeBoxName(item.recipeUuid, x)}</CardText>
              </Card>
            </React.Fragment>
          );
        }
      );
      return ITEMS;
    }
  }

  public cancelNested() {
    this.setState({
      boxItem: [],
      deliveryto: "order",
      deliveringat: "asap",
    });
    this.toggleNested();
    if (!!this.state.eventdetail && !!this.state.eventdetail.courses) {
      this.props.orderDetails({
        restaurantuuid: this.props.match.params.uuid,
        orderuuid: this.state.eventdetail.uuid,
      });
    }
  }

  // Add Order

  public addRecipeEvent() {
    let pageContent = this.generatePageContent();
    return (
      <POSRecipeAddCard
        cancelNested={this.cancelNested.bind(this)}
        state={this.state}
        toggleNested={this.toggleNested}
        RECIPEBOX={this.recipeBox(this.state.mealslist)}
        ALLRECIPE={this.recipeIngredientList(pageContent)}
        saveEvent={this.saveEvent}
        handleDeliveryChange={this.handleDeliveryChange.bind(this)}
        handleDeliveringAtChange={this.handleDeliveringAtChange.bind(this)}
        handleChange={this.handleChange.bind(this)}
        mobileOrder={this.mobileOrder.bind(this)}
        eventdetail={this.state.eventdetail}
        onSetDate={this.onSetDate.bind(this)}
        onSet={this.onSet.bind(this)}
        goBack={this.goBack}
        goHome={this.goHome}
        addRecipeBox={this.addRecipeBox}
      />
    );
  }

  public onSet = (event: any, inst: any) => {
    this.setState({
      date: event.valueText,
    });
  };

  componentDidMount() {
    document.body.className = "light-theme";
    this.getAllList();
    this.props.getLabel({ restaurantuuid: this.props.match.params.uuid });
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this.setState({
        isFetching: newProps.isFetching,
      });
      if (!newProps.isFetching) {
        this.setState({
          recipeList: newProps.allrecipes,
          eventlist: !!newProps.eventlists ? newProps.eventlists : [],
          mealsuuid:
            !!newProps.eventdetails && !!newProps.eventdetails.courses
              ? newProps.eventdetails.courses[0].meals
              : [],
          coureseUuid:
            !!newProps.eventdetails && !!newProps.eventdetails.courses
              ? newProps.eventdetails.courses[0].uuid
              : "",
          labelList: newProps.labels,
        });
      }
      if (!!this.state.isUpdated && !newProps.isFetching) {
        this.getEventLists(this.state.currentDate);
        this.setState({
          isUpdated: false,
        });
      }
      if (newProps.eventdetails.courses && !newProps.isFetching) {
        this.setState({
          eventdetail: newProps.eventdetails,
        });
      }
      // eslint-disable-next-line
      if (
        newProps.addevent.status ||
        (newProps.eventupdate.status &&
          !newProps.isFetching &&
          !!this.state.nestedModal)
      ) {
        this.setState({
          nestedModal: false,
          deliveryto: "order",
          deliveringat: "asap",
        });
      }
      if (
        newProps.eventupdate.status &&
        !newProps.isFetching &&
        !!this.state.modal &&
        !!this.state.nestedModal
      ) {
        this.setState({
          modal: false,
          nestedModal: false,
        });
      }
    }
  }

  // Set Event/Order data

  public setDataFormat() {
    let eventlistData =
      (!!this.state.eventlist && this.state.eventlist.length) > 0
        ? this.state.eventlist
        : [];
    let filterdArray: any = [];
    filterdArray =
      eventlistData.length > 0 &&
      eventlistData.map((item: any, i: number) => {
        let obj = {
          end: new Date(item.end),
          start: new Date(item.start),
          text: item.name,
          type: "MOBILE",
          color: "#ca4747",
          order_uuid: item.orderUuid,
        };
        return obj;
      });
    return filterdArray;
  }

  public getEventLists(day: any) {
    let date = new Date(day);
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.props.eventList({
      restaurantuuid: this.props.match.params.uuid,
      data: {
        from_date: encodeURIComponent(firstDay.toISOString()),
        to_date: encodeURIComponent(lastDay.toISOString()),
      },
    });
  }

  private _setFunctionBindings(): void {
    this.renderCalandarView = this.renderCalandarView.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onEventSelect = this.onEventSelect.bind(this);
    this.toggleNested = this.toggleNested.bind(this);
    this.addRecipeBox = this.addRecipeBox.bind(this);
    this.removeRecipeBox = this.removeRecipeBox.bind(this);
    this.setCurrentBox = this.setCurrentBox.bind(this);
    this.setCurrentBoxRecipe = this.setCurrentBoxRecipe.bind(this);
    this.onSetDate = this.onSetDate.bind(this);
    this.addNewEvent = this.addNewEvent.bind(this);
    this.saveEvent = this.saveEvent.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleQtyChange = this.handleQtyChange.bind(this);
  }

  public handleDeliveryChange(e: any) {
    this.setState({
      deliveryto: e.target.value,
    });
    if (e.target.value === "inventory") {
      this.setState({
        table: "",
        deliveringat: "deliverat",
        date: new Date(),
      });
    }
  }

  public handleDeliveringAtChange(e: any) {
    this.setState({
      deliveringat: e.target.value,
      date: new Date(),
    });
  }

  public getMealName(recipeId: any) {
    let posRecipeIngList = this.props.allrecipes.length
      ? this.props.allrecipes
      : Object.entries(this.props.allrecipes);
    let recipeName: string = "";
    // eslint-disable-next-line
    posRecipeIngList.map((recipe: any, i: number) => {
      if (recipe.uuid === recipeId) {
        recipeName = recipe.name;
      }
    });
    return recipeName;
  }

  public mealItem(mealItem: any, qty: any) {
    let filterdArray: any = [];
    if (!!qty) {
      filterdArray = qty.filter(function (obj: any) {
        return obj !== "";
      });
    }

    let recipeData: any;
    if (!!mealItem && mealItem.length) {
      // eslint-disable-next-line
      recipeData = mealItem.map((recipe: any, i: number) => {
        if (!!filterdArray) {
          if (
            filterdArray.length >= 1 &&
            filterdArray.length === mealItem.length &&
            filterdArray[i] !== "" &&
            filterdArray[i] !== undefined
          ) {
            this.setState({
              validate: true,
            });
            return {
              recipeUuid: recipe,
              quantity: filterdArray[i],
            };
          }
        }
      });
      if (filterdArray.length !== mealItem.length) {
        this.setState({
          validate: false,
        });
        commonService.toastService(
          this.props.t("toast.pleaseAddQuantity"),
          "warning"
        );
      }
    }
    return recipeData;
  }

  public saveEvent(): void {
    let mealsArray: any = [];
    // eslint-disable-next-line
    let filtered = this.state.mealslist.filter(function (obj: any) {
      return obj.length > 0;
    });
    // eslint-disable-next-line
    mealsArray = filtered.map((mealItem: any, i: number) => {
      if (!!mealItem && !!mealItem[0]) {
        let mealData = {
          name: this.getMealName(mealItem[0]),
          orderLines: this.mealItem(mealItem, this.qtyAll[i]),
        };
        return mealData;
      } else {
        return undefined;
      }
    });
    let valid = false;
    mealsArray.forEach((element: any, index: any) => {
      if (!!mealsArray[index]) {
        valid = true;
      } else {
        valid = false;
      }
    });

    if (!!valid) {
      let dt = new Date(this.state.date);
      dt.setHours(dt.getHours() + 1);
      let data = {
        table: this.state.table,
        inventoryOrder: this.state.deliveryto === "inventory" ? true : false,

        courses: [
          {
            deliveryDate: !!this.state.date
              ? this.state.deliveringat === "oncall"
                ? new Date(dt).toISOString()
                : new Date(this.state.date).toISOString()
              : "",
            onCall: this.state.deliveringat === "oncall" ? true : false,
            meals: !!mealsArray[0] ? mealsArray : [],
          },
        ],
      };
      if (!!this.state.eventdetail && !!this.state.eventdetail.courses) {
        let valid = true;
        mealsArray.forEach((meals: any, index: any) => {
          meals.orderLines.forEach((obj: any) => {
            if (obj === undefined || obj === null) {
              valid = false;
            }
          });
        });
        if (!!valid) {
          this.props.eventUpdate({
            restaurantuuid: this.props.match.params.uuid,
            orderuuid: this.state.eventdetail.uuid,
            data: data,
          });
          this.setState({
            modal: false,
          });
        }
      } else {
        let valid = true;
        mealsArray.forEach((meals: any, index: any) => {
          meals.orderLines.forEach((obj: any) => {
            if (obj === undefined || obj === null) {
              valid = false;
            }
          });
        });
        if (!!valid) {
          this.props.addEvent({
            restaurantuuid: this.props.match.params.uuid,
            data: data,
          });
        }
      }
      this.setState({
        isUpdated: true,
        validate: false,
      });
    } else {
      commonService.toastService(
        this.props.t("toast.pleaseAddRecipe"),
        "warning"
      );
    }
  }

  public setStateData() {
    this.setState({
      mealslist: [0],
      boxItem: [],
      eventdetail: [],
      coureseUuid: "",
      mealsuuid: [],
      qtyList: [],
      editOrder: false,
      deliveryto: "order",
      table: "",
      toMobile: false,
      stack: [],
    });
    this.qtyAll = [];
    this.qList = [];
    this.setBox = false;
  }

  public addNewEvent() {
    this.toggleNested();
    this.setStateData();
    this.setState({
      date: new Date(),
    });
  }

  public onSetDate(event: any) {
    let dateNow = commonService.dateTime(event.date);
    if (!!event.date) {
      this.setState({
        currentDate: dateNow,
      });
    }
  }

  public onPageChange = (event: any, inst: any) => {
    let dateNow = commonService.dateTime(event.firstDay);
    this.setState({
      changeDate: new Date(dateNow),
    });
  };

  public onPageLoaded = (event: any, inst: any) => {
    let dateNow = commonService.dateTime(event.firstDay);
    if (!this.state.changeDate) {
      this.setState({
        currentDate: new Date(),
      });
    } else {
      this.setState({
        currentDate: new Date(dateNow),
      });
    }
    this.getEventLists(dateNow);
  };

  public onEventSelect(event: any) {
    if (event.event && event.event.order_uuid) {
      this.props.orderDetails({
        restaurantuuid: this.props.match.params.uuid,
        orderuuid: event.event.order_uuid,
      });
    }
    this.setState({
      event: event.event,
      modal: !this.state.modal,
      date: new Date(this.state.currentDate),
    });
    this.setStateData();
  }

  public getAllList() {
    this.props.recipeAll({
      restaurantuuid: this.props.match.params.uuid,
    });
  }

  public generatePageContent = () => {
    let stack = this.state.stack;
    let label: string;
    if (stack) {
      label = stack[stack.length - 1];
    }
    let pageContent: any[] =
      this.state.labelList.length > 0
        ? this.state.labelList.filter((l: any) => this.checkLabel(label, l))
        : [];
    pageContent = pageContent.concat(
      this.state.recipeList.length > 0
        ? this.state.recipeList.filter((r: any) =>
          this.checkLabel2(label, r, false)
        )
        : []
    );
    if (pageContent.length % 2 !== 0) {
      pageContent.push({ uuid: "", isLabel: true });
    }
    return pageContent;
  };

  public checkLabel = (source: any, target: any, isLabel: boolean = true) => {
    target.isLabel = isLabel;
    if (!source && (!target.parent_labels || !target.parent_labels.length)) {
      return true;
    } else if (source) {
      if (
        target.parent_labels &&
        this.checkParentItem(target.parent_labels, source)
      ) {
        return true;
      }
    }
    return false;
  };

  public checkParentItem = (parent_labels: any, source: any) => {
    return parent_labels.find((item: any) => item === source);
  };

  public checkLabel2 = (source: any, target: any, isLabel: boolean = true) => {
    target.isLabel = isLabel;
    if (!source && (!target.label_uuids || !target.label_uuids.length)) {
      return true;
    } else if (source) {
      if (target.label_uuids && target.label_uuids.indexOf(source) !== -1) {
        return true;
      }
    }
    return false;
  };

  public onSelectRecipe = (recipe: any) => {
    if (recipe.isLabel) {
      let stack = this.state.stack;
      stack.push(recipe.uuid);
      this.setState({ stack });
    } else {
      if (this.props.onSelectRecipe) {
        this.props.onSelectRecipe(recipe);
      }
    }
  };

  public goBack = () => {
    let stack = this.state.stack;
    stack.pop();
    this.setState({ stack });
  };

  public goHome = () => {
    this.setState({ stack: [] });
  };

  public toggle() {
    this.setState((prevState: any) => ({
      modal: !prevState.modal,
    }));
  }

  public toggleNested() {
    if (!!this.state.eventdetail.courses) {
      let box: any = [];
      // eslint-disable-next-line
      if (
        !!this.state.eventdetail.courses &&
        this.state.eventdetail.courses.length > 0
      )
        this.state.eventdetail.courses[0].meals.map((meals: any, x: number) => {
          this.qList = [];
          // eslint-disable-next-line
          this.state.boxItem = [];
          // eslint-disable-next-line
          meals.orderLines.map((recipe: any, y: number) => {
            this.qList[y] = recipe.quantity;
            this.state.boxItem.push(recipe.recipeUuid);
          });
          this.qtyAll[x] = this.qList;
          box[x] = this.state.boxItem;
        });
      this.setState({
        boxItem: box[0],
        mealslist: box,
        editOrder: true,
        deliveryto: !this.state.eventdetail.inventoryOrder
          ? "order"
          : "inventory",
        table: this.state.eventdetail.table,
        deliveringat:
          !!this.state.eventdetail.courses &&
            this.state.eventdetail.courses.length > 0 &&
            !!this.state.eventdetail.courses[0].onCall
            ? "oncall"
            : "deliverat",
      });
      this.setBox = false;
    }
    this.setState((prevState: any) => ({
      nestedModal: !prevState.nestedModal,
      boxindex: 0,
      recipeList: this.props.allrecipes,
      date: this.state.eventdetail.deliveryDate,
      deliveryto: !this.state.eventdetail.inventoryOrder
        ? "order"
        : "inventory",
      deliveringat:
        !!this.state.eventdetail.courses &&
          this.state.eventdetail.courses.length > 0 &&
          !!this.state.eventdetail.courses[0].onCall
          ? "oncall"
          : "deliverat",
      toMobile: true,
    }));
  }

  public addRecipeBox() {
    this.state.mealslist.push([]);
    this.setState({
      mealslist: this.state.mealslist,
    });
    this.setCurrentBox(this.state.mealslist.length - 1);
    this.setBox = false;
  }

  public removeRecipeBox(index: any) {
    this.qtyAll.splice(index, 1);
    this.state.mealslist.splice(index, 1);
    this.state.mealsuuid.splice(index, 1);
    this.setState({
      boxItem: [],
      boxindex: this.state.mealslist.length - 1,
      coureseUuid: this.state.coureseUuid,
      qtyList: [],
    });
    this.setCurrentBox(this.state.mealslist.length - 1);
  }

  public setCurrentBox(index: any) {
    this.setState({
      boxindex: index,
      boxItem:
        this.state.mealslist[index] === 0 ? [] : this.state.mealslist[index],
      editOrder: false,
    });
    this.qList = !!this.qtyAll[index] ? this.qtyAll[index] : [];
    this.setBox = true;
  }

  public setCurrentBoxRecipe(recipe: any) {
    if (recipe.isLabel) {
      let stack = this.state.stack;
      stack.push(recipe.uuid);
      this.setState({ stack });
      return;
    }
    let value: any;
    let filterdArray: any;
    let box = this.state.mealslist;
    let filterQty: any;
    if (this.state.boxItem.includes(recipe.uuid)) {
      value = this.state.qtyList[this.state.boxItem.indexOf(recipe.uuid)];
      filterQty = this.state.qtyList.filter(function (obj: any) {
        return obj !== value;
      });
      this.qList.splice(this.state.boxItem.indexOf(recipe.uuid), 1);
      this.setState({
        qtyList: filterQty,
      });
      filterdArray = this.state.boxItem.filter(function (obj: any) {
        return obj !== recipe.uuid;
      });
    } else {
      this.state.boxItem.push(recipe.uuid);
      filterdArray = this.state.boxItem;
    }

    if (
      !this.setBox &&
      !this.state.editOrder &&
      this.state.deliveryto === "order"
    ) {
      box[this.state.boxindex] = filterdArray;
      this.setState({
        boxindex: this.state.boxindex + 1,
      });
      filterdArray = [];
      this.state.mealslist.push([]);
    } else {
      box[this.state.boxindex] = filterdArray;
    }

    this.setState({
      mealslist: box,
      boxItem: filterdArray,
    });
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.allrecipe.isFetching ||
    state.eventlist.isFetching ||
    state.addevent.isFetching ||
    state.event.isFetching ||
    state.updateevent.isFetching ||
    state.labels.isFetching;
  let failure =
    state.allrecipe.failure ||
    state.eventlist.failure ||
    state.addevent.failure ||
    state.event.failure ||
    state.updateevent.failure ||
    state.labels.failure;
  return {
    isFetching: isFetching,
    failure: failure,
    allrecipes: state.allrecipe.data,
    eventlists: state.eventlist.data,
    addevent: state.addevent.data,
    eventdetails: state.event.data,
    eventupdate: state.updateevent.data,
    labels: state.labels.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    recipeAll: (credentials: any) => {
      dispatch(allRecipeRequest(credentials));
    },
    eventList: (credentials: any) => {
      dispatch(eventListRequest(credentials));
    },
    addEvent: (credentials: any) => {
      dispatch(addEventRequest(credentials));
    },
    orderDetails: (credentials: any) => {
      dispatch(eventRequest(credentials));
    },
    eventUpdate: (credentials: any) => {
      dispatch(eventUpdateRequest(credentials));
    },
    getLabel: (credentials: any) => {
      dispatch(labelRequest(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(PosComponent))
);
