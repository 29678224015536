import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function roomRequest(credentials: object): ActionModel {
  return {
    type: types.ROOM.REQUEST,
    credentials
  };
}

export function roomSuccess(data: object): ActionModel {
  return {
    type: types.ROOM.SUCCESS,
    data
  };
}

export function roomFailure(err: object): ActionModel {
  return {
    type: types.ROOM.FAILURE,
    err
  };
}

export function roomUpdate(credentials: object): ActionModel {
  return {
    type: types.ROOM_UPDATE.REQUEST,
    credentials
  };
}

export function roomUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.ROOM_UPDATE.SUCCESS,
    data
  };
}

export function roomUpdateFailure(err: object): ActionModel {
  return {
    type: types.ROOM_UPDATE.FAILURE,
    err
  };
}

export function roomAdd(credentials: object): ActionModel {
  return {
    type: types.ROOM_ADD.REQUEST,
    credentials
  };
}

export function roomAddSuccess(data?: object): ActionModel {
  return {
    type: types.ROOM_ADD.SUCCESS,
    data
  };
}

export function roomAddFailure(err: object): ActionModel {
  return {
    type: types.ROOM_ADD.FAILURE,
    err
  };
}

export function roomRemove(credentials: object): ActionModel {
  return {
    type: types.ROOM_REMOVE.REQUEST,
    credentials
  };
}

export function roomRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.ROOM_REMOVE.SUCCESS,
    data
  };
}

export function roomRemoveFailure(err: object): ActionModel {
  return {
    type: types.ROOM_REMOVE.FAILURE,
    err
  };
}
