import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import {
    transportAddFailure,
    transportAddSuccess,
    transportFailure,
    transportRemoveFailure,
    transportRemoveSuccess,
    transportSuccess,
    transportUpdateFailure,
    transportUpdateSuccess,
} from '../redux/actions/transport.action';
import * as types from '../redux/actions/types';
import handleResponse from '../services/response.service';
import transport from '../services/transport.service';

const transportCall = (params: any) => transport.transport(RESTAURANT_REQUEST, params);
const updateTransportCall = (params: any) => transport.updateTransport(RESTAURANT_REQUEST, params);
const addTransportCall = (params: any) => transport.addTransport(RESTAURANT_REQUEST, params);
const removeTransportCall = (params: any) => transport.removeTransport(RESTAURANT_REQUEST, params);

function* handleTransportRequest(params: object) {
    try {
        let result;
        result = yield call(transportCall, params);
        yield handleTransport200(result);
    } catch (error) {
        throw error;
    }
}

const handleTransport200 = function* handleTransport200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(transportSuccess(result.data));
    } else {
        yield put(transportFailure(result.data));
        handleResponse(result);
    }
}

function* handleTransportUpdate(params: object) {
    try {
        let result;
        result = yield call(updateTransportCall, params);
        yield handleTransportUpdate200(result);
    } catch (error) {
        throw error;
    }
}

const handleTransportUpdate200 = function* handleTransportUpdate200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(transportUpdateSuccess(result.data));
    } else {
        yield put(transportUpdateFailure(result.data));
    }
    handleResponse(result);
}

function* handleTransportAdd(params: object) {
    try {
        let result;
        result = yield call(addTransportCall, params);
        yield handleTransportAdd200(result);
    } catch (error) {
        throw error;
    }
}

const handleTransportAdd200 = function* handleTransportAdd200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(transportAddSuccess(result.data));
    } else {
        yield put(transportAddFailure(result.data));
    }
    handleResponse(result);
}

function* handleTransportRemove(params: object) {
    try {
        let result;
        result = yield call(removeTransportCall, params);
        yield handleTransportRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handleTransportRemove200 = function* handleTransportRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(transportRemoveSuccess(result.data));
    } else {
        yield put(transportRemoveFailure(result.data));
    }
    handleResponse(result);
}
export default function* transportSaga() {
    yield takeLatest(types.TRANSPORT.REQUEST, handleTransportRequest);
    yield takeLatest(types.TRANSPORT_UPDATE.REQUEST, handleTransportUpdate);
    yield takeLatest(types.TRANSPORT_ADD.REQUEST, handleTransportAdd);
    yield takeLatest(types.TRANSPORT_REMOVE.REQUEST, handleTransportRemove);
}
