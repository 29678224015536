import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowsAltV,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import MaskedInput from "react-maskedinput";
import {
  Col,
  Collapse,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { GRAY, WHITE } from "../../../constant/constant";
import {
  RecipeTaskInterventionCardProps,
  taskEditType,
} from "../../../models/task.model";
import commonService from "../../../services/common.service";
import {
  CardItemCardComponent,
  CardItemContainer,
} from "../../card-components/card-item";
import CardMedia from "../../card-media/card-media";
import { withCardItemSelectionState } from "../../cardItemSelectionManager";
import ConditionalLinkArea from "../../conditionalLinkArea/conditionalLinkArea";
import InlineEditInput from "../../inline-edit-input/inline-edit-input";
import WarningComment, {
  getMandatoryWarning,
} from "../../warning-comment/warning-comment";
import RecipeTasksInterventionStepCard from "./recipe-tasks-intervention-steps-card";

function RecipeTasksInterventionCard(props: RecipeTaskInterventionCardProps) {
  const [interventionDescription, setInterventionDescription] =
    useState<string>("");
  const [warnings, setWarnings] = useState<any>({});

  const [interventionTime, setInterventionTime] = useState<string>("");
  const [interventionImage, setInterventionImage] = useState<string>("");
  const [interventionThumbnail, setInterventionThumbnail] =
    useState<string>("");
  const [isStepOpen, setStepOpen] = useState<boolean>(false);
  const [printLabel, setPrintLabel] = useState<boolean>(false);
  let isItCardEditInprogress: any = useRef(false);

  const {
    cardItemData,
    cardItemId,
    cardItemSelectionData,
    getUuidFromMediaManager,
    handleTaskInterventionDelete,
    handleTaskInterventionSave,
    handleCardSelection,
    isNewCardItem,
    lastIntervention,
    onCancelEdit,
    onContentEditChange,
    onItemModify,
    parentCardItemSelectionData,
    providedDraggable,
    restLang,
  } = props;
  const { t, i18n } = useTranslation();

  const isEdit: boolean =
    cardItemSelectionData?.selectionState?.isBeingEdited || false;
  let isCardOpen: boolean =
    cardItemSelectionData?.selectionState?.isItemSelected || isEdit;
  if (cardItemSelectionData?.selectionState?.selectedItem?.length > 2) {
    isCardOpen =
      cardItemSelectionData.selectionState.selectedItem[1].id ===
      cardItemData.uuid;
  }
  useEffect(() => {
    if (cardItemSelectionData?.selectionState?.selectedItem?.length > 1) {
      isCardOpen =
        cardItemSelectionData.selectionState.selectedItem[1].id ===
        cardItemData.uuid;
    }
  }, [cardItemSelectionData.selectionState.selectedItem]);

  useEffect(() => {
    if (isItCardEditInprogress.current) return;
    loadCardItemData();
    isItCardEditInprogress.current = true; //Need to update the location of this code
  }, [cardItemData]);

  useEffect(() => {
    applyWarning();
  }, [interventionDescription, isEdit]);

  const applyWarning = () => {
    const warnings: any = {};
    const descWarning: any = getMandatoryWarning(interventionDescription || "");
    if (descWarning) warnings.description = descWarning;
    setWarnings(warnings);
  };

  const clearImage = () => {
    setInterventionImage("");
    setInterventionThumbnail("");
  };

  const handleCancel = () => {
    isItCardEditInprogress.current = false;
    cardItemSelectionData.deselectCardItem();
    loadCardItemData();
    onCancelEdit();
  };

  const handleDelete = () => {
    isItCardEditInprogress.current = false;
    handleTaskInterventionDelete(cardItemId);
  };

  const handleEdit = () => {
    onItemModify(cardItemData.uuid, {
      action: "edit",
      area: "intervention",
      parentTaskId: parentCardItemSelectionData?.cardItem?.id || "",
    });
    cardItemSelectionData.editCardItem();
  };

  const handleSave = () => {
    if (Object.keys(warnings).length > 0) return;
    const interventionTimeMs: number =
      commonService.getTimeInMs(interventionTime);
    const intervention_position: number = cardItemData?.intervention_position
      ? cardItemData?.intervention_position
      : lastIntervention?.uuid
      ? 2
      : 1;
    const formData: any = {
      locales: {
        [restLang]: {
          description: interventionDescription,
        },
      },
      image_uuid: interventionImage,
      ingredients: cardItemData?.ingredients || [],
      intervention_position: intervention_position,
      print_label: printLabel,
      prepared_items: cardItemData?.prepared_items || [],
    };
    if (cardItemData.intervention_position !== 3) {
      formData["intervention_time"] = isNaN(interventionTimeMs)
        ? 0
        : interventionTimeMs;
    }
    if (formData.intervention_position === 2) {
      const intervention_parent_uuid: string =
        cardItemData?.intervention_parent_uuid
          ? cardItemData.intervention_parent_uuid
          : lastIntervention?.uuid
          ? lastIntervention?.uuid
          : "";
      formData["intervention_parent"] = intervention_parent_uuid;
    }

    isItCardEditInprogress.current = false;
    handleTaskInterventionSave(formData, cardItemId);
  };

  const handleSelect = () => {
    handleCardSelection(
      { interventionId: cardItemData.uuid },
      taskEditType.INTERVENTION
    );
    cardItemSelectionData.selectCardItem();
  };

  const handleStepSave = (formData: any) => {
    handleTaskInterventionSave(formData, cardItemId);
  };

  const loadCardItemData = () => {
    const locales: any = commonService.applyLocalization(
      "restaurant",
      "description",
      cardItemData?.locales
    );

    setInterventionDescription(locales["description"]);
    setInterventionTime(
      commonService.getMsToDate(cardItemData.intervention_time)
    );
    setInterventionImage(cardItemData?.image_uuid || "");
    setInterventionThumbnail(cardItemData?.thumbnail_url);
  };

  const onShowMediaManager = async (isEdit: boolean) => {
    if (!isEdit) return;
    let mediaItem = await getUuidFromMediaManager(interventionImage, ["image"]);
    if (mediaItem) {
      setInterventionImage(mediaItem.mediaUuid);
    }
  };

  const renderSteps = () => {
    return (
      <div>
        <Row className="app-row-padding">
          <Col>
            <h5
              onClick={() => toggleStepsCollapse()}
              className="cursor-pointer"
            >
              {t("common.steps")}
            </h5>
          </Col>
        </Row>
        <Collapse isOpen={isStepOpen} className="px-2">
          {cardItemData?.task_step?.map((step: any) => (
            <RecipeTasksInterventionStepCard
              key={step.uuid}
              cardItemData={step}
              cardItemId={"step" + step.uuid}
              getUuidFromMediaManager={getUuidFromMediaManager}
              handleCardSelection={handleCardSelection}
              handleTaskStepSave={handleStepSave}
              isNewCardItem={false}
              onCancelEdit={onCancelEdit}
              onItemModify={onItemModify}
              parentCardItemSelectionData={cardItemSelectionData}
              restLang={restLang}
            />
          ))}

          <div>
            <RecipeTasksInterventionStepCard
              cardItemData={{ uuid: "newInterventionStep" }}
              cardItemId={"newInterventionStep"}
              key={"newInterventionStep"}
              getUuidFromMediaManager={getUuidFromMediaManager}
              handleCardSelection={handleCardSelection}
              handleTaskStepSave={handleStepSave}
              isNewCardItem={true}
              onCancelEdit={onCancelEdit}
              onItemModify={onItemModify}
              parentCardItemSelectionData={cardItemSelectionData}
              restLang={restLang}
            />
          </div>
        </Collapse>
      </div>
    );
  };

  const toggleStepsCollapse = () => {
    setStepOpen(!isStepOpen);
  };

  const isTimeAvailable: boolean =
    (!isNewCardItem || (isNewCardItem && isEdit)) &&
    cardItemData.intervention_position !== 3;
  return (
    <CardItemContainer className={""}>
      <>
        {providedDraggable && !isEdit && (
          <div
            {...providedDraggable.dragHandleProps}
            className="pointer-cursor"
          ></div>
        )}
        {isTimeAvailable && (
          <div className={"row h-100"}>
            <div className={"col my-auto"} style={{ textAlign: "right" }}>
              {t("intervention.toBeDoneAfter")} :
            </div>
            <ConditionalLinkArea
              activeLink={isEdit}
              preventDefault={false}
              stopPropagation={true}
              className={
                "intervention-type   col my-auto card-component timing mb-3" +
                cardItemSelectionData?.className
              }
              style={{ minWidth: "9em" }}
            >
              <span className=" ">
                <InlineEditInput
                  name={"timeAfter"}
                  value={interventionTime}
                  editElement={MaskedInput}
                  edit={isEdit}
                  style={{
                    display: "block",
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  styleEdit={{ width: "8em" }}
                  propsEdit={{
                    key: "timeAfter" + cardItemId,
                    type: "text",
                    className: "masked-input",
                    mask: "11d 11:11:11",
                  }}
                  // onChange={(name: string, val: any) => setInterventionTime(val)}
                  readOnlyContentCallback={commonService.getDateCut}
                  conversionCallback={(e: any) => {
                    setInterventionTime(e);
                  }}
                />
              </span>
            </ConditionalLinkArea>
            <div className={"col my-auto"} style={{ verticalAlign: "middle" }}>
              <WarningComment data={null} />
              <WarningComment data={null} />
            </div>
          </div>
        )}
        <CardItemCardComponent
          cardItemSelectionData={cardItemSelectionData}
          className={
            "bf-recipe-ing-task-card  ingredient-type ingredient assigned"
          }
          cancelCallback={handleCancel}
          deleteCallback={handleDelete}
          editCallback={handleEdit}
          isNewCardItem={isNewCardItem}
          parentCardItemSelectionData={parentCardItemSelectionData}
          readOnly={false}
          selectCallback={handleSelect}
          saveCallback={handleSave}
          preventDefault={false}
          stopPropagation={true}
        >
          <div className="d-flex justify-content-between  bf-title">
            <WarningComment
              containerClassName={"d-block w-100 mb-2 mr-2"}
              // itemWrapperClass={"d-block "}
              data={warnings?.description || null}
              displayType={"bottom"}
            >
              <InlineEditInput
                id={"interventionDescription_" + cardItemId}
                name={"interventionDescription_" + cardItemId}
                value={interventionDescription}
                editElement={Input}
                edit={isEdit}
                style={{ display: "block" }}
                styleEdit={{ minHeight: 100, width: "100%" }}
                propsEdit={{
                  key: "interventionDescription_" + cardItemId,
                  type: "textarea",
                  placeholder: "What needs to be done?",
                }}
                styleContainer={{ width: "100%" }}
                onChange={(name: string, val: any) =>
                  setInterventionDescription(val)
                }
              />
            </WarningComment>
          </div>
          {isCardOpen && (
            <div>
              <CardMedia
                clearImage={() => clearImage()}
                mediaUuid={interventionImage}
                onClick={() => {
                  onShowMediaManager(isEdit);
                }}
                activeLink={isEdit}
                entityType="media"
                thumbnail={interventionThumbnail}
              />

              <div className="px-4 mt-3">
                <Label
                  className="mb-2  pointer-cursor"
                  onClick={() => setPrintLabel(!printLabel)}
                >
                  <Input type={"checkbox"} checked={printLabel} />
                  {t("common.printLabel")}
                </Label>
                <div>{renderSteps()}</div>
              </div>
              {providedDraggable !== null && isEdit ? (
                <div
                  {...providedDraggable.dragHandleProps}
                  style={{
                    display: "block",
                    right: "15px",
                    width: "32px",
                    height: "32px",
                    textAlign: "center",
                    zIndex: 0,
                    borderRadius: "100%",
                    position: "absolute",
                    backgroundColor: GRAY,
                    top: "0",
                    bottom: 0,
                    margin: "auto",
                  }}
                  className="pointer-cursor"
                >
                  <FontAwesomeIcon
                    style={{
                      color: WHITE,
                      fontSize: 20,
                      margin: "6px",
                    }}
                    icon={faArrowsAltV}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </CardItemCardComponent>
      </>
    </CardItemContainer>
  );
}

// export default RecipeTasksInterventionCard;

export default withCardItemSelectionState(
  "Intervention",
  2
)(RecipeTasksInterventionCard);
