import "../../../styles/storage.scss";

import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function StorageRackAddCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <Modal isOpen={props.state.addstoragerackmodal}>
      <ModalHeader toggle={props.addStorageRackToggle} className="modal-header">
        {t("rack.createStorageRack")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstname">{t("rack.addRack")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name="storagerackname"
              autoComplete="storagerackname"
              placeholder={t("rack.enterRackName")}
              value={props.state.storagerackname}
              onChange={props.handleChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.addStorageRackToggle}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={props.saveStorageRackAdd}
          disabled={!props.state.storagerackname || !!props.state.isFetching}
        >
          {t("common.save")}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
