import { call, put, takeLatest } from 'redux-saga/effects';
import { COMPONENT_REQUEST } from '../config/api.config';
import {
    componentFailure,
    componentSuccess
} from '../redux/actions/component.action';
import * as types from '../redux/actions/types';
import handleResponse from '../services/response.service';
import component from '../services/component.service';

const componentCall = (params: any) => component.componentList(COMPONENT_REQUEST);

function* handleComponentRequest(params: object) {
    try {
        let result;
        result = yield call(componentCall, params);
        yield handlRight200(result);
    } catch (error) {
        throw error;
    }
}

const handlRight200 = function* handlRight200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(componentSuccess(result.data));
    } else {
        yield put(componentFailure(result.data));
        handleResponse(result);
    }
}

export default function* componentSaga() {
    yield takeLatest(types.COMPONENT.REQUEST, handleComponentRequest);
}