import { Component } from "react";
import { OpenConfirmModal } from "../components/card-components/confirm-card/confirm-modal";

export const RecipeEditHelper = {
  updateRecipe: (
    editedItem: any,
    body: any,
    updateRecipe: Function,
    component: Component
  ): void => {
    RecipeEditHelper.checkNewRecipeTrigger(editedItem, body)
      ? RecipeEditHelper.handleNewRecipeTrigger(body, updateRecipe, component)
      : updateRecipe(body);
  },

  checkNewRecipeTrigger: (editedItem: any, body: any): boolean => {
    return (
      (body.data?.output_quantity &&
        editedItem.output_quantity !== body.data.output_quantity) ||
      (body.data?.output_unit_uuid &&
        editedItem.output_unit_uuid !== body.data.output_unit_uuid) ||
      (body.data?.product_barcode &&
        editedItem.product_barcode !== body.data.product_barcode)
    );
  },

  handleNewRecipeTrigger: (
    body: any,
    updateRecipe: Function,
    component: Component
  ): void => {
    OpenConfirmModal()
      .then((accepted) => {
        body?.credentials?.data
          ? (body.credentials.data.new_recipe_trigger = true)
          : (body.data.new_recipe_trigger = true);
      })
      .finally(() => {
        updateRecipe(body);
        component.setState({ isUpdated: true });
      });
  },

  redirectNewRecipe: (
    responseStatus: any,
    recipeUuid: string,
    history: any
  ): void => {
    if (
      responseStatus?.recipeUuid &&
      responseStatus?.recipeUuid !== recipeUuid
    ) {
      const path: string = history.location.pathname.replace(
        /\/([a-f\d-]+)\/details/,
        `/${responseStatus.recipeUuid}/details`
      );
      history.replace(path);
    }
  },

  isUnitDisabled: (
    openModal: boolean,
    recipeUuid: string,
    units: any
  ): boolean => {
    if (!openModal) return false;

    return units?.some((unit: any) => {
      const hasRecipeToConversion = unit.to_conversions?.some(
        (conversion: any) => conversion?.recipe_uuid === recipeUuid
      );
      const hasRecipeFromConversion = unit.from_conversions?.some(
        (conversion: any) => conversion?.recipe_uuid === recipeUuid
      );
      return hasRecipeToConversion || hasRecipeFromConversion;
    });
  },
};
