import { call, put, takeLatest } from "redux-saga/effects";

import { GLOBAL_REQUEST, RESTAURANT_REQUEST } from "../config/api.config";
import {
  languageUpdateSuccess,
  languageUpdateFailure,
  languageFailure,
  languageSuccess,
} from "../redux/actions/language.action";
import * as types from "../redux/actions/types";
import language from "../services/language.service";
import handleResponse from "../services/response.service";

const languageCall = (params: object) =>
  language.language(GLOBAL_REQUEST, params);

const updateLanguageCall = (params: any) =>
  language.updateLanguage(RESTAURANT_REQUEST, params);

function* handleLanguageRequest(params: object) {
  try {
    let result;
    result = yield call(languageCall, params);
    yield handleLanguage200(result);
  } catch (error) {
    throw error;
  }
}

const handleLanguage200 = function* handleLanguage200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(languageSuccess(result.data));
  } else {
    yield put(languageFailure(result.data));
    handleResponse(result);
  }
};

function* handleLanguageUpdate(params: object) {
  try {
    let result;
    result = yield call(updateLanguageCall, params);
    yield handleLanguageUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleLanguageUpdate200 = function* handleLanguageUpdate200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(languageUpdateSuccess(result.data));
  } else {
    yield put(languageUpdateFailure(result.data));
  }
  handleResponse(result);
};

export default function* languageSaga() {
  yield takeLatest(types.LANGUAGE.REQUEST, handleLanguageRequest);
  yield takeLatest(types.LANGUAGE_UPDATE.REQUEST, handleLanguageUpdate);
}
