import React, { Component } from "react";
import { Button, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { create } from "apisauce";
import { Link } from "react-router-dom";

import ModalFactory from "react-modal-promise";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

import { ACCESS_TOKEN } from "../../constant/constant";
import { BASE_URL } from "../../config/app.config";
import commonService from "../../services/common.service";
import { OpenDeleteModal } from "../../components/card-components/delete-card/delete-modal";
import LoaderComponent from "../../components/loader/loader";
import NavigationRightComponent from "../../components/navigation/navigation-right/navigation-right";

import ChannelsItemListCard from "../../components/channels/ChannelsItemListCard";
import RestaurantHeaderComponent from "../../components/navigation/navigation-header/restaurant-header/restaurant-header";
import RestaurantLeftNavComponent from "../../components/navigation/navigation-left/restaurant-navigation/restaurant-left-navigation";
import { restaurantRequest } from "../../redux/actions/restaurant.action";

import { ChannelsInitState, ChannelsState } from "../../models/channels.model";

import {
  channelsDetails,
  channelsAddRestaurants,
} from "../../redux/actions/channels.action";

import WarningComment from "../../components/warning-comment/warning-comment";
import { withCardItemSelectionStateManager } from "../../components/cardItemSelectionManager";

const api = create({
  baseURL: BASE_URL,
});
class ChannelsDetailsPage extends Component<any> {
  changedItems: any = {};
  editDataTask: any = [];
  expandedTasks: Set<any> = new Set();
  state: ChannelsState;
  pageText: Object = {
    addButtonTitle: "",
    pageTitle: "Ingredient class details",
  };
  constructor(props: any) {
    super(props);
    this.changedItems = {
      allContent: new Set(),
      inlineContent: new Set(),
    };
    this.state = ChannelsInitState;
    api.setHeaders({
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Cache-Control": "no-cache",
      Locale: localStorage.getItem("i18nextLng") || "en-gb",
      "content-type": "application/json",
    });
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.getRestaurant();
    this.props.getChannelDetails({
      uuid: this.props.match.params.itemUuid,
    });
    this.updateRestaurantList(this.props.restaurant);
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.ChannelDetails !== this.props.ChannelDetails) {
      this.setState({
        item: this.props.ChannelDetails,
        channelRestaurants: this.props.ChannelDetails?.restaurants,
      });
      this.updateRestaurantList(this.props.restaurant);
    }
    if (
      prevState.channelRestaurants.length !==
      this.state.channelRestaurants.length
    ) {
      this.updateRestaurantList(this.props.restaurant);
    }
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  public updateRestaurantList = (allRestaurants: any) => {
    let restaurantList: any =
      !!allRestaurants &&
      allRestaurants.length > 0 &&
      allRestaurants
        ?.filter((item: any) => !this.state.channelRestaurants.includes(item))
        .map((list: any) => {
          return {
            value: list.uuid,
            label: commonService.applyLocalization(
              "restaurant",
              "name",
              list.locales
            )["name"],
          };
        });

    let restaurantListNew: any =
      !!allRestaurants &&
      allRestaurants.length > 0 &&
      allRestaurants
        ?.filter(
          (item: any) => !this.state.channelRestaurants.includes(item.uuid)
        )
        .map((list: any) => {
          return {
            value: list.uuid,
            label: commonService.applyLocalization(
              "restaurant",
              "name",
              list.locales
            )["name"],
          };
        });

    this.setState({
      restaurantArray: restaurantListNew,
      restaurant: restaurantList,
    });
  };

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    let update: any = {};
    if (nextProps.isFetching !== prevState.isFetching) {
      update.isFetching = nextProps.isFetching;
    }
    if (!nextProps.isFetching && !!prevState.isUpdated) {
      update.isUpdated = false;
      update.fetchData = true;
    }
    return update;
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <ModalFactory />
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <RestaurantHeaderComponent />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <RestaurantLeftNavComponent display="channels" />
            </Col>
            <Col xl={8} lg={6}>
              <p>{this.state.item?.name}</p>
              <div className="dim-box mb-3">
                <Row className="app-row-padding">
                  <Col xs={12}>
                    <h4>
                      {t("restaurant.restaurants")}
                      <WarningComment data={null} />
                    </h4>
                    {this.renderItemsBlock()}
                  </Col>
                </Row>
                <div className="my-3 text-right">
                  <Button
                    color="info"
                    onClick={this.handleDataItemSave}
                    disabled={
                      this.state.isFetching ||
                      //   this.state.isSaveButtonDisabled ||
                      this.props.cardItemSelectionData.selectionState.mode ===
                        "edit"
                    }
                  >
                    {t("common.save")}
                  </Button>
                </div>
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }

  public getPageTitle = () => {
    return {
      addButtonTitle: this.props.t(""),
      pageTitle: this.props.t(""),
    };
  };

  public fetchData = () => {
    this.setState({
      fetchData: true,
    });
  };

  public handleDataItemSave = () => {
    this.props.AddRestaurantsToChannels({
      uuid: this.props.match.params.itemUuid,
      data: {
        restaurants: this.state.channelRestaurants,
      },
    });
  };

  public handleItemAddSubmit = (data: any, cardId: any, index: any) => {
    this.setState({ isSaveButtonDisabled: false });
    let dataItems: any = [];
    this.state.channelRestaurants &&
      this.state.channelRestaurants.length > 0 &&
      this.state.channelRestaurants.map((item: any) => {
        dataItems.push(item);
      });
    if (cardId === "newCard") {
      dataItems.push(data);
    } else {
      dataItems[index] = data;
    }

    let uniqueArray: any = dataItems.filter(
      (item: any, pos: any) => dataItems.indexOf(item) == pos
    );

    this.props.cardItemSelectionData.deselectCardItem();
    this.setState({
      channelRestaurants: uniqueArray,
      isUpdated: true,
    });
  };
  public handleItemRemove = (indx: any) => {
    OpenDeleteModal().then(() => {
      let items = [...this.state.channelRestaurants];
      this.props.cardItemSelectionData.deselectCardItem();
      items.splice(indx, 1);
      this.setState({
        channelRestaurants: items,
        isSaveButtonDisabled: false,
        isUpdated: true,
      });
    });
  };

  public filterRestaurants = () => {
    return (
      this.state.restaurant.length > 0 &&
      this.state.restaurant?.filter(
        (choice: any) => !this.state.channelRestaurants.includes(choice.value)
      )
    );
  };

  public renderItemsBlock = () => {
    return (
      <div>
        {!!this.state.channelRestaurants &&
          this.state.channelRestaurants.length > 0 &&
          this.state.channelRestaurants.map((card: any, indx: any) => {
            let itemFiltered: any =
              !!this.props.restaurant &&
              this.props.restaurant.length > 0 &&
              this.props.restaurant.find((rest: any) => rest.uuid === card);
            return (
              <ChannelsItemListCard
                indx={indx}
                key={itemFiltered.uuid}
                allRecipes={this.props.allRecipes}
                cardItemData={itemFiltered}
                cardItemId={itemFiltered.uuid}
                restaurantOptions={this.state.restaurantArray}
                restaurantCheckList={this.state.restaurant}
                isNewCardItem={false}
                onDeleteCard={this.handleItemRemove}
                onSaveCard={this.handleItemAddSubmit}
                parentCardItemSelectionData={this.props.cardItemSelectionData}
              />
            );
          })}
        <ChannelsItemListCard
          key={"newCard"}
          cardItemData={{ type: "newCard" }}
          cardItemId={"newCard"}
          restaurantOptions={this.state.restaurantArray}
          restaurantCheckList={this.state.restaurant}
          isNewCardItem={true}
          onDeleteCard={this.handleItemRemove}
          onSaveCard={this.handleItemAddSubmit}
          parentCardItemSelectionData={this.props.cardItemSelectionData}
        />
      </div>
    );
  };
}

const mapStateToProps: any = (state: any) => {
  return {
    isFetching:
      state.ChannelsRestaurants.isFetching || state.restaurant.isFetching,
    failure: state.ChannelsRestaurants.failure || state.restaurant.failure,
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    restaurant: state.restaurant.data,
    ChannelDetails: state.ChannelDetails.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getRestaurant: () => {
      dispatch(restaurantRequest());
    },
    getChannelDetails: (credentials: any) => {
      dispatch(channelsDetails(credentials));
    },
    AddRestaurantsToChannels: (credentials: any) => {
      dispatch(channelsAddRestaurants(credentials));
    },
  };
};
const enhance = compose(
  withCardItemSelectionStateManager("Task"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);

export default withTranslation()(enhance(ChannelsDetailsPage));
