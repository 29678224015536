import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function profileRequest(): ActionModel {
    return {
        type: types.PROFILE.REQUEST
    };
}

export function profileSuccess(data?: object): ActionModel {
    return {
        type: types.PROFILE.SUCCESS,
        data
    };
}

export function profileFailure(err: object): ActionModel {
    return {
        type: types.PROFILE.FAILURE,
        err
    };
}

export function profileUpdate(credentials: object): ActionModel {
    return {
        type: types.PROFILE_UPDATE.REQUEST,
        credentials
    };
}

export function profileUpdateSuccess(data?: object): ActionModel {
    return {
        type: types.PROFILE_UPDATE.SUCCESS,
        data
    };
}

export function profileUpdateFailure(err: object): ActionModel {
    return {
        type: types.PROFILE_UPDATE.FAILURE,
        err
    };
}