import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function taskStepRequest(credentials: object): ActionModel {
    return {
      type: types.TASK_STEP_ADD.REQUEST,
      credentials
    };
  }
  
  export function taskStepAddSuccess(data?: object): ActionModel {
    return {
      type: types.TASK_STEP_ADD.SUCCESS,
      data
    };
  }
  
  export function taskStepAddFailure(err: object): ActionModel {
    return {
      type: types.TASK_STEP_ADD.FAILURE,
      err
    };
  }

  export function taskStepUpdate(credentials: object): ActionModel {
    return {
      type: types.TASK_STEP_UPDATE.REQUEST,
      credentials
    };
  }
  
  export function taskStepUpdateSuccess(data?: object): ActionModel {
    return {
      type: types.TASK_STEP_UPDATE.SUCCESS,
      data
    };
  }
  
  export function taskStepUpdateFailure(err: object): ActionModel {
    return {
      type: types.TASK_STEP_UPDATE.FAILURE,
      err
    };
  }

  export function taskStepRemove(credentials: object): ActionModel {
    return {
      type: types.TASK_STEP_REMOVE.REQUEST,
      credentials
    };
  }
  
  export function taskStepRemoveSuccess(data?: object): ActionModel {
    return {
      type: types.TASK_STEP_REMOVE.SUCCESS,
      data
    };
  }
  
  export function taskStepRemoveFailure(err: object): ActionModel {
    return {
      type: types.TASK_STEP_REMOVE.FAILURE,
      err
    };
  }
  