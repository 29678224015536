import "../../../styles/role-rights.scss";
import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GRAY } from "../../../constant/constant";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const INVENTORY_ACCESS_RESTRICTED = 0;
const INVENTORY_ACCESS_LIMITED = 1;
const INVENTORY_ACCESS_FULL = 2;

export default function SupplierEditCard(props: any) {
  const { restaurantInventoryAccess, restaurantAllowPartialDelivery } =
    props.state;
  const { t, i18n } = useTranslation();
  const [allowInventoryAccess, setAllowInventoryAccess] = useState<boolean>();
  const [allowPartialDelivery, setAllowpartialDelivery] = useState<boolean>(
    restaurantAllowPartialDelivery
  );

  const [inventoryAccess, setInventoryAccess] = useState<Number>();
  const [isSmarttoniSupplier, setIsSmarttoniSupplier] =
    useState<boolean>(false);
  useEffect(() => {
    setAllowpartialDelivery(restaurantAllowPartialDelivery);
  }, [restaurantAllowPartialDelivery]);
  useEffect(() => {
    if (
      !isEmpty(props.state.restaurant) &&
      props.state.restaurant?.value !== ""
    ) {
      setIsSmarttoniSupplier(true);
    } else {
      setIsSmarttoniSupplier(false);
    }
  }, [props.state.restaurant]);

  useEffect(() => {
    setAllowInventoryAccess(
      restaurantInventoryAccess !== INVENTORY_ACCESS_RESTRICTED
    );
    setInventoryAccess(restaurantInventoryAccess || INVENTORY_ACCESS_LIMITED);
  }, [restaurantInventoryAccess]);

  const handleAllowAccess = () => {
    setAllowInventoryAccess(!allowInventoryAccess);
  };

  const handleAllowPartialDelivery = () => {
    setAllowpartialDelivery(!allowPartialDelivery);
  };

  useEffect(() => {
    setInventoryAccess(INVENTORY_ACCESS_LIMITED);
  }, [allowInventoryAccess]);

  const handleInventoryAccess = (data: BaseSyntheticEvent) => {
    setInventoryAccess(data.target.value);
  };

  const saveEdit = () => {
    props.state.restaurantInventoryAccess = allowInventoryAccess
      ? inventoryAccess
      : INVENTORY_ACCESS_RESTRICTED;
    props.state.restaurantAllowPartialDelivery = allowPartialDelivery;
    props.saveEdit();
  };

  return (
    <Modal isOpen={props.state.editmodal}>
      <ModalHeader toggle={props.toggle} className="modal-header">
        {t("supplier.editSupplier")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label style={{ marginTop: 5 }} for="restaurant">
              {t("supplier.OptionallySelectSmarttoniHostedSupplier")}
            </Label>
            <Select
              name="restaurant"
              value={props.state.restaurant}
              onChange={props.handleSelectChange}
              options={props.restaurantOptions}
              maxMenuHeight={200}
              placeholder={t("common.select")}
            />
            {props.state.restaurant?.value && (
              <>
                <FormGroup style={{ marginTop: 10, marginBottom: 5 }}>
                  <CustomInput
                    id={"allowPartialDelivery"}
                    type="checkbox"
                    name={"allowPartialdelivery"}
                    checked={allowPartialDelivery}
                    onChange={handleAllowPartialDelivery}
                    label={t("supplier.SupplierAddCard.allowPartialDelivery")}
                  />
                </FormGroup>
                <FormGroup style={{ marginTop: 10, marginBottom: 5 }}>
                  <CustomInput
                    id={"allowInventoryAccess"}
                    type="checkbox"
                    name={"allowInventoryAccess"}
                    checked={allowInventoryAccess}
                    onChange={handleAllowAccess}
                    label={t("supplier.SupplierAddCard.allowInventoryAccess")}
                  />
                </FormGroup>
                {allowInventoryAccess && (
                  <FormGroup style={{ marginTop: 10, marginBottom: 5 }}>
                    <Row className="app-row-padding pointer-cursor">
                      <Col>
                        <CustomInput
                          id={"restaurantInventoryAccessLimited"}
                          type="radio"
                          name={"restaurantInventoryAccess"}
                          value={INVENTORY_ACCESS_LIMITED}
                          defaultChecked={
                            inventoryAccess === INVENTORY_ACCESS_LIMITED
                              ? true
                              : false
                          }
                          onChange={handleInventoryAccess}
                          label={t("supplier.SupplierAddCard.limitedAccess")}
                        />
                      </Col>
                      <Col>
                        <CustomInput
                          id={"restaurantInventoryAccessFull"}
                          type="radio"
                          name={"restaurantInventoryAccess"}
                          value={INVENTORY_ACCESS_FULL}
                          defaultChecked={
                            inventoryAccess === INVENTORY_ACCESS_FULL
                              ? true
                              : false
                          }
                          onChange={handleInventoryAccess}
                          label={t("supplier.SupplierAddCard.fullAccess")}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                )}
              </>
            )}
            <Label style={{ marginTop: 5 }} for="name">
              {t("supplier.SupplierAddCard.supplierName")}
            </Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name="name"
              autoComplete="name"
              placeholder={t("supplier.SupplierAddCard.enterSupplierName")}
              value={props.state.name}
              onChange={props.handleChange}
              disabled={isSmarttoniSupplier}
            />
            <Label style={{ marginTop: 5 }} for="email">
              {t("supplier.SupplierAddCard.supplierEmail")}
            </Label>
            <Input
              type="text"
              required
              name="email"
              autoComplete="name"
              placeholder={t("supplier.SupplierAddCard.enterSupplierEmail")}
              value={props.state.email}
              onChange={props.handleChange}
              disabled={isSmarttoniSupplier}
            />
            <Label style={{ marginTop: 5 }} for="phoneNumber">
              {t("supplier.SupplierAddCard.supplierPhoneNumber")}
            </Label>
            <Input
              type="tel"
              required
              name="phoneNumber"
              autoComplete="name"
              placeholder={t(
                "supplier.SupplierAddCard.enterSupplierPhoneNumber"
              )}
              value={props.state.phoneNumber}
              onChange={props.handleChange}
              disabled={isSmarttoniSupplier}
            />
            <Label style={{ marginTop: 5 }} for="vendorNumber">
              {t("supplier.SupplierAddCard.vendorNumber")}
            </Label>
            <Input
              type="text"
              required
              name="vendorNumber"
              autoComplete="name"
              placeholder={t("supplier.SupplierAddCard.enterVendorNumber")}
              value={props.state.vendorNumber}
              onChange={props.handleChange}
            />

            <Label style={{ marginTop: 5 }} for="emailTemplate">
              {t("email.orderByEmail")}
            </Label>
            <Select
              name="emailTemplate"
              value={props.state.emailTemplate}
              onChange={props.handleSelectChange}
              options={props.emailTemplateOptions}
              maxMenuHeight={200}
              placeholder={t("common.select")}
              isDisabled={
                isSmarttoniSupplier &&
                props.state.emailTemplate !== "" &&
                props.emailTemplateOptions.length === 1
              }
            />
            <Label style={{ marginTop: 5 }} for="deliverySchedule">
              {t("common.deliverySchedule")}{" "}
              <FontAwesomeIcon
                style={{ color: GRAY, cursor: "pointer" }}
                icon={faInfoCircle}
                onClick={() =>
                  window.open(
                    "https://crontab.guru/",
                    "Crontab Help",
                    "width=1200,height=600"
                  )
                }
              />
            </Label>
            <Input
              type="text"
              name="deliverySchedule"
              placeholder={t("common.deliverySchedule")}
              value={props.state.deliverySchedule}
              onChange={props.handleChange}
              disabled={isSmarttoniSupplier}
              invalid={!props.state.isValidCrontab}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={saveEdit}
          disabled={
            !props.state.name ||
            !!props.state.isFetching ||
            !props.state.isValidCrontab
          }
        >
          {t("common.save")}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
