export interface UsersModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface UsersState {
  users: any;
  isFetching: boolean;
  deletemodal: boolean;
  uuid: string;
  isUpdated: boolean;
  restaurantuuid: string;
  email: string;
  userInvitation: string;
  resendmodal: boolean;
  sendmodal: boolean;
  invitedeletemodal: boolean;
  validate: boolean;
  rolelist: any;
  roleToggle: boolean;
  rolecheck: any;
  roleData: any;
  currentuser: any;
  staffModal: boolean;
}

export const UsersInitState = {
  isFetching: false,
  users: "",
  deletemodal: false,
  uuid: "",
  isUpdated: false,
  restaurantuuid: "",
  email: "",
  userInvitation: "",
  resendmodal: false,
  sendmodal: false,
  invitedeletemodal: false,
  validate: false,
  rolelist: [],
  roleToggle: false,
  rolecheck: [],
  roleData: [],
  currentuser: [],
  staffModal: false,
};
