import apiCall from "./api.call.config";

async function addQrCode(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/qr-code",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}
async function getRoomDetails(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/get?components=room,station,table"
    );
  } catch (e) {
    throw e;
  }
}
async function removeQrCode(url: string, params: any) {
  try {
    return await apiCall.put(
      url + "/" + params.credentials.restaurantuuid + "/qr-code/delete",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function qrCodes(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.credentials.restaurantuuid + "/qr-code"
    );
  } catch (e) {
    throw e;
  }
}
async function updateQrCode(url: string, params: any) {
  try {
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/qr-code/" +
        params.credentials.id,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  addQrCode,
  getRoomDetails,
  removeQrCode,
  qrCodes,
  updateQrCode,
};
