export interface ContentManagementModel {
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
  readonly isFetching: boolean;
}
export interface ContentManagementState {
  activeContent: any;
  addModal: boolean;
  contents: Array<any>;
  isEdit: boolean;
  isFetching: boolean;
  isUpdated: boolean;
  restaurantuuid: string;
  showModal: boolean;
}

export const ContentManagementInitState = {
  activeContent: {
    name: "",
    description: "",
    uuid: "",
  },
  addModal: false,
  contents: [],
  isEdit: false,
  isFetching: false,
  isUpdated: false,
  restaurantuuid: "",
  showModal: false,
};
