import "../../styles/recipe.scss";

import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { INVENTORY_TYPE } from "../../constant/constant";

export default function ManageStorageStorage(props: any) {
  const { t, i18n } = useTranslation();
  const [main_storage, setMainStorage] = useState(false);
  const [inventory_type, setInventoryType] = useState("1");
  const [min_quantity, setMin] = useState("");
  const [max_quantity, setMax] = useState("");
  const [outputQuantity, setOutputQuantity] = useState("");
  const [outputUnit, setOutputUnit] = useState("");
  const [conversionFactor, setConversionFactor] = useState("");

  useEffect(() => {
    const minQuantity =
      props.storageFormData?.min_quantity === undefined ||
      props.storageFormData?.min_quantity === null
        ? ""
        : props.storageFormData?.min_quantity;
    setMainStorage(props.storageFormData?.main_storage || false);
    setMin(minQuantity);
    setMax(props.storageFormData?.max_quantity || "");
    setInventoryType(props.storageFormData?.inventory_type || "1");
    setOutputQuantity(props.storageFormData?.output_quantity || "");
    setOutputUnit(props.storageFormData?.output_unit || "");
  }, [props.storageFormData]);

  useEffect(() => {
    const unit: any = props?.units.find((u: any) => u.value === outputUnit);

    setConversionFactor(String(unit?.conversionFactor || 1));
  }, [outputUnit]);

  const handleChange = (element: any) => {
    const name = element.target.name;
    const value = element.target.value;
    if (name === "main_storage") {
      setMainStorage(element.target.checked);
    } else if (name === "min_quantity") {
      setMin(value);
    }
    if (name === "max_quantity") {
      setMax(value);
    }
  };
  const cancelEdit = () => {
    props.toggleStorageMenu();
  };
  const renderManaged = () => {
    let list = [
      {
        managedId: INVENTORY_TYPE.MANAGED,
        title: t("common.managed"),
      },
      {
        managedId: INVENTORY_TYPE.NO_STOCK,
        title: t("common.noStock"),
      },
      {
        managedId: INVENTORY_TYPE.INFINITY,
        title: t("common.infinite"),
      },
    ];

    let managedList;
    if (!!list) {
      managedList = list.map((sup: any, i: number) => {
        return (
          <option key={i} value={sup.managedId}>
            {sup.title}
          </option>
        );
      });
      return managedList;
    }
  };
  return (
    <React.Fragment>
      <Modal isOpen={props.toggleStorage}>
        {!!props.toggleStorage && (
          <>
            <ModalHeader toggle={cancelEdit} className="modal-header">
              {t("common.manageStorage")}
            </ModalHeader>
            <ModalBody>
              <FormGroup row className="p-0">
                <Label for="override" sm={6} lg={5} className="text-right">
                  {t("storage.setAsMain")}
                </Label>
                <Col sm={6} lg={7} className="d-flex pt-2">
                  <CustomInput
                    checked={main_storage}
                    id="main_storage"
                    label=""
                    name="main_storage"
                    onChange={(e) => handleChange(e)}
                    type="switch"
                  />
                  <span className="ml-2 text-capitalize">{main_storage}</span>
                </Col>
              </FormGroup>
              <FormGroup row className="p-0">
                <Label sm={6} lg={5} className="text-right">
                  {t("common.quantity")}
                </Label>
                <Col sm={6} lg={7}>
                  <div className="row p-0">
                    <div className="col d-flex align-items-center">
                      <span className="mr-2">{t("common.from")}</span>
                      <Input
                        name="min_quantity"
                        type="number"
                        value={min_quantity}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="col d-flex align-items-center pl-0">
                      <span className="mr-2">{t("common.to")}</span>
                      <Input
                        name="max_quantity"
                        type="number"
                        value={max_quantity || ""}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row className="p-0">
                <Label
                  sm={6}
                  lg={5}
                  className="text-right"
                  for="outputquantity"
                >
                  {t("common.outputQuantity")}{" "}
                  <span className="required-star-style">*</span>
                </Label>
                <Col sm={6} lg={7}>
                  <Input
                    type="number"
                    required
                    name="outputquantity"
                    autoComplete="outputquantity"
                    onChange={(e) => {
                      setOutputQuantity(e.target.value);
                    }}
                    placeholder={t("common.enterOutputQuantity")}
                    value={outputQuantity}
                    min="0"
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="p-0">
                <Label sm={6} lg={5} className="text-right" for="lastname">
                  {t("common.outputUnit")}{" "}
                  <span className="required-star-style">*</span>
                </Label>

                <Col sm={6} lg={7}>
                  <Input
                    type="select"
                    id="countrySelect"
                    required
                    name="outputunituuid"
                    onChange={(e: any) => {
                      const idx = e.target.selectedIndex;
                      setOutputUnit(e.target.value);
                    }}
                    value={outputUnit}
                  >
                    <option value="">{t("common.selectOutputUnit")}</option>
                    {Object.keys(props?.units || {}).length > 0 &&
                      props?.units.map((unit: any, index: number) => (
                        <option value={unit.value} key={index}>
                          {unit.label}
                        </option>
                      ))}
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row className="p-0">
                <Label sm={6} lg={5} className="text-right">
                  {t("inventory.inventoryType")}
                </Label>
                <Col sm={6} lg={7}>
                  <Input
                    type="select"
                    id="managedId"
                    required
                    name="managedId"
                    onChange={(e) => {
                      setInventoryType(e.target.value);
                    }}
                    value={inventory_type}
                  >
                    <option value="">{t("common.select")}...</option>
                    {renderManaged()}
                  </Input>
                </Col>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => cancelEdit()}>
                {t("common.cancel")}
              </Button>
              <Button
                color="info"
                onClick={() => {
                  props.saveRecipeStorage({
                    main_storage,
                    min_quantity,
                    max_quantity,
                    output_quantity: Number(outputQuantity) || undefined,
                    output_unit_uuid: outputUnit,
                    conversionFactor,
                    inventory_type: Number(inventory_type) || undefined,
                  });
                }}
                disabled={
                  (!!min_quantity &&
                    !!max_quantity &&
                    Number(max_quantity) < Number(min_quantity)) ||
                  (!!min_quantity && Number(min_quantity) < 0) ||
                  (!!max_quantity && Number(max_quantity) <= 0) ||
                  !!!outputQuantity ||
                  !!!outputUnit
                }
              >
                {t("common.save")}
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </React.Fragment>
  );
}
