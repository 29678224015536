import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

import {
  languageRequest,
  languageUpdate,
} from "../../redux/actions/language.action";
import { currencyRequest } from "../../redux/actions/currency.action";
import { restaurantDetailRequest } from "../../redux/actions/restaurant.detail.action";
import { SettingsInitState, SettingsState } from "../../models/settings.model";
import {
  settingsAdd,
  settingsRequest,
  stationsRequest,
} from "../../redux/actions/stations.action";
import "../../styles/operation-mode.scss";
import { label as getLabelList } from "../../services/labels.service";
import PosSettingsCard from "../card-components/pos-settings-card/pos-settings-card";
import SettingsCard from "../card-components/settings-card/settings-card";
import LoaderComponent from "../loader/loader";
import SettingsHeaderComponent from "../navigation/navigation-header/settings-header/settings-header";
import SettingsNavComponent from "../navigation/navigation-left/settings-navigation/settings-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import RestaurantLangCard from "../card-components/restaurant-language/restaurant-language";
import { withTranslation } from "react-i18next";
import commonService from "../../services/common.service";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import _ from "lodash";
import ModalFactory from "react-modal-promise";
import { OpenMediaManager } from "../card-components/media-manager-card/media-manager-card";
import {
  getReportingTemplates,
  setReportingTemplates,
} from "../../services/report.service";
import ReportingTemplates from "../reports/ReportingTemplates";
import { OpenDeleteModal } from "../card-components/delete-card/delete-modal";
import { ISSUPERADMIN } from "../../constant/constant";
import RestaurantBrandingCard from "../card-components/restaurant-branding/restaurant-branding-card";

class SettingsComponent extends Component<any> {
  state: SettingsState;
  option: any = [];
  settingstatus: any = 0;
  constructor(props: any) {
    super(props);
    this.state = SettingsInitState;
    this._setFunctionBindings();
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.getLanguages({ restaurantuuid: this.props.match.params.uuid });
    this.props.getRestaurantDetail({
      restaurantuuid: this.props.match.params.uuid,
    });
    this.props.getStations({ uuid: this.props.match.params.uuid });
    this.props.getSettigs({ restaurantuuid: this.props.match.params.uuid });
    this.props.getCurrency();
    getLabelList(RESTAURANT_REQUEST, {
      credentials: {
        restaurantuuid: this.props?.match?.params.uuid,
      },
    }).then((response: any) => {
      this.setState({
        labelList: response?.data,
      });
    });
    this.getReportTemplates();
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this.setState({
        isFetching: newProps.isFetching,
      });
      if (!!newProps.stations && !newProps.isFetching) {
        this.setState({
          operationmode: newProps.stations,
        });
      }
      if (!!this.state.isUpdated && !newProps.isFetching) {
        this.props.getRestaurantDetail({
          restaurantuuid: this.props.match.params.uuid,
        });
        this.props.getStations({ uuid: this.props.match.params.uuid });
        this.props.getSettigs({ restaurantuuid: this.props.match.params.uuid });
        this.setState({
          isUpdated: false,
        });
      }
      if (!!newProps.restaurantDetail && !newProps.isFetching) {
        this.setState({
          selectedLang: newProps.restaurantDetail?.languages,
        });
      }
      if (
        !!newProps.languages &&
        !newProps.isFetching &&
        !this.state.isUpdated
      ) {
        let globalLanguages = newProps?.languages?.filter(
          (item: any) =>
            newProps.restaurantDetail?.languages?.findIndex(
              (element: any) => element.uuid === item.uuid
            ) === -1
        );
        let languages = [
          ...newProps?.restaurantDetail?.languages,
          ...globalLanguages,
        ];
        if (
          this.state.currentLanguage !== newProps.restaurantDetail?.languages
        ) {
          this.setState({
            currentLanguage: newProps.restaurantDetail?.languages,
          });
        }
        languages.forEach((item: any) => {
          if (
            newProps.restaurantDetail?.languages?.findIndex(
              (element: any) => element.uuid === item.uuid
            ) !== -1
          )
            item.isChecked = true;
          else item.isChecked = false;
        });
        this.setState({
          languages: languages,
        });
      }

      if (
        !!newProps.setting &&
        !newProps.isFetching &&
        !this.state.isSettingUpdated
      ) {
        let [currency] = newProps.currencyList.filter(
          (item: any) => item.uuid === newProps.setting.currency.uuid
        );
        let deliveryStation: any =
          newProps.stations.find(
            (st: any) => st.uuid === newProps.setting.delivery_station
          ) || null;
        if (deliveryStation) {
          deliveryStation = {
            label: commonService.applyLocalization(
              "restaurant",
              "name",
              deliveryStation.locales
            )["name"],
            value: deliveryStation.uuid,
          };
        }

        let currencyLocale = commonService.applyLocalization(
          "restaurant",
          "name",
          currency.locales
        );
        let decimalPart = String(
          newProps.setting.minimal_cash_denomination
        ).split(".")[1];
        let minimalCashDenomination: any =
          newProps.setting.minimal_cash_denomination;
        if (
          newProps.setting.minimal_cash_denomination &&
          decimalPart?.length !== currency.minor_units
        ) {
          minimalCashDenomination = minimalCashDenomination?.toFixed(
            currency.minor_units
          );
        }
        if (!newProps.setting.minimal_cash_denomination) {
          minimalCashDenomination = this.findMinimalDigitalDenomination(
            currency.minor_units
          );
        }

        this.setState({
          country: {
            label: currencyLocale.country,
            value: currencyLocale.country,
          },
          currency: {
            label: currencyLocale?.name,
            value: newProps.setting.currency,
          },
          deliveryStation: deliveryStation || null,
          showFirstLevelLabels:
            newProps.setting.pos_show_root_labels === "1" ? true : false,
          currencyCode: currency?.code,
          minorUnit: currency.minor_units,
          minimalDigitalDenomination: this.findMinimalDigitalDenomination(
            currency.minor_units
          ),
          minimalCashDenomination: minimalCashDenomination,
          reservation: newProps.setting.reservation,
          posDefaultLabel: {
            value: newProps.setting.pos_default_label,
            label: commonService.applyLocalization(
              "restaurant",
              "name",
              this.state.labelList?.find(
                (label: any) =>
                  label.uuid === newProps.setting.pos_default_label
              )?.locales
            )["name"],
          },
          setting: newProps.setting,
          url: newProps.setting.url,
          recipe_edit_mode: !!newProps.setting.settings.recipe_edit_mode
            ? newProps.setting.settings.recipe_edit_mode
            : 0,
          meals_to_same_chef: !!newProps.setting.settings.meals_to_same_chef
            ? newProps.setting.settings.meals_to_same_chef
            : 0,
          currentSettingsData: {
            currency: newProps.setting.currency?.uuid,
            minimal_cash_denomination: parseFloat(minimalCashDenomination),
            hourly_cooks_rate: newProps.setting.hourly_cooks_rate,
            reservation: newProps.setting.reservation,
            settings: {
              meals_to_same_chef: parseInt(
                newProps.setting.settings.meals_to_same_chef
              ),
              recipe_edit_mode: parseInt(
                newProps.setting.settings.recipe_edit_mode
              ),
            },
            url: newProps.setting.url,
          },
          corporateColor: newProps.setting.corporate_color,
          restaurantLogo: newProps.setting.restaurant_logo,
        });
      }
      // TODO Need to remove below hacks
      else if (
        !!newProps.setting &&
        !newProps.isFetching &&
        this.state.isSettingUpdated
      ) {
        this.setState({
          isSettingUpdated: false,
        });
      }
    }
  }

  updateLogo = (media: any) => {
    this.setState({
      restaurantLogo: media,
      currentSettingsData: {
        ...this.state.currentSettingsData,
        restaurant_logo: media.uuid,
      },
    });
  };

  private _setFunctionBindings(): void {
    this.handleMediaManager = this.handleMediaManager.bind(this);
    this.openMediaManagerModal = this.openMediaManagerModal.bind(this);
  }

  public async openMediaManagerModal(
    defaultUuid: any = null,
    filter: any = []
  ) {
    return await OpenMediaManager({
      restaurantUuid: this.props.match.params.uuid,
      typeFilter: ["files"],
      selectedFiles: this.state.reportTemplates?.map(
        (template: any) => template.uuid
      ),
      predefinedMediaUuid: defaultUuid,
      acceptableTypes: ".csv,.xls,.xlsx",
      // apiFilters: `name=""&pageNo=1&pageSize=20&type=files&uuid="" & `,
      apiFilters: {
        pageNo: 1,
        pageSize: 50,
        filter: "type=files",
      },
      multiSelection: true,
      entityType: "files",
    });
  }

  getReportTemplates = () => {
    this.setState({ isFetching: true });
    const payLoad = {
      credentials: {
        restaurantuuid: this.props.match.params.uuid,
      },
    };
    getReportingTemplates(RESTAURANT_REQUEST, payLoad)
      .then((data: any) => {
        if (data.status === 200) {
          this.setState({
            reportTemplates: data.data,
            isFetching: false,
          });
        } else {
          this.setState({ isFetching: false });
        }
      })
      .catch((error: any) => this.setState({ isFetching: false }));
  };

  handleCheckBoxChange = (item: any) => {
    item.isChecked = !item.isChecked;
    let { languages } = this.state;
    this.setState({
      languages: languages,
    });
  };

  handleDeleteReportingTemplates = (id: string) => {
    OpenDeleteModal().then(() => {
      const templates: any = this.state.reportTemplates
        .filter((template: any) => template.uuid !== id)
        ?.map((template: any) => template.uuid);
      this.handleReportingTemplateSave(templates);
    });
  };

  private async handleMediaManager() {
    const media: any = await this.openMediaManagerModal();
    if (media) {
      const templates: any = [
        ...this.state.reportTemplates?.map((template: any) => template.uuid),
        media.mediaUuid,
      ];
      this.handleReportingTemplateSave(templates);
    }
  }

  handleMinimalCashDenomination = (e: any) => {
    let decimal = e.target.value.split(".")[1];
    let integer = e.target.value.split(".")[0];
    let hasError = false;
    let minorUnit: any = this.state.minorUnit;
    if (decimal?.length === minorUnit || minorUnit === 0) {
      let formatDecimal = decimal?.replace(/^0+(\d)|(\d)0+$/gm, "$1$2");
      let formatInteger = integer?.replace(/^0+(\d)|(\d)0+$/gm, "$1$2");
      if (formatInteger === "0") {
        if (
          formatDecimal !== "1" &&
          formatDecimal !== "2" &&
          formatDecimal !== "5"
        ) {
          hasError = true;
        } else {
          hasError = false;
        }
      } else {
        if (
          formatInteger !== "1" &&
          formatInteger !== "2" &&
          formatInteger !== "5"
        ) {
          hasError = true;
        } else {
          if (formatDecimal !== "0" && minorUnit !== 0) {
            hasError = true;
          } else {
            hasError = false;
          }
        }
      }
    } else {
      hasError = true;
    }
    this.setState({
      minimalCashDenomination: e.target.value,
      hasError: hasError,
      currentSettingsData: {
        ...this.state.currentSettingsData,
        minimal_cash_denomination: parseFloat(e.target.value),
      },
    });
  };

  handleReportingTemplateSave = (templates: any) => {
    this.setState({ isFetching: true });
    const payLoad = {
      credentials: {
        restaurantuuid: this.props.match.params.uuid,
        data: { templates },
      },
    };
    setReportingTemplates(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        commonService.toastService(response.data.flash, "success");
        this.getReportTemplates();
      })
      .catch((error: any) => {
        this.setState({
          isFetching: false,
        });
        commonService.toastService("", "danger", JSON.stringify(error.error));
      });
  };

  updateLanguages = () => {
    this.props.updateLanguage({
      restaurantuuid: this.props.match.params.uuid,
      data: {
        languages: this.state.languages
          .filter((item: any) => item.isChecked)
          .map((element: any) => element.uuid),
      },
    });
    this.setState({
      isUpdated: true,
    });
  };

  posList = () => {
    return (
      <PosSettingsCard
        labelList={this.state.labelList}
        handleDefaultLabelChange={this.handleDefaultLabelChange}
        handleChangeSetting={this.handleChangeSetting}
        handleSubmit={this.handleSubmit}
        posDefaultLabel={this.state.posDefaultLabel}
        showFirstLevelLabels={this.state.showFirstLevelLabels}
        {...this.props}
      />
    );
  };

  restaurantBranding = () => {
    return (
      <RestaurantBrandingCard
        handleChangeSetting={this.handleChangeSetting}
        handleSubmit={this.handleSubmit}
        restaurantUuid={this.props.match.params.uuid}
        updateLogo={this.updateLogo}
        corporateColor={this.state.corporateColor}
        restaurantLogo={this.state.restaurantLogo}
      />
    );
  };

  handleDefaultLabelChange = (label: any) => {
    this.setState({
      posDefaultLabel: label,
    });
    this.setState({
      currentSettingsData: {
        ...this.state.currentSettingsData,
        pos_default_label: label.value,
      },
    });
  };
  render() {
    let isSuperAdmin: any = localStorage.getItem(ISSUPERADMIN);
    const { t } = this.props;
    const { languages } = this.state;
    return (
      <div>
        <ModalFactory />
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <SettingsHeaderComponent settings={"settings"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <SettingsNavComponent
                display={"settings"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              {isSuperAdmin === "true" && (
                <div className="white-box mb-3">
                  <h4>{t("reports.reportingTemplates")}</h4>
                  <Button color="info" onClick={this.handleMediaManager}>
                    {t("common.add")}
                  </Button>
                  {this.reportTemplatesList()}
                </div>
              )}
              <div className="white-box mb-3">
                <h4>{t("common.settings")}</h4>
                {this.settingsList(this.state.setting.settings)}
              </div>
              <div className="white-box mb-3">
                <h4>{t("common.pos")}</h4>
                {this.posList()}
              </div>
              <div className="white-box mb-3">
                <h4>{t("common.languages")}</h4>
                <RestaurantLangCard
                  handleCheckBoxChange={this.handleCheckBoxChange}
                  languages={this.state.languages}
                  restaurantLanguage={
                    this.props.restaurantDetail?.language || ""
                  }
                  selectedLang={this.state.selectedLang}
                />
                <div className="d-flex justify-content-end">
                  <Button color="info" onClick={this.updateLanguages}>
                    {t("common.save")}
                  </Button>{" "}
                </div>
              </div>
              <div className="white-box mb-3">
                <h4>{t("restaurant.branding")}</h4>
                {this.restaurantBranding()}
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  reportTemplatesList = () => {
    return (
      <ReportingTemplates
        templates={this.state.reportTemplates}
        deleteTemplate={this.handleDeleteReportingTemplates}
      />
    );
  };
  public settingsList(setting: any): any {
    if (!!setting) {
      return (
        <SettingsCard
          country={this.state.country}
          currencyList={this.state.currencyList}
          currency={this.state.currency}
          currencyCode={this.state.currencyCode}
          deliveryStation={this.state.deliveryStation}
          assembleTaskTitleEn={
            this.state.assembleTaskTitleEn ||
            this.state.setting.assemble_task_title_en
          }
          assembleTaskTitleDe={
            this.state.assembleTaskTitleDe ||
            this.state.setting.assemble_task_title_de
          }
          assembleTaskDescriptionEn={
            this.state.assembleTaskDescriptionEn ||
            this.state.setting.assemble_task_description_en
          }
          assembleTaskDescriptionDe={
            this.state.assembleTaskDescriptionDe ||
            this.state.setting.assemble_task_description_de
          }
          locales={this.state.setting.settings}
          meals_to_same_chef={this.state.meals_to_same_chef}
          restaurantCode={setting.restaurant_code}
          minimalCashDenomination={this.state.minimalCashDenomination}
          minimalDigitalDenomination={this.state.minimalDigitalDenomination}
          minorUnit={this.state.minorUnit}
          recipe_edit_mode={this.state.recipe_edit_mode}
          url={this.state?.setting.url[0]}
          reservationUrl={this.state.reservation}
          stationList={this.props.stations}
          handleMinimalCashDenomination={this.handleMinimalCashDenomination}
          handleChangeSetting={this.handleChangeSetting.bind(this)}
          handleChangeCurrency={this.handleChangeCurrency}
          handleCountryChange={this.handleCountryChange}
          handleSubmit={this.handleSubmit.bind(this)}
          hasError={this.state.hasError}
        />
      );
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.props.t("common.noRecords")}
        </div>
      );
    }
  }

  public handleChangeCurrency = (currency: any) => {
    let [selectedCurrency] = this.state.currencyList.filter((item: any) => {
      return currency.value === item.uuid;
    });
    this.setState({
      currency: currency,
      currencyCode: selectedCurrency.code,
      minorUnit: selectedCurrency.minor_units,
      minimalDigitalDenomination: this.findMinimalDigitalDenomination(
        selectedCurrency.minor_units
      ),
      minimalCashDenomination: this.findMinimalDigitalDenomination(
        selectedCurrency.minor_units
      ),

      currentSettingsData: {
        ...this.state.currentSettingsData,
        currency: currency.value,
        minimal_cash_denomination: this.findMinimalDigitalDenomination(
          selectedCurrency.minor_units
        ),
      },
    });
  };

  findMinimalDigitalDenomination = (minorUnit: any) => {
    switch (minorUnit) {
      case 0:
        return 1;
      case 1:
        return 0.1;
      case 2:
        return 0.01;

      case 3:
        return 0.001;
      case 4:
        return 0.0001;
    }
  };

  handleCountryChange = (country: any) => {
    this.setState({
      country: country,
      currencyList: this.props.currencyList.filter((currency: any) => {
        let currencyLocale = commonService.applyLocalization(
          "restaurant",
          "name",
          currency.locales
        );
        return (
          currencyLocale.country.toLowerCase() === country.value.toLowerCase()
        );
      }),
      currency: {
        label: "Select",
        value: "",
      },
      currencyCode: "",
      hasError: false,
      minorUnit: "",
      minimalDigitalDenomination: "",
      minimalCashDenomination: "",
    });
  };

  handleChangeSetting = (event: any) => {
    let recipe_edit_mode = parseInt(this.state.recipe_edit_mode);
    let meals_to_same_chef = parseInt(this.state.meals_to_same_chef);
    let url = this.state.url.length === 0 ? [] : [this.state.url[0]];
    let reservation = this.state.reservation;
    let showFirstLevelLabels = this.state.showFirstLevelLabels;
    let deliveryStation = this.state.deliveryStation?.value || null;
    let assembleTaskTitleEn =
      this.state.assembleTaskTitleEn ||
      this.state.setting.assemble_task_title_en ||
      "";
    let assembleTaskDescriptionEn =
      this.state.assembleTaskDescriptionEn ||
      this.state.setting.assemble_task_description_en ||
      "";
    let assembleTaskTitleDe =
      this.state.assembleTaskTitleDe ||
      this.state.setting.assemble_task_title_de ||
      "";
    let assembleTaskDescriptionDe =
      this.state.assembleTaskDescriptionDe ||
      this.state.setting.assemble_task_description_de ||
      "";
    let corporateColor =
      this.state.corporateColor || this.state.setting.corporate_color || "";
    switch (event.target.name) {
      case "recipe_edit_mode":
        recipe_edit_mode = parseInt(event.target.value) === 1 ? 0 : 1;
        this.setState({ recipe_edit_mode: recipe_edit_mode });
        break;
      case "meals_to_same_chef":
        meals_to_same_chef = parseInt(event.target.value) === 1 ? 0 : 1;
        this.setState({ meals_to_same_chef: meals_to_same_chef });
        break;
      case "baseUrl":
        url =
          event.target.value.trim() === "" ? [] : [event.target.value.trim()];
        this.setState({ url: url });
        break;

      case "reservation":
        reservation = event.target.value;
        this.setState({
          reservation: event.target.value,
        });
        break;
      case "showFirstLevelLabels":
        showFirstLevelLabels = event.target.checked;
        this.setState({
          showFirstLevelLabels: event.target.checked,
        });

        break;
      case "deliveryStation":
        deliveryStation = event.target.value?.value;

        this.setState({
          deliveryStation: event.target.value,
        });

        break;
      case "assembleTaskTitleEn":
        this.setState({
          assembleTaskTitleEn: event.target.value,
        });
        break;
      case "assembleTaskDescriptionEn":
        this.setState({
          assembleTaskDescriptionEn: event.target.value,
        });
        break;
      case "assembleTaskTitleDe":
        this.setState({
          assembleTaskTitleDe: event.target.value,
        });
        break;
      case "assembleTaskDescriptionDe":
        this.setState({
          assembleTaskDescriptionDe: event.target.value,
        });
        break;
      case "corporateColor":
        corporateColor = event.target.value;
        this.setState({
          corporateColor: corporateColor,
        });
        break;
    }
    this.setState({
      isUpdated: true,
      currentSettingsData: {
        ...this.state.currentSettingsData,
        delivery_station: deliveryStation,
        settings: {
          meals_to_same_chef: meals_to_same_chef,
          recipe_edit_mode: recipe_edit_mode,
        },
        url: url,
        reservation: reservation,
        pos_show_root_labels: showFirstLevelLabels,
        assemble_task_title_en:
          event.target.name === "assembleTaskTitleEn"
            ? event.target.value
            : assembleTaskTitleEn,
        assemble_task_description_en:
          event.target.name === "assembleTaskDescriptionEn"
            ? event.target.value
            : assembleTaskDescriptionEn,
        assemble_task_title_de:
          event.target.name === "assembleTaskTitleDe"
            ? event.target.value
            : assembleTaskTitleDe,
        assemble_task_description_de:
          event.target.name === "assembleTaskDescriptionDe"
            ? event.target.value
            : assembleTaskDescriptionDe,
        corporate_color: corporateColor,
      },
    });
  };
  handleSubmit = () => {
    const currentSettingsData: any = { ...this.state.currentSettingsData };
    this.props.settingsAdd({
      restaurantuuid: this.props.match.params.uuid,
      data: currentSettingsData,
    });
    this.setState({
      isSettingUpdated: true,
    });
  };
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.stations.isFetching ||
    state.operationmode.isFetching ||
    state.settings.isFetching ||
    state.currency.isFetching ||
    state.settingsadd.isFetching ||
    state.languageUpdate.isFetching ||
    state.restaurantDetail.isFetching;
  let failure =
    state.stations.failure ||
    state.operationmode.failure ||
    state.settings.failure ||
    state.currency.failure ||
    state.settingsadd.failure ||
    state.languageUpdate.failure ||
    state.restaurantDetail.failure;
  return {
    labels: state.labels.data,
    languages: state.languages.data,
    currencyList: state.currency.data,
    restaurantDetail: state.restaurantDetail.data,
    stations: state.stations.data,
    isFetching: isFetching,
    failure: failure,
    setting: state.settings.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getStations: (credentials: any) => {
      dispatch(stationsRequest(credentials));
    },
    getSettigs: (credentials: any) => {
      dispatch(settingsRequest(credentials));
    },
    getCurrency: (credentials: any) => {
      dispatch(currencyRequest(credentials));
    },
    settingsAdd: (credentials: any) => {
      dispatch(settingsAdd(credentials));
    },
    getLanguages: (credentials: any) => {
      dispatch(languageRequest(credentials));
    },
    updateLanguage: (credentials: any) => {
      dispatch(languageUpdate(credentials));
    },
    getRestaurantDetail: (credentials: any) => {
      dispatch(restaurantDetailRequest(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingsComponent))
);
