import apiCall from "./api.call.config";

export async function label(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.credentials.restaurantuuid + "/label?sort=name:asc"
    );
  } catch (e) {
    throw e;
  }
}

async function updateLabel(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.put(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/label/" +
        params.credentials.uuid,
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function addLabel(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/label",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

async function removeLabel(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.remove(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/label/" +
        params.credentials.uuid
    );
  } catch (e) {
    throw e;
  }
}

export default {
  label,
  updateLabel,
  addLabel,
  removeLabel,
};
