import { faRedo, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { GRAY, GREEN } from "../../constant/constant";
import { UsersInitState, UsersState } from "../../models/users.model";
import { rolesRequest } from "../../redux/actions/roles.action";
import {
  userInvitationAdd,
  userInvitationRemove,
  userInvitationRequest,
  userInvitationResend,
} from "../../redux/actions/user.invitation.action";
import {
  userRoleRequest,
  usersRemove,
  usersRequest,
} from "../../redux/actions/users.action";
import commonService from "../../services/common.service";
import validationService from "../../services/validation.service";
import "../../styles/users.scss";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import UsersCard from "../card-components/users-card/users-card";
import LoaderComponent from "../loader/loader";
import StaffHeaderComponent from "../navigation/navigation-header/staff-header/staff-header";
import UsersLeftComponent from "../navigation/navigation-left/users-navigation/users-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import i18n from "../../i18n";
import _ from "lodash";
import AddStaff from "./addStaff";
class UsersComponent extends Component<any> {
  state: UsersState;
  roleList: any = [];
  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.state = UsersInitState;
  }
  render() {
    const { t } = this.props;
    let validationMessage;
    if (!!this.state.validate) {
      validationMessage = (
        <InputGroup>
          <Label className="validation">
            {t("email.emailValidationMessage")}
          </Label>
        </InputGroup>
      );
    } else {
      validationMessage = "";
    }

    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <StaffHeaderComponent staff={"staff"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <UsersLeftComponent
                display={"users"}
                restaurantuuid={this.props.match.params.uuid}
              ></UsersLeftComponent>
            </Col>
            <Col xl={8} lg={6}>
              <div
                className="white-box mb-3"
                style={{
                  display: this.state.users.length > 0 ? "block" : "none",
                }}
              >
                <Label className="user-add">{t("user.user")}</Label>
                {this.userList(this.state.users)}
              </div>
              <div className="white-box mb-3">
                <Label
                  className="user-add"
                  style={{
                    display:
                      this.state.userInvitation.length > 0 ? "block" : "none",
                  }}
                >
                  {t("invitation.invitations")}
                </Label>
                {this.invitationList(this.state.userInvitation)}
                <Link
                  to="#"
                  color="info"
                  className="user-add"
                  onClick={this.sendToggle}
                >
                  {t("invitation.sendInvitation")}
                </Link>
                <Link
                  to="#"
                  color="info"
                  className="ml-3 user-add"
                  onClick={this.toggleStaffModal}
                >
                  {t("common.addStaff")}
                </Link>
              </div>
              <div
                style={{
                  display:
                    !this.state.isFetching &&
                    this.state.userInvitation.length === 0 &&
                    this.state.users.length === 0
                      ? "block"
                      : "none",
                }}
              >
                <Label className="no-records"> {t("common.noRecords")}</Label>
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent></NavigationRightComponent>
            </Col>
          </Row>
        </div>

        {/* Mangae user roles */}

        <Modal isOpen={this.state.roleToggle}>
          <ModalHeader toggle={this.toggleRole} className="modal-header">
            {t("role.manageRoles")}
          </ModalHeader>
          <ModalBody>
            {
              // eslint-disable-next-line
              !!this.state.rolelist.length
                ? this.state.rolelist.map((roles: any, index: number) => {
                    return (
                      <CustomInput
                        key={index}
                        type="checkbox"
                        id={"task-recipe-list" + index.toString()}
                        value={roles.uuid}
                        defaultChecked={this.getSelectedRoles(roles.uuid)}
                        onChange={this.handleRoleChange.bind(this)}
                        label={
                          commonService.applyLocalization(
                            "restaurant",
                            "name",
                            roles?.locales
                          )["name"]
                        }
                      />
                    );
                  })
                : null
            }
          </ModalBody>

          <ModalFooter>
            <Button color="secondary" onClick={this.toggleRole}>
              {t("common.cancel")}
            </Button>
            <Button color="info" onClick={this.saveUserRoleAdd}>
              {t("common.save")}
            </Button>
          </ModalFooter>
        </Modal>
        {/* Delete User */}

        <DeleteModalCard
          isOpen={this.state.deletemodal}
          isFetching={!!this.state.isFetching}
          okDelete={this.deleteConfirmToggle}
          cancelDelete={this.deleteToggle}
        />

        {/*  Send Invitation  */}

        <Modal isOpen={this.state.sendmodal}>
          <ModalHeader toggle={this.sendToggle} className="modal-header">
            {t("invitation.sendNewInvitations")}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="firstname">{t("email.email")}</Label>
                <Label className="required-star-style">*</Label>
                <Input
                  type="email"
                  required
                  name="email"
                  autoComplete="email"
                  placeholder={t("common.emailPlaceholder")}
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </FormGroup>
              {validationMessage}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.sendToggle}>
              {t("common.cancel")}
            </Button>
            <Button
              color="info"
              onClick={this.saveInvitationSend}
              disabled={
                !validationService.validateEmail(this.state.email) ||
                !this.state.email ||
                !!this.state.isFetching
              }
            >
              {t("common.save")}
            </Button>{" "}
          </ModalFooter>
        </Modal>

        {/*  Resend Invitation  */}

        <Modal isOpen={this.state.resendmodal}>
          <ModalBody>
            <Row className="app-row-padding">
              <Col sm="8" style={{ padding: 10, textAlign: "center" }}>
                <h6>{t("common.resendInvitationQuestion")}</h6>
              </Col>
              <Col sm="4" style={{ padding: 0, textAlign: "center" }}>
                <div style={{ float: "right" }}>
                  <Button
                    color="secondary"
                    style={{ margin: 5 }}
                    onClick={this.reSendToggle}
                  >
                    {t("common.cancel")}
                  </Button>
                  <Button
                    color="info"
                    style={{ margin: 5 }}
                    disabled={!!this.state.isFetching}
                    onClick={this.reSendConfirmToggle}
                  >
                    {t("common.ok")}
                  </Button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        {/* Delete Invitation */}

        <DeleteModalCard
          isOpen={this.state.invitedeletemodal}
          isFetching={!!this.state.isFetching}
          okDelete={this.deleteInvConfirmToggle}
          cancelDelete={this.deleteInvToggle}
        />

        {/*  Add Staff */}
        <AddStaff
          isOpen={this.state.staffModal}
          toggleModal={this.toggleStaffModal}
          restaurantUuid={this.props.match.params.uuid}
          invokeParentUpdate={this.invokeUsersUpdate}
        />
      </div>
    );
  }

  public getSelectedRoles(val: any): any {
    return !!this.state.currentuser && this.state.currentuser.length > 0
      ? this.state.currentuser.some((item: any) => item === val)
      : false;
  }

  public handleRoleChange(event: any): void {
    if (event.target.checked) {
      let parentFilter: any = this.state.currentuser.includes((item: any) => {
        return event.target.value === item;
      });
      if (!parentFilter) {
        this.roleList = this.state.currentuser;
        this.roleList.push(event.target.value);
      }
    } else {
      let parentRemoveFilter = this.state.currentuser.filter((item: any) => {
        return item !== event.target.value;
      });
      this.roleList = parentRemoveFilter;
    }
    // eslint-disable-next-line
    let filteredArr = this.roleList.filter((item: any, index: any) => {
      if (this.roleList.indexOf(item) === index) return item;
    });
    this.setState({
      currentuser: filteredArr,
    });
  }

  public toggleRole() {
    this.setState({
      roleToggle: !this.state.roleToggle,
    });
  }

  public manageRoles(event: any) {
    this.setState({
      roleToggle: !this.state.roleToggle,
      currentuser: event.roles,
      uuid: event.uuid,
    });
  }

  public userList(data: any): any {
    let userData =
      data.length > 0
        ? data?.map((user: any) => {
            user["full_name"] =
              user.user.first_name + " " + user.user.last_name;
            user["full_name"] = user["full_name"].toLowerCase();
            return user;
          })
        : [];
    userData = _.sortBy(userData, "full_name");

    let users;
    if (!!data && data.length) {
      users = userData.map((locales: any, i: number) => {
        return (
          <span key={i}>
            <div className="child-box mb-3">
              <UsersCard
                data={locales}
                roleList={this.state.rolelist}
                setDelete={this.setDelete.bind(this, locales)}
                manageRoles={this.manageRoles.bind(this, locales)}
              />
            </div>
          </span>
        );
      });
      return users;
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.props.t("common.noRecords")}
        </div>
      );
    }
  }

  public invitationList(data: any): any {
    let invitation;
    let invitedOn;
    if (!!data && data.length) {
      invitation = data.map((locales: any, i: number) => {
        invitedOn = new Date(locales.invited_on);
        return (
          <span key={i}>
            <div className="child-box mb-3">
              <Row className="app-row-padding">
                <Col xs="12" sm="6">
                  {locales.email}{" "}
                </Col>
                <Col xs="12" sm="4">
                  {commonService.dateFormat(invitedOn)}
                </Col>
                <Col>
                  <span style={{ marginTop: 20 }}>
                    <div style={{ float: "right", display: "flex" }}>
                      <span
                        onClick={this.setResend.bind(this, locales)}
                        className="pointer-cursor"
                        data-toggle="tooltip"
                        data-placement="left"
                        title={this.props.t("common.resendInvitation")}
                      >
                        <FontAwesomeIcon
                          style={{ color: GREEN, fontSize: 20, margin: 5 }}
                          icon={faRedo}
                        />
                      </span>
                      <span
                        onClick={this.setInvDelete.bind(this, locales)}
                        className="pointer-cursor"
                        data-toggle="tooltip"
                        data-placement="left"
                        title={this.props.t("common.delete")}
                      >
                        <FontAwesomeIcon
                          style={{ color: GRAY, fontSize: 20, margin: 5 }}
                          icon={faTrashAlt}
                        />
                      </span>
                    </div>
                  </span>
                </Col>
              </Row>
            </div>
          </span>
        );
      });
      return invitation;
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.props.t("common.noRecords")}
        </div>
      );
    }
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.getRoleList({ restaurantuuid: this.props.match.params.uuid });
    this.props.getUsers({ restaurantuuid: this.props.match.params.uuid });
    this.props.getUserInvitation({
      restaurantuuid: this.props.match.params.uuid,
    });
    this.setState({
      restaurantuuid: this.props.match.params.uuid,
    });
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
      if (!!newProps && !newProps.isFetching) {
        this.setState({
          users: newProps.user,
          userInvitation: newProps.invitation,
          rolelist: newProps.rolelist,
        });
      }
      if (!!this.state.isUpdated && !newProps.isFetching) {
        this.props.getUsers({ restaurantuuid: this.props.match.params.uuid });
        this.props.getUserInvitation({
          restaurantuuid: this.props.match.params.uuid,
        });
        this._setStates("isUpdated", false);
      }
      if (!!this.state.roleToggle && !newProps.isFetching) {
        this.toggleRole();
      }
      if (!!this.state.sendmodal && !newProps.isFetching) {
        this.sendToggle();
      }
    }
  }

  public deleteToggle(): void {
    this.setState((prevState: any) => ({
      deletemodal: !prevState.deletemodal,
    }));
  }

  public saveUserRoleAdd(): void {
    this.props.addUserRoles({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
      data: {
        role_uuids: this.state.currentuser,
      },
    });
    this._setStates("isUpdated", true);
  }

  public deleteConfirmToggle(): void {
    this.props.removeUser({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
    });
    this._setStates("isUpdated", true);
    this.deleteToggle();
  }

  private _setFunctionBindings(): void {
    this.handleChange = this.handleChange.bind(this);
    this.deleteConfirmToggle = this.deleteConfirmToggle.bind(this);
    this.deleteToggle = this.deleteToggle.bind(this);
    this.setDelete = this.setDelete.bind(this);

    this.sendToggle = this.sendToggle.bind(this);
    this.deleteInvConfirmToggle = this.deleteInvConfirmToggle.bind(this);
    this.deleteInvToggle = this.deleteInvToggle.bind(this);
    this.reSendToggle = this.reSendToggle.bind(this);
    this.reSendConfirmToggle = this.reSendConfirmToggle.bind(this);
    this.saveInvitationSend = this.saveInvitationSend.bind(this);
    this.setInvDelete = this.setInvDelete.bind(this);
    this.setResend = this.setResend.bind(this);
    this.toggleRole = this.toggleRole.bind(this);
    this.saveUserRoleAdd = this.saveUserRoleAdd.bind(this);
    this.toggleStaffModal = this.toggleStaffModal.bind(this);
    this.invokeUsersUpdate = this.invokeUsersUpdate.bind(this);
  }

  public setDelete(event: any): void {
    this._setStates("uuid", event.uuid);
    this.deleteToggle();
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  public handleChange(e: any): void {
    this._setStates(e.target.name, e.target.value);
    if (!!validationService.validateEmail(e.target.value)) {
      this.setState({
        email: e.target.value,
        validate: false,
      });
    } else {
      this.setState({
        email: e.target.value,
        validate: true,
      });
    }
  }

  public saveInvitationSend(): void {
    this.props.sendUserInvitation({
      restaurantuuid: this.state.restaurantuuid,
      data: {
        email: this.state.email,
      },
    });
    this._setStates("isUpdated", true);
  }

  public deleteInvToggle(): void {
    this.setState((prevState: any) => ({
      invitedeletemodal: !prevState.invitedeletemodal,
    }));
  }

  public deleteInvConfirmToggle(): void {
    this.props.removeUserInvitation({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
    });
    this._setStates("isUpdated", true);
    this.deleteInvToggle();
  }

  public reSendToggle(): void {
    this.setState((prevState: any) => ({
      resendmodal: !prevState.resendmodal,
    }));
  }

  public reSendConfirmToggle(): void {
    this.props.reSendUserInvitation({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
    });
    this.reSendToggle();
  }

  public sendToggle(): void {
    this.setState((prevState: any) => ({
      sendmodal: !prevState.sendmodal,
      email: "",
    }));
  }

  public setInvDelete(event: any): void {
    this._setStates("uuid", event.uuid);
    this.deleteInvToggle();
  }

  public setResend(event: any): void {
    this._setStates("uuid", event.uuid);
    this.reSendToggle();
  }

  public toggleStaffModal(event: any): void {
    this.setState((prevState: any) => ({
      staffModal: !prevState.staffModal,
    }));
  }

  public invokeUsersUpdate(): void {
    this.props.getUsers({ restaurantuuid: this.props.match.params.uuid });
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.role.isFetching ||
    state.users.isFetching ||
    state.usersremove.isFetching ||
    state.userinvitation.isFetching ||
    state.userroleadd.isFetching ||
    state.userinvitationremove.isFetching ||
    state.userinvitationadd.isFetching ||
    state.userinvitationresend.isFetching;
  let failure =
    state.role.failure ||
    state.users.failure ||
    state.usersremove.failure ||
    state.userinvitation.failure ||
    state.userinvitationremove.failure ||
    state.userroleadd.failure ||
    state.userinvitationadd.failure ||
    state.userinvitationresend.failure;

  return {
    rolelist: state.role.data,
    roleadd: state.userroleadd.data,
    invitation: state.userinvitation.data,
    user: state.users.data,
    isFetching: isFetching,
    failure: failure,
    invitationStatus: state.userinvitationadd.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getRoleList: (credentials: any) => {
      dispatch(rolesRequest(credentials));
    },
    getUsers: (credentials: any) => {
      dispatch(usersRequest(credentials));
    },
    removeUser: (credentials: any) => {
      dispatch(usersRemove(credentials));
    },
    getUserInvitation: (credentials: any) => {
      dispatch(userInvitationRequest(credentials));
    },
    reSendUserInvitation: (credentials: any) => {
      dispatch(userInvitationResend(credentials));
    },
    sendUserInvitation: (credentials: any) => {
      dispatch(userInvitationAdd(credentials));
    },
    removeUserInvitation: (credentials: any) => {
      dispatch(userInvitationRemove(credentials));
    },
    addUserRoles: (credentials: any) => {
      dispatch(userRoleRequest(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersComponent))
);
