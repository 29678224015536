export interface ChatModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export const ChatModelInitState = {
  isFetching: false,
  data: [],
  error: {},
  failure: false,
};

export interface ChatState {
  chatInit:any
}

export const ChatInitState = {
  chatInit:""
};

