import "../../styles/transport.scss";

import React from "react";
import { Col, Row } from "reactstrap";
import SettingsHeaderComponent from "../navigation/navigation-header/settings-header/settings-header";
import UsersLeftComponent from "../navigation/navigation-left/users-navigation/users-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import ModalContainer from "react-modal-promise";

export const UsersMenuLayout: React.FC<any> = (props) => {
  return (
    <div>
      <ModalContainer />
      <div className="container-fluid">
        <SettingsHeaderComponent settings={"settings"} />
        <Row className="main light-theme">
          <Col xl={2} lg={3} className="hide-left-max">
            <UsersLeftComponent
              display={props.display}
              restaurantuuid={props.restaurantUuid}
            />
          </Col>
          <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
            {props.children}
            {/* content here */}
          </Col>
          <Col xl={2} lg={3}>
            <NavigationRightComponent />
          </Col>
        </Row>
      </div>
    </div>
  );
};
