import "../../../styles/storage.scss";

import React from "react";
import {
  Button,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function StorageAddCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <Modal isOpen={props.state.addstoragemodal}>
      <ModalHeader toggle={props.addStorageToggle} className="modal-header">
        {t("storage.addStorage")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstname">{t("storage.storageName")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name="storagename"
              autoComplete="storagename"
              placeholder={t("storage.enterStorageName")}
              value={props.state.storagename}
              onChange={props.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="stationuuid">{t("room.room")}</Label>
            <Label className="required-star-style">*</Label>
            <CustomInput
              type="select"
              id="roomuuid"
              name="roomuuid"
              onChange={props.handleRoomChange}
            >
              <option value="">{t("room.selectRoom")}...</option>
              {props.roomList}
            </CustomInput>
          </FormGroup>
          <FormGroup>
            <Label for="station">{t("station.responsibleStation")}</Label>
            <Label className="required-star-style">*</Label>
            <CustomInput
              type="select"
              id="station"
              name="station"
              onChange={props.handleChange}
            >
              <option value="">{t("station.selectStation")}...</option>
              {props.getStationList()}
            </CustomInput>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.addStorageToggle}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={props.saveStorageAdd}
          disabled={
            !props.state.storagename ||
            !props.state.roomuuid ||
            !props.state.station ||
            !!props.state.isFetching
          }
        >
          {t("common.save")}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
