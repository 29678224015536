export interface GuestGroupModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export const GuestGroupModelInitState = {
  isFetching: false,
  data: [],
  error: {},
  failure: false,
};
