import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import {
    taskAddFailure,
    taskAddSuccess,
    taskFailure,
    taskIngredientAddFailure,
    taskIngredientAddSuccess,
    taskIngredientFailure,
    taskIngredientRemoveFailure,
    taskIngredientRemoveSuccess,
    taskIngredientSuccess,
    taskIngredientUpdateFailure,
    taskIngredientUpdateSuccess,
    taskRemoveFailure,
    taskRemoveSuccess,
    taskSuccess,
    taskUpdateFailure,
    taskUpdateSuccess,
} from '../redux/actions/task.action';
import * as types from '../redux/actions/types';
import handleResponse from '../services/response.service';
import task from '../services/task.service';

const taskCall = (params: any) => task.task(RESTAURANT_REQUEST, params);
const updateTaskCall = (params: any) => task.updateTask(RESTAURANT_REQUEST, params);
const addTaskCall = (params: any) => task.addTask(RESTAURANT_REQUEST, params);
const removeTaskCall = (params: any) => task.removeTask(RESTAURANT_REQUEST, params);
const taskIngredientCall = (params: any) => task.taskIngredients(RESTAURANT_REQUEST, params);
const addTaskIngredientCall = (params: any) => task.addTaskIngredients(RESTAURANT_REQUEST, params);
const removeTaskIngredientCall = (params: any) => task.removeTaskIngredients(RESTAURANT_REQUEST, params);
const updateTaskIngredientCall = (params: any) => task.updateTaskIngredients(RESTAURANT_REQUEST, params);

function* handleTaskRequest(params: object) {
    try {
        let result;
        result = yield call(taskCall, params);
        yield handleTask200(result);
    } catch (error) {
        throw error;
    }
}
const handleTask200 = function* handleTask200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskSuccess(result.data));
    } else {
        yield put(taskFailure(result.data));
        handleResponse(result);
    }
}

function* handleTaskUpdate(params: object) {
    try {
        let result;
        result = yield call(updateTaskCall, params);
        yield handleTaskUpdate200(result);
    } catch (error) {
        throw error;
    }
}

const handleTaskUpdate200 = function* handleTaskUpdate200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskUpdateSuccess(result.data));
    } else {
        yield put(taskUpdateFailure(result.data));
    }
    handleResponse(result);
}

function* handleTaskAdd(params: object) {
    try {
        let result;
        result = yield call(addTaskCall, params);
        yield handleTaskAdd200(result);
    } catch (error) {
        throw error;
    }
}

const handleTaskAdd200 = function* handleTaskAdd200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskAddSuccess(result.data));
    } else {
        yield put(taskAddFailure(result.data));
    }
    handleResponse(result);
}

// Task Remove

function* handleTaskRemove(params: object) {
    try {
        let result;
        result = yield call(removeTaskCall, params);
        yield handleTaskRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handleTaskRemove200 = function* handleTaskRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskRemoveSuccess(result.data));
    } else {
        yield put(taskRemoveFailure(result.data));
    }
    handleResponse(result);
}

// Task Ingredient Get

function* handleTaskIngredientRequest(params: object) {
    try {
        let result;
        result = yield call(taskIngredientCall, params);
        yield handleTaskIngredient200(result);
    } catch (error) {
        throw error;
    }
}
const handleTaskIngredient200 = function* handleTaskIngredient200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskIngredientSuccess(result.data));
    } else {
        yield put(taskIngredientFailure(result.data));
        handleResponse(result);
    }
}

function* handleTaskIngredientAdd(params: object) {
    try {
        let result;
        result = yield call(addTaskIngredientCall, params);
        yield handleTaskIngredientAdd200(result);
    } catch (error) {
        throw error;
    }
}

const handleTaskIngredientAdd200 = function* handleTaskIngredientAdd200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskIngredientAddSuccess(result.data));
    } else {
        yield put(taskIngredientAddFailure(result.data));
    }
    handleResponse(result);
}

function* handleTaskIngredientRemove(params: object) {
    try {
        let result;
        result = yield call(removeTaskIngredientCall, params);
        yield handleTaskIngredientRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handleTaskIngredientRemove200 = function* handleTaskIngredientRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskIngredientRemoveSuccess(result.data));
    } else {
        yield put(taskIngredientRemoveFailure(result.data));
    }
    handleResponse(result);
}

function* handleTaskIngredientUpdate(params: object) {
    try {
        let result;
        result = yield call(updateTaskIngredientCall, params);
        yield handleTaskIngredientUpdate200(result);
    } catch (error) {
        throw error;
    }
}

const handleTaskIngredientUpdate200 = function* handleTaskIngredientUpdate200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(taskIngredientUpdateSuccess(result.data));
    } else {
        yield put(taskIngredientUpdateFailure(result.data));
    }
    handleResponse(result);
}

export default function* taskSaga() {
    yield takeLatest(types.TASK.REQUEST, handleTaskRequest);
    yield takeLatest(types.TASK_UPDATE.REQUEST, handleTaskUpdate);
    yield takeLatest(types.TASK_ADD.REQUEST, handleTaskAdd);
    yield takeLatest(types.TASK_REMOVE.REQUEST, handleTaskRemove);
    yield takeLatest(types.TASK_INGREDIENT.REQUEST, handleTaskIngredientRequest);
    yield takeLatest(types.TASK_INGREDIENT_ADD.REQUEST, handleTaskIngredientAdd);
    yield takeLatest(types.TASK_INGREDIENT_REMOVE.REQUEST, handleTaskIngredientRemove);
    yield takeLatest(types.TASK_INGREDIENT_UPDATE.REQUEST, handleTaskIngredientUpdate);
}
