import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Label, Row } from "reactstrap";
import { getSyntheticTrailingComments } from "typescript";
import { GRAY, GREEN } from "../../../constant/constant";
import commonService from "../../../services/common.service";
import "../../../styles/units.scss";

export default function TableCard(props: any) {
  const { t, i18n } = useTranslation();
  const getStation = (stationId: any) => {
    if( Object.keys(props.stations).length > 0 && 
    props.stations.filter((station: any) => station.uuid === stationId)){
      const tableLocale = commonService.applyLocalization(
        "restaurant",
        "name",
        props.stations.filter((station: any) => station.uuid === stationId)[0].locales
      );
      return tableLocale.name;
    }
  };
  return (
    <div className="child-box mb-3">
      <div className="d-flex justify-content-between">
        <div>
          <h6 className="m-0">{props.locales.name}</h6>
        </div>
        <div>
          <strong>{t("station.station")}:</strong>{" "}
          {getStation(props.locales.station)}
        </div>
        <div>
          <div>
            <div className="mr-2">
              <span
                onClick={props.setEditable}
                className="pointer-cursor"
                data-toggle="tooltip"
                data-placement="left"
                title={t("common.edit")}
              >
                <FontAwesomeIcon
                  style={{ color: GREEN, fontSize: 20 }}
                  icon={faEdit}
                />
              </span>
              <span
                style={{ margin: 10 }}
                onClick={props.setDelete}
                className="pointer-cursor"
                data-toggle="tooltip"
                data-placement="left"
                title={t("common.delete")}
              >
                <FontAwesomeIcon
                  style={{ color: GRAY, fontSize: 20 }}
                  icon={faTrashAlt}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
