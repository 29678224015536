import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  allRecipeFailure,
  allRecipeSuccess,
  recipeAddFailure,
  recipeAddSuccess,
  recipeCopyFailure,
  recipeCopySuccess,
  recipeDetailsFailure,
  recipeDetailsSuccess,
  recipeEditFailure,
  recipeEditSuccess,
  recipeFailure,
  recipeItemsFailure,
  recipeItemsSuccess,
  recipeIngredientAddFailure,
  recipeIngredientAddSuccess,
  recipeIngredientFailure,
  recipeIngredientRemoveFailure,
  recipeIngredientRemoveSuccess,
  recipeIngredientSuccess,
  recipeIngredientUpdateFailure,
  recipeIngredientUpdateSuccess,
  recipePublishFailure,
  recipePublishSuccess,
  recipeRemoveFailure,
  recipeRemoveSuccess,
  recipeSuccess,
  recipeUpdateFailure,
  recipeUpdateSuccess,
} from "../redux/actions/recipe.action";
import * as types from "../redux/actions/types";
import recipe from "../services/recipe.service";
import handleResponse from "../services/response.service";

const publishRecipeCall = (params: any) =>
  recipe.publishRecipe(RESTAURANT_REQUEST, params);
const editRecipeCall = (params: any) =>
  recipe.editRecipe(RESTAURANT_REQUEST, params);
const recipeCall = (params: any) => recipe.recipe(RESTAURANT_REQUEST, params);
const updateRecipeCall = (params: any) =>
  recipe.updateRecipe(RESTAURANT_REQUEST, params);
const addRecipeCall = (params: any) =>
  recipe.addRecipe(RESTAURANT_REQUEST, params);
const removeRecipeCall = (params: any) =>
  recipe.removeRecipe(RESTAURANT_REQUEST, params);
const recipeIngredientCall = (params: any) =>
  recipe.recipeIngredient(RESTAURANT_REQUEST, params);
const addRecipeIngredientCall = (params: any) =>
  recipe.addRecipeIngredient(RESTAURANT_REQUEST, params);
const removeRecipeIngredientCall = (params: any) =>
  recipe.removeRecipeIngredient(RESTAURANT_REQUEST, params);
const updateRecipeIngredientCall = (params: any) =>
  recipe.updateIngredientRecipe(RESTAURANT_REQUEST, params);
const recipeDetailsCall = (params: any) =>
  recipe.recipeDetails(RESTAURANT_REQUEST, params);
const recipeAllCall = (params: any) =>
  recipe.allRecipe(RESTAURANT_REQUEST, params);
const copyRecipeCall = (params: any) =>
  recipe.recipeCopy(RESTAURANT_REQUEST, params);

const allRecipeItemsCall = (params: any) =>
  recipe.recipeItems(RESTAURANT_REQUEST, params);

function* handleRecipeRequest(params: object) {
  try {
    let result;
    result = yield call(recipeCall, params);
    yield handleRecipe200(result);
  } catch (error) {
    throw error;
  }
}

const handleRecipe200 = function* handleRecipe200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(recipeSuccess(result.data));
  } else {
    yield put(recipeFailure(result.data));
    handleResponse(result);
  }
};

function* handleRecipePublish(params: object) {
  try {
    let result;
    result = yield call(publishRecipeCall, params);
    yield handleRecipePublish200(result);
  } catch (error) {
    throw error;
  }
}

const handleRecipePublish200 = function* handleRecipePublish200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(recipePublishSuccess(result.data));
  } else {
    yield put(recipePublishFailure(result.data));
  }
  handleResponse(result);
};

function* handleRecipeUpdate(params: object) {
  try {
    let result;
    result = yield call(updateRecipeCall, params);
    yield handleRecipeUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleRecipeUpdate200 = function* handleRecipeUpdate200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(recipeUpdateSuccess(result.data));
  } else {
    yield put(recipeUpdateFailure(result.data));
  }
  handleResponse(result);
};

function* handleRecipeAdd(params: object) {
  try {
    let result;
    result = yield call(addRecipeCall, params);
    yield handleRecipeAdd200(result);
  } catch (error) {
    throw error;
  }
}

const handleRecipeAdd200 = function* handleRecipeAdd200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(recipeAddSuccess(result.data));
  } else {
    yield put(recipeAddFailure(result.data));
  }
  handleResponse(result);
};

function* handleRecipeEdit(params: object) {
  try {
    let result;
    result = yield call(editRecipeCall, params);
    yield handleRecipeEdit200(result);
  } catch (error) {
    throw error;
  }
}

const handleRecipeEdit200 = function* handleRecipeEdit200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(recipeEditSuccess(result.data));
  } else {
    yield put(recipeEditFailure(result.data));
  }
  handleResponse(result);
};

function* handleRecipeRemove(params: object) {
  try {
    let result;
    result = yield call(removeRecipeCall, params);
    yield handleRecipeRemove200(result);
  } catch (error) {
    throw error;
  }
}

const handleRecipeRemove200 = function* handleRecipeRemove200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(recipeRemoveSuccess(result.data));
  } else {
    yield put(recipeRemoveFailure(result.data));
  }
  handleResponse(result);
};

function* handleRecipeIngredientRequest(params: object) {
  try {
    let result;
    result = yield call(recipeIngredientCall, params);
    yield handleRecipeIngredient200(result);
  } catch (error) {
    throw error;
  }
}

const handleRecipeIngredient200 = function* handleRecipeIngredient200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(recipeIngredientSuccess(result.data));
  } else {
    yield put(recipeIngredientFailure(result.data));
    handleResponse(result);
  }
};

function* handleRecipeIngredientAdd(params: object) {
  try {
    let result;
    result = yield call(addRecipeIngredientCall, params);
    yield handleRecipeIngredientAdd200(result);
  } catch (error) {
    throw error;
  }
}

const handleRecipeIngredientAdd200 = function* handleRecipeAddIngredient200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(recipeIngredientAddSuccess(result.data));
  } else {
    yield put(recipeIngredientAddFailure(result.data));
  }
  handleResponse(result);
};

function* handleRecipeIngredientRemove(params: object) {
  try {
    let result;
    result = yield call(removeRecipeIngredientCall, params);
    yield handleRecipeIngredientRemove200(result);
  } catch (error) {
    throw error;
  }
}

const handleRecipeIngredientRemove200 =
  function* handleRecipeIngredientRemove200(result: any) {
    if (!result) {
      return;
    }
    if (result.status === 200) {
      yield put(recipeIngredientRemoveSuccess(result.data));
    } else {
      yield put(recipeIngredientRemoveFailure(result.data));
    }
    handleResponse(result);
  };

function* handleRecipeIngredientUpdate(params: object) {
  try {
    let result;
    result = yield call(updateRecipeIngredientCall, params);
    yield handleRecipeIngredientUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleRecipeIngredientUpdate200 =
  function* handleRecipeIngredientUpdate200(result: any) {
    if (!result) {
      return;
    }
    if (result.status === 200) {
      yield put(recipeIngredientUpdateSuccess(result.data));
    } else {
      yield put(recipeIngredientUpdateFailure(result.data));
    }
    handleResponse(result);
  };

function* handleRecipeDetailsRequest(params: object) {
  try {
    let result;
    result = yield call(recipeDetailsCall, params);
    yield handleRecipeDetails200(result);
  } catch (error) {
    throw error;
  }
}

const handleRecipeDetails200 = function* handleRecipeDetails200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(recipeDetailsSuccess(result.data));
  } else {
    yield put(recipeDetailsFailure(result.data));
    handleResponse(result);
  }
};

function* handleRecipeAllRequest(params: object) {
  try {
    let result;
    result = yield call(recipeAllCall, params);
    yield handleRecipAll200(result);
  } catch (error) {
    throw error;
  }
}

const handleRecipAll200 = function* handleRecipAll200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(allRecipeSuccess(result.data));
  } else {
    yield put(allRecipeFailure(result.data));
    handleResponse(result);
  }
};

function* handleRecipeCopy(params: object) {
  try {
    let result;
    result = yield call(copyRecipeCall, params);
    yield handleRecipeCopy200(result);
  } catch (error) {
    throw error;
  }
}

const handleRecipeCopy200 = function* handleRecipeCopy200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(recipeCopySuccess(result.data));
  } else {
    yield put(recipeCopyFailure(result.data));
  }
  handleResponse(result);
};

function* handleAllRecipeItemsRequest(params: object) {
  try {
    let result;
    result = yield call(allRecipeItemsCall, params);
    yield handleAllRecipeItems200(result);
  } catch (error) {
    throw error;
  }
}

const handleAllRecipeItems200 = function* handleAllRecipeItems200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(recipeItemsSuccess(result.data));
  } else {
    yield put(recipeItemsFailure(result.data));
    handleResponse(result);
  }
};

export default function* recipeSaga() {
  yield takeLatest(types.RECIPE_ITEMS.REQUEST, handleAllRecipeItemsRequest);
  yield takeLatest(types.RECIPE.REQUEST, handleRecipeRequest);
  yield takeLatest(types.RECIPE_UPDATE.REQUEST, handleRecipeUpdate);
  yield takeLatest(types.RECIPE_ADD.REQUEST, handleRecipeAdd);
  yield takeLatest(types.RECIPE_REMOVE.REQUEST, handleRecipeRemove);
  yield takeLatest(
    types.RECIPE_INGREDIENT.REQUEST,
    handleRecipeIngredientRequest
  );
  yield takeLatest(
    types.RECIPE_INGREDIENT_ADD.REQUEST,
    handleRecipeIngredientAdd
  );
  yield takeLatest(
    types.RECIPE_INGREDIENT_REMOVE.REQUEST,
    handleRecipeIngredientRemove
  );
  yield takeLatest(
    types.RECIPE_INGREDIENT_UPDATE.REQUEST,
    handleRecipeIngredientUpdate
  );
  yield takeEvery(types.RECIPE_DETAILS.REQUEST, handleRecipeDetailsRequest);
  yield takeLatest(types.RECIPE_ALL.REQUEST, handleRecipeAllRequest);
  yield takeLatest(types.RECIPE_COPY.REQUEST, handleRecipeCopy);
  yield takeLatest(types.RECIPE_PUBLISH.REQUEST, handleRecipePublish);
  yield takeLatest(types.RECIPE_EDIT.REQUEST, handleRecipeEdit);
}
