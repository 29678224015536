export interface RestaurantModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
  readonly languages: any;
}
export interface RestaurantState {
  addmodal: boolean;
  address: string;
  city: string;
  copymodel: boolean;
  country: any;
  currentLanguage: string;
  description: string;
  editmodal: boolean;
  email: string;
  errors: any;
  filedata: any;
  image_url: string;
  imagemodal: boolean;
  isFetching: boolean;
  isUpdated: boolean;
  language: any;
  name: any;
  open: boolean;
  phone: string;
  restaurant: any;
  uuid: string;
  vatCode: string;
  zip: string;
}

export const RestaurantInitState = {
  addmodal: false,
  address: "",
  city: "",
  copymodel: false,
  country: {},
  currentLanguage: "",
  description: "",
  editmodal: false,
  email: "",
  errors: {},
  filedata: [],
  image_url: "",
  imagemodal: false,
  isFetching: false,
  isUpdated: false,
  language: "",
  name: "",
  open: false,
  phone: "",
  restaurant: "",
  uuid: "",
  vatCode: "",
  zip: "",
};
