import "../../styles/printer.scss";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { PrinterInitState, PrinterState } from "../../models/printer.model";
import {
  printerAdd,
  printerRemove,
  printerRequest,
  printerUpdate,
} from "../../redux/actions/printer.action";
import { printerProtocolRequest } from "../../redux/actions/printer.protocol.action";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import PrinterAddCard from "../card-components/printer-add-card/printer-add-card";
import PrinterCard from "../card-components/printer-card/printer-card";
import PrinterEditCard from "../card-components/printer-edit-card/printer-edit-card";
import LoaderComponent from "../loader/loader";
import SettingsHeaderComponent from "../navigation/navigation-header/settings-header/settings-header";
import SettingsNavComponent from "../navigation/navigation-left/settings-navigation/settings-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import i18next from "i18next";
import { withTranslation } from "react-i18next";

class PrinterComponent extends Component<any> {
  state: PrinterState;
  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.state = PrinterInitState;
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <SettingsHeaderComponent settings={"settings"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <SettingsNavComponent
                display={"printer"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={!!localStorage.getItem("WEBVIEW") ? 10 : 8} lg={6}>
              {this.state.printer.length === 0 && (
                <Link
                  to="#"
                  color="info"
                  className="printer-add"
                  onClick={this.addPrinterToggle}
                >
                  {t("printer.addPrinter")}
                </Link>
              )}

              <div className="white-box mb-3 shadow">
                <h4>{t("printer.printers")}</h4>
                {this.printerList(this.state.printer)}
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>

        {/*  Add Printer Modal */}

        <PrinterAddCard
          state={this.state}
          addPrinterToggle={this.addPrinterToggle}
          handleChange={this.handleChange}
          handleProtocolChange={this.handleProtocolChange.bind(this)}
          savePrinterAdd={this.savePrinterAdd}
          OPTIONS={this.protocolList(this.state.protocol)}
        />

        {/*  Edit Printer Modal */}

        <PrinterEditCard
          state={this.state}
          editPrinterToggle={this.editPrinterToggle}
          handleChange={this.handleChange}
          handleProtocolChange={this.handleProtocolChange.bind(this)}
          savePrinterEdit={this.savePrinterEdit}
          OPTIONS={this.protocolList(this.state.protocol)}
        />

        {/* Dlete Printer Modal*/}

        <DeleteModalCard
          isOpen={this.state.deletemodal}
          isFetching={!!this.state.isFetching}
          okDelete={this.okDeletePrinter}
          cancelDelete={this.deletePrinterToggle}
        />
      </div>
    );
  }

  public printerList(printerData: any) {
    let printer;
    if (!!printerData && printerData.length > 0) {
      printer = printerData.map((locales: any, i: number) => {
        return (
          <PrinterCard
            key={i}
            printer={locales}
            printerprotocol={this.state.protocol}
            setPrinterEditable={this.setPrinterEditable.bind(this, locales)}
            setPrinterDelete={this.setPrinterDelete.bind(this, locales)}
          ></PrinterCard>
        );
      });
      return printer;
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.props.t("common.noRecords")}
        </div>
      );
    }
  }

  public protocolList(protocolData: any) {
    let protocols;
    if (!!protocolData) {
      protocols = protocolData.map((protocol: any, i: number) => {
        return (
          <option key={i} value={protocol.uuid}>
            {protocol.name}
          </option>
        );
      });
      return protocols;
    }
  }

  componentDidMount() {
    document.body.className = "light-theme";
    if (!this.state.isFetching) {
      this.props.printer({ restaurantuuid: this.props.match.params.uuid });
    }
    if (!this.state.isFetching) {
      this.props.printerProtocol({
        restaurantuuid: this.props.match.params.uuid,
      });
    }
    this._setStates("restaurantuuid", this.props.match.params.uuid);
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
    }
    if (!!this.state.isUpdated && !newProps.failure && !newProps.isFetching) {
      this.props.printer({ restaurantuuid: this.props.match.params.uuid });
      this._setStates("isUpdated", false);
    }
    if (!!newProps.printers && !newProps.isFetching && !newProps.failure) {
      this._setStates("printer", newProps.printers);
    }
    if (
      !!newProps.printerprotocol &&
      !newProps.isFetching &&
      !newProps.failure
    ) {
      this._setStates("protocol", newProps.printerprotocol);
    }
    if (
      !!newProps.printerupdate.status &&
      !!this.state.editmodal &&
      !newProps.isFetching
    ) {
      this.editPrinterToggle();
    }
    if (
      !!newProps.printeradd.status &&
      !!this.state.addmodal &&
      !newProps.isFetching
    ) {
      this.addPrinterToggle();
    }
  }

  // Function binding

  private _setFunctionBindings(): void {
    this.deletePrinterToggle = this.deletePrinterToggle.bind(this);
    this.okDeletePrinter = this.okDeletePrinter.bind(this);
    this.addPrinterToggle = this.addPrinterToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.savePrinterAdd = this.savePrinterAdd.bind(this);
    this.editPrinterToggle = this.editPrinterToggle.bind(this);
    this.savePrinterEdit = this.savePrinterEdit.bind(this);
  }

  public handleChange(e: any): void {
    this._setStates(e.target.name, e.target.value);
  }

  public handleProtocolChange(e: any): void {
    this.state.protocol.forEach((protocol: any) => {
      if (protocol.uuid === e.target.value) {
        this.setState({
          printerprotocoluuid: protocol.uuid,
        });
      } else if (e.target.value === "") {
        this.setState({
          printerprotocoluuid: "",
        });
      }
    });
  }

  public editPrinterToggle(): void {
    this.setState((prevState: any) => ({
      editmodal: !prevState.editmodal,
    }));
  }

  public setPrinterEditable(event: any): void {
    this.setState({
      uuid: event.uuid,
      name: event.name,
      printerip: event.printer_ip,
      printerport: event.printer_port,
      poslisteningport: event.pos_listening_port,
      printerprotocoluuid: event.printer_protocol_uuid,
    });
    this.editPrinterToggle();
  }

  public savePrinterEdit(): void {
    this.props.updatePrinter({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
      data: {
        name: this.state.name,
        printer_ip: this.state.printerip,
        printer_port: this.state.printerport,
        pos_listening_port: this.state.poslisteningport,
        printer_protocol_uuid: this.state.printerprotocoluuid,
      },
    });
    this._setStates("isUpdated", true);
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  public addPrinterToggle(): void {
    this.setState((prevState: any) => ({
      addmodal: !prevState.addmodal,
      name: "",
      printerip: "",
      printerport: "",
      poslisteningport: "",
      printerprotocoluuid: "",
    }));
  }

  public savePrinterAdd(): void {
    this.props.addPrinter({
      restaurantuuid: this.state.restaurantuuid,
      data: {
        name: this.state.name,
        printer_ip: this.state.printerip,
        printer_port: this.state.printerport,
        pos_listening_port: this.state.poslisteningport,
        printer_protocol_uuid: this.state.printerprotocoluuid,
      },
    });
    this._setStates("isUpdated", true);
  }

  public setPrinterDelete(event: any): void {
    this._setStates("uuid", event.uuid);
    this.deletePrinterToggle();
  }

  public deletePrinterToggle(): void {
    this.setState((prevState: any) => ({
      deletemodal: !prevState.deletemodal,
    }));
  }

  public okDeletePrinter(): void {
    this.props.removePrinter({
      uuid: this.state.uuid,
      restaurantuuid: this.state.restaurantuuid,
    });
    this._setStates("isUpdated", true);
    this.deletePrinterToggle();
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.printer.isFetching ||
    state.printeradd.isFetching ||
    state.printerremove.isFetching ||
    state.printerupdate.isFetching ||
    state.printerprotocol.isFetching;
  let failure =
    state.printer.failure ||
    state.printeradd.failure ||
    state.printerremove.failure ||
    state.printerupdate.failure ||
    state.printerprotocol.failure;
  return {
    printers: state.printer.data,
    printeradd: state.printeradd.data,
    printerupdate: state.printerupdate.data,
    printerprotocol: state.printerprotocol.data,
    isFetching: isFetching,
    failure: failure,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    printer: (credentials: any) => {
      dispatch(printerRequest(credentials));
    },
    removePrinter: (credentials: any) => {
      dispatch(printerRemove(credentials));
    },
    addPrinter: (credentials: any) => {
      dispatch(printerAdd(credentials));
    },
    updatePrinter: (credentials: any) => {
      dispatch(printerUpdate(credentials));
    },
    printerProtocol: (credentials: any) => {
      dispatch(printerProtocolRequest(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(PrinterComponent))
);
