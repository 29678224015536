import {
  faChevronDown,
  faChevronRight,
  faEdit,
  faPlusCircle,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Collapse,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { GRAY, GREEN } from "../../../constant/constant";
import i18n from "../../../i18n";
import commonService from "../../../services/common.service";
import { empty } from "ramda";

const getLocationType = (type: string) => {
  switch (type) {
    case "room":
      return i18n.t("machine.room");
    case "station":
      return i18n.t("machine.station");
    case "storage":
      return i18n.t("machine.storage");
    case "rack":
      return i18n.t("machine.rack");
    case "place":
      return i18n.t("machine.place");
  }
};

const getLocationName = (locales) => {
  let locale = commonService.applyLocalization("restaurant", "name", locales);
  return locale;
};

export default function MachineCard(props: any) {
  const { t, i18n } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const toggleCollapse = () => {
    if (!isOpen) {
      props.getKeyValuePairs(props.machine.uuid);
    }
    setOpen((prevState: boolean) => {
      return !prevState;
    });
  };
  return (
    <div className="child-box mb-3">
      <Row className="app-row-padding">
        <Col>
          <h6>{props.machineLocales.name}</h6>
        </Col>
        <div style={{ float: "right" }}>
          <span
            onClick={props.setMachineEditable}
            className="pointer-cursor"
            data-toggle="tooltip"
            data-placement="left"
            title={t("common.edit")}
          >
            <FontAwesomeIcon
              style={{ color: GREEN, fontSize: 20 }}
              icon={faEdit}
            />
          </span>
          <span
            style={{ margin: 10 }}
            onClick={props.setMachineDelete}
            className="pointer-cursor"
            data-toggle="tooltip"
            data-placement="left"
            title={t("common.delete")}
          >
            <FontAwesomeIcon
              style={{ color: GRAY, fontSize: 20 }}
              icon={faTrashAlt}
            />
          </span>
        </div>
      </Row>
      <Row className="app-row-padding">
        <Col>
          <Row className="pl-3">
            <Label>{t("machine.location")}:</Label>{" "}
            <span>
              {props.machine.locations.map((loc) => {
                if (loc.locationType) {
                  return (
                    <>
                      {`${getLocationType(loc.locationType)} - ${
                        getLocationName(loc.locationLocales).name
                      }`}
                      <br />
                    </>
                  );
                }
              })}
            </span>
          </Row>
        </Col>
      </Row>
      <Row className="app-row-padding">
        <Col>
          <Label>{t("common.username")}:</Label> {props.machine?.username || ""}
        </Col>
      </Row>
      <Row className="app-row-padding">
        <Col className="p-0  mt-2">
          <header
            className="cursor-pointer btn btn-link d-flex align-items-center"
            onClick={toggleCollapse}
          >
            <FontAwesomeIcon
              className="bf-accordion-arrow mr-2"
              icon={isOpen ? faChevronDown : faChevronRight}
            />
            <div>{t("printer.keyValuePairs")}</div>
          </header>
          <Collapse isOpen={isOpen} className="px-2">
            <FormGroup>
              <div className="b-minHeight-100">
                {props.keyValuePairs?.map((item: any, index: any) => {
                  return (
                    !item?.isDeleted && (
                      <div
                        className="d-flex align-items-center mb-2"
                        key={index}
                      >
                        <Input
                          name={"newKey_" + index}
                          placeholder={t("printer.enterKey")}
                          min="0"
                          value={item.key}
                          readOnly={!item?.isNew}
                          onChange={(e: any) =>
                            props.handleKeyValueChange(
                              e,
                              index,
                              props.machine.uuid,
                              "edit"
                            )
                          }
                          className="mr-2 w-25"
                        />
                        <Input
                          name={"newValue_" + index}
                          placeholder={t("printer.enterVal")}
                          min="0"
                          value={item.value}
                          onChange={(e: any) =>
                            props.handleKeyValueChange(
                              e,
                              index,
                              props.machine.uuid,
                              "edit"
                            )
                          }
                          className="mr-2 w-75"
                        />
                        <FontAwesomeIcon
                          style={{ fontSize: 20 }}
                          icon={faTimes}
                          className="b-cursor-p"
                          onClick={() =>
                            props.handleKeyValues(
                              "delete",
                              props.machine.uuid,
                              index
                            )
                          }
                        />
                      </div>
                    )
                  );
                })}
                <div className="d-flex align-items-center">
                  <Input
                    name="newKey"
                    placeholder={t("printer.enterKey")}
                    min="0"
                    value={props.newKey}
                    onChange={(e: any) =>
                      props.handleKeyValueChange(
                        e,
                        0,
                        props.machine.uuid,
                        "new"
                      )
                    }
                    className={`mr-2 w-25 ${
                      props.newKeyError ? "border-danger" : ""
                    }`}
                  />
                  <Input
                    name="newValue"
                    placeholder={t("printer.enterVal")}
                    min="0"
                    value={props.newValue}
                    onChange={(e: any) =>
                      props.handleKeyValueChange(
                        e,
                        0,
                        props.machine.uuid,
                        "new"
                      )
                    }
                    className="mr-2 w-75"
                  />
                  <FontAwesomeIcon
                    style={{ fontSize: 20 }}
                    icon={faPlusCircle}
                    onClick={() =>
                      props.handleKeyValues("new", props.machine.uuid)
                    }
                  />
                </div>
              </div>
              <div className="text-right my-2">
                <Button
                  color="info"
                  onClick={() => props.saveKeyValues(props.machine.uuid)}
                >
                  {t("common.save")}
                </Button>
              </div>
            </FormGroup>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
}
