import { call, put, takeLatest } from "redux-saga/effects";
import { RESTAURANT_REQUEST } from "../config/api.config";
import handleResponse from "../services/response.service";
import content from "../services/content.management.service";
import * as types from "../redux/actions/types";
import {
  contentManagementAddFailure,
  contentManagementAddSuccess,
  contentManagementFailure,
  contentManagementRemoveFailure,
  contentManagementRemoveSuccess,
  contentManagementSuccess,
  contentManagementUpdateFailure,
  contentManagementUpdateSuccess,
} from "../redux/actions/content.management.action";

const addContentCall = (params: any) =>
  content.addContent(RESTAURANT_REQUEST, params);
const removeContentCall = (params: any) =>
  content.removeContent(RESTAURANT_REQUEST, params);
const updateContentCall = (params: any) =>
  content.updateContent(RESTAURANT_REQUEST, params);
const getContentCall = (params: any) =>
  content.getContent(RESTAURANT_REQUEST, params);

function* handleContentManagementRequest(params: object) {
  try {
    let result;
    result = yield call(getContentCall, params);
    yield handleContentManagement200(result);
  } catch (error) {
    throw error;
  }
}

const handleContentManagement200 = function* handleContentManagement200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(contentManagementSuccess(result.data));
  } else {
    yield put(contentManagementFailure(result.data));
    handleResponse(result);
  }
};

function* handleContentManagementUpdate(params: object) {
  try {
    let result;
    result = yield call(updateContentCall, params);
    yield handleContentManagementUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleContentManagementUpdate200 =
  function* handleContentManagementUpdate200(result: any) {
    if (!result) {
      return;
    }
    if (result.status === 200) {
      yield put(contentManagementUpdateSuccess(result.data));
    } else {
      yield put(contentManagementUpdateFailure(result.data));
    }
    handleResponse(result);
  };

function* handleContentManagementAdd(params: object) {
  try {
    let result;
    result = yield call(addContentCall, params);
    yield handleContentManagementAdd200(result);
  } catch (error) {
    throw error;
  }
}

const handleContentManagementAdd200 = function* handleContentManagementAdd200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(contentManagementAddSuccess(result.data));
  } else {
    yield put(contentManagementAddFailure(result.data));
  }
  handleResponse(result);
};

function* handleContentManagementRemove(params: object) {
  try {
    let result;
    result = yield call(removeContentCall, params);
    yield handleContentManagementRemove200(result);
  } catch (error) {
    throw error;
  }
}

const handleContentManagementRemove200 =
  function* handleContentManagementRemove200(result: any) {
    if (!result) {
      return;
    }
    if (result.status === 200) {
      yield put(contentManagementRemoveSuccess(result.data));
    } else {
      yield put(contentManagementRemoveFailure(result.data));
    }
    handleResponse(result);
  };
export default function* contentManagementSaga() {
  yield takeLatest(
    types.CONTENT_MANAGEMENT.REQUEST,
    handleContentManagementRequest
  );
  yield takeLatest(
    types.CONTENT_MANAGEMENT_ADD.REQUEST,
    handleContentManagementAdd
  );
  yield takeLatest(
    types.CONTENT_MANAGEMENT_REMOVE.REQUEST,
    handleContentManagementRemove
  );
  yield takeLatest(
    types.CONTENT_MANAGEMENT_UPDATE.REQUEST,
    handleContentManagementUpdate
  );
}
