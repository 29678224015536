import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "reactstrap";
import {
  TRAFFICLIGHT_GREEN,
  TRAFFICLIGHT_RED,
  TRAFFICLIGHT_YELLOW,
} from "../../constant/constant";
import "./warning-comment.scss";
import i18n from "i18next";

export function getMandatoryWarning(value: any) {
  if (value === null || value === "") {
    return { type: "stop", text: i18n.t("validation.emptyInput") };
  } else {
    return null;
  }
}

class WarningComment extends Component<any, any> {
  tooltipRef: any = [];

  constructor(props: any) {
    super(props);
    this.state = { tooltipOpen: false };
    this._setFunctionBindings();
  }

  private _setFunctionBindings(): void {
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.getTooltip = this.getTooltip.bind(this);
  }

  render() {
    let dataarray: any[] = [];

    if (this.props.data !== null) {
      if (!Array.isArray(this.props.data)) {
        dataarray.push(this.props.data);
      } else {
        for (let i = 0; i < this.props.data.length; i++) {
          if (
            this.props.data[i] !== null &&
            typeof this.props.data[i] !== "undefined"
          ) {
            dataarray.push(this.props.data[i]);
          }
        }
      }
    }

    if (!this.props.displayType || this.props.displayType === "tooltip") {
      for (let j = this.tooltipRef.length; j < dataarray.length; j++) {
        this.tooltipRef.push(React.createRef());
      }

      return dataarray.map((data: any, i) => {
        return (
          <span key={i}>
            <span ref={this.tooltipRef[i]}>
              {this.getIcon(this.getColor(data?.type))}
            </span>
            {this.getTooltip(data.text, this.tooltipRef[i])}
          </span>
        );
      });
    } else if (this.props.displayType === "bottom") {
      return (
        <div className={this.props?.containerClassName || ""}>
          <div className={this.props?.itemWrapperClass || ""}>
            {this.props.children}
          </div>
          {dataarray.map((data: any, i) => {
            return (
              <React.Fragment key={i}>
                <div style={{ float: "left" }} title={data?.text}>
                  {this.getIcon(this.getColor(data?.type))}
                </div>
                {!(this.props?.validationStyle === "iconOnly") && (
                  <div className="WarningComment-Text">{data?.text}</div>
                )}
                <div style={{ clear: "both" }} />
              </React.Fragment>
            );
          })}
        </div>
      );
    }
    return "";
  }

  private getColor(type: any) {
    let color = "#000000";

    switch (type) {
      case "stop":
        color = "red";
        break;
      case "attention":
        color = "yellow";
        break;
      case "recommendation":
        color = "green";
        break;
    }

    if (color === "red") {
      color = TRAFFICLIGHT_RED;
    } else if (color === "yellow") {
      color = TRAFFICLIGHT_YELLOW;
    } else if (color === "green") {
      color = TRAFFICLIGHT_GREEN;
    }
    return color;
  }

  private getIcon(color: any) {
    return (
      <FontAwesomeIcon
        className="WarningComment-Icon"
        style={{ color: color }}
        icon={faExclamationTriangle}
      />
    );
  }

  private getTooltip(text: any, ref: any) {
    if (text !== null && text !== "") {
      return (
        <Tooltip
          className="WarningComment-Tooltip"
          placement="right"
          isOpen={this.state.tooltipOpen}
          target={ref}
          toggle={this.toggleTooltip}
          autohide={false}
          onClick={this.toggleTooltip}
        >
          {text}
        </Tooltip>
      );
    } else {
      return "";
    }
  }

  toggleTooltip() {
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  }
}

export default WarningComment;
