import "../../styles/page404.scss";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Alert, Table } from "reactstrap";

import { withTranslation } from "react-i18next";
import moment from "moment";
import commonService from "../../services/common.service";
class CorrectedPaymentTransactions extends Component<any> {
  renderDebitAccountType = (debitAccountType: number) => {
    switch (debitAccountType) {
      case 1:
        return this.props.t("common.receivables");
      case 2:
        return this.props.t("common.cash");
    }
  };
  render() {
    const { t, data } = this.props;
    return (
      <>
        {!data && <Alert color="info">{t("common.noTransactions")}</Alert>}
        {data?.length > 0 && (
          <>
            <section className="mt-3 mb-5">
              <h5>{t("reports.correctedPaymentTransactions")}</h5>

              <Table bordered striped>
                <thead>
                  <tr className="bg-light">
                    <th className="w-25">{t("reports.timestamp")}</th>
                    <th className="w-25">{t("reports.debitCostCenterKey")}</th>
                    <th className="w-25">{t("reports.debitAccount")}</th>
                    <th className="w-25">{t("reports.creditCostCenterKey")}</th>
                    <th className="w-25">{t("common.currency")}</th>
                    <th className="w-25">{t("common.amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((innerData: any) => {
                    return (
                      <tr>
                        <td>
                          {moment(innerData.timestamp, "x").format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>{innerData.debitCostCenterKey}</td>
                        <td>
                          {this.renderDebitAccountType(
                            innerData.debitAccountType
                          )}
                        </td>
                        <td>{innerData.creditCostCenterKey}</td>

                        <td>{innerData.currency}</td>
                        <td>
                          {commonService.displayDigitalAmount(innerData.amount)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </section>
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withRouter(CorrectedPaymentTransactions));
