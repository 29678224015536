import { TableModel } from "../../models/table.model";
import * as types from "../actions/types";

const initialState: TableModel = {
  isFetching: false,
  data: [],
  error: {},
  failure: false,
};
export function tableReducer(state = initialState, action: any): TableModel {
  switch (action.type) {
    case types.TABLES.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.TABLES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.TABLES.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function tableAddReducer(state = initialState, action: any): TableModel {
  switch (action.type) {
    case types.TABLE_ADD.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.TABLE_ADD.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.TABLE_ADD.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
export function tableRemoveReducer(
  state = initialState,
  action: any
): TableModel {
  switch (action.type) {
    case types.TABLE_REMOVE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.TABLE_REMOVE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        failure: false,
        error: {},
      };
    case types.TABLE_REMOVE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
export function tableUpdateReducer(
  state = initialState,
  action: any
): TableModel {
  switch (action.type) {
    case types.TABLE_UPDATE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.TABLE_UPDATE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        failure: false,
        error: {},
      };
    case types.TABLE_UPDATE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
