import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import {
    printerProtocolAddFailure,
    printerProtocolAddSuccess,
    printerProtocolFailure,
    printerProtocolRemoveFailure,
    printerProtocolRemoveSuccess,
    printerProtocolSuccess,
    printerProtocolUpdateFailure,
    printerProtocolUpdateSuccess,
} from '../redux/actions/printer.protocol.action';
import * as types from '../redux/actions/types';
import printer from '../services/printer.protocol.service';
import handleResponse from '../services/response.service';

const printerProtocolCall = (params: any) => printer.printerProtocol(RESTAURANT_REQUEST, params);
const updatePrinterProtocolCall = (params: any) => printer.updatePrinterProtocol(RESTAURANT_REQUEST, params);
const addPrinterProtocolCall = (params: any) => printer.addPrinterProtocol(RESTAURANT_REQUEST, params);
const removePrinterProtocolCall = (params: any) => printer.removePrinterProtocol(RESTAURANT_REQUEST, params);

function* handlePrinterProtocolRequest(params: object) {
    try {
        let result;
        result = yield call(printerProtocolCall, params);
        yield handlePrinterProtocol200(result);
    } catch (error) {
        throw error;
    }
}

const handlePrinterProtocol200 = function* handlePrinterProtocol200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(printerProtocolSuccess(result.data));
    } else {
        yield put(printerProtocolFailure(result.data));
        handleResponse(result);
    }
}

function* handlePrinterProtocolUpdate(params: object) {
    try {
        let result;
        result = yield call(updatePrinterProtocolCall, params);
        yield handlePrinterProtocolUpdate200(result);
    } catch (error) {
        throw error;
    }
}

const handlePrinterProtocolUpdate200 = function* handlePrinterProtocolUpdate200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(printerProtocolUpdateSuccess(result.data));
    } else {
        yield put(printerProtocolUpdateFailure(result.data));
    }
    handleResponse(result);
}

function* handlePrinterProtocolAdd(params: object) {
    try {
        let result;
        result = yield call(addPrinterProtocolCall, params);
        yield handlePrinterProtocolAdd200(result);
    } catch (error) {
        throw error;
    }
}

const handlePrinterProtocolAdd200 = function* handlePrinterProtocolAdd200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(printerProtocolAddSuccess(result.data));
    } else {
        yield put(printerProtocolAddFailure(result.data));
    }
    handleResponse(result);
}

function* handlePrinterProtocolRemove(params: object) {
    try {
        let result;
        result = yield call(removePrinterProtocolCall, params);
        yield handlePrinterProtocolRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handlePrinterProtocolRemove200 = function* handlePrinterProtocolRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(printerProtocolRemoveSuccess(result.data));
    } else {
        yield put(printerProtocolRemoveFailure(result.data));
    }
    handleResponse(result);
}
export default function* printerProtocolSaga() {
    yield takeLatest(types.PRINTER_PROTOCOL.REQUEST, handlePrinterProtocolRequest);
    yield takeLatest(types.PRINTER_PROTOCOL_UPDATE.REQUEST, handlePrinterProtocolUpdate);
    yield takeLatest(types.PRINTER_PROTOCOL_ADD.REQUEST, handlePrinterProtocolAdd);
    yield takeLatest(types.PRINTER_PROTOCOL_REMOVE.REQUEST, handlePrinterProtocolRemove);
}
