import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function languageRequest(credentials: object): ActionModel {
  return {
    type: types.LANGUAGE.REQUEST,
    credentials,
  };
}

export function languageSuccess(data: object): ActionModel {
  return {
    type: types.LANGUAGE.SUCCESS,
    data,
  };
}

export function languageFailure(err: object): ActionModel {
  return {
    type: types.LANGUAGE.FAILURE,
    err,
  };
}

export function languageUpdate(credentials: object): ActionModel {
  return {
    type: types.LANGUAGE_UPDATE.REQUEST,
    credentials,
  };
}

export function languageUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.LANGUAGE_UPDATE.SUCCESS,
    data,
  };
}

export function languageUpdateFailure(err: object): ActionModel {
  return {
    type: types.LANGUAGE_UPDATE.FAILURE,
    err,
  };
}
