import React from "react";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import "../../../app/app.scss";

import i18next from "i18next";
import { useTranslation } from "react-i18next";
export default function DeleteModalCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <Modal isOpen={props.isOpen}>
      <ModalBody>
        <Row className="app-row-padding">
          <Col sm="12" style={{ padding: 10, textAlign: "center" }}>
            <h6>{!!props.qnTitle ? props.qnTitle : t("questions.delete")}</h6>
          </Col>
          <Col sm="12" style={{ padding: 0, textAlign: "center" }}>
            <div>
              <Button
                color="secondary"
                style={{ margin: 5 }}
                onClick={props.cancelDelete}
              >
                {t("common.cancel")}
              </Button>
              <Button
                color="info"
                style={{ margin: 5 }}
                disabled={props.isFetching}
                onClick={props.okDelete}
              >
                {t("common.ok")}
              </Button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}
