import React, { Component } from 'react';

import PosComponent from '../../components/pos/pos';

class PosPage extends Component {

    render() {
        return (
            <PosComponent />
        );
    }
}
export default PosPage;
