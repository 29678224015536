import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { create } from "apisauce";
import ModalFactory from "react-modal-promise";

import LoaderComponent from "../../components/loader/loader";
import PrinterTemplateAddEditModal from "../../components/card-components/printer-template/printerTemplateAddEditModal";
import { BASE_URL } from "../../config/app.config";
import { ACCESS_TOKEN } from "../../constant/constant";
import { OpenDeleteModal } from "../../components/card-components/delete-card/delete-modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { GREEN } from "../../constant/constant";

import {
  PrinterTemplateState,
  PrinterTemplateInitState,
} from "../../models/printer.template.model";
import commonService from "../../services/common.service";
import { withTranslation } from "react-i18next";
import {
  printerTemplateAdd,
  printerTemplateRemove,
  printerTemplateRequest,
  printerTemplateUpdate,
} from "../../redux/actions/printer.template.action";
import ImageTitleComponentWrapper from "../../components/card-components/image-title-component/imageTitleComponentWrapper";

const api = create({
  baseURL: BASE_URL,
});

class PrinterTemplateComponent extends Component<any> {
  state: PrinterTemplateState;
  constructor(props: any) {
    super(props);
    this.state = PrinterTemplateInitState;
    api.setHeaders({
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Cache-Control": "no-cache",
      Locale: localStorage.getItem("i18nextLng") || "en-gb",
      "content-type": "application/json",
    });
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.printerTemplate({
      restaurantuuid: this.props.match.params.uuid,
      templateType: this.props.templateType,
    });
    this.setState({ restaurantuuid: this.props.match.params.uuid });
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      (this.state.addItemData !== {} &&
        prevState.addItemData !== this.state.addItemData) ||
      (this.state.updateItemData !== {} &&
        prevState.updateItemData !== this.state.updateItemData)
    ) {
      this.setState({
        isAddItemModalOpen: false,
        itemModalData: {
          name: "",
          description: "",
        },
        selectedItemId: null,
        updateItemData: {},
      });
      this.props.printerTemplate({
        restaurantuuid: this.props.match.params.uuid,
        templateType: this.props.templateType,
      });
    }
    if (
      prevState.isDeleted &&
      Object.keys(this.state.removeItemData).length !== 0 &&
      this.state.removeItemData !== prevState.removeItemData
    ) {
      this.setState({ isDeleted: false, removeItemData: {} });
      this.props.printerTemplate({
        restaurantuuid: this.props.match.params.uuid,
        templateType: this.props.templateType,
      });
    }
    if (!!this.state.fetchData) {
      this.props.printerTemplate({
        restaurantuuid: this.props.match.params.uuid,
        templateType: this.props.templateType,
      });
      this.setState({
        fetchData: false,
      });
    }
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    let update: any = {};
    if (nextProps.isFetching !== prevState.isFetching) {
      update.isFetching = nextProps.isFetching;
    }
    if (nextProps.items !== prevState.items) {
      update.items = nextProps.items;
    }
    if (
      nextProps.addItemData &&
      nextProps.addItemData !== prevState.addItemData
    ) {
      update.addItemData = nextProps.addItemData;
    }
    if (
      Object.keys(nextProps.removeItemData).length !== 0 &&
      nextProps.removeItemData !== prevState.removeItemData
    ) {
      update.removeItemData = nextProps.removeItemData;
    }
    if (
      !!nextProps.updateItemData &&
      nextProps.updateItemData !== prevState.updateItemData
    ) {
      update.updateItemData = nextProps.updateItemData;
    }
    if (
      !!prevState.isAddItemModalOpen &&
      !nextProps.isFetching &&
      !!prevState.isUpdated
    ) {
      update.isAddItemModalOpen = false;
      update.isUpdated = false;
      update.fetchData = true;
    }
    if (!nextProps.isFetching && !!prevState.isUpdated) {
      update.isUpdated = false;
      update.fetchData = true;
    }
    return update;
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <div>
          <ModalFactory />
          <LoaderComponent display={this.state.isFetching} />

          <PrinterTemplateAddEditModal
            formData={this.state.itemModalData}
            handleCancel={this.handleItemUpdateCancel}
            handleSubmit={this.handleSubmit}
            isModalOpen={this.state.isAddItemModalOpen}
          />

          <ImageTitleComponentWrapper
            cardType="title"
            elementId={this.state.elementId}
            fetchData={this.fetchData}
            itemData={this.state.items}
            itemsDetails={this.state.itemsDetails}
            onAddNewItem={this.handleAddItemClick}
            onItemDelete={this.handleItemDelete}
            onItemEdit={this.handleItemEdit}
            pageText={this.getPageTitle()}
            setItemId={this.setItemId}
          />
        </div>
      </>
    );
  }

  public fetchData = () => {
    this.setState({
      fetchData: true,
    });
  };

  public setItemId = (itemId: string) => {
    this.setState({
      elementId: itemId,
    });
  };

  public getExtraDetails = (data: any) => {
    let localeData = commonService.applyLocalization(
      "restaurant",
      "description",
      data.locales
    );
    // TODO SR-747 Printer template : Need to a add function
    return (
      <div className="d-flex justify-content-center w-100">
        <span
          onClick={() => null}
          className="pointer-cursor"
          data-toggle="tooltip"
          data-placement="left"
          title={this.props.t("common.view")}
        >
          <FontAwesomeIcon style={{ color: GREEN, margin: 5 }} icon={faEye} />
        </span>
      </div>
    );
  };

  public getPageTitle = () => {
    return {
      addButtonTitle: this.props.t("printer.addPrinterTemplate"),
      pageTitle:
        this.props.templateType === "MAIL"
          ? this.props.t("email.emailTemplates")
          : this.props.t("printer.printerTemplates"),
    };
  };
  public handleAddItemClick = (data: any) => {
    this.setState({ isAddItemModalOpen: !this.state.isAddItemModalOpen });
  };

  public handleItemDelete = (itemId: any) => {
    OpenDeleteModal().then(() => {
      this.setState({ isDeleted: true });
      this.props.removePrinterTemplate({
        restaurantuuid: this.props.match.params.uuid,
        uuid: itemId,
      });
    });
  };

  public handleItemEdit = (itemId: any) => {
    const [selectedItem]: any = this.state.items.filter((item: any) => {
      return item.uuid === itemId;
    });
    const name = commonService.applyLocalization(
      "restaurant",
      "name",
      selectedItem.locales
    ).name;

    const description = commonService.applyLocalization(
      "restaurant",
      "description",
      selectedItem.locales
    ).description;

    this.setState({
      isAddItemModalOpen: true,
      itemModalData: {
        name,
        description,
        isEdit: true,
      },
      selectedItemId: itemId,
    });
  };
  public handleItemUpdateCancel = () => {
    this.setState({
      isAddItemModalOpen: false,
      itemModalData: {
        name: "",
        description: "",
        isEdit: false,
      },
      selectedItemId: "",
    });
  };

  public handleSubmit = (data: any) => {
    data["type"] = this.props.templateType;
    if (!!this.state.selectedItemId) {
      this.props.updatePrinterTemplate({
        data,
        restaurantuuid: this.props.match.params.uuid,
        uuid: this.state.selectedItemId,
      });
    } else {
      this.props.addPrinterTemplate({
        data,
        restaurantuuid: this.props.match.params.uuid,
      });
    }
  };
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.printerTemplate.isFetching ||
    state.printerTemplateAdd.isFetching ||
    state.printerTemplateRemove.isFetching ||
    state.printerTemplateUpdate.isFetching ||
    state.upload.isFetching;
  let failure =
    state.printerTemplate.failure ||
    state.printerTemplateAdd.failure ||
    state.printerTemplateRemove.failure ||
    state.printerTemplateUpdate.failure ||
    state.upload.failure;
  let states: any = {
    addItemData: state.printerTemplateAdd.data,
    failure: failure,
    isFetching: isFetching,
    items: state.printerTemplate.data,
    removeItemData: state.printerTemplateRemove.data,
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    updateItemData: state.printerTemplateUpdate.data,
  };
  return states;
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    addPrinterTemplate: (credentials: any) => {
      dispatch(printerTemplateAdd(credentials));
    },
    printerTemplate: (credentials: any) => {
      dispatch(printerTemplateRequest(credentials));
    },
    removePrinterTemplate: (credentials: any) => {
      dispatch(printerTemplateRemove(credentials));
    },
    updatePrinterTemplate: (credentials: any) => {
      dispatch(printerTemplateUpdate(credentials));
    },
  };
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(PrinterTemplateComponent))
);
