import * as types from '../actions/types';

const initialState: any = {
  isFetching: false,
  data: {},
  error: {},
  failure: false,

};
export default function serviceSetUpdateReducer(state = initialState, action: any): any {
  switch (action.type) {
    case types.SERVICESET_UPDATE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {}
      };
    case types.SERVICESET_UPDATE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {}
      };
    case types.SERVICESET_UPDATE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        data:action.err,
        error: action.err
      };
      case types.SERVICESET_ADD.REMOVE:
        return {
          ...state,
          isFetching: false,
          data: {},
          failure: false,
          error: {}
        };
    default:
      return state;
  }
}
