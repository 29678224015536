import { ForgotPasswordModel } from '../../models/forgotpassword.model';
import * as types from '../actions/types';

const initialState: ForgotPasswordModel = {
  isFetching: false,
  data: {},
  error: {},
  failure: false
};

export default function resetpasswordReducer(state = initialState, action: any): ForgotPasswordModel {
  switch (action.type) {
    case types.RESET_PASSWORD.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {}
      };
    case types.RESET_PASSWORD.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action,
        failure: false,
        error: {}
      };
    case types.RESET_PASSWORD.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err
      };
    default:
      return state;
  }
}
