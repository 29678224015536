import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function inventoryRequest(credentials: object): ActionModel {
  return {
    type: types.INVENTORY.REQUEST,
    credentials
  };
}

export function inventorySuccess(data: object): ActionModel {
  return {
    type: types.INVENTORY.SUCCESS,
    data
  };
}

export function inventoryFailure(err: object): ActionModel {
  return {
    type: types.INVENTORY.FAILURE,
    err
  };
}
