import { TaskInterventionModel } from '../../models/task.intervention.model';
import { TaskModel } from '../../models/task.model';
import * as types from '../actions/types';

const initialState: TaskInterventionModel = {
  isFetching: false,
  data: {},
  error: {},
  failure: false,
};
export default function taskInterventionStepAddReducer(state = initialState, action: any): TaskModel {
  switch (action.type) {
    case types.TASK_INTERVENTION_STEP_ADD.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {}
      };
    case types.TASK_INTERVENTION_STEP_ADD.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {}
      };
    case types.TASK_INTERVENTION_STEP_ADD.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        data:action.err,
        error: action.err
      };
    default:
      return state;
  }
}
