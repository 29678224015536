import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import "../../../styles/transport.scss";

export default function TransportAddCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <Modal isOpen={props.addmodal} className="modal-dialog-centered modal-lg">
      <ModalHeader toggle={props.addToggle} className="modal-header">
        {t("common.addRouter")}
      </ModalHeader>
      <ModalBody>
        <Row className="app-row-padding">
          <Col sm="4">
            <Label>
              {" "}
              <h6>{t("common.from")}</h6>
            </Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="select"
              name="fromtype"
              onChange={props.handleTypeChange}
              required
              value={props.fromtype}
            >
              <option value="">{t("common.select")}</option>
              <option value="room">{t("room.room")} </option>
              <option value="storage">{t("common.storage")} </option>
              <option value="rack">{t("common.rack")} </option>
              <option value="place">{t("common.places")} </option>
              <option value="station">{t("station.station")} </option>
              <option value="machine">{t("machine.machine")} </option>
            </Input>
            <br></br>
            <Input
              type="select"
              name="fromvalue"
              id="fromvalue"
              onChange={props.handleChange}
              value={props.fromvalue}
              required
            >
              <option value="">{t("common.select")}</option>
              {props.selectFromType(props.fromtype)}
            </Input>
          </Col>
          <Col sm="4">
            <Label>
              {" "}
              <h6>{t("common.to")}</h6>
            </Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="select"
              name="totype"
              onChange={props.handleTypeChange}
              required
              value={props.totype}
            >
              <option value="">{t("common.select")}</option>
              <option value="room">{t("room.room")} </option>
              <option value="storage">{t("common.storage")} </option>
              <option value="rack">{t("common.rack")} </option>
              <option value="place">{t("common.places")} </option>
              <option value="station">{t("station.station")} </option>
              <option value="machine">{t("machine.machine")} </option>
            </Input>
            <br></br>
            <Input
              type="select"
              name="tovalue"
              id="tovalue"
              onChange={props.handleChange}
              value={props.tovalue}
              required
            >
              <option value="">{t("common.select")}</option>
              {props.selectToType(props.totype)}
            </Input>
          </Col>
          <Col sm="4">
            <div className="mb-2">
              <Label>
                {" "}
                <h6>{t("task.transportTask")}</h6>
              </Label>
              <Label className="required-star-style">*</Label>
              <Input
                type="select"
                name="transportmode"
                id="transportmode"
                required
                onChange={props.handleChange}
                value={props.transportmode}
              >
                <option value="">{t("common.select")}</option>
                <option value={1}>{t("transport.noTransport")}</option>
                <option value={2}>{t("transport.immediateTransport")}</option>
                <option value={3}>{t("transport.useTransportSynergy")}</option>
              </Input>
            </div>

            {(props.transportmode == 2 || props.transportmode == 3) && (
              <Label className="w-100">
                <h6>{t("station.responsibleStation")}</h6>
                <Input
                  type="select"
                  name="responsibleStation"
                  id="responsibleStation"
                  onChange={props.handleChange}
                  value={props.responsibleStation}
                  required
                >
                  <option value="">{t("common.select")}</option>
                  {props.selectToType("responsibleStation")}
                </Input>
              </Label>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.addToggle}>
          <option value="">{t("common.cancel")}</option>
        </Button>
        <Button color="info" onClick={props.saveAdd}>
          <option value="">{t("common.save")}</option>
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
