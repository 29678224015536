import '../../styles/page404.scss';

import React, { Component } from 'react';

import Page404Component from '../../components/page404/page404';

class Page404 extends Component {
    render() {
        return (
            <div className="page-404-style">
                <Page404Component/>
            </div>
        );
    }
}
export default Page404;
