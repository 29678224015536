import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function usersRequest(credentials: object): ActionModel {
  return {
    type: types.USERS.REQUEST,
    credentials
  };
}

export function usersSuccess(data: object): ActionModel {
  return {
    type: types.USERS.SUCCESS,
    data
  };
}

export function usersFailure(err: object): ActionModel {
  return {
    type: types.USERS.FAILURE,
    err
  };
}

export function usersRemove(credentials: object): ActionModel {
  return {
    type: types.USERS_REMOVE.REQUEST,
    credentials
  };
}

export function usersRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.USERS_REMOVE.SUCCESS,
    data
  };
}

export function usersRemoveFailure(err: object): ActionModel {
  return {
    type: types.USERS_REMOVE.FAILURE,
    err
  };
}

export function userRoleRequest(credentials: object): ActionModel {
  return {
    type: types.USER_ROLE_ADD.REQUEST,
    credentials
  };
}

export function userRoleAddSuccess(data?: object): ActionModel {
  return {
    type: types.USER_ROLE_ADD.SUCCESS,
    data
  };
}

export function userRoleAddFailure(err: object): ActionModel {
  return {
    type: types.USER_ROLE_ADD.FAILURE,
    err
  };
}
