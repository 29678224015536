import React, { Component } from "react";
import { Collapse, Col, Input, Row } from "reactstrap";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

import {
  CardItemCardComponent,
  CardItemContainer,
} from "../card-components/card-item";
import { withCardItemSelectionState } from "../cardItemSelectionManager";
import WarningComment, {
  getMandatoryWarning,
} from "../warning-comment/warning-comment";
import {
  CourseListCardInitState,
  CourseListCardState,
} from "../../models/course.list.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PHILIPPINE_GRAY, WHITE } from "../../constant/constant";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import commonService from "../../services/common.service";

class DateItemListCard extends Component<any, any> {
  state: CourseListCardState;
  constructor(props: any) {
    super(props);
    this.state = CourseListCardInitState;
  }

  componentDidMount() {
    this._loadValues();
    this.setState({
      cardItemData: this.props.cardItemData,
      courseListName: this.props.courseListName,
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.courseListName !== prevState.courseListName &&
      this.props.cardItemSelectionData.selectionState.mode !== "edit"
    ) {
      this.setState({
        cardItemData: this.props.cardItemData,
        courseListName: this.props.courseListName,
      });
    }
    if (
      JSON.stringify(prevProps.itemsList) !==
      JSON.stringify(this.props.itemsList)
    ) {
      this.loadCourseNames();
    }
  }

  private _loadValues = () => {
    if (this.props.cardItemData?.type === "newCard") {
      this.setState({
        courseListName: "",
        warnings: {
          courseListName: getMandatoryWarning(""),
        },
      });
    } else {
      this._setInitialValues();
    }
    this.loadCourseNames();
  };

  private _setInitialValues() {
    const courseListName = this.props.courseListName;
    const cardItemData = this.props.cardItemData;

    this.setState({
      courseListName,
      cardItemData,
      warnings: {
        courseListName: getMandatoryWarning(courseListName),
      },
    });
  }

  render() {
    const { t } = this.props;
    return (
      <CardItemContainer className={"task-type px-0"}>
        <CardItemCardComponent
          cardItemSelectionData={this.props.cardItemSelectionData}
          className={"task-type assigned"}
          deleteCallback={this.deleteCard}
          onDeleteClick={this.deleteCard}
          readOnly={false}
          saveCallback={this.saveCard}
          selectCallback={this.selectCard}
          saveButton={this.props.saveButton}
        >
          <Row>
            {!this.props.cardItemSelectionData.selectionState
              .isItemSelected && (
              <>
                <Col lg={4}>
                  <FontAwesomeIcon
                    className="b-drag-icon mr-1"
                    style={{ color: PHILIPPINE_GRAY }}
                    icon={faEllipsisV}
                  />
                  {this.props.courseListName}
                </Col>
              </>
            )}
            {this.props.cardItemSelectionData.selectionState.isItemSelected && (
              <Col lg={6}>
                {!this.props.cardItemSelectionData.selectionState
                  .isBeingEdited && (
                  <span>
                    <FontAwesomeIcon
                      className="b-drag-icon mr-1"
                      style={{ color: WHITE }}
                      icon={faEllipsisV}
                    />
                    {this.props.courseListName}
                  </span>
                )}
                {this.props.cardItemSelectionData.selectionState
                  .isBeingEdited && (
                  <WarningComment
                    data={this.state.warnings.courseListName}
                    displayType={"bottom"}
                  >
                    <div className="d-flex align-items-center">
                      <label className="mr-3">{t("common.name")}</label>
                      <Input
                        type="text"
                        name="courseListName"
                        id="courseListName"
                        placeholder={t("course.enterCourseListName")}
                        onChange={this.handleChange}
                        value={this.state.courseListName}
                      />
                    </div>
                  </WarningComment>
                )}
              </Col>
            )}
          </Row>
        </CardItemCardComponent>
      </CardItemContainer>
    );
  }

  deleteCard = () => {
    this.props.onDeleteCard(this.props.indx);
  };

  getUnitLabel = (recipeId: string) => {
    return this.props.getOutputUnit(recipeId);
  };

  handleChange = (elem: any) => {
    let currentWarning: any;
    if (elem.target.name === "courseListName") {
      const isValid =
        elem.target.value.trim() === "" ? null : elem.target.value;
      currentWarning = {
        courseListName: getMandatoryWarning(isValid),
      };
      if (
        currentWarning.courseListName === null &&
        this.state.courseListNames !== [] &&
        this.state.courseListNames.includes(elem.target.value.toLowerCase())
      ) {
        currentWarning = {
          courseListName: {
            type: "stop",
            text: this.props.t("course.nameUnique"),
          },
        };
      }
    } else {
      currentWarning = {
        [elem.target.name]: getMandatoryWarning(elem.target.value),
      };
    }
    const warnings = { ...this.state.warnings, ...currentWarning };
    this.setState({
      [elem.target.name]: elem.target.value,
      warnings: warnings,
    });
  };

  loadCourseNames = () => {
    let courseNames: Array<any> = [];
    this.setState({ courseListNames: [] });
    !!this.props?.itemsList &&
      this.props?.itemsList.map((item: any) => {
        let name: string = commonService.applyLocalization(
          "restaurant",
          "name",
          item.locales
        )["name"];

        courseNames.push(name.toLowerCase());
      });
    this.setState({
      courseListNames: courseNames,
    });
  };

  uuidv4 = () => {
    return "sampleid-xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  saveCard = () => {
    let lang: any = localStorage.getItem("i18nextLng");
    if (this.state.warnings.courseListName === null) {
      const formData: any = {
        locales: {
          [lang]: {
            name: this.state.courseListName,
          },
        },
        uuid: this.uuidv4(),
      };
      if (this.props.cardItemId !== "newCard") {
        formData.uuid = this.props.cardItemData.uuid;
      }
      this.props.onSaveCard(formData, this.props.cardItemId, this.props.indx);
      if (this.props.cardItemId === "newCard") {
        this._setInitialValues();
      }
    }
  };
  selectCard = () => {
    if (this.props.cardItemSelectionData.selectionState.mode === "edit") {
      this._loadValues();
    }
    this.props.cardItemSelectionData.selectCardItem();
  };
}

export default withTranslation()(
  withRouter(
    withCardItemSelectionState("DateItemListCard", 1)(DateItemListCard)
  )
);
