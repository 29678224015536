import { UnitsModel } from '../../models/units.model';
import * as types from '../actions/types';

const initialState: UnitsModel = {
  isFetching: false,
  data: {},
  error: {},
  failure: false,
};
export default function unitsReducer(state = initialState, action: any): UnitsModel {
  switch (action.type) {
    case types.UNITS.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {}
      };
    case types.UNITS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {}
      };
    case types.UNITS.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err
      };
    default:
      return state;
  }
}
