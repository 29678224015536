import React, { Component } from "react";
import { connect } from "react-redux";
import ModalFactory from "react-modal-promise";
import { withRouter } from "react-router";
import { create } from "apisauce";
import { Col, Row } from "reactstrap";

import { ACCESS_TOKEN, INVENTORY_TYPE } from "../../../constant/constant";
import { BASE_URL } from "../../../config/app.config";
import ConceptHeaderComponent from "../../../components/navigation/navigation-header/concept-header/concept-header";
import commonService, {
  shouldOpenCreateModal,
} from "../../../services/common.service";
import ItemWrapper from "../../../components/service-item/shared/itemWrapper";
import ItemAddModal from "../../../components/service-item/shared/itemAddModal";
import {
  ItemChoiceInitState,
  ItemChoiceState,
} from "../../../models/itemChoice.model";

import handleResponse from "../../../services/response.service";
import LoaderComponent from "../../../components/loader/loader";
import { OpenDeleteModal } from "../../../components/card-components/delete-card/delete-modal";
import RestaurantNavComponent from "../../../components/navigation/navigation-left/restaurant-navigation/restaurant-navigation";
import NavigationRightComponent from "../../../components/navigation/navigation-right/navigation-right";
import {
  recipeAdd,
  recipeRemove,
  recipeRequest,
  recipeUpdate,
  recipeDetailsRequest,
  recipeItemsRequest,
  recipeCopy,
} from "../../../redux/actions/recipe.action";
import { recipeSubstituteRequest } from "../../../redux/actions/recipe.substitutes.action";
import { ingredientCostRequest } from "../../../redux/actions/ingredients.action";
import { tagsRequest } from "../../../redux/actions/tags.action";
import { unitsRequest } from "../../../redux/actions/units.action";
import { withTranslation } from "react-i18next";
import { RESTAURANT_REQUEST } from "../../../config/api.config";
import _ from "lodash";
import {
  getRecipeStorageDetails,
  updateRecipeStorage,
  addRecipeStorageKeyValue,
} from "../../../services/recipe.service";
import { getIngredients } from "../../../services/ingredients.new.service";
import ManageStorageStorage from "../../../components/manage-storage/manage-storage";
import ingredientFn from "../../../services/ingredients.service";
import { Badge } from "reactstrap";
import { store } from "../../../redux/store/store";
import { WebViewPostHoc } from "../../../hoc/webViewPostHoc";
import { storageType } from "../../../models/storage.model";
import { ItemReplacementHelper } from "../../../helpers/itemReplacementHelper";
import { RecipeEditHelper } from "../../../helpers/recipeEditHelper";
import { generateRandomNumber } from "../../../helpers/commonHelper";
import { manageKeyValuePairPrompt } from "../../../components/prompts/ManageKeyValuePair";
import { areYouSureModal } from "../../../components/prompts/AreYouSure";
import { UrlSyncHelper } from "../../../helpers/urlSyncHelper";
const api = create({
  baseURL: BASE_URL,
});

class RecipePage extends Component<any> {
  state: ItemChoiceState;
  pageText: Object = {
    addButtonTitle: "Add recipe",
    pageTitle: "Recipe",
  };
  recipesRelatedUnits: any = {};
  subscriber: any = null;
  editItemData: any = {};
  historyUnListen: any = null;
  constructor(props: any) {
    super(props);
    this.state = ItemChoiceInitState;
    api.setHeaders({
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Cache-Control": "no-cache",
      Locale: localStorage.getItem("i18nextLng") || "en-gb",
      "content-type": "application/json",
    });
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.loadMetaData();
    this.loadSuppliers();
    this.setState({ restaurantuuid: this.props.match.params.uuid });
    this.props.getTags({ restaurantuuid: this.props.match.params.uuid });
    this.props.getSubstitutes({
      uuid: this.props.match.params.uuid,
      type: "3",
    });
    this.subscriber = store.subscribe(this.handleStoreChange.bind(this));
    this.getItemsListByUrl();
    this.historyUnListen = this.props.history.listen(
      (location: any, action: any) => {
        if (action === "POP" || (action === "PUSH" && location.search === "")) {
          this.getItemsListByUrl(location);
        }
      }
    );
  }

  handleStoreChange() {
    const prevunits = this.props.units;
    const currentUnitis = store.getState().units;
    if (
      !currentUnitis.isFetching &&
      !prevunits.isFetching &&
      prevunits !== currentUnitis
    ) {
      this.subscriber();
      if (shouldOpenCreateModal()) {
        this.setState({ ...this.state, isAddItemModalOpen: true });
      }
    }
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    let elementId = this.state.elementId;
    if (
      prevProps.editRecipeStatus !== this.props.editRecipeStatus &&
      this.props.editRecipeStatus?.recipeUuid
    ) {
      this.setItemId(this.props.editRecipeStatus?.recipeUuid);
      elementId = this.props.editRecipeStatus?.recipeUuid;
    }
    if (prevState.recipeDetails !== this.state.recipeDetails) {
      let recipeDetail: any = [];
      recipeDetail = this.state.itemsDetails.filter(
        (item: any) => item.uuid !== this.state.recipeDetails.uuid
      );
      if (recipeDetail.length > 0) {
        this.setState({
          itemsDetails: [...recipeDetail, this.state.recipeDetails],
        });
      } else if (
        this.state.recipeDetails &&
        Object.keys(this.state.recipeDetails).length !== 0 &&
        this.state.recipeDetails.constructor === Object
      ) {
        this.setState({
          itemsDetails: [this.state.recipeDetails],
        });
      }
    }
    if (!!this.state.fetchData) {
      this.getItemList(this.state.currentPage, this.state.filterParams);
      this.props.getUnits({ restaurantuuid: this.props.match.params.uuid });
      if (elementId && !this.state.recipeStorages[elementId]) {
        this.getStorageDetails(elementId);
      }
      this.setState({
        fetchData: false,
        isUpdated: false,
      });
    }
    if (this.state.isUpdated && !this.props.isFetching) {
      this.getItemList(this.state.currentPage, this.state.filterParams);

      this.props.getUnits({ restaurantuuid: this.props.match.params.uuid });
      if (elementId !== "") {
        this.getCostDetails(elementId);
      }
      if (elementId && !this.state.recipeStorages[elementId]) {
        this.getStorageDetails(elementId);
      }
      this.setState({
        isUpdated: false,
      });
    }

    if (
      JSON.stringify(prevProps.ingredientCost) !==
        JSON.stringify(this.props.ingredientCost) ||
      !this.state.ingredientsCost[this.state.recipe]
    ) {
      if (!!this.state.recipe && !!this.props.ingredientCost) {
        let cost: any = {};
        cost[this.state.recipe] = this.props.ingredientCost;
        const allCost = {
          ...this.state.ingredientsCost,
          ...cost,
        };

        this.setState({
          ingredientsCost: allCost,
        });
      }
    }
    if (
      JSON.stringify(prevProps.recipeServiceSets) !==
        JSON.stringify(this.props.recipeServiceSets) ||
      this.state.recipeServiceSets[elementId] !== this.props.recipeServiceSets
    ) {
      if (!!elementId) {
        let serviceSets: any = {};
        serviceSets[elementId] = this.props.recipeServiceSets;
        const allServiceSets = {
          ...this.state.recipeServiceSets,
          ...serviceSets,
        };

        this.setState({
          recipeServiceSets: allServiceSets,
        });
      }
    }

    if (
      JSON.stringify(prevProps.recipeSubstitutes) !==
      JSON.stringify(this.props.recipeSubstitutes)
    ) {
      let recipeSubstituteArray: any = [];
      recipeSubstituteArray = this.state.recipeSubstituteArray.filter(
        (item: any) => item.uuid !== elementId
      );

      let recipeObj: any;
      recipeObj = {
        uuid: elementId,
        substitutes: this.props.recipeSubstitutes,
      };

      this.setState({
        recipeSubstituteArray: [...recipeSubstituteArray, recipeObj],
      });
    }
    if (prevProps.deviceData.barcode !== this.props.deviceData.barcode) {
      this.setState({ barcode: this.props.deviceData.barcode });
    }
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    let update: any = {};
    if (
      (nextProps.items?.length || nextProps.items?.length === 0) &&
      nextProps.items !== prevState.items
    ) {
      update.items = nextProps.items;
    }

    if (nextProps.units && !_.isEqual(nextProps.units, prevState.units)) {
      update.units = nextProps.units;
    }
    if (nextProps.tags && nextProps.tags !== prevState.tags) {
      update.tags = nextProps.tags;
    }
    if (
      !!nextProps.recipeDetails &&
      nextProps.recipeDetails !== prevState.recipeDetails
    ) {
      update.recipeDetails = nextProps.recipeDetails;
    }
    if (
      !!prevState.isAddItemModalOpen &&
      !nextProps.isFetching &&
      !!prevState.isUpdated
    ) {
      update.isAddItemModalOpen = false;
      update.isUpdated = false;
      update.fetchData = true;
    }
    if (!nextProps.isFetching && !!prevState.isUpdated) {
      // update.isUpdated = false;
      update.fetchData = true;
    }
    return update;
  }

  componentWillUnmount() {
    if (this.historyUnListen) {
      this.historyUnListen();
    }
  }

  setPageState(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  getItemsListByUrl(location?: Function): void {
    const { pageNo, filter } = UrlSyncHelper.setStateFromUrl(
      this.setPageState.bind(this),
      this.setFilterParams.bind(this),
      location ?? this.props.location
    );
    this.getItemList(pageNo, filter);
  }

  getBaseUnitOptions = () => {
    if (!Array.isArray(this.props.units)) return;
    let units: Array<any> = [];
    const baseUnit: any = this.props.units?.find(
      (unit: any) => unit.uuid === this.props.settings.base_weight_unit
    );
    if (baseUnit) {
      units =
        commonService.getRelateUnitDropdowns(
          baseUnit.uuid,
          this.props.units,
          this.props.units,
          1,
          this.props.elementId
        ) || [];
    }
    return _.sortBy(units, [(option: any) => option.label.toLowerCase()]);
  };

  getItemList = (pageNo: any, filter?: any) => {
    this.showLoading(true);
    const filterData = {
      type: 1,
      labelArray: filter?.labelArray
        ? filter?.labelArray?.map((item: any) => {
            return item.value;
          })
        : "[]",
      name: !!filter?.name?.trim() ? filter?.name?.trim() : "",
      supplierArray: filter?.supplierArray
        ? filter?.supplierArray?.map((item: any) => {
            return item.value;
          })
        : "[]",
      showArchived: filter?.showArchived,
      pageNo: pageNo,
      includeDrafts: true,
    };
    getIngredients(RESTAURANT_REQUEST, {
      credentials: {
        ...filterData,
        restaurantuuid: this.props?.match?.params.uuid,
      },
    }).then((data: any) => {
      this.setState({
        currentPage: pageNo,
        isFetching: false,
        isFilterApplied: filter ? true : false,
        items: data?.data?.items ? data?.data?.items : [],
        totalPages: Math.ceil(Number(data?.data?.total) / 20),
        totalCount: Number(data?.data?.total),
      });
      ItemReplacementHelper.setImportedItems(
        data?.data?.items,
        this.props.match.params.uuid,
        this.setImportedItems
      );
      const isDetailPage =
        this.props.history.location.pathname.endsWith("/details");
      if (!this.state.isBrowserNav && !isDetailPage) {
        UrlSyncHelper.setUrlFromState(filterData, this.props.history);
      }
      this.setState({ isBrowserNav: false });
    });
  };

  setImportedItems = (data: any) => {
    this.setState({
      importedItems: data,
    });
  };

  setCurrentPage = (currentPage: number) => {
    this.setState({
      currentPage: currentPage,
    });
  };

  public handleCostUpdate = (value: number, itemId: string) => {
    const payLoad = {
      credentials: {
        restaurantuuid: this.props.match.params.uuid,
        uuid: itemId,
        data: {
          cost: Number(value) || 0,
        },
      },
    };
    ingredientFn.updateIngredientCost(RESTAURANT_REQUEST, payLoad).then(() => {
      let currentIngredientCost: any = {};
      currentIngredientCost[itemId] = Number(value) || 0;
      const allCost = {
        ...this.state.ingredientsCost,
        ...currentIngredientCost,
      };
      this.setState((prevState: any) => ({
        elementId: itemId,
        ingredientsCost: allCost,
        isUpdated: !prevState.isUpdated,
        isCostUpdated: true,
      }));
      this.getCostDetails(itemId);
    });
  };

  handleUnitUpdate = (value: any, area: string) => {
    if (area === "visibility") {
      this.setState({
        isUnitModalOpen: value,
        newUnit: null,
      });
    } else if (area === "save") {
      this.setState({
        newUnit: value,
        outputunituuid: value.uuid,
        isUnitModalOpen: false,
      });
    }
  };

  loadSuppliers = () => {
    if (this.state.supplierList.length === 0) {
      api
        .get(
          "/api/1.0/restaurant/" + this.props.match.params.uuid + "/supplier ",
          {}
        )
        .then((response: any) => {
          const responseData: any = handleResponse(response);
          if (responseData.ok) {
            this.setState({
              supplierList: response.data,
              supplierOptions: response.data?.map((item: any) => ({
                label: commonService.applyLocalization(
                  "restaurant",
                  "name",
                  item?.locales
                ).name,
                value: item.uuid,
              })),
              supplierResponse: response,
            });
          } else {
            handleResponse(response);
          }
        });
    }
  };

  openNewUnitModal = () => {
    this.setState({
      isUnitModalOpen: true,
    });
  };
  renderManged = () => {
    let list = [
      {
        managedId: 1,
        title: this.props.t("common.managed"),
      },
      {
        managedId: 2,
        title: this.props.t("common.noStock"),
      },
      {
        managedId: 3,
        title: this.props.t("common.infinite"),
      },
    ];

    let managedList;
    if (!!list) {
      managedList = list.map((sup: any, i: number) => {
        return (
          <option key={i} value={sup.managedId}>
            {sup.title}
          </option>
        );
      });
      return managedList;
    }
  };

  setFilterParams = (filterParams: any) => {
    this.setState({
      filterParams,
    });
  };

  showLoading = (state: boolean = true) => {
    this.setState({
      isFetching: state,
    });
  };

  randomString(length: number, chars: string) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  generateSKU = () => {
    let state: any = this.state;
    state["sku"] = this.randomString(
      12,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    );
    this.setState(state);
  };

  render() {
    let managedList = this.renderManged();
    return (
      <>
        <div>
          <ModalFactory />
          <ManageStorageStorage
            toggleStorageMenu={this.toggleStorageMenu}
            toggleStorage={this.state.toggleStorageModal}
            saveRecipeStorage={this.saveRecipeStorage}
            storageFormData={this.state.storageFormData}
            units={this.getRelatedUnitsForStorage()}
          />
          <LoaderComponent display={this.state.isFetching} />

          <ItemAddModal
            isEdit={this.state.isEdit}
            area="recipe"
            barcode={this.state.barcode}
            color={this.state.color}
            confirmationCompletion={this.state.confirmationCompletion}
            description={this.state.description}
            elementId={this.state.elementId}
            getRelatedUnits={this.getRelatedUnits}
            handleCancel={this.handleAddItemClick}
            handleChange={this.handleChange}
            handleLabelSelect={this.handleLabelSelect}
            handleUnitChange={this.handleUnitChange}
            isFetching={this.state.isFetching}
            isModalOpen={this.state.isAddItemModalOpen}
            isUnitDisabled={RecipeEditHelper.isUnitDisabled(
              this.state.isAddItemModalOpen,
              this.state.itemId,
              this.props.units
            )}
            labelOptions={this.state.labelOptions}
            labels={this.state.labels}
            managedList={managedList}
            name={this.state.name}
            newUnit={this.state.newUnit}
            outputquantity={this.state.outputquantity}
            outputunituuid={this.state.outputunituuid}
            inheritTags={this.state.inheritTags}
            package_quantity={this.state.package_quantity}
            package_unit_uuid={this.state.package_unit_uuid}
            posName={this.state.posName}
            printerName={this.state.printerName}
            printLabel={this.state.printLabel}
            saveItem={this.handleItemSave}
            selectedLabels={this.state.selectedLabels}
            shelfLife={this.state.shelfLife}
            textLabels={this.getAddModalLabel()}
            type="newIngredientList"
            units={this.props.units}
            replacementItem={this.state.replacementItem}
            sku={this.state.sku}
            setSKU={this.generateSKU}
            handleOutputQuantityUpdateFormula={
              this.handleOutputQuantityUpdateFormula
            }
          />

          <div className="container-fluid">
            <ConceptHeaderComponent concept={"concept"} />

            <Row className="main light-theme">
              <Col xl={2} lg={3} className="hide-left-max">
                <RestaurantNavComponent
                  display={"recipe"}
                  restaurantuuid={this.props.match.params.uuid}
                />
              </Col>
              <Col xl={8} lg={6}>
                <ItemWrapper
                  addNewStorage={this.addNewStorage}
                  allStorage={this.state.storage}
                  area={"recipe"}
                  barcode={this.state.barcode}
                  currentPage={this.state.currentPage}
                  editStorage={this.editStorage}
                  editKeyValue={this.editKeyValue}
                  elementId={this.state.elementId}
                  fetchData={this.fetchData}
                  filterParams={this.state.filterParams}
                  getBestMatchUnits={this.getBestMatchUnits}
                  getExtraDetails={this.getExtraDetails}
                  getUpdatedCostPrices={this.getCostDetails}
                  getItems={this.getItemList}
                  // getRelatedUnits={this.getRelatedUnits}
                  getSubstitutes={this.props.getSubstitutes}
                  getUnitLabelName={this.displayUnitName}
                  handleCostUpdate={this.handleCostUpdate}
                  handleImageUpdate={this.handleImageUpdate}
                  handleSelect={this.handleSelect}
                  handleUnitUpdate={this.handleUnitUpdate}
                  hasCopy={true}
                  hasDetails={true}
                  hasTag={true}
                  hasPagination={true}
                  isFilterApplied={this.state.isFilterApplied}
                  isUnitModalOpen={this.state.isUnitModalOpen}
                  isUpdated={this.state.isUpdated}
                  ingredientsCost={this.state.ingredientsCost}
                  itemData={this.state.items}
                  itemsDetails={this.state.itemsDetails}
                  labels={this.state.labels}
                  onAddNewItem={this.handleAddItemClick}
                  onItemCopy={this.handleItemCopy}
                  onItemDelete={this.handleItemDelete}
                  onItemEdit={this.handleItemEdit}
                  onMoveToDetailPage={this.moveToDetailPage}
                  pageText={this.getPageTitle()}
                  rooms={this.state.rooms}
                  recipeStorage={this.state.recipeStorages}
                  recipeServiceSet={this.state.recipeServiceSets}
                  recipeSubstitutes={this.state.recipeSubstituteArray}
                  removeStorage={this.removeStorage}
                  renderStatusBadge={this.renderStatusBadge}
                  substitutes={this.props.substitutes}
                  setItemId={this.setItemId}
                  setCurrentPage={this.setCurrentPage}
                  setFilterParams={this.setFilterParams}
                  showItemCount={true}
                  showItemStatus={true}
                  supplierOptions={this.state.supplierOptions}
                  tags={this.props?.tags}
                  totalPages={this.state.totalPages}
                  totalCount={this.state.totalCount}
                  type="newIngredientList"
                  units={this.state.units}
                  importedItems={this.state.importedItems}
                  isBrowserNav={this.state.isBrowserNav}
                />
              </Col>
              <Col xl={2} lg={3}>
                <NavigationRightComponent />
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }

  public getAddModalLabel = () => {
    return {
      modalTitle: this.state.isEdit
        ? this.props.t("recipe.editRecipe")
        : this.props.t("recipe.addRecipe"),
    };
  };

  public getPageTitle = () => {
    return {
      addButtonTitle: this.props.t("recipe.addRecipe"),
      pageTitle: this.props.t("recipe.recipes"),
    };
  };
  public fetchData = () => {
    this.setState({
      fetchData: true,
    });
  };

  public setItemId = (itemId: string) => {
    this.setState({
      elementId: itemId,
    });
  };

  public displayUnitName = (unitId: string) => {
    if (Object.keys(this.state.globalUnits).length > 0) {
      let choiceUnit = this.state.globalUnits?.filter(
        (unit: any) => unit.uuid === unitId
      )[0];
      return commonService.applyLocalization(
        "restaurant",
        "name",
        choiceUnit?.locales
      )["symbol"];
    }
  };

  public handleItemCopy = (recipeuuid: string) => {
    this.props.copyRecipe({
      recipeuuid: recipeuuid,
      restaurantuuid: this.props.match.params.uuid,
    });
  };

  public handleItemDelete = (
    itemId: any,
    element: string,
    showModal: boolean = true
  ) => {
    showModal
      ? OpenDeleteModal().then(() => {
          this.excItemDelete(itemId, element);
        })
      : this.excItemDelete(itemId, element);
  };

  public excItemDelete = (itemId: any, element: string) => {
    this.props.removeRecipe({
      uuid: itemId,
      restaurantuuid: this.props.match.params.uuid,
    });
    this.setState({
      isUpdated: true,
      elementId: "",
    });
  };

  public moveToDetailPage = (itemId: string) => {
    this.setState({
      uuid: itemId,
    });
    this.props.history.push(
      `/restaurant/${this.props.match.params.uuid}/recipe/${itemId}/details`
    );
  };

  public getExtraDetails = (data: any) => {
    return (
      <>
        <Row className="mb-2 py-0 ">
          <Col lg={4} sm={6}>
            {this.props.t("common.outputQuantity")}{" "}
          </Col>
          <Col lg={6} sm={6}>
            {data.output_quantity} {this.displayUnitName(data.output_unit_uuid)}
          </Col>
        </Row>
        <Row className="mb-2 py-0">
          <Col lg={4} sm={6}>
            {this.props.t("common.boxQuantity")}{" "}
          </Col>
          <Col lg={6} sm={6}>
            {data.package_quantity}{" "}
            {this.displayUnitName(data.package_unit_uuid)}
          </Col>
        </Row>
        <Row className="mb-2 py-0">
          <Col lg={4} sm={6}>
            {this.props.t("common.printLabel")}
          </Col>
          <Col lg={6} sm={6} className="text-capitalize">
            <Badge color={`${data.print_label ? "primary" : "secondary"}`}>
              {JSON.stringify(!!data.print_label)}
            </Badge>
          </Col>
        </Row>
        <Row className="mb-2 py-0">
          <Col lg={4} sm={6}>
            {this.props.t("recipe.verifyOutputQuantity")}
          </Col>
          <Col lg={6} sm={6}>
            {data?.completion_quantity_correction === 0
              ? this.props.t("recipe.noVerification")
              : data?.completion_quantity_correction === 1
              ? this.props.t("recipe.verifyTotalQuantity")
              : data?.completion_quantity_correction === 2
              ? this.props.t("recipe.adjustPerLabel")
              : ""}
          </Col>
        </Row>
      </>
    );
  };

  public getDetails = (recipeId: any) => {
    this.setState({ isFetching: true });
    this.props.getRecipeDetail({
      restaurantuuid: this.props.match.params.uuid,
      recipeuuid: recipeId,
    });
  };

  getBestMatchUnits = (quantity: string, unit: string, recipeId: string) => {
    if (quantity) {
      let quantityUpdated: any = quantity;
      const currentItemOutputUnit: string =
        this.state.items.find((item: any) => item.uuid === recipeId)
          ?.output_unit_uuid || "";
      if (currentItemOutputUnit !== "") {
        const relatedUnits: Array<any> = this.getRelatedUnits(
          currentItemOutputUnit,
          recipeId
        );
        const savedUnitDetails: any = relatedUnits.find(
          (u: any) => u.value === unit
        );
        if (savedUnitDetails) {
          quantityUpdated = String(
            // Math.round(
            Number(quantity) / savedUnitDetails.conversionFactor
            // )
          );

          if (quantityUpdated.split(".")[1]?.length > 3) {
            quantityUpdated = parseFloat(Number(quantityUpdated).toFixed(3));
          }
        }
      }

      const currentUnit = this.props.units?.find(
        (unitData: any) => unitData.uuid === unit
      );
      const data: any = {
        quantity: quantityUpdated,
      };

      if (currentUnit) {
        const locales: any = commonService.applyLocalization(
          "restaurant",
          "name",
          currentUnit?.locales
        );
        data["unit"] = {
          label: locales.name,
          symbol: locales.symbol,
          value: currentUnit.uuid,
        };
      }
      return data;
    }
    return;
  };

  public getCostDetails = (ingredientId: any) => {
    this.props.getIngredientCost({
      restaurantuuid: this.props.match.params.uuid,
      uuid: ingredientId,
    });
  };

  public toggleStorageMenu = () => {
    this.setState({
      toggleStorageModal: !this.state.toggleStorageModal,
    });
  };

  public updateRecipeStorage(data: any, ingredientId: string) {
    const payLoad = {
      credentials: {
        recipeuuid: ingredientId,
        restaurantuuid: this.props.match.params.uuid,
        data: {
          storage: data.map((d: any) => {
            const { options, ...rest } = d;
            return rest;
          }),
        },
      },
    };

    updateRecipeStorage(RESTAURANT_REQUEST, payLoad).then((data) => {
      if (data.status === 200) {
        commonService.toastService(
          this.props.t("storage.storageUpdateSuccess"),
          "success"
        );
        this.setState(
          {
            isStorageUpdated: true,
          },
          () => {
            this.getStorageDetails(ingredientId);
          }
        );
      } else {
        this.setState({ isFetching: false });
        commonService.toastService(
          this.props.t("storage.storageUpdateFailed"),
          "danger"
        );
      }
    });
  }

  public getStorageDetails = (ingredientId: any) => {
    this.setState({ ingredientId: ingredientId });
    if (
      !!this.state.recipeStorages[ingredientId] &&
      !this.state.isStorageUpdated
    )
      return;
    this.setState({ isFetching: true, isStorageUpdated: false });
    const payLoad = {
      credentials: {
        recipeuuid: ingredientId,
        restaurantuuid: this.props.match.params.uuid,
      },
    };

    getRecipeStorageDetails(RESTAURANT_REQUEST, payLoad).then((data) => {
      if (data.status === 200) {
        let allRecipeStorages = _.clone(this.state.recipeStorages);

        allRecipeStorages[ingredientId] = data.data;
        this.setState({
          recipeStorages: allRecipeStorages,
          isFetching: false,
        });
      }
    });
  };

  public addNewStorage = (ingredientId: string, area: string, data: any) => {
    const mainStorageDetails = this.state.recipeStorages[ingredientId]?.find(
      (item: any) => item.main_storage
    );
    this.setState({
      activeStorageDetails: {
        area,
        data,
        ingredientId,
      },
      storageFormData: {
        inventory_type:
          mainStorageDetails?.inventory_type || INVENTORY_TYPE.MANAGED,
        main_storage: false,
        min_quantity: "",
        max_quantity: "",
        output_quantity: "",
        output_unit: "",
      },
      toggleStorageModal: true,
    });
  };

  public saveRecipeStorage = (formData: any) => {
    this.setState({
      isFetching: true,
      toggleStorageModal: false,
      storageFormData: {},
    });
    let updatedStorage: any = [];
    const { ingredientId } = this.state.activeStorageDetails;
    let allRecipeStorages = _.clone(
      this.state.recipeStorages[ingredientId] || []
    );

    if (!!this.state.activeStorageDetails?.isEdit) {
      const { editData }: any = this.state.activeStorageDetails;
      const index = _.findIndex(allRecipeStorages, (storage: any) => {
        return storage.uuid === editData.uuid;
      });

      if (!!formData.main_storage && !editData.main_storage) {
        const index = _.findIndex(allRecipeStorages, (storage: any) => {
          return storage.main_storage === true;
        });
        if (index >= 0) {
          allRecipeStorages[index]["main_storage"] = false;
        }
      }
      allRecipeStorages[index]["main_storage"] = formData.main_storage;

      if (!!formData.min_quantity && Number(formData.min_quantity) >= 0) {
        allRecipeStorages[index]["min_quantity"] =
          Number(formData.min_quantity) *
          (Number(formData.conversionFactor) || 1);
      } else {
        allRecipeStorages[index]["min_quantity"] = undefined;
      }
      if (!!formData.max_quantity && Number(formData.max_quantity) > 0) {
        allRecipeStorages[index]["max_quantity"] =
          Number(formData.max_quantity) *
          (Number(formData.conversionFactor) || 1);
      } else {
        allRecipeStorages[index]["max_quantity"] = undefined;
      }
      if (!!formData.inventory_type) {
        allRecipeStorages[index]["inventory_type"] = formData.inventory_type;
      } else {
        delete allRecipeStorages[index]["inventory_type"];
      }
      const outputQty: number =
        (Number(formData.conversionFactor) || 1) * formData.output_quantity;

      allRecipeStorages[index].output_quantity = outputQty;
      allRecipeStorages[index].output_unit = formData.output_unit_uuid;
      updatedStorage = allRecipeStorages;
    } else {
      const { area, data } = this.state.activeStorageDetails;
      let storage: any = {};
      if (area === storageType.ROOM) {
        storage.room = data[storageType.ROOM];
      } else if (area === storageType.STORAGE) {
        storage.room = data[storageType.ROOM];
        storage.storage = data[storageType.STORAGE];
      } else if (area === storageType.STORAGE_RACK) {
        storage.room = data[storageType.ROOM];
        storage.storage = data[storageType.STORAGE];
        storage.storage_rack = data[storageType.STORAGE_RACK];
      } else if (area === storageType.STORAGE_PLACE) {
        storage.room = data[storageType.ROOM];
        storage.storage = data[storageType.STORAGE];
        storage.storage_rack = data[storageType.STORAGE_RACK];
        storage.storage_place = data[storageType.STORAGE_PLACE];
      }
      if (!!formData.main_storage) {
        const index = _.findIndex(allRecipeStorages, (storage: any) => {
          return storage.main_storage === true;
        });
        if (index >= 0) {
          allRecipeStorages[index]["main_storage"] = false;
        }
        storage.main_storage = true;
      }
      if (!!formData.min_quantity && Number(formData.min_quantity) >= 0) {
        storage.min_quantity =
          Number(formData.min_quantity) *
          (Number(formData.conversionFactor) || 1);
      }
      if (!!formData.max_quantity && Number(formData.max_quantity) > 0) {
        storage.max_quantity =
          Number(formData.max_quantity) *
          (Number(formData.conversionFactor) || 1);
      }
      if (!!formData.inventory_type) {
        storage.inventory_type = formData.inventory_type;
      } else {
        delete storage.inventory_type;
      }
      const outputQty: number =
        (Number(formData.conversionFactor) || 1) * formData.output_quantity;
      storage.output_quantity = outputQty;
      storage.output_unit = formData.output_unit_uuid;

      updatedStorage = [...allRecipeStorages, ...[storage]];
    }
    this.updateRecipeStorage(updatedStorage, ingredientId);
  };

  public removeStorage = (ingredientId: string, uuid: string) => {
    OpenDeleteModal()
      .then(() => {
        this.setState({ isFetching: true });
        let allRecipeStorages = _.clone(
          this.state.recipeStorages[ingredientId]
        );

        const index = _.findIndex(allRecipeStorages, function (storage: any) {
          return storage.uuid === uuid;
        });
        if (index >= 0) {
          allRecipeStorages.splice(index, 1);
          this.setState({ isStorageUpdated: true });
          this.updateRecipeStorage(allRecipeStorages, ingredientId);
        } else {
          this.setState({ isFetching: false });
        }
      })
      .catch(() => {
        return;
      });
  };

  public renderStatusBadge = (status: number, drafts: string) => {
    switch (status) {
      case 1: {
        return (
          <Badge className="bf-badge-bg">{this.props.t("recipe.draft")}</Badge>
        );
      }
      case 2: {
        if (drafts)
          return (
            <>
              <Badge className="mr-2" color="success">
                {this.props.t("recipe.published")}
              </Badge>
              <Badge className="bf-badge-bg">
                {this.props.t("recipe.draftExists")}
              </Badge>
            </>
          );
        else
          return (
            <Badge color="success">{this.props.t("recipe.published")}</Badge>
          );
      }
      case 3: {
        return <Badge color="danger">{this.props.t("recipe.archived")} </Badge>;
      }
    }
  };

  public editKeyValue = async (ingredientId: string, uuid: string) => {
    let allRecipeStorages = _.clone(this.state.recipeStorages[ingredientId]);
    const editData = allRecipeStorages.find((st: any) => st.uuid === uuid);

    const changeKeyValue = async (
      keyValues: { key: string; value: string }[],
      action: string = "add"
    ) => {
      const payload = {
        options: keyValues.map((k) => ({
          ...k,
          action,
          resource_type: "recipeStorageLocation",
          resource_id: editData.uuid,
        })),
      };
      const routeData = {
        credentials: {
          recipeuuid: ingredientId,
          restaurantuuid: this.props.match.params.uuid,
          data: payload,
        },
      };
      addRecipeStorageKeyValue(RESTAURANT_REQUEST, routeData).then((data) => {
        if (data.status === 200) {
          commonService.toastService(
            this.props.t("storage.keyValueUpdateSuccess"),
            "success"
          );
          this.setState(
            {
              isStorageUpdated: true,
            },
            () => {
              this.getStorageDetails(ingredientId);
            }
          );
        } else {
          this.setState({ isFetching: false });
          commonService.toastService(
            this.props.t("storage.keyValueUpdateFailed"),
            "danger"
          );
        }
      });
    };
    const onEdit = async (keyValues: { key: string; value: string }[]) => {
      await changeKeyValue(keyValues);
    };
    const onDelete = async (keyValues: { key: string; value: string }[]) => {
      await areYouSureModal({
        onConfirm: async () => {
          await changeKeyValue(keyValues, "remove");
        },
      });
    };
    await manageKeyValuePairPrompt({
      onConfirm: changeKeyValue,
      onEdit,
      onDelete,
      keyValues: editData.options,
    });
  };

  public editStorage = (ingredientId: string, uuid: string) => {
    let allRecipeStorages = _.clone(this.state.recipeStorages[ingredientId]);
    const editData = allRecipeStorages.find((st: any) => st.uuid === uuid);
    const recipe = this.state.items?.find(
      (item: any) => item.uuid === ingredientId
    );
    let quantityUpdated: any = editData?.output_quantity;
    let minQuantity: any = editData?.min_quantity;
    let maxQuantity: any = editData?.max_quantity;
    const currentItemOutputUnit: string = recipe?.output_unit_uuid || "";
    if (currentItemOutputUnit !== "") {
      const relatedUnits: Array<any> = this.getRelatedUnits(
        currentItemOutputUnit,
        ingredientId
      );
      const savedUnitDetails: any = relatedUnits.find(
        (u: any) => u.value === editData?.output_unit
      );
      if (savedUnitDetails) {
        quantityUpdated = commonService.getSignificantDigit(
          Number(quantityUpdated) / savedUnitDetails.conversionFactor
        );
        minQuantity = commonService.getSignificantDigit(
          Number(minQuantity) / savedUnitDetails.conversionFactor
        );
        maxQuantity = commonService.getSignificantDigit(
          Number(maxQuantity) / savedUnitDetails.conversionFactor
        );
      }
    }
    this.setState({
      activeStorageDetails: {
        isEdit: true,
        editData,
        ingredientId,
      },
      storageFormData: {
        inventory_type: editData?.inventory_type,
        main_storage: editData?.main_storage,
        min_quantity: minQuantity,
        max_quantity: maxQuantity,
        output_quantity: quantityUpdated,
        output_unit: editData?.output_unit,
      },
      toggleStorageModal: true,
    });
  };

  getRelatedUnits = (unit: any, ingredientId: string) => {
    if (unit) {
      const units: Array<any> = this.getRelatedUnitDropdowns(
        unit,
        ingredientId
      );
      return units;
    }
    return [];
  };

  getRelatedUnitDropdowns = (
    unitId: string,
    ingredientId: string,
    allUnits: Array<any> = [],
    conversionFactor: number = 1
  ) => {
    const recipeUnit = Array.isArray(this.props.units)
      ? this.props.units?.find((unit: any) => {
          if (unit?.recipe_uuid)
            return unit.uuid === unitId && unit.recipe_uuid === ingredientId;
          return unit.uuid === unitId && !unit?.recipe_uuid;
        })
      : null;

    if (!recipeUnit) return [];
    let units: Array<any> = [
      {
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          recipeUnit.locales
        )["name"],
        value: recipeUnit.uuid,
        symbol: commonService.applyLocalization(
          "restaurant",
          "name",
          recipeUnit.locales
        )["symbol"],
        conversionFactor,
      },
    ];
    const fromUnits = recipeUnit?.from_conversions;
    const toUnits = recipeUnit?.to_conversions;

    if (fromUnits?.length > 0) {
      fromUnits.forEach((from: any) => {
        if (from?.recipe_uuid && from.recipe_uuid !== ingredientId) return;
        const itsExist = allUnits?.filter(
          (allUnit: any) => allUnit?.value === from.toUnit
        );
        if (!itsExist?.length && recipeUnit.uuid !== from.toUnit) {
          const allUnitsMerged = _.concat(allUnits, units);
          const fromUnit: any = this.getRelatedUnitDropdowns(
            from.toUnit,
            ingredientId,
            allUnitsMerged,
            conversionFactor / from.conversion_factor
          );

          if (!!fromUnit) units = _.concat(units, fromUnit);
        }
      });
    }

    if (toUnits?.length > 0) {
      toUnits.forEach((to: any) => {
        if (to?.recipe_uuid && to.recipe_uuid !== ingredientId) return;
        const itsExist = allUnits?.filter(
          (allUnit: any) => allUnit?.value === to.fromUnit
        );
        if (!itsExist?.length && recipeUnit.uuid !== to.fromUnit) {
          const allUnitsMerged = _.concat(allUnits, units);
          const toUnit: any = this.getRelatedUnitDropdowns(
            to.fromUnit,
            ingredientId,
            allUnitsMerged,
            conversionFactor * to.conversion_factor
          );

          if (!!toUnit) units = _.concat(units, toUnit);
        }
      });
    }
    return _.uniqBy(units, function (e) {
      return e.value;
    });
  };

  getRelatedUnitsForStorage = () => {
    let ingredientDetails = _.clone(
      this.state.items.find(
        (item: any) =>
          item.uuid === this.state.activeStorageDetails.ingredientId
      )
    );
    if (ingredientDetails?.output_unit_uuid) {
      return this.getRelatedUnits(
        ingredientDetails?.output_unit_uuid,
        ingredientDetails?.uuid
      );
    }
    return [];
  };
  public handleAddItemClick = () => {
    this.setState(
      {
        barcode:
          this.props.settings.settings.restaurant_code +
          generateRandomNumber(10),
      },
      () => {
        this.setState({
          color: "#FFFFFF",
          confirmationCompletion: false,
          description: "",
          isAddItemModalOpen: !this.state.isAddItemModalOpen,
          isEdit: false,
          itemId: "",
          name: "",
          newUnit: null,
          package_quantity: "1",
          package_unit_uuid: "",
          posName: "",
          printerName: "",
          outputquantity: "",
          outputunituuid: "",
          selectedLabels: [],
          supplierId: "",
          sku: "",
          replacementItem: "",
        });
      }
    );
  };
  public handleLabelSelect = (selectedLabels: any) => {
    if (selectedLabels != null) {
      this.setState({
        selectedLabels,
      });
    } else {
      this.setState({
        selectedLabels: [],
      });
    }
  };
  public handleChange = (data: any) => {
    this.setState({
      [data.target.name]:
        data.target.name === "inheritTags" || data.target.name === "printLabel"
          ? data.target.checked
          : data.target.value,
    });
  };
  public handleItemEdit = (itemId: any) => {
    const [selectedItem]: any = this.state.items.filter((item: any) => {
      return item.uuid === itemId;
    });

    this.editItemData = selectedItem;
    let allLabels = this.state.labels?.length ? this.state?.labels : [];
    let locale = commonService.applyLocalization(
      "restaurant",
      "name",
      selectedItem?.locales
    );

    const shelf_life: string = commonService.getMsToDate(
      selectedItem?.shelf_life || 0
    );
    this.setState({
      barcode: selectedItem?.product_barcode || "",
      color: selectedItem.color,
      confirmationCompletion: selectedItem?.completion_quantity_correction,
      description: locale.description,
      elementId: selectedItem.uuid,
      isAddItemModalOpen: true,
      isEdit: true,
      itemId: selectedItem.uuid,
      name: locale.name,
      newUnit: null,
      posName: locale.pos_name,
      printerName: locale.printer_name,
      printLabel: selectedItem?.print_label || false,
      package_unit_uuid: selectedItem.package_unit_uuid,
      package_quantity: selectedItem.package_quantity,
      outputquantity: selectedItem.output_quantity,
      outputunituuid: selectedItem.output_unit_uuid,
      inheritTags: selectedItem.inherit_tags_and_labels,
      selectedLabels: selectedItem.label_uuids
        ? selectedItem?.label_uuids?.split(",").map((label: any) => {
            return { value: label, label: this.labelName(label, allLabels) };
          })
        : [],
      shelfLife: shelf_life || "",
      supplierId: selectedItem?.supplier_uuid,
      sku: selectedItem.sku,
      replacementItem: selectedItem.replacement_item_uuid,
      outputQuantityUpdateFormula: "same",
    });
  };
  handleOutputQuantityUpdateFormula = (event) => {
    const value = event.target.value;
    const formula = value === "1" ? "same" : "changed";
    this.setState({ ...this.state, outputQuantityUpdateFormula: formula });
  };
  public handleItemSave = () => {
    const shelfLifeMs: number = commonService.getTimeInMs(this.state.shelfLife);
    const body: any = {
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.state.isEdit ? this.state.elementId : undefined,
      data: {
        completion_quantity_correction: Number(
          this.state.confirmationCompletion
        ),
        type: this.state.isEdit ? undefined : 1,
        label_uuid: this.state.selectedLabels.map((item: any) => item.value),
        output_quantity: Number(this.state.outputquantity),
        output_unit_uuid: this.state.outputunituuid,
        color: this.state.color,
        supplier_uuid: !!this.state.supplierId
          ? this.state.supplierId
          : undefined,
        sku: this.state.sku,
        product_barcode: this.state.barcode,
        inherit_tags_and_labels: this.state.inheritTags,
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.name,
            description: this.state.description,
            pos_name: this.state.posName,
            printer_name: this.state.printerName,
          },
        },
        package_quantity: Number(this.state.package_quantity || ""),
        package_unit_uuid: this.state.package_unit_uuid,
        print_label: this.state.printLabel,
        shelf_life: shelfLifeMs,
        replacement_item: this.state.replacementItem,
        output_quantity_update_formula: this.state.outputQuantityUpdateFormula,
      },
    };

    if (body.data.output_unit_uuid === "NEW_RECIPE_UNIT") {
      body.data.output_unit_uuid = "";
      body.data["output_unit"] = this.state.newUnit;
      delete body.data.output_unit.uuid;
    }

    if (this.state.isEdit) {
      this.state.recipeStorages[this.state.elementId] = undefined;
      RecipeEditHelper.updateRecipe(
        this.editItemData,
        body,
        this.props.updateRecipe,
        this
      );
    } else {
      this.props.addRecipe(body);
    }
    this.setState({ isUpdated: true });
  };

  handleImageUpdate = (imageId: string) => {
    const [selectedItem]: any = this.state.items.filter((item: any) => {
      return item.uuid === this.state.elementId;
    });
    let locale = commonService.applyLocalization(
      "restaurant",
      "name",
      selectedItem?.locales
    );
    const payload: any = {
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.state.elementId,
      data: {
        image_uuid: imageId,
        label_uuid: selectedItem?.label_uuids?.split(",") || [],
        color: selectedItem.color,
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: locale.name,
            description: locale.description,
            pos_name: locale.pos_name,
            printer_name: locale.printer_name,
          },
        },
        output_quantity: selectedItem.output_quantity,
        output_unit_uuid: selectedItem.output_unit_uuid,
        product_barcode: selectedItem?.product_barcode || "",
        shelf_life: selectedItem?.shelf_life || undefined,
        sku: selectedItem.sku,
        supplier_uuid: selectedItem?.supplier_uuid
          ? selectedItem?.supplier_uuid
          : undefined,
      },
    };

    this.props.updateRecipe(payload);
    this.setState({ isUpdated: true });
  };

  public handleSelect = (key: any, recipeId: any) => {
    if (key === "tags") {
      if (
        !this.state.itemsDetails.some((item: any) => item.uuid === recipeId)
      ) {
        this.getDetails(recipeId);
        this.setState({ isUpdated: true });
      }
    } else if (key === "cost") {
      this.setState({ isFetching: true });
      this.getCostDetails(recipeId);
      this.setState({ isUpdated: true });
    } else if (key === "storage") {
      this.getStorageDetails(recipeId);
    } else if (key === "substitutes") {
      if (
        !this.state.recipeSubstituteArray.some(
          (item: any) => item.uuid === recipeId
        )
      ) {
        this.setState({ isFetching: true });
        this.props.getRecipeSubstitutes({
          restaurantuuid: this.props.match.params.uuid,
          recipeuuid: recipeId,
        });
        this.setState({ isUpdated: true });
      }
    }
    this.setState({ elementId: recipeId, recipe: recipeId });
  };

  handleSupplierSelectChange = (e: any) => {
    this.setState({
      supplierId: e.target.value,
    });
  };
  public handleUnitChange = (e: any) => {
    if (e.target.value === "NEW_UNIT") {
      this.openNewUnitModal();
      return;
    } else if (e.target.value === "NEW_RECIPE_UNIT") {
      this.setState({
        outputunituuid: e.target.value,
        isUnitModalOpen: false,
      });
    } else {
      let outputUnit: string = "";
      this.props.units.forEach((unit: any) => {
        if (unit.uuid === e.target.value) {
          outputUnit = unit.uuid;
        } else if (e.target.value === "") {
          outputUnit = "";
        }
      });
      this.setState({
        outputunituuid: outputUnit,
        isUnitModalOpen: false,
      });
    }
  };

  public loadMetaData = () => {
    api
      .get(
        "/api/1.0/restaurant/" +
          this.props.match.params.uuid +
          "/get??sort=name:desc&components=unit, storage, label, room",
        {}
      )
      .then((response: any) => {
        const responseData: any = handleResponse(response);
        if (responseData.ok) {
          let options: Array<any> = [];
          const globalUnits = response.data?.unit;
          const { room, storage } = response.data;
          const rooms: any = {};
          response.data?.label?.map((labl: any) => {
            options.push({
              value: labl.uuid,
              label: commonService.applyLocalization(
                "restaurant",
                "name",
                labl?.locales
              ).name,
            });
          });
          room.forEach((r: any) => {
            rooms[r.uuid] = r;
            const name: string = commonService
              .applyLocalization("restaurant", "name", r.locales)
              ["name"].toLowerCase();
            rooms[r.uuid]["nameLower"] = name.toLowerCase();
            rooms[r.uuid]["name"] = name;
          });
          storage.forEach((st: any) => {
            if (!rooms[st.room_uuid]?.["storage"]) {
              rooms[st.room_uuid]["storage"] = [];
            }
            rooms[st.room_uuid]["storage"].push(st);
          });

          this.setState({
            globalUnits,
            labelOptions: _.sortBy(options, "label"),
            labels: response.data.label ? response.data.label : [],
            rooms: _.sortBy(rooms, "nameLower"),
            storage: response.data.storage ? response.data.storage : [],
            units: response.data.unit ? response.data.unit : [],
          });
        } else {
          handleResponse(response);
        }
      });
  };

  public labelName = (parent: any, filterResult: any) => {
    let name;
    if (!!filterResult) {
      name = filterResult.map((item: any) => {
        if (parent === item.uuid) {
          return commonService.applyLocalization(
            "restaurant",
            "name",
            item?.locales
          ).name;
        }
      });
      return name;
    }
  };
}
const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.recipe.isFetching ||
    state.ingredientCost.isFetching ||
    state.reciperemove.isFetching ||
    state.recipeupdate.isFetching ||
    state.recipeadd.isFetching ||
    state.recipedetails.isFetching ||
    state.recipecopy.isFetching ||
    state.recipeupdate.isFetching ||
    state.tagAssign.isFetching ||
    state.units.isFetching ||
    state.unitsremove.isFetching ||
    state.upload.isFetching;
  let failure =
    state.recipe.failure ||
    state.ingredientCost.failure ||
    state.reciperemove.failure ||
    state.recipeupdate.failure ||
    state.recipeadd.failure ||
    state.recipecopy.failure ||
    state.recipedetails.failure ||
    state.recipeupdate.failure ||
    state.units.failure ||
    state.unitsremove.failure ||
    state.upload.failure;
  return {
    editRecipeStatus: state.recipeupdate.data,
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    isFetching: isFetching,
    ingredientCost: state.ingredientCost.data.cost,
    failure: failure,
    addRecipeStatus: state.recipeadd.data,
    recipeCopy: state.recipecopy.data,
    recipeDetails: state.recipedetails.data,
    recipeDetailsRequest: state.recipeedit.data,
    recipeEdit: state.recipeedit.data,
    recipeRemove: state.reciperemove.data,
    recipeServiceSets: state.ingredientCost.data.serviceSets,
    recipeSubstitutes: state.recipeSubstitute.data,
    settings: state.settings.data,
    substitutes: state.allRecipeItems.data,
    tags: state.tags.data,
    units: state.units.data,
    unitsRemove: state.unitsremove.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    addRecipe: (credentials: any) => {
      dispatch(recipeAdd(credentials));
    },
    copyRecipe: (credentials: any) => {
      dispatch(recipeCopy(credentials));
    },
    getRecipeDetail: (credentials: any) => {
      dispatch(recipeDetailsRequest(credentials));
    },
    getIngredientCost: (credentials: any) => {
      dispatch(ingredientCostRequest(credentials));
    },
    getRecipeSubstitutes: (credentials: any) => {
      dispatch(recipeSubstituteRequest(credentials));
    },
    getSubstitutes: (credentials: any) => {
      dispatch(recipeItemsRequest(credentials));
    },
    getTags: (credentials: any) => {
      dispatch(tagsRequest(credentials));
    },
    getUnits: (credentials: any) => {
      dispatch(unitsRequest(credentials));
    },
    recipe: (credentials: any) => {
      dispatch(recipeRequest(credentials));
    },
    removeRecipe: (credentials: any) => {
      dispatch(recipeRemove(credentials));
    },
    updateRecipe: (credentials: any) => {
      dispatch(recipeUpdate(credentials));
    },
  };
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WebViewPostHoc(RecipePage))),
);
