import { useEffect, useState } from "react";

export enum MESSAGE_TYPE {
  BARCODE,
}

type Message = {
  type: MESSAGE_TYPE;
  message: string;
};
type DeviceMessageEvent = Event & { data?: string };

export const useRnWebviewPost = () => {
  const [barcode, setBarcode] = useState("");

  useEffect(() => {
    const handleMessage = (event: DeviceMessageEvent) => {
      if (event.data) {
        try {
          const data: Message = JSON.parse(event.data);
          switch (data.type) {
            case MESSAGE_TYPE.BARCODE:
              if (data.message !== barcode) {
                setBarcode(data.message);
              }
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    document.addEventListener("message", handleMessage);

    return () => {
      document.removeEventListener("message", handleMessage);
    };
  }, [barcode]);

  return { barcode };
};
