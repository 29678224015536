import _ from "lodash";
import React, { useMemo } from "react";
import RecipeLocationTreeCard from "./RecipeLocationTreeCard";

interface Props {
  actions: {
    addNewStorage: Function;
    editStorage: Function;
    editKeyValue: Function;
    getBestMatchUnits: Function;
    removeStorage: Function;
  };
  allLocations: Array<any>;
  recipeId: string;
  savedLocations: Array<any>;
}

const RecipeLocationTreeCardWrapper = (props: Props) => {
  const { actions, allLocations, recipeId, savedLocations } = props;

  const getClasses = (childHasSelection: boolean, ItemSelected: boolean) => {
    let classes = "b_tree_label ";
    if (childHasSelection && !ItemSelected) {
      classes += "text-info";
    } else if (ItemSelected) {
      classes += "font-weight-bold";
    }
    return classes;
  };

  const getUpdatedLocations = () => {
    const allLocationsUpdated = _.cloneDeep(allLocations);
    _.cloneDeep(savedLocations)?.forEach((location: any) => {
      const roomDetails = allLocationsUpdated.find(
        (room: any) => room.uuid === location.room,
      );
      if (roomDetails) {
        if (!location?.storage) {
          location["isActive"] = true;
          roomDetails["selection"] = location;
        } else {
          roomDetails["selection"] = roomDetails["selection"] || {};
          roomDetails["selection"]["hasNode"] = true;
          const storageDetails: any = roomDetails?.storage?.find(
            (st: any) => st.uuid === location.storage,
          );
          if (storageDetails && !location?.storage_rack) {
            location["isActive"] = true;
            storageDetails["selection"] = location;
          } else if (storageDetails) {
            storageDetails["selection"] = storageDetails["selection"] || {};
            storageDetails["selection"]["hasNode"] = true;
            const rackDetails: any = storageDetails?.storageracks?.find(
              (st: any) => st.uuid === location.storage_rack,
            );
            if (rackDetails && !location?.storage_place) {
              location["isActive"] = true;
              rackDetails["selection"] = location;
            } else if (rackDetails && location?.storage_place) {
              rackDetails["selection"] = rackDetails["selection"] || {};
              rackDetails["selection"]["hasNode"] = true;
              const placeDetails: any = rackDetails?.places?.find(
                (st: any) => st.uuid === location.storage_place,
              );

              if (placeDetails) {
                location["isActive"] = true;
                placeDetails["selection"] = location;
              }
            }
          }
        }
      }
    });
    return allLocationsUpdated;
  };

  const renderCards = () => {
    const updatedLocations: any = getUpdatedLocations();
    const actionsData: any = {
      addNewStorage: actions.addNewStorage,
      editStorage: actions.editStorage,
      editKeyValue: actions.editKeyValue,
      getBestMatchUnits: actions.getBestMatchUnits,
      getClasses: getClasses,
      removeStorage: actions.removeStorage,
    };
    const metaData: any = {
      recipeId: recipeId,
    };
    return (
      <ul className="b_tree mt-3">
        {updatedLocations.map((room: any, indx: number) => (
          <RecipeLocationTreeCard
            actions={actionsData}
            key={indx}
            metaData={metaData}
            storage={room}
            area={"room"}
          />
        ))}
      </ul>
    );
  };

  const renderContents: any = useMemo(
    () => renderCards(),
    [allLocations, savedLocations],
  );
  return <div>{renderContents}</div>;
};

export default RecipeLocationTreeCardWrapper;
