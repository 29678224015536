import { UserVerificationModel } from '../../models/user.verification.model';
import * as types from '../actions/types';

const initialState: UserVerificationModel = {
  isFetching: false,
  data: {},
  error: {},
  failure: false
};

export default function userVerificationReducer(state = initialState, action: any): UserVerificationModel {
  switch (action.type) {
    case types.USER_VERIFICATION.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {}
      };
    case types.USER_VERIFICATION.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {}
      };
    case types.USER_VERIFICATION.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err
      };
    default:
      return state;
  }
}
