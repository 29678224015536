import apiCall from './api.call.config';

async function room(url: string, params: any) {
    try {
        return await apiCall.get(url + '/' + params.credentials.restaurantuuid + '/room?sort=name:asc');
    } catch (e) {
        throw e;
    }
}

async function updateRoom(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.put(url + '/' + params.credentials.restaurantuuid + '/room/'
            + params.credentials.uuid, params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function addRoom(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url + '/' + params.credentials.restaurantuuid + '/room', params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function removeRoom(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.remove(url + '/' + params.credentials.restaurantuuid + '/room/' + params.credentials.uuid);
    } catch (e) {
        throw e;
    }
}

async function details(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.restaurantuuid + "/room/" + params.uuid + "/details"
    );
  } catch (e) {
    throw e;
  }
}

async function guestGroups(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.restaurantuuid +
        "/room/" +
        params.uuid +
        "/guest-groups"
    );
  } catch (e) {
    throw e;
  }
}

export default {
  room,
  updateRoom,
  addRoom,
  removeRoom,
  details,
  guestGroups,
};
