import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  Badge,
  Table,
  Input,
} from "reactstrap";
import DatePicker from "react-datepicker";

import commonService from "../../../services/common.service";

import "../../../styles/tag-assign.scss";
import { GRAY, USER_RIGHTS } from "../../../constant/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";

export default function ShoppingCartModalCard(props: any) {
  const { t, i18n } = useTranslation();
  const { orderComment, deliveryDate, placeOrder, shoppingCartArray } = props;
  const [showDeliveryDate, setShowDeliveryDate] = useState(false);
  const [viewItemPrices, setViewItemPrices] = useState(false);

  const activeRestaurant = JSON.parse(
    localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
  );
  useEffect(() => {
    if (deliveryDate === "") {
      const newDate: any = moment().add(1, "days").toDate();
      props.handleDateChange(newDate);
    }
  }, []);
  useEffect(() => {
    setShowDeliveryDate(false);
  }, []);
  useEffect(() => {
    const viewItemPrices = commonService.hasPermissionToAccess(
      USER_RIGHTS.VIEW_ITEM_PRICES,
      activeRestaurant?.uuid
    );
    setViewItemPrices(viewItemPrices === true);
  }, [activeRestaurant?.uuid]);

  const handleOrderClick = () => {
    if (!showDeliveryDate) setShowDeliveryDate(true);
    else {
      placeOrder();
      setShowDeliveryDate(false);
    }
  };

  const handleCancel = () => {
    if (showDeliveryDate) setShowDeliveryDate(false);
    else props.toggle();
  };

  const handleClearCart = () => {
    props.clearShoppingCart();
  };

  const renderTagValue = (tag: any) => {
    var value: any = "";
    switch (tag.tag?.meta.type) {
      case 1: {
        value = !!tag.recipe_tag_meta.value
          ? tag.recipe_tag_meta.value
          : tag.recipe_tag_meta.default;

        break;
      }
      case 2: {
        value =
          !!String(tag.recipe_tag_meta.value) &&
          tag.recipe_tag_meta.value !== undefined
            ? String(tag.recipe_tag_meta.value)
            : String(tag.recipe_tag_meta.default);

        break;
      }
      case 3: {
        value =
          tag.recipe_tag_meta.value && tag.recipe_tag_meta.value?.value
            ? tag.recipe_tag_meta.value.value
            : tag.recipe_tag_meta.value || tag.recipe_tag_meta.value === ""
            ? tag.recipe_tag_meta.value
            : tag.recipe_tag_meta.default;

        break;
      }
    }
    return value;
  };
  const minorUnit = JSON.parse(
    localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
  )?.minor_units;

  return (
    <Modal size="xl" isOpen={props.isOpen}>
      <ModalHeader toggle={props.toggle} className="modal-header">
        {t("common.shoppingCart")}
      </ModalHeader>
      <ModalBody>
        {!showDeliveryDate ? (
          shoppingCartArray?.length > 0 ? (
            <Table responsive className="table-striped text-nowrap">
              <thead>
                <tr>
                  <th className="text-center">{t("common.quantity")}</th>
                  <th>{t("common.unit")}</th>
                  <th>{t("common.name")}</th>
                  {viewItemPrices && <th>{t("common.unitPrice")}</th>}
                  <th>{t("common.totalQuantity")}</th>
                  {viewItemPrices && <th>{t("common.totalCost")}</th>}
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {shoppingCartArray?.map((item: any, itemIndex: any) => (
                  <tr key={itemIndex}>
                    <td className="text-center">{item.quantity}</td>
                    <td>{item?.unit_name}</td>
                    <td>
                      <div>
                        {
                          commonService.applyLocalization(
                            "restaurant",
                            "name",
                            item?.locales
                          )["name"]
                        }
                        <Badge color="info" className="ml-2 mt-1">
                          <span className="mr-1">{item?.output_quantity}</span>
                          {item?.unit_symbol}
                        </Badge>
                      </div>
                      <div className="ml-2">
                        {item?.tags
                          ?.filter((tag: any) => tag?.recipe_tag_meta?.override)
                          ?.map((tag: any, index: any) => {
                            return tag.tag.key === "com.smarttoni.comment" ||
                              tag.tag.key ===
                                "com.smarttoni.overridableprice" ? (
                              tag.recipe_tag_meta.value ? (
                                <p className="m-0">
                                  {commonService.applyLocalization(
                                    "restaurant",
                                    "name",
                                    tag?.tag?.locales
                                  )["name"] + "("}

                                  {tag?.recipe_tag_meta?.value
                                    ? String(tag?.recipe_tag_meta?.value)
                                    : String(tag?.recipe_tag_meta?.default)}
                                  {")"}
                                </p>
                              ) : (
                                ""
                              )
                            ) : (
                              <p className="m-0">
                                {commonService.applyLocalization(
                                  "restaurant",
                                  "name",
                                  tag?.tag?.locales
                                )["name"] + "("}
                                {renderTagValue(tag)}
                                {")"}
                              </p>
                            );
                          })}
                      </div>
                    </td>
                    {viewItemPrices && (
                      <td>
                        {parseFloat(
                          commonService.displayDigitalAmount(item.price)
                        )?.toFixed(minorUnit)}{" "}
                        {activeRestaurant?.currency_code}
                      </td>
                    )}
                    <td>
                      <span className="mr-1">
                        {Number(item.quantity || 0) * item.output_quantity}
                      </span>
                      {item?.unit_symbol}
                    </td>
                    {viewItemPrices && (
                      <td>
                        {(
                          item.quantity *
                          commonService.displayDigitalAmount(item.price)
                        )?.toFixed(minorUnit)}{" "}
                        {activeRestaurant?.currency_code}
                      </td>
                    )}
                    <td>
                      <span
                        onClick={() => {
                          props.editCartItem(item, itemIndex);
                        }}
                        className="pointer-cursor"
                        data-toggle="tooltip"
                        data-placement="left"
                        title={t("common.edit")}
                      >
                        <FontAwesomeIcon
                          style={{
                            color: GRAY,
                          }}
                          className="m-1"
                          icon={faEdit}
                        />
                      </span>{" "}
                      <span
                        onClick={() => {
                          props.removeCartItem(itemIndex);
                        }}
                        className="pointer-cursor"
                        data-toggle="tooltip"
                        data-placement="left"
                        title={t("common.delete")}
                      >
                        <FontAwesomeIcon
                          style={{
                            color: GRAY,
                          }}
                          className="m-1"
                          icon={faTrashAlt}
                        />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Alert color="info">{t("common.noItemsInCart")}</Alert>
          )
        ) : (
          <>
            <Row>
              <Col sm={4}>{t("common.deliveryDate")}</Col>
              <Col sm={8}>
                <DatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy h:mm aa"
                  disabledKeyboardNavigation
                  minDate={moment().add(1, "days").toDate()}
                  selected={deliveryDate}
                  onChange={(value: any) => props.handleDateChange(value)}
                  showTimeInput
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4}>{t("common.orderComment")}</Col>
              <Col sm={8}>
                <Input
                  className="form-control"
                  type="textarea"
                  style={{ minHeight: 120 }}
                  required={true}
                  name="comment"
                  placeholder={t("common.enterOrderComment")}
                  value={orderComment}
                  onChange={props.handleCommentChange}
                />
              </Col>
            </Row>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        {!showDeliveryDate && (
          <Button
            color="danger"
            disabled={shoppingCartArray?.length === 0}
            className="mr-auto"
            onClick={handleClearCart}
          >
            {t("common.clearCart")}
          </Button>
        )}
        <Button color="secondary" onClick={handleCancel}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          disabled={
            shoppingCartArray?.length === 0 ||
            (!props.deliveryDate && showDeliveryDate)
          }
          onClick={handleOrderClick}
        >
          {showDeliveryDate ? t("common.send") : t("common.order")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
