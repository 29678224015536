import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import PrinterService from "../../services/recipe.printer.service";
import KeyValueComponent, { KeyValue } from "./key-value-component";
import { Col, Row } from "reactstrap";
import LoaderComponent from "../loader/loader";

type PrderInterfaceProps = {
  restaurantUuid: string;
};

const OrderInterface: React.FC<PrderInterfaceProps> = ({ restaurantUuid }) => {
  const resourceId = restaurantUuid;
  const resourceType = "orderInterface";
  const { t, i18n } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const [keyValuePairs, setKeyValuePairs] = useState<KeyValue[]>([]);

  useEffect(() => {
    document.body.className = "light-theme";
  }, []);

  useEffect(() => {
    fetchAllKeyValues();
  }, [restaurantUuid]);

  const fetchAllKeyValues = () => {
    setIsFetching(true);
    const payLoad = {
      credentials: {
        resource_type: resourceType,
        resource_id: resourceId,
        restaurantuuid: restaurantUuid,
      },
    };
    PrinterService.getKeyValueOptions(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        setKeyValuePairs(response.data);
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
      });
  };

  return (
    <div className="white-box mb-3">
      <LoaderComponent display={!!isFetching} />
      <h4>{t("common.orderInterface")}</h4>
      <Row className="app-row-padding mt-2">
        <Col>
          <KeyValueComponent
            restaurantUuid={restaurantUuid}
            initialData={keyValuePairs}
            resourceType={resourceType}
            resourceId={resourceId}
            fetchData={fetchAllKeyValues}
          />
        </Col>
      </Row>
    </div>
  );
};

export default OrderInterface;
