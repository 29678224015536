export interface ServiceSetModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface ServiceSetState {
  addLocationTitle: string;
  addTiming: boolean;
  allRecipes: any;
  allRecipesChecked: any;
  allSavedLabels: any;
  color: string;
  currencyCode: any;
  currentRoom: any;
  currentVat: any;
  description: any;
  editTime: boolean;
  endTime: any;
  endTimeIndex: any;
  hasLabelUpdated: any;
  isDeleted: boolean;
  isEdit: boolean;
  isFetching: boolean;
  isModalOpen: boolean;
  isNewServiceSet: boolean;
  isRoomModal: boolean;
  isUpdated: boolean;
  isValidSelect: any;
  isWeekDayOpen: any;
  itemModalData: any;
  labelOptions: Array<any>;
  name: string;
  open: boolean;
  recipeList: any;
  restaurantuuid: string;
  room_uuid: any;
  rooms: any;
  searchName: string;
  selectable: boolean;
  selectedItemId: string;
  selectedOption: any;
  SelectedWeekIndex: any;
  serviceset: any;
  serviceSetRooms: any;
  serviceSetVat: any;
  startTime: any;
  startTimeIndex: any;
  supplierRestaurants: Array<any>;
  timeValue: string;
  uuid: string;
  vat_class_uuid: any;
  vat: any;
  weekDayIndex: any;
  weekDays: any;
}

export const ServiceSetInitState = {
  addLocationTitle: "",
  addTiming: false,
  allRecipes: [],
  allRecipesChecked: true,
  allSavedLabels: {},
  color: "#CCCCCC",
  currencyCode: "",
  currentRoom: {},
  currentVat: {},
  description: "",
  editTime: false,
  endTime: [],
  endTimeIndex: "",
  hasLabelUpdated: null,
  isDeleted: false,
  isEdit: false,
  isFetching: false,
  isModalOpen: false,
  isNewServiceSet: false,
  isRoomModal: false,
  isUpdated: false,
  isValidSelect: true,
  isWeekDayOpen: false,
  itemModalData: {},
  labelOptions: [],
  name: "",
  open: false,
  recipeList: [],
  restaurantuuid: "",
  room_uuid: "",
  rooms: [],
  searchName: "",
  selectable: false,
  selectedItemId: "",
  selectedOption: "select",
  SelectedWeekIndex: "",
  serviceset: [],
  serviceSetRooms: [],
  serviceSetVat: [],
  startTime: [],
  startTimeIndex: "",
  supplierRestaurants: [],
  timeValue: "",
  uuid: "",
  vat_class_uuid: "",
  vat: [],
  weekDayIndex: [],
  weekDays: [
    {
      id: 1,
      day: "Monday",
      isSelected: false,
    },
    {
      id: 2,
      day: "Tuesday",
      isSelected: false,
    },
    {
      id: 3,
      day: "Wednesday",
      isSelected: false,
    },
    { id: 4, day: "Thursday", isSelected: false },
    {
      id: 5,
      day: "Friday",
      isSelected: false,
    },
    {
      id: 6,
      day: "Saturday",
      isSelected: false,
    },
    {
      id: 7,
      day: "Sunday",
      isSelected: false,
    },
  ],
};

export interface ServiceSetCardState {
  activeTab: string;
  isChannelEditable: boolean;
  isRestaurantEditable: boolean;
  labels: Array<any>;
  labelsUuids: Array<any>;
  selectedChannels: Array<any>;
  selectedRestaurants: Array<any>;
  inventoryAccessToClient: boolean;
  isInventoryAccessEditable: boolean;
}
export const ServiceSetCardInitState = {
  activeTab: "details",
  isChannelEditable: false,
  isRestaurantEditable: false,
  labels: [],
  labelsUuids: [],
  selectedChannels: [],
  selectedRestaurants: [],
  inventoryAccessToClient: false,
  isInventoryAccessEditable: false,
};
export interface ServiceSetLabelCardState {
  warnings: any;
  label: any;
}
export const ServiceSetCardLabelInitState = {
  warnings: {},
  label: {},
};
