import apiCall from "./api.call.config";
import { connect } from "react-redux";
import { fetchMediaIfRequired, postMedia } from "../database/media.database";

export const ACTION_DATABASE_SETFETCHING = "ACTION_DATABASE_FETCH";
export const ACTION_DATABASE_SETREADY = "ACTION_DATABASE_SETREADY";
export const ACTION_DATABASE_SETERROR = "ACTION_DATABASE_SETERROR";
export const ACTION_DATABASE_POSTSUCCESS = "ACTION_DATABASE_POSTSUCCESS";

export function databaseSelector(state: any, entity: any, initState: any) {
  if (!state.database[entity]) {
    return {
      isValid: false,
      isFetching: false,
      isError: false,
      data: initState,
    };
  } else if (!state.database[entity].isValid) {
    return Object.assign(state.database[entity], {
      data: initState,
    });
  } else {
    return state.database[entity] || {};
  }
}

interface DatabaseActionModel {
  type: string;
  entity: any;
  reducerCallback?: Function;
  data?: any;
}

export function fetchDatabaseIfRequired(
  entity: any,
  endPointUrl: any,
  reducerCallback: Function,
  entityType: string
) {
  return function (dispatch: any, getState: any) {
    if (
      !getState().database[entity + "_" + entityType] ||
      !getState().database[entity + "_" + entityType].isValid
    ) {
      return dispatch(
        fetchDatabase(entity, endPointUrl, reducerCallback, entityType)
      );
    } else {
      return;
    }
  };
}

export function fetchDatabasePaginated(
  entity: any,
  endPointUrl: any,
  reducerCallback: Function,
  entityType: string
) {
  return function (dispatch: any, getState: any) {
    return dispatch(
      fetchDatabase(entity, endPointUrl, reducerCallback, entityType)
    );
  };
}

function fetchDatabase(
  entity: any,
  endPointUrl: any,
  reducerCallback: Function,
  entityType: string
) {
  return function (dispatch: any) {
    dispatch(databaseSetFetching(entity));
    return apiCall.get(endPointUrl).then(
      (response: any) => {
        if (response.status === 200) {
          dispatch(
            databaseSetReady(
              entity + "_" + entityType,
              reducerCallback,
              response
            )
          );
        } else {
          dispatch(databaseSetError(entity));
          throw new Error("Get from database not successful");
        }
      },
      (error: any) => {
        throw new Error("Get from database not successful");
      }
    );
  };
}

export function postToDatabase(
  entity: any,
  endPointUrl: any,
  reducerCallback: Function,
  data: any,
  entityType: string
) {
  return function (dispatch: any, getState: any) {
    return new Promise((resolve, reject) => {
      apiCall
        .post(endPointUrl, data)
        .then((response: any) => {
          if (response.status === 200) {
            dispatch(
              databasePostSuccess(
                entity + "_" + entityType,
                reducerCallback,
                response
              )
            );
            resolve("Data edit successful");
          } else {
            reject("Data edit not successful");
          }
        })
        .catch((error: any) => {
          reject("Data edit not successful");
        });
    });
  };
}

function databaseSetFetching(entity: any): DatabaseActionModel {
  return {
    type: ACTION_DATABASE_SETFETCHING,
    entity: entity,
  };
}

function databaseSetReady(
  entity: any,
  reducerCallback: Function,
  data: any
): DatabaseActionModel {
  return {
    type: ACTION_DATABASE_SETREADY,
    entity: entity,
    reducerCallback: reducerCallback,
    data: data,
  };
}

function databasePostSuccess(
  entity: any,
  reducerCallback: Function,
  data: any
): DatabaseActionModel {
  return {
    type: ACTION_DATABASE_POSTSUCCESS,
    entity: entity,
    reducerCallback: reducerCallback,
    data: data,
  };
}

function databaseSetError(entity: any): DatabaseActionModel {
  return {
    type: ACTION_DATABASE_SETERROR,
    entity: entity,
  };
}

export function databaseReducer(state = {}, action: any): Object {
  let specificState;
  switch (action.type) {
    case ACTION_DATABASE_SETFETCHING:
      // @ts-ignore
      if (state[action.entity]) {
        // @ts-ignore
        specificState = { ...state[action.entity] };
      } else {
        specificState = {
          isValid: false,
          isFetching: false,
          isError: false,
          data: null,
        };
      }
      return {
        ...state,
        [action.entity]: Object.assign(specificState, {
          isFetching: true,
        }),
      };
      break;
    case ACTION_DATABASE_SETREADY:
      // @ts-ignore
      if (state[action.entity]) {
        // @ts-ignore
        specificState = { ...state[action.entity] };
      } else {
        specificState = {
          isValid: false,
          isFetching: false,
          isError: false,
          data: null,
        };
      }
      return {
        ...state,
        [action.entity]: {
          isValid: true,
          isFetching: false,
          isError: false,
          data: action.reducerCallback(specificState.data, action.data),
        },
      };
      break;
    case ACTION_DATABASE_POSTSUCCESS:
      // @ts-ignore
      if (state[action.entity]) {
        // @ts-ignore
        specificState = { ...state[action.entity] };
      } else {
        specificState = {
          isValid: false,
          isFetching: false,
          isError: false,
          data: null,
        };
      }
      return {
        ...state,
        [action.entity]: Object.assign(specificState, {
          // @ts-ignore
          data: action.reducerCallback(specificState.data, action.data),
        }),
      };
      break;
    case ACTION_DATABASE_SETERROR:
      // @ts-ignore
      if (state[action.entity]) {
        // @ts-ignore
        specificState = { ...state[action.entity] };
      } else {
        specificState = {
          isValid: false,
          isFetching: false,
          isError: false,
          data: null,
        };
      }
      return {
        ...state,
        [action.entity]: Object.assign(specificState, {
          isFetching: false,
          isError: true,
        }),
      };
      break;
    default:
      return state;
  }
}

export function databaseFetchingCallback(oldState: any, response: any): any {
  return response.data;
}

export function databasePostToCollectionCallback(
  oldState: any,
  response: any
): any {
  return [...oldState, response.data];
}

export async function removeMedia(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.post(
      url + "/" + params.credentials.restaurantuuid + "/media",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function getMedia(url: string, params: any) {
  try {
    // eslint-disable-next-line
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/media?" +
        params.credentials.URLSearchParams
    );
  } catch (e) {
    throw e;
  }
}
