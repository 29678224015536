import "../../styles/page404.scss";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Alert, Table } from "reactstrap";

import { withTranslation } from "react-i18next";
import moment from "moment";
import commonService from "../../services/common.service";
class RevenuePerPaymentType extends Component<any> {
  render() {
    const { t, data } = this.props;
    return (
      <>
        {data?.revenuePerPaymentType?.length === 0 && (
          <Alert color="info">{t("common.noRecords")}</Alert>
        )}
        {data?.revenuePerPaymentType?.length > 0 && (
          <>
            <section className="mt-3 mb-5">
              <h5>{t("reports.revenuePerPaymentType")}</h5>

              <Table bordered striped>
                <thead>
                  <tr className="bg-light">
                    <th className="w-25">{t("reports.timestamp")}</th>
                    <th className="w-25">{t("reports.paymentType")}</th>
                    <th className="w-25">{t("common.currency")}</th>
                    <th className="w-25">{t("common.amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.revenuePerPaymentType?.map((innerData: any) => {
                    return (
                      <tr>
                        <td>
                          {moment(innerData.timestamp, "x").format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>{innerData.paymentType}</td>
                        <td>{innerData.currency}</td>
                        <td>
                          {commonService.displayDigitalAmount(innerData.amount)}
                        </td>
                      </tr>
                    );
                  })}
                  {data?.revenuePerPaymentType.length === 0 && (
                    <tr>
                      <td colSpan={4} className="text-center">
                        {t("common.noRecords")}
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <th>{t("common.total")}</th>
                    <th></th>
                    <th>{data?.restaurantCurrency}</th>
                    <th>
                      {commonService.displayDigitalAmount(
                        data?.totalRevenuePerPaymentType
                      )}
                    </th>
                  </tr>
                </tfoot>
              </Table>
            </section>
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withRouter(RevenuePerPaymentType));
