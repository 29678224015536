import { TaskStepModel } from '../../models/task.step.model';
import * as types from '../actions/types';

const initialState: TaskStepModel = {
    isFetching: false,
    data: {},
    error: {},
    failure: false,
  };

  export default function taskStepAddReducer(state = initialState, action: any): TaskStepModel {
    switch (action.type) {
      case types.TASK_STEP_ADD.REQUEST:
        return {
          ...state,
          isFetching: true,
          failure: false,
          error: {}
        };
      case types.TASK_STEP_ADD.SUCCESS:
        return {
          ...state,
          isFetching: false,
          data: action.data,
          failure: false,
          error: {}
        };
      case types.TASK_STEP_ADD.FAILURE:
        return {
          ...state,
          isFetching: false,
          failure: true,
          data:action.err,
          error: action.err
        };
      default:
        return state;
    }
  }