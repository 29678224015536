import { ActionModel } from "../../models/action.model";
import * as types from "./types";

export function choiceRequest(credentials: object): ActionModel {
  return {
    type: types.CHOICE.REQUEST,
    credentials,
  };
}

export function choiceSuccess(data: object): ActionModel {
  return {
    type: types.CHOICE.SUCCESS,
    data,
  };
}

export function choiceFailure(err: object): ActionModel {
  return {
    type: types.CHOICE.FAILURE,
    err,
  };
}

export function ingredientClassRequest(credentials: object): ActionModel {
  return {
    type: types.INGREDIENT_CLASS.REQUEST,
    credentials,
  };
}

export function ingredientClassSuccess(data: object): ActionModel {
  return {
    type: types.INGREDIENT_CLASS.SUCCESS,
    data,
  };
}

export function ingredientClassFailure(err: object): ActionModel {
  return {
    type: types.INGREDIENT_CLASS.FAILURE,
    err,
  };
}

export function choiceItemUpdate(credentials: object): ActionModel {
  return {
    type: types.CHOICE_UPDATE.REQUEST,
    credentials,
  };
}

export function choiceItemUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.CHOICE_UPDATE.SUCCESS,
    data,
  };
}

export function choiceItemUpdateFailure(err: object): ActionModel {
  return {
    type: types.CHOICE_UPDATE.FAILURE,
    err,
  };
}
