import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function taskRequest(credentials: object): ActionModel {
  return {
    type: types.TASK.REQUEST,
    credentials
  };
}

export function taskSuccess(data: object): ActionModel {
  return {
    type: types.TASK.SUCCESS,
    data
  };
}

export function taskFailure(err: object): ActionModel {
  return {
    type: types.TASK.FAILURE,
    err
  };
}

export function taskUpdate(credentials: object): ActionModel {
  return {
    type: types.TASK_UPDATE.REQUEST,
    credentials
  };
}

export function taskUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.TASK_UPDATE.SUCCESS,
    data
  };
}

export function taskUpdateFailure(err: object): ActionModel {
  return {
    type: types.TASK_UPDATE.FAILURE,
    err
  };
}

export function taskAdd(credentials: object): ActionModel {
  return {
    type: types.TASK_ADD.REQUEST,
    credentials
  };
}

export function taskAddSuccess(data?: object): ActionModel {
  return {
    type: types.TASK_ADD.SUCCESS,
    data
  };
}

export function taskAddFailure(err: object): ActionModel {
  return {
    type: types.TASK_ADD.FAILURE,
    err
  };
}

export function taskRemove(credentials: object): ActionModel {
  return {
    type: types.TASK_REMOVE.REQUEST,
    credentials
  };
}

export function taskRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.TASK_REMOVE.SUCCESS,
    data
  };
}

export function taskRemoveFailure(err: object): ActionModel {
  return {
    type: types.TASK_REMOVE.FAILURE,
    err
  };
}

export function taskIngredientRequest(credentials: object): ActionModel {
  return {
    type: types.TASK_INGREDIENT.REQUEST,
    credentials
  };
}

export function taskIngredientSuccess(data: object): ActionModel {
  return {
    type: types.TASK_INGREDIENT.SUCCESS,
    data
  };
}

export function taskIngredientFailure(err: object): ActionModel {
  return {
    type: types.TASK_INGREDIENT.FAILURE,
    err
  };
}

export function taskIngredientAdd(credentials: object): ActionModel {
  return {
    type: types.TASK_INGREDIENT_ADD.REQUEST,
    credentials
  };
}

export function taskIngredientAddSuccess(data?: object): ActionModel {
  return {
    type: types.TASK_INGREDIENT_ADD.SUCCESS,
    data
  };
}

export function taskIngredientAddFailure(err: object): ActionModel {
  return {
    type: types.TASK_INGREDIENT_ADD.FAILURE,
    err
  };
}

export function taskIngredientRemove(credentials: object): ActionModel {
  return {
    type: types.TASK_INGREDIENT_REMOVE.REQUEST,
    credentials
  };
}

export function taskIngredientRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.TASK_INGREDIENT_REMOVE.SUCCESS,
    data
  };
}

export function taskIngredientRemoveFailure(err: object): ActionModel {
  return {
    type: types.TASK_INGREDIENT_REMOVE.FAILURE,
    err
  };
}

export function taskIngredientUpdate(credentials: object): ActionModel {
  return {
    type: types.TASK_INGREDIENT_UPDATE.REQUEST,
    credentials
  };
}

export function taskIngredientUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.TASK_INGREDIENT_UPDATE.SUCCESS,
    data
  };
}

export function taskIngredientUpdateFailure(err: object): ActionModel {
  return {
    type: types.TASK_INGREDIENT_UPDATE.FAILURE,
    err
  };
}
