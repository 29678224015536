import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import {
    rolesAddFailure,
    rolesAddSuccess,
    rolesFailure,
    rolesRemoveFailure,
    rolesRemoveSuccess,
    rolesSuccess,
    rolesUpdateFailure,
    rolesUpdateSuccess,
} from '../redux/actions/roles.action';
import * as types from '../redux/actions/types';
import handleResponse from '../services/response.service';
import userRoleList from '../services/roles.service';

const rolesCall = (params: any) => userRoleList.roleList(RESTAURANT_REQUEST, params);
const updateRoleCall = (params: any) => userRoleList.updateRole(RESTAURANT_REQUEST, params);
const addRoleCall = (params: any) => userRoleList.addRole(RESTAURANT_REQUEST, params);
const removeRoleCall = (params: any) => userRoleList.removeRole(RESTAURANT_REQUEST, params);

function* handleRolesRequest(params: object) {
    try {
        let result;
        result = yield call(rolesCall, params);
        yield handlRoles200(result);
    } catch (error) {
        throw error;
    }
}

const handlRoles200 = function* handlRoles200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(rolesSuccess(result.data));
    } else {
        yield put(rolesFailure(result.data));
        handleResponse(result);
    }
}

function* handleRoleUpdate(params: object) {
    try {
        let result;
        result = yield call(updateRoleCall, params);
        yield handleRoleUpdate204(result);
    } catch (error) {
        throw error;
    }
}

const handleRoleUpdate204 = function* handleRoleUpdate204(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 204) {
        yield put(rolesUpdateSuccess(result.data));
    } else {
        yield put(rolesUpdateFailure(result.data));
    }
    handleResponse(result);
}

function* handleRoleAdd(params: object) {
    try {
        let result;
        result = yield call(addRoleCall, params);
        yield handleRoleAdd201(result);
    } catch (error) {
        throw error;
    }
}

const handleRoleAdd201 = function* handleRoleAdd201(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 201) {
        yield put(rolesAddSuccess(result.data));
    } else {
        yield put(rolesAddFailure(result.data));
    }
    handleResponse(result);
}

function* handleRoleRemove(params: object) {
    try {
        let result;
        result = yield call(removeRoleCall, params);
        yield handleRolesRemove204(result);
    } catch (error) {
        throw error;
    }
}

const handleRolesRemove204 = function* handleRolesRemove204(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 204) {
        yield put(rolesRemoveSuccess(result.data));
    } else {
        yield put(rolesRemoveFailure(result.data));
    }
    handleResponse(result);
}

export default function* userRolesSaga() {
    yield takeLatest(types.ROLES.REQUEST, handleRolesRequest);
    yield takeLatest(types.ROLES_UPDATE.REQUEST, handleRoleUpdate);
    yield takeLatest(types.ROLES_ADD.REQUEST, handleRoleAdd);
    yield takeLatest(types.ROLES_REMOVE.REQUEST, handleRoleRemove);
}