import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function forgotpasswordRequest(credentials: object): ActionModel {
  return {
    type: types.FORGOT_PASSWORD.REQUEST,
    credentials
  };
}

export function forgotpasswordSuccess(data?: object): ActionModel {
  return {
    type: types.FORGOT_PASSWORD.SUCCESS,
    data
  };
}

export function forgotpasswordFailure(err: object): ActionModel {
  return {
    type: types.FORGOT_PASSWORD.FAILURE,
    err
  };
}
