import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import "../../../styles/units.scss";
import Select from "react-select";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { unitModalProps } from "../../../models/units.model";
export default function UnitsAddCard(props: unitModalProps) {
  const { t, i18n } = useTranslation();
  const { handleChange, isModalOpen, payload, unitModalActions } = props;
  return (
    <Modal isOpen={isModalOpen}>
      <ModalHeader
        toggle={() => unitModalActions("cancel")}
        className="modal-header"
      >
        {t("unit.addUnits")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="name">{t("unit.unitName")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name={"name"}
              autoComplete="name"
              placeholder={t("unit.enterUnitName")}
              value={payload.name}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="symbol">{t("unit.unitSymbol")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name="symbol"
              autoComplete="symbol"
              placeholder={t("unit.enterUnitSymbol")}
              value={payload.symbol}
              onChange={handleChange}
            />
          </FormGroup>
          {payload?.hasGrossWeight && (
            <>
              <FormGroup>
                <Label for="grossWeight">{t("unit.grossWeight")}</Label>
                <div className="d-flex ">
                  <div className="b-width-200 mr-2">
                    <Input
                      type="number"
                      required={true}
                      name="grossWeight"
                      placeholder={t("unit.enterGrossWeight")}
                      value={payload.grossWeight}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-75">
                    <Select
                      name={"grossWeightUnitSelection"}
                      value={payload.grossWeightUnitSelection}
                      onChange={(e) =>
                        props.handleChange({
                          target: {
                            name: "grossWeightUnitSelection",
                            value: e,
                          },
                        })
                      }
                      options={unitModalActions("getBaseUnitOptions") || []}
                      maxMenuHeight={200}
                      styles={{
                        menu: (provided: any) => ({
                          ...provided,
                          color: "#000",
                        }),
                      }}
                    />
                  </div>
                </div>
              </FormGroup>
            </>
          )}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => unitModalActions("cancel")}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={() => unitModalActions("save")}
          disabled={
            !payload.name ||
            !payload.symbol ||
            (payload?.hasGrossWeight &&
              payload.grossWeight?.trim() !== "" &&
              isNaN(parseInt(payload?.grossWeight?.trim() || "")))
          }
        >
          {t("common.save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
