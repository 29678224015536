import React, { Component } from "react";
import { withCardItemSelectionState } from "../cardItemSelectionManager";
import {
  CardItemCardComponent,
  CardItemContainer,
} from "../card-components/card-item";
import Select from "react-select";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

import commonService from "../../services/common.service";
import { ChannelsInitState, ChannelsState } from "../../models/channels.model";
import WarningComment, {
  getMandatoryWarning,
} from "../warning-comment/warning-comment";
import { Col, Row } from "reactstrap";

class ChannelsItemListCard extends Component<any, any> {
  state: ChannelsState;
  constructor(props: any) {
    super(props);
    this.state = ChannelsInitState;
  }
  componentDidMount() {
    this._loadValues();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.restaurantCheckList.length !==
        this.props.restaurantCheckList.length ||
      prevState.cardItemData !== this.props.cardItemData
    ) {
      this._loadValues();
    }
  }
  private _loadValues() {
    this.setState({
      cardItemData: this.props.cardItemData,
    });
    if (this.state?.type === "newCard") {
      this.setState({
        component_price: false,
        outputQuantity: null,
        restaurant: "",
      });
    } else if (
      this.props.cardItemSelectionData.selectionState.isItemSelected &&
      this.props.cardItemSelectionData.selectionState.mode === "edit"
    ) {
      const restaurant =
        commonService.applyLocalization(
          "restaurant",
          "name",
          this.state.cardItemData.locales
        )["name"] || "";
      this.setState({
        restaurant: restaurant,
        warnings: {
          restaurant: getMandatoryWarning(this.state.cardItemData.uuid),
        },
      });
    } else {
      let restaurant =
        !!this.props.restaurantCheckList &&
        this.props.restaurantCheckList?.filter(
          (item: any) => item.value === this.state.cardItemData.uuid
        )[0];
      this.setState({
        component_price: this.props.cardItemData?.component_price,
        outputQuantity: this.state.cardItemData.output_quantity,
        restaurant: restaurant,
        warnings: {
          restaurant: getMandatoryWarning(this.state.cardItemData.uuid),
          outputQuantity: getMandatoryWarning(
            this.state.cardItemData.output_quantity
          ),
        },
      });
    }
  }

  private selectThisChoice = () => {
    let restaurant =
      this.props.restaurantCheckList &&
      this.props.restaurantCheckList?.filter(
        (item: any) => item.value === this.state.cardItemData.uuid
      )[0];

    this.props.cardItemSelectionData.selectCardItem();
    this.setState({
      restaurant: restaurant,
      outputQuantity: this.state.cardItemData.output_quantity,
      warnings: {
        restaurant: getMandatoryWarning(this.state.cardItemData.uuid),
        outputQuantity: getMandatoryWarning(
          this.state.cardItemData.output_quantity
        ),
      },
    });
  };

  render() {
    const { t } = this.props;
    return (
      <CardItemContainer className={"task-type px-0"}>
        <CardItemCardComponent
          cardItemSelectionData={this.props.cardItemSelectionData}
          className={"task-type assigned"}
          deleteCallback={this.deleteCard}
          onDeleteClick={this.deleteCard}
          readOnly={false}
          saveCallback={this.saveCard}
          selectCallback={this.selectThisChoice}
          saveButton={false}
        >
          <Row>
            <Col md={4} lg={6} xl={4}>
              <WarningComment
                data={this.state.warnings?.restaurant}
                displayType={"bottom"}
              >
                {this.props.cardItemSelectionData.selectionState
                  .isBeingEdited ? (
                  <Select
                    name="restaurant"
                    value={this.state.restaurant}
                    onChange={this.handleSelectChange}
                    options={this.props.restaurantOptions}
                    maxMenuHeight={200}
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        color: "#000",
                      }),
                    }}
                  />
                ) : (
                  <div>{this.state.restaurant?.label}</div>
                )}
              </WarningComment>
            </Col>
          </Row>
        </CardItemCardComponent>
      </CardItemContainer>
    );
  }

  deleteCard = () => {
    this.props.onDeleteCard(this.props.indx);
  };

  handleSelectChange = (value: any) => {
    const warnings: any = { ...this.state.warnings };

    this.setState({ restaurant: value });
    warnings.restaurant = getMandatoryWarning(value.value);
    this.setState({ warnings });
  };

  saveCard = () => {
    if (!this.state.restaurant) {
      this.setState({
        warnings: {
          restaurant: getMandatoryWarning(this.state.restaurant || ""),
        },
      });
    }

    if (this.state.warnings.restaurant === null && this.state.restaurant) {
      this.props.onSaveCard(
        this.state.restaurant.value,
        this.props.cardItemId,
        this.props.indx
      );
    }
  };
}

export default withTranslation()(
  withRouter(
    withCardItemSelectionState("ItemListCard", 1)(ChannelsItemListCard)
  )
);
