import "../../../styles/navigation-header.scss";

import React from "react";
import { Alert, Col, Form, Navbar } from "reactstrap";

import smartToni from "../../../assets/images/logo/smartToni.png";
import RestaurantHeaderCard from "../restaurant-header-card/restaurant-header-card";
import RightHeader from "../right-header-card/right-header-card";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import commonService from "../../../services/common.service";
import { USER_RIGHTS } from "../../../constant/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export default function HeaderWithRestaurantCard(props: any) {
  const { t, i18n } = useTranslation();

  let hasRestaurantManageAccess: any = false;
  if (typeof props?.hasAccess !== "undefined") {
    hasRestaurantManageAccess = props.hasAccess;
  } else {
    hasRestaurantManageAccess = commonService.hasPermissionToAccess(
      USER_RIGHTS.MANAGE_RESTAURANT,
      props?.uuid,
      props.redirectionURL
    );
  }

  const isRestaurantAndProfileLangSame = () => {
    const restaurantDetails: any = JSON.parse(
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
    );
    const profileLanguages = JSON.parse(
      localStorage.getItem("USERLANGUAGE") || "[]"
    );
    let isItExist: boolean = false;
    const selectedLang: any = profileLanguages.find(
      (pLang: any) => pLang.uuid === restaurantDetails?.language
    );
    if (selectedLang) {
      isItExist = true;
    }
    return isItExist;
  };

  const hasRestaurantLang: boolean = isRestaurantAndProfileLangSame();
  return (
    <>
      <div
        className={`hide-left-max b-header-wrapper sticky ${
          !hasRestaurantLang ? " has-lang-alert" : ""
        }`}
      >
        <Navbar className="row py-2" expand="lg">
          <Col className="col-lg-3 col-xl-2">
            <div
              className="d-flex smart-logo-width pl-15"
              style={{
                display: !!localStorage.getItem("WEBVIEW") ? "none" : "block",
              }}
            >
              <Link to={`/restaurant/${props.uuid}/home`}>
                <img
                  className="img-home-fluid compact-logo"
                  src={smartToni}
                  alt=""
                />
              </Link>
            </div>
          </Col>
          <Col
            className="d-flex align-items-center col-lg-5 col-xl-6 b-main-nav-wrapper"
            style={{
              display: !!localStorage.getItem("WEBVIEW") ? "none" : "block",
            }}
          >
            <RestaurantHeaderCard
              uuid={props.uuid}
              display={props.dis}
              concept={props.concept}
              staff={props.staff}
              operate={props.operate}
              support={props.support}
              hasAccess={hasRestaurantManageAccess}
            />
          </Col>
          <Col className="col-lg-4 col-xl-4  b-main-nav-right">
            <RightHeader
              hasAccess={hasRestaurantManageAccess}
              goToSettings={props.goToSettings}
              settings={props.settings}
              restaurantName={props.restaurantName}
              RESTAURANT={props.RESTAURANT}
              modalToggle={props.modalToggle}
              goToProfile={props.goToProfile}
            />
          </Col>
        </Navbar>
        {!hasRestaurantLang && (
          <Alert color="danger">
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
            {t("language.userRestLangNitMatching")}
          </Alert>
        )}
      </div>
    </>
  );
}
