import { call, put, takeLatest } from "redux-saga/effects";

import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  courseListFailure,
  courseListRequest,
  courseListSuccess,
  courseListUpdate,
  courseListUpdateFailure,
  courseListUpdateSuccess
} from "../redux/actions/course.list.action";

import * as types from "../redux/actions/types";
import courselist from "../services/course.list.service";
import handleResponse from "../services/response.service";

const courseListCall = (params: any) =>
  courselist.courseList(RESTAURANT_REQUEST, params);

const updateCourseListCall = (params: any) =>
  courselist.updateCourseList(RESTAURANT_REQUEST, params);

function* handleCourseListRequest(params: object) {
  try {
    let result: any;
    result = yield call(courseListCall, params);
    yield handleCourseListRequest200(result);
  } catch (error) {
    throw error;
  }
}

const handleCourseListRequest200 = function* handleCourseListRequest200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(courseListSuccess(result.data));
  } else {
    yield put(courseListFailure(result.data));
    handleResponse(result);
  }
};

function* handleCourseListUpdate(params: object) {
  try {
    let result;
    result = yield call(updateCourseListCall, params);
    yield handleCourseListUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleCourseListUpdate200 = function* handleCourseListUpdate200(
  result: any
) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(courseListUpdateSuccess(result.data));
  } else {
    yield put(courseListUpdateFailure(result.data));
  }
  handleResponse(result);
};

export default function* courseListSaga() {
  yield takeLatest(types.COURSE_LIST.REQUEST, handleCourseListRequest);
  yield takeLatest(types.COURSE_LIST_UPDATE.REQUEST, handleCourseListUpdate);
}
