import { faClipboard, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { QRCodeState, QRCodeInitState } from "../../models/qrcode.model";
import {
  getRoomDetails,
  qrCodeAdd,
  qrCodeRemove,
  qrCodeRequest,
  qrCodeUpdate,
} from "../../redux/actions/qrcode.action";
import commonService from "../../services/common.service";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import LoaderComponent from "../loader/loader";
import OperateHeaderComponent from "../navigation/navigation-header/operate-header/operate-header";
import OperateLeftComponent from "../navigation/navigation-left/operate-navigation/operate-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import QRCodeLists from "./qrcode-lists";
import { withTranslation } from "react-i18next";
import { settingsRequest } from "../../redux/actions/stations.action";

class QRCodesComponent extends Component<any> {
  state: QRCodeState;
  constructor(props: any) {
    super(props);
    this.state = QRCodeInitState;
    this._setFunctionBindings();
  }
  componentDidMount() {
    document.body.className = "light-theme";
    this.props.getRoomDetails({ restaurantuuid: this.props.match.params.uuid });
    this.props.getQrCodes({ restaurantuuid: this.props.match.params.uuid });
    this.props.getRestaurantSetting({
      restaurantuuid: this.props.match.params.uuid,
    });
  }

  _setFunctionBindings() {
    this.handleAddNewCode = this.handleAddNewCode.bind(this);
    this.handleRemoveCode = this.handleRemoveCode.bind(this);
    this.handleSelectQrCodes = this.handleSelectQrCodes.bind(this);
    this.handleUpdateCode = this.handleUpdateCode.bind(this);
  }

  cancelDelete() {
    this.setState({ isDeleteModalOpen: false, selectedItemId: [] });
  }
  confirmDelete() {
    const codes: Array<string> =
      this.state.selectedItemId.length !== 0
        ? this.state.selectedItemId
        : this.state.selectedCodes;

    let formData: any = {
      restaurantuuid: this.props.match.params.uuid,
      data: {
        qr_code: codes,
      },
    };
    this.props.removeQrCode(formData);
    this.setState({
      isDeleteModalOpen: false,
      selectedCodes: [],
      selectedItemId: [],
      isFetching: true,
      isUpdated: true,
    });
  }
  copySelectedCodesToClipboard() {
    if (this.state.selectedCodes.length === 0) {
      commonService.toastService(
        this.props.t("toast.nocodeSelected"),
        "warning"
      );
      return;
    }
    const el = document.createElement("textarea");
    let val: any = "";
    const selectedCodes = this.state.qrCodes.filter((code: any) => {
      return this.state.selectedCodes.includes(code.uuid);
    });
    selectedCodes.map((code: any, index: number) => {
      val += code.url;
      if (index + 1 < selectedCodes.length) {
        val += ", ";
      }
    });
    el.value = val;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    commonService.toastService(
      this.props.t("toast.selectedCodesCopied"),
      "secondary"
    );
  }
  getCode(area: string, uuid: string, qrCodes: Array<object>) {
    return qrCodes.filter((data: any) => {
      return data[area] === uuid;
    });
  }
  getSubItem(
    data: any,
    area: string,
    key: string,
    uuid: string,
    areaName: string,
    qrCodes: Array<object>
  ) {
    const subItem = data[area].filter((item: any) => {
      return item[key] === uuid;
    });
    subItem.map((station: any, index: any) => {
      station["codes"] = this.getCode(areaName, station.uuid, qrCodes);
      station["area"] = areaName;
      if (key === "room_uuid") {
        station["child"] = this.getSubItem(
          data,
          "table",
          "station",
          station.uuid,
          "table",
          qrCodes
        );
      }
    });
    return subItem;
  }

  handleAddNewCode(id: any, area: number) {
    let formData: any = {
      restaurantuuid: this.props.match.params.uuid,
      data: {
        domain: this.state.qrCodeBaseUrl[0],
      },
    };
    formData.data[area] = [id];
    this.props.addQrCodes(formData);
    this.setState({ isFetching: true, isUpdated: true });
  }

  handleRemoveCode(id: string) {
    this.setState({ isDeleteModalOpen: true, selectedItemId: [id] });
  }
  handleSelectQrCodes(data: any) {
    const updatedItems: Array<string> = [...this.state.selectedCodes];
    if (data.isChecked) {
      updatedItems.push(data.key);
    } else {
      const index = updatedItems.indexOf(data.key);
      if (index > -1) {
        updatedItems.splice(index, 1);
      }
    }
    this.setState({ selectedCodes: updatedItems });
  }
  handleUpdateCode(destinationData: any, draggableItem: string) {
    let data: any = {};
    data[destinationData.destinationArea] = destinationData.destinationId;
    let formData: any = {
      restaurantuuid: this.props.match.params.uuid,
      id: draggableItem,
      data: data,
    };
    this.setState({ isFetching: true, isUpdated: true });
    this.props.updateQrCode(formData);
  }
  removeSelectedCodes() {
    if (this.state.selectedCodes.length === 0) {
      commonService.toastService(
        this.props.t("toast.noCodeSelected"),
        "warning"
      );
      return;
    }
    this.setState({ isDeleteModalOpen: true, selectedItemId: [] });
  }
  setRoomData(roomData: any, qrCodes: Array<object>) {
    if (Object.keys(roomData).length === 0) return;
    let rooms = [...roomData.room];

    rooms.map((room: any, index: any) => {
      room["child"] = this.getSubItem(
        roomData,
        "station",
        "room_uuid",
        room.uuid,
        "station",
        qrCodes
      );
      room["codes"] = this.getCode("room", room.uuid, qrCodes);
      room["area"] = "room";
    });
    this.setState({ roomsGrouped: rooms });
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <LoaderComponent display={!!this.state.isFetching} />
        <DeleteModalCard
          isOpen={this.state.isDeleteModalOpen}
          isFetching={!!this.props.isFetching}
          okDelete={this.confirmDelete.bind(this)}
          cancelDelete={this.cancelDelete.bind(this)}
        />
        <div className="container-fluid">
          <OperateHeaderComponent operate={"operate"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <OperateLeftComponent
                display={"qr-codes"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={8} lg={6}>
              <div className="white-box mb-3">
                <header className="d-flex justify-content-between px-3">
                  <h4>{this.props.t("qrCode.QRCodes")}</h4>
                  <div className="col-right">
                    <Button
                      color="link"
                      className="px-1 py-0"
                      onClick={() => {
                        this.copySelectedCodesToClipboard();
                      }}
                      title={this.props.t("qrCode.copySelectedQRcode")}
                    >
                      <FontAwesomeIcon size="lg" icon={faClipboard} />
                    </Button>
                    <Button
                      color="link"
                      className="px-1 py-0"
                      onClick={() => {
                        this.removeSelectedCodes();
                      }}
                      title={this.props.t("qrCode.removeSelectedQRcode")}
                    >
                      <FontAwesomeIcon size="lg" icon={faTrash} />
                    </Button>
                  </div>
                </header>

                <QRCodeLists
                  rooms={this.state.roomsGrouped}
                  qrCodeBaseUrl={this.state.qrCodeBaseUrl}
                  onNewCode={this.handleAddNewCode}
                  onRemoveCode={this.handleRemoveCode}
                  onSelection={this.handleSelectQrCodes}
                  onUpdateQrCodes={this.handleUpdateCode}
                />
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this.setState({
        isFetching: newProps.isFetching,
      });
      if (this.state.isUpdated) {
        this.setState({ isUpdated: false });
        this.props.getQrCodes({ restaurantuuid: this.props.match.params.uuid });
        return;
      }
      if (
        !!newProps.qrCodeBaseUrl &&
        !!newProps.roomDetails &&
        !!newProps.qrCodes &&
        !newProps.isFetching &&
        !newProps.failure
      ) {
        this.setState({
          qrCodes: newProps.qrCodes,
          qrCodeBaseUrl: newProps.qrCodeBaseUrl,
          rooms: newProps.roomDetails,
        });
        this.setRoomData(newProps.roomDetails, newProps.qrCodes);
      }
    }
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching = state.qrCodes.isFetching;
  let failure = state.qrCodes.failure;
  return {
    isFetching: isFetching,
    failure: failure,
    qrCodes: state.qrCodes.data,
    qrCodeAdd: state.qrCodeAdd.data,
    qrCodeRemove: state.qrCodeRemove.data,
    qrCodeUpdate: state.qrCodeUpdate.data,
    roomDetails: state.qrCodeGetRoomDetails.data,
    qrCodeBaseUrl: state.settings.data?.url,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    addQrCodes: (credentials: any) => {
      dispatch(qrCodeAdd(credentials));
    },
    getQrCodes: (credentials: any) => {
      dispatch(qrCodeRequest(credentials));
    },
    getRestaurantSetting: (credentials: any) => {
      dispatch(settingsRequest(credentials));
    },
    getRoomDetails: (credentials: any) => {
      dispatch(getRoomDetails(credentials));
    },
    removeQrCode: (credentials: any) => {
      dispatch(qrCodeRemove(credentials));
    },
    updateQrCode: (credentials: any) => {
      dispatch(qrCodeUpdate(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(QRCodesComponent))
);
