import { IngredientsModel } from "../../models/ingredients.model";
import * as types from "../actions/types";

const initialState: IngredientsModel = {
  isFetching: false,
  data: {},
  error: {},
  failure: false,
};
export default function ingredientsDetailReducer(
  state = initialState,
  action: any
): IngredientsModel {
  switch (action.type) {
    case types.INGREDIENTS_DETAIL.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.INGREDIENTS_DETAIL.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.INGREDIENTS_DETAIL.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function ingredientCostReducer(
  state = initialState,
  action: any
): IngredientsModel {
  switch (action.type) {
    case types.INGREDIENT_COST.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.INGREDIENT_COST.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.INGREDIENT_COST.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}

export function ingredientCostUpdateReducer(
  state = initialState,
  action: any
): IngredientsModel {
  switch (action.type) {
    case types.INGREDIENT_COST_UPDATE.REQUEST:
      return {
        ...state,
        isFetching: true,
        failure: false,
        error: {},
      };
    case types.INGREDIENT_COST_UPDATE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        failure: false,
        error: {},
      };
    case types.INGREDIENT_COST_UPDATE.FAILURE:
      return {
        ...state,
        isFetching: false,
        failure: true,
        error: action.err,
      };
    default:
      return state;
  }
}
