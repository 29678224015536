import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import {
    labelAddFailure,
    labelAddSuccess,
    labelFailure,
    labelRemoveFailure,
    labelRemoveSuccess,
    labelSuccess,
    labelUpdateFailure,
    labelUpdateSuccess,
} from '../redux/actions/labels.action';
import * as types from '../redux/actions/types';
import labels from '../services/labels.service';
import handleResponse from '../services/response.service';

const labelsCall = (params: any) => labels.label(RESTAURANT_REQUEST, params);
const updateLabelsCall = (params: any) => labels.updateLabel(RESTAURANT_REQUEST, params);
const addLabelsCall = (params: any) => labels.addLabel(RESTAURANT_REQUEST, params);
const removeLabelsCall = (params: any) => labels.removeLabel(RESTAURANT_REQUEST, params);

function* handleLabelsRequest(params: object) {
    try {
        let result;
        result = yield call(labelsCall, params);
        yield handleLabels200(result);
    } catch (error) {
        throw error;
    }
}

const handleLabels200 = function* handleLabels200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(labelSuccess(result.data));
    } else {
        yield put(labelFailure(result.data));
        handleResponse(result);
    }
}

function* handleLabelsUpdate(params: object) {
    try {
        let result;
        result = yield call(updateLabelsCall, params);
        yield handleLabelsUpdate200(result);
    } catch (error) {
        throw error;
    }
}

const handleLabelsUpdate200 = function* handleLabelsUpdate200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(labelUpdateSuccess(result.data));
    } else {
        yield put(labelUpdateFailure(result.data));
    }
    handleResponse(result);
}

function* handleLabelsAdd(params: object) {
    try {
        let result;
        result = yield call(addLabelsCall, params);
        yield handleLabelsAdd200(result);
    } catch (error) {
        throw error;
    }
}

const handleLabelsAdd200 = function* handleLabelsAdd200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(labelAddSuccess(result.data));
    } else {
        yield put(labelAddFailure(result.data));
    }
    handleResponse(result);
}

function* handleLabelsRemove(params: object) {
    try {
        let result;
        result = yield call(removeLabelsCall, params);
        yield handleLabelsRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handleLabelsRemove200 = function* handleLabelsRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(labelRemoveSuccess(result.data));
    } else {
        yield put(labelRemoveFailure(result.data));
    }
    handleResponse(result);
}
export default function* labelsSaga() {
    yield takeLatest(types.LABELS.REQUEST, handleLabelsRequest);
    yield takeLatest(types.LABELS_UPDATE.REQUEST, handleLabelsUpdate);
    yield takeLatest(types.LABELS_ADD.REQUEST, handleLabelsAdd);
    yield takeLatest(types.LABELS_REMOVE.REQUEST, handleLabelsRemove);
}
