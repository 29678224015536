import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Input,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import { compose } from "redux";

import ModalFactory from "react-modal-promise";
import { Prompt, withRouter } from "react-router";
import { withTranslation } from "react-i18next";

import LoaderComponent from "../../components/loader/loader";
import ConceptHeaderComponent from "../../components/navigation/navigation-header/concept-header/concept-header";
import RestaurantNavComponent from "../../components/navigation/navigation-left/restaurant-navigation/restaurant-navigation";
import NavigationRightComponent from "../../components/navigation/navigation-right/navigation-right";

import WarningComment from "../../components/warning-comment/warning-comment";
import { withCardItemSelectionStateManager } from "../../components/cardItemSelectionManager";

import {
  clearRecipeItems,
  recipeDetailsRequest,
  recipeItemsRequest,
} from "../../redux/actions/recipe.action";
import ServiceSetWrapper from "../../components/service-set/serviceSetWrapper";
import {
  clearServiceSetDetailsRequest,
  serviceSetDetailsRequest,
  serviceSetUpdateRecipes,
} from "../../redux/actions/serviceset.details.action";
import { servicesetRequest } from "../../redux/actions/serviceset.action";
import {
  ServiceSetDetailsInitState,
  ServiceSetDetailsState,
} from "../../models/serviceset.details.model";
import ServiceSetRecipeCard from "../../components/service-set/serviceSetRecipeCard";
import { vatRequest } from "../../redux/actions/vat.action";
import { courseListRequest } from "../../redux/actions/course.list.action";
import { OpenDeleteModal } from "../../components/card-components/delete-card/delete-modal";
import DragAndDropWrapper from "../../components/drag-drop-component";
import { settingsRequest } from "../../redux/actions/stations.action";
import { currencyRequest } from "../../redux/actions/currency.action";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import commonService from "../../services/common.service";
import { updateServiceSetLabel } from "../../services/service.set";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import { getRestaurantSuppliers } from "../../services/supplier.restaurants.service";
import handleResponse from "../../services/response.service";
import { unitsRequest } from "../../redux/actions/units.action";
import Select from "react-select";
import { getRestaurantItemMetaDetails } from "../../services/restaurant.detail.service";

class ServiceSetDetailsPage extends Component<any> {
  state: ServiceSetDetailsState;
  newlySavedLabels: Array<any> = [];
  allRecipesUpdated: boolean = false;
  constructor(props: any) {
    super(props);
    this.state = ServiceSetDetailsInitState;
  }

  componentDidMount() {
    this.props.getSettings({ restaurantuuid: this.props.match.params.uuid });
    document.body.className = "light-theme";

    this.getSuppliers();

    this.props.getAllRecipes({
      uuid: this.props.match.params.uuid,
      type: "1,2,3,4,5",
    });

    this.props.getServiceSetDetail({
      restaurantUuid: this.props.match.params.uuid,
      itemUuid: this.props.match.params.itemUuid,
    });
    this.props.getUnits({ restaurantuuid: this.props.match.params.uuid });
    this.props.getVatList({ restaurantuuid: this.props.match.params.uuid });

    this.props.getCourseList({
      restaurantuuid: this.props.match.params.uuid,
    });
    this.setState({
      isJustMounted: true,
      itemId: this.props.match.params.itemUuid,
    });

    window.onbeforeunload = (event: any) => {
      if (
        !_.isEqual(this.state.savedServiceRecipes, this.state.serviceRecipes)
      ) {
        const message = this.props.t("common.routeChangeAlert");
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = message;
        }
        return message;
      } else {
        return;
      }
    };

    this.loadMetaData();
  }

  componentWillUnmount() {
    this.props.clearServiceSetDetail();
    this.props.clearAllRecipes();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.isUpdated && !this.props.isFetching && !this.props.failure) {
      this.props.getServiceSetDetail({
        restaurantUuid: this.props.match.params.uuid,
        itemUuid: this.props.match.params.itemUuid,
      });
      this.setState({
        isUpdated: false,
      });
    }
    if (
      prevProps.allRecipes &&
      (!_.isEqual(prevProps.allRecipes, this.props.allRecipes) ||
        (!this.allRecipesUpdated && this.props.allRecipes?.length > 0))
    ) {
      this.setAllRecipesWithSupplier();
    }
    if (
      !_.isEqual(
        prevState.selectedLabelOptions,
        this.state.selectedLabelOptions
      ) ||
      !_.isEqual(prevState.searchByName, this.state.searchByName)
    ) {
      this.filterServiceSetItems();
    }
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    let update: any = {};

    if (nextProps.isFetching !== prevState.isFetching) {
      update.isFetching = nextProps.isFetching;
    }

    if (
      !_.isEqual(nextProps.serviceSetDetails, prevState.serviceSetDetails) &&
      !nextProps.failure
    ) {
      update.serviceSetDetails = _.clone(nextProps.serviceSetDetails);
      update.serviceRecipes =
        _.clone(nextProps.serviceSetDetails?.recipes) || [];
      update.savedServiceRecipes =
        _.clone(nextProps.serviceSetDetails?.recipes) || [];
      update.serviceRecipesGrouped = _.groupBy(
        update.serviceRecipes,
        "selectable"
      );
    }
    if (
      (!!prevState.isUpdated &&
        JSON.stringify(prevState.serviceSetDetails) !==
          JSON.stringify(nextProps.serviceSetTimeUpdate?.data) &&
        JSON.stringify(nextProps.serviceSetTimeUpdate?.data)) ||
      (prevState.isUpdated && !nextProps.isFetching && !nextProps.failure)
    ) {
      update.serviceSetDetails = _.clone(nextProps.serviceSetTimeUpdate.data);
      update.serviceRecipes =
        _.clone(nextProps.serviceSetTimeUpdate.data?.recipes) || [];
      update.savedServiceRecipes =
        _.clone(nextProps.serviceSetTimeUpdate.data?.recipes) || [];
      update.serviceRecipesGrouped = _.groupBy(
        update.serviceRecipes,
        "selectable"
      );
      update.isUpdate = false;
    }
    if (!_.isEqual(nextProps.recipeDetails, prevState.currentItemDetails)) {
      update.currentItemDetails = nextProps.recipeDetails;
    }

    return update;
  }

  setUpdated = () => {
    this.setState({
      isUpdated: true,
    });
  };

  loadMetaData = () => {
    const payload: any = {
      credentials: {
        items: "label",
        restaurantuuid: this.props.match.params.uuid,
      },
    };
    getRestaurantItemMetaDetails(RESTAURANT_REQUEST, payload).then(
      (response: any) => {
        const responseData: any = handleResponse(response);
        if (responseData.ok) {
          const { label } = responseData.data;
          this.setState({ labels: label });
        }
      }
    );
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Prompt
          when={
            !_.isEqual(
              this.state.savedServiceRecipes,
              this.state.serviceRecipes
            )
          }
          message={t("common.routeChangeAlert")}
        />
        <ModalFactory />
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <ConceptHeaderComponent concept={"concept"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <RestaurantNavComponent
                display={"service-sets"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={8} lg={6}>
              <ServiceSetWrapper
                handleTimeUpdate={this.handleTimeUpdate}
                getServiceSet={this.props.getServiceSet}
                isDetailPage={true}
                isServiceItemsUpdate={
                  !_.isEqual(
                    this.state.savedServiceRecipes,
                    this.state.serviceRecipes
                  )
                }
                itemData={
                  Object.keys(this.state.serviceSetDetails || {}).length === 0
                    ? []
                    : [this.state.serviceSetDetails]
                }
                pageText={this.getPageTitle()}
                setUpdated={this.setUpdated}
              />
              <div className="dim-box mb-3">
                <Row className="app-row-padding">
                  <Col xs={12}>
                    <h4>
                      {t("choiceItem.items")} (
                      {this.getFilteredItemsCount(this.state.serviceRecipes)}
                      )
                      <WarningComment data={null} />
                    </h4>
                    {this.renderFilterSection()}
                    {this.renderItemsBlock()}
                  </Col>
                </Row>
                <div className="my-3 text-right">
                  <Button color="info" onClick={this.handlePageSave}>
                    {t("common.save")}
                  </Button>
                </div>
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }

  getOptions = (optionArray: Array<any>) => {
    const options: Array<any> = [];
    optionArray.forEach((item: any) => {
      const option: any = {
        value: item.uuid,
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          item.locales
        )["name"],
      };

      options.push(option);
    });
    return options;
  };

  handleSelectChange = (selectedOption: any) => {
    const selectedLabels: Array<any> =
      selectedOption != null ? selectedOption : [];
    this.setState({
      selectedLabelOptions: selectedLabels,
    });
  };

  filterServiceSetItems = () => {
    if (this.state.serviceRecipes?.length > 0) {
      const filteredRecipes = this.state.serviceRecipes.map((item: any) => {
        item.show = true;
        const [currentRecipe] = this.state.allRecipes?.filter((recipe: any) => {
          return item.recipe_uuid === recipe.uuid;
        });
        const itemLocale = commonService.applyLocalization(
          "restaurant",
          "name",
          currentRecipe.locales
        );
        currentRecipe.name = itemLocale.name;
        if (this.state?.searchByName.trim() !== "") {
          item.show = currentRecipe.name
            .toLowerCase()
            .includes(this.state.searchByName.toLowerCase());
        }

        if (item.show && this.state.selectedLabelOptions?.length > 0) {
          item.show = this.state.selectedLabelOptions.every(
            (l: { label: string; value: string }) => {
              return currentRecipe.label_uuids.includes(l.value);
            }
          );
        }

        return item;
      });
      this.setState({
        serviceRecipes: filteredRecipes,
        serviceRecipesGrouped: _.groupBy(filteredRecipes, "selectable"),
      });
    }
  };

  getFilteredItems = (items: any) => {
    return items
      ? items.filter((item: any) => !item.hasOwnProperty("show") || !!item.show)
      : [];
  };

  getFilteredItemsCount = (items: any): number => {
    return this.getFilteredItems(items)?.length;
  };

  public checkItemsInTheServiceSet = (recipeId: string) => {
    const isAdded = this.state.serviceRecipes.filter((item: any) => {
      return item.recipe_uuid === recipeId;
    });
    return isAdded.length > 0;
  };

  public getCurrentItemDetails = (itemId: string, type: string) => {
    this.setState({ isUpdated: false });
    if (type === "choice") {
      this.props.getItemDetail({
        restaurantuuid: this.props.match.params.uuid,
        recipeuuid: itemId,
      });
    }
  };

  public getNonSelectableServiceItems = (items: Array<any>) => {
    items?.map((item: any) => {
      if (this.state.allRecipes?.length > 0) {
        const [currentRecipe] = this.state.allRecipes?.filter((recipe: any) => {
          return item.recipe_uuid === recipe.uuid;
        });
        if (!!currentRecipe) {
          item.name = commonService
            .applyLocalization("restaurant", "name", currentRecipe.locales)
            ?.name?.toLowerCase();
        }
      }
    });
    return _.orderBy(items, ["name"], ["asc"]);
  };

  public getPageTitle = () => {
    return { pageTitle: this.props.t("serviceSet.details") };
  };

  getSupplierDetails = (supplierId: string) => {
    const supplierDetails = this.state.supplierList.find(
      (supplier: any) => supplier.uuid === supplierId
    );
    return commonService.applyLocalization(
      "restaurant",
      "name",
      supplierDetails?.locales
    )["name"];
  };

  getSuppliers = () => {
    const recipeId: string = this.props.match.params.recipeuuid;
    const restaurantId: string = this.props.match.params.uuid;
    const payload = {
      credentials: {
        recipeuuid: recipeId,
        restaurantuuid: restaurantId,
      },
    };
    getRestaurantSuppliers(RESTAURANT_REQUEST, payload).then((data) => {
      const responseData: any = handleResponse(data);
      if (responseData?.ok) {
        this.setState({
          supplierList: responseData.data,
        });
      }
    });
  };

  public handleChange = (elem: any) => {
    this.setState({
      [elem.target.name]: elem.target.value,
    });
  };

  public handleItemRemove = (itemId: any) => {
    OpenDeleteModal().then(() => {
      const allRecipes = [...this.state.serviceRecipes];

      const updatedRecipeList = _.filter(allRecipes, (recipe: any) => {
        return recipe.uuid !== itemId;
      });

      this.props.cardItemSelectionData.deselectCardItem();

      this.setState({
        serviceRecipes: updatedRecipeList,
        serviceRecipesGrouped: _.groupBy(updatedRecipeList, "selectable"),
      });
    });
  };

  public handleItemSave = (itemData: any, itemIndex: any, area: string) => {
    const allRecipes: any = this.state.serviceRecipes;
    const currency: any = allRecipes.map((item: any) => item.currency)[0];
    itemData.currency = currency;
    if (itemIndex === "newCard") {
      itemData["cardType"] = "newCard";
      allRecipes.push(itemData);
    } else {
      const indx = _.findIndex(allRecipes, function (item: any) {
        if (itemData.uuid) {
          return item.uuid === itemData.uuid;
        } else {
          return item.recipe_uuid === itemData.recipe_uuid;
        }
      });
      allRecipes[indx] = itemData;
    }
    const serviceSetGrouped = _.groupBy(allRecipes, "selectable");
    this.setState({
      serviceRecipes: allRecipes,
      serviceRecipesGrouped: serviceSetGrouped,
    });
    if (area === "mainItem") {
      this.props.cardItemSelectionData.deselectCardItem();
    }
    if (itemIndex === "newCard" && itemData.selectable) {
      this.handleServiceSetLabels(itemData.recipe_uuid);
    }
  };

  public handlePageSave = () => {
    const data = JSON.parse(JSON.stringify(this.state.serviceRecipes));
    const recipes = data.map((recipe: any) => {
      recipe.price = Number(recipe.price);
      delete recipe.sort_order;
      delete recipe.currency;
      delete recipe.name;
      delete recipe.type;
      delete recipe.show;
      if (recipe.cardType) {
        delete recipe.cardType;
        delete recipe.uuid;
      }
      if (recipe?.recipe_output_unit) {
        delete recipe.recipe_output_unit;
      }
      return recipe;
    });

    this.props.updateServiceSetRecipes({
      restaurantUuid: this.props.match.params.uuid,
      itemUuid: this.props.match.params.itemUuid,
      data: {
        recipe: recipes,
      },
    });
    this.setState({ isUpdated: true });
  };

  handleServiceSetLabels = (recipe: string) => {
    const [currentRecipe] = this.state.allRecipes?.filter((item: any) => {
      return item.uuid === recipe;
    });
    if (!!currentRecipe.label_uuids?.length) {
      const recipeLabels = currentRecipe.label_uuids;
      let serviceSetLabels = this.state.serviceSetDetails.labels.map(
        (label: any) => label.label_uuid
      );
      if (this.newlySavedLabels.length > 0) {
        serviceSetLabels = [...serviceSetLabels, ...this.newlySavedLabels];
        serviceSetLabels = _.uniqBy(serviceSetLabels, (item) => item);
      }
      let unsavedLabels = recipeLabels.filter(
        (item: any) => !serviceSetLabels.includes(item)
      );
      if (unsavedLabels.length > 0) {
        OpenDeleteModal({
          title: this.props.t("serviceSet.labelsMissingServiceSet"),
          saveButton: this.props.t("serviceSet.addLabelToServiceSet"),
        }).then(() => {
          this.setState({
            isFetching: true,
          });
          this.handleLabelApiUpdate(
            [...serviceSetLabels, ...unsavedLabels],
            this.props.match.params.itemUuid
          );
        });
      }
    }
  };

  handleLabelApiUpdate = (labels: any, serviceSet: string) => {
    const payLoad = {
      credentials: {
        restaurantuuid: this.props.match.params.uuid,
        serviceSetUuid: serviceSet,
        data: { label: labels },
      },
    };

    updateServiceSetLabel(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        if (response.status === 200) {
          this.setState({
            isFetching: false,
          });
          this.newlySavedLabels.push(...labels);
          commonService.toastService(
            this.props.t("serviceSet.labelUpdatedSuccess"),
            "success"
          );
        }
      })
      .catch((error: any) => {
        this.setState({
          isFetching: false,
        });
        commonService.toastService("", "danger", JSON.stringify(error.error));
      });
  };

  public handleTimeUpdate = (isUpdated: boolean) => {
    this.setState({ isUpdated });
  };

  public reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  public onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    this.setState({ isSaveButtonDisabled: false });
    const itemsList = this.reorder(
      this.state.serviceRecipesGrouped.true,
      result.source.index,
      result.destination.index
    );

    var updatedItemList: any = [];
    if (this.state.serviceRecipesGrouped?.false)
      updatedItemList = [
        ...itemsList,
        ...this.state.serviceRecipesGrouped?.false,
      ];
    else updatedItemList = [...itemsList];

    this.setState({
      serviceRecipes: updatedItemList,
      serviceRecipesGrouped: _.groupBy(updatedItemList, "selectable"),
    });
  };

  public getChildrenProps = (card: any, index: any) => {
    return {
      indx: index,
      key: card.uuid,
      cardItemData: card,
      cardItemId: card.uuid,
    };
  };

  public renderFilterSection = () => {
    const labelOptions: Array<any> = this.getOptions(this.state.labels);
    return (
      <div className="mb-4 rounded card">
        <Row className="row p-2 mb-2">
          <Col xs={12} sm={6} md={5} lg={5} className="m-3">
            <Select
              value={this.state.selectedLabelOptions}
              onChange={this.handleSelectChange}
              options={labelOptions}
              isMulti
              maxMenuHeight={200}
              placeholder={this.props.t("label.searchByLabel")}
            />
          </Col>

          <Col xs={12} sm={6} md={5} lg={5} className="m-3">
            <Input
              type="search"
              name="searchByName"
              placeholder={this.props.t("label.searchByName")}
              onChange={this.handleChange.bind(this)}
              value={this.state.searchByName}
            />
          </Col>
        </Row>
      </div>
    );
  };

  public renderItemsBlock = () => {
    const restDtls: any =
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "";
    const restaurantDetail = JSON.parse(restDtls);

    const currencyCode = restaurantDetail?.currency_code;
    return (
      <div>
        {this.state.serviceRecipesGrouped.true &&
          this.state.serviceRecipesGrouped.true.length !== 0 && (
            <Card className="mb-4 rounded item-cards">
              <CardHeader
                className="b-cursor-p"
                onClick={() => this.toggleBlock("selectable")}
              >
                <FontAwesomeIcon
                  className="bf-accordion-arrow mr-2"
                  icon={
                    !this.state.selectableCollapse
                      ? faChevronDown
                      : faChevronRight
                  }
                />
                {this.props.t("serviceSet.selectableRecipes")} (
                {this.getFilteredItemsCount(
                  this.state.serviceRecipesGrouped.true
                )}
                )
              </CardHeader>
              <Collapse isOpen={!this.state.selectableCollapse}>
                <CardBody>
                  <DragAndDropWrapper
                    getChildrenProps={this.getChildrenProps}
                    onDragEnd={this.onDragEnd}
                    onDragStart={() => {
                      this.props.cardItemSelectionData.deselectCardItem();
                    }}
                    itemsList={this.getFilteredItems(
                      this.state.serviceRecipesGrouped.true
                    )}
                  >
                    <ServiceSetRecipeCard
                      allRecipes={this.state.allRecipes}
                      checkItemsInTheServiceSet={this.checkItemsInTheServiceSet}
                      currencyCode={currencyCode}
                      courseList={this.props?.courseList}
                      currentItemDetails={this.state.currentItemDetails}
                      getCurrentItemDetails={this.getCurrentItemDetails}
                      hasSorting
                      isNewCardItem={false}
                      onDeleteCard={this.handleItemRemove}
                      onSaveCard={this.handleItemSave}
                      parentCardItemSelectionData={
                        this.props.cardItemSelectionData
                      }
                      saveButton={false}
                      serviceRecipes={this.state.serviceRecipes}
                      vatList={this.props?.vatList}
                      units={this.props?.units}
                    />
                  </DragAndDropWrapper>
                </CardBody>
              </Collapse>
            </Card>
          )}
        {this.state.serviceRecipesGrouped.false &&
          this.state.serviceRecipesGrouped.false.length !== 0 && (
            <Card className="mb-4 rounded">
              <CardHeader
                className="b-cursor-p"
                onClick={() => this.toggleBlock("nonSelectable")}
              >
                <FontAwesomeIcon
                  className="bf-accordion-arrow mr-2"
                  icon={
                    !this.state.nonSelectableCollapse
                      ? faChevronDown
                      : faChevronRight
                  }
                />
                {this.props.t("serviceSet.nonSelectableRecipes")} (
                {this.getFilteredItemsCount(
                  this.state.serviceRecipesGrouped.false
                )}
                )
              </CardHeader>
              <Collapse isOpen={!this.state.nonSelectableCollapse}>
                <CardBody>
                  {this.getNonSelectableServiceItems(
                    this.getFilteredItems(
                      this.state.serviceRecipesGrouped.false
                    )
                  ).map((item: any, indx: number) => (
                    <ServiceSetRecipeCard
                      indx={indx}
                      key={item.uuid}
                      allRecipes={this.state.allRecipes}
                      checkItemsInTheServiceSet={this.checkItemsInTheServiceSet}
                      cardItemData={item}
                      cardItemId={item.uuid}
                      courseList={this.props?.courseList}
                      currencyCode={currencyCode}
                      currentItemDetails={this.state.currentItemDetails}
                      getCurrentItemDetails={this.getCurrentItemDetails}
                      isNewCardItem={false}
                      onDeleteCard={this.handleItemRemove}
                      onSaveCard={this.handleItemSave}
                      parentCardItemSelectionData={
                        this.props.cardItemSelectionData
                      }
                      saveButton={false}
                      serviceRecipes={this.state.serviceRecipes}
                      vatList={this.props?.vatList}
                      units={this.props?.units}
                    />
                  ))}
                </CardBody>
              </Collapse>
            </Card>
          )}
        <div className="">
          <ServiceSetRecipeCard
            key={"newCard"}
            allRecipes={this.state.allRecipes}
            cardItemData={{ type: "newCard" }}
            cardItemId={"newCard"}
            checkItemsInTheServiceSet={this.checkItemsInTheServiceSet}
            courseList={this.props?.courseList}
            currentItemDetails={this.state.currentItemDetails}
            getCurrentItemDetails={this.getCurrentItemDetails}
            isNewCardItem={true}
            onDeleteCard={this.handleItemRemove}
            onSaveCard={this.handleItemSave}
            parentCardItemSelectionData={this.props.cardItemSelectionData}
            saveButton={false}
            serviceRecipes={this.state.serviceRecipes}
            vatList={this.props?.vatList}
            units={this.props?.units}
          />
        </div>
      </div>
    );
  };

  setAllRecipesWithSupplier = () => {
    const recipes: any = this.props?.allRecipes?.map((recipe: any) => {
      if (recipe?.supplier_uuid) {
        const supplierName: string = this.getSupplierDetails(
          recipe.supplier_uuid
        );
        const recipeName: string = commonService.applyLocalization(
          "restaurant",
          "name",
          recipe?.locales
        )["name"];

        recipe["recipeName"] = recipeName;
        if (supplierName)
          recipe["recipeName"] = recipe["recipeName"] + ` (${supplierName})`;
      }
      return recipe;
    });
    this.allRecipesUpdated = true;
    this.setState({
      allRecipes: recipes,
    });
  };

  public toggleBlock = (area: string) => {
    if (area === "selectable") {
      this.setState({
        selectableCollapse: !this.state.selectableCollapse,
      });
    } else {
      this.setState({
        nonSelectableCollapse: !this.state.nonSelectableCollapse,
      });
    }
  };
}

const mapStateToProps: any = (state: any) => {
  let failure =
    state.allRecipeItems.failure ||
    state.courseList.failure ||
    state.recipedetails.failure ||
    state.serviceSetDetails.failure ||
    state.serviceSetRecipeUpdate.failure ||
    state.serviceSetTimeUpdate.failure ||
    state.settings.failure ||
    state.units.failure ||
    state.currency.failure ||
    state.vatClass.failure;
  let isFetching =
    state.allRecipeItems.isFetching ||
    state.courseList.isFetching ||
    state.recipedetails.isFetching ||
    state.serviceSetDetails.isFetching ||
    state.serviceSetRecipeUpdate.isFetching ||
    state.serviceSetTimeUpdate.isFetching ||
    state.settings.isFetching ||
    state.units.isFetching ||
    state.currency.isFetching ||
    state.vatClass.isFetching;

  return {
    isFetching: isFetching,
    failure: failure,
    allRecipes: state.allRecipeItems?.data || [],
    courseList: state.courseList.data,
    recipeDetails: state.recipedetails.data,
    serviceSetTimeUpdate: state.serviceSetTimeUpdate.data,
    serviceSetDetails: state.serviceSetDetails.data,
    serviceSetRecipeUpdate: state.serviceSetRecipeUpdate.data,
    vatList: state.vatClass.data,
    currencyList: state.currency.data,
    settings: state.settings.data,
    units: state.units.data,
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    clearServiceSetDetail: (credentials: any) => {
      dispatch(clearServiceSetDetailsRequest(credentials));
    },
    clearAllRecipes: (credentials: any) => {
      dispatch(clearRecipeItems(credentials));
    },
    getAllRecipes: (credentials: any) => {
      dispatch(recipeItemsRequest(credentials));
    },
    getCourseList: (credentials: any) => {
      dispatch(courseListRequest(credentials));
    },
    getItemDetail: (credentials: any) => {
      dispatch(recipeDetailsRequest(credentials));
    },
    getServiceSetDetail: (credentials: any) => {
      dispatch(serviceSetDetailsRequest(credentials));
    },
    getServiceSet: (credentials: any) => {
      dispatch(servicesetRequest(credentials));
    },
    getVatList: (credentials: any) => {
      dispatch(vatRequest(credentials));
    },
    updateServiceSetRecipes: (credentials: any) => {
      dispatch(serviceSetUpdateRecipes(credentials));
    },
    getSettings: (credentials: any) => {
      dispatch(settingsRequest(credentials));
    },
    getUnits: (credentials: any) => {
      dispatch(unitsRequest(credentials));
    },
  };
};
const enhance = compose(
  withCardItemSelectionStateManager("Task"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);

export default withTranslation()(enhance(ServiceSetDetailsPage));
