import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { InventoryInitState, InventoryState } from '../../models/inventory.model';
import { supportRequest } from '../../redux/actions/support.action';
import '../../styles/inventory.scss';
import LoaderComponent from '../loader/loader';
import SupporteHeaderComponent from '../navigation/navigation-header/support-header/support-header';
import OperateLeftComponent from '../navigation/navigation-left/support-navigation/support-navigation';
import NavigationRightComponent from '../navigation/navigation-right/navigation-right';
import { ISSUPERADMIN } from '../../constant/constant';



class SupportComponent extends Component<any> {
    state: InventoryState;
    private baseUrl: string = 'https://cobrowse.io/dashboard?token='
    constructor(props: any) {
        super(props);
        this.state = InventoryInitState;
        
    }

    render() {
        return (
            <React.Fragment>
                <LoaderComponent display={!!this.state.isFetching} />
                <div className="container-fluid">
                    <SupporteHeaderComponent support={'support'} />
                    <Row className="main light-theme">
                        <Col xl={2} lg={3} className="hide-left-max">
                            <OperateLeftComponent display={"support"} restaurantuuid={this.props.match.params.uuid} />
                        </Col>
                        <Col xl={8} lg={6}>
                            <div className="white-box mb-3">
                                {this.state.isSuperadmin ?
                                    <iframe style={{ width: '100%', minHeight: 500, }} src={this.state.baseUrl} title="description"></iframe>
                                :
                                    <p>You do not have permission to access this page</p>
                                }
                            </div>
                        </Col>
                        <Col xl={2} lg={3}>
                            <NavigationRightComponent />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        )
    }

    

    componentDidMount() {
        document.body.className = 'light-theme';
        let isSuperAdmin = localStorage.getItem(ISSUPERADMIN) === 'true' ? true : false;
        this.setState({isSuperadmin:isSuperAdmin});
        if (!isSuperAdmin) {
            return false;
        }
        
        let token = localStorage.getItem('SUPPORT_TOKEN')
        if (!!token && JSON.parse(token)) {
            let supportToken =JSON.parse(token);
            const timestamp = new Date().getTime();
            if(supportToken.expires_in<timestamp){
                let url=this.state.baseUrl;
                this.setState({
                    baseUrl:url+supportToken.access_token
                })
            }else{
                this.props.support({ restaurantuuid: this.props.match.params.uuid });
            }
        } else {
            this.props.support({ restaurantuuid: this.props.match.params.uuid });
        }

    }

    UNSAFE_componentWillReceiveProps(newProps: any) {
        
        if (!!newProps) {
            this.setState({
                isFetching: newProps.isFetching
            });
            if (!!newProps && !newProps.isFetching && !newProps.failure) {
                let url=this.state.baseUrl;
                this.setState({
                    support: newProps.supports,
                    baseUrl:url+newProps.supports.access_token
                });
                
                localStorage.setItem('SUPPORT_TOKEN', JSON.stringify(newProps.supports));
            } 
        }
    }
}

const mapStateToProps: any = (state: any) => {
    let isFetching = state.support.isFetching;
    let failure = state.support.failure;
    return {
        supports: state.support.data,
        isFetching: isFetching,
        failure: failure
    };
};

const mapDispatchToProps: object = (dispatch: any) => {
    return {
        support: (credentials: any) => {
            dispatch(supportRequest(credentials));
        },

    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps)(withRouter(SupportComponent));
