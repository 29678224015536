import { call, put, takeLatest } from "redux-saga/effects";

import { RESTAURANT_REQUEST } from "../config/api.config";
import {
  roomAddFailure,
  roomAddSuccess,
  roomFailure,
  roomRemoveFailure,
  roomRemoveSuccess,
  roomSuccess,
  roomUpdateFailure,
  roomUpdateSuccess,
} from "../redux/actions/room.action";
import * as types from "../redux/actions/types";
import handleResponse from "../services/response.service";
import rooms from "../services/room.service";

const roomCall = (params: any) => rooms.room(RESTAURANT_REQUEST, params);
const updateRoomCall = (params: any) =>
  rooms.updateRoom(RESTAURANT_REQUEST, params);
const addRoomCall = (params: any) => rooms.addRoom(RESTAURANT_REQUEST, params);
const removeRoomCall = (params: any) =>
  rooms.removeRoom(RESTAURANT_REQUEST, params);

function* handleRoomRequest(params: object) {
  try {
    let result;
    result = yield call(roomCall, params);
    yield handleRoom200(result);
  } catch (error) {
    throw error;
  }
}

const handleRoom200 = function* handleRoom200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(roomSuccess(result.data));
  } else {
    yield put(roomFailure(result.data));
    handleResponse(result);
  }
};

function* handleRoomUpdate(params: object) {
  try {
    let result;
    result = yield call(updateRoomCall, params);
    yield handleRoomUpdate200(result);
  } catch (error) {
    throw error;
  }
}

const handleRoomUpdate200 = function* handleRoomUpdate200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(roomUpdateSuccess(result.data));
  } else {
    yield put(roomUpdateFailure(result.data));
  }
  handleResponse(result);
};

function* handleRoomAdd(params: object) {
  try {
    let result;
    result = yield call(addRoomCall, params);
    yield handleRoomAdd200(result);
  } catch (error) {
    throw error;
  }
}

const handleRoomAdd200 = function* handleRoomAdd200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(roomAddSuccess(result.data));
  } else {
    yield put(roomAddFailure(result.data));
  }
  handleResponse(result);
};

function* handleRoomRemove(params: object) {
  try {
    let result;
    result = yield call(removeRoomCall, params);
    yield handleRoomRemove200(result);
  } catch (error) {
    throw error;
  }
}

const handleRoomRemove200 = function* handleRoomRemove200(result: any) {
  if (!result) {
    return;
  }
  if (result.status === 200) {
    yield put(roomRemoveSuccess(result.data));
  } else {
    yield put(roomRemoveFailure(result.data));
  }
  handleResponse(result);
};
export default function* roomSaga() {
  yield takeLatest(types.ROOM.REQUEST, handleRoomRequest);
  yield takeLatest(types.ROOM_UPDATE.REQUEST, handleRoomUpdate);
  yield takeLatest(types.ROOM_ADD.REQUEST, handleRoomAdd);
  yield takeLatest(types.ROOM_REMOVE.REQUEST, handleRoomRemove);
}
