import "../../../styles/room.scss";

import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Select from "react-select";

export default function RoomAddCard(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader toggle={props.addToggle} className="modal-header">
        {t("room.addRoom")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstname">{t("room.roomName")}</Label>
            <Label className="required-star-style">*</Label>
            <Input
              type="text"
              required
              name="name"
              autoComplete="name"
              placeholder={t("room.enterRoomName")}
              value={props.name}
              onChange={props.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="pickingRoom">{t("room.pickingRoom")}</Label>
            <Select
              options={props.otherRoomList}
              autosize={true}
              menuPlacement="auto"
              menuPosition="fixed"
              className="w-100"
              onChange={(data) =>
                props.handleChange({
                  target: { name: "pickingLocation", value: data },
                })
              }
              value={props?.pickingLocation}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.addToggle}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={props.saveAdd}
          disabled={!props.name || !!props.isFetching}
        >
          {t("common.save")}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
