import apiCall from './api.call.config';

async function servers(url: string, params: any) {    
    try {
        return await apiCall.get(url + '/' + params.credentials.restaurantuuid + '/server?sort=name:asc');
    } catch (e) {
        throw e;
    }
}

async function updateServers(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.put(url + '/' + params.credentials.restaurantuuid + '/server/'
            + params.credentials.uuid+'/stop');
    } catch (e) {
        throw e;
    }
}



export default {
    servers,
    updateServers,
  
}
