import apiCall from './api.call.config';

async function roleRightList(url: string, params: any) {
    try {
        return await apiCall.get(url + '/' + params.credentials.restaurantuuid + '/role');
    } catch (e) {
        throw e;
    }
}

async function updateRoleRight(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.put(url + '/' + params.credentials.restaurantuuid + '/role/'
            + params.credentials.uuid, params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function addRoleRight(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.post(url + '/' + params.credentials.restaurantuuid + '/role/' + params.credentials.uuid
            + '/right', params.credentials.data);
    } catch (e) {
        throw e;
    }
}

async function removeRoleRight(url: string, params: any) {
    try {
        // eslint-disable-next-line
        return await apiCall.remove(url + '/' + params.credentials.restaurantuuid + '/role/' + params.credentials.uuid);
    } catch (e) {
        throw e;
    }
}

export default {
    roleRightList,
    updateRoleRight,
    addRoleRight,
    removeRoleRight
}