import { call, put, takeLatest } from 'redux-saga/effects';

import { RESTAURANT_REQUEST } from '../config/api.config';
import * as types from '../redux/actions/types';
import {
    userRoleAddFailure,
    userRoleAddSuccess,
    usersFailure,
    usersRemoveFailure,
    usersRemoveSuccess,
    usersSuccess,
} from '../redux/actions/users.action';
import handleResponse from '../services/response.service';
import users from '../services/users.service';

const usersCall = (params: any) => users.users(RESTAURANT_REQUEST, params);
const removeUsersCall = (params: any) => users.removeUsers(RESTAURANT_REQUEST, params);
const addTaskCall = (params: any) => users.addUserRole(RESTAURANT_REQUEST, params);

function* handleUsersRequest(params: object) {
    try {
        let result;
        result = yield call(usersCall, params);
        yield handleUsers200(result);
    } catch (error) {
        throw error;
    }
}

const handleUsers200 = function* handleUsers200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(usersSuccess(result.data));
    } else {
        yield put(usersFailure(result.data));
        handleResponse(result);
    }
}

function* handleUsersRemove(params: object) {
    try {
        let result;
        result = yield call(removeUsersCall, params);
        yield handleUsersRemove200(result);
    } catch (error) {
        throw error;
    }
}

const handleUsersRemove200 = function* handleUsersRemove200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(usersRemoveSuccess(result.data));
    } else {
        yield put(usersRemoveFailure(result.data));
    }
    handleResponse(result);
}

function* handleUserRoleAdd(params: object) {
    try {
        let result;
        result = yield call(addTaskCall, params);
        yield handleUserRoleAdd200(result);
    } catch (error) {
        throw error;
    }
}

const handleUserRoleAdd200 = function* handleUserRoleAdd200(result: any) {
    if (!result) {
        return;
    }
    if (result.status === 200) {
        yield put(userRoleAddSuccess(result.data));
    } else {
        yield put(userRoleAddFailure(result.data));
    }
    handleResponse(result);
}

export default function* usersSaga() {
    yield takeLatest(types.USERS.REQUEST, handleUsersRequest);
    yield takeLatest(types.USERS_REMOVE.REQUEST, handleUsersRemove);
    yield takeLatest(types.USER_ROLE_ADD.REQUEST, handleUserRoleAdd);
}
