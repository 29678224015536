import "../../../../styles/navigation-left.scss";

import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Collapse } from "reactstrap";
import i18next from "i18next";
import { withTranslation } from "react-i18next";

interface state {
  collapse: boolean;
}
class PrinterInterfaceNavigationComponent extends Component<any, state> {
  isOpen: any = false;
  isValid: any = false;
  constructor(props: any) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <div onClick={this.toggle.bind(this)} className="pointer-cursor">
          {t("printer.printerInterface")}
          <FontAwesomeIcon
            style={{
              display: !this.state.collapse && !this.isValid ? "block" : "none",
            }}
            className="nav-icon-style"
            pull="right"
            icon={faChevronDown}
          />
          <FontAwesomeIcon
            style={{
              display: this.state.collapse && !this.isValid ? "block" : "none",
            }}
            className="nav-icon-style"
            pull="right"
            icon={faChevronUp}
          />
        </div>
        <Collapse
          isOpen={this.state.collapse || this.checkSubMenu(this.props.display)}
        >
          <Link
            to={`/restaurant/${this.props.restaurantuuid}/printer-interfaces`}
          >
            <Button
              style={{ marginTop: 5 }}
              className={
                this.props.display === "printer"
                  ? "btn-dark child mb-2"
                  : "btn-light child mb-2"
              }
            >
              {t("printer.printer")}
            </Button>
          </Link>
          <Link to={`/restaurant/${this.props.restaurantuuid}/printer/message`}>
            <Button
              className={
                this.props.display === "message"
                  ? "btn-dark child mb-2"
                  : "btn-light child mb-2"
              }
            >
              {t("printer.printerMessage")}
            </Button>
          </Link>
          <Link
            to={`/restaurant/${this.props.restaurantuuid}/printer/protocol`}
          >
            <Button
              className={
                this.props.display === "protocol"
                  ? "btn-dark child mb-2"
                  : "btn-light child mb-2"
              }
            >
              {t("printer.printerProtocol")}
            </Button>
          </Link>
        </Collapse>
      </React.Fragment>
    );
  }

  public toggle() {
    this.isOpen = true;
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  public checkSubMenu(value: any) {
    switch (value) {
      case "printer":
        this.isValid = true;
        break;
      case "message":
        this.isValid = true;
        break;
      case "protocol":
        this.isValid = true;
        break;
      default:
        this.isValid = false;
        break;
    }
    return this.isValid;
  }

  componentWillUnmount() {
    this.isOpen = false;
  }
}

export default withTranslation()(
  withRouter(PrinterInterfaceNavigationComponent)
);
