import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Alert, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { RECIPE_STATUS } from "../../constant/constant";
import {
  ServiceSetOverviewItemListWrapperProps,
  serviceSetOverviewMode,
} from "../../models/serviceset.model";
import commonService from "../../services/common.service";
import ServiceSetItemCard from "./ServiceSetItemCard";

function ServiceSetOverviewItemListWrapper(
  props: ServiceSetOverviewItemListWrapperProps
) {
  const { t, i18n } = useTranslation();
  const { actions, allRecipes, metaData, labels, serviceSetRecipes, units } =
    props;
  const [filteredRecipes, setFilteredRecipes] = useState<Array<any>>([]);
  const [recipeName, setRecipeName] = useState<string>("");
  const [selectedLabels, setSelectedLabels] = useState<Array<any>>([]);

  useEffect(() => {
    applyFilter();
  }, [
    allRecipes,
    recipeName,
    selectedLabels,
    metaData.activeServiceSet,
    metaData?.serviceSetRecipesData,
    metaData.pageMode,
  ]);

  const applyFilter = () => {
    const labels: Array<string> =
      selectedLabels?.map((label: any) => label.value) || [];

    const items: Array<any> = _.cloneDeep(allRecipes).filter((recipe: any) => {
      if (recipe.status !== RECIPE_STATUS.PUBLISHED) {
        return false;
      }
      let nameIncludes: boolean = true;
      const itemLocale = commonService.applyLocalization(
        "restaurant",
        "name",
        recipe.locales
      );
      recipe.name = itemLocale.name;

      if (recipe?.supplier_uuid) {
        const supplierName: string = props.actions.getSupplierDetails(
          recipe.supplier_uuid
        );
        if (supplierName) recipe.name = recipe.name + ` (${supplierName})`;
      }
      if (recipeName.trim() !== "") {
        nameIncludes = recipe.name
          .toLowerCase()
          .includes(recipeName.toLowerCase());
      }
      if (nameIncludes && labels.length > 0) {
        nameIncludes = labels.every((label: string) => {
          return recipe.label_uuids.includes(label);
        });
      }

      if (metaData.activeServiceSet !== "") {
        const serviceSetRecipes =
          metaData?.serviceSetRecipesData?.[metaData?.activeServiceSet] || [];

        if (serviceSetRecipes.includes(recipe.uuid)) {
          recipe.isAssigned = true;
        } else {
          if (
            metaData.pageMode === serviceSetOverviewMode.SERVICE_SET_SELECTION
          )
            return false;
          recipe.isAssigned = false;
        }
      }
      return nameIncludes;
    });

    setFilteredRecipes(
      _.sortBy(items, [(item: any) => item?.name?.toLowerCase()])
    );
  };

  const handleLabelsFilterSelect = (options: any) => {
    setSelectedLabels(options);
  };

  const getOptions = (optionArray: Array<any>) => {
    const options: Array<any> = [];
    optionArray.forEach((item: any) => {
      const option: any = {
        value: item.uuid,
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          item.locales
        )["name"],
      };

      options.push(option);
    });

    return options;
  };

  const handleChange = (elem: any) => {
    setRecipeName(elem.target.value);
  };

  const handleRecipeEdit = (recipeId: string) => {
    actions.handleRecipeEdit(recipeId);
  };

  const handleRecipeSelection = (recipeId: string) => {
    actions.handleRecipeSelection(recipeId);
  };

  const labelOptions: Array<any> = useMemo(() => getOptions(labels), [labels]);
  const isServiceSetSelected: boolean = metaData.activeServiceSet !== "";
  return (
    <div className="m-2 border h-100 p-3">
      <div className="b-sticky-below-header bg-white border-bottom mx-n3">
        <Form className="px-3">
          <FormGroup row className="mb-2 py-1">
            <Label for="serviceSets" md={4} className="text-right-md">
              {t("label.labels")}
            </Label>
            <Col md={8}>
              <Select
                value={selectedLabels}
                onChange={handleLabelsFilterSelect}
                options={labelOptions}
                maxMenuHeight={200}
                isMulti
                placeholder={t("common.select")}
              />
            </Col>
          </FormGroup>
          <FormGroup row className="mb-2 py-1">
            <Label for="serviceSets" md={4} className="text-right-md">
              {t("common.name")}
            </Label>
            <Col md={8}>
              <Input
                type="text"
                required
                name="recipeName"
                autoComplete="recipeName"
                placeholder={t("recipe.enterRecipeName")}
                value={recipeName}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          {/* <div className="text-right mb-2">
            <Button color={"info"} size={"sm"} onClick={() => applyFilter()}>
              {t("common.filter")}
            </Button>
          </div> */}
        </Form>
      </div>
      <div className="mt-2">
        {filteredRecipes?.length === 0 && (
          <Alert color="primary">{t("common.noItemsToDisplay")}</Alert>
        )}
        {filteredRecipes.map((item: any, index: any) => {
          const actionsData: any = {
            handleCancel: actions.handleRecipeCancel,
            handleEdit: handleRecipeEdit,
            handleSave: actions.handleRecipeSave,
            handleSelection: handleRecipeSelection,
            setServiceSetRecipes: actions.setServiceSetRecipes,
            storageActions: {
              addNewStorage: actions.storageActions.addNewStorage,
              editStorage: actions.storageActions.editStorage,
              removeStorage: actions.storageActions.removeStorage,
              getBestMatchUnits: actions.storageActions.getBestMatchUnits,
            },
          };
          const selections: any = {
            isAssignee:
              metaData.pageMode === serviceSetOverviewMode.SERVICE_SET_EDIT,
            isEdit: metaData.pageMode === serviceSetOverviewMode.RECIPE_EDIT,
            isRecipeSelected: item.uuid === metaData.activeRecipe,
            isServiceSetSelected: isServiceSetSelected,
          };

          const meta: any = {
            allLocations: metaData.allLocations,
            recipeLocations: metaData.recipesLocationsData[item.uuid],
            serviceSetRecipes: metaData.serviceSetRecipes,
          };

          return (
            <React.Fragment key={index}>
              <ServiceSetItemCard
                key={index}
                actions={actionsData}
                metaData={meta}
                selections={selections}
                recipeData={item}
                units={units}
                serviceSetRecipes={serviceSetRecipes}
                settings={props.settings}
                fetchAllUnits={props.fetchAllUnits}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
export default ServiceSetOverviewItemListWrapper;
