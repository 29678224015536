import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { GRAY } from "../../../constant/constant";

export default function GuestGroupListCard(props: any) {
  const { t } = useTranslation();
  const { guestGroup, setDelete, performShopOrder } = props;

  return (
    <>
      <div className="child-box mb-3">
        <div className="d-flex justify-content-between">
          <div>
            <h6 className="m-0">
              {guestGroup.table.name + " - " + guestGroup.name}
            </h6>
            <p className="text-center mb-0">
              <small>{guestGroup.expectedDeliveryTime}</small>
            </p>
          </div>
          <div className="mr-2">
            <span
              onClick={performShopOrder}
              className="pointer-cursor"
              data-toggle="tooltip"
              data-placement="left"
              title={t("common.performShopOrder")}
            >
              <FontAwesomeIcon
                style={{ color: GRAY, fontSize: 20, margin: 5 }}
                icon={faShoppingCart}
              />
            </span>
            <span
              onClick={setDelete}
              className="pointer-cursor"
              data-toggle="tooltip"
              data-placement="left"
              title={t("guestGroup.closeGuestGroup")}
            >
              <FontAwesomeIcon
                style={{ color: GRAY, fontSize: 20, margin: 5 }}
                icon={faTrashAlt}
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
