import { ActionModel } from '../../models/action.model';
import * as types from './types';

export function transportRequest(credentials: object): ActionModel {
  return {
    type: types.TRANSPORT.REQUEST,
    credentials
  };
}

export function transportSuccess(data: object): ActionModel {
  return {
    type: types.TRANSPORT.SUCCESS,
    data
  };
}

export function transportFailure(err: object): ActionModel {
  return {
    type: types.TRANSPORT.FAILURE,
    err
  };
}

export function transportUpdate(credentials: object): ActionModel {
  return {
    type: types.TRANSPORT_UPDATE.REQUEST,
    credentials
  };
}

export function transportUpdateSuccess(data?: object): ActionModel {
  return {
    type: types.TRANSPORT_UPDATE.SUCCESS,
    data
  };
}

export function transportUpdateFailure(err: object): ActionModel {
  return {
    type: types.TRANSPORT_UPDATE.FAILURE,
    err
  };
}

export function transportAdd(credentials: object): ActionModel {
  return {
    type: types.TRANSPORT_ADD.REQUEST,
    credentials
  };
}

export function transportAddSuccess(data?: object): ActionModel {
  return {
    type: types.TRANSPORT_ADD.SUCCESS,
    data
  };
}

export function transportAddFailure(err: object): ActionModel {
  return {
    type: types.TRANSPORT_ADD.FAILURE,
    err
  };
}

export function transportRemove(credentials: object): ActionModel {
  return {
    type: types.TRANSPORT_REMOVE.REQUEST,
    credentials
  };
}

export function transportRemoveSuccess(data?: object): ActionModel {
  return {
    type: types.TRANSPORT_REMOVE.SUCCESS,
    data
  };
}

export function transportRemoveFailure(err: object): ActionModel {
  return {
    type: types.TRANSPORT_REMOVE.FAILURE,
    err
  };
}
