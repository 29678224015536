export interface MachineModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export type formData = {
  keyValuePairs: any;
  machineCapacity: string;
  machineName: string;
  machineType: Array<any>;
  password: string;
  room: string;
  username: string;
  uuid: string;
};
export interface MachineState {
  activeTab: string;
  capacity: any;
  displayColorPicker: boolean;
  formData: formData;
  isFetching: boolean;
  isUpdated: boolean;
  isModalOpen: boolean;
  keyValuePairs: any;
  machine: any;
  machineTypes: Array<any>;
  newKey: any;
  newKeyError: any;
  newValue: any;
  open: boolean;
  restaurantuuid: any;
  roomList: any;
  uuid: any;
}

export const MachineInitState: MachineState = {
  activeTab: "machines",
  capacity: "",
  displayColorPicker: false,
  formData: {
    keyValuePairs: {},
    machineCapacity: "",
    machineName: "",
    machineType: [],
    password: "",
    room: "",
    username: "",
    uuid: "",
  },
  isFetching: false,
  isModalOpen: false,
  isUpdated: false,
  keyValuePairs: {},
  machine: [],
  machineTypes: [],
  newKey: {},
  newKeyError: {},
  newValue: {},
  open: false,
  restaurantuuid: "",
  roomList: [],
  uuid: "",
};

export interface MachineTypeCardProps {
  cardItemData: any;
  cardItemId: string;
  cardItemSelectionData: any;
  isNewCardItem?: boolean;
  onDeleteCard: Function;
  onSaveCard: Function;
  parentCardItemSelectionData: any;
}
