import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Country } from "country-state-city";
import commonService from "../../../services/common.service";
import CurrencyInput from "react-currency-input-field";
import Select from "react-select";

export default function SettingsCard(props: any) {
  const [QrCodeUrl, setQrCodeUrl] = useState("");
  const [isValidUrl, setIsValidUrl] = useState(true);

  const [reservationUrl, setReservationUrl] = useState("");
  const [isValidReservationUrl, setIsValidReservationUrl] = useState(true);
  const [countryList, setCountries] = useState<any>([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getCountryOptions();
    setQrCodeUrl(props.url);
    setReservationUrl(props.reservationUrl);
  }, []);

  const getCountryOptions = () => {
    let countries = Country.getAllCountries().map((country: any) => {
      return {
        value: country.name,
        label: country.name,
      };
    });
    setCountries(countries);
  };

  const getStationList = () => {
    const options: Array<any> =
      props.stationList?.map((item: any) => ({
        label: commonService.applyLocalization(
          "restaurant",
          "name",
          item.locales
        )?.name,
        value: item.uuid,
      })) || [];

    options.unshift({
      value: "",
      label: t("common.select"),
    });

    return options;
  };
  const handleUrlChange = (element: any) => {
    const currentVal = element.target.value;
    let isValid = true;
    if (currentVal.trim() !== "") {
      isValid = isUrlValid(currentVal);
    }
    setQrCodeUrl(currentVal);
    setIsValidUrl(isValid);
    if (isValid) {
      props.handleChangeSetting(element);
    }
  };

  const handleReservationUrlChange = (element: any) => {
    const currentVal = element.target.value;
    let isValid = true;
    if (currentVal.trim() !== "") {
      isValid = isUrlValid(currentVal);
    }
    setReservationUrl(currentVal);
    setIsValidReservationUrl(isValid);
    if (isValid) {
      props.handleChangeSetting(element);
    }
  };

  const isUrlValid = (userInput: string) => {
    var res = userInput.match(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g
    );
    if (res == null) return false;
    else return true;
  };

  return (
    <>
      <div className="child-box mb-3">
        <Form>
          <FormGroup className="row" controlId="restaurantCode">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {t("common.restaurantCode")}
              </Label>
            </Col>
            <Col sm="6" lg="6">
              <Input
                name="restaurantCode"
                type="text"
                disabled
                value={props.restaurantCode}
              />
            </Col>
          </FormGroup>

          <FormGroup check>
            <CustomInput
              id={"recipe_edit_mode"}
              type="checkbox"
              name={"recipe_edit_mode"}
              value={props.recipe_edit_mode}
              defaultChecked={
                parseInt(props.recipe_edit_mode) === 1 ? true : false
              }
              onChange={props.handleChangeSetting}
              label={t("common.recipeEdit")}
            />
          </FormGroup>
          <FormGroup check>
            <CustomInput
              id={"meals_to_same_chef"}
              type="checkbox"
              name={"meals_to_same_chef"}
              value={props.meals_to_same_chef}
              defaultChecked={
                parseInt(props.meals_to_same_chef) === 1 ? true : false
              }
              onChange={props.handleChangeSetting}
              label={t("common.assignChefToMeal")}
            />
          </FormGroup>
        </Form>
      </div>

      <div className="child-box mb-3">
        <Form>
          <FormGroup className="row" controlId="QRCodeUrl">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {t("qrCode.qrCodeBaseUrl")}
              </Label>
            </Col>
            <Col sm="6" lg="6">
              <Input
                name="baseUrl"
                type="text"
                invalid={!isValidUrl}
                placeholder="https://www.smarttoni.com/qrcode?key="
                value={QrCodeUrl}
                onChange={handleUrlChange}
              />
              {/* <Form.Control
                name="baseUrl"
                type="text"
                isInvalid={!isValidUrl}
                placeholder="https://www.smarttoni.com/qrcode?key="
                value={QrCodeUrl}
                onChange={handleUrlChange}
              /> */}
              <FormFeedback type="invalid">
                {t("validation.url")}
                <br />
                <small>https://www.smarttoni.com/qrcode?key=</small>
              </FormFeedback>
            </Col>
          </FormGroup>
        </Form>
      </div>

      <div className="child-box mb-3">
        <Form>
          <FormGroup className="p-0 row">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {t("common.country")}
              </Label>
            </Col>
            <Col sm="6" lg="6">
              <Select
                name="country"
                value={props.country}
                onChange={props.handleCountryChange}
                maxMenuHeight={200}
                options={countryList}
                placeholder={t("restaurant.selectCountry")}
              />
            </Col>
          </FormGroup>
          <FormGroup className="p-0 row">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {t("common.addCurrency")}
              </Label>
            </Col>
            <Col sm="6" lg="6">
              <Select
                id="currency"
                name="currency"
                onChange={props.handleChangeCurrency}
                maxMenuHeight={200}
                options={props.currencyList.map((item: any) => ({
                  label: commonService.applyLocalization(
                    "restaurant",
                    "name",
                    item.locales
                  )?.name,
                  value: item.uuid,
                }))}
                placeholder={t("common.selectCurrency")}
                value={props.currency}
              />
            </Col>
          </FormGroup>
          <FormGroup className="p-0 row">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {t("common.currencyCode")}
              </Label>
            </Col>
            <Col sm="2" lg="2">
              {props.currencyCode}
            </Col>
          </FormGroup>
          <FormGroup className="p-0 row">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {t("common.minorUnit")}
              </Label>
            </Col>
            <Col sm="2" lg="2">
              {props.minorUnit}
            </Col>
          </FormGroup>
          <FormGroup className="p-0 row">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {t("common.minimalDigitalDenomination")}
              </Label>
            </Col>
            <Col sm="2" lg="2">
              {props.minimalDigitalDenomination}
            </Col>
          </FormGroup>
          <FormGroup className="p-0 row">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {t("common.minimalCashDenomination")}
              </Label>
            </Col>
            <Col sm="6" lg="6">
              <Input
                invalid={props.hasError ? true : false}
                type="text"
                name="minimalCashDenomination"
                onChange={props.handleMinimalCashDenomination}
                value={props.minimalCashDenomination}
              />
              <span className="bf-font-size-12">
                {t("common.minimalCashText")}
              </span>
            </Col>
          </FormGroup>
        </Form>
      </div>

      <div className="child-box mb-3">
        <Form>
          <FormGroup className="row" controlId="reservation">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {t("station.deliveryStation")}
              </Label>
            </Col>
            <Col sm="6" lg="6">
              <Select
                id="deliveryStation"
                name="deliveryStation"
                onChange={(data: any) =>
                  props.handleChangeSetting({
                    target: {
                      name: "deliveryStation",
                      value: data,
                    },
                  })
                }
                maxMenuHeight={200}
                options={getStationList()}
                placeholder={t("station.selectStation")}
                value={props.deliveryStation}
              />
            </Col>
          </FormGroup>
        </Form>
      </div>

      <div className="child-box mb-3">
        <Form>
          <FormGroup className="row" controlId="reservation">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {t("common.reservationUrl")}
              </Label>
            </Col>
            <Col sm="6" lg="6">
              <Input
                name="reservation"
                type="text"
                invalid={!isValidReservationUrl}
                placeholder="https://www.smarttoni.com/qrcode?key="
                value={reservationUrl}
                onChange={handleReservationUrlChange}
              />
              {/* <Form.Control
                name="reservation"
                type="text"
                isInvalid={!isValidReservationUrl}
                placeholder=""
                value={reservationUrl}
                onChange={handleReservationUrlChange}
              /> */}
            </Col>
          </FormGroup>
        </Form>
      </div>

      <div className="child-box mb-3">
        <Form>
          <FormGroup className="row" controlId="reservation">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {t("common.assembleTaskTitle(en-gb)")}
              </Label>
            </Col>
            <Col sm="6" lg="6">
              <Input
                name="assembleTaskTitleEn"
                type="text"
                value={props.assembleTaskTitleEn}
                onChange={props.handleChangeSetting}
              />
            </Col>
          </FormGroup>
          <FormGroup className="row" controlId="reservation">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {t("common.assembleTaskDescription(en-gb)")}
              </Label>
            </Col>
            <Col sm="6" lg="6">
              <Input
                name="assembleTaskDescriptionEn"
                type="text"
                value={props.assembleTaskDescriptionEn}
                onChange={props.handleChangeSetting}
              />
            </Col>
          </FormGroup>
          <FormGroup className="row" controlId="reservation">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {t("common.assembleTaskTitle(de-ch)")}
              </Label>
            </Col>
            <Col sm="6" lg="6">
              <Input
                name="assembleTaskTitleDe"
                type="text"
                value={props.assembleTaskTitleDe}
                onChange={props.handleChangeSetting}
              />
            </Col>
          </FormGroup>
          <FormGroup className="row" controlId="reservation">
            <Col sm="4" lg="4">
              <Label className="ml-4 mr-1 mt-2 pl-2">
                {t("common.assembleTaskDescription(de-ch)")}
              </Label>
            </Col>
            <Col sm="6" lg="6">
              <Input
                name="assembleTaskDescriptionDe"
                type="text"
                value={props.assembleTaskDescriptionDe}
                onChange={props.handleChangeSetting}
              />
            </Col>
          </FormGroup>
        </Form>
      </div>

      <div className="d-flex justify-content-end mt-3">
        <Button
          className="btn btn-info"
          type="button"
          onClick={props.handleSubmit}
          disabled={!(isValidUrl && isValidReservationUrl) || props.hasError}
        >
          {t("common.save")}
        </Button>
      </div>
    </>
  );
}
