export interface LabelsModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}
export interface LabelsState {
  labels: any;
  isFetching: boolean;
  editModal:boolean
  name: any;
  addmodal: boolean,
  open: boolean;
  uuid: string;
  isUpdated: boolean,
  restaurantuuid: string,
  labeldescription:string,
  color:string,
  selectedOption: any,
  displayColorPicker: boolean,
  printerList: any,
  printerUuid: any,
}

export const LabelsInitState = {
  name: '',
  color: '#CCCCCC',
  isFetching: false,
  labels: [],
  addmodal: false,
  editModal: false,
  open: false,
  uuid: '',
  isUpdated: false,
  restaurantuuid: '',
  labeldescription: '',
  selectedOption: [],
  displayColorPicker: false,
  printerList: [],
  printerUuid: ''
};