import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Col, CustomInput, Row } from "reactstrap";
import { RoleInitState, RoleState } from "../../models/roles.model";
import { componentRequest } from "../../redux/actions/component.action";
import { roleRightAdd } from "../../redux/actions/roleright.action";
import {
  rolesAdd,
  rolesRemove,
  rolesRequest,
  rolesUpdate,
} from "../../redux/actions/roles.action";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import RightsAddCard from "../card-components/manage-role-right-card/manage-role-right-card";
import RoleAddCard from "../card-components/role-add-card/role-add-card";
import RoleCard from "../card-components/role-card/role-card";
import RoleEditCard from "../card-components/role-edit-card/role-edit-card";
import LoaderComponent from "../loader/loader";
import StaffHeaderComponent from "../navigation/navigation-header/staff-header/staff-header";
import UsersLeftComponent from "../navigation/navigation-left/users-navigation/users-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import { withTranslation } from "react-i18next";
import commonService from "../../services/common.service";
import _ from "lodash";

class RolesAndRightsComponent extends Component<any> {
  state: RoleState;
  manageRightList: any = [];
  constructor(props: any) {
    super(props);
    this._setFunctionBindings();
    this.state = RoleInitState;
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <StaffHeaderComponent staff={"staff"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <UsersLeftComponent
                display={"roles"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={8} lg={6}>
              <Link
                to="#"
                color="info"
                className="unit-add"
                onClick={this.AddToggle}
              >
                {t("role.addRole")}
              </Link>
              <div className="white-box mb-3">
                <h4>{t("role.roles")}</h4>
                {this.rolesList(this.state.rolelist)}
                {!this.state.isFetching && this.state.rolelist.length === 0
                  ? t("common.noRecords")
                  : ""}
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent></NavigationRightComponent>
            </Col>
          </Row>
        </div>

        {/*  Edit Role  */}
        <RoleEditCard
          state={this.state}
          toggle={this.toggle}
          handleChange={this.handleChange}
          saveEdit={this.saveEdit}
        />

        {/*  Add Role  */}
        <RoleAddCard
          state={this.state}
          AddToggle={this.AddToggle}
          handleChange={this.handleChange}
          saveRoleAdd={this.saveRoleAdd}
        />

        {/* Delete Role */}
        <DeleteModalCard
          isOpen={this.state.open}
          isFetching={!!this.state.isFetching}
          okDelete={this.deleteConfirmToggle}
          cancelDelete={this.deleteToggle}
        />

        {/* Manage Rights Role */}
        <RightsAddCard
          isOpen={this.state.managemodal}
          toggle={this.cancelManagetoggle}
          cancelManagetoggle={this.cancelManagetoggle}
          saveManageRight={this.saveManageRight}
          componentList={this.componentList(this.state.componentlist)}
        />
      </div>
    );
  }

  public getChecked(val: any): any {
    if (
      !!this.state.manageright &&
      this.state.manageright.some((item: any) => item === val)
    ) {
      return true;
    } else return false;
  }
  public componentList(data: any): any {
    if (!!data && data.length > 0) {
      return data.map((component: any, i: number) => {
        return (
          <div className="child-box mb-3" key={i}>
            <Row className=" app-row-padding">
              <Col>
                <h6 className="text-muted">
                  {
                    commonService.applyLocalization(
                      "restaurant",
                      "name",
                      component.locales
                    )["name"]
                  }
                </h6>
              </Col>
            </Row>
            {component.rights.map((item: any, index: number) => {
              return (
                <Row key={index} className=" app-row-padding">
                  <Col className="ml-4 form-check">
                    <CustomInput
                      type="checkbox"
                      className="form-check-input"
                      id={item.uuid}
                      defaultChecked={this.getChecked(item.uuid)}
                      value={item.uuid}
                      onChange={(e) => this.handleRoleRightChange(e)}
                      label={
                        commonService.applyLocalization(
                          "restaurant",
                          "name",
                          item.locales
                        )["name"]
                      }
                    />
                  </Col>
                </Row>
              );
            })}
          </div>
        );
      });
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.props.t("common.noRecords")}
        </div>
      );
    }
  }

  public rolesList(data: any): any {
    let roleList;
    let rolesData =
      data.length > 0
        ? data?.map((role: any) => {
            role["name"] = commonService
              .applyLocalization("restaurant", "name", role?.locales)
              .name.toLowerCase();
            return role;
          })
        : [];

    rolesData = _.sortBy(rolesData, "name");
    if (!!data && data.length > 0) {
      roleList = rolesData.map((locales: any, i: number) => {
        return (
          <RoleCard
            key={i}
            setEditable={this.setEditable.bind(this, locales)}
            setDelete={this.setDelete.bind(this, locales)}
            locales={locales}
            addRighttoggle={this.addRighttoggle}
            managerighttoggle={this.managerighttoggle}
          />
        );
      });
      return roleList;
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.props.t("common.noRecords")}
        </div>
      );
    }
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.getRoleList({ restaurantuuid: this.props.match.params.uuid });
    this.props.getComponentList();
    this.componentList(this.state.componentlist);
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isfetching);
      if (!!newProps && !newProps.isfetching) {
        this.setState({
          rolelist: newProps.rolelist,
          componentlist: newProps.componentlist,
        });
      }
      if (!!this.state.isUpdated && !newProps.isfetching) {
        this.props.getRoleList({
          restaurantuuid: this.props.match.params.uuid,
        });
        this.props.getComponentList();
        this.setState({
          isUpdated: false,
        });
      }
    }
  }

  public AddToggle(): void {
    this.setState((prevState: any) => ({
      addmodal: !prevState.addmodal,
    }));
  }

  public saveRoleAdd(): void {
    this.props.addRole({
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.state.uuid,
      data: {
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.name,
          },
        },
      },
    });
    this._setStates("isUpdated", true);
    this.AddToggle();
  }

  public saveManageRight(): void {
    this.props.addRoleRight({
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.state.roler_uuid,
      data: {
        right_uuids: this.state.manageright,
      },
    });
    this._setStates("isUpdated", true);
    this.cancelManagetoggle();
  }

  public saveEdit(): void {
    this.props.updateRole({
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.state.uuid,
      data: {
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: this.state.name,
          },
        },
      },
    });
    this._setStates("isUpdated", true);
    this.toggle();
  }

  public toggle(): void {
    this.setState((prevState: any) => ({
      editmodal: !prevState.editmodal,
    }));
  }

  public cancelManagetoggle(): void {
    this.setState((prevState: any) => ({
      managemodal: !prevState.managemodal,
    }));
  }

  public addRighttoggle(): void {
    this.setState((prevState: any) => ({
      addrightmodal: !prevState.addrightmodal,
    }));
  }

  public managerighttoggle(event: any, roler_uuid: any): void {
    this.setState((prevState: any) => ({
      managemodal: !prevState.managemodal,
      manageright: event,
      roler_uuid: roler_uuid,
    }));
    this.manageRightList = event;
  }

  private _setFunctionBindings(): void {
    this.handleChange = this.handleChange.bind(this);
    this.handleRoleRightChange = this.handleRoleRightChange.bind(this);
    this.saveEdit = this.saveEdit.bind(this);
    this.saveRoleAdd = this.saveRoleAdd.bind(this);
    this.toggle = this.toggle.bind(this);
    this.setEditable = this.setEditable.bind(this);
    this.setDelete = this.setDelete.bind(this);
    this.deleteToggle = this.deleteToggle.bind(this);
    this.AddToggle = this.AddToggle.bind(this);
    this.deleteConfirmToggle = this.deleteConfirmToggle.bind(this);
    this.addRighttoggle = this.addRighttoggle.bind(this);
    this.managerighttoggle = this.managerighttoggle.bind(this);
    this.cancelManagetoggle = this.cancelManagetoggle.bind(this);
    this.saveManageRight = this.saveManageRight.bind(this);
  }

  public setDelete(event: any): void {
    this._setStates("uuid", event.uuid);
    this.deleteToggle();
  }

  public deleteToggle(): void {
    this.setState((prevState: any) => ({
      open: !prevState.open,
    }));
  }

  public deleteConfirmToggle(): void {
    this.props.removeRole({
      uuid: this.state.uuid,
      restaurantuuid: this.props.match.params.uuid,
    });
    this._setStates("isUpdated", true);
    this.deleteToggle();
  }

  private _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
  }

  public setEditable(event: any): void {
    this.setState({
      name: commonService.applyLocalization(
        "restaurant",
        "name",
        event?.locales
      )["name"],
      uuid: event.uuid,
    });
    this.toggle();
  }

  public handleChange(e: any): void {
    this._setStates(e.target.name, e.target.value);
  }

  public handleRoleRightChange(event: any): void {
    if (event.target.checked) {
      let rightFilter: any = this.manageRightList.includes(event.target.value);
      if (!rightFilter) {
        this.manageRightList.push(event.target.value);
      }
    } else {
      let rightRemoveFilter = this.manageRightList.filter((item: any) => {
        return item !== event.target.value;
      });
      this.manageRightList = rightRemoveFilter;
    }
    //Remove duplicates
    this.setState({
      manageright: this.manageRightList.filter(
        (val: any, id: any, array: any) => array.indexOf(val) === id
      ),
    });
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.role.isFetching ||
    state.roleremove.isFetching ||
    state.roleadd.isFetching ||
    state.roleupdate.isFetching ||
    state.component.isFetching ||
    state.rolerightadd.isFetching;
  let failure =
    state.role.failure ||
    state.roleadd.failure ||
    state.roleremove.failure ||
    state.roleupdate.failure ||
    state.rolerightadd.failure ||
    state.component.failure;

  return {
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    rolelist: state.role.data,
    componentlist: state.component.data,
    isfetching: isFetching,
    failure: failure,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getRoleList: (credentials: any) => {
      dispatch(rolesRequest(credentials));
    },
    getComponentList: () => {
      dispatch(componentRequest());
    },
    updateRole: (credentials: any) => {
      dispatch(rolesUpdate(credentials));
    },
    addRole: (credentials: any) => {
      dispatch(rolesAdd(credentials));
    },
    addRoleRight: (credentials: any) => {
      dispatch(roleRightAdd(credentials));
    },
    removeRole: (credentials: any) => {
      dispatch(rolesRemove(credentials));
    },
  };
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(RolesAndRightsComponent))
);
